import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
            d="M15.8327 18.3337H4.16602V16.667H15.8327V18.3337ZM9.99935 15.0003L4.99935 10.0003L6.17435 8.82533L9.16602 11.8087V1.66699H10.8327V11.8087L13.8243 8.82533L14.9993 10.0003L9.99935 15.0003Z"
            fill="#5D697D"
        />
    </svg>
)
