import { memo } from 'react'
import { compose, withProps } from 'recompose'
import { Subtract } from 'utility-types'
import { connect, MapStateToProps } from 'react-redux'

import P, { INotifier } from './Notifier'
import withUpdates, { IProvidedProps, IRequiredProps } from './withUpdates'
import { PullNew } from '@/Services/Updates'
import { RootStore } from '@/_helpers/StoreType'
import parseNativeNotification from './parseNativeNotification'
import { System } from './PushNotification'
import TabNotification from './TabNotification'

export * from './model/actions.js'

type afterHOC = IRequiredProps & Subtract<INotifier, IProvidedProps>

type toAdd = Pick<afterHOC, 'UpdateNotifications' | 'SystemNotifications' | 'RegisterNotifications'>

type afterAdd = Subtract<afterHOC, toAdd>

type tState = Pick<afterAdd, 'token'>
type afterConnect = Subtract<afterAdd, tState>

const Tab = new TabNotification()

const Adds: toAdd = {
    UpdateNotifications: (token: string, from: Date) => {
        return PullNew(token, from).then((res) => parseNativeNotification(res.results))
    },
    SystemNotifications: System,
    RegisterNotifications: (count) => {
        Tab.count += count
    },
}

const mapState: MapStateToProps<tState, afterConnect, RootStore> = (state) => ({
    token: state.authentication.user?.token,
})

export default compose<INotifier, afterConnect>(
    connect<tState, undefined, afterConnect, RootStore>(mapState),
    withProps(Adds),
    withUpdates(),
    memo
)(P)
