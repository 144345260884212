import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.21 9.28945C1.06974 9.28921 0.936031 9.23007 0.841497 9.12645C0.745219 9.02368 0.697379 8.88471 0.709997 8.74445L0.832497 7.39745L6.4915 1.74045L8.26 3.50845L2.6025 9.16495L1.2555 9.28745C1.24 9.28895 1.2245 9.28945 1.21 9.28945ZM8.613 3.15495L6.845 1.38695L7.9055 0.326453C7.99928 0.232564 8.12654 0.17981 8.25925 0.17981C8.39195 0.17981 8.51921 0.232564 8.613 0.326453L9.6735 1.38695C9.76739 1.48074 9.82014 1.608 9.82014 1.7407C9.82014 1.87341 9.76739 2.00067 9.6735 2.09445L8.6135 3.15445L8.613 3.15495Z"
                fill="#A3ADBE"
            />
        </svg>
    )
}
