import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.9916 5.83334L9.99998 8.82501L7.00831 5.83334L5.83331 7.00834L8.82498 10L5.83331 12.9917L7.00831 14.1667L9.99998 11.175L12.9916 14.1667L14.1666 12.9917L11.175 10L14.1666 7.00834L12.9916 5.83334Z"
            fill="#333333"
        />
    </svg>
)
