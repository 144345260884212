import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.0656 2.76752C12.7532 2.4551 12.2467 2.4551 11.9343 2.76752C11.6218 3.07994 11.6218 3.58647 11.9343 3.89889L14.7353 6.69989H6.66654C5.57031 6.69989 4.51898 7.13536 3.74383 7.91052C2.96868 8.68567 2.5332 9.737 2.5332 10.8332V16.6666C2.5332 17.1084 2.89138 17.4666 3.3332 17.4666C3.77503 17.4666 4.1332 17.1084 4.1332 16.6666V10.8332C4.1332 10.1613 4.40011 9.51698 4.8752 9.04189C5.35029 8.56679 5.99465 8.29989 6.66654 8.29989H14.7352L11.9343 11.1009C11.6218 11.4133 11.6218 11.9198 11.9343 12.2322C12.2467 12.5446 12.7532 12.5446 13.0656 12.2322L17.2323 8.06556C17.5447 7.75314 17.5447 7.2466 17.2323 6.93418L13.0656 2.76752Z"
            fill="#5D697D"
        />
    </svg>
)
