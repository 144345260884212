import { gql } from '@apollo/client'

interface queryParams {
    orderBy: string
    limit?: number
    filters: string[]
    cursor?: string
}

export const getYouTubePostsQuery = (queryParams: queryParams) => {
    return gql`
        query GetYtPosts {
            ytPosts(
              ordering: {${queryParams.orderBy}}, 
              ${queryParams.limit ? 'first: ' + queryParams.limit + ',' : ''}
              after: "${queryParams.cursor ? queryParams.cursor : ''}", 
              filter: {${queryParams.filters.join(',')}}
             ) {
                edges {
                    node {
                        avatar
                        brandId
                        brandName
                        channelDescription
                        channelId
                        channelTitle
                        commentCount
                        countryId
                        description
                        id
                        likeCount
                        publishedAt
                        videoId
                        videoTitle
                        viewCount
                    }
                }
                pageInfo {
                    endCursor
                    hasNextPage
                    hasPreviousPage
                    startCursor
                }
                totalCount
            }
        }
    `
}

export const GET_YOUTUBE_BRANDS = gql`
    query GetBrands($brand: String) {
        get_yt_brands(args: { search: $brand }) {
            name
            substrings
        }
    }
`
