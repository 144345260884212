import Popup from '@/_yc/Popup'
import React, { FC, useCallback } from 'react'
import useToken from '@/_helpers/Hooks/useToken'
import { oauthGmailReq } from '@/pages/Outreach/Service/Campaigns'
import Flex from '@/_yc/Flex'
import Text from '@/components/Text'
import Icons from '../Icons'
import Gmail from './Gmail.png'
import { useSpaceTranslation } from '@/_helpers'

import cn from './MailSettingsPopup.module.scss'
import useOpenClose from '@/_helpers/useOpenClose'
import { SmtpPopup } from './SmtpPopup/SmtpPopup'

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 500,
    zIndex: 2000,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}

interface MailSettingsPopupProps {
    isOpen: boolean
    close: () => void
}

export const MailSettingsPopup: FC<MailSettingsPopupProps> = ({ isOpen, close }) => {
    const token = useToken()
    const [isOpenSmtpPopup, openSmtpPopup, closeSmtpPopup] = useOpenClose()

    const gmail = useCallback(() => {
        if (!token) return
        oauthGmailReq(token).then(({ redirect_url }) => window.open(redirect_url))
    }, [token])

    const t = useSpaceTranslation('settings.mail')

    if (!isOpen) return <></>

    return (
        <>
            {!isOpenSmtpPopup && (
                <div className={cn.root}>
                    <Popup style={style} isOpen={isOpen} onClose={close}>
                        <div className={cn.popup}>
                            <Flex column>
                                <Flex content="space-between" align="center">
                                    <Text fSize={20}>{t('gmail.title')}</Text>
                                    <div onClick={close}>
                                        <Icons name="close" className={cn['popup--close']} />
                                    </div>
                                </Flex>
                                <Text fSize={14} margin="20px 0 0 0">
                                    {t('gmail.subtitle')}
                                </Text>

                                <Flex content="space-between">
                                    <div className={cn['popup--provider']} onClick={gmail}>
                                        <img src={Gmail} alt="gmail" style={{ width: 56, height: 42 }} />
                                        <Text fSize={16} style={{ lineHeight: '20px' }}>
                                            {t('gmail.continue')}
                                        </Text>
                                    </div>

                                    <div
                                        className={cn['popup--provider']}
                                        onClick={() => {
                                            openSmtpPopup()
                                        }}
                                    >
                                        <Icons name="mail" className={cn.mailIcon} />
                                        <Text fSize={16} style={{ lineHeight: '20px' }}>
                                            {t('gmail.other')}
                                        </Text>
                                    </div>
                                </Flex>

                                <div className={cn['popup--desc']}>
                                    <Text fSize={12}>{t('gmail.desc')}</Text>
                                </div>
                            </Flex>
                        </div>
                    </Popup>
                </div>
            )}

            <SmtpPopup
                isOpen={isOpenSmtpPopup}
                close={() => {
                    closeSmtpPopup()
                }}
                closeParent={close}
            />
        </>
    )
}
