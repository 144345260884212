import React, { FC } from 'react'

import { Flex } from '@/_yc'
import MostViewed from './MostViewed/MostViewed'
import TimeSpan from './TimeSpan/TimeSpan'

export interface IControls {}

const Controls: FC<IControls> = () => {
    return (
        <Flex content="flex-end">
            <MostViewed />
            <TimeSpan />
        </Flex>
    )
}

export default Controls
