import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import GlobalWidth from '@/_yc/GlobalWidth'
import React, { useCallback, useContext, useEffect } from 'react'

import { useSelector } from 'react-redux'
import PageTitle from '@/components/PageTitle'
import cn from './Header.module.scss'
import Button from '@/components/Button'
import { useMutation } from '@apollo/client'
import { CampaignManagerContext } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerContext'
import { CREATE_EMPTY_MEDIA_PLAN } from '../utils/queries'

export const Header = () => {
    const t = useSpaceTranslation('media_planner.header')
    const context = useContext(CampaignManagerContext)

    const linkToHelper = useSelector<any, string>((state) => state.Commercial_.value.hp_cmanager)

    const [createMediaPlan, { data, loading }] = useMutation(CREATE_EMPTY_MEDIA_PLAN, {
        context: context,
    })

    const onCreateClick = useCallback(() => {
        createMediaPlan()
    }, [createMediaPlan])

    useEffect(() => {
        if (data && data.createMediaPlanOrder && !loading && data.createMediaPlanOrder.mediaPlanOrder.id) {
            window.location.href = `/media-planning/${data.createMediaPlanOrder.mediaPlanOrder.id}`
        }
    }, [data, loading])

    return (
        <>
            <GlobalWidth>
                <div className={cn.header}>
                    <div className={cn.heading}>
                        <PageTitle
                            title={t('title')}
                            linkToHelper={linkToHelper}
                            icon="media-planner"
                            iconSize="25px"
                            iconStyle={{ background: '#C9D49B' }}
                        />
                    </div>

                    <Button variant="primary" onClick={onCreateClick}>
                        {t('btn')}
                    </Button>
                </div>
            </GlobalWidth>
        </>
    )
}
