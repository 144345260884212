import PageLayout from '@/Layouts/PageLayout'
import PartChanger from '@/Layouts/ReportLayout/Extra/PartChanger'
import { useSpaceTranslation } from '@/_helpers'
import { useCampaignId } from '@/_helpers/Hooks/useCampaignId'
// import { useQuery } from '@apollo/client'
import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { CampaignManagerContext } from '../CampaignManagerContext/CampaignManagerContext'
import { CampaignManagerProvider } from '../CampaignManagerContext/CampaignManagerProvider'
import {
    //  IBlogger, ICampaign, ICampaignInfo,
    IPost,
} from '../types'
import CampaignHeader from './CampaignHeader/CampaignHeader'
// import { GET_CAMPAIGN } from './queries'
import { Content } from './Tabs/Content/Content'
// import { Influencers } from './Tabs/Influencers/Influencers'
// import { PublicationsTracking } from './Tabs/PublicationsTracking/PublicationsTracking'
import {
    // ICampaignData,
    Tab,
} from './types'
// import { preprocessCampaign } from './utils'
import useToken from '@/_helpers/Hooks/useToken'
import { GetCampaignAdPostsReq } from './queries'
import { useLocation } from 'react-router-dom'
import { GetCampaignReq, GetCampaignsRequestsReq } from './queries'

import cn from './Campaign.module.scss'

const Campaign = () => {
    const t = useSpaceTranslation('campaign_manager.campaign')
    const { t: tTitles } = useTranslation()
    const token = useToken()
    const [data, setData] = useState<IPost[]>([])
    const [status, setStatus] = useState<{ loading: boolean; error: boolean }>({ loading: true, error: false })

    const id = useCampaignId()
    const [campaignName, setcampaignName] = useState<string>('')
    const [campaignState, setcampaignState] = useState<boolean>(false)

    const locationState = (useLocation().state as { name: string }) || undefined
    useEffect(() => {
        if (locationState && locationState.name) setcampaignName(locationState.name)
        if (!token) return
        GetCampaignReq(token, id)
            .then((res) => {
                setcampaignName(res.name)
                //@ts-ignore
                setcampaignState(res.is_active)
            })
            .catch(() => setcampaignName(''))
    }, [locationState, token, id])

    // const context = useContext(CampaignManagerContext)

    const [tab, setTab] = useState<Tab>('content')

    // const { data, loading } = useQuery<ICampaignData>(GET_CAMPAIGN, {
    //     context: context,
    //     variables: {
    //         id: id,
    //     },
    // })
    useEffect(() => {
        setStatus({ loading: true, error: false })
        if (!token) return
        if (!id) {
            setStatus({ loading: false, error: true })
            return
        }
        Promise.all([GetCampaignAdPostsReq(token, id), GetCampaignsRequestsReq(token, id)])
            .then((res) => {
                setStatus({ loading: false, error: false })
                // const tempAr = [
                //     ...res[0],
                //     ...res[1].map((item: any) => {
                //         return {
                //             ...item,
                //             isRequest: true,
                //         }
                //     }),
                // ]

                const result = [...res[0]].map((el: any) => {
                    const temp = res[1].filter((item: any) => {
                        if (el.code) {
                            return el?.code === item?.media_metadata?.shortcode
                        }
                        if (el.video_id) {
                            return el?.video_id === item?.media_metadata?.video_id
                        }
                        if (el.media_id) {
                            return el?.media_id === item?.media_metadata?.media_id
                        }
                        if (el.message_id) {
                            return (
                                parseInt(el?.message_id) === parseInt(item?.media_metadata?.message_id) &&
                                el?.channel_name === item?.media_metadata?.channel_name
                            )
                        }
                        return false
                    })

                    return {
                        ...el,
                        id: temp.length > 0 ? temp[0].id : -1,
                        content_price:
                            temp.length > 0
                                ? temp[0].content_price === '0.00'
                                    ? undefined
                                    : temp[0].content_price
                                : undefined,
                    }
                })

                res[1].forEach((item: any) => {
                    const isExists =
                        res[0].filter((el: any) => {
                            if (el.code) {
                                return el?.code === item?.media_metadata?.shortcode
                            }
                            if (el.video_id) {
                                return el?.video_id === item?.media_metadata?.video_id
                            }
                            if (el.media_id) {
                                return el?.media_id === item?.media_metadata?.media_id
                            }
                            if (el.message_id) {
                                return (
                                    parseInt(el?.message_id) === parseInt(item?.media_metadata?.message_id) &&
                                    el?.channel_name === item?.media_metadata?.channel_name
                                )
                            }
                            return false
                        }).length > 0

                    if (!isExists) {
                        result.push({
                            ...item,
                            isRequest: true,
                        })
                    }
                })

                setData(result)
            })
            .catch((er) => {
                setStatus({ loading: false, error: true })
                console.log(er)
            })
    }, [id, token])

    // const preprocessedCampaign = useMemo(() => {
    //     if (!data || !data?.campaignOrderById)
    //         return {
    //             name: '',
    //             posts: [] as IPost[],
    //             status: '',
    //             id: '',
    //             postsCount: 0,
    //             createdDate: '',
    //             modifiedDate: '',
    //             data: {} as ICampaignInfo,
    //             bloggers: [] as IBlogger[],
    //         }

    //     return preprocessCampaign(data.campaignOrderById) as ICampaign
    // }, [data])

    const changeTab = (value: string | number) => {
        setTab(value as Tab)
    }

    const partChangerValues = [
        {
            title: t('tabs.dash'),
            value: 'dashboard',
            dispFunction: (v: any) => changeTab(v),
            soon: true,
        },
        {
            title: t('tabs.influencers'),
            value: 'influencers',
            dispFunction: (v: any) => changeTab(v),
            soon: true,
        },
        {
            title: t('tabs.calendar'),
            value: 'calendar',
            dispFunction: (v: any) => changeTab(v),
            soon: true,
        },
        {
            title: t('tabs.contentCoo'),
            value: 'calendar',
            dispFunction: (v: any) => changeTab(v),
            soon: true,
        },
        {
            title: t('tabs.content'),
            value: 'content',
            dispFunction: (v: any) => changeTab(v),
        },
        {
            title: t('tabs.payments'),
            value: 'payments',
            dispFunction: (v: any) => changeTab(v),
            soon: true,
        },
        // {
        //     title: t('tabs.tracking'),
        //     value: 'tracking',
        //     dispFunction: (v: any) => changeTab(v),
        //     soon: true,
        // },
    ]

    return (
        <CampaignManagerProvider>
            <PageLayout color="common-background" title={tTitles('titles.t5')}>
                {/* <GlobalWidth> */}
                <div className={cn.children}>
                    <CampaignHeader name={campaignName} id={id} />

                    <PartChanger values={partChangerValues} currentPart={4} />
                    <div style={{ margin: '30px 0 0 0' }}>
                        {tab === 'influencers' && <>{/* <Influencers bloggers={[]} loading={false} /> */}</>}
                        {tab === 'content' && (
                            <>
                                <Content posts={data} loading={status} campaignState={campaignState} />
                            </>
                        )}
                        {tab === 'tracking' && <>{/* <PublicationsTracking campaign={} loading={false} /> */}</>}
                    </div>
                </div>
                {/* </GlobalWidth> */}
            </PageLayout>
        </CampaignManagerProvider>
    )
}

export default memo(Campaign)
