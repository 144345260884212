import React, { SVGAttributes, Ref } from 'react';

interface Ip extends SVGAttributes<SVGElement> {
	ref?: Ref<any>
}

export default React.forwardRef<any, Ip>((props: Ip, ref: Ref<any>) => (
	<svg ref={ref} {...props} width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M7.80556 5.70049C7.67589 5.56677 7.51854 5.50002 7.33326 5.50002H2.22217V3.20838C2.22217 2.70222 2.39581 2.2702 2.74306 1.9121C3.09033 1.55408 3.50928 1.37504 4.00006 1.37504C4.49079 1.37504 4.90982 1.55406 5.25694 1.9121C5.60421 2.2702 5.77786 2.70224 5.77786 3.20838C5.77786 3.33247 5.82181 3.43988 5.90969 3.53058C5.99777 3.62131 6.10197 3.66668 6.22218 3.66668H6.66684C6.78712 3.66668 6.89128 3.62131 6.97935 3.53058C7.06706 3.43988 7.11119 3.33247 7.11119 3.20838C7.11119 2.32503 6.80678 1.56964 6.19805 0.941767C5.58923 0.313872 4.85652 0 4.00006 0C3.14352 0 2.41089 0.313872 1.80202 0.941742C1.19325 1.56954 0.888862 2.32501 0.888862 3.20835V5.5H0.666665C0.481537 5.5 0.324089 5.5669 0.194444 5.70047C0.0647984 5.83404 0 5.99646 0 6.18744V10.3125C0 10.5036 0.0648227 10.6659 0.194444 10.7996C0.324089 10.9331 0.481537 11 0.666665 11H7.33326C7.51854 11 7.67603 10.9331 7.80556 10.7996C7.93508 10.6659 8 10.5036 8 10.3125V6.18747C8.0001 5.99648 7.93525 5.83424 7.80556 5.70049Z" fill="#6172FF"/>
	</svg>
))