import { AccountIndex } from '@/pages/Auth/DetailedInfoPopUp/types'
import Service from '@/_helpers/Service'

const RegisterUserService = new Service({
    url: '/core/accounts/register/',
    method: 'POST',
})
export const registerUser = (
    email: string,
    password: string,
    confirm_password: string,
    accept_language: string,
    referral_code?: string
) =>
    RegisterUserService.call({
        headers: {
            'accept-language': accept_language,
        },
        data: {
            email,
            password,
            password_confirm: confirm_password,
            referral_code,
        },
    })

const LanguageUserService = new Service({
    url: 'core/accounts/profile/',
    method: 'PATCH',
})
export const changeUserLng = (lng: string, token: string) =>
    LanguageUserService.call(
        {
            data: {
                language: lng,
            },
        },
        token
    )

const UserDetailsService = new Service({
    method: 'POST',
    url: '/api/user_details/',
})

interface ISharedDetails {
    token: string
    user_type?: AccountIndex
}
export interface IBrandDetails {
    company_name?: string
    company_site?: string
    role?: string
    employees_count?: string
}
export interface IInflluencerDetails {
    phone?: string
    links?: string[]
}
export type IBrandInfluencer = IBrandDetails | IInflluencerDetails
type IDetails = ISharedDetails & IBrandInfluencer
export const submitDetails = (data: IDetails) => {
    const { token, ...prepData } = data
    return UserDetailsService.call(
        {
            data: prepData,
        },
        data.token
    )
}

const requestCampaignService = new Service({
    method: 'POST',
    url: '/api/requests/new/',
})

export const requestCampaign = (token: string, phone: string, email: string, name?: string) => {
    return requestCampaignService.call(
        {
            data: {
                name: name || '',
                phone,
                email: email,
                demo_type: 'campaign_manager',
            },
        },
        token
    )
}

const ForgotPasswordService = new Service({
    method: 'POST',
    url: '/core/accounts/send-reset-password-link/',
})
export const forgotPasswordRequest = (email: string) =>
    ForgotPasswordService.call({
        data: { login: email },
    })

const CreateReferral = new Service<{ code: string }>({
    method: 'POST',
    url: '/core/affiliate/referral/',
})

export const referralCreationService = (code: string, token: string) =>
    CreateReferral.call(
        {
            data: {
                code,
            },
        },
        token
    )

const getReferral = new Service<{
    code: string
    balance: number
    hits: number
    payments_count: number
    payments_income: number
    payments_profit: number
    referral_stats: any
    registrations: number
}>({
    method: 'GET',
    url: '/core/affiliate/referral/',
})

export const referralService = (token: string) => getReferral.call({}, token)

const resetPasswordService = new Service({
    url: '/core/accounts/reset-password/',
    method: 'POST',
})
export const resetPassword = (
    user_id: string,
    timestamp: string,
    signature: string,
    password: string,
    accept_language: string
) =>
    resetPasswordService.call({
        headers: {
            'accept-language': accept_language,
        },
        data: {
            user_id,
            timestamp,
            signature,
            password,
        },
    })
