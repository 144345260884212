import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

import sorry from './sorry.png'


// Show this bar if there are no items
const EmptyBar = (props) => (
    <div style = {{ textAlign: 'center' }}>
        <div 
            style = {{
                textAlign: 'center',
                fontSize: 30,
                color: "#2a2a31",
                margin: "3px 0"
            }}
        >
            {props.reason}
        </div>
        <div 
            style = {{
                textAlign: 'center',
                fontSize: 14,
                color: "#888",
                margin: "3px 0"
            }}
        >
            {props.description}
        </div>
        <div>
            <img src={sorry} alt="" height="28" width="30" />
        </div>
    </div>
)

// Show this bar if there are no items
const LoadingBar = () => (
    <div style = {{ textAlign: 'center' }}>
        <CircularProgress />
    </div>
)

export { EmptyBar, LoadingBar };
