import React, { FC, useState } from 'react'
import Icons from '../Icons'

import cn from './ValueInput.module.scss'

interface ValueInputProps {
    onSubmit: (value: string) => void
    onClose: () => void
    placeholder: string
}

export const ValueInput: FC<ValueInputProps> = ({ onClose, onSubmit, placeholder }) => {
    const [value, setValue] = useState('')

    return (
        <div className={cn.valueInput}>
            <input value={value} placeholder={placeholder} type={'text'} onChange={(e) => setValue(e.target.value)} />

            <div className={cn.buttons}>
                <div className={cn['btn--done']} onClick={() => onSubmit(value)}>
                    <Icons name={'done'} />
                </div>

                <div onClick={onClose} className={cn['btn--close']}>
                    <Icons name={'close--small'} />
                </div>
            </div>
        </div>
    )
}
