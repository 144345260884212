import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Scrollbars from 'react-custom-scrollbars'
import { Flex } from '@/_yc'

import { BasicFilter, BasicFiltersOptions } from '../BasicFilter'

import cn from './SelectorFilter.module.scss'
import useOpenClose from '@/_helpers/useOpenClose'
import Popup from '@/_yc/Popup'
import Text from '@/components/Text'
import Item from './Item'
import useToken from '@/_helpers/Hooks/useToken'
import FilterBase from '../FilterBase'
import Tag from '@/pages/SearchPage/Bloggers/SearchPanel/Chips/Tag'
import SelectedTag from './SelectedTag'
import { trackFilterChange } from '@/_helpers/amplitude/track'
import MainFilterBase from '../MainFilterBase/MainFilterBase'
import { CustomSlider } from '../Slider/CustomSlider'
import Icons from '@/components/Icons'
import { useGlobalWidth } from '@/_helpers/Hooks/useGlobalWidth'
import { useSearch } from '../../hooks/useSearch'
import { Checkbox } from './Checkbox'

export type ISelectorFilter<ValueType, HasPercentage extends boolean> = {
    isMulti?: boolean
    autocomplete?: boolean
    checkmarkOptions?: Array<{
        label: string
        value: ValueType
        translate?: boolean
    }>
    options:
        | Array<{
              label: string
              value: ValueType
              translate?: boolean
          }>
        | ((
              token: string,
              locale: string,
              query?: string
          ) => Promise<
              Array<{
                  label: string
                  value: ValueType
              }>
          >)
    hasPercentage?: HasPercentage
}

type Config = {
    placeholder?: string
}

type SelectorState<ValueType, HasPercentage extends boolean = false> = {
    value: ValueType[]
    percentage: HasPercentage extends true ? number : null
}

const style = { zIndex: 10, marginTop: 7 }
const dn = { display: 'none' }

const hideTrack = (props: { [k: string]: any }) => <div {...props} className="track-horizontal" style={dn} />
const hideThumb = (props: { [k: string]: any }) => <div {...props} className="thumb-horizontal" style={dn} />

export class SelectorFilter<ServerData, ValueType, HasPercentage extends boolean = false> extends BasicFilter<
    ServerData,
    SelectorState<ValueType, HasPercentage>
> {
    config: Config
    selector: ISelectorFilter<ValueType, HasPercentage>

    // !!! SHIT CODE !!!
    // YOU SHOULD STRINGIFY VALUETYPE TO USE IT AS A KEY
    private labelMap = new Map<
        string,
        {
            label: string
            translate?: boolean
        }
    >()

    constructor({
        isMulti,
        options,
        hasPercentage,
        placeholder,
        checkmarkOptions,
        ...basic
    }: ISelectorFilter<ValueType, HasPercentage> &
        BasicFiltersOptions<ServerData, SelectorState<ValueType, HasPercentage>> &
        Config) {
        super(basic)
        this.selector = {
            isMulti,
            options,
            hasPercentage,
            checkmarkOptions,
        }

        this.config = {
            placeholder,
        }

        if (!(options instanceof Function)) {
            for (const option of options) {
                this.labelMap.set(JSON.stringify(option.value), {
                    label: option.label,
                    translate: option.translate,
                })
            }
        }

        for (const option of checkmarkOptions || []) {
            this.labelMap.set(JSON.stringify(option.value), {
                label: option.label,
                translate: option.translate,
            })
        }
    }

    getDefaultValue() {
        return {
            value: [],
            percentage: this.selector.hasPercentage ? 10 : (null as any),
        }
    }

    MainView = (props: { filterName: string; autocomplete?: boolean }) => {
        const { t } = useTranslation()
        const [value, setValue, clear] = this.useFilterState()
        const { i18n } = useTranslation()

        const ref = useRef<HTMLDivElement>(null)

        const [state, open, close, toggle] = useOpenClose()
        const [searchValue, setSearchValue] = useState('')
        const { restart } = useSearch()

        const search = useCallback(() => {
            setTimeout(() => {
                restart()
            }, 300)
        }, [restart])

        const width = useMemo(() => {
            if (!ref.current || !state) return
            return {
                width: window.getComputedStyle(ref.current).width,
            }
        }, [state])

        const getOptions = useCallback((token: string | null, lang: string, query = '') => {
            if (!token || !(this.selector.options instanceof Function)) return

            this.selector.options(token, lang, query).then((ops) => {
                for (const option of ops) {
                    this.labelMap.set(JSON.stringify(option.value), {
                        label: option.label,
                        translate: false,
                    })
                }

                setOptions(ops)
            })
        }, [])

        const token = useToken()

        useEffect(() => {
            getOptions(token, i18n.language)
        }, [token, i18n.language, getOptions])

        useEffect(() => {
            if (!props.autocomplete) return
            getOptions(token, i18n.language, searchValue)
        }, [props.autocomplete, searchValue, token, i18n.language, getOptions])

        const [options, setOptions] = useState(() => {
            if (this.selector.options instanceof Function) {
                return []
            }
            return this.selector.options
        })

        const setSearch = useCallback((val) => setSearchValue(val.target.value), [])

        const change = useCallback(
            (value: ValueType) => {
                setSearchValue('')
                const labelMapEntry = this.labelMap.get(JSON.stringify(value))
                trackFilterChange({
                    t,
                    title: this.options.title,
                    value: labelMapEntry?.label,
                    platform: this.options.platform,
                    translate: labelMapEntry?.translate,
                })
                if (!this.selector.isMulti) {
                    close()
                    setValue((prev) => {
                        if (prev.value[0] === value) return undefined
                        return {
                            ...prev,
                            value: [value],
                        }
                    })
                    return
                }
                setValue((prev) => {
                    const next = prev.value.includes(value)
                        ? prev.value.filter((item) => item !== value)
                        : prev.value.concat(value)

                    if (!next.length) return undefined

                    return {
                        ...prev,
                        value: next,
                    }
                })

                search()
            },
            [setValue, close, t, search]
        )

        const globalWidth = useGlobalWidth()

        const soon = this.options.soon
        return (
            <div>
                <MainFilterBase onClick={open} icon={this.options.icon} ref={ref} isActive={state} soon={soon}>
                    {!props.autocomplete ? (
                        <>
                            {value.value.length > 0 ? (
                                <div style={{ marginLeft: 10 }}>
                                    <SelectedTag onDelete={clear} count={value.value.length} />
                                </div>
                            ) : (
                                <Text color={value.value[0] ? undefined : 'gray-4'} fSize={12}>
                                    {this.labelMap.has(JSON.stringify(value.value[0]))
                                        ? this.labelMap.get(JSON.stringify(value.value[0]))?.translate
                                            ? t(this.labelMap.get(JSON.stringify(value.value[0]))?.label || '')
                                            : this.labelMap.get(JSON.stringify(value.value[0]))?.label
                                        : (this.config.placeholder && t(this.config.placeholder)) ||
                                          t(props.filterName)}
                                </Text>
                            )}
                        </>
                    ) : state ? (
                        <>
                            {value.value.length > 0 && (
                                <div style={{ marginLeft: 10 }}>
                                    <SelectedTag small={true} onDelete={clear} count={value.value.length} />
                                </div>
                            )}
                            <input
                                className={cn.mainInput}
                                type="text"
                                value={searchValue}
                                onChange={setSearch}
                                autoFocus
                                placeholder={
                                    state
                                        ? (this.config.placeholder && t(this.config.placeholder)) ||
                                          t('filters.global.any')
                                        : ''
                                }
                            />
                        </>
                    ) : value.value.length > 0 ? (
                        <div style={{ marginLeft: 10 }}>
                            <SelectedTag onDelete={clear} count={value.value.length} />
                        </div>
                    ) : (
                        <Text color={value.value[0] ? undefined : 'gray-4'} fSize={12}>
                            {this.labelMap.has(JSON.stringify(value.value[0]))
                                ? this.labelMap.get(JSON.stringify(value.value[0]))?.translate
                                    ? t(this.labelMap.get(JSON.stringify(value.value[0]))?.label || '')
                                    : this.labelMap.get(JSON.stringify(value.value[0]))?.label
                                : t(props.filterName) || t('filters.global.any')}
                        </Text>
                    )}
                </MainFilterBase>

                {!props.autocomplete && this.selector.checkmarkOptions && (
                    <Popup
                        disablePreventOverflow
                        style={{ zIndex: 10, marginTop: 16, marginLeft: -23, width: globalWidth }}
                        anchor={ref.current}
                        isOpen={state}
                        onClose={toggle}
                        placement="bottom-start"
                    >
                        <div className={cn.mainPopperWithCheckmark}>
                            <Flex align="center">
                                <Flex align="center" content="flex-start" className={cn['item--checkmark-title']}>
                                    <Text>Gaming: </Text>
                                </Flex>
                            </Flex>

                            {this.selector.checkmarkOptions.map((item, i) => {
                                const isActive = value.value.includes(item.value)
                                return (
                                    <>
                                        {item.label.toLowerCase().includes(searchValue.toLowerCase()) && (
                                            <Flex
                                                onClick={() => change(item.value)}
                                                align="center"
                                                style={{ padding: '0 0 0 16px' }}
                                            >
                                                <Checkbox isChecked={isActive} />
                                                <Flex
                                                    align="center"
                                                    content="flex-start"
                                                    className={cn['item--main--checkmark']}
                                                >
                                                    <Text semibold={isActive}>
                                                        {item.translate ? t(item.label) : item.label}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        )}
                                    </>
                                )
                            })}

                            {options.map((item, i) => {
                                const isActive = value.value.includes(item.value)
                                return (
                                    <>
                                        {item.label.toLowerCase().includes(searchValue.toLowerCase()) && (
                                            <Flex onClick={() => change(item.value)} align="center">
                                                {!isActive && <div className={cn['done--placeholder']}></div>}
                                                {isActive && <Icons name="done" className={cn.done} />}
                                                <Flex align="center" content="flex-start" className={cn['item--main']}>
                                                    <Text semibold={isActive}>
                                                        {item.translate ? t(item.label) : item.label}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        )}
                                    </>
                                )
                            })}
                        </div>
                    </Popup>
                )}

                {!props.autocomplete && !this.selector.checkmarkOptions && (
                    <Popup
                        disablePreventOverflow
                        style={{ zIndex: 10, marginTop: 16, marginLeft: -23, width: globalWidth }}
                        anchor={ref.current}
                        isOpen={state}
                        onClose={toggle}
                        placement="bottom-start"
                    >
                        <div className={cn.mainPopper}>
                            {options.map((item, i) => {
                                const isActive = value.value.includes(item.value)
                                return (
                                    <>
                                        {item.label.toLowerCase().includes(searchValue.toLowerCase()) && (
                                            <Flex onClick={() => change(item.value)} align="center">
                                                {!isActive && <div className={cn['done--placeholder']}></div>}
                                                {isActive && <Icons name="done" className={cn.done} />}
                                                <Flex align="center" content="flex-start" className={cn['item--main']}>
                                                    <Text semibold={isActive}>
                                                        {item.translate ? t(item.label) : item.label}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        )}
                                    </>
                                )
                            })}
                        </div>
                    </Popup>
                )}

                {props.autocomplete && (
                    <Popup
                        disablePreventOverflow
                        style={{ zIndex: 10, marginTop: 16, marginLeft: -10 }}
                        anchor={ref.current}
                        isOpen={state}
                        onClose={toggle}
                    >
                        <div style={width} className={cn.popper}>
                            <Scrollbars
                                renderTrackHorizontal={hideTrack}
                                renderThumbHorizontal={hideThumb}
                                autoHeight
                                autoHeightMax={300}
                            >
                                {options.map((item, i) => (
                                    <>
                                        {item.label.toLowerCase().includes(searchValue.toLowerCase()) && (
                                            <Item
                                                key={i}
                                                {...item}
                                                isActive={value.value.includes(item.value)}
                                                onClick={change}
                                            />
                                        )}
                                    </>
                                ))}
                            </Scrollbars>
                        </div>
                    </Popup>
                )}
            </div>
        )
    }

    View = (props: { disabled: boolean; filterName: string; autocomplete?: boolean; scrollToFilter?: Function }) => {
        const ref = useRef<any>(null)

        const [value, setValue, clear, clickedVal, setClicked] = this.useFilterState()
        const [percentage, setPercentage] = useState(10)

        const token = useToken()

        const [options, setOptions] = useState(() => {
            if (this.selector.options instanceof Function) {
                return []
            }
            return this.selector.options
        })
        const { i18n } = useTranslation()

        const [state, open, close, toggle] = useOpenClose()

        const { t } = useTranslation()
        const width = useMemo(() => {
            if (!ref.current || !state) return
            return {
                width: window.getComputedStyle(ref.current).width,
            }
        }, [state])
        const [searchValue, setSearchValue] = useState('')
        const setSearch = useCallback((val) => setSearchValue(val.target.value), [])

        const change = useCallback(
            (value: ValueType) => {
                setSearchValue('')
                const labelMapEntry = this.labelMap.get(JSON.stringify(value))
                trackFilterChange({
                    t,
                    title: this.options.title,
                    value: labelMapEntry?.label,
                    platform: this.options.platform,
                    translate: labelMapEntry?.translate,
                })
                if (!this.selector.isMulti) {
                    if (!this.selector.hasPercentage) close()
                    setValue((prev) => {
                        if (prev.value[0] === value) return undefined
                        return {
                            ...prev,
                            value: [value],
                        }
                    })
                    return
                }
                if (props.autocomplete && !this.selector.hasPercentage) {
                    close()
                }

                setValue((prev) => {
                    const next = prev.value.includes(value)
                        ? prev.value.filter((item) => item !== value)
                        : prev.value.concat(value)

                    if (!next.length) return undefined

                    return {
                        ...prev,
                        value: next,
                    }
                })
            },
            [setValue, close, props.autocomplete, t]
        )

        if (clickedVal) {
            props.scrollToFilter && props.scrollToFilter(ref)
            open()
            setClicked()
        }
        const onPercentageCommit = useCallback(
            (event: ChangeEvent<{}>, value: number | number[]) => {
                if (Array.isArray(value)) return
                setValue((prev) => ({
                    ...prev,
                    percentage: prev.percentage ? value : (prev.percentage as any),
                }))
            },
            [setValue]
        )

        const changePercentage = useCallback((event: ChangeEvent<{}>, value: number | number[]) => {
            if (Array.isArray(value)) return
            setPercentage(value)
        }, [])

        const getOptions = useCallback((token: string | null, lang: string, query = '') => {
            if (!token || !(this.selector.options instanceof Function)) return

            this.selector.options(token, lang, query).then((ops) => {
                for (const option of ops) {
                    this.labelMap.set(JSON.stringify(option.value), {
                        label: option.label,
                        translate: false,
                    })
                }

                setOptions(ops)
            })
        }, [])

        const locked = this.useIsLocked()

        useEffect(() => {
            getOptions(token, i18n.language)
        }, [token, i18n.language, getOptions])

        useEffect(() => {
            if (!props.autocomplete) return
            getOptions(token, i18n.language, searchValue)
        }, [props.autocomplete, searchValue, token, i18n.language, getOptions])

        const soon = this.options.soon
        return (
            <>
                <Flex>
                    <FilterBase onClick={toggle} ref={ref} isActive={state} disabled={locked} soon={soon}>
                        {!props.autocomplete ? (
                            <>
                                {value.value.length > 0 ? (
                                    <SelectedTag onDelete={clear} count={value.value.length} />
                                ) : (
                                    <Text color={value.value[0] ? undefined : 'gray-4'}>
                                        {this.labelMap.has(JSON.stringify(value.value[0]))
                                            ? this.labelMap.get(JSON.stringify(value.value[0]))?.translate
                                                ? t(this.labelMap.get(JSON.stringify(value.value[0]))?.label || '')
                                                : this.labelMap.get(JSON.stringify(value.value[0]))?.label
                                            : (this.config.placeholder && t(this.config.placeholder)) ||
                                              t('filters.global.any')}
                                    </Text>
                                )}
                            </>
                        ) : state ? (
                            <>
                                {value.value.length > 0 && (
                                    <SelectedTag small={true} onDelete={clear} count={value.value.length} />
                                )}
                                <input
                                    className={cn.input}
                                    type="text"
                                    value={searchValue}
                                    onChange={setSearch}
                                    autoFocus
                                    placeholder={
                                        state
                                            ? ''
                                            : (this.config.placeholder && t(this.config.placeholder)) ||
                                              t('filters.global.any')
                                    }
                                />
                            </>
                        ) : value.value.length > 0 ? (
                            <SelectedTag onDelete={clear} count={value.value.length} />
                        ) : (
                            <Text color={value.value[0] ? undefined : 'gray-4'}>
                                {this.labelMap.has(JSON.stringify(value.value[0]))
                                    ? this.labelMap.get(JSON.stringify(value.value[0]))?.translate
                                        ? t(this.labelMap.get(JSON.stringify(value.value[0]))?.label || '')
                                        : this.labelMap.get(JSON.stringify(value.value[0]))?.label
                                    : (this.config.placeholder && t(this.config.placeholder)) ||
                                      t('filters.global.any')}
                            </Text>
                        )}

                        <Flex align="center">
                            {value.percentage && (
                                <div className={cn.label}>
                                    <Text color="gray-3" fSize={14}>
                                        {value.percentage}%
                                    </Text>
                                </div>
                            )}

                            {!soon && (
                                <Icons name="arrow-down" className={cn[state ? 'icon--opened' : 'icon--closed']} />
                            )}
                        </Flex>
                    </FilterBase>
                </Flex>
                <Popup disablePreventOverflow style={style} anchor={ref.current} isOpen={state} onClose={toggle}>
                    <div>
                        <div style={width} className={cn.popper}>
                            <Scrollbars
                                renderTrackHorizontal={hideTrack}
                                renderThumbHorizontal={hideThumb}
                                autoHeight
                                autoHeightMax={220}
                            >
                                {options.map((item, i) => (
                                    <>
                                        {item.label.toLowerCase().includes(searchValue.toLowerCase()) && (
                                            <Item
                                                key={i}
                                                {...item}
                                                isActive={value.value.includes(item.value)}
                                                onClick={change}
                                            />
                                        )}
                                    </>
                                ))}
                            </Scrollbars>
                        </div>
                        {value.percentage && (
                            <div>
                                <div
                                    style={{ ...width, marginTop: '6px' }}
                                    className={`${cn.popper} ${
                                        value.value.length !== 0 ? '' : cn['popper--disabled']
                                    } ${cn['popper--slider']}`}
                                >
                                    <Text fSize={12} color="gray-new-2" margin="0 0 10px 0">
                                        Audience percentage
                                    </Text>
                                    <CustomSlider
                                        value={percentage}
                                        onChange={changePercentage}
                                        onChangeCommitted={onPercentageCommit}
                                        getAriaLabel={() => ''}
                                        valueLabelDisplay="on"
                                        disabled={value.value.length === 0}
                                        min={0}
                                        step={1}
                                        max={95}
                                        valueLabelFormat={(value: number) => `${value}%`}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </Popup>
            </>
        )
    }

    Tags = () => {
        const [value, setValue, , , setClicked] = this.useFilterState()

        const { t } = useTranslation()

        const del = useCallback(
            (id: ValueType | null) => {
                setValue((prev) => {
                    const res = prev.value.filter((item) => item !== id)
                    if (!res.length) return
                    return {
                        ...prev,
                        value: res,
                    }
                })
            },
            [setValue]
        )
        return (
            <>
                {value.value.map((item) => (
                    <Tag
                        key={JSON.stringify(item) + ''}
                        label={`${t(this.options.title)}: ${t(this.labelMap.get(JSON.stringify(item))?.label || '')}`}
                        value={item}
                        onDelete={del}
                        onClick={setClicked}
                    />
                ))}
            </>
        )
    }
}
