import React, { FC, useMemo } from 'react'

import StandartLabel from '../../Blocks/StandartLabel'
import { shortNum } from '@/_helpers/_graphs/_tech'

import cn from './ErMetrics.module.scss'
import useReportTranslation from '../useReportTranslation'

export interface IErMetrics {
    space?: number
    title: string
    data: Array<{
        label: string
        value: number
        active: boolean
    }>
    mainValue: number
    hidden?: boolean
    width: string
}

const ErMetrics: FC<IErMetrics> = (props) => {
    const { width, data, mainValue, hidden } = props

    const t = useReportTranslation(props.title)

    const [max, grid] = useMemo(() => {
        const m = data.reduce((prev, item) => (prev < item.value ? item.value : prev), 0)
        const del = Math.round(m / 7)
        const g: string[] = new Array(8).fill(null).map((item, index) => shortNum(index * del, 0))
        return [m, g]
    }, [data])

    const names: Array<JSX.Element> = []
    const bars: Array<JSX.Element> = []

    data.forEach((item, index) => {
        names.push(
            <div className={cn.name} key={index}>
                {item.label}
            </div>
        )
        bars.push(
            <div
                key={index}
                className={cn.bar}
                style={{
                    width: `${(item.value * 100) / max}%`,
                    background: item.active ? '#6172FF' : '#F1F1EE',
                }}
            />
        )
    })

    return (
        <div className={cn.root} style={{ width }}>
            <StandartLabel title={t('title')} helperText={t('helper')} />
            <div className={cn.value}>{(mainValue * 100).toFixed(2)}%</div>
            <div className={hidden ? cn['bar_container--hidden'] : cn['bar_container--visible']}>
                <div className={cn.bar_names}>{names}</div>
                <div className={cn.graph_container}>
                    <div className={cn.bars}>{bars}</div>
                    {grid.map((item, index) => (
                        <div key={index} className={cn.del}>
                            <div
                                className={cn.line}
                                style={{
                                    opacity: index && 1,
                                }}
                            />
                            <div className={cn.str}>{item}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ErMetrics
