import Slider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core'

export const CustomSlider = withStyles({
    root: {
        color: '#D7DCE6',
        height: 4,
    },
    thumb: (props: any) => ({
        height: '16px !important',
        width: '16px !important',

        backgroundColor: '#fff',
        border: props.disabled ? '2px solid #929EB0' : '2px solid #6071FF',
        marginTop: '-5px !important',
        marginLeft: '-5px !important',
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit !important',
        },
    }),
    valueLabel: (props: any) => ({
        left: 'calc(-50% - 4px)',
        fontSize: 12,
        fontWeight: 'normal',
        top: -16,
        backgroundColor: 'unset',
        color: 'black',
        '&:before': {
            display: 'none',
        },
        '& *': {
            background: 'transparent',
            color: props.disabled ? '#929EB0' : '#6071FF',
        },
    }),
    track: (props: any) => ({
        height: 4,
        borderRadius: 4,
        backgroundColor: props.disabled ? '#929EB0' : '#6071FF',
    }),
    rail: {
        height: 4,
        borderRadius: 4,
    },
})(Slider)
