import React, { FC, CSSProperties } from 'react'
import cn from './LoadingAvatar.module.scss'
interface ILoadingAvatar {
    style?: CSSProperties
    width?: string
}

const LoadingAvatar: FC<ILoadingAvatar> = ({ style, width = '50' }) => {
    return (
        <div className={cn.avatar} style={{ width: `${width}px`, height: `${width}px`, ...style }}>
            <div className={cn['avatar--background']} style={{ width: `${width}px`, height: `${width}px` }} />
            <svg
                width={width}
                height={width}
                viewBox={`0 0 ${width} ${width}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={cn['avatar--icon']}
            >
                <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width={width} height={width}>
                    <rect width={width} height={width} rx="4" fill="#6172FF" />
                </mask>
                <g mask="url(#mask0)">
                    <circle
                        cx={`${parseInt(width) / 2}`}
                        cy={`${parseInt(width) * 0.4}`}
                        r={`${parseInt(width) * 0.18}`}
                        fill="#6172FF"
                    />
                    <circle
                        cx={`${parseInt(width) / 2}`}
                        cy={`${parseInt(width) + 1}`}
                        r={`${parseInt(width) * 0.4}`}
                        fill="#6172FF"
                    />
                </g>
            </svg>
        </div>
    )
}

export default LoadingAvatar
