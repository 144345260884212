import React, { FC } from 'react'
import cn from './Tooltip.module.scss'
import { shortNum } from '@/_helpers/_graphs/_tech'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

interface Tooltip {
    dataPointIndex: number
    w: any
}

const Tooltip: FC<Tooltip> = ({ dataPointIndex, w }) => {
    const t = useSpaceTranslation('report_layout.allocation_graph')
    const data = w.config.series[0].data[dataPointIndex]

    return (
        <div className={cn.tooltip}>
            <div className={cn.flex}>
                <div className={cn.likes}>{shortNum(data.x)}</div>
                <div className={cn.title}>{t('likes')}</div>
            </div>

            <div className={cn.flex}>
                <div className={cn.dislikes}>{shortNum(data.y)}</div>
                <div className={cn.title}>{t('comms')}</div>
            </div>
        </div>
    )
}

export default Tooltip
