import React, { FC, useMemo } from 'react'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import MoreModal from '@/components/MoreModal'
import { StandartLabel } from '../../Blocks'
import Bar from './Bar'
import useReportTranslation from '../useReportTranslation'

import cn from './Interests.module.scss'

// import { shortNum } from '@/_helpers/_graphs/_tech'
// import LegendItem from '../LegendItem'
// import { IBar } from './Bar'

export interface IInterests {
    title: string
    data: Array<{
        share: number
        label?: string
        category?: string
        name?: string
    }>
    width: string | number
    hidden?: boolean
}

const Interests: FC<IInterests> = ({ title, width, data, hidden }) => {
    const t = useReportTranslation(title)

    const size = useWindowWidth('mobile')
    const sortedData = data.sort((a, b) => {
        return a.share > b.share ? -1 : 1
    })
    const max = useMemo(
        () =>
            sortedData.reduce((prev, item) => {
                let prom = item.share
                return prom > prev ? prom : prev
            }, 0),
        [sortedData]
    )

    let grid: any = []
    for (let i = 0; i <= 10; i++) {
        grid.push(i / 10)
        if (i / 10 > max) break
        if (max < 0.3) i -= 0.5
        if (max > 0.7) i++
    }
    grid.reverse()

    return (
        <>
            <div className={cn.root} style={{ width }}>
                <div className={cn.hat}>
                    <StandartLabel title={t('title')} helperText={t('helper')} />
                    {!hidden && (
                        <MoreInterests title={title} width={width} data={sortedData} hidden={hidden} grid={grid} />
                    )}
                </div>
                <div className={hidden ? cn['data--hidden'] : cn['data--default']}>
                    {size !== 'mobile' ? (
                        <>
                            {grid.map((item: any, index: any) => (
                                <div key={index} className={cn.grid}>
                                    <div className={cn.grid_str}>{`${item * 100}%`}</div>
                                    <div
                                        className={cn.grid_line}
                                        style={{
                                            opacity: index !== grid.length - 1 && index ? 1 : 0,
                                        }}
                                    />
                                </div>
                            ))}
                            <div className={cn.chart}>
                                {sortedData.slice(0, 5).map((item, index) => (
                                    <Bar title={title} key={index} {...item} max={grid[0]} />
                                ))}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={cn.chart}>
                                {sortedData.slice(0, 5).map((item, index) => (
                                    <Bar title={title} key={index} {...item} max={grid[0]} />
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default Interests

interface IMoreInterests {
    title: string
    data: Array<{
        share: number
        label?: string
        category?: string
        name?: string
    }>
    width: string | number
    hidden?: boolean
    grid: Array<number>
}
const MoreInterests: FC<IMoreInterests> = ({ title, width, data, hidden, grid }) => {
    const t = useReportTranslation(title)
    const size = useWindowWidth('mobile')
    return (
        <MoreModal title={t('title')}>
            <div className={cn.root} style={{ width }}>
                <div className={hidden ? cn['data--hidden'] : cn['data--default']}>
                    {grid.map((item: any, index: any) => (
                        <div key={index} className={cn.grid}>
                            <div className={cn.grid_str}>{`${item * 100}%`}</div>
                            <div
                                className={cn.grid_line}
                                style={{
                                    opacity: index !== grid.length - 1 && index ? 1 : 0,
                                }}
                            />
                        </div>
                    ))}
                    <div className={cn.chart}>
                        {size === 'mobile'
                            ? data
                                  .slice(0, 5)
                                  .map((item, index) => <Bar title={title} key={index + 5} {...item} max={grid[0]} />)
                            : data
                                  .slice(0, 10)
                                  .map((item, index) => <Bar title={title} key={index} {...item} max={grid[0]} />)}
                    </div>
                </div>
            </div>
        </MoreModal>
    )
}
