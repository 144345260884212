export const defaultData = [
    {
        label: 'Male',
        label_color: '#6071FF',
        value: 0.44096900762685703,
        color: '#6071FF',
        percent_color: '#E2E6FF',
    },
    {
        label: 'Female',
        label_color: '#FF659C',
        value: 0.559030992373143,
        color: '#FF659C',
        percent_color: '#FFDCE2',
    },
]
