import { useEffect, useState } from 'react'

export const useGlobalWidth = () => {
    const [width, setWidth] = useState<number>(0)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1440) {
                setWidth(1180)
            } else if (window.innerWidth < 768) {
                setWidth(window.innerWidth - 20)
            } else {
                setWidth(Math.min(window.innerWidth - 280, 1180))
            }
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return width
}
