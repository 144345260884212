import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="12"
        height="16"
        viewBox="0 0 12 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.00008 15.4999C4.94753 14.6021 3.97191 13.6179 3.08342 12.5574C1.75008 10.9649 0.166749 8.59325 0.166749 6.33325C0.165567 3.97288 1.58695 1.84441 3.76764 0.941084C5.94833 0.0377568 8.45847 0.53762 10.1268 2.20742C11.2238 3.29959 11.8383 4.78524 11.8334 6.33325C11.8334 8.59325 10.2501 10.9649 8.91675 12.5574C8.02826 13.6179 7.05264 14.6021 6.00008 15.4999ZM6.00008 3.83325C5.10692 3.83325 4.2816 4.30975 3.83502 5.08325C3.38844 5.85676 3.38844 6.80975 3.83502 7.58325C4.2816 8.35676 5.10692 8.83325 6.00008 8.83325C7.3808 8.83325 8.50008 7.71397 8.50008 6.33325C8.50008 4.95254 7.3808 3.83325 6.00008 3.83325Z"
            fill="#8F8F8F"
        />
    </svg>
)
