import React, { FC } from 'react'
import { PopupModal } from 'react-calendly'

interface ICalendlyWidget {
    isOpen: boolean
    close: VoidFunction
}

const CalendlyWidget: FC<ICalendlyWidget> = ({ isOpen, close }) => {
    const windowRef = document.getElementById('root')

    if (!windowRef) return null

    return (
        <PopupModal
            url="https://calendly.com/yoloco-demo/30min?hide_gdpr_banner=1"
            onModalClose={close}
            open={isOpen}
            rootElement={windowRef!}
        />
    )
}
export default CalendlyWidget
