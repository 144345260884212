import React, { FC, memo, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import cn from './SearchItems.module.scss'
import { ListItem, Segment } from '@/Layouts/ListLayout'
import { SearchItem as SI } from '@/Reponses'
import Text from '@/components/Text'
import Avatar from '@/components/Avatar'
import BloggerLink from '@/components/Share/BloggerLink'
import useReportBought from '@/components/Share/useReportBought'
import { shortNum } from '@/_helpers/_graphs/_tech'
import InstaLink from '@/components/Share/InstaLink'
import Button from '@/components/Button'
import { useSpaceTranslation } from '@/_helpers'
import Locker from '@/components/Locker'
import { MassAddCheckBoxNEW } from '@/components/MassAddToList'
import AddToList from '@/pages/Lists/components/AddToList/AddToList'
import Flex from '@/_yc/Flex'
import { getShortString } from '@/_helpers/strings/getShortString'
import Icons from '@/components/Icons'
import { trackOpenReport } from '@/_helpers/amplitude/track'
import Topics from '@/pages/SearchPage/Bloggers/Topics/Topics'
import { Menu } from '@/pages/SearchPage/Bloggers/MobileMenu/Menu/Menu'
import { Platform } from '@/pages/Reports/types'
import { Like } from '@/components/Like/Like'
import { Comment, NewComment } from '@/components/Comment/Comment'
import useOpenClose from '@/_helpers/useOpenClose'
import { AddCommentToBloggerReq, CreateCommentToBloggerReq } from '@/pages/Lists/model/service'
import useToken from '@/_helpers/Hooks/useToken'

export interface ISearchItem extends SI {
    index: number
    margin?: string | number
    likedId?: number
    comment?: { comment: string; comment_id: number }
}

const overflow = {
    overflow: 'hidden',
}

const bdrs = {
    borderRadius: 4,
}

const SearchItem: FC<ISearchItem> = (props) => {
    const [wasUnlocked, setWasUnlocked] = useState(false)

    const locked = !props.unlocked && !wasUnlocked && !props.promo

    const unlock = useCallback(() => setWasUnlocked(true), [])

    useReportBought(props.id, unlock)

    const t = useSpaceTranslation('Influencer_explorer')
    const currentTariff = useSelector((store: RootStore) => {
        return store.authentication.user?.profile?.package ?? ''
    })

    // comment
    const token = useToken()
    const [isOpen, open, close] = useOpenClose()
    const [comment, setComment] = useState<string | undefined>(props.comment?.comment)
    const addComment = (valueComment: string) => {
        if (!token) return
        if (props.comment?.comment_id)
            AddCommentToBloggerReq(token, valueComment, props.id.toString(), props.comment.comment_id).then(() => {
                setComment(valueComment)
            })
        else
            CreateCommentToBloggerReq(token, valueComment, props.id.toString()).then(() => {
                setComment(valueComment)
            })
        close()
    }

    const handleOpen = (e: any) => {
        e.preventDefault()
        open()
    }

    return (
        <div>
            <div className={cn.desktopWrap}>
                <ListItem margin={props.margin} className={cn.item}>
                    <Segment content="center" margin="0 0 0 20px">
                        <MassAddCheckBoxNEW
                            data={{
                                hasEmail: props.has_email,
                                avatar: props.avatar,
                                name: props.fullName || '',
                                username: props.username,
                            }}
                            id={props.id.toString()}
                            externalId={props.id.toString()}
                            type={Platform.Instagram}
                            key={props.id}
                            index={props.index}
                        />
                    </Segment>
                    <Segment content="center" margin="0 0 0 -20px">
                        <Text color="gray-3">{props.index}</Text>
                    </Segment>
                    <Segment margin="0 0 0 -20px">
                        <Avatar src={props.avatar} />
                    </Segment>
                    {/* username */}
                    <Segment margin="0 0 0 -20px" column style={overflow}>
                        <Flex align="center">
                            {props.fullName && (
                                <Text
                                    className={cn['text-overflow']}
                                    component="h6"
                                    fSize={16}
                                    semibold
                                    margin="0 5px 0 0"
                                >
                                    {getShortString(props.fullName, props.has_email ? 15 : 18)}
                                </Text>
                            )}
                            {props.has_email && <Icons name="mail" style={{ minWidth: '12px' }} />}
                            {(!comment || comment.length === 0) && (
                                <Flex className={cn['new-comment']}>
                                    <NewComment handleOpen={handleOpen} />
                                </Flex>
                            )}
                        </Flex>
                        <Text
                            className={cn['text-overflow']}
                            color="violet-1"
                            component={BloggerLink}
                            isDemo={locked}
                            semibold={false}
                            id={props.id}
                            fSize={14}
                            newTab
                            isPromo={props.promo}
                        >
                            {props.username}
                        </Text>
                        <div className={cn.topicsTablet}>
                            <Topics topics={props.topics} size={12} fSize={10} maxCharacterLength={10} />
                        </div>
                    </Segment>
                    <Text component={Segment} content="center" color="gray-3" fSize={16}>
                        {shortNum(props.followers)}
                    </Text>
                    <Text component={Segment} content="center" color="gray-3" fSize={16}>
                        {(props.er * 100).toFixed(2)} %
                    </Text>
                    <div className={cn.topicsDesktop}>
                        <Topics topics={props.topics} size={12} fSize={10} />
                    </div>
                    <Segment content="flex-end">
                        {locked && currentTariff !== '' ? (
                            <Locker
                                style={{
                                    width: 120,
                                    marginRight: 10,
                                    flexGrow: 0,
                                    padding: 0,
                                }}
                                platform={Platform.Instagram}
                                isPromo={props.promo}
                                id={props.id}
                                label={t('found_blogger.unlock')}
                            />
                        ) : (
                            <BloggerLink newTab id={props.id} platform={Platform.Instagram} isPromo={props.promo}>
                                <Button
                                    width={120}
                                    size="small"
                                    style={{ padding: 0 }}
                                    variant={locked ? 'outline' : 'primary'}
                                    margin="0 10px 0 0px"
                                    onClick={() => trackOpenReport('infl_exp_ig')}
                                >
                                    {locked
                                        ? t('found_blogger.unlock')
                                        : props.promo
                                        ? t('found_blogger.analyze_button_promo')
                                        : t('found_blogger.analyze_button_label')}
                                </Button>
                            </BloggerLink>
                        )}
                        <InstaLink username={props.username} style={bdrs} />
                        <Flex margin="0 0 0 10px">
                            <AddToList
                                type={Platform.Instagram}
                                external_id={props.id.toString()}
                                place="infl_exp_ig"
                                send_ig_bloggers_on_full_parsing_if_needed={false}
                            />
                        </Flex>
                        <Flex margin="0 0 0 10px">
                            <Like
                                externalId={props.id.toString()}
                                likeId={props.likedId}
                                platform={Platform.Instagram}
                            />
                        </Flex>
                    </Segment>
                    <Comment
                        isOpen={isOpen}
                        open={open}
                        close={close}
                        value={comment}
                        setValue={setComment}
                        submit={addComment}
                        style={{ gridColumn: '4/9', margin: '10px 0 0 -20px' }}
                    />
                </ListItem>
            </div>
            <div className={cn.mobileWrap}>
                <div className={cn['mobileWrap--user']}>
                    <Flex>
                        <Segment align="flex-start">
                            <Avatar size={70} style={{ borderRadius: 10 }} src={props.avatar} />
                        </Segment>
                        <div className={cn['mobileWrap--user-info']}>
                            <Segment column style={overflow}>
                                <Flex align="center">
                                    {props.fullName && (
                                        <Text
                                            className={cn['text-overflow']}
                                            component="h6"
                                            fSize={14}
                                            semibold
                                            margin="0 5px 0 0"
                                        >
                                            {getShortString(props.fullName, 18)}
                                        </Text>
                                    )}
                                    {props.has_email && <Icons name="mail"></Icons>}
                                </Flex>
                                <Text
                                    className={cn['text-overflow']}
                                    color="violet-1"
                                    component={BloggerLink}
                                    isDemo={locked}
                                    semibold={false}
                                    id={props.id}
                                    fSize={12}
                                    newTab
                                    isPromo={props.promo}
                                >
                                    {props.username}
                                </Text>
                            </Segment>
                            <Segment component="ul" className={cn.topics} margin="2px 0 0 0">
                                <Topics topics={props.topics} size={12} fSize={10} />
                            </Segment>
                        </div>
                    </Flex>
                    <Menu type={Platform.Instagram} externalId={props.id.toString()} place="infl_exp_ig" />
                </div>
                <div className={cn['mobileWrap--stats']}>
                    <Text
                        component={Segment}
                        // content="center"
                        color="gray-new"
                        fSize={14}
                        column
                        style={{ flexBasis: 100, flex: 1, fontWeight: 600 }}
                    >
                        {shortNum(props.followers)}
                        <span className={cn['mobileWrap--stats-keyWord']}>{t('grid_header.followers')}</span>
                    </Text>

                    <Text
                        component={Segment}
                        color="gray-new"
                        fSize={14}
                        column
                        style={{ flexBasis: 100, flex: 1, fontWeight: 600 }}
                    >
                        {(props.er * 100).toFixed(2)} %
                        <span className={cn['mobileWrap--stats-keyWord']}>{t('grid_header.engagement')}</span>
                    </Text>

                    {locked && currentTariff !== '' ? (
                        <Locker
                            style={{ marginRight: 10, width: 120, flexGrow: 0, padding: 0 }}
                            isPromo={props.promo}
                            platform={Platform.Instagram}
                            id={props.id}
                            label={t('found_blogger.unlock')}
                        />
                    ) : (
                        <BloggerLink newTab id={props.id} platform={Platform.Instagram} isPromo={props.promo}>
                            <Button
                                width={120}
                                size="small"
                                variant={locked ? 'outline' : 'primary'}
                                margin="0 10px 0 10px"
                                onClick={() => trackOpenReport('infl_exp_ig')}
                            >
                                {locked
                                    ? t('found_blogger.unlock')
                                    : props.promo
                                    ? t('found_blogger.analyze_button_promo')
                                    : t('found_blogger.analyze_button_label')}
                            </Button>
                        </BloggerLink>
                    )}

                    <InstaLink username={props.username} style={bdrs} />

                    {/* <Flex margin="0 0 0 10px">
                        <AddToList type="instagram" external_id={props.id.toString()} place="infl_exp_ig" />
                    </Flex> */}
                </div>
            </div>
        </div>
    )
}

export default memo(SearchItem)
