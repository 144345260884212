import React from 'react'
import { Switch, Route, Router, Redirect } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import pt from 'prop-types'
import * as amplitude from '@amplitude/analytics-browser'

import { history } from '@/_helpers'
import { alertActions } from '../_redux/_actions'
import { PrivateRoute } from '../components/PrivateRoute/PrivateRoute'
import { LoginPage, loginActions } from '@/pages/Auth/LoginPage'
// import NavBar from "./AppBar";
// import BottomBar from './BottomBar'
import Notifier from '@/components/Notifier'
import SearchBloggers from '@/pages/SearchPage/Bloggers'
import { BloggerReport } from '@/pages/Reports/BloggerReport'
import SignUpPage from '@/pages/Auth/SignUpPage'
import { ForgotPage } from '@/pages/Auth/ForgotPage'
import { VerifyEmail } from '@/pages/Auth/VerifyEmailPage'
import { ResetPassword } from '@/pages/Auth/ResetPassword'
import { EmptyPage } from '@/pages/EmptyPage'
import { Settings } from '@/pages/SettingsPage/Settings'
import BillingPage from '@/pages/BillingPage'
import TariffPage from '@/pages/TariffPage'
import { Payment } from '@/pages/Payment/Payment'

import AboutPage from '@/pages/AboutPage'
import Intersections from '@/pages/Intersections'
import Commercial from '@/components/Commercial'
import OAuth from '@/OAuth/'
import DemoReport from '@/pages/Reports/DemoReport'
import RequireDemoPage from '@/pages/RequireDemoPage'
import Lists from '@/pages/Lists'
import ListOfBloggers from '@/pages/Lists/ListOfBloggers'
import ExpiredSubs from '@/components/ExpiredSubs'
import * as Routs from '../routs.js'
import OpenReportPage from '@/pages/Reports/OpenReportPage'
import ChannelReport from '@/pages/Reports/ChannelReport/ChannelReport'
import { ApolloProvider } from '@apollo/client'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import Posts from '@/pages/SearchPage/AdsExplorer/AdsExplorer'
import ReferralPage from '@/pages/ReferralPage'
// import { DetailedInfo } from '@/pages/Auth/DetailedInfo/DetailedInfo'
import CampaignManager from '@/pages/CampaignManager/CampaignManager'
import Campaign from '@/pages/CampaignManager/Campaign/Campaign'
import { Templates } from '@/pages/Outreach/Templates/Templates'
import { Messenger } from '@/pages/Outreach/Messenger/Messenger'
import { OutreachListener } from '@/pages/Outreach/Service/OutreachListener'
import FeedbackPage from '@/pages/FeedbackPage'
import { Stats } from '@/pages/Outreach/Stats/Stats'
import { TwitchReport } from '@/pages/Reports/TwitchReport/TwitchReport'
import MediaPlanning from '@/pages/MediaPlanning/MediaPlanning'
import { OauthGmail } from '@/pages/Redirects/OauthGmail/OauthGmail'
import { MediaPlan } from '@/pages/MediaPlanning/MediaPlan/MediaPlan'
import { VkReport } from '@/pages/Reports/VkReport/VkReport'
import { TikTokReport } from '@/pages/Reports/TikTokReport/TikTokReport'

import { TgReport } from '@/pages/Reports/TgReport/TgReport'

import SubscriptionConfirmation from '@/pages/SubscriptionConfirmation'
import TargetAudience from '@/pages/TargetAudience'
const THEME = createMuiTheme({})

const graphqlFetcher = new Fetcher()

class App extends React.Component {
    static get propTypes() {
        return {
            dispatch: pt.func.isRequired,
            authentication: pt.shape({
                loggedIn: pt.bool,
            }).isRequired,
        }
    }
    componentDidMount() {
        console.log('componentDidMount')
        const { dispatch, authentication } = this.props
        if (
            !authentication.loggedIn &&
            history.location.pathname !== Routs.LOGIN &&
            history.location.pathname !== Routs.SIGNUP &&
            history.location.pathname !== Routs.FORGOT &&
            history.location.pathname !== Routs.VERIFY &&
            history.location.pathname !== Routs.RESET &&
            history.location.pathname !== Routs.RESET + '/' &&
            history.location.pathname !== Routs.OAUTH &&
            history.location.pathname !== Routs.REQUIRE_DEMO
        ) {
            history.push(Routs.LOGIN, {})
        }

        history.listen((location, action) => {
            window.scrollTo(0, 0)
            dispatch(alertActions.clear())
        })

        if (this.props.authentication.loggedIn) {
            console.log('in APP')
            this.props.dispatch(loginActions.getUserInfo())
            this.props.dispatch(loginActions.getUserDetails())
            this.props.dispatch(loginActions.getPlatforms())
        }
        amplitude.init('a3616d24e3cdcd491a4895a189ea52a4')
    }
    componentDidUpdate(prevProps) {
        if (prevProps.authentication.loggedIn !== this.props.authentication.loggedIn) {
            if (this.props.authentication.loggedIn) {
                console.log('in APP')
                this.props.dispatch(loginActions.getUserInfo())
                this.props.dispatch(loginActions.getUserDetails())
                this.props.dispatch(loginActions.getPlatforms())
            }
        }
    }
    render() {
        return (
            <MuiThemeProvider theme={THEME}>
                {this.props.authentication.loggedIn && <Commercial />}
                <ApolloProvider client={graphqlFetcher.getClient()}>
                    <Router history={history}>
                        <ExpiredSubs />
                        <Notifier />
                        <OutreachListener />
                        <Switch>
                            <PrivateRoute path={Routs.SEARCH} component={SearchBloggers} />
                            <Route exact path="/">
                                <Redirect to={Routs.SEARCH} />
                            </Route>
                            <Route path={Routs.LISTS} component={Lists} exact />
                            <Route path={`${Routs.LISTS}/:id`} component={ListOfBloggers} />

                            <Route path={Routs.OAUTH_GMAIL_REDIRECT} component={OauthGmail} />
                            <Route path={Routs.ADS_EXPLORER} component={Posts} />
                            <Route path={Routs.LOGIN} component={LoginPage} />
                            <Route path={Routs.CAMPAIGN_MANAGER} component={CampaignManager} exact />
                            <Route path={Routs.MEDIA_PLANNING} component={MediaPlanning} exact />
                            <Route path={`${Routs.MEDIA_PLANNING}/:id`} component={MediaPlan} exact />
                            <Route path={`${Routs.OUTREACH}/templates`} component={Templates} exact />
                            <Route path={`${Routs.OUTREACH}/messenger/`} component={Messenger} exact />
                            <Route path={`${Routs.OUTREACH}`} component={Stats} exact />
                            <Route path={`${Routs.CAMPAIGN_MANAGER}/:id`} component={Campaign} />
                            <Route path={`${Routs.REPORT}/Instagram/:id`} component={BloggerReport} />
                            <Route path={`${Routs.REPORT}/YouTube/:id`} component={ChannelReport} />
                            <Route path={Routs.AFFILIATE} component={ReferralPage} />
                            <Route path={Routs.SIGNUP} component={SignUpPage} />
                            {/* <Route path={Routs.USER_DETAILS} component={DetailedInfo} /> */}
                            <Route path={Routs.FORGOT} component={ForgotPage} />
                            <Route path={Routs.VERIFY} component={VerifyEmail} />
                            <Route path={Routs.RESET} component={ResetPassword} />
                            <Route path={Routs.SETTINGS} component={Settings} />
                            <Route path={Routs.BILLING} exact component={BillingPage} />
                            <Route exact path="/">
                                <Redirect to={Routs.SEARCH} />
                            </Route>
                            <Route path={Routs.LOGIN} component={LoginPage} />
                            <Route path={`${Routs.REPORT}/instagram/:id`} component={BloggerReport} />
                            <Route path={`${Routs.REPORT}/youtube/:id`} component={ChannelReport} />
                            <Route path={`${Routs.REPORT}/twitch/:id`} component={TwitchReport} />
                            <Route path={`${Routs.REPORT}/vk/:id`} component={VkReport} />
                            <Route path={`${Routs.REPORT}/tiktok/:id`} component={TikTokReport} />
                            <Route path={`${Routs.REPORT}/telegram/:id`} component={TgReport} />

                            <Route path={Routs.SIGNUP} component={SignUpPage} />
                            <Route path={Routs.FORGOT} component={ForgotPage} />
                            <Route path={Routs.VERIFY} component={VerifyEmail} />
                            <Route path={Routs.RESET} component={ResetPassword} />
                            <Route path={Routs.PAYMENT} component={Payment} />
                            <Route path={Routs.BILLING} exact component={BillingPage} />
                            <Route path={Routs.TARIFFS} exact component={TariffPage} />
                            <Route path={Routs.ABOUT} exact component={AboutPage} />
                            <Route path={`${Routs.DEMO_REPORT}/:id/`} component={DemoReport} />
                            <Route path={Routs.OAUTH} component={OAuth} />

                            <Route
                                path={`${Routs.OPEN_REPORT}/:platform/:external_id/:type`}
                                exact
                                component={OpenReportPage}
                            />
                            <Route path={Routs.AFFILIATE} exact component={ReferralPage} />
                            <Route path={Routs.REQUIRE_DEMO} exact component={RequireDemoPage} />
                            <Route exact path={Routs.INTERSECTIONS}>
                                <Redirect to={Routs.INTERSECTIONS_DEFAULT} />
                            </Route>
                            <Route path={`${Routs.INTERSECTIONS}/:intersection/`} component={Intersections} />

                            <Route
                                path={`${Routs.OPEN_REPORT}/:platform/:external_id/:type`}
                                exact
                                component={OpenReportPage}
                            />
                            <Route
                                path={`${Routs.OPEN_REPORT}/:platform/:account_type/:external_id/:type`}
                                exact
                                component={OpenReportPage}
                            />

                            <Route path={Routs.REQUIRE_DEMO} exact component={RequireDemoPage} />
                            <Route path={Routs.AFFILIATE} component={ReferralPage} />
                            <Route path={Routs.FEEDBACK} component={FeedbackPage} />
                            <Route path={Routs.TARGET_AUDIENCE} component={TargetAudience} />
                            <Route path={Routs.SUBSCRIPTION_CONFIRMATION} component={SubscriptionConfirmation} />
                            <Route component={EmptyPage} />
                        </Switch>
                        {/* <BottomBar /> */}
                    </Router>
                </ApolloProvider>
            </MuiThemeProvider>
        )
    }
}

export { App }
