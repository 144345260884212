import React, { FC, useState, useEffect } from 'react'

import cn from './PaymentList.module.scss'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { Text, Flex, CommonError } from '@/_yc'
import { NativePayment } from '@/Reponses'
import ParsedDate from '@/components/Share/ParsedDate'
import Button from '@/components/Button'

import empty_icon from './EmptyFace.png'
import Scrollbars from 'react-custom-scrollbars'
import Loader from '@/components/Loader'
import Check from './Check'
import RedCross from '@/components/Icons/UI/RedCross'
import { getCurrency } from '@/_helpers/getCurrency'
import { getList } from '@/Services/Billing'
import useToken from '@/_helpers/Hooks/useToken'

export interface IPaymentList {}

const image = {
    width: 100,
    height: 100,
}
export interface PaymentResponse {
    next: string
    results: NativePayment[]
}

const PaymentList: FC<IPaymentList> = (props) => {
    const t = useSpaceTranslation('billing.list')
    const token = useToken()

    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<boolean>(false)
    const [data, setData] = useState<NativePayment[]>([])
    const [page, setPage] = useState<number>(1)
    const [isActive, setIsActive] = useState<boolean>(false)

    useEffect(() => {
        if (!token) return
        setLoading(true)
        setError(false)

        getList(token, page)
            .then((res) => {
                setLoading(false)

                setIsActive(res.next !== null)
                setData(data.concat(res.results))
            })
            .catch(() => {
                setLoading(false)
                setError(true)
            })
        // eslint-disable-next-line
    }, [token, page, setData])

    const loadMore = () => {
        if (!isActive) return
        setPage(page + 1)
    }

    return (
        <div className={cn.root}>
            <Flex align="center">
                <h2 className={cn.title}>{t('title')}</h2>
            </Flex>
            {!error &&
                (loading || (data.length && data.length > 0) ? (
                    <>
                        <div className={cn.hat}>
                            <Flex align={'center'} className={cn.name}>
                                <Text size={12} margin="0">
                                    <span className={cn['bold']}>{t('hat.name')}</span>
                                </Text>
                            </Flex>
                            <Flex content="center" align="center" className={cn.length}>
                                <Text size={12} margin="0">
                                    <span className={cn['bold']}>{t('hat.duration')}</span>
                                </Text>
                            </Flex>
                            <Flex content="center" align="center" className={cn.date}>
                                <Text color="secondary" margin="0">
                                    <span className={cn['bold']}>{t('hat.from')}</span>
                                </Text>
                            </Flex>
                            <Flex content="flex-end" align="center" className={cn.price}>
                                <Text size={12} margin="0">
                                    <span className={cn['bold']}>{t('hat.price')}</span>
                                </Text>
                            </Flex>
                        </div>
                        <Scrollbars className={cn.scroll} autoHeight autoHeightMax={400}>
                            <div className={cn.items}>
                                {data &&
                                    data?.map((item, i) => {
                                        if (!item.subscription.tariff || !item.subscription.valid_from) return null
                                        if (item.is_paid || item.subscription.recurring_payment_enabled)
                                            return (
                                                <div
                                                    key={i}
                                                    className={i === data.length - 1 ? cn.item : cn['item--line']}
                                                >
                                                    <Flex align="center" className={cn.name}>
                                                        <span className={cn['name--text']}>
                                                            {item.subscription.tariff.package.name}
                                                        </span>
                                                    </Flex>
                                                    <Flex content="center" align="center" className={cn.length}>
                                                        <span className={cn['name--text']}>
                                                            {item.subscription.tariff?.valid_days}
                                                        </span>
                                                    </Flex>
                                                    <Flex content="center" align="center" className={cn.date}>
                                                        <ParsedDate
                                                            monthAsNumber
                                                            dateString={item.subscription.valid_from}
                                                        >
                                                            {({ date, month, year }) => (
                                                                <span className={cn['name--text']}>
                                                                    {date}.{month}.{year}
                                                                </span>
                                                            )}
                                                        </ParsedDate>
                                                    </Flex>
                                                    <Flex content="flex-end" align="center" className={cn.price}>
                                                        <span className={cn['name--text']}>
                                                            {item.subscription.tariff?.price}{' '}
                                                            {getCurrency(item.subscription.tariff.currency)}
                                                        </span>
                                                    </Flex>
                                                    <Flex content="flex-end" align="center" className={cn.price}>
                                                        {item.is_paid ? <Check /> : <RedCross />}
                                                    </Flex>
                                                </div>
                                            )
                                        return null
                                    })}
                            </div>
                            {loading && (
                                <Flex margin="20px 0" content="center" align="center" className={cn.loading}>
                                    <Loader />
                                </Flex>
                            )}
                        </Scrollbars>

                        {!loading && (
                            <Flex content="center" margin="20px 20px 0 ">
                                <Button
                                    variant="outline"
                                    disabled={!isActive}
                                    onClick={loadMore}
                                    style={{ width: '100%' }}
                                >
                                    {t('load')}
                                </Button>
                            </Flex>
                        )}
                    </>
                ) : (
                    <Flex column content="center" align="center" className={cn.empty}>
                        <img style={image} src={empty_icon} alt={'empty_icon'} />
                        <Text size={36} margin="30px 0 12px">
                            {t('empty.title')}
                        </Text>
                        <Text size={18} margin="0" color="secondary">
                            {t('empty.subTitle')}
                        </Text>
                    </Flex>
                ))}
            {error && !loading && (
                <Flex content={'center'} className={cn.error}>
                    <CommonError />
                </Flex>
            )}
        </div>
    )
}

export default PaymentList
