import React, { FC, memo } from 'react'
import { AdapterProvider, useSearchBy } from '../SearchAdapter'
import SearchPanel from './SearchPanel'
import SearchItems from './SearchItems'
import PageLayout from '@/Layouts/PageLayout'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { findAdapterType } from '../utils/findAdapterType'
import { useEffect } from 'react'
import { ISearchBy } from '../SearchAdapter/bloggers/youtube/types'
import { useDispatch } from 'react-redux'
import { loginActions } from '@/pages/Auth/LoginPage'
import useToken from '@/_helpers/Hooks/useToken'
import FetchCustomLists from '@/pages/Lists/CatalogOfLists/FetchCustomLists'
import { Platform } from '@/pages/Reports/types'
export interface ISearchPage {}

const SearchPage: FC<ISearchPage> = () => {
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const adapter = findAdapterType(pathname)
    const token = useToken()

    FetchCustomLists()

    const { clear, add } = useSearchBy()
    useEffect(() => {
        //init config.by searchBy
        if (adapter === Platform.Instagram) {
            clear()
            const filters = ['info.biography', 'info.fullName', 'info.username']
            filters.forEach((i) => add(i))
        } else if (adapter === Platform.YouTube) {
            clear()
            const filters: ISearchBy[] = ['title', 'description']
            filters.forEach((i) => add(i))
        } else if (adapter === Platform.Twitch) {
            clear()
            const filters: ISearchBy[] = ['title', 'description']
            filters.forEach((i) => add(i))
        } else if (adapter === Platform.Vk) {
            clear()
            const filters: ISearchBy[] = ['title', 'description']
            filters.forEach((i) => add(i))
        } else if (adapter === Platform.Tg) {
            clear()
            const filters: ISearchBy[] = ['title', 'description']
            filters.forEach((i) => add(i))
        }
    }, [adapter, add, clear])

    useEffect(() => {
        if (!token) return
        dispatch(loginActions.getUserDetails())
    }, [token, dispatch])

    return (
        <AdapterProvider>
            <PageLayout color="common-background" title={t('titles.t1')}>
                <SearchPanel />
                <SearchItems platfrom={adapter as Platform} />
            </PageLayout>
        </AdapterProvider>
    )
}

export default memo(SearchPage)
