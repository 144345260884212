import Channel, { Payload, Message } from './Channel'
import Observable from '@/_helpers/Observable'

export default class SubChannel<PayloadType> {
    private subs = new Observable<{ to?: string; payload: PayloadType }>()

    /**
     *
     * @param chanel Channel instance for connecton
     * @param type message type for observation
     * @param encode Function for transpiling from StringMap to PayloadType
     * @param decode Function for transpiling from PayloadType to StringMap
     */

    constructor(
        private chanel: Channel,
        private type: string,
        private encode: (m: PayloadType) => Payload,
        private decode: (p: Payload | undefined) => PayloadType | null
    ) {
        chanel.subscribe(this.handler)
    }

    private handler = (message: Message) => {
        if (message.type !== this.type) return
        const parsedMessage = this.decode(message.payload)

        if (parsedMessage !== null) {
            this.subs.update({
                to: message.to,
                payload: parsedMessage,
            })
        }
    }
    /**
     *
     * Post message in SubChanel
     *
     * @param message message to post
     * @param to optional aditional address
     */
    post(message: PayloadType, to?: string) {
        this.chanel.postMessage(this.type, this.encode(message), to)
    }

    subscribe = this.subs.subscribe.bind(this.subs)
}
