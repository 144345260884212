import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.0631 12.7075L21.5932 10.1368C22.2798 9.6368 21.9238 8.54789 21.0742 8.54789H16.7148L15.3669 4.3898C15.1049 3.58191 13.9539 3.58027 13.6914 4.3898L12.3435 8.54789H7.98408C7.1341 8.54789 6.77885 9.63703 7.46511 10.1368L10.9951 12.7075L9.64628 16.8684C9.38425 17.6768 10.3158 18.3495 11.003 17.849L14.5291 15.2812L18.0552 17.849C18.7383 18.3464 19.6745 17.6787 19.4118 16.8684L18.0631 12.7075ZM16.1914 12.6432L16.9012 14.8326L15.0482 13.4832C14.7389 13.258 14.3195 13.2581 14.0103 13.4832L12.1573 14.8326L12.867 12.6432C12.9841 12.2819 12.8556 11.8864 12.5482 11.6626L10.685 10.3057H12.9842C13.366 10.3057 13.7044 10.06 13.8219 9.69738L14.5292 7.51536L15.2366 9.69738C15.3541 10.06 15.6925 10.3057 16.0743 10.3057H18.3734L16.5102 11.6626C16.2029 11.8864 16.0743 12.2819 16.1914 12.6432Z"
            fill="#6071FF"
        />
        <path
            d="M28.7967 27.7102L23.7586 18.8727C25.277 16.8936 26.1813 14.4181 26.1813 11.7345C26.1812 5.26405 20.9279 0 14.4706 0C8.0133 0 2.75995 5.26405 2.75995 11.7345C2.75995 14.4379 3.67749 16.9303 5.21631 18.9166L0.203101 27.7104C-0.378146 28.7306 0.357129 29.9999 1.53377 29.9999H9.02095C9.82003 29.9999 10.5622 29.5686 10.9579 28.8744L14.0444 23.4604C14.3656 23.4719 14.6067 23.4724 14.9546 23.4581L18.042 28.8744C18.4377 29.5687 19.1799 30 19.979 30H27.4662C28.6415 29.9999 29.3788 28.7319 28.7967 27.7102ZM9.42721 28.0051C9.34391 28.1513 9.18824 28.2421 9.02089 28.2421H1.92612L6.46134 20.2868C8.01847 21.7519 9.974 22.7963 12.1459 23.2363L9.42721 28.0051ZM4.52095 11.7345C4.52095 6.23331 8.98432 1.75781 14.4706 1.75781C19.9569 1.75781 24.4202 6.23331 24.4202 11.7345C24.4202 17.2357 19.9569 21.7112 14.4706 21.7112C8.98432 21.7112 4.52095 17.2356 4.52095 11.7345ZM19.979 28.2421C19.8116 28.2421 19.656 28.1513 19.5726 28.0051L16.8482 23.2256C19.0167 22.7758 20.9675 21.7231 22.5182 20.2511L27.0738 28.2421H19.979Z"
            fill="#6071FF"
        />
    </svg>
)
