import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import ym from 'react-yandex-metrika';

import DownArrow from '@material-ui/icons/KeyboardArrowDown'
import avatar from '@/_yc/Icons/avatar.jpg'

import { Avatar, StandartPopper } from '@/_yc/'
import Counter from '../ReportCounter.js'
import { TARIFFS, BILLING, SETTINGS, LOGIN } from '@/routs.js'

import cn from './AuthMenu.module.scss'

const useGlob = () => {
	if (global.isProd) {
		global.gtag('event', 'тариф_сайдбар', { 'event_category': 'tariff', 'event_action':'send_tariff'}); 
		ym("reachGoal", 'tariff');
	}
}

const marginTop = {
	marginTop: 6,
	zIndex: 100
}

const avatarStyle = {
	width: 30,
	height: 30
}

const avatarLoadStyle = {
	width: 26,
	height: 26
}

const emailStyle = { 
	fontWeight: "bold", 
	fontStyle: 'normal', 
	fontSize: 12
}

const downArrow = {
	transition: "transform .3s"
}

const contentStyle = {
	marginBottom: 15,
	display: 'flex',
	alignItems: 'center'
}

class AuthMenu extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			open: false,
			disabled: false,
			options: [
				{ label: "top_menu.user_menu.pricing", to: TARIFFS, cb: useGlob },
				{ label: "top_menu.user_menu.billing", to: BILLING },
				{ label: "top_menu.user_menu.settings", to: SETTINGS }
			]
		}
		this.block = React.createRef();
	}
	handleClick(val){
		if (!val) {
			this.setState({ open: val }, e=>{
				setTimeout(e=>this.setState({ disabled: false }), 0)
			})
			return
		}
		this.setState({open: val, disabled: true})
	}
	render(){
		const { t } = this.props
		return(
			<StandartPopper 
				btn = {
					<div 
						ref={this.block} 
						className = {cn.btn}
						onClick={!this.state.disabled ? e=>this.handleClick(!this.state.open) : e=>e}
					>
						{this.props.auth.infoLoaded ? 
							this.props.xs ? 
							<Avatar 
								src = {avatar}
								style = {avatarStyle}
								loadingStyle = {avatarLoadStyle}
							/> 
							: 
							<React.Fragment>
								<span style = {emailStyle}>{this.props.auth.user.profile.email}</span>
								<Counter className = {cn["counter--btn"]} style = {{}}/>
								<DownArrow 
									style = {downArrow}
									className = {this.state.open ? cn["arrow--up"] : cn["arrow--down"]}
								/>	
							</React.Fragment>
						: null}	
					</div>
				}
				content = {
					<div 
              			className = {cn.popup}
              			style = {{ background: this.props.color }}
              		>
              			<div className = {cn.link} style = {contentStyle} >
              				{t("top_menu.user_menu.reports")} <Counter className={cn["counter--pop"]} style={{}} />
              			</div>
              			{this.state.options.map((item, i) => (
              				<Link 
              					to = {item.to}
              					key = {i}
              					style = {{
              						marginBottom: i === this.state.options.length-1 ? undefined : 20
              					}}
              					onClick = {item.disabled ? e=>e.preventDefault() : item.cb}
              					className = {item.disabled ? cn["link--disabled"] : cn.link}
              				>
	              				{t(item.label)}
	              			</Link>
              			))}
						<div className = {cn.divider} />
              			<Link 
              				to = {LOGIN}
              				className = {cn.link}
              			>
              				{t("top_menu.user_menu.logout")}
              			</Link>
              		</div>	
				}
				open = {this.state.open}
				anchor = {this.block.current}
				onClickAway = {this.handleClick.bind(this)}
				style = {marginTop}
			/>
		)
	}
}

AuthMenu = withTranslation()(connect(
	({ authentication }) =>({ auth: authentication })
)(AuthMenu))

export { AuthMenu }