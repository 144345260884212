import React, { FC, memo, useMemo } from 'react'
import { useInterpolate } from '../useInterpolate'
import interpolate from 'color-interpolate'
import cn from './Matrix.module.scss'
import Text from '@/components/Text'

export interface IOverlapPercent {
    percent: number
    isActive?: boolean
}

const colopMap = interpolate(['#ffffff', '#B8BFFF'])

const OverlapPercent: FC<IOverlapPercent> = (props) => {
    const background = useInterpolate(props.percent / 100, null, 0.4, colopMap)

    const style = useMemo(() => {
        return {
            background,
        }
    }, [background])

    return (
        <Text
            tAlign="center"
            style={style}
            className={props.isActive ? cn['percent--active'] : cn['percent--default']}
            color={props.isActive ? 'green' : 'violet-3'}
            fSize={props.isActive ? 16 : 14}
            semibold={props.isActive}
        >
            {props.percent.toFixed(0)}%
        </Text>
    )
}

export default memo(OverlapPercent)
