import React from 'react'

import Icons from '@/components/Icons'
import { SelectorFilter } from '../../../../filters/SelectorFilter'

export const getAudQuality = () => {
    return new SelectorFilter({
        platform: 'infl_vk',
        title: 'filters.aq.label',
        autocomplete: true,
        placeholder: 'filters.account_type.any',
        icon: <Icons name="filter--geo" />,
        helper: 'filters.aq.text',
        space: 1,
        id: 'aud_quality_vk',
        soon: true,
        options: async (token: string, locale, query) => {
            return []
        },
        beforeSend: ({ value }) => {
            return null
            // return {
            //     name: 'location.city',
            //     type: 'array',
            //     value: value,
            //     gqlFilter: getGqlFilter(value as string[]),
            // }
        },
    })
}
