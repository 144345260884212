import { createReducer } from '@reduxjs/toolkit'
import { setPackages } from './actions'
import { ITariffPage } from './types'

const defaultState: ITariffPage = {
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
}
const TariffPageNEW = createReducer<ITariffPage>(defaultState, (builder) => {
    builder.addCase(setPackages, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                data: payload,
            }
        }
    })
})
export default TariffPageNEW
