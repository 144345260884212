import React, { FC, useEffect, useRef } from 'react'
import { MessageData } from '../types'
import Text from '@/components/Text'

import cn from './Message.module.scss'
import moment from 'moment'
import useOnScreen from './useOnScreen'
import useToken from '@/_helpers/Hooks/useToken'
import Avatar from '@/components/Avatar'
import { readMessageReq } from '@/pages/Outreach/Service/Chats'

interface MessageProps extends MessageData {
    avatar: string
    lastMessageRef?: React.RefObject<HTMLDivElement>
    chatId: number
    username: string
}

export const Message: FC<MessageProps> = (props) => {
    const ref = useRef(null)
    const isVisible = useOnScreen(ref)

    const token = useToken()

    useEffect(() => {
        if (!token) return

        if (props.chatId === 0) return

        if (props.isRead || !isVisible) return

        readMessageReq(token, props.chatId, props.id)
    }, [isVisible, props.message, props.isRead, token, props.chatId, props.id])
    console.log(props)
    return (
        <div className={cn.message} ref={props.lastMessageRef || ref}>
            <div className={cn[`message--${props.sender}`]}>
                {props.sender === 'blogger' && (
                    <>
                        {props.username !== '' && (
                            <a
                                href={`https://www.instagram.com/${props.username}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Avatar
                                    alt="AVA"
                                    src={props.avatar}
                                    aria-label="Аватарка"
                                    className={cn[`avatar--blogger`]}
                                    loadingAvatarWidth={'30'}
                                />
                            </a>
                        )}
                        {props.username === '' && (
                            <Avatar
                                alt="AVA"
                                src={props.avatar}
                                aria-label="Аватарка"
                                className={cn[`avatar--blogger`]}
                                loadingAvatarWidth={'30'}
                            />
                        )}
                    </>
                )}
                {props.sender === 'user' && <div className={cn[`avatar--user`]}>{props.avatar}</div>}

                <div className={cn.messageContent}>
                    {props.image && <img className={cn.img} src={props.image} alt="msg-img" />}
                    <Text dangerouslySetInnerHTML={{ __html: props.message }}></Text>
                    <div className={cn[`message-info--${props.sender}`]}>
                        <Text fSize={12} color="gray-2">
                            {moment(props.time).format('HH:mm, DD.MM.YY')}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    )
}
