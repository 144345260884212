import React, { FC, memo, useEffect, useState } from 'react'
import { GlobalWidth } from '@/_yc'

import Text from '@/components/Text'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import ListLayout from '@/Layouts/ListLayout'
import { useAdapter, useSearch } from '../../SearchAdapter'
import Hidder from '../../Hidder'
import TiktokHidder from '../../Hidder/TiktokHidder'
import cn from './SearchItems.module.scss'
import BloggerList from '../BloggerList'
import { getNumberWithSpaces } from '@/_helpers/nums/numberWithSpaces'
import { Platform } from '@/pages/Reports/types'
import { useCount } from '@/pages/SearchPage/SearchAdapter/hooks/useCount'

export interface ISearchItems {
    platfrom: Platform
}

const paddings = { right: 30 }

const SearchItems: FC<ISearchItems> = (props) => {
    const t = useSpaceTranslation('Influencer_explorer')

    const Adapter = useAdapter()

    const { restart, next, selected, isHidden, checkCount } = useSearch((state) => state)

    const [totalCount, setTotalCount] = useState(0)
    useEffect(() => {
        if (!isHidden) return
        if (selected.total !== 0) return setTotalCount(selected.total)
        checkCount().then((res) => {
            setTotalCount(res.count)
        })
    }, [isHidden, selected.total, checkCount])

    useEffect(() => {
        restart()
    }, [restart])

    const state = useCount()

    return (
        <GlobalWidth>
            <Text semibold color="gray-new-2" fSize={12}>
                {!state.loading && state.count
                    ? t('found_label.result', { count: getNumberWithSpaces(state.count) })
                    : t('found_label.inProcess')}
            </Text>

            <ListLayout margin="8px 0 0" gridGap={20} sidePadding={paddings} templateColumns={Adapter.templateColumns}>
                <div className={cn['adapter--head']}>
                    <Adapter.Head className={cn['adapter--head']} margin="0 0 1px" />
                </div>
                <BloggerList onReach={next} className={cn.bloggerList} loading={selected.loading} />

                {isHidden && props.platfrom !== Platform.TikTok && (
                    <Hidder platfrom={props.platfrom} count={totalCount} />
                )}
                {isHidden && props.platfrom === Platform.TikTok && <TiktokHidder />}
            </ListLayout>
        </GlobalWidth>
    )
}

export default memo(SearchItems)
