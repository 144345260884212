import React, { SVGAttributes } from 'react'

export const HiddenLikes = (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.3133 12.2563L10.076 10.019C9.11072 10.4585 8.06051 10.6799 6.99998 10.6676C5.90657 10.6812 4.82446 10.4453 3.83598 9.97764C3.06977 9.60381 2.38174 9.0875 1.80865 8.45631C1.20031 7.80421 0.722178 7.04187 0.39998 6.21031L0.333313 6.00098L0.403313 5.79031C0.885186 4.56117 1.69615 3.48842 2.74731 2.68964L0.99998 0.94231L1.94198 0.000976562L13.2546 11.3136L12.3146 12.2563H12.3133ZM3.69065 3.63364C2.8386 4.23092 2.16575 5.04945 1.74465 6.00098C2.64759 8.08425 4.73056 9.40542 6.99998 9.33431C7.69987 9.34004 8.39569 9.22734 9.05798 9.00098L7.85798 7.80098C7.59087 7.93191 7.29745 8.00031 6.99998 8.00098C5.89829 7.99406 5.00689 7.10267 4.99998 6.00098C5.0003 5.70282 5.0687 5.40868 5.19998 5.14098L3.69065 3.63364ZM12.2346 8.40764L11.3066 7.48031C11.6971 7.03892 12.0171 6.53994 12.2553 6.00098C11.3536 3.9167 9.26981 2.59503 6.99998 2.66764C6.83531 2.66764 6.66998 2.67364 6.50998 2.68498L5.33331 1.50698C5.88107 1.3898 6.43984 1.33191 6.99998 1.33431C8.09339 1.32074 9.1755 1.55672 10.164 2.02431C10.9302 2.39813 11.6182 2.91444 12.1913 3.54564C12.7994 4.19695 13.2775 4.95836 13.6 5.78898L13.6666 6.00098L13.5966 6.21164C13.2845 7.0237 12.8227 7.76995 12.2353 8.41164L12.2346 8.40764Z"
                fill="#5D697D"
            />
        </svg>
    )
}
