import React, { FC } from 'react'
import Flex from '@/_yc/Flex'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

import { Switch } from './Switch'
import { ISwitch } from './Switch'

import cn from './YearlyDiscount.module.scss'

const YearlyDiscount: FC<ISwitch> = ({ value, setValue }) => {
    const t = useSpaceTranslation('tariffs_page.discount')

    return (
        <>
            <Flex className={cn.switch} align="center">
                <p className={cn[`switch--text${!value ? '-active' : ''}`]}>{t('monthly')}</p>

                <Switch value={value} setValue={setValue} />

                <p className={cn[`switch--text${value ? '-active' : ''}`]}>{t('yearly')}</p>

                <Flex className={cn['switch--discount']} align="center" margin="0 0 0 8px">
                    <p>{t('discount')}</p>
                </Flex>
            </Flex>
        </>
    )
}
export default YearlyDiscount
