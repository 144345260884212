import React, {
    useEffect,
    // useMemo,
    useRef,
} from 'react'
import PageLayout from '@/Layouts/PageLayout'
import { useTranslation } from 'react-i18next'

import { Flex, GlobalWidth } from '@/_yc'
import Header from '@/Layouts/ReportLayout/Header/Header'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Text from '@/components/Text'
import Arrow from '@/components/Share/BackButton/Arrow'
import PartChanger from '@/Layouts/ReportLayout/Extra/PartChanger'
import { RootStore } from '@/_helpers/StoreType'
import { useQuery } from '@apollo/client'
import useChannelId from '@/_helpers/useChannelId'
import { push } from '@/components/Notifier/PushNotification'
import LegacyNotification from '@/components/Notifier/MessageTypes/LegacyNotification'
import { setTgRawData } from '@/Layouts/ReportLayout/Model/actions'
import Segment from '@/Layouts/ReportLayout/Content/Segment'
// import { ContextMenu } from '@/_yc'
import { ReportHeader } from './ReportHeader/ReportHeader'
import { isNotEnoughReportsError } from '../OpenReportPage/utils'
import { GET_TG_REPORT } from './queries'
import { Platform } from '../types'
import { TgReportResponseData } from './types'
import { AccountInfo } from './components/AccountInfo'
import GrowGraph from './components/GrowGraph/GrowGraph'
import { StatsTable } from './components/StatsTable'
import Posts from './components/Posts'
import Mentions from './components/Mentions'

import cn from './TgReport.module.scss'

export const TgReport = () => {
    const { t } = useTranslation()
    const graphRef = useRef(null)
    const mentionRef = useRef(null)
    // const activityRef = useRef(null)
    const postsRef = useRef(null)

    const channelId = useChannelId(3)

    const history = useHistory()

    const dispatch = useDispatch()

    const TgDataStore = useSelector((store: RootStore) => {
        return store.report.rawTgData
    })

    const {
        loading: loadingTg,
        data: dataTg,
        error: errorTg,
    } = useQuery<TgReportResponseData>(GET_TG_REPORT, {
        variables: {
            id: channelId,
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        skip: TgDataStore?.getTelegraReport !== undefined,
    })

    useEffect(() => {
        if (loadingTg || errorTg || !dataTg || TgDataStore?.getTelegraReport) {
            return
        }
        dispatch(setTgRawData(dataTg))
    }, [TgDataStore, dataTg, errorTg, loadingTg, dispatch])

    useEffect(() => {
        const isNotEnoughReports = errorTg && isNotEnoughReportsError(errorTg)
        if (isNotEnoughReports) {
            push(new LegacyNotification(t('not_enough_reports'), 'error'))
            history.push('/billing')
        }
    }, [errorTg, t, history])

    return (
        <PageLayout color={'common-background'} className={cn.root} title={t('titles.t2')}>
            <GlobalWidth style={{ padding: '26px 50px' }}>
                <Header>
                    <Flex content="space-between" style={{ width: '100%' }}>
                        <Link to={`/search/${Platform.Tg}`}>
                            <Flex grow align="center" content="space-between">
                                <Arrow className={cn.arrow} />
                                <Text margin={'0 0 0 10px'} color="gray-3">
                                    {t('report_layout.go_back')}
                                </Text>
                            </Flex>
                        </Link>
                    </Flex>
                </Header>

                <div className={cn.main}>
                    <ReportHeader />

                    <PartChanger
                        values={[
                            {
                                title: t('report_titles.account_data.title'),
                                value: graphRef,
                            },
                            // {
                            //     title: t('report_titles.auditory.title'),
                            //     value: activityRef,
                            // },
                            {
                                title: t('report_titles.quot.title'),
                                value: mentionRef,
                            },
                            {
                                title: t('report_titles.pubs.title'),
                                value: postsRef,
                            },
                        ]}
                    />
                    <AccountInfo />
                </div>

                <Segment ref={graphRef} title={t('report_titles.account_data.title')}>
                    <GrowGraph />
                    <div style={{ marginTop: '20px' }}>
                        <StatsTable />
                    </div>
                </Segment>

                {/* <Segment className={cn.segment} ref={audienceRef} title={t('report_titles.auditory.title')}>
                    <Demographics />
                </Segment>*/}

                <Segment className={cn.segment} ref={mentionRef} title={t('report_titles.quoting.title')}>
                    <Mentions />
                </Segment>

                <Segment className={cn.segment} ref={postsRef} title={t('report_titles.publications.title')}>
                    <Posts />
                </Segment>
            </GlobalWidth>
        </PageLayout>
    )
}
