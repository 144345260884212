import Flex from '@/_yc/Flex'
import React, { FC, useRef, useState } from 'react'
import EmptyCover from './EmptyCover'
// import Loader from '@/components/Loader'
import Text from '@/components/Text'
import useOpenClose from '@/_helpers/useOpenClose'
import Popup from '@/_yc/Popup'

interface IPostCover {
    url: string
    [key: string]: any
    media_url?: string
    // isInst?: boolean
    // shortCode?: string
    width?: string
    height?: string
}

export const PostCover: FC<IPostCover> = ({
    url,
    style,
    media_url,
    width = '280px',
    height = '280px',
    // isInst, shortCode
}) => {
    const [coverError, setCoverError] = useState(false)
    const [isOpen, open, close] = useOpenClose()
    const icon = useRef<HTMLDivElement>(null)

    // if (isInst && shortCode) {
    //     return (
    //         <blockquote
    //             className="instagram-media"
    //             style={{
    //                 background: 'white',
    //                 // width: 'calc(100% - 2px)',
    //                 borderRadius: '4px',
    //                 border: 'none',
    //                 boxShadow: 'none',
    //                 display: 'block',
    //                 margin: '0px 0px 12px',
    //                 height: '270px',
    //                 overflowY: 'scroll',
    //             }}
    //             data-instgrm-captioned
    //             data-instgrm-permalink={`https://www.instagram.com/p/${shortCode}/?utm_source=ig_embed&amp;utm_campaign=loading`}
    //             data-instgrm-version="14"
    //         >
    //             <Flex content="center" align="center" style={{ width: '100%', height: '100%' }}>
    //                 <Loader />
    //             </Flex>
    //         </blockquote>
    //     )
    // }
    return (
        <>
            {!coverError && url ? (
                <img
                    src={url}
                    alt={'cover'}
                    style={style || { width: width, height: height, objectFit: 'cover' }}
                    onError={() => setCoverError(true)}
                />
            ) : (
                <Flex
                    ref={icon}
                    content={'center'}
                    align={'center'}
                    style={style || { width: width, height: height }}
                    onMouseOver={media_url ? open : undefined}
                    onMouseLeave={close}
                >
                    <EmptyCover style={style || { width: width, height: height }} alt="ava" />
                </Flex>
            )}
            <Popup
                isOpen={isOpen}
                onClose={close}
                // placement={'bottom'}
                anchor={icon.current}
                boundariesElement={'window'}
                style={{ zIndex: 15 }}
            >
                <div
                    style={{
                        // maxHeight: '100px',
                        maxWidth: '600px',
                        borderRadius: '4px',
                        padding: '10px 12px',
                        marginBottom: '14px',
                        backgroundColor: '#5D697D',
                    }}
                >
                    <Text fSize={10} color={'white'}>
                        {media_url}
                    </Text>
                </div>
            </Popup>
        </>
    )
}
