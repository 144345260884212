import React, { FC, memo, useState } from 'react'
import cn from '../instagram/SearchItems.module.scss'
import { ListItem, Segment } from '@/Layouts/ListLayout'
import Text from '@/components/Text'
import Avatar from '@/components/Avatar'
import { shortNum } from '@/_helpers/_graphs/_tech'
import Button from '@/components/Button'
import { useSpaceTranslation } from '@/_helpers'
import YTLink from '@/components/Share/YTLink/YTLink'
import BloggerLink from '@/components/Share/BloggerLink'
import { YTSearchItem } from './types'
import Locker from '@/components/Locker'
import { TARIFFS } from '@/routs'
import { MassAddCheckBoxNEW } from '@/components/MassAddToList'
import AddToList from '@/pages/Lists/components/AddToList/AddToList'
import Flex from '@/_yc/Flex'
import { trackOpenReport } from '@/_helpers/amplitude/track'
import Icons from '@/components/Icons'
import Topics from '@/pages/SearchPage/Bloggers/Topics/Topics'
import { getShortString } from '@/_helpers/strings/getShortString'
import { Menu } from '@/pages/SearchPage/Bloggers/MobileMenu/Menu/Menu'
import { Platform } from '@/pages/Reports/types'
import { Like } from '@/components/Like/Like'
import { Comment, NewComment } from '@/components/Comment/Comment'
import useOpenClose from '@/_helpers/useOpenClose'
import { AddCommentToBloggerReq, CreateCommentToBloggerReq } from '@/pages/Lists/model/service'
import useToken from '@/_helpers/Hooks/useToken'

export interface ISearchItem extends YTSearchItem {
    index: number
    margin?: string | number
    likedId?: number
    comment?: { comment: string; comment_id: number }
}

const overflow = {
    overflow: 'hidden',
}

const bdrs = {
    borderRadius: 4,
    marginLeft: 10,
}

const SearchItem: FC<ISearchItem> = (props) => {
    const locked = !props.unlocked && !props.promo
    const topicsGeneral =
        props.YTtopics && props.YTtopics.map((topic) => topic?.youtube_channeltopic?.youtube_channeltopicgeneral_topics)

    const topics = topicsGeneral && [
        ...new Set(topicsGeneral.map((topic) => topic[0]?.youtube_channeltopicgeneral?.name)),
    ]
    const nonEmptyTopics = topics.filter((t) => !!t)

    const t = useSpaceTranslation('Influencer_explorer')

    // comment
    const token = useToken()
    const [isOpen, open, close] = useOpenClose()
    const [comment, setComment] = useState<string | undefined>(props.comment?.comment)
    const addComment = (valueComment: string) => {
        if (!token) return
        if (props.comment?.comment_id)
            AddCommentToBloggerReq(token, valueComment, props.id.toString(), props.comment.comment_id).then(() => {
                setComment(valueComment)
            })
        else
            CreateCommentToBloggerReq(token, valueComment, props.id.toString()).then(() => {
                setComment(valueComment)
            })
        close()
    }

    const handleOpen = (e: any) => {
        e.preventDefault()
        open()
    }
    return (
        <div>
            <div className={cn.desktopWrap}>
                <ListItem margin={props.margin} className={cn.item}>
                    <Segment content="center" margin="0 0 0 20px">
                        <MassAddCheckBoxNEW
                            data={{
                                hasEmail: props.hasEmail,
                                name: props.fullName || '',
                                username: props.username,
                                avatar: props.avatar,
                            }}
                            id={props.id}
                            type={Platform.YouTube}
                            key={props.id}
                            index={props.index}
                        />
                    </Segment>
                    <Segment content="center" margin="0 0 0 -20px">
                        <Text color="gray-3">{props.index}</Text>
                    </Segment>
                    <Segment margin="0 0 0 -20px">
                        <Avatar src={props.avatar} />
                    </Segment>
                    <Segment margin="0 0 0 -20px" style={overflow}>
                        <div className={cn.nameAndTopics}>
                            <Flex content="flex-start" align="center">
                                {props.fullName && (
                                    <Text className={cn['text-overflow']} component="h6" fSize={14} semibold>
                                        {getShortString(props.fullName, 18)}
                                    </Text>
                                )}
                                {props.hasEmail && <Icons name="mail" margin="0 0 0 5px"></Icons>}

                                {(!comment || comment.length === 0) && (
                                    <Flex className={cn['new-comment']}>
                                        <NewComment handleOpen={handleOpen} />
                                    </Flex>
                                )}
                            </Flex>
                            <div className={cn.topicsTablet}>
                                {nonEmptyTopics && (
                                    <Topics topics={nonEmptyTopics} size={10} fSize={10} maxCharacterLength={13} />
                                )}
                            </div>
                        </div>
                    </Segment>

                    <Text component={Segment} content="center" color="gray-3" fSize={12}>
                        {shortNum(props.followers)}
                    </Text>
                    <Text component={Segment} content="center" color="gray-3" fSize={12}>
                        {(props.er * 100).toFixed(2)} %
                    </Text>

                    <div className={cn.topicsDesktop}>
                        {nonEmptyTopics && (
                            <Topics topics={nonEmptyTopics} size={10} fSize={10} maxCharacterLength={20} />
                        )}
                    </div>
                    <Segment content="flex-end">
                        {locked ? (
                            <Locker
                                style={{
                                    width: 120,
                                    // marginLeft: 10,
                                    flexGrow: 0,
                                    padding: 0,
                                }}
                                platform={Platform.YouTube}
                                isPromo={props.promo}
                                id={props.id}
                                label={t('found_blogger.unlock')}
                            />
                        ) : (
                            <BloggerLink
                                newTab
                                id={props.id}
                                failLink={`${TARIFFS}?error=error`}
                                platform={Platform.YouTube}
                            >
                                <Button
                                    width={120}
                                    size="small"
                                    style={{ padding: 0 }}
                                    variant={locked ? 'outline' : 'primary'}
                                    // margin="0 0 0 10px"
                                    onClick={() => trackOpenReport('infl_exp_yt')}
                                >
                                    {locked ? t('found_blogger.unlock') : t('found_blogger.analyze_button_label')}
                                </Button>
                            </BloggerLink>
                        )}
                        <YTLink username={props.id.toString()} style={bdrs} />

                        <Flex margin="0 0 0 10px">
                            <AddToList type={Platform.YouTube} external_id={props.id} place="infl_exp_yt" />
                        </Flex>
                        <Flex margin="0 0 0 10px">
                            <Like externalId={props.id.toString()} likeId={props.likedId} platform={Platform.YouTube} />
                        </Flex>
                    </Segment>
                    <Comment
                        isOpen={isOpen}
                        open={open}
                        close={close}
                        value={comment}
                        setValue={setComment}
                        submit={addComment}
                        style={{ gridColumn: '4/9', margin: '0 0 0 -20px' }}
                    />
                </ListItem>
            </div>
            <div className={cn.mobileWrap}>
                <div className={cn['mobileWrap--user']}>
                    <Flex>
                        <Segment align="flex-start">
                            <Avatar size={70} style={{ borderRadius: 10 }} src={props.avatar} loadingAvatarWidth="70" />
                        </Segment>
                        <div className={cn['mobileWrap--user-info']}>
                            <Segment column style={overflow}>
                                {props.fullName && (
                                    <Text className={cn['text-overflow']} component="h6" fSize={14} semibold>
                                        {getShortString(props.fullName, 25)}
                                    </Text>
                                )}
                                <Text
                                    className={cn['text-overflow']}
                                    color="violet-1"
                                    // component={BloggerLink}
                                    isDemo={locked}
                                    semibold={false}
                                    id={props.id}
                                    fSize={12}
                                    newTab
                                >
                                    {getShortString(props.username, 25)}
                                </Text>
                            </Segment>
                            {topics && <Topics topics={nonEmptyTopics} size={10} fSize={10} maxCharacterLength={20} />}
                        </div>
                    </Flex>
                    <Menu type={Platform.YouTube} externalId={props.id} place="infl_exp_yt" />
                </div>
                <div className={cn['mobileWrap--stats']}>
                    <Text
                        component={Segment}
                        color="gray-new"
                        fSize={14}
                        column
                        style={{ flexBasis: 100, flex: 1, fontWeight: 600 }}
                    >
                        {shortNum(props.followers)}
                        <span className={cn['mobileWrap--stats-keyWord']}>{t('grid_header.followers')}</span>
                    </Text>
                    <Text
                        component={Segment}
                        color="gray-new"
                        fSize={14}
                        column
                        style={{ flexBasis: 100, flex: 1, fontWeight: 600 }}
                    >
                        {(props.er * 100).toFixed(2)} %
                        <span className={cn['mobileWrap--stats-keyWord']}>{t('grid_header.engagement')}</span>
                    </Text>

                    {/* <Flex margin="0 0 0 10px">
                        <AddToList type="youtube" external_id={props.id} place="infl_exp_yt" />
                    </Flex> */}

                    {locked ? (
                        <Locker
                            style={{ marginRight: 0, width: 120, flexGrow: 0 }}
                            isPromo={props.promo}
                            id={props.id}
                            platform={Platform.YouTube}
                            label={t('found_blogger.unlock')}
                        />
                    ) : (
                        <BloggerLink
                            newTab
                            id={props.id}
                            style={{ width: 120, flexGrow: 0 }}
                            platform={Platform.YouTube}
                        >
                            <Button
                                width={120}
                                size="small"
                                variant={locked ? 'outline' : 'primary'}
                                onClick={() => trackOpenReport('infl_exp_yt')}
                            >
                                {locked ? t('found_blogger.unlock') : t('found_blogger.analyze_button_label')}
                            </Button>
                        </BloggerLink>
                    )}
                    <YTLink username={props.id.toString()} style={bdrs} />
                </div>
            </div>
        </div>
    )
}

export default memo(SearchItem)
