import Text from '@/components/Text'
import { FC } from 'react'
import React from 'react'
import cn from './Item.module.scss'

interface ItemProps {
    title: string
    value: string
    isLoading: boolean
}

export const Item: FC<ItemProps> = ({ title, value, isLoading }) => {
    const className = isLoading ? cn.loading : cn.item
    return (
        <div className={className}>
            <Text fSize={20} semibold>
                {value}
            </Text>
            {!isLoading && (
                <Text color="gray-new-2" fSize={12}>
                    {title}
                </Text>
            )}
        </div>
    )
}
