import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.00008 17.3334C4.39771 17.3334 0.666748 13.6025 0.666748 9.00008C0.666748 4.39771 4.39771 0.666748 9.00008 0.666748C13.6025 0.666748 17.3334 4.39771 17.3334 9.00008C17.3284 13.6004 13.6004 17.3284 9.00008 17.3334ZM9.00008 2.33341C5.31818 2.33341 2.33341 5.31818 2.33341 9.00008C2.33341 12.682 5.31818 15.6667 9.00008 15.6667C12.682 15.6667 15.6667 12.682 15.6667 9.00008C15.6626 5.3199 12.6803 2.33755 9.00008 2.33341ZM13.1667 9.83342H8.16675V4.83342H9.83342V8.16675H13.1667V9.83342Z"
            fill="#8F8F8F"
        />
    </svg>
)
