import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import Flex from '@/_yc/Flex'
import Popup from '@/_yc/Popup'
import useOpenClose from '@/_helpers/useOpenClose'
import Text from '../Text'

import Icons from '../Icons'

import cn from './HiddenMetrics.module.scss'

const HiddenMetric = () => {
    const { t } = useTranslation()
    const icon = useRef<HTMLDivElement>(null)
    const [isOpen, open, close] = useOpenClose()

    return (
        <>
            <Flex className={cn.root}>
                <Flex onMouseEnter={open} onMouseLeave={close} ref={icon} margin={'0 0 0 6px'}>
                    <Icons name="hidden-likes" className={cn.icon} />
                </Flex>

                {isOpen && <div className={cn.arrow} />}
                <Popup
                    isOpen={isOpen}
                    onClose={close}
                    placement={'top'}
                    anchor={icon.current}
                    boundariesElement={'scrollParent'}
                    style={{ zIndex: 100 }}
                >
                    <div className={cn.tooltip}>
                        <Text fSize={10} color={'white'}>
                            {t('report_layout.hidden')}
                        </Text>
                    </div>
                </Popup>
            </Flex>
        </>
    )
}

export default HiddenMetric
