import React, { FC, ComponentProps, useMemo } from 'react'

import LegendItem from '../LegendItem'

import useReportTranslation from '../useReportTranslation'

import { MultiBarChart as MultiBarChartUI } from '@yoloker/ui-collection'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'

export interface IMultiBarChart {
    title: string
    blockWidth: string | number
    data?: Array<{
        items: Array<{
            share: number
            color: string
            qty: number
        }>
        label: string
    }>
    legend: ComponentProps<typeof LegendItem>[]
    hidden?: boolean
}

const MultiBarChart: FC<IMultiBarChart> = ({ title, blockWidth, data, legend, hidden }) => {
    const t = useReportTranslation(title)
    const tLegend = useReportTranslation('legend')

    const size = useWindowWidth()
    const height = useMemo(() => {
        return size === 'mobile' ? '' : '310px'
    }, [size])

    const preprocessedLegend = useMemo(() => {
        return legend.map((item, i) => ({
            value: tLegend(item.label),
            color: item.color,
            id: i,
        }))
    }, [legend, tLegend])

    return (
        <MultiBarChartUI
            size={'small'}
            isDemo={hidden}
            data={data}
            legend={preprocessedLegend}
            title={t('title')}
            titleStyle={{ color: 'gray-4', fSize: 14 }}
            style={{ width: blockWidth, height: height, marginBottom: '20px' }}
            tooltipText={t('helper')}
        />
    )
}

export default MultiBarChart
