import { createReducer } from '@reduxjs/toolkit'
import {
    addEmailAccount,
    addToSelectedAccounts,
    clearSelectedAccounts,
    removeFromSelectedAccounts,
    setEmailAccounts,
} from './actions'
import { EmailAccount } from '../EmailAccounts/EmailAccounts'

export interface SettingsState {
    selectedAccounts: string[]
    emailAccounts: EmailAccount[]
}

const defaultState: SettingsState = {
    selectedAccounts: [],
    emailAccounts: [],
}

export const Settings = createReducer<SettingsState>(defaultState, (builder) => {
    builder.addCase(addToSelectedAccounts, (state, { payload }) => {
        return {
            ...state,
            selectedAccounts: state.selectedAccounts.concat(payload),
        }
    })
    builder.addCase(removeFromSelectedAccounts, (state, { payload }) => {
        return {
            ...state,
            selectedAccounts: state.selectedAccounts.filter((id) => id !== payload),
        }
    })
    builder.addCase(clearSelectedAccounts, (state) => {
        return {
            ...state,
            selectedAccounts: [],
        }
    })
    builder.addCase(setEmailAccounts, (state, { payload }) => {
        return {
            ...state,
            emailAccounts: payload,
        }
    })
    builder.addCase(addEmailAccount, (state, { payload }) => {
        return {
            ...state,
            emailAccounts: [...state.emailAccounts, payload],
        }
    })
})
