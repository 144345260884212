import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as Actions from '../../Model/actions'
import { RootStore } from '@/_helpers/StoreType'
import { useAdapter } from './useAdapter'

export const useSearchBy = () => {
    const searchBy = useSelector((store: RootStore) => store.search.searchBy)

    const dispatch = useDispatch()

    const { keyWords } = useAdapter()

    const add = useCallback(
        (value: string) => {
            dispatch(Actions.addToSearchBy({ value }))
        },
        [dispatch]
    )

    const remove = useCallback(
        (value: string) => {
            dispatch(Actions.removeFromSearchBy({ value }))
        },
        [dispatch]
    )

    const clear = useCallback(() => {
        dispatch(Actions.clearSearchBy())
    }, [dispatch])

    const toggle = useCallback(
        (value: string) => {
            dispatch(Actions.toggleSearchBy({ value }))
        },
        [dispatch]
    )

    return { searchBy, add, remove, toggle, options: keyWords, clear }
}
