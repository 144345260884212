export default {
	BLOGGER_REVIEW_REQUEST: "BLOGGER_REVIEW_REQUEST",
	BLOGGER_REVIEW_REQUEST_SUCCESS: "BLOGGER_REVIEW_REQUEST_SUCCESS",
	BLOGGER_REVIEW_REQUEST_FAILURE: "BLOGGER_REVIEW_REQUEST_FAILURE",

	BLOGGER_REPORT_SWITCH_INIT: "BLOGGER_REPORT_SWITCH_INIT",
	BLOGGER_REPORT_SWITCH_CONTENT: "BLOGGER_REPORT_SWITCH_CONTENT",
	BLOGGER_REPORT_STATUS: "BLOGGER_REPORT_STATUS"
}

export const BLOGGER_REPORT_CLEAR = "BLOGGER_REPORT_CLEAR"