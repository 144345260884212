import React, { FC } from 'react'
import Flex from '@/_yc/Flex'
import Text from '@/components/Text'
import { IPost } from '@/pages/CampaignManager/types'
import { shortNum } from '@/_helpers/_graphs/_tech'
import Avatar from '@/components/Avatar'

import cn from './PostList.module.scss'
import Icons from '@/components/Icons'
import { Platform } from '@/pages/Reports/types'
import { PostReport } from '../PostReport'
import { IncorrectLink } from '../components/IncorrectLink'
import { PostCover } from '@/pages/SearchPage/SearchAdapter/adsExplorer/PostCover/PostCover'
// import { ValueInput } from '@/components/ValueInput/ValueInput'
import { ValueInput } from '../../../ValueInput/ValueInput'
import CircleLoader from '@/components/CircleLoader'
import { useTranslation } from 'react-i18next'
import { PostChangeCampaignRequestReq } from '../../../queries'
import useToken from '@/_helpers/Hooks/useToken'
import { useCampaignId } from '@/_helpers/Hooks/useCampaignId'
import notif from '@/components/Notifier/model/actions.js'
import { useDispatch } from 'react-redux'
import { AddLinksPopup } from '../../../SearchBar/AddLinksPopup'
import { GlobalWidth } from '@/_yc'
interface IPostList {
    posts: IPost[]
}
export const PostList: FC<IPostList> = ({ posts }) => {
    const { t } = useTranslation()
    const token = useToken()
    const cId = useCampaignId()
    const dispatch = useDispatch()

    const head = [
        null,
        t('campaign_manager.campaign.content.header.fullNameUsername'),
        t('campaign_manager.campaign.content.header.type'),
        t('campaign_manager.campaign.content.header.contentPreview'),
        t('campaign_manager.campaign.content.header.followers'),
        t('campaign_manager.campaign.content.header.publicationDate'),
        t('campaign_manager.campaign.content.header.views'),
        t('campaign_manager.campaign.content.header.likes'),
        t('campaign_manager.campaign.content.header.comments'),
        t('campaign_manager.campaign.content.header.content_price'),
        'CPV',
        t('campaign_manager.campaign.content.header.er'),
        // t('campaign_manager.campaign.content.header.other'),
        null,
    ]

    const onPriceSubmit = (bloggerData: any, post: IPost | undefined) => {
        dispatch(notif.loading(t('campaign_manager.hider.req.loading')))
        if (!token) {
            dispatch(notif.error(t('campaign_manager.hider.req.error')))
            return
        }
        if (!post) {
            dispatch(notif.error(t('campaign_manager.hider.req.error')))
            return
        }
        PostChangeCampaignRequestReq(token, cId, post.service_type, post.id || 0, {
            content_price: parseInt(bloggerData.content_price),
        })
            .then(() => {
                dispatch(notif.success(t('campaign_manager.hider.req.success')))
            })
            .catch(() => {
                dispatch(notif.error(t('campaign_manager.hider.req.error')))
            })
    }

    return (
        <>
            <div
                style={{
                    overflow: 'scroll',
                    position: 'relative',
                    marginTop: '20px',
                    background: posts.length === 0 ? 'white' : 'unset',
                }}
            >
                <div style={{ width: '1660px' }}>
                    <div className={cn['table-header']}>
                        {head.map((item) => (
                            <Text color="gray-3" fSize="10px">
                                {item ?? ''}
                            </Text>
                        ))}
                    </div>
                    {posts.length === 0 ? (
                        <>
                            <GlobalWidth margin="0 0 0 0">
                                {/* empty page */}
                                <Flex align="center" content="center" column style={{ width: '100%', height: '380px' }}>
                                    <Text color="gray-1" fSize="20px" fWeight="600">
                                        List of advertising posts
                                    </Text>
                                    <Text color="gray-3" fSize="14px" margin="0 0 16px 0">
                                        To start detailed stats - add links to advertising posts{' '}
                                    </Text>
                                    <AddLinksPopup />
                                </Flex>
                            </GlobalWidth>
                        </>
                    ) : (
                        posts.map((item: IPost, i: number) => (
                            <>
                                <div className={cn['table-item']} key={item.url}>
                                    <div />

                                    <Flex>
                                        <Avatar
                                            alt="AVA"
                                            src={''}
                                            type={item.service_type as Platform}
                                            aria-label="Аватарка"
                                            className={cn.ava}
                                        />
                                        <Flex column margin="0 0 0 8px" content="center" className={cn.names}>
                                            {item?.channel_name ||
                                            item?.blogger_info?.username ||
                                            item?.blogger_info?.full_name ? (
                                                <>
                                                    {item?.blogger_info?.full_name ? (
                                                        <Text>{item?.blogger_info?.full_name}</Text>
                                                    ) : item.channel_name ? (
                                                        <Text>{item?.channel_name}</Text>
                                                    ) : null}
                                                    <Text>{item?.blogger_info?.username}</Text>
                                                </>
                                            ) : (
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        height: '16px',
                                                        borderRadius: '10px',
                                                        backgroundColor: '#F2F4F7',
                                                    }}
                                                />
                                            )}
                                        </Flex>
                                    </Flex>

                                    <div>
                                        <Flex className={cn.socials} align="center">
                                            {item.service_type.includes('youtube') ? (
                                                <Icons name="ie-youtube" />
                                            ) : item.service_type.includes('instagram') ? (
                                                <Icons name="ie-instagram" />
                                            ) : item.service_type.includes('vk') ? (
                                                <Icons name="ie-vk" />
                                            ) : item.service_type.includes('twitch') ? (
                                                <Icons name="ie-twitch" />
                                            ) : item.service_type.includes('telegram') ? (
                                                <Icons name="ie-tg" />
                                            ) : (
                                                <div className={cn.loader}>
                                                    <CircleLoader />{' '}
                                                </div>
                                            )}
                                        </Flex>
                                    </div>

                                    <div>
                                        <PostCover
                                            url={item?.preview_url ? item?.preview_url : ''}
                                            media_url={item?.media_url ? item.media_url : item.url}
                                            width="70px"
                                            height="70px"
                                            // isInst={props?.service_type === 'instagram'}
                                            // shortCode={props?.code}
                                        />
                                    </div>

                                    {item?.blogger_info?.followers !== undefined &&
                                    item?.blogger_info?.followers !== null ? (
                                        <Text>{shortNum(item?.blogger_info?.followers)}</Text>
                                    ) : (
                                        <div className={cn.loader}>
                                            <CircleLoader />{' '}
                                        </div>
                                    )}

                                    {item.created_at !== undefined &&
                                    item.created_at !== null &&
                                    item.created_at.length > 0 ? (
                                        <Text>{new Date(item.created_at).toLocaleDateString()}</Text>
                                    ) : (
                                        <div className={cn.loader}>
                                            <CircleLoader />{' '}
                                        </div>
                                    )}

                                    {item?.views !== undefined && item?.views !== null ? (
                                        <Text>{shortNum(item?.views)}</Text>
                                    ) : (
                                        <div className={cn.loader}>
                                            <CircleLoader />{' '}
                                        </div>
                                    )}

                                    {item?.likes !== undefined && item?.likes !== null ? (
                                        <Text>{shortNum(item?.likes)}</Text>
                                    ) : (
                                        <div className={cn.loader}>
                                            <CircleLoader />{' '}
                                        </div>
                                    )}

                                    {item?.comments !== undefined && item?.comments !== null ? (
                                        <Text>{shortNum(item?.comments)}</Text>
                                    ) : (
                                        <div className={cn.loader}>
                                            <CircleLoader />{' '}
                                        </div>
                                    )}

                                    <ValueInput
                                        value={item?.content_price && parseInt(item?.content_price)}
                                        valueName="content_price"
                                        post={item}
                                        onSubmit={onPriceSubmit}
                                    />
                                    {/* {item?.content_price !== undefined && item?.content_price !== null ? (
                                    <Text>{item?.content_price}</Text>
                                ) : (
                                    <div className={cn.loader}>
                                        <CircleLoader />{' '}
                                    </div>
                                )} */}

                                    {item?.cpv !== undefined && item?.cpv !== null ? (
                                        <Text>{parseFloat(item?.cpv).toFixed(2)}</Text>
                                    ) : (
                                        <div className={cn.loader}>
                                            <CircleLoader />{' '}
                                        </div>
                                    )}

                                    {item?.blogger_info?.er !== undefined && item?.blogger_info?.er !== null ? (
                                        <Text>{item?.blogger_info?.er.toFixed(2)}</Text>
                                    ) : (
                                        <div className={cn.loader}>
                                            <CircleLoader />{' '}
                                        </div>
                                    )}

                                    <Flex align="center" style={{ columnGap: '8px' }}>
                                        {item?.media_not_found ? (
                                            <>
                                                <IncorrectLink />
                                            </>
                                        ) : (
                                            <a
                                                href={item.media_url ?? item.url ?? ''}
                                                className={cn.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <Icons name="external-link" />
                                            </a>
                                        )}
                                        <PostReport
                                            post_id={item?.video_id ?? item?.media_id ?? item?.code ?? item.message_id}
                                            platform={item?.service_type}
                                            disabled={item?.isRequest}
                                            channel_name={item?.channel_name}
                                        />
                                    </Flex>
                                </div>
                                <div className={cn.dividerHoriz} />
                            </>
                        ))
                    )}
                </div>
            </div>
        </>
    )
}
