import Title from '@/_helpers/TitleControl'

import common from './Favicons/favicon.png'
import dotted from './Favicons/favicon_dotted.png'
import SyncronizedStorage from '@/_helpers/SynchronizedStorage'
import Observable from '@/_helpers/Observable'

const title = new Title()

export default class TabNotification {
    static instance?: TabNotification

    private observable = new Observable<number>()

    private store = new SyncronizedStorage<number>(
        'YOLOCO__NOTIF__COUNTER',
        0,
        (i) => ({ value: i }),
        (native) => {
            if (!native || typeof native.value !== 'number') return null
            return native.value
        }
    )

    private favicon =
        document.querySelector<HTMLLinkElement>('link[rel="shortcut icon"]') ||
        document.head.appendChild(
            (() => {
                const link = document.createElement('link')
                link.rel = 'shortcut icon'
                link.href = common
                return link
            })()
        )

    constructor() {
        if (TabNotification.instance) return TabNotification.instance
        TabNotification.instance = this
        this.store.subscribe(() => {
            this.update()
        })
        if (this.store.getValue() > 0) {
            this.update()
        }
    }

    set count(count: number) {
        this.store.setValue(count)
        this.update()
    }

    get count(): number {
        return this.store.getValue()
    }

    private update() {
        const count = this.store.getValue()

        this.observable.update(count)

        if (count > 0) {
            this.favicon.href = dotted
            title.prefix = `(${count})`
        } else {
            this.favicon.href = common
            title.prefix = ''
        }
    }

    subscribe: Observable<number>['subscribe'] = this.observable.subscribe.bind(this.observable)
}
