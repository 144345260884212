import React, { FC } from 'react'
import cn from './Row.module.scss'
import { MassAddCheckBoxNEW } from '@/components/MassAddToList'
import Text from '@/components/Text'
import { EmailAccount } from '../EmailAccounts'
import useOpenClose from '@/_helpers/useOpenClose'
import { EditSmtpPopup } from '@/components/MailSettingsPopup/EditSmtpPopup/EditSmtpPopup'
import { EditGmailPopup } from '@/components/MailSettingsPopup/EditGmailAccount/EditGmailAccount'
import { getShortString } from '@/_helpers/strings/getShortString'

interface RowProps extends EmailAccount {
    index: number
}

export const Row: FC<RowProps> = (props) => {
    const [isOpenPopup, openPopup, closePopup] = useOpenClose()

    return (
        <>
            <div className={cn.row}>
                <MassAddCheckBoxNEW id={props.email} type={'settings-accounts'} index={props.index} />
                <Text onClick={openPopup} style={{ cursor: 'pointer' }}>
                    {getShortString(props.senderName, 24)}
                </Text>
                <Text>{getShortString(props.email, 24)}</Text>
                <Text>{props.type}</Text>
                <Text>{props.totalEmails}</Text>
                <div className={cn.progressContainer}>
                    <div className={cn.progressBar}>
                        <div
                            className={cn.progress}
                            style={{ width: `${(props.sentToday / props.dailyLimit) * 100}%` }}
                        ></div>
                    </div>
                    <Text>
                        {props.sentToday}/{props.dailyLimit}
                    </Text>
                </div>
            </div>
            {props.type === 'gmail' && isOpenPopup && (
                <EditGmailPopup isOpen={isOpenPopup} close={closePopup} account={props} />
            )}
            {props.type === 'smtp/imap' && isOpenPopup && (
                <EditSmtpPopup isOpen={isOpenPopup} close={closePopup} account={props} />
            )}
        </>
    )
}
