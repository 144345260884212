import React, { useMemo } from 'react'
import MoreModal from '@/components/MoreModal'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { Flex } from '@/_yc'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import PopularBrands from './PopularBrands'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import moment from 'moment'
import ListOfAccounts from '@/Layouts/ReportLayout/Content/ResuableWidgets/AccountCards/ ListOfAccounts'
import AccountCard from '@/Layouts/ReportLayout/Content/ResuableWidgets/AccountCards/Card/AccountCard'
import { MockInContainer } from '../Mock/Mock'

import cn from './ListAccountsUI.module.scss'

const BrandMentions = () => {
    const t = useSpaceTranslation('report_titles.brandAffinity')
    const size = useWindowWidth()

    const brandMentions = useSelector((store: RootStore) => {
        return store.report.rawVkData?.getVkReport?.data.vk_account[0].vk_brand_mentions
    })
    const accountInfo = useSelector((store: RootStore) => {
        return {
            type: store.report.rawVkData?.getVkReport?.data.vk_account[0].account_type,
            accountId: store.report.rawVkData?.getVkReport?.data.vk_account[0].account_id,
        }
    })

    const toDate = (dateString: string) => {
        return moment(dateString).format('DD.MM.YYYY')
    }

    let popularBrands: { [key: string]: { count: number; avatar: string; title: string } } = {}
    let popBrandsAr: Array<{
        avatar: string
        title: string
        postsCount: number
    }> = []
    const preprocessedData = useMemo(() => {
        let tempAr = []
        if (brandMentions) {
            for (let i = 0; i < brandMentions.length; i++) {
                popularBrands[brandMentions[i].youtube_brand.name] = {
                    count: popularBrands[brandMentions[i].youtube_brand.name]
                        ? popularBrands[brandMentions[i].youtube_brand.name]['count'] + 1
                        : 1,
                    avatar: brandMentions[i].youtube_brand.avatar,
                    title: brandMentions[i].youtube_brand.name,
                }

                brandMentions[i].vk_accountpost &&
                    tempAr.push({
                        id: brandMentions[i].youtube_brand.id,
                        title: brandMentions[i].youtube_brand.name,
                        avatar: brandMentions[i].youtube_brand.avatar,
                        published_at: toDate(brandMentions[i].vk_accountpost.published_at),
                        views: brandMentions[i].vk_accountpost.views || 0,
                        linkToPost: `https://vk.com/wall${accountInfo.type === 'user' ? '' : '-'}${
                            accountInfo.accountId
                        }_${brandMentions[i].vk_accountpost.post_id}`,
                    })
            }
        }
        for (let item in popularBrands) {
            popBrandsAr.push({
                postsCount: popularBrands[item].count,
                avatar: popularBrands[item].avatar,
                title: popularBrands[item].title,
            })
        }
        return tempAr
    }, [brandMentions, accountInfo, popBrandsAr, popularBrands])

    if (!preprocessedData || preprocessedData.length === 0) {
        return <MockInContainer />
    }
    return (
        <>
            {preprocessedData && preprocessedData.length !== 0 && (
                <Flex className={cn['brand-container']} wrap>
                    <div
                        style={
                            preprocessedData.length > 1 && size !== 'mobile'
                                ? { width: popBrandsAr.length > 0 ? '720px' : '100%' }
                                : { width: '100%' }
                        }
                    >
                        <div>
                            <MoreModal title={t('title')}>
                                <ListOfAccounts
                                    amount={Math.min(preprocessedData.length, 30)}
                                    cardType="horizontal"
                                    data={preprocessedData}
                                />
                            </MoreModal>
                        </div>
                        <Flex className={cn['root']} content="space-between" align="space-between" wrap>
                            {preprocessedData.slice(0, Math.min(6, preprocessedData.length)).map((account) => {
                                return (
                                    <div style={{ marginTop: '24px' }}>
                                        <AccountCard data={account} cardType={'horizontal'} showVideo={false} />
                                    </div>
                                )
                            })}
                        </Flex>
                    </div>
                    {popBrandsAr.length > 1 && <PopularBrands data={popBrandsAr} isDemo={false} />}
                </Flex>
            )}
        </>
    )
}
export default BrandMentions
