import { createAction } from '@reduxjs/toolkit'
import { IBlogger, MailingList } from './types'
import { ICustomListInfo } from '@/pages/Lists/model/types'

export const AddToListINST = createAction<IBlogger[]>('@MassAddToList/massAddToListAddINST')
export const DeleteFromListINST = createAction<string>('@MassAddToList/massAddToListAddDeleteINST')

export const AddToListYT = createAction<IBlogger[]>('@MassAddToList/massAddToListAddYT')
export const DeleteFromListYT = createAction<string>('@MassAddToList/massAddToListAddDeleteYT')

export const RemoveListINST = createAction('@MassAddToList/massAddToListAddRemoveListINST')
export const RemoveListYT = createAction('@MassAddToList/massAddToListAddRemoveListYT')
export const RemoveListTwitch = createAction('@MassAddToList/massAddToListAddRemoveListTwitch')
export const RemoveListVk = createAction('@MassAddToList/massAddToListAddRemoveListVk')
export const RemoveListTg = createAction('@MassAddToList/massAddToListAddRemoveListTg')

export const AddToListTwitch = createAction<IBlogger[]>('@MassAddToList/massAddToListAddTwitch')
export const DeleteFromListTwitch = createAction<string>('@MassAddToList/massAddToListAddDeleteTwitch')

export const AddToListVk = createAction<IBlogger[]>('@MassAddToList/massAddToListAddVk')
export const DeleteFromListVk = createAction<string>('@MassAddToList/massAddToListAddDeleteVk')

export const AddToListTg = createAction<IBlogger[]>('@MassAddToList/massAddToListAddTg')
export const DeleteFromListTg = createAction<string>('@MassAddToList/massAddToListAddDeleteTg')

export const SetBloggersMailingList = createAction<MailingList>('@MassAddToList/setBloggersMailingList')
export const RemoveFromMailingList = createAction<string>('@MassAddToList/removeFromMailingList')

export const SetMailingListSetId = createAction<number[]>('@MassAddToList/setMailingListSetId')

export const SetLastClicked = createAction<number | undefined>('@MassAddToList/SetLastClicked')

export const AddToListsAud = createAction<ICustomListInfo[]>('@MassAddToList/massAddToListsAud')
export const DeleteFromListsAud = createAction<number>('@MassAddToList/massAddToListAddDeleteListsAud')
export const RemoveListsAud = createAction('@MassAddToList/massAddToListAddRemoveListsAud')

export const AddToListMediaPlannerPlans = createAction<string[]>('@MassAddToList/massAddToListAddMediaPlannerPlans')
export const DeleteFromListMediaPlannerPlans = createAction<string>(
    '@MassAddToList/massAddToListAddDeleteMediaPlannerPlans'
)
export const RemoveListMediaPlannerPlans = createAction('@MassAddToList/massAddToListAddRemoveListMediaPlannerPlans')

export const AddToListMediaPlannerBloggers = createAction<string[]>(
    '@MassAddToList/massAddToListAddMediaPlannerBloggers'
)
export const DeleteFromListMediaPlannerBloggers = createAction<string>(
    '@MassAddToList/massAddToListAddDeleteMediaPlannerBloggers'
)
export const RemoveListMediaPlannerBloggers = createAction(
    '@MassAddToList/massAddToListAddRemoveListMediaPlannerBloggers'
)

export const SetAllBloggers = createAction<IBlogger[]>('@MassAddToList/massSetAllBloggers')
export const SetAllMediaPlans = createAction<string[]>('@MassAddToList/massSetAllMediaPlans')
export const SetAllMediaBloggers = createAction<string[]>('@MassAddToList/massSetAllMediaBloggers')

export const SetDefault = createAction('@MassAddToList/massSetDefault')
