export const countryOptions = [
    { label: 'USA', value: 'usa' },
    { label: 'Российская Федерация', value: 'russia' },
    { label: 'Армения', value: 'armenia' },
    { label: 'Грузия', value: 'georgia' },
    { label: 'Германия', value: 'germany' },
    { label: 'Франция', value: 'france' },
    { label: 'Япония', value: 'japan' },
    { label: 'Китай', value: 'china' },
    { label: 'Канада', value: 'canada' },
    { label: 'Австралия', value: 'australia' },
]
export const cityOptions = [
    { label: 'Нью-Йорк', value: 'new_york' },
    { label: 'Москва', value: 'moscow' },
    { label: 'Ереван', value: 'yerevan' },
    { label: 'Тбилиси', value: 'tbilisi' },
    { label: 'Берлин', value: 'berlin' },
    { label: 'Париж', value: 'paris' },
    { label: 'Токио', value: 'tokyo' },
    { label: 'Пекин', value: 'beijing' },
    { label: 'Торонто', value: 'toronto' },
    { label: 'Сидней', value: 'sydney' },
]
export const genderOptions = [
    { label: 'Мужской', value: 'male' },
    { label: 'Женский', value: 'female' },
    { label: 'Другой', value: 'other' },
]
export const ageOptions = [
    { label: '18-24 года', value: '18-24' },
    { label: '25-34 года', value: '25-34' },
    { label: '35-44 года', value: '35-44' },
    { label: '45-54 года', value: '45-54' },
    { label: '55-64 года', value: '55-64' },
    { label: '65 лет и старше', value: '65+' },
]
export const interestsOptions = [
    {
        title: 'Технологии и медиа',
        values: [
            { label: 'Технологии', value: 'technology' },
            { label: 'Социальные медиа', value: 'social_media' },
            { label: 'Игры', value: 'gaming' },
            { label: 'Фотография', value: 'photography' },
            { label: 'Наука', value: 'science' },
        ],
        type: 'technology_media',
    },
    {
        title: 'Спорт и активный отдых',
        values: [
            { label: 'Спорт', value: 'sports' },
            { label: 'Фитнес', value: 'fitness' },
            { label: 'Путешествия', value: 'travel' },
            { label: 'Экология', value: 'environment' },
            { label: 'Животные и природа', value: 'animals_nature' },
        ],
        type: 'sports_outdoors',
    },
    {
        title: 'Искусство и культура',
        values: [
            { label: 'Искусство', value: 'art' },
            { label: 'Музыка', value: 'music' },
            { label: 'Кино', value: 'movies' },
            { label: 'Литература', value: 'literature' },
            { label: 'Фантастика', value: 'sci_fi' },
        ],
        type: 'art_culture',
    },
    {
        title: 'Личный стиль и здоровье',
        values: [
            { label: 'Мода', value: 'fashion' },
            { label: 'Здоровье и благополучие', value: 'health_wellness' },
            { label: 'Кулинария', value: 'cooking' },
            { label: 'Семья и отношения', value: 'family_relationships' },
            { label: 'Дом и сад', value: 'home_garden' },
        ],
        type: 'personal_style_health',
    },
    {
        title: 'Финансы и бизнес',
        values: [
            { label: 'Бизнес', value: 'business' },
            { label: 'Финансы', value: 'finance' },
            { label: 'Инвестиции', value: 'investing' },
            { label: 'Политика', value: 'politics' },
            { label: 'Астрология', value: 'astrology' },
        ],
        type: 'finance_business',
    },
]

export const Options = [
    { label: 'до 20000', value: '20000' },
    { label: '20000 - 40000', value: '20000-40000' },
    { label: '40000 - 60000', value: '40000-60000' },
    { label: '60000 - 80000', value: '60000-80000' },
    { label: '80000 - 100000', value: '80000-100000' },
]

export const ownersOptions = [
    { label: 'Собственный бизнес', value: 'business_owner' },
    { label: 'Дача', value: 'country_house_owner' },
    { label: 'Авто', value: 'car_owner' },
    { label: 'Недвижимость (квартира, дом, апартаменты)', value: 'real_estate_owner' },
]

export const familyOptions = [
    { label: 'Есть дети', value: 'has_children' },
    { label: 'Молодая семья', value: 'young_family' },
]

export const categories = [
    {
        categoryName: 'auto', // Англоязычный ключ
        displayName: 'Авто', // Русское имя для отображения
        options: [
            { label: 'Интерес к страхованию недвижимости', value: 'real_estate' },
            { label: 'Интерес к страхованию жизни', value: 'life_insurance' },
            { label: 'Интерес к автострахованию', value: 'car_insurance' },
            { label: 'Интерес к страхованию ВЗР', value: 'travel_insurance' },
            { label: 'Интерес к КАСКО', value: 'casco' },
            { label: 'Интерес к лизингу', value: 'leasing' },
            { label: 'Интерес к покупке автомобилей', value: 'car_purchase' },
            { label: 'Интерес к автокредитам', value: 'auto_loans' },
        ],
    },
    {
        categoryName: 'finance', // Англоязычный ключ
        displayName: 'Финансы', // Русское имя для отображения
        options: [
            { label: 'Интерес к инвестициям', value: 'investments' },
            { label: 'Интерес к кредитам', value: 'loans' },
            { label: 'Интерес к ипотеке', value: 'mortgage' },
            { label: 'Интерес к банковским услугам', value: 'banking_services' },
            { label: 'Интерес к управлению активами', value: 'asset_management' },
            { label: 'Интерес к страхованию пенсий', value: 'pension_insurance' },
            { label: 'Интерес к криптовалютам', value: 'cryptocurrencies' },
        ],
    },
    {
        categoryName: 'health', // Англоязычный ключ
        displayName: 'Здоровье', // Русское имя для отображения
        options: [
            { label: 'Интерес к медицине', value: 'medicine' },
            { label: 'Интерес к здоровому питанию', value: 'healthy_eating' },
            { label: 'Интерес к фитнесу', value: 'fitness' },
            { label: 'Интерес к диетам', value: 'diets' },
            { label: 'Интерес к йоге', value: 'yoga' },
            { label: 'Интерес к психологическому здоровью', value: 'mental_health' },
            { label: 'Интерес к медицинскому страхованию', value: 'medical_insurance' },
        ],
    },
    {
        categoryName: 'technology', // Англоязычный ключ
        displayName: 'Технологии', // Русское имя для отображения
        options: [
            { label: 'Интерес к разработке ПО', value: 'software_development' },
            { label: 'Интерес к искусственному интеллекту', value: 'artificial_intelligence' },
            { label: 'Интерес к блокчейн-технологиям', value: 'blockchain' },
            { label: 'Интерес к мобильным приложениям', value: 'mobile_apps' },
            { label: 'Интерес к облачным вычислениям', value: 'cloud_computing' },
            { label: 'Интерес к интернету вещей', value: 'iot' },
            { label: 'Интерес к кибербезопасности', value: 'cyber_security' },
        ],
    },
    {
        categoryName: 'travel', // Англоязычный ключ
        displayName: 'Путешествия', // Русское имя для отображения
        options: [
            { label: 'Интерес к международным поездкам', value: 'international_travel' },
            { label: 'Интерес к туризму', value: 'tourism' },
            { label: 'Интерес к отдыху на природе', value: 'outdoor_recreation' },
            { label: 'Интерес к экотуризму', value: 'ecotourism' },
            { label: 'Интерес к круизам', value: 'cruises' },
            { label: 'Интерес к бюджетным поездкам', value: 'budget_travel' },
            { label: 'Интерес к приключенческим путешествиям', value: 'adventure_travel' },
        ],
    },
    {
        categoryName: 'education', // Англоязычный ключ
        displayName: 'Образование', // Русское имя для отображения
        options: [
            { label: 'Интерес к онлайн-курсам', value: 'online_courses' },
            { label: 'Интерес к высшему образованию', value: 'higher_education' },
            { label: 'Интерес к обучению за границей', value: 'study_abroad' },
            { label: 'Интерес к профессиональной подготовке', value: 'professional_training' },
            { label: 'Интерес к языковым курсам', value: 'language_courses' },
            { label: 'Интерес к детскому образованию', value: 'child_education' },
            { label: 'Интерес к научным исследованиям', value: 'scientific_research' },
        ],
    },
    {
        categoryName: 'lifestyle', // Англоязычный ключ
        displayName: 'Образ жизни', // Русское имя для отображения
        options: [
            { label: 'Интерес к моде', value: 'fashion' },
            { label: 'Интерес к дизайну интерьера', value: 'interior_design' },
            { label: 'Интерес к здоровому образу жизни', value: 'healthy_lifestyle' },
            { label: 'Интерес к кулинарии', value: 'cooking' },
            { label: 'Интерес к садоводству', value: 'gardening' },
            { label: 'Интерес к искусству', value: 'art' },
            { label: 'Интерес к фотографии', value: 'photography' },
        ],
    },
    {
        categoryName: 'entertainment', // Англоязычный ключ
        displayName: 'Развлечения', // Русское имя для отображения
        options: [
            { label: 'Интерес к фильмам', value: 'movies' },
            { label: 'Интерес к музыке', value: 'music' },
            { label: 'Интерес к видеоиграм', value: 'video_games' },
            { label: 'Интерес к телевидению', value: 'tv_shows' },
            { label: 'Интерес к концертам', value: 'concerts' },
            { label: 'Интерес к фестивалям', value: 'festivals' },
            { label: 'Интерес к комедийным шоу', value: 'comedy_shows' },
        ],
    },
]
export const additionalOptions = [
    {
        title: 'Возраст детей',
        values: [
            { label: 'Малыши', value: 'Малыши' },
            { label: 'Дошкольники', value: 'Дошкольники' },
            { label: 'Школьники', value: 'Школьники' },
            { label: 'Подростки', value: 'Подростки' },
        ],
        type: 'age_of_children',
    },
    {
        title: 'Тип устройства',
        values: [
            { label: 'Планшет', value: 'Планшет' },
            { label: 'Кнопочный телефон', value: 'Кнопочный телефон' },
            { label: 'Смартфон', value: 'Смартфон' },
        ],
        type: 'device_type',
    },
    {
        title: 'Тип занятости',
        values: [
            { label: 'Работающий', value: 'Работающий' },
            { label: 'Студент', value: 'Студент' },
            { label: 'Домохозяйки', value: 'Домохозяйки' },
            { label: 'Пенсионер', value: 'Пенсионер' },
        ],
        type: 'employment_type',
    },
    {
        title: 'Семья',
        values: [
            { label: 'Есть дети', value: 'has_children' },
            { label: 'Молодая семья', value: 'young_family' },
        ],
        type: 'family',
    },
    {
        title: 'Интересующие сайты',
        values: [{ label: 'https://www.youtube.com', value: 'https://www.youtube.com' }],
        type: 'sites_of_interest',
    },
]
export const additionalTitles: Record<string, string> = {
    selectedAges: 'target_audience.additional_params.children_age',
    selectedLandlord: 'target_audience.additional_params.owners',
    selectedDevices: 'target_audience.additional_params.device_type',
    selectedEmployment: 'target_audience.additional_params.employment_type',
    selectedFamily: 'target_audience.additional_params.family',
    websites: 'target_audience.additional_params.interested_sites',
}

export const paramsTitles: Record<string, string> = {
    ages: 'target_audience.main_criteria.age',
    gender: 'target_audience.main_criteria.gender',
    country: 'target_audience.main_criteria.city_or_region',
    income: 'target_audience.main_criteria.income',
}
export const initialInterests = [
    { title: 'Авто', type: 'auto', values: [] },
    { title: 'Страхование', type: 'insurance', values: [] },
    { title: 'Финансы', type: 'finance', values: [] },
    { title: 'Еда и продукты', type: 'food_products', values: [] },
    { title: 'Красота и здоровье', type: 'beauty_health', values: [] },
    { title: 'Услуги', type: 'services', values: [] },
    { title: 'Недвижимость', type: 'real_estate', values: [] },
    { title: 'Развлечения', type: 'entertainment', values: [] },
    { title: 'Спорт', type: 'sports', values: [] },
    { title: 'Поиск работы', type: 'job_search', values: [] },
    { title: 'Животные', type: 'animals', values: [] },
    { title: 'Медицина', type: 'medicine', values: [] },
    { title: 'Туризм', type: 'tourism', values: [] },
    { title: 'Собственность', type: 'property', values: [] },
]

export const tooltips = {
    ages: 'target_audience.selectAgeRange',
    gender: 'target_audience.selectGenderTooltip',
    country: 'target_audience.selectLocation',
    income: 'target_audience.selectIncomeRange',
    selectedAges: 'target_audience.target_children_age_groups',
    selectedLandlord: 'target_audience.selectAssets',
    selectedDevices: 'target_audience.selectDevices',
    selectedEmployment: 'target_audience.selectOccupation',
    selectedFamily: 'target_audience.selectMaritalStatus',
    websites: 'target_audience.enterWebsites',
}
