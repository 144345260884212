import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="120" height="20" viewBox="0 0 120 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.7716 0.962567H25.6833L15.4156 12.5134V19.6791H10.2677V12.5134L0 0.962567H6.13797L12.9548 9.09091L19.7716 0.962567Z"
            fill="#3D3F42"
        />
        <path
            d="M25.4148 18.0749C23.548 16.7736 22.6145 14.9733 22.6145 12.6738C22.6145 10.3743 23.548 8.58289 25.4148 7.29947C27.3005 5.99822 29.8179 5.34759 32.9671 5.34759C36.1162 5.34759 38.6242 5.99822 40.491 7.29947C42.3767 8.58289 43.3196 10.3743 43.3196 12.6738C43.3196 14.9733 42.3767 16.7736 40.491 18.0749C38.6242 19.3583 36.1162 20 32.9671 20C29.8179 20 27.3005 19.3583 25.4148 18.0749ZM28.9222 9.94652C27.9605 10.6595 27.4797 11.5686 27.4797 12.6738C27.4797 13.779 27.9605 14.697 28.9222 15.4278C29.9028 16.1408 31.2511 16.4973 32.9671 16.4973C34.6831 16.4973 36.0219 16.1408 36.9836 15.4278C37.9642 14.697 38.4545 13.779 38.4545 12.6738C38.4545 11.5686 37.9642 10.6595 36.9836 9.94652C36.0219 9.21569 34.6831 8.85027 32.9671 8.85027C31.2511 8.85027 29.9028 9.21569 28.9222 9.94652Z"
            fill="#3D3F42"
        />
        <path d="M46.3866 0H51.3083V19.6791H46.3866V0Z" fill="#3D3F42" />
        <path
            d="M57.1532 18.0749C55.2864 16.7736 54.3529 14.9733 54.3529 12.6738C54.3529 10.3743 55.2864 8.58289 57.1532 7.29947C59.0389 5.99822 61.5563 5.34759 64.7055 5.34759C67.8546 5.34759 70.3626 5.99822 72.2294 7.29947C74.1151 8.58289 75.058 10.3743 75.058 12.6738C75.058 14.9733 74.1151 16.7736 72.2294 18.0749C70.3626 19.3583 67.8546 20 64.7055 20C61.5563 20 59.0389 19.3583 57.1532 18.0749ZM60.6606 9.94652C59.6989 10.6595 59.2181 11.5686 59.2181 12.6738C59.2181 13.779 59.6989 14.697 60.6606 15.4278C61.6412 16.1408 62.9895 16.4973 64.7055 16.4973C66.4215 16.4973 67.7603 16.1408 68.722 15.4278C69.7026 14.697 70.1929 13.779 70.1929 12.6738C70.1929 11.5686 69.7026 10.6595 68.722 9.94652C67.7603 9.21569 66.4215 8.85027 64.7055 8.85027C62.9895 8.85027 61.6412 9.21569 60.6606 9.94652Z"
            fill="#3D3F42"
        />
        <path
            d="M97.1895 14.5187C96.8689 16.2478 95.8317 17.5936 94.078 18.5562C92.3432 19.5187 90.1841 20 87.6006 20C84.4515 20 81.9624 19.3672 80.1333 18.1016C78.323 16.8182 77.4178 15.0089 77.4178 12.6738C77.4178 10.3387 78.3135 8.53833 80.105 7.27273C81.9152 5.98931 84.3667 5.34759 87.4592 5.34759C90.0049 5.34759 92.1452 5.81997 93.88 6.76471C95.6149 7.69162 96.6332 8.99287 96.9349 10.6685L92.1829 10.9358C91.8623 10.2763 91.2778 9.76827 90.4292 9.41177C89.5806 9.03743 88.5906 8.85027 87.4592 8.85027C85.8941 8.85027 84.6401 9.19786 83.6972 9.89305C82.7732 10.5882 82.3112 11.5152 82.3112 12.6738C82.3112 13.8503 82.7827 14.7861 83.7255 15.4813C84.6684 16.1586 85.9601 16.4973 87.6006 16.4973C88.8263 16.4973 89.8541 16.3012 90.6838 15.9091C91.5323 15.4991 92.1075 14.9554 92.4092 14.2781L97.1895 14.5187Z"
            fill="#3D3F42"
        />
        <path
            d="M102.095 18.0749C100.228 16.7736 99.295 14.9733 99.295 12.6738C99.295 10.3743 100.228 8.58289 102.095 7.29947C103.981 5.99822 106.498 5.34759 109.647 5.34759C112.797 5.34759 115.305 5.99822 117.171 7.29947C119.057 8.58289 120 10.3743 120 12.6738C120 14.9733 119.057 16.7736 117.171 18.0749C115.305 19.3583 112.797 20 109.647 20C106.498 20 103.981 19.3583 102.095 18.0749ZM105.603 9.94652C104.641 10.6595 104.16 11.5686 104.16 12.6738C104.16 13.779 104.641 14.697 105.603 15.4278C106.583 16.1408 107.931 16.4973 109.647 16.4973C111.363 16.4973 112.702 16.1408 113.664 15.4278C114.645 14.697 115.135 13.779 115.135 12.6738C115.135 11.5686 114.645 10.6595 113.664 9.94652C112.702 9.21569 111.363 8.85027 109.647 8.85027C107.931 8.85027 106.583 9.21569 105.603 9.94652Z"
            fill="#3D3F42"
        />
    </svg>
)
