import React, { memo, FC } from 'react'

import cn from './Loader.module.scss'

export interface ILoader {
    style?: React.CSSProperties
}

const Loader: FC<ILoader> = ({ style }) => {
    return (
        <div {...{ style }} className={cn.root}>
            <div className={cn.circle__medium} />
            <div className={cn.circle__small} />
            <div className={cn.dot__first} />
            <div className={cn.dot__second} />
            <div className={cn.dot__third} />
            <div className={cn.bar_start} />
            <div className={cn.bar_end} />
        </div>
    )
}

export default memo(Loader)
