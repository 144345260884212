import useOpenClose from '@/_helpers/useOpenClose'
import React, { FC } from 'react'
import Lock from './Lock'
import Unlock from './Unlock'
import cn from './FilterLock.module.scss'

export interface ILock {}

const FilterLock: FC<ILock> = (props) => {
    const [isOpen, open, close] = useOpenClose()
    return !isOpen ? <Lock className={cn.margin} onMouseEnter={open} /> : <Unlock leave={close} />
}

export default FilterLock
