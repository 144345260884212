import React, { FC, memo } from 'react'

import { Flex } from '@/_yc/'
import Avatar from '@/components/Avatar'
import Arrow from './Arrow'

import cn from './Popup.module.scss'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import { useTranslation } from 'react-i18next'
import { AutocompleteResult } from '@/Reponses'
import BloggerLink from '@/components/Share/BloggerLink'
import Text from '@/components/Text'
import { getCorrect } from '@/_helpers'
import { shortNum } from '@/_helpers/_graphs/_tech'
import { track } from '@amplitude/analytics-browser'
import { OpenReportPlace } from '@/_helpers/amplitude/types'

export interface ISearchItem extends AutocompleteResult {
    place: OpenReportPlace
}

const SearchItem: FC<ISearchItem> = (props) => {
    const size = useWindowWidth()

    const { t } = useTranslation()

    const onClick = () => {
        track(`open_${props.type}_report_in_${props.place}`)
    }

    return (
        <Flex
            component={BloggerLink}
            platform={props.type}
            type={props.type}
            align="center"
            newTab
            id={props.external_id}
            className={cn.item}
            onClick={onClick}
            accountType={props.vkAccountType}
        >
            <Avatar src={props.avatar} className={cn.avatar} type={props.type} />
            <Flex
                margin="0 0 0 10px"
                column={size === 'mobile'}
                grow
                content={size === 'mobile' ? 'center' : 'space-between'}
                align={size === 'mobile' ? 'flex-start' : 'center'}
            >
                <div className={cn.item__data}>
                    <Text semibold color="violet-1" component="h6">
                        {props.username}
                    </Text>
                    <Flex>
                        {props.fullname && (
                            <Text semibold fSize={12} color="gray-3">
                                {props.fullname}
                            </Text>
                        )}
                        {props.followers && (
                            <Text margin="0 0 0 10px" fSize={12} color="gray-3">
                                {t(`aud_insides.search_input.followers${getCorrect(props.followers)}`, {
                                    count: shortNum(props.followers) as any,
                                })}
                            </Text>
                        )}
                    </Flex>
                </div>
                <Text fSize={12} semibold color="gray-3">
                    {t(
                        props.unlocked
                            ? 'aud_insides.search_input.button.report'
                            : 'aud_insides.search_input.button.unlock'
                    )}{' '}
                    <Arrow className={cn.icon} />
                </Text>
            </Flex>
        </Flex>
    )
}

export default memo(SearchItem)
