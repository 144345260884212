import React, { SVGAttributes } from 'react'
export const TgIcon = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="18" height="18" rx="9" fill="#21A5E4" stroke="white" stroke-width="2" />
        <path
            d="M8.96697 11.3369L8.80817 13.6822C9.03538 13.6822 9.13378 13.5797 9.25178 13.4567L10.317 12.3878L12.5243 14.085C12.9291 14.3219 13.2143 14.1971 13.3235 13.694L14.7723 6.56577L14.7727 6.56535C14.9011 5.93703 14.5563 5.69133 14.1619 5.84547L5.64569 9.26887C5.06448 9.50575 5.07328 9.84595 5.54689 10.0001L7.72414 10.7111L12.7815 7.38854C13.0195 7.22306 13.2359 7.31462 13.0579 7.4801L8.96697 11.3369Z"
            fill="white"
        />
    </svg>
)
