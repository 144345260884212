import React from 'react'
import { SelectorFilter } from '../../../../filters/SelectorFilter'
import Icons from '@/components/Icons'

const getGqlFilter = (value: string[]) => {
    if (!value || value.length === 0) return ''
    if (value[0] === 'any') return ''
    return `account_type: {_eq: "${value[0]}"}`
}

export const getAccountTypeFilter = () => {
    return new SelectorFilter({
        platform: 'infl_vk',
        title: 'filters.account_type.label',
        icon: <Icons name="account--type" />,
        helper: 'filters.account_type.text',
        space: 1,

        options: [
            { label: 'filters.account_type.any', value: 'any', translate: true },
            { label: 'filters.account_type.blogger', value: 'user', translate: true },
            { label: 'filters.account_type.group', value: 'group', translate: true },
        ],
        beforeSend: ({ value }) => ({
            name: 'ageGender.gender',
            type: 'array',
            value: value,
            gqlFilter: getGqlFilter(value),
        }),
    })
}
