import React from 'react';

import Default from './Default/'
import Cats from './categories/'
import CatsWhite from './categories_white/'
import Tariff from './Tariff/'
import Report from './report/'
import Notif from './Notification/'

const Icons = ({ context, ...props }) => {
	switch (context) {
		case "cats": return <Cats {...props}/>
		case "cats_white": return <CatsWhite {...props}/>
		case "tariff": return <Tariff {...props}/>
		case "report": return <Report {...props}/>
		case "notif": return <Notif {...props}/>
		default: return <Default {...props}/>
	}
}

const Memo = React.memo(Icons)

export { Memo as Icons}