import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20 15.5H5V13H20V15.5ZM2.5 15.5H0V13H2.5V15.5ZM20 9.25H5V6.75H20V9.25ZM2.5 9.25H0V6.75H2.5V9.25ZM20 3H5.02875V0.5H20V3ZM2.5 3H0V0.5H2.5V3Z"
            fill="white"
        />
    </svg>
)
