import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import { combineHOC, selectLocker } from '../../../../_helpers'

import PB from './PDFBar.js'

const mapStateToProps = createSelector(selectLocker('pdf'), (locked) => ({ locked }))

export default combineHOC(connect(mapStateToProps))(PB)
