import React from 'react';
import { useTranslation } from 'react-i18next'

import cn from './CommonError.module.scss'

const CommonError = () => {
	const { t } = useTranslation();

	return (
		<div className = {cn.root}>
			{t("aud_insides.err.l1")}
			<br />
			{t("aud_insides.err.l2")}
			<br />
			<span>{t("aud_insides.err.l3")}</span>
		</div>
	)
}

export { CommonError as default }