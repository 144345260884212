import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Text from '@/components/Text'
import { Flex } from '@/_yc'
import { BasicFilter } from '../../../SearchAdapter/filters/BasicFilter'
import { RootStore } from '@/_helpers/StoreType'
import { FilterIdContext } from '../../../SearchAdapter/FilterIdContext'
import FilterLock from './FilterLock'
import Tooltip from './Tooltip/Tooltip'
import cn from './Filters.module.scss'

export interface IFilterItem {
    start: number
    end: number
    filter: BasicFilter<any, any>
    name: string
    categoryName: string
    scrollToFilter: Function
    isMain?: boolean
}

const FilterItem: FC<IFilterItem> = ({ filter: Filter, start, end, name, categoryName, scrollToFilter, isMain }) => {
    const { t } = useTranslation()
    const user = useSelector((state: RootStore) => state.authentication.user?.profile)
    const permitted = useSelector((store: RootStore) =>
        !Filter.options.featureKey
            ? true
            : store.authentication.user?.profile?.features?.includes(Filter.options.featureKey) || false
    )
    const locked = Filter.options.locked ? Filter.options.locked(user?.features || []) : false
    return (
        <FilterIdContext.Provider value={Filter.id || `${categoryName}.${name}`}>
            <div
                style={{
                    gridColumnStart: start,
                    gridColumnEnd: end,
                }}
            >
                {!Filter.options.innerMeta && !isMain && (
                    <Flex content="space-between" align="center" margin="0 0 4px">
                        <Flex content="flex-start" className={cn.flex}>
                            <Flex content="flex-start">
                                <div>{Filter.options.icon}</div>
                                <Text tAlign="center" margin="0 0 0 4px" fSize={12} semibold color="gray-new-2">
                                    {t(Filter.options.title)}
                                </Text>
                            </Flex>
                            {Filter.options.helper ? <Tooltip text={Filter.options.helper} /> : null}
                        </Flex>
                        {locked && <FilterLock />}
                    </Flex>
                )}

                {isMain && (
                    <Filter.MainView filterName={Filter.options.title} autocomplete={Filter.options.autocomplete} />
                )}

                {!isMain && (
                    <Filter.View
                        disabled={!permitted}
                        filterName={Filter.options.title}
                        autocomplete={Filter.options.autocomplete}
                        scrollToFilter={scrollToFilter}
                    />
                )}
            </div>
        </FilterIdContext.Provider>
    )
}

export default memo(FilterItem)
