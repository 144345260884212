import React, { useCallback, useMemo } from 'react'
import cn from './Header.module.scss'
import { ChooseAllNEW } from '@/components/MassAddToList/ChooseAll'
import Text from '@/components/Text'
import { useSpaceTranslation } from '@/_helpers'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import useToken from '@/_helpers/Hooks/useToken'
import { clearSelectedAccounts, setEmailAccounts } from '../../Model/actions'
import { deleteAccountReq } from '../../Service/service'
import { GetUserMailsReq } from '@/pages/Outreach/Service/Campaigns'

export const Header = () => {
    const t = useSpaceTranslation('settings.mail.accounts')
    const dispatch = useDispatch()
    const selectedAccounts = useSelector((state: RootStore) => state.Settings.selectedAccounts)

    const showSelectedMenu = useMemo(() => selectedAccounts.length > 0, [selectedAccounts])

    const token = useToken()
    const onChooseAllClick = useCallback(() => {
        if (showSelectedMenu) {
            dispatch(clearSelectedAccounts())
        }
    }, [showSelectedMenu, dispatch])

    const updateEmailAccounts = useCallback(() => {
        if (!token) return
        GetUserMailsReq(token)
            .then((res) => {
                const emailAccounts = res.results.map((mail) => ({
                    id: mail.id,
                    senderName: mail.sender_name,
                    email: mail.email,
                    type: mail.email_server_type,
                    totalEmails: mail.total_emails,
                    dailyLimit: mail.day_limit,
                    signature: mail.signature,
                    emailCredentials: mail.email_credentials,
                    sentToday: mail.sent_today,
                }))
                dispatch(setEmailAccounts(emailAccounts))
            })
            .catch((e) => {
                dispatch(setEmailAccounts([]))
                console.log(e)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    const onDeleteClick = useCallback(() => {
        if (!token) return

        let promises = []
        for (let i = 0; i < selectedAccounts.length; i++) {
            promises.push(deleteAccountReq(token, selectedAccounts[i]))
        }
        Promise.all(promises).then(() => {
            dispatch(clearSelectedAccounts())
            updateEmailAccounts()
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, selectedAccounts])

    if (showSelectedMenu) {
        return (
            <div className={cn.menu}>
                <div onClick={onChooseAllClick}>
                    <ChooseAllNEW platform="settings" value={showSelectedMenu ? 'dashed' : 'empty'} />
                </div>
                <div className={cn['menu-button']} onClick={onDeleteClick}>
                    <Text color="gray-2" fSize={10}>
                        {t('delete')}
                    </Text>
                </div>
            </div>
        )
    }

    return (
        <div className={cn.header}>
            <ChooseAllNEW platform="settings" />
            <Text color="gray-3" fSize={10}>
                {t('sender_name')}
            </Text>
            <Text color="gray-3" fSize={10}>
                {t('email')}
            </Text>
            <Text color="gray-3" fSize={10}>
                {t('type')}
            </Text>
            <Text color="gray-3" fSize={10}>
                {t('total_emails')}
            </Text>
            <Text color="gray-3" fSize={10}>
                {t('daily_emails')}
            </Text>
        </div>
    )
}
