export default {
    "bloggers": [
{
                "avatar": "https://cdn.yoloco.ru/francinyehlke.jpe",
                "username": "francinyehlke",
                "followers": 15561451,
                "external_id": 18774670
            },
{
                "avatar": "https://cdn.yoloco.ru/thami.jpe",
                "username": "thami",
                "followers": 50764,
                "external_id": 25758310
            },
{
                "avatar": "https://cdn.yoloco.ru/anitta.jpe",
                "username": "anitta",
                "followers": 63276751,
                "external_id": 26633036
            },
{
                "avatar": "https://cdn.yoloco.ru/paullogoes.jpe",
                "username": "paullogoes",
                "followers": 70388,
                "external_id": 31251706
            },
{
                "avatar": "https://cdn.yoloco.ru/joaoalmeidac.jpe",
                "username": "joaoalmeidac",
                "followers": 1198703,
                "external_id": 42131722
            },
{
                "avatar": "https://cdn.yoloco.ru/oficialbetoalmeida.jpe",
                "username": "oficialbetoalmeida",
                "followers": 50318,
                "external_id": 45024121
            },
{
                "avatar": "https://cdn.yoloco.ru/jreisjunior.jpe",
                "username": "jreisjunior",
                "followers": 93747,
                "external_id": 47240880
            },
{
                "avatar": "https://cdn.yoloco.ru/imbizita.jpe",
                "username": "imbizita",
                "followers": 104044,
                "external_id": 53320670
            },
{
                "avatar": "https://cdn.yoloco.ru/mihcezar.jpe",
                "username": "mihcezar",
                "followers": 85664,
                "external_id": 143827821
            },
{
                "avatar": "https://cdn.yoloco.ru/ronaldonene.jpe",
                "username": "ronaldonene",
                "followers": 62106,
                "external_id": 144617429
            },
{
                "avatar": "https://cdn.yoloco.ru/bruna_rosa_.jpe",
                "username": "bruna_rosa_",
                "followers": 48971,
                "external_id": 182709505
            },
{
                "avatar": "https://cdn.yoloco.ru/wwwingrids.jpe",
                "username": "wwwingrids",
                "followers": 78421,
                "external_id": 204415100
            },
{
                "avatar": "https://cdn.yoloco.ru/levisnovaes.jpe",
                "username": "levisnovaes",
                "followers": 6473,
                "external_id": 213172152
            },
{
                "avatar": "https://cdn.yoloco.ru/nickymitrava.jpe",
                "username": "nickymitrava",
                "followers": 84726,
                "external_id": 216224860
            },
{
                "avatar": "https://cdn.yoloco.ru/malufca.jpe",
                "username": "malufca",
                "followers": 1092886,
                "external_id": 221890539
            },
{
                "avatar": "https://cdn.yoloco.ru/kaitocasagrandi.jpe",
                "username": "kaitocasagrandi",
                "followers": 62690,
                "external_id": 224907941
            },
{
                "avatar": "https://cdn.yoloco.ru/juhmarquesf.jpe",
                "username": "juhmarquesf",
                "followers": 83354,
                "external_id": 292251162
            },
{
                "avatar": "https://cdn.yoloco.ru/leticiamunix.jpe",
                "username": "leticiamunix",
                "followers": 55932,
                "external_id": 311265518
            },
{
                "avatar": "https://cdn.yoloco.ru/superpretinha.jpe",
                "username": "superpretinha",
                "followers": 83854,
                "external_id": 315840863
            },
{
                "avatar": "https://cdn.yoloco.ru/leonardozenolli.jpe",
                "username": "leonardozenolli",
                "followers": 99578,
                "external_id": 322011776
            },
{
                "avatar": "https://cdn.yoloco.ru/wesdrag.jpe",
                "username": "wesdrag",
                "followers": 70646,
                "external_id": 323622580
            },
{
                "avatar": "https://cdn.yoloco.ru/coisadenerd.jpe",
                "username": "coisadenerd",
                "followers": 2278418,
                "external_id": 334829422
            },
{
                "avatar": "https://cdn.yoloco.ru/nilmoretto.jpe",
                "username": "nilmoretto",
                "followers": 1728855,
                "external_id": 354945278
            },
{
                "avatar": "https://cdn.yoloco.ru/imsors.jpe",
                "username": "imsors",
                "followers": 723771,
                "external_id": 598695438
            },
{
                "avatar": "https://cdn.yoloco.ru/eibarbarasa.jpe",
                "username": "eibarbarasa",
                "followers": 65268,
                "external_id": 609314488
            },
{
                "avatar": "https://cdn.yoloco.ru/milasamine.jpe",
                "username": "milasamine",
                "followers": 94783,
                "external_id": 627845169
            },
{
                "avatar": "https://cdn.yoloco.ru/gabriellalimav.jpe",
                "username": "gabriellalimav",
                "followers": 85151,
                "external_id": 657715276
            },
{
                "avatar": "https://cdn.yoloco.ru/maisa.jpe",
                "username": "maisa",
                "followers": 44721911,
                "external_id": 741697042
            },
{
                "avatar": "https://cdn.yoloco.ru/arthursoll.jpe",
                "username": "arthursoll",
                "followers": 107528,
                "external_id": 919623721
            },
{
                "avatar": "https://cdn.yoloco.ru/jordananavacchio.jpe",
                "username": "jordananavacchio",
                "followers": 132067,
                "external_id": 1202685807
            },
{
                "avatar": "https://cdn.yoloco.ru/gabriel_miras.jpe",
                "username": "gabriel_miras",
                "followers": 76849,
                "external_id": 1392846239
            },
{
                "avatar": "https://cdn.yoloco.ru/marcelleoliveiraoficial.jpe",
                "username": "marcelleoliveiraoficial",
                "followers": 86249,
                "external_id": 1555690962
            },
{
                "avatar": "https://cdn.yoloco.ru/jhully_r.jpe",
                "username": "jhully_r",
                "followers": 123128,
                "external_id": 1578035865
            },
{
                "avatar": "https://cdn.yoloco.ru/abdalabrothers.jpe",
                "username": "abdalabrothers",
                "followers": 96563,
                "external_id": 2022206328
            },
{
                "avatar": "https://cdn.yoloco.ru/luis.traveling.jpe",
                "username": "luis.traveling",
                "followers": 82781,
                "external_id": 2042498632
            },
{
                "avatar": "https://cdn.yoloco.ru/caioepn.jpe",
                "username": "caioepn",
                "followers": 23073,
                "external_id": 3073459808
            },
{
                "avatar": "https://cdn.yoloco.ru/guiipaiva.jpe",
                "username": "guiipaiva",
                "followers": 64702,
                "external_id": 3583686062
            },
{
                "avatar": "https://cdn.yoloco.ru/dudanunxs.jpe",
                "username": "dudanunxs",
                "followers": 94145,
                "external_id": 4736267424
            }
    ],
    "inProcess": false,
    "date": "Aug 16, 2022",
    "id": "demo"
}
