import React, { FC } from 'react'
import styles from './finish.module.scss'
import Modal from '@/_yc/Modal'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import Button from '@/components/Button'
import { ReactComponent as IconWarning } from '@/pages/TargetAudience/icons/circle-worning.svg'
import { useTranslation } from 'react-i18next'

interface FinishModalProps {
    onClose: () => void
    onSubmit: () => void
    isVisible: boolean
}

const Index: FC<FinishModalProps> = ({ onClose, onSubmit, isVisible }) => {
    const { t } = useTranslation()

    if (!isVisible) return null

    return (
        <Modal onClose={onClose}>
            <Flex column className={styles.modalContent}>
                <Flex className={styles.iconWrapper}>
                    <IconWarning />
                </Flex>
                <Flex column className={styles.textWrapper}>
                    <Text component="h3" margin="0 0 10px 0" fSize="20px" semibold color="gray-new-1">
                        {t('target_audience.attention')}
                    </Text>
                    <Text margin="0 0 16px 0" fSize="14px" color="gray-new-4">
                        {t('target_audience.analysis_started')}
                    </Text>
                </Flex>
                <Flex column className={styles.inputWrapper}>
                    <Button onClick={onSubmit}>{t('target_audience.thank_you')}</Button>
                </Flex>
            </Flex>
        </Modal>
    )
}

export default Index
