import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.99183 0.833252L5.00016 3.82492L2.0085 0.833252L0.833496 2.00825L3.82516 4.99992L0.833496 7.99159L2.0085 9.16658L5.00016 6.17492L7.99183 9.16658L9.16683 7.99159L6.17516 4.99992L9.16683 2.00825L7.99183 0.833252Z"
            fill="#ADADAD"
        />
    </svg>
)
