import Icons from '@/components/Icons'
import React from 'react'
import { RangeFilter, RangeValueType } from '../../../../filters/RangeFilter'
import { getRange } from '../../../youtube/utils/filters/getRange'

const getGqlFilter = (range: RangeValueType) => {
    if (!range.from && !range.to) return ''

    return `median_comments_w: ${getRange(range.from, range.to)}`
}

export const getCommentsFilter = () => {
    return new RangeFilter({
        platform: 'infl_tg',
        title: 'filters.comments.label',
        helper: 'filters.comments.text',
        space: 1,
        options: {
            from: [
                { value: 10, label: '10' },
                { value: 50, label: '50' },
                { value: 100, label: '100' },
                { value: 200, label: '200' },
                { value: 500, label: '500' },
                { value: 1000, label: '1K' },
                { value: 2000, label: '2K' },
                { value: 5000, label: '5K' },
            ],
            to: [
                { value: 50, label: '50' },
                { value: 100, label: '100' },
                { value: 200, label: '200' },
                { value: 500, label: '500' },
                { value: 1000, label: '1K' },
                { value: 2000, label: '2K' },
                { value: 5000, label: '5K' },
                { value: 10_000, label: '10K' },
            ],
        },
        icon: <Icons name="filter--comments" />,
        beforeSend: (data) => {
            return {
                name: 'metrics.comments',
                type: 'range',
                value: data,
                gqlFilter: getGqlFilter(data),
            }
        },
    })
}
