import React, { FC } from 'react'

import { useSpaceTranslation } from '@/_helpers'
import cn from './NoData.module.scss'
import Text from '@/components/Text'

export interface INoData {}

const NoData: FC<INoData> = () => {
    const t = useSpaceTranslation('report_layout.graph')
    return (
        <>
            <div className={cn.noData}>
                <Text fSize={21} color="gray-1" margin="0 0 10px 0">
                    {t('no_data')}
                </Text>
                <Text fSize={16} color="gray-1" margin="0 0 10px 0">
                    {t('getting_info')}
                </Text>
            </div>
        </>
    )
}

export default NoData
