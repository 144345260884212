import React, { FC, CSSProperties } from 'react'

import cn from '../InstaLink/InstaLink.module.scss'
import Icons from '@/components/Icons'
import useClass from '@/_helpers/Hooks/useClass'

export interface IYTLink {
    username: string

    margin?: string
    className?: string
    style?: CSSProperties
}

// TODO: remove this component and use ExternalLink instead
/**
 * @deprecated use ExternalLink instead
 */
const YTLink: FC<IYTLink> = (props) => {
    const root = useClass(cn.root, props.className)

    return (
        <a
            href={`https://www.youtube.com/channel/${props.username}`}
            className={root}
            style={{
                margin: props.margin,
                ...props.style,
            }}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Icons name="external-link" />
        </a>
    )
}

export default YTLink
