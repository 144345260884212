import { memo } from 'react'
import { mapProps, compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { createSelector } from 'reselect'

import { getCorrect } from '../../../../_helpers'
import IC from './IntersectionCard.js'
import { shortStr } from '../../../../_helpers/_graphs/_tech'

export { IIntersectionCard } from './IntersectionCard.js'

const propsMapper = createSelector(
	props => props.name,
	props => props.date,
	props => props.bloggers, 
	props => props.menu,
	props => props.t,
	props => props.index, 
	props => props.renaming,
	props => props.onRenamed,
	props => props.onCancel,
	props => props.inProcess,
	props => props.progress,
	props => props.id,
	(name, date, bloggers, menu, t, index, renaming, onRenamed, onCancel, inProcess, progress, id) => ({
		name, 
		date, 
		bloggers: bloggers.slice(0, 9).map(i => i.avatar), 
		menu,
		names: shortStr(bloggers.map((item) => item.username).join(", "), 100),
		counter: t(
			`intersections.dashboards.card.counter${getCorrect(bloggers.length)}`, 
			{ count: bloggers.length }
		),
		index,
		renaming,
		onRenamed,
		onCancel,
		inProcess,
		progress,
		id
	})
)

export default compose(
	withTranslation(),
	mapProps(propsMapper),
	memo
)(IC)