import React, { SVGAttributes } from 'react'
const Message = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 4H2.66667V5.33333H4V4Z" fill="#76849B" />
        <path d="M5.33333 4H6.66667V5.33333H5.33333V4Z" fill="#76849B" />
        <path d="M9.33333 4H8V5.33333H9.33333V4Z" fill="#76849B" />
        <path
            d="M0 1.33333V12L3.2 9.6C3.43054 9.42643 3.71142 9.3328 4 9.33333H10.6667C11.403 9.33333 12 8.73638 12 8V1.33333C12 0.596954 11.403 0 10.6667 0H1.33333C0.596954 0 0 0.596954 0 1.33333ZM1.33333 9.33333V1.33333H10.6667V8H3.556C3.26738 7.99922 2.98643 8.09287 2.756 8.26667L1.33333 9.33333Z"
            fill="#76849B"
        />
    </svg>
)

export default Message
