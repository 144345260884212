import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.6667 17.3334H2.33341C1.88681 17.3489 1.45381 17.1783 1.13782 16.8623C0.821831 16.5463 0.651225 16.1133 0.666745 15.6667V7.33341C0.651225 6.88681 0.821831 6.45381 1.13782 6.13782C1.45381 5.82183 1.88681 5.65123 2.33341 5.66674H5.66674V2.33341C5.65123 1.88681 5.82183 1.45381 6.13782 1.13782C6.45381 0.821831 6.88681 0.651225 7.33341 0.666745H15.6667C16.1133 0.651225 16.5463 0.821831 16.8623 1.13782C17.1783 1.45381 17.3489 1.88681 17.3334 2.33341V10.6667C17.3487 11.1133 17.178 11.5461 16.8621 11.8621C16.5461 12.178 16.1133 12.3487 15.6667 12.3334H12.3334V15.6667C12.3487 16.1133 12.178 16.5461 11.8621 16.8621C11.5461 17.178 11.1133 17.3487 10.6667 17.3334ZM2.33341 7.33341V15.6667H10.6667V12.3334H7.33341C6.88688 12.3487 6.45402 12.178 6.13809 11.8621C5.82216 11.5461 5.65148 11.1133 5.66674 10.6667V7.33341H2.33341ZM7.33341 2.33341V10.6667H15.6667V2.33341H7.33341Z"
            fill="#8F8F8F"
        />
    </svg>
)
