import { RootStore } from '@/_helpers/StoreType'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useSearch } from './useSearch'

export const useCount = () => {
    const [state, setState] = useState<{
        loading: boolean
        count: number | null
        error: boolean
    }>({
        loading: false,
        count: null,
        error: false,
    })

    const search = useSelector((store: RootStore) => store.search)

    const { checkCount } = useSearch()

    useEffect(() => {
        if (search) {
            let active = true
            setState((prev) => ({ ...prev, loading: true }))
            checkCount()
                .then(({ count }) => {
                    if (!active) return
                    setState({
                        loading: false,
                        count,
                        error: false,
                    })
                })
                .catch(() => {
                    if (!active) return
                    setState({
                        loading: false,
                        count: null,
                        error: true,
                    })
                })
            return () => {
                active = false
            }
        }
    }, [search, checkCount])

    return state
}
