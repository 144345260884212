import Icons from '@/components/Icons'
import React, { FC } from 'react'

import cn from './SelectorFilter.module.scss'

interface CheckboxProps {
    isChecked: boolean
}

export const Checkbox: FC<CheckboxProps> = ({ isChecked }) => {
    return (
        <div className={cn['checkbox-topics']}>
            {isChecked && <Icons name="checkbox-topics--checked" />}
            {!isChecked && <Icons name="checkbox-topics" />}
        </div>
    )
}
