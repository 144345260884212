import Icons from '@/components/Icons'
import React from 'react'
import { RangeFilter, RangeValueType } from '@/pages/SearchPage/SearchAdapter/filters/RangeFilter'

const getGqlFilter = (range: RangeValueType) => {
    if (!range.from && !range.to) return ''
    return `saves: { left_number:  ${range.from}, right_number: ${range.to}}`
}

export const getSavesFilter = () => {
    return new RangeFilter({
        platform: 'infl_tiktok',
        title: 'filters.saves.label',
        helper: 'filters.saves.text',
        icon: <Icons name="filter--saves" />,
        space: 1,
        id: 'saves_tiktok',
        mainOptions: [
            { labelValue: '', label: '100-1K', valueFrom: 100, valueTo: 1000 },
            { labelValue: '', label: '1K-5K', valueFrom: 1000, valueTo: 5000 },
            { labelValue: '', label: '5K-50K', valueFrom: 5000, valueTo: 50_000 },
            { labelValue: '', label: '50K-100K', valueFrom: 50_000, valueTo: 100_000 },
            { labelValue: '', label: '100K+', valueFrom: 100_000, valueTo: 1_000_000 },
        ],
        options: {
            from: [
                { value: 100, label: '100' },
                { value: 1000, label: '1K' },
                { value: 5000, label: '5K' },
                { value: 50_000, label: '50K' },
                { value: 100_000, label: '100K' },
            ],
            to: [
                { value: 1000, label: '1K' },
                { value: 5000, label: '5K' },
                { value: 50_000, label: '50K' },
                { value: 100_000, label: '100K' },
                { value: 1_000_000, label: '1M' },
            ],
        },
        beforeSend: (data) => {
            return {
                name: 'metrics.views',
                type: 'range',
                value: data,
                gqlFilter: getGqlFilter(data),
            }
        },
    })
}
