import React, { FC, CSSProperties, useCallback, useRef, useEffect } from 'react'

import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'

import cn from './Comment.module.scss'

interface IComment {
    close: VoidFunction
    isOpen: boolean
    open: VoidFunction
    value?: string
    setValue: Function
    style?: CSSProperties
    submit: Function
}
export const Comment: FC<IComment> = (props) => {
    const ref = useRef<HTMLDivElement>(null)
    const submitComment = useCallback(() => {
        props.submit(props.value)
        props.close()
    }, [props])

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                props.close()
            }
        }
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [props])

    if (!props.isOpen && (!props.value || props.value?.length === 0)) return <></>

    if (!props.isOpen && props.value && props.value.length > 0) {
        return (
            <Flex className={cn['comment--root']} style={props.style} onClick={props.open}>
                <Icons name="message-circle" style={{ minWidth: '16px' }} />
                <p className={cn.comment}>{props.value}</p>
            </Flex>
        )
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            submitComment()
        }
    }

    return (
        <Flex className={cn.root} style={props.style} ref={ref}>
            <input
                value={props.value}
                placeholder={'Enter'}
                type={'text'}
                onChange={(e) => props.setValue(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            <Flex>
                <div className={cn.doneButton} onClick={submitComment}>
                    <Icons name={'done'} />
                </div>
                <div className={cn.crossButton} onClick={props.close}>
                    <Icons name={'close--small'} />
                </div>
            </Flex>
        </Flex>
    )
}

interface INewComment {
    handleOpen: Function
}
export const NewComment: FC<INewComment> = ({ handleOpen }) => {
    return (
        <>
            <Flex onClick={(e: any) => handleOpen(e)} align="center" margin="0 0 0 6px">
                <span style={{ color: '#929EB0', fontSize: '12px', lineHeight: '16px' }}>+</span>
                <Icons name="message-circle" />
            </Flex>
        </>
    )
}
