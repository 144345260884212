import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { FilterCategory } from '../../../filters/FilterCategory'
import { getFollowersFilter } from './FilterConfigs/Followers'
import { getKeywordsFilter } from './FilterConfigs/Keywords'
import { getAvgViewsFilter } from './FilterConfigs/views'
import { getInterestsFilter } from './FilterConfigs/Interests'
import { getERFilter } from './FilterConfigs/Er'
import { getLastPostFilter } from './FilterConfigs/LastPublDate'
import { getLanguagesFilter } from './FilterConfigs/language'
import { getERFilter as erFollowers } from './FilterConfigs/Er_foll'
import { getCommentsFilter } from './FilterConfigs/comments'
import { getAvgReactFilter } from './FilterConfigs/avgViews'
import { getPostsFilter } from './FilterConfigs/Posts_amount'

// const InterestsFilterObject = getInterestsFilter()
const keyWordsFilter = getKeywordsFilter()

export const getMainFilters = (fetcher: Fetcher) => {
    return {
        main: new FilterCategory('filters.categories.main', {
            interests: getInterestsFilter(fetcher, true),
            // language: getLanguagesFilter(fetcher),
            views: getAvgViewsFilter(),
            followers: getFollowersFilter(),
            keywords: keyWordsFilter,
        }),
    }
}

export const getFilters = (fetcher: Fetcher) => {
    return {
        channel_metrics: new FilterCategory('filters.categories.channel', {
            interests: getInterestsFilter(fetcher),
            language: getLanguagesFilter(fetcher),
            views: getAvgViewsFilter(),
            followers: getFollowersFilter(),
            keywords: keyWordsFilter,
        }),
        content_metrics: new FilterCategory('filters.categories.content', {
            er: getERFilter(),
            er_foll: erFollowers(),
            avg_reactions: getAvgReactFilter(),
            avg_comments: getCommentsFilter(),
            posts_m: getPostsFilter(),
            las_publ: getLastPostFilter(),
        }),
    }
}
