import Flex from '@/_yc/Flex'
import React, { FC } from 'react'
import cn from './DataChange.module.scss'

import { Tooltip } from '@yoloker/ui-collection'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

export interface IDataChange {
    changeValue: number
    isEmptyData: boolean
}

const DataChange: FC<IDataChange> = ({ changeValue, isEmptyData }) => {
    const t = useSpaceTranslation('report_layout.graph.tooltip')
    const sign = changeValue >= 0 ? '+' : ''
    const percentValue = `${sign}${(changeValue * 100).toFixed(2)}%`
    const negativeClass = changeValue < 0 ? cn.negative : ''
    const emptyClass = isEmptyData ? cn['blur--active'] : cn['blur--default']

    return (
        <Flex align={'center'}>
            <div className={`${cn.dataChange} ${negativeClass} ${emptyClass}`}>{percentValue}</div>

            <Tooltip text={t('data_change')}></Tooltip>
        </Flex>
    )
}

export default DataChange
