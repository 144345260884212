import { createAction } from '@reduxjs/toolkit'
import { MessageData } from '../Messenger/Chat/types'
import { YolocoChatMessage } from '../Messenger/Chat/YolocoChat/getTutorial'
import { AdvertisingCampaigns, CampaignStatus } from '../Service/Campaigns'
import { Chat, MessageFromBackend } from '../Service/Chats'
import { TemplateResponse } from '../Service/Templates'

export const setTutorialMessages = createAction<YolocoChatMessage[]>('@Outreach/SetTutorialMessages')
export const getTutorialMessages = createAction('@Outreach/GetTutorialMessages')

export const updateAdvertisingCampaigns = createAction<AdvertisingCampaigns>('@Outreach/UpdateCampaigns')

export const updateChats = createAction<Chat[]>('@Outreach/UpdateChats')
export const addChats = createAction<Chat[]>('@Outreach/AddChats')
export const updateSearchChats = createAction<number[]>('@Outreach/UpdateSearchChats')

export const addUnread = createAction<number>('@Outreach/UpdateUnread')
export const clearUnread = createAction<number>('@Outreach/ClearUnread')

export const updateLastMessage =
    createAction<{ chatId: number; message: string; time: string }>(`@Outreach/UpdateLastMessage`)

export const updateMessages = createAction<MessageFromBackend[]>('@Outreach/UpdateMessages')
export const addMessage = createAction<MessageData>('@Outreach/AddMessage')

export const updateTemplates = createAction<TemplateResponse>('@Outreach/UpdateTemplates')
export const setCurrentTemplate = createAction<number>('@Outreach/SetCurrentTemplate')

export const setCurrentChat = createAction<number>('@Outreach/SetCurrentChat')
export const setCurrentCampaign = createAction<string>('@Outreach/SetCurrentCampaign')

export const addToSelectedCampaigns = createAction<string>('@Outreach/AddToSelectedCampaigns')
export const addToSelectedCampaignsAr = createAction<string[]>('@Outreach/AddToSelectedCampaignsAr')
export const removeFromSelectedCampaigns = createAction<string>('@Outreach/RemoveFromSelectedCampaigns')

export const clearSelectedCampaigns = createAction('@Outreach/ClearSelectedCampaigns')

export const setCampaignsFilter = createAction<CampaignStatus | 'all'>('@Outreach/SetCampaignsFilter')

export const addExcludedBlogger = createAction<string>('@Outreach/SetExcludedBloggers')
export const addExcludedChannel = createAction<string>('@Outreach/SetExcludedChannels')

export const clearExcluded = createAction('@Outreach/ClearExcluded')
