import { Currency, MediaPlanOrder } from '../utils/types'
import React, { FC, useMemo } from 'react'
import cn from './TopStats.module.scss'
import { Item } from './Item/Item'
import { shortNum } from '@/_helpers/_graphs/_tech'
import { ChooseCurrencyItem } from './Item/ChooseCurrencyItem'

interface TopStatsProps {
    mediaPlan: MediaPlanOrder
    isPlanLoading: boolean
    currency: Currency
}

export const TopStats: FC<TopStatsProps> = ({ mediaPlan, isPlanLoading, currency }) => {
    // const totalReach = mediaPlan.data?.total_reach
    const totalReach = useMemo(() => {
        const bloggers = mediaPlan.bloggers
        if (!bloggers) return null

        let reach = 0
        for (let blogger of bloggers) {
            if (blogger.totalReach) {
                reach += blogger.totalReach
            }
        }

        return reach
    }, [mediaPlan])

    const totalBudget = useMemo(() => {
        const bloggers = mediaPlan.bloggers
        if (!bloggers) return null

        let budget = 0
        for (let blogger of bloggers) {
            if (blogger.totalCost) {
                budget += blogger.totalCost
            }
        }

        return budget
    }, [mediaPlan])

    const totalLikes = useMemo(() => {
        const likes = mediaPlan.data?.metrics?.likes
        if (!likes) return ''

        return shortNum(likes)
    }, [mediaPlan])

    const totalComments = useMemo(() => {
        const comments = mediaPlan.data?.metrics?.comments
        if (!comments) return ''

        return shortNum(comments)
    }, [mediaPlan])

    const totalFollowers = useMemo(() => {
        const followers = mediaPlan.data?.metrics?.followers
        if (!followers) return ''

        return shortNum(followers)
    }, [mediaPlan])

    const medianER = useMemo(() => {
        const er = mediaPlan.data?.metrics?.er_median
        if (!er) return ''

        return `${(er * 100).toFixed(2)}%`
    }, [mediaPlan])

    const costPerView = useMemo(() => {
        return totalReach && totalBudget ? (totalBudget / totalReach).toFixed(2) : null
    }, [totalBudget, totalReach])

    const totalInfluencers = mediaPlan.data?.metrics?.influencers_count
    return (
        <div className={cn.topStats}>
            {isPlanLoading && (
                <div className={cn.loadingContainer}>
                    <div className={cn.loading}></div>
                    <div className={cn.loading}></div>
                    <div className={cn.loading}></div>
                    <div className={cn.loading}></div>
                    <div className={cn.loading}></div>
                    <div className={cn.loading}></div>
                </div>
            )}

            {!isPlanLoading && (
                <>
                    <Item title="Total reach" value={shortNum(totalReach)} isLoading={isPlanLoading} />
                    <Item
                        title="Total likes"
                        value={totalLikes?.toString()}
                        isLoading={isPlanLoading || totalLikes === ''}
                    />
                    <Item
                        title="Total comments"
                        value={totalComments.toString()}
                        isLoading={isPlanLoading || totalComments === ''}
                    />
                    <Item
                        title="Total followers"
                        value={totalFollowers.toString()}
                        isLoading={isPlanLoading || totalFollowers === ''}
                    />
                    <Item title="Median ER" value={medianER} isLoading={isPlanLoading || medianER === ''} />
                    <ChooseCurrencyItem
                        title="Total budget"
                        value={shortNum(totalBudget)}
                        isLoading={false}
                        currency={currency}
                    />
                    {totalInfluencers !== undefined && totalInfluencers !== null && (
                        <Item title="Total influencers" value={totalInfluencers.toString()} isLoading={isPlanLoading} />
                    )}
                    {costPerView && <Item title="Cost per view" value={costPerView} isLoading={isPlanLoading} />}
                </>
            )}
        </div>
    )
}
