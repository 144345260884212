import React, { FC, memo, useState } from 'react'

import { useSpaceTranslation } from '@/_helpers'
import Icons from '@/components/Icons'
import Flex from '@/_yc/Flex'
import Text from '@/components/Text'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import cn from './NameInput.module.scss'

interface INameInput {
    curName: string | null
    onSubmit: (name: string) => void
}

const NameInput: FC<INameInput> = ({ curName, onSubmit }) => {
    const t = useSpaceTranslation('campaign_manager.campaign.header')
    const [name, setName] = useState<string>(curName || t('name_placeholder'))
    const [state, setState] = useState(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
    }

    const handleClick = () => {
        setState(!state)
    }

    return (
        <div className={cn.nameInput}>
            {!state ? (
                <Flex align="center" onClick={handleClick}>
                    <Text fSize={30} margin="0 6px 0">
                        {' '}
                        {name}
                    </Text>
                    <Icons name={'edit'} className={cn.icon} />
                </Flex>
            ) : (
                <ClickAwayListener onClickAway={() => setState(false)}>
                    <input autoFocus value={name} onChange={handleChange} onBlur={() => onSubmit(name)}></input>
                </ClickAwayListener>
            )}
        </div>
    )
}

export default memo(NameInput)
