import { Flex } from '@/_yc'
import React, { FC, useRef } from 'react'
import TooltipIcon from './Icons/TooltipIcon'
import cn from './Tooltip.module.scss'
import Text from '@/components/Text'
import { useTranslation } from 'react-i18next'
import { renderToStaticMarkup } from 'react-dom/server'
import useOpenClose from '@/_helpers/useOpenClose'
import TooltipIconDisabled from './Icons/TooltipDisabledIcon'
import Popup from '@/_yc/Popup'

interface ITooltip {
    text: string
    isHTML?: boolean
    children?: React.ReactNode
}

const Tooltip: FC<ITooltip> = (props) => {
    const [isOpen, open, close] = useOpenClose()
    const { t } = useTranslation()
    const textAsElem = renderToStaticMarkup(<Text>{t(props.text)}</Text>)
    const renderedText = textAsElem.slice(textAsElem.indexOf('>') + 1, textAsElem.lastIndexOf('<'))
    const icon = useRef<HTMLDivElement>(null)
    const getHTML = () => {
        return { __html: props.text }
    }
    return (
        <>
            {!props?.children ? (
                <Flex onMouseEnter={open} onMouseLeave={close} ref={icon} margin={'0 0 0 6px'}>
                    {isOpen ? <TooltipIcon /> : <TooltipIconDisabled />}
                </Flex>
            ) : (
                <div onMouseEnter={open} onMouseLeave={close} ref={icon}>
                    {props?.children}
                </div>
            )}
            {isOpen && !props.isHTML && <div className={cn.arrow} />}

            <Popup
                isOpen={isOpen}
                onClose={close}
                placement={props.isHTML ? 'bottom' : 'top'}
                anchor={icon.current}
                boundariesElement={'window'}
                style={{ zIndex: 15 }}
            >
                <div className={cn.tooltip}>
                    {!props.isHTML ? (
                        <Text fSize={10} color={'white'}>
                            {renderedText}
                        </Text>
                    ) : (
                        <Text dangerouslySetInnerHTML={getHTML()} color={'white'}></Text>
                    )}
                </div>
            </Popup>
        </>
    )
}

export default Tooltip
