import { authHeader } from '@/_helpers/auth-header.js'
import { APIService } from '@/_helpers/APIService.js'

export default new APIService({
    methods: {
        login: (login, password, recaptchaToken) => ({
            url: '/core/accounts/login/',
            method: 'POST',
            data: { login, password, recaptcha_token: recaptchaToken },
        }),
        logout: () => ({
            url: '/core/accounts/logout/',
            method: 'POST',
            headers: authHeader(),
            data: { revoke_token: false },
            validateStatus: (status) => {
                return (status >= 200 && status < 300) || 401
            },
        }),
        getUserInfo: () => ({
            url: '/core/accounts/profile/',
            method: 'GET',
            headers: authHeader(),
        }),
        getUserDetails: () => ({
            url: '/api/user_details/',
            method: 'GET',
            headers: authHeader(),
        }),
        getSubscriptionPlatforms: () => ({
			headers: authHeader(),
			method: "GET",
			url: '/api/subscriptions/current/'
		}),
    },
})
