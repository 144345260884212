import React, { FC, useMemo } from 'react'
import Text from '@/components/Text'
import { Flex } from '@/_yc'
import cn from './Row.module.scss'
import { MassAddCheckBoxNEW } from '@/components/MassAddToList'
import { getShortString } from '@/_helpers/strings/getShortString'
import { useHistory } from 'react-router-dom'
import { MediaPlanOrder } from '../../utils/types'
import moment from 'moment'
import Avatar from '@/components/Avatar'
import BloggerLink from '@/components/Share/BloggerLink'
import MoreIcon from './MoreIcon.svg'
import Icons from '@/components/Icons'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import { shortStr } from '@/_helpers/_graphs/_tech'

interface RowProps {
    data: MediaPlanOrder
    index: number
}

export const Row: FC<RowProps> = ({ data, index }) => {
    const history = useHistory()
    const onCampaignNameClick = () => {
        history.push(`/media-planning/${data.id}`)
        console.log('click')
    }

    const isInstagram = data.bloggers.some((b) => b.bloggerType === 'instagram')
    const isYoutube = data.bloggers.some((b) => b.bloggerType === 'youtube')
    const profile = useSelector((store: RootStore) => store.authentication?.user?.profile)

    const fullName = useMemo(() => {
        if (!profile) return ''
        if (!profile.first_name || !profile.last_name) return profile.email
        return shortStr(`${profile.first_name} ${profile.last_name}`, 14)
    }, [profile])

    return (
        <div className={cn.row}>
            <MassAddCheckBoxNEW id={data.id.toString()} type={'media-planner-plans'} index={index + 1} />
            <Flex column>
                <Text semibold style={{ lineHeight: '18px', cursor: 'pointer' }} onClick={onCampaignNameClick}>
                    {getShortString(data.name, 24)}
                </Text>
                <Text fSize={12} color="gray-3" margin="4px 0 0 0" style={{ lineHeight: '16px' }}>
                    {data.bloggers.length} influencers
                </Text>
            </Flex>

            <Text>{fullName}</Text>
            <Text>{moment(data.modifiedDate).format('DD.MM.YYYY')}</Text>
            <div className={cn.social}>
                {isInstagram && <Icons name="media-plan-instagram" />}
                {isYoutube && <Icons name="media-plan-youtube" />}
            </div>
            {data?.bloggers?.length > 0 && (
                <div className={cn.avatars}>
                    {data.bloggers.slice(0, 12).map((b) => {
                        return (
                            <BloggerLink id={b.externalId} newTab platform={b.bloggerType}>
                                <div className={cn.avatarContainer}>
                                    <Avatar
                                        alt="AVA"
                                        src={b.data?.info?.avatar}
                                        aria-label="Аватарка"
                                        className={cn.avatar}
                                        loadingAvatarWidth="34"
                                    />
                                </div>
                            </BloggerLink>
                        )
                    })}

                    {data.bloggers.length > 12 && (
                        <div className={cn.moreIcon}>
                            <img src={MoreIcon} alt="more" />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
