import React, { FC } from 'react'
import cn from './BarChart.module.scss'

export interface IBarChart {
    id: number
    value: number
    color: string
    type: string
    label?: string
    isLoading?: boolean
}

const BarChart: FC<IBarChart> = ({ id, value, color, type, label, isLoading }) => {
    const skeleton = isLoading ? '--skeleton' : ''
    const colorSkeleton = color.toLowerCase() === '#ff659c' ? 'pink' : 'blue'
    const percent = (value * 100).toFixed(1)
    return (
        <div key={id} className={cn['root--' + type]}>
            <div className={cn['head--' + type]}>
                <p className={cn['label--' + type + skeleton]}>{type === 'horizontal' && label}</p>
                <p className={cn['percent--' + type + skeleton]}>{`${percent}%`}</p>
            </div>
            <div className={cn['bar--' + type]}>
                <div
                    className={`${cn['bar--line-' + type + skeleton]} ${
                        cn['bar--line-' + type + skeleton + '--' + colorSkeleton]
                    }`}
                    style={{
                        height: type === 'vertical' ? `${percent}%` : undefined,
                        width: type === 'horizontal' ? `${percent}%` : undefined,
                        backgroundColor: isLoading ? '' : color,
                    }}
                ></div>
            </div>
        </div>
    )
}

export default BarChart
