import React, { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Layout from '@/Layouts/AudienceLayout'
import { DEMO_REPORT, REPORT, TARIFFS } from '@/routs'
import { history } from '@/_helpers'
import { Reports } from '@/_helpers/TabChannel'
import { useQuery } from '@apollo/client'
import { GET_REPORT_DATA } from '@/pages/Reports/ChannelReport/reportQueries'
import { setRawChannelData, setRawTwitchData, setTikTokRawData } from '@/Layouts/ReportLayout/Model/actions'
import { IGetChannelData } from '@/Layouts/ReportLayout/Model/types'
import { push } from '@/components/Notifier/PushNotification'
import LegacyNotification from '@/components/Notifier/MessageTypes/LegacyNotification'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { GET_VK_REPORT } from '@/pages/Reports/VkReport/queries'
import useAccountType from '@/pages/Reports/VkReport/helpers/useAccountType'
import { VkAccountType } from '@/pages/SearchPage/SearchAdapter/bloggers/vk/utils/types'

import { isNotEnoughReportsError } from './utils'
import { Platform } from '../types'
import { GET_TWITCH_REPORT } from '../TwitchReport/queries'
import { TwitchReportResponseData } from '../TwitchReport/types'
import { RootStore } from '@/_helpers/StoreType'
import { GET_TG_REPORT } from '../TgReport/queries'
import { GET_TIKTOK_REPORT } from '../TikTokReport/queries'
import { ITiktokReportResponse } from '../TikTokReport/types'

export type OpenTypes = 'demo' | 'report'

export interface IOpenReportPage
    extends RouteComponentProps<{
        external_id: string
        type: OpenTypes | 'promo' | string
        platform: Platform
    }> {
    GetReportId: (external_id: number | string) => Promise<{
        noReports: boolean
        report_id?: number
    }>
    getSearchParams: () => { [key: string]: string }
    GetReportYT: (external_id: string) => void
}

const redirectToDemo = (id: string) => history.push(`${DEMO_REPORT}/${id}/`)

const OpenReportPage: FC<IOpenReportPage> = (props) => {
    const [isError, setIsError] = useState(false)
    const dispatch = useDispatch()
    const { external_id, type } = props.match.params
    const t = useSpaceTranslation('channel_report')

    const tariff = useSelector((store: RootStore) => {
        return store.authentication.user?.profile?.package
    })

    const { loading, data, error } = useQuery<IGetChannelData>(GET_REPORT_DATA, {
        variables: {
            id: external_id,
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        skip: props.match.params.platform !== Platform.YouTube || !tariff,
    })

    const {
        loading: loadingTwitch,
        data: dataTwitch,
        error: errorTwitch,
    } = useQuery<TwitchReportResponseData>(GET_TWITCH_REPORT, {
        variables: {
            id: external_id,
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        skip: props.match.params.platform !== Platform.Twitch || !tariff,
    })

    const {
        loading: loadingVk,
        data: dataVk,
        error: errorVk,
    } = useQuery<any>(GET_VK_REPORT, {
        variables: {
            id: external_id,
            type: 'user',
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        skip: props.match.params.platform !== Platform.Vk || !tariff,
    })

    const {
        loading: loadingTg,
        data: dataTg,
        error: errorTg,
    } = useQuery<any>(GET_TG_REPORT, {
        variables: {
            id: external_id,
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        skip: props.match.params.platform !== Platform.Tg || !tariff,
    })

    const {
        loading: loadingTikTok,
        data: dataTikTok,
        error: errorTikTok,
    } = useQuery<ITiktokReportResponse>(GET_TIKTOK_REPORT, {
        variables: {
            id: external_id,
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        skip: props.match.params.platform !== Platform.TikTok || !tariff,
    })

    const accountType = useAccountType() as VkAccountType
    useEffect(() => {
        if (tariff === null || tariff === undefined) return

        if (type === 'demo') {
            redirectToDemo(external_id)
            return
        }

        // redirect to tariff if there is no tariff
        if (tariff.length === 0 && type !== 'promo') {
            if (props.match.params.platform === Platform.Instagram) {
                return redirectToDemo(external_id)
            }
            return history.push(TARIFFS)
        }

        const params = props.getSearchParams()

        const fail_link = params.fail_link || null

        if (props.match.params.platform === Platform.YouTube) {
            if (data && !loading && !error) {
                data.getChannel && dispatch(setRawChannelData(data.getChannel))
                history.push(`${REPORT}/${props.match.params.platform}/${external_id}/`)
                return
            }

            if (error) {
                const isNotEnoughReports = isNotEnoughReportsError(error)
                setIsError(true)
                if (isNotEnoughReports) {
                    push(new LegacyNotification(t('not_enough_reports'), 'error'))
                    history.push(TARIFFS)
                }
                return
            }

            return
        }

        if (props.match.params.platform === Platform.Instagram) {
            props
                .GetReportId(+external_id)
                .then(({ noReports, report_id }) => {
                    if (noReports) {
                        if (fail_link) return history.push(fail_link)
                        return redirectToDemo(external_id)
                    }

                    if (report_id) {
                        Reports.post({
                            blogger_id: +external_id,
                            action: 'unlocked',
                        })
                        history.push(`${REPORT}/${props.match.params.platform}/${report_id}/`)
                    }
                })
                .catch(() => {
                    setIsError(true)
                })
            return
        }

        if (props.match.params.platform === Platform.Twitch) {
            if (dataTwitch && !loadingTwitch && !errorTwitch) {
                dataTwitch.getTwitchUser && dispatch(setRawTwitchData(dataTwitch))
                history.push(`${REPORT}/${props.match.params.platform}/${external_id}/`)
                return
            }

            if (error) {
                const isNotEnoughReports = isNotEnoughReportsError(error)
                setIsError(true)
                if (isNotEnoughReports) {
                    push(new LegacyNotification(t('not_enough_reports'), 'error'))
                    history.push(TARIFFS)
                }
                return
            }

            return
        }

        if (props.match.params.platform === Platform.Vk) {
            if (dataVk && !loadingVk && !errorVk) {
                // dataVk.getTwitchUser && dispatch(setRawTwitchData(dataTwitch)) make store vk
                if (accountType === 'user') {
                    history.push(`${REPORT}/${props.match.params.platform}/user/${external_id}/`)
                    return
                }
                if (accountType === 'group') {
                    history.push(`${REPORT}/${props.match.params.platform}/group/${external_id}/`)
                }
                return
            }

            if (error) {
                const isNotEnoughReports = isNotEnoughReportsError(error)
                setIsError(true)
                if (isNotEnoughReports) {
                    push(new LegacyNotification(t('not_enough_reports'), 'error'))
                    history.push(TARIFFS)
                }
                return
            }

            return
        }
        //TikTok
        if (props.match.params.platform === Platform.TikTok) {
            if (dataTikTok && !loadingTikTok && !errorTikTok) {
                if (dataTikTok.getTikTokReport && dataTikTok.getTikTokReport.data)
                    dispatch(setTikTokRawData(dataTikTok.getTikTokReport.data))
                else {
                    setIsError(true)
                    return
                }

                history.push(`${REPORT}/${props.match.params.platform}/${external_id}/`)
                return
            }

            if (error) {
                const isNotEnoughReports = isNotEnoughReportsError(error)
                setIsError(true)
                if (isNotEnoughReports) {
                    push(new LegacyNotification(t('not_enough_reports'), 'error'))
                    history.push(TARIFFS)
                }
                return
            }

            return
        }

        if (props.match.params.platform === Platform.Tg) {
            if (dataTg && !loadingTg && !errorTg) {
                // dataTg.getTwitchUser && dispatch(setRawTwitchData(dataTg))
                history.push(`${REPORT}/${props.match.params.platform}/${external_id}/`)
                return
            }

            if (error) {
                const isNotEnoughReports = isNotEnoughReportsError(error)
                setIsError(true)
                if (isNotEnoughReports) {
                    push(new LegacyNotification(t('not_enough_reports'), 'error'))
                    history.push(TARIFFS)
                }
                return
            }

            return
        }

        // eslint-disable-next-line
    }, [
        data,
        loading,
        error,
        dataTwitch,
        loadingTwitch,
        errorTwitch,
        dataVk,
        loadingVk,
        errorVk,
        loadingTg,
        errorTg,
        dataTg,
        loadingTikTok,
        errorTikTok,
        dataTikTok,
        tariff,
    ])

    return <Layout error={isError} loading={!isError} />
}

export default OpenReportPage
