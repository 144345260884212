import React, { CSSProperties, FC, memo, useMemo } from 'react'
import Popup from '@/_yc/Popup'
import cn from './ListPopup.module.scss'

import SorterItem from './SorterItem'
import Scrollbars from 'react-custom-scrollbars'
import Text from '@/components/Text'
import { PopperProps } from '@material-ui/core/Popper'

export interface IListPopup {
    options: Array<{
        label: string
        value: any
        disabled?: boolean
    }>
    onClick: (value: any) => void
    isActiveCheck?: (value: any) => boolean
    onClose: VoidFunction
    isOpen?: boolean
    anchor?: any
    width?: number | string
    popupStyle?: CSSProperties
    disablePreventOverflow?: boolean
    fSize?: number | string
    title?: string
    margin?: string | number
    placement?: PopperProps['placement']
    disablePortal?: boolean
}

const ListPopup: FC<IListPopup> = (props) => {
    const style = useMemo(
        () => ({
            width: props.width,
        }),
        [props.width]
    )

    const popStyle = useMemo(
        () => ({
            margin: props.margin,
            ...props.popupStyle,
        }),
        [props.margin, props.popupStyle]
    )

    return (
        <Popup
            isOpen={!!props.isOpen}
            onClose={props.onClose}
            anchor={props.anchor}
            style={popStyle}
            disablePreventOverflow={props.disablePreventOverflow}
            placement={props.placement}
            disablePortal={props.disablePortal}
        >
            <Scrollbars autoHeight autoHeightMax={200}>
                <ul style={style} className={cn.list}>
                    {props.title && (
                        <Text margin="4px 10px" color="gray-3" semibold fSize={12}>
                            {props.title}
                        </Text>
                    )}
                    {props.options.map((item) => (
                        <SorterItem
                            active={!!props.isActiveCheck && props.isActiveCheck(item.value)}
                            key={item.value}
                            {...item}
                            onClick={props.onClick}
                            fSize={props.fSize}
                        />
                    ))}
                </ul>
            </Scrollbars>
        </Popup>
    )
}

export default memo(ListPopup)
