import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { RootStore } from '@/_helpers/StoreType'
import Flex from '@/_yc/Flex'
import useToken from '@/_helpers/Hooks/useToken'
import notif from '@/components/Notifier/model/actions.js'
import { updateList, delBloggersAndChannels } from '@/pages/Lists/model/actions'
import useOpenClose from '@/_helpers/useOpenClose'
import ApprovePopup from '@/components/ApprovePoup'

import {
    RemoveListINST,
    RemoveListTg,
    RemoveListTwitch,
    RemoveListVk,
    RemoveListYT,
    SetLastClicked,
} from './_model/actions'
import Coolicon from './assets/Coolicon'
import { MassDeleteReq } from './_model/service'

import cn from './MassAddToList.module.scss'

interface IDeleteFromListButton {
    list: {
        ytList: string[]
        instList: string[]
        twitchList: string[]
        vkList: string[]
        tgList: string[]
    }
    activeListId: number
}

const DeleteFromListButton: FC<IDeleteFromListButton> = ({ list, activeListId }) => {
    const { t } = useTranslation()
    // const MassAddToListData = useSelector((store: RootStore) => store.MassAddToList.data)
    const token = useToken()
    const dispatch = useDispatch()
    const [isOpen, open, closePopUp] = useOpenClose(false)

    const listArrINST = list.instList.map((item) => parseInt(item))
    const listArrYT = list.ytList.map((item) => parseInt(item))
    const listArrTwitch = list.twitchList.map((item) => parseInt(item))
    const listArrVk = list.vkList.map((item) => parseInt(item))
    const listArrTg = list.tgList.map((item) => parseInt(item))
    const listsInfo = useSelector((store: RootStore) => store.AudienceInsights.ListsInfo)

    const handleClick = () => {
        dispatch(notif.loading(t('aud_insides.status.loading')))
        if (!token) {
            dispatch(notif.error(t('aud_insides.status.error')))
            return
        }

        Promise.all([
            MassDeleteReq(token, activeListId, listArrINST),
            MassDeleteReq(token, activeListId, listArrYT),
            MassDeleteReq(token, activeListId, listArrTwitch),
            MassDeleteReq(token, activeListId, listArrVk),
            MassDeleteReq(token, activeListId, listArrTg),
        ])
            .then(() => {
                dispatch(notif.success(t('aud_insides.status.success')))
                const currentList = listsInfo.find((item) => item.id === activeListId)
                currentList &&
                    dispatch(
                        updateList({
                            ...currentList,
                            qty: (listArrYT.length + listArrINST.length) * -1,
                            modified_at: new Date().toString(),
                        })
                    )
                listArrYT.map((item) => dispatch(delBloggersAndChannels(item)))
                listArrINST.map((item) => dispatch(delBloggersAndChannels(item)))
                listArrTwitch.map((item) => dispatch(delBloggersAndChannels(item)))
                listArrTg.map((item) => dispatch(delBloggersAndChannels(item)))
                listArrVk.map((item) => dispatch(delBloggersAndChannels(item)))

                dispatch(RemoveListTwitch())
                dispatch(RemoveListINST())
                dispatch(RemoveListYT())
                dispatch(RemoveListTg())
                dispatch(RemoveListVk())
                dispatch(SetLastClicked(undefined))
            })
            .catch(() => {
                dispatch(notif.error(t('aud_insides.status.error')))
            })
    }
    return (
        <>
            <Flex
                onClick={open}
                className={cn['delete-button']}
                align="center"
                content="space-around"
                margin="0 0 0 24px"
            >
                <Coolicon />
                <p className={cn['delete-button--text']}>{t('aud_insides.lists.deleteFromList')}</p>
            </Flex>
            <ApprovePopup
                isOpen={isOpen}
                close={closePopUp}
                approveFunction={handleClick}
                title={t('aud_insides.lists.approve.title')}
                subTitle={t('aud_insides.lists.approve.text')}
                approve={t('aud_insides.lists.approve.approve')}
                cancel={t('aud_insides.lists.approve.cancel')}
            />
        </>
    )
}
export default DeleteFromListButton
