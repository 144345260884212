import { useState, useEffect, useCallback } from 'react'

import TabNotification from '@/components/Notifier/TabNotification'
import { PullCount } from '@/Services/Updates'

const Tab = new TabNotification()

export default (token?: string) => {
    const [hasNotifications, setHasNotifications] = useState<boolean>(Tab.count > 0)

    useEffect(() => {
        const sub = Tab.subscribe((count) => {
            if (count > 0) {
                setHasNotifications(true)
            } else {
                setHasNotifications(false)
            }
        })

        return () => sub.unsubscribe()
    }, [])

    useEffect(() => {
        let mounted = true
        if (token)
            PullCount(token)
                .then(({ count }) => {
                    if (!mounted) return
                    if (count > 0) {
                        setHasNotifications(true)
                    }
                })
                .catch(console.error)

        return () => {
            mounted = false
        }
    }, [token])

    const clean = useCallback(() => {
        setHasNotifications(false)
        Tab.count = 0
    }, [])

    return {
        hasNotifications,
        clean,
    }
}
