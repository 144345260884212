import React from 'react'
import Step from '@/pages/TargetAudience/Views/components/Step/Step'
import Sidebar from './components/Sidebar/Sidebar'
import styles from './step-one.module.scss'
import { updateCampaignReq } from '@/pages/TargetAudience/services/services'
import useToken from '@/_helpers/Hooks/useToken'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setCampaignData } from '@/_redux/_reducers/target_audience.reducer'
import { ReactComponent as Settings } from '@/pages/TargetAudience/icons/settings.svg'
import { useTranslation } from 'react-i18next'

const Index = () => {
    const campaignId = parseInt(localStorage.getItem('campaignId') || '1')
    const token = useToken()
    const dispatch = useDispatch()
    const history = useHistory()
    const { t } = useTranslation()

    const handleClick = async (targetType: 'from_us' | 'from_customer') => {
        try {
            const response = await updateCampaignReq(token, campaignId, { target_type: targetType })
            if (response) {
                dispatch(setCampaignData(response))
                history.push('/target/new/two')
            }
        } catch (error) {
            console.error('Ошибка обновления кампании:', error)
        }
    }
    return (
        <div className={styles.container}>
            <Step
                step={`2 ${t('target_audience.of')} 5`}
                title="target_audience.select_targeting_type"
                description={t('target_audience.selectAudienceType')}
                icon={<Settings />}
            />
            <Sidebar handleClick={handleClick} />
        </div>
    )
}

export default Index
