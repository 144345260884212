import React, { SVGAttributes } from 'react'
const BasicPhone = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.7296 13.004H10.7109C8.1498 12.941 5.70576 11.9183 3.86291 10.1387C2.08259 8.2975 1.05937 5.85439 0.996168 3.29402C0.992396 2.93535 1.13322 2.59026 1.38691 2.33669L3.19624 0.528687C3.45658 0.268432 3.87858 0.268432 4.13891 0.528687L6.80558 3.19535C7.06583 3.45569 7.06583 3.87769 6.80558 4.13802L5.74158 5.20002C5.93892 5.9373 6.30243 6.61962 6.80424 7.19469C7.37827 7.69668 8.05972 8.06044 8.79625 8.25802L9.86291 7.19535C10.1232 6.9351 10.5452 6.9351 10.8056 7.19535L13.4722 9.86202C13.7325 10.1224 13.7325 10.5444 13.4722 10.8047L11.6629 12.6134C11.4162 12.8626 11.0803 13.0032 10.7296 13.004ZM3.66624 1.94335H3.66291L2.32958 3.27935C2.38394 5.49179 3.26713 7.60315 4.80425 9.19535C6.39653 10.7319 8.50747 11.615 10.7196 11.67L12.0576 10.3334L10.3296 8.61002L9.46758 9.47135C9.34427 9.59605 9.17628 9.66637 9.00091 9.66669C8.95408 9.66671 8.90738 9.66179 8.86158 9.65202C7.75067 9.40628 6.71917 8.88572 5.86158 8.13802C5.11388 7.28043 4.59332 6.24893 4.34758 5.13802C4.30122 4.9172 4.3697 4.68791 4.52958 4.52869L5.39091 3.66669L3.66624 1.94335Z"
            fill="#76849B"
        />
    </svg>
)

export default BasicPhone
