import { createReducer } from '@reduxjs/toolkit'
import {
    setGraphOptions,
    setGraphTimespan,
    setGraphType,
    setId,
    setInfo,
    setVideosFilter,
    setVideosTimespan,
    setVideosFilterViews,
    setRawChannelData,
    setRawVideosData,
    setRawTwitchData,
    setVkRawData,
    setTgRawData,
    setTikTokRawData,
} from './actions'
import { IReport } from './types'

const defaultState: IReport = {
    rawChannelData: {
        getChannel: undefined,
    },
    rawVideosData: {
        getVideos: undefined,
    },
    rawTwitchData: {
        getTwitchUser: undefined,
    },
    rawVkData: {
        getVkReport: undefined,
    },
    rawTgData: {
        getTelegraReport: undefined,
    },
    rawTikTokData: undefined,
    info: {
        id: '',
        lang_id: '',
        avatar: '',
        title: '',
        metrics: [{ name: '', value: 0 }],
        average_video_watch: 0,
        subscriber_count: 0,
        er_followers: 0,
        er_views: 0,
        engagement: 0,
        country: '',
        topics: [],
    },
    graph: {
        current: [],
        type: 'subscribers',
        timespan: '6months',
        loading: true,
    },
    videos: {
        filter: 'timespan',
        timespan: 'month',
        viewsFilter: 'most_viewed',
    },
}

const Report = createReducer<IReport>(defaultState, (builder) => {
    builder
        .addCase(setInfo, (state, { payload }) => {
            if (payload)
                return {
                    ...state,
                    info: payload,
                }
        })
        .addCase(setGraphOptions, (state, { payload }) => ({
            ...state,
            graph: {
                ...state.graph,
                current: payload,
            },
        }))
        .addCase(setGraphType, (state, { payload }) => ({
            ...state,
            graph: {
                ...state.graph,
                type: payload,
            },
        }))
        .addCase(setGraphTimespan, (state, { payload }) => ({
            ...state,
            graph: {
                ...state.graph,
                timespan: payload,
            },
        }))
        .addCase(setId, (state, { payload }) => ({
            ...state,
            info: {
                ...state.info,
                id: payload,
            },
        }))
        .addCase(setVideosFilter, (state, { payload }) => ({
            ...state,
            videos: {
                ...state.videos,
                filter: payload,
            },
        }))
        .addCase(setVideosFilterViews, (state, { payload }) => ({
            ...state,
            videos: {
                ...state.videos,
                viewsFilter: payload,
            },
        }))
        .addCase(setVideosTimespan, (state, { payload }) => ({
            ...state,
            videos: {
                ...state.videos,
                timespan: payload,
            },
        }))
        .addCase(setRawChannelData, (state, { payload }) => ({
            ...state,
            rawChannelData: {
                getChannel: payload,
            },
        }))
        .addCase(setRawVideosData, (state, { payload }) => ({
            ...state,
            rawVideosData: {
                getVideos: payload,
            },
        }))
        .addCase(setRawTwitchData, (state, { payload }) => {
            return {
                ...state,
                rawTwitchData: {
                    getTwitchUser: payload.getTwitchUser,
                },
            }
        })
        .addCase(setVkRawData, (state, { payload }) => {
            return {
                ...state,
                rawVkData: {
                    getVkReport: payload.getVkReport,
                },
            }
        })
        .addCase(setTgRawData, (state, { payload }) => {
            return {
                ...state,
                rawTgData: {
                    getTelegraReport: payload.getTelegraReport,
                },
            }
        })
        .addCase(setTikTokRawData, (state, { payload }) => {
            return {
                ...state,
                rawTikTokData: payload,
            }
        })
})

export default Report
