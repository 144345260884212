import React, { FC, useEffect, useRef, useState } from 'react'
import cn from './step-two.module.scss'
import { Flex } from '@/_yc'
import Targeting from '@/pages/TargetAudience/Views/components/Targeting'
import Text from '@/components/Text'
import { ReactComponent as MagicIcon } from '../../icons/magic.svg'
import { ReactComponent as ArrowIcon } from '../../icons/short_left.svg'
import { Link, useHistory } from 'react-router-dom'
import Button from '@/components/Button'
import { useTranslation } from 'react-i18next'
import ReportNameModal from '@/pages/TargetAudience/components/ReportNameModal'
import { useDispatch, useSelector } from 'react-redux'
import { updateCampaignReq } from '@/pages/TargetAudience/services/services'
import useToken from '@/_helpers/Hooks/useToken'
import { setCampaignData } from '@/_redux/_reducers/target_audience.reducer'
import { RootStore } from '@/_helpers/StoreType'

const options = [
    {
        title: 'target_audience.gender',
        values: [
            { label: 'target_audience.male', value: 'male' },
            { label: 'target_audience.female', value: 'female' },
        ],
    },
    {
        title: 'target_audience.age',
        values: [
            { label: '25-29', value: '25-29' },
            { label: '30-34', value: '30-34' },
            { label: '35-39', value: '35-39' },
            { label: '40-44', value: '40-44' },
        ],
    },
    {
        title: 'target_audience.interests',
        values: [
            { label: 'target_audience.sports', value: 'sports' },
            { label: 'target_audience.health', value: 'health' },
            { label: 'target_audience.fitness_sport', value: 'fitness_sport' },
            { label: 'target_audience.sportive_clubs', value: 'sportive_clubs' },
        ],
    },
]

const Index: FC = () => {
    const [value, setValue] = useState('')
    const [showModal, setShowModal] = useState(false)
    const name = useSelector<RootStore, string>((state) => state.targetAudience.campaign?.name)
    const prompt = useSelector<RootStore, string>((state) => state.targetAudience.campaign?.description)

    const targetType = useSelector<RootStore, 'from_customer' | 'from_us'>(
        (state) => state.targetAudience.campaign?.target_type
    )

    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const token = useToken()
    const history = useHistory()

    const campaignId = parseInt(localStorage.getItem('campaignId') || '1')

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(event.target.value)
    }

    const handleFetchTargets = async (name: string) => {
        try {
            const campaign = await updateCampaignReq(token, campaignId, { name, description: value })

            if (campaign) {
                dispatch(setCampaignData(campaign))
                setShowModal(false)
                history.push('/target/new/five', { gpt: true })
            }
        } catch (error) {
            console.error(t('target_audience.error_fetching_targets'), error)
        }
    }

    useEffect(() => {
        const textarea = textareaRef.current
        if (textarea) {
            textarea.style.height = value ? `${textarea.scrollHeight}px` : '40px'
        }
    }, [value])

    useEffect(() => {
        if (prompt) {
            setValue(prompt)
        }
    }, [prompt])

    const changeHandler = () => {
        !name && setShowModal((prev) => !prev)
        if (!name) {
            setShowModal(true)
        } else {
            handleFetchTargets(name)
        }
    }

    return (
        <Flex column className={cn['root']}>
            <Flex column className={cn['step-container']}>
                <Flex margin="0 0 24px 0" className={cn['header']} content="space-between" align="center">
                    <Targeting targetingType={targetType} />
                    <Flex>
                        <span className={cn['step']}>
                            {t('target_audience.step')} 2 {t('target_audience.of')} 3
                        </span>
                    </Flex>
                </Flex>
                <Flex className={cn['content']}>
                    <Flex align="center" content="center" className={cn['ai-block']}>
                        <Flex style={{ maxWidth: '500px' }} column content="center" align="center">
                            <Flex className={cn['icon']}>AI</Flex>
                            <Flex style={{ padding: '0 50px 24px 50px' }}>
                                <Text fSize="12px" color="gray-new-4">
                                    {t('target_audience.ai_help_text')}
                                </Text>
                            </Flex>
                            <Flex className={cn['field-wrapper']}>
                                <textarea
                                    ref={textareaRef}
                                    onChange={handleChange}
                                    className={cn['textarea']}
                                    value={value}
                                />
                                <MagicIcon className={cn['magic-icon']} />
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex column className={cn['description-block']}>
                        <Flex className={cn['description']} column>
                            <Text component="h3" fSize="14px" semibold color="gray-new-4">
                                {t('target_audience.describe_example')}
                            </Text>
                            <Text fSize="12px" color="gray-new-4">
                                {t('target_audience.example_text')}
                            </Text>
                        </Flex>
                        <Flex>
                            <Text margin="0 0 16px 0" fSize="12px" semibold color="gray-new-4">
                                {t('target_audience.llm_suggestion')}
                            </Text>
                        </Flex>
                        <Flex column className={cn['filters-block']}>
                            {options.map((option) => (
                                <Flex className={cn['filter']} align="start" content="space-between" key={option.title}>
                                    <Text fSize="12px" semibold color="gray-new-4">
                                        {t(option.title)}
                                    </Text>
                                    <Flex content="end" className={cn['chip-container']}>
                                        {option.values.map(({ label }) => (
                                            <Flex className={cn['filter-item']} key={label}>
                                                {t(label)}
                                            </Flex>
                                        ))}
                                    </Flex>
                                </Flex>
                            ))}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex className={cn['footer']} content="space-between" align="center">
                <Link to="/target/new/one">
                    <Flex align="center">
                        <ArrowIcon className={cn['arrow-icon']} style={{ marginRight: '6px' }} />
                        <Text fSize="14px" color="violet-1">
                            {t('target_audience.back')}
                        </Text>
                    </Flex>
                </Link>
                <Flex align="center">
                    <Link to="/target/new/three">
                        <Text margin="0 24px 0 0" fSize="14px" color="violet-1">
                            {t('target_audience.skip_step')}
                        </Text>
                    </Link>
                    <Button onClick={changeHandler} className={cn['btn']} disabled={true}>
                        {t('target_audience.check')}
                    </Button>
                </Flex>
            </Flex>
            <ReportNameModal isVisible={showModal} onClose={() => setShowModal(false)} onSubmit={handleFetchTargets} />
        </Flex>
    )
}

export default Index
