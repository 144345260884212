import { gql } from '@apollo/client'
import Service from '@/_helpers/Service'
import { ICampaign } from '../types'

export const GET_CAMPAIGN = gql`
    query CampaignOrderById($id: Int) {
        campaignOrderById(orderId: $id) {
            name
            campaignPosts {
                price
                reach
                post {
                    bloggerId
                    shortCode
                    status
                    isExists
                    data
                    bloggerData
                }
            }
            status
            id
            modifiedDate
            data
            bloggers {
                postsCount
                postsActualCount
                storiesCount
                postsReach
                storiesReach
                postCost
                storiesCost
                totalCost
                externalId
                comments
                data
            }
        }
    }
`

const CampaignAdPosts = new Service<any>({
    baseUrl: process.env.REACT_APP_CAMPAIGN_MANAGER,
    method: 'GET',
    url: '',
})

export const GetCampaignAdPostsReq = (token: string, campaignId: string) =>
    CampaignAdPosts.call(
        {
            url: `/api/campaigns/${campaignId}/ad-posts/`,
        },
        token
    )

const GetCampaign = new Service<ICampaign>({
    baseUrl: process.env.REACT_APP_CAMPAIGN_MANAGER,
    method: 'GET',
    url: '',
})
export const GetCampaignReq = (token: string, id: string) =>
    GetCampaign.call(
        {
            url: `/api/campaigns/${id}/`,
        },
        token
    )

const GetCampaignsRequests = new Service<any>({
    baseUrl: process.env.REACT_APP_CAMPAIGN_MANAGER,
    method: 'GET',
    url: '',
})
export const GetCampaignsRequestsReq = (token: string, campaign_id: string) =>
    GetCampaignsRequests.call(
        {
            url: `/api/campaigns/${campaign_id}/requests/`,
        },
        token
    )

const PostChangeCampaign = new Service<any>({
    baseUrl: process.env.REACT_APP_CAMPAIGN_MANAGER,
    method: 'PATCH',
    url: '',
})
export const PostChangeCampaignReq = (token: string, campaign_id: string, value: boolean) =>
    PostChangeCampaign.call(
        {
            url: `/api/campaigns/${campaign_id}/`,
            data: {
                is_active: value,
            },
        },
        token
    )

const PostChangeCampaignRequest = new Service<any>({
    baseUrl: process.env.REACT_APP_CAMPAIGN_MANAGER,
    method: 'PATCH',
    url: '',
})
export const PostChangeCampaignRequestReq = (
    token: string,
    campaign_id: string,
    service_type: string,
    request_id: number,
    data: any
) =>
    PostChangeCampaignRequest.call(
        {
            url: `/api/campaigns/${campaign_id}/requests/${service_type}/${request_id}`,
            data: data,
        },
        token
    )
