import React, { ComponentType, PureComponent } from 'react';
import { isRootDomain } from '@/_helpers/DomainPortal';

export interface IRequiredProps {
    CheckExternal: () => Promise<{ token: string }>
    login: (token: string) => void
}

export interface ISameProps {
    authentication: {
        loggedIn: boolean
    }
}

export default <Props extends ISameProps>() => (Component: ComponentType<Props>) => 
class withCrossDomainAuth extends PureComponent<Props & IRequiredProps, {}> {

    componentDidMount(){
        if (isRootDomain || this.props.authentication.loggedIn) return
        this.props.CheckExternal()
            .then(({ token }) => this.props.login(token))
            .catch(console.error)
    }

    render(){

        const { CheckExternal, login, ...rest }: IRequiredProps = this.props;

        return <Component {...rest as Props} />
    }
}