import React, { PureComponent } from 'react';
import { withWidth } from '@material-ui/core'
import { StandartPopper } from './StandartPopper.js'
import Arrow from './Arrow.js'

const arr = { margin: "14px 0" }

const mtop = { marginTop: 50 }

class PopperWithArrow extends PureComponent {
	constructor(props){
		super(props);
		this.state = {
			ref: null
		}
		this.handleRef = this.handleRef.bind(this)
	}
	handleRef(ref){
		this.setState({ ref })
	}
	render(){
		const { content, width, ...props } = this.props;
		if (width === "xs") 
			return <StandartPopper style = {mtop} {...this.props}/>
		
		return (
			<StandartPopper 
				style = {arr} 
				content = {
					<div>
						<Arrow ref = {this.handleRef}/>
						{content}
					</div>
				}
				arrowRef = {this.state.ref}
				{...props}
			/>
		)
	}
}

PopperWithArrow = withWidth("xs")(PopperWithArrow);

export { PopperWithArrow as default } 