let globalCommerce: null | EcommerceItem[] = null;

export const init = (name: string): void => {
    globalCommerce = (global as any)[name] = (global as any)[name] || [];
}

type actionCreator = (item: IItem) => void;

interface Action {
    actionField?: {
        id: string
        coupon?: string
        goal_id?: number
        revenue?: number
    },
    products: IItem[]
}

interface EcommerceItem {
    ecommerce: {
        currencyCode?: string
        detail?: Action
        add?: Action
        remove?: Action
        purchase?: Action
    }
}


interface IItem {
    id: string | number
    name: string
    price?: number
    brand?: string
    category?: string
    coupon?: string
    position?: number
    quantity?: number
    variant?: string
}


export const details: actionCreator = (item: IItem) => {
    globalCommerce && globalCommerce.push({
        ecommerce: {
            detail: {
                products: [item]
            }
        }
    })
}

export const purchase: actionCreator = (item) => {
    globalCommerce && globalCommerce.push({
        ecommerce: {
            purchase: {
                actionField: {
                    id: "BUY#" + item.id
                },
                products: [item]
            }
        }
    })
}