import React, { SVGAttributes } from 'react'
const VkIcon = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="18" height="18" rx="9" fill="#6071FF" stroke="white" stroke-width="2" />
        <path
            d="M13.3641 10.4406C13.1992 10.2306 13.2463 10.1371 13.3641 9.94943C13.3662 9.94729 14.7275 8.05043 14.8678 7.40714L14.8686 7.40671C14.9383 7.17229 14.8686 7 14.5316 7H13.4163C13.1324 7 13.0015 7.14786 12.9314 7.31329C12.9314 7.31329 12.3636 8.68386 11.5603 9.57229C11.301 9.829 11.1812 9.91129 11.0397 9.91129C10.97 9.91129 10.8616 9.829 10.8616 9.59457V7.40671C10.8616 7.12557 10.7821 7 10.5471 7H8.79346C8.61538 7 8.50955 7.13114 8.50955 7.25329C8.50955 7.51986 8.91119 7.58114 8.95284 8.33114V9.95843C8.95284 10.315 8.88867 10.3806 8.74629 10.3806C8.36717 10.3806 7.44702 9.00443 6.90172 7.42943C6.79164 7.12386 6.68411 7.00043 6.39808 7.00043H5.28242C4.96408 7.00043 4.8999 7.14829 4.8999 7.31371C4.8999 7.606 5.27902 9.05929 6.66286 10.9793C7.58515 12.2899 8.88399 13 10.0655 13C10.7757 13 10.8624 12.8423 10.8624 12.571C10.8624 11.3187 10.7983 11.2004 11.154 11.2004C11.3189 11.2004 11.6028 11.2827 12.2658 11.9149C13.0236 12.6644 13.1482 13 13.5723 13H14.6876C15.0055 13 15.1666 12.8423 15.0739 12.5311C14.8618 11.8767 13.4287 10.5306 13.3641 10.4406Z"
            fill="white"
        />
    </svg>
)

export default VkIcon
