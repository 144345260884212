import React, { SVGAttributes } from 'react'
const YTIcon = (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="20" height="20" rx="6" fill="#E04444" />
        <path
            d="M10.0047 14.6746H9.99132C9.94999 14.6746 5.81465 14.6666 4.77132 14.3833C4.1976 14.2288 3.74955 13.7805 3.59532 13.2066C3.40771 12.1482 3.31711 11.0749 3.32465 9.99998C3.31981 8.92332 3.41286 7.84845 3.60265 6.78865C3.76113 6.21432 4.20721 5.76395 4.77999 5.59998C5.79465 5.33331 9.81599 5.33331 9.98665 5.33331H10.0007C10.0427 5.33331 14.1887 5.34131 15.2213 5.62465C15.7938 5.77999 16.241 6.22738 16.396 6.79998C16.5896 7.86234 16.6804 8.94087 16.6673 10.0206C16.672 11.096 16.5787 12.1695 16.3887 13.228C16.2324 13.8011 15.7841 14.2484 15.2107 14.4033C14.1973 14.672 10.1753 14.6746 10.0047 14.6746ZM8.67132 8.00331L8.66799 12.0033L12.1427 10.0033L8.67132 8.00331Z"
            fill="white"
        />
    </svg>
)

export default YTIcon
