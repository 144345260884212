import React, { FC } from 'react'
import PageTitle from '@/components/PageTitle'
import { useTranslation } from 'react-i18next'
import Text from '@/components/Text'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as ArrowIcon } from '@/pages/TargetAudience/icons/short_left.svg'
import { Flex } from '@/_yc'
const Index: FC = () => {
    const { t } = useTranslation()
    const location = useLocation()

    const getLinkForRoute = () => {
        switch (location.pathname) {
            case '/target/new/one':
                return '/target/all'
            default:
                return location.pathname
        }
    }

    const getTextForRoute = () => {
        switch (location.pathname) {
            case '/target/new/one':
                return (
                    <Flex align="center">
                        <ArrowIcon />
                        {t('target_audience.back_to_all_reports')}
                    </Flex>
                )
            default:
                return t('target_audience.description')
        }
    }

    return (
        <div>
            <PageTitle
                title={<Flex id="title">{t('target_audience.target_audience')}</Flex>}
                linkToHelper={''}
                icon="icon-audience"
                iconSize={'25px'}
            >
                <Link to={getLinkForRoute()}>
                    <Text fSize={12} semibold color={'gray-new-3'}>
                        {getTextForRoute()}
                    </Text>
                </Link>
            </PageTitle>
        </div>
    )
}

export default Index
