import { FilterPlatform } from '@/pages/SearchPage/SearchAdapter/filters/BasicFilter'
import { track } from '@amplitude/analytics-browser'
import { T } from '../useSpaceTranslation'
import {
    IG_ADS_EXPLORER_SHOW,
    IG_EXPLORER_SHOW,
    INFL_EXP_KEYWORDS,
    YT_ADS_EXPLORER_SHOW,
    YT_EXPLORER_SHOW,
} from './events'

export interface trackChange {
    t: T
    title: string
    value: any
    platform: FilterPlatform
    translate?: boolean
}

export const trackFilterChange = ({ t, title, value, platform, translate }: trackChange) => {
    track(`${platform}_filters`, {
        filter: t(title, { lng: 'en' }),
        value: translate ? t(value, { lng: 'en' }) : value,
    })
}

export const trackSearchValue = (platform: string, search: string, searchBy: any) => {
    track(`${INFL_EXP_KEYWORDS}_${platform === 'Instagram' ? 'ig' : 'yt'}`, {
        value: search,
        search_by: searchBy,
    })
}

export const trackShowResultsBtn = (platformTitle: string) => {
    let event = IG_EXPLORER_SHOW
    switch (platformTitle) {
        case 'YouTube':
            event = YT_EXPLORER_SHOW
            break
        case 'AdsExplorerInstagram':
            event = IG_ADS_EXPLORER_SHOW
            break
        case 'AdsExplorerYouTube':
            event = YT_ADS_EXPLORER_SHOW
            break
        case 'Instagram':
            event = IG_EXPLORER_SHOW
            break
    }

    track(event)
}

export const trackOpenReport = (eventName: string) => {
    track(`${eventName}_report`)
}
