import { gql } from '@apollo/client'

export const UPDATE_NAME = gql`
    mutation UpdateName($id: Int!, $name: String) {
        updateCampaignOrder(name: $name, orderId: $id) {
            campaignOrder {
                id
                name
            }
        }
    }
`
