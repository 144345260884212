import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { LockIcon } from '../LockIcon/LockIcon'

import cn from './LockButton.module.scss'

export default ({ 
    tariff, 
    light, 
    text, 
    locked = true,
    tooltip,
    ...props 
}) => {
    const { t } = useTranslation()

    if(!locked) return props.children || (
        <div
            className={light ? cn['lock--light'] : cn['lock--default']}
        >
            {text ? text : t('report.download')}
        </div>
    )

    return (
        <div className={cn.root}>
            <LockIcon
                text={
                    <div>
                        {tooltip ? t('locked.'+tooltip) : t('locked.reason', { tariff })}{' '}
                        {!tooltip && <Link to="/tariffs">{t('locked.link')}</Link>}
                    </div>
                }
            />
            {props.children || (
                <div
                    className={light ? cn['lock--light'] : cn['lock--default']}
                >
                    {text ? text : t('report.download')}
                </div>
            )}
        </div>
    )
}
