import { getNumberWithSpaces } from '@/_helpers/nums/numberWithSpaces'
import { IVideoQuery, IYoutubeVideometric } from '../../Model/types'
import { IVideo, ThumbnailQuality } from './types'

export const constructPreviewImageLink = (videoId: string, quality: ThumbnailQuality) => {
    return `http://img.youtube.com/vi/${videoId}/${quality}.jpg`
}

const getDate = (timeStamp: string) => {
    const date = new Date(timeStamp)
    return date.toISOString().substring(0, 10).replace(/-/g, '.')
}

const getViews = (metric: IYoutubeVideometric) => {
    const views = metric ? getNumberWithSpaces(metric.view_count) : '0'
    return views
}

const preprocessRawVideoData = (data: Array<IVideoQuery>) => {
    const videos: Array<IVideo> = data.map((rawVideoData) => {
        return {
            id: rawVideoData.id,
            title: rawVideoData.title,
            views: getViews(rawVideoData.youtube_videometric),
            imageLink: constructPreviewImageLink(rawVideoData.id, 'mqdefault'),
            date: getDate(rawVideoData.published_at),
        }
    })

    return videos
}

const getVideoLink = (id: string) => {
    return `https://www.youtube.com/watch?v=${id}`
}

const filterVideos = (videoLimit: number, startDate: string, videos: Array<IVideoQuery>) => {
    const dateFilteredVideos = videos.filter((video) => {
        return new Date(video.published_at) >= new Date(startDate)
    })
    return dateFilteredVideos.slice(0, videoLimit)
}
export { preprocessRawVideoData, getVideoLink, filterVideos }
