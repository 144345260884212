import { BasicFilter } from './BasicFilter'

export class FilterCategory<
    FilterType extends Record<string, BasicFilter<any, any>> = Record<
        string,
        BasicFilter<any, any>
    >
> {
    constructor(public title: string, public filters: FilterType) {}
}
