import React, { FC } from 'react'
import cn from './Tooltip.module.scss'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { convertMinutesToDuration } from '@/Layouts/ReportLayout/Content/GrowGraph/utils'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

interface Tooltip {
    dataPointIndex: number
    w: any
}

const Tooltip: FC<Tooltip> = ({ dataPointIndex, w }) => {
    const t = useSpaceTranslation('twitch_report.videos_length')
    const { i18n } = useTranslation()
    const data = w.config.series[0].data[dataPointIndex]

    return (
        <div className={cn.tooltip}>
            <div className={cn.flex}>
                <div className={cn.date}>{moment(data.x).lang(i18n.language.slice(0, 2)).format('DD MMM')}</div>
                <div className={cn.title}>{t('date')}</div>
            </div>

            <div className={cn.flex}>
                <div className={cn.duration}>{convertMinutesToDuration(data.y)}</div>
                <div className={cn.title}>{t('duration')}</div>
            </div>
        </div>
    )
}

export default Tooltip
