import colors from './palette.module.scss'

export const getColor = (name: string) => {
    const color = colors[name]
    if (!color) {
        console.error(`Unknown color "${name}"`)
        return '#000'
    }

    return color
}
