import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { TARIFFS } from '@/routs'
import Button from '@/components/Button'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

import cn from './Tariff.module.scss'

interface IBuyButton {
    unsubscribe: VoidFunction
    recurring_payment_enabled: boolean
    open: VoidFunction
    packageID: number | undefined
}

const BuyButton: FC<IBuyButton> = ({ unsubscribe, recurring_payment_enabled, packageID, open }) => {
    const t = useSpaceTranslation('billing.tariff')

    if (packageID === 14 || packageID === 16) {
        return (
            <>
                <Link to={TARIFFS}>
                    <Button onClick={open} className={cn.sub} color="white">
                        <span style={{ color: 'white' }}>{t('subscribe')}</span>
                    </Button>
                </Link>
            </>
        )
    }

    if (recurring_payment_enabled) {
        return (
            <Button onClick={open} className={cn.unsub} color="white">
                {t('unsubscribe')}
            </Button>
        )
    }
    return null

    // return (
    //     <div>
    //         {/* <Flex className={cn['tariff--note']} margin="10px 0 20px">
    //             <Icon name="close--small" className={cn['tariff--note--icon']} />

    //             <p className={cn['tariff--note--text']}>{t(`suspend_text`)}</p>
    //         </Flex> */}
    //         <Button onClick={unsubscribe} className={cn.sub} color="white">
    //             <span style={{ color: 'white' }}>{t('subscribe')}</span>
    //         </Button>
    //     </div>
    // )
}

export default BuyButton
