import { RootStore } from '@/_helpers/StoreType'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { Flex } from '@/_yc'
import ApexCharts, { ApexOptions } from 'apexcharts'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PartChanger from '../../Extra/PartChanger'
import { setGraphTimespan, setGraphType } from '../../Model/actions'
// import DatesCreator from './DatesCreator'
import Button from '@/components/Button'
import Text from '@/components/Text'
import { shortNum } from '@/_helpers/_graphs/_tech'
import cn from './GrowGraph.module.scss'
import moment from 'moment'
import getOptions from './options'
import getStartDate from '../utils'
import { useTranslation } from 'react-i18next'
import DataChange from './DataChange/DataChange'

import {
    calculatePercentChange,
    filterMetrics,
    generateCategoriesPlug,
    generateDataPlug,
    isGraphDataEmpty,
} from './utils'
import NoData from './NoData/NoData'

export interface IGraph {}

const PLUG_LENGTH = 40

const GrowGraph: FC<IGraph> = (props) => {
    const t = useSpaceTranslation('report_layout.graph')
    const { i18n } = useTranslation()
    const graphRef = useRef(null)
    const dispatch = useDispatch()

    const [changeValue, setChangeValue] = useState(0)
    const [isEmptyData, setIsEmptyData] = useState(false)

    const categoriesPlug = generateCategoriesPlug(PLUG_LENGTH)
    const dataPlug = generateDataPlug(PLUG_LENGTH)

    const graph = useSelector((store: RootStore) => {
        return store.report.graph
    })

    const graphInfo = useSelector((store: RootStore) => {
        return store.report.info
    })

    const data = useSelector((store: RootStore) => {
        return store.report
    })

    const graphSpan = useSelector((store: RootStore) => store.report.graph.timespan)
    const graphType = useSelector((store: RootStore) => store.report.graph.type)

    const channelMetrics = useSelector((store: RootStore) => {
        const reportData = store.report.rawChannelData
        const channelData = reportData.getChannel?.data
        const metrics = channelData?.youtube_channel[0]

        const historicalMetrics = metrics?.historical_metrics
        const filteredChannelMetrics = historicalMetrics && filterMetrics(historicalMetrics, getStartDate(graphSpan))

        return filteredChannelMetrics
    })

    const graphData = useMemo(() => {
        if (!channelMetrics) return []

        return channelMetrics
            .map((metric) => {
                const isSubscribersType = graphType === 'subscribers'
                const isViewsType = graphType === 'views'

                return isSubscribersType
                    ? metric.subscriber_count
                    : isViewsType
                    ? metric.view_count
                    : metric.er_followers
            })
            .filter((item) => item !== null)
            .reverse()
    }, [channelMetrics, graphType])

    useEffect(() => {
        setIsEmptyData(isGraphDataEmpty(graphData))
    }, [graphData, setIsEmptyData, graphType, graphSpan])

    const graphCategories = useMemo(() => {
        if (!channelMetrics) return []

        return channelMetrics.map((i) => moment(i.created_at).format()).reverse()
    }, [channelMetrics])

    const options = useMemo<ApexOptions>(() => {
        const isEmpty = isGraphDataEmpty(graphData)
        const categories = isEmpty ? categoriesPlug : graphCategories
        const data = isEmpty ? dataPlug : graphData

        return getOptions({
            graphOpts: data,
            info: graph,
            graphCategories: categories,
            locale: i18n.language.slice(0, 2),
            isEmpty: isEmpty,
            toFixed: 2,
        })
    }, [graphData, graph, graphCategories, i18n.language, categoriesPlug, dataPlug])

    useEffect(() => {
        if (channelMetrics) {
            const chart = new ApexCharts(graphRef.current, options)
            chart.render()
            return () => {
                chart.destroy()
            }
        }
    }, [options, dispatch, channelMetrics, graphRef])

    useEffect(() => {
        if (!graphData || graphData.length === 0) return

        const startValue = graphData[0]
        const endValue = graphData[graphData.length - 1]

        setChangeValue(calculatePercentChange(startValue, endValue))
    }, [graphData])

    const partChangerValues = useMemo(() => {
        const values = [
            {
                title: t('subs'),
                value: '',
                dispFunction: () => {
                    dispatch(setGraphType('subscribers'))
                },
            },
            {
                title: t('views'),
                value: '',
                dispFunction: () => {
                    dispatch(setGraphType('views'))
                },
            },
        ]

        if (graphInfo.lang_id === 'ru') {
            values.push({
                title: t('er'),
                value: '',
                dispFunction: () => {
                    dispatch(setGraphType('er'))
                },
            })
        }

        return values
    }, [graphInfo, dispatch, t])

    return (
        <Flex className={cn.root} column>
            <Flex>
                <PartChanger values={partChangerValues} />
            </Flex>

            <Flex margin="20px 0 0 0" align="center" content="space-between" wrap>
                <Flex align={'center'}>
                    <Text fSize={21} semibold className={cn[`blur--${isEmptyData ? 'active' : 'default'}`]}>
                        {graphType === 'subscribers'
                            ? shortNum(data.info.subscriber_count, 2)
                            : graphType === 'er'
                            ? `${shortNum(data.info.er_followers * 100, 2, 2)}%`
                            : shortNum(graphData[graphData?.length - 1], 2)}
                    </Text>

                    <DataChange changeValue={changeValue} isEmptyData={isEmptyData} />
                </Flex>

                <Flex>
                    <Button
                        margin="0 10px 0 0"
                        color="blue"
                        onClick={() => dispatch(setGraphTimespan('month'))}
                        active={graph.timespan === 'month'}
                        variant="outline--mobileV2"
                    >
                        {t('month')}
                    </Button>
                    <Button
                        color="blue"
                        onClick={() => dispatch(setGraphTimespan('6months'))}
                        active={graph.timespan === '6months'}
                        variant="outline--mobileV2"
                    >
                        {t('6months')}
                    </Button>
                    <Button
                        margin="0 0 0 10px"
                        color="blue"
                        onClick={() => dispatch(setGraphTimespan('year'))}
                        active={graph.timespan === 'year'}
                        variant="outline--mobileV2"
                    >
                        {t('year')}
                    </Button>
                </Flex>
            </Flex>

            <div ref={graphRef} className={cn[`blur--${isEmptyData ? 'active' : 'default'}`]} />
            {isEmptyData && <NoData />}
        </Flex>
    )
}

export default GrowGraph
