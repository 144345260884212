import { ISearchItem } from './types'
import { QueryType, TikTokSearchResult, TikTokUnhideResult, ITikTokLocation, ITikTokLang } from './types'
//these 2 functions can be in one, just need to implement
export const getTikTokAccountsFromRaw = (
    queryType: QueryType,
    result: TikTokSearchResult,
    sliceIndex: number,
    offset: number
): ISearchItem[] => {
    const data = result[queryType].data.accounts
    if (!data || data.length === 0) {
        return []
    }

    const unlockedChannels = result?.getUnlockedChannels?.unlocked_tt_accounts

    const isUnlocked = (id: string): boolean => {
        return unlockedChannels.filter((item: any) => item === id).length > 0
    }

    const res = data.slice(0, sliceIndex).map((item) => {
        return {
            id: item.account.user_profile.user_id,
            username: item.account.user_profile.username,
            fullName: item.account.user_profile.fullname,
            followers: item.account.user_profile.followers,
            averageViews: item.account.user_profile.avg_views,
            avatar: item.account.user_profile.picture,
            er: item.account.user_profile.engagement_rate,
            unlocked: isUnlocked(item.account.user_profile.user_id), // unlockedChannels?.includes(item.account_id),
            hiddenReportId: item.account.hidden_result ? item.account.search_result_id : undefined,
            isHidden: false,
        }
    })

    const hiddenLength = res.filter((item) => item.hiddenReportId).length

    if (hiddenLength !== 0) {
        //if first page, from 3, else from 0 to 10
        for (let i = offset === 0 ? 3 : 0; i < res.length; i++) {
            res[i].isHidden = true
        }
    }

    return res
}

export const getTikTokAccountsFromRawUnhide = (
    queryType: string, //QueryType,
    result: TikTokUnhideResult,
    sliceIndex: number
): ISearchItem[] => {
    const data = result.unhideSearchTikTok.data.accounts
    if (!data || data.length === 0) {
        return []
    }

    // const unlockedChannels = result?.getUnlockedChannels?.unlocked_tg_accounts

    // const isUnlocked = (id: string): boolean => {
    //     return unlockedChannels.filter((item: any) => item === id).length > 0
    // }

    return data.slice(0, sliceIndex).map((item) => {
        return {
            id: item.account.user_profile.user_id,
            username: item.account.user_profile.username,
            fullName: item.account.user_profile.fullname,
            followers: item.account.user_profile.followers,
            averageViews: item.account.user_profile.avg_views,
            avatar: item.account.user_profile.picture,
            er: item.account.user_profile.engagement_rate,
            unlocked: false, //isUnlocked(item.id), // unlockedChannels?.includes(item.account_id),
            hiddenReportId: item.account.hidden_result ? item.account.search_result_id : undefined,
        }
    })
}

export const preprocessLocation = (locs: ITikTokLocation[]) => {
    if (!locs || locs.length === 0) return [{ label: 'ERROR', value: '' }]

    return locs.map((i) => ({
        label: i.name,
        value: i.id.toString(),
    }))
}

export const preprocessLanguages = (languages: ITikTokLang[]) => {
    if (!languages || languages.length === 0) return [{ label: 'ERROR', value: '' }]

    return [...languages].map((i) => ({
        label: i.name,
        value: i.code,
    }))
}
