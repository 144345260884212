import React, { FC, useMemo } from 'react'

import cn from './Topics.module.scss'
import { Segment } from '@/Layouts/ListLayout'
import Text from '@/components/Text'
import { getShortString } from '@/_helpers/strings/getShortString'
import { TopicsPopup } from './TopicsPopup/TopicsPopup'

export interface ITopics {
    topics: Array<string>
    size?: number
    fSize?: number
    maxWidth?: number
    maxCharacterLength?: number
}

const Topics: FC<ITopics> = ({ topics, size = 10, fSize = 10, maxWidth, maxCharacterLength = 20 }) => {
    const lastTopic = useMemo(() => {
        let totalChars = 0
        let i = 0
        const l = maxCharacterLength || 30
        for (let topic of topics) {
            totalChars += topic.length
            if (totalChars >= l) {
                break
            }
            i++
        }

        return i
    }, [topics, maxCharacterLength])

    return (
        <Segment component="ul" className={cn.topics}>
            {topics.slice(0, lastTopic).map((topic) => (
                <Text
                    size={size}
                    color="gray-2"
                    component="li"
                    fSize={fSize}
                    key={topic}
                    className={cn.chip}
                    style={{ maxWidth: maxWidth ? maxWidth : '' }}
                >
                    {getShortString(topic, 24)}
                </Text>
            ))}
            {topics.length > lastTopic && <TopicsPopup topics={topics.slice(lastTopic)} />}
        </Segment>
    )
}

export default Topics
