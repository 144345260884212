import PageLayout from '@/Layouts/PageLayout'
import { useCampaignId } from '@/_helpers/Hooks/useCampaignId'
import { CampaignManagerProvider } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerProvider'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Flex, GlobalWidth } from '@/_yc'
import { useTranslation } from 'react-i18next'
import MediaPlanHeader from './MediaPlanHeader/MediaPlanHeader'
import { CampaignManagerContext } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerContext'
import { useQuery } from '@apollo/client'
import { GET_CONTENT_TYPES, GET_MEDIA_PLAN } from './utils/queries'
import { ContentTypesRaw, GetMediaPlanRaw, MediaPlanData, MediaPlanOrder } from './utils/types'
import { preprocessMediaPlan } from './utils/preprocess'
import { SearchBar } from './SearchBar/SearchBar'
import { ConfigurableTable } from './ConfigurableTable/ConfigurableTable'
import cn from './MediaPlan.module.scss'
import Button from '@/components/Button'
import useOpenClose from '@/_helpers/useOpenClose'
import { MassAddPopup } from './MassAddPopup/MassAddPopup'
import { Report } from './Report/Report'
import { TopStats } from './TopStats/TopStats'
import { IUnlockedChannels } from '@/pages/Lists/model/types'
import { GET_UNLOCKED_CHANNELS } from '@/pages/Lists/model/graphQL'
import { AudienceLocker } from './AudienceLocker/AudienceLocker'
import { useDispatch } from 'react-redux'
import Text from '@/components/Text'
import LoadingButton from '@/components/Share/LoadingButton/LoadingButton'
import { downloadXlsReq } from './utils/service'
import useToken from '@/_helpers/Hooks/useToken'
import { SetAllMediaBloggers, SetDefault } from '@/components/MassAddToList/_model/actions'

export const MediaPlan = () => {
    const id = useCampaignId()
    const { t } = useTranslation()
    const context = useContext(CampaignManagerContext)
    const dispatch = useDispatch()

    const [isPopupOpen, open, close] = useOpenClose()

    const unlockedChannelsIds = useQuery<IUnlockedChannels>(GET_UNLOCKED_CHANNELS, {
        fetchPolicy: 'cache-first',
    })

    const { data, refetch, loading } = useQuery<GetMediaPlanRaw>(GET_MEDIA_PLAN, {
        context: context,
        variables: {
            orderId: id,
        },
    })

    const { data: contentTypesData } = useQuery<ContentTypesRaw>(GET_CONTENT_TYPES, {
        context: context,
        variables: {
            orderId: id,
        },
    })

    const preprocessedMediaPlan: MediaPlanOrder = useMemo(() => {
        if (!data || !data?.mediaPlanOrderById) {
            return {
                id: -1,
                name: '',
                status: '',
                unlockedBloggers: [],
                data: {} as MediaPlanData,
                bloggers: [],
                totalCost: 0,
                contentTypes: [],
                currency: '₽',
            }
        }
        const temp = preprocessMediaPlan(data.mediaPlanOrderById)
        dispatch(SetAllMediaBloggers(temp.bloggers.map((item) => item.externalId)))
        return temp
    }, [data, dispatch])

    useEffect(() => {
        return () => {
            dispatch(SetDefault())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const unlockedAccounts = useMemo(() => {
        let youtubeIds: string[] = []
        if (unlockedChannelsIds.data) {
            youtubeIds = unlockedChannelsIds.data.getUnlockedChannels.unlocked_youtube_channels
        }

        const instagramIds = preprocessedMediaPlan.unlockedBloggers

        return [...instagramIds, ...youtubeIds]
    }, [unlockedChannelsIds, preprocessedMediaPlan])

    const contentTypes = useMemo(() => {
        return contentTypesData?.mediaPlanOrderById?.contentTypes || []
    }, [contentTypesData])

    const isSomeBloggerLocked = useMemo(() => {
        if (!preprocessedMediaPlan.bloggers) return false

        return preprocessedMediaPlan.bloggers.some((blogger) => !unlockedAccounts.includes(blogger.externalId))
    }, [preprocessedMediaPlan, unlockedAccounts])

    const [xlsLoading, setXlsLoading] = useState(false)

    const token = useToken()

    const handleDownload = useCallback(async () => {
        if (!token) return

        setXlsLoading(true)

        const response = await downloadXlsReq(token, id)

        const url = window.URL.createObjectURL(new Blob([response], { type: 'application/vnd.ms-excel' }))

        const link = document.createElement('a')
        link.href = url

        link.download = `media-plan-${preprocessedMediaPlan.name}.xls`

        link.click()

        window.URL.revokeObjectURL(url)

        setXlsLoading(false)
    }, [id, preprocessedMediaPlan.name, token])

    return (
        <CampaignManagerProvider>
            <PageLayout color="common-background" title={t('titles.t7')}>
                <GlobalWidth>
                    <MediaPlanHeader id={id} name={preprocessedMediaPlan.name} />
                    <Flex>
                        <div style={{ width: 730, marginRight: 20 }}>
                            <SearchBar refetch={refetch} />
                        </div>
                        <Button onClick={open} margin="0 10px 0 0">
                            {t('media_planner.plan.header.add_multiple_btn')}
                        </Button>

                        <LoadingButton loading={xlsLoading} onClick={handleDownload}>
                            {t('aud_insides.downloadButton')}
                        </LoadingButton>
                    </Flex>
                    {((!loading && preprocessedMediaPlan.bloggers.length > 0) || loading) && (
                        <TopStats
                            mediaPlan={preprocessedMediaPlan}
                            isPlanLoading={loading}
                            currency={preprocessedMediaPlan.currency}
                        />
                    )}

                    {isPopupOpen && (
                        <MassAddPopup
                            isOpen={isPopupOpen}
                            onClose={close}
                            title={t('media_planner.plan.header.popup_title')}
                            subtitle={t('media_planner.plan.header.popup_subtitle')}
                            refetchBloggers={refetch}
                        />
                    )}

                    <div className={cn.content}>
                        <ConfigurableTable
                            contentTypes={contentTypes}
                            rows={preprocessedMediaPlan.bloggers}
                            isPlanLoading={loading}
                            refetchBloggers={refetch}
                            currency={preprocessedMediaPlan.currency}
                            unlockedAccounts={unlockedAccounts}
                        />
                    </div>

                    {preprocessedMediaPlan.bloggers.length > 0 && (
                        <Text fSize={18} margin="30px 0 18px 0" semibold>
                            {t('media_planner.plan.audience_title')}
                        </Text>
                    )}

                    {isSomeBloggerLocked && <AudienceLocker refetchBloggers={refetch} />}

                    {((!loading && preprocessedMediaPlan.bloggers.length > 0) || loading) && (
                        <Report mediaPlan={preprocessedMediaPlan} isLoading={loading || isSomeBloggerLocked}></Report>
                    )}
                </GlobalWidth>
            </PageLayout>
        </CampaignManagerProvider>
    )
}
