import { ApolloClient, InMemoryCache, createHttpLink, from, ApolloLink } from '@apollo/client'
import { authHasura } from '@/_helpers'
import { getLang } from '../getLang'

export class Fetcher {
    defaultLink = createHttpLink({
        uri: process.env.REACT_APP_HASURA_DOMAIN,
    })

    campaignManagerLink = createHttpLink({
        uri: process.env.REACT_APP_HASURA_CAMPAIGN_MANAGER_DOMAIN,
    })

    authMiddleware = new ApolloLink((operation, forward) => {
        const isCampaign = operation.getContext().clientName === 'campaignManager'

        if (isCampaign) {
            operation.setContext(({ Headers }: any) => ({
                headers: {
                    ...Headers,
                    'Accept-Language': getLang(),
                    ...authHasura(),
                },
            }))
        } else {
            operation.setContext(({ Headers }: any) => ({
                headers: {
                    ...Headers,
                    'x-hasura-role': 'subscriber',
                    'x-hasura-lang': getLang(),
                    ...authHasura(),
                },
            }))
        }

        return forward(operation)
    })

    client = new ApolloClient({
        link: from([
            this.authMiddleware,
            ApolloLink.split(
                (operation) => operation.getContext().clientName === 'campaignManager',
                this.campaignManagerLink,
                this.defaultLink
            ),
        ]),
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'cache-and-network',
                errorPolicy: 'ignore',
            },
            query: {
                fetchPolicy: 'cache-first',
                errorPolicy: 'all',
            },
        },
    })

    getClient = () => {
        return this.client
    }
}
