import React, { FC, memo } from 'react'
import { Stylable } from '@/Types'

import cn from './SearchInput.module.scss'
import { Flex } from '@/_yc'
import Icons from '@/components/Icons'
import WhereSelector from './WhereSelector'
import useClass from '@/_helpers/Hooks/useClass'
import ConnectedInput from './ConnectedInput'
import Button from '@/components/Button'
import AdapterSelector from './AdapterSelector'

export interface ISearchInput extends Stylable {
    toggleFilters?: Function
}

const SearchInput: FC<ISearchInput> = (props) => {
    const root = useClass(cn.root, props.className)

    return (
        <Flex className={root} style={props.style} margin={props.margin} align="center">
            <div className={cn.displayStandardAdapter}>
                <AdapterSelector />
            </div>
            <Icons name="search" className={cn['icon--gray4']} />
            <ConnectedInput />
            <div className={cn.displayWhere}>
                <WhereSelector />
            </div>
            <Button margin="0 0 0 0" variant="transparent" className={cn['btn--visible']} onClick={props.toggleFilters}>
                <Icons className={cn['icon--gray3']} name="slider" margin="0 5px 0 0" />
            </Button>
        </Flex>
    )
}

export default memo(SearchInput)
