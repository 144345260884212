import React from 'react';
import PropTypes from 'prop-types'

import logo from './Icons/logo.png'

export class YoloBlueLogo extends React.Component{
	static propTypes = {
		style: PropTypes.object
	}
	constructor(props){
		super(props);
		this.state = {
			loaded: false
		}
		let img = new Image();
		img.onload = e=>this.setState({ loaded: true })
		img.src = logo;
	}
	render(){
		return this.state.loaded ? <img style={this.props.style} src={logo} alt={<div />}/>
		: null
	}
}