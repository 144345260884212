import Popup from '@/_yc/Popup'
import React, { FC, useContext, useState } from 'react'

import cn from './ConfigMenu.module.scss'
import Icons from '@/components/Icons'
import useOpenClose from '@/_helpers/useOpenClose'
import { Config } from '../../ConfigurableTable'
import { Checkbox } from '@/pages/SearchPage/SearchAdapter/filters/SelectorFilter/Checkbox'
import Text from '@/components/Text'
import { useSpaceTranslation } from '@/_helpers'
import { Input } from '@/components/Input/Input'
import { Flex } from '@/_yc'
import Button from '@/components/Button'
import { useMutation } from '@apollo/client'
import { CampaignManagerContext } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerContext'
import { useCampaignId } from '@/_helpers/Hooks/useCampaignId'
import { CREATE_CUSTOM_COLUMN } from '../../../utils/queries'

interface ConfigMenuProps {
    config: Config
    onColumnToggle: (column: string) => void
    refetch: () => void
}

export const ConfigMenu: FC<ConfigMenuProps> = ({ config, onColumnToggle, refetch }) => {
    const ref = React.useRef<HTMLDivElement>(null)
    const [isOpen, open, close] = useOpenClose()
    const t = useSpaceTranslation('media_planner.plan.config_menu')

    const [isNewColumnOpen, openNewColumn, closeNewColumn] = useOpenClose()
    const [newColumnName, setNewColumnName] = useState('')

    const context = useContext(CampaignManagerContext)
    const id = useCampaignId()

    const [columnMutation] = useMutation(CREATE_CUSTOM_COLUMN, {
        context: context,
    })

    const onNewColumnSubmit = () => {
        columnMutation({
            variables: {
                orderId: id,
                columnName: newColumnName,
                columnKey: newColumnName.toLowerCase().replace(' ', '_'),
            },
        }).then(() => {
            refetch()
            closeNewColumn()
        })
    }

    return (
        <>
            <div className={cn.settings} ref={ref} onClick={open}>
                <Icons name="settings" />
            </div>
            <Popup
                isOpen={isOpen}
                onClose={close}
                anchor={ref.current}
                placement="bottom-end"
                style={{ zIndex: 130, width: 240, marginTop: '-14px' }}
            >
                <div className={cn.menu}>
                    {config.columns.map((column) => (
                        <div key={column.key} className={cn['menu-item']} onClick={() => onColumnToggle(column.key)}>
                            <Checkbox isChecked={column.active} />
                            <Text>{column.name}</Text>
                        </div>
                    ))}
                    {!isNewColumnOpen && (
                        <Text
                            fSize={12}
                            color="violet-1"
                            margin="8px 0 0 0"
                            style={{ cursor: 'pointer' }}
                            onClick={openNewColumn}
                        >
                            {t('new_column')}
                        </Text>
                    )}
                    {isNewColumnOpen && (
                        <Flex column>
                            <Input
                                placeholder={t('placeholder')}
                                value={newColumnName}
                                onChange={(e) => setNewColumnName(e.target.value)}
                            />
                            <Button
                                disabled={newColumnName === ''}
                                margin="12px 0 0 0"
                                variant="primary"
                                onClick={onNewColumnSubmit}
                            >
                                {t('btn')}
                            </Button>
                        </Flex>
                    )}
                </div>
            </Popup>
        </>
    )
}
