import Observable from './Observable'

export interface IPollinger {
    subscribe: Observable<number>['subscribe']
    startPolling: (delay: number) => void
    finishPolling: VoidFunction
}

export default class Pollinger implements IPollinger {
    private started: boolean = false

    private observable = new Observable<number>()

    private lastDelay?: number

    constructor() {
        this.observable.onUnsubscribe = (count) => {
            if (count === 0) {
                this.finishPolling()
            }
        }
        this.observable.onSubscribe = (count) => {
            if (count === 1 && !this.started && this.lastDelay) {
                this.startPolling(this.lastDelay)
            }
        }
    }

    subscribe: Observable<number>['subscribe'] = this.observable.subscribe.bind(this.observable)

    startPolling(delay: number) {
        this.lastDelay = delay
        if (this.started) return console.error('Polling is already started')
        this.started = true
        this.polling(delay)
    }

    private polling(delay: number, count: number = 1) {
        setTimeout(() => {
            if (!this.started) return

            this.observable.update(count)
            this.polling(delay, count + 1)
        }, delay)
    }

    finishPolling() {
        this.started = false
    }
}
