import React, { FC } from 'react'

import useCurrentTime from './useCurrentTime'
import Text from '@/_yc/Text'
import transformTime from './transformTime'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

export interface IUpdatingTime {
    unix: number
}

const UpdatingTime: FC<IUpdatingTime> = (props) => {
    const t = useSpaceTranslation('Notifications')
    const [interval] = useCurrentTime(props.unix)

    const { units, number } = transformTime(interval)

    return (
        <Text margin="4px 0 0" color="secondary" size={12}>
            {number > 0
                ? t('time', {
                      date: `${number} ${t(`timeUnits.${units}`, undefined, true)}`,
                  })
                : t('recent')}
        </Text>
    )
}

export default UpdatingTime
