import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.35 7.77118L0.0500031 4.47118L0.99267 3.52852L3.42867 5.96185L3.35 5.88518L9.00667 0.228516L9.94934 1.17118L4.29267 6.82852L3.35067 7.77052L3.35 7.77118Z"
            fill="#76849B"
        />
    </svg>
)
