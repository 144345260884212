import React, { ChangeEvent, FC } from 'react'

import cn from './DoubleSlider.module.scss'

import Text from '@/components/Text'
import { numberFormatter } from '@/_helpers'
import { CustomSlider } from './CustomSlider'

interface RangeSliderProps {
    from: number
    to: number
    onChange: (from: number, to: number) => void
    onChangeCommitted: (from: number, to: number, isError?: boolean) => void
}

export const RangeSlider: FC<RangeSliderProps> = ({ from, to, onChange, onChangeCommitted }) => {
    const [errorFrom, setErrorFrom] = React.useState(false)
    const [errorTo, setErrorTo] = React.useState(false)

    const handleChange = (event: ChangeEvent<{}>, newValue: number | number[]) => {
        const val = newValue as number[]
        setErrorFrom(false)
        setErrorTo(false)
        onChange(val[0] as number, val[1] as number)
    }

    const handleChangeCommitted = (event: ChangeEvent<{}>, newValue: number | number[]) => {
        const val = newValue as number[]
        setErrorFrom(false)
        setErrorTo(false)
        onChangeCommitted(val[0] as number, val[1] as number, false)
    }

    const handleFromInputChange = (from: number) => {
        const isError = from > to
        if (isError) {
            setErrorFrom(true)
        } else {
            setErrorFrom(false)
            setErrorTo(false)
        }
        onChangeCommitted(from, to, isError)
    }

    const handleToInputChange = (to: number) => {
        const isError = from > to
        if (isError) {
            setErrorTo(true)
        } else {
            setErrorTo(false)
            setErrorFrom(false)
        }
        onChangeCommitted(from, to, isError)
    }

    const parseValue = (value: string) => {
        const withoutSpaces = value.replace(/\s/g, '')
        const parsedValue = parseInt(withoutSpaces, 10)
        return isNaN(parsedValue) ? 0 : parsedValue
    }

    return (
        <div className={cn.rangeSlider}>
            <div className={cn.inputs}>
                <div className={cn.inputGroup}>
                    <Text fSize={12} color="gray-new-2" margin="0 0 6px 0">
                        From
                    </Text>
                    <input
                        value={numberFormatter(from)}
                        onChange={(e) => handleFromInputChange(parseValue(e.target.value))}
                        className={errorFrom ? cn['input--error'] : cn.input}
                    />
                </div>
                <div className={cn.inputGroup}>
                    <Text fSize={12} color="gray-new-2" margin="0 0 6px 0">
                        To
                    </Text>

                    <input
                        value={numberFormatter(to)}
                        onChange={(e) => handleToInputChange(parseValue(e.target.value))}
                        className={errorTo ? cn['input--error'] : cn.input}
                    />
                </div>
            </div>

            <div>
                <CustomSlider
                    getAriaLabel={() => ''}
                    value={[from, to]}
                    onChange={handleChange}
                    onChangeCommitted={handleChangeCommitted}
                    valueLabelDisplay="off"
                    min={0}
                    step={1000}
                    max={1000000}
                />
            </div>
        </div>
    )
}
