import React, { FC } from 'react'

import Flex from '@/_yc/Flex'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'

import cn from './LoadingFiller.module.scss'

const LoadingFiller: FC<{ grid: string }> = ({ grid }) => {
    const size = useWindowWidth('mobile')
    const isMobile = size === 'mobile'

    if (isMobile) {
        return (
            <>
                <>
                    {Array.apply(0, new Array(6)).map(() => (
                        <div className={cn.root}>
                            <div className={cn.ava} />
                            <div className={cn.name} />
                        </div>
                    ))}
                </>
            </>
        )
    }

    return (
        <>
            {Array.apply(0, new Array(6)).map(() => (
                <div style={{ display: 'grid', gridTemplateColumns: grid }} className={cn.root}>
                    <div className={cn.check} />
                    <Flex align="center">
                        <div className={cn.ava} />
                        <div className={cn.name} />
                    </Flex>
                    <div className={cn.rest} />
                </div>
            ))}
        </>
    )
}

export default LoadingFiller
