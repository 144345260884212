import { useSpaceTranslation } from '@/_helpers'
import React, { ChangeEvent, FC, useCallback, useContext } from 'react'
import cn from './SearchBar.module.scss'
import Icons from '@/components/Icons'
import { useSearch } from '@/Layouts/AudienceLayout/Header/SearchInput/useSearch'
import Menu from './Popup'
import useOpenClose from '@/_helpers/useOpenClose'
import { useComputedStyle } from '@/_helpers/Hooks/useComputedStyle'
import { CampaignManagerContext } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerContext'
import { useMutation } from '@apollo/client'
import { ADD_INFLUENCER } from '../utils/queries'
import { useCampaignId } from '@/_helpers/Hooks/useCampaignId'
import { AutocompleteResult } from '@/Reponses'

interface SearchBarProps {
    refetch: () => void
}

export const SearchBar: FC<SearchBarProps> = ({ refetch }) => {
    const t = useSpaceTranslation('media_planner.plan.search_bar')
    const { search, setSearch, results, clearSearch, error } = useSearch({ yt: true })

    const [isOpen, open, toggle] = useOpenClose()

    const closePopup = () => {
        toggle()
        clearSearch()
    }

    const context = useContext(CampaignManagerContext)
    const id = useCampaignId()

    const [addInfluencers] = useMutation(ADD_INFLUENCER, {
        context: context,
    })

    const onInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setSearch(e.target.value)
            open()
        },
        [setSearch, open]
    )

    const onItemClick = useCallback(
        (item: AutocompleteResult) => {
            console.log('click')
            addInfluencers({
                variables: {
                    orderId: id,
                    bloggers: [item.external_id],
                    bloggersType: item.type.toUpperCase(),
                },
            }).then(() => {
                refetch()
            })
        },
        [addInfluencers, id, refetch]
    )

    const { ref } = useComputedStyle((style) => style.width, [])

    return (
        <div className={cn.searchBar} ref={ref}>
            <Icons className={cn.searchIcon} name="search" />

            <input
                placeholder={t('input.placeholder')}
                value={search}
                onChange={onInputChange}
                onClick={toggle}
                autoComplete={'off'}
            ></input>

            <Menu
                width={ref.current?.offsetWidth + 10 ?? 200}
                isOpen={isOpen}
                onClose={closePopup}
                items={results}
                error={error}
                anchor={ref.current}
                style={{ zIndex: 1000 }}
                onClick={onItemClick}
            />
        </div>
    )
}
