import React from 'react'
import styles from './service-card.module.scss'
import TikTokIE from '@/components/Icons/UI/TikTokIEDisabled'
import Youtube from '@/components/Icons/UI/YouTubeIEDisabled'
import Instagram from '@/components/Icons/UI/InstagramIEDisabled'
import VK from '@/components/Icons/UI/VkDisabled'
import Telegram from '@/components/Icons/UI/TelegraDisabled'
import Twich from '@/components/Icons/UI/TwitchIEDisabled'
import { Flex } from '@/_yc'
import Text from '@/components/Text'

import { ReactComponent as Search } from '../../icons/search.svg'
import { ReactComponent as Squares } from '../../icons/squares.svg'
import { ReactComponent as Eye } from '../../icons/eye.svg'
import { ReactComponent as Competitor } from '../../icons/competitor.svg'
import { Link } from 'react-router-dom'
import Button from '@/components/Button'

type IconName = 'tiktok' | 'youtube' | 'instagram' | 'vk' | 'telegram' | 'twitch'
type Icon = 'search' | 'analysis' | 'intersection' | 'competitor'

interface Props {
    icons: IconName[]
}

interface ServiceCardProps {
    title: string
    description: string
    icon: Icon
    link: string
    icons: IconName[]
}
const iconsSoc = {
    tiktok: <TikTokIE className={styles['icon-soc']} />,
    youtube: <Youtube className={styles['icon-soc']} />,
    instagram: <Instagram className={styles['icon-soc']} />,
    vk: <VK className={styles['icon-soc']} />,
    telegram: <Telegram className={styles['icon-soc']} />,
    twitch: <Twich className={styles['icon-soc']} />,
}

const mainIcons = {
    search: <Search />,
    analysis: <Eye />,
    intersection: <Squares />,
    competitor: <Competitor />,
}

const ServiceCard: React.FC<ServiceCardProps> = ({ title, description, icon, link, icons }) => {
    return (
        <Flex column content="space-between" className={styles.card}>
            <Flex content="space-between" align="start">
                <Flex>{mainIcons[icon]}</Flex>
                <Flex align="center" className={styles.icons}>
                    {icons.map((t) => (
                        <Flex>{iconsSoc[t]}</Flex>
                    ))}
                </Flex>
            </Flex>
            <Flex className={styles.content}>
                <Flex column>
                    <Text fSize="18px" semiBold component="h3">
                        {title}
                    </Text>
                    <Text fSize="14px">{description}</Text>
                </Flex>
                <Link to={link}>
                    <Button>Начать</Button>
                </Link>
            </Flex>
        </Flex>
    )
}

export default ServiceCard
