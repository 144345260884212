import React, { ComponentType } from 'react';
import { FileDownloader } from '../FileDownloader.js'
import { Subtract } from 'utility-types';

export interface ProvidedProps {
	pdfLoader: {
		loading: boolean
		startLoading: (name: string, id: number | string) => void
	}
}

export type State = ProvidedProps["pdfLoader"]

/**
 * @param getPdfId service that returns promise with { task_id }
 * @param pollingService service that checking task status
 * @param downloadPDF function that dounload file from url
 * @param onError error function
 * @description HOC provides pdfLoader prop consists of loading state and startLoading(name: string, id: string | number) function
 * @example
 * import { withPdfLoader } from "HOCs"
 * 
 * const App = ({ pdfLoader }) => {
 * 	return (
 * 		<div 
 * 			onClick={() => pdfLoader.startLoading()} 
 * 		>
 * 			{pdfLoader.loading ? "Loading..." : "Download"}
 * 		</div>
 * 	)
 * }
 * 
 * export default withPdfLoader(
 * 	(id) => Promise.resolve("test_task-id"),
 * 	(id, task_id) => Promise.resolve({ status: "SUCCESS", url: "http://example.com/test_task-id/pdf.pdf" }),
 * 	(download_url) => Promise.resolve("CONTENT"),
 * 	(err) => console.error(err)
 * )(App)
 */
export const withPdfLoader = <Props extends ProvidedProps>(
	getPdfId: (id: number | string) => Promise<{ task_id: string }>, 
	pollingService: (id: string | number, task_id: string) => Promise<{ status: "PENDING" } | { status: "SUCCESS", url: string }>, 
	downloadPDF: (url: string) => Promise<BlobPart>, 
	onError: (Err: any) => void
) => (Component: ComponentType<Props>) => 
class withPdfLoader extends React.PureComponent<Subtract<Props, ProvidedProps>, State> {
	static get displayName(){
		return `withPdfLoader(${Component.displayName || Component.name || "Component"})`
	}
	state: State = {
		loading: false,
		startLoading: this.startLoading.bind(this)
	}
	mounted = true;

	startLoading(name: string, report_id: number | string){
		this.setState({
			loading: true
		})
		getPdfId(report_id)
			.then(({ task_id }) => {
				if (!this.mounted) return
				this.initPolling(task_id, report_id, name)
			})
			.catch(this.handleError)
	}
	initPolling(task_id: string, report_id: number | string, name: string){
		pollingService(report_id, task_id)
			.then(res => {
				if (res.status === "PENDING") {
					setTimeout(() => {
						this.initPolling(task_id, report_id, name);
					}, 2000)
				}else{
					this.download(res.url, name)
				}
			})
			.catch(this.handleError)
	}
	download(url: string, name: string){
		downloadPDF(url)
			.then(pdf => {
				this.setState({
					loading: false
				})
				FileDownloader(pdf, name)
			})
			.catch(this.handleError)
	}
	handleError = (err: any) => {
		if (!this.mounted) return
		this.setState({
			loading: false
		})
		if(onError) onError(err)
	}
	componentWillUnmount(){
		this.mounted = false;
	}
	render(){
		return <Component {...this.props as Props} pdfLoader={this.state}/>
	}
}