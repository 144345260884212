import React, { FC, forwardRef } from 'react'
import cn from './Input.module.scss'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string
    disabled?: boolean
}

type Ref = HTMLInputElement

export const Input: FC<InputProps> = forwardRef<Ref, InputProps>(
    ({ label, disabled, className, ...otherProps }, ref) => {
        const classes = [disabled ? cn.inputDisabled : cn.inputField, className].join(' ')

        return (
            <div className={cn.input}>
                <label>{label}</label>
                <input className={classes} ref={ref} disabled={disabled} {...otherProps}></input>
            </div>
        )
    }
)
