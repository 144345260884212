import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.8"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.205 8.63536L11.8555 10.7364C12.0293 10.9841 12.0477 11.3047 11.9032 11.5695C11.7587 11.8343 11.4751 12 11.1662 12H0.833814C0.524846 12 0.241235 11.8344 0.0967697 11.5695C-0.0476959 11.3047 -0.0293094 10.9841 0.144554 10.7365L1.79522 8.6354V5.70193C1.79522 3.72364 3.24809 2.07459 5.17554 1.70293V0.799577C5.17554 0.358005 5.54469 0 6.00008 0C6.45546 0 6.82461 0.358005 6.82464 0.799577V1.7029C8.75209 2.07452 10.205 3.72357 10.205 5.70189V8.63536ZM4.16625 13.2153C4.16632 13.2135 4.16639 13.2116 4.1664 13.2098H7.83374C7.83378 13.2137 7.83404 13.2176 7.83404 13.2215C7.83404 14.2038 7.01295 15 6.00007 15C4.9872 15 4.1661 14.2038 4.1661 13.2215C4.1661 13.2194 4.16618 13.2174 4.16625 13.2153Z"
            fill="white"
        />
    </svg>
)
