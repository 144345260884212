import Icons from '@/components/Icons'
import { useSpaceTranslation } from '@/_helpers'
import React, { useMemo } from 'react'

import Text from '@/components/Text'
import cn from './UpgradeTariff.module.scss'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import Button from '@/components/Button'
import Flex from '@/_yc/Flex'
import { TARIFFS } from '@/routs'
import { Link } from 'react-router-dom'

export const UpgradeTariff = () => {
    const t = useSpaceTranslation('outreach.templates.upgrade')
    const mailingList = useSelector((store: RootStore) => store.MassAddToList.mailingList)
    const allList = useMemo(() => {
        return mailingList.instList.concat(mailingList.ytList)
    }, [mailingList])

    return (
        <div className={cn.upgradeTariff}>
            <Flex>
                <div className={cn.arrowContainer}>
                    <Icons name="arrow-up" className={cn.largeArrow} />
                </div>
                <div style={{ marginLeft: 20 }}>
                    <Text fSize={16} semibold>
                        {t('title')}
                    </Text>
                    <Text fSize={12} color="gray-2">{`${allList.length} ${t('bloggers')}`}</Text>
                </div>
            </Flex>

            <Link to={TARIFFS}>
                <Button variant="primary">
                    {t('btn')}
                    <Icons name="arrow-up" className={cn.smallArrow} />
                </Button>
            </Link>
        </div>
    )
}
