import ScrollListener from '@/components/ScrollListener'
import NoResults from '@/pages/SearchPage/Bloggers/SearchItems/NoResults/NoResults'
import { useAdapter, useSearch } from '@/pages/SearchPage/SearchAdapter'
import React, { FC, useEffect } from 'react'
import { IAd } from '../../types'

import cn from './AdsGrid.module.scss'
import { AdsExplorerHidder } from '../../../Hidder/AdsExplorer/HidderAds'
import { Platform } from '@/pages/Reports/types'
import { useDispatch } from 'react-redux'
import { SetAllBloggers, SetDefault } from '@/components/MassAddToList/_model/actions'

interface IPostsGrid {
    isList?: boolean
}

export const AdsGrid: FC<IPostsGrid> = ({ isList = false }) => {
    const Adapter = useAdapter()
    const { restart, next, selected, isHidden } = useSearch((state) => state)
    const dispatch = useDispatch()

    useEffect(() => {
        restart()
    }, [restart])

    useEffect(() => {
        const script = document.createElement('script')

        script.src = '//www.instagram.com/embed.js'
        script.async = true
        script.onload = () => {
            console.log('script loaded')
            const instaEmbeds = document.querySelectorAll('.insta-embed')
            instaEmbeds.forEach((embed) => {
                // @ts-ignore
                window.instgrm?.Embeds.process(embed)
            })
        }

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [selected.items.length, selected.loading])

    const ads: IAd[] = selected.items

    useEffect(() => {
        //store bloggers to mass add
        dispatch(SetDefault())

        const allBloggers = ads
            .map((item: IAd) => {
                return {
                    //@ts-ignore
                    id: item.userId ? item.userId : item.channelId,
                    //@ts-ignore
                    externalId: item.userId ? item.userId : item.channelId,
                    hasEmail: false,
                    name: '',
                    avatar: '',
                    username: '',
                    platform: 'userId' in item ? Platform.Instagram : Platform.YouTube,
                }
            })
            .filter((blogger, index, self) => {
                const foundIndex = self.findIndex(
                    (item) => item.id === blogger.id && item.externalId === blogger.externalId
                )
                return foundIndex === index
            })

        dispatch(SetAllBloggers(allBloggers))
    }, [dispatch, ads])

    useEffect(() => {
        return () => {
            dispatch(SetDefault())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={cn.postsGrid} style={isList ? { display: 'flex', flexDirection: 'column', gap: '1px' } : {}}>
            {selected.items.length === 0 && !selected.loading ? (
                <NoResults />
            ) : (
                ads.map((ad, i) => <Adapter.Item {...ad} key={i} margin={'0 0 2px'} isList={isList} />)
            )}
            {isHidden && selected.items.length !== 0 && <AdsExplorerHidder count={selected.total} isList={isList} />}

            <ScrollListener onReach={next} />
        </div>
    )
}
