import React from 'react'
import cn from './sidebar.module.scss'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import { useTranslation } from 'react-i18next'
import { ReactComponent as UserIcon } from '@/pages/TargetAudience/icons/users.svg'
import { ReactComponent as BilineIcon } from '@/pages/TargetAudience/icons/biline.svg'
import { ReactComponent as YolocoIcon } from '@/pages/TargetAudience/icons/yoloco.svg'
import { ReactComponent as ArrowIcon } from '@/pages/TargetAudience/icons/short_left.svg'
import Button from '@/components/Button'

const Sidebar: React.FC<{ handleClick: (targetType: 'from_us' | 'from_customer') => void }> = ({ handleClick }) => {
    const { t } = useTranslation()

    return (
        <div className={cn['sidebar']}>
            <Flex column className={cn['item']}>
                <Flex column>
                    <Flex margin="0 0 16px">
                        <YolocoIcon />
                        <div className={cn['icon-wrapper']}>
                            <BilineIcon className={cn['beeline']} />
                        </div>
                    </Flex>
                    <Text
                        style={{ lineHeight: '22px' }}
                        margin="0 0 10px"
                        color={'gray-new-4'}
                        component="h3"
                        fSize={'18px'}
                        semibold
                    >
                        {t('target_audience.based_on_beeline_yoloco_data')}
                    </Text>
                    <Text margin="0 0 8px 0" style={{ lineHeight: '16px' }} fSize={'12px'} color={'gray-new-4'}>
                        {t('target_audience.useWideAudience')}
                    </Text>
                </Flex>

                <Button onClick={() => handleClick('from_us')} className={cn['selectButton']}>
                    {t('target_audience.select')}
                    <ArrowIcon />
                </Button>
            </Flex>
            <Flex className={cn['item']}>
                <Flex column>
                    <Flex margin="0 0 16px">
                        <UserIcon />
                    </Flex>
                    <Text
                        style={{ lineHeight: '22px' }}
                        margin="0 0 10px"
                        color={'gray-new-4'}
                        component="h3"
                        fSize={'18px'}
                        semibold
                    >
                        {t('target_audience.based_on_user_data')}
                    </Text>
                    <Text margin="0 0 8px 0" style={{ lineHeight: '16px' }} fSize={'12px'} color={'gray-new-4'}>
                        {t('target_audience.uploadOwnData')}
                    </Text>
                </Flex>
                <Button disabled={true} onClick={() => handleClick('from_customer')} className={cn['selectButton']}>
                    {t('target_audience.select')}
                    <ArrowIcon />
                </Button>
            </Flex>
        </div>
    )
}

export default Sidebar
