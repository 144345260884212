import Loader from '@/components/Loader'
import { IPost } from '@/pages/CampaignManager/types'
import Flex from '@/_yc/Flex'
import React, { FC, useState } from 'react'
import { PostsGrid } from './PostsGrid/PostsGrid'
// import { SearchPostsInput } from './SearchPostsBloggerInput/SearchPostsInput'
import { AddLinksPopup } from '../../SearchBar/AddLinksPopup'
import Text from '@/components/Text'
import { PostList } from './PostList'
import Icons from '@/components/Icons'
import cn from '../../Campaign.module.scss'
import { TrackinStopper } from './components/TrackingStopper'
interface IContent {
    posts: IPost[]
    loading: { loading: boolean; error: boolean }
    campaignState: boolean
}

export const Content: FC<IContent> = ({ posts, loading, campaignState }) => {
    // const [contentPosts, setContentPosts] = useState(posts)
    const [isCard, setisCard] = useState<boolean>(true)
    return (
        <div>
            <Flex
                style={{ padding: '16px', width: '100%', background: 'white', borderRadius: '10px' }}
                content="space-between"
                align="center"
            >
                <Flex align="center" style={{ columnGap: '10px' }}>
                    <AddLinksPopup />
                    <TrackinStopper state={campaignState} />
                </Flex>

                <Flex
                    style={{
                        cursor: 'pointer',
                        height: 'fit-content',
                    }}
                    align="center"
                >
                    <Flex
                        onClick={() => setisCard(!isCard)}
                        align="center"
                        style={
                            isCard
                                ? {
                                      border: 'solid 1px #929EB0',
                                      borderRight: 'none',
                                      borderRadius: '4px 0 0 4px',
                                      padding: '0 11px',
                                      height: '42px',
                                  }
                                : {
                                      borderRadius: '4px 0 0 4px',
                                      padding: '0 11px',
                                      background: '#929EB0',
                                      height: '42px',
                                  }
                        }
                    >
                        <Icons name="grid" className={cn[`content-icon${!isCard ? '-active' : ''}`]} />
                    </Flex>

                    <Flex
                        onClick={() => setisCard(!isCard)}
                        align="center"
                        style={
                            !isCard
                                ? {
                                      border: 'solid 1px #929EB0',
                                      borderLeft: 'none',
                                      borderRadius: '0 4px 4px 0',
                                      padding: '0 11px',
                                      height: '42px',
                                  }
                                : {
                                      borderRadius: '0 4px 4px 0',
                                      padding: '0 11px',
                                      background: '#929EB0',
                                      height: '42px',
                                  }
                        }
                    >
                        <Icons
                            name="lists"
                            className={cn[`content-icon${!isCard ? '' : '-active'}`]}
                            style={{ height: '13px' }}
                        />
                    </Flex>
                </Flex>
            </Flex>
            {/* <SearchPostsInput setContentPosts={setContentPosts} /> */}
            {loading.loading ? (
                <Flex content={'center'} margin="20px 0 0 0">
                    <Loader />
                </Flex>
            ) : loading.error ? (
                <Flex content={'center'} margin="20px 0 0 0">
                    <Text>Something went wrong</Text>
                </Flex>
            ) : !isCard && posts.length === 0 ? (
                <>
                    {/* empty page */}
                    <Flex align="center" content="center" column style={{ width: '100%', height: '380px' }}>
                        <Text color="gray-1" fSize="20px" fWeight="600">
                            List of advertising posts
                        </Text>
                        <Text color="gray-3" fSize="14px" margin="0 0 16px 0">
                            To start detailed stats - add links to advertising posts{' '}
                        </Text>
                        <AddLinksPopup />
                    </Flex>
                </>
            ) : (
                <>{isCard ? <PostList posts={posts} /> : <PostsGrid posts={posts} />}</>
            )}
        </div>
    )
}
