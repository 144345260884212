import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.005 14.012H10.985C10.923 14.012 4.72003 14 3.15503 13.575C2.29445 13.3432 1.62238 12.6707 1.39103 11.81C1.10961 10.2224 0.973709 8.61235 0.985028 7C0.977757 5.38502 1.11733 3.77271 1.40203 2.183C1.63974 1.32151 2.30886 0.645949 3.16803 0.4C4.69003 0 10.722 0 10.978 0H10.999C11.062 0 17.281 0.012 18.83 0.437C19.6888 0.670009 20.3595 1.3411 20.592 2.2C20.8824 3.79354 21.0187 5.41134 20.999 7.031C21.006 8.64403 20.8661 10.2544 20.581 11.842C20.3467 12.7016 19.6742 13.3726 18.814 13.605C17.294 14.008 11.261 14.012 11.005 14.012ZM9.00503 4.005L9.00003 10.005L14.212 7.005L9.00503 4.005Z"
            fill="#EF3B46"
        />
    </svg>
)
