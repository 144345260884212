import { COMERCIAL_SUCCESS, COMERCIAL_FAILURE, COMERCIAL_PROMO_CHECK } from './constants.js'

const defaultState = {
	loaded: false,
	value: {},
	error: false,
	promoChecked: false
}

export default (state = defaultState, action) => {
	switch(action.type){
		case COMERCIAL_SUCCESS: return {
			...state,
			loaded: true,
			value: action.payload
		}
		case COMERCIAL_FAILURE: return {
			...state,
			loaded: true,
			error: true
		}
		case COMERCIAL_PROMO_CHECK: return {
			...state,
			promoChecked: true,
			promocode: action.promocode
		}
		default: return state
	}
}