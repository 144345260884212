import Icons from '@/components/Icons'
import Text from '@/components/Text'
import useOpenClose from '@/_helpers/useOpenClose'
import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import Flex from '../Flex'

import cn from './BloggerArea.module.scss'

const mark = Math.random() + "ASSADAS"

const separatorKeys = ["Enter", ",", ";"]

const BloggerArea = ({ items, value, onChange, onEnter, limit, placeholder }) => {
const [pasted, setPasted] = useState(false)
	const [isFocused, setFocused, blur] = useOpenClose()

	const input = useRef(null)

	const focus = useCallback(e => {
		if (e.target.getAttribute("data-mark") === mark) {
			input.current.focus()
		}
	}, [])

	const onKeyPress = useCallback((e) => {
		if (separatorKeys.includes(e.key)) {
			e.preventDefault()
			onEnter()
		}
	}, [onEnter])


	const handlePaste = useCallback(async () => {
		setPasted(true)
	}, [setPasted])

	const handleChange = (e) =>{
		if(pasted){
			onChange(e.target.value+' ')
			setPasted(false)
			return
		}
		onChange(e.target.value)
	}

	return (
		<div data-mark={mark} className={isFocused ? cn["root--focused"] : cn['root--default']} onClick={focus}>
			<div className={cn.container}>
				{items.map(item => (
					<Item
						key={item.index}
						label={item.label}
						index={item.index}
						onClick={item.onClick}
						color={item.color}
					/>
				))}
				<textarea
					onFocus={setFocused}
					onBlur={blur}
					size="1"
					ref={input}
					value={value}
					onChange={e =>handleChange(e)}
					onKeyPress={onKeyPress}
					onPaste={handlePaste}
					placeholder={placeholder ?? ''}
				/>
			</div>
			{items.length > 0 && (
				<Text fSize={14} color="gray-3" className={cn.counter}>
					{items.length}{limit && ` / ${limit}`}
				</Text>
			)}
		</div>
	)
}

const Item = memo(({ label, onClick, index, color }) => {

	const click = useCallback(() => {
		onClick(index, label)
	}, [onClick, index, label])

	const style = useMemo(() => color && ({ backgroundColor: color }), [color])

	return (
		<Text component={Flex} margin="0 3px 3px 0" align="center" color="white" fSize={12} style={style} onClick={click} className={cn.item}>
			{label}
			<Icons name="close--small" className={cn.icon} />
		</Text>
	)
})

export { BloggerArea as default }