import Icons from '@/components/Icons'
import useClass from '@/_helpers/Hooks/useClass'
import React, { FC } from 'react'

import cn from './ExternalLink.module.scss'

interface ExternalLinkProps {
    link: string
    margin?: string
    className?: string
}
export const ExternalLink: FC<ExternalLinkProps> = (props) => {
    const root = useClass(cn.root, props.className)

    return (
        <a href={props.link} className={root} target="_blank" rel="noopener noreferrer">
            <Icons name="external-link" />
        </a>
    )
}
