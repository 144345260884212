import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export type StringMap = { [key: string]: string | number }

export type T = (str: string, config?: StringMap | null, disableNS?: boolean) => string

const useSpaceTranslation = (space: string): T => {
    const { t } = useTranslation()
    const result: T = useCallback(
        (str: string, config?: StringMap | null, disableNS?: boolean) =>
            t((disableNS ? '' : space + '.') + str, config ?? undefined),
        [t, space]
    )

    return result
}

export default useSpaceTranslation
