import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.00008 10.6667C10.841 10.6667 12.3334 9.17436 12.3334 7.33342C12.3334 5.49247 10.841 4.00008 9.00008 4.00008C7.15913 4.00008 5.66675 5.49247 5.66675 7.33342C5.66675 9.17436 7.15913 10.6667 9.00008 10.6667ZM9.00008 5.66675C9.92056 5.66675 10.6667 6.41294 10.6667 7.33342C10.6667 8.25389 9.92056 9.00008 9.00008 9.00008C8.07961 9.00008 7.33342 8.25389 7.33342 7.33342C7.33342 6.41294 8.07961 5.66675 9.00008 5.66675Z"
            fill="#8F8F8F"
        />
        <path
            d="M2.33342 17.3334C1.41294 17.3334 0.666748 16.5872 0.666748 15.6667V2.33342C0.666748 1.41294 1.41294 0.666748 2.33341 0.666748H15.6667C16.5872 0.666748 17.3334 1.41294 17.3334 2.33341V15.6667C17.3334 16.5872 16.5872 17.3334 15.6667 17.3334H2.33342ZM2.33341 2.33341V15.6667H4.83342C4.83342 13.3656 6.6989 11.5001 9.00008 11.5001C11.3013 11.5001 13.1667 13.3656 13.1667 15.6667H15.6667V2.33341H2.33341ZM11.5001 15.6667C11.5001 14.286 10.3808 13.1667 9.00008 13.1667C7.61937 13.1667 6.50008 14.286 6.50008 15.6667H11.5001Z"
            fill="#8F8F8F"
        />
    </svg>
)
