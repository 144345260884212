import Icons from '@/components/Icons'

import React from 'react'
import { Adapter } from '../../Adapter'
import ItemsHead from '../instagram/ItemsHead'
import SearchItem from './SearchItem'
import cn from '../youtube/YoutubeItems.module.scss'
import { pageToOffset } from './utils/queries/pageToOffset'
import { GraphQLError } from 'graphql'
import service from '@/pages/Auth/LoginPage/Model/_service'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { getFilters, getMainFilters } from './Filters/filters'
import { getChannelsCountQuery, getChannelsQuery } from './queries'
import { getFilterQueries } from './utils/filters/filterQueries'
import { IChannels, ISearchBy, QueryType } from './types'
import { getItems } from './utils/preprocessData'
import { getSearchByTable } from './utils/keywords/searchByTable'
import { Platform } from '@/pages/Reports/types'

const getIsFullTariff = async () => {
    // @ts-ignore
    const platformsRes = await service.getSubscriptionPlatforms()
    const platforms: string[] = platformsRes.tariff?.platforms
    const isTariffIncludesYoutube = platforms?.includes('youtube') || false
    return !isTariffIncludesYoutube
}

const fetcher = new Fetcher()
export const YoutubeAdapter = new Adapter({
    Head: () => <ItemsHead platform={Platform.YouTube} />,
    background: `linear-gradient(180deg, #F55C5C 0%, #D93C3C 100%)`,
    Icon: () => <Icons className={cn.icon} name="yt" />,
    fetcher: async (_, config) => {
        const client = fetcher.getClient()

        const offSet = pageToOffset(config.page)
        const search = config.search && `args:{search:"${config.search}"},`
        const queryType: QueryType = search === '' ? 'youtube_channel' : getSearchByTable(config.by as ISearchBy[])

        const queries = getFilterQueries(config.filters)
        const queryParams = {
            orderBy: config.sorting,
            offset: offSet,
            filters: queries,
        }

        // const amount = await client.query({
        //     query: getChannelsCountQuery(queryType, search, queryParams),
        // })

        const channels = await client.query<IChannels>({
            query: getChannelsQuery(queryType, search, queryParams),
        })

        const isFullTariff = await getIsFullTariff()
        const hasNext = !isFullTariff || config.page !== 1
        const sliceIndex = isFullTariff && (config.search !== '' || queries.length > 0) ? 3 : 30

        if (channels.error) {
            console.error(new GraphQLError(channels.error.message), channels.error.name)
        }

        return {
            hasNext: hasNext,
            total: 0, //amount.data[`${queryType}_aggregate`].aggregate.count,
            items: getItems(channels, queryType, sliceIndex),
            isHidden: isFullTariff,
        }
    },
    countCheck: async (token, config) => {
        const client = fetcher.getClient()

        const search = config.search && `args:{search:"${config.search}"},`
        const queryType: QueryType = search === '' ? 'youtube_channel' : getSearchByTable(config.by as ISearchBy[])

        const queries = getFilterQueries(config.filters)
        const queryParams = {
            orderBy: config.sorting,
            offset: 0,
            filters: queries,
        }

        const amount = await client.query({
            query: getChannelsCountQuery(queryType, search, queryParams),
        })

        return {
            count: amount.data[`${queryType}_aggregate`].aggregate.count,
        }
    },
    keyWords: [
        { label: 'forKeywords.channelName', value: 'title' },
        { label: 'forKeywords.channelDescription', value: 'description' },
    ],
    sortings: [
        {
            label: `forKeywords.subCount`,
            value: 'subscriber_count: desc_nulls_last',
        },
        {
            label: 'forKeywords.medianER',
            value: 'engagement: desc_nulls_last',
        },
        {
            label: 'forKeywords.medianViews',
            value: 'average_video_watch: desc_nulls_last',
        },
    ],
    mainFilters: getMainFilters(fetcher) as any,
    filters: getFilters(fetcher),
    templateColumns: '40px 40px 50px .8fr .3fr .3fr 1fr 250px',
    title: 'YouTube',
    Item: SearchItem,
})
