import React, { useRef, FC } from 'react'
import { useDispatch } from 'react-redux'

import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import useOpenClose from '@/_helpers/useOpenClose'
import Text from '@/components/Text'
import Popup from '@/_yc/Popup/Popup'
import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'
import useToken from '@/_helpers/Hooks/useToken'
import notif from '@/components/Notifier/model/actions.js'
import ApprovePopup from '@/components/ApprovePoup'

import { resendMailsReq } from '../../../Service/Chats'

import cn from './InstrumentsPopUp.module.scss'

interface IInstrumentsPopUp {
    curCampaign: string
}
const InstrumentsPopUp: FC<IInstrumentsPopUp> = ({ curCampaign }) => {
    const t = useSpaceTranslation('outreach.messenger.all_chats')
    const ref = useRef(null)
    const [isOpen, open, close] = useOpenClose()
    const token = useToken()
    const dispatch = useDispatch()

    const [isOpenApprove, openApprove, closeApprove] = useOpenClose()

    if (curCampaign === '') return null

    const handleResend = () => {
        if (!token) return
        resendMailsReq(token, curCampaign)
            .then(() => {
                dispatch(notif.success([t('resend_success')]))
            })
            .catch(() => {
                dispatch(notif.error([t('resend_error')]))
            })
        close()
    }
    return (
        <>
            <Text ref={ref} fSize={16} color={'gray-3'} className={cn.dots} onClick={open}>
                &bull;&bull;&bull;
            </Text>
            {isOpen && ref && ref.current && (
                <>
                    <Popup placement="bottom-end" isOpen={isOpen} onClose={close} anchor={ref.current}>
                        <Flex className={cn.popup} column>
                            <Flex align="center" onClick={openApprove}>
                                <Icons name={'redo'} />
                                <p>{t('resend')}</p>
                            </Flex>
                        </Flex>
                    </Popup>
                </>
            )}
            <ApprovePopup
                isOpen={isOpenApprove}
                close={closeApprove}
                approveFunction={handleResend}
                title={t('approvePopup.title')}
                subTitle={t('approvePopup.sub_title')}
                approve={t('approvePopup.yes')}
                cancel={t('approvePopup.no')}
            />
        </>
    )
}

export default InstrumentsPopUp
