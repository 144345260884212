import React from 'react'
import { SelectorFilter } from '@/pages/SearchPage/SearchAdapter/filters/SelectorFilter'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import Icons from '@/components/Icons'
import { GET_TIKOK_LANG } from '../../../utils/queries'
import { preprocessLanguages } from '../../../utils/PreprData'
import { ITikokLangRequest } from '../../../utils/types'

const getGqlFilter = (languages: Array<string>, percentage: number) => {
    if (!languages) return ''

    return `audience_lang: {code: "${languages}", weight: ${(percentage ? percentage : 10) / 100}}`
}

export const getAudLanguageFilter = (fetcher: Fetcher) => {
    return new SelectorFilter({
        platform: 'infl_tiktok',
        title: 'filters.aud_language.label',
        helper: 'filters.aud_language.text',
        icon: <Icons name="filter--language" />,
        autocomplete: true,
        space: 1,
        hasPercentage: true,
        isMulti: false,
        options: async () => {
            const client = fetcher.getClient()
            return await client.query<ITikokLangRequest>({ query: GET_TIKOK_LANG }).then((res) => {
                const languages = res.data?.getTikTokLang.data.data
                return preprocessLanguages(languages)
            })
        },
        beforeSend: ({ value, percentage }) => {
            return {
                name: 'audience.country',
                type: '',
                value: value,
                gqlFilter: getGqlFilter(value, percentage),
            }
        },
    })
}
