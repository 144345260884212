import React from 'react'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { SelectorFilter } from '../../../../filters/SelectorFilter'

import { PLATFORM } from '../consts'
import Icons from '@/components/Icons'

const getGqlFilter = (value: string[]) => {
    if (value.length > 0 && value[0] === 'info.email') {
        return `email: {_is_null: false}`
    }
    return ''
}

export const getContactsFilter = (fetcher: Fetcher) => {
    return new SelectorFilter({
        platform: PLATFORM,
        title: 'filters.contacts.label',
        helper: 'filters.contacts.text',
        icon: <Icons name="mail" />,
        space: 1,
        isMulti: true,
        options: [{ label: 'filters.contacts.mail', value: 'info.email', translate: true }],
        beforeSend: ({ value }) => {
            return {
                name: 'location.country',
                type: 'array',
                value: value,
                gqlFilter: getGqlFilter(value),
            }
        },
    })
}
