import React, {
    useEffect,
    // useMemo,
    useRef,
} from 'react'
import PageLayout from '@/Layouts/PageLayout'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Flex, GlobalWidth } from '@/_yc'
import Header from '@/Layouts/ReportLayout/Header/Header'
import Text from '@/components/Text'
import Arrow from '@/components/Share/BackButton/Arrow'
import PartChanger from '@/Layouts/ReportLayout/Extra/PartChanger'
import { RootStore } from '@/_helpers/StoreType'
import { useQuery } from '@apollo/client'
import useChannelId from '@/_helpers/useChannelId'
import { push } from '@/components/Notifier/PushNotification'
import LegacyNotification from '@/components/Notifier/MessageTypes/LegacyNotification'
import { setTikTokRawData } from '@/Layouts/ReportLayout/Model/actions'
import Segment from '@/Layouts/ReportLayout/Content/Segment'
// import { ContextMenu } from '@/_yc'
import LoadingButton from '@/components/Share/LoadingButton'
import { Soon } from '@/components/Soon'
import Loader from '@/components/Loader'

import { ReportHeader } from './ReportHeader/ReportHeader'
import { isNotEnoughReportsError } from '../OpenReportPage/utils'
import { GET_TIKTOK_REPORT } from './queries'
import { Platform } from '../types'
import GrowGraph from './GrowGraph/GrowGraph'
import Demographics from './Demographics'
import Content from './Content/Content'
import Posts from './Posts'
// import { tiktokMockData } from './mockData'
import {
    //  IRawTikTokData,
    ITiktokReportResponse,
} from './types'

import cn from './TikTokReport.module.scss'

export const TikTokReport = () => {
    const { t } = useTranslation()
    const graphRef = useRef(null)
    const audienceRef = useRef(null)
    const contentRef = useRef(null)
    // const activityRef = useRef(null)
    const postsRef = useRef(null)

    const channelId = useChannelId(3)

    const history = useHistory()

    const dispatch = useDispatch()

    const tiktokDataStore = useSelector((store: RootStore) => {
        return store.report.rawTikTokData
    })

    const { loading, data, error } = useQuery<ITiktokReportResponse>(GET_TIKTOK_REPORT, {
        variables: {
            id: channelId,
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        skip: tiktokDataStore !== undefined,
    })

    //error handling
    useEffect(() => {
        const isNotEnoughReports = error && isNotEnoughReportsError(error)
        if (isNotEnoughReports) {
            push(new LegacyNotification(t('not_enough_reports'), 'error'))
            history.push('/billing')
        }
    }, [error, t, history])

    //put to redux store
    useEffect(() => {
        if (loading || error || !data || !data.getTikTokReport?.data?.success) {
            return
        }
        dispatch(setTikTokRawData(data.getTikTokReport.data))
    }, [tiktokDataStore, data, error, loading, dispatch])

    //mock data
    // useEffect(() => {
    //     dispatch(setTikTokRawData(tiktokMockData))
    // }, [dispatch])

    return (
        <PageLayout color={'common-background'} className={cn.root} title={t('titles.t2')}>
            <GlobalWidth style={{ padding: '26px 50px' }}>
                <Header>
                    <Flex content="space-between" style={{ width: '1080px' }}>
                        <Link to={`/search/${Platform.Instagram}`}>
                            <Flex grow align="center" content="space-between">
                                <Arrow className={cn.arrow} />
                                <Text margin={'0 0 0 10px'} color="gray-3">
                                    {t('report_layout.go_back')}
                                </Text>
                            </Flex>
                        </Link>
                        {/* <ContextMenu
                            t={t}
                            reportButton
                            margin={'0 20px 0 0'}
                            username={twitchData?.login}
                            style={{ width: 'auto' }}
                            id={twitchData?.id}
                            type={Platform.Twitch}
                        /> */}
                        {/* <PDFBar margin="0 0 0 15px" onClick={undefined} loading={false} /> */}
                        <div className={cn.pdfSoon}>
                            <div className={cn['pdfSoon-icon']}>
                                <Soon
                                    style={{ backgroundColor: '#7685ff', padding: '1px 6px' }}
                                    textStyle={{ color: 'white' }}
                                />
                            </div>
                            <LoadingButton
                                disabled
                                loading={false}
                                onClick={undefined}
                                style={{ color: 'violet-1', fontSize: '12px' }}
                            >
                                {t('report.download')}
                            </LoadingButton>
                        </div>
                    </Flex>
                </Header>

                {loading && !error && (
                    <Flex margin="20px 0" align={'center'} content="center">
                        <Loader />
                    </Flex>
                )}

                {error && (
                    <Text type="h2" size={22} color="secondary" align="center">
                        {t('common_error')}
                    </Text>
                )}

                {!loading && !error && (
                    <>
                        <div className={cn.main}>
                            <ReportHeader />

                            <PartChanger
                                values={[
                                    {
                                        title: t('twitch_report.account_info.title'),
                                        value: graphRef,
                                    },
                                    {
                                        title: t('report_titles.auditory.title'),
                                        value: audienceRef,
                                    },
                                    {
                                        title: t('report_titles.content.title'),
                                        value: contentRef,
                                    },
                                    {
                                        title: t('report_layout.pop_posts.title'),
                                        value: postsRef,
                                    },
                                ]}
                            />
                        </div>

                        <Segment ref={graphRef} title={t('report_titles.account_data.title')}>
                            <GrowGraph />
                        </Segment>

                        {/*
                <Segment className={cn.segment} ref={activityRef} title={t('report_titles.activity.title')}>
                    <Activity />
                </Segment>
*/}
                        <Segment className={cn.segment} ref={audienceRef} title={t('report_titles.auditory.title')}>
                            <Demographics />
                        </Segment>

                        <Segment className={cn.segment} ref={contentRef} title={t('report_titles.auditory.title')}>
                            <Content />
                        </Segment>
                        <Segment className={cn.segment} ref={postsRef} title={t('report_layout.pop_posts.title')}>
                            <Posts />
                        </Segment>
                    </>
                )}
            </GlobalWidth>
        </PageLayout>
    )
}
