export type Sizes = 'mobile' | 'tablet' | 'desktop'
export type breakSizesType = {
    mobile?: number
    tablet?: number
}

export default (width: number, breakSizes?: breakSizesType): Sizes => {
    const mobileSize = breakSizes?.mobile || 600
    const tabletSize = breakSizes?.tablet || 1000

    if (width <= mobileSize) return 'mobile'
    if (width <= tabletSize) return 'tablet'
    return 'desktop'
}
