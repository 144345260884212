import React from 'react';

export function shortNum(arg, fixed = 1, fixedForSmall = 0) {

	const num = +arg;
	if (!num && num!==0) {
		console.error(`Passed value is not assignable to type number. Value: `, arg)
	}

	if (num < 1000) return num.toFixed(fixedForSmall)
	else if(num<1000000) return (num/1000).toFixed(fixed)+"K"
	else return (num/1000000).toFixed(fixed)+"M"
}

export function shortStr(str = "", lim = 14, equality = false){
	if (!str) return ""
	if(str.length<=lim){
		if (!equality) return str
		let computed = lim-(str.length+4);

		if(computed < 0) computed = 0;
		
		return (
			<span style={{ "word-break": "break-all" }}>
				{str}<span style={{ "word-wrap": "break-word", opacity: 0 }}>
					{new Array(computed).fill("s")}
				</span>
			</span>
		)
	}
	return str.substr(0, lim)+"..."
}