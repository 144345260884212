import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.598 12.6453C6.24398 12.6464 5.90427 12.5057 5.65467 12.2547L0.431999 7.03132C0.156391 6.7565 0.0150722 6.37469 0.0453321 5.98665L0.378665 1.60932C0.426198 0.950911 0.950796 0.427734 1.60933 0.381987L5.98667 0.0486541C6.02067 0.0413208 6.05533 0.0413208 6.08933 0.0413208C6.44256 0.0422276 6.78113 0.18264 7.03133 0.431987L12.2547 5.65465C12.5048 5.90472 12.6454 6.24394 12.6454 6.59765C12.6454 6.95137 12.5048 7.29059 12.2547 7.54065L7.54067 12.2547C7.29122 12.5055 6.95178 12.6462 6.598 12.6453ZM6.08867 1.37465L1.708 1.70799L1.37467 6.08865L6.598 11.312L11.3113 6.59865L6.08867 1.37465ZM3.76933 5.10266C3.13323 5.10279 2.58561 4.65356 2.46139 4.0297C2.33716 3.40584 2.67094 2.78111 3.25858 2.53757C3.84622 2.29404 4.52406 2.49953 4.87755 3.02838C5.23104 3.55722 5.16173 4.26212 4.712 4.71199C4.46255 4.96287 4.12312 5.10354 3.76933 5.10266Z"
                fill="#393F49"
            />
        </svg>
    )
}
