import React, { ForwardRefRenderFunction, Ref, HTMLAttributes } from 'react'

import cn from './GlobalWidth.module.scss'

export interface IGlobalWidth extends HTMLAttributes<HTMLDivElement> {
    ref?: Ref<HTMLDivElement>
    margin?: string
    fixedWidth?: boolean
}

const GlobalWidth: ForwardRefRenderFunction<HTMLDivElement, IGlobalWidth> = (props, ref) => {
    const { margin, className, style, fixedWidth, ...rest } = props

    return (
        <div
            {...rest}
            ref={ref}
            className={fixedWidth ? `${cn.fixedWidth} ${className || ''}` : `${cn.root} ${className || ''}`}
            style={{
                ...style,
                margin,
            }}
        >
            {props.children}
        </div>
    )
}

export default GlobalWidth
