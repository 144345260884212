import { FC, memo, useMemo, ReactElement } from 'react'
import { parseDate } from '@/_helpers'

export interface IParsedDate {
    dateString: string
    monthAsNumber?: boolean
    children: (date: { date: number; month: string; year: number }) => ReactElement
}

const ParsedDate: FC<IParsedDate> = (props) => {
    const date = useMemo(() => {
        const res = (parseDate as any)(props.dateString, true, Boolean(props.monthAsNumber))
        return res
    }, [props.dateString, props.monthAsNumber])

    return props.children(date as { date: number; month: string; year: number })
}

export default memo(ParsedDate)
