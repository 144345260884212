import React, { FC, memo, useState } from 'react'

import { Segment } from '@/Layouts/ListLayout'
import Text from '@/components/Text'
import Avatar from '@/components/Avatar'
import { getShortString } from '@/_helpers/strings/getShortString'
import { shortNum } from '@/_helpers/_graphs/_tech'
import Flex from '@/_yc/Flex'
import Button from '@/components/Button'
import { useSpaceTranslation } from '@/_helpers'
import { ExternalLink } from '@/components/Share/ExternalLink/ExternalLink'
import Locker from '@/components/Locker'
import { Platform } from '@/pages/Reports/types'
import useOpenClose from '@/_helpers/useOpenClose'
import { AddCommentToBloggerReq, CreateCommentToBloggerReq } from '@/pages/Lists/model/service'
import useToken from '@/_helpers/Hooks/useToken'
import { Comment, NewComment } from '@/components/Comment/Comment'
import BloggerLink from '@/components/Share/BloggerLink'
import { MassAddCheckBoxNEW } from '@/components/MassAddToList'
import AddToList from '@/pages/Lists/components/AddToList/AddToList'
import { trackOpenReport } from '@/_helpers/amplitude/track'
import { Like } from '@/components/Like/Like'
import { ISearchItem } from '../utils/types'
import Topics from '@/pages/SearchPage/Bloggers/Topics/Topics'

import cn from './SearchItem.module.scss'

interface SearchItemProps extends ISearchItem {
    index: number
    margin?: string | number
    likedId?: number
    comment?: { comment: string; comment_id: number }
}

const overflow = {
    overflow: 'hidden',
}

const SearchItem: FC<SearchItemProps> = (props) => {
    const t = useSpaceTranslation('Influencer_explorer')
    const locked = !props.unlocked

    // comment
    const token = useToken()
    const [isOpen, open, close] = useOpenClose()
    const [comment, setComment] = useState<string | undefined>(props.comment?.comment)

    const addComment = (valueComment: string) => {
        if (!token) return
        if (props.comment?.comment_id)
            AddCommentToBloggerReq(token, valueComment, props.id, props.comment.comment_id).then(() => {
                setComment(valueComment)
            })
        else
            CreateCommentToBloggerReq(token, valueComment, props.id).then(() => {
                setComment(valueComment)
            })
        close()
    }

    const handleOpen = (e: any) => {
        e.preventDefault()
        open()
    }
    return (
        <div className={cn.row}>
            <div>
                <MassAddCheckBoxNEW
                    data={{
                        hasEmail: false,
                        avatar: props.avatar,
                        name: props.fullName,
                        username: props.username,
                    }}
                    id={props.id + ''}
                    externalId={props.id + ''}
                    type={Platform.Tg}
                    key={props.id}
                    index={props.index}
                />
            </div>

            <Text fSize={12}>{props.index}</Text>

            <Flex content="flex-start" align="center">
                <Avatar src={props.avatar} />
                <Segment margin="0 0 0 20px" column style={overflow}>
                    <Flex align="center">
                        {props.fullName && (
                            <Text className={cn['text-overflow']} component="h6" fSize={16} semibold margin="0 5px 0 0">
                                {getShortString(props.fullName, 18)}
                            </Text>
                        )}
                        {(!comment || comment.length === 0) && (
                            <Flex className={cn['new-comment']}>
                                <NewComment handleOpen={handleOpen} />
                            </Flex>
                        )}
                    </Flex>
                    <Text
                        className={cn['text-overflow']}
                        color="gray-3"
                        // component={BloggerLink}
                        // isDemo={locked}
                        // semibold={false}
                        // id={props.id}
                        fSize={14}
                        // newTab
                    >
                        @{props.username}
                    </Text>
                </Segment>

                {/* {(!comment || comment.length === 0) && (
                    <Flex className={cn['new-comment']}>
                        <NewComment handleOpen={handleOpen} />
                    </Flex>
                )} */}
            </Flex>

            <Text margin="0 0 0 12px" fSize={12}>
                {shortNum(props.followers)}
            </Text>

            <Text margin="0 0 0 12px" fSize={12}>
                {(props.averageViews * 100).toFixed(2) + '%'}
            </Text>

            <Text margin="0 0 0 12px" fSize={12}>
                {props.tg_posts_views_count && shortNum(props.tg_posts_views_count)}
            </Text>

            <Topics topics={props.topics} />

            <Flex content="flex-end" align="center" style={{ gap: 10 }}>
                {locked ? (
                    <Locker
                        style={{
                            width: 120,
                            flexGrow: 0,
                            padding: 0,
                        }}
                        platform={Platform.Tg}
                        isPromo={false}
                        id={props.id}
                        label={t('found_blogger.unlock')}
                    />
                ) : (
                    <BloggerLink newTab id={props.id} platform={Platform.Tg}>
                        <Button
                            width={120}
                            size="small"
                            style={{ padding: 0 }}
                            variant={locked ? 'outline' : 'primary'}
                            onClick={() => trackOpenReport('infl_exp_tg')}
                        >
                            {locked
                                ? t('found_blogger.unlock')
                                : false
                                ? t('found_blogger.analyze_button_promo')
                                : t('found_blogger.analyze_button_label')}
                        </Button>
                    </BloggerLink>
                )}
                <ExternalLink link={`https://t.me/${props.username}/`} />

                <div>
                    <AddToList type={Platform.Tg} external_id={props.id} place="infl_exp_tg" />
                </div>

                <Flex>
                    <Like externalId={props.id} likeId={props.likedId} platform={Platform.Tg} />
                </Flex>
            </Flex>
            <Comment
                isOpen={isOpen}
                open={open}
                close={close}
                value={comment}
                setValue={setComment}
                submit={addComment}
                style={{ gridColumn: '3/9', margin: '0 0 0 62px' }}
            />
        </div>
    )
}

export default memo(SearchItem)
