import React, { FC, memo } from 'react'

import Flex from '@/_yc/Flex'
import { IPost } from '@/pages/CampaignManager/types'
import { PostCover } from '@/pages/SearchPage/SearchAdapter/adsExplorer/PostCover/PostCover'
// import { getPostLink } from '@/pages/SearchPage/SearchAdapter/adsExplorer/instagram/utils/getPostLink'
// import BloggerLink from '@/components/Share/BloggerLink'
import Text from '@/components/Text'
import moment from 'moment'
import Icons from '@/components/Icons'
import { getNumberWithSpaces } from '@/_helpers/nums/numberWithSpaces'
// import { Platform } from '@/pages/Reports/types'
import { shortNum } from '@/_helpers/_graphs/_tech'
import Avatar from '@/components/Avatar'
// import Button from '@/components/Button'
import { PostReport } from '../../PostReport'

import cn from './PostItem.module.scss'
import { IncorrectLink } from '../../components/IncorrectLink'
import { useTranslation } from 'react-i18next'
export interface IPostItem extends IPost {
    index: number
    margin?: string | number
}

const PostItem: FC<IPostItem> = (props) => {
    const { t } = useTranslation()
    const openPost = () => {
        // window.open(getPostLink(props?.shortCode))
    }

    return (
        <div className={cn.postItem}>
            <Flex className={cn['postItem--header']} content="space-between">
                <Flex align="center">
                    <div className={cn.bloggerAvatar}>
                        <Avatar
                            alt="AVA"
                            src={props?.avatar_url ?? ''}
                            size={32}
                            loadingAvatarWidth="32"
                            aria-label="Аватарка"
                            className={cn.ava}
                        />
                    </div>

                    <div className={cn['postItem--header--info']}>
                        {props?.blogger_info?.username ? (
                            <Text color={'gray-1'} fSize={12} bold>
                                {props?.blogger_info?.username ?? ''}
                            </Text>
                        ) : props?.blogger_info?.full_name ? (
                            <Text color={'gray-1'} fSize={12} bold>
                                {props?.blogger_info?.full_name ?? ''}
                            </Text>
                        ) : props?.channel_name ? (
                            <Text color={'gray-1'} fSize={12} bold>
                                {props?.channel_name ?? ''}
                            </Text>
                        ) : (
                            <div
                                style={{
                                    width: '100px',
                                    height: '16px',
                                    borderRadius: '10px',
                                    backgroundColor: '#F2F4F7',
                                }}
                            />
                        )}
                        <Text color={'gray-3'} fSize={10}>
                            {props?.blogger_info?.followers !== undefined && props?.blogger_info?.followers !== null
                                ? shortNum(props?.blogger_info?.followers) +
                                  ' ' +
                                  t('campaign_manager.campaign.content.subs')
                                : ''}
                        </Text>
                    </div>
                </Flex>
                <Flex className={cn.socials} align="center" margin="0 0 0 8px">
                    {props?.media_not_found ? (
                        <>
                            <IncorrectLink />
                        </>
                    ) : props?.service_type === 'youtube' ? (
                        <>
                            <Icons name="ie-youtube" />
                        </>
                    ) : props?.service_type === 'instagram' ? (
                        <>
                            <Icons name="ie-instagram" />
                        </>
                    ) : props?.service_type === 'vk' ? (
                        <>
                            <Icons name="ie-vk" />
                        </>
                    ) : props?.service_type === 'telegram' ? (
                        <>
                            <Icons name="ie-tg" />
                        </>
                    ) : (
                        <></>
                    )}
                </Flex>
            </Flex>

            <Flex column style={{ padding: '0 8px', position: 'relative' }}>
                <a href={props?.url} target="_blank" rel="noopener noreferrer">
                    <Flex content={'center'} onClick={openPost} style={{ cursor: 'pointer' }}>
                        <PostCover
                            url={props?.preview_url ? props?.preview_url : ''}
                            media_url={props?.media_url ? props.media_url : props.url}
                            // isInst={props?.service_type === 'instagram'}
                            // shortCode={props?.code}
                        />
                    </Flex>
                </a>
                <Flex
                    content={'space-between'}
                    style={
                        props?.likes !== undefined &&
                        props?.likes !== null &&
                        props.comments !== undefined &&
                        props.comments !== null
                            ? { position: 'absolute', bottom: 0, width: 260 }
                            : { display: 'none' }
                    }
                >
                    <Flex content={'flex-start'} align={'center'}>
                        <Icons name={'likes'} className={cn.whiteIcon} />
                        <Text margin={'0 0 0 10px'} color="white">
                            {props?.likes && getNumberWithSpaces(props?.likes)}
                        </Text>
                    </Flex>

                    <Flex content={'flex-start'} align={'center'}>
                        <Icons name={'comments'} className={cn.whiteIcon} />
                        <Text margin={'0 0 0 10px'} color="white">
                            {props?.comments && getNumberWithSpaces(props?.comments)}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>

            <div className={cn['postItem--info']}>
                <div className={cn['postItem--info--top']}>
                    {/* <BloggerLink newTab id={props?.channel_id} platform={Platform.Instagram}>
                        <Text color="gray-1" fontSize={12}>
                            {'fullname'}
                        </Text>
                    </BloggerLink> */}

                    <Text color="gray-3" font-size={12}>
                        {props?.created_at ? moment(props?.created_at).format('DD.MM.YYYY') : ''}
                    </Text>
                    {/* {props?.blogger_info?.reach !== undefined && props?.blogger_info?.reach !== null ? (
                        <Text color="gray-3" font-size={12}>
                            {t('campaign_manager.campaign.content.header.reach') +
                                ': ' +
                                shortNum(props?.blogger_info?.reach)}
                        </Text>
                    ) : (
                        <></>
                    )} */}
                </div>

                <Text color={'gray-1'} fSize={12} margin={'8px 0'}>
                    {/* {props?.data?.text && props?.data?.text.slice(0, 110)} */}
                </Text>

                <Flex style={{ width: '100%' }}>
                    <PostReport
                        post_id={props?.video_id ?? props?.media_id ?? props?.code ?? props?.message_id}
                        platform={props?.service_type}
                        disabled={props?.isRequest}
                        channel_name={props?.channel_name}
                    />
                </Flex>
            </div>
        </div>
    )
}

export default memo(PostItem)
