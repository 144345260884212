export interface Subscription {
    expired: boolean
    unsubscribe: VoidFunction
}

type updateFunction = (size: number) => void

export default class Observable<MessageType> {
    private subs: Array<(message: MessageType) => void> = []

    public onSubscribe?: updateFunction
    public onUnsubscribe?: updateFunction

    public update(message: MessageType) {
        this.subs.forEach((i) => i(message))
    }

    subscribe(cb: (message: MessageType) => void): Subscription {
        this.subs.push(cb)
        this.onSubscribe && this.onSubscribe(this.subs.length)

        const result = {
            expired: false,
            unsubscribe: () => {
                result.expired = true
                this.subs.splice(this.subs.indexOf(cb), 1)
                this.onUnsubscribe && this.onUnsubscribe(this.subs.length)
            },
        }

        return result
    }

    clear() {
        this.subs = []
    }

    size(): number {
        return this.subs.length
    }
}
