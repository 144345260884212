import React, { FC, useEffect, useState } from 'react'
import { Flex } from '@/_yc'
import NavFooter from '@/pages/TargetAudience/components/NavFooter'
import Step from '@/pages/TargetAudience/Views/components/Step/Step'
import Interests from '@/pages/TargetAudience/Views/components/Interests'
import cn from './step-four.module.scss'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Profile } from '@/pages/TargetAudience/icons/profile.svg'
import { AudienceInterestsResponse, Category, Language } from '@/pages/TargetAudience/services/types'
import { transformAudienceData, transformInterests } from '@/pages/TargetAudience/utils'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import { createInterestTargetsReq, fetchAllTargetData } from '@/pages/TargetAudience/services/services'
import useToken from '@/_helpers/Hooks/useToken'
import Loader from '@/components/Loader'

const StepFour: FC = () => {
    const { t } = useTranslation()
    const { i18n } = useTranslation()
    const interests = useSelector<RootStore, AudienceInterestsResponse>((state) => state.targetAudience.interests)

    const [data, setData] = useState<Category[]>([])
    const targetType = useSelector<RootStore, 'from_customer' | 'from_us'>(
        (state) => state.targetAudience.campaign?.target_type
    )
    const campaignId = parseInt(localStorage.getItem('campaignId') || '1')
    const token = useToken()
    const [selectedInterests, setSelectedInterests] = useState<Category[]>([])
    const [loading, setLoading] = useState(false)

    const handleInterestsChange = (newInterests: Category[]) => {
        setSelectedInterests(newInterests)
    }

    useEffect(() => {
        if (interests) {
            setData(transformAudienceData(interests, i18n.language.split('-')[0] as Language))
        }
    }, [interests, i18n.language])

    const submitHandler = async () => {
        await createInterestTargetsReq(
            token,
            campaignId,
            selectedInterests.map((option) => option.values.map((v) => Number(v.value))).flat()
        )
    }

    useEffect(() => {
        const loadData = async () => {
            setLoading(true)
            try {
                const targetData = await fetchAllTargetData(token, campaignId)
                if (targetData) {
                    transformDataForState(targetData)
                }
            } catch (error) {
                console.error('Ошибка загрузки данных целевой аудитории:', error)
            } finally {
                setLoading(false)
            }
        }

        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, campaignId, interests])

    const transformDataForState = (data: any) => {
        if (data.interests && interests) {
            const transformedInterests = transformInterests(interests, data.interests, i18n.language as Language)
            setSelectedInterests(transformedInterests)
        }
    }

    return (
        <Flex column className={cn['main']}>
            <Flex className={cn['container']}>
                <Step
                    step={`3 ${t('target_audience.of')} 5`}
                    title={t('target_audience.audience_clarification')}
                    description={'target_audience.selectInterestsSubTitle'}
                    targetingType={targetType}
                    icon={<Profile />}
                />

                <Flex column className={cn['sidebar']}>
                    {!loading ? (
                        <Interests
                            onInterestsChange={handleInterestsChange}
                            initialSelectedOptions={selectedInterests}
                            options={data}
                        />
                    ) : (
                        <Loader style={{ margin: 'auto' }} />
                    )}
                </Flex>
            </Flex>
            <NavFooter callBack={submitHandler} prev={'/target/new/three'} next={'/target/additional-options'} />
        </Flex>
    )
}

export default StepFour
