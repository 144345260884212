import { AQType, NativeReportData, ValueLabelColorModel, IReportReadyStatus } from './Types'

import colors from './colorMap.json'

export const BlockTitle = (title: string, ref: any, marginBottom?: string) => ({
    type: 'block_title',
    title: title,
    myRef: ref,
    marginBottom: marginBottom,
})

export const GrowGraph = (src: NativeReportData) => ({
    type: 'line_chart',
    info: src,
})

export const AllocationGraph = (src: NativeReportData) => ({
    type: 'allocation_chart',
    info: src.erSpread.values.map((item) => ({ x: item.value[1].value, y: item.value[0].value, fillColor: '#6071FF' })),
})

export const LookALineList = (src: NativeReportData, space: number = 1) => ({
    type: 'list',
    space,
    title: 'lookalikes',
    data: src.chaining.values,
})

export const ErSpread = (src: NativeReportData, space: number = 1) => ({
    type: 'er_spread',
    title: 'er_spread',
    space,
    data: src.erSpread.values.map((v) => ({
        label: v.label,
        url: v.url,
        value: v.value.map((inner) => ({
            color: inner.color === '#6172FF' ? colors.erSpread.comments : colors.erSpread.likes,
            value: inner.value,
        })),
    })),
    legend: [
        {
            color: colors.erSpread.likes,
            label: 'likes',
        },
        {
            color: colors.erSpread.comments,
            label: 'comments',
        },
    ],
})

export const UserTagsChips = (src: NativeReportData, space: number = 1) => ({
    type: 'chips',
    title: 'userTags',
    data: src.userTags.values.map((item) => item.label).slice(0, 10),
    space,
})

export const PopularTagsChips = (src: NativeReportData, space: number = 1) => ({
    type: 'chips',
    title: 'popularHashTags',
    data: src.popularHashTags.values,
    space,
})
export const Tags = (src: NativeReportData, space: number = 1) => ({
    type: 'chips',
    hashTagsTitle: 'popularHashTags',
    hashTagsData: src.popularHashTags.values,
    tagsTitle: 'userTags',
    tagsData: src.userTags.values.map((item) => item.label).slice(0, 10),
    space,
})

export const BrandAffinityList = (src: NativeReportData, space: number = 1) => ({
    type: 'list',
    title: 'brandAffinity',
    space,
    data: src.brandAffinity.values,
})

export const AQDonut = (src: NativeReportData, space: number = 2) => ({
    type: 'donut_chart',
    title: 'aq',
    space,
    data: src.audienceQuality.values.map((value) => ({
        value: value.value,
        label: value.label,
        color: colors.aq[value.label as AQType],
        label_color: colors.aq[value.label as AQType],
        percent_color: colors.aq_percent[value.label as AQType],
    })),
})

export const ReachBar = (src: NativeReportData, space: number = 1) => ({
    type: 'bar_chart',
    title: 'reach',
    space,
    data: src.audienceReach.values.map((item) => ({
        ...item,
        subLabel: 'follows',
    })),
})

export const NotableFollowersList = (src: NativeReportData, space: number = 1) => ({
    type: 'list',
    space,
    title: 'notableFollowers',
    data: src.notableFollowers.values,
})

const getValuesForAgeGender = (values: ValueLabelColorModel[]) => {
    const mappedValues = values.map((val) => ({
        color: val.color === '#FFDA54' ? colors.genders.f : colors.genders.m,
        qty: 0,
        share: val.value,
    }))

    if (mappedValues.length === 1) {
        mappedValues.push({
            color: mappedValues[0].color === colors.genders.f ? colors.genders.m : colors.genders.f,
            qty: 0,
            share: 0,
        })
    }

    mappedValues.sort((v) => (v.color === colors.genders.m ? 1 : -1))

    return mappedValues
}

export const AgeGenderMultiBar = (src: NativeReportData, space: number = 1) => ({
    type: 'multi_bar_chart',
    title: 'ageGender',
    data: src.ageGenderSpread.values.map((d) => ({
        label: d.label,
        items: getValuesForAgeGender(d.values),
    })),
    space,
    legend: [
        {
            color: '#FF659C',
            label: 'women',
        },
        {
            color: '#6071FF',
            label: 'men',
        },
    ],
})

export const GenderDonut = (src: NativeReportData, space: number = 1) => ({
    type: 'donut_chart',
    title: 'gender',
    space,
    data: src.genderSplit.values.map((val) => ({
        label: val.label,
        label_color: val.label === 'f' ? '#FF659C' : '#6071FF',
        percent_color: val.label === 'f' ? '#FFDCE2' : '#E2E6FF',
        value: val.value,
        color: val.label === 'f' ? '#FF659C' : '#6071FF',
    })),
})

export const CityHorizontal = (src: NativeReportData, space: number = 1) => ({
    type: 'horizontal_chart',
    space,
    title: 'cities',
    data: src.cities.values,
})

export const CountryHorizontal = (src: NativeReportData, space: number = 1) => ({
    type: 'horizontal_chart',
    space,
    title: 'countries',
    data: src.countries.values,
})

export const LanguageHorizontal = (src: NativeReportData, space: number = 1) => ({
    type: 'horizontal_chart',
    space,
    title: 'languages',
    data: src.languages.values,
})

export const AudienceInterests = (src: any, space: number = 1) => ({
    type: 'interests',
    space,
    title: 'AudienceInterests',
    data: src.audienceInterests.values,
})
export const AudienceBrandAffinity = (src: any, space: number = 1) => ({
    type: 'interests',
    space,
    title: 'AudienceBrandAffinity',
    data: src.audienceBrandAffinity.values,
})
export const PostTypes = (src: any, space: number = 1) => ({
    type: 'postType',
    space,
    title: 'PostType',
    data: src.postTypeAggregationsByDays.values,
})
export const OnParsingLoader = (reportReadyStatus: IReportReadyStatus, space: number = 1) => ({
    type: 'OnParsingLoader',
    space,
    title: 'OnParsingLoader',
    data: reportReadyStatus,
})
