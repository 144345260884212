import React, { SVGAttributes } from 'react'
const ChainLink = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.46492 17.535C3.44276 17.535 1.6197 16.3169 0.845724 14.4488C0.0717475 12.5806 0.499254 10.4301 1.92892 9.00002L4.05092 6.87802L5.46492 8.29202L3.34392 10.413C2.58598 11.171 2.28997 12.2757 2.5674 13.311C2.84482 14.3464 3.65354 15.1551 4.6889 15.4325C5.72426 15.71 6.82898 15.414 7.58692 14.656L9.70792 12.535L11.1219 13.95L9.00092 16.071C8.06499 17.0116 6.79178 17.5387 5.46492 17.535ZM6.17192 13.242L4.75792 11.828L11.8289 4.75702L13.2429 6.17102L6.17292 13.241L6.17192 13.242ZM13.9509 11.121L12.5359 9.70702L14.6569 7.58602C15.4252 6.83035 15.7291 5.72068 15.4532 4.679C15.1772 3.63732 14.3637 2.82367 13.3221 2.54746C12.2805 2.27126 11.1708 2.57494 10.4149 3.34302L8.29292 5.46402L6.87892 4.05002L9.00092 1.92802C10.956 -0.0100135 14.1099 -0.00310713 16.0565 1.94347C18.003 3.89005 18.01 7.04394 16.0719 8.99902L13.9509 11.12V11.121Z"
            fill="#6071FF"
        />
    </svg>
)

export default ChainLink
