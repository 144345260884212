import Service from '@/_helpers/Service'
import { ReportDetailsPost } from '@/Reponses'

const Report = new Service<ReportDetailsPost>({
    method: 'POST',
    url: '/api/reports/details/',
})

export default (token: string, external_id: number | string) =>
    Report.call(
        {
            data: { external_id },
        },
        token
    )
