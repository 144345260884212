import React, { FC, memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorConsumer, FormProvider, useController } from '@schematic-forms/react'
import { Str } from '@schematic-forms/core'

import bgImage from '@/pages/Auth/LoginPage/bg_image.png'

import FormPageLayout from '@/Layouts/FormPageLayout'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import FormInput from '@/components/Share/Form/FormInput'
import { Button, Text } from '@/_yc'
import useUrlSearchParams from './useUrlSearchParams'
import { resetPassword } from '@/Services/User'
import { AxiosError } from 'axios'
import { useHistory } from 'react-router-dom'
import { LOGIN } from '@/routs'
import useSystemNotification from '@/_helpers/Hooks/useSystemNotification'
import cn from '../ForgotPage/ForgotPage.module.scss'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
export interface IResetPassword {}

const lHeight = { lineHeight: 1 }

const ResetPassword: FC<IResetPassword> = (props) => {
    const t = useSpaceTranslation('reset_modal')
    const { i18n } = useTranslation()

    const { params } = useUrlSearchParams(['user_id', 'timestamp', 'signature'])

    const { notify } = useSystemNotification()

    const history = useHistory()

    const { controller, submit } = useController({
        fields: {
            password: Str(true),
            confirm: Str(true),
        },
        validators: {
            password: (value) => {
                if (value.length < 8) return new Error('TOO_SHORT')
            },
            confirm: (value, store) => {
                if (value !== store.password) return new Error('NOT_EQUAL')
            },
        },
        submit: async (data) => {
            if (!params.user_id || !params.timestamp || !params.signature) return
            try {
                await resetPassword(params.user_id, params.timestamp, params.signature, data.password, i18n.language)
                history.push(LOGIN, {
                    verified: 'New password saved successfully!',
                })
            } catch (e) {
                if (e.isAxiosError) {
                    const err: AxiosError = e
                    if (err.response?.status === 400) {
                        if (err.response.data.password && err.response.data.password.length !== 0) {
                            return {
                                password: err.response.data.password[0],
                            }
                        }
                        return {
                            password: err.response.data[0],
                        }
                    } else {
                        notify(t('common_error', undefined, true), 'error')
                    }
                } else {
                    notify(t('common_error', undefined, true), 'error')
                }
            }
        },
    })

    const errorTransform = useCallback(
        (_, code: string) => {
            if (!code.includes(' ')) return t(`error.${code}`)
            else return code
        },
        [t]
    )

    const size = useWindowWidth('mobile')

    return (
        <FormPageLayout title={t('title')} bgTextColor="#3D3F42" background={bgImage}>
            <Text>{t('subTitle')}</Text>
            <FormProvider controller={controller}>
                <FormInput
                    name="password"
                    type="password"
                    margin="20px 0 0"
                    title={t('fields.password')}
                    errorTransform={errorTransform}
                    removeSpace={true}
                />
                <FormInput
                    name="confirm"
                    type="password"
                    margin="10px 0 0"
                    title={t('fields.confirm')}
                    errorTransform={errorTransform}
                    removeSpace={true}
                />
                <ErrorConsumer>
                    {({ hasError }) => (
                        <Button
                            width={'100%'}
                            onClick={submit}
                            margin="20px 0 0"
                            disabled={hasError}
                            className={
                                !hasError
                                    ? size === 'mobile'
                                        ? cn['btn--mobile']
                                        : cn.btn
                                    : size === 'mobile'
                                    ? cn['btn--disabled--mobile']
                                    : cn['btn--disabled']
                            }
                        >
                            <Text style={lHeight} color="white">
                                {t('submit')}
                            </Text>
                        </Button>
                    )}
                </ErrorConsumer>
            </FormProvider>
        </FormPageLayout>
    )
}

export default memo(ResetPassword)
