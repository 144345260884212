import extraLight from './SourceSansPro-ExtraLight.ttf'
import light from './SourceSansPro-Light.ttf'
import regular from './SourceSansPro-Regular.ttf'
import semiBold from './SourceSansPro-SemiBold.ttf'
import bold from './SourceSansPro-Bold.ttf'
import black from './SourceSansPro-Black.ttf'

import italic from './SourceSansPro-Italic.ttf'
import boldItalic from './SourceSansPro-BoldItalic.ttf'
import lightItalic from './SourceSansPro-LightItalic.ttf'

loadFonts([
	{ url: regular },
	{
		url: bold,
		'font-weight': 'bold'
	},
	{
		url: boldItalic,
		'font-style': 'italic',
		'font-weight': 'bold'
	},
	{
		url: extraLight,
		'font-weight': 200
	},
	{
		url: semiBold,
		'font-weight': 600
	},
	{
		url: italic,
		'font-style': 'italic'
	},
	{
		url: light,
		'font-weight': 300
	},
	{
		url: black,
		'font-weight': 900
	},
	{
		url: lightItalic,
		'font-weight': 300,
		'font-style': 'italic'
	}
])

function loadFonts(fonts) {
	const block = document.createElement("style");

	block.innerHTML = fonts.map(({ url, postfix, ...mods}) => `
	@font-face { 
		font-family: 'main_font${postfix ? `--${postfix}` : ""}'; 
		src: url('${url}');
		${mods ? Object.keys(mods).map(mod => `${mod}: ${mods[mod]};\n`): ""}
	}
	`).join("\n")
	document.head.appendChild(block)
}