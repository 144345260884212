import React, { FC } from 'react'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import Flex from '@/_yc/Flex'
import { getCurrency } from '@/_helpers/getCurrency'

import cn from './Price.module.scss'

export interface IPrice {
    price_model: string
    currency: string
    before_price: number
    price: number
    reports_available: number
    title: string | null
    yearlySwitch: boolean
}

const Price: FC<IPrice> = (props) => {
    const t = useSpaceTranslation('tariffs_page')

    const priceUnits =
        props.price_model === 'report'
            ? `/ ${props.reports_available} ${t('report')}`
            : ` / ${props.yearlySwitch ? t('year') : t('month')}`
    const currency = getCurrency(props.currency)
    const discountPercent = `-${100 - (100 / props.before_price) * props.price}%`
    if (props.title) {
        return (
            <div className={cn.root}>
                <p className={cn.amount}>{props.title}</p>
            </div>
        )
    }
    return (
        <div className={cn.root}>
            {props.before_price !== props.price && (
                <div className={cn.original}>
                    <p className={cn['original--percent']}>{discountPercent}</p>
                    <p className={cn['original--price']}>
                        {props.before_price} {currency}
                    </p>
                </div>
            )}
            <Flex>
                <p className={cn.amount}>
                    {props.price} {currency}
                    <span className={cn['amount--units']}> {priceUnits}</span>
                </p>
            </Flex>
        </div>
    )
}
export default Price
