import { SearchFilter } from '@/pages/SearchPage/SearchAdapter/filters/SearchFilter'

const getGqlFilter = (text: string) => {
    return `fullText: {terms: ["${text}"]}`
}

export const getKeywordFilter = () => {
    return new SearchFilter({
        platform: 'ads_ig',
        title: 'ads_explorer.filters.post_metrics.keyword.label',
        helper: 'ads_explorer.filters.post_metrics.keyword.text',
        space: 2,
        placeholder: 'ads_explorer.filters.post_metrics.keyword.label',
        beforeSend: (data) => {
            return {
                name: 'metrics.keyword',
                type: 'range',
                value: `"${data}"`,
                gqlFilter: getGqlFilter(data),
            }
        },
    })
}
