import React, { SVGAttributes } from 'react'
export const TikTokIcon = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="18" height="18" rx="9" fill="#323841" stroke="white" stroke-width="2" />
        <path
            d="M10.2121 5.2083C10.7393 5.2002 11.2636 5.205 11.7872 5.2002C11.8189 5.81253 12.0407 6.43626 12.4921 6.86918C12.9426 7.31291 13.5798 7.51602 14.1998 7.58472V9.19551C13.6188 9.17661 13.035 9.0566 12.5078 8.80819C12.2782 8.70498 12.0642 8.57208 11.8549 8.43617C11.8521 9.60503 11.8597 10.7724 11.8473 11.9365C11.8159 12.4957 11.6301 13.0522 11.3025 13.513C10.7756 14.2802 9.86101 14.7803 8.92164 14.7959C8.34545 14.8286 7.76987 14.6726 7.27888 14.3852C6.46521 13.9088 5.89264 13.0366 5.80925 12.1006C5.79958 11.9005 5.79626 11.7006 5.80442 11.5053C5.87693 10.7442 6.25612 10.0161 6.8447 9.52073C7.51183 8.9438 8.44637 8.66898 9.32138 8.83159C9.32954 9.42412 9.30567 10.0161 9.30567 10.6086C8.90593 10.4802 8.43881 10.5162 8.08953 10.7571C7.83452 10.9212 7.64085 11.1726 7.53993 11.457C7.45654 11.6598 7.48041 11.8852 7.48525 12.1006C7.58103 12.757 8.21674 13.3087 8.89535 13.249C9.34525 13.2442 9.77641 12.985 10.0109 12.6055C10.0867 12.4726 10.1716 12.3367 10.1761 12.1804C10.2157 11.4648 10.2 10.7523 10.2048 10.0368C10.2082 8.42417 10.2 6.81608 10.2124 5.2086L10.2121 5.2083Z"
            fill="white"
        />
    </svg>
)
