import React, { FC } from 'react'
import Icons from '@/components/Icons'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import MultiColorIcon, { colors } from '@/components/MultiColorIcon'

import cn from './ListItem.module.scss'

interface ListItemProps {
    title: string
    count: number
    id: number
    i: number
    onClick: (id: number) => void
}

export const ListItem: FC<ListItemProps> = ({ title, count, id, i, onClick }) => {
    return (
        <div className={cn.listItem} onClick={() => onClick(id)}>
            <MultiColorIcon color={colors[i % colors.length]}>
                <Icons name="unordered-list" />
            </MultiColorIcon>

            <Flex column margin="0 0 0 16px">
                <Text>{title}</Text>
                <Text color="gray-new-2">{count} influencers</Text>
            </Flex>
        </div>
    )
}
