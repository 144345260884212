import React, { FC, useEffect } from 'react'

import { Post } from '../Cards'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { PostType } from '../Cards/Post/Post'

import { Tooltip } from '@yoloker/ui-collection'

import cn from './Posts.module.scss'
import Flex from '@/_yc/Flex'

export interface PT extends Omit<PostType, 'img' | 'date' | 'shortcode'> {
    url: string
    postDate: string
    code: string
}

export interface IPosts {
    popular: PT[]
    promotion: PT[]
    hidden?: boolean
}

const mapNativeToPost = (post: PT, i: number, hidden?: boolean) => (
    <Post
        key={i}
        img={post.url}
        text={post.text}
        date={post.postDate}
        likes={post.likes}
        comments={post.comments}
        shortcode={post.code}
        width={'auto'}
        hidden={hidden}
    />
)

const Posts: FC<IPosts> = ({ popular, promotion, hidden }) => {
    const t = useSpaceTranslation('report.headers.posts.ops')

    useEffect(() => {
        const script = document.createElement('script')

        script.src = '//www.instagram.com/embed.js'
        script.async = true

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return (
        <>
            <Flex align={'center'} margin={'0 0 12px 0'}>
                <div className={cn.header}>{t('popular')}</div>
                <div className={cn.tooltip}>
                    <Tooltip text={t('tooltips.popular')}></Tooltip>
                </div>
            </Flex>
            <div className={cn.posts}>{popular.map((item, i) => mapNativeToPost(item, i, hidden))}</div>
            {promotion.length > 0 && (
                <>
                    <Flex align={'center'}>
                        <div className={cn.header}>{t('promotion')}</div>
                        <div className={cn.tooltip}>
                            <Tooltip text={t('tooltips.promotion')}></Tooltip>
                        </div>
                    </Flex>
                    <div className={cn.posts}>{promotion.map((item, i) => mapNativeToPost(item, i, hidden))}</div>
                </>
            )}
        </>
    )
}

export default Posts
