import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { Flex } from '@/_yc'
import { ApexOptions } from 'apexcharts'
import React, { useCallback, useMemo } from 'react'

import cn from './VideosLengthGraph.module.scss'

import ReactApexChart from 'react-apexcharts'
import { renderToString } from 'react-dom/server'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import Loader from '@/components/Loader'
import { TooltipOptions } from '@/Layouts/ReportLayout/Content/AllocationGraph/types'
import { convertMinutesToDuration, convertTimeDurationToMinutes } from '@/Layouts/ReportLayout/Content/GrowGraph/utils'
import moment from 'moment'
import Tooltip from './Tooltip/Tooltip'
import { getGraphLocale } from '../../BloggerReport/Cards/InstaGrowGraph/utils'
import { useTranslation } from 'react-i18next'

export const VideosLengthGraph = () => {
    const t = useSpaceTranslation('twitch_report.videos_length')
    const { i18n } = useTranslation()

    const videosLength = useSelector((store: RootStore) => {
        const data = store.report.rawTwitchData?.getTwitchUser?.data.twitch_userinfo[0]
        const videosLength = data?.user_duration_historical
        if (!videosLength) return []
        return videosLength
    })
    const data = useMemo(() => {
        return videosLength.map((video) => {
            const duration = convertTimeDurationToMinutes(video.duration)
            const date = moment(video.created_at).format()
            return {
                x: date,
                y: duration,
                fillColor: '#6071FF',
            }
        })
    }, [videosLength])

    const displayTooltip = useCallback((options: TooltipOptions) => {
        return renderToString(<Tooltip dataPointIndex={options.dataPointIndex} w={options.w} />)
    }, [])

    const options: ApexOptions = {
        chart: {
            height: 320,
            type: 'scatter',
            toolbar: {
                show: false,
                autoSelected: 'pan',
            },
            zoom: {
                enabled: false,
            },
            defaultLocale: getGraphLocale(i18n.language.slice(0, 2)),
            locales: [
                {
                    name: 'en',
                    options: {
                        shortMonths: [
                            'Jan',
                            'Feb',
                            'Mar',
                            'Apr',
                            'May',
                            'Jun',
                            'Jul',
                            'Aug',
                            'Sep',
                            'Oct',
                            'Nov',
                            'Dec',
                        ],
                    },
                },
                {
                    name: 'ru',
                    options: {
                        shortMonths: [
                            'Янв',
                            'Фев',
                            'Мар',
                            'Апр',
                            'Май',
                            'Июн',
                            'Июл',
                            'Авг',
                            'Сен',
                            'Окт',
                            'Ноя',
                            'Дек',
                        ],
                    },
                },
            ],
        },

        title: {
            text: t('title'),
            offsetY: 5,
            style: {
                fontFamily: 'Montserrat',
                color: '#ADADAD',
            },
        },
        series: [{ data: data }],
        tooltip: {
            custom: displayTooltip,
        },
        xaxis: {
            title: {
                text: t('date'),
                offsetY: 0,
                style: {
                    fontFamily: 'Montserrat',
                    color: '#ADADAD',
                },
            },
            type: 'datetime',
            tickAmount: 6,
            labels: {
                formatter: (val) => moment(val).lang(i18n.language.slice(0, 2)).format('DD MMM'),
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            labels: {
                formatter: (val) => convertMinutesToDuration(val),
            },

            title: {
                text: t('duration'),
                offsetY: 15,
                style: {
                    fontFamily: 'Montserrat',
                    color: '#ADADAD',
                },
            },
        },
        markers: {},
    }

    return (
        <Flex className={cn.root} column>
            {data.length === 0 ? (
                <Flex content="center" margin="20px 0 30px 0">
                    <Loader />
                </Flex>
            ) : (
                <ReactApexChart options={options} height={'280px'} series={[{ data: data }]} type="scatter" />
            )}
        </Flex>
    )
}
