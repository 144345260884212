import React, { FC } from 'react'

import Flex from '@/_yc/Flex'

import Dash from './assets/Dash'
import Done from './assets/Done'

import cn from './MassAddToList.module.scss'

interface ICheckbox {
    value: 'dashed' | 'checked' | 'empty'
    disabled?: boolean
}
export const Checkbox: FC<ICheckbox> = ({ value, disabled }) => {
    const getClass = () => {
        if (disabled) {
            return 'checkbox--disabled'
        }
        if (value === 'dashed') {
            return 'checkbox--dashed'
        }
        if (value === 'checked') {
            return 'checkbox--checked'
        }
        return 'checkbox'
    }
    return (
        <Flex align="center" content="center" className={cn[getClass()]}>
            {value === 'dashed' ? <Dash /> : value === 'checked' ? <Done /> : null}
        </Flex>
    )
}

export default Checkbox
