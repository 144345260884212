import React, { FC, useMemo } from 'react'

import Icons from '@/components/Icons'
import { numberFormatter, useSpaceTranslation } from '@/_helpers'
import { shortNum } from '@/_helpers/_graphs/_tech'
import Flex from '@/_yc/Flex'
import Text from '@/components/Text'

import cn from './Predictions.module.scss'
import LoadingBar from '@/components/LoadingBar'
import { Tooltip } from '@yoloker/ui-collection'

export interface Item {
    value:
        | null
        | number
        | {
              from: number | null
              to: number | null
          }
    title: string
    icon: 'Group' | 'Reach'
    currency?: 'RUB' | 'USD' | 'BRL'
    tooltipText: string
    customTitle?: string
}

export interface IPredictions {
    items: Item[]
    isDemo?: boolean
    inProgress?: boolean
}

export type Sizes = 'mobile' | 'tablet' | 'desktop'

const getCurrency = (code: 'RUB' | 'USD' | 'BRL'): string => {
    if (code === 'RUB') return '₽'
    if (code === 'USD') return '$'
    if (code === 'BRL') return 'R$'
    return ''
}

const calcMargin = (i: number, length: number, size: Sizes) =>
    size === 'desktop' ? undefined : i === length - 1 ? '0' : '0 0 20px'

const formatText = (item: Item) => {
    if (!item.value) {
        return null
    }

    if (typeof item.value !== 'number') {
        return `≈ ${shortNum(item.value.from)} – ${shortNum(item.value.to)} ${
            item.currency ? getCurrency(item.currency) : ''
        } `
    }

    if (item.currency) {
        return `≈ ${shortNum(item.value)} ${item.currency ? getCurrency(item.currency) : ''}`
    }

    return `≈ ${numberFormatter(item.value)}`
}

export const Predictions: FC<IPredictions> = ({ ...props }) => {
    const t = useSpaceTranslation('')
    const size = 'desktop'

    const items = useMemo(
        () => props.items.filter((i) => !!i.value && (typeof i.value === 'object' ? i.value.from && i.value.to : true)),
        [props.items]
    )

    if (!items.length) return null

    return (
        <>
            <Flex content="space-between" className={cn.root} style={{ flexBasis: '100%' }}>
                {items.map((item, i) => (
                    <Flex
                        align="center"
                        // className={size !== 'desktop' ? cn.item : cn[`item--${items.length}`]}
                        className={cn[`item--${items.length}`]}
                        key={i}
                        margin={calcMargin(i, items.length, size)}
                    >
                        <Icons className={cn.icon} name={item.icon} />
                        <div className={cn.text}>
                            <Flex>
                                {!props.isDemo ? (
                                    !props.inProgress ? (
                                        <Text color={'gray-1'} margin="0 0 2px" className={cn.value}>
                                            {formatText(item)}
                                        </Text>
                                    ) : (
                                        <LoadingBar width={80} height={15} />
                                    )
                                ) : (
                                    <div className={cn.hidden} />
                                )}
                            </Flex>
                            <Flex>
                                <Text color={'gray-3'} className={cn[`predictionTitle`]} margin="0">
                                    {item.customTitle
                                        ? item.customTitle
                                        : t(`report_titles.predictions.${item.title}`, null, true)}
                                </Text>
                                {item.tooltipText && item.tooltipText.length !== 0 && (
                                    <Tooltip text={item.tooltipText} />
                                )}
                            </Flex>
                        </div>
                    </Flex>
                ))}
            </Flex>
        </>
    )
}
