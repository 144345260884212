import { gql } from '@apollo/client'

export const GET_TG_REPORT = gql`
    query getTelegraReport($id: String!) {
        getTelegraReport(channel_id: $id) {
            data
        }
    }
`
export const GET_VK_PARSING_STATUS = gql`
    query MyQuery($id: String!, $type: String!) {
        parsing_vk_request(where: { account_id: { _eq: $id }, account_type: { _eq: $type } }) {
            state_done
            updated_at
        }
    }
`
