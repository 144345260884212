import React, { FC } from 'react'

import cn from './ScrollableTable.module.scss'

interface ScrollableTableProps {
    children: React.ReactNode
}

export const ScrollableTable: FC<ScrollableTableProps> = ({ children }) => {
    return <div className={cn.scrollableTable}>{children}</div>
}
