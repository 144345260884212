import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Text } from '@/_yc/'
import Button from '@/components/Button'
import Flex from '@/_yc/Flex'
import useToken from '@/_helpers/Hooks/useToken'
import notif from '@/components/Notifier/model/actions.js'
import { Success, Failure, CommonError, CommonLoading, UsedError } from '@/components/Commercial/Notifaications/'
import { promoCheck } from '../Model/service'

import cn from './PromoInput.module.scss'

interface IPromoInput {
    onSubmit: Function
}

const PromoInput: FC<IPromoInput> = ({ onSubmit }) => {
    const { t } = useTranslation()
    const token = useToken()
    const dispatch = useDispatch()

    const [value, setValue] = useState<string | undefined>('')
    const [isOpen, setIsOpen] = useState(false)
    const open = useCallback(() => setIsOpen(true), [])

    const handleClick = () => {
        if (token && value) {
            dispatch(notif.clear())
            dispatch(notif.loading(<CommonLoading />))
            promoCheck(token, value)
                .then((res) => {
                    onSubmit(value)
                    dispatch(notif.success(<Success name={res.name} detail={res.description} />))
                })
                .catch((err) => {
                    if (err.response.status === 404) dispatch(notif.error(<Failure />))
                    else if (err.response.status === 400) dispatch(notif.error(<UsedError />))
                    else dispatch(notif.error(<CommonError />))
                })
        }
    }
    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            handleClick()
        }
    }
    return (
        <div className={cn.container}>
            {!isOpen && (
                <Text size={14} margin="0" onClick={open} className={cn.opener}>
                    {t('tariffs_page.promo.open')}
                </Text>
            )}
            <div style={!isOpen ? { visibility: 'hidden' } : {}} className={cn['root--default']}>
                <input
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder={t('tariffs_page.promo.plh')}
                    onKeyDown={handleKeyDown}
                />
                <Flex align="center" margin="0 4px 0 0">
                    <Button
                        onClick={() => {
                            handleClick()
                        }}
                        size="small"
                        variant="primary"
                    >
                        {t('tariffs_page.promo.btn')}
                    </Button>
                </Flex>
            </div>
        </div>
    )
}
export default PromoInput
