import React, { FC, useCallback } from 'react'
import { Scrollbars, positionValues } from 'react-custom-scrollbars'

import cn from './List.module.scss'
import PopupPapper from '@/components/Share/PopupPapper'
import Flex from '@/_yc/Flex'
import { Notification } from '@/components/Notifier/Types'
import Item from './Item'
import SadImg from './Sad.png'
import { Text } from '@/_yc'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import Loader from '@/components/Loader'

export interface IList {
    items: Notification[]
    loadNew: VoidFunction
    loading: boolean
    hasNext: boolean
}

const imgStyle = {
    width: 40,
    height: 40,
    marginRight: 25,
}
const List: FC<IList> = ({ items, loading, loadNew, hasNext }) => {
    const t = useSpaceTranslation('Notifications')

    const size = useWindowWidth('mobile')

    const detectBottom = useCallback(
        ({ top }: positionValues) => {
            if (!loading && top >= 0.9 && hasNext) loadNew()
        },
        [loading, loadNew, hasNext]
    )

    return (
        <PopupPapper width={size !== 'mobile' ? 420 : 'calc(100vw - 20px)'}>
            <Scrollbars onUpdate={detectBottom} autoHeight autoHeightMax={size !== 'mobile' ? 450 : 300}>
                <div className={cn.items}>
                    {items.map((Notification, i) => (
                        <Item
                            withSublabel={false}
                            key={Notification.id}
                            Notification={Notification}
                            notLast={i < items.length - 1}
                        />
                    ))}
                </div>
                {loading && (
                    <Flex content="center" margin="10px 0 20px">
                        <Loader />
                    </Flex>
                )}
            </Scrollbars>
            {!loading && items.length < 1 && (
                <Flex align="center" content="center">
                    <img style={imgStyle} src={SadImg} alt="sad" />
                    <div>
                        <Text size={18} margin="0 0 4px">
                            {t('empty.title')}
                        </Text>
                        <Text size={12} color="secondary" margin="0">
                            {t('empty.details')}
                        </Text>
                    </div>
                </Flex>
            )}
        </PopupPapper>
    )
}

export default List
