import React from 'react'
import { SelectorFilter } from '@/pages/SearchPage/SearchAdapter/filters/SelectorFilter'
import Icons from '@/components/Icons'

const getGqlFilter = (date: number[]) => {
    if (!date || date.length === 0) return ''
    return `last_posted: ${date[0]}`
}

export const getLastPostFilter = () => {
    return new SelectorFilter({
        platform: 'infl_tg',
        title: 'filters.lastVideo.label',
        helper: 'filters.lastVideo.text',
        icon: <Icons name="filter--age" />,
        space: 1,
        options: [
            { label: '1 month', value: 30 },
            { label: '3 months', value: 90 },
            { label: '6 months', value: 180 },
        ],
        beforeSend: ({ value }) => ({
            name: 'metrics.er',
            type: 'number',
            value: value,
            gqlFilter: getGqlFilter(value),
        }),
    })
}
