import React, { FC } from 'react'
import Flex from '@/_yc/Flex'
import { Helper } from '@/components/Helper/Helper'
import Feedback from '@/components/Canny/Feedback'
interface ILink {
    link: string | any
}
const LinksUnderPageTitle: FC<ILink> = ({ link }) => {
    return (
        <Flex>
            <Flex margin="0 10px 0 0">
                <Helper link={link} />
            </Flex>
            <Feedback />
        </Flex>
    )
}
export default LinksUnderPageTitle
