import React from 'react'
import { useTranslation } from 'react-i18next'

import { LockButton } from '@/_yc/'

import LoadingButton from '@/components/Share/LoadingButton'

export default ({ onClick, loading, locked, tooltip, lockedOnParsing }) => {
    const { t } = useTranslation()

    if (lockedOnParsing || locked)
        return (
            <LockButton tooltip={tooltip}>
                <LoadingButton disabled style={{ fontSize: '12px' }}>
                    {t('report.download')}
                </LoadingButton>
            </LockButton>
        )

    return (
        <LoadingButton loading={loading} onClick={onClick} style={{ color: 'violet-1', fontSize: '12px' }}>
            {t('report.download')}
        </LoadingButton>
    )
}
