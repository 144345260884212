import React, { useRef} from 'react';

import { Icons } from '../Icons/'

import cn from './YoloInput.module.scss'

const icon = {
	padding: "8px 12px 8px 2px", 
	boxSizing: 'content-box',
	cursor: "pointer"
}

const YoloInput = props =>{
	const [focused, setFocused] = React.useState(false);
	const ref = useRef(null);
	const focusInput = () => {
		ref.current.focus();
	}
	return(
		<div className = {`${cn.root} ${focused ? cn.root_focused : ""}`}>
			<input 
				value={props.value}
				ref = {ref}
				type={props.type ? props.type : "text"}
				onChange={e=>props.onChange(e.target.value)}
				placeholder={props.placeholder}
				onFocus={e=>setFocused(true)}
				onBlur={e=>setFocused(false)}
				disabled={props.disabled}
			/>
			<Icons 
				name = "search"
				style = {icon} 
				onClick = {e=>focusInput()}
			/>
		</div>
	)
}

YoloInput.defaultProps = {
	placeholder: ""
}
export { YoloInput }