import { constructPreviewImageLink } from '@/Layouts/ReportLayout/Content/Videos/utils'
import { ApolloQueryResult } from '@apollo/client'
import { IQueryData } from '../types'

export const getItems = (videos: ApolloQueryResult<IQueryData>) => {
    if (videos.loading || videos.error || !videos.data) return []

    const edges = videos.data.ytPosts.edges

    return edges.map((nodeData) => {
        const node = nodeData.node

        return {
            title: node.videoTitle,
            id: node.videoId,
            cover: constructPreviewImageLink(node.videoId, 'mqdefault'),
            publishedAt: node.publishedAt,
            description: node.description,
            avatar: node.avatar,
            channelTitle: node.channelTitle,
            brand: node.brandName,
            commentCount: node.commentCount || 0,
            viewCount: node.viewCount || 0,
            likeCount: node.likeCount || 0,
            channelId: node.channelId,
        }
    })
}
