import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.3334 17.5H1.66669V9.16666C1.66669 8.24618 2.41288 7.49999 3.33335 7.49999H6.66669V3.33332C6.66669 2.41285 7.41288 1.66666 8.33335 1.66666H11.6667C12.5872 1.66666 13.3334 2.41285 13.3334 3.33332V5.83332H16.6667C17.5872 5.83332 18.3334 6.57951 18.3334 7.49999V17.5ZM13.3334 7.49999V15.8333H16.6667V7.49999H13.3334ZM8.33335 3.33332V15.8333H11.6667V3.33332H8.33335ZM3.33335 9.16666V15.8333H6.66669V9.16666H3.33335Z"
            fill="#333333"
        />
    </svg>
)
