import React, { FC } from 'react'

import Text from '@/components/Text'

import cn from './GeneralInfo.module.scss'
import { useSpaceTranslation } from '@/_helpers'
import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'

export interface ITopInfo {
    country?: string
    email?: string
    phone?: string
    updatedDate?: string
    isDemo?: boolean
    inProgress?: boolean
}
export const GeneralInfo: FC<ITopInfo> = ({ ...props }) => {
    const t = useSpaceTranslation('report.headers.info')
    const checkGeneralData =
        (props.country && props.country.length) ||
        (props.email && props.email.length) ||
        (props.phone && props.phone.length) ||
        props.isDemo
    return (
        <Flex className={cn.root} wrap={true}>
            {checkGeneralData && (
                <Flex content="left" className={cn.TopInfo} wrap={true}>
                    {(props.isDemo || (props.country && props.country.length !== 0)) && (
                        <Flex margin="0 0 6px 0">
                            <Icons name="filter--geo" className={cn['TopInfo--icon']} style={{ width: '20px' }} />
                            {!props.isDemo ? (
                                <>
                                    <Text semibold className={cn['TopInfo--text']} color="gray-4">
                                        {props.country}
                                    </Text>
                                </>
                            ) : (
                                <div className={cn['demo-blur--email']} />
                            )}
                        </Flex>
                    )}

                    {((props.email && props.email.length !== 0) || props.isDemo) && (
                        <Flex margin="0 0 6px 0" align="center">
                            <Icons name="mail" className={cn['TopInfo--icon']} />
                            {!props.isDemo ? (
                                <Text semibold className={cn['TopInfo--text']} color="gray-4">
                                    {props.email}
                                </Text>
                            ) : (
                                <div className={cn['demo-blur--email']} />
                            )}
                        </Flex>
                    )}

                    {(props.isDemo || (props.phone && props.phone.length !== 0)) && (
                        <Flex margin="0 0 6px 0" align="center">
                            <Icons name="phone" className={cn['TopInfo--icon']} />
                            {!props.isDemo ? (
                                <Text semibold className={cn['TopInfo--text']} color="gray-4">
                                    {props.phone}
                                </Text>
                            ) : (
                                <div className={cn['demo-blur--email']} />
                            )}
                        </Flex>
                    )}
                </Flex>
            )}
            {props.updatedDate && props.updatedDate.length !== 0 && (
                <Flex margin="0 0 6px 0" className={!checkGeneralData && cn.updDate}>
                    <Icons
                        name="refresh"
                        className={!props.inProgress ? cn['TopInfo--icon'] : cn['TopInfo--icon-active']}
                    />
                    <Text
                        semibold
                        className={cn['TopInfo--text']}
                        margin="0 0 10px 8px"
                        color={!props.inProgress ? 'gray-4' : 'violet-1'}
                    >
                        {!props.inProgress ? (
                            <>
                                {t('updated')} {props.updatedDate}
                            </>
                        ) : (
                            <>{t('updating')}</>
                        )}
                    </Text>
                </Flex>
            )}
        </Flex>
    )
}
