import React, { FC, useEffect, useState } from 'react'
import Text from '@/components/Text'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import cn from './SendToMailingList.module.scss'
import Icons from '@/components/Icons'
import Flex from '@/_yc/Flex'
import { useSelector, useDispatch } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import Button from '@/components/Button'
import { getAdvertisingCampaignsReq, Campaign, addBloggersToCampaignReq } from '@/pages/Outreach/Service/Campaigns'
import useToken from '@/_helpers/Hooks/useToken'
import Loader from '@/components/Loader'
import ApprovePopup from '@/components/ApprovePoup'
import useOpenClose from '@/_helpers/useOpenClose'
import notif from '@/components/Notifier/model/actions.js'

interface ISendToMailingList {
    close: VoidFunction
}

export const Campaigns: FC<ISendToMailingList> = ({ close }) => {
    const dispatch = useDispatch()
    const t = useSpaceTranslation('aud_insides.send_to_mail')
    const token = useToken()
    const bloggersList = useSelector((store: RootStore) => store.MassAddToList.mailingList)
    const [isOpen, open, closePopUp] = useOpenClose(false)

    const [camps, setCamps] = useState<Campaign[]>([])
    const [load, setLoad] = useState<boolean>(true)
    const [chosenCamp, setChosenCamp] = useState<string>('')

    useEffect(() => {
        if (!token) return
        getAdvertisingCampaignsReq(token).then((res) => {
            setCamps(res.results)
            setLoad(false)
        })
    }, [token])

    const choseCamp = (id: string) => {
        setChosenCamp(id)
    }

    const submit = () => {
        if (!token) return

        addBloggersToCampaignReq(
            token,
            chosenCamp,
            bloggersList.instList.map((item) => item.externalId).filter((item) => item !== undefined) as string[],
            bloggersList.ytList.map((item) => item.externalId).filter((item) => item !== undefined) as string[]
        )
            .then(() => {
                dispatch(notif.success([t('approve.success')]))
            })
            .catch(() => {
                dispatch(notif.error([t('approve.er')]))
            })
    }

    return (
        <>
            <Flex content="space-between" align="center" margin="0 0 20px 0">
                <Text fSize={20}>{t('title')}</Text>
                <div onClick={close}>
                    <Icons name="close" className={cn.close}></Icons>
                </div>
            </Flex>

            <Flex column className={cn['campaign--block']} align={load ? 'center' : ''} content={load ? 'center' : ''}>
                {load && <Loader />}
                {camps.map((item) => (
                    <>
                        <Flex column className={cn.campaign} content="center" onClick={() => choseCamp(item.id)}>
                            <Flex content="space-between">
                                <Flex column>
                                    <p className={cn['campaign--text']}>{item.name}</p>
                                    <p className={cn['campaign--text-sub']}>
                                        {item.stats.chats_count + ' ' + t('influencers')}
                                    </p>
                                </Flex>
                                {item.id === chosenCamp && <Icons name="done" />}
                            </Flex>
                        </Flex>
                        <div className={cn['divider']} />
                    </>
                ))}
            </Flex>

            <div className={cn['divider--big']} />
            <Flex content="space-between" align="center" margin="20px 0 0 0">
                <Flex className={cn.message} align="center">
                    <Flex className={cn['message-icon-back']} align="center" content="center">
                        <Icons name="message" className={cn['message-icon']} />
                    </Flex>
                    <Flex column margin="0 0 0 10px">
                        <p className={cn['message-text']}>
                            {bloggersList.instList.length + bloggersList.ytList.length + ' ' + t('sending')}
                        </p>
                        <p className={cn['message-text-sub']}>{t('spend')}</p>
                    </Flex>
                </Flex>
                <Button
                    variant="primary"
                    disabled={chosenCamp === ''}
                    onClick={() => {
                        open()
                    }}
                >
                    <span>{t('add_1')}</span>
                    <span>
                        <Icons name="arrow-right" className={cn.arrowRight}></Icons>
                    </span>
                </Button>
            </Flex>
            <ApprovePopup
                isOpen={isOpen}
                close={closePopUp}
                approveFunction={submit}
                title={t('approve.title')}
                subTitle={t('approve.text')}
                approve={t('approve.approve')}
                cancel={t('approve.cancel')}
            />
        </>
    )
}
