import React, { useEffect, useRef, useState, useMemo } from 'react'
import useOpenClose from '@/_helpers/useOpenClose'
import Popup from '@/_yc/Popup'

import cn from './SelectorWithInput.module.scss'
import Icons from '../Icons'

export interface SelectorWithInputProps<T> {
    inputActive: boolean
    inputPlaceholder: string
    selectorPlaceholder: string
    onInputSubmit: (value: string) => void
    onInputCancel: () => void
    listData: T[]
    renderItem: (t: T) => React.ReactNode
    curItem?: string
}

export const SelectorWithInput = <T extends unknown>({
    inputActive,
    selectorPlaceholder,
    listData,
    renderItem,
    inputPlaceholder,
    onInputSubmit,
    onInputCancel,
    curItem,
}: SelectorWithInputProps<T>): JSX.Element => {
    const [state, , close, toggle] = useOpenClose()
    const ref = useRef<HTMLDivElement>(null)
    const [inputValue, setInputValue] = useState('')

    const onSelectorClick = () => {
        if (!inputActive) {
            toggle()
        }
    }

    useEffect(() => {
        if (!inputActive) {
            close()
        }
    }, [curItem, close, inputActive])

    const [selectorValue, setSelectorValue] = useState('')

    const options = useMemo(() => {
        if (listData.length === 0) return []
        if (selectorValue === '') return listData
        // @ts-ignore
        return listData.filter((item) => item.name.includes(selectorValue))
    }, [selectorValue, listData])

    useEffect(() => {
        if (curItem) setSelectorValue(curItem)
    }, [curItem])

    return (
        <div className={cn.selectorWithInput}>
            {!inputActive && (
                <div onClick={onSelectorClick} className={cn.selector} ref={ref}>
                    {/* <Text color={`${curItem ? 'gray-1' : 'gray-4'}`}>{curItem || selectorPlaceholder}</Text> */}
                    <input
                        onChange={(v) => setSelectorValue(v.target.value)}
                        value={selectorValue}
                        placeholder={selectorPlaceholder}
                    />

                    <Icons name="arrow-down" className={cn[state ? 'icon--opened' : 'icon--closed']} />
                </div>
            )}

            {inputActive && (
                <div className={cn.listName}>
                    <input
                        className={cn.listNameInput}
                        onChange={(v) => setInputValue(v.target.value)}
                        placeholder={inputPlaceholder}
                    />
                    <div className={cn.close} onClick={onInputCancel}>
                        <Icons name="close-small-grey"></Icons>
                    </div>
                    <div className={cn.done} onClick={() => onInputSubmit(inputValue)}>
                        <Icons name="done" />
                    </div>
                </div>
            )}

            <Popup
                isOpen={state}
                onClose={close}
                anchor={ref.current}
                placement="bottom"
                style={{ zIndex: 130, width: (ref.current?.clientWidth || 100) + 8, marginTop: '4px' }}
            >
                <div className={cn.list}>{options.map((d) => renderItem(d))}</div>
            </Popup>
        </div>
    )
}
