import React, { FC, ReactNode, useRef, useState, useEffect } from 'react'

import Header from './Header'

import cn from './AudienceLayout.module.scss'
import Flex from '@/_yc/Flex'
import { useTranslation } from 'react-i18next'
import Text from '@/components/Text'
import GlobalWidth from '@/_yc/GlobalWidth'

import Context, { IAudienceLayoutContext as IContext } from './AudienceLayoutContext'
import useClass from '@/_helpers/Hooks/useClass'
import PageLayout from '../PageLayout'
import Loader from '@/components/Loader'

export interface IAudienceLayout {
    children?: ReactNode
    loading: boolean
    error?: boolean
    isReport?: boolean
    classes?: {
        container?: string
        content?: string
    }
    title?: string
}

const AudienceLayout: FC<IAudienceLayout> = ({ title = 'titles.t2', isReport, ...props }) => {
    const { t } = useTranslation()

    const ref = useRef<any>(null)

    const [target, setTarget] = useState<null | IContext>(null)

    const root = useClass(cn.root, props.classes?.container)

    useEffect(() => {
        if (!ref.current) return

        setTarget({
            container: ref.current,
        })

        return () => {
            setTarget(null)
        }
    }, [])

    return (
        <Context.Provider value={target}>
            <PageLayout color={'common-background'} className={root} title={t(title)}>
                <Header key="SearchHeader" isReport={isReport}>
                    <Flex grow align="center" content="space-between" ref={ref}></Flex>
                </Header>
                {props.loading && !props.error && (
                    <Flex margin="20px 0" align={'center'} content="center">
                        <Loader />
                    </Flex>
                )}
                {props.error && (
                    <Text type="h2" size={22} color="secondary" align="center">
                        {t('common_error')}
                    </Text>
                )}
                {!props.loading && !props.error && props.children && (
                    <GlobalWidth className={props.classes?.content}>{props.children}</GlobalWidth>
                )}
            </PageLayout>
        </Context.Provider>
    )
}

export default AudienceLayout
