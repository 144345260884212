import React from 'react'
import pt from 'prop-types'

import notif from '../../components/Notifier/model/actions.js'

const withDownload = (Service, FileDownloader) => (Component) =>
    class withDownload extends React.PureComponent {
        static get propTypes() {
            return {
                dispatch: pt.func.isRequired,
                t: pt.func.isRequired,
            }
        }
        static get displayName() {
            return `withDownload(${
                Component.displayName || Component.name || 'Component'
            })`
        }

        state = {
            loading: false,
        }

        startDownload = (id) => {
            const { t, dispatch } = this.props
            // dispatch(notif.loading(t("common_loading")))
            this.setState({ loading: true })
            Service(id)
                .then((data) => {
                    // dispatch(notif.success(t("loaded"), 3000))
                    FileDownloader(data)
                })
                .catch((e) => dispatch(notif.error(t('common_error'), 3000)))
                .finally(() => {
                    this.setState({ loading: false })
                })
        }
        render() {
            return (
                <Component
                    {...this.props}
                    loading={this.state.loading}
                    download={this.startDownload}
                />
            )
        }
    }

export { withDownload }
