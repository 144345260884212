import { createSlice } from '@reduxjs/toolkit';

// targetAudienceSlice.js

const initialState = {
    report: null,
    campaign: null,
    enums: null,
    interests: null,
};

const targetAudienceSlice = createSlice({
    name: 'targetAudience',
    initialState,
    reducers: {
        setCampaignData(state, action) {
            state.campaign = { ...state.campaign, ...action.payload };
        },
        setTargets(state, action) {
            state.campaign = { ...state.campaign, ...action.payload };
        },
        setEnums(state, action) {
            state.enums = action.payload;
        },
        setInterests(state, action) {
            state.interests = action.payload;
        },
        setTargetTypeAndDescription(state, action) {
            state.campaign = { ...state.campaign, ...action.payload };
        }
    },
});

export const { setCampaignData, setTargets, setEnums, setInterests, setTargetTypeAndDescription } = targetAudienceSlice.actions;
export default targetAudienceSlice.reducer;
