import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import { en, ru, pt, de} from './sources/'

i18n
	.use(detector)
	.use(initReactI18next)
	.init({
		resources: {
			en: {
				translation: en
			},
			ru: {
				translation: ru
			},
			pt: {
				translation: pt
			},
			de: {
				translation: de
			}
		},
		fallbackLng: "en",
		interpolation: {
	    	escapeValue: false
	    }
	});
