const instagramHostnames = ['www.instagram.com', 'instagram.com']
const youtubeHostnames = ['www.youtube.com', 'youtube.com']
const twitchHostnames = ['www.twitch.tv', 'twitch.tv']
const vkHostnames = ['www.vk.com', 'vk.com']
const tgHostnames = ['t.me']
const tiktokHostnames = ['www.tiktok.com', 'tiktok.com']

const hostnames = [
    ...instagramHostnames,
    ...youtubeHostnames,
    ...twitchHostnames,
    ...vkHostnames,
    ...tgHostnames,
    ...tiktokHostnames,
]

export const parseUrl = (str: string) => {
    //tg
    // https://t.me/dnative/
    // https://t.me/@dnative/
    // https://t.me/s/dnative/
    // t.me/dnative
    // @dnative
    try {
        if (str.includes('/s/')) {
            return str.split('/s/')[1].replaceAll('/', '')
        }
        if (str.includes('t.me')) {
            if (!str.includes('http')) {
                return str.split('/')[1]
            }
            const url = new URL(str)
            return url.pathname.split('/')[1]
        }
    } catch {
        return str.split('?')[0].replaceAll('/', '')
    }
    //other
    try {
        const url = new URL(str)

        if (!hostnames.includes(url.hostname)) return str

        const isYoutube = youtubeHostnames.includes(url.hostname)
        const isTwitch = twitchHostnames.includes(url.hostname)
        const isInstagram = instagramHostnames.includes(url.hostname)
        const isVk = vkHostnames.includes(url.hostname)
        const isTikTok = tiktokHostnames.includes(url.hostname)

        if (isVk) {
            return str
        }

        if (str.includes('@')) {
            return str.split('@')[1].split('/')[0]
        }

        if (isYoutube) {
            if (url.pathname.includes('@')) {
                return url.pathname.split('@')[1].split('/')[0]
            }
            const username = url.pathname.split('/')[2]
            if (!username) return str
            return username
        }

        if (isInstagram) {
            return url.pathname.split('/')[1]
        }

        if (isTwitch) {
            return url.pathname.split('/')[1]
        }

        // if (isVk) {
        //     return url.pathname.split('/')[1]
        // }

        if (isTikTok) {
            return url.pathname.split('/')[1]
        }

        return str
    } catch {
        return str.split('?')[0].replaceAll('/', '').replaceAll('@', '')
    }
}
