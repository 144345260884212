import Service from '@/_helpers/Service'

const ParseService = new Service({
    method: 'POST',
    url: '/api/parsing/',
})

export const parsingRequest = (
    token: string,
    bloggers: { blogger_url: string }[],
    channels: { blogger_url: string }[]
) =>
    ParseService.call(
        {
            data: [...bloggers, ...channels],
        },
        token
    )

const ExternalIdsService = new Service({
    method: 'POST',
    url: '/core/instagram-external-ids/',
})

export const getExternalIds = (token: string, usernames: string[]) =>
    ExternalIdsService.call(
        {
            data: {
                instagram_usernames: usernames,
            },
        },
        token
    )
