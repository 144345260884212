import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.9999 5C10.3975 5 6.66659 8.73096 6.66659 13.3333C6.66659 17.9357 10.3975 21.6667 14.9999 21.6667C19.6023 21.6667 23.3333 17.9357 23.3333 13.3333C23.3333 8.73096 19.6023 5 14.9999 5ZM9.99992 13.3333C9.99992 10.5719 12.2385 8.33333 14.9999 8.33333C17.7613 8.33333 19.9999 10.5719 19.9999 13.3333C19.9999 16.0948 17.7613 18.3333 14.9999 18.3333C12.2385 18.3333 9.99992 16.0948 9.99992 13.3333Z"
            fill="#A3ADBE"
        />
        <path
            d="M28.1806 13.6971C27.7117 13.4581 27.1929 13.3334 26.6666 13.3334V10.0001C27.7192 10.0001 28.7569 10.2493 29.6947 10.7274C29.798 10.7801 29.8998 10.8354 29.9999 10.8932C30.8089 11.3602 31.5119 11.9928 32.0621 12.7509C32.6804 13.6029 33.0881 14.5891 33.252 15.6289C33.4158 16.6688 33.3311 17.7326 33.0048 18.7334C32.6785 19.7342 32.1198 20.6435 31.3745 21.3869C30.6291 22.1303 29.7184 22.6866 28.7167 23.0103C27.8254 23.2983 26.8846 23.3949 25.9558 23.2953C25.8409 23.283 25.7261 23.2677 25.6116 23.2493C24.5729 23.0829 23.5884 22.673 22.7385 22.0532L22.7367 22.0519L24.7017 19.3593C25.1268 19.6695 25.6194 19.8746 26.1391 19.9579C26.6588 20.0412 27.1908 20.0002 27.6916 19.8384C28.1924 19.6765 28.6478 19.3984 29.0204 19.0267C29.3931 18.655 29.6724 18.2004 29.8356 17.7C29.9988 17.1996 30.0411 16.6677 29.9592 16.1478C29.8773 15.6279 29.6734 15.1348 29.3643 14.7089C29.0551 14.2829 28.6495 13.9362 28.1806 13.6971Z"
            fill="#A3ADBE"
        />
        <path
            d="M33.3301 35C33.3301 34.1249 33.1577 33.2584 32.8229 32.45C32.488 31.6415 31.9972 30.907 31.3784 30.2882C30.7596 29.6694 30.0251 29.1786 29.2166 28.8437C28.4081 28.5089 27.5416 28.3365 26.6666 28.3365V25C27.8034 25 28.9306 25.1938 29.9999 25.5719C30.1659 25.6306 30.3304 25.6937 30.4934 25.7612C31.7067 26.2638 32.8091 27.0003 33.7377 27.9289C34.6662 28.8575 35.4028 29.9599 35.9054 31.1732C35.9729 31.3362 36.036 31.5007 36.0947 31.6667C36.4728 32.736 36.6666 33.8632 36.6666 35H33.3301Z"
            fill="#A3ADBE"
        />
        <path
            d="M26.6666 35H23.3333C23.3333 30.3976 19.6023 26.6667 14.9999 26.6667C10.3975 26.6667 6.66659 30.3976 6.66659 35H3.33325C3.33325 28.5567 8.5566 23.3333 14.9999 23.3333C21.4432 23.3333 26.6666 28.5567 26.6666 35Z"
            fill="#A3ADBE"
        />
    </svg>
)
