import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M9.3182 9.48508H0.681821C0.305301 9.48508 0 9.8194 0 10.2425C0 10.6656 0.305301 11 0.681821 11H9.3182C9.6947 11 10 10.6656 10 10.2425C10 9.8194 9.6947 9.48508 9.3182 9.48508Z"
            fill="white"
        />
        <path
            d="M4.48467 8.47145C4.77066 8.92718 5.26825 8.8851 5.51628 8.47145C6.14516 7.42255 8.12198 4.3459 8.12198 4.3459C8.27121 4.10966 8.26436 3.79113 8.10523 3.56316C7.94604 3.33517 7.67232 3.252 7.43261 3.3587L5.91623 4.03361L6.11642 0.69848C6.12737 0.517018 6.07056 0.338662 5.95962 0.206095C5.84871 0.0736673 5.69375 -0.00105521 5.53196 1.12649e-05H4.46893C4.3071 -0.00105521 4.15216 0.0736441 4.04125 0.206095C3.93033 0.338662 3.87351 0.517041 3.88445 0.69848L4.08465 4.03361L2.56828 3.3587C2.32861 3.252 2.05485 3.33517 1.89566 3.56316C1.73653 3.79113 1.72968 4.10963 1.87893 4.3459C1.87893 4.3459 3.83603 7.43786 4.48467 8.47145Z"
            fill="white"
        />
    </svg>
)
