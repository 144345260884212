import Avatar from '@/Layouts/ReportLayout/Content/Avatar/Avatar'
import MainInfo from '@/Layouts/ReportLayout/Content/MainInfo/MainInfo'
import Metrics from '@/Layouts/ReportLayout/Content/Metrics/Metrics'
import { useSpaceTranslation } from '@/_helpers'
import { RootStore } from '@/_helpers/StoreType'
import Flex from '@/_yc/Flex'
import React from 'react'
import { useSelector } from 'react-redux'
import { Platform } from '../../types'

import cn from './ReportHeader.module.scss'

export const ReportHeader = () => {
    const t = useSpaceTranslation('channel_report')

    const metrics = useSelector((store: RootStore) => {
        const userInfo = store.report.rawTwitchData?.getTwitchUser?.data?.twitch_userinfo[0]

        if (!userInfo) {
            return {
                name: '',
                verified: false,
                link: '',
                topics: [],
                avatar: '',
            }
        }

        const name = userInfo.display_name
        const avatar = userInfo.profile_image_url
        const link = `https://www.twitch.tv/${userInfo.login}`
        return {
            name,
            verified: false,
            link,
            topics: [],
            avatar,
        }
    })

    const restOfData = useSelector((store: RootStore) => {
        const metrics = store.report.rawTwitchData?.getTwitchUser?.data?.twitch_userinfo[0]

        if (!metrics) {
            return []
        }

        let daysAgo = null
        if (metrics.last_video_date) {
            const lastVideoDate = new Date(metrics.last_video_date)
            daysAgo = Math.floor((new Date().getTime() - lastVideoDate.getTime()) / (1000 * 3600 * 24))
        }

        const rest = [
            {
                value: metrics.subscriber_count,
                name: t('metrics.sub_count'),
            },
            {
                value: metrics.median_view_count_20_videos,
                name: t('metrics.avg_views'),
            },
        ]

        if (daysAgo !== null) {
            rest.push({
                value: daysAgo,
                name: t('metrics.last_video'),
            })
        }

        return rest
    })

    return (
        <div className={cn.header}>
            <Flex content="flex-start">
                <Avatar type={Platform.Twitch} url={metrics.avatar} />
                <MainInfo
                    data={{
                        name: metrics.name,
                        verified: false,
                        link: metrics.link,
                        topics: [],
                    }}
                />
            </Flex>

            <Metrics restOfData={restOfData} />
        </div>
    )
}
