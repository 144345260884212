import React from 'react'

import Icons from '@/components/Icons'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
// import service from '@/pages/Auth/LoginPage/Model/_service'

import ItemsHead from './ItemsHead'
import SearchItem from './SearchItem/SearchItem'
import { Adapter } from '../../Adapter'
import { getMainFilters, getFilters } from './filters/filters'
import { pageToOffset } from '../youtube/utils/queries/pageToOffset'
import { getTikTokChannelsQuery, buyTikTokReportsQuery } from './utils/queries'
import { getFilterQueries } from './utils/getFilters'

import { getTikTokAccountsFromRaw, getTikTokAccountsFromRawUnhide } from './utils/PreprData'
import { QueryType, TikTokSearchResult, ISearchBy, TikTokUnhideResult } from './utils/types'
import { getSearchByTable } from './utils/searchByTable'

// import { RootStore } from '@/_helpers/StoreType'
// import { store } from '@/_helpers/store'

import cn from './Tg.module.scss'

const fetcher = new Fetcher()
// const getIsFullTariff = async () => {
//     // @ts-ignore
//     const platformsRes = await service.getSubscriptionPlatforms()
//     const isTgAvailable = platformsRes?.is_telegram_available ?? false
//     return !isTgAvailable
// }
export const TikTokAdapter = new Adapter({
    Head: ItemsHead,
    background: `linear-gradient(180deg, #5ca4f5 0%, #593cd9 100%)`,
    Icon: () => <Icons className={cn.icon} name="ie-tiktok" />,
    fetcher: async (_, config) => {
        const client = fetcher.getClient()

        const offSet = pageToOffset(config.page, 10)
        const search = `args:{search:"${config.search}"},`

        const queries = getFilterQueries(config.filters)

        const queryType: QueryType = getSearchByTable(config.by as ISearchBy[])

        const queryParams = {
            orderBy: config.sorting,
            offset: offSet,
            filters: queries,
        }
        const accounts = await client.query<TikTokSearchResult>({
            query: getTikTokChannelsQuery(search, queryParams),
        })

        // const isTgAvailable = false
        // const isFullTariff = !isTgAvailable

        // const isFullTariff = await getIsFullTariff()
        // const hasNext = !isFullTariff || config.page !== 1
        // const sliceIndex = isFullTariff && (config.search !== '' || queries.length > 0) ? 3 : 30

        const searchResults = getTikTokAccountsFromRaw(queryType, accounts.data, 10, offSet)
        const hiddenAccountsLength = searchResults.filter((item) => item.hiddenReportId !== undefined).length
        const showIsHiddenComponent = hiddenAccountsLength > 0

        //if items include hidden result, hasNext - false
        return {
            hasNext: !showIsHiddenComponent, //hasNext,
            total: accounts.data.searchTikTokWithFilter.data.total, //amount.data[`${queryType}_aggregate`].aggregate.count || 0,
            items: searchResults,
            isHidden: showIsHiddenComponent, //isFullTariff,
            unhideCost: accounts.data.searchTikTokWithFilter.data.unhide_cost,
        }
    },
    buyMoreReports: async (_, config, searchResultIds) => {
        const client = fetcher.getClient()

        const accounts = await client.query<TikTokUnhideResult>({
            query: buyTikTokReportsQuery(),
            variables: {
                search_result_ids: searchResultIds,
            },
        })

        return {
            items: getTikTokAccountsFromRawUnhide('unhideSearchTikTok', accounts.data, 10),
        }
    },
    countCheck: undefined,
    keyWords: [
        // { label: 'forKeywords.accName', value: 'title' },
        // { label: 'forKeywords.accDescription', value: 'description' },
    ],
    sortings: [
        {
            label: `forKeywords.subCount`,
            value: 'sort: {field: "followers"}',
        },
        {
            label: 'forKeywords.medianER',
            value: 'sort: {field: "engagement_rate"}',
        },
    ],
    mainFilters: getMainFilters(fetcher) as any,
    filters: getFilters(fetcher) as any,
    templateColumns: '',
    title: 'TikTok',
    Item: SearchItem,
})
