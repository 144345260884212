import React, { useEffect } from 'react'
import { headers } from './data'
import List from '@/pages/TargetAudience/Views/components/list'
import { fetchCampaignsReq } from '@/pages/TargetAudience/services/services'
import useToken from '@/_helpers/Hooks/useToken'
import { ICampaignResponse } from '@/pages/TargetAudience/services/types'

const Index = () => {
    const [tableData, setTableData] = React.useState<ICampaignResponse[]>([])
    const [loading, setLoading] = React.useState(false)
    const token = useToken()

    useEffect(() => {
        setLoading(true)
        fetchCampaignsReq(token)
            .then((res) => {
                setTableData(res)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [token])

    return <List headers={headers} data={tableData} loading={loading} />
}

export default Index
