import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootStore } from '@/_helpers/StoreType'
import Flex from '@/_yc/Flex'
import AddToListPopup from '@/pages/Lists/components/AddToList/AddToList'
import { CreateListPlace } from '@/_helpers/amplitude/types'

import {
    RemoveListINST,
    RemoveListTwitch,
    RemoveListYT,
    SetLastClicked,
    RemoveListsAud,
    RemoveListVk,
    RemoveListTg,
} from './_model/actions'
import DeleteFromListButton from './DeleteFromListButton'
import Checkbox from './Checkbox'

interface IMassAddHeadCheckBox {
    deleteFromList?: boolean //true - delete; false - add
    activeListId?: number
    place: CreateListPlace
    send_ig_bloggers_on_full_parsing_if_needed?: boolean
}

export const MassAddHeadCheckBox: FC<IMassAddHeadCheckBox> = ({
    deleteFromList,
    activeListId,
    place,
    send_ig_bloggers_on_full_parsing_if_needed,
}) => {
    const dispatch = useDispatch()

    const MassAddToListDataINST = useSelector((store: RootStore) => store.MassAddToList.dataINST)
    const MassAddToListDataYT = useSelector((store: RootStore) => store.MassAddToList.dataYT)
    const MassAddToListDataTwitch = useSelector((store: RootStore) => store.MassAddToList.dataTwitch)
    const MassAddToListDataVk = useSelector((store: RootStore) => store.MassAddToList.dataVk)
    const MassAddToListDataTg = useSelector((store: RootStore) => store.MassAddToList.dataTg)

    const emptyList =
        MassAddToListDataINST.length === 0 &&
        MassAddToListDataYT.length === 0 &&
        MassAddToListDataTwitch.length === 0 &&
        MassAddToListDataVk.length === 0 &&
        MassAddToListDataTg.length === 0

    const handleClick = () => {
        dispatch(RemoveListINST())
        dispatch(RemoveListYT())
        dispatch(SetLastClicked(undefined))
        dispatch(RemoveListTwitch())
        dispatch(RemoveListVk())
        dispatch(RemoveListTg())
    }

    return (
        <>
            {!emptyList ? (
                <>
                    <Flex onClick={handleClick}>
                        <Checkbox value={'dashed'} />
                    </Flex>
                    {deleteFromList && (
                        <DeleteFromListButton
                            list={{
                                instList: MassAddToListDataINST.map((item) => item.id),
                                ytList: MassAddToListDataYT.map((item) => item.id),
                                twitchList: MassAddToListDataTwitch.map((item) => item.id),
                                vkList: MassAddToListDataVk.map((item) => item.id),
                                tgList: MassAddToListDataTg.map((item) => item.id),
                            }}
                            activeListId={activeListId || 0}
                        />
                    )}
                    <Flex>
                        <AddToListPopup
                            place={place}
                            external_id={''}
                            massAdd={true}
                            massAddList={{
                                instIdList: MassAddToListDataINST.map((item) => item.externalId || item.id),
                                ytList: MassAddToListDataYT.map((item) => item.channelId || item.id),
                                twitchList: MassAddToListDataTwitch.map((item) => item.channelId || item.id),
                                vkList: MassAddToListDataVk.map((item) => item.channelId || item.id),
                                tgList: MassAddToListDataTg.map((item) => item.externalId || item.id),
                                instUsernameList: [] as string[],
                            }}
                            send_ig_bloggers_on_full_parsing_if_needed={send_ig_bloggers_on_full_parsing_if_needed}
                        />
                    </Flex>
                </>
            ) : null}
        </>
    )
}

export const MassAddHeadCheckBoxCATALOG: FC<{ children: React.ReactNode }> = ({ children }) => {
    const dispatch = useDispatch()

    const catalogOfLists = useSelector((store: RootStore) => store.AudienceInsights.ListsInfo)

    const handleClick = () => {
        dispatch(RemoveListsAud())
    }

    return (
        <>
            {catalogOfLists && catalogOfLists.length !== 0 ? (
                <>
                    <Flex onClick={handleClick}>
                        <Checkbox value={'dashed'} />
                    </Flex>
                    {children}
                </>
            ) : null}
        </>
    )
}
