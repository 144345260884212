import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'

import cn from './Trippler.module.scss'

const Trippler = props => {

	const { t } = useTranslation()
	let defaultValue = props.defaultValue || null;
	const { styles } = props;
	const pointStyles = styles && styles.point ? styles.point : {};
	const rootStyles = styles && styles.root ? styles.root : {};
	return (
		<div className = {cn.root} style = {{ background: props.bg, ...rootStyles }}>
			{props.options.map((item, index) =>{
				const notDivide = index === props.options.length - 1 || 
								  (props.value && props.value.value === item.value) ||
								  (props.value && props.options[index+1] && props.value.value === props.options[index+1].value)
				return(
					<React.Fragment key = {index}>
						<div 
							onClick={
								props.disabled ? undefined : 
								props.value && item.value===props.value.value ? e => props.onChange({ value: defaultValue }) :
								e => props.onChange({value: item})
							}
							style={{ 
								cursor: !props.disabled ? "pointer" : "default",
								color: props.activeColor && props.value && item.value === props.value.value ? props.activeColor : undefined,
								background: props.activeBg && props.value && item.value === props.value.value ? props.activeBg : undefined,
								...pointStyles
							}}
							className = {props.value && item.value===props.value.value ? cn["point--active"] : cn["point--default"]}
						>
							{!props.disableTranslation ? t("filters." + item.label) : item.label}
						</div>
						{notDivide ? <div className = {cn.border_empty} /> : <div className = {cn.border} />}
					</React.Fragment>
				)})}
		</div>
	)
}

Trippler.propTypes = {
	options: PropTypes.array.isRequired,
	styles: PropTypes.shape({
		point: PropTypes.object,
		root: PropTypes.object
	})
}

const MemoTrippler = React.memo(Trippler)

export { MemoTrippler as Trippler }