import React, { FC, memo, useMemo, useRef } from 'react'
import Button from '@/components/Button'
import Icons from '@/components/Icons'
import useOpenClose from '@/_helpers/useOpenClose'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { Flex, GlobalWidth } from '@/_yc'
import { useAdapter, useSearch, useSearchBy, useSearchText } from '../../SearchAdapter'
import Filters from './Filters'
import cn from './SearchPanel.module.scss'
import Chips from './Chips'
import { useLocation } from 'react-router-dom'
import { LocTypeToTitle } from '../../utils/LocTypeToTitle'
import { useSelector } from 'react-redux'
import { trackSearchValue, trackShowResultsBtn } from '@/_helpers/amplitude/track'
import { TabAdapterSelector } from './TabAdapterSelctor/AdapterSelector'
import { MainFilters } from './MainFilters/MainFilters'
import SearchInput from './SearchInput'
import useWindowDimensions from '@/_helpers/Resize/useWindowDimensions'
import PageTitle from '@/components/PageTitle'
import { useInstagramHidden } from '@/_helpers/Hooks/useIsInstagramHidden'
export interface ISearchBar {}

const SearchBar: FC<ISearchBar> = () => {
    const ref = useRef(null)

    const [state, , close, toggle] = useOpenClose(false)

    const t = useSpaceTranslation('Influencer_explorer')

    const { restart } = useSearch()
    const loc = useLocation()
    const title = LocTypeToTitle(loc.pathname)

    const { title: platform } = useAdapter()
    const { search } = useSearchText()
    const { searchBy } = useSearchBy()

    const onClick = () => {
        trackShowResultsBtn(platform)
        trackSearchValue(platform, search, searchBy)
        restart()
    }

    const { width } = useWindowDimensions()

    const linkToHelper = useSelector<any, string>((state) => state.Commercial_.value.hp_iexplorer)

    const isInstagramHidden = useInstagramHidden()

    const isSquare = useMemo(() => {
        if (platform === 'Instagram') return true
        if (platform === 'YouTube' && isInstagramHidden) return true
    }, [isInstagramHidden, platform])

    return (
        <>
            <div className={state ? cn.hider : ''} />
            <div className={cn.root} ref={ref}>
                <GlobalWidth>
                    <PageTitle title={t(title)} linkToHelper={linkToHelper} icon="ie-icon" />

                    <div className={cn.controls}>
                        <div className={cn.tabAdapterDisplay}>
                            <TabAdapterSelector />
                        </div>
                        {(!state || width < 1280) && (
                            <>
                                <div
                                    className={cn.mainFiltersBarContainer}
                                    style={{ borderRadius: isSquare ? '0px 10px 10px 10px' : '10px' }}
                                >
                                    <div className={cn.inputDisplay}>
                                        <SearchInput className={cn.grow} toggleFilters={toggle} />
                                    </div>
                                    <div className={cn.mainFiltersDisplay}>
                                        <MainFilters />
                                    </div>
                                    <Flex>
                                        <Button variant="transparent" className={cn.btn} onClick={toggle}>
                                            <Icons className={cn.icon} name="slider" />
                                            <span className={cn.filtersBtnTitle}>{t('filters')}</span>
                                        </Button>
                                        <Button
                                            onClick={onClick}
                                            margin="0"
                                            variant="primary"
                                            width={160}
                                            className={cn[state ? 'button--hidden' : 'button--visible']}
                                        >
                                            {t('show_results')}
                                        </Button>
                                    </Flex>
                                </div>
                                <Chips filtersState={state} filtersOpen={toggle} />
                            </>
                        )}
                        <Filters onClose={close} isOpen={state} />
                    </div>
                </GlobalWidth>
            </div>
        </>
    )
}

export default memo(SearchBar)
