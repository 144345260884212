import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setSorting as action } from '../../Model/actions'
import { RootStore } from '@/_helpers/StoreType'
import { useSearch } from './useSearch'
import { useAdapter } from './useAdapter'

export const useSorting = () => {
    const value = useSelector((store: RootStore) => store.search.sorting)

    const { sortings } = useAdapter()

    const sorting = useMemo(() => {
        return value ?? sortings[0].value
    }, [value, sortings])

    const { restart } = useSearch()

    const dispatch = useDispatch()

    const setDefault = useCallback(() => {
        dispatch(action({ sorting: sortings[0].value }))
        restart()
    }, [dispatch, restart, sortings])

    const setSorting = useCallback(
        (next: string | null) => {
            dispatch(action({ sorting: next }))
            restart()
        },
        [dispatch, restart]
    )

    return { sorting, setSorting, options: sortings, setDefault }
}
