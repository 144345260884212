import { CalendarFilter, IWithDate } from '@/pages/SearchPage/SearchAdapter/filters/CalendarFilter'

const getGqlFilter = (value: IWithDate) => {
    if (!value.dates || value.dates.length === 0) return ''
    return `postDate: ${
        value.range ? `{gt: {date: "${value.dates[0]}"}, lt: {date: "${value.dates[1]}"}` : `{in: "${value.dates[0]}"`
    }}`
}

export const getPostDateFilter = () => {
    const translationPrefix = 'ads_explorer.filters.post_metrics.date'

    return new CalendarFilter({
        platform: 'ads_ig',
        title: `${translationPrefix}.label`,
        helper: `${translationPrefix}.text`,
        space: 1,
        beforeSend: (value) => ({
            name: 'metrics.date',
            type: 'number',
            value: value,
            gqlFilter: getGqlFilter(value),
        }),
    })
}
