import React, { useRef, FC, useContext } from 'react'

import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import useOpenClose from '@/_helpers/useOpenClose'
import Text from '@/components/Text'
import Popup from '@/_yc/Popup/Popup'
import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'

import { SendToMediaPlan } from './SendToMediaPlan/SendToMediaPlan'

import cn from './ToMediaPlan.module.scss'
import { MediaPlansListRaw } from '@/pages/MediaPlanning/utils/types'
import { useQuery } from '@apollo/client'
import { CampaignManagerContext } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerContext'
import { GET_MEDIA_PLANS_LIST } from '@/pages/MediaPlanning/utils/queries'

export const ToMediaPlan: FC = () => {
    const t = useSpaceTranslation('aud_insides.send_to_media_plan')
    const ref = useRef(null)
    const [isOpen, open, close] = useOpenClose()

    const context = useContext(CampaignManagerContext)
    const { data, loading, error } = useQuery<MediaPlansListRaw>(GET_MEDIA_PLANS_LIST, {
        context: context,
    })

    return (
        <>
            <Flex ref={ref} onClick={open} align="center" margin="0 0 0 30px" className={cn.btn}>
                <Icons name="media-planner" className={cn.icon} />

                <Text color="gray-2" fSize={10} semibold margin="0 0 0 6px">
                    {t('add_to_media_plan')}
                </Text>
            </Flex>

            {isOpen && ref && ref.current && (
                <>
                    <Popup
                        placement="bottom-start"
                        isOpen={isOpen}
                        onClose={close}
                        anchor={ref.current}
                        style={{ zIndex: 5 }}
                    >
                        <Flex className={cn.popup} column>
                            <SendToMediaPlan independentBTN={false} addType="new" />

                            {data?.mediaPlanOrder && data?.mediaPlanOrder.length > 0 && !loading && !error && (
                                <SendToMediaPlan independentBTN={false} addType="add" />
                            )}
                        </Flex>
                    </Popup>
                </>
            )}
        </>
    )
}
