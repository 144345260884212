import React, { FC, useEffect, useState } from 'react'
import moment from 'moment'

import Flex from '@/_yc/Flex'
import useToken from '@/_helpers/Hooks/useToken'
import { GetPostStatisticsReq, IPostStatistics } from './service'
import Loader from '@/components/Loader'
import Text from '@/components/Text'
import Icons from '@/components/Icons'
import { shortNum } from '@/_helpers/_graphs/_tech'
import Avatar from '@/components/Avatar'
import Button from '@/components/Button'
import { getNumberWithSpaces } from '@/_helpers/nums/numberWithSpaces'
import { PostCover } from '@/pages/SearchPage/SearchAdapter/adsExplorer/PostCover/PostCover'
import CloseIcon from '@material-ui/icons/Close'

import GrowGraph from './GrowGraph/GrowGraph'
import { StatsTable } from './StatsTable'

import cn from '../PostsGrid/PostItem/PostItem.module.scss'
import cn2 from './Report.module.scss'
import { useTranslation } from 'react-i18next'

export interface IPostReport {
    post_id: string | undefined
    platform: string
    closeFunc?: Function
    channel_name?: string
}

export const Report: FC<IPostReport> = ({ post_id, platform, closeFunc, channel_name }) => {
    const [status, setstatus] = useState<{ error: boolean; loading: boolean }>({ error: false, loading: true })
    const token = useToken()
    const [data, setdata] = useState<IPostStatistics>()
    const { t } = useTranslation()

    useEffect(() => {
        setstatus({ error: false, loading: true })
        if (!token || !post_id) {
            setstatus({ error: true, loading: false })
            return
        }
        if (platform === 'telegram' && !channel_name) {
            setstatus({ error: true, loading: false })
            return
        }
        GetPostStatisticsReq(token, platform, post_id, channel_name)
            .then((res) => {
                setstatus({ error: false, loading: false })
                setdata(res)
            })
            .catch(() => {
                setstatus({ error: true, loading: false })
            })
    }, [token, post_id, platform, channel_name])

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'unset'
        }
    })
    return (
        <>
            {status.loading && (
                <Flex content="center" align="center" style={{ height: '100%' }}>
                    <Loader />
                </Flex>
            )}
            {!status.loading && status.error && (
                <Flex content="center" align="center" style={{ height: '100%' }}>
                    <Text>{t('campaign_manager.hider.req.error')}</Text>
                </Flex>
            )}
            {!status.loading && !status.error && !data && (
                <Flex content="center" align="center" style={{ height: '100%' }}>
                    <Text>{t('campaign_manager.hider.req.error')}</Text>
                </Flex>
            )}
            {!status.loading && !status.error && data && (
                <Flex style={{ columnGap: '30px', height: '100%' }}>
                    <Flex style={{ width: '270px', rowGap: '12px' }} column>
                        <Flex className={cn['postItem--header']} content="space-between">
                            <Flex align="center">
                                <div className={cn.bloggerAvatar}>
                                    <Avatar
                                        alt="AVA"
                                        src={data?.avatar_url ?? ''}
                                        size={32}
                                        loadingAvatarWidth="32"
                                        aria-label="Аватарка"
                                        className={cn.ava}
                                    />
                                </div>

                                <div className={cn['postItem--header--info']}>
                                    {data?.blogger_info?.username ||
                                    data?.blogger_info?.full_name ||
                                    data?.channel_name ? (
                                        <>
                                            <Text color={'gray-1'} fSize={12} bold>
                                                {data?.blogger_info?.username ??
                                                    data?.blogger_info?.full_name ??
                                                    data?.channel_name ??
                                                    ''}
                                            </Text>
                                            {data?.blogger_info?.followers && (
                                                <Text color={'gray-3'} fSize={10}>
                                                    {shortNum(data?.blogger_info?.followers) +
                                                        ' ' +
                                                        t('campaign_manager.campaign.content.subs')}
                                                </Text>
                                            )}
                                        </>
                                    ) : (
                                        <div
                                            style={{
                                                width: '100px',
                                                height: '16px',
                                                borderRadius: '10px',
                                                backgroundColor: '#F2F4F7',
                                            }}
                                        />
                                    )}
                                </div>
                            </Flex>
                            <Flex className={cn.socials} align="center" margin="0 0 0 8px">
                                {data.service_type === 'youtube' ? (
                                    <>
                                        <Icons name="ie-youtube" />
                                    </>
                                ) : data.service_type === 'instagram' ? (
                                    <>
                                        <Icons name="ie-instagram" />
                                    </>
                                ) : data.service_type === 'vk' ? (
                                    <>
                                        <Icons name="ie-vk" />
                                    </>
                                ) : data.service_type === 'telegram' ? (
                                    <>
                                        <Icons name="ie-tg" />
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Flex>
                        </Flex>

                        <Flex content={'center'}>
                            <PostCover url={data?.preview_url ? data?.preview_url : ''} />
                        </Flex>

                        <Text color="gray-3" font-size={12}>
                            {moment(data?.created_at).format('DD.MM.YYYY')}
                        </Text>

                        <Text color="gray-1" font-size={14}>
                            {data?.text}
                        </Text>

                        <Flex content={'space-between'}>
                            <Flex content={'flex-start'} align={'center'}>
                                <Icons name={'likes'} className={cn.blackIcon} />
                                <Text margin={'0 0 0 10px'}>{data?.likes && getNumberWithSpaces(data?.likes)}</Text>
                            </Flex>

                            <Flex content={'flex-start'} align={'center'}>
                                <Icons name={'comments'} className={cn.blackIcon} />
                                <Text margin={'0 0 0 10px'}>
                                    {data?.comments && getNumberWithSpaces(data?.comments)}
                                </Text>
                            </Flex>
                        </Flex>

                        <a href={data?.url} target="_blank" rel="noopener noreferrer">
                            <Button variant="outline" style={{ width: '100%' }}>
                                {t('campaign_manager.campaign.content.report.open')}
                            </Button>
                        </a>
                    </Flex>
                    <div className={cn2.divider} />
                    <Flex grow column>
                        <Flex style={{ width: '100%' }} content="space-between">
                            <Text color="gray-1" fSize="20px" fWeight="600">
                                {t('campaign_manager.campaign.content.report.title')}
                            </Text>
                            <Flex onClick={closeFunc} style={{ cursor: 'pointer' }}>
                                <CloseIcon className={cn['close']} />
                            </Flex>
                        </Flex>
                        <GrowGraph data={data.statistic} />

                        <StatsTable data={data.statistic} />
                    </Flex>
                </Flex>
            )}
        </>
    )
}
