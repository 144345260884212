import { RangeFilter, RangeValueType } from '@/pages/SearchPage/SearchAdapter/filters/RangeFilter'
import { getRange } from '../../utils/getRange'

const getGqlFilter = (range: RangeValueType) => {
    return `comments: {${getRange(range.from, range.to)}}`
}

export const getCommentsFilter = () => {
    return new RangeFilter({
        platform: 'ads_ig',
        title: 'ads_explorer.filters.post_metrics.comments.label',
        helper: 'ads_explorer.filters.post_metrics.comments.text',
        space: 1,
        beforeSend: (data) => {
            return {
                name: 'metrics.comments',
                type: 'range',
                value: data,
                gqlFilter: getGqlFilter(data),
            }
        },
    })
}
