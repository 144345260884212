import { useMemo, useState, useCallback } from "react"

/**
 * @param initialArray array for sorting
 * @param sort sort function, that getting array elements A, B and sortingKey;
 * @param initialSortingKey initial sorting key
 * @returns [
 *      sorted_array, 
 *      setSortingKey - (nextKey: string | null) => void, 
 *      current sorting key - string | null
 * ]
 * @description this hook is used for easy sorting;
 * @example 
 *  const Example = props => {    
 *      const [array] = useState([3, 2, 1, 5, 2, 8]);
 *      const [sorted, setSortingKey] = useSorting(array, (a, b, sortingKey) => {
 *          if(sortingKey === "apper"){
 *              return a - b 
 *          }else{
 *              return b - a
 *          }
 *      }, null)
 * 
 *      return (
 *          <div>
 *              {sorted.map((n, i) => (
 *                  <span key = {i}>{n}</span>
 *              ))}
 *          </div>
 *      )
 *  }
 */
const useSorting = <ArrayType>(
    initialArray: ArrayType[], 
    sort: (elemA: ArrayType, elemB: ArrayType, sortingKey: string) => number,
    initialSortingKey: string | null = null
): [ArrayType[], (sortingKey: string | null) => void, string | null] => {

    const [sortingKey, setSortingKey] = useState<string | null>(initialSortingKey || null);

    const upgradedSetter = useCallback((nextKey: string | null) => {
        setSortingKey(prev => prev === nextKey ? null : nextKey)
    }, [])

    const sortedArray = useMemo<ArrayType[]>(
        () => {
            if(!sortingKey) return initialArray;
            return initialArray.slice(0).sort((a, b) => sort(a, b, sortingKey))
        },
        // eslint-disable-next-line
        [sortingKey, initialArray]
    )

    return [sortedArray, upgradedSetter, sortingKey]
}

export default useSorting