import { service } from './_services.js';
import Const, { BLOGGER_REPORT_CLEAR } from './_constants.js'
import { snackActions } from '@/components/Notifier/model/actions.js'
import { history } from '@/_helpers/'
import hdActions from '@/components/HeaderMenu/Model/actions.js'
import i18next from 'i18next'

import { REPORT, LISTS, DEMO_REPORT } from '@/routs.js'


const action = {
	loadReport: id => dispatch=>{
		dispatch({ type: Const.BLOGGER_REVIEW_REQUEST })

		const checkReportStatus = (externalID) => {
			service.requireReview(externalID).then((res) => {
				dispatch({ type: Const.BLOGGER_REPORT_STATUS, payload: res.blogger.ready_status })
			})
		}

		return service.loadReview(id).then(
			payload => {
				dispatch({ type: Const.BLOGGER_REVIEW_REQUEST_SUCCESS, payload })
				checkReportStatus(payload.data.mainInfo.externalId)
			},
			payload=>dispatch({ type: Const.BLOGGER_REVIEW_REQUEST_FAILURE, payload })
		)
	},
	updateReport: id => dispatch => {
		return service.loadReview(id).then(
			payload => dispatch({ type: Const.BLOGGER_REVIEW_REQUEST_SUCCESS, payload }),
			payload => dispatch({ type: Const.BLOGGER_REVIEW_REQUEST_FAILURE, payload })
		)
	},
	requireReview: (id, cb, data, solo) => dispatch => {
		const t = i18next.getFixedT();
		!data && dispatch(snackActions.loading(t("common_loading")))
		return service.requireReview(id, data).then(
			res => {
				if (cb) cb(res);
				if (!data || (solo && !res.blogger.on_parsing)) {
					dispatch(snackActions.success(t("report.alerts.loaded"), 3000))
					dispatch(hdActions.getCount())
					let ua = navigator.userAgent.toLowerCase(),
						safari = false;
					if (ua.indexOf('safari') !== -1) { 
						if (ua.indexOf('chrome') > -1) {
							safari = false;
						} else {
							safari = true;
						}
					}
					if (safari) {
						history.push(`${REPORT}/${res.id}`);
						dispatch(action.loadReport(res.id))
						return
					}
					
					window.open(`${window.location.origin}${REPORT}/${res.id}`)
				}
				if (res.blogger.on_parsing) {
					history.push(LISTS)
				}
			},
			err => {
				if (err.response && err.response.status===403) {
					let ua = navigator.userAgent.toLowerCase(),
						safari = false;
					if (ua.indexOf('safari') !== -1) {
						if (ua.indexOf('chrome') > -1) {
							safari = false;
						} else {
							safari = true;
						}
					}
					if (safari) {
						history.push(`${DEMO_REPORT}/${id}/`)
					} else {
						window.open(`${window.location.origin}${DEMO_REPORT}/${id}`)
					}
					
					return dispatch(snackActions.error(t("report.alerts.renew"), 3000))
				}
				dispatch(snackActions.error(t("common_error", 3000)))
			}
		)
	},
	updateReportStatus: (payload)=>{return {type: Const.BLOGGER_REPORT_STATUS, payload}},
	clear:() => ({ type: BLOGGER_REPORT_CLEAR })
}

export const switch_actions = {
	switch: (name, value)=>({ type: Const.BLOGGER_REPORT_SWITCH_CONTENT, name, value }),
	init: payload => ({ type: Const.BLOGGER_REPORT_SWITCH_INIT, payload })
}

export { action, action as default }