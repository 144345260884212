import React from 'react'
import PageLayout from '@/Layouts/PageLayout'
import { useTranslation } from 'react-i18next'
import { Header } from '../Header/Header'
import Flex from '@/_yc/Flex'
import { AllChats } from './AllChats/AllChats'
import { Chat } from './Chat/Chat'
import { Campaigns } from './Campaigns/Campaigns'
import { GlobalWidth } from '@/_yc'

export const Messenger = () => {
    const { t } = useTranslation()

    return (
        <PageLayout title={t('titles.t6')} color="common-background">
            <Header />

            <GlobalWidth fixedWidth>
                <Flex content="space-between">
                    <AllChats />
                    <Chat />
                    <Campaigns />
                </Flex>
            </GlobalWidth>
        </PageLayout>
    )
}
