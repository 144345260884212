import React, { FC } from 'react'
import cn from './BloggerItem.module.scss'
import Flex from '@/_yc/Flex'
import Text from '@/components/Text'
import Avatar from '@/components/Avatar'

interface BloggerItemProps {
    avatar: string
    name: string
    username: string
    id: string
}

export const BloggerItem: FC<BloggerItemProps> = ({ avatar, name, username, id }) => {
    return (
        <div className={cn.listItem}>
            <Flex>
                <Avatar alt="AVA" src={avatar} aria-label="Аватарка" className={cn.avatar} />

                <div className={cn.name}>
                    {name && (
                        <Flex align="center">
                            <Text fSize={14} margin="0 5px 0 0">
                                {name}
                            </Text>
                        </Flex>
                    )}
                    <Flex align="center">
                        <Text fSize={12} color="gray-3" margin="0 5px 0 0">
                            {username}
                        </Text>
                    </Flex>
                </div>
            </Flex>
        </div>
    )
}
