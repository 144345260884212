import Portal from '@/_helpers/Portal'
import Flex from '@/_yc/Flex'
import Popup from '@/_yc/Popup'

import React, { FC, useCallback, useState } from 'react'
import { Configuration, OpenAIApi } from 'openai'

import Text from '@/components/Text'

import cn from './ApiKey.module.scss'
import Icons from '@/components/Icons'
import { useSpaceTranslation } from '@/_helpers'
import Button from '@/components/Button'
import { Link } from 'react-router-dom'

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 460,
    zIndex: 2000,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}

interface ApiKeyProps {
    isOpen: boolean
    close: () => void
    onSave: () => void
}

export const ApiKey: FC<ApiKeyProps> = (props) => {
    const t = useSpaceTranslation('outreach.templates.create.editor.generator.api_key')
    const onClose = () => {
        props.close()
    }

    const [apiKey, setApiKey] = useState('')
    const [isKeyCorrect, setIsKeyCorrect] = useState(false)
    const [isKeyChecked, setIsKeyChecked] = useState(false)

    const onSaveApiKey = useCallback(() => {
        localStorage.setItem('openai_api_key', apiKey)
        props.onSave()
    }, [apiKey, props])

    const onCheckApiKey = useCallback(async () => {
        const configuration = new Configuration({
            apiKey: apiKey,
        })
        const openai = new OpenAIApi(configuration)

        openai
            .listModels()
            .then((response) => {
                setIsKeyCorrect(true)
                setIsKeyChecked(true)
            })
            .catch((error) => {
                setIsKeyCorrect(false)
                setIsKeyChecked(true)
                console.log(error)
            })
    }, [apiKey])

    return (
        <Portal>
            <div className={cn.root}>
                <Popup style={style} isOpen={props.isOpen} onClose={props.close}>
                    <div className={cn.popup}>
                        <Flex content="space-between" align="center" margin="0 0 20px 0">
                            <Flex column>
                                <Text fSize={20}>{t('title')}</Text>
                            </Flex>
                            <div onClick={onClose}>
                                <Icons name="close" className={cn.close}></Icons>
                            </div>
                        </Flex>

                        <Text color="gray-new-2">
                            {t('subtitle')}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://platform.openai.com/account/api-keys"
                            >
                                https://platform.openai.com/account/api-keys
                            </a>
                        </Text>

                        <div style={{ margin: '20px 0 20px 0' }}>
                            <Flex margin="0 0 6px 0">
                                <Text color="gray-1" fSize={12} semibold>
                                    {t('enter')}
                                </Text>
                            </Flex>

                            <Flex style={{ gap: 6 }}>
                                <input
                                    placeholder={t('placeholder')}
                                    className={
                                        isKeyChecked
                                            ? isKeyCorrect
                                                ? cn['inputField--correct']
                                                : cn['inputField--error']
                                            : cn.inputField
                                    }
                                    value={apiKey}
                                    onChange={(e) => setApiKey(e.target.value)}
                                ></input>

                                <Button onClick={onCheckApiKey}>{t('check')}</Button>
                            </Flex>
                        </div>

                        <div className={cn.security}>
                            <Text fSize={12} color="gray-new-2">
                                {t('security')} <Link to="/settings">{t('settings')}</Link>
                            </Text>
                        </div>

                        <Flex content="flex-end" margin="20px 0px">
                            <Button onClick={onSaveApiKey} variant="primary">
                                {t('save')}
                                <Icons className={cn.sendIcon} name="arrow-right" />
                            </Button>
                        </Flex>
                    </div>
                </Popup>
            </div>
        </Portal>
    )
}
