import Icons from '@/components/Icons'
import Tag from '@/pages/SearchPage/Bloggers/SearchPanel/Chips/Tag'
import { shortStr } from '@/_helpers/_graphs/_tech'
import useOpenClose from '@/_helpers/useOpenClose'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { BasicFilter, BasicFiltersOptions } from '../BasicFilter'
import FilterBase from '../FilterBase'

import cn from './KeywordsFilter.module.scss'
import MainFilterBase from '../MainFilterBase/MainFilterBase'
import ConnectedInput from '@/pages/SearchPage/Bloggers/SearchPanel/SearchInput/ConnectedInput'
import WhereSelector from '@/pages/SearchPage/Bloggers/SearchPanel/SearchInput/WhereSelector'

type ValueType = string

type Config = {
    placeholder?: string
}

export class KeywordsFilter<ServerData> extends BasicFilter<ServerData, ValueType> {
    config: Config

    constructor({ placeholder, ...basic }: BasicFiltersOptions<ServerData, ValueType> & Config) {
        super(basic)
        this.config = {
            placeholder,
        }
    }

    getDefaultValue() {
        return ''
    }

    MainView = (props: { filterName: string }) => {
        const ref = useRef<HTMLDivElement>(null)

        return (
            <div>
                <MainFilterBase ref={ref} icon={this.options.icon} noArrow>
                    <ConnectedInput inFilter />
                    <WhereSelector inFilter filterRef={ref} />
                </MainFilterBase>
            </div>
        )
    }

    View = (props: { scrollToFilter?: Function }) => {
        // const { t } = useTranslation()

        const [, , , clickedVal, setClicked] = this.useFilterState()

        const [state, focus, blur] = useOpenClose()

        const ref = useRef<any>(null)

        if (clickedVal) {
            props.scrollToFilter && props.scrollToFilter(ref)
            focus()
            setClicked()
        }
        return (
            <FilterBase isActive={state} ref={ref}>
                <ConnectedInput inFilter focus={focus} blur={blur} />

                {/* <input
                    placeholder={this.config.placeholder && t(this.config.placeholder)}
                    onFocus={focus}
                    onBlur={blur}
                    className={cn['input--inFilter']}
                    value={value}
                    onChange={(e) => setValue(!e.target.value ? undefined : e.target.value)}
                /> */}
                <Icons className={cn['icon---gray-3']} name={'search'} />
            </FilterBase>
        )
    }

    Tags = () => {
        const [value, , clear, , setClicked] = this.useFilterState()

        const { t } = useTranslation()

        return <Tag label={`${t(this.options.title)}: ${shortStr(value)}`} onDelete={clear} onClick={setClicked} />
    }
}
