import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../StoreType'

const useToken = (): null | string => {
    const dispatch = useDispatch()
    const token = useSelector((store: RootStore) => store.authentication.user?.token || null)

    if (!token) {
        let user = JSON.parse(localStorage.getItem('user') || '{}')
        if (user.token) {
            dispatch({
                type: 'LOGIN_PAGE_LOGIN_SUCCESS',
                payload: { token: user.token },
            })
        }
        return user.token
    }

    return token
}

export default useToken
