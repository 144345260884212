import Icons from '@/components/Icons'
import Text from '@/components/Text'
import { Flex } from '@/_yc'
import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from './SelectorFilter.module.scss'

export interface ISelected {
    count: number
    onDelete: VoidFunction
    small?: boolean
}

const Selected: FC<ISelected> = (props) => {
    const { t } = useTranslation()

    return (
        <Flex align="center" className={cn.chip}>
            <Text fSize={12} color="white">
                {!props.small
                    ? t('filters.global.selected', {
                          count: props.count,
                      })
                    : props.count}
            </Text>
            <Icons onClick={props.onDelete} name="close--small" />
        </Flex>
    )
}

export default memo(Selected)
