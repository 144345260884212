import { useSpaceTranslation } from '@/_helpers'
import { Input } from '@/components/Input/Input'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cn from './AccountSettings.module.scss'

import Text from '@/components/Text'
import Icons from '@/components/Icons'
import Flex from '@/_yc/Flex'
import Button from '@/components/Button'
import { ZodIssue, z } from 'zod'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import { changePasswordReq, saveUserInfoReq } from '../Service/service'
import useToken from '@/_helpers/Hooks/useToken'

export const AccountSettings = () => {
    const t = useSpaceTranslation('settings.account')
    const [isChangeOpen, setIsChangeOpen] = useState(false)
    const token = useToken()

    const [isSuccess, setIsSuccess] = useState(false)
    const [isPasswordsSuccess, setIsPasswordsSuccess] = useState(false)

    const userInfoSchema = useMemo(
        () =>
            z.object({
                firstName: z.string().min(0).max(20),
                lastName: z.string().min(0).max(20),
                email: z.string().email('Invalid email address'),
                phone: z.string().min(0).max(20),
            }),
        []
    )

    const passwordSchema = useMemo(
        () =>
            z.object({
                oldPassword: z.string().min(8).max(20),
                newPassword: z.string().min(8).max(20),
                newPasswordRepeat: z.string().min(8).max(20),
            }),
        []
    )

    const profile = useSelector((state: RootStore) => state.authentication.user?.profile)

    const [userInfo, setUserInfo] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    })

    const [passwordInfo, setPasswordInfo] = useState({
        oldPassword: '',
        newPassword: '',
        newPasswordRepeat: '',
    })

    useEffect(() => {
        setUserInfo({
            firstName: profile?.first_name || '',
            lastName: profile?.last_name || '',
            email: profile?.email || '',
            phone: profile?.phone || '',
        })
    }, [profile])

    const [errors, setErrors] = useState<{ [key: string]: null | string }>({
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        passwords: null,
    })

    const isUserInfoValid = useCallback(() => {
        try {
            userInfoSchema.parse(userInfo)
            setErrors({
                firstName: null,
                lastName: null,
                email: null,
                phone: null,
                passwords: null,
            })
            return true
            // @ts-ignore
        } catch (e: any) {
            setErrors({
                firstName: e.issues.find((i: ZodIssue) => i.path.includes('firstName'))?.message,
                lastName: e.issues.find((i: ZodIssue) => i.path.includes('lastName'))?.message,
                email: e.issues.find((i: ZodIssue) => i.path.includes('email'))?.message,
                phone: e.issues.find((i: ZodIssue) => i.path.includes('phone'))?.message,
                passwords: null,
            })
            return false
        }
    }, [userInfo, userInfoSchema])

    const isPasswordInfoValid = useCallback(() => {
        if (passwordInfo.newPassword !== passwordInfo.newPasswordRepeat) {
            setErrors((errors) => ({ ...errors, passwords: 'Passwords do not match' }))
            return false
        }
        try {
            passwordSchema.parse(passwordInfo)
            setErrors({
                firstName: null,
                lastName: null,
                email: null,
                phone: null,
                passwords: null,
            })
            return true
            // @ts-ignore
        } catch (e: any) {
            setErrors({
                firstName: null,
                lastName: null,
                email: null,
                phone: null,
                passwords: e.issues.find((i: ZodIssue) => i.path.includes('newPassword'))?.message,
            })
        }
    }, [passwordInfo, passwordSchema])

    const handleSaveChanges = useCallback(() => {
        if (!token) return

        if (isUserInfoValid()) {
            saveUserInfoReq(token, userInfo).then((res) => {
                setIsSuccess(true)
            })
        }

        if (isPasswordInfoValid()) {
            changePasswordReq(token, passwordInfo).then((res) => {
                setIsSuccess(true)
                setIsPasswordsSuccess(true)
            })
        }
    }, [token, isUserInfoValid, userInfo, isPasswordInfoValid, passwordInfo])

    return (
        <div className={cn.accountSettings}>
            <Text fSize={18}>{t('user_data')}</Text>

            <div className={cn.nameBlock}>
                <Input
                    className={cn.input}
                    placeholder={t('first_name.placeholder')}
                    label={t('first_name.label')}
                    value={userInfo.firstName}
                    onChange={(e) => {
                        setIsSuccess(false)
                        setUserInfo({ ...userInfo, firstName: e.target.value })
                    }}
                />
                <Input
                    className={cn.input}
                    placeholder={t('last_name.placeholder')}
                    label={t('last_name.label')}
                    value={userInfo.lastName}
                    onChange={(e) => {
                        setIsSuccess(false)
                        setUserInfo({ ...userInfo, lastName: e.target.value })
                    }}
                />
            </div>

            <div>
                <Input
                    className={cn.input}
                    placeholder={t('email.placeholder')}
                    label={t('email.label')}
                    type="email"
                    value={userInfo.email}
                    onChange={(e) => {
                        setIsSuccess(false)
                        setUserInfo({ ...userInfo, email: e.target.value })
                    }}
                />
                {errors.email && (
                    <Text color="red-1" fSize={10} margin="2px 0 0 0">
                        {errors.email}
                    </Text>
                )}
            </div>
            <Input
                className={cn.input}
                placeholder={t('phone.placeholder')}
                label={t('phone.label')}
                type="tel"
                value={userInfo.phone}
                onChange={(e) => {
                    setIsSuccess(false)
                    setUserInfo({ ...userInfo, phone: e.target.value })
                }}
            />

            <Text fSize={18} margin="20px 0 0 0">
                {t('authorization')}
            </Text>

            <div className={cn.card}>
                <div className={cn.cardHeader}>
                    <Flex align="center" style={{ gap: 10 }}>
                        <Icons name="home" />
                        <Text color="violet-1">{t('standard')}</Text>
                    </Flex>

                    {!isChangeOpen && <Button onClick={() => setIsChangeOpen(true)}>{t('change_btn')}</Button>}
                    {isChangeOpen && (
                        <Text onClick={() => setIsChangeOpen(false)} color="violet-1" style={{ cursor: 'pointer' }}>
                            {t('cancel_btn')}
                        </Text>
                    )}
                </div>

                {isChangeOpen && (
                    <div className={cn.cardInputs}>
                        <Input
                            className={cn.input}
                            placeholder={t('old_password.placeholder')}
                            label={t('old_password.label')}
                            type="password"
                            value={passwordInfo.oldPassword}
                            onChange={(e) => {
                                setIsSuccess(false)
                                setPasswordInfo({ ...passwordInfo, oldPassword: e.target.value })
                            }}
                        />
                        <Input
                            className={cn.input}
                            placeholder={t('new_password.placeholder')}
                            label={t('new_password.label')}
                            type="password"
                            value={passwordInfo.newPassword}
                            onChange={(e) => {
                                setIsSuccess(false)
                                setPasswordInfo({ ...passwordInfo, newPassword: e.target.value })
                            }}
                        />
                        <Flex align="center" style={{ gap: 20 }}>
                            <Input
                                className={cn.input}
                                placeholder={t('confirm_password.placeholder')}
                                label={t('confirm_password.label')}
                                type="password"
                                value={passwordInfo.newPasswordRepeat}
                                onChange={(e) => {
                                    setIsSuccess(false)
                                    setPasswordInfo({ ...passwordInfo, newPasswordRepeat: e.target.value })
                                }}
                            />

                            {isPasswordsSuccess && (
                                <Flex margin="18px 0 0 0" align="flex-start">
                                    <Text color="gray-new-2" fSize={12}>
                                        {t('passwords_success')}
                                    </Text>
                                    <Icons name={'done'} className={cn.done} />
                                </Flex>
                            )}
                        </Flex>
                    </div>
                )}
            </div>

            <Button margin="30px 0 0 0" onClick={() => handleSaveChanges()} variant={isSuccess ? 'success' : 'outline'}>
                {isSuccess ? t('saved') : t('save_changes')}
            </Button>
        </div>
    )
}
