import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import * as Sentry from '@sentry/browser';
import { IntercomProvider } from "react-use-intercom";

import './Translator/'
import { store } from './_helpers';
import {App} from './App';
import addLogic from './AddProdLogic.js'
import { YMInitializer } from 'react-yandex-metrika';
import { init } from "@/_helpers/Ecommerce";
import { unregister } from './serviceWorker.js'

import './_yc/Fonts/loadFonts.js'

import './globals.module.scss'
import './index.css'
import IntercomChat from './components/Commercial/IntercomChat';

global.isProd = !!process.env.REACT_APP_SENTRY_DSN;
const layerName = "dataLayer"

global.isProd && init(layerName)

const yandex = {
    accounts: [55958284],
    options: { clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: false, ecommerce: layerName }
}

const intercomAppId = "l5sfrxai";

unregister();

render(
    <IntercomProvider autoBoot={global.isProd} appId={intercomAppId}>
        <Provider store={store}>
            <App />
            {
                global.isProd &&
                <YMInitializer {...yandex} />
            }
            {
                global.isProd &&
                <IntercomChat />
            }
        </Provider>
    </IntercomProvider>,
    document.getElementById('root')
);

if (global.isProd) {
    Sentry.init({dsn: process.env.REACT_APP_SENTRY_DSN});
    addLogic();
}
