import { Platform } from '@/pages/Reports/types'
import { MediaPlanOrder, MediaPlanOrderRaw } from './types'

export const preprocessMediaPlans = (data: MediaPlanOrderRaw[]): MediaPlanOrder[] => {
    return data.map((item) => ({
        ...item,
        data: JSON.parse(item.data),
        bloggers:
            item?.bloggers?.map((blogger) => ({
                ...blogger,
                bloggerType: blogger.bloggerType.toLowerCase() as Platform,
                data: JSON.parse(blogger.data),
            })) ?? [],
    }))
}
