import React, { FC } from 'react'
import { MetricItem } from './MetricItem/MetricItem'
import cn from './Metrics.module.scss'

export interface IMetrics {
    restOfData?: Array<{
        name: string
        value: number | string
    }>
    grid?: boolean
}

const Metrics: FC<IMetrics> = (props) => {
    if (props.grid) {
        return (
            <>
                <div className={cn.grid}>
                    {props.restOfData &&
                        props.restOfData.map((i, key) =>
                            i.value !== null ? (
                                <MetricItem small={true} key={key} name={i.name} value={i.value} />
                            ) : (
                                <></>
                            )
                        )}
                </div>
            </>
        )
    }
    return (
        <div className={cn.Metrics}>
            {props.restOfData &&
                props.restOfData.map((i, key) =>
                    i.value !== null ? <MetricItem key={key} name={i.name} value={i.value} /> : <></>
                )}
        </div>
    )
}

export default Metrics
