import React from 'react';

export default () => (
	<div style = {{ width: 13, height: 13, marginRight: 5, position: 'relative' }}>
		<svg style = {{position: 'absolute', left: 3.5, top: 5.5}} width="7" height="4" viewBox="0 0 7 4" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M3.54988 1.79688C3.4947 1.7649 3.4262 1.7649 3.37103 1.79689L1.44066 2.91583C1.13782 3.09137 0.747564 2.99231 0.569008 2.69458C0.390452 2.39684 0.491209 2.01317 0.794053 1.83762L2.72442 0.718681C3.17857 0.455427 3.74233 0.455426 4.19648 0.718681L6.12685 1.83762C6.42969 2.01317 6.53045 2.39684 6.35189 2.69458C6.17334 2.99231 5.78308 3.09137 5.48024 2.91583L3.54988 1.79688Z" fill="white"/>
		</svg>
		<svg style = {{position: 'absolute', left: 3.5, top: 8.6}} width="7" height="4" viewBox="0 0 7 4" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M3.37102 1.79418C3.42619 1.82616 3.49469 1.82616 3.54987 1.79418L5.48023 0.675238C5.78307 0.499693 6.17333 0.59875 6.35188 0.896488C6.53044 1.19423 6.42968 1.5779 6.12684 1.75344L4.19647 2.87238C3.74232 3.13564 3.17857 3.13564 2.72441 2.87238L0.794043 1.75344C0.491198 1.5779 0.390442 1.19423 0.568998 0.896488C0.747554 0.598751 1.13781 0.499694 1.44065 0.675239L3.37102 1.79418Z" fill="white"/>
		</svg>
		<svg style = {{position: 'absolute', left: 2, top: 0}} width="2" height="3" viewBox="0 0 2 3" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0.457293 2.66163H1.27431C1.5223 2.66163 1.72338 2.46425 1.72338 2.22044V0.441355C1.72338 0.197522 1.5223 0 1.27431 0H0.457293C0.209277 0 0.00823212 0.197522 0.00823212 0.441355V2.22044C0.00823212 2.46425 0.209277 2.66163 0.457293 2.66163Z" fill="white"/>
		</svg>
		<svg style = {{position: 'absolute', left: 9, top: 0}} width="2" height="3" viewBox="0 0 2 3" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0.457293 2.66163H1.27431C1.5223 2.66163 1.72338 2.46425 1.72338 2.22044V0.441355C1.72338 0.197522 1.5223 0 1.27431 0H0.457293C0.209277 0 0.00823212 0.197522 0.00823212 0.441355V2.22044C0.00823212 2.46425 0.209277 2.66163 0.457293 2.66163Z" fill="white"/>
		</svg>
		<svg style = {{position: 'absolute', left: 0, top: 1.5}} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M11.7789 0.44754V1.22117C11.7789 1.93195 11.1924 2.50473 10.4696 2.50473H9.64357C8.92066 2.50473 8.32638 1.93195 8.32638 1.22117V0.441895H4.60343V1.22117C4.60343 1.93195 4.00918 2.50473 3.28637 2.50473H2.46022C1.73738 2.50473 1.15091 1.93195 1.15091 1.22117V0.44754C0.519137 0.466262 0 0.980214 0 1.61193V10.8279C0 11.4715 0.530595 12 1.18523 12H11.7446C12.3982 12 12.9298 11.4704 12.9298 10.8279V1.61193C12.9298 0.980214 12.4107 0.466262 11.7789 0.44754ZM11.3953 10.254C11.3953 10.5321 11.1659 10.7578 10.8829 10.7578H2.02435C1.74134 10.7578 1.51196 10.5321 1.51196 10.254V4.42837C1.51196 4.15013 1.74131 3.92448 2.02435 3.92448H10.8829C11.1659 3.92448 11.3953 4.15013 11.3953 4.42837L11.3953 10.254Z" fill="white"/>
		</svg>
	</div>
)