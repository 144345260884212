import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next'

import { switch_actions } from '../Model/_actions.js'

import cn from './Blocks.module.scss'

let Title = ({ title, options, link, dispatch, state }) => {
	const { t } = useTranslation();
	return (
		<div className = {cn.title}>
			<div className = {cn.self}>{t("report.headers." + title)}</div>
			{options ? <div className = {cn.buttons}>
				{options.map((item, i) => (
					<div 
						key={i} 
						className={`${state.label[link]!==item ? cn["switch_btn--default"] : cn["switch_btn--active"]}`}
						onClick={e=>dispatch(switch_actions.switch(link, item))}
						style = {{
							borderRadius: !i ? "10px 0 0 10px" : i===options.length-1 ? "0 10px 10px 0" : undefined
						}}
					>
						{t("report.headers." + item)}
					</div>
				))}
			</div> : null}
		</div>
	)
}

Title = connect(
	({ BloggerReport: { switch_content } })=>({ state: switch_content })
)(Title)

export { Title }