import React from 'react';
import { useTranslation } from 'react-i18next'

import cn from './Failure.module.scss'

const Failure = props => {
	const { t } = useTranslation();
	return (
		<div className = {cn.root}>
			<h3>{t("promo.failure.title")}</h3>
			<p>{t("promo.failure.comment")}</p>
		</div>
	)
}

export { Failure as default }