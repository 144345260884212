import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import { Flex } from '@/_yc'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { track } from '@amplitude/analytics-browser'
import { getPlatform } from '@/_helpers/getPlatform'
import { LOGIN, SIGNUP } from '@/_helpers/amplitude/events'
import { GOOGLE_SIGNUP, GOOGLE_SIGNIN } from '@/_helpers/amplitude/values'
import GoogleIcon from './GoogleIcon.png'
import cn from './OAuthButton.module.scss'

const OAuthButton = (props) => {
    const { t } = useTranslation()
    const size = useWindowWidth('mobile')

    const onClick = () => {
        if (props.isSignUp) {
            track(SIGNUP, {
                kindOfSignUp: GOOGLE_SIGNUP,
                deviceOfSignUp: getPlatform(),
            })
        } else {
            track(LOGIN, {
                kindOfSignIn: GOOGLE_SIGNIN,
                deviceOfSignIp: getPlatform(),
            })
        }

        props.oauth()
    }
    return (
        <Flex
            style={{ ...props.style, margin: props.margin }}
            align="center"
            // content="center"
            className={`${size === 'mobile' ? cn['root--mobile'] : cn.root} ${props.className}`}
            onClick={onClick}
        >
            <img src={GoogleIcon} alt='google' style={size === 'mobile' ? { width: '28px', height: '28px' } : {}} />
            
            <Flex content={'center'} >
                {t(props.login ? 'login.Google_button_label' : 'signup.Google_button_label')}
            </Flex>
        </Flex>
    )
}

export default OAuthButton
