import React, { FC, CSSProperties } from 'react'

import { Flex } from '@/_yc/'
import Text from '@/components/Text'
import { getPostLink } from '@/pages/SearchPage/SearchAdapter/adsExplorer/instagram/utils/getPostLink'
import moment from 'moment'
import Icons from '@/components/Icons'
import { getNumberWithSpaces } from '@/_helpers/nums/numberWithSpaces'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import cn from './Post.module.scss'
import { getShortString } from '@/_helpers/strings/getShortString'
import Loader from '@/components/Loader'

export interface PostType {
    text: string
    date: string
    likes: number
    comments: number
    shortcode: string
    img: string
    hidden?: boolean
    className?: string
    style?: CSSProperties
}

export interface IPost extends PostType {
    t: (str: string, args?: { [key: string]: any }) => string
    width: string
}

const Post: FC<IPost> = (props) => {
    const openPost = () => {
        window.open(getPostLink(props.shortcode))
    }

    const size = useWindowWidth('mobile') !== 'mobile' ? '340px' : '240px'
    return (
        <Flex column className={props.hidden ? cn.demo : ''}>
            <div onClick={openPost} style={{ cursor: 'pointer' }}>
                <blockquote
                    className="instagram-media"
                    style={{
                        background: 'white',
                        // width: 'calc(100% - 2px)',
                        borderRadius: '4px',
                        border: 'none',
                        boxShadow: 'none',
                        display: 'block',
                        margin: '0px 0px 12px',
                        height: '400px',
                        overflowY: 'scroll',
                    }}
                    data-instgrm-captioned
                    data-instgrm-permalink={`https://www.instagram.com/p/${props.shortcode}/?utm_source=ig_embed&amp;utm_campaign=loading`}
                    data-instgrm-version="14"
                >
                    <Flex content="center" align="center" style={{ width: '100%', height: '100%' }}>
                        <Loader />
                    </Flex>
                </blockquote>
            </div>
            <Text margin={'3px 0 0 0'} color={'gray-3'} fSize={12}>
                {moment(props.date).format('DD.MM.YYYY')}
            </Text>
            <Text color={'gray-1'} fSize={12} margin={'12px 0 16px 0'} style={{ width: size }}>
                {props.text && getShortString(props.text, 110)}
            </Text>

            <Flex content={'space-between'} style={{ width: size }}>
                <Flex content={'flex-start'} align={'center'}>
                    <Icons name={'likes'} />
                    <Text margin={'0 0 0 10px'}>{props.likes && getNumberWithSpaces(props.likes)}</Text>
                </Flex>

                <Flex content={'flex-start'} align={'center'}>
                    <Icons name={'comments'} />
                    <Text margin={'0 0 0 10px'}>{props.comments && getNumberWithSpaces(props.comments)}</Text>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Post
