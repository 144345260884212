export interface Data {
    name: string
    type: 'Draft' | 'Yoloco' | 'Beeline' | 'User'
    date: string
    status: string
}

export const headers = [
    'target_audience.name',
    'target_audience.targeting_type',
    'target_audience.creation_date',
    'target_audience.status',
    '',
]
