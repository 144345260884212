import React, { FC } from 'react'
import Text from '@/components/Text'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { SetDefault } from '@/components/MassAddToList/_model/actions'
import cn from './Tab.module.scss'
import { useSearch } from '@/pages/SearchPage/SearchAdapter'
import { SEARCH } from '@/routs'
import Icons, { TUiIconName } from '@/components/Icons'
import { Flex } from '@/_yc'

interface TabProps {
    label: string
    active: boolean
    index: number
    id: string
}

export const Tab: FC<TabProps> = ({ label, id, active, index }) => {
    const dispatch = useDispatch()
    const style = {
        height: active ? '52px' : '48px',
        backgroundColor: active ? 'white' : '',
        borderRadius: active ? '10px 10px 0 0' : index === 0 ? '10px 0 0 0' : '0 10px 0 0',
    }

    const { restart, clearSearch, clearFilters } = useSearch()

    const onLinkClick = () => {
        dispatch(SetDefault())
        restart()
        clearSearch()
        clearFilters()
    }

    const iconName = `ie-${label.toLowerCase()}${active ? '' : '--disabled'}` as TUiIconName

    return (
        <Link to={`${SEARCH}/${id}`} onClick={onLinkClick}>
            <div className={cn.tab} style={style}>
                {active && index !== 0 && (
                    <div className={cn.leftCurve}>
                        <div className={cn.leftCurveInner} />
                    </div>
                )}
                <Flex align="center" style={{ height: 18, marginTop: active ? 19 : 15, marginBottom: 15 }}>
                    {/* {label === 'Twitch' && active && (
                        <Text className={cn.beta} color="gray-3" fSize={12}>
                            *Beta
                        </Text>
                    )} */}
                    <Icons name={iconName} margin="0 10px 0 0" />
                    <Text semibold color={active ? 'gray-1' : 'gray-new-2'}>
                        {label}
                    </Text>
                </Flex>
                {active && (
                    <div className={cn.rightCurve}>
                        <div className={cn.rightCurveInner} />
                    </div>
                )}
            </div>
        </Link>
    )
}
