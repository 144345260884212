import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootStore } from '@/_helpers/StoreType'
import Flex from '@/_yc/Flex'
import Avatar from '../Avatar/Avatar'
import MainInfo from '../MainInfo/MainInfo'
import Metrics from '../Metrics/Metrics'
import { setInfo } from '@/Layouts/ReportLayout/Model/actions'
import { useSpaceTranslation } from '@/_helpers'
import { IVideoQuery } from '../../Model/types'
import GeoInfo from './Geo/GeoInfo'
import cn from './ReportHeader.module.scss'
import Email from './Email/Email'
import { Platform } from '@/pages/Reports/types'

export interface IHeaderReport {
    platform: Platform
}

const ReportHeader: FC<IHeaderReport> = ({ platform }) => {
    const dispatch = useDispatch()
    const t = useSpaceTranslation('channel_report')

    const metricData = useSelector((store: RootStore) => store.report.info)

    const lastVideo = useSelector((store: RootStore) => {
        const videosData = store.report.rawVideosData.getVideos?.data
        const videos = videosData?.youtube_channel[0].recent_videos

        if (videos?.length === 0) {
            return
        }

        const lastVideo = videos ? videos[0] : undefined
        return lastVideo
    })

    const getDateDifference = (video: IVideoQuery) => {
        const currentDate = new Date()
        const published_at = new Date(video.published_at)

        return (currentDate.getTime() - published_at.getTime()) / (1000 * 3600 * 24)
    }

    const newMetrics = useMemo(() => {
        return JSON.parse(JSON.stringify(metricData))
    }, [metricData])

    if (metricData.metrics.length === 4 && lastVideo) {
        newMetrics.metrics.push({
            value: Math.floor(getDateDifference(lastVideo)),
            name: t('metrics.last_video'),
        })

        dispatch(setInfo(newMetrics))
    }

    return (
        <>
            <Flex content="flex-start">
                <GeoInfo country={metricData.country} />
                <Email email={metricData.email} />
            </Flex>

            <div className={cn.CoverBlock}>
                <Flex content="flex-start">
                    <Avatar type={platform} url={metricData.avatar} />

                    <MainInfo
                        type={platform}
                        data={{
                            name: metricData.title,
                            verified: true,
                            link: `https://www.youtube.com/channel/${metricData.id}`,
                            topics: metricData.topics,
                        }}
                    />
                </Flex>

                <Metrics restOfData={metricData.metrics} />
            </div>
        </>
    )
}

export default ReportHeader
