import React from 'react';

import Lamp from './Lamp.js'
import Followers from './Folowers.js'
import Er from './Er.js'
import Locked from './Locked.js'
import Age from './Age.js'
import Gender from './Gender.js'
import Location from './Location.js'
import Username from './Username.js'
import Bio from './Bio.js'
import Last from './Last.js'
import Close from './Close.js'
import Question from './Question.js'
import Search from './Search.js'
import ContextMenu from './ContextMenu.js'
import Check from './Check.js'
import Loading from './Loading.js'

export default ({ name, ...props }) => {
	switch (name) {
		case "lamp": return <Lamp />
		case "followers": return <Followers />
		case "er": return <Er />
		case "age": return <Age />
		case "gender": return <Gender />
		case "loc": return <Location />
		case "username": return <Username />
		case "bio": return <Bio />
		case "last": return <Last />
		case "close": return <Close {...props}/>
		case "quest": return <Question {...props}/>
		case "search": return <Search {...props}/>
		case "context": return <ContextMenu {...props}/>
		case "check": return <Check {...props}/>
		case "loading": return <Loading {...props}/>
		case "locked": return <Locked {...props}/>
		default: 
			console.error(`Unknown icon name "${name}" in default context`)
			return null
	}
}
