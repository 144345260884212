import React from 'react'

import Text from '@/components/Text'
import cn from './TemplateList.module.scss'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
// import { getShortString } from '@/_helpers/strings/getShortString'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentTemplate } from '../../Model/actions'
import { RootStore } from '@/_helpers/StoreType'

import emptyIcon from './EmptyListIcon.svg'

export const TemplateList = () => {
    const t = useSpaceTranslation('outreach.templates.list')
    const dispatch = useDispatch()

    const templates = useSelector((state: RootStore) => state.Outreach.templates)
    const curTemplate = useSelector((store: RootStore) => store.Outreach.currentTemplate)

    const onClick = (id: number) => {
        dispatch(setCurrentTemplate(id))
    }

    return (
        <div className={cn.templateList}>
            <div className={cn.header}>
                <Text fSize={16} semibold>
                    {t('title')}
                </Text>

                <hr className={cn.divider}></hr>

                {templates.length === 0 && (
                    <div className={cn.noTemplates}>
                        <img src={emptyIcon} alt="empty templates" />
                        <Text margin="24px 0 50px 0" fSize={12} color="gray-new-2">
                            {t('empty')}
                        </Text>
                    </div>
                )}

                {templates.length !== 0 && (
                    <div className={cn.list}>
                        {templates.map((template) => (
                            <div
                                className={`${cn.listItem} ${curTemplate === template.id ? cn.selected : ''}`}
                                onClick={() => onClick(template.id)}
                                key={template.id}
                            >
                                <div className={cn.itemHeader}>
                                    <Text component="span" fSize={12} color="gray-2" semibold>
                                        {template.name}
                                    </Text>

                                    {/* <div className={cn.itemDateTime}>
                                    <Text component="span" fSize={10} color="gray-3">
                                        {template.date}
                                    </Text>
                                    <Text component="span" fSize={10} color="gray-3" margin="0 0 0 4px">
                                        {template.time}
                                    </Text>
                                </div> */}
                                </div>

                                <Text
                                    dangerouslySetInnerHTML={{ __html: template.message }}
                                    fSize={10}
                                    style={{
                                        overflow: 'hidden',
                                        height: '25px',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        display: 'flex',
                                        lineHeight: '14px',
                                    }}
                                    color="gray-2"
                                ></Text>
                                <Text fSize={10} style={{ lineHeight: '14px' }}>
                                    ...
                                </Text>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}
