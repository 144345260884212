import { Packages, CouponResponse } from './types'

import Service from '@/_helpers/Service'

const GetDictPackages = new Service<Packages>('/api/dicts/packages/')

export const getPackages = (token: string, accept_lang: string, coupon?: string) =>
    GetDictPackages.call(
        {
            headers: {
                'accept-language': accept_lang,
            },
            params: { coupon },
        },
        token
    )

const promoCheckService = new Service<CouponResponse>('/promo/check-coupon/')

export const promoCheck = (token: string, coupon: string) =>
    promoCheckService.call(
        {
            params: {
                coupon,
            },
        },
        token
    )

const paymentService = new Service<{ redirect_url: string; order_id: string }>({
    method: 'POST',
    url: '/core/payments/',
})

const capturePaymentService = new Service<{ redirect_url: string; orderId: string }>({
    method: 'POST',
    url: '/core/payments/capture/',
})

export const capturePayment = (token: string, orderId: string) =>
    capturePaymentService.call(
        {
            data: {
                id: orderId,
            },
        },
        token
    )

export const payment = (token: string, tariffID: number, recurring_payment_enabled: boolean, coupon?: string) =>
    paymentService.call(
        {
            data: {
                tariff: tariffID,
                coupon,
                save_payment_method: true,
                recurring_payment_enabled,
            },
        },
        token
    )

const requestPlanServ = new Service<{ redirect_url: string }>({
    method: 'POST',
    url: 'api/requests/new/',
})

export const requestPlan = (token: string, tariffID: number, user: any) =>
    requestPlanServ.call(
        {
            data: {
                tariff: tariffID,
                ...user,
                demo_type: 'platform',
            },
        },
        token
    )
