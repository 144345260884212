import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Scrollbars from 'react-custom-scrollbars'
import { Flex } from '@/_yc'
import { BasicFilter, BasicFiltersOptions } from '../BasicFilter'

import cn from '../SelectorFilter/SelectorFilter.module.scss'
import useOpenClose from '@/_helpers/useOpenClose'
import Popup from '@/_yc/Popup'
import Text from '@/components/Text'
import Item from '../SelectorFilter/Item'
import useToken from '@/_helpers/Hooks/useToken'
import FilterBase from '../FilterBase'
import Percentage from '../SelectorFilter/Percentage'
import Tag from '@/pages/SearchPage/Bloggers/SearchPanel/Chips/Tag'
import SelectedTag from '../SelectorFilter/SelectedTag'
import Checkbox from '@/_yc/Checkbox'
import { trackFilterChange } from '@/_helpers/amplitude/track'
import { CustomSlider } from '../Slider/CustomSlider'
import Icons from '@/components/Icons'

const style = { zIndex: 5, marginTop: 7 }
const dn = { display: 'none' }

const hideTrack = (props: { [k: string]: any }) => <div {...props} className="track-horizontal" style={dn} />
const hideThumb = (props: { [k: string]: any }) => <div {...props} className="thumb-horizontal" style={dn} />

export type ISelectorFilter<ValueType, HasPercentage extends boolean> = {
    isMulti?: boolean
    autocomplete?: boolean
    options:
        | Array<{
              label: string
              value: ValueType
              translate?: boolean
          }>
        | ((
              token: string,
              locale: string
          ) => Promise<
              Array<{
                  label: string
                  value: ValueType
              }>
          >)
    hasPercentage?: HasPercentage
}

type SelectorState<ValueType, HasPercentage extends boolean = false> = {
    value: ValueType[]
    timeFrame: number | null
    notCheck: boolean
    percentage: HasPercentage extends true ? number : null
}

export class SelectorWithOption<ServerData, ValueType, HasPercentage extends boolean = false> extends BasicFilter<
    ServerData,
    SelectorState<ValueType, HasPercentage>
> {
    selector: ISelectorFilter<ValueType, HasPercentage>

    private labelMap = new Map<
        ValueType,
        {
            label: string
            translate?: boolean
        }
    >()
    private dateLabelMap = new Map<
        number,
        {
            label: string
            translate?: boolean
        }
    >()
    constructor({
        isMulti,
        options,
        hasPercentage,
        ...basic
    }: ISelectorFilter<ValueType, HasPercentage> &
        BasicFiltersOptions<ServerData, SelectorState<ValueType, HasPercentage>>) {
        super(basic)
        this.selector = {
            isMulti,
            options,
            hasPercentage,
        }

        if (!(options instanceof Function)) {
            for (const option of options) {
                this.labelMap.set(option.value, {
                    label: option.label,
                    translate: option.translate,
                })
            }
        }
    }

    getDefaultValue() {
        return {
            value: [],
            timeFrame: null,
            notCheck: false,
            percentage: this.selector.hasPercentage ? 3 : (null as any),
        }
    }

    MainView = (props: { filterName: string }) => {
        return <div>{props.filterName}</div>
    }

    View = (props: { disabled: boolean; filterName: string; autocomplete?: boolean; scrollToFilter?: Function }) => {
        const { i18n } = useTranslation()
        const { t } = useTranslation()
        const ref = useRef<any>(null)
        const token = useToken()

        const [value, setValue, clear, clickedVal, setClicked] = this.useFilterState()
        const [state, open, close, toggle] = useOpenClose()
        const [searchValue, setSearchValue] = useState('')
        const locked = this.useIsLocked()

        const [timeFrame, setTimeFrame] = useState<number>(180)

        const [options, setOptions] = useState(() => {
            if (this.selector.options instanceof Function) {
                return []
            }
            return this.selector.options
        })

        const width = useMemo(() => {
            if (!ref.current || !state) return
            return {
                width: window.getComputedStyle(ref.current).width,
            }
        }, [state])

        const change = useCallback(
            (value: ValueType) => {
                setSearchValue('')
                trackFilterChange({ t, title: this.options.title, value, platform: this.options.platform })

                if (!this.selector.isMulti) {
                    close()
                    setValue((prev) => {
                        if (prev.value[0] === value) return undefined
                        return {
                            ...prev,
                            value: [value],
                        }
                    })
                    return
                }

                setValue((prev) => {
                    const next = prev.value.includes(value)
                        ? prev.value.filter((item) => item !== value)
                        : prev.value.concat(value)

                    return {
                        ...prev,
                        value: next,
                    }
                })
            },
            [setValue, close, t]
        )

        const changeTimeFrame = useCallback((event: ChangeEvent<{}>, val: number | number[]) => {
            setTimeFrame(val as number)
        }, [])

        const onTimeFrameCommit = useCallback(
            (event: ChangeEvent<{}>, timeFrame: number | number[]) => {
                console.log(timeFrame)
                if (Array.isArray(timeFrame)) {
                    return
                }
                setSearchValue('')
                setValue((prev) => {
                    return {
                        ...prev,
                        timeFrame: timeFrame,
                    }
                })
            },
            [setValue]
        )
        const changeNotCheck = useCallback(() => {
            if (locked) return
            setSearchValue('')
            setValue((prev) => {
                return {
                    ...prev,
                    notCheck: !prev.notCheck,
                    timeFrame: !prev.notCheck ? null : prev.timeFrame,
                }
            })
        }, [setValue, locked])

        if (clickedVal) {
            props.scrollToFilter && props.scrollToFilter(ref)
            open()
            setClicked()
        }

        const setPercentage = useCallback(
            (value: number) => {
                setValue((prev) => ({
                    ...prev,
                    percentage: prev.percentage ? value : (prev.percentage as any),
                }))
            },
            [setValue]
        )

        useEffect(() => {
            if (!token || !(this.selector.options instanceof Function)) return
            this.selector.options(token, i18n.language).then((ops) => {
                for (const option of ops) {
                    this.labelMap.set(option.value, {
                        label: option.label,
                        translate: false,
                    })
                }
                setOptions(ops)
            })
        }, [token, i18n.language])
        return (
            <>
                <Flex>
                    <FilterBase onClick={toggle} ref={ref} isActive={state} disabled={locked}>
                        {!props.autocomplete ? (
                            <>
                                {value.value.length > 1 ? (
                                    <SelectedTag onDelete={clear} count={value.value.length} />
                                ) : (
                                    <Text color={value.value[0] ? undefined : 'gray-4'}>
                                        {this.labelMap.has(value.value[0])
                                            ? this.labelMap.get(value.value[0])?.translate
                                                ? t(this.labelMap.get(value.value[0])?.label || '')
                                                : this.labelMap.get(value.value[0])?.label
                                            : t('filters.global.any')}
                                    </Text>
                                )}
                            </>
                        ) : state ? (
                            <>
                                {value.value.length > 1 && (
                                    <SelectedTag small={true} onDelete={clear} count={value.value.length} />
                                )}
                                <input
                                    className={cn.input}
                                    type="text"
                                    value={searchValue}
                                    onChange={(val) => setSearchValue(val.target.value)}
                                    autoFocus
                                    placeholder={state ? '' : t('filters.global.any')}
                                />
                            </>
                        ) : value.value.length > 1 ? (
                            <SelectedTag onDelete={clear} count={value.value.length} />
                        ) : (
                            <Text color={value.value[0] ? undefined : 'gray-4'}>
                                {this.labelMap.has(value.value[0])
                                    ? this.labelMap.get(value.value[0])?.translate
                                        ? t(this.labelMap.get(value.value[0])?.label || '')
                                        : this.labelMap.get(value.value[0])?.label
                                    : t('filters.global.any')}
                            </Text>
                        )}

                        <Flex align="center">
                            <div className={cn.label}>
                                <Text color="gray-3" fSize={14}>
                                    {timeFrame}
                                </Text>
                            </div>

                            <Icons name="arrow-down" className={cn[state ? 'icon--opened' : 'icon--closed']} />
                        </Flex>
                    </FilterBase>
                    {value.percentage && (
                        <Percentage
                            disabled={!value.value.length}
                            margin="0 0 0 16px"
                            value={value.percentage as any}
                            onChange={setPercentage}
                        />
                    )}
                </Flex>
                <Popup style={style} anchor={ref.current} isOpen={state} onClose={toggle}>
                    <div>
                        <div style={width} className={cn.popper}>
                            <Scrollbars
                                renderTrackHorizontal={hideTrack}
                                renderThumbHorizontal={hideThumb}
                                autoHeight
                                autoHeightMax={220}
                            >
                                {options.map((item, i) => (
                                    <>
                                        {item.label.toLowerCase().includes(searchValue.toLowerCase()) && (
                                            <Item
                                                key={i}
                                                {...item}
                                                isActive={value.value.includes(item.value)}
                                                onClick={change}
                                            />
                                        )}
                                    </>
                                ))}
                            </Scrollbars>
                        </div>
                        <div
                            style={{ ...width, marginTop: '6px' }}
                            className={`${cn.popper} ${value.value ? '' : cn['popper--disabled']} ${
                                cn['popper--slider']
                            }`}
                        >
                            <Text fSize={12} color="gray-new-2" margin="0 0 10px 0">
                                Period (days)
                            </Text>
                            <CustomSlider
                                value={timeFrame}
                                onChange={changeTimeFrame}
                                onChangeCommitted={onTimeFrameCommit}
                                getAriaLabel={() => ''}
                                valueLabelDisplay="on"
                                disabled={!value.value}
                                min={0}
                                step={1}
                                max={365}
                            />
                            <Flex onClick={changeNotCheck} align="center" margin="5px 0 0 0">
                                <Checkbox
                                    id="checkID"
                                    value={value.notCheck}
                                    size="small"
                                    margin={'0 6px 0 0'}
                                    style={{
                                        pointerEvents: value.notCheck || locked ? 'none' : 'auto',
                                        backgroundColor: !value.notCheck && !locked ? 'white' : '#e0e0e0',
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: '#CCCCCC',
                                    }}
                                />
                                <label htmlFor="checkID">
                                    <span className={cn['checkbox--title']}>{t(`filters.brand.checkBox`)}</span>
                                </label>
                            </Flex>
                        </div>
                    </div>
                </Popup>
            </>
        )
    }
    Tags = () => {
        const [value, setValue, , , setClicked] = this.useFilterState()

        const { t } = useTranslation()

        const del = useCallback(
            (id: ValueType | null) => {
                setValue((prev) => {
                    const res = prev.value.filter((item) => item !== id)
                    if (!res.length) return
                    return {
                        ...prev,
                        value: res,
                    }
                })
            },
            [setValue]
        )
        const getTags = useCallback(() => {
            let tag: { item: ValueType; label: string }[] = []
            value.value.forEach((item) => {
                const tempTime = value.timeFrame
                    ? `${t('filters.brand.for')} ${t(this.dateLabelMap.get(value.timeFrame)?.label || '')}`
                    : ''
                const tempLabel = `${t(this.options.title)}: ${t(
                    this.labelMap.get(item)?.label || 'filters.brand.any'
                )} ${tempTime}`
                tag.push({ item: item, label: tempLabel })
            })
            return tag
        }, [t, value])

        return (
            <>
                {getTags().map((item) => (
                    <Tag
                        key={item.item + ''}
                        label={item.label}
                        value={item.item}
                        onDelete={del}
                        onClick={setClicked}
                    />
                ))}
            </>
        )
    }
}
