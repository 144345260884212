import React, { memo } from 'react';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux'

import { APIService } from '../../../_helpers/APIService.js'
import withOAuth from './withOAuth.js'
import BT from './OAuthButton.js'
import notif from '../../Notifier/model/actions.js'
import CommonError from '../../Commercial/Notifaications/CommonError.js'

const serv = new APIService({
	methods: {
		oauth: () => ({
			url: "/core/social-accounts/login/google-oauth2/"
			// withCredentials: true
		})
	}
})

const Api = {
	OAuthService: serv.oauth
}

const mapDispatch = dispatch => ({
	error: () => {
		dispatch(notif.error(<CommonError />, 3000))
	}
})

export default compose(
	connect(null, mapDispatch),
	withProps(Api),
	withOAuth(),
	memo
)(BT)