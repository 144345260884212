import Icons from '@/components/Icons'
import React from 'react'
import { RangeFilter, RangeValueType } from '../../../../filters/RangeFilter'
import { getRange } from '../../../youtube/utils/filters/getRange'

const getGqlFilter = (range: RangeValueType) => {
    if (!range.from && !range.to) return ''
    return `median_reactions_m: ${getRange(range.from, range.to)}`
}

export const getAvgReactFilter = () => {
    return new RangeFilter({
        platform: 'infl_tg',
        title: 'filters.avg_views.label',
        helper: 'filters.avg_views.text',
        icon: <Icons name="filter--interests" />,
        space: 1,
        beforeSend: (data) => {
            return {
                name: 'metrics.views',
                type: 'range',
                value: data,
                gqlFilter: getGqlFilter(data),
            }
        },
    })
}
