import service from './service.js'
import { COMERCIAL_SUCCESS, COMERCIAL_FAILURE, COMERCIAL_PROMO_CHECK } from './constants.js'

export default {
	loadInfo: () => (dispatch) => {
		service.loadInfo()
			.then(resp => dispatch({ type: COMERCIAL_SUCCESS, payload: resp[0] }))
			.catch(err => dispatch({ type: COMERCIAL_FAILURE }))
	},
	checkPromo: (promocode) => ({ type: COMERCIAL_PROMO_CHECK, promocode })
}