import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.99998 18.3333C9.0795 18.3333 8.33331 17.5871 8.33331 16.6667H11.6666C11.6666 17.5871 10.9205 18.3333 9.99998 18.3333ZM16.6666 15.8333H3.33331V14.1667L4.99998 13.3333V8.75001C4.99998 5.86501 6.18415 3.99417 8.33331 3.48334V1.66667H11.6666V3.48334C13.8158 3.99334 15 5.86334 15 8.75001V13.3333L16.6666 14.1667V15.8333ZM9.99998 4.79167C8.98304 4.72601 8.0023 5.18107 7.39581 6.00001C6.8771 6.82047 6.62259 7.78032 6.66665 8.75001V14.1667H13.3333V8.75001C13.3773 7.78033 13.1228 6.82049 12.6041 6.00001C11.9977 5.18107 11.0169 4.72601 9.99998 4.79167Z"
            fill="#333333"
        />
    </svg>
)
