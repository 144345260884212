import React, { ComponentType, FC, memo, SVGAttributes, ReactNode } from 'react'
import Text from '@/components/Text'
import { Flex } from '@/_yc'
import cn from './Info.module.scss'

export interface IItem {
    icon: ComponentType<SVGAttributes<SVGElement>>
    title: string | number
    description: string
    children?: ReactNode
}

const Item: FC<IItem> = ({ icon: Icon, title, children, description }) => {
    return (
        <Flex className={cn.info__item}>
            <Icon className={cn.icon} />
            <div>
                <Flex align="center">
                    <Text component="h5" className={cn.title} semibold fSize={24}>
                        {title}
                    </Text>
                    {children}
                </Flex>
                <Text className={cn.description} color="gray-4" fSize={12}>
                    {description}
                </Text>
            </div>
        </Flex>
    )
}

export default memo(Item)
