import React, { FC, useCallback, useState, useEffect } from 'react'

import { FormProvider, useController, ErrorConsumer } from '@schematic-forms/react'
import Selector from '@/pages/SearchPage/SearchAdapter/filters/Selector'
import { IBrandDetails } from '@/Services/User'
import FormInput from '@/components/Share/Form/FormInput'
import { useSpaceTranslation } from '@/_helpers'
import { Str } from '@schematic-forms/core'
import Button from '@/_yc/Button'
import Flex from '@/_yc/Flex'

import cn from '../DetailedInfo.module.scss'

type fieldsType = 'companyName' | 'employeesCount' | 'role' | 'companySite'
export type userRolesType = { name: string }[]
export type userRolesValueType = { label: string; value: number }[]
interface IBrandInfo {
    onSubmit: (data: IBrandDetails) => Promise<void>
    stateCounter: number
    setStateCounter: Function
    roleOptions: userRolesValueType
}

const employeesOptions = [
    { label: '1-5', value: 1 },
    { label: '5-10', value: 2 },
    { label: '10-25', value: 3 },
    { label: '25-50', value: 4 },
    { label: '50-100', value: 5 },
    { label: '100-250', value: 6 },
    { label: '250+', value: 7 },
]

const Brand: FC<IBrandInfo> = ({ onSubmit, stateCounter, setStateCounter, roleOptions }) => {
    const t = useSpaceTranslation('detailed_info.brand_info')
    const [employeesVal, setEmployeesVal] = useState(0)
    const [roleVal, setRoleVal] = useState(0)

    const [buttonState, setButtonState] = useState<'next' | 'sub'>('next')

    const { controller, submit } = useController({
        fields: {
            companyName: Str(true),
            companySite: Str(true),
            employeesCount: Str(false),
            role: Str(false),
        },
        submit: async (data, submit: IBrandInfo['onSubmit'], employeesVal: number, roleVal: number) => {
            console.log('HERE', data)
            return await submit({
                company_name: data.companyName,
                company_site: data.companySite,
                role: roleOptions.find((e) => e.value === roleVal)?.label,
                employees_count: employeesOptions.find((e) => e.value === employeesVal)?.label,
            })
        },
    })

    const sub = useCallback(() => {
        submit(onSubmit, employeesVal, roleVal)
    }, [onSubmit, submit, employeesVal, roleVal])

    const fields: Array<fieldsType> = ['companyName', 'employeesCount', 'role', 'companySite']
    const handleNext = () => {
        if (stateCounter === 1) {
            if (employeesVal !== 0) {
                setStateCounter(stateCounter + 1)
            }
        } else if (stateCounter === 2) {
            if (roleVal !== 0) {
                setStateCounter(stateCounter + 1)
            }
        } else if (
            controller.getValues()[fields[stateCounter]] &&
            controller.getValues()[fields[stateCounter]] !== ''
        ) {
            setStateCounter(stateCounter + 1)
        }
    }
    const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            if (controller.getValues()[fields[stateCounter]] && controller.getValues()[fields[stateCounter]] !== '') {
                if (controller.errors.size() === 0) {
                    setStateCounter(stateCounter + 1)
                }
            }
        }
    }

    useEffect(() => {
        if (stateCounter === 3) {
            setButtonState('sub')
        }
        console.log(stateCounter)
    }, [stateCounter])
    return (
        <Flex column>
            <FormProvider controller={controller}>
                {/* company Name */}
                {stateCounter >= 0 && (
                    <div>
                        <p className={cn.label}>{t('fields.company_name.label')}</p>
                        <div onKeyDown={handleEnter}>
                            <FormInput
                                name="companyName"
                                // title={t('fields.company_name.label')}
                                placeholder={t('fields.company_name.placeholder')}
                                className={cn['input-box']}
                            />
                        </div>
                    </div>
                )}

                {/* size selector */}
                {stateCounter >= 1 && (
                    <div style={{ position: 'relative' }}>
                        <p className={cn.label}>{t('fields.employees_count.label')}</p>
                        <Selector
                            options={employeesOptions}
                            display={employeesOptions.find((e) => e.value === employeesVal)?.label}
                            open={false}
                            isActive={(value) => value === employeesVal}
                            onChange={(value) => {
                                setEmployeesVal(value)
                                if (stateCounter === 1) setStateCounter(stateCounter + 1)
                            }}
                            className={cn[`input-box${employeesVal !== 0 ? '--active' : ''}`]}
                            placeholder={t('fields.employees_count.placeholder')}
                            popUpStyle={{ width: '100%', marginTop: '10px' }}
                            disablePortal
                        />
                    </div>
                )}

                {/* role selector*/}
                {stateCounter >= 2 && (
                    <div style={{ position: 'relative' }}>
                        <p className={cn.label}>{t('fields.role.label')}</p>
                        <Selector
                            options={roleOptions}
                            display={roleOptions.find((e) => e.value === roleVal)?.label}
                            open={false}
                            isActive={(value) => value === roleVal}
                            onChange={(value) => {
                                setRoleVal(value)
                                if (stateCounter === 2) setStateCounter(stateCounter + 1)
                            }}
                            className={cn[`input-box${roleVal !== 0 ? '--active' : ''}`]}
                            placeholder={t('fields.role.placeholder')}
                            popUpStyle={{ width: '100%', marginTop: '10px' }}
                            disablePortal
                        />
                    </div>
                )}

                {/* website */}
                {stateCounter >= 3 && (
                    <div onKeyDown={handleEnter}>
                        <p className={cn.label}>{t('fields.company_site.label')}</p>
                        <FormInput
                            name="companySite"
                            placeholder={t('fields.company_site.placeholder')}
                            className={cn['input-box']}
                        />
                    </div>
                )}

                <ErrorConsumer>
                    {({ hasError }) => (
                        <Button
                            className={cn.button}
                            onClick={buttonState === 'next' ? handleNext : sub}
                            disabled={hasError}
                            width={'100%'}
                            color={'blue'}
                        >
                            {buttonState === 'next' ? t('submit_button') : t('get_started')}
                        </Button>
                    )}
                </ErrorConsumer>
            </FormProvider>
        </Flex>
    )
}

export default Brand
