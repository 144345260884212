export const selectLocker = feature => state => {
	if (
		state.authentication.infoLoaded && 
		!state.authentication.error &&
		state.authentication.user.profile.features?.indexOf(feature) !== -1
	) {
		return false
	}else{
		return true
	}
}