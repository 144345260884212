import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.8334 16.6667H14.1667V14.1667H11.6667V12.5H14.1667V10H15.8334V12.5H18.3334V14.1667H15.8334V16.6667ZM10 14.1667H1.66669V12.5H10V14.1667ZM12.5 10.8333H1.66669V9.16668H12.5V10.8333ZM12.5 7.50001H1.66669V5.83334H12.5V7.50001Z"
            fill="#333333"
        />
    </svg>
)
