import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'

import MoreModal from '@/components/MoreModal'
import ListOfAccounts from '../ResuableWidgets/AccountCards'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import moment from 'moment'
import { getVideoLink } from '../Videos/utils'
import cn from './BrandMentions.module.scss'

const BrandMentions = () => {
    const brandMentions = useSelector((store: RootStore) => {
        return store.report.rawChannelData.getChannel?.data.youtube_channel[0].brand_mentions
    })
    const t = useSpaceTranslation('report_layout.brand_mentions')
    const toDate = (dateString: string) => {
        return moment(dateString).format('DD.MM.YYYY')
    }
    const preprocessedData = useMemo(() => {
        let tempAr = []
        if (brandMentions) {
            for (let i = 0; i < brandMentions.length; i++) {
                tempAr.push({
                    id: brandMentions[i].youtube_video?.id,
                    title: brandMentions[i].youtube_brand?.name,
                    avatar: brandMentions[i].youtube_brand?.avatar,
                    published_at: toDate(brandMentions[i].youtube_video?.published_at),
                    views: brandMentions[i].youtube_video.youtube_videometric?.view_count,
                    linkToPost: brandMentions[i].youtube_video?.id
                        ? getVideoLink(brandMentions[i].youtube_video?.id)
                        : undefined,
                })
            }
        }
        return tempAr
    }, [brandMentions])
    return (
        <>
            {preprocessedData !== undefined && preprocessedData.length !== 0 && (
                <>
                    <div className={cn.header}>
                        <MoreModal title={t('title')}>
                            <ListOfAccounts
                                amount={Math.min(preprocessedData.length, 30)}
                                cardType="horizontal"
                                data={preprocessedData}
                            />
                        </MoreModal>
                    </div>
                    <ListOfAccounts amount={6} cardType="horizontal" data={preprocessedData} />
                </>
            )}
        </>
    )
}

export default BrandMentions
