import React, { FC, memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom'
import { RootStore } from '@/_helpers/StoreType'
import { Portal } from '@/_helpers'
import IntercomIcon from './IntercomIcon'

import cn from './Commercial.module.scss'
import { Flex } from '@/_yc'
import { createSelector } from 'reselect'
import { LOGIN } from '@/routs'
export interface IIntercomChat {}

const selector = createSelector(
    (store: RootStore) => store.authentication.user?.profile?.email || null,
    (store: RootStore) => store.authentication.user?.profile?.first_name || null,
    (email: string | null, name: string | null) => {
        if (!email) return null
        return {
            name: name || '',
            email: email,
        }
    }
)

const IntercomChat: FC<IIntercomChat> = (props) => {
    const creds = useSelector<RootStore, { name: string; email: string } | null>(selector)
    const { update, show } = useIntercom()

    useEffect(() => {
        if (creds)
            update({
                name: creds.name,
                email: creds.email,
                hideDefaultLauncher: true,
            })
        else
            update({
                hideDefaultLauncher: true,
            })
    }, [creds, update])

    const location = window.location
    const isLogin = location.pathname.includes(LOGIN)

    return (
        <Portal>
            <Flex
                onClick={show}
                content="center"
                align="center"
                className={cn.icon}
                style={isLogin ? { bottom: '90px' } : {}}
            >
                <IntercomIcon />
            </Flex>
        </Portal>
    )
}

export default memo(IntercomChat)
