import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8">
            <path
                d="M11.8555 10.7364L10.205 8.63536V6.53833C9.77582 6.77987 9.28047 6.91765 8.75294 6.91765C7.11558 6.91765 5.78823 5.5903 5.78823 3.95294C5.78823 3.05187 6.19022 2.24469 6.82464 1.70095V0.799577C6.82461 0.358005 6.45546 0 6.00008 0C5.54469 0 5.17554 0.358005 5.17554 0.799577V1.70293C3.24809 2.07459 1.79522 3.72364 1.79522 5.70193V8.6354L0.144554 10.7365C-0.0293094 10.9841 -0.0476959 11.3047 0.0967697 11.5695C0.241235 11.8344 0.524846 12 0.833813 12H11.1662C11.4751 12 11.7587 11.8343 11.9032 11.5695C12.0477 11.3047 12.0293 10.9841 11.8555 10.7364Z"
                fill="white"
            />
            <path
                d="M4.16644 13.21C4.16642 13.2118 4.16636 13.2136 4.16629 13.2154L4.16622 13.2174C4.16617 13.2188 4.16614 13.2202 4.16614 13.2217C4.16614 14.2039 4.98723 15.0001 6.00011 15.0001C7.01298 15.0001 7.83408 14.2039 7.83408 13.2217C7.83408 13.2202 7.83405 13.2188 7.834 13.2174L7.83393 13.2154C7.83389 13.2145 7.83386 13.2135 7.83383 13.2126C7.83381 13.2117 7.83379 13.2108 7.83378 13.21H4.16644Z"
                fill="white"
            />
        </g>
        <path
            d="M11.0118 3.95292C11.0118 5.20043 10.0005 6.21174 8.75296 6.21174C7.50545 6.21174 6.49414 5.20043 6.49414 3.95292C6.49414 2.7054 7.50545 1.69409 8.75296 1.69409C10.0005 1.69409 11.0118 2.7054 11.0118 3.95292Z"
            fill="#FF6161"
        />
    </svg>
)
