import Const from './_constants.js'
import moment from 'moment'

let user = JSON.parse(localStorage.getItem('user'))
const initialState = user
    ? {
        loggedIn: true,
        user,
        infoLoaded: false,
    }
    : {
        loggedIn: false,
        user: {},
        infoLoaded: false,
    }

export default (state = initialState, action) => {
    switch (action.type) {
        case Const.LOGIN_PAGE_REQUEST:
            return state
        case Const.LOGIN_PAGE_LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                user: {
                    ...state.user,
                    token: action.payload.token,
                },
            }

        case Const.LOGIN_PAGE_USER_INFO_REQUESTED:
            return {
                ...state,
                started: true,
                user: {
                    ...state.user,
                    profile: {
                        first_name: '',
                        last_name: '',
                        email: '',
                        personName: '',
                    },
                },
            }
        case Const.LOGIN_PAGE_USER_INFO_SUCCESS:
            let { payload } = action
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: {
                        ...state.user.profile,
                        ...payload,
                        package: payload.package.name,
                        isPromo: payload.package.isPromo || !payload.package.name,
                    },
                },
                infoLoaded: true,
            }
        case Const.LOGIN_PAGE_SUBSCRIPTION_TYPE:
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: {
                        ...state.user.profile,
                        platforms: action.payload.tariff?.platforms,
                    }
                }
            }
        case Const.LOGIN_PAGE_USER_INFO_FAILURE:
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: {
                        personName: 'User Menu',
                    },
                },
                infoLoaded: true,
                error: action.error,
            }
        case Const.LOGIN_PAGE_USER_INFO_UPDATE:
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: {
                        ...state.user.profile,
                        ...action.info,
                        personName:
                            action.info.first_name && action.info.last_name
                                ? action.info.first_name +
                                ' ' +
                                action.info.last_name
                                : action.info.email,
                    },
                },
            }
        case Const.USER_DETAILS_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    userDetails: {
                        isShown: action.payload.is_shown,
                        createdDate: moment(action.payload.created_date),
                    }
                }
            }
        case Const.USER_PLATFORMS:
            return {
                ...state,
                user: {
                    ...state.user,
                    platforms: action.payload.tariff?.platforms,
                    isVkAvailable: action.payload.is_vk_available ? action.payload.is_vk_available : false,
                    isTgAvailable: action.payload.is_telegram_available ? action.payload.is_telegram_available : false,
               }
            }
        case Const.USER_DETAILS_ERROR:
            return state
        case Const.LOGIN_PAGE_LOGIN_ERROR:
            return state
        case Const.LOGIN_PAGE_LOGOUT:
            return {}
        default:
            return state
    }
}
