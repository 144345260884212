import { useState, useEffect, useCallback } from 'react'

const SITE_KEY_RECAPTCHA = '6LdAvBoqAAAAAKjdv6Hk_Cp5a5b8RhIOvpFXmAa6'
const useRecaptchaV3 = () => {
    const [isRecaptchaReady, setIsRecaptchaReady] = useState(false)

    useEffect(() => {
        let script: any = null

        if ((window as any).grecaptcha) {
            setIsRecaptchaReady(true)
        } else {
            script = document.createElement('script')
            script.src = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY_RECAPTCHA}`
            script.async = true
            document.head.appendChild(script)
            script.onload = () => {
                setIsRecaptchaReady(true)
            }
        }
        return () => {
            if (script) document.head.removeChild(script)

            const recaptchaBadge = document.querySelector('.grecaptcha-badge')
            if (recaptchaBadge) recaptchaBadge.remove()
        }
    }, [setIsRecaptchaReady])

    const executeRecaptcha = useCallback(async (action) => {
        try {
            if ((window as any).grecaptcha) {
                return await (window as any).grecaptcha.execute(SITE_KEY_RECAPTCHA, { action })
            }
        } catch (e) {
            console.log('error', e)
        }
        return null
    }, [])

    return { executeRecaptcha, isRecaptchaReady }
}

export default useRecaptchaV3
