import Service from '@/_helpers/Service'

const CreateCampaignsList = new Service<any>({
    baseUrl: process.env.REACT_APP_CAMPAIGN_MANAGER,
    method: 'POST',
    url: '',
})
export const CreateCampaignsListReq = (token: string, name: string, description: string) =>
    CreateCampaignsList.call(
        {
            url: `/api/campaigns/`,
            data: {
                name: name,
                description: description,
                is_active: true,
            },
        },
        token
    )
