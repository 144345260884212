import React, { FC } from 'react'

import cn from './MarkerDot.module.scss'

interface MarkerDotProps {
    color: 'red' | 'green' | 'yellow'
}

export const MarkerDot: FC<MarkerDotProps> = ({ color }) => {
    return <div className={cn[`markerDot--${color}`]} />
}
