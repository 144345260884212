import React, { FC, memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import { ListItem, Segment } from '@/Layouts/ListLayout'

import cn from './SearchItems.module.scss'
import Text from '@/components/Text'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import Sorter from '../../../Bloggers/SearchItems/Sorter'
import { MassAddHeadCheckBox } from '@/components/MassAddToList/MassAddHeadCheckBox'
import { ChooseAllNEW } from '@/components/MassAddToList/ChooseAll'
import { useAdapter } from '../../hooks/useAdapter'
import { CreateListPlace } from '@/_helpers/amplitude/types'
import ToOutreach from '@/pages/Lists/components/ToOutreach'
import { ToMediaPlan } from '@/pages/Lists/components/ToMediaPlan/ToMediaPlan'
import { Platform } from '@/pages/Reports/types'

export interface IItemHead {
    margin?: string | number
    platform?: Platform
}

const ItemHead: FC<IItemHead> = (props) => {
    const t = useSpaceTranslation('Influencer_explorer.grid_header')
    const MassAddToListDataINST = useSelector((store: RootStore) => store.MassAddToList.dataINST)
    const MassAddToListDataYT = useSelector((store: RootStore) => store.MassAddToList.dataYT)
    const showHeader = MassAddToListDataINST.length === 0 && MassAddToListDataYT.length === 0
    const { title } = useAdapter()

    const place: CreateListPlace = useMemo(() => {
        return title === 'Instagram' ? 'infl_exp_ig' : ('infl_exp_yt' as CreateListPlace)
    }, [title])

    if (!showHeader) {
        return (
            <div className={cn['head--controls']} style={{ height: '60px' }}>
                <Segment margin="0 0 0 20px">
                    <MassAddHeadCheckBox place={place} send_ig_bloggers_on_full_parsing_if_needed={false} />
                </Segment>
                <Segment>
                    <ToOutreach />
                </Segment>
                <Segment>
                    <ToMediaPlan />
                </Segment>
                {/* <Segment>
                    <SendToMailingList />
                </Segment> */}
            </div>
        )
    }
    return (
        <>
            <ListItem height={50} margin={props.margin} className={cn.head}>
                <Segment content="center" margin="0 0 0 20px">
                    <ChooseAllNEW platform={props.platform || Platform.Instagram} />
                </Segment>
                <Segment content="center" margin="0 0 0 -20px" className={cn.desktopItem}>
                    <Text color="gray-3" component="span" fSize={10}>
                        #
                    </Text>
                </Segment>
                <Segment content="center" gridColumn="3/5" className={cn.desktopItem}>
                    <Text color="gray-3" component="span" fSize={10}>
                        {t('username')}
                    </Text>
                </Segment>
                <Segment content="center" className={cn.desktopItem}>
                    <Text color="gray-3" component="span" fSize={10}>
                        {t('followers')}
                    </Text>
                </Segment>
                <Segment content="center" className={cn.desktopItem}>
                    <Text color="gray-3" component="span" fSize={10}>
                        {t('engagement')}
                    </Text>
                </Segment>
                <Segment className={cn.desktopItem}>
                    <Text color="gray-3" component="span" fSize={10}>
                        {t('topics')}
                    </Text>
                </Segment>
                <Segment content="flex-end">
                    <Sorter />
                </Segment>
            </ListItem>
        </>
    )
}

export default memo(ItemHead)
