import React, { SVGAttributes } from 'react'

const UnlockIcon = (props: SVGAttributes<SVGElement> & { color?: string }) => (
    <svg {...props} width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.75695 7.24319C9.59486 7.0811 9.39817 7.00019 9.16658 7.00019H2.77771V4.22244C2.77771 3.60891 2.99477 3.08525 3.42882 2.6512C3.86291 2.21724 4.3866 2.00021 5.00008 2.00021C5.61349 2.00021 6.13727 2.2172 6.57118 2.6512C7.00526 3.08525 7.22232 3.60894 7.22232 4.22244C7.22232 4.37285 7.27726 4.50304 7.38712 4.61299C7.49721 4.72296 7.62746 4.77796 7.77772 4.77796H8.33355C8.4839 4.77796 8.61409 4.72296 8.72419 4.61299C8.83383 4.50304 8.88898 4.37285 8.88898 4.22244C8.88898 3.15172 8.50847 2.23609 7.74756 1.47503C6.98653 0.713947 6.07065 0.333496 5.00008 0.333496C3.92941 0.333496 3.01362 0.713947 2.25253 1.475C1.49156 2.23596 1.11108 3.15169 1.11108 4.22241V7.00016H0.833331C0.601921 7.00016 0.405112 7.08125 0.243055 7.24315C0.080998 7.40506 0 7.60193 0 7.83343V12.8335C0 13.0651 0.0810284 13.2619 0.243055 13.4239C0.405112 13.5858 0.601921 13.6668 0.833331 13.6668H9.16658C9.39817 13.6668 9.59504 13.5858 9.75695 13.4239C9.91885 13.2619 10 13.0651 10 12.8335V7.83346C10.0001 7.60196 9.91906 7.4053 9.75695 7.24319Z"
            fill={props.color ? props.color : '#6071FF'}
        />
    </svg>
)
export default UnlockIcon
