import React from 'react'
import PageLayout from '@/Layouts/PageLayout'
import { useTranslation } from 'react-i18next'
import { Header } from '../Header/Header'
import { GlobalWidth } from '@/_yc'
import { Table } from './Table/Table'
import { Filters } from './Filters/Filters'

export const Stats = () => {
    const { t } = useTranslation()

    return (
        <PageLayout title={t('titles.t6')} color="common-background">
            <Header />
            <GlobalWidth fixedWidth>
                <Filters />

                <Table />
            </GlobalWidth>
        </PageLayout>
    )
}
