import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.6665 33.3334V21.6666H18.3332L12.9715 27.0333C14.8214 28.925 17.3539 29.994 19.9998 30C24.2322 29.9937 28.0024 27.3237 29.4132 23.3333H29.4432C29.6341 22.791 29.7785 22.2334 29.8748 21.6666H33.2282C32.3883 28.3331 26.719 33.3331 19.9998 33.3334H19.9832C16.4478 33.3439 13.0551 31.9395 10.5615 29.4333L6.6665 33.3334ZM10.1232 18.3333H6.76984C7.60942 11.6693 13.2748 6.67021 19.9915 6.66657H19.9998C23.5359 6.65526 26.9294 8.05971 29.4232 10.5666L33.3332 6.66657V18.3333H21.6665L27.0365 12.9666C25.1847 11.0727 22.6487 10.0035 19.9998 9.99997C15.7674 10.0062 11.9973 12.6763 10.5865 16.6666H10.5565C10.3641 17.2085 10.2197 17.7662 10.1248 18.3333H10.1232Z"
                fill="#929EB0"
            />
        </svg>
    )
}
