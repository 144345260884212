import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.00008 17.3334C4.39771 17.3334 0.666748 13.6025 0.666748 9.00008C0.666748 4.39771 4.39771 0.666748 9.00008 0.666748C13.6025 0.666748 17.3334 4.39771 17.3334 9.00008C17.3284 13.6004 13.6004 17.3284 9.00008 17.3334ZM9.00008 2.33341C5.31818 2.33341 2.33341 5.31818 2.33341 9.00008C2.33341 12.682 5.31818 15.6667 9.00008 15.6667C12.682 15.6667 15.6667 12.682 15.6667 9.00008C15.6626 5.3199 12.6803 2.33755 9.00008 2.33341ZM12.3334 13.1667H5.66675V11.5001H12.3334V13.1667ZM6.08342 9.00008C5.39306 9.00008 4.83342 8.44044 4.83342 7.75008C4.83342 7.05973 5.39306 6.50008 6.08342 6.50008C6.77377 6.50008 7.33342 7.05973 7.33342 7.75008C7.33342 8.44044 6.77377 9.00008 6.08342 9.00008ZM11.9109 8.98841C11.2238 8.98841 10.6667 8.43138 10.6667 7.74425C10.6667 7.05711 11.2238 6.50008 11.9109 6.50008C12.5981 6.50008 13.1551 7.05711 13.1551 7.74425C13.1542 8.431 12.5977 8.9875 11.9109 8.98841Z"
            fill="#8F8F8F"
        />
    </svg>
)
