import React, { FC, ReactNode, CSSProperties, MouseEvent as Me } from 'react'

import { Popper, ClickAwayListener } from '@material-ui/core'

export interface ISideNavBar {
    anchor?: HTMLElement | null
    children: ReactNode
    isOpen: boolean
    onClose: (e: Me<Document, MouseEvent>) => void
    style?: CSSProperties
    disablePreventOverflow?: boolean
}

const mods = {
    preventOverflow: {
        enabled: true,
        boundariesElement: 'scrollParent',
    },
}

const Popup: FC<ISideNavBar> = (props) => {
    return (
        <Popper
            style={props.style}
            modifiers={!props.disablePreventOverflow ? mods : undefined}
            open={props.isOpen}
        >
            <ClickAwayListener onClickAway={props.onClose}>
                {props.children}
            </ClickAwayListener>
        </Popper>
    )
}

export default Popup
