import React, { useState } from 'react'
// import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { useHistory } from 'react-router-dom'
import { CAMPAIGN_MANAGER } from '@/routs'

import Button from '@/components/Button'

import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import notif from '@/components/Notifier/model/actions.js'
import useOpenClose from '@/_helpers/useOpenClose'
import useToken from '@/_helpers/Hooks/useToken'
import CloseIcon from '@material-ui/icons/Close'
import Popup from '@/_yc/Popup/Popup'
import Flex from '@/_yc/Flex'
import { CreateCampaignsListReq } from './services'
import cn from './Header.module.scss'

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 460,
    zIndex: 120,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}

export const CreateNewCampaign = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const token = useToken()
    const [isOpen, open, close] = useOpenClose()
    const history = useHistory()

    const [campaignName, setcampaignName] = useState<string>('')
    const [description, setdescription] = useState<string>('')

    const req = async () => {
        dispatch(notif.loading(t('campaign_manager.hider.req.loading')))
        if (!token) {
            dispatch(notif.error(t('campaign_manager.hider.req.error')))
            return
        }
        CreateCampaignsListReq(token, campaignName, description)
            .then((res) => {
                console.log(res)
                dispatch(notif.success(t('campaign_manager.hider.req.success')))
                close()
                history.push(`${CAMPAIGN_MANAGER}/${res.id}`)
            })
            .catch((er) => {
                console.log(er)
                dispatch(notif.error(t('campaign_manager.hider.req.error')))
                close()
            })
    }

    const isButtonAvailable = () => {
        return !(campaignName.length > 0)
    }
    return (
        <>
            <Button variant="primary" onClick={open} id={'create-new-campaign-ID'}>
                {t('campaign_manager.new.btn')}
            </Button>
            {isOpen && (
                <div className={cn.root}>
                    <Popup style={style} isOpen={isOpen} onClose={close}>
                        <Flex className={cn.popup} column>
                            <Flex content={'space-between'}>
                                <p className={cn.title}>{t('campaign_manager.new.title')}</p>
                                <CloseIcon className={cn['close']} onClick={close} />
                            </Flex>

                            <p className={cn.label}>{t('campaign_manager.new.name')}</p>
                            <input
                                value={campaignName}
                                onChange={(e) => setcampaignName(e.target.value)}
                                placeholder={t('campaign_manager.new.name')}
                                className={cn.input}
                            />

                            <p className={cn.label}>{t('campaign_manager.new.desc')}</p>
                            <textarea
                                value={description}
                                onChange={(e) => setdescription(e.target.value)}
                                placeholder={t('campaign_manager.new.desc')}
                                className={cn.input}
                                style={{ height: '90px' }}
                            />

                            <div className={cn.divider} />
                            <Flex content="flex-end" style={{ width: '100%' }}>
                                <Button
                                    disabled={isButtonAvailable()}
                                    variant="primary"
                                    className={cn.submit}
                                    onClick={req}
                                >
                                    {t('campaign_manager.new.submit')}
                                </Button>
                            </Flex>
                        </Flex>
                    </Popup>
                </div>
            )}
        </>
    )
}
