import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.1667 11.8337H1.83341C0.91294 11.8337 0.166748 11.0875 0.166748 10.167V1.83366C0.166748 0.913184 0.91294 0.166992 1.83341 0.166992H10.1667C11.0872 0.166992 11.8334 0.913184 11.8334 1.83366V10.167C11.8334 11.0875 11.0872 11.8337 10.1667 11.8337ZM1.83341 1.83366V10.167H10.1667V1.83366H1.83341Z"
                fill="#929EB0"
            />
        </svg>
    )
}
