import React, { memo, useEffect, useState, useMemo } from 'react'

import GlobalWidth from '@/_yc/GlobalWidth'
import Icons from '@/components/Icons'
import Loader from '@/components/Loader'
import Flex from '@/_yc/Flex'
import Text from '@/components/Text'
import useToken from '@/_helpers/Hooks/useToken'
import { useTranslation } from 'react-i18next'

import Header from './components/Header'
import CampaignItem from './components/CampaignItem'
import { GetCampaignsListReq } from './model/services'
import { CreateNewCampaign } from '../Header/CreateNewCampaign'
import { ICampaign } from './model/types'

import cn from './CampaignList.module.scss'

const CampaignsList = () => {
    const token = useToken()
    const [status, setStatus] = useState<{ loading: boolean; error: boolean }>({ loading: true, error: false })
    const [campaigns, setCampaigns] = useState<ICampaign[]>([])
    const empty = useMemo(() => campaigns.length === 0, [campaigns])
    const { t } = useTranslation()

    useEffect(() => {
        if (!token) return
        setStatus({ loading: true, error: false })
        GetCampaignsListReq(token)
            .then((res) => {
                setStatus({ loading: false, error: false })
                setCampaigns(res)
            })
            .catch((er) => {
                setStatus({ loading: false, error: true })
                console.log(er)
            })
    }, [token])
    return (
        <GlobalWidth>
            {status.error ? (
                <Flex content={'center'} margin="20px 0 0 0">
                    <Text>{t('campaign_manager.hider.req.error')}</Text>
                </Flex>
            ) : status.loading ? (
                <Flex content={'center'} margin="20px 0 0 0">
                    <Loader />
                </Flex>
            ) : (
                <>
                    <Header state={empty ? 'empty' : 'campaigns'} />
                    <Flex column className={cn.root} style={empty ? {} : { backgroundColor: 'unset' }}>
                        {empty ? (
                            <>
                                {/* empty page */}
                                <Flex align="center" content="center" column style={{ width: '100%', height: '380px' }}>
                                    <Flex className={cn.emptyIcon} align="center" content="center">
                                        <Icons name="campaign-manager" />
                                    </Flex>
                                    <Text color="gray-1" fSize="20px" fWeight="600">
                                        {t('campaign_manager.create')}
                                    </Text>
                                    <Text color="gray-3" fSize="14px" margin="0 0 16px 0">
                                        {t('campaign_manager.use')}
                                    </Text>
                                    <CreateNewCampaign />
                                </Flex>
                            </>
                        ) : (
                            campaigns.map((item) => <CampaignItem item={item} />)
                        )}
                    </Flex>
                </>
            )}
        </GlobalWidth>
    )
}

export default memo(CampaignsList)
