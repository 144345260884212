import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.89754 18.968C9.36652 18.9696 8.85696 18.7586 8.48254 18.382L0.648543 10.547C0.235132 10.1348 0.0231528 9.56207 0.0685427 8.98001L0.568543 2.41401C0.639841 1.4264 1.42674 0.641631 2.41454 0.573012L8.98055 0.0730117C9.03155 0.0620117 9.08354 0.0620117 9.13454 0.0620117C9.66439 0.063372 10.1722 0.273991 10.5475 0.648012L18.3825 8.48201C18.7578 8.85711 18.9686 9.36594 18.9686 9.89651C18.9686 10.4271 18.7578 10.9359 18.3825 11.311L11.3115 18.382C10.9374 18.7583 10.4282 18.9693 9.89754 18.968ZM9.13354 2.06201L2.56254 2.56201L2.06254 9.13301L9.89754 16.968L16.9675 9.89801L9.13354 2.06201ZM5.65454 7.65402C4.70038 7.65421 3.87896 6.98037 3.69262 6.04458C3.50629 5.10879 4.00695 4.17169 4.88841 3.80639C5.76988 3.44109 6.78664 3.74933 7.31687 4.54259C7.84711 5.33586 7.74314 6.39322 7.06854 7.06801C6.69437 7.44433 6.18522 7.65534 5.65454 7.65402Z"
            fill="#6071FF"
        />
    </svg>
)
