import React, { FC, memo, useCallback, useContext } from 'react'

import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

import cn from './CampaignHeader.module.scss'

import { Link } from 'react-router-dom'
import { CAMPAIGN_MANAGER } from '@/routs'
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import NameInput from './NameInput/NameInput'
import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'
import { useMutation } from '@apollo/client'
import { CampaignManagerContext } from '../../CampaignManagerContext/CampaignManagerContext'
import { UPDATE_NAME } from './queries'
import { IUpdateName } from './types'
import Share from '../Share'

interface ICampaignHeader {
    name: string | null
    id: string
}

const CampaignHeader: FC<ICampaignHeader> = ({ name, id }) => {
    const t = useSpaceTranslation('campaign_manager.header')
    const context = useContext(CampaignManagerContext)

    const [updateCampaignName] = useMutation<IUpdateName>(UPDATE_NAME, {
        context: context,
        variables: {
            id: id,
            name: name,
        },
    })

    const updateName = useCallback(
        (newName: string) => {
            updateCampaignName({
                variables: {
                    id: id,
                    name: newName,
                },
            })
        },
        [updateCampaignName, id]
    )

    return (
        <div className={cn.header}>
            <div style={{ display: 'flex' }}>
                <div className={cn.icon}>
                    <Icons name="campaign-manager" />
                </div>
                <div className={cn.heading}>
                    <Flex align="center">
                        <Link to={CAMPAIGN_MANAGER}>
                            <span className={cn.title}>{t('title')}</span>
                        </Link>

                        <Icons
                            name={'arrow-right'}
                            className={cn.arrow}
                            style={{ width: '15px', height: '25px', margin: '0 10px' }}
                        />
                        {name && <NameInput curName={name} onSubmit={updateName} />}
                        {!name && <NameInput curName={null} onSubmit={updateName} />}
                    </Flex>

                    <Link to={CAMPAIGN_MANAGER} className={cn.back_button}>
                        <ArrowLeft fontSize="small" />
                        {t('back_btn')}
                    </Link>
                </div>
            </div>

            <Share title={name} campaignId={id} />
        </div>
    )
}

export default memo(CampaignHeader)
