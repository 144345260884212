import { IInfluencerLocation } from '@/pages/SearchPage/SearchAdapter/bloggers/instagram/types'
import { SelectorFilter } from '@/pages/SearchPage/SearchAdapter/filters/SelectorFilter'
import { getLocations } from '@/Services/Search'

const getGqlFilter = (ids: number[], locType: 'cityId' | 'countryId') => {
    return `${locType}: {in: [${ids.map((id) => `"${id}"`).join(', ')}]}`
}

export const getGeoFilter = () => {
    return new SelectorFilter({
        platform: 'ads_ig',
        title: 'ads_explorer.filters.post_metrics.geo.label',
        autocomplete: true,
        helper: 'ads_explorer.filters.post_metrics.geo.text',
        space: 1,
        isMulti: true,
        options: (token, locale, query) => {
            return getLocations(token, locale, query)
        },
        beforeSend: ({ value }: IInfluencerLocation) => {
            const res = []
            const cities = value.filter((v) => v.type === 'city')
            const countries = value.filter((v) => v.type === 'country')

            if (cities.length > 0) {
                const citiesIds = cities.map((v) => v.id)
                res.push({
                    name: 'location_h3.city_id',
                    operator: 'terms',
                    type: 'array',
                    value: citiesIds,
                    gqlFilter: getGqlFilter(citiesIds, 'cityId'),
                })
            }

            if (countries.length > 0) {
                const countriesIds = countries.map((v) => v.id)
                res.push({
                    name: 'location_h3.country_id',
                    operator: 'terms',
                    type: 'array',
                    value: countries.map((v) => v.id),
                    gqlFilter: getGqlFilter(countriesIds, 'countryId'),
                })
            }
            return res
        },
    })
}
