import React, { FC, useCallback, useState } from 'react'

import Text from '@/components/Text'
import cn from './MailingListItem.module.scss'
import Icons from '@/components/Icons'
import Flex from '@/_yc/Flex'
import Avatar from '@/components/Avatar'
import { ValueInput } from '@/components/ValueInput/ValueInput'

export interface MailingListItemProps {
    avatar: string
    name: string
    username: string
    email: boolean
    id: string
    onDelete?: (id: string) => void
    onSaveEmail?: (id: string, email: string) => void
    currNumber?: number
}

export const MailingListItem: FC<MailingListItemProps> = ({
    avatar,
    name,
    username,
    email,
    id,
    onDelete,
    currNumber,
    onSaveEmail,
}) => {
    const [isInputActive, setIsInputActive] = useState(false)

    const onInputClose = useCallback(() => {
        setIsInputActive(false)
    }, [setIsInputActive])

    const saveEmail = useCallback(
        (email: string) => {
            console.log(email, id, 'save')
            setIsInputActive(false)
        },
        [id]
    )

    return (
        <div className={`${email ? cn.listItem : cn.listItemNoEmail}`}>
            <Flex>
                {currNumber && (
                    <Flex align="center">
                        <Text fSize={12} margin="0 12px 0 0">
                            {currNumber}
                        </Text>
                    </Flex>
                )}
                <Avatar alt="AVA" src={avatar} aria-label="Аватарка" className={cn.avatar} />

                {!isInputActive && (
                    <div className={cn.name}>
                        {name && (
                            <Flex align="center">
                                <Text fSize={14} margin="0 5px 0 0">
                                    {name}
                                </Text>
                                {email && <Icons name="mail" />}

                                {onSaveEmail && !email && (
                                    <Text
                                        fSize={12}
                                        color="violet-1"
                                        onClick={() => setIsInputActive(true)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        + Add email
                                    </Text>
                                )}
                            </Flex>
                        )}
                        <Flex align="center">
                            <Text fSize={12} color="gray-3" margin="0 5px 0 0">
                                {username}
                            </Text>
                            {!name && email && <Icons name="mail" />}
                        </Flex>
                    </div>
                )}

                {isInputActive && (
                    <Flex align="center">
                        <ValueInput placeholder="Add email" onClose={onInputClose} onSubmit={saveEmail} />
                    </Flex>
                )}
            </Flex>

            {onDelete && id && (
                <div onClick={() => onDelete(id)}>
                    <Icons name="close" className={cn.close}></Icons>
                </div>
            )}
        </div>
    )
}
