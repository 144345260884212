import Button from '@/components/Button'
import Heading from '@/components/Heading'
import Text from '@/components/Text'
import { Platform } from '@/pages/Reports/types'
import { TARIFFS } from '@/routs'
import { numberFormatter, useSpaceTranslation } from '@/_helpers'
import React, { memo, FC } from 'react'
import { Trans } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import ym from 'react-yandex-metrika'
import { findAdapterType } from '../utils/findAdapterType'

import cn from './Hidder.module.scss'

export interface IHidder {
    count: number
    platfrom: Platform
}

const Hidder: FC<IHidder> = (props) => {
    const t = useSpaceTranslation('Influencer_explorer.hidder')
    const tag = useSpaceTranslation('tags.plans_search')

    const loc = useLocation()
    const location = findAdapterType(loc.pathname)
    const isYoutube = location === Platform.YouTube
    const isVk = location === Platform.Vk

    const useGlob = () => {
        if ((global as any).isProd) {
            ;(global as any).gtag('event', tag('name'), {
                event_category: tag('category'),
                event_action: tag('action'),
            })
            ym('reachGoal', tag('category'))
        }
    }
    return (
        <div className={cn.root}>
            <div className={cn.container}>
                <Heading type="h5">{t('title', { count: numberFormatter(props.count) })}</Heading>
                <Text margin="6px 0 16px" color="gray-3">
                    <Trans
                        i18nKey={
                            isYoutube
                                ? 'Influencer_explorer.hidder.p_youtube'
                                : isVk
                                ? 'Influencer_explorer.hidder.p_vk'
                                : 'Influencer_explorer.hidder.p'
                        }
                        components={[<Text component="span" semibold color="gray-3" />]}
                    />
                </Text>
                <Link to={TARIFFS} onClick={useGlob}>
                    <Button size="small" variant="primary">
                        {t('button')}
                    </Button>
                </Link>
            </div>
        </div>
    )
}

export default memo(Hidder)
