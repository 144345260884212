import React, { useCallback, useEffect, useState, FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import moment from 'moment'

import { useSpaceTranslation } from '@/_helpers'
import { RootStore } from '@/_helpers/StoreType'
import { submitDetails, IBrandInfluencer } from '@/Services/User'
import Button from '@/_yc/Button'
import Const from '@/pages/Auth/LoginPage/Model/_constants.js'

import { Switcher, ISwitcherType } from './Components/Switcher'
import Influencer from './Influencer'
import Bar from './Components/Bar'
import { GetRoles } from './Service'
import Brand, { userRolesValueType } from './Brand'

import cn from './DetailedInfo.module.scss'

interface IQuestion {
    closePopUp: Function
}

const Question: FC<IQuestion> = ({ closePopUp }) => {
    const t = useSpaceTranslation('detailed_info')
    const Dispatch = useDispatch()

    const detailedInfo = useSelector((store: RootStore) => store.authentication.user?.userDetails)

    const [accountTypeIndex, setAccountTypeIndex] = useState<ISwitcherType>(undefined)

    const token = useSelector((state: RootStore) => state.authentication.user?.token) || ''
    const submit = useCallback(
        async (data: IBrandInfluencer) => {
            try {
                await submitDetails({
                    token: token,
                    user_type: accountTypeIndex,
                    ...data,
                }).then((res) => {
                    //@ts-ignore
                    if (res.status === 'ok') {
                        Dispatch({
                            type: Const.USER_DETAILS_SUCCESS,
                            payload: {
                                is_shown: true,
                                createdDate: detailedInfo?.createdDate,
                            },
                        })
                    }
                })

                closePopUp()

                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        [accountTypeIndex, token, closePopUp, Dispatch, detailedInfo]
    )

    const [stateCounter, setStateCounter] = useState<number>(-1)
    const maxCountOfQuestions = accountTypeIndex ? (accountTypeIndex !== 3 ? 5 : 3) : accountTypeIndex

    const handleSwitcher = (val: ISwitcherType) => {
        if (accountTypeIndex === undefined) {
            setStateCounter(stateCounter + 1)
        }
        setAccountTypeIndex(val)
    }

    const [userRoles, setUserRoles] = useState<userRolesValueType>([])
    useEffect(() => {
        GetRoles(token).then((res) => {
            setUserRoles(
                res.map((item, i) => {
                    return {
                        label: item.name,
                        value: i + 1,
                    }
                })
            )
        })
    }, [token])
    return (
        <>
            <Bar stateCounter={stateCounter} maxCount={maxCountOfQuestions} />
            <p className={cn['header']}>
                <span role="img" aria-label="Emoji">
                    👋
                </span>{' '}
                {t('title')}
            </p>

            <Switcher active={accountTypeIndex} setActive={handleSwitcher} resetStateCounter={setStateCounter} />

            {!accountTypeIndex && (
                <Button className={cn.button} onClick={undefined} disabled={true} width={'100%'} color={'blue'}>
                    {t('brand_info.submit_button')}
                </Button>
            )}
            {(accountTypeIndex === 1 || accountTypeIndex === 2) && (
                <Brand
                    onSubmit={submit}
                    stateCounter={stateCounter}
                    setStateCounter={setStateCounter}
                    roleOptions={userRoles}
                />
            )}
            {accountTypeIndex === 3 && (
                <Influencer onSubmit={submit} stateCounter={stateCounter} setStateCounter={setStateCounter} />
            )}
        </>
    )
}

export default Question
