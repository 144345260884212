import React from 'react'
import Header from '@/pages/TargetAudience/components/Header'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import cn from './layout.module.scss'
import { ReactComponent as ArrowIcon } from '../../icons/short_left.svg'
import { useTranslation } from 'react-i18next'

const Index: React.FC = ({ children }) => {
    const { t } = useTranslation()
    return (
        <div>
            <Flex align="start" className={cn['header-wrapper']}>
                <Header />
                <ArrowIcon className={cn['arrow-icon']} />
                <Text style={{ lineHeight: '36px', marginTop: '3px' }} fSize={30}>
                    {t('target_audience.report_creation')}
                </Text>
            </Flex>
            <div>{children}</div>
        </div>
    )
}

export default Index
