import Service from '@/_helpers/Service'
import {
    AudienceInterestsResponse,
    GeoResponse,
    IAdditionalParams,
    IAge,
    ICampaignCreateReq,
    ICampaignResponse,
    ICampaignUpdateReq,
    IChildrenTarget,
    IDevice,
    IFamilyStatus,
    IGender,
    IGeoCreateReq,
    IGeoTargetResponse,
    IIncome,
    IInterest,
    ILandlordType,
    IOccupation,
    ISaveReportHandlerParams,
    ISite,
    ITargetAudienceData,
    TargetResponse,
} from '@/pages/TargetAudience/services/types'
import { transformGeoOptions } from '@/pages/TargetAudience/utils'

// Запрос на создание новой кампании
const createCampaignService = new Service<ICampaignResponse>({
    method: 'POST',
    url: 'api/audience/campaign/create/',
})

export const createCampaignReq = (token: string | null, campaignData: ICampaignCreateReq) =>
    createCampaignService.call(
        {
            data: campaignData,
        },
        token as string
    )

const updateCampaignService = new Service<ICampaignResponse>({
    method: 'POST',
    url: '',
})

export const updateCampaignReq = (token: string | null, campaignId: number, campaignData: ICampaignUpdateReq) =>
    updateCampaignService.call(
        {
            url: `/api/audience/campaign/${campaignId}/update/`,
            data: campaignData,
        },
        token as string
    )

const uploadCampaignFileService = new Service<ICampaignResponse>({
    method: 'POST',
    headers: {
        'Content-Type': 'multipart/form-data',
    },
    url: '',
})

export const uploadCampaignFileReq = (token: string, campaignId: number, file: File) => {
    const formData = new FormData()
    formData.append('file', file)

    return uploadCampaignFileService.call(
        {
            url: `/api/audience/campaign/upload/${campaignId}`,
            data: formData,
        },
        token
    )
}

const getCampaignByIdService = new Service<ICampaignResponse>({
    method: 'GET',
    url: '',
})

export const getCampaignByIdReq = (token: string | null, campaignId: number | string) =>
    getCampaignByIdService.call(
        {
            url: `/api/audience/campaign/${campaignId}/get/`,
        },
        token as string
    )

const getAllCampaignsService = new Service<ICampaignResponse[]>({
    method: 'GET',
    url: '/api/audience/campaign/',
})

export const getAllCampaignsReq = (token: string) => getAllCampaignsService.call({}, token)

const GetAudienceEnums = new Service<IAdditionalParams>({
    method: 'GET',
    url: '/api/audience/enum/all',
})

export const GetAudienceEnumsReq = (token: string | null) => GetAudienceEnums.call({}, token as string)

const GetAudienceInterests = new Service<AudienceInterestsResponse>({
    method: 'GET',
    url: '/api/audience/interests/all/',
})

export const GetAudienceInterestsReq = (token: string | null) => GetAudienceInterests.call({}, token as string)

const geoSearchService = new Service<GeoResponse[]>({
    method: 'GET',
    url: '',
})

export const geoSearchReq = (token: string | null, query: string) => {
    let url = '/api/audience/geo/search'
    if (query === '') url = 'api/audience/geo/get/'
    return geoSearchService.call(
        {
            params: { query },
            url,
        },
        token as string
    )
}

const fetchCampaignTargetsByPromptService = new Service<TargetResponse>({
    method: 'POST',
    url: '/api/audience/campaign/targets/by/prompt/',
})

export const fetchCampaignTargetsByPromptReq = (token: string | null, prompt: string) =>
    fetchCampaignTargetsByPromptService.call({ data: { prompt } }, token as string)

const createGeoTargetsService = new Service<any>({
    method: 'POST',
    url: '',
})

export const createGeoTargetsReq = (token: string | null, campaignId: number, geoData: IGeoCreateReq[]) =>
    createGeoTargetsService.call(
        {
            url: `/api/audience/campaign/${campaignId}/targets/geos/create/`,
            data: geoData,
        },
        token as string
    )

const createGenderTargetsService = new Service<any>({
    method: 'POST',
    url: '',
})

export const createGenderTargetsReq = (token: string | null, campaignId: number, genderData: string[]) =>
    createGenderTargetsService.call(
        {
            url: `/api/audience/campaign/${campaignId}/targets/genders/create/`,
            data: genderData,
        },
        token as string
    )

const createIncomeTargetsService = new Service<any>({
    method: 'POST',
    url: '',
})

export const createIncomeTargetsReq = (token: string | null, campaignId: number, incomeData: string[]) =>
    createIncomeTargetsService.call(
        {
            url: `/api/audience/campaign/${campaignId}/targets/incomes/create/`,
            data: incomeData,
        },
        token as string
    )

const createAgeTargetsService = new Service<any>({
    method: 'POST',
    url: '',
})

export const createAgeTargetsReq = (token: string | null, campaignId: number, ageData: string[]) =>
    createAgeTargetsService.call(
        {
            url: `/api/audience/campaign/${campaignId}/targets/ages/create/`,
            data: ageData,
        },
        token as string
    )

const createInterestTargetsService = new Service<void>({
    method: 'POST',
    url: '',
})
export const createInterestTargetsReq = (token: string | null, campaignId: number, interestIds: number[]) =>
    createInterestTargetsService.call(
        {
            url: `/api/audience/campaign/${campaignId}/targets/interests/create/`,
            data: interestIds,
        },
        token as string
    )

const createChildrenTargetsService = new Service<void>({
    method: 'POST',
    url: '',
})

export const createChildrenTargetsReq = (token: string | null, campaignId: number, childNames: string[]) =>
    createChildrenTargetsService.call(
        {
            url: `/api/audience/campaign/${campaignId}/targets/children/create/`,
            data: childNames,
        },
        token as string
    )
const createLandlordsTargetsService = new Service<void>({
    method: 'POST',
    url: '',
})

export const createLandlordsTargetsReq = (token: string | null, campaignId: number, landlordNames: string[]) =>
    createLandlordsTargetsService.call(
        {
            url: `/api/audience/campaign/${campaignId}/targets/landlords/create/`,
            data: landlordNames,
        },
        token as string
    )

const createDevicesTargetsService = new Service<void>({
    method: 'POST',
    url: '', // Путь будет указан в запросе
})

export const createDevicesTargetsReq = (token: string | null, campaignId: number, deviceIds: string[]) =>
    createDevicesTargetsService.call(
        {
            url: `/api/audience/campaign/${campaignId}/targets/devices/create/`,
            data: deviceIds,
        },
        token as string
    )

const createOccupationsTargetsService = new Service<void>({
    method: 'POST',
    url: '',
})

export const createOccupationsTargetsReq = (token: string | null, campaignId: number, occupations: string[]) =>
    createOccupationsTargetsService.call(
        {
            url: `/api/audience/campaign/${campaignId}/targets/occupations/create/`,
            data: occupations,
        },
        token as string
    )

const createFamilyTargetsService = new Service<void>({
    method: 'POST',
    url: '',
})

export const createFamilyTargetsReq = (token: string | null, campaignId: number, familyStatuses: string[]) =>
    createFamilyTargetsService.call(
        {
            url: `/api/audience/campaign/${campaignId}/targets/family/create/`,
            data: familyStatuses,
        },
        token as string
    )

const createSiteTargetsService = new Service<void>({
    method: 'POST',
    url: '',
})

export const createSiteTargetsReq = (token: string | null, campaignId: number, sites: string[]) =>
    createSiteTargetsService.call(
        {
            url: `/api/audience/campaign/${campaignId}/targets/sites/create/`,
            data: sites,
        },
        token as string
    )

/// get
const getChildrenTargetsService = new Service<any[]>({
    method: 'GET',
    url: '',
})

export const getChildrenTargetsReq = (token: string | null, campaignId: number) =>
    getChildrenTargetsService.call(
        {
            url: `/api/audience/campaign/${campaignId}/targets/children/all/`,
        },
        token as string
    )

const getCampaignSitesService = new Service<ISite[]>({
    method: 'GET',
    url: '',
})

export const getCampaignSitesReq = (token: string | null, campaignId: number) =>
    getCampaignSitesService.call(
        {
            url: `/api/audience/campaign/${campaignId}/targets/sites/all/`,
        },
        token as string
    )

// Family status
const getFamilyStatusService = new Service<IFamilyStatus[]>({
    method: 'GET',
    url: '',
})

export const getFamilyStatusReq = (token: string | null, campaignId: number) =>
    getFamilyStatusService.call({ url: `/api/audience/campaign/${campaignId}/targets/family/all` }, token as string)

// Occupations
const getOccupationsService = new Service<IOccupation[]>({
    method: 'GET',
    url: '',
})

export const getOccupationsReq = (token: string | null, campaignId: number) =>
    getOccupationsService.call({ url: `/api/audience/campaign/${campaignId}/targets/occupations/all` }, token as string)

// Devices
const getDevicesService = new Service<IDevice[]>({
    method: 'GET',
    url: '',
})

export const getDevicesReq = (token: string | null, campaignId: number) =>
    getDevicesService.call({ url: `/api/audience/campaign/${campaignId}/targets/devices/all` }, token as string)

// Incomes
const getIncomesService = new Service<IIncome[]>({
    method: 'GET',
    url: '',
})

export const getIncomesReq = (token: string | null, campaignId: number) =>
    getIncomesService.call({ url: `/api/audience/campaign/${campaignId}/targets/incomes/all` }, token as string)

// Ages
const getAgesService = new Service<IAge[]>({
    method: 'GET',
    url: '',
})

export const getAgesReq = (token: string | null, campaignId: number) =>
    getAgesService.call({ url: `/api/audience/campaign/${campaignId}/targets/ages/all` }, token as string)

// Genders
const getGendersService = new Service<IGender[]>({
    method: 'GET',
    url: '',
})

export const getGendersReq = (token: string | null, campaignId: number) =>
    getGendersService.call({ url: `/api/audience/campaign/${campaignId}/targets/genders/all` }, token as string)

// Interests
const getInterestsService = new Service<IInterest[]>({
    method: 'GET',
    url: '',
})

export const getInterestsReq = (token: string | null, campaignId: number) =>
    getInterestsService.call({ url: `/api/audience/campaign/${campaignId}/targets/interests/all` }, token as string)

const getLandlordsService = new Service<ILandlordType[]>({
    method: 'GET',
    url: '',
})

export const getLandlordsReq = (token: string | null, campaignId: number) =>
    getLandlordsService.call({ url: `/api/audience/campaign/${campaignId}/targets/landlords/all` }, token as string)

const fetchGeoTargetsService = new Service<IGeoTargetResponse[]>({
    method: 'GET',
    url: '',
})

export const fetchGeoTargetsReq = (token: string | null, campaignId: number) =>
    fetchGeoTargetsService.call(
        {
            url: `/api/audience/campaign/${campaignId}/targets/geos/all`,
        },
        token as string
    )

const fetchCampaignsService = new Service<ICampaignResponse[]>({
    method: 'GET',
    url: '',
})

export const fetchCampaignsReq = (token: string | null) =>
    fetchCampaignsService.call(
        {
            url: `/api/audience/campaign/list/`,
        },
        token as string
    )

export const fetchAllTargetData = async (token: string | null, campaignId: number): Promise<ITargetAudienceData> => {
    try {
        const results = await Promise.allSettled([
            getFamilyStatusReq(token, campaignId),
            getOccupationsReq(token, campaignId),
            getDevicesReq(token, campaignId),
            getIncomesReq(token, campaignId),
            getAgesReq(token, campaignId),
            getGendersReq(token, campaignId),
            getInterestsReq(token, campaignId),
            getLandlordsReq(token, campaignId),
            fetchGeoTargetsReq(token, campaignId),
            getChildrenTargetsReq(token, campaignId),
            getCampaignSitesReq(token, campaignId),
        ])

        const [
            familyStatus,
            occupations,
            devices,
            incomes,
            ages,
            genders,
            interests,
            landlords,
            geo,
            childrenAge,
            websites,
        ] = results.map((result) => (result.status === 'fulfilled' ? result.value : []))

        return {
            familyStatus: familyStatus as IFamilyStatus[],
            occupations: occupations as IOccupation[],
            devices: devices as IDevice[],
            incomes: incomes as IIncome[],
            ages: ages as IAge[],
            genders: genders as IGender[],
            interests: interests as IInterest[],
            landlords: landlords as ILandlordType[],
            geo: geo as IGeoTargetResponse[],
            childrenAge: childrenAge as IChildrenTarget[],
            websites: websites as ISite[],
        }
    } catch (error) {
        console.error('Ошибка при загрузке данных целевой аудитории:', error)
        throw error
    }
}

export async function saveReportHandler({
    token,
    campaignId,
    selectedParams,
    selectedInterests,
    selectedAdditional,
}: ISaveReportHandlerParams): Promise<void> {
    try {
        const requests = [
            createGeoTargetsReq(token, campaignId, transformGeoOptions(selectedParams.country)),
            createGenderTargetsReq(
                token,
                campaignId,
                selectedParams.gender.map((option) => option.value)
            ),
            createIncomeTargetsReq(
                token,
                campaignId,
                selectedParams.income.map((option) => option.value)
            ),
            createAgeTargetsReq(
                token,
                campaignId,
                selectedParams.ages.map((option) => option.value)
            ),
            createInterestTargetsReq(
                token,
                campaignId,
                selectedInterests.flatMap((option) => option.values.map((v) => Number(v.value)))
            ),
            await createChildrenTargetsReq(
                token,
                campaignId,
                selectedAdditional.selectedAges.map((option) => option.value)
            ),
            await createDevicesTargetsReq(
                token,
                campaignId,
                selectedAdditional.selectedDevices.map((option) => option.value)
            ),
            await createOccupationsTargetsReq(
                token,
                campaignId,
                selectedAdditional.selectedEmployment.map((option) => option.value)
            ),
            await createFamilyTargetsReq(
                token,
                campaignId,
                selectedAdditional.selectedFamily.map((option) => option.value)
            ),
            await createSiteTargetsReq(
                token,
                campaignId,
                selectedAdditional.websites.map((option) => option.value)
            ),
            await createLandlordsTargetsReq(
                token,
                campaignId,
                selectedAdditional.selectedLandlord.map((option) => option.value)
            ),
        ]

        const results = await Promise.allSettled(requests)

        results.forEach((result, index) => {
            if (result.status === 'rejected') {
                console.error(`Запрос ${index + 1} завершился с ошибкой:`, result.reason)
            }
        })
    } catch (error) {
        console.error('Произошла ошибка при сохранении отчета:', error)
    }
}
