import React, { memo, useCallback, FC, CSSProperties } from 'react'
import ListPopup from '@/components/Share/ListPopup'
import FilterBase from '../FilterBase'
import useOpenClose from '@/_helpers/useOpenClose'

import { useComputedStyle } from '@/_helpers/Hooks/useComputedStyle'
import { Stylable } from '@/Types'
import Text from '@/components/Text'

export interface ISelector extends Stylable {
    isActive: (value: number) => boolean
    options: Array<{
        label: string
        value: number
        disabled?: boolean
    }>
    onChange: (value: number) => void
    display?: string
    fSize?: string | number
    placeholder?: string
    open?: boolean
    setClicked?: Function
    scrollToFilter?: Function
    popUpStyle?: CSSProperties
    disablePortal?: boolean
}

const Selector: FC<ISelector> = ({ onChange, ...props }) => {
    const popup = { zIndex: 15, marginTop: 6, ...props.popUpStyle }

    const [state, open, close, toggle] = useOpenClose()

    const { ref } = useComputedStyle((style) => style.width, [])

    const change = useCallback(
        (next: number) => {
            close()
            onChange(next)
        },
        [close, onChange]
    )
    if (props.open && props.setClicked && props.open === true) {
        props.scrollToFilter && props.scrollToFilter()
        setTimeout(open, 10)
        props.setClicked()
    }
    return (
        <>
            <FilterBase
                margin={props.margin}
                style={props.style}
                className={props.className}
                onClick={toggle}
                isActive={state}
                withArrow
                ref={ref}
            >
                <Text color={!props.display ? 'gray-4' : 'gray-2'} fSize={14}>
                    {props.display ?? props.placeholder}
                </Text>
            </FilterBase>
            <ListPopup
                popupStyle={popup}
                width={ref.current?.offsetWidth + 8}
                onClose={toggle}
                isOpen={state}
                anchor={ref.current}
                options={props.options}
                onClick={change}
                isActiveCheck={props.isActive}
                fSize={props.fSize}
                disablePortal={props.disablePortal}
            />
        </>
    )
}

export default memo(Selector)
