import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import pt from 'prop-types'

import { LockButton } from '@/_yc/'

// import { keys } from '../DefaultLists.js'
import LoadingButton from '@/components/Share/LoadingButton'


const DownloadButton = ({ onClick, loading, ...props }) => {
    const { t, locked } = props

    const selector = ({ AudienceInsights: info }) => info.ActiveListId
    const activeListId = useSelector(selector)

    const disabled = activeListId === -1

    const click = useCallback(() => {
        onClick(props.setId)
    }, [onClick, props.setId])

    if (locked)
        return (
            <LockButton light>
                <LoadingButton size="small" disabled width='100%'>
                    {t('aud_insides.downloadButton')}
                </LoadingButton>
            </LockButton>
        )

    return (
        <LoadingButton
            size="small"
            loading={loading}
            disabled={disabled}
            onClick={click}
            width='100%'
        >
            {t('aud_insides.downloadButton')}
        </LoadingButton>
    )
}

DownloadButton.propTypes = {
    pathname: pt.string.isRequired,
    setId: pt.oneOfType([pt.number, pt.string]).isRequired,
    onClick: pt.func,
    t: pt.func.isRequired,
}

export { DownloadButton as default }
