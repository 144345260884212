import { NativeReportData, IReportReadyStatus } from './Types'
import { hideSmallValues, hideInterestsForMillionaires } from './data.config'
import {
    GrowGraph,
    LookALineList,
    ErSpread,
    BrandAffinityList,
    AQDonut,
    ReachBar,
    // NotableFollowersList,
    AgeGenderMultiBar,
    GenderDonut,
    CityHorizontal,
    CountryHorizontal,
    LanguageHorizontal,
    Tags,
    BlockTitle,
    AudienceInterests,
    AudienceBrandAffinity,
    PostTypes,
    AllocationGraph,
    OnParsingLoader,
} from './ReadyCards'

export default (src: NativeReportData, reportReadyStatus?: IReportReadyStatus) => {
    return [
        [BlockTitle('account_data.title', null)],
        [GrowGraph(src)],
        [BlockTitle('auditory.title', null)],
        ...AuditoryWidgets(src, reportReadyStatus),
        [src.brandAffinity.values.length > 0 && BlockTitle('brandAffinity.title', null)],
        BrandAffinityList(src),
        [src.chaining.values.length > 0 && BlockTitle('lookalikes.title', null)],
        LookALineList(src),
        [BlockTitle('content.title', null)],
        ...[src.erSpread.values.length > 0 && ErSpread(src)],
        [src.erSpread.values.length > 0 && AllocationGraph(src)],
        src.postTypeAggregationsByDays.values.hasOwnProperty('30') && PostTypes(src),
        Tags(src),
    ]
}

const AuditoryWidgets = (src: NativeReportData, reportReadyStatus?: IReportReadyStatus) => {
    const checkSmallDataInterests = hideSmallValues(src)
    const ExistsAudInterests = src.audienceInterests.values.length !== 0 && checkSmallDataInterests.audInter > 0.05
    const ExistsAudBrandInterests =
        src.audienceBrandAffinity.values.length !== 0 && checkSmallDataInterests.brandInter > 0.05
    if (reportReadyStatus === -1) {
        return []
    }
    if (reportReadyStatus === 1) {
        return [
            src.audienceQuality.values.length > 0 && AQDonut(src),
            src.audienceReach.values.length > 0 && ReachBar(src),
            src.ageGenderSpread.values.length > 0 && AgeGenderMultiBar(src),
            src.genderSplit.values.length > 0 && GenderDonut(src),
            // NotableFollowersList(src),
            CityHorizontal(src),
            CountryHorizontal(src),
            LanguageHorizontal(src),
            hideInterestsForMillionaires(src) && ExistsAudInterests && AudienceInterests(src),
            hideInterestsForMillionaires(src) && ExistsAudBrandInterests && AudienceBrandAffinity(src),
        ]
    }
    return [OnParsingLoader(reportReadyStatus || 0.01, 1)]
}
