import React from 'react'
import styles from './stats.module.scss'
import { Flex } from '@/_yc'
import { ReactComponent as FlagIcon } from '../../icons/flag.svg'
import { ReactComponent as FollowerIcon } from '../../icons/followers.svg'
import { ReactComponent as ChatsIcon } from '../../icons/chats.svg'

interface Stat {
    label: 'Followers' | 'Chats' | 'Reports'
    value: number | string
}

interface StatsProps {
    stats: Stat[]
}
const icons = {
    Followers: <FollowerIcon />,
    Chats: <ChatsIcon />,
    Reports: <FlagIcon />,
}

const Stats: React.FC<StatsProps> = ({ stats }) => {
    return (
        <div className={styles.stats}>
            {stats.map((stat, index) => (
                <Flex align="center" key={index}>
                    <Flex>{icons[stat.label]}</Flex>
                    <Flex className={styles.stat} column>
                        <span className={styles.value}>{stat.value}</span>
                        <span className={styles.label}>{stat.label}</span>
                    </Flex>
                </Flex>
            ))}
        </div>
    )
}

export default Stats
