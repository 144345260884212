import React, { FC, memo, useCallback, useState } from 'react'
import ym from 'react-yandex-metrika'
import { useTranslation } from 'react-i18next'

import FormPageLayout from '@/Layouts/FormPageLayout'

import bg_image from './background_image.png'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

import { registerUser } from '@/Services/User'
import Done from './Done'
import Form from './Form'
import { getCookie } from '@/_helpers'
// import { history } from '@/_helpers'
// import { LOGIN } from '@/routs'
export interface ISignUpPage {
    mailCheck: (mail: string) => Promise<boolean>
}

const handleGlob = () => {
    if ((global as any).isProd) {
        ;(global as any).gtag('event', 'регистрация', {
            event_category: 'registration',
            event_action: 'send_registration',
        })
        ym('reachGoal', 'registration')
    }
}

const SignUpPage: FC<ISignUpPage> = ({ mailCheck, ...props }) => {
    // history.push(LOGIN)
    const t = useSpaceTranslation('signup')
    const { i18n } = useTranslation()
    const [email, setEmail] = useState<null | string>(null)
    const referralCode = getCookie('ref')
    const submit = useCallback(
        async (email: string, password: string) => {
            try {
                const clear = await mailCheck(email)
                if (!clear)
                    return {
                        email: 'BLOCKED',
                    }

                await registerUser(email, password, password, i18n.language, referralCode)
                setEmail(email)
                handleGlob()
                return
            } catch (e) {
                if (!e.response?.data) {
                    console.error(e)
                    return
                }
                const data = e.response.data

                return {
                    email: data.email ? 'EXISTS' : undefined,
                    password: data.password ? data.password[0] : undefined,
                }
            }
        },

        [mailCheck, i18n.language, referralCode]
    )

    return (
        <FormPageLayout
            background={bg_image}
            title={email ? t('sign_up_done.title') : t('title')}
            bgTextColor={'white'}
        >
            {email ? <Done email={email || ''} /> : <Form onSubmit={submit} />}
        </FormPageLayout>
    )
}

export default memo(SignUpPage)
