import React, { FC } from 'react'

import Text from '@/components/Text'
import Flex from '@/_yc/Flex'
import { useSpaceTranslation } from '@/_helpers'

export interface INoData {
    text?: string
}

const NoData: FC<INoData> = (props) => {
    const t = useSpaceTranslation('report_layout.videos')
    return (
        <Flex content="center" margin="20px 0 0 0">
            <Text>{props.text || t('no_data')}</Text>
        </Flex>
    )
}

export default NoData
