import React, { FC } from 'react'

import AccountCard from './Card/AccountCard'
import { IAccountData } from './types'
import cn from './ListOfAccounts.module.scss'
import { Platform } from '@/pages/Reports/types'

export interface IListOfAccounts {
    amount: number
    cardType: 'horizontal' | 'vertical'
    data: Array<IAccountData>
    showVideo?: boolean
    platform?: Platform
}
const ListOfAccounts: FC<IListOfAccounts> = ({ amount, cardType, data, showVideo, platform = Platform.YouTube }) => {
    return (
        <div className={cn['accounts--' + cardType]}>
            {data.slice(0, Math.min(amount, data.length)).map((account) => {
                return <AccountCard data={account} cardType={cardType} showVideo={showVideo} platform={platform} />
            })}
        </div>
    )
}

export default ListOfAccounts
