import React, { FC, useState } from 'react'
import styles from './report-name-modal.module.scss'
import { ReactComponent as EditIcon } from '../../icons/edit.svg'
import Modal from '@/_yc/Modal'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import { Input } from '@/components/Input/Input'
import Button from '@/components/Button'
import { useTranslation } from 'react-i18next'

interface ReportNameModalProps {
    onClose: () => void
    onSubmit: (name: string) => void
    isVisible: boolean
}

const ReportNameModal: FC<ReportNameModalProps> = ({ onClose, onSubmit, isVisible }) => {
    const [reportName, setReportName] = useState('')
    const { t } = useTranslation()

    const handleSubmit = () => {
        if (reportName.trim()) {
            onSubmit(reportName)
            setReportName('')
        }
    }

    if (!isVisible) return null

    return (
        <Modal onClose={onClose}>
            <Flex column className={styles.modalContent}>
                <Flex className={styles.iconWrapper}>
                    <EditIcon />
                </Flex>
                <Flex column className={styles.textWrapper}>
                    <Text component="h3" margin="0 0 10px 0" fSize="20px" semibold color="gray-new-1">
                        {t('target_audience.additional_params.report_name')}
                    </Text>
                    <Text margin="0 0 16px 0" fSize="14px" color="gray-new-4">
                        {t('target_audience.additional_params.prompt_report_name')}
                    </Text>
                </Flex>
                <Flex column className={styles.inputWrapper}>
                    <Text fSize="12px" semibold color="gray-new-4">
                        {t('target_audience.additional_params.enter_report_name')}
                    </Text>
                    <Input
                        placeholder={t('target_audience.additional_params.my_new_report')}
                        value={reportName}
                        onChange={(e) => setReportName(e.target.value)}
                    />
                    <Button disabled={!reportName.trim()} onClick={handleSubmit}>
                        {t('target_audience.additional_params.continue')}
                    </Button>
                </Flex>
            </Flex>
        </Modal>
    )
}

export default ReportNameModal
