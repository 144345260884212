import React, { useState, useEffect } from 'react'

import Text from '@/components/Text'
import Button from '@/components/Button'
import useOpenClose from '@/_helpers/useOpenClose'
import Popup from '@/_yc/Popup/Popup'
import Flex from '@/_yc/Flex'

import cn from './Style.module.scss'
import { useTranslation } from 'react-i18next'

import First from './First.png'
import Second from './Second.png'
import Third from './Third.png'
import Fourth from './Fourth.png'
import Fifth from './Fifth.png'

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 460,
    zIndex: 120,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}
export interface ITags {
    label: string
    index: string
    onClick: (key: string) => void
}

export const OnBoardingPopup = () => {
    const [isOpen, open, close] = useOpenClose()
    const { t } = useTranslation()

    const [state, setState] = useState<number>(0)
    const images = [First, Second, Third, Fourth, Fifth]

    function getisCampaignManagerOnboardingHidden(): boolean | null {
        const value = localStorage.getItem('isCampaignManagerOnboardingHidden')
        return value ? JSON.parse(value) : null
    }

    function setisCampaignManagerOnboardingHidden(value: boolean): void {
        localStorage.setItem('isCampaignManagerOnboardingHidden', JSON.stringify(value))
    }

    useEffect(() => {
        const isNew = getisCampaignManagerOnboardingHidden()
        if (!isNew) open()
    }, [open])

    const done = () => {
        close()
        const button = document.getElementById('create-new-campaign-ID') as HTMLButtonElement | null
        if (button) {
            button.click()
        }
        setisCampaignManagerOnboardingHidden(true)
    }
    return (
        <>
            {/* <Button onClick={open} variant={'primary'} margin="6px 0 6px 8px">
                <Text color="white">{t('campaign_manager.add_links.btn')}</Text>
            </Button> */}
            {isOpen && (
                <div className={cn.root}>
                    <Popup style={style} isOpen={isOpen} onClose={close}>
                        <Flex className={cn.popup} column>
                            <Flex column style={{ rowGap: '20px' }}>
                                <img src={images[state]} alt="campaign manager" />
                                <Text color="gray-1" fSize="18px" fWeitht={600}>
                                    {t(`campaign_manager.onBoard.${state + 1}.title`)}
                                </Text>
                                <Text color="gray-2" fSize="14px">
                                    {t(`campaign_manager.onBoard.${state + 1}.subTitle`)}
                                </Text>

                                {state !== 4 ? (
                                    <Flex content="space-between">
                                        <Text color="gray-2" fSize="14px">{`${state + 1} ${t(
                                            'campaign_manager.onBoard.of'
                                        )} 5`}</Text>
                                        <Button variant="primary" onClick={() => setState(state + 1)}>
                                            {t(`campaign_manager.onBoard.next`)}
                                        </Button>
                                    </Flex>
                                ) : (
                                    <>
                                        <Button variant="primary" onClick={done}>
                                            {t('campaign_manager.new.btn')}
                                        </Button>
                                    </>
                                )}
                            </Flex>
                        </Flex>
                    </Popup>
                </div>
            )}
        </>
    )
}
