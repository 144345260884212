import { gql } from '@apollo/client'
// import { QueryType } from './types'

interface QueryParams {
    orderBy: string
    offset: number
    filters: string[]
}

export const getTikTokChannelsQuery = (search: string, queryParams: QueryParams) => {
    return gql`
      query searchTikTokWithFilter {
        searchTikTokWithFilter (
            filter: { ${queryParams.filters.join(', ')}}, 
            paging: {limit: 10, skip: ${queryParams.offset}},
            ${queryParams.orderBy}
        ) 
        {
          data
        }
        getUnlockedChannels {
          unlocked_tt_accounts
        }
      }
  `
}

export const GET_TIKOK_GEO = gql`
    query GetGeo {
        getTikTokGeo(limit: 1000) {
            data
        }
    }
`

export const GET_TIKOK_LANG = gql`
    query GetLang {
        getTikTokLang {
            data
        }
    }
`

export const buyTikTokReportsQuery = () => {
    return gql`
        query unhideSearchTikTok($search_result_ids: [String!]) {
            unhideSearchTikTok(search_result_ids: $search_result_ids) {
                data
            }
        }
    `
}
