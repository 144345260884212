import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="17" height="17" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.8334 11.1787C10.9896 11.335 11.2016 11.4228 11.4226 11.4228H11.9109C12.1319 11.4228 12.3438 11.335 12.5001 11.1787L16.4226 7.25616L18.3334 9.16699V4.16699H13.3334L15.2442 6.07783L11.6667 9.65533L9.16672 7.15533C9.01048 6.99903 8.79855 6.91121 8.57756 6.91116H8.08922C7.86823 6.91121 7.6563 6.99903 7.50005 7.15533L1.91089 12.7445L3.08922 13.9228L8.33339 8.67866L10.8334 11.1787Z"
            fill="#5D697D"
        />
    </svg>
)
