import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.5 15.5V2.16667C0.5 1.24619 1.24619 0.5 2.16667 0.5H13.8333C14.7538 0.5 15.5 1.24619 15.5 2.16667V10.5C15.5 11.4205 14.7538 12.1667 13.8333 12.1667H5.5C5.13928 12.166 4.78818 12.283 4.5 12.5L0.5 15.5ZM2.16667 2.16667V12.1667L3.945 10.8333C4.23304 10.6161 4.58423 10.499 4.945 10.5H13.8333V2.16667H2.16667Z"
            fill="#8F8F8F"
        />
    </svg>
)
