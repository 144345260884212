import React, { FC, memo, useCallback } from 'react'
import Text from '@/components/Text'
import Icons from '@/components/Icons'
import { Flex } from '@/_yc'
import cn from './SelectorFilter.module.scss'
import { useTranslation } from 'react-i18next'

export interface IItem {
    label: string
    value: any
    isActive: boolean
    onClick: (value: any) => void
    translate?: boolean
}

const Item: FC<IItem> = ({ onClick, ...props }) => {
    const click = useCallback(() => {
        onClick(props.value)
    }, [onClick, props.value])

    const { t } = useTranslation()

    return (
        <Flex
            align="center"
            content="space-between"
            className={cn.item}
            onClick={click}
        >
            <Text>{props.translate ? t(props.label) : props.label}</Text>
            {props.isActive && <Icons name="done" className={cn.done} />}
        </Flex>
    )
}

export default memo(Item)
