import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.99998 18.3333C9.0795 18.3333 8.33331 17.5871 8.33331 16.6667H11.6666C11.6666 17.5871 10.9205 18.3333 9.99998 18.3333ZM16.6666 15.8333H3.33331V14.1667L4.99998 13.3333V8.75001C4.99998 5.86501 6.18415 3.99417 8.33331 3.48334V1.66667H10.8333C10.2899 2.38662 9.99725 3.26469 9.99998 4.16667C9.99995 4.37615 10.0155 4.58535 10.0466 4.79251H9.99998C8.98319 4.72665 8.00249 5.18138 7.39581 6.00001C6.8771 6.82047 6.62259 7.78032 6.66665 8.75001V14.1667H13.3333V8.75001C13.3333 8.57417 13.3275 8.40501 13.3158 8.25001C13.867 8.36387 14.4355 8.36586 14.9875 8.25584C14.9966 8.43167 15 8.59917 15 8.75584V13.3333L16.6666 14.1667V15.8333ZM14.1666 6.66667C13.6631 6.66728 13.1713 6.51533 12.7558 6.23084C11.774 5.55966 11.4009 4.28968 11.8637 3.19409C12.3265 2.09851 13.4971 1.48066 14.6628 1.7167C15.8285 1.95275 16.6665 2.97734 16.6666 4.16667C16.6666 5.54738 15.5474 6.66667 14.1666 6.66667Z"
            fill="#333333"
        />
        <path
            d="M12.7558 6.23083C13.1712 6.51532 13.6631 6.66728 14.1666 6.66667C15.5473 6.66667 16.6666 5.54738 16.6666 4.16667C16.6665 2.97733 15.8285 1.95274 14.6628 1.7167C13.4971 1.48065 12.3265 2.0985 11.8637 3.19409C11.4009 4.28967 11.7739 5.55965 12.7558 6.23083Z"
            fill="#F7945C"
        />
    </svg>
)
