import React from 'react';

import cn from './Loading.module.scss'
	
const rings = [
	{ delay: "0s", radius: 7 },
	{ delay: ".2s", radius: 13 },
	{ delay: ".4s", radius: 19 },
	{ delay: ".6s", radius: 25 }
]

export default props => (
	<div className = {cn.root}>
		{rings.map((item, i) => (
			<div 
				key = {i}
				style = {{ 
					animationDelay: item.delay,
					width: item.radius,
					height: item.radius,
					left: (25 - item.radius) / 2,
					top: (25 - item.radius) / 2
				}} 
				className = {cn.ring}
			/>
		))}
	</div>
)