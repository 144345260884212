export function Width(space, context) {
	switch (context) {
		case 1:
			return `100%`
		case 2:
			if (space === 1) {
				return `calc(50% - 10px)`
			} else {
				return `100%`
			}
		default:
		case 3:
			if (space === 1) {
				return `calc((100% - 40px)/3)`
			} else if (space === 2) {
				return `calc(((100% - 40px)*2)/3 + 20px)`
			} else if (space === 3) {
				return "100%"
			} else {
				return "100%"
			}
	}
}