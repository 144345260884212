import React from 'react'
import Icons from '@/components/Icons'
import { KeywordsFilter } from '@/pages/SearchPage/SearchAdapter/filters/KeywordsFilter'

import { PLATFORM } from '../../consts'

export const getKeywordsFilter = () => {
    return new KeywordsFilter({
        platform: PLATFORM,
        title: 'filters.search.label',
        icon: <Icons name="search" />,
        helper: 'filters.username.chaining.text',
        placeholder: 'filters.search.placeholder',
        space: 1,
        beforeSend: (value) => ({
            name: 'chaining',
            operator: 'equal',
            type: 'text',
            value: value,
        }),
    })
}
