import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { $getRoot, $getSelection } from 'lexical'
import { $generateNodesFromDOM } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { RootStore } from '@/_helpers/StoreType'

export const UseOnTemplateChoose = () => {
    const currentTemplateId = useSelector((store: RootStore) => store.Outreach.currentTemplate)
    const templates = useSelector((store: RootStore) => store.Outreach.templates)
    const [editor] = useLexicalComposerContext()

    useEffect(() => {
        const currTemplate = templates.find((t) => t.id === currentTemplateId)

        if (!currTemplate) return

        editor.update(() => {
            const dom = new DOMParser()
            const text = dom.parseFromString(currTemplate.message, 'text/html')
            const nodes = $generateNodesFromDOM(editor, text)
            $getRoot().select()

            // Insert them at a selection.
            const selection = $getSelection()
            selection && selection.insertNodes(nodes)
        })
    }, [currentTemplateId, templates, editor])

    return null
}
