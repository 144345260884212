import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

import { RootStore } from '@/_helpers/StoreType'
import Flex from '@/_yc/Flex'
import { Platform } from '@/pages/Reports/types'

import Checkbox from './Checkbox'
import {
    AddToListINST,
    AddToListTwitch,
    AddToListYT,
    AddToListVk,
    AddToListsAud,
    AddToListMediaPlannerPlans,
    AddToListMediaPlannerBloggers,
    AddToListTg,
} from './_model/actions'
import { addToSelectedCampaignsAr } from '@/pages/Outreach/Model/actions'
import { addToSelectedAccounts } from '@/pages/SettingsPage/Model/actions'
import { IBlogger } from './_model/types'

interface ChooseAllProps {
    value?: 'empty' | 'checked' | 'dashed'
}

export const ChooseAll: FC<ChooseAllProps> = ({ value = 'empty' }) => {
    const handleClick = () => {
        //@ts-ignore
        document.querySelectorAll("button[data-selector='mass-add']").forEach((el) => el.click())
    }
    //reduce list of bloggers (ids) based on platform
    return (
        <>
            <Flex onClick={handleClick}>
                <Checkbox value={value} />
            </Flex>
        </>
    )
}
interface ChooseAllPropsNEW {
    value?: 'empty' | 'checked' | 'dashed'
    platform:
        | Platform
        | 'listsAud'
        | 'listsBloggers'
        | 'outreach'
        | 'settings'
        | 'media-empty'
        | 'media'
        | 'media-bloggers'
}

export const ChooseAllNEW: FC<ChooseAllPropsNEW> = ({ value = 'empty', platform }) => {
    const dispatch = useDispatch()
    const allBloggers = useSelector((store: RootStore) => store.MassAddToList.allItems.allBloggers)
    const allListsAud = useSelector((store: RootStore) => store.AudienceInsights.ListsInfo)
    const allListBloggers = useSelector((store: RootStore) => store.AudienceInsights.BloggersAndChannels)
    const outreachCampaings = useSelector((store: RootStore) => store.Outreach.advertisingCampaigns.results)
    const emailAccounts = useSelector((store: RootStore) => store.Settings.emailAccounts)
    const allMediaPlans = useSelector((store: RootStore) => store.MassAddToList.allItems.allMediaPlans)
    const allMediaBloggers = useSelector((store: RootStore) => store.MassAddToList.allItems.allMediaBloggers)

    const handleClick = () => {
        switch (platform) {
            case Platform.Instagram:
                dispatch(AddToListINST(allBloggers))
                return
            case Platform.YouTube:
                dispatch(AddToListYT(allBloggers))
                return
            case Platform.Twitch:
                dispatch(AddToListTwitch(allBloggers))
                return
            case Platform.Vk:
                dispatch(AddToListVk(allBloggers))
                return
            case Platform.Tg:
                dispatch(AddToListTg(allBloggers))
                return
            case 'listsAud':
                dispatch(AddToListsAud(allListsAud))
                return
            case 'outreach':
                dispatch(addToSelectedCampaignsAr(outreachCampaings.map((item) => item.id.toString())))
                return
            case 'settings':
                dispatch(addToSelectedAccounts(emailAccounts.map((item) => item.email)))
                return
            case 'media':
                dispatch(AddToListMediaPlannerPlans(allMediaPlans))
                return
            case 'media-bloggers':
                dispatch(AddToListMediaPlannerBloggers(allMediaBloggers))
                return
            case 'listsBloggers':
                let inst: IBlogger[] = []
                let yt: IBlogger[] = []
                let tw: IBlogger[] = []
                let vk: IBlogger[] = []
                let tg: IBlogger[] = []
                allListBloggers.forEach((item) => {
                    switch (item.type) {
                        case Platform.Instagram:
                            inst.push({
                                id: item.id.toString(),
                                externalId: item.external_id,
                                bloggerId: item.bloggerId,
                                name: item.fullName || '',
                                avatar: item.avatar,
                                username: item.userName,
                                hasEmail: item.has_email,
                                platform: Platform.Instagram,
                            })
                            return
                        case Platform.YouTube:
                            yt.push({
                                id: item.id.toString(),
                                channelId: item.channelId,
                                name: item.fullName || '',
                                avatar: item.avatar,
                                username: item.userName,
                                hasEmail: item.has_email,
                                platform: Platform.YouTube,
                            })
                            return
                        case Platform.Twitch:
                            tw.push({
                                id: item.id.toString(),
                                channelId: item.channelId,
                                name: item.fullName || '',
                                avatar: item.avatar,
                                username: item.userName,
                                hasEmail: item.has_email,
                                platform: Platform.Twitch,
                            })
                            return
                        case Platform.Vk:
                            vk.push({
                                id: item.id.toString(),
                                vk_account_id: item.external_id,
                                name: item.fullName || '',
                                avatar: item.avatar,
                                username: item.userName,
                                hasEmail: item.has_email,
                                platform: Platform.Vk,
                            })
                            return
                        case Platform.Tg:
                            tg.push({
                                id: item.id.toString(),
                                channelId: item.external_id,
                                name: item.fullName || '',
                                avatar: item.avatar,
                                username: item.userName,
                                hasEmail: item.has_email,
                                platform: Platform.Tg,
                            })
                            return
                    }
                })
                dispatch(AddToListINST(inst))
                dispatch(AddToListYT(yt))
                dispatch(AddToListTwitch(tw))
                dispatch(AddToListVk(vk))
                dispatch(AddToListTg(tg))
                return
        }
    }

    return (
        <>
            <Flex onClick={handleClick}>
                <Checkbox value={value} />
            </Flex>
        </>
    )
}
