import React from 'react'
import Popup from '@/_yc/Popup'
import useOpenClose from '@/_helpers/useOpenClose'
import Icons from '@/components/Icons'
import cn from './Styles.module.scss'
import Text from '@/components/Text'

export const IncorrectLink = () => {
    const [isOpen, open, close] = useOpenClose()
    const icon = React.useRef<HTMLDivElement>(null)

    return (
        <>
            <div className={cn.alert} ref={icon} onMouseOver={open} onMouseLeave={close}>
                <Icons name="alert-triangle" />
            </div>

            <Popup
                isOpen={isOpen}
                onClose={close}
                placement={'top'}
                anchor={icon.current}
                boundariesElement={'window'}
                style={{ zIndex: 15 }}
            >
                <div
                    style={{
                        // maxHeight: '100px',
                        maxWidth: '600px',
                        borderRadius: '4px',
                        padding: '10px 12px',
                        marginBottom: '6px',
                        backgroundColor: '#5D697D',
                    }}
                >
                    <Text fSize={10} color={'white'}>
                        Incorrect link provided
                    </Text>
                </div>
            </Popup>
        </>
    )
}
