import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import cn from './LoadBar.module.scss'

interface ILoadBar {
    status: number
}
const LoadBar: FC<ILoadBar> = ({ status }) => {
    const { t } = useTranslation()
    return (
        <div className={cn.root}>
            <div style={{ width: `${(status * 100).toFixed(1)}%` }} className={cn.bar} />
            <div className={cn.label}>
                {t('aud_insides.found_blogger.parsing', { count: Math.round(status * 100) })}
            </div>
        </div>
    )
}

export default memo(LoadBar)
