import React from 'react'

import Icons from '@/components/Icons'
import { SelectorFilter } from '../../../../filters/SelectorFilter'
import { PLATFORM } from '../consts'

const getGqlFilter = (ageGroup: Array<string>, percentage: number) => {
    if (!ageGroup || !percentage) return ''
    return `audience_agender: {group: {_eq: "${ageGroup[0]}"}, total_share: {_gte: "${percentage / 100}"}}`
}

const options = [
    { label: '0-17', value: '0-17' },
    { label: '18-24', value: '18-24' },
    { label: '25-34', value: '25-34' },
    { label: '35-44', value: '35-44' },
    { label: '45-54', value: '45-54' },
    { label: '>54', value: '55-999' },
]

export const getAudAgeFilter = () => {
    return new SelectorFilter({
        platform: PLATFORM,
        locked: (features: string[]) => !features?.includes('audience_age_gender_yt'),
        title: 'filters.age.audience.label',
        helper: 'filters.age.audience.text',
        icon: <Icons name="filter--age" />,
        space: 1,
        hasPercentage: true,
        featureKey: 'ageAudienceFilter',
        options: options,
        beforeSend: ({ value, percentage }) => {
            return {
                name: 'audience.age',
                type: '',
                value: value,
                gqlFilter: getGqlFilter(value, percentage),
            }
        },
    })
}
