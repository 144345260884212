const normalized = (props, propName, componentName, required) => {
	if (required && !props[propName] && props[propName] !== 0) {
		return new Error(`Prop "${propName}" is required in "${componentName}" component`);
	}
	const type = typeof(props[propName]);
	if (type !== "number") {
		return new Error(`Prop "${propName}" must be a number, but type ${type} recieved`);
	}
	if (props[propName] >= 1 || props[propName] < 0) {
		return new Error(`Prop "${propName}" must be greater than 0 and less than or equal to 1. ${props[propName]} recieved`);
	}
}

const Export = (...args) => normalized(...args);
Export.isRequired = (...args) => normalized([...args, true])

export { Export as default }