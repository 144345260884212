import { NativeReportData } from './Types'
import {
    LookALineList,
    ErSpread,
    BrandAffinityList,
    AQDonut,
    ReachBar,
    // NotableFollowersList,
    AgeGenderMultiBar,
    GenderDonut,
    CityHorizontal,
    CountryHorizontal,
    LanguageHorizontal,
    GrowGraph,
    Tags,
} from './ReadyCards'

export default (src: NativeReportData) => {
    return [
        GrowGraph(src),
        {
            type: 'twoTailed',
            content: [
                {
                    type: 'container',
                    space: 1,
                    direction: 'col',
                    content: [Tags(src)],
                },
                LookALineList(src),
            ],
        },
        ...[src.erSpread.values.length > 0 && ErSpread(src)],
        {
            type: 'twoTailed',
            content: [
                src.audienceQuality.values.length > 0 && AQDonut(src),
                src.audienceReach.values.length > 0 && ReachBar(src),
            ],
        },
        {
            type: 'twoTailed',
            content: [BrandAffinityList(src)],
            // content: [BrandAffinityList(src), NotableFollowersList(src)],
        },
        {
            type: 'twoTailed',
            content: [
                src.ageGenderSpread.values.length > 0 && AgeGenderMultiBar(src),
                {
                    type: 'container',
                    space: 1,
                    direction: 'col',
                    content: [
                        src.genderSplit.values.length > 0 && GenderDonut(src),
                        src.cities.values.length > 0 && CityHorizontal(src),
                    ],
                },
            ],
        },
        {
            type: 'twoTailed',
            content: [CountryHorizontal(src), LanguageHorizontal(src)],
        },
    ]
}
