import React, { FC } from 'react'

import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { Flex, Text } from '@/_yc'
import useOpenClose from '@/_helpers/useOpenClose'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import CalendlyWidget from '@/components/Share/CalendlyWidget'

import cn from './RequireDemoButton.module.scss'

export interface IRequireDemoCalendly {
    margin?: string
}

const RequireDemoCalendly: FC<IRequireDemoCalendly> = (props) => {
    const t = useSpaceTranslation('require_demo')
    const size = useWindowWidth('mobile')
    const [isOpen, open, close] = useOpenClose()

    return (
        <>
            <CalendlyWidget isOpen={isOpen} close={close} />
            <Flex margin={props.margin} content="center">
                <div className={size === 'mobile' ? cn['root--mobile'] : cn.root} onClick={open}>
                    <Text color="lightGreen" semibold>
                        {t('require')}
                    </Text>
                </div>
            </Flex>
        </>
    )
}
export default RequireDemoCalendly
