import React, { SVGAttributes } from 'react'
const TwitchIcon = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="18" height="18" rx="9" fill="#8651F6" stroke="white" strokeWidth="2" />
        <path
            d="M7.375 5.2002L5.5 7.02162V13.5788H7.75V15.4002L9.625 13.5788H11.125L14.5 10.3002V5.2002H7.375ZM13.75 9.93591L12.25 11.3931H10.75L9.4375 12.6681V11.3931H7.75V5.92877H13.75V9.93591Z"
            fill="white"
        />
        <path d="M12.625 7.20377H11.875V9.38948H12.625V7.20377Z" fill="white" />
        <path d="M10.5625 7.20377H9.8125V9.38948H10.5625V7.20377Z" fill="white" />
    </svg>
)

export default TwitchIcon
