import { getCountries } from '@/pages/SearchPage/SearchAdapter/bloggers/youtube/queries'
import { ICountriesQuery } from '@/pages/SearchPage/SearchAdapter/bloggers/youtube/types'
import { preprocessCountries } from '@/pages/SearchPage/SearchAdapter/bloggers/youtube/utils/filters/prepocess'
import { getLang } from '@/_helpers/getLang'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { SelectorFilter } from '../../../../filters/SelectorFilter'

const getGqlFilter = (countries: string[]) => {
    if (!countries || countries.length === 0) return ''

    return `countryId:  {in: [${countries.map((c) => '"' + c + '"').join(',')}]}`
}

export const getCountryFilter = (fetcher: Fetcher) => {
    return new SelectorFilter({
        platform: 'ads_yt',
        title: 'ads_explorer.filters.post_metrics.country.label',
        autocomplete: true,
        helper: 'ads_explorer.filters.post_metrics.country.text',
        space: 1,
        isMulti: true,
        options: async (token: string) => {
            const client = fetcher.getClient()
            return await client.query<ICountriesQuery>({ query: getCountries(getLang()) }).then((res) => {
                const countries = res.data?.youtube_countriestranslate
                return preprocessCountries(countries)
            })
        },
        beforeSend: ({ value }) => {
            return {
                name: 'location.country',
                type: 'array',
                value: value,
                gqlFilter: getGqlFilter(value),
            }
        },
    })
}
