import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.5 15.5H1.33333C0.873096 15.5 0.5 15.1269 0.5 14.6667V0.5H2.16667V13.8333H15.5V15.5ZM4.9775 11.3333L3.83333 10.2133L7.88 6.25C8.19856 5.9404 8.70561 5.9404 9.02417 6.25L10.88 8.0675L14.3558 4.66667L15.5 5.78667L11.4533 9.75C11.1348 10.0596 10.6277 10.0596 10.3092 9.75L8.4525 7.93167L4.97833 11.3333H4.9775Z"
                fill="#5D697D"
            />
        </svg>
    )
}
