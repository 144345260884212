import React, { FC, useRef } from 'react'

import Text from '@/components/Text'
import cn from './TopicsPopup.module.scss'
import useOpenClose from '@/_helpers/useOpenClose'
import Popup from '@/_yc/Popup'

interface TopicsPopupProps {
    topics: Array<string>
}
export const TopicsPopup: FC<TopicsPopupProps> = ({ topics }) => {
    const [isOpen, open, , toggle] = useOpenClose()
    const ref = useRef(null)
    return (
        <div className={cn.topicsPopupContainer}>
            <div onClick={open} ref={ref} className={cn.count}>
                <Text fSize={10} color="gray-new-2">
                    +{topics.length}
                </Text>
            </div>

            {isOpen && <div className={cn.arrow} />}
            <Popup style={{ zIndex: 20 }} anchor={ref.current} isOpen={isOpen} onClose={toggle} placement="top">
                <div className={cn.topicsPopup}>
                    {topics.map((topic) => (
                        <Text className={cn.chip} fSize={10} color="gray-new-2">
                            {topic}
                        </Text>
                    ))}
                </div>
            </Popup>
        </div>
    )
}
