import React from 'react'
import { useSelector } from 'react-redux'

import { RootStore } from '@/_helpers/StoreType'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import Donut from '@/Layouts/ReportLayout/Content/ResuableWidgets/DonutChart/Donut'
import Flex from '@/_yc/Flex'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import { Mock } from '../Mock/Mock'

import cn from './Activity.module.scss'

const Activity = () => {
    const t = useSpaceTranslation('report_layout')

    const activity = useSelector((store: RootStore) => {
        if (
            store.report.rawVkData?.getVkReport?.data.vk_account &&
            store.report.rawVkData?.getVkReport?.data.vk_account.length > 0
        ) {
            return store.report.rawVkData?.getVkReport?.data.vk_account[0].vk_audience_activity
        } else return undefined
    })
    const devices = useSelector((store: RootStore) => {
        if (
            store.report.rawVkData?.getVkReport?.data.vk_account &&
            store.report.rawVkData?.getVkReport?.data.vk_account.length > 0
        )
            return store.report.rawVkData?.getVkReport?.data.vk_account[0].vk_audience_devices
        else return undefined
    })

    const activityData = [
        {
            value: activity?.visit_last_1_day || 0,
            color: '#6171ff',
            label: t('activity.visit_last_1_day'),
            label_color: '#323841',
            percent_color: 'white',
        },
        {
            value: activity?.visit_last_7_days || 0,
            color: '#acb5ff',
            label: t('activity.visit_last_7_days'),
            label_color: '#323841',
            percent_color: 'white',
        },
        {
            value: activity?.visit_last_30_days || 0,
            color: '#d6dbe6',
            label: t('activity.visit_last_30_days'),
            label_color: '#323841',
            percent_color: 'white',
        },
        {
            value: activity?.visit_last_30_days_ago || 0,
            color: '#e6eaf0',
            label: t('activity.visit_last_30_days_ago'),
            label_color: '#323841',
            percent_color: 'white',
        },
    ]
    const isActivityAvailable = () => {
        return (activity?.visit_last_1_day || 0) +
            (activity?.visit_last_7_days || 0) +
            (activity?.visit_last_30_days || 0) +
            (activity?.visit_last_30_days_ago || 0) ===
            0
            ? false
            : true
    }
    const getMockActivity = () => {
        return activityData.map((item, i) => {
            return {
                ...item,
                value: i + 1 === activityData.length ? 30 : 1,
            }
        })
    }

    const devicesData = [
        {
            value: devices?.web_share || 0,
            color: '#6171ff',
            label: t('devices.web_share'),
            label_color: '#323841',
            percent_color: 'white',
        },
        {
            value: devices?.iphone_share || 0,
            color: '#acb5ff',
            label: t('devices.iphone_share'),
            label_color: '#323841',
            percent_color: 'white',
        },
        {
            value: devices?.android_share || 0,
            color: '#10BF61',
            label: t('devices.android_share'),
            label_color: '#323841',
            percent_color: 'white',
        },
    ]
    const isDevicesAvailable = () => {
        return (devices?.android_share || 0) +
            (devices?.iphone_share || 0) +
            (devices?.web_share || 0) +
            (activity?.visit_last_30_days_ago || 0) ===
            0
            ? false
            : true
    }
    const getMockDevices = () => {
        return devicesData.map((item, i) => {
            return {
                ...item,
                value: i + 1 === devicesData.length ? 30 : 1,
                color: i + 1 === devicesData.length ? '#e6eaef' : item.color,
            }
        })
    }

    const size = useWindowWidth('mobile')
    const isMobile = size === 'mobile'

    return (
        <>
            <Flex content="space-between" wrap margin={'20px 0 0 0'}>
                <Flex className={cn.root} column>
                    <p className={cn.title}>{t('activity.title')}</p>
                    <Flex content="center">
                        <Donut
                            radius={90}
                            data={isActivityAvailable() ? activityData : getMockActivity()}
                            isLarge={true}
                            isLoading={false}
                        />
                    </Flex>
                    {isActivityAvailable() ? (
                        <Flex wrap>
                            {activityData.map((label) => (
                                <Flex className={cn.legend} column>
                                    <Flex align="center">
                                        <div className={cn.dot} style={{ backgroundColor: label.color }} />
                                        <p className={cn['legend-percent']}>{(label.value * 100).toFixed(1)}%</p>
                                    </Flex>
                                    <p className={cn['legend-text']}>{label.label}</p>
                                </Flex>
                            ))}
                        </Flex>
                    ) : (
                        <Mock />
                    )}
                </Flex>
                <Flex
                    className={cn.root}
                    column
                    style={{ margin: isMobile ? '20px 0 0 0' : '' }}
                    content="space-between"
                >
                    <Flex column>
                        <p className={cn.title}>{t('devices.title')}</p>
                        <Flex content="center">
                            <Donut
                                radius={90}
                                data={isDevicesAvailable() ? devicesData : getMockDevices()}
                                isLarge={true}
                                isLoading={false}
                            />
                        </Flex>
                    </Flex>

                    <Flex wrap>
                        {isDevicesAvailable() ? (
                            devicesData.map((label) => (
                                <Flex className={cn['legend-devices']} column>
                                    <Flex align="center">
                                        <div className={cn.dot} style={{ backgroundColor: label.color }} />
                                        <p className={cn['legend-percent']}>{(label.value * 100).toFixed(1)}%</p>
                                    </Flex>
                                    <p className={cn['legend-text']}>{label.label}</p>
                                </Flex>
                            ))
                        ) : (
                            <Mock />
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}
export default Activity
