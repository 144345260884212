import React, { FC } from 'react'

import Text from '@/components/Text'
import Flex from '@/_yc/Flex'
import cn from './Email.module.scss'
import { Icons } from '@/_yc/Icons'

export interface EmailProps {
    email?: string
}

const Email: FC<EmailProps> = ({ email }) => {
    if (!email) {
        return null
    }
    return (
        <Flex content="left" className={cn.Email}>
            <Icons name={'email'} context="report" className={cn['Email--icon']} />
            <Text semibold className={cn['Email--text']} color="gray-4">
                {email}
            </Text>
        </Flex>
    )
}

export default Email
