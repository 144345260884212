import React, { FC, ReactNode, useRef, useState, useEffect, useMemo } from 'react'

import Header from './Header/Header'

import Flex from '@/_yc/Flex'
import { useTranslation } from 'react-i18next'
import Text from '@/components/Text'
import GlobalWidth from '@/_yc/GlobalWidth'
import { service } from './service'

import Context, { IAudienceLayoutContext as IContext } from '../AudienceLayout/AudienceLayoutContext'
import useClass from '@/_helpers/Hooks/useClass'
import PageLayout from '../PageLayout'
import cn from './ReportLayout.module.scss'
import { Link } from 'react-router-dom'
import { SEARCH } from '@/routs'
import Arrow from './Header/Arrow'
import { ExtraContent } from '../AudienceLayout'
import PDFBar from '@/pages/Reports/BloggerReport/PDFBar'
import notif from '@/components/Notifier/model/actions.js'

import { ContextMenu } from '@/_yc'

import Segment from './Content/Segment'
import PartChanger from './Extra/PartChanger'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import Demographics from './Content/Demographics'
import VideosBlock from './Content/Videos/VideosBlock'
import ReportHeader from './Content/ReportHeader'
import SimilarAccounts from './Content/SimilarAccounts'
import AllocationGraph from './Content/AllocationGraph/AllocationGraph'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import BrandMentions from './Content/BrandMentions'
import GrowGraph from './Content/GrowGraph'
import { FileDownloader } from '@/_helpers/FileDownloader'
import Loader from '@/components/Loader'
import { Platform } from '@/pages/Reports/types'
import { OnParsingLoader } from '@/pages/Reports/BloggerReport/Cards'

export type ExpectedData = {
    id: string
    avatar: string
    title: string
    metrics: Array<{ name: string; value: number }>
    engagement: number
    country: string
    topics: string[]
}

export interface IReportLayout {
    loading: boolean
    children?: ReactNode
    error?: boolean
    graphLoading?: boolean
    classes?: {
        container?: string
        content?: string
    }
    title?: string
    platform?: 'YouTube' | 'Instagram'
}

const addToListStyle = {
    width: 170,
    height: 40,
    color: '#6071ff',
    fontSize: 12,
    padding: '0 19px',
    textAlign: 'center',
}

const ReportLayout: FC<IReportLayout> = ({ title = 'titles.t2', ...props }) => {
    const { t } = useTranslation()

    const [isPDFLoading, setIsPDFLoading] = useState(false)
    const dispatch = useDispatch()

    const ref = useRef<any>(null)
    const screenSize = useWindowWidth('mobile')

    const graphRef = useRef(null)
    const allocationRef = useRef(null)
    const reportData = useSelector((store: RootStore) => store.report.info)
    const videosRef = useRef(null)
    const demographicsRef = useRef(null)
    const similarRef = useRef(null)
    const brandMentionsRef = useRef(null)

    const [target, setTarget] = useState<null | IContext>(null)
    const root = useClass(cn.root, props.classes?.container)
    const goBackLink = `${SEARCH}${props.platform && `/${props.platform}`}`

    useEffect(() => {
        if (!ref.current) return

        setTarget({
            container: ref.current,
        })

        return () => {
            setTarget(null)
        }
    }, [])

    const channelMetrics = useSelector((store: RootStore) => {
        return store.report.rawChannelData.getChannel?.data
    })

    const isDemographicsAvailable = useMemo(() => {
        return (
            !!channelMetrics?.youtube_channel[0]?.channel_audience?.data?.age_gender ||
            channelMetrics?.youtube_channel[0]?.audience_countries.length !== 0 ||
            channelMetrics?.youtube_channel[0]?.audience_languages.length !== 0
        )
    }, [channelMetrics])

    const downloadPDF = () => {
        const channelID = channelMetrics?.youtube_channel[0].id
        const pdfTitle = channelMetrics?.youtube_channel[0].title
        setIsPDFLoading(true)

        // Ignoring because ts does not see dynamically defined fields in APIService
        service
            // @ts-ignore
            .getPdf(channelID)
            .then((pdf: any) => {
                FileDownloader(pdf, pdfTitle)
                setIsPDFLoading(false)
            })
            .catch((err: any) => {
                dispatch(notif.error(t('report_layout.pdf.error')))
                setIsPDFLoading(false)
            })
    }

    const isOnParsing = useMemo(() => {
        return channelMetrics?.channel_updating
    }, [channelMetrics])

    return (
        <Context.Provider value={target}>
            <PageLayout color={'common-background'} className={root} title={t(title)}>
                <ExtraContent>
                    <Link to={goBackLink}>
                        <Flex grow align="center" content="space-between">
                            <Arrow className={cn.arrow} />
                            <Text margin={'0 0 0 10px'} color="gray-3">
                                {t('report_layout.go_back')}
                            </Text>
                        </Flex>
                    </Link>
                    <Flex>
                        <div className={cn.addToList}>
                            <ContextMenu
                                t={t}
                                reportButton
                                style={addToListStyle}
                                margin={'0 20px 0 0'}
                                username={channelMetrics?.youtube_channel[0].title}
                                id={channelMetrics?.youtube_channel[0].id}
                                type={'youtube'}
                            />
                        </div>
                        <PDFBar margin="0 0 0 15px" onClick={downloadPDF} loading={isPDFLoading} />
                    </Flex>
                </ExtraContent>

                <Header title={title}>
                    <Flex grow align="center" content={'space-between'} ref={ref}></Flex>
                </Header>

                <div className={cn.over} />

                {props.loading && !props.error && (
                    <Flex margin="20px 0" align={'center'} content="center">
                        <Loader />
                    </Flex>
                )}

                {props.error && (
                    <Text type="h2" size={22} color="secondary" align="center">
                        {t('common_error')}
                    </Text>
                )}

                {!props.loading && reportData && !props.error && (
                    <GlobalWidth className={cn.GlobalWidth}>
                        <div className={cn.main}>
                            <ReportHeader platform={Platform.YouTube} />

                            {screenSize !== 'mobile' && (
                                <PartChanger
                                    values={[
                                        {
                                            title: t('report_layout.graph.title'),
                                            value: graphRef,
                                        },

                                        ...(isDemographicsAvailable || isOnParsing
                                            ? [
                                                  {
                                                      title: t('report_layout.demographics.title'),
                                                      value: demographicsRef,
                                                  },
                                              ]
                                            : []),
                                        {
                                            title: t('report_layout.brand_mentions.title'),
                                            value: brandMentionsRef,
                                        },
                                        {
                                            title: t('report_layout.content_analytics'),
                                            value: allocationRef,
                                        },
                                        ...(channelMetrics?.similar_accounts?.youtube_channel?.length
                                            ? [
                                                  {
                                                      title: t('report_layout.similar_accounts.title'),
                                                      value: similarRef,
                                                  },
                                              ]
                                            : []),
                                        {
                                            title: t('report_layout.videos.title'),
                                            value: videosRef,
                                        },
                                    ]}
                                />
                            )}

                            <Segment ref={graphRef} title={t('report_layout.graph.title')}>
                                <GrowGraph />
                            </Segment>

                            <Segment ref={demographicsRef} title={t('report_layout.demographics.title')}>
                                {isOnParsing && (
                                    <div style={{ margin: '10px 0 0 0' }}>
                                        <OnParsingLoader data={0.5} />
                                    </div>
                                )}
                                {isDemographicsAvailable && <Demographics />}
                            </Segment>
                            {channelMetrics?.youtube_channel[0]?.brand_mentions?.length !== 0 && (
                                <Segment ref={brandMentionsRef} title={t('report_layout.brand_mentions.title')}>
                                    <BrandMentions />
                                </Segment>
                            )}
                            <Segment ref={allocationRef} title={t('report_layout.content_analytics')}>
                                <AllocationGraph />
                            </Segment>

                            {channelMetrics?.similar_accounts?.youtube_channel?.length !== 0 && (
                                <Segment ref={similarRef} title={t('report_layout.similar_accounts.title')}>
                                    <SimilarAccounts />
                                </Segment>
                            )}
                            <Segment ref={videosRef} title={t('report_layout.videos.title')}>
                                <VideosBlock />
                            </Segment>
                        </div>
                    </GlobalWidth>
                )}
            </PageLayout>
        </Context.Provider>
    )
}

export default ReportLayout
