import React, { FC, useState } from 'react'

import { Flex } from '@/_yc'
import Text from '@/components/Text'
import cn from './MostViewed.module.scss'

import { useDispatch, useSelector } from 'react-redux'

import { RootStore } from '@/_helpers/StoreType'
import { useEffect } from 'react'
import { setVideosFilter, setVideosFilterViews } from '@/Layouts/ReportLayout/Model/actions'
import { useSpaceTranslation } from '@/_helpers'

export interface IMostViewed {}

const MostViewed: FC<IMostViewed> = () => {
    const t = useSpaceTranslation('report_layout.videos.controls')

    const [isActive, setIsActive] = useState<boolean>(false)
    const dispatch = useDispatch()

    const videoFilter = useSelector((store: RootStore) => {
        return store.report.videos.filter
    })

    const viewsFilter = useSelector((store: RootStore) => {
        return store.report.videos.viewsFilter
    })

    useEffect(() => {
        setIsActive(videoFilter === 'views' && viewsFilter === 'most_viewed')
    }, [videoFilter, viewsFilter])

    const onClick = () => {
        dispatch(setVideosFilter('views'))
        dispatch(setVideosFilterViews('most_viewed'))
    }

    return (
        <Flex align="center">
            <Text
                margin="0 20px 0 0"
                tAlign="center"
                className={`${cn['text']} ${isActive ? cn['text--active'] : ''}`}
                onClick={onClick}
            >
                {t('most_viewed')}
            </Text>
        </Flex>
    )
}

export default MostViewed
