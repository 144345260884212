import Service from '@/_helpers/Service'

export interface ICheckEmailData {
    body: string
    email_to: string
    email_acc_id: number
}
const CheckEmail = new Service<any>({
    method: 'POST',
    url: 'core/outreach/send_email_message/',
})
export const CheckEmailReq = (token: string, data: ICheckEmailData) =>
    CheckEmail.call(
        {
            data: data,
        },
        token
    )
