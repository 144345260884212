import React, { FC, useEffect, useMemo, useState } from 'react'
import LoadingButton from '@/components/Share/LoadingButton/LoadingButton'

import cn from './DownloadListModal.module.scss'
import useOpenClose from '@/_helpers/useOpenClose'
import Popup from '@/_yc/Popup'

import Text from '@/components/Text'
import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'
import { selectLocker, useSpaceTranslation } from '@/_helpers'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import { LockButton } from '@/_yc/LockButton'
import DownloadButton from '../DownloadButton'
import { DownloadParams } from '@/pages/SearchPage/AdsExplorer/Header/DownloadExcel/DownloadParams'
import { getListLockedCountReq } from '../../../model/service'
import useToken from '@/_helpers/Hooks/useToken'
import { setListLockedCount } from '../../../model/actions'

interface DownloadListModalProps {}

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 460,
    zIndex: 2000,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}

export const DownloadListModal: FC<DownloadListModalProps> = () => {
    const t = useSpaceTranslation('aud_insides.download_modal')

    const activeListId = useSelector((store: RootStore) => store.AudienceInsights.ActiveListId)
    const ListInfo = useSelector((store: RootStore) => store.AudienceInsights.ListsInfo)
    const curList = ListInfo.find((list) => list.id === activeListId)

    const reportsCount = useSelector((store: RootStore) => store.HeaderMenu_.ReportCounter_.reportsCount)

    const lockedBloggersCount = useSelector((store: RootStore) => store.AudienceInsights.listLockedCount)
    const token = useToken()
    const dispatch = useDispatch()

    const [countLoading, setCountLoading] = useState<boolean>(false)

    const [isOpen, open, close] = useOpenClose()

    useEffect(() => {
        if (!token) return

        if (!isOpen || activeListId === -1) return

        setCountLoading(true)
        getListLockedCountReq(token, activeListId).then((res) => {
            dispatch(setListLockedCount(res.count))
            setCountLoading(false)
        })
    }, [token, activeListId, isOpen, dispatch])

    const disabled = activeListId === -1

    const isLocked = useSelector(selectLocker('csv'))

    const notEnoughFunds = useMemo(() => {
        if (reportsCount === null) {
            return false
        }

        return lockedBloggersCount > reportsCount
    }, [lockedBloggersCount, reportsCount])

    return (
        <>
            {isLocked && (
                <Flex margin=" 0 10px 0 0">
                    <LockButton light>
                        <LoadingButton disabled style={{ fontSize: '12px', lineHeight: '16px' }}>
                            {t('open_btn')}
                        </LoadingButton>
                    </LockButton>
                </Flex>
            )}

            {!isLocked && (
                <LoadingButton
                    onClick={open}
                    disabled={disabled}
                    margin=" 0 10px 0 0"
                    style={{ fontSize: '12px', lineHeight: '16px' }}
                >
                    {t('open_btn')}
                </LoadingButton>
            )}

            {isOpen && (
                <div className={cn.root}>
                    <Popup style={style} isOpen={isOpen} onClose={close}>
                        <div className={cn.popup}>
                            <div onClick={close} className={cn.close}>
                                <Icons name="close--small" />
                            </div>

                            <Text fSize={18}>{t('title')}</Text>

                            <Text fSize={14} color={'gray-3'} margin={'20px 0 0 0'}>
                                {t('subtitle')}
                            </Text>

                            <DownloadParams
                                numPosts={curList?.qty || 0}
                                numTokens={lockedBloggersCount}
                                translate={t}
                                costLoading={countLoading}
                            />

                            <Flex className={cn.footer}>
                                <Flex column content="flex-start">
                                    <Flex>
                                        <Text fSize={12} color={'gray-4'}>
                                            {t('flags')}
                                        </Text>
                                        <Text
                                            fSize={12}
                                            color={notEnoughFunds ? 'red-2' : 'gray-2'}
                                            margin={'0 0 0 6px'}
                                        >
                                            {reportsCount}
                                        </Text>

                                        <Text
                                            fSize={12}
                                            color={notEnoughFunds ? 'red-2' : 'gray-2'}
                                            margin={'0 0 0 6px'}
                                        >
                                            {t('tokens').toLowerCase()}
                                        </Text>
                                        <Icons name={'flag'} className={notEnoughFunds ? cn['icon--red'] : cn.icon} />
                                    </Flex>
                                    {notEnoughFunds && (
                                        <Text fSize={10} color={'red-2'}>
                                            {t('not_enough')}
                                        </Text>
                                    )}
                                </Flex>
                                <DownloadButton />
                            </Flex>
                        </div>
                    </Popup>
                </div>
            )}
        </>
    )
}
