import constants from './constants';
import { push } from '../PushNotification';
import LegacyNotification from '../MessageTypes/LegacyNotification';
 
export const snackActions = {
	success: (payload) => () => push(new LegacyNotification(payload, "ok")),
	error: (payload) => () => push(new LegacyNotification(payload, "error")),
	info: payload => () => undefined, //push(new LegacyNotification(payload, "info")),
	warning: payload => () => push(new LegacyNotification(payload, "error")),
	clear: () => ({ type: constants.SNACK.CLEAR }),
	loading: payload => () => push(new LegacyNotification(payload, "info"))
}

export default snackActions