import React, { useCallback } from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PopNavigator } from './PopNavigator/PopNavigator.js'
import { AuthMenu } from './AuthMenu/AuthMenu.js'
import { LanguageSwitcher } from './LanguageSwitcher/LanguageSwitcher.js'
import { SEARCH } from '@/routs.js'

import SoonMark from './SoonMark.js'
import NewMark from './NewMark.js'

import HeaderLogo from './HeaderLogo.js'
// import logo from '../_yc/Icons/pageLogo.png'

import cn from './HeaderMenu.module.scss'
import NotificationBell from './NotificationBell';


const container = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end'
}

const HeaderMenu = props => {

	const { t } = useTranslation();
	const linkClick = useCallback(e => e.preventDefault(), [])
	return(
	<div className = {cn.root} >
		<Link to={SEARCH} className = {cn.yolo} >
			<HeaderLogo />
		</Link>
		{props.xs ?
			<div
				style = {container}
			>
				<LanguageSwitcher 
					color = {props.color} 
					xs={props.xs} 
				/>
				<NotificationBell />
				<PopNavigator color = {props.color} options = {props.menuOptions} />
				<div className = {cn.user}>
					<div className = {cn.user_name}>
						<AuthMenu color = {props.color} xs={props.xs} />
					</div>
				</div>
			</div> : 
			<React.Fragment>
				<div className = {cn.menu_bar}>
					{props.menuOptions.map((item, index) =>(
						<Link 
							to={item.route}
							key={index}
							onClick={item.disabled ? linkClick : undefined}
							className = {
								item.disabled ? cn["option--disabled"] :
								window.location.pathname===item.route ? cn["option--active"] :
							 	cn["option--default"]
							}
						>
							{t(item.label)}
							{item.soon && <SoonMark className={cn.mark} />}
							{item.new && <NewMark className={cn.mark} />}
						</Link>
					))}
				</div>
				<div className = {cn.user}>
					<LanguageSwitcher 
						color = {props.color} 
						xs={props.xs} 
					/>
					<NotificationBell />
					<div className = {cn.user_name}>
						<AuthMenu xs={props.xs} />
					</div>
				</div>
			</React.Fragment>
		}
	</div>
)}

export { HeaderMenu }