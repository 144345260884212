import Service from '@/_helpers/Service'

interface UserInfo {
    firstName: string
    lastName: string
    email: string
    phone: string
}
const saveUserInfo = new Service('')
export const saveUserInfoReq = (token: string, data: UserInfo) => {
    return saveUserInfo.call(
        {
            method: 'POST',
            url: '/core/accounts/profile/',
            data: {
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                phone: data.phone,
            },
        },
        token
    )
}

interface PasswordInfo {
    oldPassword: string
    newPassword: string
    newPasswordRepeat: string
}
const changePassword = new Service('')
export const changePasswordReq = (token: string, data: PasswordInfo) => {
    return changePassword.call(
        {
            method: 'POST',
            url: '/core/accounts/change-password/',
            data: {
                old_password: data.oldPassword,
                password: data.newPassword,
                password_confirm: data.newPasswordRepeat,
            },
        },
        token
    )
}

const deleteAccount = new Service('')
export const deleteAccountReq = (token: string, email: string) => {
    return deleteAccount.call(
        {
            method: 'DELETE',
            url: '/core/outreach/accounts/deactivate/',
            data: {
                email: email,
            },
        },
        token
    )
}
