import { LISTS } from '@/routs'
import { T } from '@/_helpers/useSpaceTranslation'
import { Sender } from '../types'
import img1 from './TutorialImages/1.jpg'
import img2 from './TutorialImages/2.jpg'
import img3 from './TutorialImages/3.jpg'
import img4 from './TutorialImages/4.jpg'

export interface ChatButton {
    text: string
    toSteps: [number]
    callback: (prams: any) => any
}

export interface YolocoChatMessage {
    sender: Sender
    text?: string
    img?: string
    buttons?: ChatButton[]
    step?: number
    time?: string
    id: number
}

export const getTutorial = (t: T, curTime: string, name?: string | null) => {
    return [
        {
            sender: 'blogger',
            text: `Hello${
                name ? ', ' + name : ''
            }! I'm your assistant from Yoloco.\n\nLet's create your first advertising campaign through influencers in just two steps`,
            step: 1,
            time: curTime,
            id: 0,
        },
        {
            sender: 'blogger',
            text: 'I will show you how to create effective advertising campaigns. Is it interesting for you?',
            step: 2,
            buttons: [
                {
                    text: 'Thanks, maybe later...',
                    toSteps: [-1],
                },
                {
                    text: '🎉 Yes, I have a couple of minutes!',
                    toSteps: [3, 4],
                },
            ],
            time: curTime,
            id: 1,
        },
        {
            sender: 'blogger',
            text: 'Ok, I will be here! When you will be ready I will tell you about the service.',
            step: -1,
            buttons: [
                {
                    text: "I'm ready to start learning",
                    toSteps: [3, 4],
                },
            ],
            time: curTime,
            id: 2,
        },
        {
            sender: 'blogger',
            img: img1,
            step: 3,
            time: curTime,
            id: 3,
        },
        {
            sender: 'blogger',
            text: 'In the first step, you will find the influencers you are interested in: we have more than 30 filters to make your search the most convenient and accurate',
            step: 4,
            buttons: [
                {
                    text: "I got you! What's next?",
                    toSteps: [5, 6],
                },
            ],
            time: curTime,
            id: 4,
        },
        {
            sender: 'blogger',
            img: img2,
            step: 5,
            time: curTime,
            id: 5,
        },
        {
            sender: 'blogger',
            text: 'Good! After finding influencers you need select them in the table and send it to Outreach service',
            step: 6,
            buttons: [
                {
                    text: "Easy! What's next?",
                    toSteps: [7, 8],
                },
            ],
            time: curTime,
            id: 6,
        },
        {
            sender: 'blogger',
            img: img3,
            step: 7,
            time: curTime,
            id: 7,
        },
        {
            sender: 'blogger',
            text: 'Super, final step! Create a letter template and send it to influencers you find before 🚀',
            step: 8,
            buttons: [
                {
                    text: 'Looking good, thanks!',
                    toSteps: [9, 10, 11],
                },
            ],
            time: curTime,
            id: 8,
        },
        {
            sender: 'blogger',
            img: img4,
            step: 9,
            time: curTime,
            id: 9,
        },
        {
            sender: 'blogger',
            text: 'As soon as the messages are sent, its will appear from the chat list',
            step: 10,
            time: curTime,
            id: 10,
        },
        {
            sender: 'blogger',
            text: 'Now you know how to create advertising campaigns!\nYou can start right now or I can show you how to use your other const 🌝',
            step: 11,
            buttons: [
                {
                    text: '🔥 I want to create a campaign!',
                    toSteps: [9, 10, 11],
                    callback: () => {
                        window.open(LISTS, '_blank')
                    },
                },
            ],
            time: curTime,
            id: 11,
        },
    ] as YolocoChatMessage[]
}
