import Observable from '@/_helpers/Observable'
import { useState, useEffect, useCallback } from 'react'

const modalKey = 'hideModal'

let state = Boolean(localStorage.getItem(modalKey))

const observeHidden = new Observable<boolean>()

observeHidden.subscribe((newState) => {
    state = newState
    if (newState) {
        localStorage.setItem(modalKey, 'true')
    } else {
        localStorage.removeItem(modalKey)
    }
})

export default (): [boolean, (state: boolean) => void] => {
    const [isHidden, setIsHidden] = useState(state)

    useEffect(() => {
        const sub = observeHidden.subscribe((state) => {
            setIsHidden(state)
        })
        return () => {
            sub.unsubscribe()
        }
    }, [])

    const setHidden = useCallback((newState: boolean) => {
        observeHidden.update(newState)
    }, [])

    return [isHidden, setHidden]
}
