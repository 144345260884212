import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { Flex } from '@/_yc'
import React, { FC, useEffect, useMemo, useState } from 'react'
// import DatesCreator from './DatesCreator'
import Button from '@/components/Button'
import Text from '@/components/Text'
import { shortNum } from '@/_helpers/_graphs/_tech'
import cn from './GrowGraph.module.scss'
import { useTranslation } from 'react-i18next'
import DataChange from './DataChange/DataChange'

import { calculatePercentChange, getGraphLocale, isGraphDataEmpty } from './utils'
import NoData from './NoData/NoData'
import { GrowGraph } from '@yoloker/ui-collection'
import PartChanger from '@/Layouts/ReportLayout/Extra/PartChanger'
import { IGraph } from './types'

const InstaGrowGraph: FC<IGraph> = ({ info, hidden, inHidder }) => {
    const t = useSpaceTranslation('report_layout.graph')
    const { i18n } = useTranslation()
    const [changeValue, setChangeValue] = useState(0)
    const [isEmptyData, setIsEmptyData] = useState(false)

    const [graphSpan, setGraphTimespan] = useState<'month' | 'all' | '6months'>('6months')
    const [graphType, setGraphType] = useState<'followers' | 'follows' | 'er'>('followers')

    const isFollowersType = graphType === 'followers'
    const isFollowsType = graphType === 'follows'
    const span = graphSpan === 'month' ? 'weekly_30' : graphSpan === '6months' ? 'weekly_180' : 'weekly_365'

    const graphData = useMemo(() => {
        if (!info) return []

        return isFollowersType
            ? info.followers_v2[span]?.dataSet.map((val) => val.value)
            : isFollowsType
            ? info.follows_v2[span]?.dataSet.map((val) => val.value)
            : info.erStat_v2[span]?.dataSet.map((val) => val.value).filter((item: any) => item !== null)
    }, [info, isFollowersType, isFollowsType, span])

    useEffect(() => {
        setIsEmptyData(isGraphDataEmpty(graphData))
    }, [graphData, setIsEmptyData, graphType, graphSpan])

    const graphCategories = useMemo(() => {
        if (!info) return []

        return isFollowersType
            ? info.followers_v2[span]?.dataSet.map((val) => val.label)
            : isFollowsType
            ? info.follows_v2[span]?.dataSet.map((val) => val.label)
            : info.erStat_v2[span]?.dataSet.map((val) => val.label).filter((item: any) => item !== null)
    }, [info, isFollowersType, isFollowsType, span])

    useEffect(() => {
        if (!graphData || graphData.length === 0) return

        const startValue = graphData[0]
        const endValue = graphData[graphData.length - 1]

        const isPercents = graphType === 'er'

        setChangeValue(calculatePercentChange(startValue, endValue, isPercents))
    }, [graphData, graphType])

    const partChangerValues = useMemo(() => {
        const values = [
            {
                title: t('followers'),
                value: '',
                simpleFunction: () => {
                    setGraphType('followers')
                },
            },
            {
                title: t('follows'),
                value: '',
                simpleFunction: () => {
                    setGraphType('follows')
                },
            },
            {
                title: t('er'),
                value: '',
                simpleFunction: () => {
                    setGraphType('er')
                },
            },
        ]

        return values
    }, [t])

    return (
        <Flex className={cn.root} column style={inHidder && !hidden ? { padding: '20px 24px' } : {}}>
            <Flex>
                <PartChanger values={partChangerValues} />
            </Flex>

            <Flex margin="20px 0 0 0" align="center" content="space-between" wrap>
                <Flex align={'center'}>
                    <Text fSize={21} semibold className={cn[`blur--default`]}>
                        {graphType === 'followers'
                            ? shortNum(info.followers_v2.mainValue)
                            : graphType === 'er'
                            ? `${shortNum(info.erStat_v2.mainValue * 100, 1, 2)}%`
                            : shortNum(info.follows_v2.mainValue)}
                    </Text>

                    <DataChange changeValue={changeValue} isEmptyData={isEmptyData} />
                </Flex>

                <Flex>
                    <Button
                        margin="0 10px 0 0"
                        color="blue"
                        onClick={() => setGraphTimespan('month')}
                        active={graphSpan === 'month'}
                        variant="outline--mobileV2"
                    >
                        {t('month')}
                    </Button>
                    <Button
                        margin="0 10px 0 0"
                        color="blue"
                        onClick={() => setGraphTimespan('6months')}
                        active={graphSpan === '6months'}
                        variant="outline--mobileV2"
                    >
                        {t('6months')}
                    </Button>
                    <Button
                        color="blue"
                        onClick={() => setGraphTimespan('all')}
                        active={graphSpan === 'all'}
                        variant="outline--mobileV2"
                    >
                        {t('all')}
                    </Button>
                </Flex>
            </Flex>
            <div style={{ height: '220px' }}>
                {!isEmptyData && (
                    <GrowGraph
                        height={220}
                        type={graphType}
                        graphData={graphData}
                        graphCategories={graphCategories}
                        locale={getGraphLocale(i18n.language)}
                        isDemo={hidden}
                    />
                )}
            </div>
            {isEmptyData && <NoData />}
        </Flex>
    )
}

export default InstaGrowGraph
