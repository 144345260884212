import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootStore } from '@/_helpers/StoreType'
import useToken from '@/_helpers/Hooks/useToken'

import { GetListsReq } from '../model/service'
import { setLists, setListsCount } from '../model/actions'
import { ICustomListInfo } from '../model/types'

const FetchCustomLists = () => {
    const token = useToken()
    const dispatch = useDispatch()

    const ListInfo = useSelector((store: RootStore) => store.AudienceInsights.ListsInfo)
    const ListsCount = useSelector((store: RootStore) => store.AudienceInsights.ListsCount)
    const [done, setDone] = useState(false)

    const [listStatus, setListStatus] = useState<{ error: boolean; loading: boolean }>({ error: false, loading: false })

    const paginatedFetch: any = useCallback(
        (page = 1, previousResponse: any = []) => {
            if (!token) return
            setListStatus({ error: false, loading: true })
            return GetListsReq(token, page) // Append the page number to the base URL
                .then((newResponse) => {
                    const response = previousResponse.concat(newResponse.results) // Combine the two arrays
                    if (ListsCount === 0) {
                        dispatch(setListsCount(newResponse.count))
                    }
                    if (newResponse.next !== null) {
                        page++

                        return paginatedFetch(page, response)
                    }
                    if (ListInfo.length === response.count) {
                        return
                    }
                    setDone(true)
                    dispatch(setLists(response.filter((l: ICustomListInfo) => l.is_likes !== true)))
                    setListStatus({ error: false, loading: false })
                    return response
                })
                .catch(() => {
                    setListStatus({ error: true, loading: false })
                })
        },
        [token, ListsCount, ListInfo.length, dispatch]
    )

    useEffect(() => {
        if (done) return //in case when req is done, but res.length === 0
        if (ListsCount === 0 && !listStatus.loading) {
            paginatedFetch()
        }
    }, [ListsCount, listStatus.loading, paginatedFetch, done])

    return listStatus
}
export default FetchCustomLists
