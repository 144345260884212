import { parseDate } from '@/_helpers/'

export default data => data.map(datum => format(datum))


const format = datum => {
	return {
		name: datum.name,
		bloggers: datum.bloggers.map(id => datum.data.blogger_short[id]),
		inProcess: datum.status === "PENDING",
		date: parseDate(datum.created_date),
		id: datum.id
	}
}