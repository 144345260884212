import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getNumberWithSpaces } from '@/_helpers/nums/numberWithSpaces'
import Flex from '@/_yc/Flex'
import Text from '@/components/Text'
import { RootStore } from '@/_helpers/StoreType'
import Avatar from '@/components/Avatar'
import Icons from '@/components/Icons'
import Button from '@/components/Button'
import { MockInContainer } from '../../Mock/Mock'
import cn from './Posts.module.scss'

const Posts = () => {
    const { t } = useTranslation()
    const data = useSelector((store: RootStore) => {
        return {
            posts: store.report.rawTgData?.getTelegraReport?.data.telegram_channel[0].telegram_post
                .filter((post) => post.text && post.text.length > 0)
                .slice(0, 3),
            personalInfo: {
                ava: store.report.rawTgData?.getTelegraReport?.data.telegram_channel[0].avatar,
                name: store.report.rawTgData?.getTelegraReport?.data.telegram_channel[0].title,
                username: store.report.rawTgData?.getTelegraReport?.data.telegram_channel[0].username,
            },
        }
    })
    if (!data.posts || data.posts.length === 0) {
        return <MockInContainer />
    }
    return (
        <Flex content="space-between">
            {data.posts?.map((item) => (
                <Flex className={cn.card} column>
                    <Flex>
                        <Avatar
                            src={data.personalInfo.ava ?? ''}
                            alt="AVA"
                            aria-label="Аватарка"
                            className={cn.avatar}
                        />
                        <Flex>
                            <Text fWeight="600">{data.personalInfo.name}</Text>
                            <Text fWeight="500" fSize="12px" color="gray-3">
                                {item.created_at}
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex style={{ height: '270px' }}>
                        <Flex className={cn.text}>
                            <Text>{item.text}</Text>
                        </Flex>
                    </Flex>

                    <div className={cn.dividerHoriz} />

                    <Flex margin="16px 0 0 0" content="space-between">
                        <Flex>
                            <Flex align="center">
                                <Icons name="views" className={cn.icon} />
                                <Text margin="0 0 0 8px" color="gray-3">
                                    {getNumberWithSpaces(item.views_count) ? getNumberWithSpaces(item.views_count) : 0}
                                </Text>
                            </Flex>

                            <Flex align="center" margin="0 0 0 10px">
                                <Icons name="filter--comments" className={cn.icon} />
                                <Text margin="0 0 0 8px" color="gray-3">
                                    {getNumberWithSpaces(item.comments_count)
                                        ? getNumberWithSpaces(item.comments_count)
                                        : 0}
                                </Text>
                            </Flex>
                        </Flex>
                        <a
                            href={`https://t.me/${data.personalInfo.username}/${item.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Button>{t('report_layout.open')}</Button>
                        </a>
                    </Flex>
                </Flex>
            ))}
        </Flex>
    )
}
export default Posts
