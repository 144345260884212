import { bloggerConstants } from '../_constants';

export function blogger(state = {}, action) {
  //debugger;
  switch (action.type) {
    case bloggerConstants.GETBLOGGER_REQUEST:
      return {
        loading: true
      };
    case bloggerConstants.GETBLOGGER_SUCCESS:
      return {
        items: action.blogger
      };
      case bloggerConstants.GETBLOGGER_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function bloggerWords(state = {}, action) {
  //debugger;
  switch (action.type) {
    case bloggerConstants.GETBLOGGERWORDS_REQUEST:
      return {
        loading: true
      };
    case bloggerConstants.GETBLOGGERWORDS_SUCCESS:
      return {
        items: action.bloggerWords
      };
      case bloggerConstants.GETBLOGGERWORDS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}
