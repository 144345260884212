import React, { FC, useMemo } from 'react'

import useReportTranslation from '../useReportTranslation'
import { DonutChart as DonutChartUI } from '@yoloker/ui-collection'

export interface IDonutChart {
    title: string
    width: string | number
    data?: Array<{
        value: number
        color: string
        label: string
        label_color: string
        percent_color: string
    }>
    hidden?: boolean
}

const DonutChart: FC<IDonutChart> = ({ title, width, data, hidden }) => {
    const t = useReportTranslation(title)

    const preprocessedData = useMemo(
        () =>
            data?.map((d) => ({
                ...d,
                label: t(`labels.${d.label}`),
            })),
        [data, t]
    )

    return (
        <DonutChartUI
            data={preprocessedData ? preprocessedData : []}
            tooltipText={t('helper')}
            title={t('title')}
            isDemo={hidden}
            style={{ width }}
        />
    )
}

export default DonutChart
