import React, { FC, memo } from 'react'
import { FilterIdContext } from '../../../SearchAdapter/FilterIdContext'
import { BasicFilter } from '../../../SearchAdapter/filters/BasicFilter'

export interface IChipItem {
    filter: BasicFilter<any, any>
    id: string
}

const ChipItem: FC<IChipItem> = ({ id, filter: Filter }) => {
    return (
        <FilterIdContext.Provider value={Filter.id || id}>
            <Filter.Tags />
        </FilterIdContext.Provider>
    )
}

export default memo(ChipItem)
