import React, { FC, useCallback, useContext } from 'react'
import cn from './AudienceLocker.module.scss'
import Icons from '@/components/Icons'
import { useSpaceTranslation } from '@/_helpers'
import Text from '@/components/Text'
import { CampaignManagerContext } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerContext'
import { useMutation } from '@apollo/client'
import { OPEN_INFLUENCERS } from '../utils/queries'
import { useCampaignId } from '@/_helpers/Hooks/useCampaignId'
import Flex from '@/_yc/Flex'
import LoadingButton from '@/components/Share/LoadingButton'

interface AudienceLockerProps {
    refetchBloggers: () => void
}

export const AudienceLocker: FC<AudienceLockerProps> = ({ refetchBloggers }) => {
    const t = useSpaceTranslation('media_planner.plan.audience_locker')

    const context = useContext(CampaignManagerContext)

    const id = useCampaignId()

    const [openInfluencers, { loading }] = useMutation(OPEN_INFLUENCERS, {
        context: context,
        variables: {
            orderId: id,
        },
        onCompleted: () => {
            refetchBloggers()
        },
    })

    const openInfluencersHandler = useCallback(() => {
        openInfluencers()
    }, [openInfluencers])

    return (
        <div className={cn.audienceLocker}>
            <div className={cn.icon}>
                <Icons name="pie" />
            </div>

            <Flex align="center" content="space-between" style={{ width: '100%' }}>
                <div className={cn.text}>
                    <Text fSize={16} semibold>
                        {t('title')}
                    </Text>
                    <Text fSize={12} color="gray-new-2">
                        {t('subtitle')}
                    </Text>
                </div>
                <LoadingButton
                    variant="primary"
                    onClick={openInfluencersHandler}
                    loading={loading}
                    noDownloadIcon
                    noLoadingText
                >
                    {t('analyze')}
                </LoadingButton>
            </Flex>
        </div>
    )
}
