import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.0001 15.5C8.4626 15.0233 7.8551 14.5275 7.2126 14H7.20427C4.94177 12.15 2.3776 10.0566 1.2451 7.54829C0.873032 6.74973 0.675854 5.8809 0.666758 4.99996C0.664273 3.7912 1.14908 2.63245 2.01161 1.7856C2.87414 0.938751 4.04159 0.475289 5.2501 0.49996C6.23396 0.501514 7.19663 0.785861 8.02343 1.31913C8.38674 1.55493 8.71545 1.84017 9.0001 2.16663C9.28634 1.84145 9.61514 1.55638 9.9776 1.31913C10.804 0.785756 11.7665 0.501392 12.7501 0.49996C13.9586 0.475289 15.1261 0.938751 15.9886 1.7856C16.8511 2.63245 17.3359 3.7912 17.3334 4.99996C17.3249 5.88231 17.1277 6.75262 16.7551 7.55246C15.6226 10.0608 13.0593 12.1533 10.7968 14L10.7884 14.0066C10.1451 14.5308 9.53843 15.0266 9.00093 15.5066L9.0001 15.5ZM5.2501 2.16663C4.47386 2.15691 3.72516 2.454 3.16677 2.99329C2.62875 3.52176 2.32806 4.24583 2.33336 4.99996C2.34287 5.64204 2.48829 6.27484 2.7601 6.85663C3.29469 7.93888 4.01603 8.91834 4.89093 9.74996C5.71677 10.5833 6.66677 11.39 7.48843 12.0683C7.71593 12.2558 7.9476 12.445 8.17927 12.6341L8.3251 12.7533C8.5476 12.935 8.7776 13.1233 9.0001 13.3083L9.01093 13.2983L9.01593 13.2941H9.02093L9.02843 13.2883H9.0326H9.03677L9.05177 13.2758L9.08593 13.2483L9.09177 13.2433L9.10093 13.2366H9.10593L9.11343 13.23L9.66677 12.7758L9.81177 12.6566C10.0459 12.4658 10.2776 12.2766 10.5051 12.0891C11.3268 11.4108 12.2776 10.605 13.1034 9.76746C13.9785 8.93626 14.6998 7.95706 15.2343 6.87496C15.511 6.28813 15.6585 5.64872 15.6668 4.99996C15.6702 4.24816 15.3697 3.52687 14.8334 2.99996C14.2761 2.45823 13.5273 2.1587 12.7501 2.16663C11.8017 2.15857 10.895 2.5561 10.2584 3.25913L9.0001 4.70913L7.74177 3.25913C7.10515 2.5561 6.1985 2.15857 5.2501 2.16663Z"
            fill="#8F8F8F"
        />
    </svg>
)
