import React, { useCallback, useMemo, useRef, useState } from 'react'

import { BasicFilter, BasicFiltersOptions } from '../BasicFilter'
import { Flex } from '@/_yc'
import Selector from '../Selector'
import { useTranslation } from 'react-i18next'
import Tag from '@/pages/SearchPage/Bloggers/SearchPanel/Chips/Tag'
import { shortNum } from '@/_helpers/_graphs/_tech'
import { trackFilterChange } from '@/_helpers/amplitude/track'

import Text from '@/components/Text'
import MainFilterBase from '../MainFilterBase/MainFilterBase'
import useOpenClose from '@/_helpers/useOpenClose'
import Popup from '@/_yc/Popup/Popup'
import Scrollbars from 'react-custom-scrollbars'

import cn from './RangeFilter.module.scss'
import { RangeSlider } from '../Slider/DoubleSlider'
import Icons from '@/components/Icons'
import { useSearch } from '../../hooks/useSearch'

const dn = { display: 'none' }

const hideTrack = (props: { [k: string]: any }) => <div {...props} className="track-horizontal" style={dn} />
const hideThumb = (props: { [k: string]: any }) => <div {...props} className="thumb-horizontal" style={dn} />

export type RangeValueType = {
    from: number | null
    to: number | null
}

interface RangeFilterOptions {
    options?: {
        from: { label: string; value: number }[]
        to: { label: string; value: number }[]
    }
    mainOptions?: { label: string; labelValue: string; valueFrom: number; valueTo: number }[]
}

const width = { width: 'calc(50% - 8px)' }

const defaultOptions = [
    { label: '2k', value: 2000 },
    { label: '5k', value: 5000 },
    { label: '10k', value: 10000 },
    { label: '50k', value: 50000 },
    { label: '100k', value: 100000 },
    { label: '250k', value: 250000 },
    { label: '500k', value: 500000 },
    { label: '1M', value: 1000000 },
]

export class RangeFilter<ServerData> extends BasicFilter<ServerData, RangeValueType> {
    filterOptions = {
        from: defaultOptions,
        to: defaultOptions,
    }

    mainOptions = [
        { label: 'Nano', labelValue: '1K-10K', valueFrom: 1000, valueTo: 10_000 },
        { label: 'Micro', labelValue: '10K-50K', valueFrom: 10_000, valueTo: 50_000 },
        { label: 'Macro', labelValue: '50K-500K', valueFrom: 50_000, valueTo: 500_000 },
        { label: 'Mega', labelValue: '500K-1M', valueFrom: 500_000, valueTo: 1_000_000 },
        { label: 'Celebrities', labelValue: '1M+', valueFrom: 1_000_000, valueTo: 700_000_000 },
    ]

    constructor({
        options,
        mainOptions,
        ...props
    }: RangeFilterOptions & BasicFiltersOptions<ServerData, RangeValueType>) {
        super(props)
        if (options) {
            this.filterOptions = options
        }
        if (mainOptions) {
            this.mainOptions = mainOptions
        }
    }

    getDefaultValue() {
        return {
            from: null,
            to: null,
        }
    }

    MainView = (props: { filterName: string }) => {
        const { t } = useTranslation()
        const [state, setState] = this.useFilterState()
        const ref = useRef<HTMLDivElement>(null)

        const [sliderValue, setSliderValue] = useState([10000, 50000])

        const [isOpen, open, , toggle] = useOpenClose()

        const { restart } = useSearch()

        const search = useCallback(() => {
            setTimeout(() => {
                restart()
            }, 300)
        }, [restart])

        const options = useMemo(() => {
            return this.mainOptions
        }, [])

        const width = useMemo(() => {
            if (!ref.current || !isOpen) return
            return {
                width: window.getComputedStyle(ref.current).width,
            }
        }, [isOpen])

        const onSliderChange = useCallback((from: number, to: number) => {
            setSliderValue([from, to])
        }, [])

        const onSliderChangeCommitted = useCallback(
            (from: number, to: number, error?: boolean) => {
                setSliderValue([from, to])

                if (error) return
                setState({
                    from,
                    to,
                })

                search()
            },
            [setState, search]
        )

        return (
            <div>
                <MainFilterBase ref={ref} onClick={open} isActive={isOpen} icon={this.options.icon}>
                    <Text color={'gray-4'} fSize={12}>
                        {t(props.filterName)}
                    </Text>
                </MainFilterBase>
                <Popup
                    disablePreventOverflow
                    style={{ zIndex: 15, marginTop: 16, marginLeft: -10 }}
                    anchor={ref.current}
                    isOpen={isOpen}
                    onClose={toggle}
                >
                    <div style={width}>
                        <div style={width} className={cn.popper}>
                            <Scrollbars
                                renderTrackHorizontal={hideTrack}
                                renderThumbHorizontal={hideThumb}
                                autoHeight
                                autoHeightMax={300}
                            >
                                {options.map((item, i) => {
                                    const isActive = item.valueFrom === state.from && item.valueTo === state.to
                                    return (
                                        <Flex
                                            align="center"
                                            content="space-between"
                                            className={cn.item}
                                            onClick={() => onSliderChangeCommitted(item.valueFrom, item.valueTo)}
                                        >
                                            <Flex content="flex-start">
                                                <Text>{item.label}</Text>
                                                <Text color="gray-3" margin="0 0 0 8px">
                                                    {item.labelValue}
                                                </Text>
                                            </Flex>
                                            {isActive && <Icons name="done" className={cn.done} />}
                                        </Flex>
                                    )
                                })}
                            </Scrollbars>
                        </div>
                        <div style={{ ...width, marginTop: '6px' }} className={cn.popper}>
                            <RangeSlider
                                from={sliderValue[0]}
                                to={sliderValue[1]}
                                onChange={onSliderChange}
                                onChangeCommitted={onSliderChangeCommitted}
                            />
                        </div>
                    </div>
                </Popup>
            </div>
        )
    }

    View = (props: { disabled: boolean; scrollToFilter?: Function }) => {
        const [state, setState, , clickedVal, setClicked] = this.useFilterState()
        const ref = useRef<any>(null)
        const fromOptions = useMemo(() => {
            const limit = state.to ?? Infinity
            if (!state.to) return this.filterOptions.from
            return this.filterOptions.from.map((item) => ({
                ...item,
                disabled: item.value >= limit,
            }))
        }, [state])

        const toOptions = useMemo(() => {
            const limit = state.from ?? 0
            if (!state.from) return this.filterOptions.to
            return this.filterOptions.to.map((item) => ({
                ...item,
                disabled: item.value <= limit,
            }))
        }, [state])

        const fromLabel = useMemo(() => {
            return this.filterOptions.from.find((i) => i.value === state.from)?.label
        }, [state.from])

        const toLabel = useMemo(() => {
            return this.filterOptions.to.find((i) => i.value === state.to)?.label
        }, [state.to])

        const { t } = useTranslation()

        const changeCreator = useCallback(
            (key: 'from' | 'to') => {
                return (next: number) => {
                    setState((prev) => {
                        const oposit = key === 'from' ? 'to' : 'from'
                        if (!next && !prev[oposit]) return undefined
                        if (key === 'from') {
                            const val = {
                                from: prev.from === next ? null : next,
                                to: (prev.to || Infinity) <= next ? null : prev.to,
                            }
                            trackFilterChange({
                                t,
                                title: this.options.title,
                                value: val,
                                platform: this.options.platform,
                            })

                            return val
                        } else {
                            const val = {
                                to: prev.to === next ? null : next,
                                from: (prev.from || 0) >= next ? null : prev.from,
                            }
                            trackFilterChange({
                                t,
                                title: this.options.title,
                                value: val,
                                platform: this.options.platform,
                            })

                            return val
                        }
                    })
                }
            },
            [setState, t]
        )

        const changeFrom = useCallback(changeCreator('from'), [])
        const changeTo = useCallback(changeCreator('to'), [])

        const scroll = () => {
            props.scrollToFilter && props.scrollToFilter(ref)
        }
        return (
            <Flex content="space-between" ref={ref} style={{ position: 'relative' }}>
                <Selector
                    style={width}
                    options={fromOptions}
                    isActive={(value) => state.from === value}
                    fSize={14}
                    onChange={changeFrom}
                    placeholder={t('filters.global.from')}
                    display={fromLabel}
                    open={!!state.from && clickedVal}
                    setClicked={setClicked}
                    scrollToFilter={scroll}
                />
                <Selector
                    style={width}
                    options={toOptions}
                    isActive={(value) => state.to === value}
                    fSize={14}
                    onChange={changeTo}
                    placeholder={t('filters.global.to')}
                    display={toLabel}
                    open={!!state.to && !state.from && clickedVal}
                    setClicked={setClicked}
                    scrollToFilter={scroll}
                />
            </Flex>
        )
    }

    Tags = () => {
        const [{ from, to }, , clear, , setClicked] = this.useFilterState()

        const { t } = useTranslation()

        const label =
            from && to ? `${shortNum(from)} - ${shortNum(to)}` : from ? `>=${shortNum(from)}` : `<=${shortNum(to)}`

        if (!from && !to) return null

        return <Tag label={`${t(this.options.title)}: ${label}`} onDelete={clear} onClick={setClicked} />
    }
}
