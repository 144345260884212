import React from 'react'

import reports_only from './reports_only.png'
import standart from './standart.png'
import business from './business.png'
import enterprise from './enterprise.png'
import free from './free.png'

export default ({ id, ...props }) => {
    switch (id) {
        case 5:
            return <img src={reports_only} alt={<div />} {...props} />
        case 3:
            return <img src={standart} alt={<div />} {...props} />
        case 1:
            return <img src={business} alt={<div />} {...props} />
        case 4:
            return <img src={enterprise} alt={<div />} {...props} />
        case 0:
            return <img src={free} alt={<div />} {...props} />
        default:
            return <img src={enterprise} alt={<div />} {...props} />
    }
}
