import React from 'react'
import Icons from '@/components/Icons'
import { SelectorFilter } from '@/pages/SearchPage/SearchAdapter/filters/SelectorFilter'

const getGqlFilter = (value: number[], percentage?: number) => {
    if (!value || value.length === 0) return ''
    const ageRange: { [key: string]: string } = {
        18: '18-24',
        25: '25-34',
        35: '35-44',
        45: '45-64',
        65: '65-',
    }

    return `audience_age: [
        ${value
            .map((item) => {
                return `{ code: "${ageRange[item.toString()]}", weight: ${(percentage ? percentage : 25) / 100} }`
            })
            .join(',')}
    ]`
}
export const getAudAgeFilter = (isMain?: boolean) => {
    return new SelectorFilter({
        platform: 'infl_tiktok',
        title: 'filters.age.audience.label',
        helper: 'filters.age.audience.text',
        icon: <Icons name="filter--age" />,
        hasPercentage: true,
        isMulti: true,
        space: 1,
        options: [
            { value: 18, label: '18-24' },
            { value: 25, label: '25-34' },
            { value: 35, label: '35-44' },
            { value: 45, label: '45-64' },
            { value: 65, label: '65+' },
        ],
        beforeSend: ({ value, percentage }) => {
            return {
                name: 'metrics.views',
                type: 'range',
                value: value[0],
                gqlFilter: getGqlFilter(value, percentage),
            }
        },
    })
}
