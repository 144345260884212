import Icons from '@/components/Icons'
import Text from '@/components/Text'
import ListPopup from '@/components/Share/ListPopup'
import useOpenClose from '@/_helpers/useOpenClose'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { Flex } from '@/_yc'
import React, { FC, memo, useCallback, useRef } from 'react'
import { useSearchBy, useSearchText } from '../../../SearchAdapter'
import cn from './SearchInput.module.scss'

export interface IWhereSelector {
    inFilter?: boolean
    filterRef?: any
}

const zIndex = { zIndex: 10 }

const WhereSelector: FC<IWhereSelector> = (props) => {
    const t = useSpaceTranslation('Influencer_explorer.search_input')
    const { search } = useSearchText()

    const [state, , , tg] = useOpenClose()

    const { searchBy, toggle, options } = useSearchBy()

    const ref = useRef(null)

    const check = useCallback(
        (value: string) => {
            return searchBy.includes(value)
        },
        [searchBy]
    )

    if (props.inFilter) {
        return (
            <>
                <Flex onClick={tg} align="center" className={cn['selector--inFilter']} ref={ref}>
                    <Icons className={cn['icon--gray3']} name="burger" margin="0 5px 0 0" />
                </Flex>
                <ListPopup
                    popupStyle={{ ...zIndex, margin: '25px 0px 0 0px', marginLeft: -165 }}
                    options={options}
                    anchor={ref.current}
                    isOpen={state}
                    onClose={tg}
                    isActiveCheck={check}
                    onClick={toggle}
                    placement="bottom-start"
                />
            </>
        )
    }

    return (
        <div>
            <Flex onClick={tg} ref={ref} align="center" className={cn.selector}>
                <Flex className={cn.text}>
                    <Text color="gray-4" fSize={12}>
                        {t('placeholder')}
                    </Text>
                    <Icons name="arrow-down" margin="0 0 0 35px" className={cn['icon--gray']} />
                </Flex>
                <Flex variant="transparent" className={search ? cn['icon--visible'] : cn['icon--hidden']}>
                    <Icons className={cn['icon--gray3']} name="burger" margin="0 5px 0 0" />
                </Flex>
            </Flex>
            <ListPopup
                popupStyle={zIndex}
                options={options}
                anchor={ref.current}
                isOpen={state}
                onClose={tg}
                isActiveCheck={check}
                onClick={toggle}
                placement="bottom-start"
            />
        </div>
    )
}

export default memo(WhereSelector)
