import React, { PureComponent, ComponentType } from 'react'
import { Subtract } from 'utility-types'
import { Notification } from '@/components/Notifier/Types'
import TabNotification from '@/components/Notifier/TabNotification'
import { Subscription } from '@/_helpers/Observable'

export interface IRequiredProps {
    PullPage: (token: string, page: number) => Promise<{ hasNext: boolean; results: Notification[] }>
    token: string | null
}

export interface IProvidedProps {
    items: Notification[]
    loadNew: VoidFunction
    loading: boolean
    hasNext: boolean
}

interface State extends IProvidedProps {}

const Tab = new TabNotification()

export default <Props extends IProvidedProps>() => (Component: ComponentType<Props>) =>
    class withListDownload extends PureComponent<IRequiredProps & Subtract<Props, IProvidedProps>, State> {
        state: State = {
            items: [],
            loading: false,
            loadNew: this.loadNew.bind(this),
            hasNext: true,
        }

        page = 1
        mounted = true

        prevCount = Tab.count
        sub?: Subscription

        componentDidMount() {
            this.loadNew()
            this.sub = Tab.subscribe((count) => {
                if (!this.mounted) return
                if (count > this.prevCount) {
                    this.reload()
                }
            })
        }

        componentDidUpdate(prevProps: IRequiredProps) {
            if (!prevProps.token && this.props.token) {
                this.loadNew()
            }
        }

        loadNew() {
            if (!this.state.loading) {
                this.setState({ loading: true })
                this.loadPage(this.page)
                    .then(({ hasNext, results }) => {
                        if (!this.mounted) return
                        if (hasNext) this.page++
                        this.setState({
                            items: this.state.items.concat(results),
                            loading: false,
                            hasNext,
                        })
                    })
                    .catch(console.error)
            }
        }

        loadPage = (page: number) =>
            new Promise<{ hasNext: boolean; results: Notification[] }>((resolve, reject) => {
                if (typeof this.props.token === 'string') {
                    this.props.PullPage(this.props.token, page).then(resolve).catch(reject)
                } else {
                    reject()
                }
            })

        reload(page: number = 1, content: Notification[] = []) {
            if (page <= this.page) {
                this.loadPage(page)
                    .then(({ hasNext, results }) => {
                        if (page <= this.page && page + 1 <= this.page && hasNext) {
                            this.reload(page + 1, content.concat(results))
                        } else {
                            this.setState({
                                items: content.concat(results),
                            })
                        }
                    })
                    .catch()
            }
        }

        componentWillUnmount() {
            this.mounted = false
            this.sub && this.sub.unsubscribe()
        }

        render() {
            const { PullPage, token, ...rest }: IRequiredProps = this.props

            return (
                <Component
                    {...(rest as Props)}
                    items={this.state.items}
                    loading={this.state.loading}
                    loadNew={this.state.loadNew}
                    hasNext={this.state.hasNext}
                />
            )
        }
    }
