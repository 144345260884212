import { AgeGender } from '../../Model/types'
import { IDemographicsItem } from '../ResuableWidgets/MultiBarChart/types'

interface IntermediateDemographicsItem {
    qty: number
    share: number
    items: Array<{ name: string; platform_language_id: string }>
}

interface RawCountry {
    qty: number
    share: number
    youtube_country: {
        youtube_countriestranslates: Array<{
            name: string
            platform_language_id: string
        }>
    }
}

interface RawLanguage {
    qty: number
    share: number
    youtube_language: {
        youtube_languagestranslates: Array<{
            name: string
            platform_language_id: string
        }>
    }
}
export const getCountriesFromRaw = (rawData?: RawCountry[]) => {
    if (!rawData) {
        return []
    }

    return rawData.map(({ qty, share, youtube_country }) => ({
        qty,
        share,
        items: youtube_country.youtube_countriestranslates.map((country) => {
            return {
                name: country.name,
                platform_language_id: country.platform_language_id,
            }
        }),
    }))
}

export const getLanguagesFromRaw = (rawData?: RawLanguage[]) => {
    if (!rawData) {
        return []
    }

    return rawData.map(({ qty, share, youtube_language }) => ({
        qty: qty,
        share: share,
        items: youtube_language.youtube_languagestranslates.map((language) => {
            return {
                name: language.name,
                platform_language_id: language.platform_language_id,
            }
        }),
    }))
}

export const preprocessCountryNLang = (demographics: Array<IntermediateDemographicsItem> | undefined, lang: string) => {
    let preprocessed: Array<IDemographicsItem> = []
    if (!demographics || demographics.length === 0) {
        return []
    }

    ;[...demographics]
        .sort((a, b) => b.qty - a.qty)
        .forEach((intermediateItem) => {
            preprocessed.push({
                label:
                    intermediateItem.items.filter((i) => i.platform_language_id === lang)[0] === undefined
                        ? intermediateItem.items.filter((i) => i.platform_language_id === 'en')[0]?.name ||
                          'Other languages'
                        : intermediateItem.items.filter((i) => i.platform_language_id === lang)[0].name ||
                          'Other languages',
                items: [
                    {
                        qty: intermediateItem.qty,
                        share: intermediateItem.share,
                        color: '#6071FF',
                    },
                ],
            })
        })

    return preprocessed
}

type IIndex = '0-17' | '18-24' | '25-34' | '35-44' | '45-54' | '55-999'
export const preprocessAgeGender = (ageGender: Array<AgeGender> | undefined) => {
    if (ageGender === undefined || ageGender.length === 0) return []
    let temp: Array<IDemographicsItem> = []
    const indexes: Array<IIndex> = ['0-17', '18-24', '25-34', '35-44', '45-54', '55-999']
    indexes.forEach((index, i) => {
        temp.push({
            label: index !== '55-999' ? index : '>55',
            items: [
                {
                    qty: 0,
                    share: ageGender[i].man_share,
                    color: '#6071FF',
                },
                {
                    qty: 0,
                    share: ageGender[i].woman_share,
                    color: '#FF659C',
                },
            ],
        })
    })
    return temp
}
export const getGenderDist = (ageGender: AgeGender | undefined) => {
    let temp: { men: number; women: number } = { men: 0, women: 0 }
    ageGender &&
        Object.entries(ageGender).map(
            (item) => (temp = { men: temp.men + item[1]['man']['qty'], women: temp.women + item[1]['woman']['qty'] })
        )
    return {
        men: ((100 / (temp.men + temp.women)) * temp.men) / 100,
        women: ((100 / (temp.men + temp.women)) * temp.women) / 100,
    }
}
