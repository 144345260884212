import { Platform } from '@/pages/Reports/types'
import { Chat } from '../Service/Chats'

export const getBloggerData = (chat: Chat) => {
    if (chat.participant_bloggers.length > 0) {
        return {
            data: chat.participant_bloggers[0],
            platform: Platform.Instagram,
        }
    }

    return {
        data: chat.participant_channels[0],
        platform: Platform.YouTube,
    }
}
