import React, { FC } from 'react'
import { Tab } from './Tab/Tab'

import cn from './PlatformChanger.module.scss'
import { findAdapterType } from '@/pages/SearchPage/utils/findAdapterType'
import { useLocation } from 'react-router'
import { platforms } from '../../platforms'
import { useSearch } from '@/pages/SearchPage/SearchAdapter'

interface IPlatformChanger {}

export const PlatformChanger: FC<IPlatformChanger> = () => {
    const loc = useLocation()
    const curPlatform = findAdapterType(loc.pathname)

    const { clearFilters } = useSearch()

    return (
        <div className={cn.platformChanger}>
            {platforms.map((platform, i) => (
                <div onClick={clearFilters}>
                    <Tab platform={platform} index={i} selected={curPlatform === platform.id} />
                </div>
            ))}
        </div>
    )
}
