import Button from '@/components/Button'
import Loader from '@/components/Loader'
import { SetBloggersMailingList } from '@/components/MassAddToList/_model/actions'
import { MailingListItem } from '@/pages/Lists/components/ToOutreach/SendToMailingList/MailingListItem/MailingListItem'
import { useBloggerList } from '@/pages/Outreach/utils/useBloggerList'
import { useSpaceTranslation } from '@/_helpers'
import Flex from '@/_yc/Flex'
import React, { FC, useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import cn from './BloggerList.module.scss'

interface BloggerListProps {
    selectedListId: number
    close: () => void
}

export const BloggerList: FC<BloggerListProps> = ({ selectedListId, close }) => {
    const [page, setPage] = useState(1)
    const dispatch = useDispatch()

    const t = useSpaceTranslation('outreach.templates.add_from_list')

    const bloggersRef = useRef<HTMLDivElement>(null)

    const { bloggers, loading, noMoreBloggers } = useBloggerList({ listId: selectedListId, page })

    const onScroll = useCallback(() => {
        if (!bloggersRef.current) {
            return
        }

        const { scrollTop, scrollHeight, clientHeight } = bloggersRef.current
        if (scrollTop + clientHeight > scrollHeight - 200) {
            !loading && !noMoreBloggers && setPage((prev) => prev + 1)
        }
    }, [setPage, loading, noMoreBloggers])

    const onBloggersAdd = useCallback(() => {
        dispatch(
            SetBloggersMailingList({
                ytList: bloggers.filter((item) => item.channelId && item.hasEmail),
                instList: bloggers.filter((item) => item.bloggerId && item.hasEmail),
                setId: [selectedListId],
            })
        )
        close()
    }, [bloggers, dispatch, selectedListId, close])

    return (
        <>
            {selectedListId !== null && (
                <>
                    <div className={cn.mailingListItems} onScroll={onScroll} ref={bloggersRef}>
                        {bloggers.length > 0 &&
                            bloggers.map((item, i) => {
                                return (
                                    <MailingListItem
                                        key={item.id}
                                        email={item.hasEmail}
                                        name={item.name}
                                        avatar={item.avatar}
                                        username={item.username}
                                        id={item.externalId || ''}
                                        currNumber={i + 1}
                                    />
                                )
                            })}

                        {(bloggers.length === 0 || loading) && (
                            <Flex content="center" align="center">
                                <Loader />
                            </Flex>
                        )}
                    </div>
                    <div className={cn.footer}>
                        <Button variant="primary" onClick={onBloggersAdd}>
                            {t('add')}
                        </Button>
                    </div>
                </>
            )}
        </>
    )
}
