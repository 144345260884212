import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

type Return<Map extends M> = {
    [K in keyof Map]?: string
}

type M = {
    [key: string]: any
}

/**
 *
 * @param keys array of keys u want to get from search parameters
 * @return object like { params } whare params typeof { [key: string]?: string }
 */
const useUrlSearchParams = <UrlTypes extends M>(
    keys: Array<keyof UrlTypes>
): { params: { [K in keyof UrlTypes]?: string } } => {
    const location = useLocation()

    const params = useMemo<Return<UrlTypes>>(() => {
        const result: Return<UrlTypes> = {}
        const search = new URLSearchParams(location.search)
        for (let key of keys) {
            result[key] = search.get(key + '') || undefined
        }
        return result
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, ...keys])

    return { params }
}

export default useUrlSearchParams
