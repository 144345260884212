import React, { PureComponent, ComponentType } from 'react'
import { Subtract } from 'utility-types'
import Pollinger from '../Pollinger'
import { Subscription } from '../Observable'

export type pollingFunction = (delay: number, cb: (count: number) => void) => void

export interface IProvidedProps {
    polling: pollingFunction
    pollingStop: VoidFunction
}

export default <Props extends IProvidedProps>() =>
    (Component: ComponentType<Props>) =>
        class wthPollinger extends PureComponent<Subtract<Props, IProvidedProps>, {}> {
            pollinger = new Pollinger()
            sub?: Subscription

            polling = (delay: number, cb: (count: number) => void): void => {
                this.sub = this.pollinger.subscribe(cb)
                this.pollinger.startPolling(delay)
            }

            pollingStop = () => {
                if (this.sub) {
                    this.sub.unsubscribe()
                    this.pollinger.finishPolling()
                }
            }

            componentWillUnmount() {
                this.pollingStop()
            }

            render() {
                return (
                    // @ts-ignore
                    <Component
                        // @ts-ignore
                        polling={this.polling}
                        // @ts-ignore
                        pollingStop={this.pollingStop}
                        {...(this.props as Props)}
                    />
                )
            }
        }
