import { useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootStore } from '@/_helpers/StoreType'
import useToken from '@/_helpers/Hooks/useToken'
import { useQuery } from '@apollo/client'

import { GET_UNLOCKED_CHANNELS } from '../model/graphQL'
import { setBloggersAndChannels, setBloggersAndChannelsDefault } from '../model/actions'
import { getCustomListReq } from '../model/service'
import useLikes from './useLikes'

import { CustomListsToNormal } from '../PreprocessData/index'
import { IUnlockedChannels } from '../model/types'

const GetBloggers = (sort?: string) => {
    const token = useToken()
    const dispatch = useDispatch()
    const likedBloggers = useLikes()

    const activeListId = useSelector((store: RootStore) => store.AudienceInsights.ActiveListId)
    const prevData = useSelector((store: RootStore) => store.AudienceInsights.BloggersAndChannels)

    const [listsStatus, setListsStatus] = useState<{ error: boolean; loading: boolean }>({
        error: false,
        loading: true,
    })
    const [page, setPage] = useState<{ listID: number; page: number }>({ listID: -1, page: 1 })
    const [isNextExists, setIsNextExists] = useState<boolean>(true)
    useEffect(() => {
        setPage({ listID: activeListId, page: 1 })
        setIsNextExists(true)
    }, [activeListId])

    //get unlocked channels
    const {
        data: unlockedChannelsIds,
        loading: hasuraLoading,
        error: hasuraError,
    } = useQuery<IUnlockedChannels>(GET_UNLOCKED_CHANNELS, {
        skip: false,
    })
    const unlockedChannels = useMemo(() => {
        return {
            unlockedIDS: [
                ...(unlockedChannelsIds?.getUnlockedChannels.unlocked_youtube_channels ?? []),
                ...(unlockedChannelsIds?.getUnlockedChannels.unlocked_twitch_channels ?? []),
            ],
            vkUnlocked: unlockedChannelsIds?.getUnlockedChannels.unlocked_vk_accounts ?? [],
        }
    }, [unlockedChannelsIds])

    // get bloggers & channels from custom list
    useEffect(() => {
        // if (likedBloggers.length === 0) return
        if (activeListId === -10) return
        if (hasuraError || hasuraLoading) return
        if (!token || activeListId === -1) return
        if (page.listID !== activeListId) return
        setListsStatus({ error: false, loading: true })
        getCustomListReq(token, activeListId, page.page, sort) //add pagination
            .then((res: any) => {
                if (page.page === 1) {
                    dispatch(setBloggersAndChannelsDefault([]))
                }

                // dispatch(setListLockedCount(res.locked_count))
                dispatch(setBloggersAndChannels(CustomListsToNormal(res.results, unlockedChannels, likedBloggers)))
                if (res.next === null) setIsNextExists(false)
                setListsStatus({ error: false, loading: false })
            })
            .catch(() => {
                setListsStatus({ error: true, loading: false })
            })
    }, [page, token, dispatch, unlockedChannels, activeListId, likedBloggers, sort, hasuraError, hasuraLoading])

    //pagination logic
    const handleScroll = useCallback(() => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

        if (bottom) {
            //chech if it is allowed to fetch another page
            const status = !listsStatus.error && !listsStatus.loading

            const isReady = isNextExists && status

            //change page, so next page will be fetched with useEffect
            if (isReady) {
                setPage((prevPage) => {
                    return { listID: prevPage.listID, page: prevPage.page + 1 }
                })
            }
        }
    }, [listsStatus, isNextExists])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true,
        })

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [handleScroll, listsStatus, prevData])

    //scroll for 4k displays
    const temp = document.getElementById('listItemsID')
    useEffect(() => {
        function scrollTrigger() {
            //@ts-ignore
            var el = this
            var sc = el.scrollHeight - el.clientHeight - el.scrollTop

            if (sc < 1) {
                //chech if it is allowed to fetch another page
                const status = !listsStatus.error && !listsStatus.loading

                const isReady = isNextExists && status

                //change page, so next page will be fetched with useEffect
                if (isReady) {
                    setPage((prevPage) => {
                        return { listID: prevPage.listID, page: prevPage.page + 1 }
                    })
                }
            }
        }
        if (!temp) return
        temp.addEventListener('scroll', scrollTrigger, {
            passive: true,
        })

        return () => {
            temp.removeEventListener('scroll', scrollTrigger)
        }
    }, [temp, page, isNextExists, listsStatus])

    return listsStatus
}
export default GetBloggers
