import { ICustomListReq } from '@/pages/Lists/model/types'
import Service from '@/_helpers/Service'

export interface TemplateResponse {
    count: number
    next: any
    previous: any
    results: Template[]
}
export interface Template {
    id: number
    name: string
    message: string
    sender: string
    subject: string
}
const getTemplates = new Service<TemplateResponse>('')
export const getTemplatesReq = (token: string) => {
    return getTemplates.call(
        {
            url: '/core/outreach/templates/',
        },
        token
    )
}

const createTemplate = new Service('')
export const createTemplateReq = (token: string, data: any) => {
    return createTemplate.call(
        {
            url: '/core/outreach/templates/',
            data,
            method: 'POST',
        },
        token
    )
}

const saveDraft = new Service('')
export const saveDraftReq = (token: string, data: any) => {
    return saveDraft.call(
        {
            url: '/core/outreach/drafts/',
            data,
            method: 'POST',
        },
        token
    )
}

export interface UserList {
    id: number
    name: string
    qty: number
}
export interface UserListsResponse {
    count: number
    next: string | null
    previous: string | null
    results: UserList[]
}
const getUserLists = new Service<UserListsResponse>('')
export const getUserListsReq = (token: string, page: number) => {
    return getUserLists.call(
        {
            url: `/api/sets/list/?page=${page}`,
        },
        token
    )
}

export interface UserListResponse {
    count: number
    next: string | null
    previous: string | null
    results: ICustomListReq[]
}

const getUserList = new Service<UserListResponse>('')
export const getUserListReq = (token: string, customListId: number, page: number) => {
    return getUserList.call(
        {
            url: `/api/sets/blogger/?set=${customListId}&page=${page}`,
        },
        token
    )
}

export interface Participant {
    id: string
    internal_id: number
    name: string
    email: string
    role: string
    avatar: string
    er: number
    followers: number
    has_email: boolean
}

interface GetEmailsResponse {
    participants: Participant[]
}

export const getEmails = new Service<GetEmailsResponse>('')
export const getEmailsReq = (token: string, data: { names: string[] }) => {
    return getEmails.call(
        {
            url: '/core/outreach/participants/enrich',
            data,
            method: 'POST',
        },
        token
    )
}

interface GptSettingsResponse {
    prompt: string
    max_tokens: number
    temperature: number
}

export const getGptSettings = new Service<GptSettingsResponse>('')
export const getGptSettingsReq = (token: string) => {
    return getGptSettings.call(
        {
            url: '/core/outreach/gpt/settings/',
        },
        token
    )
}
