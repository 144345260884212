import React from 'react';

export default props => (
	<svg {...props} width="60" height="15" viewBox="0 0 60 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M5.29587 2.65655L4.15212 2.81784L6.32429 7.35294H6.38196L8.55413 2.81784L7.41999 2.65655V0.986717H12.7159V2.65655L11.524 2.8463L7.71794 9.94308V12.8558L9.21731 13.1404V14.8008H3.42165V13.1404L4.92103 12.8558V9.80076L1.1822 2.8463L0 2.65655V0.986717H5.29587V2.65655Z" fill="white"/>
		<path d="M11.8374 9.57306C11.8374 8.04238 12.2699 6.79001 13.1349 5.81594C14.0063 4.83555 15.2078 4.34535 16.7392 4.34535C18.277 4.34535 19.4784 4.83239 20.3434 5.80645C21.2149 6.78052 21.6506 8.03605 21.6506 9.57306V9.7723C21.6506 11.3156 21.2149 12.5743 20.3434 13.5484C19.4784 14.5161 18.2834 15 16.7584 15C15.2142 15 14.0063 14.5161 13.1349 13.5484C12.2699 12.5743 11.8374 11.3156 11.8374 9.7723V9.57306ZM14.6439 9.7723C14.6439 10.7084 14.8105 11.4611 15.1437 12.0304C15.4769 12.5933 16.0151 12.8748 16.7584 12.8748C17.4825 12.8748 18.0111 12.5901 18.3443 12.0209C18.6839 11.4516 18.8537 10.7021 18.8537 9.7723V9.57306C18.8537 8.66224 18.6839 7.91904 18.3443 7.34345C18.0047 6.76787 17.4696 6.48008 16.7392 6.48008C16.0087 6.48008 15.4769 6.76787 15.1437 7.34345C14.8105 7.91904 14.6439 8.66224 14.6439 9.57306V9.7723Z" fill="white"/>
		<path d="M22.3811 1.66983V0H26.687V12.8558L28.0422 13.1404V14.8008H22.5252V13.1404L23.8804 12.8558V1.95446L22.3811 1.66983Z" fill="white"/>
		<path d="M28.7726 9.57306C28.7726 8.04238 29.2051 6.79001 30.0702 5.81594C30.9416 4.83555 32.143 4.34535 33.6744 4.34535C35.2123 4.34535 36.4137 4.83239 37.2787 5.80645C38.1501 6.78052 38.5858 8.03605 38.5858 9.57306V9.7723C38.5858 11.3156 38.1501 12.5743 37.2787 13.5484C36.4137 14.5161 35.2187 15 33.6937 15C32.1494 15 30.9416 14.5161 30.0702 13.5484C29.2051 12.5743 28.7726 11.3156 28.7726 9.7723V9.57306ZM31.5792 9.7723C31.5792 10.7084 31.7457 11.4611 32.0789 12.0304C32.4121 12.5933 32.9504 12.8748 33.6937 12.8748C34.4177 12.8748 34.9463 12.5901 35.2795 12.0209C35.6191 11.4516 35.7889 10.7021 35.7889 9.7723V9.57306C35.7889 8.66224 35.6191 7.91904 35.2795 7.34345C34.9399 6.76787 34.4049 6.48008 33.6744 6.48008C32.944 6.48008 32.4121 6.76787 32.0789 7.34345C31.7457 7.91904 31.5792 8.66224 31.5792 9.57306V9.7723Z" fill="white"/>
		<path d="M44.6891 12.8748C45.1889 12.8748 45.5893 12.7324 45.8905 12.4478C46.1917 12.1569 46.3422 11.771 46.3422 11.2903H48.8796L48.9085 11.3472C48.9341 12.3846 48.54 13.2543 47.7263 13.9564C46.9125 14.6521 45.9001 15 44.6891 15C43.1384 15 41.9434 14.5161 41.104 13.5484C40.2646 12.5806 39.8449 11.3378 39.8449 9.81973V9.5351C39.8449 8.0234 40.2742 6.78052 41.1329 5.80645C41.9979 4.83239 43.2281 4.34535 44.8236 4.34535C45.663 4.34535 46.4159 4.46869 47.0823 4.71537C47.7487 4.96205 48.303 5.30993 48.7451 5.75901L48.7835 8.50095H46.5056L46.0443 6.89753C45.9033 6.77736 45.7303 6.67932 45.5253 6.60342C45.3202 6.52119 45.0863 6.48008 44.8236 6.48008C44.0291 6.48008 43.4684 6.76471 43.1416 7.33397C42.8149 7.90323 42.6515 8.63694 42.6515 9.5351V9.81973C42.6515 10.7369 42.802 11.4769 43.1032 12.0398C43.4044 12.5965 43.933 12.8748 44.6891 12.8748Z" fill="white"/>
		<path d="M50.1868 9.57306C50.1868 8.04238 50.6193 6.79001 51.4843 5.81594C52.3558 4.83555 53.5572 4.34535 55.0886 4.34535C56.6264 4.34535 57.8278 4.83239 58.6929 5.80645C59.5643 6.78052 60 8.03605 60 9.57306V9.7723C60 11.3156 59.5643 12.5743 58.6929 13.5484C57.8278 14.5161 56.6328 15 55.1078 15C53.5636 15 52.3558 14.5161 51.4843 13.5484C50.6193 12.5743 50.1868 11.3156 50.1868 9.7723V9.57306ZM52.9933 9.7723C52.9933 10.7084 53.1599 11.4611 53.4931 12.0304C53.8263 12.5933 54.3645 12.8748 55.1078 12.8748C55.8319 12.8748 56.3605 12.5901 56.6937 12.0209C57.0333 11.4516 57.2031 10.7021 57.2031 9.7723V9.57306C57.2031 8.66224 57.0333 7.91904 56.6937 7.34345C56.3541 6.76787 55.8191 6.48008 55.0886 6.48008C54.3581 6.48008 53.8263 6.76787 53.4931 7.34345C53.1599 7.91904 52.9933 8.66224 52.9933 9.57306V9.7723Z" fill="white"/>
	</svg>
)