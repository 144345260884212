import React from 'react'
import { useTranslation } from 'react-i18next'

import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'

import cn from '../../CatalogOfLists/CatalogOfLists.module.scss'

const EmptyPage = () => {
    const { t } = useTranslation()

    return (
        <>
            <Flex className={cn.empty} align="center" content="center" column>
                <Flex className={cn.wrap}>
                    <Icons name="GroupList" className={cn.icon} />
                </Flex>

                <p className={cn.title}>{t('aud_insides.lists.empty')}</p>
                <p className={cn['title-sub']}>{t('aud_insides.lists.empty_sub')}</p>
            </Flex>
        </>
    )
}

export default EmptyPage
