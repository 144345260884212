import React, { FC, ReactNode, useMemo } from 'react'
import { useLocation } from 'react-router'
// import { Adapters } from '../Bloggers/SearchPanel/SearchInput/AdapterSelector'
import { platforms } from '../Bloggers/SearchPanel/TabAdapterSelctor/AdapterSelector'
import { findAdapterType } from '../utils/findAdapterType'
import { Adapter } from './Adapter'

import { AdapterContext as Context } from './AdapterContext'
import { InstagramAdapter } from './bloggers/instagram'
import { YoutubeAdapter } from './bloggers/youtube'
import { useInstagramHidden } from '@/_helpers/Hooks/useIsInstagramHidden'

export interface IAdapterProvider {
    children?: ReactNode
}

const AdapterProvider: FC<IAdapterProvider> = (props) => {
    const loc = useLocation()
    const location = findAdapterType(loc.pathname)

    const isInstagramHidden = useInstagramHidden()

    // @ts-ignore
    const value = useMemo<{ adapter: Adapter<any, any, any, any> }>(() => {
        const defaultAdapter = !isInstagramHidden ? InstagramAdapter : YoutubeAdapter
        return {
            adapter: platforms.find((i) => i.id === location)?.adapter ?? defaultAdapter,
        }
    }, [location, isInstagramHidden])

    return <Context.Provider value={value}>{props.children}</Context.Provider>
}

export default AdapterProvider
