import { Platform } from '@/pages/Reports/types'
import { Currency, CurrencyRaw, MediaPlanData, MediaPlanOrderRaw } from './types'

const parseBloggerData = (data: string) => {
    const parsed = JSON.parse(data)
    const ageGender = {}

    console.log(parsed)
    if (parsed.audience.age_gender === undefined) {
        return parsed
    }

    for (let item of parsed.audience.age_gender) {
        // @ts-ignore
        ageGender[item.group] = { ...item, dist: item.man_share + item.woman_share }
    }

    const formatted = {
        ...parsed,
        audience: {
            ...parsed.audience,
            ageGender,
        },
    }

    return formatted
}

const getCurrencySign = (currency: CurrencyRaw): Currency => {
    switch (currency) {
        case 'RUB':
            return '₽'
        case 'USD':
            return '$'
        case 'EUR':
            return '€'
        default:
            return '₽'
    }
}

export const preprocessMediaPlan = (data: MediaPlanOrderRaw) => {
    return {
        ...data,
        data: JSON.parse(data.data) as MediaPlanData,
        currency: getCurrencySign(data.currency),
        bloggers: data.bloggers
            .filter((blogger) => blogger.data)
            .map((blogger) => ({
                ...blogger,
                customData: JSON.parse(blogger.customData),
                data: parseBloggerData(blogger.data),
                bloggerType: blogger.bloggerType.toLowerCase() as Platform,
            })),
    }
}
