import { gql } from '@apollo/client'

export const GET_REPORT_DATA = gql`
    query GET_REPORT_DATA($id: String!) {
        getChannel(channel_id: $id) {
            data
        }
    }
`

export const GET_VIDEOS_DATA = gql`
    query GET_VIDEO_DATA($id: String!) {
        getVideos(channel_id: $id) {
            data
        }
    }
`
