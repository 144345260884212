import React, { FC, memo, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import cn from './Filters.module.scss'
import { Flex, GlobalWidth } from '@/_yc'
import useClass from '@/_helpers/Hooks/useClass'
import { useAdapter, useSearch } from '../../../SearchAdapter'
import Heading from '@/components/Heading'
import Button from '@/components/Button'
import Text from '@/components/Text'

import FilterItem from './FilterItem'
import SearchButton from './SearchButton'
import Icons from '@/components/Icons'
import useWindowDimensions from '@/_helpers/Resize/useWindowDimensions'
import { useInstagramHidden } from '@/_helpers/Hooks/useIsInstagramHidden'

export interface IFilters {
    isOpen?: boolean
    className?: string
    onClose?: VoidFunction
}

const grow = { flexGrow: 1, padding: 0 }

const Filters: FC<IFilters> = ({ onClose, ...props }) => {
    const root = useClass(props.isOpen ? cn['root--openned'] : cn['root--closed'], props.className)

    const { filters, title: platform } = useAdapter()

    const { t } = useTranslation()

    const { restart, clearFilters } = useSearch()

    const { width } = useWindowDimensions()

    const mod = useMemo(() => {
        if (width >= 1280) return 6
        if (width >= 744) return 4
        return 2
    }, [width])

    const arrays = useMemo(() => {
        return Object.entries(filters).map(([name, val]) => ({
            title: val.title,
            name,
            filters: Object.entries(val.filters),
        }))
    }, [filters])

    const search = useCallback(() => {
        onClose && onClose()
        setTimeout(() => {
            restart()
        }, 600)
    }, [onClose, restart])

    const scrollToFilter = async (ref: any) => {
        ref.current.scrollIntoView({ block: 'end' })
    }

    const isInstagramHidden = useInstagramHidden()

    const isSquare = useMemo(() => {
        if (platform === 'Instagram') return true
        if (platform === 'YouTube' && isInstagramHidden) return true
    }, [isInstagramHidden, platform])

    return (
        <GlobalWidth style={grow}>
            <Flex column className={root} style={{ borderRadius: isSquare ? '0px 10px 10px 10px' : '10px' }}>
                <div className={cn.filtersHeader}>
                    <Flex content="space-between">
                        <Text fSize={16}>{t('Influencer_explorer.popup_title')}</Text>
                        <Icons name="close" style={{ cursor: 'pointer' }} onClick={onClose} />
                    </Flex>
                    <hr className={cn.divider}></hr>
                </div>
                <div className={cn.filtersContainer}>
                    {arrays.map((category, i) => {
                        let sum = 1

                        return (
                            <div key={i}>
                                <Heading
                                    type="h6"
                                    color="gray-new-2"
                                    margin="0 0 16px"
                                    fSize={14}
                                    style={{
                                        fontFamily: 'Montserrat',
                                    }}
                                >
                                    {t(category.title)}
                                </Heading>
                                <div className={cn.grid}>
                                    {category.filters.map(([name, Filter]) => {
                                        const result = (
                                            <FilterItem
                                                key={`${category.name}.${name}`}
                                                filter={Filter}
                                                start={sum}
                                                end={sum + Filter.options.space}
                                                categoryName={category.name}
                                                name={name}
                                                scrollToFilter={scrollToFilter}
                                            />
                                        )
                                        sum = (sum + Filter.options.space) % mod || 1

                                        return result
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className={cn.btm_container}>
                    <Flex align="center" className={cn.bottom_bar}>
                        <Button margin="0 0 0 20px" variant="transparent" className={cn.btn} onClick={onClose}>
                            <Icons className={cn.icon} name="slider" margin="0 10px -5px 0" />
                            {t('Influencer_explorer.hide_filters')}
                        </Button>
                        <div className={cn.clearFilters} onClick={clearFilters}>
                            <Icons margin="0 8px 0 0" name="trash" />
                            <Text color="gray-new-2">{t('Influencer_explorer.clear')}</Text>
                        </div>
                        <SearchButton onClick={search} platform={platform} />
                    </Flex>
                </div>
            </Flex>
        </GlobalWidth>
    )
}

export default memo(Filters)
