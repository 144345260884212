export default () => {
	/* const jivosite = document.createElement("script");

	jivosite.setAttribute("async", "async");
	jivosite.setAttribute("data-jv-id", "QLldWFNjSn");

	document.body.appendChild(jivosite);
	jivosite.setAttribute("src", "//code.jivosite.com/widget.js"); */

	/*const yandex = document.createElement("script");
	yandex.type = "text/javascript";
	yandex.innerHTML = `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); ym(55958284, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true });`
	document.body.appendChild(yandex);
*/
	const gtag = document.createElement("script");
	gtag.setAttribute("async", "async");
	document.body.appendChild(gtag);
	gtag.src = "https://www.googletagmanager.com/gtag/js?id=UA-143288997-1";

	const gtag2 = document.createElement("script");
	gtag2.innerHTML = `
window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-143288997-1');
`
	document.body.appendChild(gtag2);

	const pixel = document.createElement("script");
	pixel.innerHTML = `
!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '2392477517714868');
  fbq('track', 'PageView');
`
	document.body.appendChild(pixel);
}