import Avatar from '@/components/Avatar'
import { ID } from '@/Reponses'
import React, { FC, memo, useCallback } from 'react'
import cn from './Matrix.module.scss'

export interface ISelectableAvatar {
    src: string
    id: ID
    onClick?: (id: ID) => void
    active?: boolean
    isAllSetted?: boolean
}

const SelectableAvatar: FC<ISelectableAvatar> = ({ onClick, ...props }) => {
    const click = useCallback(() => {
        onClick && onClick(+props.id)
    }, [onClick, props.id])

    return (
        <Avatar
            src={props.src}
            onClick={click}
            size={40}
            className={
                props.active && props.isAllSetted
                    ? cn['avatar--disabled']
                    : props.active
                    ? cn['avatar--active']
                    : cn.avatar
            }
        />
    )
}

export default memo(SelectableAvatar)
