import { useCallback, useMemo, FC, useContext, SVGAttributes } from 'react'
import { useSelector, useStore } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import { clearFilter, setFilter, setClickedFilter } from '../../Model/actions'
import { FilterIdContext } from '../FilterIdContext'

export type FilterPlatform =
    | 'infl_exp_ig'
    | 'infl_exp_yt'
    | 'ads_yt'
    | 'ads_ig'
    | 'infl_exp_twitch'
    | 'infl_vk'
    | 'infl_tg'
    | 'infl_tiktok'
export interface BasicFiltersOptions<ToServerData, DataType> {
    title: string
    autocomplete?: boolean
    icon?: SVGAttributes<SVGElement>
    helper?: string
    id?: string
    space: number
    featureKey?: string
    beforeSend(value: DataType): ToServerData
    locked?: (feats: string[], isPromo?: boolean) => boolean
    isAvailable?: boolean
    innerMeta?: boolean
    withAny?: boolean
    platform: FilterPlatform
    soon?: boolean
}

type Setter<DataType> = (next: ((prev: DataType) => DataType | undefined) | DataType | undefined) => void

export abstract class BasicFilter<ToServerData, DataType = void> {
    public abstract getDefaultValue(): DataType
    public abstract View: FC<{
        disabled: boolean
        filterName: string
        autocomplete?: boolean
        scrollToFilter?: Function
    }>
    public abstract MainView: FC<{
        filterName: string
        autocomplete?: boolean
    }>
    public abstract Tags: FC<{}>

    constructor(public options: BasicFiltersOptions<ToServerData, DataType>) {}

    public get id() {
        return this.options.id
    }

    /**
     * @description filter state control hook;
     * @returns [state, setterFunction, clear]; filter will be cleared if setterFunction returns undefined
     */
    protected useFilterState(): [DataType, Setter<DataType>, VoidFunction, boolean, Function] {
        const filterId = useContext(FilterIdContext)

        const selected = useSelector((store: RootStore) => store.search.filters[filterId]?.value)

        const store = useStore<RootStore>()

        const value = useMemo(() => {
            return selected || this.getDefaultValue()
        }, [selected])

        const clear = useCallback(() => {
            store.dispatch(clearFilter({ key: filterId }))
        }, [store, filterId])

        const change: Setter<DataType> = useCallback(
            (next) => {
                let toSet: DataType | undefined = undefined
                if (next instanceof Function) {
                    const current = store.getState().search.filters[filterId]?.value || this.getDefaultValue()
                    toSet = next(current)
                } else {
                    toSet = next
                }
                if (typeof toSet === 'undefined') {
                    clear()
                    return
                }
                store.dispatch(setFilter({ key: filterId, value: toSet }))
            },
            [store, filterId, clear]
        )

        const clickedValInitial = useSelector((store: RootStore) => store.clickedFilter['clickedFilter'])
        const clickedVal = useMemo(() => {
            return clickedValInitial === filterId
        }, [clickedValInitial, filterId])
        const setClicked = useCallback(() => {
            store.dispatch(setClickedFilter({ filterId: !!clickedVal ? null : filterId }))
        }, [store, filterId, clickedVal])

        return [value, change, clear, clickedVal, setClicked]
    }
    protected useIsLocked(): boolean {
        const store = useSelector((state: RootStore) => state.authentication.user?.profile)
        const isPromo = useSelector((state: RootStore) => state.authentication.user?.profile?.isPromo)

        if (!this.options.locked) {
            return false
        }
        if (store) {
            return this.options.locked(store.features, isPromo)
        }
        return true
    }

    protected usePlan(): string {
        const store = useSelector((state: RootStore) => state.authentication.user?.profile)
        return store?.package || ''
    }
}
