import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'

import { CommonError, Flex, GlobalWidth } from '@/_yc'
import { INTERSECTIONS_DEFAULT } from '@/routs'
import Overview from './Overview'
import Matrix from './Matrix'
import cn from './Intersection.module.scss'
import useExcelLoader from './useExcelLoader'
import servs from '../Model/service'
import { useSelector } from 'react-redux'
import LinksUnderPageTitle from '@/components/LinksUnderPageTitle'

import Heading from '@/components/Heading'
import Text from '@/components/Text'
import Icons from '@/components/Icons'

import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { chainComponents } from '@/_helpers/chainComponents'
import Info from './Info'
import DownloadButton from './DownloadButton'
import Loader from '@/components/Loader'

const tooltipClasses = {
    tooltip: cn.tooltip,
    arrow: cn.arrow,
    popper: cn.popper,
}

const BackLink = chainComponents(Flex, chainComponents(Text, Link))

const Intersection = ({
    data: { name, overview, matrix, meta },
    loaded,
    error,
    id,
    isDemo,
    pdfLoader,
}) => {
    const t = useSpaceTranslation('intersections.intersection')
    const linkToHelper = useSelector((state) => state.Commercial_.value.hp_aoverlap)

    const download = useCallback(() => {
        pdfLoader.startLoading(name, id)
        //eslint-disable-next-line
    }, [name, id])
    const [xlLoading, downloadExcel] = useExcelLoader(
        name,
        id,
        servs.requireExcel
    )
    return (
        <>
            <div className={cn.head}>
                <GlobalWidth>
                    <Heading color="gray-1" type="h3">
                        {isDemo
                            ? t('intersections.dashboards.demo.title', undefined, true)
                            : t('intersections.dashboards.title', undefined, true)}
                        <div style={{ marginTop: 7 }}>
                            <LinksUnderPageTitle link={linkToHelper} />
                        </div>
                    </Heading>
                    <Flex className={cn.subline} align="flex-end" content="space-between">
                        <BackLink align="center" color="gray-4" semibold to={INTERSECTIONS_DEFAULT}>
                            <Icons className={cn.icon} name="short--left" margin="0 6px 0 0" />
                            {t('backbtn')}
                        </BackLink>
                        {!isDemo && (
                            <DownloadButton
                                loading={pdfLoader.loading || xlLoading}
                                pdf={download}
                                excel={downloadExcel}
                            />
                        )}
                    </Flex>
                </GlobalWidth>
            </div>
            <GlobalWidth>
                {!loaded && (
                    <div className={cn.center}>
                        <Loader />
                    </div>
                )}
                {loaded && !error && (
                    <>
                        <Info
                            margin="-44px 0 30px"
                            name={name}
                            all={meta.followersSum}
                            unique={meta.sumOfUnique}
                            count={meta.count}
                            uniqueToSum={meta.uniqueToSum}
                        />
                        <Heading type="h5" color="gray-4">
                            {t('title')}
                        </Heading>
                        <Overview margin="16px 0 0" data={overview} />
                        <div className={cn.matrix}>
                            <Heading color="gray-4" type="h5">
                                {t('matrix')}
                            </Heading>

                            <Tooltip classes={tooltipClasses} arrow title={t('tooltip')}>
                                <div>
                                    <Icons name="quest" />
                                </div>
                            </Tooltip>
                        </div>
                        <div className={cn['matrix-container']}>
                            <Matrix legend={matrix.legend} rows={matrix.rows} />
                        </div>
                    </>
                )}
                {error && <CommonError />}
            </GlobalWidth>
        </>
    )
}

export { Intersection as default }
