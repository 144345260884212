import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootStore } from '@/_helpers/StoreType'
import { Platform } from '@/pages/Reports/types'
import { addToSelectedCampaignsAr } from '@/pages/Outreach/Model/actions'
import { addToSelectedAccounts } from '@/pages/SettingsPage/Model/actions'
import { CheckType } from '.'
import {
    AddToListINST,
    AddToListYT,
    AddToListTwitch,
    AddToListsAud,
    AddToListMediaPlannerPlans,
    AddToListMediaPlannerBloggers,
    AddToListTg,
} from './_model/actions'
import { IBlogger } from './_model/types'

export const UseShift = (type: CheckType, index: number, place?: 'listBloggers') => {
    const dispatch = useDispatch()

    const AllBloggers = useSelector((store: RootStore) => store.MassAddToList.allItems.allBloggers)
    const allListsAud = useSelector((store: RootStore) => store.AudienceInsights.ListsInfo)
    const allListBloggers = useSelector((store: RootStore) => store.AudienceInsights.BloggersAndChannels)
    const allOutreachCampaings = useSelector((store: RootStore) => store.Outreach.advertisingCampaigns.results)
    const allEmailAccounts = useSelector((store: RootStore) => store.Settings.emailAccounts)
    const allMediaPlans = useSelector((store: RootStore) => store.MassAddToList.allItems.allMediaPlans)
    const allMediaBloggers = useSelector((store: RootStore) => store.MassAddToList.allItems.allMediaBloggers)

    const LastClicked = useSelector((store: RootStore) => store.MassAddToList.lastClicked)

    const addWithShift = () => {
        if (!LastClicked) return
        const handleIndexes = () => {
            return [index !== 0 ? index - 1 : index, LastClicked !== 0 ? LastClicked - 1 : LastClicked]
        }
        const inds = index > LastClicked ? [LastClicked, index] : handleIndexes()

        switch (type) {
            case 'lists':
                dispatch(AddToListsAud(allListsAud.slice(inds[0], inds[1])))
                return
            case 'outreach':
                const t = allOutreachCampaings.slice(inds[0], inds[1]).map((item) => item.id.toString())
                dispatch(addToSelectedCampaignsAr(t))
                return
            case 'settings-accounts':
                dispatch(addToSelectedAccounts(allEmailAccounts.slice(inds[0], inds[1]).map((item) => item.email)))
                return
            case 'media-planner-plans':
                dispatch(AddToListMediaPlannerPlans(allMediaPlans.slice(inds[0], inds[1])))
                return
            case 'media-planner-bloggers':
                dispatch(AddToListMediaPlannerBloggers(allMediaBloggers.slice(inds[0], inds[1])))
                return
            default:
                let inst: IBlogger[] = []
                let yt: IBlogger[] = []
                let tw: IBlogger[] = []
                let tg: IBlogger[] = []
                if (place === 'listBloggers') {
                    allListBloggers.slice(inds[0], inds[1]).forEach((item) => {
                        switch (item.type) {
                            case Platform.Instagram:
                                inst.push({
                                    id: item.id.toString(),
                                    externalId: item.external_id,
                                    bloggerId: item.bloggerId,
                                    name: item.fullName || '',
                                    avatar: item.avatar,
                                    username: item.userName,
                                    hasEmail: item.has_email,
                                    platform: Platform.Instagram,
                                })
                                return
                            case Platform.YouTube:
                                yt.push({
                                    id: item.id.toString(),
                                    channelId: item.channelId,
                                    name: item.fullName || '',
                                    avatar: item.avatar,
                                    username: item.userName,
                                    hasEmail: item.has_email,
                                    platform: Platform.YouTube,
                                })
                                return
                            case Platform.Twitch:
                                tw.push({
                                    id: item.id.toString(),
                                    channelId: item.channelId,
                                    name: item.fullName || '',
                                    avatar: item.avatar,
                                    username: item.userName,
                                    hasEmail: item.has_email,
                                    platform: Platform.Twitch,
                                })
                                return
                            case Platform.Tg:
                                tg.push({
                                    id: item.id.toString(),
                                    channelId: item.external_id,
                                    name: item.fullName || '',
                                    avatar: item.avatar,
                                    username: item.userName,
                                    hasEmail: item.has_email,
                                    platform: Platform.Tg,
                                })
                                return
                        }
                    })
                } else {
                    AllBloggers.slice(inds[0], inds[1]).forEach((item) => {
                        switch (item.platform) {
                            case Platform.Instagram:
                                inst.push(item)
                                return
                            case Platform.YouTube:
                                yt.push(item)
                                return
                            case Platform.Twitch:
                                tw.push(item)
                                return
                            case Platform.Tg:
                                tg.push(item)
                                return
                            default:
                                return
                        }
                    })
                }
                dispatch(AddToListINST(inst))
                dispatch(AddToListYT(yt))
                dispatch(AddToListTwitch(tw))
                dispatch(AddToListTg(tg))
        }
        return
    }

    const [ShiftDown, setShiftDown] = useState<boolean>(false)
    const handleShiftDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Shift' && LastClicked && LastClicked === index) {
                setShiftDown(e.type === 'keydown')
                return
            }
        },
        [LastClicked, index]
    )
    const handleShiftUp = useCallback((e: KeyboardEvent) => {
        setShiftDown(false)
    }, [])

    useEffect(() => {
        window.addEventListener('keydown', handleShiftDown)
        return () => window.removeEventListener('keydown', handleShiftDown)
    }, [LastClicked, handleShiftDown])
    useEffect(() => {
        window.addEventListener('keyup', handleShiftUp)
        return () => window.removeEventListener('keyup', handleShiftUp)
    }, [LastClicked, handleShiftUp])

    return {
        addWithShift,
        ShiftDown,
    }
}
