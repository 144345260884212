import React, { PureComponent } from 'react';


export default Component => class withLogic extends PureComponent {
	static get displayName(){
		return `withLogic(${Component.displayName || Component.name || "Component"})`
	}

	state = {
		value: ""
	}
	handleChange = this.handleChange.bind(this)
	handleEnter = this.handleEnter.bind(this)

	handleChange(value){
		if (value.search(/\s/)!==-1) {
			if (value.length === 1) return
			const bloggers = value.trim().split(/\s/).filter(item => item.length > 0);

			if(this.props.value.length >= this.props.limit) return

			this.props.onChange(bloggers);
			this.setState({ value: "" })
		}else{
			this.setState({ value })
		}
	}
	handleEnter(){
		if (!this.state.value) return
		if(this.props.value.length >= this.props.limit) return
		this.props.onChange([this.state.value]);
		this.setState({ value: "" })
	}
	render(){

		const { onChange, value, ...rest } = this.props;

		return <Component 
			{...rest}
			items = {value} 
			value = {this.state.value} 
			onChange={this.handleChange} 
			onEnter={this.handleEnter}
		/>
	}
} 