import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Flex from '@/_yc/Flex'
import Text from '@/components/Text'
import { RootStore } from '@/_helpers/StoreType'
import { getNumberWithSpaces } from '@/_helpers/nums/numberWithSpaces'
import HiddenMetric from '@/components/HiddenMetric'

import { Mock } from '../../Mock/Mock'

import cn from './Stats.module.scss'

export const StatsTable = () => {
    const { t } = useTranslation()
    const head = [
        t('report_layout.graph.date'),
        t('report_layout.graph.subscriptions'),
        t('report_layout.graph.growth'),
        t('report_layout.graph.comments'),
        t('report_layout.graph.reactions'),
        t('report_layout.graph.views'),
    ]

    const data = useSelector((store: RootStore) => {
        return store.report.rawTgData?.getTelegraReport?.data.telegram_channel[0].telegram_channelmetricshistorical
    })

    const getDynamic = (i: number, type: 'subscriber_count'): { value: string; state: number } => {
        const getState = (n: number) => {
            if (n > 0) return 0
            if (n < 0) return 1
            return 2
        }
        if (!data || !data[i + 1] || !data[i + 1][type]) return { value: '0', state: 2 }
        const temp = data[i][type] - data[i + 1][type]

        return {
            value: `${temp > 0 ? '+' : ''}${getNumberWithSpaces(temp).length === 0 ? '0' : getNumberWithSpaces(temp)}`,
            state: getState(temp),
        }
    }
    return (
        <>
            <div style={{ overflow: 'scroll', position: 'relative' }} className={cn.table}>
                {data && data.length > 0 ? (
                    <>
                        <div className={cn['table-header']}>
                            {head.map((item) => (
                                <Text color="gray-3" fSize="10px">
                                    {item ?? ''}
                                </Text>
                            ))}
                        </div>
                        <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                            {data.map((item, i) => (
                                <>
                                    <div className={cn['table-item']} key={item.id + i + item.subscriber_count}>
                                        <Text color="gray-1" fSize="12px">
                                            {new Date(item.created_at).toLocaleDateString('ru')}
                                        </Text>

                                        <Text color="gray-1" fSize="12px">
                                            {item.subscriber_count ? getNumberWithSpaces(item.subscriber_count) : ''}
                                        </Text>

                                        <Flex
                                            style={{
                                                padding: '3px 8px',
                                                backgroundColor:
                                                    getDynamic(i, 'subscriber_count').state === 0
                                                        ? '#dbf5e7'
                                                        : getDynamic(i, 'subscriber_count').state === 1
                                                        ? '#FFE1E7'
                                                        : '#E6EAF0',
                                                borderRadius: '4px',
                                                width: 'fit-content',
                                            }}
                                            align="center"
                                        >
                                            <Text
                                                style={
                                                    getDynamic(i, 'subscriber_count').state === 0
                                                        ? { color: '#10BF61' }
                                                        : getDynamic(i, 'subscriber_count').state === 1
                                                        ? { color: '#F5535D' }
                                                        : { color: '#5D697D' }
                                                }
                                                fSize={10}
                                            >
                                                {getDynamic(i, 'subscriber_count').value}
                                            </Text>
                                        </Flex>

                                        {item.comments_median_count ? (
                                            <Text color="gray-1" fSize="12px">
                                                {getNumberWithSpaces(item.comments_median_count)}
                                            </Text>
                                        ) : (
                                            <HiddenMetric />
                                        )}

                                        {item.reactions_median_count ? (
                                            <Text color="gray-1" fSize="12px">
                                                {getNumberWithSpaces(item.reactions_median_count)}
                                            </Text>
                                        ) : (
                                            <HiddenMetric />
                                        )}
                                        {item.views_median_count ? (
                                            <Text color="gray-1" fSize="12px">
                                                {getNumberWithSpaces(item.views_median_count)}
                                            </Text>
                                        ) : (
                                            <HiddenMetric />
                                        )}
                                    </div>
                                    <div className={cn.dividerHoriz} />
                                </>
                            ))}
                        </div>
                    </>
                ) : (
                    <Mock />
                )}
            </div>
        </>
    )
}
