import React from 'react';
import pt from 'prop-types'
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

const withDicts = (Component) => class extends React.Component{

	static get displayName() { 
		return `withDicts(${Component.displayName || Component.name || "Component"})`
	}

	static get propTypes() {
		return {
			value: pt.arrayOf(pt.shape({
				endpoint: pt.string
			})).isRequired,
			api: pt.shape({
				getDicts: pt.func.isRequired,
				unsubscribe: pt.func.isRequired
			}).isRequired,
			t: pt.func.isRequired
		}
	}

	constructor(props){
		super(props);
		
		this.initEntities = {};

		this.lang = props.i18n.language;

		this.toLoad = [];

		props.value.forEach(({ endpoint }) => {
			if (endpoint) {
				this.initEntities[endpoint] = { options: [] }
				this.toLoad.push(endpoint)
			}
		})
		this.state = { entities: this.initEntities }
	}

	componentDidUpdate(prevProps){
		if (this.lang !== prevProps.i18n.language){
			this.lang = prevProps.i18n.language;
			this.setState({
				entities: this.initEntities
			})
			this.props.api.getDicts(this.toLoad, this.recieveDicts.bind(this))
		}
	}

	componentDidMount(){
		this.props.api.getDicts(this.toLoad, this.recieveDicts.bind(this))
	}

	recieveDicts(ep, results){
		this.setState(prev => ({
			...prev, 
			entities: {
				...prev.entities,
				[ep]: {
					...prev.entities[ep],
					options: prev.entities[ep].options.concat(results)
				}
			}
		}))
	}
	componentWillUnmount(){
		this.props.api.unsubscribe();
	}
	render(){
		const value = this.props.value.map(item => {
			if (item.endpoint in this.state.entities) {
				return {
					...item,
					options: this.state.entities[item.endpoint].options
				}
			}
			return item
		})

		const { t, i18n, tReady, ...rest } = this.props;

		return (
			<Component {...rest} value = {value}/>
		)
	}
}

const composed = compose(
	withTranslation(),
	withDicts
)

export { composed as withDicts }