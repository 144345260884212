import Text from '@/components/Text'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import React, { ChangeEvent, FC, memo, useCallback } from 'react'
import { useAdapter, useSearchBy, useSearchText } from '../../../SearchAdapter'
import cn from './SearchInput.module.scss'
import { useSearch } from '../../../SearchAdapter'
import { KeyboardEvent } from 'react'
import { trackSearchValue } from '@/_helpers/amplitude/track'

export interface IConnectedInput {
    inFilter?: boolean
    focus?: VoidFunction
    blur?: VoidFunction
}

const ConnectedInput: FC<IConnectedInput> = (props) => {
    const { search, setSearch } = useSearchText()
    const { searchBy } = useSearchBy()

    const t = useSpaceTranslation('Influencer_explorer.search_input')
    const { title: platform } = useAdapter()

    const change = useCallback(
        (e: ChangeEvent<any>) => {
            setSearch(e.target.value)
        },
        [setSearch]
    )
    const { restart } = useSearch()
    const handleKeyDown = (e: KeyboardEvent): void => {
        if (e.key === 'Enter') {
            trackSearchValue(platform, search, searchBy)
            restart()
        }
    }

    return (
        <Text
            component={'input'}
            className={props.inFilter ? cn['input--inFilter'] : cn.input}
            semibold={!props.inFilter}
            fSize={props.inFilter ? 12 : 14}
            color="gray-4"
            placeholder={t('search')}
            value={search}
            onChange={change}
            onKeyDown={handleKeyDown}
            onFocus={props.focus}
            onBlur={props.blur}
        />
    )
}

export default memo(ConnectedInput)
