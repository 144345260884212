import { useMemo } from 'react'
import interpolate from 'color-interpolate'

const colormap = interpolate(['#FF3636', '#F5C208', '#0AAB4E'])

export const useInterpolate = (
    progress: number,
    reverse?: boolean | null,
    alpha?: number,
    colorMap: typeof colormap = colormap
) => {
    const color = useMemo(() => {
        const solid = colorMap(reverse ? 1 - progress : progress)
        return alpha ? solid.slice(0, -1) + `, ${alpha})` : solid
    }, [progress, reverse, alpha, colorMap])

    return color
}
