export default class Title {

    static instance: Title;

    private title: HTMLTitleElement = document.querySelector("title") || document.head.appendChild(document.createElement("title"));

    private _label: string = this.title.innerText
    private _prefix: string = "";

    constructor(){
        if (Title.instance) return Title.instance;
        else Title.instance = this;
    }

    get prefix(): string {
        return this._prefix;
    }

    set prefix(newVal: string) {
        this._prefix = newVal;
        this.update();
    }

    get label(): string{
        return this._label;
    }

    set label(value: string){
        this._label = value;
        this.update();
    }

    private update(){
        this.title.innerText = `${this._prefix ? this._prefix + " " : ""}${this._label}`
    }

}