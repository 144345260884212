import { createReducer } from '@reduxjs/toolkit'
import {
    setLists,
    updateList,
    delList,
    renameList,
    setListsCount,
    setRecentsCount,
    setActiveList,
    setBloggersAndChannels,
    delBloggersAndChannels,
    setBloggersAndChannelsDefault,
    setListLockedCount,
    setLikes,
    delLikes,
    setLikesDefault,
    updateQTY,
} from './actions'
import { IAudienceInsights } from './types'

const defaultState: IAudienceInsights = {
    ListsInfo: [],
    ListsCount: 0,
    RecentsCount: 0,
    ActiveListId: -1,
    BloggersAndChannels: [],
    listLockedCount: 0,
    likeBloggers: [],
}

const AudienceInsights = createReducer<IAudienceInsights>(defaultState, (builder) => {
    builder.addCase(setLists, (state, { payload }) => {
        if (payload) {
            if (payload.length === 0) {
                return {
                    ...state,
                    ListsInfo: payload,
                }
            }
            return {
                ...state,
                ListsInfo: state.ListsInfo.concat(payload),
            }
        }
    })
    builder.addCase(setListLockedCount, (state, { payload }) => {
        return {
            ...state,
            listLockedCount: payload,
        }
    })
    builder.addCase(updateList, (state, { payload }) => {
        if (payload) {
            const temp = state.ListsInfo.map((item) => {
                if (item.id === payload.id) {
                    return {
                        id: payload.id,
                        name: payload.name,
                        qty: item.qty + payload.qty,
                        comment: payload.comment,
                        tags: payload.tags,
                        modified_at: payload.modified_at,
                        influencers: payload.influencers,
                    }
                }
                return item
            })
            return {
                ...state,
                ListsInfo: temp,
            }
        }
    })
    builder.addCase(updateQTY, (state, { payload }) => {
        if (payload) {
            const temp = state.ListsInfo.map((item) => {
                if (item.id === payload.id) {
                    return {
                        ...item,
                        qty: payload.qty,
                    }
                }
                return item
            })
            return {
                ...state,
                ListsInfo: temp,
            }
        }
    })
    builder.addCase(setListsCount, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                ListsCount: payload,
            }
        }
    })
    builder.addCase(delList, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                ListsInfo: state.ListsInfo.filter((item) => item.id !== payload),
            }
        }
    })
    builder.addCase(renameList, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                ListsInfo: state.ListsInfo.map((item) => {
                    if (item.id === payload.id) {
                        return payload
                    }
                    return item
                }),
            }
        }
    })
    builder.addCase(setRecentsCount, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                RecentsCount: payload,
            }
        }
    })
    builder.addCase(setActiveList, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                ActiveListId: payload,
            }
        }
    })
    builder.addCase(setBloggersAndChannels, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                BloggersAndChannels: state.BloggersAndChannels.concat(payload),
            }
        }
    })
    builder.addCase(delBloggersAndChannels, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                BloggersAndChannels: state.BloggersAndChannels.filter((item) => item.id !== payload),
            }
        }
    })
    builder.addCase(setBloggersAndChannelsDefault, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                BloggersAndChannels: payload,
            }
        }
    })
    builder.addCase(setLikes, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                likeBloggers: state.likeBloggers.concat(payload),
            }
        }
    })
    builder.addCase(delLikes, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                likeBloggers: state.likeBloggers.filter((item) => item.id !== payload),
            }
        }
    })
    builder.addCase(setLikesDefault, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                likeBloggers: payload,
            }
        }
    })
})
export default AudienceInsights
