import Icons from '@/components/Icons'
import React from 'react'
import { RangeFilter, RangeValueType } from '../../../../filters/RangeFilter'
import { getRange } from '../../utils/filters/getRange'
import { PLATFORM } from '../consts'

const getGqlFilter = (range: RangeValueType) => {
    if (!range.from && !range.to) return ''

    return `average_video_reactions: ${getRange(range.from, range.to)}`
}

export const getReactionsFilter = () => {
    return new RangeFilter({
        platform: PLATFORM,
        title: 'filters.reactions.label',
        helper: 'filters.reactions.text',
        space: 1,
        icon: <Icons name="filter--likes" />,
        beforeSend: (data) => {
            return {
                name: 'metrics.reactions',
                type: 'range',
                value: data,
                gqlFilter: getGqlFilter(data),
            }
        },
    })
}
