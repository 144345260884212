import React, { PureComponent } from 'react';

const StorageKEY = "AJHAKLFLaks"

export default Storage => Component => class extends PureComponent {
	static get displayName(){
		return `withStoredValue(${Component.displayName || Component.name || "Component"})`
	}
	setHidden = this.setHidden.bind(this)

	state = {
		hide: true
	}
	componentDidMount(){
		this.setState({
			hide: !!Storage.getItem(StorageKEY)
		})
	}
	setHidden(){
		this.setState({
			hide: true
		})
		Storage.setItem(StorageKEY, "aaasf2")
	}

	render(){
		return <Component {...this.props} showCard={this.props.showCard && !this.state.hide} hideCard = {this.setHidden}/>
	}
}