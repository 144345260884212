import React, { SVGAttributes } from 'react'
export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="60" height="60" rx="30" fill="#A3ADFF" />
            <path
                d="M40.0828 42L32.3397 34.256C28.8951 36.7047 24.1487 36.1057 21.4205 32.878C18.6924 29.6503 18.8927 24.8707 21.8814 21.8826C24.8692 18.8932 29.6496 18.692 32.8781 21.4198C36.1066 24.1476 36.706 28.8943 34.2569 32.3389L42 40.083L40.0842 41.9986L40.0828 42ZM27.6288 22.2104C25.0595 22.2099 22.8429 24.0132 22.321 26.5288C21.7991 29.0443 23.1153 31.5806 25.4727 32.6021C27.8302 33.6236 30.5809 32.8495 32.0596 30.7485C33.5382 28.6475 33.3383 25.797 31.581 23.9229L32.4007 24.7358L31.4766 23.8145L31.4604 23.7983C30.4466 22.7784 29.0668 22.2066 27.6288 22.2104Z"
                fill="white"
            />
        </svg>
    )
}
