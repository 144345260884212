import { APIService } from '@/_helpers/APIService.js'
import { authHeader } from '@/_helpers/auth-header.js'

export const service = new APIService({
    methods: {
        getPdf: (id: string) => ({
            url: `api/youtube_reports/details/${id}/pdf/`,
            method: 'GET',
            responseType: 'arraybuffer',
            headers: authHeader(),
        }),
    },
})
