import React, { FC } from 'react'
import cn from './ValueHider.module.scss'

interface IValueHider {
    [key: string]: any
}

export const ValueHider: FC<IValueHider> = (props) => {
    return <div className={cn.hider} style={props.style} />
}
