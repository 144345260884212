import React, { SVGAttributes } from 'react'
const TooltipIconDisabled = (props: SVGAttributes<SVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#EBEBEB" />
        <path d="M8.79995 3.19995L8.79995 4.79995L7.19995 4.79995L7.19995 3.19995H8.79995Z" fill="#8F8F8F" />
        <path d="M7.19995 12.8L7.19995 6.39995H8.79995L8.79995 12.8H7.19995Z" fill="#8F8F8F" />
    </svg>
)

export default TooltipIconDisabled
