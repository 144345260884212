import React, { useState, useRef, useEffect } from 'react'
import styles from './multi-select.module.scss'
import { ReactComponent as ChevronDown } from '../../icons/chevron_down.svg'
import { ReactComponent as DoneIcon } from '../../icons/done.svg'
import { useTranslation } from 'react-i18next'

interface Option {
    label: string
    value: string
}

interface MultiSelectProps {
    options: { label: string; value: string; city_id?: number; region_id?: number }[]
    placeholder: string
    onChange: (selected: Option[]) => void
    enableSearch?: boolean
    loadOptions?: (searchTerm: string) => Promise<Option[]>
    initialSelected?: Option[]
}

const MultiSelect: React.FC<MultiSelectProps> = ({
    options,
    placeholder,
    onChange,
    enableSearch = false,
    loadOptions,
    initialSelected = [],
}) => {
    const [selectedOptions, setSelectedOptions] = useState<Option[]>(initialSelected)
    const [isOpen, setIsOpen] = useState(false)
    const [searchTerm, setSearchTerm] = useState('') // Поле для строки поиска
    const [filteredOptions, setFilteredOptions] = useState<Option[]>(options)
    const selectRef = useRef<HTMLDivElement>(null)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t, i18n } = useTranslation()

    useEffect(() => {
        onChange(selectedOptions)
        // eslint-disable-next-line
    }, [selectedOptions])

    useEffect(() => {
        setSelectedOptions(initialSelected)
        // eslint-disable-next-line
    }, [JSON.stringify(initialSelected)])

    const handleRemoveOption = (option: Option) => {
        setSelectedOptions(selectedOptions.filter((o) => o.value !== option.value))
    }

    const handleSelectOption = (option: Option) => {
        if (!selectedOptions.some((o) => o.value === option.value)) {
            setSelectedOptions([...selectedOptions, option])
        } else {
            handleRemoveOption(option)
        }
    }

    const handleRemoveAllOptions = (event: React.MouseEvent) => {
        event.stopPropagation()
        setSelectedOptions([])
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setIsOpen(false)
            setSearchTerm('')
        }
    }

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [isOpen])

    useEffect(() => {
        if (enableSearch && loadOptions) {
            const load = async () => {
                const result = await loadOptions(searchTerm)
                setFilteredOptions(result)
            }
            load()
        } else {
            const filtered = options.filter((option) => option.label?.toLowerCase().includes(searchTerm.toLowerCase()))
            setFilteredOptions(filtered)
        }
        // eslint-disable-next-line
    }, [searchTerm, options, enableSearch])

    useEffect(() => {
        const updateSelectedLabels = async () => {
            if (loadOptions && selectedOptions.length > 0) {
                const updatedOptions = await Promise.all(
                    selectedOptions.map(async (option) => {
                        const [updatedOption] = await loadOptions(option.label)
                        return updatedOption ? { ...option, label: updatedOption.label } : option
                    })
                )
                setSelectedOptions(updatedOptions)
            }
        }

        updateSelectedLabels()
        // eslint-disable-next-line
    }, [i18n.language])

    return (
        <div className={styles['multi-select-wrapper']} ref={selectRef}>
            <div className={styles.multiSelect}>
                <div
                    className={`${styles.selectBox} ${selectedOptions.length > 0 ? styles.selected : ''}`}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div className={styles.selectedOptions}>
                        {selectedOptions.length > 0 ? (
                            <span className={styles.chip} onClick={handleRemoveAllOptions}>
                                Selected: {selectedOptions.length} ✕
                            </span>
                        ) : (
                            !enableSearch && <span className={styles['placeholder']}>{placeholder}</span>
                        )}
                    </div>
                    {!enableSearch && (
                        <div className={`${styles.arrow} ${isOpen ? styles.open : ''}`}>
                            <ChevronDown />
                        </div>
                    )}

                    {enableSearch && (
                        <input
                            className={styles.searchInput}
                            type="text"
                            value={searchTerm}
                            onChange={(e) => {
                                e.stopPropagation()
                                setSearchTerm(e.target.value)
                            }}
                            placeholder={placeholder}
                        />
                    )}
                </div>
                <div className={`${styles.optionsList} ${isOpen ? styles.show : styles.hide}`}>
                    {filteredOptions.map((option) => {
                        const selected = selectedOptions.some((o) => o.value === option.value)
                        return (
                            <>
                                <div
                                    key={option.label}
                                    className={`${styles.option} ${selected ? styles.selected : ''}`}
                                    onClick={() => {
                                        option.value !== 'error' && handleSelectOption(option)
                                    }}
                                >
                                    {option.label}
                                    {selected && <DoneIcon />}
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
            {selectedOptions.length > 0 && (
                <div className={styles['chip-container']}>
                    {selectedOptions.map((option) => (
                        <span key={option.value} className={styles.tag}>
                            {option.label} <span onClick={() => handleRemoveOption(option)}>✕</span>
                        </span>
                    ))}
                </div>
            )}
        </div>
    )
}

export default MultiSelect
