import React, { FC, useMemo } from 'react'

import { Flex } from '@/_yc'

import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import MultiBarChart from '@/Layouts/ReportLayout/Content/ResuableWidgets/MultiBarChart/MultiBarChart'
import { MediaPlanOrder } from '../../utils/types'

import cn from './Demographics.module.scss'
import DonutChart from '@/Layouts/ReportLayout/Content/ResuableWidgets/DonutChart'

interface DemographicsProps {
    mediaPlan: MediaPlanOrder
    isLoading: boolean
}

const Demographics: FC<DemographicsProps> = ({ mediaPlan, isLoading }) => {
    const t = useSpaceTranslation('report_layout.demographics')

    const preprocessCountryNLang = (data: { label: string; value: number }[]) => {
        const preprocessedData = []

        for (let item of data) {
            preprocessedData.push({
                label: item.label,
                items: [
                    {
                        qty: 0,
                        share: item.value,
                        color: '#6071FF',
                    },
                ],
            })
        }

        return preprocessedData
    }
    const instagramCountries = useMemo(() => {
        if (!mediaPlan || !mediaPlan.data?.countries_instagram) return []

        const data = mediaPlan.data.countries_instagram

        return preprocessCountryNLang(data)
    }, [mediaPlan])

    const instagramLanguages = useMemo(() => {
        if (!mediaPlan || !mediaPlan.data?.languages_instagram) return []

        const data = mediaPlan.data.languages_instagram
        return preprocessCountryNLang(data)
    }, [mediaPlan])

    const youtubeCountries = useMemo(() => {
        if (!mediaPlan || !mediaPlan.data?.countries_youtube) return []

        const data = mediaPlan.data.countries_youtube

        return preprocessCountryNLang(data)
    }, [mediaPlan])

    const youtubeLanguages = useMemo(() => {
        if (!mediaPlan || !mediaPlan.data?.languages_youtube) return []

        const data = mediaPlan.data.languages_youtube
        return preprocessCountryNLang(data)
    }, [mediaPlan])

    const instagramCities = useMemo(() => {
        if (!mediaPlan || !mediaPlan.data?.cities) return []

        const data = mediaPlan.data.cities
        return preprocessCountryNLang(data)
    }, [mediaPlan])

    const ageGenderLegend = useMemo(() => {
        return [
            {
                id: 2,
                value: t('age_gender.legend.men'),
                color: '#6071FF',
            },
            {
                id: 1,
                value: t('age_gender.legend.women'),
                color: '#FF659C',
            },
        ]
    }, [t])

    const ageGender = useMemo(() => {
        if (!mediaPlan || !mediaPlan.data?.age_gender) return []

        const data = mediaPlan.data.age_gender
        const preprocessedData = []

        for (const [key, value] of Object.entries(data)) {
            preprocessedData.push({
                label: key,
                items: [
                    {
                        qty: value.man_qty,
                        share: value.man_share,
                        color: '#6071FF',
                    },
                    {
                        qty: value.woman_qty,
                        share: value.woman_share,
                        color: '#FF659C',
                    },
                ],
            })
        }

        return preprocessedData
    }, [mediaPlan])

    const genderDist = useMemo(() => {
        if (!mediaPlan || !mediaPlan.data?.age_gender) return []

        const data = mediaPlan.data.age_gender

        if (Object.keys(data).length === 0) return []

        let manShare = 0
        let womanShare = 0

        for (const [, value] of Object.entries(data)) {
            manShare += value.man_share
            womanShare += value.woman_share
        }

        return [
            {
                label: t('gender_dist.legend.men'),
                label_color: `#6071FF`,
                value: manShare,
                color: '#6071FF',
                percent_color: `#E2E6FF`,
            },
            {
                label: t('gender_dist.legend.women'),
                label_color: `#FF659C`,
                value: womanShare,
                color: '#FF659C',
                percent_color: `#FFDCE2`,
            },
        ]
    }, [mediaPlan, t])

    return (
        <>
            <Flex content="space-between" margin="20px 0 0 0" wrap>
                <div className={cn.card}>
                    <MultiBarChart
                        title={t('age_gender.title')}
                        data={ageGender}
                        legend={ageGenderLegend}
                        size="large"
                        isLoading={isLoading || ageGender.length === 0}
                    />
                </div>
                <div className={cn.card}>
                    <DonutChart
                        data={genderDist}
                        title={t('gender_dist.title')}
                        isLoading={isLoading || genderDist.length === 0}
                    />
                </div>
            </Flex>
            <Flex content="space-between" margin="20px 0 0 0" wrap>
                <div className={cn.card}>
                    <MultiBarChart
                        title={t('countries_instagram.title')}
                        data={instagramCountries}
                        type="horizontal"
                        size="small"
                        isLoading={isLoading || instagramCountries.length === 0}
                    />
                </div>
                <div className={cn.card}>
                    <MultiBarChart
                        title={t('cities_instagram.title')}
                        data={instagramCities}
                        type="horizontal"
                        size="small"
                        isLoading={isLoading || instagramCities.length === 0}
                    />
                </div>
                <div className={cn.card}>
                    <MultiBarChart
                        title={t('lang_instagram.title')}
                        data={instagramLanguages}
                        type="horizontal"
                        size="small"
                        isLoading={isLoading || instagramLanguages.length === 0}
                    />
                </div>
            </Flex>
            <Flex content="space-between" margin="20px 0 0 0" wrap>
                <div className={cn.card}>
                    <MultiBarChart
                        title={t('countries_youtube.title')}
                        data={youtubeCountries}
                        type="horizontal"
                        size="medium"
                        isLoading={isLoading || youtubeCountries.length === 0}
                    />
                </div>
                <div className={cn.card}>
                    <MultiBarChart
                        title={t('lang_youtube.title')}
                        data={youtubeLanguages}
                        type="horizontal"
                        size="medium"
                        isLoading={isLoading || youtubeLanguages.length === 0}
                    />
                </div>
            </Flex>
        </>
    )
}

export default Demographics
