import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import i18next from 'i18next'

import { RootStore } from '@/_helpers/StoreType'
import Flex from '@/_yc/Flex'
import { getShortString } from '@/_helpers/strings/getShortString'
import { getNumberWithSpaces } from '@/_helpers/nums/numberWithSpaces'

import { PostType } from '../../BloggerReport/Cards/Post/Post'
import Avatar from '@/components/Avatar'
import Icons from '@/components/Icons'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import { MockInContainer } from '../Mock/Mock'

import cn from './Posts.module.scss'

export interface PT extends Omit<PostType, 'img' | 'date' | 'shortcode'> {
    url: string
    postDate: string
    code: string
}

export interface IPosts {}

const Posts: FC<IPosts> = () => {
    const posts = useSelector((store: RootStore) => {
        const rawPosts = store.report.rawVkData?.getVkReport?.data.vk_account[0].vk_accountpost
        return rawPosts?.slice(0, 3)
        // ?.filter((item) => {
        //     if (item.images === null) return false
        //     //@ts-ignore
        //     if (item.images !== null && item.images.length === 0) return false
        //     return true
        // })
    })
    const accountInfo = useSelector((store: RootStore) => {
        const name = store.report.rawVkData?.getVkReport?.data.vk_account[0].full_name
        const avatar = store.report.rawVkData?.getVkReport?.data.vk_account[0].avatar_link
        const type = store.report.rawVkData?.getVkReport?.data.vk_account[0].account_type
        const id = store.report.rawVkData?.getVkReport?.data.vk_account[0].account_id

        return {
            name: name,
            avatar: avatar,
            type: type,
            accountId: id,
        }
    })

    const size = useWindowWidth('mobile')
    const isMobile = size === 'mobile'

    if (!posts || posts.length === 0) {
        return <MockInContainer />
    }

    const getTextLength = (ims: any): number => {
        if (isMobile) {
            if (ims === null) return 400
            if (ims.length === 0) return 400
            return 140
        }
        if (ims === null) return 750
        if (ims.length === 0) return 750
        return 200
    }
    return (
        <>
            <div className={cn.posts}>
                {posts &&
                    posts.map((post, i) => (
                        <>
                            <a
                                href={`https://vk.com/wall${accountInfo.type === 'group' ? '-' : ''}${
                                    accountInfo.accountId
                                }_${post.post_id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Flex className={cn.post} column content="space-between" margin="20px 0 0 ">
                                    <Flex column>
                                        <Flex className={cn['post-head']} align="center" content="space-between">
                                            <Flex align="center">
                                                <Avatar
                                                    src={accountInfo.avatar || ''}
                                                    style={{ borderRadius: '50%' }}
                                                />
                                                <p className={cn['post-head-name']}>{accountInfo.name}</p>
                                            </Flex>
                                            <p className={cn['post-head-date']}>
                                                {new Date(post.published_at).toLocaleDateString(i18next.language)}
                                            </p>
                                        </Flex>
                                        <Flex
                                            margin="10px 0 0 0"
                                            wrap
                                            className={cn.imgs}
                                            align="center"
                                            content="center"
                                        >
                                            {post.images?.slice(0, 1).map((im) => (
                                                <Flex className={cn.img}>
                                                    <img className={cn.img} src={im} alt="publication" />
                                                </Flex>
                                            ))}
                                        </Flex>
                                        <Flex margin="16px 0 0 0" className={cn.text}>
                                            <p>{post.text && getShortString(post.text, getTextLength(post.images))}</p>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        align="center"
                                        className={cn.reactions}
                                        margin="16px 0 0 0"
                                        content="space-between"
                                    >
                                        <Flex>
                                            <Flex align="center">
                                                <Icons name="likes" />
                                                <p className={cn.reaction}>{getNumberWithSpaces(post.reactions)}</p>
                                            </Flex>

                                            <Flex align="center">
                                                <Icons name="comments" style={{ marginLeft: '16px' }} />
                                                <p className={cn.reaction}>{getNumberWithSpaces(post.comments)}</p>
                                            </Flex>
                                        </Flex>

                                        <Flex align="center" style={{ justifySelf: 'end' }}>
                                            <Icons name="show" style={{ marginLeft: '16px' }} className={cn.seen} />
                                            <p className={cn.reaction}>{getNumberWithSpaces(post.comments)}</p>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </a>
                        </>
                    ))}
            </div>
        </>
    )
}

export default Posts
