import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootStore } from '@/_helpers/StoreType'
import { useLazyQuery } from '@apollo/client'

import { GET_RECENT_SEARCHES } from '../model/graphQL'
import { IGetRecentSearchesReq } from '../model/types'
import { setRecentsCount, setBloggersAndChannels, setBloggersAndChannelsDefault } from '../model/actions'
import useLikes from './useLikes'

import { RecentSearchToNormal } from '../PreprocessData/index'

const GetRecentSearches = () => {
    const dispatch = useDispatch()

    const likedBloggers = useLikes()

    const activeListId = useSelector((store: RootStore) => store.AudienceInsights.ActiveListId)
    const [page, setPage] = useState<{ listID: number; page: number }>({ listID: -1, page: 1 })
    const prevData = useSelector((store: RootStore) => store.AudienceInsights.BloggersAndChannels)
    useEffect(() => {
        setPage({ listID: activeListId, page: 1 })
    }, [activeListId])

    const [listsStatus, setListsStatus] = useState<{ error: boolean; loading: boolean }>({
        error: false,
        loading: true,
    })

    const [getRecentSearches, { data: recentSearchesData, error, loading }] =
        useLazyQuery<IGetRecentSearchesReq>(GET_RECENT_SEARCHES)
    useEffect(() => {
        getRecentSearches({
            variables: {
                page: page.page, //add pagination
            },
        })
    }, [getRecentSearches, page])

    useLayoutEffect(() => {
        if (page.page === 1) {
            dispatch(setBloggersAndChannelsDefault([]))
        } else {
            return
        }
    }, [page, dispatch])
    useEffect(() => {
        if (!recentSearchesData) return
        const updData = RecentSearchToNormal(recentSearchesData?.getRecentSearchesV2, likedBloggers)
        dispatch(setBloggersAndChannels(updData))
        dispatch(setRecentsCount(recentSearchesData.getRecentSearchesV2.count))
    }, [recentSearchesData, dispatch, likedBloggers])

    useEffect(() => {
        setListsStatus({
            error: error ? true : false,
            loading: loading,
        })
    }, [error, loading])

    //pagination logic
    const handleScroll = useCallback(() => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

        if (bottom) {
            //chech if it is allowed to fetch another page
            const prevDataLength = prevData.length
            const dataLength = recentSearchesData?.getRecentSearchesV2?.count
            const status = !listsStatus.error && !listsStatus.loading

            const isReady =
                status &&
                dataLength &&
                dataLength !== 0 &&
                dataLength > prevDataLength &&
                recentSearchesData?.getRecentSearchesV2?.data.length !== 0

            //change page, so next page will be fetched with useEffect
            if (isReady) {
                setPage((prevPage) => {
                    return { listID: prevPage.listID, page: prevPage.page + 1 }
                })
            }
        }
    }, [listsStatus, prevData, recentSearchesData])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true,
        })

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [handleScroll, listsStatus, prevData])

    return listsStatus
}

export default GetRecentSearches
