import React, { ForwardRefRenderFunction, ReactNode, ComponentProps, useCallback, MouseEvent, useRef } from 'react'

import cn from './BloggerLink.module.scss'
import { Link } from 'react-router-dom'
import { OPEN_REPORT } from '@/routs'
import { history } from '@/_helpers'
import { Platform } from '@/pages/Reports/types'
import { VkAccountType } from '@/pages/SearchPage/SearchAdapter/bloggers/vk/utils/types'

export interface IBloggerLink extends Omit<ComponentProps<typeof Link>, 'id' | 'to'> {
    children?: ReactNode
    id: number | string
    disabled?: boolean
    isDemo?: boolean
    newTab?: boolean
    beforeRelocate?: (relocate: VoidFunction) => void
    failLink?: string
    platform: Platform
    accountType?: VkAccountType
    isPromo?: boolean
}

export const redirectToId = (id: string | number, isDemo?: boolean) => history.push(makeLink(id, isDemo))

export const makeLink = (
    id: string | number,
    isDemo?: boolean,
    failLink?: string,
    accountType?: VkAccountType,
    platform: Platform = Platform.Instagram,
    isPromo?: boolean
): string => {
    return `${OPEN_REPORT}/${platform}${accountType ? '/' + accountType : ''}/${id}/${
        isPromo ? 'promo' : isDemo ? 'demo' : 'report'
    }/${failLink ? `?fail_link=${failLink}` : ''}`
}

const BloggerLink: ForwardRefRenderFunction<HTMLAnchorElement, IBloggerLink> = (
    {
        children,
        id,
        className,
        onClick,
        disabled,
        isDemo,
        newTab,
        beforeRelocate,
        failLink,
        platform,
        accountType,
        isPromo,
        ...rest
    },
    ref
) => {
    const clone = useRef<HTMLAnchorElement>(null)

    const click = useCallback(
        (e: MouseEvent<any, any>) => {
            if (disabled) e.preventDefault()

            onClick && onClick(e)

            if (!disabled && beforeRelocate) {
                e.preventDefault()
                e.stopPropagation()
                const { nativeEvent } = e

                const newEvent: Event = new nativeEvent.constructor(nativeEvent.type, nativeEvent)

                beforeRelocate(() => {
                    if (clone.current) {
                        clone.current.dispatchEvent(newEvent)
                    }
                })
            }
        },
        [onClick, disabled, beforeRelocate]
    )
    return (
        <>
            <Link
                to={makeLink(id, isDemo, failLink, accountType, platform, isPromo)}
                className={`${cn.root} ${className || ''}`}
                onClick={click}
                ref={ref}
                target={newTab ? '_blank' : undefined}
                {...rest}
            >
                {children}
            </Link>
            <Link
                className={cn.hide}
                ref={clone}
                to={makeLink(id, isDemo, failLink, accountType)}
                target={newTab ? '_blank' : undefined}
            />
        </>
    )
}

export default BloggerLink
