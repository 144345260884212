import React, { FC, useCallback, useEffect, useState, useMemo } from 'react'

import cn from './NotifItem.module.scss'
import Flex from '@/_yc/Flex'
import { Icons } from '@/_yc/Icons'
import Render from '@/Layouts/PageLayout/header/NotificationBell/List/Item'
import { Notification } from '../Types'
import useIsOnline from '@/_helpers/MouseListener/useIsOnline'

export interface INotifItem {
    item: Notification
    onClose: (id: number | string) => void
    visible: boolean
    isPrimary: boolean
}

const HIDE_DELAY: number = 5000

const NotifItem: FC<INotifItem> = ({ item, onClose, visible, isPrimary }) => {
    const [startAnim, setStartAnim] = useState<boolean>(true)

    const click = useCallback(() => {
        onClose(item.id)
    }, [onClose, item])

    const isOnline = useIsOnline()
    useEffect(() => {
        if (!isOnline) return

        let mounted = true

        setTimeout(() => {
            if (mounted && visible) onClose(item.id)
        }, HIDE_DELAY)

        return () => {
            mounted = false
        }
    }, [isOnline, item.id, onClose, visible])

    useEffect(() => {
        let mounted = true

        setTimeout(() => {
            if (mounted) setStartAnim(false)
        }, 500)

        return () => {
            mounted = false
        }
    }, [])

    const style = useMemo(() => {
        if (item.Message.icon === 'error') return { background: '#F5535D' }
        if (item.Message.icon === 'info') return { background: '#323841', justifyContent: 'center' }
        return { background: '#323841' }
    }, [item])
    return (
        <Flex
            content="space-between"
            align="center"
            className={`${visible ? cn['root--visible'] : cn[`root--hidden`]} ${startAnim ? cn.animation : ''} ${
                !isPrimary ? cn.opacity : ''
            }`}
            style={style}
        >
            <Render notLast={false} hideTime withSublabel Notification={item} />
            <Flex
                style={item.Message.icon === 'info' ? { display: 'none' } : {}}
                content="center"
                align="center"
                className={cn.container}
                onClick={click}
            >
                <Icons context="default" name="close" />
            </Flex>
        </Flex>
    )
}

export default NotifItem
