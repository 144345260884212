import { memo } from 'react'
import { compose, withProps } from 'recompose'

import P, { IOpenReportPage } from './OpenReportPage'
import { Subtract } from 'utility-types'

import getReport from './Services'
import getParams from '@/_helpers/getParams'

type toAdd = Pick<IOpenReportPage, 'GetReportId' | 'getSearchParams'>

type afterAdds = Subtract<IOpenReportPage, toAdd>

const add: toAdd = {
    GetReportId: (external_id) =>
        new Promise((resolve, reject) => {
            const storage = localStorage.getItem('user')

            if (!storage) reject()

            let user = JSON.parse(storage as string)

            if (!user.token) reject()
            getReport(user.token, external_id)
                .then((res) => {
                    resolve({
                        noReports: false,
                        report_id: res.id,
                    })
                })
                .catch((err) => {
                    if (!err.response) return reject(err)
                    if (err.response.status === 403) {
                        resolve({ noReports: true })
                    } else {
                        reject(err)
                    }
                })
        }),
    getSearchParams: getParams,
}

export default compose<IOpenReportPage, afterAdds>(withProps(add), memo)(P)
