import React from 'react'
import { SelectorFilter } from '../../../../filters/SelectorFilter'
import moment from 'moment'
import { PLATFORM } from '../consts'
import Icons from '@/components/Icons'

const getGqlFilter = (date: string[]) => {
    if (!date || date.length === 0) return ''
    return `last_video_date: {_gte: "${date[0]}"}`
}

export const getLastVideoFilter = () => {
    const todaysDate = moment()
    return new SelectorFilter({
        platform: PLATFORM,
        title: 'filters.lastVideo.label',
        helper: 'filters.lastVideo.text',
        icon: <Icons name="filter--age" />,
        space: 1,
        locked: (feats, isPromo) => {
            if (isPromo) return true
            return false
        },
        options: [
            { label: 'Month', value: todaysDate.subtract(30, 'day').format().toString() },
            { label: '6 Months', value: todaysDate.subtract(180, 'day').format().toString() },
        ],
        beforeSend: ({ value }) => ({
            name: 'metrics.er',
            type: 'number',
            value: value,
            gqlFilter: getGqlFilter(value),
        }),
    })
}
