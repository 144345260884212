import React from 'react';
import { Link } from 'react-router-dom'

import { Button } from '../../_yc'
import { SEARCH } from '../../routs.js'

import cn from './EmptyPage.module.scss'

export const EmptyPage = props => (
	<div className = {cn.root}>
		<h1>Page not found</h1>
		<h2>Error 404</h2>
		<Link to={SEARCH} >
			<Button>Home page</Button>
		</Link>
	</div>
)