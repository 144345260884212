import React, { FC, ReactNode } from 'react'
import { Row } from '@/pages/Reports/BloggerReport/Blocks/Row'

import cn from './DemoHidder.module.scss'
import useOpenClose from '@/_helpers/useOpenClose'
import Flex from '@/_yc/Flex'
import Buttons from './Buttons'
import Button from './DemoButton'
import LockerTs from '@/_yc/Icons/Default/Locker_ts'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { TARIFFS } from '@/routs'
import BloggerLink from '@/components/Share/BloggerLink'
import { Platform } from '../../types'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import { Link } from 'react-router-dom'

export interface IDemoHidder {
    item?: any
    grid?: string
    id: string | number
    children?: (props: { open: VoidFunction; isOpen: boolean }) => ReactNode
}

const mr = { marginRight: 8 }

const border = { borderColor: '#fff' }

const DemoHidder: FC<IDemoHidder> = (props) => {
    const t = useSpaceTranslation('demo_report')
    const tariff = useSelector((store: RootStore) => {
        return store.authentication.user?.profile?.package
    })

    const [state, open, close] = useOpenClose()

    const count = props.grid?.split(' ')

    return (
        <div className={state ? cn['root--open'] : cn['root--close']}>
            {props.item ? (
                <div style={{ backgroundColor: '#fff' }}>
                    <Row item={props.item} hidden={!state} />
                </div>
            ) : (
                props.children && props.children({ open, isOpen: state })
            )}
            {count && (
                <div
                    className={state ? cn['grid--hidden'] : cn['grid--visible']}
                    style={{
                        gridTemplateColumns: count.length === 3 ? '1fr' : props.grid,
                    }}
                >
                    {count.length === 3 ? (
                        <Buttons id={props.id} showBlock={open} />
                    ) : (
                        count.map((_, i) => <Buttons id={props.id} showBlock={open} key={i} />)
                    )}
                </div>
            )}
            <div className={state ? cn['details--open'] : cn['details--closed']}>
                <Flex content="center" margin="10px auto 14px" className={cn['open-buttons--col']}>
                    {tariff !== null && tariff !== undefined && tariff !== '' ? (
                        <BloggerLink
                            disabled={tariff === null || tariff === undefined}
                            id={props.id}
                            failLink={`${TARIFFS}?error=error`}
                            platform={Platform.Instagram}
                        >
                            <Button color="white" className={cn['blogger-link']}>
                                <LockerTs style={mr} />
                                {t('unlock')}
                            </Button>
                        </BloggerLink>
                    ) : (
                        <>
                            <Link to={TARIFFS}>
                                <Button color="white" className={cn['blogger-link']}>
                                    <LockerTs style={mr} />
                                    {t('unlock')}
                                </Button>
                            </Link>
                        </>
                    )}
                    <Button style={border} onClick={close}>
                        {t('hide')}
                    </Button>
                </Flex>
            </div>
        </div>
    )
}

export default DemoHidder
