import React, { SVGAttributes } from 'react'
export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="brand / tiktok">
            <path
                id="coolicon"
                d="M10.3538 2.0135C11.2326 2 12.1063 2.008 12.979 2C13.0318 3.02055 13.4015 4.06011 14.1538 4.78165C14.9046 5.52119 15.9667 5.85971 17 5.97421V8.65886C16.0316 8.62735 15.0587 8.42734 14.18 8.01332C13.7973 7.84131 13.4407 7.6198 13.0918 7.39329C13.0872 9.34139 13.0998 11.287 13.0792 13.2271C13.0268 14.1591 12.7171 15.0867 12.1712 15.8547C11.293 17.1333 9.76867 17.9669 8.20306 17.9929C7.24274 18.0474 6.28344 17.7873 5.46513 17.3083C4.109 16.5143 3.15473 15.0607 3.01574 13.5006C2.99963 13.1671 2.99409 12.8341 3.00768 12.5086C3.12854 11.24 3.76053 10.0264 4.74149 9.20088C5.85338 8.23933 7.41094 7.78131 8.86929 8.05232C8.88289 9.03988 8.8431 10.0264 8.8431 11.014C8.17687 10.8 7.39835 10.86 6.81622 11.2615C6.3912 11.535 6.06841 11.954 5.90021 12.4281C5.76123 12.7661 5.80101 13.1416 5.80907 13.5006C5.9687 14.5947 7.02822 15.5142 8.15925 15.4147C8.90907 15.4067 9.62767 14.9747 10.0184 14.3422C10.1448 14.1206 10.2863 13.8941 10.2939 13.6336C10.3599 12.4411 10.3337 11.2535 10.3417 10.0609C10.3473 7.37329 10.3337 4.69314 10.3543 2.014L10.3538 2.0135Z"
                fill="#5D697D"
            />
        </g>
    </svg>
)
