export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    // GETCAMPAIGNS_REQUEST: 'USERS_GETCAMPAIGNS_REQUEST',
    // GETCAMPAIGNS_SUCCESS: 'USERS_GETCAMPAIGNS_SUCCESS',
    // GETCAMPAIGNS_FAILURE: 'USERS_GETCAMPAIGNS_FAILURE',
};
