import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PageTitle from '@/components/PageTitle'

export const HelperWrapper = () => {
    const linkToHelper = useSelector<any, string>((state) => state.Commercial_.value.hp_aoverlap)
    const { t } = useTranslation()
    return (
        <>
            <PageTitle
                title={t('intersections.dashboards.title')}
                linkToHelper={linkToHelper}
                icon="copy"
                iconSize={'25px'}
            />
        </>
    )
}
