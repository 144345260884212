import React, { memo } from 'react'
import { Icon } from '../Types'
import { MessageType } from './Types'
import Text from '@/components/Text'
import { Trans } from 'react-i18next'

export type OnParsingProps = { username: string }

export default class UsernameNotification implements MessageType<OnParsingProps> {
    icon: Icon = 'info'
    props: OnParsingProps

    private components: any
    private values: any

    /**
     *
     * @param username to notify
     * @param namespace translation function will seek path "Notification.${namespace}.text" and passing username key for this;
     */
    constructor(username: string, private namespace: string) {
        this.props = {
            username,
        }
        this.components = [<Text fSize={14} component={'span'} color="violet-1" />]

        this.values = {
            username: this.props.username,
        }
    }
    Component = memo(() => {
        return (
            <Text fSize={14}>
                <Trans
                    i18nKey={`Notifications.${this.namespace}.text`}
                    components={this.components}
                    values={this.values}
                />
            </Text>
        )
    })
}
