import React, { FC, memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { BILLING } from '@/routs'
import Text from '@/components/Text'
import Button from '@/components/Button'
import Icons from '@/components/Icons'
import { RootStore } from '@/_helpers/StoreType'
import actions from '@/components/HeaderMenu/Model/actions.js'
import { Flex } from '@/_yc'
import { shortNum } from '@/_helpers/_graphs/_tech'

import cn from './Header.module.scss'

export interface ICounter {
    [key: string]: any
}

const Counter: FC<ICounter> = (props) => {
    const countsInfo = useSelector((store: RootStore) => store.HeaderMenu_.ReportCounter_)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actions.getCount())
    }, [dispatch])

    return (
        <Link to={BILLING}>
            <Flex
                align="center"
                component={Button}
                className={cn.chip}
                variant="transparent"
                size="small"
                style={props.style}
            >
                <Flex align="center">
                    <Icons name="flag" className={cn.icon} />
                    <Text color="gray-3">{shortNum(countsInfo.reportsCount, 2)}</Text>
                </Flex>

                <Flex align="center">
                    <Icons name="copy" className={cn.icon} />
                    <Text color="gray-3">{shortNum(countsInfo.intersectionsCount, 2)}</Text>
                </Flex>

                <Flex align="center">
                    <Icons name="message-circle" className={cn.icon} />
                    <Text color="gray-3">{shortNum(countsInfo.chatsCount, 2)}</Text>
                </Flex>
            </Flex>
        </Link>
    )
}

export default memo(Counter)
