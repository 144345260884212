export type unit = 'min' | 'hour' | 'day' | 'week' | 'month'

const minuteBorder = 60000
const hourBorder = minuteBorder * 60
const dayBorder = hourBorder * 24
const weekBorder = dayBorder * 7
const monthBorder = dayBorder * 30

export default (milliseconds: number): { units: unit; number: number } => {
    if (milliseconds < minuteBorder) {
        return { units: 'min', number: 0 }
    } else if (milliseconds < hourBorder) {
        return { units: 'min', number: Math.round(milliseconds / minuteBorder) }
    } else if (milliseconds < dayBorder) {
        return { units: 'hour', number: Math.round(milliseconds / hourBorder) }
    } else if (milliseconds < weekBorder) {
        return { units: 'day', number: Math.round(milliseconds / dayBorder) }
    } else if (milliseconds < monthBorder) {
        return { units: 'week', number: Math.round(milliseconds / weekBorder) }
    } else {
        return {
            units: 'month',
            number: Math.round(milliseconds / monthBorder),
        }
    }
}
