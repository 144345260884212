import { useState, useEffect } from "react"
import Listener from "./Listener"

let lastMouseMove = Date.now();

Listener.subscribe(() => {
    lastMouseMove = Date.now()
})

/**
 * @description the function return true, if at the mount of component, the mouse was moving less than 5 seconds ago.
 */
export default (): boolean => {
    
    const [isOnline, setIsOnline] = useState<boolean>(Date.now() - lastMouseMove < 5000)
    
    useEffect(() => {
        if (!isOnline) {
            
            let expired = false;

            const sub = Listener.subscribe(() => {
                expired = true;
                setIsOnline(true);
                sub.unsubscribe();
            })

            return () => {
                if (!expired) sub.unsubscribe();
            }
        }
    }, [isOnline])

    return isOnline
}