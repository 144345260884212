import React from 'react';

import { SignUpService } from './Model/service.js'

export default Component => class extends React.PureComponent{
	static get displayName(){
		return `withMailCheck(${Component.displayName || Component.name || "Component"})`
	}
	mailCheck = (mail) => new Promise((resolve, reject) => {
		SignUpService.checkmail(mail)
			.then(({ check }) => resolve(check))
			.catch(reject)
	})
	render(){
		return(
			<Component {...this.props} mailCheck = {this.mailCheck}/>
		)
	}
}