import React, { FC, useState, useRef, useMemo, useCallback } from 'react'

import Popup from '@/_yc/Popup'
import Button from '@/components/Button'
import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'
import Portal from '@/_helpers/Portal'
import { useSpaceTranslation } from '@/_helpers'
import useToken from '@/_helpers/Hooks/useToken'
import { z } from 'zod'
import Text from '@/components/Text'

import { CheckEmailReq } from './service'

import cn from './CheckEmailPopup.module.scss'

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 550,
    zIndex: 2002,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}

interface ICheckEmailPopup {
    isOpen: boolean
    close: () => void
    messageBody: string
    senderId: number
}

const CheckEmailPopup: FC<ICheckEmailPopup> = ({ isOpen, close, senderId, messageBody }) => {
    const t = useSpaceTranslation('outreach.templates.create.check')
    const token = useToken()
    const ref = useRef<HTMLInputElement>(null)
    const [value, setValue] = useState('')
    const [state, setState] = useState<'send' | 'ok' | 'er'>('send')
    const [er, setEr] = useState(false)

    const emailZ = useMemo(
        () =>
            z.object({
                email: z.string().email('Invalid email address'),
            }),
        []
    )

    const check = useCallback(() => {
        try {
            emailZ.parse({
                email: value,
            })
            return true
        } catch {
            return false
        }
    }, [emailZ, value])

    const onChange = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && ref && ref.current) submit()
    }
    const submit = () => {
        if (!token) return
        if (!check()) {
            setEr(true)
            return
        }
        CheckEmailReq(token, {
            body: messageBody,
            email_to: value,
            email_acc_id: senderId,
        })
            .then(() => {
                setState('ok')
            })
            .catch(() => {
                setState('er')
            })
    }
    return (
        <>
            {isOpen && (
                <Portal>
                    <div className={cn.root}>
                        <Popup style={style} isOpen={isOpen} onClose={close}>
                            <div className={cn.popup}>
                                <Flex content="space-between" align="center">
                                    <p className={cn['title']}>{t('title')}</p>
                                    <Icons onClick={close} name="close" className={cn.icon} />
                                </Flex>

                                {state === 'send' ? (
                                    <>
                                        <p className={cn['title-sub']}>{t('address')}</p>
                                        <input
                                            className={cn.input}
                                            style={er ? {} : { marginBottom: '30px' }}
                                            ref={ref}
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                            onKeyDown={onChange}
                                            placeholder={t('placeholder')}
                                        />
                                        {er && (
                                            <Text color="red-1" fSize={10} margin="0 0 13px 0">
                                                {t('invalid')}
                                            </Text>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Flex margin="20px 0 30px" align="center" column>
                                            {state === 'ok' ? (
                                                <>
                                                    <Flex className={cn.done} content="center" align="center">
                                                        <Icons
                                                            name="done-blue"
                                                            style={{ width: '20px', height: '20px' }}
                                                        />
                                                    </Flex>
                                                    <p className={cn['title-sub-sent']}>{t('sent')}</p>
                                                </>
                                            ) : (
                                                <>
                                                    <Flex className={cn['done-red']} content="center" align="center">
                                                        <Icons
                                                            name="red_cross"
                                                            style={{ width: '20px', height: '20px' }}
                                                        />
                                                    </Flex>
                                                    <p className={cn['title-sub-sent']}>{t('wrong_sender')}</p>
                                                </>
                                            )}
                                        </Flex>
                                    </>
                                )}

                                <div className={cn.line} />
                                <Flex content={'flex-end'}>
                                    {state === 'send' ? (
                                        <Button
                                            variant="primary"
                                            width="220px"
                                            onClick={() => {
                                                setState('send')
                                                submit()
                                            }}
                                        >
                                            {t('send')}
                                        </Button>
                                    ) : (
                                        <>
                                            <Button
                                                variant="primary"
                                                width="220px"
                                                onClick={() => {
                                                    setState('send')
                                                    close()
                                                }}
                                            >
                                                {'ok'}
                                            </Button>
                                        </>
                                    )}
                                </Flex>
                            </div>
                        </Popup>
                    </div>
                </Portal>
            )}
        </>
    )
}

export default CheckEmailPopup
