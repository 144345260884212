import React, { FC, HTMLAttributes, ReactNode } from 'react'

import cn from './DemoButton.module.scss'

export interface IDemoButton extends HTMLAttributes<HTMLButtonElement> {
    color?: 'blue' | 'white'
    children: ReactNode
    margin?: string
}

const DemoButton: FC<IDemoButton> = (props) => {
    const { color, children, margin, className, style, ...rest } = props

    return (
        <button
            className={`${cn['root--' + (color || 'blue')]} ${className || ''}`}
            style={{
                ...style,
                margin,
            }}
            {...rest}
        >
            {children}
        </button>
    )
}

export default DemoButton
