import React, { memo, ComponentType } from 'react'
import Title from '@/_helpers/TitleControl'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export interface IRequiredProps {
    title: Title
}

export interface ISameProps {
    menuOptions: Array<{
        label: string
        route: string
        new?: boolean
        disabled?: boolean
        soon?: boolean
        title?: string
    }>
}

export default <Props extends ISameProps>() => (Component: ComponentType<Props>) =>
    memo(function withTitleControl(props: IRequiredProps & Props): JSX.Element {
        // eslint-disable-next-line
        const location = useLocation()

        // eslint-disable-next-line
        const { t } = useTranslation()

        for (const item of props.menuOptions) {
            if (location.pathname === item.route) {
                if (item.title) props.title.label = t(item.title)
                else props.title.label = t(item.label)

                break
            }
        }

        return <Component {...(props as Props)} />
    })
