import React, { useEffect, useState } from 'react'

import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import Icons from '@/components/Icons'
import Button from '@/components/Button'
import Flex from '@/_yc/Flex'
import useOpenClose from '@/_helpers/useOpenClose'
import Popup from '@/_yc/Popup'
import Portal from '@/_helpers/Portal'
import useToken from '@/_helpers/Hooks/useToken'
import { GetCampaignMembersReq, ISharedPerson, AddCampaignMembersReq, EditCampaignMembersReq } from './model'
import Text from '@/components/Text'
import Avatar from '@/components/Avatar'
import { useSelector, useDispatch } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import EditRole from './EditRole'
import notif from '@/components/Notifier/model/actions.js'

import cn from './Share.module.scss'

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 550,
    zIndex: 2002,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}
interface IShare {
    title: string | null
    campaignId: string
}
const Share: React.FC<IShare> = (props) => {
    const dispatch = useDispatch()
    const t = useSpaceTranslation('campaign_manager')
    const token = useToken()
    const [isOpen, open, close] = useOpenClose()
    const [newMember, setNewMember] = useState<string>('')
    const btnRef = React.useRef<HTMLButtonElement>(null)

    const currentEmail = useSelector((state: RootStore) => state.authentication.user?.profile?.email)
    const [currentUser, setCurrentUser] = useState<ISharedPerson | null>(null)

    const [members, setMembers] = useState<ISharedPerson[]>([])
    const [editedMembers, setEditedMembers] = useState<ISharedPerson[]>([])

    const onClose = () => {
        close()
        setNewMember('')
        setEditedMembers([])
    }

    useEffect(() => {
        if (!isOpen) return
        if (!token) return
        GetCampaignMembersReq(token, props.campaignId)
            .then((res) => {
                const current = res.filter((item) => item.email === currentEmail)
                if (current.length > 0) {
                    setCurrentUser(current[0])
                } else {
                    setCurrentUser({
                        email: currentEmail ?? '',
                        first_name: '',
                        last_name: '',
                        access_type: 'Owner',
                        status: 'Added',
                    })
                }
                setMembers(res.filter((item) => item.email !== currentEmail))
            })
            .catch((er) => {
                dispatch(notif.error(t('hider.req.error')))
                console.log(er)
            })
    }, [token, isOpen, props.campaignId, t, dispatch, currentEmail])

    const editMembersReq = (token: string) => {
        if (!editedMembers) return
        dispatch(notif.loading(t('hider.req.loading')))
        editedMembers.forEach((item) => {
            EditCampaignMembersReq(token, props.campaignId, item.email, item.access_type)
                .then((res) => {
                    dispatch(notif.success(t('hider.req.success')))
                    onClose()
                })
                .catch((er) => {
                    dispatch(notif.error(t('hider.req.error')))
                    console.log(er)
                })
        })
    }

    const save = () => {
        if (!token) {
            dispatch(notif.error(t('hider.req.error')))
            return
        }
        if (editedMembers.length > 0) {
            editMembersReq(token)
        }
        if (newMember.length > 0) {
            AddCampaignMembersReq(token, props.campaignId, [newMember])
                .then((res) => {
                    if (res.length > 0) {
                        const member = res.filter((item) => item.email === newMember)
                        const status = member.length > 0 ? member[0].status : null

                        if (status === null) {
                            dispatch(notif.error(t('hider.req.error')))
                            return
                        }
                        if (status === 'Does not exist') {
                            dispatch(notif.error(t('header.share.email_not_exist')))
                            return
                        }
                    }
                    dispatch(notif.success(t('hider.req.success')))
                    onClose()
                })
                .catch((er) => {
                    dispatch(notif.error(t('hider.req.error')))
                    console.log(er)
                })
        }
    }
    return (
        <>
            <Button onClick={open}>
                <Flex align="center" style={{ columnGap: '6px' }}>
                    <Icons name="share" />
                    {t('header.share.btn')}
                </Flex>
            </Button>
            {isOpen && (
                <Portal>
                    <div className={cn.root}>
                        <Popup style={style} isOpen={isOpen} onClose={onClose}>
                            <div className={cn.popup}>
                                <Flex content="space-between" align="center">
                                    <p className={cn['title']}>
                                        {props.title
                                            ? t('header.share.title', { name: props.title })
                                            : t('header.share.btn')}
                                    </p>
                                    <Icons onClick={onClose} name="close" className={cn.icon} />
                                </Flex>

                                <Flex className={cn.inputContainer}>
                                    <input
                                        className={cn.input}
                                        value={newMember}
                                        onChange={(e) => setNewMember(e.target.value)}
                                        placeholder={t('header.share.placeholder')}
                                        onKeyDown={(e: any) => {
                                            if (e.key !== 'Enter' && e.key !== 'Tab') return
                                            e.preventDefault()
                                            btnRef.current?.focus()
                                        }}
                                    />
                                </Flex>

                                <Flex style={{ rowGap: '6px' }} column>
                                    <p className={cn['title-sub']}>{t('header.share.access')}</p>
                                    {currentUser && (
                                        <ShareOwner
                                            member={currentUser}
                                            editedMembers={editedMembers}
                                            setEditedMembers={setEditedMembers}
                                        />
                                    )}

                                    {members.map((member) => (
                                        <Flex content="space-between" style={{ width: '100%' }}>
                                            <Flex style={{ columnGap: '10px' }}>
                                                <Avatar src="" alt="avatar" className={cn.avatar} />

                                                <Flex column>
                                                    <Text>
                                                        {member.first_name} {member.last_name}
                                                    </Text>
                                                    <Text color="gray-3">{member.email}</Text>
                                                </Flex>
                                            </Flex>

                                            <EditRole
                                                member={member}
                                                editedMembers={editedMembers}
                                                setEditedMembers={setEditedMembers}
                                            />
                                        </Flex>
                                    ))}
                                </Flex>

                                {/* <p className={cn['title-sub']}>{t('header.share.gen_access')}</p> */}

                                <div className={cn.line} />
                                <Flex content="flex-end">
                                    {/* <Button onClick={addMembers}> add members</Button> */}
                                    {/* {editedMembers.length <= 0 ? (
                                        <Button onClick={close} variant="primary">
                                            {t('header.share.done')}
                                        </Button>
                                    ) : ( */}
                                    <Button
                                        className={cn.btn}
                                        ref={btnRef}
                                        onClick={save}
                                        variant="primary"
                                        disabled={editedMembers.length === 0 && newMember.length === 0}
                                    >
                                        {t('header.share.save')}
                                    </Button>
                                    {/* )} */}
                                </Flex>
                            </div>
                        </Popup>
                    </div>
                </Portal>
            )}
        </>
    )
}

const ShareOwner: React.FC<{
    member: ISharedPerson
    editedMembers: ISharedPerson[]
    setEditedMembers: Function
}> = (props) => {
    const t = useSpaceTranslation('campaign_manager')

    return (
        <>
            <Flex content="space-between" style={{ width: '100%' }}>
                <Flex style={{ columnGap: '10px' }}>
                    <Flex align="center" content="center" className={cn.you}>
                        <p>{t('header.share.you')}</p>
                    </Flex>

                    <Flex column>
                        <Text>{t('header.share.you')}</Text>
                        <Text color="gray-3">{props.member.email}</Text>
                    </Flex>
                </Flex>

                <EditRole
                    member={props.member}
                    editedMembers={props.editedMembers}
                    setEditedMembers={props.setEditedMembers}
                />
            </Flex>
        </>
    )
}

export default Share
