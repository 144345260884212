import React from 'react';

import Success from './Success.js'
import Warning from './Warning.svg'
import Err from './Error.js'
import Info from './Info.svg'
import Loading from './Loading.js'

export default ({name, ...props}) => {
	switch (name) {
		case "success": return <Success {...props} />
		case "warning": return <img {...props} src = {Warning} alt={<div></div>}/>
		case "error": return <Err {...props}/>
		case "info": return <img {...props} src = {Info} alt={<div></div>}/>
		case "loading": return <Loading {...props}/>
		default: 
			console.error(`Icon is not found; name: ${name}; context: notif`)
			return null
	}
}