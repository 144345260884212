import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.4749 5.83333L7.49158 8.825L8.66658 10L13.6666 5L8.66658 0L7.49158 1.175L10.4749 4.16667H0.333252V5.83333H10.4749Z"
            fill="#6071FF"
        />
    </svg>
)
