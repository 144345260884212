import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.15" width="30" height="30" rx="15" fill="#21C262" />
        <path
            d="M12.9375 19.714L8.8125 15.589L9.99083 14.4106L13.0358 17.4523L12.9375 17.3565L20.0083 10.2856L21.1867 11.464L14.1158 18.5356L12.9383 19.7131L12.9375 19.714Z"
            fill="#21C262"
        />
    </svg>
)
