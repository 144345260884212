import { Flex } from '@/_yc'
import React, { FC, useMemo, CSSProperties } from 'react'

import Donut from './Donut'

import cn from './DonutChart.module.scss'
import { defaultData } from './defaultData'

export interface IDonutChart {
    data: Array<{
        value: number
        color: string
        label: string
        label_color: string
        percent_color: string
    }>
    title: string
    isLoading?: boolean
    containerStyle?: CSSProperties
}

const DonutChart: FC<IDonutChart> = ({ data, title, isLoading, containerStyle }) => {
    const actualData = useMemo(() => {
        if (isLoading) return defaultData

        return data
    }, [data, isLoading])

    return (
        <Flex className={cn.root} style={{ ...containerStyle }}>
            <p className={cn.title}>{title}</p>
            <Donut radius={60} data={actualData} isLarge={data.length >= 4} isLoading={isLoading} />
            <div className={cn.legend}>
                {actualData.map((item) => {
                    const percent = (item.value * 100).toFixed(1)
                    return (
                        <div className={cn['legend--section']}>
                            <p className={cn['legend--section-label']}>{item.label}</p>
                            <p
                                className={cn['legend--section-percent']}
                                style={{
                                    background: item.percent_color,
                                    color: item.label_color,
                                    width: isLoading ? '70px' : 'auto',
                                }}
                            >
                                {!isLoading ? `${percent}%` : ''}
                            </p>
                        </div>
                    )
                })}
            </div>
        </Flex>
    )
}

export default DonutChart
