class WindowService{
	constructor(event){
		Object.defineProperty(this, "_event", {
			value: event,
			enumerable: false,
			writable: false,
			configurable: false
		})
		Object.defineProperty(this, "_subs", {
			value: [],
			enumerable: false,
			writable: true,
			configurable: false
		})
		Object.defineProperty(this, "_names", {
			value: {},
			enumerable: false,
			writable: false,
			configurable: false
		})
		window.addEventListener(event, this.execute.bind(this));
	}
	clear(){
		this._subs = []
	}
	remove(index){
		this._subs = this._subs.filter((item, jindex)=>index!==jindex)
		return true
	}
	removeByName(name){
		let index = this._names[name];
		if (index===undefined) {
			console.error(`Can't find ${name}`)
			return
		}
		return this.remove(index)
	}
	add(cb, name){
		this._subs.push(cb)
		if (name) {
			this._names[name] = this._subs.length-1
		}
		return this._subs.length-1;
	}
	execute(e){
		this._subs.forEach(cd=>cd(e))
	}
}
export { WindowService }
