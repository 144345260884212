import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { EmptyChat } from './EmptyChat/EmptyChat'
import cn from './Chat.module.scss'
import { ChatHeader } from './ChatHeader/ChatHeader'
import { Message } from './Message/Message'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import { YolocoChat } from './YolocoChat/YolocoChat'
import useToken from '@/_helpers/Hooks/useToken'
import { addMessage, updateMessages } from '../../Model/actions'
import { MessageArea } from './MessageArea/MessageArea'
import Flex from '@/_yc/Flex'
import Loader from '@/components/Loader'

import Text from '@/components/Text'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { getMessagesReq, postMessageReq } from '../../Service/Chats'
import { Platform } from '@/pages/Reports/types'

export interface InternalChatData {
    campaignId: string
    campaignName: string
    chatId: number
    bloggerId: string
    avatar: string
    bloggerName: string
    lastMessage: string
    time: string
    isOnline?: boolean
    unreadCount: number
    lastReadMessageId: number
    internalId: string
    platform: Platform
    stats: {
        er: string
        followers: number
    }
}

interface ChatProps {}

export const Chat: FC<ChatProps> = () => {
    const t = useSpaceTranslation('outreach.messenger.chat')
    const messageContainerRef = useRef<HTMLDivElement>(null)
    const lastMessageRef = useRef<HTMLDivElement>(null)
    const senderFirstName = useSelector((store: RootStore) => store.authentication.user?.profile?.first_name)

    const messages = useSelector((store: RootStore) => store.Outreach.messages)
    const chats = useSelector((store: RootStore) => store.Outreach.chats)
    const campaigns = useSelector((store: RootStore) => store.Outreach.advertisingCampaigns)
    const curChat = useSelector((store: RootStore) => store.Outreach.currentChat)

    const [showNew, setShowNew] = useState(true)

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const token = useToken()

    useEffect(() => {
        if (!token) return

        setLoading(true)
        getMessagesReq(token, curChat, 10000).then((data) => {
            dispatch(updateMessages(data))
            setLoading(false)
        })
    }, [dispatch, curChat, token, setLoading])

    const chatData = useMemo(() => {
        return chats.find((chat) => chat.chatId === curChat)
    }, [chats, curChat])

    useEffect(() => {
        if (!loading && lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView()
        }
    }, [chatData, loading])

    const campaignData = useMemo(() => {
        return campaigns.results.find((campaign) => campaign.id === chatData?.campaignId)
    }, [campaigns, chatData])

    const senderIcon = useMemo(() => {
        if (!senderFirstName) return 'Y'

        return senderFirstName[0]
    }, [senderFirstName])

    const sendMessage = useCallback(
        (message: string) => {
            setShowNew(false)
            const basisId = chatData?.campaignId

            if (!message || !token || !basisId) return

            dispatch(
                addMessage({ sender: 'user', message: message, time: new Date().toString(), id: -1, isRead: false })
            )

            postMessageReq(token, curChat, basisId, message)
        },
        [token, chatData, curChat, dispatch, setShowNew]
    )

    const lastMessageId = useMemo(() => {
        return messages[0]?.id
    }, [messages])

    return (
        <div className={cn.chat}>
            {curChat < 0 && <EmptyChat />}
            {curChat === 0 && <YolocoChat />}
            {curChat > 0 && (
                <div>
                    <ChatHeader
                        name={chatData?.bloggerName || ''}
                        campaignName={campaignData?.name || ''}
                        externalId={chatData?.bloggerId || ''}
                        stats={chatData?.stats || { er: '0', followers: 0 }}
                        avatar={chatData?.avatar || ''}
                        platform={chatData?.platform || Platform.Instagram}
                    />
                    <hr className={cn.divider}></hr>

                    <div className={cn.messagesContainer} ref={messageContainerRef}>
                        {!loading &&
                            messages.map((message, i) => (
                                <>
                                    {message.id === chatData?.lastReadMessageId &&
                                        message.id !== lastMessageId &&
                                        showNew && (
                                            <Text align="center" color="gray-new-2" fSize={12} margin="0 0 12px 0">
                                                {t('unread_messages')}
                                            </Text>
                                        )}
                                    <Message
                                        key={message.time}
                                        {...message}
                                        lastMessageRef={
                                            message.id === chatData?.lastReadMessageId ? lastMessageRef : undefined
                                        }
                                        username={chatData?.bloggerName || ''}
                                        chatId={curChat}
                                        avatar={message.sender === 'blogger' ? chatData?.avatar || '' : senderIcon}
                                    />
                                </>
                            ))}
                        {loading && (
                            <Flex margin="20px 0 170px 0" align={'center'} content="center">
                                <Loader />
                            </Flex>
                        )}
                    </div>

                    <div className={cn.sendMessageContainer}>
                        <div className={cn.sendMessage}>
                            <MessageArea messageContainerRef={messageContainerRef} onSendMessage={sendMessage} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
