import { combineReducers } from 'redux';
import Const, { BLOGGER_REPORT_CLEAR } from './_constants'

const defaultState = { 
	loaded: false, 
	blogger: {}, 
	error: false, 
	inProcess: true,
	status: -1
}

export const BloggerReport = combineReducers({
	blogger_info(state = defaultState, action){
		switch (action.type) {
			case Const.BLOGGER_REVIEW_REQUEST: return defaultState
			case Const.BLOGGER_REVIEW_REQUEST_SUCCESS: return {
				...state,
				loaded: true,
				inProcess: false,
				blogger: action.payload
			}
			case Const.BLOGGER_REVIEW_REQUEST_FAILURE: return {
				...state,
				loaded: false,
				inProcess: false,
				error: action.payload
			}
			case Const.BLOGGER_REPORT_STATUS: return {
				...state,
				status: action.payload
			}
			case BLOGGER_REPORT_CLEAR: return defaultState
			default: return state
		}
	}
})