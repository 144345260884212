import { SelectorFilter } from '../../../../filters/SelectorFilter'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { ILanguagesQuery } from '../../types'
import { getLanguages } from '../../queries'
import { preprocessLanguages } from '../../utils/filters/prepocess'
import { getLang } from '@/_helpers/getLang'
import { PLATFORM } from '../consts'

const getGqlFilter = (languages: Array<string>, percentage: number) => {
    if (!languages || !percentage) return ''

    return `audience_languages: {language_code_id: {_eq: "${languages[0]}"}, share: {_gte: ${percentage / 100}}}`
}

export const getAudLanguageFilter = (fetcher: Fetcher) => {
    return new SelectorFilter({
        platform: PLATFORM,
        locked: (features: string[]) => !features?.includes('audience_language_yt'),
        title: 'filters.aud_language.label',
        helper: 'filters.aud_language.text',
        autocomplete: true,
        space: 1,
        hasPercentage: true,
        featureKey: 'genderAudienceFilter',
        options: async () => {
            const client = fetcher.getClient()
            return await client
                .query<ILanguagesQuery>({ query: getLanguages(getLang()) })
                .then((res) => {
                    const languages = res.data?.youtube_languagestranslate
                    return preprocessLanguages(languages)
                })
        },
        beforeSend: ({ value, percentage }) => {
            return {
                name: 'audience.country',
                type: '',
                value: value,
                gqlFilter: getGqlFilter(value, percentage),
            }
        },
    })
}
