import React, { FC, useRef } from 'react'

import Text from '@/components/Text'
import cn from './ChatHeader.module.scss'
import { InfoPopup } from './InfoPopup/InfoPopup'
import useOpenClose from '@/_helpers/useOpenClose'
import { Platform } from '@/pages/Reports/types'
// import { InfoPopup } from './InfoPopup/InfoPopup'
// import useOpenClose from '@/_helpers/useOpenClose'

interface ChatHeaderProps {
    name: string
    externalId: string
    avatar: string
    campaignName: string
    stats: {
        er: string
        followers: number
    }
    platform: Platform
}

export const ChatHeader: FC<ChatHeaderProps> = ({ name, campaignName, externalId, avatar, stats, platform }) => {
    const [isOpen, open, close] = useOpenClose()
    const nameRef = useRef<HTMLDivElement>(null)
    return (
        <div className={cn.chatHeader}>
            <div ref={nameRef} onClick={open}>
                <Text fSize={16} semibold color="violet-1" style={{ cursor: 'pointer' }}>
                    {name}
                </Text>
            </div>

            <Text fSize={12} color="gray-2">
                {campaignName}
            </Text>
            {externalId !== '' && (
                <InfoPopup
                    isOpen={isOpen}
                    onClose={close}
                    anchor={nameRef.current}
                    avatar={avatar}
                    name={name}
                    externalId={externalId}
                    stats={stats}
                    platform={platform}
                />
            )}
        </div>
    )
}
