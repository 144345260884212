import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.25 5.0375L13.7375 9.5125L15.5 7.75L8 0.25L0.5 7.75L2.2625 9.5125L6.75 5.0375V20.25H9.25V5.0375Z"
            fill="#6071FF"
        />
    </svg>
)
