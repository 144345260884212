import React, { FC } from 'react'

import { PopularTags } from '@yoloker/ui-collection'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

export interface IChips {
    width: string
    hidden?: boolean
    hashTagsTitle: string
    hashTagsData?: string[]
    tagsTitle: string
    tagsData?: string[]
}

const Chips: FC<IChips> = ({ tagsTitle, hashTagsTitle, tagsData, hashTagsData, width, hidden }) => {
    const t = useSpaceTranslation('report_titles')
    return (
        <div style={{ marginBottom: '20px' }}>
            <PopularTags
                width={width}
                isDemo={hidden}
                tagsTitle={tagsTitle}
                hashTagsTitle={hashTagsTitle}
                tagsData={tagsData}
                hashTagsData={hashTagsData}
                tagsTooltip={t('userTags.helper')}
                hashTagsTooltip={t('popularHashTags.helper')}
            />
        </div>
    )
}
export default Chips
