import React from 'react';
import { useTranslation } from 'react-i18next'

import cn from './Failure/Failure.module.scss'

const UsedError = props => {
	const { t } = useTranslation();
	return (
		<div className = {cn.root}>
			<h3>{t("promo.used.title")}</h3>
			<p>{t("promo.used.comment")}</p>
		</div>
	)
}

export { UsedError as default }