import Button from '@/components/Button'
import ChainLink from '@/components/Icons/UI/ChainLink'
import Link from '@/components/Icons/UI/Link'
import FormInput from '@/components/Share/Form/FormInput'
import ListLayout, { ListItem, Segment } from '@/Layouts/ListLayout'
import PageLayout from '@/Layouts/PageLayout'
import { referralCreationService, referralService } from '@/Services/User'
import { history, useSpaceTranslation } from '@/_helpers'
import { RootStore } from '@/_helpers/StoreType'
import useOpenClose from '@/_helpers/useOpenClose'
import usePromiseCall from '@/_helpers/usePromiseCall'
import { Flex, GlobalWidth, Modal, Text } from '@/_yc'
import { Str } from '@schematic-forms/core'
import { FormProvider, useController, useForm } from '@schematic-forms/react'
import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useSelector } from 'react-redux'

import cn from './ReferralPage.module.scss'
// import TooltipIcon from './TooltipIcon'
// import { Tooltip } from '@yoloker/ui-collection' //no html base yet
import Tooltip from '../SearchPage/Bloggers/SearchPanel/Filters/Tooltip/Tooltip'
import { LinkValidator } from './LinkValidator'
import i18next from 'i18next'
import Popup from '@/_yc/Popup'
import PopupPapper from '@/components/Share/PopupPapper'
const referralQuery = async (token: string | undefined) => {
    return await referralService(token || '')
}

const ReferralPage: FC = () => {
    const REFERRAL_LINK_BASE = useMemo(
        () =>
            i18next.languages[0] === 'en'
                ? 'yoloco.io/?ref='
                : i18next.languages[0] === 'pt'
                ? 'yoloco.io/pt/?ref='
                : 'yoloco.io/ru/?ref=',
        [i18next.languages] // eslint-disable-line
    )
    const tTitle = useSpaceTranslation('titles')
    const t = useSpaceTranslation('referrals')
    const token = useSelector((state: RootStore) => state.authentication.user?.token)
    const resolver = useCallback(() => referralQuery(token), [token])
    const [value, setValue] = useForm('link', REFERRAL_LINK_BASE)
    const [refCode, setRefCode] = useState('')
    const [loading, refData] = usePromiseCall(resolver)
    const [isOpen, , close] = useOpenClose(!loading && !refData)
    const [modalPart, modalPartChange] = useState<1 | 2>(1)
    const [isPaymentOpen, openPayment, closePayment] = useOpenClose(false)
    const [copyState, setCopy] = useState(false)
    const copy = useCallback(() => {
        setCopy(true)
        return navigator.clipboard.writeText(`${REFERRAL_LINK_BASE}${refCode || refData ? refData?.code : ''}`)
    }, [refCode, refData, REFERRAL_LINK_BASE])
    const ref = useRef(null)
    const { controller, submit } = useController({
        fields: {
            link: Str(true),
        },
        validators: {
            link: LinkValidator(t('link_error')),
        },
        submit: async (data) => {
            const code = data.link.replace(REFERRAL_LINK_BASE, '')
            const ref = await referralCreationService(code.replace(/\s/g, ''), token || '').catch((e: Error) => {
                return e
            })

            if (ref instanceof Error) {
                if (ref.message === 'Request failed with status code 400') {
                    return { link: t('modal.400') }
                }
                return { link: 'Error' }
            } else {
                setRefCode(code)
                modalPartChange(2)
            }
        },
    })
    const dates = Object.keys(refData?.referral_stats || '')
    const endModal = useCallback(() => {
        close()
        modalPartChange(1)
        window.location.reload()
    }, [close, modalPartChange])
    return (
        <PageLayout noPadding title={tTitle('referrals')} color={'common-background'}>
            <Flex column className={cn['root-link']}>
                {isOpen ? (
                    <FormProvider controller={controller}>
                        {modalPart === 1 ? (
                            <Modal closeHigher onClose={history.goBack} closeIcon className={cn.link}>
                                <Flex align="center" content="space-between" className={cn['link-flex']} column>
                                    <Flex content="center" className={cn['icon']}>
                                        <Link />
                                    </Flex>

                                    <Flex column content="space-between" className={cn['link-flex']}>
                                        <Text align="center" semibold size="20px">
                                            {t('modal.header')}
                                        </Text>
                                        <Text align="center" color="gray" size={14}>
                                            {t('modal.text')}
                                        </Text>

                                        <FormInput
                                            errorTransform={(n, error) => {
                                                if (error === 'REQUIRED') return t('modal.required')
                                                return error
                                            }}
                                            name="link"
                                            value={value}
                                            onChange={setValue}
                                            permaValue={REFERRAL_LINK_BASE}
                                            tSize={14}
                                        />
                                        <Button onClick={submit} variant="primary">
                                            {t('modal.button')}
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Modal>
                        ) : (
                            <Modal closeHigher onClose={endModal} closeIcon className={cn.link}>
                                <Flex className={cn.flex} column content={'space-between'}>
                                    <div>
                                        <Link className={cn.icon} />
                                        <Text align="center" semibold size="20px">
                                            {t('modal.end.header')}
                                        </Text>
                                        <Text margin={'12px 0 0 0'} align="center" color="gray" size={14}>
                                            {t('modal.end.text')}
                                        </Text>
                                    </div>
                                    <Button onClick={endModal} variant="primary">
                                        {t('modal.end.button')}
                                    </Button>
                                </Flex>
                            </Modal>
                        )}
                    </FormProvider>
                ) : null}

                <GlobalWidth className={cn.global} margin="0 10% 20%">
                    <h1 className={cn.title}>{t('title')}</h1>
                    <div className={cn.main}>
                        <Flex>
                            <Flex grow column>
                                <Flex align="center">
                                    <Text size={18} margin="0 6px 0 0" color="primary">
                                        {t('header')}
                                    </Text>
                                    <Tooltip text={t('tooltips.desc')} isHTML={true} />
                                </Flex>

                                <Text size={12} margin="3px 0" color="gray">
                                    {t('text')}
                                </Text>

                                <Flex align="center" content="space-between" grow className={cn['create-flex']}>
                                    <Flex>
                                        <ChainLink />
                                        <Text margin="0 0 0 13px" color="link">
                                            {REFERRAL_LINK_BASE +
                                                (refCode ? refCode.replace(/\s/g, '') : refData?.code || '')}
                                        </Text>
                                    </Flex>
                                    <Button variant="primary" onClick={copy}>
                                        {copyState ? t('copyDone') : t('copy')}
                                    </Button>
                                </Flex>
                            </Flex>

                            <Flex align="center" content="space-between" grow className={cn.balance}>
                                <Flex content="space-between" className={cn['balance-text']} column>
                                    <Text semibold size="32px" color="primary" margin="4px 0">
                                        {i18next.language === 'ru'
                                            ? refData?.payments_income || 0
                                            : (refData?.payments_income || 0) / 85}
                                        {i18next.language === 'ru' ? '₽' : '$'}
                                    </Text>
                                    <Text size="16px" color="gray">
                                        {t('current_balance')}
                                    </Text>
                                </Flex>

                                <div className={cn.stick} />

                                <Flex content="space-between" column>
                                    <Text
                                        align="center"
                                        className={cn.checkout}
                                        size="12px"
                                        color="gray"
                                        margin="0 0 8px 0"
                                    >
                                        {t('20_percent')}
                                    </Text>
                                    <Button ref={ref} onClick={openPayment} color="grey" variant="outline">
                                        {t('cashout')}
                                    </Button>
                                    <Popup anchor={ref.current} isOpen={isPaymentOpen} onClose={closePayment}>
                                        <PopupPapper margin="5px 0 0 0">
                                            <Text>{t('cashoutPopup')}</Text>
                                        </PopupPapper>
                                    </Popup>
                                </Flex>
                            </Flex>
                        </Flex>

                        <Flex grow column>
                            <Flex margin="30px 0 0 0" align="center">
                                <Text size={18} margin="0 6px 0 0" color="primary">
                                    {t('stats')}
                                </Text>
                                <Tooltip text={t('tooltips.stats')} isHTML={true} />
                            </Flex>
                        </Flex>

                        <Flex content="space-between">
                            <Flex grow margin="20px 0 0 0" column>
                                <Flex content="space-between" flexGrow>
                                    <Flex column className={cn['stat-part-small']}>
                                        <Text semibold size="26px" margin="3px 0">
                                            {refData?.hits || 0}
                                        </Text>

                                        <Text size="14px" color="gray">
                                            {t('unique_visits')}
                                        </Text>
                                    </Flex>

                                    <Flex column className={cn['stat-part-small']}>
                                        <Text semibold size="26px" margin="3px 0">
                                            {refData?.registrations || 0}
                                        </Text>

                                        <Text size="14px" color="gray">
                                            {t('reg_link')}
                                        </Text>
                                    </Flex>

                                    <Flex column className={cn['stat-part-small']}>
                                        <Text semibold size="26px" margin="3px 0">
                                            {refData?.payments_count || 0}
                                        </Text>

                                        <Text size="14px" color="gray">
                                            {t('purchases')}
                                        </Text>
                                    </Flex>
                                </Flex>

                                <Flex margin="20px 0 0 0" content="space-between">
                                    <Flex column className={cn['stat-part']}>
                                        <Text semibold size="26px" margin="3px 0">
                                            {i18next.language === 'ru'
                                                ? refData?.payments_income || 0
                                                : (refData?.payments_income || 0) / 85}
                                            {i18next.language === 'ru' ? '₽' : '$'}
                                        </Text>
                                        <Text size="14px" color="gray">
                                            {t('purch_sum')}
                                        </Text>
                                    </Flex>
                                    <Flex column className={cn['stat-part']}>
                                        <Text semibold size="26px" margin="3px 0">
                                            {i18next.language === 'ru'
                                                ? refData?.payments_income || 0
                                                : (refData?.payments_income || 0) / 85}
                                            {i18next.language === 'ru' ? '₽' : '$'}
                                        </Text>
                                        <Text size="14px" color="gray">
                                            {t('gains_sum')}
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex style={{ width: '45%' }} margin="20px 0 0 40px">
                                <ListLayout
                                    className={cn.list}
                                    sidePadding="20px"
                                    templateColumns="20% 20% 20% 20% 20%"
                                >
                                    {Object.keys(refData?.referral_stats || {}).length !== 0 ? (
                                        <>
                                            <ListItem className={cn['list--head']} height="46px">
                                                <Segment>
                                                    <Text semibold size={12} color="link">
                                                        {t('date')}
                                                    </Text>
                                                </Segment>

                                                <Segment content="center">
                                                    <Text semibold size={12} color="link">
                                                        {t('visits')}
                                                    </Text>
                                                </Segment>

                                                <Segment content="center">
                                                    <Text semibold size={12} color="link">
                                                        {t('regs')}
                                                    </Text>
                                                </Segment>

                                                <Segment content="center">
                                                    <Text semibold size={12} color="link">
                                                        {t('purchases')}
                                                    </Text>
                                                </Segment>

                                                <Segment content="flex-end">
                                                    <Text semibold size={12} color="link">
                                                        {t('sum')}
                                                    </Text>
                                                </Segment>
                                            </ListItem>
                                            <Scrollbars autoHeight autoHeightMax={300}>
                                                {dates.map((date, index) => (
                                                    <ListItem height={50} key={index}>
                                                        <Segment>
                                                            <Text semibold size={12} color="primary">
                                                                {date}
                                                            </Text>
                                                        </Segment>

                                                        <Segment content="center">
                                                            <Text semibold size={12} color="primary">
                                                                {typeof refData?.referral_stats[dates[index]].hits ===
                                                                'number'
                                                                    ? refData?.referral_stats[dates[index]].hits
                                                                    : ''}
                                                            </Text>
                                                        </Segment>

                                                        <Segment content="center">
                                                            <Text semibold size={12} color="primary">
                                                                {typeof refData?.referral_stats[dates[index]]
                                                                    .registrations === 'number'
                                                                    ? refData?.referral_stats[dates[index]]
                                                                          .registrations
                                                                    : ''}
                                                            </Text>
                                                        </Segment>

                                                        <Segment content="center">
                                                            <Text semibold size={12} color="primary">
                                                                {typeof refData?.referral_stats[dates[index]]
                                                                    .payments_count === 'number'
                                                                    ? refData?.referral_stats[dates[index]]
                                                                          .payments_count
                                                                    : ''}
                                                            </Text>
                                                        </Segment>

                                                        <Segment content="flex-end">
                                                            <Text semibold size={12} color="primary">
                                                                {typeof refData?.referral_stats[dates[index]]
                                                                    .payments_sum === 'number'
                                                                    ? refData?.referral_stats[dates[index]]
                                                                          .payments_sum + '₽'
                                                                    : ''}
                                                            </Text>
                                                        </Segment>
                                                    </ListItem>
                                                ))}
                                            </Scrollbars>
                                        </>
                                    ) : (
                                        <Flex className={cn['empty--flex']} grow column content="center" align="center">
                                            <Flex content="center" align="center" className={cn.circle}>
                                                <ChainLink className={cn.big} />
                                            </Flex>
                                            <Text semibold size={18} margin={'30px 0 10px 0 '} align="center">
                                                {t('start_earning')}
                                            </Text>
                                            <Text size={12} color="gray" align="center">
                                                {t('earning_small')}
                                            </Text>
                                        </Flex>
                                    )}
                                </ListLayout>
                            </Flex>
                        </Flex>
                    </div>
                </GlobalWidth>
            </Flex>
        </PageLayout>
    )
}

export default ReferralPage
