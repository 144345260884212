import React, { FC, useEffect, useMemo, useState } from 'react'

import { Flex } from '@/_yc'
import Controls from './Controls/Contorls'
import VideosTable from './VideosTable/VideosTable'

import { IVideo } from './types'
import { preprocessRawVideoData } from './utils'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import { TimeSpanType, VideoFilter } from '../../Model/types'
import Loader from '@/components/Loader'

export interface IVideosBLock {}

const VideosBlock: FC<IVideosBLock> = () => {
    const [videos, setVideos] = useState<Array<IVideo>>([])
    const [isLoading, setIsLoading] = useState(false)

    const videoFilter: VideoFilter = useSelector((store: RootStore) => {
        return store.report.videos.filter
    })

    const timeSpan: TimeSpanType = useSelector((store: RootStore) => {
        return store.report.videos.timespan
    })

    const videoData = useSelector((store: RootStore) => {
        if (!store.report.rawVideosData.getVideos) return
        const channelVideos = store.report.rawVideosData.getVideos?.data?.youtube_channel[0]
        const yearVideos = channelVideos?.year_videos || []
        const halfYearVideos = channelVideos?.half_year_videos || []
        const monthVideos = channelVideos?.month_videos || []
        const mostViewedVideos = channelVideos?.most_viewed_videos || []

        const videos = {
            yearVideos,
            halfYearVideos,
            monthVideos,
            mostViewedVideos,
        }
        return videos
    })

    const filteredVideos = useMemo(() => {
        if (!videoData) return []

        if (videoFilter === 'views') {
            return videoData.mostViewedVideos
        }

        switch (timeSpan) {
            case 'year':
                return videoData.yearVideos
            case '6months':
                return videoData.halfYearVideos
            case 'month':
                return videoData.monthVideos
        }
    }, [videoData, videoFilter, timeSpan])

    useEffect(() => {
        setIsLoading(videoData === undefined)
    }, [videoData, setIsLoading])

    useEffect(() => {
        if (!filteredVideos) return

        const preprocessedVideos = filteredVideos && preprocessRawVideoData(filteredVideos)
        preprocessedVideos && setVideos(preprocessedVideos)
    }, [filteredVideos, setVideos])

    return (
        <Flex column>
            <Controls />

            {!isLoading || videos.length > 0 ? (
                <VideosTable videos={videos.slice(0, 9)} />
            ) : (
                <Flex content="center" margin="20px 0 0 0">
                    <Loader />
                </Flex>
            )}
        </Flex>
    )
}

export default VideosBlock
