import React, { SVGAttributes } from 'react'

const Youtube = (props: SVGAttributes<SVGElement> & { color?: string }) => (
    <svg {...props} width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.00679 11.8432H8.99013C8.93846 11.8432 3.76929 11.8332 2.46513 11.479C1.74798 11.2858 1.18792 10.7254 0.995127 10.0082C0.760609 8.68514 0.647361 7.34346 0.656794 5.99984C0.650735 4.65402 0.767047 3.31043 1.00429 1.98567C1.20238 1.26776 1.75998 0.704795 2.47596 0.499837C3.74429 0.166504 8.77096 0.166504 8.98429 0.166504H9.00179C9.05429 0.166504 14.2368 0.176504 15.5276 0.530671C16.2433 0.724845 16.8022 1.28408 16.996 1.99984C17.2379 3.32779 17.3515 4.67595 17.3351 6.02567C17.341 7.36986 17.2244 8.7118 16.9868 10.0348C16.7915 10.7512 16.2311 11.3104 15.5143 11.504C14.2476 11.8398 9.22013 11.8432 9.00679 11.8432ZM7.34013 3.504L7.33596 8.50401L11.6793 6.004L7.34013 3.504Z"
            fill={props.color ? props.color : '#7685FF'}
        />
    </svg>
)
export default Youtube
