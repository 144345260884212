import React, { FC, memo, useContext, ReactNode } from 'react'

import Portal from '@/_helpers/Portal'
import Context from './AudienceLayoutContext'

export interface IExtraContent {
    children?: ReactNode
}

const ExtraContent: FC<IExtraContent> = (props) => {
    const ctx = useContext(Context)

    if (!ctx) return null

    return <Portal target={ctx.container}>{props.children}</Portal>
}

export default memo(ExtraContent)
