import React from 'react';

import { Mediator } from './Mediator.js'
import { Width } from '../helpers'

import { row, divider } from './Blocks.module.scss'

export const Row = props => {
	if (props.item.constructor.name === "Array") 
		return (
			<div className = {row} >
				{props.item.map((item, index) => (
					<React.Fragment key={index} >
						<Mediator hidden={props.hidden} item={item} width={Width(item.space, 3)} />
						{index!==props.item.length-1 && <div className = {divider} />}
					</React.Fragment>
				))}
			</div>
		)
	switch (props.item.type) {
		case "twoTailed": 
			return (
			<div className = {row} >
				{props.item.content.map((item, index) => !item ? null : (
					<React.Fragment key={index} >
						<Mediator hidden={props.hidden} item={item} width={"calc(50% - 10px)"} isTwoTailed />
						{index!==props.item.content.length-1 && <div className = {divider} />}
					</React.Fragment>
				))}
			</div>
		)
		case "row": 
			return (
			<div className = {row} >
				{props.item.map((item, index) => (
					<React.Fragment key={index} >
						<Mediator hidden={props.hidden} item={item} width={Width(item.space, 3)} />
						{index!==props.item.length-1 && <div className = {divider} />}
					</React.Fragment>
				))}
			</div>
		)
		default: return <Mediator hidden={props.hidden} item={props.item} width={Width(3, 3)} />
	}
}