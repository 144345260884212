import useClass from '@/_helpers/Hooks/useClass';
import React, { useCallback } from 'react';

import cn from './SimpleInput.module.scss'

const SimpleInput = (props, ref) => {
	const { value, error, onChange, name, type, title, style, classes } = props;

	const change = useCallback(e => onChange(e.target.value), [onChange])

	const root = useClass(
		cn.root,
		classes?.root
	)

	const titleClass = useClass(
		cn.title,
		classes?.title
	)
		
	return (
		<div ref = {ref} style = {style} className = {root}>
			<div className = {cn.hat}>
				<div className = {titleClass}>{title}</div>
				{error && <div className = {cn.error}>{error}</div>}
			</div>
			<input 
				type = {type}
				value = {value} 
				onChange = {change} 
				name = {name}
				className={classes?.input}
			/>
		</div>
	)
}

export { SimpleInput as default }