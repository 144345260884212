import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { compose } from 'recompose'

import { _actions } from '../_actions.js'

import Pop from './Popper.js'

const mapStateToProps = createSelector(
	store => store.ContextMenu_,
	lists => ({ lists })
)

const mapDispatchToProps = dispatch => ({
	clearStore(){
		dispatch(_actions.clearStore())
	},
	getLists(page){
		dispatch(_actions.getLists(page))
	},
	checkItem(index){
		dispatch(_actions.check(index))
	},
	addList(name){
		dispatch(_actions.addList(name));
	}
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation()
)(Pop)