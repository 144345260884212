import React, { FC, ReactNode, CSSProperties, MouseEvent as Me } from 'react'

import { Popper, ClickAwayListener } from '@material-ui/core'
import { PopperProps } from '@material-ui/core'

export interface IPopup {
    anchor?: HTMLElement | null
    children: ReactNode
    isOpen: boolean
    onClose: (e: Me<Document, MouseEvent>) => void
    style?: CSSProperties
    disablePreventOverflow?: boolean
    placement?: PopperProps['placement']
    boundariesElement?: 'scrollParent' | 'window'
    disablePortal?: boolean
}

const Popup: FC<IPopup> = (props) => {
    const mods = {
        flip: {
            enabled: false,
            options: {
                altBoundary: true,
                rootBoundary: 'document',
            },
        },
        preventOverflow: {
            enabled: true,
            boundariesElement: props.boundariesElement ? props.boundariesElement : 'window',
            options: {
                rootBoundary: 'document',
            },
        },
    }

    return (
        <Popper
            style={props.style}
            modifiers={mods}
            disablePortal={props.disablePortal ? props.disablePortal : false}
            anchorEl={props.anchor}
            open={props.isOpen}
            placement={props.placement}
        >
            <ClickAwayListener onClickAway={props.onClose}>{props.children}</ClickAwayListener>
        </Popper>
    )
}

export default Popup
