import React, { FC } from 'react'

interface IEmptyCover {
    [key: string]: any
}

const Cover: FC<IEmptyCover> = (props) => {
    return (
        <svg
            width="270"
            height="270"
            viewBox="0 0 270 270"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={props.style}
        >
            <mask id="mask0_1:303" maskUnits="userSpaceOnUse" x="0" y="0" width="270" height="270">
                <rect width="270" height="270" rx="4" fill="#E2E6FF" />
            </mask>
            <g mask="url(#mask0_1:303)">
                <rect width="270" height="270" rx="4" fill="#C7CCF9" />
                <path
                    d="M40.6336 161.83L0 202.045V270H230L140.21 197.09C137.744 194.65 133.746 194.65 131.281 197.09L112.7 215.479C110.234 217.919 106.236 217.919 103.771 215.479L49.5625 161.83C47.0969 159.39 43.0992 159.39 40.6336 161.83Z"
                    fill="url(#paint0_linear_1:303)"
                />
                <circle cx="195" cy="65" r="25" fill="#D9DDFF" />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_1:303"
                    x1="115"
                    y1="212.756"
                    x2="118.725"
                    y2="276.304"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DCE0FF" />
                    <stop offset="1" stopColor="#C8CEFF" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default Cover
