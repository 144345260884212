import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.6663 15H6.66634V13.3333H16.6663V15ZM4.99967 15H3.33301V13.3333H4.99967V15ZM16.6663 10.8333H6.66634V9.16667H16.6663V10.8333ZM4.99967 10.8333H3.33301V9.16667H4.99967V10.8333ZM16.6663 6.66667H6.68551V5H16.6663V6.66667ZM4.99967 6.66667H3.33301V5H4.99967V6.66667Z"
            fill="#EC8E00"
        />
    </svg>
)
