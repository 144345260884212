import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { Flex, Text } from '@/_yc'
import { ApexOptions } from 'apexcharts'
import React, { FC, useCallback } from 'react'

import cn from './Graph.module.scss'

import ReactApexChart from 'react-apexcharts'
import { shortNum } from '@/_helpers/_graphs/_tech'
import Tooltip from './Tooltip/Tooltip'
import { renderToString } from 'react-dom/server'
import { Option, TooltipOptions } from './types'
import { Tooltip as Tooltipy } from '@yoloker/ui-collection'

const AllocationGraph: FC<Option> = (props) => {
    const t = useSpaceTranslation('report_layout.allocation_graph')
    const displayTooltip = useCallback((options: TooltipOptions) => {
        return renderToString(<Tooltip dataPointIndex={options.dataPointIndex} w={options.w} />)
    }, [])

    const options: ApexOptions = {
        chart: {
            height: 460,
            type: 'scatter',
            toolbar: {
                show: false,
                autoSelected: 'pan',
            },
            zoom: {
                enabled: false,
            },
        },
        series: [{ data: props.info }],
        tooltip: {
            custom: displayTooltip,
        },
        xaxis: {
            title: {
                text: t('likes'),
                style: {
                    fontFamily: 'Montserrat',
                    color: '#ADADAD',
                },
            },
            type: 'numeric',
            tickAmount: 6,
            labels: {
                formatter: (val) => shortNum(val),
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            labels: {
                formatter: (val) => shortNum(val),
            },

            title: {
                text: t('comms'),
                offsetX: 5,
                style: {
                    fontFamily: 'Montserrat',
                    color: '#ADADAD',
                },
            },
            tooltip: {
                enabled: false,
            },
        },
        markers: {},
    }

    return (
        <Flex className={cn.root} column>
            <Flex>
                <Text semibold size={14} color="gray">
                    {t('title_likes')}
                </Text>
                <Tooltipy text={t('tooltip_likes')}></Tooltipy>
            </Flex>
            <div className={props.hidden ? cn['blur--active'] : cn['blur--default']}>
                <ReactApexChart
                    options={options}
                    width={'100%'}
                    height={'422px'}
                    series={[{ data: props.info }]}
                    type="scatter"
                />
            </div>
        </Flex>
    )
}

export default AllocationGraph
