import React, { FC, useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import useOpenClose from '@/_helpers/useOpenClose'
import Icons from '@/components/Icons'
import Flex from '@/_yc/Flex'
import useToken from '@/_helpers/Hooks/useToken'
import { AddTagReq, DeletTagReq } from '@/pages/Lists/model/service'
import { ITag } from '@/pages/Lists/model/types'
import notif from '@/components/Notifier/model/actions.js'
import { Stylable } from '@/Types'
import useClass from '@/_helpers/Hooks/useClass'

import cn from './Tags.module.scss'

interface ITags extends Stylable {
    tags: ITag[]
    id: number
    name: string
}
const Tags: FC<ITags> = (props) => {
    const { t } = useTranslation()
    const ref = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()
    const token = useToken()
    const [isOpen, open, close] = useOpenClose()
    const edit = useClass(cn.root, props.className)

    const [newTag, setNewTag] = useState<string | undefined>()
    const [tagsAr, setTagsAr] = useState<ITag[]>(props.tags)

    const addTag = () => {
        if (!token) return
        if (!newTag) return
        AddTagReq(token, props.id, props.name, [{ name: newTag }])
            .then((res) => {
                setTagsAr(res.tags)
                dispatch(notif.success(t('aud_insides.status.success')))
            })
            .catch(() => {
                dispatch(notif.error(t('aud_insides.status.error')))
            })
        close()
    }

    const deleteTag = (name: string) => {
        if (!token) return
        DeletTagReq(token, name)
            .then(() => {
                setTagsAr(tagsAr.filter((tagItem) => tagItem.name !== name))
                dispatch(notif.success(t('aud_insides.status.success')))
            })
            .catch(() => {
                dispatch(notif.error(t('aud_insides.status.error')))
            })
        close()
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            addTag()
        }
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                close()
            }
        }
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [close])

    return (
        <>
            {!isOpen && props.tags && tagsAr.length !== 0 && (
                <Flex className={cn.tags} style={{ width: '98%' }} align="center">
                    <Flex className={cn['tags-container']}>
                        {tagsAr.map((tag, i) => (
                            <Flex key={i} className={cn['tags--tag-grey']} align={'center'} style={{ width: 'unset' }}>
                                <p>#{tag.name}</p>
                            </Flex>
                        ))}
                    </Flex>
                    <Icons name={'edit'} className={edit} onClick={open} />
                </Flex>
            )}
            {!isOpen && props.tags && tagsAr.length === 0 && (
                <>
                    <p className={cn.add} onClick={open}>
                        + {t('aud_insides.tags.add_tag')}
                    </p>
                </>
            )}
            {isOpen && props.tags && (
                <>
                    <Flex className={cn.container} align="center" ref={ref}>
                        <Flex className={cn['tags-open']} style={props.tags.length === 0 ? { display: 'none' } : {}}>
                            {tagsAr.map((tag, i) => (
                                <Flex className={cn['tags--tag']} align={'center'} key={i}>
                                    <p>{tag.name}</p>
                                    <Icons name={'close--small'} onClick={() => deleteTag(tag.name)} />
                                </Flex>
                            ))}
                        </Flex>

                        <Flex
                            align="center"
                            content={'space-between'}
                            className={cn.input}
                            style={tagsAr.length === 0 ? { width: '100%' } : {}}
                        >
                            <input
                                value={newTag}
                                placeholder={'Enter'}
                                type={'text'}
                                onChange={(e) => setNewTag(e.target.value)}
                                style={tagsAr.length === 0 ? { width: 'auto' } : {}}
                                onKeyDown={handleKeyDown}
                            />
                            <Flex>
                                <div className={cn.doneButton} onClick={addTag}>
                                    <Icons name={'done'} />
                                </div>
                                <div className={cn.crossButton} onClick={close}>
                                    <Icons name={'close--small'} />
                                </div>
                            </Flex>
                        </Flex>
                    </Flex>
                </>
            )}
        </>
    )
}

export default Tags
