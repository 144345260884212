import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '@/components/Button'
import Checkbox from '@/_yc/Checkbox'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { Icons, Modal, Text } from '@/_yc'
import Icon from '@/components/Icons'
import { RootStore } from '@/_helpers/StoreType'
import Flex from '@/_yc/Flex'
import TariffIcons from './Icons/TariffIcons'
import TariffOption from './TariffOption'
import Price from './Price'
import { Package } from '../Model/types'
import ym from 'react-yandex-metrika'

import cn from './TariffCard.module.scss'
import i18next from 'i18next'

import useOpenClose from '@/_helpers/useOpenClose'
import { ErrorConsumer, FieldConsumer, FormProvider, useController } from '@schematic-forms/react'
import { Bool, EmailValidator, Str } from '@schematic-forms/core'
import { customPhoneValidator } from '@/_helpers/CustomPhoneValidator'
import { createRequest } from '@/Services/Billing'
import useToken from '@/_helpers/Hooks/useToken'
import FormInput from '@/components/Share/Form/FormInput'
import Check from './Icons/Check'
import { track } from '@amplitude/analytics-browser'
export interface ITariffCard {
    card: Package
    handlePayment: Function
    yearlySwitch: boolean
}

type TariffIndex = 1 | 3 | 5 | 7

const labelYmMap = {
    1: 'kupit_business_',
    3: 'kupit_standart_',
    7: 'kupit_agensy_',
    5: 'kupit_only_reporst_',
}

const amplitudeMap = {
    5: 'only reports',
    3: 'standard',
    1: 'business',
    7: 'agency',
}

const TariffCard: FC<ITariffCard> = ({ card, handlePayment, yearlySwitch }) => {
    const t = useSpaceTranslation('tariffs_page')

    const [isPopupOpen, popupOpen, popupClose] = useOpenClose()
    const [stage, setStage] = useState<1 | 2>(1)
    const close = useCallback(() => {
        setStage(1)
        popupClose()
    }, [popupClose])
    const [currentTariffIndex, setCurrentTariffIndex] = useState(0)
    const [checkRecurring, setCheckRecurring] = useState(true)

    const currentTariff = card.tariffs[currentTariffIndex]

    const token = useToken()

    const { controller, submit } = useController({
        fields: {
            name: Str(true, ''),
            email: Str(true, ''),
            phone: Str(true, ''),
            check: Bool(true, false),
        },
        validators: {
            email: EmailValidator(),
            phone: (value) => {
                const phTest = customPhoneValidator(value)

                if (!phTest) return new Error('NOT_VALID_PHONE_NUMBER')
                else return
            },
        },
        submit: async (data) => {
            await createRequest(data.name, data.email, data.phone, token || '').then(() => setStage(2))
        },
    })

    const sub = useCallback(
        (e) => {
            e.preventDefault()
            submit()
        },
        [submit]
    )

    const tariff_status =
        useSelector((store: RootStore) => {
            return store.authentication.user?.profile?.package ?? ''
        }) !== ''
            ? true
            : false

    const handleCheck = () => {
        setCheckRecurring(!checkRecurring)
    }

    const buttonClick = useCallback(
        (cardId: TariffIndex, curTariffId: number, checkRecurring: boolean) => {
            if (!card.subscription && card.id !== 5) {
                popupOpen()
                return
            }
            if ((global as any).isProd) {
                const plan = labelYmMap[cardId as TariffIndex]
                plan && ym('reachGoal', `${plan}${i18next.language}`)
            }

            track('payments', { package: amplitudeMap[cardId as TariffIndex] })
            handlePayment(curTariffId, checkRecurring, currentTariff?.currency)
        },
        [handlePayment, card, popupOpen, currentTariff]
    )

    const errorTransform = useCallback(
        (name: string, error: string) => {
            if (!error.includes(' ')) return t(`modal.fields.${name}.errors.${error}`)
            else return error
        },
        [t]
    )

    const sortedFeatures = [...card.features].sort((a, b) => (a.position > b.position ? 1 : -1))
    return (
        <div className={`${!card.label ? cn.root : cn['root--with-specialOffer']}`}>
            {card.label && (
                <div
                    className={cn.specialOffer}
                    style={{
                        background: card.color,
                    }}
                >
                    {card.label}
                </div>
            )}
            <Flex className={cn.card_label} content="space-between">
                {card.name}
            </Flex>

            <TariffIcons tariffs={card.tariffs} option={currentTariff?.platforms[0]} />

            <p className={cn.brief}>{card.brief}</p>

            <Price
                price_model={currentTariff?.price_model}
                currency={currentTariff?.currency}
                before_price={currentTariff?.before_price}
                price={currentTariff?.price}
                reports_available={currentTariff?.reports_available}
                title={card.title}
                yearlySwitch={yearlySwitch}
            />

            <Flex content="space-between" style={card.tariffs.length === 1 && { display: 'none' }}>
                {card.tariffs.map((tariff, index) => (
                    <TariffOption
                        price_model={tariff.price_model}
                        index={index}
                        currentTariff={currentTariffIndex}
                        setTariff={setCurrentTariffIndex}
                        label={tariff.price_model === 'report' ? `${tariff.reports_available}` : tariff.platforms[0]}
                    />
                ))}
            </Flex>

            <div className={cn.pros}>
                {sortedFeatures.map((item) => (
                    <div className={cn['pros--item']}>
                        {item.type === '+' ? (
                            <Icons context="" name="check" className={cn.checkIcon} />
                        ) : (
                            <Icon name="close--small" className={cn.icon} />
                        )}
                        <span>{item.name}</span>
                    </div>
                ))}
            </div>

            <div className={cn.btns}>
                <Flex onClick={handleCheck} className={cn.item} margin="5px 0 10px 0">
                    <Checkbox
                        id="subID"
                        value={checkRecurring}
                        size="big"
                        margin={'0 8px 0 0'}
                        style={{
                            backgroundColor: 'white',
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: '#6071FF',
                        }}
                    />
                    <label htmlFor="subID">
                        <span className={cn['checkbox--title']}>{t(`subscripton.auto`)}</span>
                    </label>
                </Flex>

                <Button
                    onClick={() => buttonClick(card?.id as TariffIndex, currentTariff?.id, checkRecurring)}
                    variant="outline"
                >
                    {tariff_status ? card.button : t(`change_plan`)}
                </Button>
                {isPopupOpen ? (
                    <Modal className={cn.modal} closeIcon onClose={close}>
                        {stage === 1 ? (
                            <>
                                <Text size={20} margin={'-20px 0 0 0'}>
                                    {t('modal.modal_header')}
                                </Text>
                                <Text size={14} margin={'15px 0 0 0'} color="gray">
                                    {t('modal.modal_text')}
                                </Text>
                                <FormProvider controller={controller}>
                                    <form onSubmit={sub}>
                                        <FormInput
                                            margin={'18px 0 0 0'}
                                            name="name"
                                            title={t('modal.req_name_title')}
                                            placeholder={t('modal.req_name_placeholder')}
                                            className={cn['modal--input']}
                                            errorTransform={errorTransform}
                                        />
                                        <FormInput
                                            margin={'18px 0 0 0'}
                                            name="phone"
                                            title={t('modal.req_phone_title')}
                                            placeholder={t('modal.req_phone_placeholder')}
                                            className={cn['modal--input']}
                                            errorTransform={errorTransform}
                                        />
                                        <FormInput
                                            margin={'18px 0 0 0'}
                                            name="email"
                                            title={t('modal.req_mail_title')}
                                            placeholder={t('modal.req_mail_placeholder')}
                                            className={cn['modal--input']}
                                            errorTransform={errorTransform}
                                        />
                                        <Flex margin="16px 0 0" align="center">
                                            <FieldConsumer field="check">
                                                {({ value, setValue, error }) => (
                                                    <Flex column>
                                                        <Flex>
                                                            <Checkbox value={value} onChange={setValue} />
                                                            <Flex margin="0 5px 0 5px" align="center" wrap>
                                                                <Text size={12} color="gray">
                                                                    {t('modal.agreement')}
                                                                </Text>
                                                                <a
                                                                    target="_blank"
                                                                    href={'/terms.pdf'}
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <Text
                                                                        className={cn['link']}
                                                                        color="secondary"
                                                                        size={12}
                                                                    >
                                                                        {t('modal.agreement_link')}
                                                                    </Text>
                                                                </a>
                                                            </Flex>
                                                        </Flex>
                                                        <Text color="red" size={11}>
                                                            {error ? errorTransform('check', error || '') : ''}
                                                        </Text>
                                                    </Flex>
                                                )}
                                            </FieldConsumer>
                                        </Flex>
                                        <ErrorConsumer>
                                            {({ hasError }) => (
                                                <Button
                                                    margin="20px 0 0"
                                                    type="submit"
                                                    className={cn.button}
                                                    disabled={hasError}
                                                    variant="primary"
                                                >
                                                    <Text color="white">{t('modal.req_order')}</Text>
                                                </Button>
                                            )}
                                        </ErrorConsumer>
                                    </form>
                                </FormProvider>
                            </>
                        ) : (
                            <>
                                <Check />
                                <Text margin="40px 0 0" color="gray2" size={20}>
                                    {t('modal.req_title')}
                                </Text>
                                <Text margin="10px 0 0" color="gray">
                                    {t('modal.suc_descr')}
                                </Text>
                                <Button
                                    margin="20px 0 0"
                                    type="submit"
                                    className={cn.button}
                                    variant="primary"
                                    onClick={close}
                                >
                                    <Text color="white">{t('modal.close')}</Text>
                                </Button>
                            </>
                        )}
                    </Modal>
                ) : undefined}
            </div>
        </div>
    )
}
export default TariffCard
