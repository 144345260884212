import React from 'react'
import { useTranslation } from 'react-i18next'

import Flex from '@/_yc/Flex'

import cn from '../CatalogOfLists/CatalogOfLists.module.scss'

const ErrorPage = () => {
    const { t } = useTranslation()
    return (
        <>
            <Flex className={cn.empty} align="center" content="center" column>
                <p className={cn.title}>{t('aud_insides.status.error')}</p>
            </Flex>
        </>
    )
}

export default ErrorPage
