import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { FilterCategory } from '../../../filters/FilterCategory'
import { getFollowersFilter } from './FilterConfigs/channel/Followers'
import { getAvgViewsFilter } from './FilterConfigs/channel/views'
import { getContactsFilter } from './FilterConfigs/channel/contacts'
import { getSubGainFilter } from './FilterConfigs/channel/subGain'
import { getBioFilter } from './FilterConfigs/channel/bio'
import { getKeywordsFilter } from './FilterConfigs/channel/Keywords'
import { getGenderFilter } from './FilterConfigs/influencer/gender'
import { getAgeFilter } from './FilterConfigs/influencer/age'
import { getLookalikeFilter } from './FilterConfigs/influencer/lookalike'
import { getLastPostFilter } from './FilterConfigs/content/LastPublDate'
import { getSavesFilter } from './FilterConfigs/content/saves'
import { getSharesFilter } from './FilterConfigs/content/shares'
import { getEngagementsFilter } from './FilterConfigs/content/Engagements'
import { getERFilter } from './FilterConfigs/content/er'
import { getLikesGtowthFilter } from './FilterConfigs/content/likesGrowth'
import { getAudGenderFilter } from './FilterConfigs/audience/audGender'
import { getAudAgeFilter } from './FilterConfigs/audience/audAge'
import { getAudLookalikeFilter } from './FilterConfigs/audience/audLookalike'
import { getInfCountryFilter } from './FilterConfigs/influencer/location'
import { getAudCountryFilter } from './FilterConfigs/audience/audLocation'
import { getInflLanguageFilter } from './FilterConfigs/influencer/language'
import { getAudLanguageFilter } from './FilterConfigs/audience/audLang'

// const InterestsFilterObject = getInterestsFilter()
const followersFilter = getFollowersFilter()
const viewsFilter = getAvgViewsFilter()
const savesFilter = getSavesFilter()
const sharesFilter = getSharesFilter()
const engagementFilter = getEngagementsFilter()

export const getMainFilters = (fetcher: Fetcher) => {
    return {
        main: new FilterCategory('filters.categories.main', {
            followers: followersFilter,
            views: viewsFilter,
            saves: savesFilter,
            shares: sharesFilter,
            // engagement: engagementFilter,
        }),
    }
}

export const getFilters = (fetcher: Fetcher) => {
    return {
        channel_metrics: new FilterCategory('filters.categories.channel', {
            followers: followersFilter,
            followersGrowth: getSubGainFilter(),
            views: viewsFilter,
            bio: getBioFilter(),
            keywords: getKeywordsFilter(),
            contacts: getContactsFilter(),
            // interests: getInterestsFilter(fetcher),
            // language: getLanguagesFilter(fetcher),
        }),
        influencer_metrics: new FilterCategory('filters.categories.influencer', {
            location: getInfCountryFilter(fetcher),
            lang: getInflLanguageFilter(fetcher),
            gender: getGenderFilter(),
            age: getAgeFilter(),
            lookalike: getLookalikeFilter(),
        }),
        content_metrics: new FilterCategory('filters.categories.content', {
            las_publ: getLastPostFilter(),
            saves: savesFilter,
            shares: sharesFilter,
            engagement: engagementFilter,
            er: getERFilter(),
            likesGrowth: getLikesGtowthFilter(),
        }),
        audience_metrics: new FilterCategory('filters.categories.audience', {
            audLocation: getAudCountryFilter(fetcher),
            audLang: getAudLanguageFilter(fetcher),
            audGender: getAudGenderFilter(),
            age: getAudAgeFilter(),
            audLookalike: getAudLookalikeFilter(),
        }),
    }
}
