import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.4965 4.6033C19.108 4.259 19.798 4.07812 20.4998 4.07812C21.2016 4.07812 21.8915 4.259 22.5031 4.6033C23.1146 4.9476 23.6271 5.44369 23.991 6.04373L23.9932 6.04729L38.1099 29.614L38.116 29.6243L38.116 29.6243C38.4725 30.2418 38.6611 30.9418 38.6631 31.6548C38.6651 32.3678 38.4804 33.0689 38.1273 33.6883C37.7743 34.3077 37.2651 34.8239 36.6506 35.1855C36.0361 35.5471 35.3377 35.7415 34.6247 35.7493L34.6165 35.7494L6.38312 35.7494H6.37488V35.7493C5.66193 35.7415 4.96345 35.5471 4.34895 35.1855C3.73445 34.8239 3.22533 34.3077 2.87225 33.6883C2.51917 33.0689 2.33445 32.3678 2.33644 31.6548C2.33844 30.9418 2.52709 30.2418 2.88363 29.6243L2.88964 29.6139L2.88972 29.614L17.0064 6.0473L17.0085 6.04373C17.3725 5.44369 17.885 4.9476 18.4965 4.6033ZM20.4998 5.57812C20.0558 5.57812 19.6193 5.69256 19.2324 5.91038C18.8461 6.12788 18.5223 6.44114 18.292 6.82001L18.291 6.82167L4.17983 30.3793C3.95607 30.7687 3.83769 31.2098 3.83644 31.659C3.83517 32.1101 3.95204 32.5536 4.17542 32.9455C4.39879 33.3374 4.72089 33.664 5.10966 33.8927C5.49735 34.1208 5.93787 34.2438 6.38762 34.2494H34.612C35.0617 34.2438 35.5022 34.1208 35.8899 33.8927C36.2787 33.664 36.6008 33.3374 36.8242 32.9455C37.0475 32.5536 37.1644 32.1101 37.1631 31.659C37.1619 31.2098 37.0435 30.7687 36.8198 30.3793L22.7085 6.82167L22.7076 6.82012C22.4774 6.4412 22.1535 6.1279 21.7672 5.91038C21.3803 5.69256 20.9438 5.57812 20.4998 5.57812ZM20.5 14.2494C20.9142 14.2494 21.25 14.5852 21.25 14.9994V21.6661C21.25 22.0803 20.9142 22.4161 20.5 22.4161C20.0858 22.4161 19.75 22.0803 19.75 21.6661V14.9994C19.75 14.5852 20.0858 14.2494 20.5 14.2494ZM20.5 27.5828C20.0858 27.5828 19.75 27.9186 19.75 28.3328C19.75 28.747 20.0858 29.0828 20.5 29.0828H20.5167C20.9309 29.0828 21.2667 28.747 21.2667 28.3328C21.2667 27.9186 20.9309 27.5828 20.5167 27.5828H20.5Z"
            fill="#5D697D"
        />
    </svg>
)
