import React, { memo, ComponentType, useMemo } from 'react'
import { Icon } from '../Types'
import { MessageType } from './Types'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import Text from '@/components/Text'
import { Trans } from 'react-i18next'

export type ParsingErrorProps = {
    username: string
    errorType: ParsingErrorType
}

export type ParsingErrorType = 'DOESNT_EXIST' | 'PRIVATE' | 'TOO_SMALL' | 'TOO_LARGE' | 'SERVICE_ERROR'

export default class ParsingError implements MessageType<ParsingErrorProps> {
    icon: Icon = 'error'

    props: ParsingErrorProps

    private values: any

    constructor(username: string, errorType: ParsingErrorType) {
        this.props = { username, errorType }

        this.values = {
            username: this.props.username,
        }
    }
    Component: ComponentType<{ withSublabel?: boolean }> = memo(({ withSublabel }) => {
        const t = useSpaceTranslation('Notifications.onError')

        const components = useMemo(
            () => [<Text fSize={withSublabel ? 12 : 14} component={'span'} color="violet-1" />],
            [withSublabel]
        )

        return (
            <div>
                {withSublabel && (
                    <Text fSize={14} margin="0 0 2px">
                        {t('title')}
                    </Text>
                )}
                <Text color={withSublabel ? 'gray-3' : undefined} fSize={withSublabel ? 12 : 14}>
                    <Trans
                        i18nKey={`Notifications.onError.${this.props.errorType}`}
                        components={components}
                        values={this.values}
                    />
                </Text>
            </div>
        )
    })
}
