import React from 'react';

import cn from './LoadingBar.module.scss'

const lines = new Array(5).fill(1)

export const LoadingBar = (props) => (
	<div className = {cn.root}>
		{lines.map((item, index) => (
			<div 
				key = {index} 
				className = {cn.root_line} 
				style={{
					animationDelay: `${index*.7}s`
				}}
			/>
		))}
	</div>
)
