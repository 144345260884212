import React from 'react';

import { StandartLabel } from '../../Blocks'
import { Icons } from '../../../../../_yc'

import cn from './IconList.module.scss'

export const IconList = ({ data, title, helperText, width }) => (
	<div 
		className = {cn.root}
		style={{ width }}
	>
		<StandartLabel title={title} helperText={helperText}/>
		<div>
			{data.map((item, index) => (
				<div key={index} className = {cn.option}>
					<div className = {cn.option_icon}>
						<Icons 
							name = {item.icon} 
							context = "cats"
							style = {{
								width: 30, 
								height: 30
							}}
						/>
						<div className = {cn.option_head} >{item.label}</div>
					</div>
					{item.value ? 
					<div className = {cn.option_value}>
						{(item.value * 100).toFixed(1)}%
					</div> : null}
				</div>
			))}
		</div>
	</div>
)