import { AdsExplorerInstagramAdapter } from '../SearchAdapter/adsExplorer/instagram'
import { AdsExplorerYoutubeAdapter } from '../SearchAdapter/adsExplorer/youtube'
import { IPlatform } from './Header/types'

export const platforms: IPlatform[] = [
    {
        title: 'Instagram',
        id: 'instagram',
        adapter: AdsExplorerInstagramAdapter,
    },
    {
        title: 'YouTube',
        id: 'youtube',
        adapter: AdsExplorerYoutubeAdapter,
    },
]
