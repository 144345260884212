import React from 'react'
import cn from './SearchItems.module.scss'

import { Adapter } from '../../Adapter'
import Icons from '@/components/Icons'
import ItemsHead from './ItemsHead'
import SearchItem from './SearchItem'
import { getCategories, getLocations, searchBloggers, getCategoriesChildAge } from '@/Services/Search'
import { FilterCategory } from '../../filters/FilterCategory'
import { SelectorFilter } from '../../filters/SelectorFilter'
import { SearchFilter } from '../../filters/SearchFilter'
import { RangeFilter } from '../../filters/RangeFilter'
// import { ListedFilter } from '../../filters/ListedFilter'
import { IAudienceLocation, IInfluencerLocation } from './types'
import moment from 'moment'
import { KeywordsFilter } from '../../filters/KeywordsFilter'

const today = moment()
const PLATFORM = 'infl_exp_ig'

const LocationFilterObject = new SelectorFilter({
    platform: PLATFORM,
    title: 'filters.main_locations.label',
    autocomplete: true,
    icon: <Icons name="filter--geo" />,
    helper: 'filters.main_locations.text',
    placeholder: 'filters.main_locations.placeholder',
    space: 1,
    id: 'location_inst',
    isMulti: true,
    options: (token, locale, query) => {
        return getLocations(token, locale, query)
    },
    beforeSend: ({ value }: IInfluencerLocation) => {
        const res = []
        const cities = value.filter((v) => v.type === 'city')
        const countries = value.filter((v) => v.type === 'country')

        if (cities.length > 0) {
            res.push({
                name: 'location_h3.city_id',
                operator: 'terms',
                type: 'array',
                value: cities.map((v) => v.id),
            })
        }

        if (countries.length > 0) {
            res.push({
                name: 'location_h3.country_id',
                operator: 'terms',
                type: 'array',
                value: countries.map((v) => v.id),
            })
        }
        return res
    },
})

export const InstagramAdapter = new Adapter({
    Icon: (props) => <Icons className={cn.icon} name="instagram" />,
    title: 'Instagram',
    background: `linear-gradient(95.42deg, #6652DF 9.51%, #FF8D74 38.6%, #FFD37C 67.24%, #FF6FAF 95.43%)`,
    templateColumns: '40px 40px 50px 1fr .5fr .5fr .95fr 250px',
    fetcher: async (token, config) => {
        const conditions = Object.values(config.filters).flatMap((cat) => {
            return Object.values(cat)
                .filter(Boolean)
                .flatMap((i) => i)
        })

        if (config.mainFilters) {
            conditions.push(
                ...Object.values(config.mainFilters).flatMap((cat) => {
                    return Object.values(cat)
                        .filter(Boolean)
                        .flatMap((i) => i)
                })
            )
        }
        const res = await searchBloggers(
            token,
            config.sorting,
            conditions,
            config.search
                ? {
                      text: config.search,
                      by: config.by,
                  }
                : undefined,
            config.page
        )

        return {
            hasNext: !!res.next,
            items: res.results,
            total: res.founded,
            isHidden: res.hide,
        }
    },
    countCheck: async (token, config) => {
        const res = await searchBloggers(
            token,
            config.sorting,
            Object.values(config.filters).flatMap((cat) => {
                return Object.values(cat)
                    .filter(Boolean)
                    .flatMap((i) => i)
            }),
            config.search
                ? {
                      text: config.search,
                      by: config.by,
                  }
                : undefined,
            1
        )
        return { count: res.founded }
    },
    Head: ItemsHead,
    Item: SearchItem,
    sortings: [
        { label: 'sortBy.followers', value: 'metrics.followers' },
        { label: 'sortBy.engagement', value: 'metrics.engagement' },
        { label: 'sortBy.relevance', value: '_score' },
    ],
    keyWords: [
        { label: 'forKeywords.username', value: 'info.username' },
        { label: 'forKeywords.bio', value: 'info.biography' },
        { label: 'forKeywords.name', value: 'info.fullName' },
        { label: 'forKeywords.posts', value: 'feed.text' },
    ],
    mainFilters: {
        main: new FilterCategory('filters.main', {
            interests: new SelectorFilter({
                platform: PLATFORM,
                title: 'filters.cat.fields.infl.label',
                autocomplete: false,
                icon: <Icons name="filter--interests--main" />,
                helper: 'filters.cat.fields.infl.text',
                space: 1,
                id: 'interests_inst',
                isMulti: true,
                options: (token, locale) => {
                    const categories = getCategories(token, 'categories', locale)
                    return categories
                },
                beforeSend: ({ value }) => ({
                    name: 'interests',
                    type: 'array',
                    value: value,
                    operator: 'terms',
                }),
            }),
            location: LocationFilterObject,
            followers: new RangeFilter({
                platform: PLATFORM,
                title: 'filters.followers.influencer.label',
                icon: <Icons name="filter--followers" />,
                helper: 'filters.followers.influencer.text',
                id: 'followers_inst',
                space: 1,
                beforeSend: (data) => {
                    const result = new Array<{
                        name: 'metrics.followers'
                        operator: 'lte' | 'gte'
                        type: 'range'
                        value: number
                    }>()
                    if (data.from) {
                        result.push({
                            name: 'metrics.followers',
                            operator: 'gte',
                            type: 'range',
                            value: data.from,
                        })
                    }
                    if (data.to) {
                        result.push({
                            name: 'metrics.followers',
                            operator: 'lte',
                            type: 'range',
                            value: data.to,
                        })
                    }
                    return result
                },
            }),
            text: new KeywordsFilter({
                platform: PLATFORM,
                title: 'filters.search.label',
                icon: <Icons name="search" className={cn['icon--gray']} />,
                helper: 'filters.username.chaining.text',
                placeholder: 'filters.search.placeholder',
                space: 1,
                beforeSend: (value) => ({
                    name: 'chaining',
                    operator: 'equal',
                    type: 'text',
                    value: value,
                }),
            }),
        }),
    },
    filters: {
        infl: new FilterCategory('filters.categories.blogger', {
            interests: new SelectorFilter({
                platform: PLATFORM,
                title: 'filters.cat.fields.infl.label',
                autocomplete: true,
                icon: <Icons name="filter--interests" />,
                helper: 'filters.cat.fields.infl.text',
                id: 'interests_inst',
                space: 1,
                isMulti: true,
                options: (token, locale) => getCategories(token, 'categories', locale),
                beforeSend: ({ value }) => ({
                    name: 'interests',
                    type: 'array',
                    value: value,
                    operator: 'terms',
                }),
            }),
            location: LocationFilterObject,
            followers: new RangeFilter({
                platform: PLATFORM,
                title: 'filters.followers.influencer.label',
                icon: <Icons name="filter--followers" />,
                helper: 'filters.followers.influencer.text',
                space: 1,
                id: 'followers_inst',
                beforeSend: (data) => {
                    const result = new Array<{
                        name: 'metrics.followers'
                        operator: 'lte' | 'gte'
                        type: 'range'
                        value: number
                    }>()
                    if (data.from) {
                        result.push({
                            name: 'metrics.followers',
                            operator: 'gte',
                            type: 'range',
                            value: data.from,
                        })
                    }
                    if (data.to) {
                        result.push({
                            name: 'metrics.followers',
                            operator: 'lte',
                            type: 'range',
                            value: data.to,
                        })
                    }
                    return result
                },
            }),
            aq: new SelectorFilter({
                platform: PLATFORM,
                locked: (feats) => {
                    if (feats) {
                        return !feats.includes('quality')
                    }
                    return true
                },
                title: 'filters.yo_score.label',
                icon: <Icons name="filter--yo-score" />,
                helper: 'filters.yo_score.text',
                options: [
                    { label: '>=20', value: 0.2 },
                    { label: '>=30', value: 0.3 },
                    { label: '>=40', value: 0.4 },
                    { label: '>=45', value: 0.45 },
                    { label: '>=50', value: 0.5 },
                    { label: '>=60', value: 0.6 },
                    { label: '>=65', value: 0.65 },
                    { label: '>=70', value: 0.7 },
                    { label: '>=75', value: 0.75 },
                    { label: '>=80', value: 0.8 },
                ],
                space: 1,
                beforeSend: ({ value }) => ({
                    name: 'blogger_rating.main_value',
                    operator: 'gte',
                    type: 'number',
                    value: value[0],
                }),
            }),
            // look: new ListedFilter({
            //     locked: (feats) => {
            //         if (feats) {
            //             return !feats.includes('appearanceFilter')
            //         }
            //         return true
            //     },
            //     title: 'filters.look.label',
            //     icon: <Icons name="filter--look" />,
            //     space: 1,
            //     lists: {
            //         hairColor: {
            //             title: 'filters.look.fields.hairColor.label',
            //             options: [
            //                 {
            //                     label: 'filters.look.fields.hairColor.blond',
            //                     value: 'blond',
            //                 },
            //                 {
            //                     label: 'filters.look.fields.hairColor.brown',
            //                     value: 'brown',
            //                 },
            //                 {
            //                     label: 'filters.look.fields.hairColor.gray',
            //                     value: 'gray',
            //                 },
            //                 {
            //                     label: 'filters.look.fields.hairColor.red',
            //                     value: 'red',
            //                 },
            //                 {
            //                     label: 'filters.look.fields.hairColor.black',
            //                     value: 'black',
            //                 },
            //                 {
            //                     label: 'filters.look.fields.hairColor.other',
            //                     value: 'other',
            //                 },
            //             ],
            //         },
            //         accessories: {
            //             title: 'filters.look.fields.accessories.label',
            //             options: [
            //                 {
            //                     label: 'filters.look.fields.accessories.glasses',
            //                     value: 'glasses',
            //                 },
            //                 {
            //                     label: 'filters.look.fields.accessories.headwear',
            //                     value: 'headwear',
            //                 },
            //                 {
            //                     label: 'filters.look.fields.accessories.mask',
            //                     value: 'mask',
            //                 },
            //             ],
            //         },
            //         beard: {
            //             title: 'filters.look.fields.beard.label',
            //             options: [
            //                 {
            //                     label: 'filters.look.fields.beard.yes',
            //                     value: 1,
            //                 },
            //                 { label: 'filters.look.fields.beard.no', value: 0 },
            //             ],
            //         },
            //         moustache: {
            //             title: 'filters.look.fields.moustache.label',
            //             options: [
            //                 {
            //                     label: 'filters.look.fields.moustache.yes',
            //                     value: 1,
            //                 },
            //                 {
            //                     label: 'filters.look.fields.moustache.no',
            //                     value: 0,
            //                 },
            //             ],
            //         },
            //     },
            //     beforeSend: (data) => {
            //         const result: any = []
            //         if (data.accessories)
            //             result.push({
            //                 name: 'faces.accessories',
            //                 operator: 'terms',
            //                 type: 'text',
            //                 value: data.accessories.value,
            //             })
            //         if (typeof data.beard !== 'undefined')
            //             result.push({
            //                 name: 'faces.beard',
            //                 operator: 'term',
            //                 type: 'text',
            //                 value: data.beard.value,
            //             })
            //         if (typeof data.moustache !== 'undefined')
            //             result.push({
            //                 name: 'faces.moustache',
            //                 operator: 'term',
            //                 type: 'text',
            //                 value: data.moustache.value,
            //             })
            //         if (data.hairColor)
            //             result.push({
            //                 name: 'faces.color',
            //                 operator: 'terms',
            //                 type: 'text',
            //                 value: data.hairColor.value,
            //             })
            //         return result
            //     },
            // }),
            gender: new SelectorFilter({
                platform: PLATFORM,
                title: 'filters.gender.influencer.label',
                icon: <Icons name="filter--gender" />,
                helper: 'filters.gender.influencer.text',
                space: 1,
                options: [
                    {
                        label: 'filters.gender.value.male',
                        value: 1,
                        translate: true,
                    },
                    {
                        label: 'filters.gender.value.female',
                        value: 2,
                        translate: true,
                    },
                ],
                beforeSend: ({ value }) => ({
                    name: 'ageGender.gender',
                    type: 'text',
                    value: value[0],
                }),
            }),
            age: new SelectorFilter({
                platform: PLATFORM,
                title: 'filters.age.influencer.label',
                icon: <Icons name="filter--age" />,
                helper: 'filters.age.influencer.text',
                space: 1,
                options: [
                    { label: '17 - 24', value: { from: 17, to: 24 } },
                    { label: '24 - 34', value: { from: 24, to: 34 } },
                    { label: '34 - 44', value: { from: 34, to: 44 } },
                    { label: '44 - 54', value: { from: 44, to: 54 } },
                    { label: '54 - 64', value: { from: 54, to: 64 } },
                    { label: '> 64', value: { from: 64 } },
                ],
                beforeSend: ({ value }) => ({
                    name: 'ageGender.age',
                    operator: 'gte',
                    type: 'range',
                    value: value[0],
                }),
            }),
            chil_age: new SelectorFilter({
                platform: PLATFORM,
                title: 'filters.age.children.label',
                icon: <Icons name="filter--children-age" />,
                helper: 'filters.age.children.text',
                space: 1,
                options: (token, locale) => getCategoriesChildAge(token, 'labels', locale),
                beforeSend: (data) => ({
                    name: 'feed.labels',
                    operator: 'terms',
                    type: 'array',
                    value: data.value,
                }),
            }),
            accountType: new SelectorFilter({
                platform: PLATFORM,
                title: 'filters.account_type.label',
                icon: <Icons name="account--type" />,
                helper: 'filters.account_type.text',
                space: 1,

                options: [
                    { label: 'filters.account_type.blogger', value: 'blogger', translate: true },
                    { label: 'filters.account_type.public', value: 'public', translate: true },
                ],
                beforeSend: ({ value }) =>
                    value[0] === 'blogger'
                        ? {
                              name: 'ageGender.gender',
                              type: 'array',
                              value: [1, 2],
                          }
                        : {
                              name: 'ageGender.gender',
                              type: 'array',
                              value: [3, 4, 5],
                              operator: 'terms',
                          },
            }),
            contacts: new SelectorFilter({
                platform: PLATFORM,
                title: 'filters.contacts.label',
                icon: <Icons name="mail" />,
                helper: 'filters.contacts.text',
                space: 1,
                isMulti: true,

                options: [{ label: 'filters.contacts.mail', value: 'info.email', translate: true }],
                beforeSend: ({ value }) => {
                    const results: any[] = []
                    value.forEach((item) => {
                        results.push({
                            name: item,
                            type: 'exists',
                        })
                    })
                    return results
                },
            }),
        }),
        content: new FilterCategory('filters.categories.content', {
            er: new SelectorFilter({
                platform: PLATFORM,
                title: 'filters.er.fields.er.label',
                icon: <Icons name="filter--engagement" />,
                helper: 'filters.er.fields.er.text_inst',
                space: 1,
                options: new Array(20).fill(null).map((_, i) => ({
                    label: `>=${i + 1}%`,
                    value: (i + 1) / 100,
                })),
                beforeSend: ({ value }) => ({
                    name: 'metrics.er',
                    type: 'number',
                    value: value[0],
                    operator: 'gte',
                }),
            }),
            lastPost: new SelectorFilter({
                platform: PLATFORM,
                title: 'filters.lastPost.label',
                helper: 'filters.lastPost.text',
                space: 1,
                locked: (feats, isPromo) => {
                    if (isPromo) return true
                    return false
                },
                featureKey: 'lastPublicationDate',
                options: [
                    {
                        label: 'filters.lastPost.options.2weeks',
                        value: today.subtract(2, 'weeks').toISOString(),
                        translate: true,
                    },
                    {
                        label: 'filters.lastPost.options.1month',
                        value: today.subtract(1, 'month').toISOString(),
                        translate: true,
                    },
                    {
                        label: 'filters.lastPost.options.3months',
                        value: today.subtract(3, 'months').toISOString(),
                        translate: true,
                    },
                ],
                beforeSend: ({ value }) => ({
                    name: 'feed.postDate',
                    type: 'range',
                    value: { from: value[0] },
                }),
            }),
            reach: new SelectorFilter({
                platform: PLATFORM,
                title: 'filters.er.fields.reach.label',
                icon: <Icons name="filter--reach" />,
                helper: 'filters.er.fields.reach.text',
                space: 1,
                options: [
                    { label: '>= 100', value: 100 },
                    { label: '>= 200', value: 200 },
                    { label: '>= 400', value: 400 },
                    { label: '>= 800', value: 800 },
                    { label: '>= 1500', value: 1500 },
                    { label: '>= 3000', value: 3000 },
                    { label: '>= 5000', value: 5000 },
                    { label: '>= 10000', value: 10000 },
                    { label: '>= 15000', value: 15000 },
                    { label: '>= 25000', value: 25000 },
                    { label: '>= 50000', value: 50000 },
                    { label: '>= 100 000', value: 100000 },
                    { label: '>= 500 000', value: 500000 },
                    { label: '>= 1 000 000', value: 1000000 },
                ],
                beforeSend: ({ value }) => ({
                    name: 'metrics.predictReach',
                    operator: 'gte',
                    type: 'number',
                    value: value[0],
                }),
            }),
            likes: new SelectorFilter({
                platform: PLATFORM,
                title: 'filters.er.fields.likes.label',
                icon: <Icons name="filter--likes" />,
                helper: 'filters.er.fields.likes.text',
                space: 1,
                options: [
                    { label: '>= 100', value: 100 },
                    { label: '>= 200', value: 200 },
                    { label: '>= 400', value: 400 },
                    { label: '>= 800', value: 800 },
                    { label: '>= 1500', value: 1500 },
                    { label: '>= 3000', value: 3000 },
                    { label: '>= 5000', value: 5000 },
                    { label: '>= 10000', value: 10000 },
                    { label: '>= 15000', value: 15000 },
                    { label: '>= 25000', value: 25000 },
                    { label: '>= 50000', value: 50000 },
                ],
                beforeSend: ({ value }) => ({
                    name: 'metrics.likes',
                    operator: 'gte',
                    type: 'number',
                    value: value[0],
                }),
            }),
            comments: new SelectorFilter({
                platform: PLATFORM,
                title: 'filters.er.fields.comments.label',
                icon: <Icons name="filter--comments" />,
                helper: 'filters.er.fields.comments.text',
                space: 1,
                options: [
                    { label: '>= 50', value: 50 },
                    { label: '>= 100', value: 100 },
                    { label: '>= 200', value: 200 },
                    { label: '>= 400', value: 400 },
                    { label: '>= 800', value: 800 },
                    { label: '>= 1000', value: 1000 },
                    { label: '>= 2000', value: 2000 },
                    { label: '>= 4000', value: 4000 },
                    { label: '>= 10000', value: 10000 },
                    { label: '>= 15000', value: 15000 },
                    { label: '>= 25000', value: 25000 },
                ],
                beforeSend: ({ value }) => ({
                    name: 'metrics.comments',
                    operator: 'gte',
                    type: 'number',
                    value: value[0],
                }),
            }),
            //     views: new SelectorFilter({
            //         title: 'filters.er.fields.views.label',
            //         helper: 'filters.er.fields.views.text',
            //         space: 1,
            //         options: [
            //             { label: '>= 100', value: 100 },
            //             { label: '>= 200', value: 200 },
            //             { label: '>= 400', value: 400 },
            //             { label: '>= 800', value: 800 },
            //             { label: '>= 1500', value: 1500 },
            //             { label: '>= 3000', value: 3000 },
            //             { label: '>= 5000', value: 5000 },
            //             { label: '>= 10000', value: 10000 },
            //             { label: '>= 15000', value: 15000 },
            //             { label: '>= 25000', value: 25000 },
            //             { label: '>= 50000', value: 50000 },
            //         ],
            //         beforeSend: ({ value }) => ({
            //             name: 'metrics.views',
            //             operator: 'gte',
            //             type: 'number',
            //             value: value[0],
            //         }),
            //     }),
        }),
        audience: new FilterCategory('filters.categories.audience', {
            location: new SelectorFilter({
                platform: PLATFORM,
                locked: (feats) => {
                    if (feats) {
                        return !feats.includes('cityAudienceFilter')
                    }
                    return true
                },
                title: 'filters.loc.fields.aud.label',
                autocomplete: true,
                placeholder: 'filters.loc.plh',
                icon: <Icons name="filter--geo" />,
                helper: 'filters.loc.fields.aud.text',
                options: (token, locale, query) => {
                    return getLocations(token, locale, query)
                },
                space: 2,
                hasPercentage: true,
                isMulti: true,
                featureKey: 'locationAudienceFilter',
                beforeSend: ({ value, percentage }: IAudienceLocation) => {
                    const res = []
                    const cities = value.filter((v) => v.type === 'city')
                    const countries = value.filter((v) => v.type === 'country')

                    if (cities.length > 0) {
                        res.push({
                            name: 'audience.cities_ids.city_id',
                            nested: 'audience.cities_ids',
                            operator: 'gte',
                            percentage,
                            share: 'audience.cities_ids.share',
                            type: 'combo',
                            value: cities.map((v) => v.id),
                        })
                    }

                    if (countries.length > 0) {
                        res.push({
                            name: 'audience.countries_ids.country_id',
                            nested: 'audience.countries_ids',
                            operator: 'gte',
                            percentage,
                            share: 'audience.countries_ids.share',
                            type: 'combo',
                            value: countries.map((v) => v.id),
                        })
                    }
                    return res
                },
            }),
            gender: new SelectorFilter({
                platform: PLATFORM,
                locked: (feats) => {
                    if (feats) {
                        return !feats.includes('genderAudienceFilter')
                    }
                    return true
                },
                title: 'filters.gender.audience.label',
                icon: <Icons name="filter--gender" />,
                helper: 'filters.gender.audience.text',
                options: [
                    {
                        value: 1,
                        label: 'filters.gender.value.male',
                        translate: true,
                    },
                    {
                        value: 2,
                        label: 'filters.gender.value.female',
                        translate: true,
                    },
                ],
                space: 1,
                hasPercentage: true,
                featureKey: 'genderAudienceFilter',
                beforeSend: ({ value, percentage }) => ({
                    name: 'audience.genders.gender',
                    nested: 'audience.genders',
                    operator: 'gte',
                    percentage,
                    share: 'audience.genders.share',
                    type: 'combo',
                    value: value[0],
                }),
            }),
            age: new SelectorFilter({
                platform: PLATFORM,
                locked: (feats) => {
                    if (feats) {
                        return !feats.includes('ageAudienceFilter')
                    }
                    return true
                },
                title: 'filters.age.audience.label',
                icon: <Icons name="filter--age" />,
                helper: 'filters.age.audience.text',
                options: [
                    { label: '0 - 17', value: 1 },
                    { label: '18 - 24', value: 2 },
                    { label: '25 - 34', value: 3 },
                    { label: '35 - 44', value: 4 },
                    { label: '45 - 54', value: 5 },
                    { label: '> 54', value: 6 },
                ],
                space: 1,
                hasPercentage: true,
                featureKey: 'genderAudienceFilter',
                beforeSend: ({ value, percentage }) => ({
                    name: 'audience.ages.age_group',
                    nested: 'audience.ages',
                    operator: 'gte',
                    percentage,
                    share: 'audience.ages.share',
                    type: 'combo',
                    value: value[0],
                }),
            }),
        }),
        other: new FilterCategory('filters.categories.other', {
            photo: new SelectorFilter({
                platform: PLATFORM,
                locked: (feats) => {
                    if (feats) {
                        return !feats.includes('tagsFilter')
                    }
                    return true
                },
                title: 'filters.labels.label',
                icon: <Icons name="filter--photo" />,
                helper: 'filters.labels.text',
                space: 2,
                isMulti: true,
                options: (token, locale) => getCategories(token, 'labels', locale),
                beforeSend: (data) => ({
                    name: 'feed.labels',
                    operator: 'terms',
                    type: 'array',
                    value: data.value,
                }),
            }),
            text: new SearchFilter({
                platform: PLATFORM,
                title: 'filters.username.label',
                icon: <Icons name="filter--similar" />,
                helper: 'filters.username.chaining.text',
                placeholder: 'filters.username.plh',
                space: 1,
                beforeSend: (value) => ({
                    name: 'chaining',
                    operator: 'equal',
                    type: 'text',
                    value: value,
                }),
            }),
            bio: new SearchFilter({
                platform: PLATFORM,
                title: 'filters.bio.label',
                icon: <Icons name="filter--bio" />,
                helper: 'filters.bio.text',
                placeholder: 'filters.bio.plh',
                space: 1,
                beforeSend: (value) => ({
                    name: 'info.biography',
                    operator: 'equal',
                    type: 'text',
                    value: value,
                }),
            }),
            brand: new SearchFilter({
                platform: PLATFORM,
                title: 'filters.brand.label',
                icon: <Icons name="filter--brand" />,
                helper: 'filters.brand.textInst',
                placeholder: 'filters.brand.plh',
                space: 1,
                beforeSend: (value) => ({
                    name: 'brands.username',
                    operator: 'equal',
                    type: 'text',
                    value: value,
                }),
            }),
            // openAccs: new SelectorFilter({
            //     title: 'filters.public.label',
            //     options: [
            //         {
            //             label: 'filters.public.label',
            //             value: true,
            //             translate: true,
            //         },
            //     ],
            //     space: 1,
            //     beforeSend: () => ({
            //         name: 'info.isPrivate',
            //         operator: 'term',
            //         type: 'boolean',
            //         value: false,
            //     }),
            // }),
        }),
    },
})
