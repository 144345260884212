import { APIService } from '../../../../_helpers/APIService.js'

export const SignUpService = new APIService({
	methods: {
		signUp: (email, password, password_confirm)=>({
			url: "/core/accounts/register/",
			data: { email, password, password_confirm },
            method: "post",
            validateStatus: status=>{
                return (status >= 200 && status < 300) || status===400
            }
		}),
		checkmail: (email) => ({
			url: "/core/mail-check/",
			method: "GET",
			params: { email },
            validateStatus: status=>{
                return (status >= 200 && status < 300) || status===403
            }
		}),
		resendEmail: (email) => ({
			url: "/core/resend-email/",
			method: "POST",
			data: { email }
		})
	}
})

export default SignUpService