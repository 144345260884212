import Text from '@/components/Text'
import { useSpaceTranslation } from '@/_helpers'
import Flex from '@/_yc/Flex/Flex'
import React from 'react'

import icon from './paymentIcon.png'
import cn from './Header.module.scss'

export const Header = () => {
    const t = useSpaceTranslation('payment')
    return (
        <Flex content="flex-start">
            <div className={cn.img}>
                <img src={icon} alt="payment" style={{ width: '30px', height: '30px' }} />
            </div>
            <Text fSize={30}>{t('title')}</Text>
        </Flex>
    )
}
