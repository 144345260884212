import React, { FC, ReactNode, useEffect } from 'react'

import { Text, Flex } from '@/_yc'
import Portal from '@/_helpers/Portal'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import useOpenClose from '@/_helpers/useOpenClose'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

import cn from './MoreModal.module.scss'

import Icons from '@/components/Icons'

export interface IMoreModal {
    title: string
    children?: ReactNode
}
const MoreModal: FC<IMoreModal> = ({ title, ...props }) => {
    const t = useSpaceTranslation('report_layout')

    const [state, open, close] = useOpenClose()
    useEffect(() => {
        if (state) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'unset'
        }
    }, [state])
    return (
        <>
            <Flex grow={true} content="flex-end">
                <Text onClick={open} className={cn.text} color="link">
                    {t('view_more')}
                </Text>
            </Flex>

            {state && (
                <Portal>
                    <Flex content="center" align="flex-start" className={cn.root}>
                        <ClickAwayListener onClickAway={close}>
                            <div>
                                <div onClick={close} className={cn['icon--container']}>
                                    <Icons className={cn['icon--close']} name="close" />
                                </div>
                                <div className={cn.modal}>
                                    <div>
                                        <p className={cn.title}>{title}</p>
                                        {props.children}
                                    </div>
                                </div>
                            </div>
                        </ClickAwayListener>
                    </Flex>
                </Portal>
            )}
        </>
    )
}

export default MoreModal
