import React from 'react'
import { useTranslation } from 'react-i18next'

import Flex from '@/_yc/Flex'
import Text from '@/components/Text'
import { shortNum } from '@/_helpers/_graphs/_tech'
import Avatar from '@/components/Avatar'
import { Platform } from '@/pages/Reports/types'
import useChannelId from '@/_helpers/useChannelId'
import Loader from '@/components/Loader'
import { GET_TG_REPORT_MENTIONS_IN, GET_TG_REPORT_MENTIONS_OUT, IMentionsResponse, IMentionsData } from './service'
import cn from './Mentions.module.scss'
import { useQuery } from '@apollo/client'
import { MockInContainer } from '../../Mock/Mock'
interface IMention {
    titleKey: string
    data?: IMentionsData[]
    loading: boolean
    error: boolean
}
const MentionsView: React.FC<IMention> = ({ data = [], ...props }) => {
    const { t } = useTranslation()
    return (
        <>
            <div className={cn.table}>
                <Text component="h3">{t('report_layout.mentions.' + props.titleKey)}</Text>
                <div className={cn['table-header']}>
                    <Text color="gray-3" fSize="10px">
                        {t('report_layout.mentions.head.channel')}
                    </Text>
                    <Text color="gray-3" fSize="10px">
                        {t('report_layout.mentions.head.mentions')}
                    </Text>
                    <Text color="gray-3" fSize="10px">
                        {t('report_layout.mentions.head.out_of_all')}
                    </Text>
                </div>
                {props.loading ? (
                    <>
                        <Flex align="center" content="center" style={{ height: '70%' }}>
                            <Loader />
                        </Flex>
                    </>
                ) : data.length === 0 || props.error ? (
                    <>
                        <Flex align="center" content="center" style={{ height: '70%' }}>
                            <MockInContainer />
                        </Flex>
                    </>
                ) : (
                    data.slice(0, 5).map((item) => (
                        <>
                            <a
                                href={`https://t.me/${item.username}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={cn['table-item']}
                            >
                                <Flex style={{ columnGap: '12px' }}>
                                    <Avatar src="" alt="avatar" className={cn.avatar} type={Platform.Tg} />
                                    <Flex column style={{ width: '100%' }}>
                                        <Text className={cn.title}>{item.title}</Text>
                                        <Flex style={{ columnGap: '12px' }} column={false}>
                                            {/* <Text color="gray-3" fSize="10px">
                                            {shortNum(item.mentions_count)}
                                        </Text> */}
                                            <Text color="gray-3" fSize="10px">
                                                {`${t('report_layout.mentions.head.er')} ${(
                                                    item.er_views * 100
                                                ).toFixed(2)}%`}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Text>{shortNum(item.mentions_count)}</Text>
                                <Text>{item.mentions_percentage + '%'}</Text>
                            </a>
                            <div className={cn.divider} />
                        </>
                    ))
                )}
            </div>
        </>
    )
}

const Mentions = () => {
    const channelId = useChannelId(3)

    const {
        loading: in_loading,
        data: in_data,
        error: in_error,
    } = useQuery<IMentionsResponse>(GET_TG_REPORT_MENTIONS_IN, {
        variables: {
            id: channelId,
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        skip: false,
    })

    const {
        loading: out_loading,
        data: out_data,
        error: out_error,
    } = useQuery<IMentionsResponse>(GET_TG_REPORT_MENTIONS_OUT, {
        variables: {
            id: channelId,
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        skip: false,
    })

    return (
        <Flex style={{ columnGap: '12px' }}>
            <MentionsView
                titleKey={'title_incoming'}
                data={in_data?.get_telegram_ingoing_mentions}
                loading={in_loading}
                error={in_error ? true : false}
            />
            <MentionsView
                titleKey={'title_outgoing'}
                data={out_data?.get_telegram_outgoing_mentions}
                loading={out_loading}
                error={out_error ? true : false}
            />
        </Flex>
    )
}

export default Mentions
