import React, { SVGAttributes } from 'react'
const Mail = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.8 9.79995H1.2C0.537258 9.79995 0 9.26269 0 8.59995V1.34775C0.027965 0.70548 0.55712 0.199344 1.2 0.199952H10.8C11.4627 0.199952 12 0.737211 12 1.39995V8.59995C12 9.26269 11.4627 9.79995 10.8 9.79995ZM1.2 2.52075V8.59995H10.8V2.52075L6 5.71995L1.2 2.52075ZM1.68 1.39995L6 4.27995L10.32 1.39995H1.68Z"
            fill="#76849B"
        />
    </svg>
)

export default Mail
