import React, { SVGAttributes } from 'react'
const Outreach = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="20" height="20" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 4.39999H2.66667V5.73333H4V4.39999Z" fill="#323841" />
        <path d="M5.33333 4.39999H6.66667V5.73333H5.33333V4.39999Z" fill="#323841" />
        <path d="M9.33333 4.39999H8V5.73333H9.33333V4.39999Z" fill="#323841" />
        <path
            d="M0 1.73333V12.4L3.2 9.99999C3.43054 9.82642 3.71142 9.7328 4 9.73333H10.6667C11.403 9.73333 12 9.13637 12 8.39999V1.73333C12 0.996948 11.403 0.399994 10.6667 0.399994H1.33333C0.596954 0.399994 0 0.996948 0 1.73333ZM1.33333 9.73333V1.73333H10.6667V8.39999H3.556C3.26738 8.39922 2.98643 8.49287 2.756 8.66666L1.33333 9.73333Z"
            fill="#323841"
        />
    </svg>
)

export default Outreach
