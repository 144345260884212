import React, { FC } from 'react'

import cn from './TimeSpan.module.scss'
import Button from '@/components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { setVideosFilter, setVideosTimespan } from '@/Layouts/ReportLayout/Model/actions'
import { RootStore } from '@/_helpers/StoreType'
import { TimeSpanType } from '@/Layouts/ReportLayout/Model/types'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

export interface ITimeSpan {}

const TimeSpan: FC<ITimeSpan> = () => {
    const t = useSpaceTranslation('report_layout.videos.controls')

    const dispatch = useDispatch()

    const videoFilter = useSelector((store: RootStore) => {
        return store.report.videos.filter
    })

    const timespan = useSelector((store: RootStore) => {
        return store.report.videos.timespan
    })

    const onClick = (timespan: TimeSpanType) => {
        dispatch(setVideosFilter('timespan'))
        dispatch(setVideosTimespan(timespan))
    }

    return (
        <>
            <Button
                className={cn.TimeSpanButton}
                margin="0 10px 0 0"
                color="blue"
                onClick={() => onClick('month')}
                active={videoFilter === 'timespan' && timespan === 'month'}
                variant="outline--mobileV2"
            >
                {t('month')}
            </Button>
            <Button
                className={cn.TimeSpanButton}
                color="blue"
                onClick={() => onClick('6months')}
                active={videoFilter === 'timespan' && timespan === '6months'}
                variant="outline--mobileV2"
            >
                {t('6months')}
            </Button>
            <Button
                className={cn.TimeSpanButton}
                margin="0 0 0 10px"
                color="blue"
                onClick={() => onClick('year')}
                active={videoFilter === 'timespan' && timespan === 'year'}
                variant="outline--mobileV2"
            >
                {t('year')}
            </Button>
        </>
    )
}

export default TimeSpan
