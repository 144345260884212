import { useSpaceTranslation } from '@/_helpers'
import { RootStore } from '@/_helpers/StoreType'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCampaignsFilter } from '../../Model/actions'
import { CampaignStatus } from '../../Service/Campaigns'
import { FilterButton } from './FilterButton/FilterButton'
import cn from './Filters.module.scss'

export const Filters = () => {
    const t = useSpaceTranslation('outreach.stats.filters')
    const dispatch = useDispatch()

    const onButtonClick = (status: CampaignStatus | 'all') => {
        dispatch(setCampaignsFilter(status))
    }

    const activeStatus = useSelector((state: RootStore) => state.Outreach.campaignsFilter)

    return (
        <div className={cn.filters}>
            <FilterButton
                text={t('all')}
                icon="outreach"
                onClick={() => onButtonClick('all')}
                active={activeStatus === 'all'}
            />
            <FilterButton
                text={t('active')}
                icon="outreach"
                onClick={() => onButtonClick('active')}
                active={activeStatus === 'active'}
            />
            <FilterButton
                text={t('draft')}
                icon="outreach"
                onClick={() => onButtonClick('draft')}
                active={activeStatus === 'draft'}
            />
            <FilterButton
                text={t('archive')}
                icon="outreach"
                onClick={() => onButtonClick('archived')}
                active={activeStatus === 'archived'}
            />
        </div>
    )
}
