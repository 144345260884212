import React, { FC, useMemo, useRef, useEffect, useState } from 'react'
// import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import { Flex } from '@/_yc'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
// import { Link } from 'react-router-dom';
import Title from '@/pages/Reports/BloggerReport/Title'
// import Predictions from '@/pages/Reports/BloggerReport/Predictions'
import useStaticData from './useStaticData'
import DemoHidder from './DemoHidder'
import Layout from '@/Layouts/AudienceLayout'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'

import desktopConfig from './DemoData.desktop'
import mobileConfig from './DemoData.mobile'
import tabletConfig from './DemoData.tablet'
import DemoPosts from './DemoPosts/DemoPosts'
// import BloggerRating from '../BloggerReport/BloggerRating'
import { BloggerRating, Predictions } from '@yoloker/ui-collection'
import PartChanger from '@/Layouts/ReportLayout/Extra/PartChanger'
import { IRefs } from '../BloggerReport/Types'
import { redirectToId } from '@/components/Share/BloggerLink'
import { getMonth } from '@/_helpers/parseDate_ts'
import i18n from 'i18next'

import cn from './DemoReport.module.scss'

export interface RequiredInfo {
    external_id: number
    avatar: string
    isVerified: boolean
    fullName?: string
    username: string
    categories: string[]
    postNumber: number
    avLikes: number
    avComments: number
    er: number
    followers: number
    follows: number
    is_unlocked: boolean
    updated: string
}

export interface IDemoReport {
    match: {
        params: {
            id: string
        }
    }
    data: null | RequiredInfo
    loaded: boolean
    error: boolean
}

const a: string[] = []

const classes = { content: cn.root }
export interface Item {
    value:
        | null
        | number
        | {
              from: number | null
              to: number | null
          }
    title: string
    icon: 'Group' | 'Reach'
    currency?: 'RUB' | 'USD' | 'BRL'
    tooltipText: string
}

export interface IPredictions {
    items: Item[]
    isDemo?: boolean
    inProgress?: boolean
}

const DemoReport: FC<IDemoReport> = (props) => {
    const t = useSpaceTranslation('report_titles')

    const TestPredictions: Array<Item> = [
        {
            icon: 'Group',
            title: 'coverage',
            value: 1,
            tooltipText: t('predictions.tooltips.coverage'),
        },
        {
            currency: 'RUB',
            icon: 'Reach',
            title: 'postPrice',
            value: {
                from: 1,
                to: 2,
            },
            tooltipText: t('predictions.tooltips.postPrice'),
        },
        {
            currency: 'RUB',
            icon: 'Reach',
            title: 'storyPrice',
            value: 1,
            tooltipText: t('predictions.tooltips.storyPrice'),
        },
    ]

    const { data } = props

    if (data?.is_unlocked) {
        redirectToId(data.external_id)
    }
    const loaded = !!data

    const [, categories, metrics] = useStaticData(
        loaded,
        data ? data.categories : a,
        data ? data.postNumber : 0,
        data ? data.avLikes : 0,
        data ? data.avComments : 0,
        data ? data.followers : 0,
        data ? data.follows : 0,
        data ? data.er : 0
    )
    const size = useWindowWidth()

    const xs = size === 'mobile'

    const accountDataRef = useRef(null)
    const audienceRef = useRef(null)
    const brandsRef = useRef(null)
    const similarAccsRef = useRef(null)
    const contentRef = useRef(null)

    const refs: IRefs = {
        accountData: accountDataRef,
        audience: audienceRef,
        brands: brandsRef,
        similarAccs: similarAccsRef,
        content: contentRef,
    }

    const Config = useMemo(() => {
        if (size === 'desktop') return desktopConfig(refs)
        if (size === 'mobile') return mobileConfig()
        return tabletConfig()
    }, [size, refs])

    const [updatedDate, setUpdatedDate] = useState<{ date: string | undefined; completed: boolean }>({
        date: undefined,
        completed: false,
    })
    useEffect(() => {
        if (data?.updated) {
            const tempDate = new Date(data.updated)
            const actualCompleteDate = data.updated
                ? `${tempDate.getDate()} ${getMonth(tempDate.getMonth() + 1, i18n.language)} ${tempDate.getFullYear()}`
                : undefined
            setUpdatedDate({
                date: actualCompleteDate,
                completed: true,
            })
        } else {
            setUpdatedDate({
                date: undefined,
                completed: false,
            })
        }
    }, [data])
    return (
        <Layout classes={classes} loading={!props.loaded} error={props.error} isReport>
            <Flex margin="15px 0 30px" align="center" content="space-between">
                {/* <Link to={LISTS} className={cn.back_button}>
                    <ArrowLeft fontSize="small" />
                    {t("report.back_btn", undefined, true)}
                </Link> */}
                {/* <PDFBar onClick={this.download} loading={this.props.pdfLoader.loading} /> */}
            </Flex>
            <div className={cn.container}>
                {data && (
                    <Title
                        avatar={data.avatar}
                        username={data.username}
                        isVerified={data.isVerified}
                        fullName={data.fullName}
                        xs={xs}
                        metrics={metrics}
                        categories={categories}
                        isDemo={true}
                        er={data.er && data.er}
                        updatedDate={updatedDate}
                        reportReadyStatus={false}
                    />
                )}

                {size === 'desktop' && (
                    <PartChanger
                        values={[
                            {
                                title: t('account_data.title'),
                                value: accountDataRef,
                            },
                            {
                                title: t('auditory.title'),
                                value: audienceRef,
                            },
                            {
                                title: t('brandAffinity.title'),
                                value: brandsRef,
                            },
                            {
                                title: t('lookalikes.title'),
                                value: similarAccsRef,
                            },
                            {
                                title: t('content.title'),
                                value: contentRef,
                            },
                        ]}
                    />
                )}

                <Flex column={size === 'mobile'} margin="20px 0 20px 0">
                    <BloggerRating mainValue={0.5} tooltipText={t('blogger_rating.tooltip')} isDemo={true} />
                    <Flex margin={size !== 'mobile' ? '0 0 0 20px' : ''} style={{ flexBasis: '100%' }}>
                        <Predictions items={TestPredictions} isDemo={true} />
                    </Flex>
                </Flex>

                {size === 'desktop' ? (
                    <>
                        <DemoHidder id={props.data?.external_id || 0} item={Config[0]} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[1]} grid={'1fr 1fr 1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[2]} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[3]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[4]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[5]} grid={'1fr 1fr 1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[6]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[7]} grid={'1fr 1fr 1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[8]} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[9]} grid={'1fr 1fr 1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[10]} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[11]} grid={'1fr 1fr 1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[12]} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[13]} grid={'1fr 1fr 1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[14]} grid={'1fr 1fr 1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[15]} grid={'1fr 1fr 1fr'} />
                    </>
                ) : size === 'tablet' ? (
                    <>
                        <DemoHidder id={props.data?.external_id || 0} item={Config[0]} grid={'1fr 1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[1]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[2]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[3]} grid={'1fr 1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[4]} grid={'1fr 1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[5]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[6]} grid={'1fr 1fr'} />
                    </>
                ) : (
                    <>
                        <DemoHidder id={props.data?.external_id || 0} item={Config[0]} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[1]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[2]} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[3]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[4]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[5]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[6]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[7]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[8]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[9]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[10]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[11]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[12]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[13]} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[14]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[15]} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[16]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[17]} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[18]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[19]} grid={'1fr'} />
                        <DemoHidder id={props.data?.external_id || 0} item={Config[20]} grid={'1fr'} />
                    </>
                )}
                <DemoPosts id={props.data?.external_id || 0} />
            </div>
        </Layout>
    )
}

export default DemoReport
