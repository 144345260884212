import React, { FC, memo, useEffect, useCallback, FormEvent } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useController, FormProvider, ErrorConsumer } from '@schematic-forms/react'
import { Str, EmailValidator } from '@schematic-forms/core'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { RootStore } from '@/_helpers/StoreType'
import { SIGNUP, FORGOT, SEARCH } from '@/routs'
import { Flex, Text, Button } from '@/_yc'
import { OAuthButton } from '@/components/Share'
import { snackActions } from '@/components/Notifier'
import FormPageLayout from '@/Layouts/FormPageLayout'
import FormInput from '@/components/Share/Form/FormInput'
import RequireDemoCalendly from '@/components/Share/RequireDemoButton/RequireDemoCalendly'
import { history } from '@/_helpers'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import useRecaptchaV3 from '@/components/reCaptchaV3'
// import CalendlyWidget from '@/components/Share/CalendlyWidget'
// import useOpenClose from '@/_helpers/useOpenClose'

import bg_image from './bg_image.png'
import { loginActions } from './Model'

import cn from './LoginPage.module.scss'
import { track } from '@amplitude/analytics-browser'
import { getPlatform } from '@/_helpers/getPlatform'
import { EMAIL_SIGNIN } from '@/_helpers/amplitude/values'
import { LOGIN } from '@/_helpers/amplitude/events'

export interface ILoginPage {}

const lHeight = { lineHeight: 1 }

const font = { fontSize: 14 }

const fontMobile = { fontSize: 12 }

const LoginPage: FC<ILoginPage> = () => {
    const t = useSpaceTranslation('login')
    // const [isOpen, open, close] = useOpenClose()

    const { executeRecaptcha, isRecaptchaReady } = useRecaptchaV3()

    const size = useWindowWidth('mobile')

    const loggedIn = useSelector<RootStore, boolean>((state) => state.authentication.loggedIn)

    const location = useLocation<{ verified: boolean }>()

    const dispatch = useDispatch()
    const { controller, submit } = useController({
        fields: {
            email: Str(true),
            password: Str(true),
        },
        validators: {
            email: EmailValidator(),
        },
        submit: async (data) => {
            track(LOGIN, {
                kindOfSignUp: EMAIL_SIGNIN,
                deviceOfSignUp: getPlatform(),
            })

            executeRecaptcha('LOGIN')
                .then((recaptchaToken) => {
                    dispatch(loginActions.login(data.email, data.password, recaptchaToken))
                })
                .catch((error) => {
                    console.error(error)
                })
        },
    })

    const onSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            if (!isRecaptchaReady) return
            e.preventDefault()
            submit()
        },
        [submit, isRecaptchaReady]
    )

    const transformError = useCallback(
        (name: string, code: string) => {
            return t(`fields.${name}.errors.${code}`)
        },
        [t]
    )

    useEffect(() => {
        if (loggedIn && !!localStorage.getItem('user')) {
            history.push(SEARCH, {})
            return
        }

        if (location.state && location.state.verified) dispatch(snackActions.success(location.state.verified))

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <FormProvider controller={controller}>
            {/* <CalendlyWidget isOpen={isOpen} close={close} /> */}

            <FormPageLayout background={bg_image} title={t('title')} bgTextColor={'#3C555F'}>
                <form onSubmit={onSubmit}>
                    <Flex margin="0 0 20px" align="center">
                        <Text size={size === 'mobile' ? 12 : 14} margin="0 8px 0 0">
                            {t('subtitle.plain_text')}
                        </Text>
                        <Link style={size === 'mobile' ? fontMobile : font} to={SIGNUP}>
                            {t('subtitle.link')}
                        </Link>
                        {/* <Text
                            size={size === 'mobile' ? 12 : 14}
                            style={{ fontSize: '14px ', color: '#6172FF', cursor: 'pointer' }}
                            onClick={open}
                        >
                            {t('subtitle.link')}
                        </Text> */}
                    </Flex>
                    <FormInput
                        titleSize={size === 'mobile' ? 10 : 16}
                        titleColor="gray2"
                        name="email"
                        margin="0 0 10px"
                        title={t('fields.email.label')}
                        placeholder={t('fields.email.label')}
                        errorTransform={transformError}
                        tSize={size === 'mobile' ? 14 : 18}
                        className={size === 'mobile' ? cn['field--mobile'] : cn.field}
                        removeSpace={true}
                    />
                    <FormInput
                        titleSize={size === 'mobile' ? 10 : 16}
                        titleColor="gray2"
                        name="password"
                        title={t('fields.password.label.plain_text')}
                        placeholder={t('fields.password.label.plain_text')}
                        errorTransform={transformError}
                        type="password"
                        margin="0 0 20px"
                        tSize={size === 'mobile' ? 14 : 18}
                        className={size === 'mobile' ? cn['field--mobile'] : cn.field}
                        spaceBetween
                        removeSpace={true}
                    >
                        <Link to={FORGOT}>
                            <Text margin="0 0 0 12px" size={size === 'mobile' ? 10 : 16} color="link">
                                {t('fields.password.label.link')}
                            </Text>
                        </Link>
                    </FormInput>
                    <ErrorConsumer>
                        {({ hasError }) => (
                            <Button
                                className={
                                    !hasError
                                        ? size === 'mobile'
                                            ? cn['btn--mobile']
                                            : cn.btn
                                        : size === 'mobile'
                                        ? cn['btn--disabled--mobile']
                                        : cn['btn--disabled']
                                }
                                type="submit"
                                disabled={hasError || !isRecaptchaReady}
                                width={'100%'}
                            >
                                <Text color={'white'} style={lHeight}>
                                    {t('Sign_in_button_label')}
                                </Text>
                            </Button>
                        )}
                    </ErrorConsumer>
                </form>
                <div className={size === 'mobile' ? undefined : cn.demo}>
                    <RequireDemoCalendly margin={size === 'mobile' ? '20px 0 0' : '80px 0 0'} />
                </div>
                <Flex margin="18px 0" content="space-between" align="center">
                    <Flex grow className={cn.line} />
                    <Text size={12} color="secondary" margin="0 14px">
                        {t('or')}
                    </Text>
                    <Flex grow className={cn.line} />
                </Flex>

                <OAuthButton login margin="0" />
            </FormPageLayout>
        </FormProvider>
    )
}

export default memo(LoginPage)
