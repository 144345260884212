import { RootStore } from '@/_helpers/StoreType'
import { Flex } from '@/_yc'
import React, { FC, memo, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AdapterContext } from '../../../SearchAdapter/AdapterContext'
import { BasicFilter } from '../../../SearchAdapter/filters/BasicFilter'
import ChipItem from './ChipItem'

export interface IChips {
    filtersState: boolean
    filtersOpen: Function
}

const Chips: FC<IChips> = (props) => {
    const {
        adapter: { filtersFlatMap },
    } = useContext(AdapterContext)

    const filters = useSelector((store: RootStore) => store.search.filters)
    const mapperArray = useMemo(() => {
        const result = new Array<{
            id: string
            Filter: BasicFilter<any, any>
        }>()

        for (const id of Object.keys(filters)) {
            const Filter = filtersFlatMap.get(id)
            if (!Filter) continue
            result.push({ id, Filter })
        }

        return result
    }, [filters, filtersFlatMap])

    const style = useMemo(
        () => ({
            padding: '0 0 16px',
        }),
        []
    )

    return (
        <Flex
            wrap
            style={{ ...style, cursor: 'pointer', width: 'fit-content' }}
            onClick={!props.filtersState && props.filtersOpen}
        >
            {mapperArray.map((item) => (
                <ChipItem key={item.id} id={item.id} filter={item.Filter} />
            ))}
        </Flex>
    )
}

export default memo(Chips)
