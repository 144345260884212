import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="4" fill="#E04444" />
        <path
            d="M10.0037 14.6748H9.99034C9.94901 14.6748 5.81368 14.6668 4.77034 14.3835C4.19663 14.2289 3.74858 13.7806 3.59434 13.2068C3.40673 12.1484 3.31613 11.0751 3.32368 10.0002C3.31883 8.92351 3.41188 7.84864 3.60168 6.78883C3.76015 6.2145 4.20623 5.76413 4.77901 5.60016C5.79368 5.3335 9.81501 5.3335 9.98568 5.3335H9.99968C10.0417 5.3335 14.1877 5.3415 15.2203 5.62483C15.7929 5.78017 16.24 6.22756 16.395 6.80016C16.5886 7.86252 16.6795 8.94105 16.6663 10.0208C16.671 11.0962 16.5777 12.1697 16.3877 13.2282C16.2315 13.8013 15.7831 14.2486 15.2097 14.4035C14.1963 14.6722 10.1743 14.6748 10.0037 14.6748ZM8.67034 8.0035L8.66701 12.0035L12.1417 10.0035L8.67034 8.0035Z"
            fill="white"
        />
    </svg>
)
