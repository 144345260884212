import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.5642 16.3392L10.8017 11.5758C8.68306 13.0821 5.7637 12.7136 4.08573 10.7283C2.40776 8.74293 2.53097 5.803 4.36917 3.96501C6.20689 2.12621 9.14711 2.00247 11.1328 3.68035C13.1185 5.35823 13.4872 8.27788 11.9808 10.3967L16.7433 15.16L15.565 16.3383L15.5642 16.3392ZM7.90417 4.16666C6.32392 4.16631 4.96058 5.27557 4.63957 6.82287C4.31855 8.37017 5.1281 9.93023 6.57807 10.5585C8.02804 11.1868 9.71992 10.7107 10.6294 9.41838C11.5388 8.12607 11.4159 6.37277 10.335 5.22001L10.8392 5.72001L10.2708 5.15334L10.2608 5.14334C9.63733 4.51599 8.78867 4.16428 7.90417 4.16666Z"
            fill="#adadad"
        />
    </svg>
)
