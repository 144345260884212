import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <path
            d="M15.2569 10.2427C15.0949 10.0806 14.8982 9.9997 14.6666 9.9997H8.27771V7.22195C8.27771 6.60842 8.49477 6.08476 8.92882 5.65071C9.36291 5.21675 9.8866 4.99972 10.5001 4.99972C11.1135 4.99972 11.6373 5.21672 12.0712 5.65071C12.5053 6.08476 12.7223 6.60845 12.7223 7.22195C12.7223 7.37236 12.7773 7.50255 12.8871 7.6125C12.9972 7.72247 13.1275 7.77747 13.2777 7.77747H13.8336C13.9839 7.77747 14.1141 7.72247 14.2242 7.6125C14.3338 7.50255 14.389 7.37236 14.389 7.22195C14.389 6.15123 14.0085 5.2356 13.2476 4.47454C12.4865 3.71346 11.5707 3.33301 10.5001 3.33301C9.42941 3.33301 8.51362 3.71346 7.75253 4.47451C6.99156 5.23548 6.61108 6.1512 6.61108 7.22192V9.99967H6.33333C6.10192 9.99967 5.90511 10.0808 5.74305 10.2427C5.581 10.4046 5.5 10.6014 5.5 10.8329V15.833C5.5 16.0646 5.58103 16.2614 5.74305 16.4234C5.90511 16.5853 6.10192 16.6663 6.33333 16.6663H14.6666C14.8982 16.6663 15.095 16.5853 15.2569 16.4234C15.4188 16.2614 15.5 16.0646 15.5 15.833V10.833C15.5001 10.6015 15.4191 10.4048 15.2569 10.2427Z"
            fill="#6071FF"
        />
    </svg>
)
