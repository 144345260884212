import React, { FC, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { ErrorConsumer, FieldConsumer, FormProvider, useController } from '@schematic-forms/react'
import { Bool, EmailValidator, Str } from '@schematic-forms/core'
import { customPhoneValidator } from '@/_helpers/CustomPhoneValidator'

import FormPageLayout from '@/Layouts/FormPageLayout'

import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { Flex, Text, Checkbox, Button, OfertaLink } from '@/_yc'

import FormInput from '@/components/Share/Form/FormInput'

import { LOGIN, SIGNUP } from '@/routs'

import cn from './RequireDemoPage.module.scss'
import image from './bg_img.png'
import useOpenClose from '@/_helpers/useOpenClose'
import SuccessModal from './SuccessModal'
import { Trans } from 'react-i18next'
import BackButton from '@/components/Share/BackButton'
import { demoRequest } from '@/Services/Commercial'
import ym from 'react-yandex-metrika'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'

export interface IRequireDemoPage {}

const components = [<OfertaLink />]

const RequireDemoPage: FC<IRequireDemoPage> = (props) => {
    const [state, open, close] = useOpenClose()

    const t = useSpaceTranslation('require_demo')
    const tag = useSpaceTranslation('tags')

    const targetObj = {
        name: tag('demo_reg.name'),
        category: tag('demo_reg.category'),
        action: tag('demo_reg.action'),
    }
    const location = useLocation<{ pathname?: string }>()
    const prev = useMemo(() => {
        if (location.state?.pathname === LOGIN) return 'LOGIN'
        if (location.state?.pathname === SIGNUP) return 'SIGNUP'
        return 'UNKNOWN'
    }, [location])
    const { controller, submit } = useController({
        fields: {
            name: Str(true),
            company_name: Str(true),
            email: Str(true),
            phone: Str(true),
            agree: Bool(true),
        },
        validators: {
            email: EmailValidator(),
            phone: (value) => {
                const phTest = customPhoneValidator(value)

                if (!phTest) return new Error('NOT_VALID_PHONE_NUMBER')
                else return
            },
        },
        submit: async (data) => {
            await demoRequest(data.email, data.phone, data.company_name, data.name)
            if (prev === 'SIGNUP' && (global as any).isProd) {
                ;(global as any).gtag('event', targetObj.name, {
                    event_category: targetObj.category,
                    event_action: targetObj.action,
                })
                ym('reachGoal', targetObj.category)
            } else if (prev === 'SIGNUP') {
                console.log(targetObj)
            }
            open()
        },
    })

    const errorTransform = useCallback(
        (name: string, error: string) => {
            return t(`errors.${error}`)
        },
        [t]
    )

    const size = useWindowWidth('mobile')

    const lHeight = { lineHeight: 1 }

    return (
        <FormPageLayout mobileBg fullWidth bgTextColor="#525EC2" background={image} title={t('title')}>
            {state ? (
                <SuccessModal onClose={close} />
            ) : (
                <>
                    <Text size={size === 'mobile' ? 12 : undefined}>{t('details')}</Text>
                    <FormProvider controller={controller}>
                        {size === 'desktop' ? (
                            <div className={cn.grid}>
                                <FormInput
                                    titleSize={16}
                                    titleColor="gray2"
                                    tSize={18}
                                    className={cn.field}
                                    margin="20px 0 0"
                                    placeholder={t('fields.name')}
                                    title={t('fields.name')}
                                    name="name"
                                    errorTransform={errorTransform}
                                />
                                <FormInput
                                    titleSize={16}
                                    titleColor="gray2"
                                    tSize={18}
                                    margin={'20px 0 0'}
                                    className={cn.field}
                                    title={t('fields.company_name')}
                                    placeholder={t('fields.company_name')}
                                    name="company_name"
                                    errorTransform={errorTransform}
                                />
                                <FormInput
                                    titleSize={16}
                                    titleColor="gray2"
                                    tSize={18}
                                    margin="10px 0 0"
                                    className={cn.field}
                                    placeholder={t('fields.email')}
                                    title={t('fields.email')}
                                    name="email"
                                    errorTransform={errorTransform}
                                />
                                <FormInput
                                    titleSize={16}
                                    titleColor="gray2"
                                    tSize={18}
                                    margin="10px 0 0"
                                    className={cn.field}
                                    placeholder={t('fields.phone')}
                                    title={t('fields.phone')}
                                    name="phone"
                                    errorTransform={errorTransform}
                                />
                                <FieldConsumer field="agree">
                                    {({ value, setValue, error }) => (
                                        <Flex
                                            margin="15px 0 0"
                                            onClick={() => setValue(!value)}
                                            align="center"
                                            className={error ? cn.error : undefined}
                                            width={'340px'}
                                        >
                                            <Checkbox className={cn.check} value={value} />
                                            <Text margin="0 0 0 10px" size={14} color="secondary">
                                                <Trans i18nKey="require_demo.agree" components={components} />
                                            </Text>
                                        </Flex>
                                    )}
                                </FieldConsumer>
                                <ErrorConsumer>
                                    {({ hasError }) => (
                                        <Button
                                            className={!hasError ? cn.btn : cn['btn--disabled']}
                                            onClick={submit}
                                            key="sumbit"
                                            margin="20px 0 0"
                                            disabled={hasError}
                                        >
                                            {t('submit')}
                                        </Button>
                                    )}
                                </ErrorConsumer>
                            </div>
                        ) : (
                            <Flex column>
                                <FormInput
                                    titleSize={10}
                                    titleColor="gray2"
                                    tSize={14}
                                    className={size === 'mobile' ? cn['field--mobile'] : cn.field}
                                    margin="20px 0 0"
                                    title={t('fields.name')}
                                    placeholder={t('fields.name')}
                                    name="name"
                                    errorTransform={errorTransform}
                                />
                                <FormInput
                                    titleSize={10}
                                    titleColor="gray2"
                                    margin={'20px 0 0'}
                                    tSize={14}
                                    className={size === 'mobile' ? cn['field--mobile'] : cn.field}
                                    placeholder={t('fields.company_name')}
                                    title={t('fields.company_name')}
                                    name="company_name"
                                    errorTransform={errorTransform}
                                />
                                <FormInput
                                    titleSize={10}
                                    titleColor="gray2"
                                    margin="10px 0 0"
                                    title={t('fields.email')}
                                    placeholder={t('fields.email')}
                                    name="email"
                                    errorTransform={errorTransform}
                                    tSize={14}
                                    className={size === 'mobile' ? cn['field--mobile'] : cn.field}
                                />
                                <FormInput
                                    titleSize={10}
                                    titleColor="gray2"
                                    margin="10px 0 0"
                                    tSize={14}
                                    className={size === 'mobile' ? cn['field--mobile'] : cn.field}
                                    title={t('fields.phone')}
                                    placeholder={t('fields.phone')}
                                    name="phone"
                                    errorTransform={errorTransform}
                                />
                                <FieldConsumer field="agree">
                                    {({ value, setValue, error }) => (
                                        <Flex
                                            margin="15px 0 0"
                                            onClick={() => setValue(!value)}
                                            align="center"
                                            className={error ? cn.error : undefined}
                                            width={'340px'}
                                        >
                                            <Checkbox className={cn.check} value={value} />
                                            <Text margin="0 0 0 10px" size={10} color="secondary">
                                                <Trans i18nKey="require_demo.agree" components={components} />
                                            </Text>
                                        </Flex>
                                    )}
                                </FieldConsumer>
                                <ErrorConsumer>
                                    {({ hasError }) => (
                                        <Button
                                            width={'100%'}
                                            className={
                                                !hasError
                                                    ? size === 'mobile'
                                                        ? cn['btn--mobile']
                                                        : cn.btn
                                                    : size === 'mobile'
                                                    ? cn['btn--disabled--mobile']
                                                    : cn['btn--disabled']
                                            }
                                            onClick={submit}
                                            key="sumbit"
                                            margin="20px 0 0"
                                            disabled={hasError}
                                        >
                                            <Text style={lHeight} color="white">
                                                {t('submit')}
                                            </Text>
                                        </Button>
                                    )}
                                </ErrorConsumer>
                            </Flex>
                        )}
                        <BackButton margin={size === 'mobile' ? '20px 0 0' : '30px 0 0'}>
                            {t(`back_${prev}`)}
                        </BackButton>
                    </FormProvider>
                </>
            )}
        </FormPageLayout>
    )
}

export default RequireDemoPage
