import React, { SVGAttributes } from 'react'
export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.00006 13.9998C7.57006 13.6184 7.08406 13.2218 6.57006 12.7998H6.56339C4.75339 11.3198 2.70206 9.64511 1.79606 7.63844C1.4984 6.99959 1.34066 6.30452 1.33338 5.59977C1.33139 4.63276 1.71924 3.70576 2.40926 3.02828C3.09929 2.35081 4.03325 1.98004 5.00006 1.99977C5.78714 2.00102 6.55728 2.22849 7.21872 2.65511C7.50937 2.84375 7.77234 3.07194 8.00006 3.33311C8.22905 3.07297 8.49209 2.84491 8.78206 2.65511C9.44321 2.22841 10.2132 2.00092 11.0001 1.99977C11.9669 1.98004 12.9008 2.35081 13.5909 3.02828C14.2809 3.70576 14.6687 4.63276 14.6667 5.59977C14.6599 6.30565 14.5022 7.0019 14.2041 7.64177C13.2981 9.64844 11.2474 11.3224 9.43739 12.7998L9.43072 12.8051C8.91606 13.2244 8.43072 13.6211 8.00072 14.0051L8.00006 13.9998ZM5.00006 3.33311C4.37906 3.32534 3.7801 3.563 3.33339 3.99444C2.90298 4.41722 2.66243 4.99647 2.66667 5.59977C2.67427 6.11344 2.79061 6.61967 3.00806 7.08511C3.43573 7.95091 4.0128 8.73447 4.71272 9.39977C5.37339 10.0664 6.13339 10.7118 6.79072 11.2544C6.97272 11.4044 7.15806 11.5558 7.34339 11.7071L7.46006 11.8024C7.63806 11.9478 7.82206 12.0984 8.00006 12.2464L8.00872 12.2384L8.01272 12.2351H8.01672L8.02272 12.2304H8.02606H8.02939L8.04139 12.2204L8.06872 12.1984L8.07339 12.1944L8.08072 12.1891H8.08472L8.09072 12.1838L8.53339 11.8204L8.64939 11.7251C8.83672 11.5724 9.02206 11.4211 9.20406 11.2711C9.86139 10.7284 10.6221 10.0838 11.2827 9.41377C11.9827 8.74881 12.5598 7.96545 12.9874 7.09977C13.2088 6.63031 13.3268 6.11878 13.3334 5.59977C13.3362 4.99833 13.0957 4.4213 12.6667 3.99977C12.2209 3.56639 11.6218 3.32676 11.0001 3.33311C10.2413 3.32666 9.51602 3.64469 9.00672 4.20711L8.00006 5.36711L6.99339 4.20711C6.4841 3.64469 5.75877 3.32666 5.00006 3.33311Z"
                fill="#5D697D"
            />
        </svg>
    )
}
