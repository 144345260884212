import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="25" height="25" rx="12.5" fill="#6172FF" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 10C13.3284 10 14 10.6716 14 11.5L14 18.5C14 19.3284 13.3284 20 12.5 20C11.6716 20 11 19.3284 11 18.5L11 11.5C11 10.6716 11.6716 10 12.5 10Z"
            fill="white"
        />
        <path
            d="M14 6.5C14 7.32843 13.3284 8 12.5 8C11.6716 8 11 7.32843 11 6.5C11 5.67157 11.6716 5 12.5 5C13.3284 5 14 5.67157 14 6.5Z"
            fill="white"
        />
    </svg>
)
