import service from './service.js'
import Const from './constants.js'

export default {
	getCount: () => dispatch => {
		return service.getCount()
			.then(
				res => dispatch({
					type: Const.HEADER_MENU_COUNT_SUCCESS,
					reportsCount: res.remain_reports,
					intersectionsCount: res.remain_intersections,
					chatsCount: res.remain_chats
				}),
				err => dispatch({ type: Const.HEADER_MENU_COUNT_ERROR, err })
			)
	}
}