import Icons from '@/components/Icons'
import { useSpaceTranslation } from '@/_helpers'
import React, { FC, useEffect, useState } from 'react'

import Text from '@/components/Text'

import { TemplateGenerator } from './TemplateGenerator/TemplateGenerator'
import { ApiKey } from './ApiKey/ApiKey'

import { $getSelection } from 'lexical'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

interface EditorProps {
    className: any
    setSenderName: Function
    setSubject: Function
}

export const TemplateGeneratorBTN: FC<EditorProps> = ({ className, setSenderName, setSubject }) => {
    const t = useSpaceTranslation('outreach.templates.create.editor')

    const [isTemplateGeneratorOpen, setIsTemplateGeneratorOpen] = useState(false)
    const [isApiKeySaved, setIsApiKeySaved] = useState(false)

    useEffect(() => {
        const apiKey = localStorage.getItem('openai_api_key')
        if (apiKey) {
            setIsApiKeySaved(true)
        }
    }, [])

    const onSaveApiKey = () => {
        setIsApiKeySaved(true)
    }

    const onTemplateGeneratorClick = () => {
        setIsTemplateGeneratorOpen(true)
    }

    const onTemplateGeneratorClose = () => {
        setIsTemplateGeneratorOpen(false)
    }

    const [editor] = useLexicalComposerContext()

    const onGeneratorSave = ({ template, sender }: { template: string; sender: string }) => {
        const firstLine = template.substring(0, template.indexOf('\n')).replace('Subject: ', '')
        const messageBody = template.substring(template.indexOf('\n') + 1)

        setSubject(firstLine)
        editor.update(() => {
            const selection = $getSelection()
            if (selection) {
                selection.insertText(messageBody)
            }
        })
        setSenderName(sender)
    }

    return (
        <>
            <div className={className} onClick={onTemplateGeneratorClick}>
                <Icons name="magic" />
                <Text fSize={12}>{t('generator.btn')}</Text>
            </div>

            {isTemplateGeneratorOpen &&
                (isApiKeySaved ? (
                    <TemplateGenerator
                        isOpen={isTemplateGeneratorOpen}
                        close={onTemplateGeneratorClose}
                        onGeneratorSave={onGeneratorSave}
                    />
                ) : (
                    <ApiKey isOpen={isTemplateGeneratorOpen} close={onTemplateGeneratorClose} onSave={onSaveApiKey} />
                ))}
        </>
    )
}
