import { APIService } from '../../../../_helpers/APIService.js'

export const VerifyService = new APIService({
	methods:{
		verify: (user_id, timestamp, signature)=>({
			url: "/core/accounts/verify-registration/",
			method: "POST",
			data: { user_id, timestamp, signature }
		})
	}
})