import React from 'react'
import MoreModal from '@/components/MoreModal'
import ListOfAccounts from '@/Layouts/ReportLayout/Content/ResuableWidgets/AccountCards/ ListOfAccounts'
import { useSpaceTranslation } from '@/_helpers'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import { UserGame } from '../types'

const preprocessedData: any = []

for (let i = 0; i < 30; i++) {
    preprocessedData.push({
        id: 1,
        title: 'title',
        avatar: 'avatar',
        published_at: '29.01.2021',
        views: 1,
    })
}

export const GameMentions = () => {
    const t = useSpaceTranslation('report_layout.brand_mentions')

    const userGames = useSelector((store: RootStore) => {
        const reportData = store.report.rawTwitchData
        const channelData = reportData?.getTwitchUser?.data
        const metrics = channelData?.twitch_userinfo[0]

        if (!metrics) {
            return []
        }

        return metrics?.user_games.map((game: UserGame) => {
            const gameData = game.twitch_twitchgamecategory
            return {
                id: gameData.igdb_id,
                title: gameData.name,
                avatar: gameData.box_art_url.replace('{width}', '200').replace('{height}', '200'),
            }
        })
    })
    return (
        <div>
            <MoreModal title={t('title')}>
                <ListOfAccounts amount={Math.min(userGames.length, 30)} cardType="horizontal" data={userGames} />
            </MoreModal>
            <ListOfAccounts amount={6} cardType="horizontal" data={userGames} />
        </div>
    )
}
