import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.0051 15.8434H9.98842C9.93675 15.8434 4.76759 15.8334 3.46342 15.4792C2.74627 15.2861 2.18621 14.7257 1.99342 14.0084C1.7589 12.6854 1.64565 11.3437 1.65509 10.0001C1.64903 8.65426 1.76534 7.31067 2.00259 5.98591C2.20067 5.26801 2.75828 4.70504 3.47425 4.50008C4.74258 4.16675 9.76925 4.16675 9.98259 4.16675H10.0001C10.0526 4.16675 15.2351 4.17675 16.5259 4.53091C17.2416 4.72509 17.8005 5.28433 17.9943 6.00008C18.2362 7.32803 18.3498 8.6762 18.3334 10.0259C18.3393 11.3701 18.2227 12.712 17.9851 14.0351C17.7898 14.7514 17.2294 15.3106 16.5126 15.5042C15.2459 15.8401 10.2184 15.8434 10.0051 15.8434ZM8.33842 7.50425L8.33425 12.5042L12.6776 10.0042L8.33842 7.50425Z"
            fill="#F55C5C"
        />
    </svg>
)
