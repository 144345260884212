export const levels = [
    {
        title: 'Poor',
        color: '#EF3B46',
        range: '0-20',
    },
    {
        title: 'Low',
        color: '#EE715F',
        range: '21-40',
    },
    {
        title: 'Middle',
        color: '#FBBC5E',
        range: '41-60',
    },
    {
        title: 'Good',
        color: '#AADC70',
        range: '61-80',
    },
    {
        title: 'High',
        color: '#59D98B',
        range: '81-90',
    },
    {
        title: 'Excellent',
        color: '#1FBC67',
        range: '91-100',
    },
]
export const GetLevel = (main_value: number) => {
    let index = Math.round(main_value * 100)
    if (index <= 20) {
        return levels[0]
    } else if (index <= 40) {
        return levels[1]
    } else if (index <= 60) {
        return levels[2]
    } else if (index <= 80) {
        return levels[3]
    } else if (index <= 90) {
        return levels[4]
    } else {
        return levels[5]
    }
}
