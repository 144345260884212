import React, { FC } from 'react'
import Text from '@/components/Text'

import cn from './PostBrands.module.scss'

interface IPostBrands {
    brand: string
}

export const PostBrands: FC<IPostBrands> = ({ brand }) => {
    return (
        <div className={cn.postBrands}>
            <Text fSize={12} color={'gray-1'}>
                {brand}
            </Text>
        </div>
    )
}
