import React from 'react'

import { SelectorFilter } from '@/pages/SearchPage/SearchAdapter/filters/SelectorFilter'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import Icons from '@/components/Icons'
import { GET_TIKOK_GEO } from '../../../utils/queries'
import { preprocessLocation } from '../../../utils/PreprData'
import { ITikokGeoRequest } from '../../../utils/types'

const getGqlFilter = (countries: Array<string>, percentage: number) => {
    if (!countries || !percentage) return ''
    const countriesFilter = countries
        .map((item) => {
            return `{id: ${parseInt(item)},
                weight: ${(percentage ? percentage : 10) / 100}
            }`
        })
        .join(', ')

    return `audience_geo: [${countriesFilter}]`
}

export const getAudCountryFilter = (fetcher: Fetcher) => {
    return new SelectorFilter({
        platform: 'infl_tiktok',
        title: 'filters.loc.fields.aud.label',
        helper: 'filters.loc.fields.aud.text',
        icon: <Icons name="filter--geo" />,
        space: 1,
        autocomplete: true,
        hasPercentage: true,
        isMulti: true,
        options: async () => {
            const client = fetcher.getClient()
            return await client.query<ITikokGeoRequest>({ query: GET_TIKOK_GEO }).then((res) => {
                const locs = res.data?.getTikTokGeo.data
                return preprocessLocation(locs)
            })
        },
        beforeSend: ({ value, percentage }) => {
            return {
                name: 'audience.country',
                type: '',
                value: value,
                gqlFilter: getGqlFilter(value, percentage),
            }
        },
    })
}
