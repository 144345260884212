import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
                x="1"
                y="1"
                width="18"
                height="18"
                rx="9"
                fill="url(#paint0_linear_2482_38882)"
                stroke="white"
                strokeWidth="2"
            />
            <path d="M7.97433 6.91602L7.96924 13.0822L13.2811 9.99909L7.97433 6.91602Z" fill="white" />
            <defs>
                <linearGradient
                    id="paint0_linear_2482_38882"
                    x1="10"
                    y1="2"
                    x2="10"
                    y2="18"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FE4737" />
                    <stop offset="1" stopColor="#C01D0E" />
                </linearGradient>
            </defs>
        </svg>
    )
}
