import React, { useEffect } from 'react'
import { Service } from './service'
import { getParams } from '@/_helpers'
import { LOGIN, SEARCH } from '@/routs'
import { useHistory } from 'react-router-dom'

export const OauthGmail = () => {
    const history = useHistory()

    useEffect(() => {
        const params = getParams()

        // @ts-ignore
        Service.OAuth(params)
            .then((res: any) => {
                window.location.href = SEARCH
            })
            .catch((err: any) => {
                history.push(LOGIN)
                console.error({ err })
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <></>
}
