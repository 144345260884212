import React, { FC } from 'react'
import cn from './range-select.module.scss'
import { Flex } from '@/_yc'

interface Option {
    label: string
    value: string
}

interface MultiSelectProps {
    options: Option[]
    selectedValues: Option[]
    onChange: (selected: Option[]) => void
}

const RangeSelect: FC<MultiSelectProps> = ({ options, selectedValues, onChange }) => {
    const toggleValue = (selectedOption: Option) => {
        try {
            const isSelected = selectedValues?.some((item) => item.value === selectedOption.value)

            if (isSelected) {
                onChange(selectedValues?.filter((item) => item.value !== selectedOption.value))
            } else {
                onChange([...selectedValues, selectedOption])
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className={cn['range-container']}>
            <Flex className={cn['options']}>
                {options.map((option) => (
                    <button
                        key={option.value}
                        className={`${cn.option} ${
                            selectedValues?.some((item) => item.value === option.value) ? cn.active : ''
                        }`}
                        onClick={() => toggleValue(option)}
                    >
                        {option.label} {/* Отображаем label */}
                    </button>
                ))}
            </Flex>
        </div>
    )
}

export default RangeSelect
