import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { RootStore } from '@/_helpers/StoreType'

import { Flex } from '@/_yc'
import MultiBarChart from '../ResuableWidgets/MultiBarChart'
import DonutChart from '../ResuableWidgets/DonutChart'
import {
    preprocessCountryNLang,
    preprocessAgeGender,
    getCountriesFromRaw,
    getLanguagesFromRaw,
} from './PreproccessData'

import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

const Demographics = () => {
    const t = useSpaceTranslation('report_layout.demographics')
    const { i18n } = useTranslation()
    const demographics = useSelector((store: RootStore) => {
        const getChannelData = store.report.rawChannelData.getChannel?.data
        return getChannelData?.youtube_channel[0].audience_agender
    })
    const audienceCountries = useSelector((store: RootStore) => {
        return store.report.rawChannelData.getChannel?.data?.youtube_channel[0].audience_countries
    })
    const audienceLang = useSelector((store: RootStore) => {
        return store.report.rawChannelData.getChannel?.data?.youtube_channel[0].audience_languages
    })

    const countries = useMemo(
        () => preprocessCountryNLang(getCountriesFromRaw(audienceCountries), i18n.language.slice(0, 2)),
        [audienceCountries, i18n.language]
    )
    const languages = useMemo(
        () => preprocessCountryNLang(getLanguagesFromRaw(audienceLang), i18n.language.slice(0, 2)),
        [audienceLang, i18n.language]
    )
    const ageGender = useMemo(() => preprocessAgeGender(demographics), [demographics])
    const genderPercent = useSelector((store: RootStore) => {
        const getChannelData = store.report.rawChannelData.getChannel?.data
        if (getChannelData) return getChannelData?.youtube_channel[0].audience_agender_total
        return { total_man_share: 0, total_woman_share: 0 }
    })

    const age_gender_Legend = useMemo(() => {
        return [
            {
                id: 2,
                value: t('age_gender.legend.men'),
                color: '#6071FF',
            },
            {
                id: 1,
                value: t('age_gender.legend.women'),
                color: '#FF659C',
            },
        ]
    }, [t])

    const genderDist = useMemo(
        () => [
            {
                label: t('gender_dist.legend.men'),
                label_color: `#6071FF`,
                value: genderPercent?.total_man_share,
                color: '#6071FF',
                percent_color: `#E2E6FF`,
            },
            {
                label: t('gender_dist.legend.women'),
                label_color: `#FF659C`,
                value: genderPercent?.total_woman_share,
                color: '#FF659C',
                percent_color: `#FFDCE2`,
            },
        ],
        [t, genderPercent]
    )
    const ageGenderCheck = ageGender.reduce(
        (partialSum, item) => partialSum + item.items.reduce((partSum, a) => partSum + a.share, 0),
        0
    )
    const isAgeGenderAvailable =
        genderDist[0].value + genderDist[1].value !== 0 && ageGenderCheck !== 0 && ageGender.length !== 0

    return (
        <>
            {isAgeGenderAvailable && (
                <Flex content="space-between" margin="20px 0 0 0" wrap>
                    <MultiBarChart
                        title={t('age_gender.title')}
                        data={ageGender}
                        legend={age_gender_Legend}
                        size="large"
                    />
                    <DonutChart data={genderDist} title={t('gender_dist.title')} />
                </Flex>
            )}
            <Flex content="space-between" margin="20px 0 0 0" wrap>
                {countries.length !== 0 && (
                    <MultiBarChart title={t('countries.title')} data={countries} type="horizontal" size="medium" />
                )}
                {languages.length !== 0 && (
                    <MultiBarChart title={t('lang.title')} data={languages} type="horizontal" size="medium" />
                )}
            </Flex>
        </>
    )
}

export default Demographics
