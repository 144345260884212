import React, { FC } from 'react'
import cn from './ItemsHead.module.scss'
import { memo } from 'react'
import { ChooseAllNEW } from '@/components/MassAddToList/ChooseAll'
import Text from '@/components/Text'
import { useSpaceTranslation } from '@/_helpers'
import Sorter from '@/pages/SearchPage/Bloggers/SearchItems/Sorter'
import Flex from '@/_yc/Flex'
import { Segment } from '@/Layouts/ListLayout'
import { MassAddHeadCheckBox } from '@/components/MassAddToList/MassAddHeadCheckBox'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import { Platform } from '@/pages/Reports/types'
export interface IItemHead {
    margin?: string | number
}

const ItemsHead: FC<IItemHead> = (props) => {
    const t = useSpaceTranslation('Influencer_explorer.grid_header')
    const MassAddToListDataTwitch = useSelector((store: RootStore) => store.MassAddToList.dataTwitch)
    const showHeader = MassAddToListDataTwitch.length === 0

    if (!showHeader) {
        return (
            <div className={cn['head--controls']} style={{ height: '60px' }}>
                <Segment margin="0 0 0 20px">
                    <MassAddHeadCheckBox place={'infl_exp_twitch'} />
                </Segment>
            </div>
        )
    }

    return (
        <div className={cn.head}>
            <ChooseAllNEW platform={Platform.Twitch} />
            <Text color="gray-3" fSize={10}>
                #
            </Text>
            <Text color="gray-3" fSize={10}>
                {t('username')}
            </Text>
            <Text color="gray-3" fSize={10}>
                {t('followers')}
            </Text>
            <Text color="gray-3" fSize={10}>
                {t('avg_views')}
            </Text>
            <Flex content="flex-end">
                <Sorter />
            </Flex>
        </div>
    )
}

export default memo(ItemsHead)
