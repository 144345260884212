import React, { useEffect } from 'react'
import { headers } from '../AllDataReports/data'
import List from '../components/list'
import { ICampaignResponse } from '@/pages/TargetAudience/services/types'
import useToken from '@/_helpers/Hooks/useToken'
import { fetchCampaignsReq } from '@/pages/TargetAudience/services/services'

const Index = () => {
    const [tableData, setTableData] = React.useState<ICampaignResponse[]>([])
    const [loading, setLoading] = React.useState(false)
    const token = useToken()

    useEffect(() => {
        setLoading(true)
        fetchCampaignsReq(token)
            .then((res) => {
                setTableData(res)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [token])
    return <List headers={headers} data={tableData.filter((r) => r.status === 'draft')} loading={loading} />
}

export default Index
