import React from 'react'

import Icons from '@/components/Icons'
import { Fetcher } from '@/_helpers/graphql/Fetcher'

import { pageToOffset } from '../youtube/utils/queries/pageToOffset'
import ItemsHead from './ItemsHead'
import SearchItem from './SearchItem/SearchItem'
import { Adapter } from '../../Adapter'
import { getTelegramChannelsCountQuery, getTelegramChannelsQuery } from './utils/queries'
import { getTgAccountsFromRaw } from './utils/PreprData'
import { QueryType, TgUserInfoResult, ISearchBy } from './utils/types'
import { getMainFilters, getFilters } from './filters/filters'
import { getFilterQueries } from '../youtube/utils/filters/filterQueries'
import { getSearchByTable } from './utils/searchByTable'
import service from '@/pages/Auth/LoginPage/Model/_service'

// import { RootStore } from '@/_helpers/StoreType'
// import { store } from '@/_helpers/store'

import cn from './Tg.module.scss'

const fetcher = new Fetcher()
const getIsFullTariff = async () => {
    // @ts-ignore
    const platformsRes = await service.getSubscriptionPlatforms()
    const isTgAvailable = platformsRes?.is_telegram_available ?? false
    return !isTgAvailable
}
export const TgAdapter = new Adapter({
    Head: ItemsHead,
    background: `linear-gradient(180deg, #5ca4f5 0%, #593cd9 100%)`,
    Icon: () => <Icons className={cn.icon} name="ie-tg" />,
    fetcher: async (_, config) => {
        const client = fetcher.getClient()

        const offSet = pageToOffset(config.page)
        const search = `args:{search:"${config.search}"},`

        const queryType: QueryType = getSearchByTable(config.by as ISearchBy[])

        const queries = getFilterQueries(config.filters)

        const queryParams = {
            orderBy: config.sorting,
            offset: offSet,
            filters: queries,
        }

        // const amount = await client.query({
        //     query: getVkChannelsCountQuery(queryType, search, queryParams),
        // })

        const accounts = await client.query<TgUserInfoResult>({
            query: getTelegramChannelsQuery(queryType, search, queryParams),
        })

        // const isTgAvailable = false
        // const isFullTariff = !isTgAvailable

        const isFullTariff = await getIsFullTariff()
        const hasNext = !isFullTariff || config.page !== 1
        const sliceIndex = isFullTariff && (config.search !== '' || queries.length > 0) ? 3 : 30

        return {
            hasNext: hasNext,
            total: 0, //amount.data[`${queryType}_aggregate`].aggregate.count || 0,
            items: getTgAccountsFromRaw(queryType, accounts.data, sliceIndex),
            isHidden: isFullTariff,
        }
    },
    countCheck: async (token, config) => {
        const client = fetcher.getClient()

        const search = `args:{search:"${config.search}"},`

        const queryType: QueryType = getSearchByTable(config.by as ISearchBy[])

        const queries = getFilterQueries(config.filters)

        const queryParams = {
            orderBy: config.sorting,
            offset: 0,
            filters: queries,
        }

        const amount = await client.query({
            query: getTelegramChannelsCountQuery(queryType, search, queryParams),
        })

        return {
            count: amount.data[`${queryType}_aggregate`].aggregate.count || 0,
        }
    },
    keyWords: [
        { label: 'forKeywords.accName', value: 'title' },
        { label: 'forKeywords.accDescription', value: 'description' },
    ],
    sortings: [
        {
            label: `forKeywords.subCount`,
            value: 'subscribers_count: desc_nulls_last',
        },
        {
            label: 'forKeywords.medianER',
            value: 'er_views: desc_nulls_last',
        },
    ],
    mainFilters: getMainFilters(fetcher) as any,
    filters: getFilters(fetcher) as any,
    templateColumns: '',
    title: 'Telegram',
    Item: SearchItem,
})
