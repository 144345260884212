type IconName = 'tiktok' | 'youtube' | 'instagram' | 'vk' | 'telegram' | 'twitch'
type Icon = 'search' | 'analysis' | 'intersection' | 'competitor'

interface ServiceCardData {
    title: string
    description: string
    icon: Icon
    link: string
    icons: IconName[]
}
export const serviceCardsData: ServiceCardData[] = [
    {
        title: 'subscription_confirmation.search_blogger',
        description: 'subscription_confirmation.search_blogger_description',
        icon: 'search',
        link: '/search',
        icons: ['instagram', 'youtube', 'tiktok', 'twitch', 'vk', 'telegram'],
    },
    {
        title: 'subscription_confirmation.blogger_analysis',
        description: 'subscription_confirmation.blogger_analysis_description',
        icon: 'analysis',
        link: '/audience',
        icons: ['instagram', 'youtube', 'tiktok', 'twitch', 'vk', 'telegram'],
    },
    {
        title: 'subscription_confirmation.audience_overlap',
        description: 'subscription_confirmation.audience_overlap_description',
        icon: 'intersection',
        link: '/intersections',
        icons: ['instagram'],
    },
    {
        title: 'subscription_confirmation.competitor_analysis',
        description: 'subscription_confirmation.competitor_analysis_description',
        icon: 'competitor',
        link: '/ads/instagram',
        icons: ['instagram', 'youtube'],
    },
]
