import Button from '@/components/Button'
import Icons from '@/components/Icons'
import Text from '@/components/Text'
import { BILLING, TARIFFS, SETTINGS, LOGIN, AFFILIATE } from '@/routs'
import { RootStore } from '@/_helpers/StoreType'
import useOpenClose from '@/_helpers/useOpenClose'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { Flex } from '@/_yc'
import Popup from '@/_yc/Popup'
import React, { FC, memo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import cn from './ProfileMenu.module.scss'
import ym from 'react-yandex-metrika'
import { useDispatch } from 'react-redux'
import { loginActions } from '@/pages/Auth/LoginPage/Model'
export interface IProfileMenu {
    margin: string | number
}

const padding = { paddingTop: '5px', zIndex: 400, marginRight: -20 }
const ProfileMenu: FC<IProfileMenu> = (props) => {
    const ref = useRef(null)

    const t = useSpaceTranslation('top_menu.user_menu')
    const tag = useSpaceTranslation('tags.plans_sidebar')
    const useGlob = () => {
        if ((global as any).isProd) {
            ;(global as any).gtag('event', tag('name'), {
                event_category: tag('category'),
                event_action: tag('action'),
            })
            ym('reachGoal', tag('category'))
        }
    }
    const fullName = useSelector((store: RootStore) => {
        if (store.authentication.user?.profile?.first_name && store.authentication.user?.profile?.last_name) {
            return `${store.authentication.user.profile.first_name} ${store.authentication.user.profile.last_name}`
        } else {
            return store.authentication.user?.profile?.email || null
        }
    })

    const [state, , , toggle] = useOpenClose()
    const dispatch = useDispatch()
    const handleLogOut = () => {
        dispatch(loginActions.logout())
    }
    return (
        <>
            <Flex
                ref={ref}
                component={Button}
                align="center"
                content="center"
                variant="transparent"
                className={cn.btn}
                width={40}
                size="small"
                margin={props.margin}
                onClick={toggle}
            >
                <Icons name="profile" className={cn.icon} />
            </Flex>
            <Popup style={padding} isOpen={state} anchor={ref.current} onClose={toggle} placement="bottom-end">
                <div className={cn.modal}>
                    <Text className={cn.fullName} margin="4px 10px" fSize={14} semibold>
                        {fullName}
                    </Text>
                    <Text className={cn.link} fSize={14} color="gray-3" component={Link} to={BILLING}>
                        {t('billing')}
                    </Text>
                    <Text className={cn.link} fSize={14} color="gray-3" component={Link} to={TARIFFS} onClick={useGlob}>
                        {t('pricing')}
                    </Text>
                    <Text className={cn.link} fSize={14} color="gray-3" component={Link} to={SETTINGS}>
                        {t('settings')}
                    </Text>
                    <Text className={cn.link} fSize={14} color="gray-3" component={Link} to={AFFILIATE}>
                        {t('referrals')}
                    </Text>
                    <div className={cn.divider} />
                    <Text
                        className={cn.link}
                        fSize={14}
                        color="gray-3"
                        component={Link}
                        onClick={() => handleLogOut()}
                        to={LOGIN}
                    >
                        {t('logout')}
                    </Text>
                </div>
            </Popup>
        </>
    )
}

export default memo(ProfileMenu)
