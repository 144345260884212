import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { FilterCategory } from '../../../filters/FilterCategory'
import { getBrandsFilter } from './postMetrics/brands'
import { getCommentsFilter } from './postMetrics/comments'
import { getCountryFilter } from './postMetrics/country'
import { getDateFilter } from './postMetrics/date'
import { getFollowersFilter } from './postMetrics/followers'
import { getLikesFilter } from './postMetrics/likes'
import { getViewsFilter } from './postMetrics/views'
import { getKeywordFilter } from '../../instagram/filters/postMetrics/keywords'

export const getFilters = (fetcher: Fetcher) => {
    return {
        post_metrics: new FilterCategory('', {
            brands: getBrandsFilter(fetcher),
            views: getViewsFilter(),
            keywords: getKeywordFilter(),
            likes: getLikesFilter(),
            date: getDateFilter(),
            comments: getCommentsFilter(),
            followers: getFollowersFilter(),
            country: getCountryFilter(fetcher),
        }),
    }
}
