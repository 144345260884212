import React from 'react';

export const StandartEmptyText = props => (
	<div
		style = {{
			position: 'absolute',
			top: "0",
			left: 0,
			width: "100%",
			height: "100%",
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			zIndex: 100,
		}}
	>
		<h3
			style = {{
				fontSize: 30,
				color: "#2a2a31",
				margin: "0 0 6px",
				textAlign: 'center'
			}}
		>
			{props.label}
		</h3>
		<p
			style = {{
				fontSize: 14,
				color: "#2a2a31",
				margin: "0",
				textAlign: 'center'
			}}
		>
			{props.sublabel}
		</p>
	</div>
)