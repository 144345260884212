import React, { memo } from 'react'
// import { useTranslation } from 'react-i18next'

const OfertaLink = (props) => {
    // const { i18n } = useTranslation()

    // const lng = useMemo(() => {
    //     return i18n.language.split('-')[0]
    // }, [i18n])

    return (
        <a
            {...props}
            rel="noopener noreferrer"
            href={window.location.origin + `/privacy_policy.pdf`}
            target="_blank"
        >
            {props.children}
        </a>
    )
}

const memed = memo(OfertaLink)

export { memed as OfertaLink }
