import React, { FC, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootStore } from '@/_helpers/StoreType'
import { Platform } from '@/pages/Reports/types'
import { ICustomListInfo } from '@/pages/Lists/model/types'
import { addToSelectedCampaigns, removeFromSelectedCampaigns } from '@/pages/Outreach/Model/actions'
import { addToSelectedAccounts, removeFromSelectedAccounts } from '@/pages/SettingsPage/Model/actions'

import {
    AddToListINST,
    DeleteFromListINST,
    AddToListYT,
    DeleteFromListYT,
    SetLastClicked,
    AddToListTwitch,
    DeleteFromListTwitch,
    AddToListVk,
    DeleteFromListVk,
    AddToListsAud,
    DeleteFromListsAud,
    RemoveListsAud,
    AddToListMediaPlannerPlans,
    DeleteFromListMediaPlannerPlans,
    AddToListMediaPlannerBloggers,
    DeleteFromListMediaPlannerBloggers,
    DeleteFromListTg,
    AddToListTg,
} from './_model/actions'
import Checkbox from './Checkbox'
import { UseShift } from './UseShift'

import cn from './MassAddToList.module.scss'

interface IMassAddToList {
    id: string
    channelId?: string
    externalId?: string
    bloggerId?: number
    data?: {
        avatar: string
        name: string
        username: string
        hasEmail: boolean
    }
    listData?: ICustomListInfo
    type: Platform | 'outreach' | 'lists' | 'settings-accounts' | 'media-planner-plans' | 'media-planner-bloggers'
    disabled?: boolean
    index: number
}

export const MassAddCheckBox: FC<IMassAddToList> = ({
    id,
    data,
    type,
    disabled,
    channelId,
    bloggerId,
    externalId,
    index,
    listData,
}) => {
    const dispatch = useDispatch()
    const [checkValue, setCheckValue] = useState(false)

    //need type
    const MassAddToListDataINST = useSelector((store: RootStore) => store.MassAddToList.dataINST)
    const MassAddToListDataYT = useSelector((store: RootStore) => store.MassAddToList.dataYT)
    const MassAddToListDataTwitch = useSelector((store: RootStore) => store.MassAddToList.dataTwitch)
    const MassAddToListDataVk = useSelector((store: RootStore) => store.MassAddToList.dataVk)
    const selectedCampaigns = useSelector((store: RootStore) => store.Outreach.selectedCampaigns)
    const MassAddToListsAud = useSelector((store: RootStore) => store.MassAddToList.listsAud)
    const selectedAccounts = useSelector((store: RootStore) => store.Settings.selectedAccounts)
    const selectedMediaPlans = useSelector((store: RootStore) => store.MassAddToList.mediaPlanner.selectedMediaPlans)
    const selectedMediaPlanBloggers = useSelector(
        (store: RootStore) => store.MassAddToList.mediaPlanner.selectedBloggers
    )

    const LastClicked = useSelector((store: RootStore) => store.MassAddToList.lastClicked)

    useEffect(() => {
        switch (type) {
            case Platform.Instagram: {
                if (MassAddToListDataINST.map((item) => item.id).indexOf(id) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
            case Platform.YouTube: {
                if (MassAddToListDataYT.map((item) => item.id).indexOf(id) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
            case Platform.Twitch: {
                if (MassAddToListDataTwitch.map((item) => item.id).indexOf(id) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
            case Platform.Vk: {
                if (MassAddToListDataVk.map((item) => item.id).indexOf(id) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
            case 'outreach': {
                if (selectedCampaigns.indexOf(id) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
            case 'lists': {
                if (MassAddToListsAud.map((item) => item.id).indexOf(parseInt(id)) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
            case 'settings-accounts': {
                if (selectedAccounts.indexOf(id) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
            case 'media-planner-plans': {
                if (selectedMediaPlans.indexOf(id) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
            case 'media-planner-bloggers': {
                if (selectedMediaPlanBloggers.indexOf(id) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
        }
    }, [
        MassAddToListDataINST,
        MassAddToListDataYT,
        MassAddToListDataTwitch,
        selectedCampaigns,
        MassAddToListsAud,
        selectedAccounts,
        selectedMediaPlans,
        selectedMediaPlanBloggers,
        MassAddToListDataVk,
        id,
        type,
    ])

    const addToList = () => {
        if (!checkValue) {
            switch (type) {
                case Platform.Instagram: {
                    if (!data) return
                    dispatch(
                        AddToListINST([
                            {
                                id,
                                avatar: data.avatar,
                                name: data.name,
                                username: data.username,
                                bloggerId,
                                externalId,
                                hasEmail: data.hasEmail,
                            },
                        ])
                    )
                    break
                }
                case Platform.YouTube: {
                    if (!data) return
                    dispatch(
                        AddToListYT([
                            {
                                id,
                                avatar: data.avatar,
                                name: data.name,
                                username: data.username,
                                externalId,
                                hasEmail: data.hasEmail,
                            },
                        ])
                    )
                    break
                }
                case Platform.Twitch: {
                    if (!data) return
                    dispatch(
                        AddToListTwitch([
                            {
                                id,
                                avatar: data.avatar,
                                name: data.name,
                                username: data.username,
                                channelId,
                                hasEmail: data.hasEmail,
                            },
                        ])
                    )
                    break
                }
                case Platform.Vk: {
                    if (!data) return
                    dispatch(
                        AddToListVk([
                            {
                                id,
                                avatar: data.avatar,
                                name: data.name,
                                username: data.username,
                                channelId,
                                hasEmail: data.hasEmail,
                            },
                        ])
                    )
                    break
                }
                case 'outreach': {
                    dispatch(addToSelectedCampaigns(id))
                    break
                }
                case 'lists': {
                    if (!listData) return
                    dispatch(AddToListsAud([listData]))
                    break
                }
                case 'settings-accounts': {
                    dispatch(addToSelectedAccounts([id]))
                    break
                }
                case 'media-planner-plans': {
                    dispatch(AddToListMediaPlannerPlans([id]))
                    break
                }
                case 'media-planner-bloggers': {
                    dispatch(AddToListMediaPlannerBloggers([id]))
                    break
                }
            }
        } else {
            switch (type) {
                case Platform.Instagram: {
                    dispatch(DeleteFromListINST(id))
                    break
                }
                case Platform.YouTube: {
                    dispatch(DeleteFromListYT(id))
                    break
                }
                case Platform.Twitch: {
                    dispatch(DeleteFromListTwitch(id))
                    break
                }
                case Platform.Vk: {
                    dispatch(DeleteFromListVk(id))
                    break
                }
                case 'outreach': {
                    dispatch(removeFromSelectedCampaigns(id))
                    break
                }
                case 'lists': {
                    dispatch(DeleteFromListsAud(parseInt(id)))
                    break
                }
                case 'settings-accounts': {
                    dispatch(removeFromSelectedAccounts(id))
                    break
                }
                case 'media-planner-plans': {
                    dispatch(DeleteFromListMediaPlannerPlans(id))
                    break
                }
                case 'media-planner-bloggers': {
                    dispatch(DeleteFromListMediaPlannerBloggers(id))
                    break
                }
            }
        }
    }
    const addWithShift = () => {
        if (!LastClicked) return
        const handleIndexes = () => {
            return [index !== 0 ? index - 1 : index, LastClicked !== 0 ? LastClicked - 1 : LastClicked]
        }
        const inds = index > LastClicked ? [LastClicked, index] : handleIndexes()

        //просто здесь вместо массива jsx элементов брать массив данных из редакса и работать по нему
        const checks = Array.from(document.querySelectorAll("button[data-selector='mass-add']")).slice(inds[0], inds[1])
        //@ts-ignore
        checks.forEach((el) => el.click())

        return
    }

    const handleClick = (e: boolean) => {
        console.log('click')
        if (disabled) return
        if (e) index && dispatch(SetLastClicked(index))

        if (e && LastClicked) {
            addWithShift()
            return
        }

        addToList()
    }

    useEffect(() => {
        return () => {
            //clean on unmount
            switch (type) {
                case Platform.Instagram: {
                    dispatch(DeleteFromListINST(id))
                    break
                }
                case Platform.YouTube: {
                    dispatch(DeleteFromListYT(id))
                    break
                }
                case Platform.Twitch: {
                    dispatch(DeleteFromListTwitch(id))
                    break
                }
                case Platform.Vk: {
                    dispatch(DeleteFromListVk(id))
                    break
                }
                case Platform.Tg: {
                    dispatch(DeleteFromListTg(id))
                    break
                }
                case 'outreach': {
                    dispatch(removeFromSelectedCampaigns(id))
                    break
                }
                case 'lists': {
                    dispatch(RemoveListsAud())
                    break
                }
                case 'settings-accounts': {
                    dispatch(removeFromSelectedAccounts(id))
                    break
                }
                case 'media-planner-plans': {
                    dispatch(DeleteFromListMediaPlannerPlans(id))
                    break
                }
                case 'media-planner-bloggers': {
                    dispatch(DeleteFromListMediaPlannerBloggers(id))
                    break
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [ShiftDown, setShiftDown] = useState<boolean>(false)
    const handleShiftDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Shift' && LastClicked && LastClicked === index) {
                setShiftDown(e.type === 'keydown')
                return
            }
        },
        [LastClicked, index]
    )
    const handleShiftUp = useCallback((e: KeyboardEvent) => {
        setShiftDown(false)
    }, [])

    useEffect(() => {
        window.addEventListener('keydown', handleShiftDown)
        return () => window.removeEventListener('keydown', handleShiftDown)
    }, [LastClicked, handleShiftDown])
    useEffect(() => {
        window.addEventListener('keyup', handleShiftUp)
        return () => window.removeEventListener('keyup', handleShiftUp)
    }, [LastClicked, handleShiftUp])

    return (
        <>
            <button
                className={cn[`root${ShiftDown ? '--highlight' : ''}`]}
                onClick={(event) => handleClick(event.shiftKey)}
                data-selector="mass-add"
            >
                <Checkbox value={checkValue ? 'checked' : 'empty'} disabled={disabled} />
            </button>
        </>
    )
}

export type CheckType =
    | Platform
    | 'outreach'
    | 'lists'
    | 'settings-accounts'
    | 'media-planner-plans'
    | 'media-planner-bloggers'

interface IMassAddToListNEW {
    id: string
    channelId?: string
    externalId?: string
    bloggerId?: number
    data?: {
        avatar: string
        name: string
        username: string
        hasEmail: boolean
    }
    listData?: ICustomListInfo
    type: CheckType
    place?: 'listBloggers'
    disabled?: boolean
    index: number
}

export const MassAddCheckBoxNEW: FC<IMassAddToListNEW> = ({
    id,
    data,
    type,
    disabled,
    channelId,
    bloggerId,
    externalId,
    index,
    listData,
    place,
}) => {
    const dispatch = useDispatch()
    const [checkValue, setCheckValue] = useState(false)
    const { addWithShift, ShiftDown } = UseShift(type, index, place)

    //need type
    const MassAddToListDataINST = useSelector((store: RootStore) => store.MassAddToList.dataINST)
    const MassAddToListDataYT = useSelector((store: RootStore) => store.MassAddToList.dataYT)
    const MassAddToListDataTwitch = useSelector((store: RootStore) => store.MassAddToList.dataTwitch)
    const MassAddToListDataVk = useSelector((store: RootStore) => store.MassAddToList.dataVk)
    const MassAddToListDataTg = useSelector((store: RootStore) => store.MassAddToList.dataTg)

    const selectedCampaigns = useSelector((store: RootStore) => store.Outreach.selectedCampaigns)
    const MassAddToListsAud = useSelector((store: RootStore) => store.MassAddToList.listsAud)
    const selectedAccounts = useSelector((store: RootStore) => store.Settings.selectedAccounts)
    const selectedMediaPlans = useSelector((store: RootStore) => store.MassAddToList.mediaPlanner.selectedMediaPlans)
    const selectedMediaPlanBloggers = useSelector(
        (store: RootStore) => store.MassAddToList.mediaPlanner.selectedBloggers
    )

    const LastClicked = useSelector((store: RootStore) => store.MassAddToList.lastClicked)

    useEffect(() => {
        switch (type) {
            case Platform.Instagram: {
                if (MassAddToListDataINST.map((item) => item.id).indexOf(id) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
            case Platform.YouTube: {
                if (MassAddToListDataYT.map((item) => item.id).indexOf(id) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
            case Platform.Twitch: {
                if (MassAddToListDataTwitch.map((item) => item.id).indexOf(id) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
            case Platform.Vk: {
                if (MassAddToListDataVk.map((item) => item.id).indexOf(id) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
            case Platform.Tg: {
                if (MassAddToListDataTg.map((item) => item.id).indexOf(id) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
            case 'outreach': {
                if (selectedCampaigns.indexOf(id) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
            case 'lists': {
                if (MassAddToListsAud.map((item) => item.id).indexOf(parseInt(id)) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
            case 'settings-accounts': {
                if (selectedAccounts.indexOf(id) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
            case 'media-planner-plans': {
                if (selectedMediaPlans.indexOf(id) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
            case 'media-planner-bloggers': {
                if (selectedMediaPlanBloggers.indexOf(id) === -1) {
                    setCheckValue(false)
                } else {
                    setCheckValue(true)
                }
                break
            }
        }
    }, [
        MassAddToListDataINST,
        MassAddToListDataYT,
        MassAddToListDataTwitch,
        selectedCampaigns,
        MassAddToListsAud,
        selectedAccounts,
        selectedMediaPlans,
        selectedMediaPlanBloggers,
        MassAddToListDataVk,
        MassAddToListDataTg,
        id,
        type,
    ])

    const addToList = () => {
        if (!checkValue) {
            switch (type) {
                case Platform.Instagram: {
                    if (!data) return
                    dispatch(
                        AddToListINST([
                            {
                                id,
                                avatar: data.avatar,
                                name: data.name,
                                username: data.username,
                                bloggerId,
                                externalId,
                                hasEmail: data.hasEmail,
                            },
                        ])
                    )
                    break
                }
                case Platform.YouTube: {
                    if (!data) return
                    dispatch(
                        AddToListYT([
                            {
                                id,
                                avatar: data.avatar,
                                name: data.name,
                                username: data.username,
                                channelId,
                                hasEmail: data.hasEmail,
                            },
                        ])
                    )
                    break
                }
                case Platform.Twitch: {
                    if (!data) return
                    dispatch(
                        AddToListTwitch([
                            {
                                id,
                                avatar: data.avatar,
                                name: data.name,
                                username: data.username,
                                channelId,
                                hasEmail: data.hasEmail,
                            },
                        ])
                    )
                    break
                }
                case Platform.Vk: {
                    if (!data) return
                    dispatch(
                        AddToListVk([
                            {
                                id,
                                avatar: data.avatar,
                                name: data.name,
                                username: data.username,
                                channelId,
                                hasEmail: data.hasEmail,
                            },
                        ])
                    )
                    break
                }
                case Platform.Tg: {
                    if (!data) return
                    dispatch(
                        AddToListTg([
                            {
                                id,
                                avatar: data.avatar,
                                name: data.name,
                                username: data.username,
                                externalId,
                                hasEmail: data.hasEmail,
                            },
                        ])
                    )
                    break
                }
                case 'outreach': {
                    dispatch(addToSelectedCampaigns(id))
                    break
                }
                case 'lists': {
                    if (!listData) return
                    dispatch(AddToListsAud([listData]))
                    break
                }
                case 'settings-accounts': {
                    dispatch(addToSelectedAccounts([id]))
                    break
                }
                case 'media-planner-plans': {
                    dispatch(AddToListMediaPlannerPlans([id]))
                    break
                }
                case 'media-planner-bloggers': {
                    dispatch(AddToListMediaPlannerBloggers([id]))
                    break
                }
            }
        } else {
            switch (type) {
                case Platform.Instagram: {
                    dispatch(DeleteFromListINST(id))
                    break
                }
                case Platform.YouTube: {
                    dispatch(DeleteFromListYT(id))
                    break
                }
                case Platform.Twitch: {
                    dispatch(DeleteFromListTwitch(id))
                    break
                }
                case Platform.Vk: {
                    dispatch(DeleteFromListVk(id))
                    break
                }
                case Platform.Tg: {
                    dispatch(DeleteFromListTg(id))
                    break
                }
                case 'outreach': {
                    dispatch(removeFromSelectedCampaigns(id))
                    break
                }
                case 'lists': {
                    dispatch(DeleteFromListsAud(parseInt(id)))
                    break
                }
                case 'settings-accounts': {
                    dispatch(removeFromSelectedAccounts(id))
                    break
                }
                case 'media-planner-plans': {
                    dispatch(DeleteFromListMediaPlannerPlans(id))
                    break
                }
                case 'media-planner-bloggers': {
                    dispatch(DeleteFromListMediaPlannerBloggers(id))
                    break
                }
            }
        }
    }

    const handleClick = (e: boolean) => {
        if (disabled) return
        if (e) index && dispatch(SetLastClicked(index))

        if (e && LastClicked) {
            addWithShift()
            return
        }
        addToList()
    }

    return (
        <>
            <button
                className={cn[`root${ShiftDown ? '--highlight' : ''}`]}
                onClick={(event) => handleClick(event.shiftKey)}
                data-selector="mass-add"
            >
                <Checkbox value={checkValue ? 'checked' : 'empty'} disabled={disabled} />
            </button>
        </>
    )
}
