import React, { FC, useMemo } from 'react'

import desktopConfig from '../data.config'
import mobileConfig from '../data.config.mobile'
import tabletConfig from '../data.config.tablet'
import { IRefs, NativeReportData, IReportReadyStatus } from '../Types'
import { Row } from '../Blocks'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'

export interface IReportRows {
    data: NativeReportData
    xs: boolean
    refs: IRefs
    reportReadyStatus?: IReportReadyStatus
}

const ReportRows: FC<IReportRows> = ({ data, refs, reportReadyStatus }) => {
    const size = useWindowWidth()
    const rows = useMemo(() => {
        if (size === 'mobile') return mobileConfig(data, reportReadyStatus)
        if (size === 'tablet') return tabletConfig(data)
        return desktopConfig(data, refs, reportReadyStatus)
    }, [size, data, refs, reportReadyStatus])

    return (
        <>
            {(rows as any[]).map((item, index) => (
                <Row key={index} item={item} index={index} />
            ))}
        </>
    )
}

export default ReportRows
