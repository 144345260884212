import React, { FC } from 'react'

import { useSpaceTranslation } from '@/_helpers'
import Flex from '@/_yc/Flex'

import cn from '../DetailedInfo.module.scss'

export type ISwitcherType = 1 | 2 | 3 | undefined

interface ISwitcher {
    active: ISwitcherType
    setActive: Function
    resetStateCounter: Function
}

export const Switcher: FC<ISwitcher> = ({ active, setActive, resetStateCounter }) => {
    const t = useSpaceTranslation('detailed_info.account_type')

    const handleClick = (type: ISwitcherType) => {
        setActive(type)
        resetStateCounter(0)
    }
    return (
        <>
            <Flex column>
                <p className={cn.label} style={{ marginBottom: '0' }}>
                    {t('label')}
                </p>

                <Flex wrap content={'space-between'}>
                    <Flex
                        className={cn[`switch--circle${active === 1 ? '-active' : ''}`]}
                        onClick={() => handleClick(1)}
                        margin="6px 10px 0 0"
                    >
                        <p className={cn['switch--circle--text']}>{t('brand')}</p>
                    </Flex>

                    <Flex
                        className={cn[`switch--circle${active === 2 ? '-active' : ''}`]}
                        onClick={() => handleClick(2)}
                        margin="6px 10px 0 0"
                    >
                        <p className={cn['switch--circle--text']}>{t('agency')}</p>
                    </Flex>

                    <Flex
                        className={cn[`switch--circle${active === 3 ? '-active' : ''}`]}
                        onClick={() => handleClick(3)}
                        margin="6px 0 0 0"
                    >
                        <p className={cn['switch--circle--text']}>{t('influencer')}</p>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}
