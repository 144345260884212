import React from 'react'

import Icons from '@/components/Icons'
import { SelectorFilter } from '../../../../filters/SelectorFilter'
import { PLATFORM } from '../../consts'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { getTwitchGamesQuery, TwitchGameCategoryResponse } from '../../utils/queries'
import { transformTwitchGames } from '../../utils/transform'
import { toQueryWithOR } from '../../../youtube/utils/queries/toQueryWithOR'

const getGqlFilter = (topics: string[]) => {
    if (!topics || topics.length === 0) return ''
    const ids = topics.map((v) => v.split(',')).flat()

    const filterName = 'user_games'
    const filterId = 'game_id'
    return toQueryWithOR(ids, filterName, filterId)
}

export const getGamesFilter = (fetcher: Fetcher) => {
    return new SelectorFilter({
        platform: PLATFORM,
        title: 'filters.games.label',
        autocomplete: true,
        helper: 'filters.games.text',
        icon: <Icons name="filter--interests--main" />,
        space: 1,
        id: 'interests_yt',
        isMulti: true,
        options: (token: string, locale: string, query?: string) => {
            const client = fetcher.getClient()
            return client
                .query<TwitchGameCategoryResponse>({ query: getTwitchGamesQuery({ limit: 30, search: query || '' }) })
                .then((res) => {
                    const games = res.data?.search_twitch_by_game_category
                    return transformTwitchGames(games)
                })
        },
        beforeSend: ({ value }) => ({
            name: 'interests',
            type: 'array',
            value: value,
            gqlFilter: getGqlFilter(value),
        }),
    })
}
