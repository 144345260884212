import React from 'react';

import Location from './Location.svg'
import Mail from './Mail.svg'
import Mobile from './Mobile.svg'
import PPL from './PPl'
import Price from './Price'

export default ({ name, ...props }) => {
	switch (name) {
		case "location": return <img {...props} src = {Location} alt={<div></div>}/>
		case "email": return <img src = {Mail} alt={<div></div>}/>
		case "phone": return <img src={Mobile} alt={<div></div>} />
		case "reach": return <PPL {...props}/>
		case "money": return <Price {...props} />
		default: return null
	}
}