import { SearchFilter } from '@/pages/SearchPage/SearchAdapter/filters/SearchFilter'

const getGqlFilter = (name: string) => {
    return `username: {contains: "${name}"}`
}

export const getUsernameFilter = () => {
    return new SearchFilter({
        platform: 'ads_ig',
        title: 'ads_explorer.filters.post_metrics.username.label',
        helper: 'ads_explorer.filters.post_metrics.username.text',
        space: 1,
        placeholder: 'ads_explorer.filters.post_metrics.username.label',
        beforeSend: (data) => {
            return {
                name: 'metrics.comments',
                type: 'range',
                value: data,
                gqlFilter: getGqlFilter(data),
            }
        },
    })
}
