import React, { FC } from 'react'

import Text from '@/components/Text'
import Flex from '@/_yc/Flex'
import cn from './GeoInfo.module.scss'
import { Icons } from '@/_yc/Icons'

export interface IGeoInfo {
    country: string
}

const GeoInfo: FC<IGeoInfo> = ({ country }) => {
    if (!country || country.length === 0) {
        return null
    }
    return (
        <Flex content="left" className={cn.GeoInfo}>
            <Icons name={'location'} context="report" className={cn['GeoInfo--icon']} />
            <Text semibold className={cn['GeoInfo--country']} color="gray-4">
                {country}
            </Text>
        </Flex>
    )
}

export default GeoInfo
