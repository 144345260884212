import React, { FC } from 'react'

import cn from './VideoStats.module.scss'

import { Flex } from '@/_yc'
import Text from '@/components/Text'
import Icons from '@/components/Icons'

export interface IVideoStats {
    views: string
    date: string
}

const VideoStats: FC<IVideoStats> = ({ views, date }) => {
    return (
        <Flex content="space-between" align="center" className={cn.VideoStats}>
            <Flex align="center">
                <Icons name="views" />
                <Text className={cn['VideoStats--views']}>{views}</Text>
            </Flex>

            <Text color="gray-3" fSize={12}>
                {date}
            </Text>
        </Flex>
    )
}

export default VideoStats
