import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import GlobalWidth from '@/_yc/GlobalWidth'

import Text from '@/components/Text'
import cn from './Header.module.scss'
import { OUTREACH } from '../../../routs'
import Flex from '@/_yc/Flex'
import Arrow from '@/components/Share/BackButton/Arrow'
import PageTitle from '@/components/PageTitle'
import Button from '@/components/Button'

export const Header = () => {
    const t = useSpaceTranslation('outreach.header')

    const { pathname } = useLocation()

    return (
        <GlobalWidth>
            <div className={cn.header}>
                <PageTitle title={t('title')} linkToHelper={''} icon="outreach" marginBottom="0" iconSize="25px">
                    {pathname !== '/outreach' && (
                        <Link to={OUTREACH}>
                            <Flex grow align="center" content="flex-start">
                                <Arrow className={cn.arrow} />
                                <Text margin={'0 0 0 10px'} color="gray-3">
                                    {t('go_back')}
                                </Text>
                            </Flex>
                        </Link>
                    )}
                </PageTitle>
                {pathname === '/outreach' && (
                    <Link to={`${OUTREACH}/templates`}>
                        <Button variant="primary">{t('create_campaign')}</Button>
                    </Link>
                )}
            </div>
        </GlobalWidth>
    )
}
