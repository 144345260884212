import React, { FC, ReactNode } from 'react'

import cn from './MultiColorIcon.module.scss'

export enum IconColor {
    orange = 'orange',
    red = 'red',
    grey = 'grey',
    blue = 'blue',
    green = 'green',
    violet = 'violet',
}

export const colors: IconColor[] = Object.values(IconColor)

interface IIcon {
    children: ReactNode
    color: IconColor
}
const MultiColorIcon: FC<IIcon> = (props) => {
    return (
        <>
            <div className={cn[`icon--${props.color}`]}>{props.children}</div>
        </>
    )
}
export default MultiColorIcon
