import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { createSelector } from 'reselect'

import {
    combineHOC,
    withDownload,
    FileDownloader,
    selectLocker,
} from '@/_helpers/'
import service from './_services'
import DB from './DownloadButton'

const Wrapper = ({ location, download, ...props }) => (
    <DB {...props} pathname={location.pathname} onClick={download} />
)

const mapStateToProps = createSelector(
    (state) => state.AudienceInsights.ActiveListId,
    selectLocker('csv'),
    (setId, locked) => ({ setId, locked })
)

export default combineHOC(
    withRouter,
    withDownload(service.downloadList, (blob) =>
        FileDownloader(blob, 'list', 'xlsx')
    ),
    connect(mapStateToProps),
    withTranslation()
)(Wrapper)
