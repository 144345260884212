import React, {
    useEffect,
    // useMemo,
    useRef,
} from 'react'
import PageLayout from '@/Layouts/PageLayout'
import { useTranslation } from 'react-i18next'

import { Flex, GlobalWidth } from '@/_yc'
import Header from '@/Layouts/ReportLayout/Header/Header'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Text from '@/components/Text'
import Arrow from '@/components/Share/BackButton/Arrow'
import PartChanger from '@/Layouts/ReportLayout/Extra/PartChanger'
import { RootStore } from '@/_helpers/StoreType'
import { useQuery } from '@apollo/client'
import useChannelId from '@/_helpers/useChannelId'
import { push } from '@/components/Notifier/PushNotification'
import LegacyNotification from '@/components/Notifier/MessageTypes/LegacyNotification'
import { setVkRawData } from '@/Layouts/ReportLayout/Model/actions'
import Segment from '@/Layouts/ReportLayout/Content/Segment'
// import { ContextMenu } from '@/_yc'

import { ReportHeader } from './ReportHeader/ReportHeader'
import { isNotEnoughReportsError } from '../OpenReportPage/utils'
import { GET_VK_REPORT } from './queries'
import { Platform } from '../types'
import { VkReportResponseData } from './types'
import GrowGraph from './GrowGraph/GrowGraph'
import Demographics from './Demographics'
import Activity from './Activity'
import Posts from './Posts'
import BrandMentions from './BrandMentions/ ListAccountsUI'

import cn from './VkReport.module.scss'

export const VkReport = () => {
    const { t } = useTranslation()
    const graphRef = useRef(null)
    const audienceRef = useRef(null)
    const activityRef = useRef(null)
    const brandRef = useRef(null)
    const postsRef = useRef(null)

    const channelId = useChannelId(4)
    const type = useChannelId(3)

    const history = useHistory()

    const dispatch = useDispatch()

    const vkDataStore = useSelector((store: RootStore) => {
        return store.report.rawVkData
    })

    const {
        loading: loadingVK,
        data: dataVk,
        error: errorVk,
    } = useQuery<VkReportResponseData>(GET_VK_REPORT, {
        variables: {
            id: channelId,
            type: type,
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        skip: vkDataStore?.getVkReport !== undefined,
    })

    useEffect(() => {
        if (loadingVK || errorVk || !dataVk || vkDataStore?.getVkReport) {
            return
        }
        dispatch(setVkRawData(dataVk))
    }, [vkDataStore, dataVk, errorVk, loadingVK, dispatch])

    useEffect(() => {
        const isNotEnoughReports = errorVk && isNotEnoughReportsError(errorVk)
        if (isNotEnoughReports) {
            push(new LegacyNotification(t('not_enough_reports'), 'error'))
            history.push('/billing')
        }
    }, [errorVk, t, history])

    // const twitchData = useMemo(() => {
    //     if (twitchDataStore?.getTwitchUser?.data.twitch_userinfo[0]) {
    //         return twitchDataStore?.getTwitchUser?.data.twitch_userinfo[0]
    //     }
    //     return null
    // }, [twitchDataStore])

    return (
        <PageLayout color={'common-background'} className={cn.root} title={t('titles.t2')}>
            <GlobalWidth style={{ padding: '26px 50px' }}>
                <Header>
                    <Flex content="space-between" style={{ width: '100%' }}>
                        <Link to={`/search/${Platform.Vk}`}>
                            <Flex grow align="center" content="space-between">
                                <Arrow className={cn.arrow} />
                                <Text margin={'0 0 0 10px'} color="gray-3">
                                    {t('report_layout.go_back')}
                                </Text>
                            </Flex>
                        </Link>
                        {/* <ContextMenu
                            t={t}
                            reportButton
                            margin={'0 20px 0 0'}
                            username={twitchData?.login}
                            style={{ width: 'auto' }}
                            id={twitchData?.id}
                            type={Platform.Twitch}
                        /> */}
                    </Flex>
                </Header>

                <div className={cn.main}>
                    <ReportHeader id={channelId} type={type} />

                    <PartChanger
                        values={[
                            {
                                title: t('twitch_report.account_info.title'),
                                value: graphRef,
                            },
                            {
                                title: t('report_titles.activity.title'),
                                value: activityRef,
                            },
                            {
                                title: t('report_titles.auditory.title'),
                                value: audienceRef,
                            },
                            {
                                title: t('report_layout.videos.title'),
                                value: postsRef,
                            },
                            {
                                title: t('report_layout.brand_mentions.title'),
                                value: brandRef,
                            },
                            // {
                            //     title: t('report_layout.videos.title'),
                            //     value: 'null',
                            // },
                        ]}
                    />
                </div>

                <Segment ref={graphRef} title={t('report_titles.account_data.title')}>
                    <GrowGraph />
                </Segment>

                <Segment className={cn.segment} ref={activityRef} title={t('report_titles.activity.title')}>
                    <Activity />
                </Segment>

                <Segment className={cn.segment} ref={audienceRef} title={t('report_titles.auditory.title')}>
                    <Demographics />
                </Segment>

                <Segment className={cn.segment} ref={brandRef} title={t('report_titles.brandAffinity.title')}>
                    <BrandMentions />
                </Segment>

                <Segment className={cn.segment} ref={postsRef} title={t('report_titles.publications.title')}>
                    <Posts />
                </Segment>
            </GlobalWidth>
        </PageLayout>
    )
}
