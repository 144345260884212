import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
                x="1"
                y="1"
                width="18"
                height="18"
                rx="9"
                fill="url(#paint0_linear_2482_38815)"
                stroke="white"
                strokeWidth="2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.9999 13.5999C11.9881 13.5999 13.5999 11.9881 13.5999 9.9999C13.5999 8.01168 11.9881 6.3999 9.9999 6.3999C8.01168 6.3999 6.3999 8.01168 6.3999 9.9999C6.3999 11.9881 8.01168 13.5999 9.9999 13.5999ZM9.9999 12.3999C11.3254 12.3999 12.3999 11.3254 12.3999 9.9999C12.3999 8.67442 11.3254 7.5999 9.9999 7.5999C8.67442 7.5999 7.5999 8.67442 7.5999 9.9999C7.5999 11.3254 8.67442 12.3999 9.9999 12.3999Z"
                fill="white"
            />
            <ellipse cx="13.9" cy="6.1002" rx="0.9" ry="0.9" fill="white" />
            <defs>
                <linearGradient
                    id="paint0_linear_2482_38815"
                    x1="10"
                    y1="2"
                    x2="10"
                    y2="18"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ED5ADE" />
                    <stop offset="1" stopColor="#895AED" />
                </linearGradient>
            </defs>
        </svg>
    )
}
