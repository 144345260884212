import React, { FC, useState } from 'react'
import cn from './Avatar.module.scss'
import InstaIcon from './InstaIcon'
import YTIcon from './YTIcon'
import LoadingAvatar from '@/components/LoadingAvatar/LoadingAvatar'
import { Platform } from '@/pages/Reports/types'
import TwitchIcon from './TwitchIcon'
import VkIcon from './VkIcon'
import { TgIcon } from './TgIcon'
import { TikTokIcon } from './TikTokIcon'

export interface IAvatar {
    url: string
    type: Platform
}

const Avatar: FC<IAvatar> = ({ type = Platform.Instagram, ...props }) => {
    const [loaded, setLoaded] = useState(false)

    return (
        <div>
            {type === Platform.YouTube && <YTIcon className={cn.icon} />}
            {type === Platform.Instagram && <InstaIcon className={cn.icon} />}

            <div style={{ position: 'relative' }}>
                {type === Platform.Twitch && <TwitchIcon className={cn['icon--twitch']} />}
                {type === Platform.Vk && <VkIcon className={cn['icon--vk']} />}
                {type === Platform.Tg && <TgIcon className={cn['icon--tg']} />}
                {type === Platform.TikTok && <TikTokIcon className={cn['icon--tiktok']} />}

                {!loaded && (
                    <div className={cn[`main--${type}`]}>
                        <LoadingAvatar width="82" style={{ zIndex: 1 }} />
                    </div>
                )}
                <img
                    alt={'avatar'}
                    className={cn[`main--${type}`]}
                    style={!loaded ? { display: 'none' } : {}}
                    src={props.url}
                    onLoad={() => setLoaded(true)}
                />
            </div>
        </div>
    )
}

export default Avatar
