import Service from '@/_helpers/Service'

const MassDelete = new Service({
    method: 'DELETE',
    url: '',
})
export const MassDeleteReq = (token: string, listId: number, list: number[]) =>
    MassDelete.call(
        {
            url: `/api/sets/blogger/${listId}/mass/`,
            data: {
                search_items_ids: list,
            },
        },
        token
    )
