import React from 'react';

function createConnector() {
	const subscriptions = new Set();
	const subscribe = (cb) => {
		subscriptions.add(cb)
	}
	const run = () => {
		subscriptions.forEach(cb => cb())
	}
	const clear = () => {
		subscriptions.clear();
	}
	return Component => props => <Component run={run} clear={clear} subscribe={subscribe} {...props}/>
}

export const connector = createConnector();

export { createConnector }