import React, { useContext, useEffect, useMemo } from 'react'

import { Header } from './Header/Header'
import { Row } from './Row/Row'
import cn from './Table.module.scss'
import { EmptyState } from './EmptyState/EmptyState'
import { CampaignManagerContext } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerContext'
import { useQuery } from '@apollo/client'
import { GET_MEDIA_PLANS_WITH_BLOGGERS_LIST } from '../utils/queries'
import { MediaPlansListRaw } from '../utils/types'
import { preprocessMediaPlans } from '../utils/preprocess'
import LoadingFiller from '@/pages/Lists/components/LoadingFiller'
import { useDispatch } from 'react-redux'
import { SetAllMediaPlans, SetDefault } from '@/components/MassAddToList/_model/actions'

export const PlansTable = () => {
    const context = useContext(CampaignManagerContext)
    const { data, loading, error, refetch } = useQuery<MediaPlansListRaw>(GET_MEDIA_PLANS_WITH_BLOGGERS_LIST, {
        context: context,
    })
    const dispatch = useDispatch()

    useEffect(() => {
        if (!error && !loading && data && data.mediaPlanOrder) {
            dispatch(SetAllMediaPlans(data.mediaPlanOrder.map((item) => item.id.toString())))
        }
    }, [dispatch, loading, error, data])
    useEffect(() => {
        return () => {
            dispatch(SetDefault())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const mediaPlans = useMemo(() => {
        if (!data || loading || error) return []

        if (!data.mediaPlanOrder) return []

        return preprocessMediaPlans(data.mediaPlanOrder)
    }, [data, loading, error])

    return (
        <div className={cn.table}>
            <Header refetch={refetch} />
            <div className={cn.rows}>
                {mediaPlans.length === 0 && !loading && <EmptyState />}

                {mediaPlans.map((item, i) => (
                    <Row key={item.id} data={item} index={i} />
                ))}

                {loading && <LoadingFiller grid="3% 29% 68%" />}
            </div>
        </div>
    )
}
