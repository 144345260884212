import React, { useEffect, useState } from 'react'
import PageLayout from '@/Layouts/PageLayout'
import { useTranslation } from 'react-i18next'
import { Header } from '../Header/Header'
import { CreateTemplate } from './CreateTemplate/CreateTemplate'
import { TemplateList } from './TemplateList/TemplateList'

import cn from './Templates.module.scss'
import GlobalWidth from '@/_yc/GlobalWidth'
import useToken from '@/_helpers/Hooks/useToken'
import { useDispatch } from 'react-redux'
import { updateTemplates } from '../Model/actions'
import { InfluencersList } from './InfluencersList/InfluencersList'
import { UpgradeTariff } from './UpgradeTariff/UpgradeTariff'
import { getTemplatesReq } from '../Service/Templates'

export const Templates = () => {
    const { t } = useTranslation()
    const token = useToken()
    const dispatch = useDispatch()

    const [showUpgradeTariff, setShowUpgradeTariff] = useState(false)

    useEffect(() => {
        if (!token) return

        getTemplatesReq(token).then((res) => dispatch(updateTemplates(res)))
    }, [dispatch, token])

    return (
        <PageLayout title={t('titles.t6')} color="common-background">
            <Header />

            <GlobalWidth>
                <div className={cn.templates}>
                    {showUpgradeTariff && <UpgradeTariff />}

                    <div className={cn.content}>
                        <CreateTemplate setShowUpgradeTariff={setShowUpgradeTariff} />
                        <div>
                            <TemplateList />
                            <InfluencersList />
                        </div>
                    </div>
                </div>
            </GlobalWidth>
        </PageLayout>
    )
}
