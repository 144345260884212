import { FilterCategory } from '../../../filters/FilterCategory'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { getInterestsFilter } from './General/interests'
import { getCountryFilter } from './General/countries'
import { getLanguagesFilter } from './General/langauges'
import { getFollowersFilter } from './ChannelMetrics/followers'
import { getVideoCountFilter } from './ChannelMetrics/videoCount'
import { getSubGainFilter } from './ChannelMetrics/subGain'
import { getAvgViewsFilter } from './ContentMetrics/avgViews'
import { getReactionsFilter } from './ContentMetrics/reactions'
import { getCommentsFilter } from './ContentMetrics/comments'
import { getERFilter } from './ContentMetrics/er'
import { getBrandMentions } from './ContentMetrics/brandMentions'
import { getAudCountryFilter } from './Audience/country'
import { getAudLanguageFilter } from './Audience/language'
import { getLastVideoFilter } from './ChannelMetrics/lastVideo'
import { getAudGenderFilter } from './Audience/gender'
import { getAudAgeFilter } from './Audience/age'
import { getKeywordsFilter } from './General/keywords'
import { getContactsFilter } from './General/contacts'

export const getFilters = (fetcher: Fetcher) => {
    return {
        channel_metrics: new FilterCategory('filters.categories.channel', {
            followers: getFollowersFilter(),
            videos_count: getVideoCountFilter(),
            subscribersGain: getSubGainFilter(),
            lastVideo: getLastVideoFilter(),
        }),
        infl: new FilterCategory('filters.categories.blogger', {
            interests: getInterestsFilter(fetcher),
            country: getCountryFilter(fetcher),
            language: getLanguagesFilter(fetcher),
            contacts: getContactsFilter(fetcher),
        }),
        content_metrics: new FilterCategory('filters.categories.content', {
            avg_views: getAvgViewsFilter(),
            reactions: getReactionsFilter(),
            comments: getCommentsFilter(),
            brandMentions: getBrandMentions(fetcher),
            er: getERFilter(),
        }),
        audience_metrics: new FilterCategory('filters.categories.audience', {
            country: getAudCountryFilter(fetcher),
            language: getAudLanguageFilter(fetcher),
            gender: getAudGenderFilter(),
            age: getAudAgeFilter(),
        }),
    }
}

export const getMainFilters = (fetcher: Fetcher) => {
    return {
        main: new FilterCategory('filters.categories.main', {
            interests: getInterestsFilter(fetcher, true),
            country: getCountryFilter(fetcher, true),
            followers: getFollowersFilter(true),
            keywords: getKeywordsFilter(),
        }),
    }
}
