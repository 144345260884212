import React, { useCallback } from 'react'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { useHistory, useLocation } from 'react-router-dom'
import { LISTS } from '@/routs'
// import { useDispatch } from 'react-redux'
// import { audience_actions } from '@/pages/OLDAudienceInsides/Model/_actions'

const rootStyle = { fontSize: 18, lineHeight: '23px', color: '#2a2a31' }
const color = { color: '#6172FF', cursor: 'pointer' }

const BloggerAddedIn = ({ username, lists }) => {
    const t = useSpaceTranslation('context_menu.alerts')

    const history = useHistory()
    const location = useLocation()
    // const dispatch = useDispatch()

    const click = useCallback(
        (id) => {
            if (location.pathname !== LISTS) {
                history.push(LISTS, { listId: id })
            }
            // else {
            //     dispatch(audience_actions.switchList(id))
            // }
        },
        [history, location]
    )

    return (
        <div style={rootStyle}>
            {t('success.blogger')} <span style={color}>{username}</span>{' '}
            {t('success.addedTo')}{' '}
            {lists.map((list, i) => (
                <>
                    <span onClick={() => click(list.id)} style={color} key={i}>
                        {list.name}
                    </span>
                    {i !== lists.length - 1 ? ', ' : ''}
                </>
            ))}
        </div>
    )
}

export { BloggerAddedIn as default }
