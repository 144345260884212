import React, { Fragment, createRef } from 'react'

import CloseIcon from '@material-ui/icons/Close'

import { Icons, Button } from '../../../_yc/'

import cn from './Popper.module.scss'
import Scrollbars from 'react-custom-scrollbars'
import Loader from '@/components/Loader'

const ButtonStyle = {
    width: '100%',
    padding: '6px 0',
    marginTop: 10,
    textAlign: 'center',
}

const LbStyle = {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
}

const spanColor = { color: '#2a2a31' }

const opacity1 = { opacity: 1 }
const opacity0 = { opacity: 0 }

export default class Popper extends React.PureComponent {
    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.lists.checked) return prevState
        return { value: '', writeMode: false }
    }
    constructor(props) {
        super(props)
        this.state = {
            value: '',
            mode: 'default',
        }
        this.input = createRef()
    }
    componentDidMount() {
        this.props.clearStore()
        this.props.getLists(1)
    }
    componentWillUnmount() {
        this.props.clearStore()
    }
    changeCheck(index) {
        this.props.checkItem(index)
        this.props.onChange()
    }
    handleAdding(e) {
        if (!this.state.value) return
        if (e.key === 'Enter') {
            this.props.addList(this.state.value)
            this.setState({ mode: 'default', value: '' })
        }
    }
    handleInput(e) {
        this.setState({
            value: e.target.value.substring(0, 15),
        })
    }
    changeMode(mode) {
        this.setState({ mode }, () => {
            if (mode === 'adding') {
                this.input.current.focus()
            }
        })
    }
    render() {
        const { t, lists } = this.props
        return (
            <div className={cn.root}>
                <div className={cn.header}>
                    <div className={cn.header_label}>
                        {t('context_menu.label')}
                    </div>
                </div>
                <div className={cn.popper_lists}>
                    {!lists.isFinished && (
                        <div style={LbStyle}>
                            <Loader />
                        </div>
                    )}
                    {lists.isFinished && !lists.error && (
                        <Fragment>
                            <Scrollbars autoHeight autoHeightMax={'250px'}>
                                {lists.items.map((item, i) => (
                                    <div
                                        key={i}
                                        className={cn.context_item}
                                        onClick={(e) => this.changeCheck(i)}
                                    >
                                        <span style={spanColor}>
                                            {item.name}
                                        </span>
                                        <Icons
                                            name="check"
                                            className={cn.context_item_icon}
                                            style={
                                                item.checked
                                                    ? opacity1
                                                    : opacity0
                                            }
                                        />
                                    </div>
                                ))}
                            </Scrollbars>

                            {this.state.mode !== 'adding' ? (
                                <span
                                    onClick={(e) => this.changeMode('adding')}
                                    className={cn['add-link']}
                                >
                                    {t('context_menu.newset')}
                                </span>
                            ) : (
                                <Fragment>
                                    <div className={cn['writer']}>
                                        <input
                                            ref={this.input}
                                            placeholder={t(
                                                'context_menu.placeholder'
                                            )}
                                            value={this.state.value}
                                            onChange={(e) =>
                                                this.handleInput(e)
                                            }
                                            onKeyPress={(e) =>
                                                this.handleAdding(e)
                                            }
                                        />
                                        <CloseIcon
                                            className={cn['icon']}
                                            onClick={(e) =>
                                                this.setState({
                                                    value: '',
                                                    mode: 'default',
                                                })
                                            }
                                        />
                                    </div>
                                    <Button
                                        onClick={() =>
                                            this.handleAdding({ key: 'Enter' })
                                        }
                                        disabled={this.state.value.length < 1}
                                        style={ButtonStyle}
                                    >
                                        {t('aud_insides.lists.saveList')}
                                    </Button>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                    {lists.error && <div>{t('common_error')}</div>}
                </div>
            </div>
        )
    }
}
