import React, { FC } from 'react'
import Flex from '@/_yc/Flex'
import { useTranslation } from 'react-i18next'
import Text from '@/components/Text'
import { RootStore } from '@/_helpers/StoreType'
import { useSelector } from 'react-redux'
import { Soon } from '@/components/Soon'
import HiddenMetric from '@/components/HiddenMetric'

interface IInfo {
    posts?: number
    reactions?: number
    comments?: number
    reposts?: number
}
export const AccountInfo: FC<IInfo> = (props) => {
    const { t } = useTranslation()

    const data = useSelector((store: RootStore) => {
        const userInfo = store.report.rawTgData?.getTelegraReport?.data.telegram_channel

        if (!userInfo || userInfo.length === 0) {
            return [
                {
                    title: t('report_layout.account_info.posts_day'),
                    value: null,
                },
                {
                    title: t('report_layout.account_info.reactions'),
                    value: null,
                },
                {
                    title: t('report_layout.account_info.comments'),
                    value: null,
                },
                {
                    title: t('report_layout.account_info.reposts'),
                    value: null,
                },
            ]
        }

        return [
            {
                title: t('report_layout.account_info.posts_day'),
                value: null,
            },
            {
                title: t('report_layout.account_info.reactions'),
                value: userInfo[0].median_reactions_m,
            },
            {
                title: t('report_layout.account_info.comments'),
                value: userInfo[0].median_comments_m,
            },
            {
                title: t('report_layout.account_info.reposts'),
                value: null,
            },
        ]
    })

    return (
        <>
            <Flex
                style={{ background: '#f9f9f9', borderRadius: '10px', padding: '20px 80px' }}
                align="center"
                margin="20px 0 0 0"
                content="space-between"
            >
                {data.map((item) => (
                    <Flex column>
                        {item.value !== undefined && item.value !== null ? (
                            <>
                                {item.value === 0 ? (
                                    <>
                                        <HiddenMetric />
                                    </>
                                ) : (
                                    <Text>~{item.value}</Text>
                                )}
                            </>
                        ) : (
                            <Soon />
                            // <div
                            //     style={{
                            //         width: '80px',
                            //         height: '16px',
                            //         background: '#cccccc',
                            //         borderRadius: '10px',
                            //         margin: '3px 0 5px 0',
                            //     }}
                            // />
                        )}
                        <Text color="gray-3" fSize="12px" margin="0 0 0 0">
                            {item.title}
                        </Text>
                    </Flex>
                ))}
            </Flex>
        </>
    )
}
