import React from 'react'
import cn from './header.module.scss'
import Stats from '../Stats'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import HandIcon from '../../icons/hand.jpg'
import { Helper } from '@/components/Helper/Helper'
import { useSelector } from 'react-redux'

type Tariff = {
    remain_reports: number
    remain_intersections: number
    remain_chats: number
}

interface HeaderProps {
    title: string
    subtitle: string
    tariff: Tariff
}

const Header: React.FC<HeaderProps> = ({ title, subtitle, tariff }) => {
    const linkToHelper = useSelector<any, string>((state) => state.Commercial_.value.hp_cmanager)

    const formatNumber = (num: number): string => {
        if (num >= 1_000_000) return `${(num / 1_000_000).toFixed(1)}M`
        if (num >= 1_000) return `${(num / 1_000).toFixed(1)}K`
        return num.toString()
    }
    return (
        <Flex className={cn['header']} content="space-between" align="start">
            <Flex className={cn['img-wrapper']}>
                <img className={cn['image']} src={HandIcon} alt="hand" />
            </Flex>
            <Flex column style={{ maxWidth: '582px' }}>
                <Text className={cn['title']}>{title}</Text>
                <Text className={cn['sub-title']}>{subtitle}</Text>
                <Flex className={cn['helper']}>
                    <Helper link={linkToHelper} />
                </Flex>
            </Flex>
            <Flex>
                <Stats
                    stats={[
                        {
                            label: 'Reports',
                            value: tariff.remain_reports ? `+${tariff.remain_reports}` : 0,
                        },
                        {
                            label: 'Followers',
                            value: tariff.remain_intersections
                                ? `+${formatNumber(tariff.remain_intersections)} followers`
                                : 0,
                        },
                        {
                            label: 'Chats',
                            value: tariff.remain_chats ? `+${tariff.remain_chats}` : 0,
                        },
                    ]}
                />
            </Flex>
        </Flex>
    )
}

export default Header
