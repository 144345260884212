import React, { useRef, FC } from 'react'

import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import useOpenClose from '@/_helpers/useOpenClose'
import Text from '@/components/Text'
import Popup from '@/_yc/Popup/Popup'
import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'

import { $getSelection } from 'lexical'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

import cn from './VariableChooser.module.scss'

export type variableType = '<full_name_influencer>' | '<full_name_sender>'

interface IVariableChooser {}
const VariableChooser: FC<IVariableChooser> = () => {
    const t = useSpaceTranslation('outreach.templates.create.var')
    const ref = useRef(null)
    const [isOpen, open, close] = useOpenClose()

    const [editor] = useLexicalComposerContext()

    const handleClick = (varTemp: variableType) => {
        editor.update(() => {
            const selection = $getSelection()
            if (selection) {
                selection.insertText(varTemp)
            }
        })
    }

    return (
        <>
            <Flex className={cn.var} ref={ref} onClick={open}>
                <Icons name="code" />

                <Text className={cn['var--text']}>{t('var')}</Text>
            </Flex>

            {isOpen && ref && ref.current && (
                <>
                    <Popup placement="bottom-end" isOpen={isOpen} onClose={close} anchor={ref.current}>
                        <Flex className={cn.popup} column>
                            <Flex align="center" onClick={() => handleClick('<full_name_influencer>')}>
                                <p>{t('full_name')}</p>
                            </Flex>
                            <Flex align="center" onClick={() => handleClick('<full_name_sender>')}>
                                <p>{t('user_name')}</p>
                            </Flex>
                        </Flex>
                    </Popup>
                </>
            )}
        </>
    )
}

export default VariableChooser
