import React, { FC, useState } from 'react'
import { Tooltip } from '@yoloker/ui-collection'
import Flex from '@/_yc/Flex'
import { IPostTypeAggregation } from '../../Types'
import Button from '@/components/Button'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import { shortNum } from '@/_helpers/_graphs/_tech'

import cn from './PostType.module.scss'

interface IPostTypes {
    title: string
    data: IPostTypeAggregation
    width: string | number
    hidden?: boolean
}
const PostType: FC<IPostTypes> = ({ data, hidden, title, width }) => {
    const t = useSpaceTranslation(`report_titles.${title}`)
    const size = useWindowWidth('mobile')
    const [option, setOptions] = useState<'30' | '90'>('30')

    const getImage = () => {
        return data[option].GraphImage ? data[option].GraphImage : 0
    }
    const getVideo = () => {
        return data[option].GraphVideo ? data[option].GraphVideo : 0
    }

    const getWidth = () => {
        const w = (getImage() * 100) / (getImage() + getVideo())
        return w
    }
    const getButtonStyle = (opt: string) => {
        if (size === 'mobile') {
            return 'outline--mobileV2'
        }
        return option === opt ? 'primary' : 'outline'
    }

    return (
        <>
            <Flex className={cn.root} column>
                <Flex content="space-between" className={cn.head}>
                    <Flex align="center">
                        <p className={cn.title}>{t('title')}</p>
                        <Tooltip text={t('tooltip')} />
                    </Flex>
                    <Flex align="center" className={cn[`buttons${hidden ? '--blur' : ''}`]}>
                        <Button
                            onClick={() => setOptions('30')}
                            size="small"
                            active={option === '30'}
                            variant={getButtonStyle('30')}
                        >
                            30 {t('days')}
                        </Button>
                        <Button
                            onClick={() => setOptions('90')}
                            size="small"
                            margin="0 0 0 10px"
                            variant={getButtonStyle('90')}
                        >
                            90 {t('days')}
                        </Button>
                        {/* <Button size="small" margin="0 0 0 10px" variant="primary">
							{t('allTime')}
						</Button> */}
                    </Flex>
                </Flex>
                <Flex column className={hidden ? cn.blur : ''}>
                    <p className={cn.value}> {shortNum(getImage() + getVideo())}</p>
                    <p className={cn.helper}>{t('helper') + option + ' ' + t('days')}</p>
                    <div className={cn.bar}>
                        <div
                            className={cn['bar--filled']}
                            style={{
                                width: `${getWidth()}%`,
                                borderRadius: getVideo() === 0 ? '10px' : '10px 0 0 10px',
                            }}
                        />
                    </div>
                    <Flex className={cn.types}>
                        <Flex align="center">
                            <p className={cn.type}>{t('types.photo')}</p>
                            <Flex margin="0 0 0 10px" className={cn['card--first']} align="center" content="center">
                                <p className={cn['card--first--text']}>{shortNum(getImage())}</p>
                            </Flex>
                        </Flex>
                        <Flex align="center" margin="0 0 0 30px">
                            <p className={cn.type}>{t('types.viedo')}</p>
                            <Flex margin="0 0 0 10px" className={cn['card--second']} align="center" content="center">
                                <p className={cn['card--second--text']}>{shortNum(getVideo())}</p>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}
export default PostType
