import React, { FC, memo } from 'react'
import Text from '@/components/Text'

import cn from './SearchItem.module.scss'
import Icons from '@/components/Icons'
import Flex from '@/_yc/Flex'
import { getNumberWithSpaces } from '@/_helpers/nums/numberWithSpaces'
import { IAdVideo } from '../types'
import moment from 'moment'
import { getVideoLink } from '@/Layouts/ReportLayout/Content/Videos/utils'
import BloggerLink from '@/components/Share/BloggerLink'
import { PostBrands } from '../../PostBrands/PostBrands'
import { Platform } from '@/pages/Reports/types'
import { useTranslation } from 'react-i18next'
import Button from '@/components/Button'
import AddToList from '@/pages/Lists/components/AddToList/AddToList'
import { MassAddCheckBoxNEW } from '@/components/MassAddToList'
export interface ISearchItem extends IAdVideo {
    index: number
    margin?: string | number
    isList?: boolean
}

const SearchItem: FC<ISearchItem> = (props) => {
    const openVideo = () => {
        window.open(getVideoLink(props.id))
    }
    const { t } = useTranslation()

    if (props.isList) {
        return (
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '0.2fr 0.8fr 1.2fr 0.5fr 0.5fr 0.8fr',
                    padding: '22px 16px',
                    background: 'white',
                }}
            >
                <div>
                    <MassAddCheckBoxNEW
                        data={{
                            hasEmail: false,
                            avatar: '',
                            name: '',
                            username: '',
                        }}
                        id={props?.channelId ? props.channelId.toString() : ''}
                        externalId={props?.channelId ? props.channelId.toString() : ''}
                        type={Platform.YouTube}
                        key={props.id}
                        index={props.index}
                    />
                </div>

                <BloggerLink newTab id={props.channelId} platform={Platform.YouTube}>
                    <div className={cn['searchItem--info']}>
                        <img src={props.avatar} alt={'avatar'} width={'34px'} height={'34px'} />

                        <div className={cn['searchItem--info--blogger']}>
                            <Text color={'violet-1'} fSize={14} bold>
                                {props.channelTitle}
                            </Text>
                            <Text margin={'3px 0 0 0'} color={'gray-3'} fSize={12}>
                                {moment(props.publishedAt).format('DD.MM.YYYY')}
                            </Text>
                        </div>
                    </div>
                </BloggerLink>

                <Flex>
                    <img src={props.cover} alt={'cover'} onClick={openVideo} style={{ cursor: 'pointer' }} />
                </Flex>

                <Flex content={'flex-start'} align="flex-start">
                    <Flex align="center">
                        <Icons name={'likes'} />
                        <Text margin={'0 0 0 10px'}>{props.viewCount && getNumberWithSpaces(props.likeCount)}</Text>
                    </Flex>
                </Flex>

                <Flex content={'flex-start'} align="flex-start">
                    <Flex align="center">
                        <Icons name={'comments'} />
                        <Text margin={'0 0 0 10px'}>
                            {props.commentCount && getNumberWithSpaces(props.commentCount)}
                        </Text>
                    </Flex>
                </Flex>

                <Flex content="space-around">
                    <a href={`https://www.youtube.com/${props.channelTitle}`} target="_blank" rel="noreferrer noopener">
                        <Button variant="outline">{t('ads_explorer.open_profile')}</Button>
                    </a>

                    <AddToList
                        type={Platform.YouTube}
                        external_id={props?.channelId ? props.channelId.toString() : ''}
                        place="competitor_analysis"
                    />
                </Flex>
            </div>
        )
    }
    return (
        <div className={cn.searchItem}>
            <img src={props.cover} alt={'cover'} onClick={openVideo} style={{ cursor: 'pointer' }} />

            <BloggerLink newTab id={props.channelId} platform={Platform.YouTube}>
                <div className={cn['searchItem--info']}>
                    <img src={props.avatar} alt={'avatar'} width={'34px'} height={'34px'} />

                    <div className={cn['searchItem--info--blogger']}>
                        <Text color={'violet-1'} fSize={14} bold>
                            {props.channelTitle}
                        </Text>
                        <Text margin={'3px 0 0 0'} color={'gray-3'} fSize={12}>
                            {moment(props.publishedAt).format('DD.MM.YYYY')}
                        </Text>
                    </div>
                </div>
            </BloggerLink>

            <div style={{ margin: '10px 0 0 0' }}>
                <PostBrands brand={props.brand} />
            </div>

            <Text
                color={'gray-1'}
                fSize={14}
                margin={'12px 0 16px 0'}
                style={{ height: '60px', overflow: 'hidden' }}
                semibold
            >
                {props.title && props.title.slice(0, 80)}
            </Text>

            <Flex content={'space-between'}>
                <Flex content={'flex-start'} align={'center'}>
                    <Icons name={'views'} />
                    <Text margin={'0 0 0 10px'}>{props.viewCount && getNumberWithSpaces(props.viewCount)}</Text>
                </Flex>

                <Flex content={'flex-start'} align={'center'}>
                    <Icons name={'comments'} />
                    <Text margin={'0 0 0 10px'}>{props.commentCount && getNumberWithSpaces(props.commentCount)}</Text>
                </Flex>
            </Flex>
        </div>
    )
}

export default memo(SearchItem)
