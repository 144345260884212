import { gql } from '@apollo/client'

export interface TwitchGameCategory {
    box_art_url: string
    id: string
    igdb_id: string
    name: string
}

export interface TwitchGameCategoryResponse {
    search_twitch_by_game_category: TwitchGameCategory[]
}

export const getTwitchGamesQuery = ({ limit, search }: { limit: number; search: string }) => {
    return gql`
      query GetTwitchGames {
        search_twitch_by_game_category(args: {search: "${search}"}, limit: ${limit}) {
          box_art_url
          id
          igdb_id
          name
        }
      }
    `
}
