import React, { PureComponent } from 'react';
import pt from 'prop-types'

import { Portal } from '@/_helpers/'
import { SimpleInput, BloggerArea } from '@/_yc/'
import { parseName } from '@/_helpers/getNames'
import { NameError, NoInformation } from './Errors.js'

import cn from './CreationModal.module.scss'
import Heading from '@/components/Heading';
import Text from '@/components/Text';
import Button from '@/components/Button';
import LoadingButton from '@/components/Share/LoadingButton/LoadingButton'

export default class CreationModal extends PureComponent {

	static get propTypes(){
		return {
			onClose: pt.func.isRequired,
			t: pt.func.isRequired,
			onSuccess: pt.func.isRequired,
			initialBloggers: pt.arrayOf(pt.string.isRequired),
			initialName: pt.string
		}
	}

	state = {
		value: this.props.initialName ?? "",
		bloggers: this.props.initialBloggers?.map(item => {
			return {
				index: item,
				label: item,
				onClick: this.handleDelete.bind(this, item)
			}
		}) ?? [],
		errMessage: null
	}

	mark = Math.random() + "ASDASD"
	list = new Set();//the list of saved usernames

	handleClose = this.handleClose.bind(this)
	handleChange = this.handleChange.bind(this)
	changeBloggers = this.changeBloggers.bind(this)
	handleDelete = this.handleDelete.bind(this)
	clear = this.clear.bind(this)
	handleStart = this.handleStart.bind(this)

	componentDidUpdate(prevProps){
		if ((this.props.error !== prevProps.error) && this.props.error) {
			if (this.props.error instanceof NameError) {
				this.setState({
					bloggers: this.state.bloggers.map((item) => {
						if (!this.props.error.names[item.index]) return item
						return {
							...item,
							color: "#F58990"
						}
					}),
					errMessage: this.props.t(this.props.error.text)
				})
			}else if (this.props.error instanceof NoInformation) {
				this.setState({
					bloggers: this.state.bloggers.map((item) => {
						if (!this.props.error.names[item.index]) return item
						return {
							...item,
							color: "#ffe000"
						}
					}),
					errMessage: this.props.t(this.props.error.text)
				})
			}else {
				this.setState({
					errMessage: this.props.t(this.props.error.text)
				})
			}
		}
	}

	handleClose(e){
		if (e.target.getAttribute("data-mark") === this.mark) {
			this.props.onClose();
		}
	}

	handleChange(value){
		this.setState({ value })
	}

	handleDelete(key){
		this.list.delete(key);

		this.setState({
			bloggers: this.state.bloggers.filter(({ index }) => index !== key)
		})
	}

	changeBloggers(bloggers){

		const parsedBloggers = [];

		bloggers.forEach(string => {
			const name = parseName(string);

			if (this.list.has(name)) return

			parsedBloggers.push({
				label: string,
				index: name,
				onClick: this.handleDelete
			})
			this.list.add(name)
		})

		this.setState({ 
			bloggers: this.state.bloggers.concat(parsedBloggers)
		})
	}

	handleStart(){
		this.props.onSuccess(this.state.value, this.state.bloggers.map(item => ({ username: item.index })))
	}

	clear(){
		this.list.clear();
		this.setState({ bloggers: [] })
	} 

	render(){
		const { t } = this.props;
		return (
			<Portal>
				<div onClick = {this.handleClose} data-mark = {this.mark} className = {cn.background}>
					<div className = {cn.root}>
						<Heading type="h5">
							{t("intersections.dashboards.modal.title")}
						</Heading>
						<Text margin="10px 0 20px" color="gray-3" fSize={12}>
							{t("intersections.dashboards.modal.subtitle")}
						</Text>
						<SimpleInput
							classes={{
								title: cn.title,
								input: cn.input
							}}
							name = "intersection"
							title = {t("intersections.dashboards.modal.input")}
							value = {this.state.value}
							onChange = {this.handleChange}
						/>
						{
							this.state.errMessage &&
							<div className = {cn.error}>{this.state.errMessage}</div>
						}
						<div className = {cn.textarea}>
							<div className = {cn.hat}>
								<Text semibold fSize={12} color="gray-4" component="span">
									{t("intersections.dashboards.modal.textarea")}
								</Text>
								{
									this.state.bloggers.length > 0 &&
									<span onClick = {this.clear} className = {cn.hat__clear}>
										{t("intersections.dashboards.modal.clear")}
									</span>
								}
							</div>
							<BloggerArea 
								value = {this.state.bloggers} 
								onChange = {this.changeBloggers} 
								limit={55}
							/>
						</div>
						<div className = {cn.btns}>
							<LoadingButton
                                width={222}
                                loading={!this.props.state.loaded}
                                onClick={this.handleStart}
                                variant="primary"
                                noDownloadIcon
                            >
                                {t('intersections.dashboards.modal.start')}
                            </LoadingButton>
							<Button width={154} onClick = {this.props.onClose} color = {"optional"}>
								{t("intersections.dashboards.modal.cancel")}
							</Button>							
						</div>
					</div>
				</div>
			</Portal>
		)
	}
}