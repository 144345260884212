import React, { useState } from 'react'
import styles from './input-tags.module.scss'
import { ReactComponent as PlusIcon } from '../../icons/plus.svg'

interface Website {
    label: string
    value: string
}

interface WebsiteInputProps {
    initialWebsites?: Website[]
    onChange: (websites: Website[]) => void
    placeholder: string
}

const WebsiteInput: React.FC<WebsiteInputProps> = ({ initialWebsites = [], onChange, placeholder }) => {
    const [websites, setWebsites] = useState<Website[]>(initialWebsites)
    const [inputValue, setInputValue] = useState<string>('')

    const handleAddWebsite = () => {
        const trimmedValue = inputValue.trim()
        if (trimmedValue && !websites.some((website) => website.value === trimmedValue)) {
            const newWebsite = { label: trimmedValue, value: trimmedValue }
            const newWebsites = [...websites, newWebsite]
            setWebsites(newWebsites)
            onChange(newWebsites)
            setInputValue('')
        }
    }

    const handleRemoveWebsite = (websiteToRemove: Website) => {
        const updatedWebsites = websites.filter((website) => website.value !== websiteToRemove.value)
        setWebsites(updatedWebsites)
        onChange(updatedWebsites)
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleAddWebsite()
        }
    }

    return (
        <div className={styles['website-input-wrapper']}>
            <div className={styles['input-wrapper']}>
                <input
                    type="text"
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <button onClick={handleAddWebsite} className={styles['add-button']}>
                    <PlusIcon />
                </button>
            </div>
            <div className={styles['websites-container']}>
                {websites.map((website) => (
                    <span key={website.value} className={styles['website-chip']}>
                        {website.label}{' '}
                        <span className={styles['remove-button']} onClick={() => handleRemoveWebsite(website)}>
                            ✕
                        </span>
                    </span>
                ))}
            </div>
        </div>
    )
}

export default WebsiteInput
