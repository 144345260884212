import { useSpaceTranslation } from '@/_helpers'
import React, { useCallback, useContext, useEffect } from 'react'
import cn from './EmptyState.module.scss'

import Text from '@/components/Text'
import Icons from '@/components/Icons'
import Button from '@/components/Button'
import { CampaignManagerContext } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerContext'
import { useMutation } from '@apollo/client'
import { CREATE_EMPTY_MEDIA_PLAN } from '../../utils/queries'

export const EmptyState = () => {
    const t = useSpaceTranslation('media_planner.table.empty_state')
    const context = useContext(CampaignManagerContext)

    const [createMediaPlan, { data, loading }] = useMutation(CREATE_EMPTY_MEDIA_PLAN, {
        context: context,
    })

    useEffect(() => {
        if (data && data.createMediaPlanOrder && !loading && data.createMediaPlanOrder.mediaPlanOrder.id) {
            window.location.href = `/media-planning/${data.createMediaPlanOrder.mediaPlanOrder.id}`
        }
    }, [data, loading])

    const onCreateClick = useCallback(() => {
        createMediaPlan()
    }, [createMediaPlan])

    return (
        <div className={cn.emptyState}>
            <div className={cn.imgContainer}>
                <Icons name="media-planner" className={cn.img}></Icons>
            </div>
            <Text fSize={20} margin="24px 0 0 0" semibold style={{ lineHeight: '24px' }}>
                {t('title')}
            </Text>
            <Text fSize={14} margin="8px 0 0 0" color="gray-3">
                {t('subtitle')}
            </Text>
            <Button variant="primary" margin="20px 0 0 0" onClick={onCreateClick}>
                {t('create')}
            </Button>
        </div>
    )
}
