import React, { FC, memo, HTMLAttributes } from 'react'
import { Tooltip } from '@yoloker/ui-collection'
import cn from './Blocks.module.scss'

export interface IStandatrtLabel extends HTMLAttributes<HTMLDivElement> {
    title: string
    helperText?: string
    margin?: string
}

const StandartLabel: FC<IStandatrtLabel> = ({ title, helperText, style, margin, className, ...rest }) => {
    return (
        <div
            className={`${cn.standart_label} ${className || ''}`}
            style={{
                ...style,
                margin,
            }}
        >
            <h3>{title}</h3>
            {!!helperText && <Tooltip text={helperText} />}
        </div>
    )
}

export default memo(StandartLabel)
