import React, { FC, ReactNode, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Flex from '@/_yc/Flex'
import Button from '@/components/Button'
import Text from '@/components/Text'
import Icons from '@/components/Icons'
import cn from './CSVLoader.module.scss'

interface ICSVLoader {
    children: ReactNode
    changeBloggers: Function
    dropCSVfile: Function
    file: File | undefined
    setFile: Function
}
const CSVLoader: FC<ICSVLoader> = ({ children, changeBloggers, dropCSVfile, file, setFile }) => {
    const { t } = useTranslation()

    const [isDragging, setIsDragging] = useState(false)

    const handleDragOver = (event: React.DragEvent) => {
        event.preventDefault()
        setIsDragging(true)
    }

    const handleDragLeave = () => {
        setIsDragging(false)
    }

    const handleDrop = (event: React.DragEvent) => {
        event.preventDefault()
        setIsDragging(false)
        const files = event.dataTransfer.files
        if (files.length > 0) {
            const temp = files[0]
            setFile(temp)

            if (temp) {
                fileReader.onload = function (event) {
                    const csvOutput = event?.target?.result
                    if (typeof csvOutput === 'string') {
                        getBloggersFromCSV(csvOutput)
                    }
                }

                fileReader.readAsText(temp)
            }
        }
    }

    const fileReader = new FileReader()

    const handleOnChange = (e: any) => {
        const temp = e.target.files[0]
        setFile(temp)

        if (temp) {
            fileReader.onload = function (event) {
                const csvOutput = event?.target?.result
                if (typeof csvOutput === 'string') {
                    getBloggersFromCSV(csvOutput)
                }
            }

            fileReader.readAsText(temp)
        }
    }

    const getBloggersFromCSV = useCallback(
        (text: string) => {
            const bloggers = text.split(/\r?[\n,]/)
            changeBloggers(bloggers)
        },
        [changeBloggers]
    )

    return (
        <>
            <Flex column>
                {!file ? (
                    <Flex
                        column
                        align="center"
                        content="center"
                        className={`${cn.root} ${isDragging ? cn.dragging : ''}`}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                    >
                        <Text style={{ lineHeight: '18px' }} fSize={'14px'} color="gray-new-3">
                            {t('bulk_add.dragFileHere')}
                        </Text>
                        <Text style={{ lineHeight: '18px' }} margin="0 0 2px 0" fSize={'12px'} color="gray-new-3">
                            {t('bulk_add.or')}
                        </Text>
                        <Button htmlFor="csvFileInput" onClick={() => document.getElementById('csvFileInput')?.click()}>
                            <Text fSize={'12px'} semibold color="violet-1">
                                {t('bulk_add.chooseFileToUpload')}
                            </Text>
                        </Button>

                        <input
                            type={'file'}
                            id={'csvFileInput'}
                            accept={'.csv'}
                            onChange={handleOnChange}
                            style={{ display: 'none' }}
                        />
                    </Flex>
                ) : (
                    <>{children}</>
                )}

                {file && (
                    <Flex className={cn.name} content="space-between" align="center">
                        <Text>
                            {file?.name + ' '}
                            <span>({file?.size}K)</span>
                        </Text>
                        <Icons
                            name="close--small"
                            onClick={() => {
                                setFile(undefined)
                                dropCSVfile()
                            }}
                        />
                    </Flex>
                )}
            </Flex>
        </>
    )
}

export default CSVLoader
