import React, { FC, SVGAttributes } from 'react'
import { Platform } from '@/pages/Reports/types'
import VerifIcon from './VerifIcon_1'
interface IVer extends SVGAttributes<SVGElement> {
    type: Platform
    width?: string
    height?: string
}

const VerificationIcon: FC<IVer> = (props) => {
    const width = props.width || '20'
    const height = props.height || '20'
    if (props.type === Platform.Vk) {
        return <VerifIcon />
    }
    if (props.type === Platform.YouTube)
        return (
            <div>
                <svg width={width} height={height} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="10" fill="#606060" />
                    <path
                        d="M5.69374 8.95769L3.82617 10.8253L8.62647 15.6256L17.0305 7.22148L15.0442 5.23515L8.50771 11.7717L5.69374 8.95769Z"
                        fill="white"
                    />
                </svg>
            </div>
        )

    return <div />
}
export default VerificationIcon
