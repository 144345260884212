import React from 'react'
import Text from '@/components/Text'
import { shortNum } from '@/_helpers/_graphs/_tech'
import { Flex } from '@/_yc'
import { FC } from 'react'
import cn from './MetricItem.module.scss'

interface IItem {
    name: string
    value: number | string
    small?: boolean
}

export const MetricItem: FC<IItem> = (props) => (
    <Flex className={cn[`MetricItem${props.small ? '-small' : ''}`]} column>
        <Text className={cn[`MetricItem--value${props.small ? '-small' : ''}`]} semibold>
            {typeof props.value === 'number' ? shortNum(props.value) : props.value}
        </Text>
        <Text color={'gray-3'} className={cn['MetricItem--name']}>
            {props.name}
        </Text>
    </Flex>
)
