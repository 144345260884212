import Service from '@/_helpers/Service'
export type accessType = 'Viewer' | 'Editor' | 'Commentator' | 'Owner'
export interface ISharedPerson {
    email: string
    first_name: string
    last_name: string
    access_type: accessType
    status: string
}

const GetCampaignMembers = new Service<ISharedPerson[]>({
    baseUrl: process.env.REACT_APP_CAMPAIGN_MANAGER,
    method: 'GET',
    url: '',
})

export const GetCampaignMembersReq = (token: string, campaignId: string) =>
    GetCampaignMembers.call(
        {
            url: `/api/campaigns/${campaignId}/members/`,
        },
        token
    )

const AddCampaignMembers = new Service<ISharedPerson[]>({
    baseUrl: process.env.REACT_APP_CAMPAIGN_MANAGER,
    method: 'POST',
    url: '',
})

export const AddCampaignMembersReq = (token: string, campaignId: string, list_email: string[]) =>
    AddCampaignMembers.call(
        {
            url: `/api/campaigns/${campaignId}/members/`,
            data: list_email,
        },
        token
    )

const EditCampaignMembers = new Service<ISharedPerson[]>({
    baseUrl: process.env.REACT_APP_CAMPAIGN_MANAGER,
    method: 'PATCH',
    url: '',
})

export const EditCampaignMembersReq = (token: string, campaignId: string, email: string, newAccessType: string) =>
    EditCampaignMembers.call(
        {
            url: `/api/campaigns/${campaignId}/members/`,
            params: {
                email: email,
            },
            data: {
                access_type: newAccessType,
            },
        },
        token
    )
