import React, { FC, memo } from 'react'
import { Link } from 'react-router-dom'

import { Text, Button } from '@/_yc'
import { LOGIN } from '@/routs'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import cn from './SignUp.module.scss'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import Resend from './Resend'
export interface IDone {
    email: string
}
const lHeight = { lineHeight: 1 }
const Done: FC<IDone> = ({ email }) => {
    const t = useSpaceTranslation('signup')
    const size = useWindowWidth('mobile')
    return (
        <>
            <Text size={size === 'mobile' ? 12 : undefined} margin="0 0 30px">
                {t('sign_up_done.subtitle')}
            </Text>
            <Link to={LOGIN}>
                <Button className={size === 'desktop' ? cn.btn : cn['btn--mobile']} margin="0 0 20px" width={'100%'}>
                    <Text style={lHeight} color="white">
                        {t('sign_up_done.button_label')}
                    </Text>
                </Button>
            </Link>
            <Resend email={email} />
        </>
    )
}

export default memo(Done)
