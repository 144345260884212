import Flex from '@/_yc/Flex'
import cn from './FilterLock.module.scss'
import React, { SVGAttributes } from 'react'
import Text from '@/_yc/Text'
import UnlockIcon from './UnlockIcon'
import { useTranslation } from 'react-i18next'
const Unlock = (props: SVGAttributes<SVGElement> & { leave: VoidFunction }) => {
    const { t } = useTranslation()
    return (
        <Flex className={cn['margin--unlock']} onMouseLeave={props.leave}>
            <Flex align="center" className={cn.abs}>
                <div className={cn.unlock}>
                    <UnlockIcon color="#FFFFFF" />
                </div>
                <div className={cn.unlock}>
                    <Text size="10px" color="white">
                        <span>{t('filters.lock.to_unlock')} </span>
                        <span>
                            <a
                                href="https://app.yoloco.io/tariffs"
                                target="_blank"
                                className={cn.link}
                                rel="noopener noreferrer"
                            >
                                {t('filters.lock.activate')}
                            </a>
                        </span>
                        <span> {t('filters.lock.plan')}</span>
                    </Text>
                </div>
            </Flex>

            <div className={cn.triangle} />
            <UnlockIcon />
        </Flex>
    )
}

export default Unlock
