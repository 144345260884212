import React from 'react';
import pt from 'prop-types'

import VisibilityIcon from '@material-ui/icons/Visibility'

import cn from './LoginInput.module.scss'

const LoginInput = (props) =>{
	const { 
		title, 
		value = "", 
		onChange, 
		type = "text", 
		error, 
		disableVision, 
		disablePad, 
		style,
		onBlur,
		onFocus
	} = props;
	let [isVisible, setVisible] = React.useState(false);
	if (type==="radial") {
		return (
			<div 
				className = {cn.root_radial} 
				style = {{ 
					padding: disablePad ? "0 0 11px" : undefined,
					...style 
				}} 
				onClick = {e=>onChange(!value)} 
			>
				<div 
					className = {cn.rad_self}
					style={{
						background: error ? "rgba(255, 0, 0, .1)" : undefined,
					}} 
				>
					<div 
						className = {cn.value}
						style={{
							opacity: value ? 1 : 0
						}}
					/>
				</div>
				<div className = {cn.title}>
					{title}
					{error ? <React.Fragment>
						<br />
						<div 
							style={{
								fontSize: 12,
								color: 'red'
							}}
						>
							{error}
						</div>
						</React.Fragment>
					 : null}
				</div>
			</div>
		)
	}

	return(
		<div className = {cn.root} style = {{ padding: disablePad ? "0 0 11px" : undefined }}>
			<div className = {cn.label}>
				<div>
					{title}
				</div>
				{error ? <div className = {cn.err_label}>{error}</div> : null}
			</div>
			{type!=="password" ? 
			<input 
				className={`${cn.inp} ${error ? cn.inp_error : ""}`}
				value={value} 
				type={type} 
				name={title ? title.toString().toLowerCase() : undefined}
				onChange={e=>onChange(e.target.value)} 
				onBlur = {onBlur}
				onFocus = {onFocus}
			/>:
			<div className = {`${cn.imp_cont}  ${error ? cn.imp_cont_error : ""}`}>
				<input 
					className = {cn.pass_inp}
					value={value} 
					name={title ? title.toString().toLowerCase() : undefined}
					type={isVisible ? "text" : "password"}
					onChange={e=>onChange(e.target.value)}
					onBlur = {onBlur}
					onFocus = {onFocus}
				/>
				{!disableVision && <VisibilityIcon 
					className = {cn.icon}
					style={{
						color: isVisible ? "#2a2a31" : "#BABABA"
					}}
					onClick={e=>setVisible(!isVisible)}
				/>}
			</div>
			}
		</div>
	)
}

LoginInput.propTypes = {
	title: pt.string.isRequired,
	value: pt.oneOfType([
		pt.string.isRequired,
		pt.bool.isRequired
	]).isRequired,
	onChange: pt.func.isRequired,
	type: pt.oneOf([ "text", "radial", "number", "email", "phone", "password" ]),
	error: pt.oneOfType([pt.string, pt.bool]),
	disableVision: pt.bool,
	disablePad: pt.bool,
	style: pt.object
}

export { LoginInput }