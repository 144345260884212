import React, { ForwardRefRenderFunction, HTMLAttributes, useCallback } from 'react';

import cn from './Checkbox.module.scss'
import Flex from '@/_yc/Flex';
import Mark from "./Mark";

export interface ICheckbox extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>{
    value?: boolean
    onChange?: (state: boolean) => void
    margin?: string
    size?: "small" | "big"
}

const Checkbox: ForwardRefRenderFunction<HTMLDivElement, ICheckbox> = (props, ref) => {

    const { value, onChange, margin, className, size="small", ...rest } = props;

    const click = useCallback(() => {
        onChange && onChange(!value);
    }, [onChange, value])

    return (
        <Flex 
            align="center" 
            content="center" 
            margin={margin} 
            ref={ref} 
            className={`${cn[`root--${size}`]} ${className || ""}`} {...rest}
            onClick={click}
        >
            {
                value &&
                <Mark />
            }
        </Flex>
    )
}

export default Checkbox
