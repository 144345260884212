import React from 'react'
import { useSelector } from 'react-redux'

import { RootStore } from '@/_helpers/StoreType'
import MoreModal from '@/components/MoreModal'
import ListOfAccounts from '../ResuableWidgets/AccountCards'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import cn from './SimilarAccount.module.scss'

const SimilarAccounts = () => {
    const similarAccounts = useSelector((store: RootStore) => {
        const getChannelData = store.report.rawChannelData.getChannel?.data
        return getChannelData?.similar_accounts.youtube_channel
    })

    const t = useSpaceTranslation('report_layout.similar_accounts')
    return (
        <>
            {similarAccounts && similarAccounts.length !== 0 && (
                <>
                    <div className={cn.header}>
                        <MoreModal title={t('title')}>
                            <ListOfAccounts
                                amount={Math.min(similarAccounts.length, 30)}
                                cardType="vertical"
                                data={similarAccounts}
                            />
                        </MoreModal>
                    </div>
                    <ListOfAccounts amount={5} cardType="vertical" data={similarAccounts} />
                </>
            )}
        </>
    )
}

export default SimilarAccounts
