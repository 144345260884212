import React, { CSSProperties, FC, ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import SearchInput from './SearchInput/'

import cn from './Header.module.scss'
import { Flex } from '@/_yc'
import Heading from '@/components/Heading'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'

export interface IHeader {
    isReport?: boolean
    children?: ReactNode
}

const Header: FC<IHeader> = (props) => {
    const { t } = useTranslation()

    const size = useWindowWidth()

    const xs = size === 'mobile'

    const style = useMemo<CSSProperties | undefined>(() => {
        if (xs)
            return {
                width: '100%',
            }
    }, [xs])

    return (
        <header className={cn.root}>
            <div className={cn.widther}>
                <Heading margin="0 0 14px" color={'gray-1'} type="h3">
                    {t('aud_insides.title')}
                </Heading>
                <Flex
                    column={xs && !props.isReport}
                    align={xs && !props.isReport ? 'flex-start' : 'center'}
                    content={xs && !props.isReport ? 'center' : 'space-between'}
                >
                    {!props.isReport && <SearchInput place="aud_insights" />}

                    {!props.isReport && (
                        <Flex style={style} margin={xs ? '10px 0 0' : '0 0 0 20px'} align="center">
                            {props.children}
                        </Flex>
                    )}

                    {props.isReport && props.children}
                </Flex>
            </div>
        </header>
    )
}

export { Header as default }
