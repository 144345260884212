import Observable from '@/_helpers/Observable'
import { useState, useEffect } from 'react'

let timer: NodeJS.Timeout | null = null

const TimeObservable = new Observable<number>()
TimeObservable.onSubscribe = (size) => {
    if (size === 1 && !timer) {
        timer = setInterval(() => TimeObservable.update(Date.now()), 1000 * 60)
    }
}
TimeObservable.onUnsubscribe = (size) => {
    if (size === 0 && timer) {
        clearInterval(timer)
        timer = null
    }
}

export default (date: number): [number] => {
    const [time, setTime] = useState<number>(Date.now() - date)

    useEffect(() => {
        const sub = TimeObservable.subscribe((newTime) => setTime(newTime - date))

        return () => sub.unsubscribe()
    }, [date])

    return [time]
}
