import Observable from "./Observable";

export default class WindowListener {
    private observable = new Observable<Event>();
    private subscribed: boolean = false;
    constructor(private event: string){
        this.observable.onSubscribe = this.onSubscribe.bind(this);
        this.observable.onUnsubscribe = this.onUnsubscribe.bind(this);
    }

    private onSubscribe(count: number): void{
        if (!this.subscribed) {
            window.addEventListener(this.event, this.handle);
            this.subscribed = true;
        }
    }

    private onUnsubscribe(count: number): void {
        if(count === 0){
            window.removeEventListener(this.event, this.handle);
            this.subscribed = false;
        }
    }

    private handle = (event: Event) => {
        this.observable.update(event)
    }

    subscribe = this.observable.subscribe.bind(this.observable);
}