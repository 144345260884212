import { createAction } from '@reduxjs/toolkit'
import { ISearchState } from './types'

export const setSorting = createAction<{ sorting: ISearchState['sorting'] }>('@search/sorting/set')

export const setSearchText = createAction<{ search: string }>('@search/text/set')

export const clearSearchText = createAction('@search/text/clear')

export const addToSearchBy = createAction<{ value: string }>('@search/searchBy/add')

export const clearSearchBy = createAction('@search/searchBy/clear')

export const removeFromSearchBy = createAction<{ value: string }>('@search/searchBy/remove')

export const toggleSearchBy = createAction<{ value: string }>('@search/searchBy/toggle')

export const setFilter = createAction<{ key: string; value: any }>('@search/filters/set')

export const clearFilter = createAction<{ key: string }>('@search/filter/clear')

export const clearAll = createAction('@search/all/clear')

export const setClickedFilter = createAction<{ filterId: string | null }>('@clickedFilter/filter')
