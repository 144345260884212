import { useCallback, useEffect, useRef, useState } from 'react'

/**
 *
 * @param transform Function to transform Style declaration to your data
 * @param transformDependencies transform function dependencies for useCallback function
 */
export const useComputedStyle = <DataType>(
    transform: (style: CSSStyleDeclaration) => DataType,
    transformDependencies: any[]
) => {
    const [style, setStyle] = useState<DataType | null>(null)

    const ref = useRef<any>(null)

    const memoTransform = useCallback(transform, transformDependencies)

    useEffect(() => {
        if (!ref.current) return
        setStyle(memoTransform(window.getComputedStyle(ref.current)))
    }, [memoTransform])

    return { ref, style }
}
