import React from 'react';
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { StandartPopper } from '../../../_yc'

import cn from './PopNavigator.module.scss'

class PopNavigator extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			open: false,
			disabled: false
		}
		this.block = React.createRef()
	}
	handleClick(val, event){
		if (this.state.disabled && val) return
		if (!val) {
			this.setState({ open: val }, e=>{
				setTimeout(e=>this.setState({ disabled: false }), 0)
			})
			return
		}
		this.setState({open: val, disabled: true})
	}
	render(){
		return(
			<StandartPopper 
				btn = {(
					<div 
						ref={this.block} 
						onClick = {!this.state.disabled ? e => this.handleClick(true, e) : e=>e} 
						className = {cn.btn}
					>
						<div className = {cn.big_line}></div>
						<div className = {cn.big_line}></div>
						<div className = {cn.small_line}></div>
					</div>
				)}
				open = {this.state.open}
				anchor = {this.block.current}
				onClickAway = {this.handleClick.bind(this)}
				content = {
					<div 
						className = {cn.popup}
              			style = {{ background: this.props.color ? this.props.color : undefined }}
					>
						{this.props.options.map((item, index) => (
							<Link 
								to={item.route}
								key={index}
								onClick={item.disabled ? e=>e.preventDefault() : undefined}
								style = {{
									color: window.location.pathname===item.route ? "#FFDA54" : undefined,
									opacity: item.disabled ? .7 : undefined,
									marginBottom: index !== this.props.options.length - 1 ? 30 : undefined
								}}
							>
								{this.props.t(item.label)}
							</Link>
						))}
					</div>
				}
			/>
		)
	}
}

PopNavigator = withTranslation()(PopNavigator);

export { PopNavigator }