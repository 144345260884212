import React, { FC, useCallback, useContext } from 'react'
import { ContentType, Currency, MediaPlanBlogger } from '../../../utils/types'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import { shortNum } from '@/_helpers/_graphs/_tech'
import { GetLevel as YoScoreLevels } from '@/pages/Reports/BloggerReport/BloggerRating/BloggerLevel'
import { ValueInput } from '../ValueInput/ValueInput'
import { CampaignManagerContext } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerContext'
import { useMutation } from '@apollo/client'
import { UPDATE_BLOGGER_DATA, UPDATE_CUSTOM_BLOGGER_DATA } from '../../../utils/queries'
import BloggerLink from '@/components/Share/BloggerLink'
import Button from '@/components/Button'
import { ContentTypePopup } from './ContentType/ContentType'
import { getShortString } from '@/_helpers/strings/getShortString'

export enum CellType {
    ContentType = 'contentType',
    ShortNumInput = 'shortNumInput',
    ShortNumValue = 'shortNumValue',
    LongNumInput = 'longNumInput',
    YoScore = 'yoScore',
    Percent = 'percent',
    Money = 'money',
    CustomNumInput = 'customNumInput',
    Analyze = 'analyze',
    Float = 'float',
    String = 'string',
}

interface CellProps {
    blogger: MediaPlanBlogger
    contentTypes: ContentType[]
    cellType: CellType
    currency: Currency
    value: number | null
    valueKey: string
}

export const Cell: FC<CellProps> = ({ blogger, cellType, value, valueKey, contentTypes, currency }) => {
    const context = useContext(CampaignManagerContext)

    const [updateMediaPlan] = useMutation(UPDATE_BLOGGER_DATA, {
        context: context,
    })

    const [updateCustomData] = useMutation(UPDATE_CUSTOM_BLOGGER_DATA, {
        context: context,
    })

    const onSubmit = useCallback(
        (data: { [key: string]: number | null }) => {
            console.log(data)
            updateMediaPlan({
                variables: {
                    bloggerId: blogger.externalId,
                    orderId: blogger.mediaPlanId.id,
                    bloggerData: data,
                },
            })
        },
        [updateMediaPlan, blogger]
    )

    const onCustomSubmit = useCallback(
        (data: { [key: string]: number | null }) => {
            console.log(JSON.stringify(data))
            updateCustomData({
                variables: {
                    bloggerId: blogger.externalId,
                    orderId: blogger.mediaPlanId.id,
                    bloggerData: JSON.stringify(data),
                },
            })
        },
        [blogger, updateCustomData]
    )

    switch (cellType) {
        case CellType.ContentType:
            return (
                <Flex align="center">
                    <ContentTypePopup blogger={blogger} contentTypes={contentTypes} />
                </Flex>
            )
        case CellType.ShortNumInput:
            return (
                <Flex align="center">
                    <ValueInput value={value} valueKey={valueKey} onSubmit={onSubmit} />
                </Flex>
            )
        case CellType.ShortNumValue:
            return (
                <Flex align="center">
                    <Text fSize={12}>{shortNum(value)}</Text>
                </Flex>
            )
        case CellType.LongNumInput:
            return (
                <Flex align="center">
                    <ValueInput value={value} valueKey={valueKey} onSubmit={onSubmit} />
                </Flex>
            )
        case CellType.YoScore:
            return (
                <Flex align="center">
                    <Text fSize={12}>{value ? `${(parseFloat(value.toString()) * 100).toFixed(0)}` : ''}</Text>
                    {value && (
                        <div
                            style={{
                                height: '6px',
                                width: '6px',
                                marginLeft: '4px',
                                borderRadius: '50%',
                                background: `${YoScoreLevels(parseFloat(value.toString())).color}`,
                            }}
                        />
                    )}
                </Flex>
            )
        case CellType.Analyze:
            return (
                <Flex align="center">
                    <BloggerLink newTab id={blogger.externalId} platform={blogger.bloggerType}>
                        <Button width={100} size="small" style={{ padding: 0 }} variant={'outline'} margin="0">
                            Analyze
                        </Button>
                    </BloggerLink>
                </Flex>
            )
        case CellType.Percent:
            return (
                <Flex align="center">
                    <Text fSize={12}>{(value ? value * 100 : 0).toFixed(2)}%</Text>
                </Flex>
            )
        case CellType.Money:
            return (
                <Flex align="center">
                    <ValueInput value={value} valueKey={valueKey} onSubmit={onSubmit} currency={currency} />
                </Flex>
            )
        case CellType.CustomNumInput:
            return (
                <Flex align="center">
                    <ValueInput value={value} valueKey={valueKey.split('customData.')[1]} onSubmit={onCustomSubmit} />
                </Flex>
            )
        case CellType.Float:
            return (
                <Flex align="center">
                    <Text fSize={12}>{value ? parseFloat(value.toString()).toFixed(2) : ''}</Text>
                </Flex>
            )
        case CellType.String:
            return (
                <Flex align="center">
                    <Text fSize={12}>{value ? getShortString(value.toString(), 13) : ''}</Text>
                </Flex>
            )
        default:
            return <div>Unknown cell type</div>
    }
}
