import React, { FC, ReactNode } from 'react'
import { CampaignManagerContext } from './CampaignManagerContext'

export interface ICampaignManagerProvider {
    children?: ReactNode
}

export const CampaignManagerProvider: FC<ICampaignManagerProvider> = (props) => {
    return (
        <CampaignManagerContext.Provider value={{ clientName: 'campaignManager' }}>
            {props.children}
        </CampaignManagerContext.Provider>
    )
}
