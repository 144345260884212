import React, { PureComponent } from 'react';
import pt from 'prop-types'

import parseResponse from './parseResponse.js'
import demoData from './demoData.js';
export default (pullService) => Component => class extends PureComponent {
	static get propTypes(){
		return {
			id: pt.string.isRequired
		}
	}

	intiState = {
		loaded: false,
		data: {},
		error: false,
		isDemo: this.props.id === "demo"
	}

	mounted = true

	state = this.intiState

	componentDidMount(){
		if (this.props.id === "demo"){
			return this.setState({
				loaded: true,
				data: parseResponse(demoData)
			})
		}
		pullService(this.props.id)
			.then(resp => {
				if (!this.mounted) return
				this.setState({
					loaded: true,
					data: parseResponse(resp)
				})
			})
			.catch(e => this.setState({ loaded: true, error: true }))
	}

	componentWillUnmount(){
		this.mounted = false;
	}

	render(){
		return <Component {...this.state} {...this.props}/>
	}
}

