import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types'

import cn from './Avatar.module.scss'

const Avatar = props => {
	
	const [error, setError] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		const img = new Image();

		let mounted = true;

		img.onload = () => {
			mounted && setIsLoaded(true);
		}
		
		img.onerror = () => {
			mounted && setError(true)
		}

		img.src = props.src;

		return () => {
			mounted = false;
		}
	}, [props.src])

	return (
		<div
			className={cn.root}
			style={{ ...props.style, background: error ? "#A3A3A3" : undefined }}
		>
			{
				isLoaded ? (
				<img src={props.src} alt={"Avatar"} />
				) : !error ? (
					<div
						className={cn.loading}
						style={props.loadingStyle}
					/>
				) : null
			}
		</div>
	)
}

Avatar.propTypes = {
	src: PropTypes.string.isRequired,
	style: PropTypes.object,
	loadingStyle: PropTypes.object,
	animationDelay: PropTypes.number
}

Avatar.defaultProps = {
	style: {},
	loadingStyle: {}
}

const memedAvatar = memo(Avatar);

export { memedAvatar as Avatar };