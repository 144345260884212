import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import { getUserListsReq, UserList } from '@/pages/Outreach/Service/Templates'
import useToken from '@/_helpers/Hooks/useToken'
import Portal from '@/_helpers/Portal'
import Flex from '@/_yc/Flex'
import Popup from '@/_yc/Popup'
import Text from '@/components/Text'

import cn from './AddFromListPopup.module.scss'
import { useSpaceTranslation } from '@/_helpers'
import Icons from '@/components/Icons'
import { ListItem } from './ListItem/ListItem'
import Loader from '@/components/Loader'
import { BloggerList } from './BloggerList/BloggerList'

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 460,
    zIndex: 2000,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}

interface AddFromListPopupProps {
    isOpen: boolean
    close: () => void
}

export const AddFromListPopup: FC<AddFromListPopupProps> = (props) => {
    const token = useToken()
    const t = useSpaceTranslation('outreach.templates.add_from_list')

    const [userLists, setUserLists] = useState<UserList[]>([])

    const [selectedListId, setSelectedListId] = useState<number | null>(null)

    const userListsRef = useRef<HTMLDivElement>(null)

    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [noMoreUserLists, setNoMoreUserLists] = useState(false)

    // disable scroll on body
    useEffect(() => {
        if (props.isOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [props.isOpen])

    useEffect(() => {
        if (!token) return
        setLoading(true)
        getUserListsReq(token, page).then((res) => {
            setUserLists((userLists) => [...userLists, ...res.results])
            setLoading(false)
            setNoMoreUserLists(res.next === null)
        })
    }, [token, page, setUserLists, setLoading, setNoMoreUserLists])

    const onListClick = (id: number) => {
        setSelectedListId(id)
    }

    const onClose = () => {
        setSelectedListId(null)
        props.close()
    }

    const onScroll = useCallback(() => {
        if (!userListsRef.current) {
            return
        }

        const { scrollTop, scrollHeight, clientHeight } = userListsRef.current
        if (scrollTop + clientHeight > scrollHeight - 200) {
            !loading && !noMoreUserLists && setPage((prev) => prev + 1)
        }
    }, [setPage, loading, noMoreUserLists])

    if (!props.isOpen) return <></>

    return (
        <Portal>
            <div className={cn.root}>
                <Popup style={style} isOpen={props.isOpen} onClose={onClose}>
                    <div className={cn.popup}>
                        <Flex content="space-between" align="center" margin="0 0 20px 0">
                            <Flex column>
                                <Text fSize={20}>{t('title')}</Text>
                            </Flex>
                            <div onClick={onClose}>
                                <Icons name="close" className={cn.close}></Icons>
                            </div>
                        </Flex>

                        {/* User lists */}
                        {selectedListId === null && (
                            <div className={cn.mailingListItems} ref={userListsRef} onScroll={onScroll}>
                                {userLists.length > 0 &&
                                    userLists.map((item, i) => (
                                        <ListItem
                                            key={item.id}
                                            title={item.name}
                                            count={item.qty}
                                            id={item.id}
                                            i={i}
                                            onClick={onListClick}
                                        />
                                    ))}

                                {(userLists.length === 0 || loading) && (
                                    <Flex content="center" align="center">
                                        <Loader />
                                    </Flex>
                                )}
                            </div>
                        )}

                        {selectedListId !== null && <BloggerList selectedListId={selectedListId} close={onClose} />}
                    </div>
                </Popup>
            </div>
        </Portal>
    )
}
