import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.6663 14.6663H3.33301V13.333H12.6663V14.6663ZM7.99967 11.9997L3.99967 7.99967L4.93967 7.05967L7.33301 9.44634V1.33301H8.66634V9.44634L11.0597 7.05967L11.9997 7.99967L7.99967 11.9997Z"
            fill="#5D697D"
        />
    </svg>
)
