import React, { FC, memo, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Text, Button } from '@/_yc'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { LOGIN, SIGNUP } from '@/routs'

import cn from './RequireDemoPage.module.scss'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'

export interface ISuccessModal {
    onClose: VoidFunction
}
const lHeight = { lineHeight: 1 }
const SuccessModal: FC<ISuccessModal> = (props) => {
    const t = useSpaceTranslation('require_demo.modal')

    const location = useLocation<{ pathname?: string }>()

    const prev = useMemo(() => {
        if (location.state?.pathname === LOGIN) return 'LOGIN'
        if (location.state?.pathname === SIGNUP) return 'SIGNUP'
        return 'UNKNOWN'
    }, [location])
    const size = useWindowWidth('mobile')

    return (
        <>
            <Text size={size === 'mobile' ? 12 : undefined} margin="0 0 30px">
                {t('title')}
            </Text>
            <Link to={LOGIN}>
                <Button className={size === 'desktop' ? cn.btn : cn['btn--mobile']} margin="0 0 20px" width={'100%'}>
                    <Text style={lHeight} color="white">
                        {t(`continue_${prev}`)}
                    </Text>
                </Button>
            </Link>
        </>
    )
}

export default memo(SuccessModal)
