import Observable from '@/_helpers/Observable'
import { ComponentType, SVGAttributes } from 'react'
import { BasicFilter } from './filters/BasicFilter'
import { FilterCategory } from './filters/FilterCategory'

export interface IState<DataType> {
    page: number
    total: number
    hasNext: boolean
    items: DataType[]
    loading: boolean
    error: boolean
    isHidden: boolean
    cursor?: string
}

export interface FetcherConfig<FilterTypes> {
    sorting: string
    search: string
    by: string[]
    filters: FilterTypes
    mainFilters?: FilterTypes
    locale: string
}

const defaultState: IState<any> = {
    hasNext: true,
    total: 0,
    page: 1,
    items: [],
    loading: true,
    error: false,
    isHidden: false,
}

type ReturnT<Filter extends BasicFilter<any, any>> = ReturnType<Filter['options']['beforeSend']>

export type MappedFilters<FiltersType extends Record<string, FilterCategory>> = {
    [K in keyof FiltersType]: {
        [J in keyof FiltersType[K]['filters']]: ReturnT<FiltersType[K]['filters'][J]> | null
    }
}

export class Adapter<
    IconProps extends { margin?: string | number } & SVGAttributes<SVGElement>,
    ItemProps extends Record<string, any>,
    HeadProps extends { margin: string | number; setIsList: Function; isList: boolean },
    FiltersType extends Record<string, FilterCategory>
> {
    private observable = new Observable<IState<ItemProps>>()

    private state: IState<ItemProps> = defaultState

    public filtersFlatMap = new Map<string, BasicFilter<any, any>>()
    constructor(
        public config: {
            Icon: ComponentType<IconProps>
            templateColumns: string
            title: string
            background: string
            Head: ComponentType<HeadProps>
            Item: ComponentType<ItemProps & { index: number; margin: number | string }>
            fetcher: (
                token: string,
                config: FetcherConfig<MappedFilters<FiltersType>> & {
                    page: number
                    cursor?: string
                }
            ) => Promise<{
                items: ItemProps[]
                total: number
                hasNext: boolean
                isHidden: boolean
                cursor?: string
                query?: string
                unhideCost?: number //cost to open account in search for tikTok
            }>
            buyMoreReports?: (
                token: string,
                config: FetcherConfig<MappedFilters<FiltersType>>,
                searchResultIds: string[]
            ) => Promise<{
                items: ItemProps[]
                cursor?: string
                query?: string
            }>
            countCheck?: (
                token: string,
                config: FetcherConfig<MappedFilters<FiltersType>>
            ) => Promise<{ count: number }>
            sortings: Array<{
                label: string
                value: string
            }>
            keyWords: Array<{
                label: string
                value: string
            }>
            filters: FiltersType
            mainFilters?: FiltersType
            unhideCostTiktok?: number
        }
    ) {
        for (const [, filters] of Object.entries(config.filters)) {
            for (const [, filter] of Object.entries(filters.filters)) {
                if (filter.id) {
                    this.filtersFlatMap.set(`${filter.id}`, filter)
                }
            }
        }
        for (const [category, filters] of Object.entries(config.filters)) {
            for (const [name, filter] of Object.entries(filters.filters)) {
                this.filtersFlatMap.set(`${category}.${name}`, filter)
            }
        }
        if (config.mainFilters) {
            for (const [category, filters] of Object.entries(config.mainFilters)) {
                for (const [name, filter] of Object.entries(filters.filters)) {
                    this.filtersFlatMap.set(`${category}.${name}`, filter)
                }
            }
        }
    }

    getState(): IState<ItemProps> {
        return this.state
    }

    subscribe: Observable<IState<ItemProps>>['subscribe'] = this.observable.subscribe.bind(this.observable)

    private send() {
        this.observable.update(this.state)
    }

    async resetPages(token: string, config: FetcherConfig<MappedFilters<FiltersType>>) {
        try {
            this.state = defaultState
            this.send()

            const data = await this.config.fetcher(token, {
                ...config,
                page: this.state.page,
                cursor: this.state.cursor,
            })

            this.state = {
                ...this.state,
                hasNext: data.hasNext,
                items: data.items,
                total: data.total,
                loading: false,
                isHidden: data.isHidden,
                cursor: data.cursor,
            }
        } catch (e) {
            console.log(e, 'ERROR')
            this.state = {
                ...this.state,
                hasNext: false,
                loading: false,
                error: true,
                total: 0,
            }
        }
        this.send()
    }

    async nextPage(token: string, config: FetcherConfig<MappedFilters<FiltersType>>) {
        if (this.state.error || this.state.loading || !this.state.hasNext) return

        this.state = {
            ...this.state,
            loading: true,
        }
        this.send()

        try {
            const data = await this.config.fetcher(token, {
                ...config,
                page: this.state.page + 1,
                cursor: this.state.cursor,
            })

            this.state = {
                ...this.state,
                loading: false,
                hasNext: data.hasNext,
                items: this.state.items.concat(data.items),
                total: data.total,
                page: this.state.page + 1,
                isHidden: data.isHidden,
                cursor: data.cursor,
            }
        } catch (e) {
            console.error(e)
            this.state = {
                ...this.state,
                hasNext: false,
                loading: false,
                error: true,
                total: 0,
            }
        }

        this.send()
    }

    async checkCount(token: string, config: FetcherConfig<MappedFilters<FiltersType>>): Promise<{ count: number }> {
        return this.config.countCheck
            ? await this.config.countCheck(token, config)
            : {
                  count: (
                      await this.config.fetcher(token, {
                          ...config,
                          page: 1,
                      })
                  ).total,
              }
    }

    async unhideCostTiktok(
        token: string,
        config: FetcherConfig<MappedFilters<FiltersType>>
    ): Promise<{ unhideCost: number | undefined }> {
        return {
            unhideCost: (
                await this.config.fetcher(token, {
                    ...config,
                    page: 1,
                })
            ).unhideCost,
        }
    }

    async unhideReportsTikTok(
        token: string,
        config: FetcherConfig<MappedFilters<FiltersType>>,
        searchResultIds: string[]
    ) {
        if (this.config.buyMoreReports === undefined) return

        if (this.state.error || this.state.loading) return

        this.state = {
            ...this.state,
            loading: true,
        }
        this.send()

        try {
            const data = await this.config.buyMoreReports(
                token,
                {
                    ...config,
                },
                searchResultIds
            )
            const items = this.state.items.map((item) => {
                return {
                    ...item,
                    isHidden: false,
                }
            })
            this.state = {
                ...this.state,
                loading: false,
                hasNext: true,
                items: items
                    .filter((item) => item.hiddenReportId === undefined)
                    .concat(data.items.map((item) => ({ ...item, isHidden: false }))),
                page: this.state.page,
                isHidden: true,
                cursor: data.cursor,
            }
        } catch (e) {
            console.error(e)
            this.state = {
                ...this.state,
                hasNext: false,
                loading: false,
                error: true,
                total: 0,
            }
        }

        this.send()
    }
}
