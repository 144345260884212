import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { RootStore } from '@/_helpers/StoreType'
import { IRawTikTokData } from '../types'
import ErSpread from '@/pages/Reports/BloggerReport/Cards/ErSpread/ErSpread'
import colors from '@/pages/Reports/BloggerReport/colorMap.json'
import { PopularTags } from '@yoloker/ui-collection'

import cn from './Content.module.scss'

const Content = () => {
    const { t } = useTranslation()
    const tiktokData: IRawTikTokData | undefined = useSelector((store: RootStore) => store.report.rawTikTokData)

    const erSpread = useMemo(() => {
        if (!tiktokData?.user_profile.top_posts) return { success: false }
        if (tiktokData?.user_profile.top_posts.length === 0) return { success: false }
        return {
            success: true,
            data: tiktokData?.user_profile.top_posts.map((item) => {
                return {
                    label: new Date(item.created).toString(),
                    url: item.link,
                    value: [
                        {
                            color: colors.erSpread.likes,
                            value: item.stat.likes ?? 0,
                        },
                        {
                            color: colors.erSpread.comments,
                            value: item.stat.comments ?? 0,
                        },
                    ],
                }
            }),
        }
    }, [tiktokData])

    const legend = [
        {
            color: colors.erSpread.likes,
            label: 'likes',
        },
        {
            color: colors.erSpread.comments,
            label: 'comments',
        },
    ]

    const topMentions = useMemo(() => {
        if (!tiktokData?.user_profile.top_mentions) return { success: false }
        if (tiktokData?.user_profile.top_mentions.length === 0) return { success: false }

        return {
            success: true,
            data: tiktokData?.user_profile?.top_mentions.map((item) => {
                return item.tag.replace('@', '')
            }),
        }
    }, [tiktokData])

    const hashtags = useMemo(() => {
        if (!tiktokData?.user_profile.top_hashtags) return { success: false }
        if (tiktokData?.user_profile.top_hashtags.length === 0) return { success: false }

        return {
            success: true,
            data: tiktokData?.user_profile?.top_hashtags.map((item) => {
                return item.tag.replace('#', '')
            }),
        }
    }, [tiktokData])

    return (
        <>
            {erSpread.success && erSpread.data && (
                <div className={cn.border}>
                    <ErSpread data={erSpread.data} legend={legend} title={'er_spread'} width="100%" />
                </div>
            )}

            {(topMentions.success || hashtags.success) && (
                <div className={cn.tags}>
                    <PopularTags
                        width={'100%'}
                        isDemo={false}
                        tagsTitle={'userTags'}
                        hashTagsTitle={'popularHashTags'}
                        tagsData={topMentions.data}
                        hashTagsData={hashtags.data}
                        tagsTooltip={t('report_titles.userTags.helper')}
                        hashTagsTooltip={t('report_titles.popularHashTags.helper')}
                    />
                </div>
            )}
        </>
    )
}
export default Content
