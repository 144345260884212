import React from 'react';
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'

import { HeaderMenu } from '../../components/HeaderMenu'

import Detective from './icons/detective.svg'
import Hello from './icons/hello.svg'
import Mac from './icons/mac.svg'

import cn from './AboutPage.module.scss'
import { globalWidth } from '@/globals.module.scss'

const MainLink = ({ value }) => <a href={`mailto:${value}`}>{value}</a>

const contacts = [
	{ label: "legal_name" },
	{ label: "inn" },
	{ label: "ogrn" },
	{ label: "email", Component: MainLink }
]

const AboutPage = props => {
	const { t } = useTranslation();
	const { source } = props;
	return (
		<div className = {cn.root}>
			<header className = {globalWidth}>
				<HeaderMenu xs = {props.width === "xs"}/>
			</header>
			<h1 className = {cn.h1}>{t("about.header")}</h1>
			<div className = {cn.container}>
				<div className = {cn["legend"]}>
					<div className = {cn["emoji"]}>
						<img src = {Detective} alt = "Detective" />
						<img src = {Hello} alt = "Hello" />
						<img src = {Mac} alt = "Mac" />
					</div>
					{
						source.loaded &&
						<div className = {cn.markdown}>
							<Markdown source={source.value.description}/>
						</div>
					}
				</div>
				<div className = {cn["contacts"]}>
					<h2>{t("about.contacts.header")}</h2>
					<ul>
						{
							source.loaded && !source.error && 
							contacts.map((Item) => (
								<li key={Item.label}>
									<h3>{t(`about.contacts.labels.${Item.label}`)}</h3>
									<p>
										{
											Item.Component ? 
											<Item.Component value = {source.value[Item.label]}/> :
											source.value[Item.label]
										}
									</p>
								</li>
							))
						}
					</ul>
				</div>
			</div>
		</div>
	)
}

export { AboutPage as default }
