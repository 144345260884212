import React from 'react'
import Icons from '@/components/Icons'
import { getLang } from '@/_helpers/getLang'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { SelectorFilter } from '../../../../filters/SelectorFilter'
import { getLanguages } from '../../queries'
import { ILanguagesQuery } from '../../types'
import { preprocessLanguages } from '../../utils/filters/prepocess'
import { toQueryWithOR } from '../../utils/queries/toQueryWithOR'
import { PLATFORM } from '../consts'

const getGqlFilter = (languages: string[]) => {
    if (!languages || languages.length === 0) return ''

    const filterName = 'channel_language'
    const filterId = 'code'
    return toQueryWithOR(languages, filterName, filterId)
}

export const getLanguagesFilter = (fetcher: Fetcher) => {
    return new SelectorFilter({
        platform: PLATFORM,
        title: 'filters.channel_language.label',
        autocomplete: true,
        helper: 'filters.channel_language.text',
        space: 1,
        isMulti: true,
        icon: <Icons name="filter--language" />,
        options: async (token: string) => {
            const client = fetcher.getClient()
            return await client
                .query<ILanguagesQuery>({ query: getLanguages(getLang()) })
                .then((res) => {
                    const languages = res.data?.youtube_languagestranslate
                    return preprocessLanguages(languages)
                })
        },
        beforeSend: ({ value }) => {
            return {
                name: 'location.country',
                operator: 'terms',
                type: 'array',
                value: value,
                gqlFilter: getGqlFilter(value),
            }
        },
    })
}
