import React from 'react'
import Icons from '@/components/Icons'
import { SearchFilter } from '@/pages/SearchPage/SearchAdapter/filters/SearchFilter'

const getGqlFilter = (text: string) => {
    if (!text || text.length === 0) return ''

    return `keywords: "${text}"`
}

export const getKeywordsFilter = (isMain?: boolean) => {
    return new SearchFilter({
        platform: 'infl_tiktok',
        title: 'filters.search.label',
        icon: <Icons name="search" />,
        helper: 'filters.username.chaining.text',
        placeholder: 'filters.search.placeholder',
        space: 1,
        beforeSend: (value) => ({
            name: 'info.biography',
            operator: 'equal',
            type: 'text',
            value: value,
            gqlFilter: getGqlFilter(value),
        }),
    })
}
