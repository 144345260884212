import Observable from './Observable'

// const dev = process.env.NODE_ENV === "development";

const rootDomain =
    process.env.REACT_APP_ROOT_DOMAIN || 'https://app.yolotech.ru'

const whiteList = [
    process.env.REACT_APP_SECOND_DOMAIN || 'https://app2.yolotech.ru',
]

const GET_TOKEN = 'lasjdkhgqoqdlkslks'
const SEND_TOKEN = '12198alksmala;;'

export const checkExternalDomain = () =>
    new Promise<{ token: string }>((resolve, reject) => {
        if (window.location.origin === rootDomain)
            return reject(new Error('This is root domain'))

        const iframe = getIframe()
        iframe.onload = () => {
            if (!iframe.contentWindow) return

            // console.log("# Iframe loaded")

            const sub = getTokenObservable.subscribe(({ token }) => {
                // console.log("# token received");

                sub.unsubscribe()
                resolve({ token })
            })

            setTimeout(() => {
                if (!sub.expired) sub.unsubscribe()
            }, 1000)

            iframe.contentWindow.postMessage({ action: GET_TOKEN }, rootDomain)
        }
        iframe.onerror = reject
    })

export const isRootDomain = window.location.origin === rootDomain

function getIframe(): HTMLIFrameElement {
    const i = document.querySelector<HTMLIFrameElement>(
        `iframe[src="${rootDomain}"]`
    )
    if (i) return i
    const iframe = document.createElement('iframe')
    iframe.src = rootDomain
    iframe.style.display = 'none'
    document.body.appendChild(iframe)

    return iframe
}

const getTokenObservable = new Observable<{ token: string }>()
getTokenObservable.onUnsubscribe = (count) => {
    if (count === 0) {
        const i = document.querySelector<HTMLIFrameElement>(
            `iframe[src="${rootDomain}"]`
        )
        if (i) i.remove()
    }
}

window.addEventListener('message', (event) => {
    // console.log(`# Message! ${window.location.origin}; isRoot: ${isRootDomain}; Action: ${event.data.action}`)
    if (
        event.source instanceof MessagePort ||
        (!!window.ServiceWorker && event.source instanceof window.ServiceWorker)
    )
        return

    if (event.data.action === GET_TOKEN) {
        if (!whiteList.includes(event.origin) || !isRootDomain) return
        // console.log(`# REQUEST! origin: ${window.location.origin}; caller: ${event.origin}`)
        const item = localStorage.getItem('user')
        if (!item) return

        try {
            const json = JSON.parse(item)
            if (!json.token) return

            if (event.source) {
                event.source.postMessage(
                    {
                        action: SEND_TOKEN,
                        token: json.token,
                    },
                    '*'
                )
            }
        } catch (e) {
            console.error(e)
        }
    } else if (event.data.action === SEND_TOKEN) {
        if (event.origin !== rootDomain) return
        const token: string | undefined = event.data.token
        if (!token) return
        // console.log(`# Token received: ${event.data.token}`)
        getTokenObservable.update({ token })
    } /* else {
        console.log(`# Unhandled message. Data: `, event.data)
    } */
})
