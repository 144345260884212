import React, { FC } from 'react'

import cn from './Colorize.module.scss'
import { useInterpolate } from '../../useInterpolate'

export interface IColorize {
    percentage: number
}

const Colorize: FC<IColorize> = ({ percentage }) => {
    const color = useInterpolate(percentage / 100)
    const background = useInterpolate(percentage / 100, null, 0.2)

    return (
        <div
            style={{
                color,
                background,
            }}
            className={cn.root}
        >
            {percentage}%
        </div>
    )
}

export { Colorize as default }
