import Popup from '@/_yc/Popup'
import React, { FC, useCallback, useMemo, useState } from 'react'

import cn from './EditGmailAccount.module.scss'
import Flex from '@/_yc/Flex'
import Text from '@/components/Text'
import Icons from '@/components/Icons'
import { useSpaceTranslation } from '@/_helpers'
import { Input } from '@/components/Input/Input'
import LoadingButton from '@/components/Share/LoadingButton'
import { SmtpData, editAccountReq } from '@/pages/Outreach/Service/Campaigns'
import useToken from '@/_helpers/Hooks/useToken'
import { EmailAccount } from '@/pages/SettingsPage/EmailAccounts/EmailAccounts'
import { z } from 'zod'

interface EditGmailPopupProps {
    isOpen: boolean
    close: () => void
    account: EmailAccount
}

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 700,
    zIndex: 2000,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}

export const EditGmailPopup: FC<EditGmailPopupProps> = ({ isOpen, close, account }) => {
    const t = useSpaceTranslation('settings.mail.smtp')

    const smtpSchema = useMemo(
        () =>
            z.object({
                email: z.string().email('Invalid email address'),
                sender_name: z.string().min(1, 'Sender name is required'),
                signature: z.string().min(1, 'Signature is required'),
                total_emails: z.string().min(1, 'Total emails is required'),
            }),
        []
    )

    const [errors, setErrors] = useState({
        email: [],
        sender_name: [],
        signature: [],
        total_emails: [],
    })

    const [email, setEmail] = useState(account.email)
    const [senderName, setSenderName] = useState(account.senderName)

    const [totalEmails, setTotalEmails] = useState(account.dailyLimit.toString())

    const [signature, setSignature] = useState(account.signature)

    const token = useToken()

    const validSchema = useCallback(() => {
        try {
            smtpSchema.parse({
                email,
                sender_name: senderName,
                signature,
                total_emails: totalEmails,
            })
            return true
            // @ts-ignore
        } catch (e: any) {
            setErrors(e.formErrors.fieldErrors)
            console.log(e)
            return false
        }
    }, [email, senderName, signature, totalEmails, smtpSchema])

    const handleEditAccount = useCallback(() => {
        if (!token) return

        if (!validSchema()) return

        const data: SmtpData = {
            email: email,
            sender_name: senderName,
            signature: signature,
            email_server_type: 'gmail',
            day_limit: parseInt(totalEmails),
            daily_ramp_up: 40,
        }
        editAccountReq(token, data, account.id).then((res) => {
            console.log(res)
        })

        close()
    }, [validSchema, email, senderName, totalEmails, signature, token, close, account])

    return (
        <div className={cn.root}>
            <Popup style={style} isOpen={isOpen} onClose={close}>
                <div className={cn.popup}>
                    <div className={cn.header}>
                        <Flex content="space-between" align="center">
                            <Text fSize={20}>{t('edit_gmail_title')}</Text>
                            <div onClick={close}>
                                <Icons name="close" className={cn['popup--close']} />
                            </div>
                        </Flex>
                    </div>

                    <div className={cn.grid} style={{ marginTop: 46 }}>
                        <div>
                            <Input
                                label={t('email.title')}
                                placeholder={t('email.placeholder')}
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                disabled
                            />
                            {errors.email?.length > 0 && (
                                <Text color="red-1" fSize={10}>
                                    {errors.email[0]}
                                </Text>
                            )}
                        </div>
                        <div>
                            <Input
                                label={t('sender_name.title')}
                                placeholder={t('sender_name.placeholder')}
                                value={senderName}
                                onChange={(e) => setSenderName(e.target.value)}
                            />
                            {errors.sender_name?.length > 0 && (
                                <Text color="red-1" fSize={10}>
                                    {errors.sender_name[0]}
                                </Text>
                            )}
                        </div>
                    </div>

                    <div style={{ marginTop: 30 }}>
                        <Text fSize={16} component="span">
                            {t('safety.title')}
                        </Text>
                        <Text fSize={16} component="span" color="gray-3">
                            {' '}
                            {t('safety.optional')}
                        </Text>
                    </div>

                    <div className={cn.grid} style={{ marginTop: 18 }}>
                        <div>
                            <Input
                                label={t('safety.total_emails.label')}
                                value={totalEmails}
                                onChange={(e) => setTotalEmails(e.target.value)}
                            />
                            {errors.total_emails?.length > 0 && (
                                <Text color="red-1" fSize={10}>
                                    {errors.total_emails[0]}
                                </Text>
                            )}
                        </div>
                        <Text color="gray-new-2" fSize={12} margin="18px 0 0 0">
                            {t('safety.total_emails.explainer')}
                        </Text>
                    </div>

                    <div className={cn.signature}>
                        <Text fSize={12}>{t('signature.title')}</Text>

                        <div className={cn.editor}>
                            <textarea
                                className={cn.editorArea}
                                placeholder={t('signature.placeholder')}
                                value={signature}
                                onChange={(e) => setSignature(e.target.value)}
                            ></textarea>
                        </div>

                        {errors.signature.length > 0 && (
                            <Text color="red-1" fSize={10}>
                                {errors.signature[0]}
                            </Text>
                        )}
                    </div>

                    <div className={cn.footer}>
                        <LoadingButton variant="primary" noDownloadIcon noLoadingText onClick={handleEditAccount}>
                            {t('btn_save')}
                        </LoadingButton>
                    </div>
                </div>
            </Popup>
        </div>
    )
}
