import React, { FC } from 'react'

import Button from '@/components/Button'
import useOpenClose from '@/_helpers/useOpenClose'
import Popup from '@/_yc/Popup/Popup'
import Flex from '@/_yc/Flex'
import { Report, IPostReport } from './Report'
import cn from './Report.module.scss'
import { useTranslation } from 'react-i18next'

const style = {
    zIndex: 120,
    width: '70vw',
    height: '100vh',
    marginLeft: '30%',
}
interface IPop extends IPostReport {
    disabled?: boolean
}

export const PostReport: FC<IPop> = ({ post_id, platform, disabled, channel_name }) => {
    const [isOpen, open, close] = useOpenClose()
    const { t } = useTranslation()

    return (
        <>
            <Button style={{ width: '100%', padding: '0 10px' }} onClick={open} disabled={disabled}>
                {t('campaign_manager.campaign.content.report.stats')}
            </Button>
            {isOpen && (
                <div className={cn.root}>
                    <Popup style={style} isOpen={isOpen} onClose={close} placement="right">
                        <Flex className={cn.popup} column>
                            <Report
                                post_id={post_id}
                                platform={platform}
                                closeFunc={close}
                                channel_name={channel_name}
                            />
                        </Flex>
                    </Popup>
                </div>
            )}
        </>
    )
}
