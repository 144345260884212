import cn from '../Editor.module.scss'
const exampleTheme = {
    ltr: cn["ltr"],
    rtl: cn["rtl"],
    placeholder: cn["editor-placeholder"],
    paragraph: cn["editor-paragraph"],
    quote: cn["editor-quote"],
    heading: {
      h1: cn["editor-heading-h1"],
      h2: cn["editor-heading-h2"],
      h3: cn["editor-heading-h3"],
      h4: cn["editor-heading-h4"],
      h5: cn["editor-heading-h5"]
    },
    list: {
      nested: {
        listitem: cn["editor-nested-listitem"]
      },
      ol: cn["editor-list-ol"],
      ul: cn["editor-list-ul"],
      listitem: cn["editor-listitem"]
    },
    image: cn["editor-image"],
    link: cn["editor-link"],
    text: {
      bold: cn["editor-text-bold"],
      italic: cn["editor-text-italic"],
      overflowed: cn["editor-text-overflowed"],
      hashtag: cn["editor-text-hashtag"],
      underline: cn["editor-text-underline"],
      strikethrough: cn["editor-text-strikethrough"],
      underlineStrikethrough: cn["editor-text-underlineStrikethrough"],
      code: cn["editor-text-code"]
    },
    code: cn["editor-code"],
    codeHighlight: {
      atrule: cn["editor-tokenAttr"],
      attr: cn["editor-tokenAttr"],
      boolean: cn["editor-tokenProperty"],
      builtin: cn["editor-tokenSelector"],
      cdata: cn["editor-tokenComment"],
      char: cn["editor-tokenSelector"],
      class: cn["editor-tokenFunction"],
      "class-name": cn["editor-tokenFunction"],
      comment: cn["editor-tokenComment"],
      constant: cn["editor-tokenProperty"],
      deleted: cn["editor-tokenProperty"],
      doctype: cn["editor-tokenComment"],
      entity: cn["editor-tokenOperator"],
      function: cn["editor-tokenFunction"],
      important: cn["editor-tokenVariable"],
      inserted: cn["editor-tokenSelector"],
      keyword: cn["editor-tokenAttr"],
      namespace: cn["editor-tokenVariable"],
      number: cn["editor-tokenProperty"],
      operator: cn["editor-tokenOperator"],
      prolog: cn["editor-tokenComment"],
      property: cn["editor-tokenProperty"],
      punctuation: cn["editor-tokenPunctuation"],
      regex: cn["editor-tokenVariable"],
      selector: cn["editor-tokenSelector"],
      string: cn["editor-tokenSelector"],
      symbol: cn["editor-tokenProperty"],
      tag: cn["editor-tokenProperty"],
      url: cn["editor-tokenOperator"],
      variable: cn["editor-tokenVariable"]
    }
  };
  
  export default exampleTheme;
  