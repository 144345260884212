import useClass from '@/_helpers/Hooks/useClass'
import React, { FC, memo, HTMLAttributes, useMemo } from 'react'
import { getColor } from '../color'
import cn from './Heading.module.scss'

export interface IHeading extends HTMLAttributes<HTMLHeadingElement> {
    type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    fSize?: number
    margin?: string | number
    color?: string
}

const Heading: FC<IHeading> = ({
    type: Component = 'h1',
    fSize,
    margin,
    className,
    style,
    color,
    ...props
}) => {
    const st = useMemo(
        () =>
            !fSize && !margin && !style && !color
                ? undefined
                : {
                      margin,
                      fontSize: fSize,
                      color: color && getColor(color),
                      ...style,
                  },
        [fSize, margin, style, color]
    )

    const root = useClass(cn.root, className)

    return <Component {...props} style={st} className={root} />
}

export default memo(Heading)
