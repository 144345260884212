import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.74738 16.3068C8.30487 16.3081 7.88023 16.1322 7.56822 15.8184L1.03988 9.28926C0.695373 8.94573 0.518724 8.46847 0.556549 7.98342L0.973216 2.51176C1.03263 1.68875 1.68838 1.03477 2.51155 0.977591L7.98322 0.560924C8.02572 0.551758 8.06905 0.551758 8.11155 0.551758C8.55309 0.552891 8.9763 0.728407 9.28905 1.04009L15.8182 7.56842C16.1309 7.881 16.3066 8.30503 16.3066 8.74717C16.3066 9.18932 16.1309 9.61334 15.8182 9.92592L9.92572 15.8184C9.61391 16.132 9.18961 16.3079 8.74738 16.3068ZM8.11072 2.21842L2.63488 2.63509L2.21822 8.11092L8.74738 14.6401L14.6391 8.74842L8.11072 2.21842ZM5.21155 6.87843C4.41642 6.87859 3.73189 6.31706 3.57662 5.53723C3.42134 4.75741 3.83855 3.97649 4.57311 3.67207C5.30766 3.36766 6.15496 3.62452 6.59682 4.28558C7.03868 4.94663 6.95204 5.82776 6.38988 6.39009C6.07807 6.70369 5.65378 6.87953 5.21155 6.87843Z"
            fill="#8F8F8F"
        />
    </svg>
)
