import React, { useRef, FC, useState, useEffect } from 'react'

import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import useOpenClose from '@/_helpers/useOpenClose'
import Text from '@/components/Text'
import Popup from '@/_yc/Popup/Popup'
import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'
import useToken from '@/_helpers/Hooks/useToken'
import { getAdvertisingCampaignsReq } from '@/pages/Outreach/Service/Campaigns'

import { SendToMailingList } from './SendToMailingList/SendToMailingList'

import cn from './ToOutreach.module.scss'

interface IInstrumentsPopUp {}
const ToOutreach: FC<IInstrumentsPopUp> = () => {
    const t = useSpaceTranslation('aud_insides.send_to_mail')
    const ref = useRef(null)
    const [isOpen, open, close] = useOpenClose()
    const token = useToken()

    const [isCampaigns, setIsCampaigns] = useState<boolean>(false)
    useEffect(() => {
        if (!token) return
        getAdvertisingCampaignsReq(token).then((res) => {
            setIsCampaigns(res.results.length > 0)
        })
    }, [token])

    return (
        <>
            <Flex ref={ref} onClick={open} align="center" margin="0 0 0 30px" className={cn.btn}>
                <Icons name="message" />

                <Text color="gray-2" fSize={10} semibold margin="0 0 0 6px">
                    {t('add_to_outreach')}
                </Text>
            </Flex>

            {isOpen && ref && ref.current && (
                <>
                    <Popup
                        placement="bottom-start"
                        isOpen={isOpen}
                        onClose={close}
                        anchor={ref.current}
                        style={{ zIndex: 5 }}
                    >
                        <Flex className={cn.popup} column>
                            <SendToMailingList independentBTN={false} addType="new" />

                            {isCampaigns && <SendToMailingList independentBTN={false} addType="add" />}
                        </Flex>
                    </Popup>
                </>
            )}
        </>
    )
}

export default ToOutreach
