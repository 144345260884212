import { APIService } from '@/_helpers/APIService.js'
import { authHeader } from '@/_helpers/auth-header.js'
import Observable from '@/_helpers/Observable';

export const service = new APIService({
	methods:{
		loadReview: id=>({
			url: `/api/reports/details/${id}/`,
			method: "GET",
			headers: authHeader()
		}),
		requireReview: (external_id, data)=>({
			url: "/api/reports/details/",
			method: "POST",
			headers: authHeader(),
			data: { external_id, ...data }
		}),
		getPdfId: (report_id) => ({
			url: `/api/reports/details/${report_id}/pdf/`,
			method: "POST",
			headers: authHeader()
		}),
		pollingService: (report_id, task_id) => ({
			url: `/api/reports/details/${report_id}/pdf/`,
			method: "GET",
			headers: authHeader(),
			params: { task_id }
		}),
		downloadPDF: (url) => ({
			url,
			method: "GET",
			responseType: 'arraybuffer',
			headers: authHeader()
		}),
		pollingUpdates: (external_id) => ({
			headers: authHeader(),
			url: "/api/reports/check-is-fresh/",
			params: { external_id }
		})
	}
})

class ResizeService extends Observable {
	constructor(){
		super();
		window.addEventListener("resize", this.handle, false);
	}
	handle = (resizeEvent) => {
		this.update(resizeEvent)
	}
}

export const resizeService = new ResizeService()