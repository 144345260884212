import React, { SVGAttributes } from 'react'
const RedCross = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.6587 0.166748L6.00033 4.82508L1.34199 0.166748L0.166992 1.34175L4.82533 6.00008L0.166992 10.6584L1.34199 11.8334L6.00033 7.17508L10.6587 11.8334L11.8337 10.6584L7.17533 6.00008L11.8337 1.34175L10.6587 0.166748Z"
            fill="#F8848C"
        />
    </svg>
)

export default RedCross
