import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useToken from '@/_helpers/Hooks/useToken'
import { RootStore } from '@/_helpers/StoreType'

import { getFavoritesBloggersReq } from '../model/service'
import { setLikes } from '../model/actions'
import { FavoriteBloggers } from '../model/types'

const useLikes = () => {
    const token = useToken()
    const dispatch = useDispatch()
    const likeBloggers = useSelector((store: RootStore) => store.AudienceInsights.likeBloggers)

    useEffect(() => {
        if (!token) return
        getFavoritesBloggersReq(token).then((res: FavoriteBloggers[]) => {
            dispatch(setLikes(res))
        })
    }, [dispatch, token])

    return likeBloggers
}

export default useLikes
