import { createReducer } from '@reduxjs/toolkit'
import { ISearchState, IClickedFilter } from './types'

import * as Actions from './actions'

const defaultState: ISearchState = {
    sorting: null,
    search: '',
    searchBy: ['info.biography', 'info.fullName', 'info.username'],
    filters: {},
}
const defaultStateClickedFilter: IClickedFilter = {
    clickedFilter: null,
}
export const clickedFilter = createReducer<IClickedFilter>(defaultStateClickedFilter, (builder) =>
    builder.addCase(Actions.setClickedFilter, (state, { payload }) => ({
        clickedFilter: payload.filterId,
    }))
)

export default createReducer<ISearchState>(defaultState, (builder) =>
    builder
        .addCase(Actions.setSorting, (state, { payload }) => ({
            ...state,
            sorting: payload.sorting,
        }))
        .addCase(Actions.setSearchText, (state, { payload }) => ({
            ...state,
            search: payload.search,
        }))
        .addCase(Actions.clearSearchText, (state) => ({
            ...state,
            search: '',
        }))
        .addCase(Actions.addToSearchBy, (state, { payload }) => ({
            ...state,
            searchBy: state.searchBy.concat(payload.value),
        }))
        .addCase(Actions.removeFromSearchBy, (state, { payload }) => ({
            ...state,
            searchBy: state.searchBy.filter((item) => item !== payload.value),
        }))
        .addCase(Actions.toggleSearchBy, (state, { payload }) => ({
            ...state,
            searchBy: state.searchBy.includes(payload.value)
                ? state.searchBy.filter((item) => item !== payload.value)
                : state.searchBy.concat(payload.value),
        }))
        .addCase(Actions.clearSearchBy, (state) => ({
            ...state,
            searchBy: [],
        }))
        .addCase(Actions.setFilter, (state, { payload }) => ({
            ...state,
            filters: {
                ...state.filters,
                [payload.key]: {
                    value: payload.value,
                },
            },
        }))
        .addCase(Actions.clearAll, (state) => ({
            ...state,
            filters: {},
        }))
        .addCase(Actions.clearFilter, (state, { payload }) => {
            const filters = { ...state.filters }
            delete filters[payload.key]

            return {
                ...state,
                filters,
            }
        })
)
