import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.96716 7.33621L4.80835 9.68148C5.03556 9.68148 5.13396 9.579 5.25196 9.45594L6.31719 8.38704L8.52444 10.0843C8.92925 10.3211 9.21446 10.1964 9.32366 9.69324L10.7725 2.56504L10.7729 2.56462C10.9013 1.9363 10.5565 1.6906 10.1621 1.84474L1.64588 5.26814C1.06466 5.50502 1.07346 5.84522 1.54707 5.99936L3.72433 6.71041L8.78165 3.38781C9.01965 3.22233 9.23606 3.31389 9.05805 3.47937L4.96716 7.33621Z"
                fill="#5D697D"
            />
        </svg>
    )
}
