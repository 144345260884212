import Text from '@/components/Text'
import Flex from '@/_yc/Flex'
import React, { FC } from 'react'
import { HTMLAttributes } from 'react'
import { useCallback, ReactNode } from 'react'
import cn from './PartChanger.module.scss'

export interface IDispItem extends HTMLAttributes<HTMLElement> {
    value: string | number
    func: (value: string | number) => void
    title: string
    index: number
    setIndex: React.Dispatch<React.SetStateAction<number>>
    children?: ReactNode
    soon?: boolean
}

const DispatchItem: FC<IDispItem> = ({ value, title, func, setIndex, index, ...props }) => {
    const onClick = useCallback(() => {
        if (props.soon) return

        func(value)
        setIndex(index)
    }, [func, value, setIndex, index, props.soon])
    return (
        <Flex onClick={onClick} className={props.className} align="center">
            <Text className={cn['item--text']}>{title}</Text>
            {props.children}
        </Flex>
    )
}

export default DispatchItem
