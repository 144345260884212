import React, { FC } from 'react'
import Text from '@/components/Text'
import { Flex } from '@/_yc'
import cn from './Row.module.scss'
import { MassAddCheckBoxNEW } from '@/components/MassAddToList'
import { getNumberWithSpaces } from '@/_helpers/nums/numberWithSpaces'
import { MarkerDot } from '@/components/MarkerDot/MarkerDot'
import { useDispatch } from 'react-redux'
import { setCurrentCampaign } from '@/pages/Outreach/Model/actions'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { Campaign } from '@/pages/Outreach/Service/Campaigns'
import { shortNum } from '@/_helpers/_graphs/_tech'
import { getShortString } from '@/_helpers/strings/getShortString'

interface RowProps {
    data: Campaign
    index: number
}

export const Row: FC<RowProps> = ({ data, index }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const onCampaignNameClick = () => {
        dispatch(setCurrentCampaign(data.id.toString()))
        history.push('/outreach/messenger/')
    }

    const getOpenRateColor = (rate: number) => {
        if (rate < 0.15) return 'red'
        if (rate < 0.25) return 'yellow'
        return 'green'
    }

    const getBounceRateColor = (rate: number) => {
        if (rate < 0.1) return 'green'
        if (rate < 0.25) return 'yellow'
        return 'red'
    }

    const getRepliedRateColor = (rate: number) => {
        if (rate < 0.05) return 'red'
        if (rate < 0.1) return 'yellow'
        return 'green'
    }

    const repliedRate = (data.stats.answered_chats_count / data.stats.chats_count) * 100

    return (
        <div className={cn.row}>
            <MassAddCheckBoxNEW id={data.id.toString()} type={'outreach'} index={index + 1} />
            <Flex column>
                <Text semibold style={{ lineHeight: '18px', cursor: 'pointer' }} onClick={onCampaignNameClick}>
                    {getShortString(data.name, 24)}
                </Text>
                <Text fSize={12} color="gray-3" margin="4px 0 0 0" style={{ lineHeight: '16px' }}>
                    {data.stats.chats_count} influencers
                </Text>
            </Flex>

            <div className={cn[`state--${data.stats.status}`]}>
                <Text fSize={10} className={cn[`color--${data.stats.status}`]}>
                    {data.stats.status}
                </Text>
            </div>

            <div className={cn.numberItem}>
                <Text>{getNumberWithSpaces(data.stats.chats_count)} </Text>
            </div>

            <div className={cn.numberItem}>
                <MarkerDot color={getOpenRateColor(data.stats.opened_rate_relative)} />
                <Text>
                    {getNumberWithSpaces(data.stats.opened_rate_absolute)}{' '}
                    {`(${shortNum(data.stats.opened_rate_relative * 100, 1, 1)}%)`}
                </Text>
            </div>

            <div className={cn.numberItem}>
                <MarkerDot color={getRepliedRateColor(repliedRate / 100)} />
                <Text>
                    {getNumberWithSpaces(data.stats.answered_chats_count)} {`(${shortNum(repliedRate, 1, 1)}%)`}
                </Text>
            </div>

            <div className={cn.numberItem}>
                <MarkerDot color={getBounceRateColor(data.stats.bounce_rate_relative)} />
                <Text>
                    {getNumberWithSpaces(data.stats.bounce_rate_absolute)}{' '}
                    {`(${shortNum(data.stats.bounce_rate_relative * 100, 1, 1)}%)`}
                </Text>
            </div>

            <Text>{data.stats.sender}</Text>

            <Text>{moment(data.stats.created_at).format('DD.MM.YY')}</Text>
        </div>
    )
}
