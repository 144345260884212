import React from 'react';

export default () => (
	<div style = {{ width: 13, height: 13, marginRight: 5, position: 'relative' }}>
		<svg style = {{position: 'absolute', right: 0, top: 0}} width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M10.1918 7.2379e-05C10.1562 -0.00158445 7.71525 0.0257533 7.71525 0.0257533C7.28153 0.0307238 6.93251 0.446589 6.93844 0.872395C6.94395 1.29406 7.2955 1.69916 7.725 1.69916C7.72754 1.69916 7.73177 1.69916 7.73516 1.69916L8.18964 1.62584L7.36158 2.40331C6.68642 1.94437 5.888 1.6772 5.04935 1.6772C3.96545 1.6772 2.94636 2.08188 2.17971 2.83119C0.954763 4.02866 0.6786 5.80396 1.34868 7.26404C1.39188 7.27813 1.43551 7.29138 1.47998 7.30339C1.6278 7.34274 1.78029 7.36387 1.93235 7.36884C1.95437 7.36967 1.97682 7.37008 1.99927 7.37008C2.37285 7.37008 2.73712 7.2686 3.05479 7.07641C2.33346 6.12621 2.41224 4.77424 3.29368 3.91186C3.76214 3.45333 4.38605 3.20025 5.04977 3.20025C5.71349 3.20025 6.33698 3.45291 6.80586 3.91186C7.7754 4.85956 7.7754 6.40125 6.80586 7.34813C6.44838 7.69855 6.00025 7.92844 5.5123 8.01749C5.47884 8.06057 5.44453 8.10365 5.4098 8.14548C4.9532 8.68809 4.36995 9.11266 3.71385 9.38355C4.13784 9.52728 4.58766 9.60225 5.04935 9.60225C6.13367 9.60225 7.15276 9.18763 7.91984 8.43791C9.29557 7.09215 9.52217 5.01282 8.50647 3.47528L9.44212 2.60876V3.14681C9.44212 3.57179 9.78605 3.91683 10.2206 3.91683C10.6556 3.91683 11 3.57179 11 3.14681V0.763873C11.0004 0.558011 10.9648 0.0468779 10.1918 7.2379e-05Z" fill="white"/>
		</svg>
		<svg style = {{position: 'absolute', left: 0, bottom: 0}} width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M5.64949 9.11328H4.70706V8.24261C6.57582 7.9129 7.99094 6.31447 7.99094 4.39793C7.99094 3.87975 7.88971 3.38519 7.7008 2.93246C7.49495 2.85666 7.27554 2.81607 7.04894 2.81607C6.68933 2.81607 6.34709 2.91589 6.05187 3.10146C6.29965 3.47549 6.44367 3.92035 6.44367 4.39793C6.44367 5.71677 5.34791 6.78915 3.99928 6.78915C2.65151 6.78915 1.55532 5.71677 1.55532 4.39793C1.55532 3.3943 2.19109 2.5344 3.08862 2.18026C3.2716 1.89984 3.4872 1.6364 3.73371 1.39575C4.07002 1.06728 4.44953 0.79473 4.86081 0.583898C4.5838 0.524252 4.29578 0.491943 4.00098 0.491943C1.79887 0.491943 0 2.24487 0 4.39834C0 6.27222 1.35879 7.84041 3.15004 8.21734V9.11369H2.27199C1.84377 9.11369 1.49645 9.45707 1.49645 9.875C1.49645 10.2942 1.84377 10.6371 2.27199 10.6371H3.15004V11.2427C3.15004 11.6607 3.50075 11.9999 3.92855 11.9999C4.35677 11.9999 4.70706 11.6607 4.70706 11.2427V10.6371H5.64949C6.07771 10.6371 6.42418 10.2942 6.42418 9.875C6.42418 9.45665 6.07728 9.11328 5.64949 9.11328Z" fill="white"/>
		</svg>
	</div>
)