import Heading from '@/components/Heading'
import Text from '@/components/Text'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { Flex, Modal } from '@/_yc'
import React, { FC, memo, useCallback } from 'react'
import cn from './DeletionModal.module.scss'
import Button from '@/components/Button'

export interface IDeletionModal {
    onClose?: VoidFunction
    intersectionId: number
    onDelete?: (id: number) => void
}

const DeletionModal: FC<IDeletionModal> = ({ onDelete, ...props }) => {
    const t = useSpaceTranslation('intersections.dashboards.deletion')

    const click = useCallback(() => {
        onDelete && onDelete(props.intersectionId)
    }, [onDelete, props.intersectionId])

    return (
        <Modal className={cn.root} onClose={props.onClose}>
            <Heading type="h5">{t('title')}</Heading>
            <Text fSize={12} margin="10px 0 0" color="gray-3">
                {t('text')}
            </Text>
            <Flex content="space-between" margin="32px 0 0">
                <Button width="calc(50% - 10px)" onClick={click} variant="primary">
                    {t('delete')}
                </Button>
                <Button width="calc(50% - 10px)" onClick={props.onClose}>
                    {t('cancel')}
                </Button>
            </Flex>
        </Modal>
    )
}

export default memo(DeletionModal)
