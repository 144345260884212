export default {
    "id": 2900,
    "name": "Overlap Example",
    "subscription": 36899,
    "status": "SUCCESS",
    "bloggers": [
        354945278,
        221890539,
        18774670,
        334829422,
        2022206328,
        204415100,
        315840863,
        31251706,
        45024121,
        143827821,
        741697042,
        2042498632,
        311265518,
        47240880,
        598695438,
        53320670,
        1392846239,
        609314488,
        4736267424,
        919623721,
        26633036,
        323622580,
        25758310,
        144617429,
        216224860,
        627845169,
        1555690962,
        213172152,
        322011776,
        224907941,
        292251162,
        1202685807,
        42131722,
        1578035865,
        3583686062,
        657715276,
        3073459808,
        182709505
    ],
    "data": {
        "task_id": "f738bef6-26b5-4d79-9aa9-c9a7e08cbb33",
        "task_status": "SUCCESS",
        "blogger_info": {
            "18774670": {
                "aq": 0.6545858691517688,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Franciny Ehlke",
                "followers": 15561451,
                "geo_share": 0.1526214658248696,
                "gender_share": 0.8322812363952982
            },
            "25758310": {
                "aq": 0.13887569857909923,
                "age": "25-34",
                "geo": "Рио-де-Жанейро",
                "gender": 2,
                "fullname": "THAMIRIS MACEDO ✨",
                "followers": 50764,
                "geo_share": 0.16202945990180032,
                "gender_share": 0.6399142653012622
            },
            "26633036": {
                "aq": 0.6977089123453768,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Anitta 🎤",
                "followers": 63276751,
                "geo_share": 0.12582781456953643,
                "gender_share": 0.5845377692937006
            },
            "31251706": {
                "aq": 0.08897781011276829,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 1,
                "fullname": "DJ PAULLO GÓES",
                "followers": 70388,
                "geo_share": 0.15252211331580207,
                "gender_share": 0.817625284738041
            },
            "42131722": {
                "aq": 0.5642358175172038,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "João Almeida",
                "followers": 1198703,
                "geo_share": 0.16067312125403413,
                "gender_share": 0.7776592908557718
            },
            "45024121": {
                "aq": 0.23694714992814944,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Beto Almeida",
                "followers": 50318,
                "geo_share": 0.36303630363036304,
                "gender_share": 0.6979143305673918
            },
            "47240880": {
                "aq": 0.1259865824782952,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Júnior",
                "followers": 93747,
                "geo_share": 0.14324178782983307,
                "gender_share": 0.6189114573062512
            },
            "53320670": {
                "aq": 0.3367951471664267,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Bianca Schiavon",
                "followers": 104044,
                "geo_share": 0.22263147125025579,
                "gender_share": 0.5166377816291161
            },
            "143827821": {
                "aq": 0.2102853117294822,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "MIH CEZAR- Dicas P/ Corredores",
                "followers": 85664,
                "geo_share": 0.16112735974474873,
                "gender_share": 0.5870712401055409
            },
            "144617429": {
                "aq": 0.42339278053563767,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 1,
                "fullname": "Ronaldo Carvalho",
                "followers": 62106,
                "geo_share": 0.21043771043771045,
                "gender_share": 0.6897102897102897
            },
            "182709505": {
                "aq": 0.3161766762706649,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Bruna Rosa",
                "followers": 48971,
                "geo_share": 0.29237947122861585,
                "gender_share": 0.8406871000336814
            },
            "204415100": {
                "aq": 0.4945833476631014,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 1,
                "fullname": "ingrid",
                "followers": 78421,
                "geo_share": 0.24584655590815754,
                "gender_share": 0.7805025734181048
            },
            "213172152": {
                "aq": 0.24877388804329442,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Levis Novaes",
                "followers": 6473,
                "geo_share": 0.3823134471513524,
                "gender_share": 0.555352241537054
            },
            "216224860": {
                "aq": 0.47123998114097126,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 1,
                "fullname": "Nicky",
                "followers": 84726,
                "geo_share": 0.20335570469798658,
                "gender_share": 0.6915422885572139
            },
            "221890539": {
                "aq": 0.5577143718993574,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Malu Camargo",
                "followers": 1092886,
                "geo_share": 0.1582265181760874,
                "gender_share": 0.7002899332479267
            },
            "224907941": {
                "aq": 0.16529993091225856,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Na Panela com Kaito Casagrandi",
                "followers": 62690,
                "geo_share": 0.15124458874458874,
                "gender_share": 0.6867428766422112
            },
            "292251162": {
                "aq": 0.2940251885159468,
                "age": "25-34",
                "geo": "Рио-де-Жанейро",
                "gender": 2,
                "fullname": "𝐽𝑢 𝐹𝑟𝑎𝑛𝑐̧𝑎 𝑀𝑎𝑟𝑞𝑢𝑒𝑠🦋✨",
                "followers": 83354,
                "geo_share": 0.21694214876033058,
                "gender_share": 0.8056344989497096
            },
            "311265518": {
                "aq": 0.31422412537504735,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Leticia Muniz",
                "followers": 55932,
                "geo_share": 0.21272727272727274,
                "gender_share": 0.7867435158501441
            },
            "315840863": {
                "aq": 0.17830496745312602,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "ALINE REITTER|CACHOS ➿CURLY",
                "followers": 83854,
                "geo_share": 0.1308694335049985,
                "gender_share": 0.8368382886149384
            },
            "322011776": {
                "aq": 0.12915748609799602,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 1,
                "fullname": "Leonardo Zenolli",
                "followers": 99578,
                "geo_share": 0.18526466380543632,
                "gender_share": 0.579064587973274
            },
            "323622580": {
                "aq": 0.26704054089467755,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 1,
                "fullname": "wes",
                "followers": 70646,
                "geo_share": 0.1926145429678596,
                "gender_share": 0.6481059527200228
            },
            "334829422": {
                "aq": 0.6751661257919951,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Leon",
                "followers": 2278418,
                "geo_share": 0.18917018284106893,
                "gender_share": 0.5101744186046512
            },
            "354945278": {
                "aq": 0.6651305683563749,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Nil Moretto",
                "followers": 1728855,
                "geo_share": 0.1773981603153745,
                "gender_share": 0.5068870523415978
            },
            "598695438": {
                "aq": 0.35417169821836625,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Jê",
                "followers": 723771,
                "geo_share": 0.18057455540355677,
                "gender_share": 0.7453132796350417
            },
            "609314488": {
                "aq": 0.3677385572599027,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Barbara Sá",
                "followers": 65268,
                "geo_share": 0.177703052250388,
                "gender_share": 0.8486863444696724
            },
            "627845169": {
                "aq": 0.2064891254598159,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Samine",
                "followers": 94783,
                "geo_share": 0.1696941825707059,
                "gender_share": 0.7374394136422745
            },
            "657715276": {
                "aq": 0.26731379503453423,
                "age": "25-34",
                "geo": "Рио-де-Жанейро",
                "gender": 2,
                "fullname": "Gabriella Lima",
                "followers": 85151,
                "geo_share": 0.3082706766917293,
                "gender_share": 0.6332848999664692
            },
            "741697042": {
                "aq": 0.6719697836786083,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "+A",
                "followers": 44721911,
                "geo_share": 0.16410488245931285,
                "gender_share": 0.5397225725094578
            },
            "919623721": {
                "aq": 0.23969794733802002,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Arthur Soll ✨",
                "followers": 107528,
                "geo_share": 0.1545041432771986,
                "gender_share": 0.7665809193602505
            },
            "1202685807": {
                "aq": 0.3747016706443914,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Jordana Navacchio",
                "followers": 132067,
                "geo_share": 0.14940421631530706,
                "gender_share": 0.7146017699115044
            },
            "1392846239": {
                "aq": 0.24852171935410508,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Gabriel Miras",
                "followers": 76849,
                "geo_share": 0.19495958155016643,
                "gender_share": 0.7816434724983433
            },
            "1555690962": {
                "aq": 0.27759395201051823,
                "age": "25-34",
                "geo": "Рио-де-Жанейро",
                "gender": 2,
                "fullname": "ᴍᴀʀᴄᴇʟʟᴇ ᴏʟɪᴠᴇɪʀᴀ",
                "followers": 86249,
                "geo_share": 0.2167707404103479,
                "gender_share": 0.7967176941111571
            },
            "1578035865": {
                "aq": 0.2288762238784159,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Jhully Rodrigues",
                "followers": 123128,
                "geo_share": 0.15607940446650123,
                "gender_share": 0.8101860213111793
            },
            "2022206328": {
                "aq": 0.33203487293637546,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 1,
                "fullname": "Abdala Brothers",
                "followers": 96563,
                "geo_share": 0.16951030356747174,
                "gender_share": 0.900141007563133
            },
            "2042498632": {
                "aq": 0.2520657654985381,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Luis Henrique",
                "followers": 82781,
                "geo_share": 0.12326978323322016,
                "gender_share": 0.6173321759259259
            },
            "3073459808": {
                "aq": 0.3145175673265561,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 1,
                "fullname": "Caio Ferreira",
                "followers": 23073,
                "geo_share": 0.15019408778739923,
                "gender_share": 0.7934480843975569
            },
            "3583686062": {
                "aq": 0.3407336407440029,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 1,
                "fullname": "I N S A N E",
                "followers": 64702,
                "geo_share": 0.19826128954358851,
                "gender_share": 0.678923426838514
            },
            "4736267424": {
                "aq": 0.3568130323801876,
                "age": "25-34",
                "geo": "Сан-Паулу",
                "gender": 2,
                "fullname": "Duda Nunes",
                "followers": 94145,
                "geo_share": 0.20747520976353928,
                "gender_share": 0.6757442880221556
            }
        },
        "blogger_short": {
            "18774670": {
                "avatar": "https://cdn.yoloco.ru/francinyehlke.jpe",
                "username": "francinyehlke",
                "followers": 15561451,
                "external_id": 18774670
            },
            "25758310": {
                "avatar": "https://cdn.yoloco.ru/thami.jpe",
                "username": "thami",
                "followers": 50764,
                "external_id": 25758310
            },
            "26633036": {
                "avatar": "https://cdn.yoloco.ru/anitta.jpe",
                "username": "anitta",
                "followers": 63276751,
                "external_id": 26633036
            },
            "31251706": {
                "avatar": "https://cdn.yoloco.ru/paullogoes.jpe",
                "username": "paullogoes",
                "followers": 70388,
                "external_id": 31251706
            },
            "42131722": {
                "avatar": "https://cdn.yoloco.ru/joaoalmeidac.jpe",
                "username": "joaoalmeidac",
                "followers": 1198703,
                "external_id": 42131722
            },
            "45024121": {
                "avatar": "https://cdn.yoloco.ru/oficialbetoalmeida.jpe",
                "username": "oficialbetoalmeida",
                "followers": 50318,
                "external_id": 45024121
            },
            "47240880": {
                "avatar": "https://cdn.yoloco.ru/jreisjunior.jpe",
                "username": "jreisjunior",
                "followers": 93747,
                "external_id": 47240880
            },
            "53320670": {
                "avatar": "https://cdn.yoloco.ru/imbizita.jpe",
                "username": "imbizita",
                "followers": 104044,
                "external_id": 53320670
            },
            "143827821": {
                "avatar": "https://cdn.yoloco.ru/mihcezar.jpe",
                "username": "mihcezar",
                "followers": 85664,
                "external_id": 143827821
            },
            "144617429": {
                "avatar": "https://cdn.yoloco.ru/ronaldonene.jpe",
                "username": "ronaldonene",
                "followers": 62106,
                "external_id": 144617429
            },
            "182709505": {
                "avatar": "https://cdn.yoloco.ru/bruna_rosa_.jpe",
                "username": "bruna_rosa_",
                "followers": 48971,
                "external_id": 182709505
            },
            "204415100": {
                "avatar": "https://cdn.yoloco.ru/wwwingrids.jpe",
                "username": "wwwingrids",
                "followers": 78421,
                "external_id": 204415100
            },
            "213172152": {
                "avatar": "https://cdn.yoloco.ru/levisnovaes.jpe",
                "username": "levisnovaes",
                "followers": 6473,
                "external_id": 213172152
            },
            "216224860": {
                "avatar": "https://cdn.yoloco.ru/nickymitrava.jpe",
                "username": "nickymitrava",
                "followers": 84726,
                "external_id": 216224860
            },
            "221890539": {
                "avatar": "https://cdn.yoloco.ru/malufca.jpe",
                "username": "malufca",
                "followers": 1092886,
                "external_id": 221890539
            },
            "224907941": {
                "avatar": "https://cdn.yoloco.ru/kaitocasagrandi.jpe",
                "username": "kaitocasagrandi",
                "followers": 62690,
                "external_id": 224907941
            },
            "292251162": {
                "avatar": "https://cdn.yoloco.ru/juhmarquesf.jpe",
                "username": "juhmarquesf",
                "followers": 83354,
                "external_id": 292251162
            },
            "311265518": {
                "avatar": "https://cdn.yoloco.ru/leticiamunix.jpe",
                "username": "leticiamunix",
                "followers": 55932,
                "external_id": 311265518
            },
            "315840863": {
                "avatar": "https://cdn.yoloco.ru/superpretinha.jpe",
                "username": "superpretinha",
                "followers": 83854,
                "external_id": 315840863
            },
            "322011776": {
                "avatar": "https://cdn.yoloco.ru/leonardozenolli.jpe",
                "username": "leonardozenolli",
                "followers": 99578,
                "external_id": 322011776
            },
            "323622580": {
                "avatar": "https://cdn.yoloco.ru/wesdrag.jpe",
                "username": "wesdrag",
                "followers": 70646,
                "external_id": 323622580
            },
            "334829422": {
                "avatar": "https://cdn.yoloco.ru/coisadenerd.jpe",
                "username": "coisadenerd",
                "followers": 2278418,
                "external_id": 334829422
            },
            "354945278": {
                "avatar": "https://cdn.yoloco.ru/nilmoretto.jpe",
                "username": "nilmoretto",
                "followers": 1728855,
                "external_id": 354945278
            },
            "598695438": {
                "avatar": "https://cdn.yoloco.ru/imsors.jpe",
                "username": "imsors",
                "followers": 723771,
                "external_id": 598695438
            },
            "609314488": {
                "avatar": "https://cdn.yoloco.ru/eibarbarasa.jpe",
                "username": "eibarbarasa",
                "followers": 65268,
                "external_id": 609314488
            },
            "627845169": {
                "avatar": "https://cdn.yoloco.ru/milasamine.jpe",
                "username": "milasamine",
                "followers": 94783,
                "external_id": 627845169
            },
            "657715276": {
                "avatar": "https://cdn.yoloco.ru/gabriellalimav.jpe",
                "username": "gabriellalimav",
                "followers": 85151,
                "external_id": 657715276
            },
            "741697042": {
                "avatar": "https://cdn.yoloco.ru/maisa.jpe",
                "username": "maisa",
                "followers": 44721911,
                "external_id": 741697042
            },
            "919623721": {
                "avatar": "https://cdn.yoloco.ru/arthursoll.jpe",
                "username": "arthursoll",
                "followers": 107528,
                "external_id": 919623721
            },
            "1202685807": {
                "avatar": "https://cdn.yoloco.ru/jordananavacchio.jpe",
                "username": "jordananavacchio",
                "followers": 132067,
                "external_id": 1202685807
            },
            "1392846239": {
                "avatar": "https://cdn.yoloco.ru/gabriel_miras.jpe",
                "username": "gabriel_miras",
                "followers": 76849,
                "external_id": 1392846239
            },
            "1555690962": {
                "avatar": "https://cdn.yoloco.ru/marcelleoliveiraoficial.jpe",
                "username": "marcelleoliveiraoficial",
                "followers": 86249,
                "external_id": 1555690962
            },
            "1578035865": {
                "avatar": "https://cdn.yoloco.ru/jhully_r.jpe",
                "username": "jhully_r",
                "followers": 123128,
                "external_id": 1578035865
            },
            "2022206328": {
                "avatar": "https://cdn.yoloco.ru/abdalabrothers.jpe",
                "username": "abdalabrothers",
                "followers": 96563,
                "external_id": 2022206328
            },
            "2042498632": {
                "avatar": "https://cdn.yoloco.ru/luis.traveling.jpe",
                "username": "luis.traveling",
                "followers": 82781,
                "external_id": 2042498632
            },
            "3073459808": {
                "avatar": "https://cdn.yoloco.ru/caioepn.jpe",
                "username": "caioepn",
                "followers": 23073,
                "external_id": 3073459808
            },
            "3583686062": {
                "avatar": "https://cdn.yoloco.ru/guiipaiva.jpe",
                "username": "guiipaiva",
                "followers": 64702,
                "external_id": 3583686062
            },
            "4736267424": {
                "avatar": "https://cdn.yoloco.ru/dudanunxs.jpe",
                "username": "dudanunxs",
                "followers": 94145,
                "external_id": 4736267424
            }
        },
        "total_audience": 132906709,
        "intersection_data": {
            "bloggers_to_buckets": {
                "18774670": [
                    0.8767275495392414,
                    0.10226998709213755,
                    0.017149017253036657,
                    0.002000009456309486,
                    0.0003924368436730197
                ],
                "25758310": [
                    0.9557630189868431,
                    0.03596470495548728,
                    0.006145119357125975,
                    0.001339320885527456,
                    0.0002757425352556527
                ],
                "26633036": [
                    0.9393200016387696,
                    0.04855765510271899,
                    0.009190766443460171,
                    0.0010105746163685775,
                    0.00018663765437437692
                ],
                "31251706": [
                    0.9044416063573152,
                    0.08264509720448418,
                    0.00953597275436356,
                    0.001319710515112814,
                    0.0003973321980984816
                ],
                "42131722": [
                    0.7248639864339715,
                    0.22019359853034692,
                    0.05201724016109659,
                    0.004385407098612779,
                    0.0007725099507760427
                ],
                "45024121": [
                    0.9478595059331604,
                    0.0439588739872026,
                    0.0037045107866637613,
                    0.0004556350165415321,
                    0.00007924087244200558
                ],
                "47240880": [
                    0.9333070034051002,
                    0.06089685428519582,
                    0.005668050766948113,
                    0.000693829189927735,
                    0.00011741724752623209
                ],
                "53320670": [
                    0.9151893362140473,
                    0.07130731688497077,
                    0.012452014892262582,
                    0.00320222226508999,
                    0.0007426840795540038
                ],
                "143827821": [
                    0.9681278613282388,
                    0.02622228952855795,
                    0.0023414838717193014,
                    0.00041937024568106893,
                    0.00020968512284053447
                ],
                "144617429": [
                    0.8776983316309159,
                    0.09426285325161729,
                    0.024446714334354785,
                    0.0027579162410623086,
                    0.0004937010554988083
                ],
                "182709505": [
                    0.7776223776223776,
                    0.15376388317564788,
                    0.05551213492389963,
                    0.009399424105306458,
                    0.0028383381324557796
                ],
                "204415100": [
                    0.94224245670416,
                    0.05177646848777004,
                    0.005152141199275639,
                    0.000828933608794348,
                    0.0001530338970081873
                ],
                "213172152": [
                    0.9025880083587847,
                    0.07924770937148369,
                    0.012859668863526763,
                    0.0028934254942935217,
                    0.0019289503295290146
                ],
                "216224860": [
                    0.8637179285376987,
                    0.1116322997009287,
                    0.01807020305367543,
                    0.0038092239886667718,
                    0.0009444356996694475
                ],
                "221890539": [
                    0.8860764273635592,
                    0.10203453073391085,
                    0.016368526077418608,
                    0.001656795516739653,
                    0.00032923500653159773
                ],
                "224907941": [
                    0.9558470145831423,
                    0.039603778776483536,
                    0.0029349720260478766,
                    0.00047693295423278,
                    0.00007337430065119692
                ],
                "292251162": [
                    0.5638688613794042,
                    0.2598966127057543,
                    0.1096721534485104,
                    0.04577608488641001,
                    0.015916201877295606
                ],
                "311265518": [
                    0.6642337069027054,
                    0.25894068099079165,
                    0.05985437932757513,
                    0.010118495253051609,
                    0.002944535655649939
                ],
                "315840863": [
                    0.8791722137694941,
                    0.08305201597565615,
                    0.020849182198554583,
                    0.00839197413465196,
                    0.0034114682388740967
                ],
                "322011776": [
                    0.9032984487941209,
                    0.08077731198037619,
                    0.014466527932764324,
                    0.003086326668074112,
                    0.0009349458636185421
                ],
                "323622580": [
                    0.8283388643981046,
                    0.1368401935047138,
                    0.024353195634586492,
                    0.005068766820215629,
                    0.0017996598807931711
                ],
                "334829422": [
                    0.5689287789759153,
                    0.3989152192161167,
                    0.027571511590366114,
                    0.0043907793633369925,
                    0.0008071285594369471
                ],
                "354945278": [
                    0.4601777059773829,
                    0.49664781906300487,
                    0.03542003231017771,
                    0.005694668820678514,
                    0.0010096930533117932
                ],
                "598695438": [
                    0.7902449555387645,
                    0.16011255820532194,
                    0.053111542054857235,
                    0.00920118662552436,
                    0.0029926189510200586
                ],
                "609314488": [
                    0.948233096521313,
                    0.04242711905928467,
                    0.006201004409603136,
                    0.0014392454679078882,
                    0.00033684468397844196
                ],
                "627845169": [
                    0.6436395209047597,
                    0.21606037668640635,
                    0.08387506122267242,
                    0.035175208157086246,
                    0.013057126319070305
                ],
                "657715276": [
                    0.6950662977469546,
                    0.18964629223711477,
                    0.0709451770934397,
                    0.029082372105836838,
                    0.011103525099716124
                ],
                "741697042": [
                    0.7728309167446211,
                    0.1752221702525725,
                    0.045661833489242284,
                    0.004560336763330215,
                    0.0009354536950420954
                ],
                "919623721": [
                    0.8620278070478091,
                    0.11291601003366313,
                    0.02052424957338282,
                    0.0050448064603362585,
                    0.0013521200309588861
                ],
                "1202685807": [
                    0.8162171967589793,
                    0.13727173781593904,
                    0.032228806385294476,
                    0.008598379489660177,
                    0.002648446003144274
                ],
                "1392846239": [
                    0.8787341640164746,
                    0.10205411605234346,
                    0.015640477555914706,
                    0.0035842761065637873,
                    0.0008211250716855221
                ],
                "1555690962": [
                    0.6161296698952362,
                    0.22312101813979657,
                    0.09475876959569389,
                    0.042133592835312546,
                    0.015326835647057035
                ],
                "1578035865": [
                    0.6348760238892073,
                    0.2114897472403528,
                    0.08882643344489026,
                    0.03962428119741878,
                    0.017232771850673963
                ],
                "2022206328": [
                    0.8655450249205898,
                    0.1153707099140046,
                    0.018735635152235107,
                    0.0018206234021124396,
                    0.00030989334504041523
                ],
                "2042498632": [
                    0.9450669118892626,
                    0.04729811485129347,
                    0.00690898531084384,
                    0.001427776298913438,
                    0.0003992933717300293
                ],
                "3073459808": [
                    0.9342671467462896,
                    0.05567752700447879,
                    0.007025555457978397,
                    0.0013612013699833142,
                    0.0003512777728989198
                ],
                "3583686062": [
                    0.768791066884424,
                    0.18980971552280754,
                    0.031581146264575186,
                    0.00343727448820692,
                    0.0005507235367845342
                ],
                "4736267424": [
                    0.7656469536339251,
                    0.18364414356878273,
                    0.041182356197045175,
                    0.007823476816962575,
                    0.002320432579724954
                ]
            },
            "bloggers_to_intersections": {
                "18774670": [
                    [
                        354945278,
                        0.248
                    ],
                    [
                        221890539,
                        6.735
                    ],
                    [
                        334829422,
                        0.238
                    ],
                    [
                        2022206328,
                        0.013
                    ],
                    [
                        204415100,
                        0.039
                    ],
                    [
                        315840863,
                        0.061
                    ],
                    [
                        31251706,
                        0.014
                    ],
                    [
                        45024121,
                        0.017
                    ],
                    [
                        143827821,
                        0.026
                    ],
                    [
                        741697042,
                        1.586
                    ],
                    [
                        2042498632,
                        0.07
                    ],
                    [
                        311265518,
                        0.044
                    ],
                    [
                        47240880,
                        0.053
                    ],
                    [
                        598695438,
                        0.392
                    ],
                    [
                        53320670,
                        0.074
                    ],
                    [
                        1392846239,
                        0.047
                    ],
                    [
                        609314488,
                        0.056
                    ],
                    [
                        4736267424,
                        0.059
                    ],
                    [
                        919623721,
                        0.271
                    ],
                    [
                        26633036,
                        1.945
                    ],
                    [
                        323622580,
                        0.048
                    ],
                    [
                        25758310,
                        0.023
                    ],
                    [
                        144617429,
                        0.01
                    ],
                    [
                        216224860,
                        0.073
                    ],
                    [
                        627845169,
                        0.093
                    ],
                    [
                        1555690962,
                        0.081
                    ],
                    [
                        213172152,
                        0.003
                    ],
                    [
                        322011776,
                        0.035
                    ],
                    [
                        224907941,
                        0.024
                    ],
                    [
                        292251162,
                        0.104
                    ],
                    [
                        1202685807,
                        0.129
                    ],
                    [
                        42131722,
                        1.623
                    ],
                    [
                        1578035865,
                        0.057
                    ],
                    [
                        3583686062,
                        0.015
                    ],
                    [
                        657715276,
                        0.081
                    ],
                    [
                        3073459808,
                        0.006
                    ],
                    [
                        182709505,
                        0.034
                    ],
                    [
                        18774670,
                        100
                    ]
                ],
                "25758310": [
                    [
                        354945278,
                        0.024
                    ],
                    [
                        221890539,
                        0.752
                    ],
                    [
                        18774670,
                        0.095
                    ],
                    [
                        334829422,
                        0.03
                    ],
                    [
                        2022206328,
                        0.311
                    ],
                    [
                        204415100,
                        0.093
                    ],
                    [
                        315840863,
                        0.234
                    ],
                    [
                        31251706,
                        0.167
                    ],
                    [
                        45024121,
                        0.116
                    ],
                    [
                        143827821,
                        0.227
                    ],
                    [
                        741697042,
                        0.002
                    ],
                    [
                        2042498632,
                        0.227
                    ],
                    [
                        311265518,
                        0.274
                    ],
                    [
                        47240880,
                        0.252
                    ],
                    [
                        598695438,
                        0.313
                    ],
                    [
                        53320670,
                        0.191
                    ],
                    [
                        1392846239,
                        0.335
                    ],
                    [
                        609314488,
                        0.187
                    ],
                    [
                        4736267424,
                        0.274
                    ],
                    [
                        919623721,
                        0.187
                    ],
                    [
                        26633036,
                        0.01
                    ],
                    [
                        323622580,
                        0.087
                    ],
                    [
                        144617429,
                        0.268
                    ],
                    [
                        216224860,
                        0.047
                    ],
                    [
                        627845169,
                        0.104
                    ],
                    [
                        1555690962,
                        0.106
                    ],
                    [
                        213172152,
                        0.014
                    ],
                    [
                        322011776,
                        0.104
                    ],
                    [
                        224907941,
                        0.106
                    ],
                    [
                        292251162,
                        0.065
                    ],
                    [
                        1202685807,
                        0.03
                    ],
                    [
                        42131722,
                        0.205
                    ],
                    [
                        1578035865,
                        0.091
                    ],
                    [
                        3583686062,
                        0.347
                    ],
                    [
                        657715276,
                        0.193
                    ],
                    [
                        3073459808,
                        0.037
                    ],
                    [
                        182709505,
                        0.215
                    ],
                    [
                        25758310,
                        100
                    ]
                ],
                "26633036": [
                    [
                        354945278,
                        0.032
                    ],
                    [
                        221890539,
                        1.506
                    ],
                    [
                        18774670,
                        1.873
                    ],
                    [
                        334829422,
                        0.091
                    ],
                    [
                        2022206328,
                        0.016
                    ],
                    [
                        204415100,
                        0.023
                    ],
                    [
                        315840863,
                        0.015
                    ],
                    [
                        31251706,
                        0.005
                    ],
                    [
                        45024121,
                        0.002
                    ],
                    [
                        143827821,
                        0.013
                    ],
                    [
                        741697042,
                        2.417
                    ],
                    [
                        2042498632,
                        0.026
                    ],
                    [
                        311265518,
                        0.015
                    ],
                    [
                        47240880,
                        0.008
                    ],
                    [
                        598695438,
                        0.171
                    ],
                    [
                        53320670,
                        0.02
                    ],
                    [
                        1392846239,
                        0.015
                    ],
                    [
                        609314488,
                        0.012
                    ],
                    [
                        4736267424,
                        0.033
                    ],
                    [
                        919623721,
                        0.085
                    ],
                    [
                        323622580,
                        0.014
                    ],
                    [
                        25758310,
                        0.002
                    ],
                    [
                        144617429,
                        0.008
                    ],
                    [
                        216224860,
                        0.038
                    ],
                    [
                        627845169,
                        0.036
                    ],
                    [
                        1555690962,
                        0.026
                    ],
                    [
                        213172152,
                        0
                    ],
                    [
                        322011776,
                        0.017
                    ],
                    [
                        224907941,
                        0.005
                    ],
                    [
                        292251162,
                        0.042
                    ],
                    [
                        1202685807,
                        0.095
                    ],
                    [
                        42131722,
                        0.351
                    ],
                    [
                        1578035865,
                        0.015
                    ],
                    [
                        3583686062,
                        0.012
                    ],
                    [
                        657715276,
                        0.032
                    ],
                    [
                        3073459808,
                        0.004
                    ],
                    [
                        182709505,
                        0.009
                    ],
                    [
                        26633036,
                        100
                    ]
                ],
                "31251706": [
                    [
                        354945278,
                        0.007
                    ],
                    [
                        221890539,
                        0.14
                    ],
                    [
                        18774670,
                        0.043
                    ],
                    [
                        334829422,
                        0.011
                    ],
                    [
                        2022206328,
                        0.043
                    ],
                    [
                        204415100,
                        0.048
                    ],
                    [
                        315840863,
                        0.143
                    ],
                    [
                        45024121,
                        0.4
                    ],
                    [
                        143827821,
                        0.085
                    ],
                    [
                        741697042,
                        0.004
                    ],
                    [
                        2042498632,
                        0.186
                    ],
                    [
                        311265518,
                        0.116
                    ],
                    [
                        47240880,
                        3.306
                    ],
                    [
                        598695438,
                        0.295
                    ],
                    [
                        53320670,
                        0.139
                    ],
                    [
                        1392846239,
                        0.505
                    ],
                    [
                        609314488,
                        0.089
                    ],
                    [
                        4736267424,
                        0.152
                    ],
                    [
                        919623721,
                        0.382
                    ],
                    [
                        26633036,
                        0.016
                    ],
                    [
                        323622580,
                        1.679
                    ],
                    [
                        25758310,
                        0.121
                    ],
                    [
                        144617429,
                        0.082
                    ],
                    [
                        216224860,
                        0.258
                    ],
                    [
                        627845169,
                        0.167
                    ],
                    [
                        1555690962,
                        0.051
                    ],
                    [
                        213172152,
                        0.018
                    ],
                    [
                        322011776,
                        2.075
                    ],
                    [
                        224907941,
                        0.146
                    ],
                    [
                        292251162,
                        0.035
                    ],
                    [
                        1202685807,
                        0.021
                    ],
                    [
                        42131722,
                        0.159
                    ],
                    [
                        1578035865,
                        0.068
                    ],
                    [
                        3583686062,
                        0.112
                    ],
                    [
                        657715276,
                        0.094
                    ],
                    [
                        3073459808,
                        0.072
                    ],
                    [
                        182709505,
                        0.123
                    ],
                    [
                        31251706,
                        100
                    ]
                ],
                "42131722": [
                    [
                        354945278,
                        0.091
                    ],
                    [
                        221890539,
                        20.572
                    ],
                    [
                        18774670,
                        1.617
                    ],
                    [
                        334829422,
                        0.073
                    ],
                    [
                        2022206328,
                        0.096
                    ],
                    [
                        204415100,
                        0.173
                    ],
                    [
                        315840863,
                        0.064
                    ],
                    [
                        31251706,
                        0.053
                    ],
                    [
                        45024121,
                        0.016
                    ],
                    [
                        143827821,
                        0.016
                    ],
                    [
                        741697042,
                        0.089
                    ],
                    [
                        2042498632,
                        0.077
                    ],
                    [
                        311265518,
                        0.051
                    ],
                    [
                        47240880,
                        0.046
                    ],
                    [
                        598695438,
                        6.856
                    ],
                    [
                        53320670,
                        0.121
                    ],
                    [
                        1392846239,
                        0.41
                    ],
                    [
                        609314488,
                        0.039
                    ],
                    [
                        4736267424,
                        0.059
                    ],
                    [
                        919623721,
                        0.57
                    ],
                    [
                        26633036,
                        0.364
                    ],
                    [
                        323622580,
                        0.143
                    ],
                    [
                        25758310,
                        0.049
                    ],
                    [
                        144617429,
                        0.074
                    ],
                    [
                        216224860,
                        0.082
                    ],
                    [
                        627845169,
                        0.243
                    ],
                    [
                        1555690962,
                        0.195
                    ],
                    [
                        213172152,
                        0.007
                    ],
                    [
                        322011776,
                        0.277
                    ],
                    [
                        224907941,
                        0.011
                    ],
                    [
                        292251162,
                        0.332
                    ],
                    [
                        1202685807,
                        0.834
                    ],
                    [
                        1578035865,
                        0.169
                    ],
                    [
                        3583686062,
                        0.096
                    ],
                    [
                        657715276,
                        0.155
                    ],
                    [
                        3073459808,
                        0.015
                    ],
                    [
                        182709505,
                        0.038
                    ],
                    [
                        42131722,
                        100
                    ]
                ],
                "45024121": [
                    [
                        354945278,
                        0.014
                    ],
                    [
                        221890539,
                        0.105
                    ],
                    [
                        18774670,
                        0.071
                    ],
                    [
                        334829422,
                        0.018
                    ],
                    [
                        2022206328,
                        0.028
                    ],
                    [
                        204415100,
                        0.067
                    ],
                    [
                        315840863,
                        0.18
                    ],
                    [
                        31251706,
                        0.559
                    ],
                    [
                        143827821,
                        0.269
                    ],
                    [
                        741697042,
                        0.002
                    ],
                    [
                        2042498632,
                        0.133
                    ],
                    [
                        311265518,
                        0.121
                    ],
                    [
                        47240880,
                        0.612
                    ],
                    [
                        598695438,
                        0.149
                    ],
                    [
                        53320670,
                        0.113
                    ],
                    [
                        1392846239,
                        0.139
                    ],
                    [
                        609314488,
                        0.19
                    ],
                    [
                        4736267424,
                        0.125
                    ],
                    [
                        919623721,
                        0.133
                    ],
                    [
                        26633036,
                        0.01
                    ],
                    [
                        323622580,
                        0.208
                    ],
                    [
                        25758310,
                        0.117
                    ],
                    [
                        144617429,
                        0.053
                    ],
                    [
                        216224860,
                        0.097
                    ],
                    [
                        627845169,
                        0.018
                    ],
                    [
                        1555690962,
                        0.018
                    ],
                    [
                        213172152,
                        0.018
                    ],
                    [
                        322011776,
                        0.67
                    ],
                    [
                        224907941,
                        1.311
                    ],
                    [
                        292251162,
                        0.024
                    ],
                    [
                        1202685807,
                        0.008
                    ],
                    [
                        42131722,
                        0.069
                    ],
                    [
                        1578035865,
                        0.048
                    ],
                    [
                        3583686062,
                        0.256
                    ],
                    [
                        657715276,
                        0.022
                    ],
                    [
                        3073459808,
                        0.059
                    ],
                    [
                        182709505,
                        0.166
                    ],
                    [
                        45024121,
                        100
                    ]
                ],
                "47240880": [
                    [
                        354945278,
                        0.006
                    ],
                    [
                        221890539,
                        0.193
                    ],
                    [
                        18774670,
                        0.12
                    ],
                    [
                        334829422,
                        0.009
                    ],
                    [
                        2022206328,
                        0.041
                    ],
                    [
                        204415100,
                        0.021
                    ],
                    [
                        315840863,
                        0.224
                    ],
                    [
                        31251706,
                        2.487
                    ],
                    [
                        45024121,
                        0.33
                    ],
                    [
                        143827821,
                        0.095
                    ],
                    [
                        741697042,
                        0.004
                    ],
                    [
                        2042498632,
                        0.117
                    ],
                    [
                        311265518,
                        0.044
                    ],
                    [
                        598695438,
                        0.399
                    ],
                    [
                        53320670,
                        0.061
                    ],
                    [
                        1392846239,
                        0.209
                    ],
                    [
                        609314488,
                        0.059
                    ],
                    [
                        4736267424,
                        0.062
                    ],
                    [
                        919623721,
                        0.423
                    ],
                    [
                        26633036,
                        0.019
                    ],
                    [
                        323622580,
                        0.742
                    ],
                    [
                        25758310,
                        0.137
                    ],
                    [
                        144617429,
                        0.032
                    ],
                    [
                        216224860,
                        0.111
                    ],
                    [
                        627845169,
                        0.12
                    ],
                    [
                        1555690962,
                        0.118
                    ],
                    [
                        213172152,
                        0.002
                    ],
                    [
                        322011776,
                        0.549
                    ],
                    [
                        224907941,
                        0.139
                    ],
                    [
                        292251162,
                        0.047
                    ],
                    [
                        1202685807,
                        0.045
                    ],
                    [
                        42131722,
                        0.105
                    ],
                    [
                        1578035865,
                        0.108
                    ],
                    [
                        3583686062,
                        0.11
                    ],
                    [
                        657715276,
                        0.074
                    ],
                    [
                        3073459808,
                        0.038
                    ],
                    [
                        182709505,
                        0.191
                    ],
                    [
                        47240880,
                        100
                    ]
                ],
                "53320670": [
                    [
                        354945278,
                        0.154
                    ],
                    [
                        221890539,
                        0.943
                    ],
                    [
                        18774670,
                        0.151
                    ],
                    [
                        334829422,
                        0.138
                    ],
                    [
                        2022206328,
                        0.119
                    ],
                    [
                        204415100,
                        0.351
                    ],
                    [
                        315840863,
                        0.068
                    ],
                    [
                        31251706,
                        0.095
                    ],
                    [
                        45024121,
                        0.055
                    ],
                    [
                        143827821,
                        0.049
                    ],
                    [
                        741697042,
                        0.007
                    ],
                    [
                        2042498632,
                        0.151
                    ],
                    [
                        311265518,
                        0.246
                    ],
                    [
                        47240880,
                        0.055
                    ],
                    [
                        598695438,
                        0.78
                    ],
                    [
                        1392846239,
                        1.107
                    ],
                    [
                        609314488,
                        0.218
                    ],
                    [
                        4736267424,
                        0.553
                    ],
                    [
                        919623721,
                        0.877
                    ],
                    [
                        26633036,
                        0.042
                    ],
                    [
                        323622580,
                        0.474
                    ],
                    [
                        25758310,
                        0.094
                    ],
                    [
                        144617429,
                        0.204
                    ],
                    [
                        216224860,
                        0.315
                    ],
                    [
                        627845169,
                        0.589
                    ],
                    [
                        1555690962,
                        0.28
                    ],
                    [
                        213172152,
                        0.006
                    ],
                    [
                        322011776,
                        0.508
                    ],
                    [
                        224907941,
                        0.031
                    ],
                    [
                        292251162,
                        0.431
                    ],
                    [
                        1202685807,
                        1.291
                    ],
                    [
                        42131722,
                        0.247
                    ],
                    [
                        1578035865,
                        0.144
                    ],
                    [
                        3583686062,
                        0.107
                    ],
                    [
                        657715276,
                        0.162
                    ],
                    [
                        3073459808,
                        0.177
                    ],
                    [
                        182709505,
                        0.278
                    ],
                    [
                        53320670,
                        100
                    ]
                ],
                "143827821": [
                    [
                        354945278,
                        0.01
                    ],
                    [
                        221890539,
                        0.19
                    ],
                    [
                        18774670,
                        0.065
                    ],
                    [
                        334829422,
                        0.01
                    ],
                    [
                        2022206328,
                        0.197
                    ],
                    [
                        204415100,
                        0.043
                    ],
                    [
                        315840863,
                        0.298
                    ],
                    [
                        31251706,
                        0.07
                    ],
                    [
                        45024121,
                        0.158
                    ],
                    [
                        741697042,
                        0.002
                    ],
                    [
                        2042498632,
                        0.377
                    ],
                    [
                        311265518,
                        0.178
                    ],
                    [
                        47240880,
                        0.104
                    ],
                    [
                        598695438,
                        0.179
                    ],
                    [
                        53320670,
                        0.059
                    ],
                    [
                        1392846239,
                        0.051
                    ],
                    [
                        609314488,
                        0.147
                    ],
                    [
                        4736267424,
                        0.227
                    ],
                    [
                        919623721,
                        0.092
                    ],
                    [
                        26633036,
                        0.034
                    ],
                    [
                        323622580,
                        0.022
                    ],
                    [
                        25758310,
                        0.134
                    ],
                    [
                        144617429,
                        0.091
                    ],
                    [
                        216224860,
                        0.02
                    ],
                    [
                        627845169,
                        0.069
                    ],
                    [
                        1555690962,
                        0.061
                    ],
                    [
                        213172152,
                        0.01
                    ],
                    [
                        322011776,
                        0.007
                    ],
                    [
                        224907941,
                        0.358
                    ],
                    [
                        292251162,
                        0.047
                    ],
                    [
                        1202685807,
                        0.017
                    ],
                    [
                        42131722,
                        0.041
                    ],
                    [
                        1578035865,
                        0.105
                    ],
                    [
                        3583686062,
                        0.107
                    ],
                    [
                        657715276,
                        0.098
                    ],
                    [
                        3073459808,
                        0.016
                    ],
                    [
                        182709505,
                        0.164
                    ],
                    [
                        143827821,
                        100
                    ]
                ],
                "144617429": [
                    [
                        354945278,
                        0.051
                    ],
                    [
                        221890539,
                        0.232
                    ],
                    [
                        18774670,
                        0.037
                    ],
                    [
                        334829422,
                        0.044
                    ],
                    [
                        2022206328,
                        4.368
                    ],
                    [
                        204415100,
                        0.066
                    ],
                    [
                        315840863,
                        0.077
                    ],
                    [
                        31251706,
                        0.099
                    ],
                    [
                        45024121,
                        0.046
                    ],
                    [
                        143827821,
                        0.133
                    ],
                    [
                        741697042,
                        0.003
                    ],
                    [
                        2042498632,
                        0.318
                    ],
                    [
                        311265518,
                        0.386
                    ],
                    [
                        47240880,
                        0.051
                    ],
                    [
                        598695438,
                        0.33
                    ],
                    [
                        53320670,
                        0.359
                    ],
                    [
                        1392846239,
                        0.056
                    ],
                    [
                        609314488,
                        0.099
                    ],
                    [
                        4736267424,
                        0.724
                    ],
                    [
                        919623721,
                        0.07
                    ],
                    [
                        26633036,
                        0.029
                    ],
                    [
                        323622580,
                        0.216
                    ],
                    [
                        25758310,
                        0.232
                    ],
                    [
                        216224860,
                        0.099
                    ],
                    [
                        627845169,
                        0.148
                    ],
                    [
                        1555690962,
                        0.078
                    ],
                    [
                        213172152,
                        0.043
                    ],
                    [
                        322011776,
                        0.3
                    ],
                    [
                        224907941,
                        0.078
                    ],
                    [
                        292251162,
                        0.133
                    ],
                    [
                        1202685807,
                        0.087
                    ],
                    [
                        42131722,
                        0.267
                    ],
                    [
                        1578035865,
                        0.102
                    ],
                    [
                        3583686062,
                        5.431
                    ],
                    [
                        657715276,
                        0.146
                    ],
                    [
                        3073459808,
                        0.09
                    ],
                    [
                        182709505,
                        0.383
                    ],
                    [
                        144617429,
                        100
                    ]
                ],
                "182709505": [
                    [
                        354945278,
                        0.049
                    ],
                    [
                        221890539,
                        0.734
                    ],
                    [
                        18774670,
                        0.146
                    ],
                    [
                        334829422,
                        0.037
                    ],
                    [
                        2022206328,
                        0.047
                    ],
                    [
                        204415100,
                        0.177
                    ],
                    [
                        315840863,
                        0.644
                    ],
                    [
                        31251706,
                        0.179
                    ],
                    [
                        45024121,
                        0.173
                    ],
                    [
                        143827821,
                        0.29
                    ],
                    [
                        741697042,
                        0.006
                    ],
                    [
                        2042498632,
                        0.253
                    ],
                    [
                        311265518,
                        9.307
                    ],
                    [
                        47240880,
                        0.368
                    ],
                    [
                        598695438,
                        0.831
                    ],
                    [
                        53320670,
                        0.592
                    ],
                    [
                        1392846239,
                        0.216
                    ],
                    [
                        609314488,
                        0.917
                    ],
                    [
                        4736267424,
                        11.088
                    ],
                    [
                        919623721,
                        0.337
                    ],
                    [
                        26633036,
                        0.041
                    ],
                    [
                        323622580,
                        0.343
                    ],
                    [
                        25758310,
                        0.224
                    ],
                    [
                        144617429,
                        0.463
                    ],
                    [
                        216224860,
                        0.319
                    ],
                    [
                        627845169,
                        0.958
                    ],
                    [
                        1555690962,
                        0.333
                    ],
                    [
                        213172152,
                        0.193
                    ],
                    [
                        322011776,
                        0.514
                    ],
                    [
                        224907941,
                        0.177
                    ],
                    [
                        292251162,
                        0.539
                    ],
                    [
                        1202685807,
                        0.226
                    ],
                    [
                        42131722,
                        0.167
                    ],
                    [
                        1578035865,
                        0.44
                    ],
                    [
                        3583686062,
                        0.234
                    ],
                    [
                        657715276,
                        0.537
                    ],
                    [
                        3073459808,
                        0.058
                    ],
                    [
                        182709505,
                        100
                    ]
                ],
                "204415100": [
                    [
                        354945278,
                        0.139
                    ],
                    [
                        221890539,
                        3.348
                    ],
                    [
                        18774670,
                        0.105
                    ],
                    [
                        334829422,
                        0.125
                    ],
                    [
                        2022206328,
                        0.116
                    ],
                    [
                        315840863,
                        0.01
                    ],
                    [
                        31251706,
                        0.043
                    ],
                    [
                        45024121,
                        0.043
                    ],
                    [
                        143827821,
                        0.047
                    ],
                    [
                        741697042,
                        0.015
                    ],
                    [
                        2042498632,
                        0.06
                    ],
                    [
                        311265518,
                        0.125
                    ],
                    [
                        47240880,
                        0.026
                    ],
                    [
                        598695438,
                        0.297
                    ],
                    [
                        53320670,
                        0.464
                    ],
                    [
                        1392846239,
                        0.245
                    ],
                    [
                        609314488,
                        0.048
                    ],
                    [
                        4736267424,
                        0.238
                    ],
                    [
                        919623721,
                        0.043
                    ],
                    [
                        26633036,
                        0.065
                    ],
                    [
                        323622580,
                        0.027
                    ],
                    [
                        25758310,
                        0.06
                    ],
                    [
                        144617429,
                        0.05
                    ],
                    [
                        216224860,
                        0.055
                    ],
                    [
                        627845169,
                        0.078
                    ],
                    [
                        1555690962,
                        0.038
                    ],
                    [
                        213172152,
                        0.005
                    ],
                    [
                        322011776,
                        0.083
                    ],
                    [
                        224907941,
                        0.006
                    ],
                    [
                        292251162,
                        0.054
                    ],
                    [
                        1202685807,
                        0.185
                    ],
                    [
                        42131722,
                        0.469
                    ],
                    [
                        1578035865,
                        0.011
                    ],
                    [
                        3583686062,
                        0.038
                    ],
                    [
                        657715276,
                        0.085
                    ],
                    [
                        3073459808,
                        0.117
                    ],
                    [
                        182709505,
                        0.11
                    ],
                    [
                        204415100,
                        100
                    ]
                ],
                "213172152": [
                    [
                        354945278,
                        0
                    ],
                    [
                        221890539,
                        0.305
                    ],
                    [
                        18774670,
                        0.096
                    ],
                    [
                        334829422,
                        0.016
                    ],
                    [
                        2022206328,
                        0.434
                    ],
                    [
                        204415100,
                        0.064
                    ],
                    [
                        315840863,
                        0.161
                    ],
                    [
                        31251706,
                        0.209
                    ],
                    [
                        45024121,
                        0.145
                    ],
                    [
                        143827821,
                        0.145
                    ],
                    [
                        741697042,
                        0.016
                    ],
                    [
                        2042498632,
                        0.113
                    ],
                    [
                        311265518,
                        0.418
                    ],
                    [
                        47240880,
                        0.032
                    ],
                    [
                        598695438,
                        1.398
                    ],
                    [
                        53320670,
                        0.096
                    ],
                    [
                        1392846239,
                        0.032
                    ],
                    [
                        609314488,
                        0.048
                    ],
                    [
                        4736267424,
                        0.466
                    ],
                    [
                        919623721,
                        0.161
                    ],
                    [
                        26633036,
                        0.016
                    ],
                    [
                        323622580,
                        0.563
                    ],
                    [
                        25758310,
                        0.113
                    ],
                    [
                        144617429,
                        0.402
                    ],
                    [
                        216224860,
                        0.354
                    ],
                    [
                        627845169,
                        0.563
                    ],
                    [
                        1555690962,
                        1.013
                    ],
                    [
                        322011776,
                        0.161
                    ],
                    [
                        224907941,
                        0.113
                    ],
                    [
                        292251162,
                        1.157
                    ],
                    [
                        1202685807,
                        0.048
                    ],
                    [
                        42131722,
                        0.225
                    ],
                    [
                        1578035865,
                        0.402
                    ],
                    [
                        3583686062,
                        0.498
                    ],
                    [
                        657715276,
                        0.9
                    ],
                    [
                        3073459808,
                        0.016
                    ],
                    [
                        182709505,
                        1.511
                    ],
                    [
                        213172152,
                        100
                    ]
                ],
                "216224860": [
                    [
                        354945278,
                        0.318
                    ],
                    [
                        221890539,
                        1.07
                    ],
                    [
                        18774670,
                        0.488
                    ],
                    [
                        334829422,
                        0.227
                    ],
                    [
                        2022206328,
                        0.069
                    ],
                    [
                        204415100,
                        0.135
                    ],
                    [
                        315840863,
                        0.057
                    ],
                    [
                        31251706,
                        0.573
                    ],
                    [
                        45024121,
                        0.154
                    ],
                    [
                        143827821,
                        0.054
                    ],
                    [
                        741697042,
                        0.031
                    ],
                    [
                        2042498632,
                        0.132
                    ],
                    [
                        311265518,
                        0.211
                    ],
                    [
                        47240880,
                        0.327
                    ],
                    [
                        598695438,
                        0.702
                    ],
                    [
                        53320670,
                        1.029
                    ],
                    [
                        1392846239,
                        0.261
                    ],
                    [
                        609314488,
                        0.211
                    ],
                    [
                        4736267424,
                        0.29
                    ],
                    [
                        919623721,
                        1.121
                    ],
                    [
                        26633036,
                        0.264
                    ],
                    [
                        323622580,
                        5.235
                    ],
                    [
                        25758310,
                        0.076
                    ],
                    [
                        144617429,
                        0.183
                    ],
                    [
                        627845169,
                        0.132
                    ],
                    [
                        1555690962,
                        0.192
                    ],
                    [
                        213172152,
                        0.069
                    ],
                    [
                        322011776,
                        1.02
                    ],
                    [
                        224907941,
                        0.101
                    ],
                    [
                        292251162,
                        0.277
                    ],
                    [
                        1202685807,
                        0.29
                    ],
                    [
                        42131722,
                        0.548
                    ],
                    [
                        1578035865,
                        0.085
                    ],
                    [
                        3583686062,
                        0.142
                    ],
                    [
                        657715276,
                        0.088
                    ],
                    [
                        3073459808,
                        0.176
                    ],
                    [
                        182709505,
                        0.488
                    ],
                    [
                        216224860,
                        100
                    ]
                ],
                "221890539": [
                    [
                        354945278,
                        0.071
                    ],
                    [
                        18774670,
                        1.681
                    ],
                    [
                        334829422,
                        0.058
                    ],
                    [
                        2022206328,
                        0.061
                    ],
                    [
                        204415100,
                        0.31
                    ],
                    [
                        315840863,
                        0.049
                    ],
                    [
                        31251706,
                        0.012
                    ],
                    [
                        45024121,
                        0.006
                    ],
                    [
                        143827821,
                        0.019
                    ],
                    [
                        741697042,
                        0.055
                    ],
                    [
                        2042498632,
                        0.061
                    ],
                    [
                        311265518,
                        0.082
                    ],
                    [
                        47240880,
                        0.021
                    ],
                    [
                        598695438,
                        3.071
                    ],
                    [
                        53320670,
                        0.115
                    ],
                    [
                        1392846239,
                        0.377
                    ],
                    [
                        609314488,
                        0.042
                    ],
                    [
                        4736267424,
                        0.122
                    ],
                    [
                        919623721,
                        0.529
                    ],
                    [
                        26633036,
                        0.39
                    ],
                    [
                        323622580,
                        0.051
                    ],
                    [
                        25758310,
                        0.045
                    ],
                    [
                        144617429,
                        0.016
                    ],
                    [
                        216224860,
                        0.04
                    ],
                    [
                        627845169,
                        0.208
                    ],
                    [
                        1555690962,
                        0.133
                    ],
                    [
                        213172152,
                        0.002
                    ],
                    [
                        322011776,
                        0.115
                    ],
                    [
                        224907941,
                        0.008
                    ],
                    [
                        292251162,
                        0.215
                    ],
                    [
                        1202685807,
                        0.588
                    ],
                    [
                        42131722,
                        5.154
                    ],
                    [
                        1578035865,
                        0.092
                    ],
                    [
                        3583686062,
                        0.03
                    ],
                    [
                        657715276,
                        0.243
                    ],
                    [
                        3073459808,
                        0.019
                    ],
                    [
                        182709505,
                        0.042
                    ],
                    [
                        221890539,
                        100
                    ]
                ],
                "224907941": [
                    [
                        354945278,
                        0.002
                    ],
                    [
                        221890539,
                        0.125
                    ],
                    [
                        18774670,
                        0.092
                    ],
                    [
                        334829422,
                        0
                    ],
                    [
                        2022206328,
                        0.139
                    ],
                    [
                        204415100,
                        0.009
                    ],
                    [
                        315840863,
                        0.211
                    ],
                    [
                        31251706,
                        0.189
                    ],
                    [
                        45024121,
                        1.214
                    ],
                    [
                        143827821,
                        0.563
                    ],
                    [
                        741697042,
                        0.002
                    ],
                    [
                        2042498632,
                        0.224
                    ],
                    [
                        311265518,
                        0.086
                    ],
                    [
                        47240880,
                        0.238
                    ],
                    [
                        598695438,
                        0.11
                    ],
                    [
                        53320670,
                        0.059
                    ],
                    [
                        1392846239,
                        0.053
                    ],
                    [
                        609314488,
                        0.15
                    ],
                    [
                        4736267424,
                        0.11
                    ],
                    [
                        919623721,
                        0.051
                    ],
                    [
                        26633036,
                        0.022
                    ],
                    [
                        323622580,
                        0.128
                    ],
                    [
                        25758310,
                        0.099
                    ],
                    [
                        144617429,
                        0.084
                    ],
                    [
                        216224860,
                        0.059
                    ],
                    [
                        627845169,
                        0.033
                    ],
                    [
                        1555690962,
                        0.017
                    ],
                    [
                        213172152,
                        0.013
                    ],
                    [
                        322011776,
                        0.106
                    ],
                    [
                        292251162,
                        0.029
                    ],
                    [
                        1202685807,
                        0.022
                    ],
                    [
                        42131722,
                        0.044
                    ],
                    [
                        1578035865,
                        0.039
                    ],
                    [
                        3583686062,
                        0.207
                    ],
                    [
                        657715276,
                        0.02
                    ],
                    [
                        3073459808,
                        0.05
                    ],
                    [
                        182709505,
                        0.158
                    ],
                    [
                        224907941,
                        100
                    ]
                ],
                "292251162": [
                    [
                        354945278,
                        0.033
                    ],
                    [
                        221890539,
                        2.476
                    ],
                    [
                        18774670,
                        0.298
                    ],
                    [
                        334829422,
                        0.024
                    ],
                    [
                        2022206328,
                        0.052
                    ],
                    [
                        204415100,
                        0.057
                    ],
                    [
                        315840863,
                        1.324
                    ],
                    [
                        31251706,
                        0.034
                    ],
                    [
                        45024121,
                        0.016
                    ],
                    [
                        143827821,
                        0.054
                    ],
                    [
                        741697042,
                        0.011
                    ],
                    [
                        2042498632,
                        0.189
                    ],
                    [
                        311265518,
                        0.358
                    ],
                    [
                        47240880,
                        0.06
                    ],
                    [
                        598695438,
                        8.963
                    ],
                    [
                        53320670,
                        0.608
                    ],
                    [
                        1392846239,
                        0.335
                    ],
                    [
                        609314488,
                        0.151
                    ],
                    [
                        4736267424,
                        0.671
                    ],
                    [
                        919623721,
                        0.925
                    ],
                    [
                        26633036,
                        0.125
                    ],
                    [
                        323622580,
                        0.608
                    ],
                    [
                        25758310,
                        0.045
                    ],
                    [
                        144617429,
                        0.106
                    ],
                    [
                        216224860,
                        0.12
                    ],
                    [
                        627845169,
                        15.478
                    ],
                    [
                        1555690962,
                        13.416
                    ],
                    [
                        213172152,
                        0.098
                    ],
                    [
                        322011776,
                        0.419
                    ],
                    [
                        224907941,
                        0.022
                    ],
                    [
                        1202685807,
                        2.352
                    ],
                    [
                        42131722,
                        0.958
                    ],
                    [
                        1578035865,
                        8.317
                    ],
                    [
                        3583686062,
                        0.079
                    ],
                    [
                        657715276,
                        12.213
                    ],
                    [
                        3073459808,
                        0.031
                    ],
                    [
                        182709505,
                        0.356
                    ],
                    [
                        292251162,
                        100
                    ]
                ],
                "311265518": [
                    [
                        354945278,
                        0.041
                    ],
                    [
                        221890539,
                        1.244
                    ],
                    [
                        18774670,
                        0.166
                    ],
                    [
                        334829422,
                        0.029
                    ],
                    [
                        2022206328,
                        0.118
                    ],
                    [
                        204415100,
                        0.175
                    ],
                    [
                        315840863,
                        0.457
                    ],
                    [
                        31251706,
                        0.146
                    ],
                    [
                        45024121,
                        0.109
                    ],
                    [
                        143827821,
                        0.273
                    ],
                    [
                        741697042,
                        0.004
                    ],
                    [
                        2042498632,
                        0.35
                    ],
                    [
                        47240880,
                        0.073
                    ],
                    [
                        598695438,
                        0.741
                    ],
                    [
                        53320670,
                        0.455
                    ],
                    [
                        1392846239,
                        0.193
                    ],
                    [
                        609314488,
                        0.421
                    ],
                    [
                        4736267424,
                        23.76
                    ],
                    [
                        919623721,
                        0.484
                    ],
                    [
                        26633036,
                        0.057
                    ],
                    [
                        323622580,
                        0.218
                    ],
                    [
                        25758310,
                        0.248
                    ],
                    [
                        144617429,
                        0.405
                    ],
                    [
                        216224860,
                        0.12
                    ],
                    [
                        627845169,
                        1.18
                    ],
                    [
                        1555690962,
                        0.393
                    ],
                    [
                        213172152,
                        0.046
                    ],
                    [
                        322011776,
                        1.064
                    ],
                    [
                        224907941,
                        0.084
                    ],
                    [
                        292251162,
                        0.469
                    ],
                    [
                        1202685807,
                        0.248
                    ],
                    [
                        42131722,
                        0.195
                    ],
                    [
                        1578035865,
                        0.377
                    ],
                    [
                        3583686062,
                        0.355
                    ],
                    [
                        657715276,
                        0.928
                    ],
                    [
                        3073459808,
                        0.055
                    ],
                    [
                        182709505,
                        8.075
                    ],
                    [
                        311265518,
                        100
                    ]
                ],
                "315840863": [
                    [
                        354945278,
                        0.006
                    ],
                    [
                        221890539,
                        0.498
                    ],
                    [
                        18774670,
                        0.155
                    ],
                    [
                        334829422,
                        0.01
                    ],
                    [
                        2022206328,
                        0.017
                    ],
                    [
                        204415100,
                        0.01
                    ],
                    [
                        31251706,
                        0.12
                    ],
                    [
                        45024121,
                        0.108
                    ],
                    [
                        143827821,
                        0.304
                    ],
                    [
                        741697042,
                        0.006
                    ],
                    [
                        2042498632,
                        0.141
                    ],
                    [
                        311265518,
                        0.304
                    ],
                    [
                        47240880,
                        0.25
                    ],
                    [
                        598695438,
                        1.877
                    ],
                    [
                        53320670,
                        0.084
                    ],
                    [
                        1392846239,
                        0.235
                    ],
                    [
                        609314488,
                        0.21
                    ],
                    [
                        4736267424,
                        0.448
                    ],
                    [
                        919623721,
                        0.359
                    ],
                    [
                        26633036,
                        0.039
                    ],
                    [
                        323622580,
                        0.176
                    ],
                    [
                        25758310,
                        0.141
                    ],
                    [
                        144617429,
                        0.053
                    ],
                    [
                        216224860,
                        0.021
                    ],
                    [
                        627845169,
                        3.184
                    ],
                    [
                        1555690962,
                        1.593
                    ],
                    [
                        213172152,
                        0.012
                    ],
                    [
                        322011776,
                        0.15
                    ],
                    [
                        224907941,
                        0.137
                    ],
                    [
                        292251162,
                        1.157
                    ],
                    [
                        1202685807,
                        0.133
                    ],
                    [
                        42131722,
                        0.16
                    ],
                    [
                        1578035865,
                        2.866
                    ],
                    [
                        3583686062,
                        0.059
                    ],
                    [
                        657715276,
                        2.021
                    ],
                    [
                        3073459808,
                        0.017
                    ],
                    [
                        182709505,
                        0.372
                    ],
                    [
                        315840863,
                        100
                    ]
                ],
                "322011776": [
                    [
                        354945278,
                        0.016
                    ],
                    [
                        221890539,
                        0.98
                    ],
                    [
                        18774670,
                        0.073
                    ],
                    [
                        334829422,
                        0.014
                    ],
                    [
                        2022206328,
                        0.063
                    ],
                    [
                        204415100,
                        0.065
                    ],
                    [
                        315840863,
                        0.127
                    ],
                    [
                        31251706,
                        1.47
                    ],
                    [
                        45024121,
                        0.34
                    ],
                    [
                        143827821,
                        0.006
                    ],
                    [
                        741697042,
                        0.004
                    ],
                    [
                        2042498632,
                        0.243
                    ],
                    [
                        311265518,
                        0.599
                    ],
                    [
                        47240880,
                        0.517
                    ],
                    [
                        598695438,
                        0.497
                    ],
                    [
                        53320670,
                        0.53
                    ],
                    [
                        1392846239,
                        0.51
                    ],
                    [
                        609314488,
                        0.119
                    ],
                    [
                        4736267424,
                        0.474
                    ],
                    [
                        919623721,
                        0.575
                    ],
                    [
                        26633036,
                        0.037
                    ],
                    [
                        323622580,
                        1.381
                    ],
                    [
                        25758310,
                        0.053
                    ],
                    [
                        144617429,
                        0.177
                    ],
                    [
                        216224860,
                        0.326
                    ],
                    [
                        627845169,
                        0.548
                    ],
                    [
                        1555690962,
                        0.189
                    ],
                    [
                        213172152,
                        0.01
                    ],
                    [
                        224907941,
                        0.058
                    ],
                    [
                        292251162,
                        0.31
                    ],
                    [
                        1202685807,
                        0.257
                    ],
                    [
                        42131722,
                        0.59
                    ],
                    [
                        1578035865,
                        0.165
                    ],
                    [
                        3583686062,
                        0.657
                    ],
                    [
                        657715276,
                        0.161
                    ],
                    [
                        3073459808,
                        0.079
                    ],
                    [
                        182709505,
                        0.251
                    ],
                    [
                        322011776,
                        100
                    ]
                ],
                "323622580": [
                    [
                        354945278,
                        0.033
                    ],
                    [
                        221890539,
                        0.71
                    ],
                    [
                        18774670,
                        0.168
                    ],
                    [
                        334829422,
                        0.025
                    ],
                    [
                        2022206328,
                        0.059
                    ],
                    [
                        204415100,
                        0.035
                    ],
                    [
                        315840863,
                        0.244
                    ],
                    [
                        31251706,
                        1.953
                    ],
                    [
                        45024121,
                        0.173
                    ],
                    [
                        143827821,
                        0.031
                    ],
                    [
                        741697042,
                        0.005
                    ],
                    [
                        2042498632,
                        0.2
                    ],
                    [
                        311265518,
                        0.201
                    ],
                    [
                        47240880,
                        1.147
                    ],
                    [
                        598695438,
                        1.682
                    ],
                    [
                        53320670,
                        0.811
                    ],
                    [
                        1392846239,
                        0.887
                    ],
                    [
                        609314488,
                        0.188
                    ],
                    [
                        4736267424,
                        0.325
                    ],
                    [
                        919623721,
                        2.47
                    ],
                    [
                        26633036,
                        0.051
                    ],
                    [
                        25758310,
                        0.073
                    ],
                    [
                        144617429,
                        0.21
                    ],
                    [
                        216224860,
                        2.746
                    ],
                    [
                        627845169,
                        0.835
                    ],
                    [
                        1555690962,
                        0.608
                    ],
                    [
                        213172152,
                        0.058
                    ],
                    [
                        322011776,
                        2.269
                    ],
                    [
                        224907941,
                        0.116
                    ],
                    [
                        292251162,
                        0.738
                    ],
                    [
                        1202685807,
                        0.225
                    ],
                    [
                        42131722,
                        0.502
                    ],
                    [
                        1578035865,
                        0.332
                    ],
                    [
                        3583686062,
                        0.196
                    ],
                    [
                        657715276,
                        0.368
                    ],
                    [
                        3073459808,
                        0.187
                    ],
                    [
                        182709505,
                        0.276
                    ],
                    [
                        323622580,
                        100
                    ]
                ],
                "334829422": [
                    [
                        354945278,
                        39.517
                    ],
                    [
                        221890539,
                        1.582
                    ],
                    [
                        18774670,
                        1.624
                    ],
                    [
                        2022206328,
                        0.103
                    ],
                    [
                        204415100,
                        0.316
                    ],
                    [
                        315840863,
                        0.026
                    ],
                    [
                        31251706,
                        0.026
                    ],
                    [
                        45024121,
                        0.029
                    ],
                    [
                        143827821,
                        0.029
                    ],
                    [
                        741697042,
                        0.546
                    ],
                    [
                        2042498632,
                        0.039
                    ],
                    [
                        311265518,
                        0.052
                    ],
                    [
                        47240880,
                        0.026
                    ],
                    [
                        598695438,
                        0.184
                    ],
                    [
                        53320670,
                        0.462
                    ],
                    [
                        1392846239,
                        0.119
                    ],
                    [
                        609314488,
                        0.074
                    ],
                    [
                        4736267424,
                        0.058
                    ],
                    [
                        919623721,
                        0.087
                    ],
                    [
                        26633036,
                        0.646
                    ],
                    [
                        323622580,
                        0.048
                    ],
                    [
                        25758310,
                        0.048
                    ],
                    [
                        144617429,
                        0.084
                    ],
                    [
                        216224860,
                        0.232
                    ],
                    [
                        627845169,
                        0.084
                    ],
                    [
                        1555690962,
                        0.045
                    ],
                    [
                        213172152,
                        0.003
                    ],
                    [
                        322011776,
                        0.045
                    ],
                    [
                        224907941,
                        0
                    ],
                    [
                        292251162,
                        0.058
                    ],
                    [
                        1202685807,
                        0.294
                    ],
                    [
                        42131722,
                        0.5
                    ],
                    [
                        1578035865,
                        0.006
                    ],
                    [
                        3583686062,
                        0.068
                    ],
                    [
                        657715276,
                        0.032
                    ],
                    [
                        3073459808,
                        0.042
                    ],
                    [
                        182709505,
                        0.058
                    ],
                    [
                        334829422,
                        100
                    ]
                ],
                "354945278": [
                    [
                        221890539,
                        2.419
                    ],
                    [
                        18774670,
                        2.12
                    ],
                    [
                        334829422,
                        49.435
                    ],
                    [
                        2022206328,
                        0.081
                    ],
                    [
                        204415100,
                        0.44
                    ],
                    [
                        315840863,
                        0.02
                    ],
                    [
                        31251706,
                        0.02
                    ],
                    [
                        45024121,
                        0.028
                    ],
                    [
                        143827821,
                        0.036
                    ],
                    [
                        741697042,
                        0.158
                    ],
                    [
                        2042498632,
                        0.036
                    ],
                    [
                        311265518,
                        0.093
                    ],
                    [
                        47240880,
                        0.024
                    ],
                    [
                        598695438,
                        0.287
                    ],
                    [
                        53320670,
                        0.646
                    ],
                    [
                        1392846239,
                        0.145
                    ],
                    [
                        609314488,
                        0.105
                    ],
                    [
                        4736267424,
                        0.137
                    ],
                    [
                        919623721,
                        0.145
                    ],
                    [
                        26633036,
                        0.287
                    ],
                    [
                        323622580,
                        0.081
                    ],
                    [
                        25758310,
                        0.048
                    ],
                    [
                        144617429,
                        0.121
                    ],
                    [
                        216224860,
                        0.408
                    ],
                    [
                        627845169,
                        0.141
                    ],
                    [
                        1555690962,
                        0.057
                    ],
                    [
                        213172152,
                        0
                    ],
                    [
                        322011776,
                        0.065
                    ],
                    [
                        224907941,
                        0.004
                    ],
                    [
                        292251162,
                        0.097
                    ],
                    [
                        1202685807,
                        0.4
                    ],
                    [
                        42131722,
                        0.784
                    ],
                    [
                        1578035865,
                        0.016
                    ],
                    [
                        3583686062,
                        0.061
                    ],
                    [
                        657715276,
                        0.044
                    ],
                    [
                        3073459808,
                        0.024
                    ],
                    [
                        182709505,
                        0.097
                    ],
                    [
                        354945278,
                        100
                    ]
                ],
                "598695438": [
                    [
                        354945278,
                        0.026
                    ],
                    [
                        221890539,
                        9.687
                    ],
                    [
                        18774670,
                        0.309
                    ],
                    [
                        334829422,
                        0.021
                    ],
                    [
                        2022206328,
                        0.064
                    ],
                    [
                        204415100,
                        0.087
                    ],
                    [
                        315840863,
                        0.588
                    ],
                    [
                        31251706,
                        0.077
                    ],
                    [
                        45024121,
                        0.028
                    ],
                    [
                        143827821,
                        0.057
                    ],
                    [
                        741697042,
                        0.014
                    ],
                    [
                        2042498632,
                        0.153
                    ],
                    [
                        311265518,
                        0.154
                    ],
                    [
                        47240880,
                        0.139
                    ],
                    [
                        53320670,
                        0.301
                    ],
                    [
                        1392846239,
                        0.494
                    ],
                    [
                        609314488,
                        0.091
                    ],
                    [
                        4736267424,
                        0.32
                    ],
                    [
                        919623721,
                        0.903
                    ],
                    [
                        26633036,
                        0.14
                    ],
                    [
                        323622580,
                        0.379
                    ],
                    [
                        25758310,
                        0.059
                    ],
                    [
                        144617429,
                        0.072
                    ],
                    [
                        216224860,
                        0.083
                    ],
                    [
                        627845169,
                        2.039
                    ],
                    [
                        1555690962,
                        2.17
                    ],
                    [
                        213172152,
                        0.032
                    ],
                    [
                        322011776,
                        0.184
                    ],
                    [
                        224907941,
                        0.022
                    ],
                    [
                        292251162,
                        2.453
                    ],
                    [
                        1202685807,
                        0.899
                    ],
                    [
                        42131722,
                        5.417
                    ],
                    [
                        1578035865,
                        1.877
                    ],
                    [
                        3583686062,
                        0.114
                    ],
                    [
                        657715276,
                        1.6
                    ],
                    [
                        3073459808,
                        0.038
                    ],
                    [
                        182709505,
                        0.15
                    ],
                    [
                        598695438,
                        100
                    ]
                ],
                "609314488": [
                    [
                        354945278,
                        0.04
                    ],
                    [
                        221890539,
                        0.539
                    ],
                    [
                        18774670,
                        0.181
                    ],
                    [
                        334829422,
                        0.035
                    ],
                    [
                        2022206328,
                        0.031
                    ],
                    [
                        204415100,
                        0.058
                    ],
                    [
                        315840863,
                        0.271
                    ],
                    [
                        31251706,
                        0.096
                    ],
                    [
                        45024121,
                        0.147
                    ],
                    [
                        143827821,
                        0.193
                    ],
                    [
                        741697042,
                        0.002
                    ],
                    [
                        2042498632,
                        0.19
                    ],
                    [
                        311265518,
                        0.361
                    ],
                    [
                        47240880,
                        0.084
                    ],
                    [
                        598695438,
                        0.375
                    ],
                    [
                        53320670,
                        0.346
                    ],
                    [
                        1392846239,
                        0.213
                    ],
                    [
                        4736267424,
                        0.53
                    ],
                    [
                        919623721,
                        0.211
                    ],
                    [
                        26633036,
                        0.04
                    ],
                    [
                        323622580,
                        0.175
                    ],
                    [
                        25758310,
                        0.145
                    ],
                    [
                        144617429,
                        0.089
                    ],
                    [
                        216224860,
                        0.103
                    ],
                    [
                        627845169,
                        0.187
                    ],
                    [
                        1555690962,
                        0.118
                    ],
                    [
                        213172152,
                        0.005
                    ],
                    [
                        322011776,
                        0.181
                    ],
                    [
                        224907941,
                        0.126
                    ],
                    [
                        292251162,
                        0.17
                    ],
                    [
                        1202685807,
                        0.292
                    ],
                    [
                        42131722,
                        0.126
                    ],
                    [
                        1578035865,
                        0.15
                    ],
                    [
                        3583686062,
                        0.037
                    ],
                    [
                        657715276,
                        0.095
                    ],
                    [
                        3073459808,
                        0.159
                    ],
                    [
                        182709505,
                        0.683
                    ],
                    [
                        609314488,
                        100
                    ]
                ],
                "627845169": [
                    [
                        354945278,
                        0.039
                    ],
                    [
                        221890539,
                        1.961
                    ],
                    [
                        18774670,
                        0.218
                    ],
                    [
                        334829422,
                        0.029
                    ],
                    [
                        2022206328,
                        0.062
                    ],
                    [
                        204415100,
                        0.068
                    ],
                    [
                        315840863,
                        2.982
                    ],
                    [
                        31251706,
                        0.131
                    ],
                    [
                        45024121,
                        0.01
                    ],
                    [
                        143827821,
                        0.066
                    ],
                    [
                        741697042,
                        0.004
                    ],
                    [
                        2042498632,
                        0.141
                    ],
                    [
                        311265518,
                        0.736
                    ],
                    [
                        47240880,
                        0.125
                    ],
                    [
                        598695438,
                        6.098
                    ],
                    [
                        53320670,
                        0.68
                    ],
                    [
                        1392846239,
                        0.433
                    ],
                    [
                        609314488,
                        0.136
                    ],
                    [
                        4736267424,
                        1.338
                    ],
                    [
                        919623721,
                        0.891
                    ],
                    [
                        26633036,
                        0.087
                    ],
                    [
                        323622580,
                        0.563
                    ],
                    [
                        25758310,
                        0.059
                    ],
                    [
                        144617429,
                        0.097
                    ],
                    [
                        216224860,
                        0.047
                    ],
                    [
                        1555690962,
                        7.383
                    ],
                    [
                        213172152,
                        0.039
                    ],
                    [
                        322011776,
                        0.607
                    ],
                    [
                        224907941,
                        0.02
                    ],
                    [
                        292251162,
                        12.665
                    ],
                    [
                        1202685807,
                        1.788
                    ],
                    [
                        42131722,
                        0.573
                    ],
                    [
                        1578035865,
                        6.517
                    ],
                    [
                        3583686062,
                        0.061
                    ],
                    [
                        657715276,
                        9.717
                    ],
                    [
                        3073459808,
                        0.029
                    ],
                    [
                        182709505,
                        0.519
                    ],
                    [
                        627845169,
                        100
                    ]
                ],
                "657715276": [
                    [
                        354945278,
                        0.013
                    ],
                    [
                        221890539,
                        2.466
                    ],
                    [
                        18774670,
                        0.205
                    ],
                    [
                        334829422,
                        0.012
                    ],
                    [
                        2022206328,
                        0.117
                    ],
                    [
                        204415100,
                        0.08
                    ],
                    [
                        315840863,
                        2.036
                    ],
                    [
                        31251706,
                        0.079
                    ],
                    [
                        45024121,
                        0.013
                    ],
                    [
                        143827821,
                        0.101
                    ],
                    [
                        741697042,
                        0.022
                    ],
                    [
                        2042498632,
                        0.156
                    ],
                    [
                        311265518,
                        0.623
                    ],
                    [
                        47240880,
                        0.083
                    ],
                    [
                        598695438,
                        5.149
                    ],
                    [
                        53320670,
                        0.201
                    ],
                    [
                        1392846239,
                        0.262
                    ],
                    [
                        609314488,
                        0.074
                    ],
                    [
                        4736267424,
                        0.926
                    ],
                    [
                        919623721,
                        0.472
                    ],
                    [
                        26633036,
                        0.084
                    ],
                    [
                        323622580,
                        0.267
                    ],
                    [
                        25758310,
                        0.117
                    ],
                    [
                        144617429,
                        0.103
                    ],
                    [
                        216224860,
                        0.034
                    ],
                    [
                        627845169,
                        10.456
                    ],
                    [
                        1555690962,
                        7.817
                    ],
                    [
                        213172152,
                        0.067
                    ],
                    [
                        322011776,
                        0.192
                    ],
                    [
                        224907941,
                        0.013
                    ],
                    [
                        292251162,
                        10.754
                    ],
                    [
                        1202685807,
                        0.297
                    ],
                    [
                        42131722,
                        0.395
                    ],
                    [
                        1578035865,
                        4.683
                    ],
                    [
                        3583686062,
                        0.111
                    ],
                    [
                        3073459808,
                        0.037
                    ],
                    [
                        182709505,
                        0.313
                    ],
                    [
                        657715276,
                        100
                    ]
                ],
                "741697042": [
                    [
                        354945278,
                        0.114
                    ],
                    [
                        221890539,
                        1.365
                    ],
                    [
                        18774670,
                        9.808
                    ],
                    [
                        334829422,
                        0.494
                    ],
                    [
                        2022206328,
                        0.026
                    ],
                    [
                        204415100,
                        0.035
                    ],
                    [
                        315840863,
                        0.015
                    ],
                    [
                        31251706,
                        0.009
                    ],
                    [
                        45024121,
                        0.003
                    ],
                    [
                        143827821,
                        0.006
                    ],
                    [
                        2042498632,
                        0.018
                    ],
                    [
                        311265518,
                        0.006
                    ],
                    [
                        47240880,
                        0.012
                    ],
                    [
                        598695438,
                        0.111
                    ],
                    [
                        53320670,
                        0.02
                    ],
                    [
                        1392846239,
                        0.012
                    ],
                    [
                        609314488,
                        0.003
                    ],
                    [
                        4736267424,
                        0.029
                    ],
                    [
                        919623721,
                        0.047
                    ],
                    [
                        26633036,
                        15.52
                    ],
                    [
                        323622580,
                        0.009
                    ],
                    [
                        25758310,
                        0.003
                    ],
                    [
                        144617429,
                        0.006
                    ],
                    [
                        216224860,
                        0.029
                    ],
                    [
                        627845169,
                        0.012
                    ],
                    [
                        1555690962,
                        0.035
                    ],
                    [
                        213172152,
                        0.003
                    ],
                    [
                        322011776,
                        0.012
                    ],
                    [
                        224907941,
                        0.003
                    ],
                    [
                        292251162,
                        0.023
                    ],
                    [
                        1202685807,
                        0.041
                    ],
                    [
                        42131722,
                        0.55
                    ],
                    [
                        1578035865,
                        0.003
                    ],
                    [
                        3583686062,
                        0.006
                    ],
                    [
                        657715276,
                        0.053
                    ],
                    [
                        3073459808,
                        0.006
                    ],
                    [
                        182709505,
                        0.009
                    ],
                    [
                        741697042,
                        100
                    ]
                ],
                "919623721": [
                    [
                        354945278,
                        0.034
                    ],
                    [
                        221890539,
                        4.179
                    ],
                    [
                        18774670,
                        0.535
                    ],
                    [
                        334829422,
                        0.025
                    ],
                    [
                        2022206328,
                        0.017
                    ],
                    [
                        204415100,
                        0.032
                    ],
                    [
                        315840863,
                        0.282
                    ],
                    [
                        31251706,
                        0.251
                    ],
                    [
                        45024121,
                        0.062
                    ],
                    [
                        143827821,
                        0.074
                    ],
                    [
                        741697042,
                        0.015
                    ],
                    [
                        2042498632,
                        0.253
                    ],
                    [
                        311265518,
                        0.253
                    ],
                    [
                        47240880,
                        0.369
                    ],
                    [
                        598695438,
                        2.262
                    ],
                    [
                        53320670,
                        0.848
                    ],
                    [
                        1392846239,
                        0.465
                    ],
                    [
                        609314488,
                        0.129
                    ],
                    [
                        4736267424,
                        0.269
                    ],
                    [
                        26633036,
                        0.174
                    ],
                    [
                        323622580,
                        1.395
                    ],
                    [
                        25758310,
                        0.089
                    ],
                    [
                        144617429,
                        0.038
                    ],
                    [
                        216224860,
                        0.332
                    ],
                    [
                        627845169,
                        0.746
                    ],
                    [
                        1555690962,
                        0.677
                    ],
                    [
                        213172152,
                        0.009
                    ],
                    [
                        322011776,
                        0.533
                    ],
                    [
                        224907941,
                        0.026
                    ],
                    [
                        292251162,
                        0.634
                    ],
                    [
                        1202685807,
                        0.827
                    ],
                    [
                        42131722,
                        1.129
                    ],
                    [
                        1578035865,
                        0.395
                    ],
                    [
                        3583686062,
                        0.051
                    ],
                    [
                        657715276,
                        0.367
                    ],
                    [
                        3073459808,
                        0.042
                    ],
                    [
                        182709505,
                        0.153
                    ],
                    [
                        919623721,
                        100
                    ]
                ],
                "1202685807": [
                    [
                        354945278,
                        0.12
                    ],
                    [
                        221890539,
                        6.03
                    ],
                    [
                        18774670,
                        0.33
                    ],
                    [
                        334829422,
                        0.11
                    ],
                    [
                        2022206328,
                        0.116
                    ],
                    [
                        204415100,
                        0.175
                    ],
                    [
                        315840863,
                        0.135
                    ],
                    [
                        31251706,
                        0.018
                    ],
                    [
                        45024121,
                        0.005
                    ],
                    [
                        143827821,
                        0.018
                    ],
                    [
                        741697042,
                        0.017
                    ],
                    [
                        2042498632,
                        0.313
                    ],
                    [
                        311265518,
                        0.168
                    ],
                    [
                        47240880,
                        0.051
                    ],
                    [
                        598695438,
                        2.921
                    ],
                    [
                        53320670,
                        1.619
                    ],
                    [
                        1392846239,
                        0.443
                    ],
                    [
                        609314488,
                        0.231
                    ],
                    [
                        4736267424,
                        0.501
                    ],
                    [
                        919623721,
                        1.073
                    ],
                    [
                        26633036,
                        0.252
                    ],
                    [
                        323622580,
                        0.164
                    ],
                    [
                        25758310,
                        0.018
                    ],
                    [
                        144617429,
                        0.062
                    ],
                    [
                        216224860,
                        0.111
                    ],
                    [
                        627845169,
                        1.942
                    ],
                    [
                        1555690962,
                        1.344
                    ],
                    [
                        213172152,
                        0.004
                    ],
                    [
                        322011776,
                        0.31
                    ],
                    [
                        224907941,
                        0.015
                    ],
                    [
                        292251162,
                        2.091
                    ],
                    [
                        42131722,
                        2.141
                    ],
                    [
                        1578035865,
                        0.98
                    ],
                    [
                        3583686062,
                        0.073
                    ],
                    [
                        657715276,
                        0.3
                    ],
                    [
                        3073459808,
                        0.087
                    ],
                    [
                        182709505,
                        0.133
                    ],
                    [
                        1202685807,
                        100
                    ]
                ],
                "1392846239": [
                    [
                        354945278,
                        0.047
                    ],
                    [
                        221890539,
                        4.164
                    ],
                    [
                        18774670,
                        0.129
                    ],
                    [
                        334829422,
                        0.048
                    ],
                    [
                        2022206328,
                        0.035
                    ],
                    [
                        204415100,
                        0.25
                    ],
                    [
                        315840863,
                        0.258
                    ],
                    [
                        31251706,
                        0.464
                    ],
                    [
                        45024121,
                        0.091
                    ],
                    [
                        143827821,
                        0.057
                    ],
                    [
                        741697042,
                        0.005
                    ],
                    [
                        2042498632,
                        0.112
                    ],
                    [
                        311265518,
                        0.141
                    ],
                    [
                        47240880,
                        0.255
                    ],
                    [
                        598695438,
                        1.73
                    ],
                    [
                        53320670,
                        1.496
                    ],
                    [
                        609314488,
                        0.181
                    ],
                    [
                        4736267424,
                        0.194
                    ],
                    [
                        919623721,
                        0.65
                    ],
                    [
                        26633036,
                        0.043
                    ],
                    [
                        323622580,
                        0.7
                    ],
                    [
                        25758310,
                        0.222
                    ],
                    [
                        144617429,
                        0.043
                    ],
                    [
                        216224860,
                        0.108
                    ],
                    [
                        627845169,
                        0.507
                    ],
                    [
                        1555690962,
                        0.245
                    ],
                    [
                        213172152,
                        0.003
                    ],
                    [
                        322011776,
                        0.661
                    ],
                    [
                        224907941,
                        0.038
                    ],
                    [
                        292251162,
                        0.321
                    ],
                    [
                        1202685807,
                        0.477
                    ],
                    [
                        42131722,
                        1.134
                    ],
                    [
                        1578035865,
                        0.173
                    ],
                    [
                        3583686062,
                        0.048
                    ],
                    [
                        657715276,
                        0.285
                    ],
                    [
                        3073459808,
                        0.083
                    ],
                    [
                        182709505,
                        0.137
                    ],
                    [
                        1392846239,
                        100
                    ]
                ],
                "1555690962": [
                    [
                        354945278,
                        0.021
                    ],
                    [
                        221890539,
                        1.72
                    ],
                    [
                        18774670,
                        0.26
                    ],
                    [
                        334829422,
                        0.021
                    ],
                    [
                        2022206328,
                        0.053
                    ],
                    [
                        204415100,
                        0.046
                    ],
                    [
                        315840863,
                        2.037
                    ],
                    [
                        31251706,
                        0.055
                    ],
                    [
                        45024121,
                        0.014
                    ],
                    [
                        143827821,
                        0.079
                    ],
                    [
                        741697042,
                        0.018
                    ],
                    [
                        2042498632,
                        0.266
                    ],
                    [
                        311265518,
                        0.335
                    ],
                    [
                        47240880,
                        0.169
                    ],
                    [
                        598695438,
                        8.865
                    ],
                    [
                        53320670,
                        0.441
                    ],
                    [
                        1392846239,
                        0.286
                    ],
                    [
                        609314488,
                        0.117
                    ],
                    [
                        4736267424,
                        0.585
                    ],
                    [
                        919623721,
                        1.104
                    ],
                    [
                        26633036,
                        0.088
                    ],
                    [
                        323622580,
                        0.56
                    ],
                    [
                        25758310,
                        0.082
                    ],
                    [
                        144617429,
                        0.07
                    ],
                    [
                        216224860,
                        0.093
                    ],
                    [
                        627845169,
                        10.086
                    ],
                    [
                        213172152,
                        0.096
                    ],
                    [
                        322011776,
                        0.286
                    ],
                    [
                        224907941,
                        0.014
                    ],
                    [
                        292251162,
                        14.995
                    ],
                    [
                        1202685807,
                        1.689
                    ],
                    [
                        42131722,
                        0.631
                    ],
                    [
                        1578035865,
                        7.811
                    ],
                    [
                        3583686062,
                        0.087
                    ],
                    [
                        657715276,
                        9.923
                    ],
                    [
                        3073459808,
                        0.044
                    ],
                    [
                        182709505,
                        0.246
                    ],
                    [
                        1555690962,
                        100
                    ]
                ],
                "1578035865": [
                    [
                        354945278,
                        0.007
                    ],
                    [
                        221890539,
                        1.435
                    ],
                    [
                        18774670,
                        0.224
                    ],
                    [
                        334829422,
                        0.004
                    ],
                    [
                        2022206328,
                        0.037
                    ],
                    [
                        204415100,
                        0.017
                    ],
                    [
                        315840863,
                        4.458
                    ],
                    [
                        31251706,
                        0.089
                    ],
                    [
                        45024121,
                        0.044
                    ],
                    [
                        143827821,
                        0.166
                    ],
                    [
                        741697042,
                        0.002
                    ],
                    [
                        2042498632,
                        0.235
                    ],
                    [
                        311265518,
                        0.39
                    ],
                    [
                        47240880,
                        0.187
                    ],
                    [
                        598695438,
                        9.325
                    ],
                    [
                        53320670,
                        0.276
                    ],
                    [
                        1392846239,
                        0.246
                    ],
                    [
                        609314488,
                        0.181
                    ],
                    [
                        4736267424,
                        0.71
                    ],
                    [
                        919623721,
                        0.784
                    ],
                    [
                        26633036,
                        0.059
                    ],
                    [
                        323622580,
                        0.372
                    ],
                    [
                        25758310,
                        0.085
                    ],
                    [
                        144617429,
                        0.111
                    ],
                    [
                        216224860,
                        0.05
                    ],
                    [
                        627845169,
                        10.826
                    ],
                    [
                        1555690962,
                        9.498
                    ],
                    [
                        213172152,
                        0.046
                    ],
                    [
                        322011776,
                        0.303
                    ],
                    [
                        224907941,
                        0.039
                    ],
                    [
                        292251162,
                        11.305
                    ],
                    [
                        1202685807,
                        1.498
                    ],
                    [
                        42131722,
                        0.664
                    ],
                    [
                        3583686062,
                        0.059
                    ],
                    [
                        657715276,
                        7.23
                    ],
                    [
                        3073459808,
                        0.015
                    ],
                    [
                        182709505,
                        0.396
                    ],
                    [
                        1578035865,
                        100
                    ]
                ],
                "2022206328": [
                    [
                        354945278,
                        0.026
                    ],
                    [
                        221890539,
                        0.666
                    ],
                    [
                        18774670,
                        0.036
                    ],
                    [
                        334829422,
                        0.041
                    ],
                    [
                        204415100,
                        0.118
                    ],
                    [
                        315840863,
                        0.018
                    ],
                    [
                        31251706,
                        0.039
                    ],
                    [
                        45024121,
                        0.018
                    ],
                    [
                        143827821,
                        0.218
                    ],
                    [
                        741697042,
                        0.012
                    ],
                    [
                        2042498632,
                        0.615
                    ],
                    [
                        311265518,
                        0.085
                    ],
                    [
                        47240880,
                        0.049
                    ],
                    [
                        598695438,
                        0.222
                    ],
                    [
                        53320670,
                        0.159
                    ],
                    [
                        1392846239,
                        0.035
                    ],
                    [
                        609314488,
                        0.026
                    ],
                    [
                        4736267424,
                        0.234
                    ],
                    [
                        919623721,
                        0.023
                    ],
                    [
                        26633036,
                        0.045
                    ],
                    [
                        323622580,
                        0.046
                    ],
                    [
                        25758310,
                        0.204
                    ],
                    [
                        144617429,
                        3.313
                    ],
                    [
                        216224860,
                        0.028
                    ],
                    [
                        627845169,
                        0.072
                    ],
                    [
                        1555690962,
                        0.045
                    ],
                    [
                        213172152,
                        0.035
                    ],
                    [
                        322011776,
                        0.081
                    ],
                    [
                        224907941,
                        0.098
                    ],
                    [
                        292251162,
                        0.049
                    ],
                    [
                        1202685807,
                        0.124
                    ],
                    [
                        42131722,
                        0.263
                    ],
                    [
                        1578035865,
                        0.026
                    ],
                    [
                        3583686062,
                        8.664
                    ],
                    [
                        657715276,
                        0.127
                    ],
                    [
                        3073459808,
                        0.079
                    ],
                    [
                        182709505,
                        0.03
                    ],
                    [
                        2022206328,
                        100
                    ]
                ],
                "2042498632": [
                    [
                        354945278,
                        0.011
                    ],
                    [
                        221890539,
                        0.623
                    ],
                    [
                        18774670,
                        0.178
                    ],
                    [
                        334829422,
                        0.015
                    ],
                    [
                        2022206328,
                        0.576
                    ],
                    [
                        204415100,
                        0.057
                    ],
                    [
                        315840863,
                        0.144
                    ],
                    [
                        31251706,
                        0.159
                    ],
                    [
                        45024121,
                        0.081
                    ],
                    [
                        143827821,
                        0.392
                    ],
                    [
                        741697042,
                        0.007
                    ],
                    [
                        311265518,
                        0.237
                    ],
                    [
                        47240880,
                        0.133
                    ],
                    [
                        598695438,
                        0.499
                    ],
                    [
                        53320670,
                        0.19
                    ],
                    [
                        1392846239,
                        0.104
                    ],
                    [
                        609314488,
                        0.15
                    ],
                    [
                        4736267424,
                        0.295
                    ],
                    [
                        919623721,
                        0.328
                    ],
                    [
                        26633036,
                        0.07
                    ],
                    [
                        323622580,
                        0.146
                    ],
                    [
                        25758310,
                        0.139
                    ],
                    [
                        144617429,
                        0.226
                    ],
                    [
                        216224860,
                        0.051
                    ],
                    [
                        627845169,
                        0.154
                    ],
                    [
                        1555690962,
                        0.212
                    ],
                    [
                        213172152,
                        0.008
                    ],
                    [
                        322011776,
                        0.293
                    ],
                    [
                        224907941,
                        0.148
                    ],
                    [
                        292251162,
                        0.168
                    ],
                    [
                        1202685807,
                        0.313
                    ],
                    [
                        42131722,
                        0.198
                    ],
                    [
                        1578035865,
                        0.154
                    ],
                    [
                        3583686062,
                        0.496
                    ],
                    [
                        657715276,
                        0.157
                    ],
                    [
                        3073459808,
                        0.038
                    ],
                    [
                        182709505,
                        0.149
                    ],
                    [
                        2042498632,
                        100
                    ]
                ],
                "3073459808": [
                    [
                        354945278,
                        0.026
                    ],
                    [
                        221890539,
                        0.707
                    ],
                    [
                        18774670,
                        0.057
                    ],
                    [
                        334829422,
                        0.057
                    ],
                    [
                        2022206328,
                        0.268
                    ],
                    [
                        204415100,
                        0.404
                    ],
                    [
                        315840863,
                        0.061
                    ],
                    [
                        31251706,
                        0.224
                    ],
                    [
                        45024121,
                        0.132
                    ],
                    [
                        143827821,
                        0.061
                    ],
                    [
                        741697042,
                        0.009
                    ],
                    [
                        2042498632,
                        0.136
                    ],
                    [
                        311265518,
                        0.136
                    ],
                    [
                        47240880,
                        0.158
                    ],
                    [
                        598695438,
                        0.448
                    ],
                    [
                        53320670,
                        0.804
                    ],
                    [
                        1392846239,
                        0.281
                    ],
                    [
                        609314488,
                        0.457
                    ],
                    [
                        4736267424,
                        0.263
                    ],
                    [
                        919623721,
                        0.198
                    ],
                    [
                        26633036,
                        0.035
                    ],
                    [
                        323622580,
                        0.496
                    ],
                    [
                        25758310,
                        0.083
                    ],
                    [
                        144617429,
                        0.233
                    ],
                    [
                        216224860,
                        0.246
                    ],
                    [
                        627845169,
                        0.114
                    ],
                    [
                        1555690962,
                        0.127
                    ],
                    [
                        213172152,
                        0.004
                    ],
                    [
                        322011776,
                        0.347
                    ],
                    [
                        224907941,
                        0.119
                    ],
                    [
                        292251162,
                        0.101
                    ],
                    [
                        1202685807,
                        0.316
                    ],
                    [
                        42131722,
                        0.141
                    ],
                    [
                        1578035865,
                        0.035
                    ],
                    [
                        3583686062,
                        0.246
                    ],
                    [
                        657715276,
                        0.136
                    ],
                    [
                        182709505,
                        0.123
                    ],
                    [
                        3073459808,
                        100
                    ]
                ],
                "3583686062": [
                    [
                        354945278,
                        0.028
                    ],
                    [
                        221890539,
                        0.477
                    ],
                    [
                        18774670,
                        0.059
                    ],
                    [
                        334829422,
                        0.04
                    ],
                    [
                        2022206328,
                        12.743
                    ],
                    [
                        204415100,
                        0.057
                    ],
                    [
                        315840863,
                        0.095
                    ],
                    [
                        31251706,
                        0.15
                    ],
                    [
                        45024121,
                        0.245
                    ],
                    [
                        143827821,
                        0.175
                    ],
                    [
                        741697042,
                        0.004
                    ],
                    [
                        2042498632,
                        0.779
                    ],
                    [
                        311265518,
                        0.378
                    ],
                    [
                        47240880,
                        0.196
                    ],
                    [
                        598695438,
                        0.579
                    ],
                    [
                        53320670,
                        0.211
                    ],
                    [
                        1392846239,
                        0.07
                    ],
                    [
                        609314488,
                        0.046
                    ],
                    [
                        4736267424,
                        0.56
                    ],
                    [
                        919623721,
                        0.104
                    ],
                    [
                        26633036,
                        0.051
                    ],
                    [
                        323622580,
                        0.226
                    ],
                    [
                        25758310,
                        0.334
                    ],
                    [
                        144617429,
                        6.058
                    ],
                    [
                        216224860,
                        0.085
                    ],
                    [
                        627845169,
                        0.104
                    ],
                    [
                        1555690962,
                        0.108
                    ],
                    [
                        213172152,
                        0.059
                    ],
                    [
                        322011776,
                        1.242
                    ],
                    [
                        224907941,
                        0.215
                    ],
                    [
                        292251162,
                        0.11
                    ],
                    [
                        1202685807,
                        0.114
                    ],
                    [
                        42131722,
                        0.387
                    ],
                    [
                        1578035865,
                        0.061
                    ],
                    [
                        657715276,
                        0.177
                    ],
                    [
                        3073459808,
                        0.106
                    ],
                    [
                        182709505,
                        0.216
                    ],
                    [
                        3583686062,
                        100
                    ]
                ],
                "4736267424": [
                    [
                        354945278,
                        0.036
                    ],
                    [
                        221890539,
                        1.103
                    ],
                    [
                        18774670,
                        0.132
                    ],
                    [
                        334829422,
                        0.019
                    ],
                    [
                        2022206328,
                        0.193
                    ],
                    [
                        204415100,
                        0.199
                    ],
                    [
                        315840863,
                        0.401
                    ],
                    [
                        31251706,
                        0.114
                    ],
                    [
                        45024121,
                        0.067
                    ],
                    [
                        143827821,
                        0.208
                    ],
                    [
                        741697042,
                        0.011
                    ],
                    [
                        2042498632,
                        0.26
                    ],
                    [
                        311265518,
                        14.172
                    ],
                    [
                        47240880,
                        0.062
                    ],
                    [
                        598695438,
                        0.914
                    ],
                    [
                        53320670,
                        0.61
                    ],
                    [
                        1392846239,
                        0.159
                    ],
                    [
                        609314488,
                        0.368
                    ],
                    [
                        919623721,
                        0.308
                    ],
                    [
                        26633036,
                        0.077
                    ],
                    [
                        323622580,
                        0.21
                    ],
                    [
                        25758310,
                        0.148
                    ],
                    [
                        144617429,
                        0.452
                    ],
                    [
                        216224860,
                        0.098
                    ],
                    [
                        627845169,
                        1.279
                    ],
                    [
                        1555690962,
                        0.41
                    ],
                    [
                        213172152,
                        0.031
                    ],
                    [
                        322011776,
                        0.501
                    ],
                    [
                        224907941,
                        0.064
                    ],
                    [
                        292251162,
                        0.525
                    ],
                    [
                        1202685807,
                        0.441
                    ],
                    [
                        42131722,
                        0.134
                    ],
                    [
                        1578035865,
                        0.409
                    ],
                    [
                        3583686062,
                        0.314
                    ],
                    [
                        657715276,
                        0.823
                    ],
                    [
                        3073459808,
                        0.064
                    ],
                    [
                        182709505,
                        5.738
                    ],
                    [
                        4736267424,
                        100
                    ]
                ]
            },
            "bloggers_to_unique_followers": {
                "18774670": {
                    "number_of_unique": 13665741,
                    "percentage_of_unique": 0.878
                },
                "25758310": {
                    "number_of_unique": 48478,
                    "percentage_of_unique": 0.955
                },
                "26633036": {
                    "number_of_unique": 59213988,
                    "percentage_of_unique": 0.94
                },
                "31251706": {
                    "number_of_unique": 63664,
                    "percentage_of_unique": 0.904
                },
                "42131722": {
                    "number_of_unique": 869027,
                    "percentage_of_unique": 0.725
                },
                "45024121": {
                    "number_of_unique": 47800,
                    "percentage_of_unique": 0.95
                },
                "47240880": {
                    "number_of_unique": 87394,
                    "percentage_of_unique": 0.932
                },
                "53320670": {
                    "number_of_unique": 94972,
                    "percentage_of_unique": 0.913
                },
                "143827821": {
                    "number_of_unique": 83190,
                    "percentage_of_unique": 0.971
                },
                "144617429": {
                    "number_of_unique": 54542,
                    "percentage_of_unique": 0.878
                },
                "182709505": {
                    "number_of_unique": 38083,
                    "percentage_of_unique": 0.778
                },
                "204415100": {
                    "number_of_unique": 73857,
                    "percentage_of_unique": 0.942
                },
                "213172152": {
                    "number_of_unique": 5842,
                    "percentage_of_unique": 0.903
                },
                "216224860": {
                    "number_of_unique": 73267,
                    "percentage_of_unique": 0.865
                },
                "221890539": {
                    "number_of_unique": 967113,
                    "percentage_of_unique": 0.885
                },
                "224907941": {
                    "number_of_unique": 59983,
                    "percentage_of_unique": 0.957
                },
                "292251162": {
                    "number_of_unique": 46998,
                    "percentage_of_unique": 0.564
                },
                "311265518": {
                    "number_of_unique": 37147,
                    "percentage_of_unique": 0.664
                },
                "315840863": {
                    "number_of_unique": 73962,
                    "percentage_of_unique": 0.882
                },
                "322011776": {
                    "number_of_unique": 89742,
                    "percentage_of_unique": 0.901
                },
                "323622580": {
                    "number_of_unique": 58700,
                    "percentage_of_unique": 0.831
                },
                "334829422": {
                    "number_of_unique": 1300598,
                    "percentage_of_unique": 0.571
                },
                "354945278": {
                    "number_of_unique": 795999,
                    "percentage_of_unique": 0.46
                },
                "598695438": {
                    "number_of_unique": 573543,
                    "percentage_of_unique": 0.792
                },
                "609314488": {
                    "number_of_unique": 61912,
                    "percentage_of_unique": 0.949
                },
                "627845169": {
                    "number_of_unique": 61128,
                    "percentage_of_unique": 0.645
                },
                "657715276": {
                    "number_of_unique": 59196,
                    "percentage_of_unique": 0.695
                },
                "741697042": {
                    "number_of_unique": 34623921,
                    "percentage_of_unique": 0.774
                },
                "919623721": {
                    "number_of_unique": 92660,
                    "percentage_of_unique": 0.862
                },
                "1202685807": {
                    "number_of_unique": 107953,
                    "percentage_of_unique": 0.817
                },
                "1392846239": {
                    "number_of_unique": 67351,
                    "percentage_of_unique": 0.876
                },
                "1555690962": {
                    "number_of_unique": 53176,
                    "percentage_of_unique": 0.617
                },
                "1578035865": {
                    "number_of_unique": 78485,
                    "percentage_of_unique": 0.637
                },
                "2022206328": {
                    "number_of_unique": 83482,
                    "percentage_of_unique": 0.865
                },
                "2042498632": {
                    "number_of_unique": 78065,
                    "percentage_of_unique": 0.943
                },
                "3073459808": {
                    "number_of_unique": 21589,
                    "percentage_of_unique": 0.936
                },
                "3583686062": {
                    "number_of_unique": 50093,
                    "percentage_of_unique": 0.774
                },
                "4736267424": {
                    "number_of_unique": 71977,
                    "percentage_of_unique": 0.765
                }
            }
        },
        "total_unique_audience": 113934618,
        "unique_audience_share": 0.8572525710496677
    },
    "task_id": "f738bef6-26b5-4d79-9aa9-c9a7e08cbb33",
    "intersection_count": 132906709,
    "created_date": "2022-09-16"
}