import React, { FC, memo, useMemo } from 'react'
import Heading from '@/components/Heading'
import { shortNum } from '@/_helpers/_graphs/_tech'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { Flex } from '@/_yc'
import { useInterpolate } from '../useInterpolate'
import Bench from './Bench'
import Crown from './Crown'
import Group from './Group'
import cn from './Info.module.scss'
import Item from './Item'
import Text from '@/components/Text'

export interface IInfo {
    margin?: string | number
    name: string
    unique: number
    all: number
    count: number
    uniqueToSum: number
}

const Info: FC<IInfo> = (props) => {
    const t = useSpaceTranslation('intersections.intersection.head')

    const color = useInterpolate(props.uniqueToSum, false)
    const background = useInterpolate(props.uniqueToSum, false, 0.15)

    const style = useMemo(
        () => ({
            background,
            color,
        }),
        [background, color]
    )

    return (
        <div style={{ margin: props.margin }} className={cn.root}>
            <Heading type="h5">{props.name}</Heading>
            <Flex wrap margin="24px 0 0">
                <Item title={shortNum(props.unique)} description={t('unique.description')} icon={Bench}>
                    <Text fSize={18} margin="0 0 0 10px" semibold className={cn.up} style={style}>
                        {(props.uniqueToSum * 100).toFixed(0)}%
                    </Text>
                </Item>
                <Item title={shortNum(props.all)} description={t('all.description')} icon={Group} />
                <Item title={props.count} description={t('count.description')} icon={Crown} />
            </Flex>
        </div>
    )
}

export default memo(Info)
