import React, { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import cn from './Header.module.scss'
import { Flex } from '@/_yc'
import Heading from '@/components/Heading'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'

export interface IHeader {
    children?: ReactNode
    title?: string
}

const Header: FC<IHeader> = (props) => {
    const { t } = useTranslation()

    const size = useWindowWidth()

    const xs = size === 'mobile'

    const style = { width: '100%' }

    return (
        <header className={cn.root}>
            <div className={cn.widther}>
                <Heading margin="0 0 14px" color={'gray-1'} type="h3">
                    {props.title ? props.title : t('aud_insides.title')}
                </Heading>
                <Flex column={xs} align={xs ? 'flex-start' : 'center'} content={xs ? 'center' : 'space-between'}>
                    <Flex grow style={style} align="center">
                        {props.children}
                    </Flex>
                </Flex>
            </div>
        </header>
    )
}

export { Header as default }
