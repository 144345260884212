export default (response) => {
	const { name, status, bloggers, data } = response;
	const { blogger_short, blogger_info, total_unique_audience, unique_audience_share, total_audience, intersection_data={} } = data;
	const { bloggers_to_intersections, bloggers_to_unique_followers } = intersection_data;

	const legend = [];
	const rows = [];

	const ids = Object.keys(bloggers_to_intersections);

	ids.forEach((extId) => {
		legend.push({
			id: extId,
			avatar: blogger_short[extId].avatar
		})

		const cols = new Array(ids.length);

		bloggers_to_intersections[extId].forEach(([id, percent]) => {
			cols[ids.indexOf(id + "")] = {
				id: id, percent
			};
		})

		rows.push({
			id: extId,
			username: blogger_short[extId].username,
			fullName: blogger_info[extId].fullname,
			avatar: blogger_short[extId].avatar,
			data: cols
		})
	})

	return {
		name,
		inProcess: status !== "SUCCESS",
		overview: bloggers.map((id) => {
			return {
				id,
				avatar: blogger_short[id].avatar,
				username: blogger_short[id].username,
				fullName: blogger_info[id].fullname,
				followers: blogger_info[id].followers,
				intersectionsPercent: +(bloggers_to_unique_followers[id].percentage_of_unique * 100).toFixed(1),
				intersectionsCount: bloggers_to_unique_followers[id].number_of_unique,
				aq: Math.round(blogger_info[id].aq * 100),
				age: blogger_info[id].age,
				gender: blogger_info[id].gender
			}
		}),
		matrix: {
			legend,
			rows
		},
		meta: {
			followersSum: total_audience,
			sumOfUnique: total_unique_audience,
			uniqueToSum: unique_audience_share,
			count: bloggers.length
		}
	}
} 
/*
{
	name: "TEST",
	inProcess: false,
	overview: [
		{
			username: "name",
			fullName: "fullName",
			followers: formatedFollowers(12567000),
			intersectionsPercent: 100,
			intersectionsCount: numberFormatter(1234567),
			aq: 65,
			age: "18-24",
			gender: "male"
		},
		{
			username: "1name1",
			fullName: "1fullName1",
			followers: formatedFollowers(12567000),
			intersectionsPercent: 45,
			intersectionsCount: numberFormatter(1234567),
			aq: 0,
			age: "18-24",
			gender: "female"
		},
		{
			username: "1name111",
			fullName: "1fullName111",
			followers: formatedFollowers(200),
			intersectionsPercent: 45,
			intersectionsCount: numberFormatter(2100),
			aq: 50,
			age: "18-24",
			gender: "female"
		}
	],
	matrix: {
		legend: ["name", "1name1", "2name2", "2name21", "2name22", "2name23", "2name24"],
		columns: [
			{
				label: "name",
				data: [100, 90, 80, 70, 60, 50, 40]
			},
			{
				label: "1name1",
				data: [90, 100, 70, 60, 50, 50, 50]
			},
			{
				label: "2name2",
				data: [80, 70, 100, 50, 50, 50, 50]
			},
			{
				label: "2name21",
				data: [70, 60, 100, 100, 50, 50, 50]
			},
			{
				label: "2name22",
				data: [60, 50, 100, 50, 100, 50, 50]
			},
			{
				label: "2name23",
				data: [50, 50, 100, 50, 50, 100, 50]
			},
			{
				label: "2name24",
				data: [40, 50, 100, 50, 50, 50, 100]
			}
		]
	}
}
*/