import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 12.6668H2V11.3335H6V12.6668ZM8.66667 10.0002H2V8.66683H8.66667V10.0002ZM11.3333 7.3335H2V6.00016H11.3333V7.3335ZM14 4.66683H2V3.3335H14V4.66683Z"
                fill="#76849B"
            />
        </svg>
    )
}
