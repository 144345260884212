import React, { PureComponent } from 'react'
import pt from 'prop-types'
import ym from 'react-yandex-metrika'

const useGlob = () => {
    if (global.isProd) {
        global.gtag('event', 'регистрация', {
            event_category: 'registration',
            event_action: 'send_registration',
        })
        ym('reachGoal', 'registration')
    }
}

export default () => (Component) =>
    class withOAuth extends PureComponent {
        static propTypes = {
            OAuthService: pt.func.isRequired,
            error: pt.func.isRequired,
        }
        //https://app.yolotech.ru/oauth/?state=SJcyU6vcLuNCJew0GcVLerNH4trbr6gc&code=4%2F0AVHEtk4_6pNIBRO9PoWSub8ulrdbWbL6YXSeloV9oStEcpbEazRcVKRpCjJz5ttiRCDCQQ&scope=email+profile+openid+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&authuser=0&prompt=none
        oauth = this.oauth.bind(this)
        oauth() {
            this.props
                .OAuthService()
                .then(({ redirect_url }) => {
                    window.location.href = redirect_url

                    if (window.location.pathname === '/signup') {
                        useGlob()
                    }
                })
                .catch((err) => {
                    console.error({ err })
                    this.props.error()
                })
        }

        render() {
            return <Component oauth={this.oauth} {...this.props} />
        }
    }
