import React from 'react'
import cn from './EmptyState.module.scss'
import { ChooseAllNEW } from '@/components/MassAddToList/ChooseAll'

import Text from '@/components/Text'
import { useSpaceTranslation } from '@/_helpers'
import Icons from '@/components/Icons'

export const EmptyState = () => {
    const t = useSpaceTranslation('media_planner.plan.table.empty_state')
    return (
        <div className={cn.emptyState}>
            <div className={cn.header}>
                <div>
                    <ChooseAllNEW platform="media-empty" value={'empty'} />
                </div>

                <Text color="gray-3" fSize={10}>
                    {t('username')}
                </Text>

                <Text color="gray-3" fSize={10}>
                    {t('reach')}
                </Text>

                <Text color="gray-3" fSize={10}>
                    {t('followers')}
                </Text>

                <Text color="gray-3" fSize={10}>
                    {t('er')}
                </Text>

                <Text color="gray-3" fSize={10}>
                    {t('likes')}
                </Text>

                <Text color="gray-3" fSize={10}>
                    {t('comments')}
                </Text>

                <Text color="gray-3" fSize={10}>
                    {t('integration')}
                </Text>
            </div>

            <div className={cn.emptyStateInner}>
                <div className={cn.imgContainer}>
                    <Icons name="media-planner" className={cn.img}></Icons>
                </div>
                <Text fSize={14} margin="8px 0 0 0" color="gray-3">
                    {t('subtitle')}
                </Text>
            </div>
        </div>
    )
}
