import React, { SVGAttributes } from 'react'
const Link = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40" cy="40" r="40" fill="#FFEEE4" />
        <path
            d="M31.1643 61.3376C26.1089 61.3376 21.5512 58.2923 19.6163 53.6219C17.6813 48.9514 18.7501 43.5753 22.3243 40L27.6293 34.695L31.1643 38.23L25.8618 43.5325C23.9669 45.4274 23.2269 48.1892 23.9205 50.7776C24.614 53.366 26.6358 55.3878 29.2242 56.0814C31.8126 56.7749 34.5744 56.0349 36.4693 54.14L41.7718 48.8375L45.3068 52.375L40.0043 57.6775C37.6644 60.0289 34.4814 61.3467 31.1643 61.3376ZM32.9318 50.605L29.3968 47.07L47.0743 29.3925L50.6093 32.9275L32.9343 50.6025L32.9318 50.605ZM52.3793 45.3025L48.8418 41.7675L54.1443 36.465C56.0649 34.5759 56.8248 31.8017 56.1349 29.1975C55.445 26.5933 53.4113 24.5592 50.8073 23.8687C48.2032 23.1782 45.4289 23.9373 43.5393 25.8575L38.2343 31.16L34.6993 27.625L40.0043 22.3201C44.892 17.475 52.7767 17.4922 57.6431 22.3587C62.5096 27.2251 62.5268 35.1098 57.6818 39.9975L52.3793 45.3V45.3025Z"
            fill="#F87B36"
        />
    </svg>
)

export default Link
