import React, { useState } from 'react'
import styles from './custom-checkbox.module.scss'
import { ReactComponent as CheckedIcon } from '../../icons/check-box-checked.svg'
import { ReactComponent as CheckBoxIcon } from '../../icons/check-box.svg'

interface CustomCheckboxProps {
    isChecked?: boolean
    onChange?: (checked: boolean) => void
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ isChecked = false, onChange }) => {
    const [checked, setChecked] = useState(isChecked)

    const handleCheckboxChange = () => {
        const newChecked = !checked
        setChecked(newChecked)
        if (onChange) {
            onChange(newChecked)
        }
    }

    return (
        <div className={styles.checkboxContainer} onClick={handleCheckboxChange}>
            <div className={styles.iconContainer}>{checked ? <CheckedIcon /> : <CheckBoxIcon />}</div>
        </div>
    )
}

export default CustomCheckbox
