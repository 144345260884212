import { shortNum } from '@/_helpers/_graphs/_tech'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import moment from 'moment'
import { FC } from 'react'
import cn from './Tooltip.module.scss'
import React from 'react'
import { useMemo } from 'react'
import { convertMinutesToDuration } from '../utils'
export interface ITooltip {
    series: Array<Array<number>>
    seriesIndex: number
    dataPointIndex: number
    w: any
    type: string
    toFixed?: number
}

const Tooltip: FC<ITooltip> = (props) => {
    const t = useSpaceTranslation('report_layout.graph')
    const val = useMemo(() => {
        const metric = props.series[props.seriesIndex][props.dataPointIndex]

        if (props.type === 'duration') {
            return convertMinutesToDuration(metric)
        }

        return props.type === 'er'
            ? shortNum(metric * 100, props.toFixed || 1, 3) + '%'
            : shortNum(metric, props.toFixed || 1)
    }, [props])

    const date = props.w.config.xaxis.categories[props.dataPointIndex]

    return (
        <div className={cn.tooltip}>
            <div className={cn.date}>{moment(date).format('DD.MM.YYYY')}</div>

            <div className={cn.value}>{val}</div>

            <div className={cn.type}>{t(`tooltip.${props.type}`)}</div>
        </div>
    )
}
export default Tooltip
