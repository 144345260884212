import { compose, withProps } from 'recompose'

import { HeaderMenu as HM } from './HeaderMenu.js';
import withTitle from "./withTitleControl";
import { memo } from 'react';
import Title from "@/_helpers/TitleControl";
import { SEARCH, LISTS, INTERSECTIONS_DEFAULT } from '../../routs.js'

export const HeaderMenu = compose(
    withProps({
        title: new Title(),
        menuOptions: [
            { label: "top_menu.p1", route: SEARCH, title: "titles.t1" },
            { label: "top_menu.p2", route: LISTS, title: "titles.t2" },
            { label: "top_menu.p3", route: INTERSECTIONS_DEFAULT, title: "titles.t3" }
            // { label: "top_menu.p4", route: "/asdasd", disabled: true, new: false }
        ]
    }),
    withTitle(),
    memo
)(HM)