import { history } from '@/_helpers/'
import Const from './_constants.js'
import service from './_service.js'
import { snackActions } from '@/components/Notifier/model/actions.js'
import { SEARCH } from '@/routs.js'

export default {
    login: (email, password, recaptchaToken) => async (dispatch) => {
        dispatch({ type: Const.LOGIN_PAGE_REQUEST, user: { email } })
        service.login(email.toLowerCase(), password, recaptchaToken).then(
            (user) => {
                if (!user.token)
                    return dispatch(
                        snackActions.error('Something went wrong', 3000)
                    )
                localStorage.setItem(
                    'user',
                    JSON.stringify({ token: user.token })
                )
                dispatch({
                    type: Const.LOGIN_PAGE_LOGIN_SUCCESS,
                    payload: { token: user.token },
                })
                service.getUserInfo().then(
                    (payload) =>
                        dispatch({
                            type: Const.LOGIN_PAGE_USER_INFO_SUCCESS,
                            payload,
                        }),
                    (error) =>
                        dispatch({
                            type: Const.LOGIN_PAGE_USER_INFO_FAILURE,
                            error,
                        })
                )
                history.push(SEARCH)
            },
            (error) => {
                if (error.isAxiosError && !error.response) {
                    return dispatch(
                        snackActions.error('Something went wrong', 3000)
                    )
                } else if (error.response.status === 400) {
                    if (error.response.data.detail) {
                        dispatch(snackActions.error(error.response.data.detail, 3000))
                        return
                    }
                    if (error.response.data.password) {
                        dispatch(snackActions.error(error.response.data.password, 3000))
                        return
                    }
                    if (error.response.data.email) {
                        dispatch(snackActions.error(error.response.data.email, 3000)                        )
                        return
                    }
                    dispatch(snackActions.error('Something went wrong', 3000))

                } else {
                    dispatch(snackActions.error('Something went wrong', 3000))
                }
            }
        )
    },
    logout: () => {
        service.logout()
        localStorage.removeItem('user')
        return { type: Const.LOGIN_PAGE_LOGOUT }
    },
    getUserInfo: () => (dispatch) => {
        dispatch({ type: Const.LOGIN_PAGE_USER_INFO_REQUESTED })
        return service.getUserInfo().then(
            (payload) =>
                dispatch({ type: Const.LOGIN_PAGE_USER_INFO_SUCCESS, payload }),
            (error) =>
                dispatch({ type: Const.LOGIN_PAGE_USER_INFO_FAILURE, error })
        )
    },
    getPlatforms: () => (dispatch) => {
        return service.getSubscriptionPlatforms().then(
            payload => dispatch({type: Const.USER_PLATFORMS, payload})
        )
    },
    getUserDetails: () => (dispatch) => {
        return service.getUserDetails().then(
            (payload) => {
                dispatch({type: Const.USER_DETAILS_SUCCESS, payload})
                // if (!payload.is_shown) {
                //     history.push(USER_DETAILS)
                // }
            },
            (error) => dispatch({type: Const.USER_DETAILS_ERROR, error}),
        )
    },
    updateUserInfo: (info) => ({
        type: Const.LOGIN_PAGE_USER_INFO_UPDATE,
        info,
    }),
}
