import React, { FC, useMemo, CSSProperties } from 'react'
import BarChart from './BarChart'
import { IDemographicsItem } from './types'

import cn from './MultiBarChart.module.scss'
import { horizontalDefaultData, verticalDefaultData } from './defaultData'

export interface IMultiBarChart {
    title?: string
    data?: Array<IDemographicsItem>
    legend?: Array<{
        id: number
        value: string
        color: string
    }>
    type?: string
    size: 'small' | 'medium' | 'large'
    isLoading?: boolean
    containerStyle?: CSSProperties
}

const MultiBarChart: FC<IMultiBarChart> = ({
    title,
    data,
    legend,
    type = 'vertical',
    size,
    isLoading,
    containerStyle,
}) => {
    const skeleton = isLoading ? '--skeleton' : ''
    const widgetWidth = size === 'large' ? 875 : size === 'medium' ? 530 : 340
    const numberOfBars = legend ? 6 : 5

    const displayData = useMemo(() => {
        if (isLoading && type === 'vertical') return verticalDefaultData
        if (isLoading && type === 'horizontal') return horizontalDefaultData

        return data
    }, [data, isLoading, type])

    return (
        <div className={cn['root--' + type]} style={{ width: widgetWidth, ...containerStyle }}>
            <div>
                <div className={cn['head--' + type]}>
                    {title && <span className={cn['title']}>{title}</span>}
                    {legend && (
                        <div className={cn['legend--' + type]}>
                            {legend.map((item) => {
                                return (
                                    <div className={cn['legend--item-' + type + skeleton]}>
                                        <div
                                            style={{
                                                backgroundColor: item.color,
                                            }}
                                            className={cn['legend--circle-' + type + skeleton]}
                                        ></div>
                                        <span key={item.id} className={cn['value' + skeleton]}>
                                            {item.value}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
                {displayData && (
                    <div className={cn['charts--' + type]}>
                        {displayData.slice(0, numberOfBars).map((sec) => {
                            return (
                                <div className={cn['charts--section--wrapper']}>
                                    <div className={cn['charts--section-' + type]}>
                                        {sec.items.map((item) => {
                                            return (
                                                <BarChart
                                                    id={item.qty}
                                                    value={item.share}
                                                    color={item.color}
                                                    type={type}
                                                    isLoading={isLoading}
                                                    label={sec.label || undefined}
                                                />
                                            )
                                        })}
                                    </div>
                                    {type === 'vertical' && (
                                        <p className={cn['label--' + type + skeleton]}>{sec.label}</p>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

export default MultiBarChart
