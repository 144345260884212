import { IRefs, NativeReportData, IReportReadyStatus } from './Types'
import {
    GrowGraph,
    LookALineList,
    ErSpread,
    // UserTagsChips,
    // PopularTagsChips,
    Tags,
    BrandAffinityList,
    AQDonut,
    ReachBar,
    // NotableFollowersList,
    AgeGenderMultiBar,
    GenderDonut,
    CityHorizontal,
    CountryHorizontal,
    LanguageHorizontal,
    BlockTitle,
    AudienceBrandAffinity,
    AudienceInterests,
    PostTypes,
    AllocationGraph,
    OnParsingLoader,
} from './ReadyCards'

export default (src: NativeReportData, refs: IRefs, reportReadyStatus?: IReportReadyStatus) => {
    return [
        [BlockTitle('account_data.title', refs.accountData)],
        [GrowGraph(src)],
        [BlockTitle('auditory.title', refs.audience)],
        ...AuditoryWidgets(src, reportReadyStatus),
        [src.brandAffinity.values.length > 0 && BlockTitle('brandAffinity.title', refs.brands, '0')],
        BrandAffinityList(src),
        [src.chaining.values.length > 0 && BlockTitle('lookalikes.title', refs.similarAccs, '0')],
        LookALineList(src),
        [BlockTitle('content.title', refs.content)],
        ...[src.erSpread.values.length > 0 && ErSpread(src, 2)],
        [src.erSpread.values.length > 0 && AllocationGraph(src)],
        src.postTypeAggregationsByDays.values.hasOwnProperty('30') ? [PostTypes(src, 3), Tags(src, 3)] : Tags(src, 3),
    ]
}
export const hideSmallValues = (src: NativeReportData) => {
    const audInter = src.audienceInterests.values.reduce((prev, item) => {
        let prom = item.share
        return prom > prev ? prom : prev
    }, 0)
    const brandInter = src.audienceBrandAffinity.values.reduce((prev, item) => {
        let prom = item.share
        return prom > prev ? prom : prev
    }, 0)
    return {
        audInter: audInter > 1 ? audInter / 10000000 : audInter,
        brandInter: brandInter > 1 ? brandInter / 10000000 : brandInter,
    }
}
export const hideInterestsForMillionaires = (src: NativeReportData) => {
    return src.followers.mainValue >= 1000000 ? false : true
}

const AuditoryWidgets = (src: NativeReportData, reportReadyStatus?: IReportReadyStatus) => {
    const checkSmallDataInterests = hideSmallValues(src)
    const ExistsAudInterests = src.audienceInterests.values.length !== 0 && checkSmallDataInterests.audInter > 0.05
    const ExistsAudBrandInterests =
        src.audienceBrandAffinity.values.length !== 0 && checkSmallDataInterests.brandInter > 0.05
    if (reportReadyStatus === -1) {
        return []
    }
    if (reportReadyStatus === 1) {
        return [
            [
                src.audienceQuality.values.length > 0 && AQDonut(src),
                src.audienceReach.values.length > 0 && ReachBar(src),
            ],
            [
                src.ageGenderSpread.values.length > 0 && AgeGenderMultiBar(src, 3),
                src.genderSplit.values.length > 0 && GenderDonut(src, 1),
            ],
            // NotableFollowersList(src),
            [CityHorizontal(src), CountryHorizontal(src), LanguageHorizontal(src)],
            hideInterestsForMillionaires(src) && [
                ExistsAudInterests && AudienceInterests(src, ExistsAudBrandInterests ? 1.5 : 3),
                ExistsAudBrandInterests && AudienceBrandAffinity(src, ExistsAudInterests ? 1.5 : 3),
            ],
        ]
    }
    return [OnParsingLoader(reportReadyStatus || 0.01, 1)]
}
