import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import React, { FC } from 'react'
import Button from '@/components/Button'
import Text from '@/components/Text'
import { TARIFFS } from '@/routs'

import cn from './HidderAds.module.scss'
import { Link } from 'react-router-dom'
import { numberFormatter } from '@/_helpers/numberFormatter'
import Flex from '@/_yc/Flex'
import AdsBack from './AdsList.svg'
interface IHidder {
    count: number
    isList?: boolean
}

export const AdsExplorerHidder: FC<IHidder> = ({ count, isList = false }) => {
    const t = useSpaceTranslation('ads_explorer.hidder')

    if (isList) {
        return (
            <>
                <Flex style={{ background: 'white', borderRadius: '10px' }} margin="10px 0 0 0" content="space-between">
                    <Flex column style={{ padding: '30px 40px' }}>
                        <Flex className={cn.textContainer}>
                            <Text fSize={16} color={'gray-1'} component="span" style={{ lineHeight: '24px' }}>
                                {t('to_watch')}
                            </Text>
                            <Text fSize={16} color={'violet-1'} component="span" style={{ lineHeight: '24px' }}>
                                {count === 1
                                    ? t('ad_post', { count: numberFormatter(count) })
                                    : t('ad_posts', { count: numberFormatter(count) })}
                            </Text>
                            <Text fSize={16} color={'gray-1'} component="span" style={{ lineHeight: '24px' }}>
                                {t('purchase')}
                            </Text>
                            <Text fSize={16} color={'violet-1'} component="span" style={{ lineHeight: '24px' }}>
                                {t('agency')}
                            </Text>
                        </Flex>
                        <Text fSize={14} color={'gray-2'}>
                            {t('desc')}
                        </Text>
                        <Link to={TARIFFS}>
                            <Button variant="primary" width={210} margin={'20px 0 0 0'}>
                                {t('button')}
                            </Button>
                        </Link>
                    </Flex>
                    <img src={AdsBack} alt="ads" style={{ margin: '10px 100px 10px 0' }} />
                </Flex>
            </>
        )
    }
    return (
        <div className={cn.root}>
            <div className={cn.container}>
                <Text fSize={16} color={'gray-1'} component="span" style={{ lineHeight: '24px' }}>
                    {t('to_watch')}
                </Text>
                <Text fSize={16} color={'violet-1'} component="span" style={{ lineHeight: '24px' }}>
                    {count === 1
                        ? t('ad_post', { count: numberFormatter(count) })
                        : t('ad_posts', { count: numberFormatter(count) })}
                </Text>
                <Text fSize={16} color={'gray-1'} component="span" style={{ lineHeight: '24px' }}>
                    {t('purchase')}
                </Text>
                <Text fSize={16} color={'violet-1'} component="span" style={{ lineHeight: '24px' }}>
                    {t('agency')}
                </Text>

                <Link to={TARIFFS}>
                    <Button variant="primary" width={210} margin={'20px 0 0 0'}>
                        {t('button')}
                    </Button>
                </Link>
            </div>
        </div>
    )
}
