import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40" cy="40" r="40" fill="#E4F8EC" />
        <path
            d="M33.8125 54.1424L21.4375 41.7674L24.9725 38.2324L34.1075 47.3574L33.8125 47.0699L55.025 25.8574L58.56 29.3924L37.3475 50.6074L33.815 54.1399L33.8125 54.1424Z"
            fill="#4ECF81"
        />
    </svg>
)
