import React, { FC } from 'react'
import cn from './InfoPopup.module.scss'
import Popup from '@/_yc/Popup'
import BloggerLink from '@/components/Share/BloggerLink'
import Button from '@/components/Button'

import Text from '@/components/Text'
import Flex from '@/_yc/Flex'
import { shortNum } from '@/_helpers/_graphs/_tech'
import Avatar from '@/components/Avatar'
import { Platform } from '@/pages/Reports/types'

interface InfoPopupProps {
    isOpen: boolean
    onClose: VoidFunction
    anchor: HTMLDivElement | null
    avatar: string
    name: string
    externalId: string
    stats: {
        er: string
        followers: number
    }
    platform: Platform
}

export const InfoPopup: FC<InfoPopupProps> = ({
    isOpen,
    onClose,
    anchor,
    avatar,
    name,
    externalId,
    stats,
    platform,
}) => {
    const er = parseFloat(stats.er !== '' ? stats.er : '0')
    return (
        <>
            {isOpen && <div className={cn.arrow} />}
            <Popup
                isOpen={isOpen}
                onClose={onClose}
                style={{ zIndex: 5, top: 10 }}
                anchor={anchor}
                placement="top-start"
            >
                <div className={cn.infoPopup}>
                    <Flex>
                        <div className={cn.avatarContainer}>
                            <Avatar alt="AVA" src={avatar} aria-label="Аватарка" className={cn.avatar} />
                        </div>
                        <div className={cn.infoBlock}>
                            <Text semibold>{name}</Text>
                            <Text fSize={12} color="gray-2">{`${shortNum(stats.followers)} followers • ${
                                er * 100
                            }% ER`}</Text>
                        </div>
                    </Flex>
                    <BloggerLink newTab id={externalId} platform={platform}>
                        <Button width={120} size="small" variant={'primary'} margin="0 0 0 10px">
                            Open report
                        </Button>
                    </BloggerLink>
                </div>
            </Popup>
        </>
    )
}
