import React, { FC, useEffect, useState } from 'react'
import { Flex } from '@/_yc'
import NavFooter from '@/pages/TargetAudience/components/NavFooter'
import Step from '@/pages/TargetAudience/Views/components/Step/Step'
import cn from './step-three.module.scss'
import { useTranslation } from 'react-i18next'
import { ReactComponent as GlobusIcon } from '@/pages/TargetAudience/icons/globus.svg'
import EditMainCriteria from '@/pages/TargetAudience/Views/CreateReportStepFive/components/EditMainCriteria'
import { Option } from '@/pages/TargetAudience/Views/types'
import {
    mapOptions,
    mapOptionsAdapter,
    transformDataToOptions,
    transformGeoOptions,
} from '@/pages/TargetAudience/utils'
import { IAdditionalParams, Language } from '@/pages/TargetAudience/services/types'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import Loader from '@/components/Loader'
import {
    createAgeTargetsReq,
    createGenderTargetsReq,
    createGeoTargetsReq,
    createIncomeTargetsReq,
    fetchAllTargetData,
} from '@/pages/TargetAudience/services/services'
import useToken from '@/_helpers/Hooks/useToken'
import { useLocation } from 'react-router-dom'

interface CriteriaState {
    ages: { label: string; value: string }[]
    gender: { label: string; value: string }[]
    country: { label: string; value: string }[]
    income: { label: string; value: string }[]
}

const Index: FC = () => {
    const [params, setParams] = useState<{ [key: string]: Option[] } | null>(null)
    const [loading, setLoading] = useState(false)
    const targetType = useSelector<RootStore, 'from_customer' | 'from_us'>(
        (state) => state.targetAudience.campaign?.target_type
    )
    const enums = useSelector<RootStore, IAdditionalParams>((state) => state.targetAudience.enums)
    const campaignId = parseInt(localStorage.getItem('campaignId') || '1')
    const token = useToken()
    const location = useLocation()

    const [selectedParams, setSelectedParams] = useState<{ [key: string]: Option[] }>({
        ages: [],
        gender: [],
        country: [],
        income: [],
    })

    const { t, i18n } = useTranslation('target_audience')

    useEffect(() => {
        if (enums) {
            setParams({
                AgeGroup: mapOptions(enums.AgeGroup, i18n.language as Language),
                GenderGroups: mapOptions(enums.GenderGroups, i18n.language as Language),
                IncomesSize: mapOptions(enums.IncomesSize, i18n.language as Language),
                Geo: [],
            })
        }
    }, [enums, i18n.language])

    useEffect(() => {
        const loadData = async () => {
            setLoading(true)
            try {
                const targetData = await fetchAllTargetData(token, campaignId)
                if (targetData) {
                    transformDataForState(targetData)
                }
            } catch (error) {
                console.error('Ошибка загрузки данных целевой аудитории:', error)
            } finally {
                setLoading(false)
            }
        }
        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, campaignId, location.pathname])

    const transformDataForState = (data: any) => {
        setSelectedParams({
            country: transformDataToOptions(data.geo, i18n.language.split('-')[0] as Language),
            ages: mapOptionsAdapter(data.ages, i18n.language as Language),
            gender: mapOptionsAdapter(data.genders, i18n.language as Language),
            income: mapOptionsAdapter(data.incomes, i18n.language as Language),
        })
    }

    const changeParams = (data: { [key: string]: Option[] }) => {
        setSelectedParams(data)
    }

    const submitParams = async () => {
        const requests = [
            createGeoTargetsReq(token, campaignId, transformGeoOptions(selectedParams.country)),
            createGenderTargetsReq(
                token,
                campaignId,
                selectedParams.gender.map((option) => option.value)
            ),
            createIncomeTargetsReq(
                token,
                campaignId,
                selectedParams.income.map((option) => option.value)
            ),
            createAgeTargetsReq(
                token,
                campaignId,
                selectedParams.ages.map((option) => option.value)
            ),
        ]

        await Promise.allSettled(requests)
    }

    return (
        <Flex column className={cn['main']}>
            <Flex className={cn['container']}>
                <Step
                    step={'2 из 5'}
                    title={t('target_audience.main_criteria.main_criteria')}
                    description={t('target_audience.setDemographicParams')}
                    targetingType={targetType}
                    icon={<GlobusIcon />}
                />

                <Flex column className={cn['sidebar']}>
                    {params && !loading ? (
                        <EditMainCriteria onChange={changeParams} options={params} initialState={selectedParams} />
                    ) : (
                        <Loader style={{ margin: 'auto' }} />
                    )}
                </Flex>
            </Flex>
            <NavFooter callBack={submitParams} prev={'/target/new/two'} next={'/target/new/four'} />
        </Flex>
    )
}

export default Index
