import React, { FC } from 'react'
import { Flex } from '@/_yc'
import { Tooltip, Avatar } from '@yoloker/ui-collection'
// import Avatar from '@/components/Avatar'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

import cn from './PopularBrands.module.scss'
import { getShortString } from '@/_helpers/strings/getShortString'

export interface IBrand {
    avatar: string
    title: string
    postsCount: number
}
interface IPopularBrands {
    data: Array<IBrand>
    isDemo?: boolean
}

const PopularBrands: FC<IPopularBrands> = (props) => {
    const t = useSpaceTranslation('report_titles.popularBrands')
    // const tempSRC = 'https://cdn.yoloco.ru/trodriguezzz.jpe'
    const max = props.data.reduce((prev, current) => (prev.postsCount > current.postsCount ? prev : current)).postsCount
    const brandsSort = (brands: IBrand[]) => {
        return brands.sort((a: IBrand, b: IBrand) => {
            return b.postsCount - a.postsCount
        })
    }
    return (
        <>
            <Flex
                className={props.isDemo ? cn['root--blur'] : cn.root}
                content={props.data.length < 5 ? 'start' : 'space-between'}
                column
            >
                <Flex align="center">
                    <p className={cn.title}>{t('title')}</p>
                    <Tooltip text={t('tooltip')} />
                </Flex>
                {brandsSort(props.data)
                    .slice(0, 5)
                    .map((item) => {
                        return (
                            <Flex margin="24px 0 0 0" key={item.title}>
                                <Avatar className={cn.avatar} src={item.avatar} size={40} loadingAvatarWidth="40" />
                                <Flex column margin="0 0 0 12px" style={{ width: '100%' }}>
                                    <Flex content="space-between">
                                        <p className={cn.text}>{getShortString(item.title, 25)}</p>
                                        <p className={cn.text}>{item.postsCount}</p>
                                    </Flex>
                                    <div className={cn.bar}>
                                        <div
                                            className={cn['bar--filled']}
                                            style={{
                                                width: `${(item.postsCount * 100) / max}%`,
                                            }}
                                        />
                                    </div>
                                </Flex>
                            </Flex>
                        )
                    })}
            </Flex>
        </>
    )
}
export default PopularBrands
