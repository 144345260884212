export default (props, propName, componentName) => {
	const type = typeof(props[propName])
	if (props.model === "report" && (type!=="string" || !props[propName])) {
		return new Error(
			`Prop "${propName}" in "${componentName}" (model = ${props.model}) must be type of "string", but ${props[propName]} received.`
		)
	}
	if (
		props.model === "month" && 
		props[propName] && 
		type!=="number" &&
		type!=="string"
	) {
		return new Error(
			`Prop "${propName}" in "${componentName}" (model = ${props.model}) must be type of "number" or "string" or nothing, but ${props[propName]} received.`
		)
	}
}