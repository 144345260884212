import React, { FC } from 'react'

import Text from '@/components/Text'
import cn from './VideoCover.module.scss'

export interface IVideoCover {
    imageLink: string
    title: string
}

const VideoCover: FC<IVideoCover> = ({ imageLink, title }) => {
    return (
        <div className={cn.VideoCover}>
            <div>
                <img
                    src={imageLink}
                    className={cn['VideoCover--image']}
                    alt="Video preview"
                />
            </div>
            <Text className={cn['VideoCover--title']} semibold>
                {title}
            </Text>
        </div>
    )
}

export default VideoCover
