import React, { useMemo } from 'react'

import { useAdapter } from '@/pages/SearchPage/SearchAdapter'

import cn from './MainFilters.module.scss'
import FilterItem from '../Filters/FilterItem'

export const MainFilters = () => {
    const { mainFilters } = useAdapter()

    const arrays = useMemo(() => {
        if (!mainFilters) return []

        return Object.entries(mainFilters).map(([name, val]) => ({
            title: val.title,
            name,
            filters: Object.entries(val.filters),
        }))
    }, [mainFilters])

    const scrollToFilter = async (ref: any) => {
        ref.current.scrollIntoView({ block: 'end' })
    }

    return (
        <div className={cn.filtersContainer}>
            {arrays.map((category, i) => {
                let sum = 1

                return (
                    <div key={i}>
                        <div className={cn.grid}>
                            {category.filters.map(([name, Filter]) => {
                                const result = (
                                    <FilterItem
                                        key={`${category.name}.${name}`}
                                        filter={Filter}
                                        start={sum}
                                        end={sum + Filter.options.space}
                                        categoryName={category.name}
                                        name={name}
                                        scrollToFilter={scrollToFilter}
                                        isMain={true}
                                    />
                                )
                                sum = (sum + Filter.options.space) % 5 || 1

                                return result
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
