import React, { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import Icons from '@/components/Icons'
import Text from '@/components/Text'
import useOpenClose from '@/_helpers/useOpenClose'
import { useSorting } from '../../../SearchAdapter'
import cn from './Sorter.module.scss'
import ListPopup from '@/components/Share/ListPopup'
import { Flex } from '@yoloker/ui-collection'

export interface ISorter {}

const Sorter: FC<ISorter> = (props) => {
    const { sorting, setSorting, options, setDefault } = useSorting()

    const ref = useRef(null)

    const [state, , close, toggle] = useOpenClose()

    useEffect(() => {
        const curSort = options.find((item) => item.value === sorting)
        if (!curSort) setDefault()
    }, [sorting, setSorting, options, setDefault])

    const label = useMemo(() => {
        return options.find((item) => item.value === sorting)?.label ?? ''
    }, [sorting, options])

    const { t } = useTranslation()

    const change = useCallback(
        (next: string) => {
            setSorting(next)
            close()
        },
        [setSorting, close]
    )

    return (
        <>
            <Flex align="center">
                <Text ref={ref} onClick={toggle} fSize={12} color="gray-3" className={cn.root}>
                    {t(label)}
                    <Icons name="sorter" margin="0 0 -4px 4px" />
                </Text>
                <ListPopup
                    isOpen={state}
                    onClose={toggle}
                    anchor={ref.current}
                    options={options}
                    onClick={change}
                    popupStyle={{ zIndex: 10 }}
                />
            </Flex>
        </>
    )
}

export default memo(Sorter)
