import React from 'react'
import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'
import Text from '@/components/Text'
import { useSpaceTranslation } from '@/_helpers'
import cn from './Mock.module.scss'

export const Mock = () => {
    const t = useSpaceTranslation('report_layout.demographics')
    return (
        <>
            <Flex className={cn.root} align="center" column content="center" margin="24px 0 0 ">
                <Icons name="alert-triangle" />
                <Text margin="10px 0 0 0" align="center">
                    {t('mock')}
                </Text>
            </Flex>
        </>
    )
}

export const MockInContainer = () => {
    return (
        <Flex
            style={{ borderRadius: '10px', backgroundColor: 'white', padding: '0 24px 24px 24px' }}
            align="center"
            content="center"
        >
            <Mock />
        </Flex>
    )
}
