import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import notif from '@/components/Notifier/model/actions.js'
import useOpenClose from '@/_helpers/useOpenClose'
import useToken from '@/_helpers/Hooks/useToken'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@/components/Button'
import Popup from '@/_yc/Popup/Popup'
import Flex from '@/_yc/Flex'
import { track } from '@amplitude/analytics-browser'
import { BloggerArea } from '@/_yc'

import { AddCustomListReq } from '../../model/service'
import { setLists } from '../../model/actions'

import cn from './CreateNewList.module.scss'

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 460,
    zIndex: 120,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}
export interface ITags {
    label: string
    index: string
    onClick: (key: string) => void
}

const CreateNewList = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const token = useToken()
    const [isOpen, open, close] = useOpenClose()
    const [tags, setTags] = useState<ITags[]>([])

    const [listName, setListName] = useState<string>('')
    const [comment, setComment] = useState<string>('')

    const req = async () => {
        track(`create_list_aud_insights_side`)

        dispatch(notif.loading(t('aud_insides.status.loading')))
        if (!token) {
            dispatch(notif.error(t('aud_insides.status.error')))
            return
        }
        const tempTags = await tags.map((item) => {
            return { name: item.label }
        })
        AddCustomListReq(token, listName, tempTags, comment)
            .then((res) => {
                dispatch(notif.success(t('aud_insides.status.list_saved', { listName: res.name })))
                dispatch(setLists([res]))
                close()
            })
            .catch(() => {
                dispatch(notif.error(t('aud_insides.status.error')))
                close()
            })
    }

    const [uniqueTags] = useState<Set<string>>(new Set<string>())

    const LIMIT = 30

    const handleDelete = useCallback(
        (index: string) => {
            uniqueTags.delete(index)
            setTags((prev) => prev.filter((blogger) => blogger.index !== index))
        },
        [uniqueTags]
    )
    const parseInputTags = useCallback(
        (inputTags: string[]) => {
            const parsedTags: ITags[] = []

            for (let blogger of inputTags) {
                const name = blogger

                if (uniqueTags.has(name)) continue
                parsedTags.push({
                    label: blogger,
                    index: name,
                    onClick: handleDelete,
                })

                uniqueTags.add(name)
            }

            return parsedTags
        },
        [uniqueTags, handleDelete]
    )

    const changeTags = (inputTags: string[]) => {
        const numOfTags = LIMIT - tags.length
        const parsedTags: ITags[] = parseInputTags(inputTags)

        setTags((prev) => prev.concat(parsedTags.slice(0, numOfTags)))
    }

    return (
        <>
            <Button onClick={open} variant="primary" className={cn.btn}>
                <p className={cn['btn-text']}>{`+ ${t('aud_insides.lists.add')}`}</p>
            </Button>
            {isOpen && (
                <div className={cn.root}>
                    <Popup style={style} isOpen={isOpen} onClose={close}>
                        <Flex className={cn.popup} column>
                            <Flex content={'space-between'}>
                                <p className={cn.title}>{t('aud_insides.lists.add')}</p>
                                <CloseIcon className={cn['close']} onClick={close} />
                            </Flex>

                            <p className={cn.label}>{t('aud_insides.lists.list_name')}</p>
                            <input
                                value={listName}
                                onChange={(e) => setListName(e.target.value)}
                                placeholder={t('aud_insides.lists.list_name')}
                                className={cn.input}
                            />

                            <p className={cn.label}>{t('aud_insides.tags.tags')}</p>
                            <div className={cn.tags}>
                                <BloggerArea value={tags} onChange={changeTags} limit={LIMIT} />
                            </div>

                            <p className={cn.label}>{t('aud_insides.comment.comment')}</p>
                            <textarea
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder={t('aud_insides.comment.placeholder')}
                                className={cn.input}
                                style={{ height: '90px' }}
                            />

                            <div className={cn.divider} />
                            <Flex content="flex-end" style={{ width: '100%' }}>
                                <Button variant="primary" className={cn.submit} onClick={req}>
                                    {t('aud_insides.lists.submit')}
                                </Button>
                            </Flex>
                        </Flex>
                    </Popup>
                </div>
            )}
        </>
    )
}

export default CreateNewList
