import React, { FC } from 'react'
import Avatar from '@/components/Avatar'
import { useSpaceTranslation } from '@/_helpers'
import Flex from '@/_yc/Flex'
import { IAccountData } from '../types'
import cn from './AccountCard.module.scss'
import BloggerLink from '@/components/Share/BloggerLink'
import { Platform } from '@/pages/Reports/types'
import { shortNum } from '@/_helpers/_graphs/_tech'
import Text from '@/components/Text'
export interface IAccountCard {
    cardType: 'horizontal' | 'vertical'
    showVideo?: boolean
    data: IAccountData
    platform?: Platform
}

const AccountCard: FC<IAccountCard> = ({ data, cardType, showVideo, platform = Platform.YouTube }) => {
    const t = useSpaceTranslation('report_layout')
    return (
        <div className={cn.root}>
            <div className={cn['contents--' + cardType]}>
                <div className={cn.avatar}>
                    <Avatar className={cn['avatar--image']} src={data.avatar} size={100} loadingAvatarWidth="100" />
                </div>

                <div className={cn['info--' + cardType]}>
                    <p className={cn['info--name--' + cardType]}>{data.title}</p>
                    {cardType === 'vertical' && (
                        <BloggerLink platform={platform} newTab id={data.id} className={cn['info--link']}>
                            {!data.username ? t('open_report') : '@' + data.username}
                        </BloggerLink>
                    )}
                    {cardType === 'vertical' && data.subscriber_count && (
                        <>
                            <Text margin="30px 0 0 0" style={{ color: '#5D697D' }}>
                                {shortNum(data.subscriber_count)} {t('graph.subs').toLowerCase()}
                            </Text>
                        </>
                    )}
                    {cardType === 'horizontal' && showVideo && (
                        <a
                            href={`https://www.youtube.com/watch?v=${data.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={cn['info--link']}
                        >
                            {t('brand_mentions.link')}
                        </a>
                    )}
                    {data.linkToPost && (
                        <a
                            href={data.linkToPost}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={cn['info--link']}
                        >
                            {t('brand_mentions.linkPost')}
                        </a>
                    )}

                    {cardType === 'horizontal' && (
                        <Flex>
                            {data.views && (
                                <Flex column={true}>
                                    <p className={cn['info--views-published']}>{data.views}</p>
                                    <p className={cn['info--views-published--title']}>{t('brand_mentions.views')}</p>
                                </Flex>
                            )}
                            {data.published_at && (
                                <Flex column={true} margin="0 0 0 40px">
                                    <p className={cn['info--views-published']}>{data.published_at}</p>
                                    <p className={cn['info--views-published--title']}>
                                        {t('brand_mentions.published')}
                                    </p>
                                </Flex>
                            )}
                        </Flex>
                    )}
                </div>
            </div>
        </div>
    )
}

export default AccountCard
