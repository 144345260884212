import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none">
        <path
            d="M6 2C4.15905 2 2.66667 3.49238 2.66667 5.33333C2.66667 7.17428 4.15905 8.66667 6 8.66667C7.84095 8.66667 9.33333 7.17428 9.33333 5.33333C9.33333 3.49238 7.84095 2 6 2ZM4 5.33333C4 4.22876 4.89543 3.33333 6 3.33333C7.10457 3.33333 8 4.22876 8 5.33333C8 6.4379 7.10457 7.33333 6 7.33333C4.89543 7.33333 4 6.4379 4 5.33333Z"
            fill="#333"
        />
        <path
            d="M11.2723 5.47885C11.0847 5.38323 10.8772 5.33337 10.6667 5.33337V4.00004C11.0877 4.00004 11.5028 4.09971 11.8779 4.29097C11.9192 4.31203 11.9599 4.33414 12 4.35727C12.3236 4.54409 12.6048 4.79713 12.8249 5.10037C13.0722 5.44115 13.2353 5.83565 13.3008 6.25158C13.3664 6.66751 13.3325 7.09305 13.202 7.49337C13.0714 7.89369 12.8479 8.25741 12.5498 8.55475C12.2517 8.8521 11.8874 9.07462 11.4867 9.20411C11.1302 9.31933 10.7539 9.35797 10.3824 9.31814C10.3364 9.3132 10.2905 9.30707 10.2447 9.29973C9.8292 9.23315 9.43539 9.06919 9.09542 8.82126L9.09472 8.82076L9.8807 7.74371C10.0508 7.86781 10.2478 7.94985 10.4557 7.98316C10.6636 8.01648 10.8764 8.00009 11.0767 7.93535C11.277 7.87061 11.4592 7.75935 11.6082 7.61068C11.7573 7.46201 11.869 7.28016 11.9343 7.08001C11.9995 6.87985 12.0165 6.66709 11.9837 6.45913C11.9509 6.25117 11.8694 6.05393 11.7457 5.88354C11.6221 5.71316 11.4598 5.57448 11.2723 5.47885Z"
            fill="#333"
        />
        <path
            d="M13.3321 14C13.3321 13.65 13.2631 13.3034 13.1292 12.98C12.9952 12.6566 12.7989 12.3628 12.5514 12.1153C12.3039 11.8678 12.0101 11.6714 11.6867 11.5375C11.3633 11.4035 11.0167 11.3346 10.6667 11.3346V10C11.1214 10 11.5723 10.0775 12 10.2288C12.0664 10.2522 12.1322 10.2775 12.1974 10.3045C12.6827 10.5055 13.1237 10.8001 13.4951 11.1716C13.8665 11.543 14.1612 11.984 14.3622 12.4693C14.3892 12.5345 14.4144 12.6003 14.4379 12.6667C14.5891 13.0944 14.6667 13.5453 14.6667 14H13.3321Z"
            fill="#333"
        />
        <path
            d="M10.6667 14H9.33333C9.33333 12.1591 7.84095 10.6667 6 10.6667C4.15905 10.6667 2.66667 12.1591 2.66667 14H1.33333C1.33333 11.4227 3.42267 9.33333 6 9.33333C8.57733 9.33333 10.6667 11.4227 10.6667 14Z"
            fill="#333"
        />
    </svg>
)
