import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.00016 12.6659C6.90675 12.6795 5.82463 12.4436 4.83616 11.9759C4.06996 11.6021 3.38193 11.0858 2.80883 10.4546C2.20178 9.802 1.72381 9.04036 1.40016 8.20992L1.3335 7.99925L1.4035 7.78859C1.72737 6.95888 2.20432 6.19743 2.8095 5.54392C3.38239 4.9128 4.07019 4.3965 4.83616 4.02259C5.82464 3.55498 6.90675 3.31899 8.00016 3.33259C9.09358 3.31902 10.1757 3.555 11.1642 4.02259C11.9304 4.39641 12.6184 4.91272 13.1915 5.54392C13.7997 6.19562 14.2778 6.9575 14.6002 7.78859L14.6668 7.99925L14.5968 8.20992C13.551 10.9324 10.9163 12.7122 8.00016 12.6659ZM8.00016 4.66592C5.73074 4.59481 3.64778 5.91598 2.74483 7.99925C3.64763 10.0826 5.73069 11.4039 8.00016 11.3326C10.2695 11.4035 12.3524 10.0824 13.2555 7.99925C12.3537 5.91497 10.27 4.59331 8.00016 4.66592ZM8.00016 9.99925C7.03838 10.0056 6.20641 9.33083 6.01414 8.38844C5.82187 7.44604 6.32301 6.49927 7.21042 6.12836C8.09783 5.75745 9.12365 6.06601 9.65919 6.86492C10.1947 7.66384 10.0904 8.72997 9.41016 9.40992C9.03771 9.78676 8.53 9.99896 8.00016 9.99925Z"
            fill="#323841"
        />
    </svg>
)
