import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.33333 13.6668H2.66667C1.93029 13.6668 1.33333 13.0699 1.33333 12.3335V3.66683H0V2.3335H2.66667V1.66683C2.66667 0.93045 3.26362 0.333496 4 0.333496H8C8.73638 0.333496 9.33333 0.93045 9.33333 1.66683V2.3335H12V3.66683H10.6667V12.3335C10.6667 13.0699 10.0697 13.6668 9.33333 13.6668ZM2.66667 3.66683V12.3335H9.33333V3.66683H2.66667ZM4 1.66683V2.3335H8V1.66683H4ZM8 11.0002H6.66667V5.00016H8V11.0002ZM5.33333 11.0002H4V5.00016H5.33333V11.0002Z"
                fill="#5D697D"
            />
        </svg>
    )
}
