import React, { FC, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import Popup from '@/_yc/Popup/Popup'
import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'
import MultiColorIcon, { colors } from '@/components/MultiColorIcon'
import * as Routs from '@/routs.js'
import useOpenClose from '@/_helpers/useOpenClose'
import useToken from '@/_helpers/Hooks/useToken'
import notif from '@/components/Notifier/model/actions.js'

import { DeleteCustomListReq, EditCustomListReq } from '../../../model/service'
import { ICustomListInfo, ITag } from '../../../model/types'
import { delList } from '../../../model/actions'
import MobilePopUp from './MobilePopUp'
import { AddTagReq } from '@/pages/Lists/model/service'

import cn from './ListItem.module.scss'

interface IListItem {
    data: ICustomListInfo
    i: number
}
const MobileListItem: FC<IListItem> = (props) => {
    const { t } = useTranslation()
    const token = useToken()
    const dispatch = useDispatch()
    const ref = useRef(null)
    const [isOpen, open, close] = useOpenClose()
    const [state, setState] = useState<'tag' | 'comment'>('tag')

    const [tagsAr, setTagsAr] = useState<ITag[]>(props.data.tags)
    const [comment, setComment] = useState<string | null>(props.data.comment)

    const [isOpenEdit, openEdit, closeEdit] = useOpenClose()
    const [value, setValue] = useState('')

    const handleOpen = (e: any) => {
        e.preventDefault()
        open()
    }

    const deleteList = () => {
        if (!token) return
        DeleteCustomListReq(token, props.data)
            .then(() => {
                dispatch(notif.success(t('aud_insides.status.list_deleted', { listName: props.data.name })))
                dispatch(delList(props.data.id))
            })
            .catch((e) => {
                dispatch(notif.error(t('aud_insides.status.error')))
            })
        closeEdit()
    }

    const addTag = () => {
        if (!token) return
        // if (!newTag) return
        AddTagReq(token, props.data.id, props.data.name, [{ name: value }])
            .then((res) => {
                setTagsAr(res.tags)
                dispatch(notif.success(t('aud_insides.status.success')))
            })
            .catch(() => {
                dispatch(notif.error(t('aud_insides.status.error')))
            })
        closeEdit()
    }

    const addComment = () => {
        if (!token) return
        EditCustomListReq(token, props.data.name, props.data.id, value)
            .then(() => {
                setComment(value)
                dispatch(notif.success(t('aud_insides.status.success')))
            })
            .catch(() => {
                dispatch(notif.error(t('aud_insides.status.error')))
            })

        closeEdit()
    }

    return (
        <>
            <div className={cn.item}>
                <Link to={`${Routs.LISTS}/${props.data.id}`}>
                    <Flex>
                        <MultiColorIcon color={colors[props.i % colors.length]}>
                            <Icons name={'lists'} className={cn.icon} />
                        </MultiColorIcon>

                        <Flex column content="space-between" className={cn['name--container']} margin="0 0 0 16px" grow>
                            <Flex align={'center'} content="space-between">
                                <p className={cn.name}>{props.data.name}</p>
                                <div ref={ref}>
                                    <p className={cn.dots} onClick={(e: any) => handleOpen(e)}>
                                        &bull;&bull;&bull;
                                    </p>
                                </div>
                            </Flex>
                            <p className={cn['name-sub']}>
                                {props.data.qty} {t('aud_insides.lists.influencers')} &bull;{' '}
                                {moment(props.data.modified_at).format('DD.MM.YYYY')}
                            </p>
                            {props.data.tags && tagsAr.length !== 0 && (
                                <Flex wrap>
                                    {tagsAr.map((item: ITag) => (
                                        <>
                                            <p className={cn.tag}>{item.name}</p>
                                        </>
                                    ))}
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                </Link>
                {props.data.comment && props.data.comment.length !== 0 && (
                    <Flex className={cn['comment--root']}>
                        <Icons name="message-circle" />
                        <p className={cn.comment}>{comment}</p>
                    </Flex>
                )}
                {isOpen && ref && ref.current && (
                    <>
                        <Popup placement="bottom-end" isOpen={isOpen} onClose={close} anchor={ref.current}>
                            <Flex className={cn.popup} column>
                                <p
                                    onClick={() => {
                                        openEdit()
                                        setState('tag')
                                    }}
                                >
                                    {t('aud_insides.tags.add_tag')}
                                </p>
                                <p
                                    onClick={() => {
                                        openEdit()
                                        setState('comment')
                                    }}
                                >
                                    {t('aud_insides.comment.add')}
                                </p>
                                <p onClick={deleteList}>{t('aud_insides.lists.delete_1')}</p>
                            </Flex>
                        </Popup>
                    </>
                )}
                {isOpenEdit && (
                    <>
                        <MobilePopUp
                            state={state}
                            isOpen={isOpenEdit}
                            close={closeEdit}
                            title={state === 'tag' ? t('aud_insides.tags.add_tag') : t('aud_insides.comment.add')}
                            submit={state === 'tag' ? addTag : addComment}
                            value={value}
                            setValue={setValue}
                        />
                    </>
                )}
            </div>
        </>
    )
}

export default MobileListItem
