export function parseDate(str: string, asObj: true, monthAsNumber: true): { month: number; date: number; year: number }
export function parseDate(
    str: string,
    asObj: true,
    monthAsNumber?: false
): { month: string; date: number; year: number }
export function parseDate(str: string, asObj?: false, monthAsNumber?: boolean): string
export function parseDate(
    str: string,
    asObj?: boolean,
    monthAsNumber?: boolean
): string | { month: number | string; date: number; year: number } {
    const date = new Date(str)

    const m = date.getMonth() + 1

    let month

    if (monthAsNumber) {
        if ((m + '').length === 1) {
            month = `0${m}`
        } else {
            month = m + ''
        }
    } else {
        month = getMonth(m)
    }

    if (asObj) return { month, date: date.getDate(), year: date.getFullYear() }

    return `${month} ${date.getDate()}, ${date.getFullYear()}`
}

function getMonth(month: number, lang?: string): string {
    if (lang && lang === 'ru') {
        switch (+month) {
            case 1:
                return 'янв'
            case 2:
                return 'фев'
            case 3:
                return 'мар'
            case 4:
                return 'апр'
            case 5:
                return 'мая'
            case 6:
                return 'июня'
            case 7:
                return 'июля'
            case 8:
                return 'авг'
            case 9:
                return 'сен'
            case 10:
                return 'окт'
            case 11:
                return 'ноя'
            case 12:
                return 'дек'
            default:
                return 'месяц'
        }
    } else {
        switch (+month) {
            case 1:
                return 'Jan'
            case 2:
                return 'Feb'
            case 3:
                return 'Mar'
            case 4:
                return 'Apr'
            case 5:
                return 'May'
            case 6:
                return 'Jun'
            case 7:
                return 'Jul'
            case 8:
                return 'Aug'
            case 9:
                return 'Sep'
            case 10:
                return 'Oct'
            case 11:
                return 'Nov'
            case 12:
                return 'Dec'
            default:
                return 'month'
        }
    }
}

export { getMonth }
