import React from 'react'
import Icons from '@/components/Icons'
import { SelectorFilter } from '../../../../filters/SelectorFilter'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { ILanguagesQuery } from '../../../youtube/types'
import { getLanguages } from '../../../youtube/queries'
import { getLang } from '@/_helpers/getLang'
import { preprocessLanguages } from '../../../youtube/utils/filters/prepocess'
import { toQueryWithOrDirect } from '../../../youtube/utils/queries/toQueryWithOR'

const getGqlFilter = (languages: string[]) => {
    if (!languages || languages.length === 0) return ''

    const filterName = 'language'
    return toQueryWithOrDirect(languages, filterName)
}

export const getLanguagesFilter = (fetcher: Fetcher) => {
    return new SelectorFilter({
        platform: 'infl_tg',
        title: 'filters.channel_language.label',
        autocomplete: true,
        helper: 'filters.channel_language.text',
        space: 1,
        isMulti: true,
        icon: <Icons name="filter--language" />,
        options: async (token: string) => {
            const client = fetcher.getClient()
            return await client.query<ILanguagesQuery>({ query: getLanguages(getLang()) }).then((res) => {
                const languages = res.data?.youtube_languagestranslate
                return preprocessLanguages(languages)
            })
        },
        beforeSend: ({ value }) => {
            return {
                name: 'location.country',
                operator: 'terms',
                type: 'array',
                value: value,
                gqlFilter: getGqlFilter(value),
            }
        },
    })
}
