import { FilterCategory } from '../../../filters/FilterCategory'
import { getFollowersFilter } from './ChannelMetrics/followers'
import { getKeywordsFilter } from './ContentMetrics/keywords'
import { getLanguagesFilter } from './ChannelMetrics/language'
import { getAvgViewsFilter } from './ContentMetrics/views'
// import { getContactsFilter } from './ChannelMetrics/contacts'
import { getStreamsFilter } from './ContentMetrics/streams'
import { getGamesFilter } from './ContentMetrics/games'
import { Fetcher } from '@/_helpers/graphql/Fetcher'

export const getMainFilters = (fetcher: Fetcher) => {
    return {
        main: new FilterCategory('filters.categories.main', {
            followers: getFollowersFilter(),
            views: getAvgViewsFilter(),
            language: getLanguagesFilter(fetcher),
            keywords: getKeywordsFilter(),
        }),
    }
}

export const getFilters = (fetcher: Fetcher) => {
    return {
        channel_metrics: new FilterCategory('filters.categories.channel', {
            followers: getFollowersFilter(),
            language: getLanguagesFilter(fetcher),
            // contacts: getContactsFilter(),
        }),
        content_metrics: new FilterCategory('filters.categories.content', {
            views: getAvgViewsFilter(),
            streams: getStreamsFilter(),
            games: getGamesFilter(fetcher),
            // keywords: getKeywordsFilter(),
        }),
    }
}
