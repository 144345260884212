import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.9987 8.33331C8.47146 8.33331 9.66537 7.13941 9.66537 5.66665C9.66537 4.19389 8.47146 2.99998 6.9987 2.99998C5.52594 2.99998 4.33203 4.19389 4.33203 5.66665C4.33203 7.13941 5.52594 8.33331 6.9987 8.33331ZM6.9987 4.33331C7.73508 4.33331 8.33203 4.93027 8.33203 5.66665C8.33203 6.40303 7.73508 6.99998 6.9987 6.99998C6.26232 6.99998 5.66537 6.40303 5.66537 5.66665C5.66537 4.93027 6.26232 4.33331 6.9987 4.33331Z"
                fill="white"
            />
            <path
                d="M1.66537 13.6666C0.928985 13.6666 0.332031 13.0697 0.332031 12.3333V1.66665C0.332031 0.930267 0.928985 0.333313 1.66536 0.333313H12.332C13.0684 0.333313 13.6654 0.930267 13.6654 1.66665V12.3333C13.6654 13.0697 13.0684 13.6666 12.332 13.6666H1.66537ZM1.66536 1.66665V12.3333H3.66536C3.66536 10.4924 5.15775 8.99998 6.9987 8.99998C8.83965 8.99998 10.332 10.4924 10.332 12.3333H12.332V1.66665H1.66536ZM8.9987 12.3333C8.9987 11.2287 8.10327 10.3333 6.9987 10.3333C5.89413 10.3333 4.9987 11.2287 4.9987 12.3333H8.9987Z"
                fill="white"
            />
        </svg>
    )
}
