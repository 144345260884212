import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.0742 3.44726C10.734 3.10711 10.1705 3.11913 9.81552 3.47411L1.07411 12.2155C0.719127 12.5705 0.707106 13.134 1.04726 13.4742L2.52543 14.9523C2.86559 15.2925 3.4291 15.2805 3.78409 14.9255L12.5255 6.18409C12.8805 5.8291 12.8925 5.26558 12.5523 4.92543L11.0742 3.44726ZM8.49781 6.27L10.4261 4.34174L11.6579 5.57355L9.72961 7.5018L8.49781 6.27ZM7.9836 6.7842L1.94174 12.8261L3.17355 14.0579L9.21541 8.01601L7.9836 6.7842Z"
            fill="#5D697D"
        />
        <path
            d="M13.9998 0.799805C13.9998 0.799805 14.3212 1.4412 14.6398 1.7598C14.9584 2.07841 15.5998 2.3998 15.5998 2.3998C15.5998 2.3998 14.9584 2.7212 14.6398 3.0398C14.3212 3.35841 13.9998 3.9998 13.9998 3.9998C13.9998 3.9998 13.6784 3.35841 13.3598 3.0398C13.0412 2.7212 12.3998 2.3998 12.3998 2.3998C12.3998 2.3998 13.0412 2.07841 13.3598 1.7598C13.6784 1.4412 13.9998 0.799805 13.9998 0.799805Z"
            fill="#5D697D"
        />
        <path
            d="M5.5998 0.799805C5.5998 0.799805 6.08189 1.76189 6.5598 2.2398C7.03771 2.71771 7.9998 3.1998 7.9998 3.1998C7.9998 3.1998 7.03771 3.68189 6.5598 4.1598C6.08189 4.63771 5.5998 5.5998 5.5998 5.5998C5.5998 5.5998 5.11771 4.63771 4.6398 4.1598C4.16189 3.68189 3.1998 3.1998 3.1998 3.1998C3.1998 3.1998 4.16189 2.71771 4.6398 2.2398C5.11771 1.76189 5.5998 0.799805 5.5998 0.799805Z"
            fill="#5D697D"
        />
        <path
            d="M11.5998 9.9998C11.5998 9.9998 12.0015 10.8015 12.3998 11.1998C12.7981 11.5981 13.5998 11.9998 13.5998 11.9998C13.5998 11.9998 12.7981 12.4015 12.3998 12.7998C12.0015 13.1981 11.5998 13.9998 11.5998 13.9998C11.5998 13.9998 11.1981 13.1981 10.7998 12.7998C10.4015 12.4015 9.5998 11.9998 9.5998 11.9998C9.5998 11.9998 10.4015 11.5981 10.7998 11.1998C11.1981 10.8015 11.5998 9.9998 11.5998 9.9998Z"
            fill="#5D697D"
        />
    </svg>
)
