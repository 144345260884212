import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Loader from '@/components/Loader'
import { RootStore } from '@/_helpers/StoreType'
import PageLayout from '@/Layouts/PageLayout'
import { track } from '@amplitude/analytics-browser'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import notif from '@/components/Notifier/model/actions.js'
import useToken from '@/_helpers/Hooks/useToken'
import Flex from '@/_yc/Flex'

import { getPackages, payment, requestPlan } from './Model/service'
import { setPackages } from './Model/actions'
import { Packages } from './Model/types'
import TariffCard from './TariffCard'
import PromoInput from './PromoInput'
import YearlyDiscount from './YearlyDiscount'

import cn from './TariffPage.module.scss'

const TariffPage = () => {
    const dispatch = useDispatch()
    const t = useSpaceTranslation('tariffs_page')
    const token = useToken()
    const { i18n } = useTranslation()

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [data, setData] = useState<Packages | undefined>()
    const [promocode, setPromocode] = useState()

    //@ts-ignore
    const promocodeFromURL = useSelector((state) => state.Commercial_.promocode)

    useEffect(() => {
        if (promocodeFromURL) setPromocode(promocodeFromURL)
    }, [promocodeFromURL])

    useEffect(() => {
        if (!token) return
        setLoading(true)
        getPackages(token, i18n.language, promocode)
            .then((res) => {
                dispatch(setPackages(res))
                setData(res)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
                setError(true)
            })
    }, [dispatch, token, i18n.language, promocode])
    const userProfile = useSelector((store: RootStore) => {
        return store.authentication.user?.profile
    })

    const checkRequestTariff = (id: number) => {
        return data?.results.find((obj) => {
            return (
                obj.title !== null &&
                obj.tariffs.filter((tariffVar) => {
                    return tariffVar.id === id
                }).length === 1
            )
        })
    }
    const handlePayment = (tariffID: number, recurring_payment_enabled: boolean, currency: string) => {
        if (token) {
            dispatch(notif.loading(t('status.loading')))
            if (checkRequestTariff(tariffID)) {
                return requestPlan(token, tariffID, userProfile)
                    .then((res) => {
                        dispatch(notif.success(t('status.success')))
                    })
                    .catch(() => {
                        dispatch(notif.error(t('status.error')))
                    })
            }
            payment(token, tariffID, recurring_payment_enabled, promocode)
                .then((res) => {
                    if (res.redirect_url === '') {
                        window.location.href = `/payment/${tariffID}${currency ? '/' + currency : ''}`
                    } else {
                        window.location.href = res.redirect_url
                    }
                })
                .catch(() => {
                    track('payment failed')
                    dispatch(notif.error(t('status.error')))
                })
        }
    }

    const [yearlySwitch, setYearlySwitch] = useState<boolean>(false)
    const filteredPackages = useMemo(
        () =>
            data?.results
                .map((pack) => {
                    return {
                        ...pack,
                        tariffs: pack.tariffs.filter((trf) =>
                            yearlySwitch ? trf.valid_days >= 360 : trf.valid_days < 360
                        ),
                    }
                })
                .filter((item) => item.tariffs.length !== 0),
        [data, yearlySwitch]
    )

    return (
        <PageLayout title="Pricing - Yoloco" color="common-background">
            <div className={cn.root}>
                <div className={cn.hat}>
                    <h1>{t('title')}</h1>
                    <PromoInput onSubmit={setPromocode} />
                </div>

                <YearlyDiscount value={yearlySwitch} setValue={setYearlySwitch} />

                <div className={cn.packs}>
                    {loading && (
                        <Flex content="center" style={{ width: '100%' }}>
                            <Loader />
                        </Flex>
                    )}

                    {error && (
                        <div className={cn['error']}>
                            {t('err.l1')}
                            <br />
                            {t('err.l2')}
                            <br />
                            <span className={cn.errStyle}>{t('err.l3')}</span>
                        </div>
                    )}

                    {!error && !loading && filteredPackages && filteredPackages.length > 0 ? (
                        filteredPackages.map((item) => (
                            <TariffCard yearlySwitch={yearlySwitch} card={item} handlePayment={handlePayment} />
                        ))
                    ) : (
                        <Flex style={{ width: '100%' }} content="center">
                            <p>
                                {t('discount.notifier', {
                                    type: yearlySwitch ? t('discount.yearly') : t('discount.monthly'),
                                })}
                            </p>
                        </Flex>
                    )}
                </div>
            </div>
        </PageLayout>
    )
}

export default TariffPage
