import mapper, { Sizes, breakSizesType } from './SizeMapper'
import { useState, useEffect } from 'react'
import Listener from './ResizeListener'

/**
 *
 * @param breakpoints can be [mobile | table | desktop] or Array of them;
 * @description returns current size of window;
 *
 * Mapping:
 *
 * mobile: <= 600px;
 *
 * table: > 600px && <= 1000px
 *
 * desktop: > 1000px
 */
const useWindowWidth = (breakpoints?: Sizes | Sizes[], breakSizes?: breakSizesType): Sizes => {
    const [curSize, setCurSize] = useState<Sizes>(mapper(window.innerWidth))

    useEffect(() => {
        const subscription = Listener.subscribe(() => {
            const size = mapper(window.innerWidth, breakSizes)
            if (size === curSize) return

            if (!breakpoints) return setCurSize(size)

            if (typeof breakpoints === 'string') {
                if (breakpoints === size || breakpoints === curSize) return setCurSize(size)
            }

            if (breakpoints.includes(size) || breakpoints.includes(curSize)) setCurSize(size)
        })
        return () => subscription.unsubscribe()
    }, [breakpoints, setCurSize, curSize, breakSizes])

    return curSize
}

export default useWindowWidth
