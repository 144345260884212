import React, { FC, useMemo } from 'react'

import { StandartLabel } from '../../Blocks'
import Bar from './Bar'
import { shortNum } from '@/_helpers/_graphs/_tech'
import LegendItem from '../LegendItem'
import { IBar } from './Bar'

import cn from './ErSpread.module.scss'
import useReportTranslation from '../useReportTranslation'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'

export interface IErSpread {
    title: string
    width: string | number
    data: Array<Omit<IBar, 'max'>>
    legend?: Array<{
        label: string
        color: string
    }>
    hidden?: boolean
}

const ErSpread: FC<IErSpread> = (props) => {
    let { title, width, data, legend, hidden } = props

    const t = useReportTranslation(title)

    const size = useWindowWidth('mobile')
    const max = useMemo(
        () =>
            data.reduce((prev, item) => {
                let prom = item.value.reduce((pr, it) => (it.value > pr ? it.value : pr), 0)
                return prom > prev ? prom : prev
            }, 0),
        [data]
    )
    const del = Math.log10(max) / 5

    const grid = useMemo(
        () =>
            new Array(6)
                .fill(null)
                .map((item, index) => {
                    let cur = Math.pow(10, del * index).toFixed(0)
                    return shortNum(cur, 0)
                })
                .reverse(),
        [del]
    )
    // console.log('data', data)
    // console.log('grid', grid)
    // console.log('max', max)
    return (
        <div className={cn.root} style={{ width }}>
            <div className={cn.hat}>
                <StandartLabel title={t('title')} helperText={t('helper')} />
                {legend && legend.length > 0 && (
                    <div className={hidden ? cn['legend--hidden'] : cn['legend--default']}>
                        {legend.map((item) => (
                            <LegendItem key={item.label} color={item.color} label={item.label} />
                        ))}
                    </div>
                )}
            </div>
            <div className={hidden ? cn['data--hidden'] : cn['data--default']}>
                {grid.map((item, index) => (
                    <div key={index} className={cn.grid}>
                        <div className={cn.grid_str}>{item}</div>
                        <div
                            className={cn.grid_line}
                            style={{
                                opacity: index !== grid.length - 1 && index ? 1 : 0,
                            }}
                        />
                    </div>
                ))}
                <div className={cn.chart}>
                    {size === 'mobile'
                        ? data
                              .slice(-4)
                              .reverse()
                              .map((item, index) => <Bar key={index + 5} {...item} max={Math.log10(max)} />)
                        : data.reverse().map((item, index) => <Bar key={index} {...item} max={Math.log10(max)} />)}
                </div>
            </div>
        </div>
    )
}

export default ErSpread
