import React, { CSSProperties, FC, memo, useCallback, useLayoutEffect, useMemo, useRef } from 'react'

import cn from './LanguageSwitcher.module.scss'
import useOpenClose from '@/_helpers/useOpenClose'
import ListPopup from '@/components/Share/ListPopup'
import { useTranslation } from 'react-i18next'
import Text from '@/components/Text'
import { updateLibLocale } from '@yoloker/ui-collection'
import { changeUserLng } from '@/Services/User'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
export interface ILanguageSwitcher {
    margin?: string | number
    textStyle?: CSSProperties
}

const options = [
    { label: 'English', value: 'en' },
    { label: 'Portuguese', value: 'pt' },
    { label: 'Deutsche', value: 'de' },
    { label: 'Русский', value: 'ru' },
]

const zIndex = { zIndex: 200 }

const LanguageSwitcher: FC<ILanguageSwitcher> = (props) => {
    const ref = useRef(null)
    const user = useSelector((state: RootStore) => state.authentication.user?.token)
    const userLanguage = useSelector((state: RootStore) => state.authentication.user?.profile?.language)
    const [state, , close, toggle] = useOpenClose()
    const { t, i18n } = useTranslation()

    const display = useMemo(() => {
        const toBe = i18n.language.split('-')[0]
        return options.find((item) => item.value === toBe)?.value
    }, [i18n.language])

    const change = useCallback(
        (value: string) => {
            close()
            i18n.changeLanguage(value)
            updateLibLocale(value)
            changeUserLng(value, user || '')
        },
        [i18n, user, close]
    )
    useLayoutEffect(() => {
        if (userLanguage && userLanguage !== i18n.language.slice(0, 2)) {
            changeUserLng(i18n.language.slice(0, 2), user || '')
        }
    })

    return (
        <>
            <Text
                margin={props.margin}
                fSize={12}
                color="gray-1"
                component="div"
                onClick={toggle}
                ref={ref}
                className={cn.btn}
                style={props.textStyle}
            >
                {display}
            </Text>
            <ListPopup
                popupStyle={zIndex}
                margin="11px 0 0"
                title={t('top_menu.language')}
                isOpen={state}
                anchor={ref.current}
                width={140}
                onClose={toggle}
                onClick={change}
                options={options}
            />
        </>
    )
}

export default memo(LanguageSwitcher)
