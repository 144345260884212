import { Channel, SubChannel } from '@/_helpers/CrossTabChannel'

const channel = new Channel('YolocoTabs')

export const Reports = new SubChannel<{ blogger_id: number; action?: 'unlocked' }>(
    channel,
    'report/open',
    ({ blogger_id, action }) => ({
        blogger_id: blogger_id + '',
        action,
    }),
    (native) =>
        !native
            ? null
            : {
                  blogger_id: native.blogger_id ? +native.blogger_id : -1,
                  action: native.action === 'unlocked' ? 'unlocked' : undefined,
              }
)

export default channel
