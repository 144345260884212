import { gql } from '@apollo/client'
import { QueryType } from './types'

interface QueryParams {
    orderBy: string
    offset: number
    filters: string[]
}

export const getVkChannelsQuery = (table: QueryType, search: string, queryParams: QueryParams) => {
    return gql`
      query SearchChannels {
        ${table} (${search} limit: 30, order_by: {${queryParams.orderBy}}, offset: ${queryParams.offset}, 
          where: {
            ${queryParams.filters.join(', ')}
          }) 
        {
          avatar_link
          account_type
          full_name
          username
          followers
          er_views
          account_id
          vk_accounttopic {
            vk_topic {
              name
            }
          }
        }
        getUnlockedChannels {
          unlocked_vk_accounts
        }
      }
  `
}

export const getVkChannelsCountQuery = (queryType: QueryType, search: string, queryParams: QueryParams) => {
    return gql`
      query GetChannelsCount {
        ${queryType}_aggregate(${search} 
          where: {
            ${queryParams.filters.join(', ')}
          }) {
          aggregate {
            count
          }
        }
      }
  `
}

export const GET_VK_TOPICS = gql`
    query GetTopics {
        vk_topic(order_by: { name: asc }) {
            id
            name
        }
    }
`
