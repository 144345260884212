import React, {
    HTMLAttributes,
    useMemo,
    ReactHTML,
    ComponentType,
    forwardRef,
    ForwardRefRenderFunction,
    CSSProperties,
} from 'react'
import useClass from '@/_helpers/Hooks/useClass'
import { getColor } from '../color'
import cn from './Text.module.scss'

export interface IText extends Omit<HTMLAttributes<HTMLElement>, 'id'> {
    component?: keyof ReactHTML | ComponentType<any>
    fSize?: number | string
    semibold?: boolean
    margin?: string | number
    color?: string
    tAlign?: CSSProperties['textAlign']
    [key: string]: any
}

const Text: ForwardRefRenderFunction<any, IText> = (
    {
        component: Component = 'p',
        fSize,
        margin,
        style,
        className,
        semibold = false,
        color,
        tAlign,
        ...props
    },
    ref
) => {
    const st = useMemo(
        () =>
            !fSize && !margin && !style && !semibold && !color
                ? undefined
                : {
                      margin,
                      fontSize: fSize,
                      fontWeight:
                          semibold === true
                              ? 600
                              : semibold === false
                              ? 500
                              : undefined,
                      color: color && getColor(color),
                      textAlign: tAlign,
                      ...style,
                  },
        [fSize, margin, style, semibold, color, tAlign]
    )

    const root = useClass(cn.root, className)

    return <Component {...props} style={st} className={root} ref={ref} />
}

export default forwardRef(Text)
