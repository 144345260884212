import FormPageLayout from '@/Layouts/FormPageLayout'
import React, { FC, memo, useCallback } from 'react'
import { ErrorConsumer, FormProvider, useController } from '@schematic-forms/react'
import { EmailValidator, Str } from '@schematic-forms/core'
import { Link } from 'react-router-dom'
import { AxiosError } from 'axios'

import bg_image from '@/pages/Auth/LoginPage/bg_image.png'
import cn from './ForgotPage.module.scss'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import useOpenClose from '@/_helpers/useOpenClose'

import { Button, Text } from '@/_yc'

import FormInput from '@/components/Share/Form/FormInput'
import BackButton from '@/components/Share/BackButton'

import { forgotPasswordRequest } from '@/Services/User'

import { LOGIN } from '@/routs'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'

export interface IForgotPage {}
const lHeight = { lineHeight: 1 }
const ForgotPage: FC<IForgotPage> = (props) => {
    const [isDone, done] = useOpenClose()

    const t = useSpaceTranslation('forgot')

    const { controller, submit } = useController({
        fields: {
            email: Str(true),
        },
        validators: {
            email: EmailValidator('INCORRECT'),
        },
        submit: async (data) => {
            try {
                await forgotPasswordRequest(data.email.toLowerCase())
                done()
            } catch (e) {
                if (e.isAxiosError) {
                    const err: AxiosError = e

                    if (err.response?.status === 404) {
                        return {
                            email: 'UNKNOWN',
                        }
                    } else {
                        console.error(err)
                    }
                }
                console.error(e)
            }
        },
    })

    const errorTransform = useCallback(
        (name: string, code: string) => {
            return t(`fields.${name}.errors.${code}`)
        },
        [t]
    )
    const size = useWindowWidth('mobile')
    return (
        <FormPageLayout background={bg_image} bgTextColor="#3D3F42" title={isDone ? t('done_title') : t('title')}>
            <Text size={size === 'mobile' ? 12 : 14}>{isDone ? t('done_details') : t('subtitle')}</Text>
            {!isDone ? (
                <>
                    <FormProvider controller={controller}>
                        <FormInput
                            titleSize={size === 'mobile' ? 10 : 16}
                            titleColor="gray2"
                            title={t('fields.email.label')}
                            name="email"
                            margin="20px 0 0"
                            errorTransform={errorTransform}
                            tSize={size === 'mobile' ? 14 : 18}
                            className={size === 'mobile' ? cn['field--mobile'] : cn.field}
                            placeholder="Email"
                        />
                        <ErrorConsumer>
                            {({ hasError }) => (
                                <Button
                                    width={'100%'}
                                    onClick={submit}
                                    margin="20px 0 0"
                                    disabled={hasError}
                                    className={
                                        !hasError
                                            ? size === 'mobile'
                                                ? cn['btn--mobile']
                                                : cn.btn
                                            : size === 'mobile'
                                            ? cn['btn--disabled--mobile']
                                            : cn['btn--disabled']
                                    }
                                >
                                    <Text style={lHeight} color="white">
                                        {t('button_label')}
                                    </Text>
                                </Button>
                            )}
                        </ErrorConsumer>
                    </FormProvider>
                    <BackButton margin={size === 'mobile' ? '20px 0 0' : '30px 0 0'} />
                </>
            ) : (
                <Link to={LOGIN}>
                    <Button width={'100%'} margin="20px 0 0" className={size === 'mobile' ? cn['btn--mobile'] : cn.btn}>
                        <Text style={lHeight} color="white">
                            {t('done_btn')}
                        </Text>
                    </Button>
                </Link>
            )}
        </FormPageLayout>
    )
}

export default memo(ForgotPage)
