import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.6667 18.3333H3.33335C2.88675 18.3488 2.45375 18.1782 2.13776 17.8622C1.82177 17.5463 1.65116 17.1133 1.66668 16.6667V8.33332C1.65116 7.88672 1.82177 7.45372 2.13776 7.13773C2.45375 6.82174 2.88675 6.65113 3.33335 6.66665H6.66668V3.33332C6.65116 2.88672 6.82177 2.45372 7.13776 2.13773C7.45375 1.82174 7.88675 1.65113 8.33335 1.66665H16.6667C17.1133 1.65113 17.5463 1.82174 17.8623 2.13773C18.1783 2.45372 18.3489 2.88672 18.3333 3.33332V11.6667C18.3486 12.1132 18.1779 12.546 17.862 12.862C17.5461 13.1779 17.1132 13.3486 16.6667 13.3333H13.3334V16.6667C13.3486 17.1132 13.1779 17.546 12.862 17.862C12.5461 18.1779 12.1132 18.3486 11.6667 18.3333ZM3.33335 8.33332V16.6667H11.6667V13.3333H8.33335C7.88682 13.3486 7.45396 13.1779 7.13803 12.862C6.8221 12.546 6.65141 12.1132 6.66668 11.6667V8.33332H3.33335ZM8.33335 3.33332V11.6667H16.6667V3.33332H8.33335Z"
            fill="#333333"
        />
    </svg>
)
