import React, { FC, useCallback, useState, useEffect } from 'react'

import { FormProvider, useController, ErrorConsumer } from '@schematic-forms/react'
import { IInflluencerDetails } from '@/Services/User'
import { useSpaceTranslation } from '@/_helpers'
import { Str, Arr } from '@schematic-forms/core'
import Button from '@/_yc/Button'
import Input from '@/_yc/Input'
import Flex from '@/_yc/Flex'

import Plus from './Plus'

import cn from '../DetailedInfo.module.scss'

type fieldsType = 'links'
interface IBrandInfo {
    onSubmit: (data: IInflluencerDetails) => Promise<void>
    stateCounter: number
    setStateCounter: Function
}

const Influencer: FC<IBrandInfo> = ({ onSubmit, stateCounter, setStateCounter }) => {
    const t = useSpaceTranslation('detailed_info.brand_info')
    const [links, setLinks] = useState<string[]>([''])

    const [buttonState, setButtonState] = useState<'next' | 'sub'>('next')

    const { controller, submit } = useController({
        fields: {
            links: Arr(Str(false), false, []),
        },
        submit: async (data, submit: IBrandInfo['onSubmit'], links: string[]) => {
            return await submit({
                links: links,
            })
        },
    })

    const sub = useCallback(() => {
        submit(onSubmit, links)
    }, [onSubmit, submit, links])

    const fields: Array<fieldsType> = ['links']
    const handleNext = () => {
        if (controller.getValues()[fields[stateCounter]]) {
            setStateCounter(stateCounter + 1)
        }
    }
    const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            if (controller.getValues()[fields[stateCounter]]) {
                if (controller.errors.size() === 0) {
                    setStateCounter(stateCounter + 1)
                }
            }
        }
    }

    const handleLinkChange = (e: string, i: number) => {
        let tempLinks = [...links]
        tempLinks[i] = e
        setLinks(tempLinks)
    }

    const handleLink = (empty: boolean) => {
        if (empty && links.length > 1) {
            let tempLinks = [...links]
            tempLinks = tempLinks.slice(0, -1)
            setLinks(tempLinks)
            return
        }
        setLinks([...links].concat(['']))
    }

    useEffect(() => {
        if (stateCounter === 0) {
            setButtonState('sub')
        }
    }, [stateCounter])
    return (
        <Flex column>
            <FormProvider controller={controller}>
                {/* links */}
                {stateCounter >= 0 && (
                    <div onKeyDown={handleEnter}>
                        <p className={cn.label}>{t('fields.links.label')}</p>
                        {links.map((link, i) => (
                            <Flex align="center">
                                <Input
                                    onChange={(e) => handleLinkChange(e, i)}
                                    placeholder={t('fields.links.placeholder')}
                                    className={cn['input-box']}
                                    value={link}
                                    // style={{ marginTop: '16px' }}
                                />
                                <button
                                    style={i === 0 && link === '' ? { display: 'none' } : {}}
                                    onClick={() => handleLink(link === '')}
                                    className={cn['influencer--add']}
                                >
                                    {link === '' && i !== 0 ? <div className={cn['influencer--dash']} /> : <Plus />}
                                </button>
                            </Flex>
                        ))}
                    </div>
                )}

                <ErrorConsumer>
                    {({ hasError }) => (
                        <Button
                            className={cn.button}
                            onClick={buttonState === 'next' ? handleNext : sub}
                            disabled={hasError}
                            width={'100%'}
                            color={'blue'}
                        >
                            {buttonState === 'next' ? t('submit_button') : t('get_started')}
                        </Button>
                    )}
                </ErrorConsumer>
            </FormProvider>
        </Flex>
    )
}

export default Influencer
