import Icons from '@/components/Icons'
import React from 'react'
import { FC } from 'react'
import Text from '@/components/Text'

import cn from './CheckBox.module.scss'

interface CheckBoxProps {
    isChecked: boolean
    label?: string
    onClick?: () => void
}

export const CheckBox: FC<CheckBoxProps> = ({ isChecked, label, onClick }) => {
    return (
        <div className={cn.checkboxContainer} onClick={onClick}>
            <div className={cn.checkbox}>{isChecked && <Icons name="done" />}</div>
            {label && <Text fSize={12}>{label}</Text>}
        </div>
    )
}
