import { authHeader } from '@/_helpers'
import Service from '@/_helpers/Service'

const GetSideLinks = new Service<any>('/api/dicts/company/')

export const getSideLinks = (token: string) =>
    GetSideLinks.call(
        {
            url: '/api/dicts/company/',
            headers: authHeader(),
        },
        token
    )
