import React from 'react'
// import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { SelectorFilter } from '@/pages/SearchPage/SearchAdapter/filters/SelectorFilter'

import Icons from '@/components/Icons'

const getGqlFilter = (value: string[]) => {
    if (value.length > 0) {
        //&& value[0] === 'info.email'
        return `with_contact: 
               [${value.map((item) => `{type: "${item}"}`)}]
        `
    }
    return ''
}

export const getContactsFilter = () => {
    return new SelectorFilter({
        platform: 'infl_tiktok',
        title: 'filters.contacts.label',
        helper: 'filters.contacts.text',
        icon: <Icons name="mail" />,
        space: 1,
        isMulti: true,
        options: [
            { label: 'Bbm', value: 'bbm', translate: false },
            { label: 'Email', value: 'email', translate: false },
            { label: 'Facebook', value: 'facebook', translate: false },
            { label: 'Instagram', value: 'instagram', translate: false },
            { label: 'Itunes', value: 'itunes', translate: false },
            { label: 'Kakao', value: 'kakao', translate: false },
            { label: 'Kik', value: 'kik', translate: false },
            { label: 'Lineid', value: 'lineid', translate: false },
            { label: 'Linktree', value: 'linktree', translate: false },
            { label: 'Phone', value: 'phone', translate: false },
            { label: 'Pinterest', value: 'pinterest', translate: false },
            { label: 'Sarahah', value: 'sarahah', translate: false },
            { label: 'Sayat', value: 'sayat', translate: false },
            { label: 'Skype', value: 'skype', translate: false },
            { label: 'Snapchat', value: 'snapchat', translate: false },
            { label: 'Telegram', value: 'telegram', translate: false },
            { label: 'Tiktok', value: 'tiktok', translate: false },
            { label: 'Tumblr', value: 'tumblr', translate: false },
            { label: 'Twitchtv', value: 'twitchtv', translate: false },
            { label: 'Twitter', value: 'twitter', translate: false },
            { label: 'Viber', value: 'viber', translate: false },
            { label: 'Vk', value: 'vk', translate: false },
            { label: 'Wechat', value: 'wechat', translate: false },
            { label: 'Weibo', value: 'weibo', translate: false },
            { label: 'Whatsapp', value: 'whatsapp', translate: false },
            { label: 'Youtube', value: 'youtube', translate: false },
        ],
        beforeSend: ({ value }) => {
            return {
                name: 'location.country',
                type: 'array',
                value: value,
                gqlFilter: getGqlFilter(value),
            }
        },
    })
}
