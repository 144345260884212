import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import cn from './Helper.module.scss'
import helper from './helper.svg'

interface HelperProps {
    link: string
}

export const Helper: FC<HelperProps> = ({ link }) => {
    const { t } = useTranslation()
    return (
        <div className={cn.helper} onClick={() => window.open(link, '_blank')}>
            <img src={helper} alt="helper" style={{ marginRight: 8 }}></img>
            <div>{t('helper.title')}</div>
        </div>
    )
}
