import { DjangoPaggination } from '@/Reponses'
import { AxiosRequestConfig } from 'axios'
import Service from './Service'

export class PagginationService<DataItem> extends Service<
    DjangoPaggination<DataItem>
> {
    async getAll(
        config?: AxiosRequestConfig,
        token?: string
    ): Promise<DataItem[]> {
        return await this.recursion(config, token)
    }

    private async recursion(
        config?: AxiosRequestConfig,
        token?: string,
        page: number = 1,
        results: DataItem[] = []
    ): Promise<DataItem[]> {
        const res = await this.call(
            {
                ...config,
                params: {
                    ...config?.params,
                    page,
                },
            },
            token
        )
        if (res.next)
            return await this.recursion(
                config,
                token,
                page + 1,
                results.concat(res.results)
            )
        return results.concat(res.results)
    }
}
