import React, { FC } from 'react'

import { Flex } from '@/_yc'
import cn from './VideoCard.module.scss'
import VideoStats from './VideoStats/VideoStats'
import VideoCover from './VideoCover/VideoCover'
import { getVideoLink } from '../../utils'

export interface IVideoCard {
    id: string
    title: string
    views: string
    imageLink: string
    date: string
}

const VideoCard: FC<IVideoCard> = ({ id, title, views, imageLink, date }) => {
    const openVideo = () => {
        window.open(getVideoLink(id))
    }

    return (
        <Flex
            className={cn.VideoCard}
            column
            content="space-between"
            onClick={openVideo}
        >
            <VideoCover title={title} imageLink={imageLink} />
            <VideoStats views={views} date={date} />
        </Flex>
    )
}

export default VideoCard
