import { useCallback, useContext, useEffect, useState } from 'react'
import { useStore } from 'react-redux'
import { AdapterContext } from '../AdapterContext'
import { FetcherConfig, IState } from '../Adapter'
import useToken from '@/_helpers/Hooks/useToken'
import { RootStore } from '@/_helpers/StoreType'
import { clearAll, clearSearchText } from '../../Model/actions'
import { useTranslation } from 'react-i18next'

type ReturnType<DataType = void> = {
    restart: VoidFunction
    selected: DataType
    next: VoidFunction
    clearSearch: VoidFunction
    clearFilters: VoidFunction
    checkCount: () => Promise<{ count: number }>
    isHidden: boolean
    filters: any
    unhideReportsTikTok: VoidFunction
    hiddenTiktokReportCount: number
    checkUnhideCostTiktok: () => Promise<{ unhideCost?: number }>
}

export function useSearch(): ReturnType
export function useSearch<DataType>(selector: (state: IState<any>) => DataType): ReturnType<DataType>
export function useSearch<DataType = void>(selector?: (state: IState<any>) => DataType): ReturnType<DataType> {
    const context = useContext(AdapterContext)

    const [state, setState] = useState<DataType>(() => {
        if (!selector) return undefined as any
        return selector(context.adapter.getState())
    })

    const [isHidden, setIsHidden] = useState(context.adapter.getState().isHidden)

    const token = useToken()

    const store = useStore<RootStore>()

    const { i18n } = useTranslation()

    const getConfig = useCallback((): FetcherConfig<any> => {
        const state = store.getState().search

        const filters = Object.entries(context.adapter.config.filters).map(([categoryName, value]) => {
            const pre = Object.entries(value.filters).map(([key, filter]) => {
                if (filter.id) {
                    const val = state.filters[filter.id]?.value
                    if (!val) return [key, null]
                    return [key, filter.options.beforeSend(val)]
                }

                const val = state.filters[`${categoryName}.${key}`]?.value
                if (!val) return [key, null]
                return [key, filter.options.beforeSend(val)]
            })

            return [categoryName, Object.fromEntries(pre)]
        })

        if (context.adapter.config.mainFilters) {
            const mainFilters = Object.entries(context.adapter.config.mainFilters).map(([categoryName, value]) => {
                const pre = Object.entries(value.filters).map(([key, filter]) => {
                    const val = state.filters[`${categoryName}.${key}`]?.value
                    if (!val) return [key, null]
                    return [key, filter.options.beforeSend(val)]
                })

                return [categoryName, Object.fromEntries(pre)]
            })
            filters.push(...mainFilters)
        }

        return {
            sorting: state.sorting || context.adapter.config.sortings[0].value,
            search: state.search,
            by: state.searchBy.length > 0 ? state.searchBy : context.adapter.config.keyWords.map((i) => i.value),
            filters: Object.fromEntries(filters),
            locale: i18n.language,
        }
    }, [store, context.adapter.config, i18n.language])

    const restart = useCallback(() => {
        if (!token) return
        context.adapter.resetPages(token, getConfig())
    }, [token, getConfig, context.adapter])

    const next = useCallback(() => {
        if (!token) return
        context.adapter.nextPage(token, getConfig())
    }, [token, getConfig, context.adapter])

    const checkCount = useCallback(() => {
        if (!token) return Promise.reject()
        return context.adapter.checkCount(token, getConfig())
    }, [getConfig, token, context])

    const checkUnhideCostTiktok = useCallback(() => {
        if (!token) return Promise.reject()
        return context.adapter.unhideCostTiktok(token, getConfig())
    }, [getConfig, token, context])

    const clearFilters = useCallback(() => {
        store.dispatch(clearAll())
    }, [store])

    const clearSearch = useCallback(() => {
        store.dispatch(clearSearchText())
    }, [store])

    useEffect(() => {
        const sub = context.adapter.subscribe((state) => {
            setIsHidden(state.isHidden)
            if (!selector) return
            setState(selector(state))
        })

        return () => sub.unsubscribe()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context])

    const unhideReportsTikTok = useCallback(() => {
        if (!token) return
        const hiddenItems = context.adapter
            .getState()
            .items.filter((item) => item.hiddenReportId !== undefined)
            .map((item) => item.hiddenReportId)

        context.adapter.unhideReportsTikTok(token, getConfig(), hiddenItems)
    }, [context, getConfig, token])

    const hiddenTiktokReportCount = context.adapter
        .getState()
        .items.filter((item) => item.hiddenReportId !== undefined || item.isHidden === true).length
    return {
        selected: state,
        restart,
        next,
        clearSearch,
        clearFilters,
        checkCount,
        isHidden,
        filters: getConfig().filters,
        unhideReportsTikTok,
        hiddenTiktokReportCount,
        checkUnhideCostTiktok,
    }
}
