import React, { FC, ReactNode, useState, useEffect, useMemo, CSSProperties } from 'react'
import Scrollbars from 'react-custom-scrollbars'

import { Flex, Text } from '@/_yc'

import useWindowWidth from '@/_helpers/Resize/useWindowWidth'

import YolocoSVG from './Yoloco_svg'

import cn from './FormPageLayout.module.scss'

export interface IFormPageLayout {
    /** @description background image src*/
    background: string

    /** @description form title*/
    title: string

    /** @description background text color*/
    bgTextColor?: string
    mobileBg?: boolean
    children?: ReactNode
    fullWidth?: boolean
    classes?: {
        backgroundText?: string
    }
}

const loaded: Set<string> = new Set()

const preloader = (image?: string) =>
    new Promise<string>((resolve, reject) => {
        if (!image) return reject()

        const img = new Image()

        img.onload = () => {
            loaded.add(image)
            resolve(image)
        }
        img.onerror = () => reject()

        img.src = image
    })

const FormPageLayout: FC<IFormPageLayout> = (props) => {
    const [bgReady, setBgReady] = useState<boolean>(loaded.has(props.background))
    const bgStyle = {
        backgroundImage: `url(${props.background})`,
        backgroundPosition: '50% 63%',
        backgroundSize: '100%',
        borderRadius: '10px 10px 0 0',
        width: 'calc(100%+32px)',
        margin: '-16px -16px 0 -16px',
        height: 280,
    }

    const size = useWindowWidth('mobile')

    const textStype = useMemo<CSSProperties | undefined>(() => {
        if (props.bgTextColor)
            return {
                color: props.bgTextColor,
            }
    }, [props.bgTextColor])

    useEffect(() => {
        if (!bgReady) {
            let mounted = true

            preloader(props.background)
                .then(() => {
                    if (mounted) setBgReady(true)
                })
                .catch((err) => console.error(`Failed to load bg image`, err))

            return () => {
                mounted = false
            }
        }
    }, [bgReady, props.background])

    return (
        <Flex className={size === 'mobile' ? cn['root-mobile'] : cn.root}>
            {size !== 'mobile' && (
                <div
                    className={cn.image}
                    style={{
                        backgroundImage: bgReady ? `url(${props.background})` : undefined,
                        animationName: bgReady ? cn.imageIntro : undefined,
                    }}
                >
                    <div className={cn.text}>
                        <YolocoSVG color={props.bgTextColor} className={cn.desktop_svg} />
                        <Text
                            className={props.classes?.backgroundText}
                            margin="10px 0 0"
                            size={20}
                            style={{ ...textStype, width: '100%' }}
                        >
                            Discover the world’s top influencers.
                        </Text>
                    </div>
                </div>
            )}
            <Flex className={size !== 'mobile' ? cn['container--default'] : cn['container--mobile']}>
                <Scrollbars height="100%" width="100%">
                    <Flex
                        align={size !== 'mobile' ? 'center' : 'flex-start'}
                        content="center"
                        className={cn.scroll}
                        column
                    >
                        {size === 'mobile' && (
                            <div className={cn.svg}>
                                <YolocoSVG width={72} color="#3D3F42" />
                            </div>
                        )}
                        <div className={size === 'mobile' || props.fullWidth ? cn['form--mobile'] : cn.form}>
                            {size === 'mobile' && props.mobileBg && bgReady && <div style={bgStyle} />}
                            <Text
                                margin={props.mobileBg ? '20px 0 8px' : '0 0 8px'}
                                size={size === 'mobile' ? 18 : 30}
                                type="h1"
                            >
                                {props.title}
                            </Text>
                            {props.children}
                        </div>
                    </Flex>
                </Scrollbars>
            </Flex>
        </Flex>
    )
}

export default FormPageLayout
