import React, { FC } from 'react'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

import Flex from '@/_yc/Flex'
import { getShortString } from '@/_helpers/strings/getShortString'
import { getNumberWithSpaces } from '@/_helpers/nums/numberWithSpaces'

import Avatar from '@/components/Avatar'
import Icons from '@/components/Icons'
import cn from './Posts.module.scss'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'

export interface IPosts {
    posts: {
        username: string
        avatar: string
        likes: number
        comments: number | undefined
        created: string
        text: string | undefined
        followers: number | undefined
        fullName: string | undefined
        url: string
        thumbnail: string | undefined
        hashtags: string[] | undefined
    }[]
}

export interface IPosts {}

const Posts: FC<IPosts> = ({ posts }) => {
    const { t } = useTranslation()

    const size = useWindowWidth('mobile')
    const isMobile = size === 'mobile'

    return (
        <>
            <div className={cn.posts}>
                {posts &&
                    posts.map((post, i) => (
                        <>
                            <a href={post.url} target="_blank" rel="noopener noreferrer">
                                <Flex className={cn.post} column content="space-between" margin="20px 0 0 ">
                                    <Flex column>
                                        <Flex className={cn['post-head']} content="space-between">
                                            <Flex>
                                                <Avatar src={post.avatar || ''} className={cn.ava} />
                                                <Flex column margin="0 0 0 10px">
                                                    <p className={cn['post-head-name']}>{post.username}</p>
                                                    {post.followers && (
                                                        <p className={cn['post-head-sub']}>
                                                            {post.followers}{' '}
                                                            {t('report_layout.graph.subs').toLowerCase()}
                                                        </p>
                                                    )}
                                                </Flex>
                                            </Flex>
                                            <p className={cn['post-head-date']}>
                                                {new Date(post.created).toLocaleDateString(i18next.language)}
                                            </p>
                                        </Flex>

                                        <Flex
                                            margin="10px 0 0 0"
                                            wrap
                                            className={cn.imgContainer}
                                            align="center"
                                            content="center"
                                        >
                                            <img className={cn.img} src={post.thumbnail} alt="publication" />
                                        </Flex>

                                        <Flex margin="16px 0 0 0" className={cn.text}>
                                            <p>{post.text && getShortString(post.text, isMobile ? 140 : 200)}</p>
                                        </Flex>
                                    </Flex>

                                    <Flex
                                        align="center"
                                        className={cn.reactions}
                                        margin="16px 0 0 0"
                                        content="space-between"
                                    >
                                        <Flex>
                                            <Flex align="center">
                                                <Icons name="likes" />
                                                <p className={cn.reaction}>{getNumberWithSpaces(post.likes)}</p>
                                            </Flex>
                                        </Flex>
                                        {post.comments && (
                                            <Flex align="center">
                                                <Icons name="comments" style={{ marginLeft: '16px' }} />
                                                <p className={cn.reaction}>{getNumberWithSpaces(post.comments)}</p>
                                            </Flex>
                                        )}
                                    </Flex>
                                </Flex>
                            </a>
                        </>
                    ))}
            </div>
        </>
    )
}

export default Posts
