import { ISearchBy, QueryType } from './types'

const searchByMap = {
    title: 'search_telegram_by_title',
    description: 'search_telegram_by_description',
}

export const getSearchByTable = (searchBy: ISearchBy[]) => {
    switch (searchBy.length) {
        case 1:
            return searchByMap[searchBy[0]] as QueryType
        default:
            return 'search_telegram_by_title_description' as QueryType
    }
}
