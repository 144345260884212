import Text from '@/components/Text'
import { FC, useCallback, useContext, useRef, useState } from 'react'
import React from 'react'
import cn from './Item.module.scss'
import { Currency, CurrencyRaw } from '../../utils/types'
import Icons from '@/components/Icons'
import { Flex } from '@yoloker/ui-collection'
import Popup from '@/_yc/Popup'
import { CampaignManagerContext } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerContext'
import { useCampaignId } from '@/_helpers/Hooks/useCampaignId'
import { useMutation } from '@apollo/client'
import { GET_MEDIA_PLAN, SET_CURRENCY } from '../../utils/queries'

interface ItemProps {
    title: string
    value: string
    isLoading: boolean
    currency?: Currency
}

export const ChooseCurrencyItem: FC<ItemProps> = ({ title, value, isLoading, currency }) => {
    const className = isLoading ? cn.loading : cn.currencyItem
    const [isOpen, setIsOpen] = useState(false)
    const ref = useRef(null)

    const context = useContext(CampaignManagerContext)
    const id = useCampaignId()

    const [setCurrency] = useMutation(SET_CURRENCY, {
        context: context,
        refetchQueries: [GET_MEDIA_PLAN, 'GetMediaPlan'],
    })

    const setCurrencyCallback = useCallback(
        (currency: CurrencyRaw) => {
            setCurrency({
                variables: {
                    orderId: id,
                    currency: currency,
                },
            })

            setIsOpen(false)
        },
        [setCurrency, id]
    )

    return (
        <div className={className}>
            <div className={cn.value} onClick={() => setIsOpen(true)}>
                {currency && (
                    <Text fSize={20} semibold>
                        {currency}
                    </Text>
                )}
                <Text fSize={20} semibold ref={ref}>
                    {value}
                </Text>
                <Icons name="edit" className={cn.icon} />
            </div>

            {isOpen && (
                <>
                    <Popup
                        placement="bottom-start"
                        isOpen={isOpen}
                        onClose={() => setIsOpen(false)}
                        anchor={ref.current}
                        style={{ zIndex: 5 }}
                    >
                        <Flex className={cn.popup} column>
                            <p onClick={() => setCurrencyCallback('RUB')}>RUB</p>
                            <p onClick={() => setCurrencyCallback('EUR')}>EURO</p>
                            <p onClick={() => setCurrencyCallback('USD')}>USD</p>
                        </Flex>
                    </Popup>
                </>
            )}
            <Text color="gray-new-2" fSize={12}>
                {title}
            </Text>
        </div>
    )
}
