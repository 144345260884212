import Service from '@/_helpers/Service'

const requireDemoService = new Service({
    method: 'POST',
    url: '/api/requests/new/',
})

export const demoRequest = (email: string, phone?: string, company?: string, first_name?: string, last_name?: string) =>
    requireDemoService.call({
        data: {
            email,
            first_name,
            last_name,
            phone,
            company,
            demo_type: 'platform',
        },
    })

const requirePromoCompanyService = new Service({
    url: '/api/requests/campaign/',
    method: 'POST',
})

export const requirePromoCompany = (
    token: string,
    name: string,
    product: string,
    company: string,
    target: number,
    phone: string,
    budget: number
) =>
    requirePromoCompanyService.call(
        {
            data: {
                contact_name: name,
                product,
                company,
                target,
                phone,
                budget,
            },
        },
        token
    )
