import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.00004 14.6668C4.31814 14.6668 1.33337 11.6821 1.33337 8.00016C1.33337 4.31826 4.31814 1.3335 8.00004 1.3335C11.6819 1.3335 14.6667 4.31826 14.6667 8.00016C14.6627 11.6804 11.6803 14.6628 8.00004 14.6668ZM8.00004 2.66683C5.05452 2.66683 2.66671 5.05464 2.66671 8.00016C2.66671 10.9457 5.05452 13.3335 8.00004 13.3335C10.9456 13.3335 13.3334 10.9457 13.3334 8.00016C13.3301 5.05602 10.9442 2.67014 8.00004 2.66683ZM11.3334 8.66683H7.33337V4.66683H8.66671V7.3335H11.3334V8.66683Z"
                fill="#5D697D"
            />
        </svg>
    )
}
