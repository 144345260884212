import React, { useEffect } from 'react'
import Flex from '@/_yc/Flex'
import Button from '@/components/Button'

import Notification from './Notification'

import cn from './ChangeLog.module.scss'

const CannyChangeLog = () => {
    useEffect(() => {
        //@ts-ignore
        Canny('initChangelog', {
            appID: '63594af1fa1acc4500dfd208',
            position: 'bottom',
            align: 'right',
        })
    }, [])
    return (
        <Flex
            component={Button}
            align="center"
            content="center"
            variant="transparent"
            className={cn.root}
            width={40}
            size="small"
            margin="0 0 0 12px"
            data-canny-changelog
        >
            <Notification />
        </Flex>
    )
}
export default CannyChangeLog
