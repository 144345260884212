import React, { FC, ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { MassAddHeadCheckBox, MassAddHeadCheckBoxCATALOG } from '@/components/MassAddToList/MassAddHeadCheckBox'
import { ChooseAllNEW } from '@/components/MassAddToList/ChooseAll'
import { RootStore } from '@/_helpers/StoreType'
import Flex from '@/_yc/Flex'
import useToken from '@/_helpers/Hooks/useToken'
import Icons from '@/components/Icons'

import { DeleteCustomListReq } from '../../model/service'
import IntersectList from '../IntersectList'
import ToOutreach from '../ToOutreach'
import { SetDefault } from '@/components/MassAddToList/_model/actions'
import cn from './ListHeader.module.scss'
import { ToMediaPlan } from '../ToMediaPlan/ToMediaPlan'

interface IListHeader {
    isRecent: boolean
    grid: string
    texts: string[]
    children?: ReactNode
    isCatalofOfLists?: boolean
}
const ListHeader: FC<IListHeader> = ({ isRecent, grid, texts, children, isCatalofOfLists }) => {
    const { t } = useTranslation()
    const token = useToken()
    const dispatch = useDispatch()
    const MassAddToListDataINST = useSelector((store: RootStore) => store.MassAddToList.dataINST)
    const MassAddToListDataYT = useSelector((store: RootStore) => store.MassAddToList.dataYT)
    const MassAddToListDataTwitch = useSelector((store: RootStore) => store.MassAddToList.dataTwitch)
    const MassAddToListDataVk = useSelector((store: RootStore) => store.MassAddToList.dataVk)
    const MassAddToListDataTg = useSelector((store: RootStore) => store.MassAddToList.dataTg)
    const MassAddListsAud = useSelector((store: RootStore) => store.MassAddToList.listsAud)

    const showHeader =
        MassAddToListDataINST.length === 0 &&
        MassAddToListDataYT.length === 0 &&
        MassAddToListDataTwitch.length === 0 &&
        MassAddToListDataVk.length === 0 &&
        MassAddToListDataTg.length === 0
    const showHeaderCatalog = isCatalofOfLists && MassAddListsAud.length !== 0

    const ActiveListId = useSelector((store: RootStore) => store.AudienceInsights.ActiveListId)

    useEffect(() => {
        return () => {
            dispatch(SetDefault())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const deleteLists = () => {
        if (!token) return
        Promise.all(MassAddListsAud.map((item) => DeleteCustomListReq(token, item))).then(() =>
            window.location.reload()
        )
    }
    if (!showHeader) {
        return (
            <>
                <Flex className={cn.header} style={{ display: 'flex', gridColumn: '1/5' }} align="center">
                    <MassAddHeadCheckBox
                        deleteFromList={!isRecent}
                        activeListId={ActiveListId}
                        place="aud_insights_mass"
                    />
                    {ActiveListId !== -1 && <IntersectList margin="0 5px 0 0" />}
                    <ToOutreach />
                    <ToMediaPlan />
                </Flex>
            </>
        )
    }
    if (showHeaderCatalog) {
        return (
            <>
                <Flex className={cn.header} style={{ gridColumn: '1/5' }} align="center">
                    <MassAddHeadCheckBoxCATALOG>
                        <Flex align="center" margin="0 0 0 30px">
                            <Icons name={'trash'} />
                            <p className={cn['delete']} onClick={deleteLists}>
                                {t('aud_insides.lists.delete')}
                            </p>
                        </Flex>
                    </MassAddHeadCheckBoxCATALOG>
                </Flex>
            </>
        )
    }

    return (
        <>
            <div className={cn.header} style={{ gridTemplateColumns: grid }}>
                <Flex align="center">
                    <ChooseAllNEW platform={isCatalofOfLists ? 'listsAud' : 'listsBloggers'} />
                </Flex>
                <Flex align="center" style={{ marginLeft: `${isCatalofOfLists ? '50px' : '60px'}` }}>
                    <Flex style={{ width: '100%' }}>
                        <p className={cn['column-title']} style={{ justifySelf: 'center' }}>
                            {t(texts[0])}
                        </p>
                    </Flex>
                </Flex>
                {texts.slice(1).map((item: string) => (
                    <p className={cn['column-title']} key={item}>
                        {t(item)}
                    </p>
                ))}
                {children}
            </div>
        </>
    )
}
export default ListHeader
