import React, { CSSProperties, FC, memo } from 'react'

import cn from './Popup.module.scss'

import Popper from '@/_yc/Popup'
import { AutocompleteResult } from '@/Reponses'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import LoadCircle from '@/Layouts/AudienceLayout/Header/SearchInput/LoadCircle/LoadCircle'
import { SearchItem } from '../SearchItem/SearchItem'

export interface IPopup {
    items: AutocompleteResult[]
    anchor?: any
    isOpen: boolean
    style?: CSSProperties
    onClose: VoidFunction
    width?: string | number
    error: boolean
    onClick: (item: AutocompleteResult) => void
}

const Popup: FC<IPopup> = ({ onClick, ...props }) => {
    const size = useWindowWidth('mobile')

    return (
        <Popper isOpen={props.isOpen} onClose={props.onClose} anchor={props.anchor} style={props.style}>
            <div
                onClick={props.onClose}
                className={cn.root}
                style={{
                    width: size === 'mobile' ? 'calc(100vw - 20px)' : props.width,
                }}
            >
                {props.items.length > 0 || props.error ? (
                    Array.isArray(props.items) &&
                    props.items.map((item) => (
                        <SearchItem key={item.external_id} onClick={() => onClick(item)} {...item} />
                    ))
                ) : (
                    <div className={cn.loader}>
                        <LoadCircle />
                    </div>
                )}
            </div>
        </Popper>
    )
}

export default memo(Popup)
