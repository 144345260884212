import React, { PureComponent } from 'react';

const initState = {
	min: 1,
	sec: 0,
	disabled: true
}

export default ResendService => Component => class withResend extends PureComponent {

	state = initState

	mounted = true;

	componentDidMount(){
		this.tik()
	}
	tik(){
		if (!this.mounted) return
		setTimeout(() => {
			if (!this.mounted) return
			if (this.state.sec === 0) {
				if(this.state.min === 0){
					this.setState({
						disabled: false
					})
				}else{
					this.setState({
						min: this.state.min - 1,
						sec: 59
					})
					this.tik()
				}
			}else{
				this.setState({
					sec: this.state.sec - 1
				})
				this.tik()
			}
		}, 1000)
	}
	resend = () => {
		if (this.state.disabled) return
		this.setState(initState)
		this.tik();
		ResendService(this.props.email)
	}
	componentWillUnmount(){
		this.mounted = false;
	}
	render(){
		const { min, sec, disabled } = this.state;

		return <Component 
					time = {`${min}:${(sec + "").length < 2 ? "0" + sec : sec}`}
					onClick = {this.resend} 
					disabled ={disabled}
					{...this.props}
			   />
	}
}