import Service from '@/_helpers/Service'

interface PaymentStatusResponse {
    status: 'succeeded' | 'canceled' | 'pending'
    is_paid: boolean
}

const paymentStatusService = new Service<PaymentStatusResponse>({
    method: 'GET',
    url: 'api/payments/check/',
})

export const checkPaymentStatus = (token: string) => paymentStatusService.call({}, token)
