import { APIService } from '../_helpers/APIService.js'

export default new APIService({
	methods: {
		OAuth: (params) => ({
			url: `/core/social-accounts/complete/google-oauth2/`,
			method: "GET",
			params
		})
	}
})
