import { gql } from '@apollo/client'

interface queryParams {
    orderBy: string
    cursor?: string
    filters: string[]
    limit?: number
}

export const getPostsQuery = (queryParams: queryParams) => {
    return gql`
        query GetPosts {
            igPosts(ordering: {${queryParams.orderBy}}, 
                ${queryParams.limit ? 'first: ' + queryParams.limit + ',' : ''}
                after: "${queryParams.cursor ? queryParams.cursor : ''}", 
            filter: {
                ${queryParams.filters.join(', ')}
            }) {
                totalCount
                edges {
                    node {
                        id
                        brand
                        brandCategory
                        postDate
                        username
                        code
                        likes
                        followersCount
                        postType
                        profilePicture
                        text
                        url
                        userId
                        views
                        comments
                    }
                }
                pageInfo {
                    hasNextPage
                    startCursor
                    endCursor
                }
            }
        }
    `
}

export const BRAND_CATEGORIES_QUERY = gql`
    query GetBrandCategories {
        igBrandCategories
    }
`

export const BRANDS_QUERY = gql`
    query GetBrands($brand: String) {
        igBrands(name: $brand) {
            brandName
            brandUsername
        }
    }
`
