import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.6668 15H13.3335V10.8333H16.6668V15ZM11.6668 15H8.3335V10.8333H11.6668V15ZM6.66683 15H3.3335V10.8333H6.66683V15ZM16.6668 9.16666H13.3335V5H16.6668V9.16666ZM11.6668 9.16666H8.3335V5H11.6668V9.16666ZM6.66683 9.16666H3.3335V5H6.66683V9.16666Z"
            fill="#929EB0"
        />
    </svg>
)
