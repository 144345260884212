import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.66602 13.3334V8.66668H7.33268L5.18802 10.8133C5.92799 11.57 6.94099 11.9976 7.99935 12C9.69231 11.9975 11.2004 10.9295 11.7647 9.33335H11.7767C11.853 9.11642 11.9108 8.8934 11.9493 8.66668H13.2907C12.9547 11.3333 10.687 13.3333 7.99935 13.3334H7.99268C6.57852 13.3376 5.22146 12.7758 4.22402 11.7733L2.66602 13.3334ZM4.04868 7.33335H2.70735C3.04318 4.66775 5.30935 2.66811 7.99602 2.66665H7.99935C9.41377 2.66213 10.7712 3.22391 11.7687 4.22668L13.3327 2.66665V7.33335H8.66602L10.814 5.18668C10.0733 4.42911 9.05888 4.00144 7.99935 4.00001C6.30638 4.00251 4.79834 5.07054 4.23402 6.66668H4.22202C4.14505 6.88342 4.08728 7.1065 4.04935 7.33335H4.04868Z"
            fill="#ADADAD"
        />
    </svg>
)
