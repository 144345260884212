import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import Icons from '@/components/Icons'
import Text from '@/components/Text'
import { numberFormatter, useSpaceTranslation } from '@/_helpers'
import { Flex } from '@/_yc'

import cn from './MoneyCounter.module.scss'
import { RootStore } from '@/_helpers/StoreType'
import PromoUpgrade from '@/components/Share/PromoUpgrade'

export interface IMoneyCounter {
    money?: number
    margin?: number | string
}
export const MoneyCounter: FC<IMoneyCounter> = (props) => {
    const t = useSpaceTranslation('intersections.dashboards.hidder')
    const tag = useSpaceTranslation('tags.plans_overlap')
    const targetObj = {
        name: tag('name'),
        category: tag('category'),
        action: tag('action'),
    }
    const isPromo = useSelector((store: RootStore) => {
        return store.authentication.user?.profile?.isPromo ?? true
    })

    if (isPromo)
        return (
            <Flex align="center" margin={props.margin}>
                <PromoUpgrade target={targetObj} />
                <Text semibold fSize={14} color="gray-3" margin="0 0 0 16px">
                    {t('zero')}
                </Text>
            </Flex>
        )

    return (
        <Flex margin={props.margin} align="center">
            <Text component={Flex} semibold color="gray-3" fSize={14} className={cn.root} align="center">
                <Icons name="copy" className={cn.iconBlack} />
                {numberFormatter(props.money)}
            </Text>
            <Text margin="0 0 0 16px" semibold color="gray-3" fSize={14}>
                {t('intersections.dashboards.count', undefined, true)}
            </Text>
        </Flex>
    )
}
