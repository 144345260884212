import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="25" height="25" rx="12.5" fill="#FF6161" />
        <path
            d="M15.8083 7.25958L12.4905 10.5774L9.2059 7.29276C8.67225 6.75911 8.18949 7.1499 7.65045 7.68894C7.11141 8.22799 6.72061 8.71075 7.25427 9.2444L10.5389 12.529L7.31867 15.7492C6.77963 16.2883 7.16559 16.7671 7.69924 17.3008C8.23289 17.8344 8.71175 18.2204 9.25079 17.6813L12.471 14.4611L15.7556 17.7457C16.2893 18.2794 16.772 17.8886 17.3111 17.3496C17.8501 16.8105 18.2409 16.3277 17.7072 15.7941L14.4226 12.5095L17.7404 9.19171C18.2795 8.65266 17.8935 8.1738 17.3598 7.64015C16.8262 7.1065 16.3473 6.72054 15.8083 7.25958Z"
            fill="white"
        />
    </svg>
)
