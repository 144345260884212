import React, { FC, memo, useCallback, useContext } from 'react'

import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

import cn from './MediaPlanHeader.module.scss'

import { Link } from 'react-router-dom'
import { MEDIA_PLANNING } from '@/routs'
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'
import { useMutation } from '@apollo/client'
import { UPDATE_NAME } from './queries'
import NameInput from '@/pages/CampaignManager/Campaign/CampaignHeader/NameInput/NameInput'
import { CampaignManagerContext } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerContext'
import { UpdateNameResp } from './types'

interface ICampaignHeader {
    name: string | null
    id: string
}

const MediaPlanHeader: FC<ICampaignHeader> = ({ name, id }) => {
    const t = useSpaceTranslation('media_planner.plan.header')
    const context = useContext(CampaignManagerContext)

    const [updateCampaignName] = useMutation<UpdateNameResp>(UPDATE_NAME, {
        context: context,
        variables: {
            orderId: id,
            name: name,
        },
    })

    const updateName = useCallback(
        (newName: string) => {
            updateCampaignName({
                variables: {
                    orderId: id,
                    name: newName,
                },
            })
        },
        [updateCampaignName, id]
    )

    return (
        <div className={cn.header}>
            <div className={cn.icon}>
                <Icons name="media-planner" />
            </div>
            <div className={cn.heading}>
                <Flex align="center">
                    <Link to={MEDIA_PLANNING}>
                        <span className={cn.title}>{t('title')}</span>
                    </Link>

                    <Icons
                        name={'arrow-right'}
                        className={cn.arrow}
                        style={{ width: '15px', height: '25px', margin: '0 10px' }}
                    />
                    {name && <NameInput curName={name} onSubmit={updateName} />}
                    {!name && <NameInput curName={null} onSubmit={updateName} />}
                </Flex>

                <Link to={MEDIA_PLANNING} className={cn.back_button}>
                    <ArrowLeft fontSize="small" />
                    {t('back_btn')}
                </Link>
            </div>
        </div>
    )
}

export default memo(MediaPlanHeader)
