import React, { memo, FC } from 'react'
import GlobalWidth from '@/_yc/GlobalWidth'

import Flex from '@/_yc/Flex'
import Text from '@/components/Text'

import cn from '../CampaignList.module.scss'
import { useTranslation } from 'react-i18next'

interface IHeader {
    state: 'empty' | 'campaigns'
}
const CampaignGrid: FC<IHeader> = ({ state }) => {
    const { t } = useTranslation()

    if (state === 'empty') {
        return (
            <GlobalWidth>
                <div className={cn['header-empty']}>
                    <Text color={'gray-3'} fSize={10}></Text>

                    <Text color={'gray-3'} fSize={10}>
                        {t('campaign_manager.list_header.name')}
                    </Text>
                    <Text color={'gray-3'} fSize={10}>
                        {t('campaign_manager.list_header.social')}
                    </Text>
                    <Text color={'gray-3'} fSize={10}>
                        {t('campaign_manager.list_header.created')}
                    </Text>
                    <Flex style={{ width: '100%' }} content="end">
                        <Text color={'gray-3'} fSize={10}>
                            {t('campaign_manager.list_header.influencers')}
                        </Text>
                    </Flex>
                </div>
            </GlobalWidth>
        )
    }
    return (
        <>
            <GlobalWidth>
                <div className={cn['header-campaigns']}>
                    <Text color={'gray-3'} fSize={10}></Text>

                    <Text color={'gray-3'} fSize={10}>
                        {t('campaign_manager.list_header.name')}
                    </Text>
                    <Text color={'gray-3'} fSize={10}>
                        {t('campaign_manager.list_header.status')}
                    </Text>
                    <Text color={'gray-3'} fSize={10}>
                        {t('campaign_manager.list_header.social')}
                    </Text>
                    <Text color={'gray-3'} fSize={10}>
                        {t('campaign_manager.list_header.created')}
                    </Text>
                    <Text color={'gray-3'} fSize={10}>
                        {t('campaign_manager.list_header.posts')}
                    </Text>
                </div>
            </GlobalWidth>
        </>
    )
}

export default memo(CampaignGrid)
