import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import { InstagramAdapter } from '@/pages/SearchPage/SearchAdapter/bloggers/instagram'
import { YoutubeAdapter } from '@/pages/SearchPage/SearchAdapter/bloggers/youtube'
import { TwitchAdapter } from '@/pages/SearchPage/SearchAdapter/bloggers/twitch'
import { VkAdapter } from '@/pages/SearchPage/SearchAdapter/bloggers/vk'
import { TgAdapter } from '@/pages/SearchPage/SearchAdapter/bloggers/telegram'
import { TikTokAdapter } from '@/pages/SearchPage/SearchAdapter/bloggers/tiktok'
import { Platform } from '@/pages/Reports/types'
import { useVkHidden, useInstagramHidden, useTgHidden, useTikTokHidden } from '@/_helpers/Hooks/useIsInstagramHidden'

import { Tab } from './Tab/Tab'

import cn from './AdapterSelector.module.scss'

export const platforms = [
    {
        id: Platform.Instagram,
        label: InstagramAdapter.config.title,
        adapter: InstagramAdapter,
    },
    {
        id: Platform.YouTube,
        label: YoutubeAdapter.config.title,
        adapter: YoutubeAdapter,
    },
    {
        id: Platform.Twitch,
        label: TwitchAdapter.config.title,
        adapter: TwitchAdapter,
    },
    {
        id: Platform.Vk,
        label: VkAdapter.config.title,
        adapter: VkAdapter,
    },
    {
        id: Platform.Tg,
        label: TgAdapter.config.title,
        adapter: TgAdapter,
    },
    {
        id: Platform.TikTok,
        label: TikTokAdapter.config.title,
        adapter: TikTokAdapter,
    },
]

export const TabAdapterSelector = () => {
    const { pathname } = useLocation()
    const history = useHistory()
    const [platformsState, setPlatforms] = useState(platforms)
    const isVkHidden = useVkHidden()
    const isTgHidden = useTgHidden()
    const isTikTokHidden = useTikTokHidden()
    const isInstagramHidden = useInstagramHidden()

    useEffect(() => {
        if (!isInstagramHidden && !isVkHidden && !isTgHidden && !isTikTokHidden) {
            setPlatforms(platforms)
        }
        let tempPlatforms = [...platforms]
        if (isInstagramHidden) {
            tempPlatforms = tempPlatforms.filter((platform) => platform.id !== Platform.Instagram)
        }
        if (isVkHidden) {
            tempPlatforms = tempPlatforms.filter((platform) => platform.id !== Platform.Vk)
        }
        if (isTgHidden) {
            tempPlatforms = tempPlatforms.filter((platform) => platform.id !== Platform.Tg)
        }
        if (isTikTokHidden) {
            tempPlatforms = tempPlatforms.filter((platform) => platform.id !== Platform.TikTok)
        }
        setPlatforms(tempPlatforms)
    }, [isInstagramHidden, isVkHidden, isTgHidden, isTikTokHidden])

    const curPlatform = useMemo(() => {
        if (pathname.includes(Platform.YouTube)) {
            return Platform.YouTube
        }
        if (pathname.includes(Platform.Twitch)) {
            return Platform.Twitch
        }
        if (pathname.includes(Platform.Vk)) {
            if (isVkHidden === null) return
            if (isVkHidden) {
                history.push(`/search/${!isInstagramHidden ? Platform.Instagram : Platform.YouTube}`)
                return
            }
            return Platform.Vk
        }
        if (pathname.includes(Platform.Tg)) {
            if (isTgHidden === null) return
            if (isTgHidden) {
                history.push(`/search/${!isInstagramHidden ? Platform.Instagram : Platform.YouTube}`)
                return
            }
            return Platform.Tg
        }
        if (isInstagramHidden) {
            return Platform.YouTube
        }
        if (pathname.includes(Platform.TikTok)) {
            if (isTikTokHidden === null) return
            if (isTikTokHidden) {
                history.push(`/search/${!isInstagramHidden ? Platform.Instagram : Platform.YouTube}`)
                return
            }
            return Platform.TikTok
        }
        return Platform.Instagram
    }, [pathname, isVkHidden, isInstagramHidden, isTgHidden, isTikTokHidden, history])

    return (
        <div className={cn.tabs}>
            {platformsState.map((platform, i) => (
                <Tab
                    key={platform.id}
                    label={platform.label}
                    active={curPlatform === platform.id}
                    index={i}
                    id={platform.id}
                />
            ))}
        </div>
    )
}
