import React, { memo, useEffect, useRef, useState } from 'react'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import RangeSelect from '@/pages/TargetAudience/components/RangeSelect'
import cn from './edit-main-criteria.module.scss'
import MultiSelect from '@/pages/TargetAudience/components/MultiSelect'
import styles from '@/pages/TargetAudience/components/CustomAccordion/custom-accordion.module.scss'
import { ReactComponent as DoneIcon } from '../../../../icons/done.svg'
import Button from '@/components/Button'
import { IAdditionalParamsData } from '@/pages/TargetAudience/services/types'
import useToken from '@/_helpers/Hooks/useToken'
import { geoSearchReq, updateCampaignReq } from '@/pages/TargetAudience/services/services'
import { useTranslation } from 'react-i18next'
import Tooltip from '@/pages/TargetAudience/components/Tooltip'
import { setCampaignData } from '@/_redux/_reducers/target_audience.reducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'

interface Option {
    label: string
    value: string
}

interface CriteriaState {
    [key: string]: Option[]
}

interface EditMainCriteriaProps {
    initialState?: CriteriaState
    onChange: (data: CriteriaState) => void
    onEdit?: (type: null) => void
    options: IAdditionalParamsData | null
}

const Index: React.FC<EditMainCriteriaProps> = memo(({ onEdit, options, onChange, initialState }) => {
    const [selectedAges, setSelectedAges] = useState<Option[]>(initialState?.ages || [])
    const [selectedGender, setSelectedGender] = useState<Option[]>(initialState?.gender || [])
    const [selectedCountry, setSelectedCountry] = useState<Option[]>(initialState?.country || [])
    const [selectedIncome, setSelectedIncome] = useState<Option[]>(initialState?.income || [])

    const token = useToken()
    const { t, i18n } = useTranslation()
    const name = useSelector<RootStore, string>((state) => state.targetAudience.campaign?.name)
    const campaignId = parseInt(localStorage.getItem('campaignId') || '1')
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null)
    const dispatch = useDispatch()

    useEffect(() => {
        if (
            selectedAges?.length > 0 ||
            selectedGender?.length > 0 ||
            selectedCountry?.length > 0 ||
            selectedIncome?.length > 0
        ) {
            onChange({
                ages: selectedAges,
                gender: selectedGender,
                country: selectedCountry,
                income: selectedIncome,
            })
        }
        //eslint-disable-next-line
    }, [selectedAges, selectedGender, selectedCountry, selectedIncome])

    const handleAgeChange = (selected: Option[]) => {
        setSelectedAges(selected)
    }

    const handleGenderChange = (selected: Option[]) => {
        setSelectedGender(selected)
    }

    const loadOptions = async (searchTerm = ''): Promise<Option[]> => {
        try {
            const response = await geoSearchReq(token, searchTerm)
            return response.map((geo) => ({
                label: String(geo[`name_${i18n.language.split('-')[0]}` as keyof typeof geo] || ''),
                value: geo.city_id ? `city_${geo.city_id}` : `region_${geo.region_id}`,
            }))
        } catch (error) {
            console.error('Ошибка загрузки городов:', error)
            return [{ label: t('target_audience.nothing_found'), value: 'error' }]
        }
    }

    const reportNameHandler = async (value: string) => {
        try {
            await updateCampaignReq(token, campaignId, { name: value })
        } catch (error) {
            console.error('Ошибка обновления кампании:', error)
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        dispatch(setCampaignData({ name: value }))
        if (debounceTimeout.current) clearTimeout(debounceTimeout.current)

        debounceTimeout.current = setTimeout(() => {
            reportNameHandler(value)
        }, 300)
    }

    return (
        <Flex column className={cn['root']}>
            {onEdit && (
                <Flex className={styles['title-wrap']} align="center" content="space-between">
                    <Text fSize="14" color="gray-new-4">
                        {t('target_audience.main_criteria.main_criteria')}
                    </Text>
                    <DoneIcon style={{ cursor: 'pointer' }} onClick={() => onEdit(null)} />
                </Flex>
            )}
            {onEdit && (
                <Flex column>
                    <Flex align="center" margin="0 0 8px 0">
                        <Text fSize={'12px'} semibold color={'gray-new-4'}>
                            {t('target_audience.report_title')}
                        </Text>
                    </Flex>
                    <input
                        className={cn['input-report-name']}
                        onChange={handleChange}
                        placeholder={t('target_audience.report_title')}
                        value={name}
                    />
                </Flex>
            )}
            <Flex column>
                <Flex align="center" margin="0 0 8px 0">
                    <Text fSize={'12px'} semibold color={'gray-new-4'}>
                        {t('target_audience.main_criteria.city_or_region')}
                    </Text>
                    <Tooltip title={t('target_audience.selectLocation')} placement={'right'}>
                        <span className={cn['info-icon']}>i</span>
                    </Tooltip>
                </Flex>
                <MultiSelect
                    options={options?.Geo || []}
                    placeholder={'Введите'}
                    onChange={(options) => setSelectedCountry(options)}
                    enableSearch={true}
                    initialSelected={selectedCountry}
                    loadOptions={loadOptions}
                />
            </Flex>
            <Flex column>
                <Flex align="center" margin="0 0 8px 0">
                    <Text fSize={'12px'} semibold color={'gray-new-4'}>
                        {t('target_audience.main_criteria.gender')}
                    </Text>
                    <Tooltip title={t('target_audience.selectGenderTooltip')} placement={'right'}>
                        <span className={cn['info-icon']}>i</span>
                    </Tooltip>
                </Flex>
                <Flex className={cn['field-wrapper']}>
                    <RangeSelect
                        options={options?.GenderGroups || []}
                        selectedValues={selectedGender}
                        onChange={handleGenderChange}
                    />
                </Flex>
            </Flex>
            <Flex column>
                <Flex align="center" margin="0 0 8px 0">
                    <Text fSize={'12px'} semibold color={'gray-new-4'}>
                        {t('target_audience.main_criteria.age')}
                    </Text>
                    <Tooltip title={t('target_audience.selectAgeRange')} placement={'right'}>
                        <span className={cn['info-icon']}>i</span>
                    </Tooltip>
                </Flex>
                <Flex className={cn['field-wrapper']}>
                    <RangeSelect
                        options={options?.AgeGroup || []}
                        selectedValues={selectedAges}
                        onChange={handleAgeChange}
                    />
                </Flex>
            </Flex>
            <Flex column>
                <Flex align="center" margin="0 0 8px 0">
                    <Text fSize={'12px'} semibold color={'gray-new-4'}>
                        {t('target_audience.main_criteria.income')}
                    </Text>
                    <Tooltip title={t('target_audience.selectIncomeRange')} placement={'right'}>
                        <span className={cn['info-icon']}>i</span>
                    </Tooltip>
                </Flex>
                <MultiSelect
                    options={options?.IncomesSize || []}
                    placeholder={'Выберите'}
                    onChange={(options) => setSelectedIncome(options)}
                    enableSearch={false}
                    initialSelected={selectedIncome}
                />
            </Flex>
            {onEdit && (
                <Flex column>
                    <Button onClick={() => onEdit(null)} className={cn['btn']}>
                        Сохранить
                        <DoneIcon />
                    </Button>
                </Flex>
            )}
        </Flex>
    )
})
export default Index
