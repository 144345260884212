export class NameError {

	text = "intersections.dashboards.modal.errors.name";

	constructor(names){
		this.names = names;
	}
}

export class NotEnough {
	text = "intersections.dashboards.modal.errors.not_enough"
}

export class MoreBloggers {
	text = "intersections.dashboards.modal.errors.more"
}
export class EmptyName {
	text = "intersections.dashboards.modal.errors.empty_name"
}

export class NoInformation {

	text = "intersections.dashboards.modal.errors.no_data";

	constructor(names){
		this.names = names;
	}
}
