import useClass from '@/_helpers/Hooks/useClass'
import React, { memo, HTMLAttributes, useMemo, ForwardRefRenderFunction, forwardRef } from 'react'
import cn from './Button.module.scss'

export interface IButton extends Omit<HTMLAttributes<HTMLButtonElement>, 'id'> {
    [key: string]: any
    variant?:
        | 'primary'
        | 'outline'
        | 'transparent'
        | 'transparent--outline'
        | 'pure'
        | 'outline--white'
        | 'outline--mobileV2'
        | 'outline--grey'
        | 'success'
    size?: 'small' | 'medium' | 'large'
    margin?: string | number
    disabled?: boolean
    width?: number | string
    component?: any
    color?: 'white' | string
    active?: boolean
}

const Button: ForwardRefRenderFunction<any, IButton> = (
    {
        variant = 'outline',
        size = 'medium',
        margin,
        children,
        className,
        style,
        width,
        active,
        component: Component = 'button',
        ...props
    },
    ref
) => {
    const root = useClass(cn.root, cn[variant], cn[size], className, active && cn[`${variant}--active`])

    const st = useMemo(
        () =>
            !margin && !style && !width
                ? undefined
                : {
                      margin,
                      width,
                      padding: width && 0,
                      ...style,
                  },
        [margin, style, width]
    )

    return (
        <Component ref={ref} disabled={props.disabled} {...props} style={st} className={root}>
            {children}
        </Component>
    )
}

export default memo(forwardRef(Button))
