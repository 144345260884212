import { ISearchItem } from './types'
import { QueryType, TgUserInfoResult } from './types'

export const getTgAccountsFromRaw = (
    queryType: QueryType,
    result: TgUserInfoResult,
    sliceIndex: number
): ISearchItem[] => {
    const data = result[queryType]
    if (!data || data.length === 0) {
        return []
    }

    const unlockedChannels = result?.getUnlockedChannels?.unlocked_tg_accounts

    const isUnlocked = (id: string): boolean => {
        return unlockedChannels.filter((item: any) => item === id).length > 0
    }

    return data.slice(0, sliceIndex).map((item) => {
        return {
            id: item.id,
            username: item.username,
            fullName: item.title,
            followers: item.subscribers_count,
            averageViews: item.er_views,
            avatar: item.avatar,
            tg_posts_views_count: item.median_views_w,
            unlocked: isUnlocked(item.id), // unlockedChannels?.includes(item.account_id),
            topics: item.channel_topics.map((item) => item?.telegram_topic?.name ?? ''),
        }
    })
}
