import useToken from '@/_helpers/Hooks/useToken'
import { RootStore } from '@/_helpers/StoreType'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateAdvertisingCampaigns } from '../../Model/actions'
import { getAdvertisingCampaignsReq } from '../../Service/Campaigns'
import { Header } from './Header/Header'
import { Row } from './Row/Row'
import cn from './Table.module.scss'
import Flex from '@/_yc/Flex'
import Loader from '@/components/Loader'
import { EmptyState } from './EmptyState/EmptyState'
import { SetDefault } from '@/components/MassAddToList/_model/actions'
export const Table = () => {
    const token = useToken()
    const dispatch = useDispatch()
    const activeStatusFilter = useSelector((state: RootStore) => state.Outreach.campaignsFilter)
    const [isLoading, setIsLoading] = useState(false)
    const [nextPage, setNextPage] = useState<string | null>(null)
    const [page, setPage] = useState<number>(1)

    useEffect(() => {
        if (!token) return

        setIsLoading(true)
        if (activeStatusFilter === 'all') {
            getAdvertisingCampaignsReq(token, undefined, page).then((data) => {
                setIsLoading(false)
                setNextPage(data.next)

                dispatch(updateAdvertisingCampaigns(data))
            })
            return
        }

        getAdvertisingCampaignsReq(token, activeStatusFilter).then((data) => {
            setIsLoading(false)
            setNextPage(data.next)

            dispatch(updateAdvertisingCampaigns(data))
        })
    }, [dispatch, token, activeStatusFilter, page])

    const data = useSelector((state: RootStore) => state.Outreach.advertisingCampaigns)
    useEffect(() => {
        return () => {
            dispatch(SetDefault())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //pagination logic
    const handleScroll = React.useCallback(() => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

        if (bottom && nextPage && !isLoading) {
            //change page so request will run from useEffect
            setPage(page + 1)
        }
    }, [nextPage, page, isLoading])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true,
        })

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [handleScroll])

    //scroll for 4k displays
    const listElementsContainer = document.getElementById('outreachRows')
    useEffect(() => {
        function scrollTrigger(this: HTMLDivElement) {
            // // @ts-ignore
            var el = this
            var sc = el.scrollHeight - el.clientHeight - el.scrollTop

            if (sc < 1) {
                if (nextPage && !isLoading) {
                    //change page so request will run from useEffect
                    setPage(page + 1)
                }
            }
        }
        if (!listElementsContainer) return
        listElementsContainer.addEventListener('scroll', scrollTrigger, {
            passive: true,
        })

        return () => {
            listElementsContainer.removeEventListener('scroll', scrollTrigger)
        }
    }, [listElementsContainer, nextPage, isLoading, page])

    return (
        <div className={cn.table} id={'outreachRows'}>
            <Header />
            <div className={cn.rows}>
                {data.results.length === 0 && !isLoading && <EmptyState />}

                {data.results.map((item, i) => (
                    <Row key={item.id} data={item} index={i} />
                ))}

                {isLoading && (
                    <Flex content="center">
                        <Loader />
                    </Flex>
                )}
            </div>
        </div>
    )
}
