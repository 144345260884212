import i18next from 'i18next'
import React from 'react'

import Service from './_service.js'
import Const from './_constants.js'
import { snackActions } from '@/components/Notifier/model/actions.js'
// import { history } from '@/_helpers/'
// import ListsAction from '@/pages/OLDAudienceInsides/Model/_actions/lists.js'
import BloggerAddedIn from '../BloggerAddedIn.js'
import { Platform } from '@/pages/Reports/types.ts'
// import { LISTS } from '@/routs.js'

export const _actions = {
    getLists: (page) => (dispatch) => {
        dispatch({ type: Const.CONTEXT_MENU_REQUEST })
        return Service.getLists(page).then(
            (result) => {
                if (result.next) dispatch(_actions.getLists(page + 1))
                dispatch({ ...result, type: Const.CONTEXT_MENU_SUCCESS })
            },
            (error) => dispatch({ ...error, type: Const.CONTEXT_MENU_FAILURE })
        )
    },
    clearStore: () => ({ type: Const.CONTEXT_MENU_CLEAR }),
    check: (index) => ({ type: Const.CONTEXT_MENU_CHECK, index }),
    addToLists: (lists, bloggerId, username, type) => (dispatch) => {
        const t = i18next.getFixedT()
        let succ = 0,
            failure = 0,
            firstSuccess = null
        dispatch(snackActions.loading(t('context_menu.alerts.process')))
        lists.forEach((list) => {
            if (type === Platform.Instagram || !type) {
                Service.addBloggers(list.id, [bloggerId]).then(
                    (success) => {
                        succ++
                        if (!firstSuccess) {
                            firstSuccess = list.id
                        }
                        // if (window.location.pathname === '/audience') {
                        //     dispatch(ListsAction.increaseCountIn(list.id))
                        // }
                        handleResult(succ, failure, lists.length, dispatch, firstSuccess, username, lists)
                    },
                    (error) => {
                        failure++
                        handleResult(succ, failure, lists.length, dispatch, firstSuccess)
                    }
                )
            }

            if (type === Platform.YouTube) {
                Service.addChannels(list.id, [bloggerId]).then(
                    (success) => {
                        succ++
                        if (!firstSuccess) {
                            firstSuccess = list.id
                        }
                        // if (window.location.pathname === '/audience') {
                        //     dispatch(ListsAction.increaseCountIn(list.id))
                        // }
                        handleResult(succ, failure, lists.length, dispatch, firstSuccess, username, lists)
                    },
                    (error) => {
                        failure++
                        handleResult(succ, failure, lists.length, dispatch, firstSuccess)
                    }
                )
            }

            if (type === Platform.Twitch) {
                Service.addTwitchChannels(list.id, [bloggerId]).then(
                    (success) => {
                        succ++
                        if (!firstSuccess) {
                            firstSuccess = list.id
                        }
                        // if (window.location.pathname === '/audience') {
                        //     dispatch(ListsAction.increaseCountIn(list.id))
                        // }
                        handleResult(succ, failure, lists.length, dispatch, firstSuccess, username, lists)
                    },
                    (error) => {
                        failure++
                        handleResult(succ, failure, lists.length, dispatch, firstSuccess)
                    }
                )
            }
        })
    },
    addList: (name) => (dispatch) => {
        const t = i18next.getFixedT()
        dispatch(snackActions.loading(t('context_menu.alerts.process')))
        return Service.addList(name).then(
            (item) => {
                dispatch(snackActions.success(t('context_menu.alerts.list_success'), 3000))
                dispatch({ type: Const.CONTEXT_MENU_NEW_SUCCESS, item })
                // if (history.location.pathname === LISTS) {
                // dispatch(ListsAction.clearLists)
                // dispatch(ListsAction.getLists(1))
                // }
            },
            (error) => {
                dispatch(snackActions.error(t('common_error'), 3000))
            }
        )
    },
}

function handleResult(success, failure, max, dispatch, firstSuccess, username, lists) {
    const t = i18next.getFixedT()

    if (success + failure === max) {
        if (!failure)
            return dispatch(
                snackActions.success(
                    <BloggerAddedIn username={username} lists={lists} />,
                    // t("context_menu.alerts.success", { username: username }),
                    4000
                )
            )
        else if (success) return dispatch(snackActions.success(t('context_menu.alerts.semiresult'), 4000))
        else return dispatch(snackActions.error(t('context_menu.alerts.error'), 3000))
    }
}
