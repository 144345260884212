import { SelectorFilter } from '@/pages/SearchPage/SearchAdapter/filters/SelectorFilter'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { GET_YOUTUBE_BRANDS } from '../../queries'
import { IQueryBrands } from '../../types'
import { preprocessBrands } from '../utils/preprocessBrands'

const getGqlFilter = (brands: string[]) => {
    return `brandName: {in: [${brands.join(' ')}]}`
}

export const getBrandsFilter = (fetcher: Fetcher) => {
    return new SelectorFilter({
        platform: 'ads_yt',
        title: 'ads_explorer.filters.post_metrics.brands.brand.label',
        autocomplete: true,
        helper: 'ads_explorer.filters.post_metrics.brands.brand.text',
        space: 1,
        isMulti: true,
        options: async (token: string, locale: string, query?: string) => {
            const client = fetcher.getClient()
            return await client
                .query<IQueryBrands>({
                    query: GET_YOUTUBE_BRANDS,
                    variables: {
                        brand: query || '',
                    },
                })
                .then((res) => {
                    const brands = res.data?.get_yt_brands
                    return preprocessBrands(brands)
                })
        },
        beforeSend: ({ value }) => {
            return {
                name: 'filters.brand.label',
                type: 'array',
                value: value,
                gqlFilter: getGqlFilter(value as string[]),
            }
        },
    })
}
