import React, { FC } from 'react'

import cn from './EmptyAccounts.module.scss'
import Icons from '@/components/Icons'
import Text from '@/components/Text'
import Button from '@/components/Button'
import { useSpaceTranslation } from '@/_helpers'

interface EmptyAccountsProps {
    open: () => void
}

export const EmptyAccounts: FC<EmptyAccountsProps> = ({ open }) => {
    const t = useSpaceTranslation('settings.mail.accounts.empty')
    return (
        <div className={cn.empty}>
            <div className={cn.mailContainer}>
                <Icons name="mail" className={cn.mail} />
            </div>

            <Text fSize={20} semibold margin="24px 0 0 0">
                {t('title')}
            </Text>

            <Text fSize={14} color="gray-3" margin="8px 0 0 0">
                {t('description')}
            </Text>

            <Button margin="20px 0 0 0" onClick={open} variant="primary">
                {t('button')}
            </Button>
        </div>
    )
}
