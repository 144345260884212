import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import useOpenClose from '@/_helpers/useOpenClose'
import Popup from '@/_yc/Popup/Popup'
import Flex from '@/_yc/Flex'
// import { Platform } from '@/pages/Reports/types'

import { MassParsingPopup, IActionType } from './MassParsingPopup'

import cn from './MassParsingNEW.module.scss'
const MassParsingNEW = () => {
    const { t } = useTranslation()

    const [isOpen, , close, toggle] = useOpenClose()
    const [isMassPopupOpen, openMassPopup, closeMassPopup] = useOpenClose()
    // const [platform, setPlatfotm] = useState<Platform>(Platform.Instagram)
    const [actionType, setActionType] = useState<IActionType>('bulk')

    const ref = useRef(null)
    return (
        <>
            <Button className={cn.add} onClick={toggle} ref={ref}>
                {t('aud_insides.search_input.mass_add')}
            </Button>
            {isOpen && (
                <>
                    <Popup
                        placement="bottom-end"
                        isOpen={isOpen}
                        onClose={close}
                        anchor={ref.current}
                        style={{ zIndex: 5 }}
                    >
                        <Flex className={cn.popup} column>
                            <p
                                onClick={() => {
                                    setActionType('bulk')
                                    openMassPopup()
                                }}
                            >
                                {t(`aud_insides.mass_parsing.bulk.title`)}
                            </p>
                            <p
                                onClick={() => {
                                    setActionType('csv')
                                    openMassPopup()
                                }}
                            >
                                {t(`aud_insides.mass_parsing.csv.title`)}
                            </p>
                        </Flex>
                    </Popup>
                </>
            )}
            {isMassPopupOpen && (
                <MassParsingPopup
                    isOpen={isMassPopupOpen}
                    close={closeMassPopup}
                    title={t(`aud_insides.mass_parsing.${actionType}.title`)}
                    subtitle={t(`aud_insides.mass_parsing.${actionType}.desc`)}
                    actionType={actionType}
                    setActionType={setActionType}
                />
            )}
        </>
    )
}

export default MassParsingNEW
