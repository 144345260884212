import React, { ComponentProps, FC, useCallback, ReactNode } from 'react'
import { useHistory } from 'react-router-dom'

import Arrow from './Arrow'
import { Button, Flex, Text } from '@/_yc'
import useClass from '@/_helpers/Hooks/useClass'

import cn from './BackButton.module.scss'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { LOGIN } from '@/routs'

type E = ComponentProps<typeof Button>

export interface IBackButton extends Omit<E, 'onClick' | 'color' | 'size' | 'children'> {
    children?: ReactNode
}

const icon = { marginRight: 10 }

const BackButton: FC<IBackButton> = ({ children, className, ...props }) => {
    const history = useHistory()

    const t = useSpaceTranslation('require_demo')

    const back = useCallback(() => {
        history.goBack()
    }, [history])

    const root = useClass(cn.root, className)

    return (
        <Button className={root} size="small" onClick={back} color="optional" {...props}>
            <a href={LOGIN}>
                <Flex align="center">
                    <Arrow style={icon} />
                    <Text semibold={false} color="secondary">
                        {children || t('back_UNKNOWN')}
                    </Text>
                </Flex>
            </a>
        </Button>
    )
}

export default BackButton
