import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.15" width="30" height="30" rx="15" fill="#EF3B46" />
        <path
            d="M17.9918 10.8335L15.0002 13.8252L12.0085 10.8335L10.8335 12.0085L13.8252 15.0002L10.8335 17.9918L12.0085 19.1668L15.0002 16.1752L17.9918 19.1668L19.1668 17.9918L16.1752 15.0002L19.1668 12.0085L17.9918 10.8335Z"
            fill="#EF3B46"
        />
    </svg>
)
