import React from 'react'
import Flex from '@/_yc/Flex'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import Icons from '@/components/Icons'
import cn from './Hidder.module.scss'
import { useSearch } from '../SearchAdapter'

const TiktokHidder = () => {
    const t = useSpaceTranslation('Influencer_explorer')
    const { unhideReportsTikTok, hiddenTiktokReportCount, checkUnhideCostTiktok } = useSearch((state) => state)

    const unhideReport = () => {
        unhideReportsTikTok()
    }

    const [unhideCost, setUnhideCost] = React.useState(1)
    React.useEffect(() => {
        checkUnhideCostTiktok()
            .then((res) => {
                setUnhideCost(res.unhideCost ? res.unhideCost : 1)
            })
            .catch((err) => console.log(err))
    }, [checkUnhideCostTiktok])

    return (
        <>
            <Flex className={cn.tiktok} onClick={unhideReport}>
                <p>
                    {t('tiktok.hidder', {
                        count: hiddenTiktokReportCount,
                        price: Math.ceil(hiddenTiktokReportCount * unhideCost),
                    })}
                </p>
                <Icons name="unlock" />
            </Flex>
        </>
    )
}

export default TiktokHidder
