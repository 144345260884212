import { MessageData } from '../Messenger/Chat/types'

interface MessageEvent {
    type: string
    message: {
        id: number
        sender_blogger: {
            id: number
            username: string
            avatar: string
            email: string
        }
        created_at: string
        chat_id: number
        body: string
        status: string
    }
}

interface ChatEvent extends MessageData {
    chatId: number
    type: string
}

export const parseChatMessage = (m: MessageEvent) => {
    return {
        type: m.type,
        sender: 'blogger',
        bloggerName: m.message?.sender_blogger?.username,
        message: m.message?.body,
        time: m.message?.created_at,
        chatId: m.message?.chat_id,
        id: m.message?.id,
        isRead: false,
    } as ChatEvent
}
