import Service from '@/_helpers/Service'
import { ChatsFilterType } from '../Messenger/AllChats/ChatsFilter/ChatsFilter'

interface Blogger {
    id: string
    name: string
    avatar: string
    email: string
    internal_id: string
    er: string
    followers: number
}
interface User {
    username: string
    id: number
}
export interface Chat {
    id: number
    basis_id: string
    basis_kind: string
    participant_users: User[]
    participant_bloggers: Blogger[]
    participant_channels: Blogger[]
    advertising_campaign: {
        id: string
        name: string
        chats_count: number
    }
    last_message: {
        created_at: string
        sender_user: number | null
        sender_blogger: {
            avatar: string
            username: string
            id: number
        }
        body: string
    }
    unread_messages: number
    last_read_message: number
    answered: boolean
}
interface GetChatsReqParams {
    token: string
    basisId: string
    page: number
    filter?: ChatsFilterType
}
const getChats = new Service<Chat[]>('')
export const getChatsReq = ({ token, basisId, page, filter }: GetChatsReqParams) => {
    const params = new URLSearchParams({})
    params.append('basis_id', basisId.toString())
    if (filter && filter !== 'all') {
        params.append(filter, 'true')
    }

    const limit = 100

    params.append('limit', limit.toString())
    params.append('offset', (page * limit).toString())

    return getChats.call(
        {
            url: `/core/outreach/chats/?${params.toString()}`,
        },
        token
    )
}

export interface MessageFromBackend {
    id: number
    sender_user: number
    sender_blogger: number
    created_at: string
    chat_id: number
    participant_users: number[]
    sender_channel: string
    body: string
    status: string
    is_read: boolean
}
const getMessages = new Service<MessageFromBackend[]>('')
export const getMessagesReq = (token: string, chatId: number, limit?: number) => {
    return getMessages.call(
        {
            url: `/core/outreach/messages/?chat_id=${chatId}&limit=${limit || 1000}`,
        },
        token
    )
}

const readMessage = new Service<any>('')
export const readMessageReq = (token: string, chatId: number, messageId: number) => {
    return readMessage.call(
        {
            url: `/core/outreach/messages/read`,
            method: 'POST',
            data: {
                message_id: messageId,
                chat_id: chatId,
            },
        },
        token
    )
}

const postMessage = new Service<any>('')
export const postMessageReq = (token: string, chatId: number, basisId: string, body: string) => {
    return postMessage.call(
        {
            url: `/core/outreach/messages/`,
            method: 'POST',
            data: { chat_id: chatId, body, basis_id: basisId },
        },
        token
    )
}

const searchChats = new Service<{ chats: Chat[]; messages: MessageFromBackend[] }>('')
export const searchChatsReq = (token: string, query: string) => {
    return searchChats.call(
        {
            url: `/core/outreach/search/?query=${query}`,
            method: 'GET',
        },
        token
    )
}

const resendMails = new Service<any>('')
export const resendMailsReq = (token: string, campaign_id: string) => {
    return resendMails.call(
        {
            url: `/core/outreach/advertising_campaigns/${campaign_id}/repeat/`,
            method: 'POST',
        },
        token
    )
}
