import Service from '@/_helpers/Service'
export interface IHistoricalMetrics {
    created_at: string
    comments: number
    dislikes: number
    likes: number
    views: number
}

export interface IPostStatistics {
    service_type: string
    url: string
    created_at: string
    views: number
    likes: number
    comments: number
    dislikes: number | undefined
    text: string
    blogger_info: {
        username: string
        full_name: string | null
        followers: number
        reach: number
        er: number
    }
    channel_id: string
    video_id: string
    statistic: [IHistoricalMetrics]
    avatar_url: string | undefined
    channel_name: string | undefined
    preview_url: string | undefined
}
const GetPostStatistics = new Service<IPostStatistics>({
    baseUrl: process.env.REACT_APP_CAMPAIGN_MANAGER,
    method: 'GET',
    url: '',
})

export const GetPostStatisticsReq = (token: string, platform: string, post_id: string, channel_name?: string) =>
    GetPostStatistics.call(
        {
            url: `/api/campaigns/ad-posts/${platform}/${platform === 'telegram' ? channel_name + '/' : ''}${post_id}`,
        },
        token
    )
