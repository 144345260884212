export const toQueryWithOR = (filterValues: string[], filterName: string, filterId: string) => {
    return filterValues.length > 1
        ? `${filterName}: {_or: [${filterValues.map((i) => `{${filterId}:{_eq: "${i}"}}`)}]}`
        : `${filterName}: {${filterId}: {_eq: "${filterValues[0]}"}}`
}

export const toQueryWithOrDirect = (filterValues: string[], filterName: string) => {
    return filterValues.length > 1
        ? `_or: [${filterValues.map((i) => `{${filterName}: {_eq: "${i}"}}`)}]`
        : `${filterName}: {_eq: "${filterValues[0]}"}`
}
