import React from 'react'
import styles from '@/pages/TargetAudience/Views/components/list/list.module.scss'
import { Checkbox } from '@/_yc'
import { useTranslation } from 'react-i18next'

const Index = ({ headers }: { headers: string[] }) => {
    const { t } = useTranslation()
    return (
        <>
            <div className={styles.headerRow}>
                <div className={styles.headerCell}>
                    <Checkbox value={false} className={styles['checkbox']} />
                </div>
                {headers.map((header, index) => (
                    <div key={index} className={styles.headerCell}>
                        {t(header)}
                    </div>
                ))}
            </div>
        </>
    )
}

export default Index
