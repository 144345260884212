import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M30.7574 1.82899C29.9222 1.35062 28.9275 1.55857 28.3974 2.08853C24.9777 5.50829 24.9981 5.55638 24.5896 5.71052C24.0818 5.90225 23.557 5.79646 23.1397 5.4546C16.8529 0.0782355 17.2556 0.419709 17.1998 0.380269C16.3392 -0.226789 15.2562 -0.0513444 14.677 0.514619C8.50714 6.53965 8.85614 6.23144 8.60445 6.36885C7.89384 6.7565 7.18004 6.5004 6.66536 5.89652C3.4481 2.12173 3.6856 2.39374 3.61235 2.32924C2.03375 0.938662 -0.235775 2.42135 0.019846 4.70804C0.718585 11.8542 1.19201 18.8781 1.19264 18.8845C1.41907 21.2003 1.34576 20.4784 1.3627 20.6223C1.5782 22.4997 2.9273 24 4.64221 24H27.331C29.0976 24 30.438 22.4005 30.6122 20.6063L31.9806 4.47096C32.1047 3.3625 31.6252 2.32612 30.7574 1.82899ZM2.16919 3.72592C2.219 3.69914 2.26512 3.68324 2.34562 3.74049L5.24901 7.14702C6.69805 8.84714 8.8812 8.83016 10.2369 7.49524L15.9271 1.93847C15.9896 1.89896 16.0447 1.89731 16.1068 1.9328C22.2457 7.18271 21.9414 6.92266 21.9539 6.93297C22.883 7.6981 24.0978 7.93137 25.2414 7.49988C26.1254 7.1663 26.2429 6.91846 29.6632 3.49806C29.7353 3.45048 29.7794 3.45862 29.8377 3.49202C30.0038 3.58718 30.1621 3.86753 30.1173 4.25817C30.1157 4.27179 30.1568 4.94482 29.0002 16.7386H2.97367C1.87382 5.48998 1.8845 4.50728 1.88313 4.49558C1.83432 4.07032 2.0275 3.80232 2.16919 3.72592ZM27.331 22.0916H4.64215C3.94672 22.0916 3.33879 21.3686 3.22579 20.4084L3.16023 18.647H28.813L28.7472 20.4082C28.6344 21.3685 28.0265 22.0916 27.331 22.0916Z"
            fill="#6071FF"
        />
    </svg>
)
