import React, { FC, memo, useCallback, useEffect, useState } from 'react'

import cn from './Sidebar.module.scss'
import Flex from '@/_yc/Flex'
import { NavLink, useLocation } from 'react-router-dom'
import Icons from '@/components/Icons'

import Text from '@/components/Text'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'

import useToken from '@/_helpers/Hooks/useToken'
import { getSideLinks } from '@/Services/SideLinks'

import actions from '@/pages/Auth/LoginPage/Model/_actions'
import { identify, Identify } from '@amplitude/analytics-browser'

const tagStyle = { width: 15 }
const margin = { marginBottom: 16 }

interface ISidebar {
    links: {
        title: string
        to: string
        icon: string
        active: string[]
        isNew?: boolean
    }[]
}

const Sidebar: FC<ISidebar> = ({ links }) => {
    const { pathname } = useLocation()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [botLinks, setBotLinks] = useState<any>([])
    const token = useToken()

    useEffect(() => {
        if (!token) return
        getSideLinks(token).then((data) => {
            setBotLinks(data)
        })
    }, [t, token])

    const checkActiveLink = useCallback(
        (activeLinks) => {
            for (let i = 0; i < activeLinks.length; i++) {
                if (pathname.includes(activeLinks[i])) return true
            }
            return false
        },
        [pathname]
    )

    useEffect(() => {
        dispatch(actions.getUserInfo())
    }, [dispatch])

    const plan = useSelector((store: RootStore) => store.authentication.user?.profile?.package)

    useEffect(() => {
        const event = new Identify()

        event.set('user_plan', plan || '')
        identify(event)
    }, [plan])

    return (
        <div className={cn.sidebar}>
            <Flex column margin="110px 0 0 0" className={cn.links}>
                {links.map((item) => {
                    return (
                        <Flex
                            component={NavLink}
                            className={cn.link}
                            key={item.to}
                            to={item.to}
                            align="center"
                            style={{ marginBottom: '30px' }}
                        >
                            <Icons
                                name={item.icon as any}
                                className={checkActiveLink(item.active) ? cn['icon--active'] : cn.icon}
                            />

                            <Text
                                fSize={12}
                                semibold
                                className={checkActiveLink(item.active) ? cn['title--active'] : cn.title}
                            >
                                {t(item.title)}
                            </Text>

                            {item.isNew && <div className={cn.new}>New</div>}
                        </Flex>
                    )
                })}
            </Flex>

            {botLinks && botLinks.length > 0 ? (
                <Flex column margin="110px 0 0 0" className={cn['links--bottom']}>
                    <a href={botLinks[0].book_link}>
                        <Flex className={cn.link} align="center" style={margin}>
                            <Icons name={'basic-phone'} className={cn['icon--bottom']} />

                            <Text fSize={12} semibold className={cn['title--bottom']}>
                                {t('bot_menu.call')}
                            </Text>
                        </Flex>
                    </a>
                    <a href={botLinks[0].hp_sidebar}>
                        <Flex className={cn.link} align="center" style={margin}>
                            <Icons name={'message'} className={cn['icon--bottom']} />

                            <Text fSize={12} semibold className={cn['title--bottom']}>
                                {t('bot_menu.help')}
                            </Text>
                        </Flex>
                    </a>
                    <a href={'https://app.yoloco.io/tariffs'}>
                        <Flex className={cn.link} align="center" style={margin}>
                            <Icons name={'tag--outline'} style={tagStyle} className={cn['icon--bottom']} />

                            <Text fSize={12} semibold className={cn['title--bottom']}>
                                {t('bot_menu.plans')}
                            </Text>
                        </Flex>
                    </a>
                    <a href={`mailto: ${botLinks[0].email}`}>
                        <Flex className={cn.link} align="center" style={margin}>
                            <Icons name={'mail'} className={cn['icon--bottom']} />

                            <Text fSize={12} semibold className={cn['title--bottom']}>
                                {t('bot_menu.email')}
                            </Text>
                        </Flex>
                    </a>
                </Flex>
            ) : undefined}
        </div>
    )
}

export default memo(Sidebar)
