import { useState, useEffect, useCallback } from 'react'

type ReturnType<DataType> = [boolean, DataType | null, boolean, VoidFunction]

function usePromiseCall<DataType>(call: () => Promise<DataType>): ReturnType<DataType>

function usePromiseCall<DataType, Arguments>(
    call: (arg: Arguments) => Promise<DataType>,
    arg: Arguments
): ReturnType<DataType>

function usePromiseCall<DataType, Arguments = undefined>(
    call: (args?: Arguments) => Promise<DataType>,
    args?: Arguments
): ReturnType<DataType> {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<DataType | null>(null)
    const [error, setError] = useState<boolean>(false)
    const [counter, setCounter] = useState(0)
    const callBack = useCallback(call, [args])
    const reload = useCallback(() => {
        setCounter((p) => p + 1)
    }, [])

    useEffect(() => {
        setLoading(true)
        setError(false)
        let isMounted = true
        callBack(args)
            .then((res) => {
                if (!isMounted) return
                setData(res)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                if (!isMounted) return
                setError(true)
                setLoading(false)
            })
        return () => {
            isMounted = false
        }
    }, [call, args, counter, reload, callBack])

    return [loading, data, error, reload]
}

export default usePromiseCall
