import { Notification } from './Types'
import Observable from '@/_helpers/Observable'
import { ReadyType } from './MessageTypes'
import OnParsing from './MessageTypes/OnParsing'
import BloggerReady from './MessageTypes/BloggerReady'
import ParsingError from './MessageTypes/ParsingError'

export const System = new Observable<Notification>()

let last = 0

const getId = () => `system_notification_${last++}`

export const push = (Message: ReadyType) =>
    System.update({
        Message,
        postDate: Date.now(),
        id: getId(),
        type: 'SYSTEM_PUSH',
    })

if (process.env.NODE_ENV === 'development') {
    Object.defineProperty(window, 'notification', {
        value: {
            parsing: () => push(new OnParsing('buzova861111111111111111')),
            ready: () => push(new BloggerReady('buzova8611111111111111111111', 267685466)),
            error: () => push(new ParsingError('vovan_231211111111111', 'DOESNT_EXIST')),
        },
    })
}

/* console.log({
    parsing: () => push(new OnParsing("buzova861111111111111111")),
    ready: () => push(new BloggerReady("buzova8611111111111111111111", 267685466)),
    error: () => push(new ParsingError("vovan_231211111111111", "DOESNT_EXIST"))
}) */
