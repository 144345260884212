import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask
            id="mask0_8794_10538"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="70"
            height="70"
        >
            <rect width="70" height="70" rx="10" fill="#E2E6FF" />
        </mask>
        <g mask="url(#mask0_8794_10538)">
            <rect width="70" height="70" rx="10" fill="#D9DDFF" />
            <path
                d="M13.7801 27.6248L0 41.3538V70H78L47.5493 39.6623C46.7132 38.8292 45.3574 38.8292 44.5213 39.6623L38.2199 45.9403C37.3837 46.7734 36.028 46.7734 35.1918 45.9403L16.8082 27.6248C15.972 26.7917 14.6163 26.7917 13.7801 27.6248Z"
                fill="url(#paint0_linear_8794_10538)"
            />
            <circle cx="50" cy="19" r="6" fill="#A3ADFF" />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_8794_10538"
                x1="39"
                y1="45.0107"
                x2="39"
                y2="70"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#A3ADFF" />
                <stop offset="1" stopColor="#A6AFFF" stopOpacity="0.29" />
            </linearGradient>
        </defs>
    </svg>
)
