import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.99997 13.0942L15.0083 8.08584L13.8308 6.90667L9.99997 10.74L6.16997 6.90667L4.99164 8.085L9.99997 13.0942Z"
            fill="#333333"
        />
    </svg>
)
