export class CORSError extends Error {
    constructor(
        public user: {
            token: string | null
            email: string | null
        },
        public requestedUrl: string
    ) {
        super()
    }
}
