import React, { FC, memo, useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import useLikes from '@/pages/Lists/ListOfBloggers/useLikes'
import { GetBloggersCommentsReq } from '@/pages/Lists/model/service'
import useToken from '@/_helpers/Hooks/useToken'
import { SetAllBloggers, SetDefault } from '@/components/MassAddToList/_model/actions'
import { Platform } from '@/pages/Reports/types'

import { useAdapter, useSearch } from '../../SearchAdapter'
import NoResults from '../SearchItems/NoResults/NoResults'

import { FixedSizeList as List, ListOnScrollProps } from 'react-window'
import { Flex } from '@/_yc'
import Loader from '@/components/Loader'
import useWindowDimensions from '@/_helpers/Resize/useWindowDimensions' // Подключаем react-window

export interface IBloggerList {
    className?: string
    onReach: VoidFunction
    loading?: boolean
}

interface IComment {
    external_id: string
    created_at: string
    id: number
    text: string
}
const style = {
    zIndex: 1000,
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
}

const BloggerList: FC<IBloggerList> = (props) => {
    const token = useToken()
    const Adapter = useAdapter()
    const dispatch = useDispatch()
    const { width } = useWindowDimensions()

    const [commentsOfBloggers, setCommentsOfBloggers] = useState<IComment[]>()

    useEffect(() => {
        if (!token) return
        GetBloggersCommentsReq(token).then((res) => setCommentsOfBloggers(res))
    }, [token])

    const getCommentText = useCallback(
        (id: string) => {
            if (!commentsOfBloggers) return
            const temp = commentsOfBloggers.find((comment) => comment.external_id === id)
            return temp ? { comment: temp.text, comment_id: temp.id } : null
        },
        [commentsOfBloggers]
    )

    const likedBloggers: any = useLikes().reduce((acc: any, item: any) => {
        acc[item.external_id || item.blogger_id || item.channel_id || item.twitch_channel_id || item.vk_account_id] =
            item.id
        return acc
    }, {})

    const { restart, selected } = useSearch((state) => state)

    useEffect(() => {
        restart()
    }, [restart])

    useEffect(() => {
        const url = window.location.pathname
        const pl = url.includes(Platform.YouTube)
            ? Platform.YouTube
            : url.includes(Platform.Twitch)
            ? Platform.Twitch
            : url.includes(Platform.Vk)
            ? Platform.Vk
            : url.includes(Platform.Tg)
            ? Platform.Tg
            : url.includes(Platform.TikTok)
            ? Platform.TikTok
            : Platform.Instagram

        const externalIDs = selected.items.map((item) => ({
            id: item.id + '',
            externalId: item.id + '',
            name: item.fullName,
            avatar: item.avatar,
            username: item.username,
            hasEmail: item.has_email ?? item.hasEmail,
            platform: pl,
        }))

        dispatch(SetAllBloggers(externalIDs))
    }, [dispatch, selected.items])

    useEffect(() => {
        return () => {
            dispatch(SetDefault())
        }
    }, [dispatch])

    const searchResults = React.useMemo(() => {
        return selected.items.filter((item) => item.hiddenReportId === undefined && item.isHidden !== true)
    }, [selected.items])

    const handleScroll = ({ scrollDirection, scrollOffset }: ListOnScrollProps) => {
        if (scrollDirection === 'forward' && scrollOffset + 880 >= searchResults.length * 80) {
            props.onReach() // Вызов функции onReach
        }
    }
    const calculateItemHeight = (screenWidth: number) => {
        if (screenWidth < 744) {
            return 159
        } else if (screenWidth < 1280) {
            return 103
        } else {
            return 81
        }
    }

    const itemHeight = useMemo(() => calculateItemHeight(width), [width])

    const Row = ({ index, style }: { index: number; style: React.CSSProperties }) => {
        const item = searchResults[index]
        const elementHeight = style.height ? (style.height as number) - 1 : 80
        return (
            <div
                style={{
                    ...style,
                    height: elementHeight,
                    paddingBottom: '1px',
                    boxSizing: 'border-box',
                }}
            >
                <Adapter.Item
                    {...item}
                    index={index + 1}
                    likedId={likedBloggers[item.id.toString()]}
                    key={item.id + getCommentText(item.id + '') + likedBloggers[item.id.toString()]}
                    margin={'0 0 1px'}
                    comment={getCommentText(item.id + '')}
                />
            </div>
        )
    }

    return (
        <div style={{ position: 'relative' }} className={props.className}>
            {selected.items.length === 0 && !selected.loading ? (
                <NoResults />
            ) : (
                <>
                    <List
                        height={searchResults.length < 10 ? searchResults.length * itemHeight : 600}
                        itemCount={searchResults.length}
                        itemSize={itemHeight}
                        width={'100%'}
                        onScroll={handleScroll}
                    >
                        {Row}
                    </List>
                    {props.loading && (
                        <Flex style={searchResults.length ? style : undefined} content="center" margin="20px 0">
                            <Loader />
                        </Flex>
                    )}
                </>
            )}
        </div>
    )
}

export default memo(BloggerList)
