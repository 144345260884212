import React, { FC, useEffect, useMemo, useState } from 'react'
import cn from './step-five.module.scss'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import StepAi from './components/StepAi'
import { useTranslation } from 'react-i18next'
import ParamsList from './components/ParamsList'
import Button from '@/components/Button'
import { ReactComponent as ArrowIcon } from '../../icons/short_left.svg'
import { Option } from '@/pages/TargetAudience/Views/types'
import { AudienceInterestsResponse, Category, IAdditionalParams, Language } from '@/pages/TargetAudience/services/types'
import {
    fetchAllTargetData,
    fetchCampaignTargetsByPromptReq,
    saveReportHandler,
    updateCampaignReq,
} from '@/pages/TargetAudience/services/services'
import useToken from '@/_helpers/Hooks/useToken'
import {
    mapOptions,
    mapOptionsAdapter,
    transformAudienceData,
    transformDataByType,
    transformDataToOptions,
    transformInterests,
    updateInterestsLanguage,
    updateOptionsLanguage,
} from '@/pages/TargetAudience/utils'
import { initialInterests } from '@/pages/TargetAudience/Views/CreateReportStepFive/const'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import FinishModal from '@/pages/TargetAudience/Views/components/FinishModal'
import { useHistory, useLocation } from 'react-router-dom'
import EditComponentRenderer from '@/pages/TargetAudience/Views/CreateReportStepFive/components/EditComponentRenderer'

interface LocationState {
    gpt?: boolean
}

const Index: FC = () => {
    const { t } = useTranslation()
    const token = useToken()
    const { i18n } = useTranslation()
    const history = useHistory()
    const campaignId = parseInt(localStorage.getItem('campaignId') || '1')
    const location = useLocation<LocationState>()
    const gpt = location?.state?.gpt

    const prompt = useSelector<RootStore, string>((state) => state.targetAudience.campaign?.description)
    const enums = useSelector<RootStore, IAdditionalParams>((state) => state.targetAudience.enums)
    const interests = useSelector<RootStore, AudienceInterestsResponse>((state) => state.targetAudience.interests)
    const targetType = useSelector<RootStore, 'from_customer' | 'from_us'>(
        (state) => state.targetAudience.campaign?.target_type
    )
    const name = useSelector<RootStore, string>((state) => state.targetAudience.campaign?.name)

    const [selectedInterests, setSelectedInterests] = useState<Category[]>(initialInterests)

    const [selectedParams, setSelectedParams] = useState<{ [key: string]: Option[] }>({
        country: [],
        gender: [],
        ages: [],
        income: [],
    })

    const [selectedAdditional, setSelectedAdditional] = useState<{ [key: string]: Option[] }>({
        selectedAges: [],
        selectedLandlord: [],
        selectedDevices: [],
        selectedEmployment: [],
        selectedFamily: [],
        websites: [],
    })

    const [filters, setFilters] = useState({
        params: {} as { [key: string]: Option[] },
        interests: [] as Category[],
        additional: {} as { [key: string]: Option[] },
    })

    const [editType, setEditType] = useState<string | null>(null)
    const [showModal, setShowModal] = useState(false)

    const lang = useMemo(() => i18n.language.split('-')?.[0] as Language, [i18n.language])

    useEffect(() => {
        if (enums && interests) {
            setFilters({
                params: {
                    AgeGroup: mapOptions(enums.AgeGroup, lang),
                    GenderGroups: mapOptions(enums.GenderGroups, lang),
                    IncomesSize: mapOptions(enums.IncomesSize, lang),
                    Geo: [],
                },
                interests: transformAudienceData(interests, lang),
                additional: {
                    ChildrenAge: mapOptions(enums.ChildrenAge, lang),
                    LandlordType: mapOptions(enums.LandlordType, lang),
                    DeviceTypes: mapOptions(enums.DeviceTypes, lang),
                    OccupationType: mapOptions(enums.OccupationType, lang),
                    FamilyStatuses: mapOptions(enums.FamilyStatuses, lang),
                },
            })
        }
    }, [enums, interests, lang])

    useEffect(() => {
        const loadData = async () => {
            try {
                const targetData =
                    gpt && prompt
                        ? await fetchCampaignTargetsByPromptReq(token, prompt)
                        : await fetchAllTargetData(token, campaignId)

                if (targetData) transformDataForState(targetData)
            } catch (error) {
                console.error('Ошибка загрузки данных целевой аудитории:', error)
            }
        }

        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, campaignId, interests, gpt, prompt])

    useEffect(() => {
        setSelectedAdditional((prevParams) => ({
            selectedAges: updateOptionsLanguage(prevParams.selectedAges, filters.additional?.ChildrenAge || []),
            selectedLandlord: updateOptionsLanguage(
                prevParams.selectedLandlord,
                filters.additional?.LandlordType || []
            ),
            selectedDevices: updateOptionsLanguage(prevParams.selectedDevices, filters.additional?.DeviceTypes || []),
            selectedFamily: updateOptionsLanguage(prevParams.selectedFamily, filters.additional?.FamilyStatuses || []),
            selectedEmployment: updateOptionsLanguage(
                prevParams.selectedEmployment,
                filters.additional?.OccupationType || []
            ),
            websites: selectedAdditional.websites,
        }))
        setSelectedParams({
            country: updateOptionsLanguage(selectedParams.country, filters.params?.Geo || []),
            ages: updateOptionsLanguage(selectedParams.ages, filters.params?.AgeGroup || []),
            gender: updateOptionsLanguage(selectedParams.gender, filters.params?.GenderGroups || []),
            income: updateOptionsLanguage(selectedParams.income, filters.params?.IncomesSize || []),
        })

        setSelectedInterests(updateInterestsLanguage(selectedInterests, interests, lang as Language))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang, filters, interests])

    const transformDataForState = (data: any) => {
        if (data.interests && interests) {
            const transformedInterests = transformInterests(interests, data.interests, lang as Language)
            setSelectedInterests(transformedInterests)
        }

        setSelectedParams({
            country: transformDataToOptions(data.geo, lang as Language),
            ages: mapOptionsAdapter(data.ages, lang as Language),
            gender: mapOptionsAdapter(data.genders, lang as Language),
            income: mapOptionsAdapter(data.incomes, lang as Language),
        })

        setSelectedAdditional({
            selectedDevices: mapOptionsAdapter(data.devices, lang as Language),
            selectedLandlord: mapOptionsAdapter(data.landlords, lang as Language),
            selectedEmployment: mapOptionsAdapter(data.occupations, lang as Language),
            selectedFamily: mapOptionsAdapter(data.familyStatus, lang as Language),
            selectedAges: mapOptionsAdapter(data.childrenAge, lang as Language),
            websites: data?.websites?.map((website: string) => ({ label: website, value: website })) || [],
        })
    }

    const handleInterestsChange = (newInterests: Category[]) => {
        setSelectedInterests((prevState) => {
            const interestsMap = new Map()

            prevState.forEach((el) => interestsMap.set(el.type, { ...el }))

            newInterests.forEach((newItem) => {
                const existingItem = interestsMap.get(newItem.type)
                if (existingItem) {
                    interestsMap.set(newItem.type, { ...existingItem, values: newItem.values })
                } else {
                    interestsMap.set(newItem.type, newItem)
                }
            })

            if (newInterests.length === 0) {
                interestsMap.forEach((item, type) => {
                    item.values = []
                })
            }

            return Array.from(interestsMap.values())
        })
    }

    const handleAdditionalChange = (newAdditional: { [key: string]: Option[] }) => {
        setSelectedAdditional(newAdditional)
    }

    const changeParams = (data: { [key: string]: Option[] }) => {
        setSelectedParams(data)
    }

    function handleRemove(type: string, criterionIndex: number, valueIndex: number) {
        switch (type) {
            case 'interests':
                setSelectedInterests((prevInterests) => {
                    const updatedInterests = [...prevInterests]
                    updatedInterests[criterionIndex].values.splice(valueIndex, 1)
                    return updatedInterests
                })
                break
            case 'params':
                setSelectedParams((prevParams) => {
                    const updatedParams = { ...prevParams }
                    const paramType = Object.keys(updatedParams)[criterionIndex]
                    if (paramType) {
                        updatedParams[paramType].splice(valueIndex, 1)
                    }
                    return updatedParams
                })
                break
            case 'additional':
                setSelectedAdditional((prevAdditional) => {
                    const updatedAdditional = { ...prevAdditional }
                    const additionalType = Object.keys(updatedAdditional)[criterionIndex]
                    if (additionalType) {
                        updatedAdditional[additionalType].splice(valueIndex, 1)
                    }
                    return updatedAdditional
                })
                break
            default:
                break
        }
    }

    const submitHandler = async () => {
        setShowModal(false)
        history.push('/target/all')
    }

    const selectedData = {
        interests: selectedInterests,
        params: selectedParams,
        additional: selectedAdditional,
    }

    async function saveHandler(status: 'draft' | 'progress' | 'archived' | 'deleted') {
        setShowModal(true)
        await updateCampaignReq(token, campaignId, { status })

        await saveReportHandler({
            token,
            campaignId,
            selectedParams,
            selectedInterests,
            selectedAdditional,
        })
    }

    return (
        <Flex column>
            <Flex column className={cn['main']}>
                <Flex className={cn['content']}>
                    <Flex className={cn['block']}>
                        <StepAi
                            step={`5 ${t(`target_audience.of`)} 5`}
                            title={t('target_audience.final_check')}
                            description={t('target_audience.based_on_request')}
                            targetingType={targetType}
                            gptPrompt={prompt}
                            hide={!gpt}
                        />
                    </Flex>
                    <Flex column className={cn['block']}>
                        <Text component="h2" fSize={'18px'} semi color={'gray-new-4'}>
                            {t('target_audience.parameters')}
                        </Text>
                        {Object.entries(filters).map(([type, data], index) => (
                            <Flex key={type} column>
                                {editType !== type && (
                                    <ParamsList
                                        criteria={transformDataByType(
                                            type,
                                            selectedData[type as keyof typeof selectedData]
                                        )}
                                        onEdit={() => setEditType(type)}
                                        type={type}
                                        onRemove={(criterionIndex, valueIndex) =>
                                            handleRemove(type, criterionIndex, valueIndex)
                                        }
                                        reportName={name}
                                        showReportName={!index}
                                    />
                                )}
                                <EditComponentRenderer
                                    type={editType}
                                    data={data}
                                    selectedInterests={selectedInterests}
                                    selectedParams={selectedParams}
                                    selectedAdditional={selectedAdditional}
                                    onEdit={setEditType}
                                    onInterestsChange={handleInterestsChange}
                                    onParamsChange={changeParams}
                                    onAdditionalChange={handleAdditionalChange}
                                    hide={!(editType === type)}
                                />
                            </Flex>
                        ))}
                    </Flex>
                </Flex>
            </Flex>
            <Flex content="space-between" className={cn['footer']}>
                <Flex>
                    <Flex onClick={() => history.goBack()} align="center" style={{ cursor: 'pointer' }}>
                        <ArrowIcon className={cn['icon-prev']} />
                        <Text margin="0 0 0 8px" className={cn['text']}>
                            {t('target_audience.back')}
                        </Text>
                    </Flex>
                </Flex>
                <Flex style={{ gap: '16px' }}>
                    <Button onClick={() => saveHandler('draft')}>{t('target_audience.save_draft')}</Button>
                    <Button onClick={() => saveHandler('progress')} className={cn['btn-next']}>
                        {t('target_audience.start_analysis')}
                        <ArrowIcon className={cn['icon-next']} />
                    </Button>
                </Flex>
            </Flex>
            <FinishModal onClose={() => setShowModal(false)} onSubmit={submitHandler} isVisible={showModal} />
        </Flex>
    )
}

export default Index
