import { gql } from '@apollo/client'

export const GET_DOWNLOAD_LINK = gql`
    mutation M($graphqlQuery: String!, $dryRun: Boolean!, $postsCount: Int!) {
        exportExcel(dryRun: $dryRun, graphqlQuery: $graphqlQuery, postsCount: $postsCount) {
            result {
                ... on ServerError {
                    detail
                    __typename
                }
                ... on NotEnoughFundsError {
                    detail
                    __typename
                }
                ... on UnauthorizedError {
                    detail
                    __typename
                }
                ... on ExcelData {
                    price
                    downloadLink
                    __typename
                }
            }
        }
    }
`
