import React, { PureComponent } from 'react';
import pt from 'prop-types'

// import PromoCard from './PromoCard/'

import cn from './Commercial.module.scss'
// import IntercomChat from './IntercomChat';

export default class Commercial extends PureComponent {
	static get propTypes(){
		return {
			showCard: pt.bool,
			text: pt.string,
			hideCard: pt.func,
			loadCommercial: pt.func.isRequired
		}
	}
	componentDidMount(){
		this.props.loadCommercial();
	}
	componentDidUpdate(prevProps){
		if (!prevProps.showCard && this.props.showCard) 
			document.body.style.overflow = "hidden"

		if (prevProps.showCard && !this.props.showCard) 
			document.body.style.overflow = "unset"
	}
	render(){
		// const { showCard, text } = this.props;
		return (
			<div className = {cn.root}>
				{
					/* showCard &&
					<div className = {cn.cardholder}>
						<PromoCard text = {text} onClick={this.props.hideCard} />
					</div> */
				}
			</div>
		)
	}
}