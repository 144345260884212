import React from 'react'
import Text from '@/components/Text'

import cn from './ChatItem.module.scss'
import { getShortString } from '@/_helpers/strings/getShortString'
import Flex from '@/_yc/Flex'
import moment from 'moment'
import { InternalChatData } from '../../Chat/Chat'
import Avatar from '@/components/Avatar'

interface ChatProps extends InternalChatData {
    active: boolean
}

export const ChatItem = (props: ChatProps) => {
    const timeDiff = moment().diff(moment(props.time), 'days')

    const getFormattedTime = (time: string, diff: number) => {
        if (diff === 0) {
            return moment(time).format('HH:mm')
        } else if (diff === 1) {
            return 'Yesterday'
        } else {
            return moment(time).format('DD.MM.YY')
        }
    }

    const formattedLastMess = props.lastMessage.replace(/([^>\s])</g, '$1 &nbsp;<')

    return (
        <div className={`${cn.chatItem} ${props.active ? cn['chatItem--active'] : ''}`}>
            <div className={cn.avatarContainer}>
                <Avatar alt="AVA" src={props.avatar} aria-label="Аватарка" className={cn.avatar} />

                {props.isOnline && <div className={cn.online}></div>}
            </div>

            <div className={cn.content}>
                <Flex content="space-between" align="flex-end">
                    <Text semibold component="span">
                        {getShortString(props.bloggerName, 15)}
                    </Text>
                    <Text component="span" color="gray-2" fSize={12}>
                        {props.time ? getFormattedTime(props.time, timeDiff) : ''}
                    </Text>
                </Flex>
                <Flex>
                    <Text
                        fSize={12}
                        semibold={props.unreadCount > 0}
                        style={{
                            overflow: 'hidden',
                            height: '20px',
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            display: 'flex',
                        }}
                        dangerouslySetInnerHTML={{ __html: formattedLastMess }}
                    ></Text>
                    <Text fSize={12} semibold={props.unreadCount > 0}>
                        ...
                    </Text>
                </Flex>

                <Flex content="space-between">
                    <Text fSize={10} color="gray-2">
                        {getShortString(props.campaignName, 35)}
                    </Text>
                    {props.unreadCount > 0 && <div className={cn.unreadCount}>{props.unreadCount}</div>}
                </Flex>
            </div>
        </div>
    )
}
