import React, { PureComponent } from 'react';
import pt from 'prop-types'

import normalize from './normalize.js'

const PollingDelay = 5000;

export default (PullService, PushService, DeleteService, PutService) => Component => class extends PureComponent {
	static get displayName(){
		return `withItems(${Component.displayName || Component.name || "Component"})`
	}

	static propTypes = {
		loading: pt.func.isRequired,
		error: pt.func.isRequired,
		clear: pt.func.isRequired,
		updateMoney: pt.func.isRequired
	}

	initState = {
		loaded: false,
		error: false,
		value: []
	}

	state = this.initState

	mounted = true;
	line = 0;

	componentDidMount(){
		this.loadItems()
	}

	loadItems(){
		this.line++;
		const current = this.line;

		this.pull(current)
			.then(items => {
				if (!this.mounted || current !== this.line) return
				this.setState({
					loaded: true,
					value: normalize(items)
				})
				this.initPolling()
			})
			.catch(err => {
				console.log({ err })
				if (!this.mounted || current !== this.line) return
				this.setState({
					loaded: true,
					error: true
				})
			})
	}

	initPolling(){
		this.line++;
		const current = this.line;
		setTimeout(() => {
			if (current !== this.line) return
			this.loadItems();
		}, PollingDelay)
	}

	pull(limit, page = 1, items = []){
		return new Promise((resolve, reject) => {
			PullService(page)
				.then(res => {
					if (!res.next) {
						resolve(items.concat(res.results).sort((a, b) => b.id - a.id))
					}else{
						this.pull(limit, page + 1, items.concat(res.results))
							.then(resolve)
							.catch(reject)
					}
				})
				.catch(reject)
		})
	}

	addIntersection = (name, bloggers) => new Promise((resolve, reject) => {
		if (!name) return reject({ 
			response: {
				status: "empty_name"
			}
		})
		if (bloggers.length < 2) return reject({ 
			response: {
				status: "more"
			}
		})
		this.line++;
        this.setState({loaded: false})
		this.props.loading();
		PushService(name, bloggers)
			.then(() => {
				this.props.clear()
				this.setState(this.initState)
				this.loadItems()
				this.props.updateMoney()
				resolve();
			})
			.catch(err => {
				this.props.clear()
                this.setState({loaded: true})
				// this.props.error();
				reject(err)
			})
	})

	deleteIntersection = id => {
		this.line++;
		const backup = this.state.value;
		this.setState(prev => ({
			...prev,
			value: prev.value.filter(item => item.id !== id)
		}))
		DeleteService(id)
			.catch(err => {
				this.props.error();
				this.setState({
					value: backup
				})
			})
			.finally(() => this.initPolling())
	}

	renameIntersection = (id, name) => {
		if (!name) return

		this.line++;
		const backup = this.state.value;
		this.setState(prev => ({
			...prev,
			value: prev.value.map(item => {
				if (item.id !== id) return item;
				return {
					...item,
					name
				}
			})
		}))
		PutService(id, name)
			.catch(err => {
				this.props.error();
				this.setState({
					value: backup
				})
			})
			.finally(() => this.initPolling())
	}

	componentWillUnmount(){
		this.mounted = false;
	}

	render(){
		return (
			<Component  
				{...this.props}
				items = {this.state} 
				addItem = {this.addIntersection}
				deleteItem = {this.deleteIntersection}
				renameItem = {this.renameIntersection}
			/>
		)
	}
}