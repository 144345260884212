import React, { FC, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import useWindowWidth from '@/_helpers/Resize/useWindowWidth'

import { Platform } from '@/pages/Reports/types'
import { RootStore } from '@/_helpers/StoreType'
import Flex from '@/_yc/Flex'
import { shortNum } from '@/_helpers/_graphs/_tech'
import Topics from '@/pages/SearchPage/Bloggers/Topics/Topics'
import BloggerLink from '@/components/Share/BloggerLink'
import Button from '@/components/Button'
import { TARIFFS } from '@/routs'
import Avatar from '@/components/Avatar'
import Popup from '@/_yc/Popup/Popup'
import useOpenClose from '@/_helpers/useOpenClose'
import useToken from '@/_helpers/Hooks/useToken'
import AddToList from '../../components/AddToList/AddToList'

import { INormalListData } from '../../../model/types'
import MobilePopUp from './MobilePopUp'
import LoadBar from '../LoadBar/LoadBar'
import {
    AddToLikesReq,
    AddCommentToBloggerReq,
    DeleteBloggerFormListReq,
    CreateCommentToBloggerReq,
} from '@/pages/Lists/model/service'

import cn from './ListItem.module.scss'

interface IListItem {
    item: INormalListData
    index: number
}

const MobileListItem: FC<IListItem> = ({ item }) => {
    const { t } = useTranslation()
    const ref = useRef(null)
    const token = useToken()
    const [isOpen, open, close] = useOpenClose()
    const [isOpenEdit, openEdit, closeEdit] = useOpenClose()

    const [state, setState] = useState<'addToList' | 'comment'>('addToList')
    const [value, setValue] = useState('')

    const [currentComment, setCurrentComment] = useState<string | undefined>(item.comment?.comment)

    const isPromo = useSelector((store: RootStore) => store.authentication.user?.profile?.isPromo)
    const isDemo = isPromo && item.type === Platform.Instagram && !item.unlocked

    const size = useWindowWidth('mobile')
    const isMobile = size === 'mobile'

    const addToLike = () => {
        if (!token) return
        switch (item.type) {
            case Platform.Instagram: {
                if (!item.external_id) return
                AddToLikesReq(token, [item.external_id])
                break
            }
            case Platform.YouTube: {
                if (!item.channelId) return
                AddToLikesReq(token, [item.channelId])
                break
            }
            case Platform.Twitch: {
                if (!item.external_id) return
                AddToLikesReq(token, [item.external_id])
                break
            }
        }
        close()
    }

    const addComment = () => {
        if (!token) return

        if (item.comment?.comment_id)
            AddCommentToBloggerReq(token, value, item.external_id, item.comment.comment_id).then(() => {
                setCurrentComment(value)
            })
        else
            CreateCommentToBloggerReq(token, value, item.external_id).then(() => {
                setCurrentComment(value)
            })
        closeEdit()
        close()
    }

    const removeFromList = () => {
        if (!token) return
        DeleteBloggerFormListReq(token, item.bloggerId || item.id)
        close()
    }

    return (
        <>
            <div className={cn.item}>
                <Flex>
                    {/* Avatar */}
                    <Avatar
                        loadingAvatarWidth="70"
                        size={isMobile ? 70 : undefined}
                        alt="AVA"
                        src={item.avatar}
                        type={item.type}
                        aria-label="Аватарка"
                        className={cn.ava}
                    />

                    <Flex content={'space-between'} grow>
                        <Flex
                            column
                            content={!isMobile ? 'center' : ''}
                            margin={isMobile ? '0 0 0 12px' : '0 0 0 16px'}
                        >
                            <p className={cn.fullname}>{item.fullName ? item.fullName : item.userName}</p>
                            {currentComment && <p className={cn['text--comment']}>{currentComment}</p>}
                            {item.topics ? (
                                <Topics
                                    topics={typeof item.topics === 'string' ? [item.topics] : item.topics}
                                    size={12}
                                    fSize={10}
                                />
                            ) : (
                                <p className={cn.text}></p>
                            )}
                        </Flex>
                        <p className={cn.dots} onClick={open}>
                            <span ref={ref}>&bull;&bull;&bull;</span>
                        </p>
                    </Flex>
                </Flex>

                {item.statusPercent && item.statusPercent !== 1 ? (
                    <Flex style={{ gridColumn: '3/9' }} margin={'16px 0 0 0'}>
                        <LoadBar status={item.statusPercent} />
                    </Flex>
                ) : (
                    <>
                        <Flex grow content={'space-between'} margin={'16px 0 0 0'}>
                            <p className={cn.text}>
                                {shortNum(item.followers)}
                                <span className={cn['text--sub']}>{t('aud_insides.grid_header.followers')}</span>
                            </p>
                            <p className={cn.text}>
                                {item.er}
                                <span className={cn['text--sub']}>{t('aud_insides.grid_header.erEN')}</span>
                            </p>

                            <BloggerLink
                                newTab
                                id={item.external_id}
                                isDemo={isDemo}
                                platform={item.type}
                                failLink={`${TARIFFS}?error=error`}
                                className={`${cn[`list-item${item.unlocked ? '--unlocked--mobile' : '--locked'}`]} ${
                                    cn['mobile--gridThree']
                                }`}
                            >
                                <Button width="120px" size="small" variant={item.unlocked ? 'primary' : 'outline'}>
                                    <p className={cn['button-text']}>
                                        {item.unlocked
                                            ? t('aud_insides.found_blogger.analyze_button_label')
                                            : t('aud_insides.found_blogger.unlock')}
                                    </p>
                                </Button>
                            </BloggerLink>
                        </Flex>
                    </>
                )}
            </div>
            {isOpen && ref && ref.current && (
                <>
                    <Popup placement="bottom-end" isOpen={isOpen} onClose={close} anchor={ref.current}>
                        <Flex className={cn.popup} column>
                            <p onClick={addToLike}>{t('aud_insides.lists.addToFav')}</p>

                            <p
                                onClick={() => {
                                    openEdit()
                                    setState('addToList')
                                }}
                            >
                                {t('aud_insides.lists.addToList')}
                            </p>
                            <p
                                onClick={() => {
                                    openEdit()
                                    setState('comment')
                                }}
                            >
                                {t('aud_insides.comment.add')}
                            </p>
                            <p onClick={removeFromList}>{t('aud_insides.lists.remove_from')}</p>
                        </Flex>
                    </Popup>
                </>
            )}
            {isOpenEdit && (
                <>
                    <MobilePopUp
                        state={state}
                        isOpen={isOpenEdit}
                        close={closeEdit}
                        title={state === 'addToList' ? t('aud_insides.lists.addToList') : t('aud_insides.comment.add')}
                        submit={state === 'addToList' ? () => undefined : addComment}
                        value={value}
                        setValue={setValue}
                    />
                </>
            )}
            <AddToList
                place="aud_insights_side"
                type={item.type}
                useMobilePopup
                external_id={item.external_id}
                isMobileOpen={isOpenEdit && state === 'addToList'}
                onMobileClose={closeEdit}
            />
        </>
    )
}

export default MobileListItem
