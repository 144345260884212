import React, { PureComponent, ComponentType } from 'react'
import { Subtract } from 'utility-types'
import { Notification } from './Types'
import Observable, { Subscription } from '@/_helpers/Observable'
import { connect } from './WebSocketChannel/getWebSocket'

export interface IRequiredProps {
    token?: string
    UpdateNotifications: (token: string, from: Date) => Promise<Notification[]>

    SystemNotifications: Observable<Notification>

    RegisterNotifications: (count: number) => void
}

export interface IProvidedProps {
    items: Array<{
        visible: boolean
        notification: Notification
        isPrimary: boolean
    }>
    close: (id: number) => void
    onClear: VoidFunction
}

interface State extends IProvidedProps {}

const fromNToI = (notification: Notification) => ({
    visible: true,
    notification,
    isPrimary: true,
})

export default <Props extends IProvidedProps>() =>
    (Component: ComponentType<Props>) =>
        class withUpdates extends PureComponent<IRequiredProps & Subtract<Props, IProvidedProps>, State> {
            // Notifications pull
            state: State = {
                items: [],
                close: this.close.bind(this),
                onClear: this.clear.bind(this),
            }
            // active notifications limit
            count: number = 4
            mounted: boolean = true

            // sub on local system notifications such as input errors
            sub?: Subscription

            //ws subscription
            wsSub?: Subscription

            /** @deprecated */
            pollingSub?: Subscription

            componentDidUpdate(prevProps: IRequiredProps & Subtract<Props, IProvidedProps>) {
                if (prevProps.token !== this.props.token) {
                    if (this.wsSub) this.wsSub.unsubscribe()
                    if (this.props.token) {
                        this.wsSub = connect(this.props.token as string, (update) => {
                            if (!this.mounted) return
                            this.pushUpdates([update])
                        })
                    }
                }
            }

            componentDidMount() {
                //subs on ws events
                if (this.props.token) {
                    this.wsSub = connect(this.props.token as string, (update) => {
                        if (!this.mounted) return
                        this.pushUpdates([update])
                        this.props.RegisterNotifications(1)
                    })
                }
                // this.pollingSub = PlatformUpdates(this.update);
                this.sub = this.props.SystemNotifications.subscribe((notification) => {
                    if (!this.mounted) return

                    this.pushUpdates([notification])
                    // this.props.RegisterNotifications(1)
                })
            }

            // push updates to state;
            pushUpdates(update: Notification[]) {
                //exit on empty array
                if (update.length < 1) return

                //in order to show only 1 notification
                this.setState({
                    items: update.map(fromNToI),
                })
                // if new + old <= max -> just insert new notifications to state
                // if (this.state.items.length + update.length <= this.count) {
                //     this.setState({
                //         items: this.state.items
                //             .map((i) => ({ ...i, isPrimary: false }))
                //             .concat(update.reverse().map(fromNToI)),
                //     })

                //     // if update lte old state remove excess notifications and insert new
                // } else if (update.length <= this.count) {
                //     const dif = this.count - update.length
                //     if (dif === 0) this.setState({ items: update.reverse().map(fromNToI) })
                //     else
                //         this.setState({
                //             items: this.state.items
                //                 .slice(-1 * dif)
                //                 .map((i) => ({ ...i, isPrimary: false }))
                //                 .concat(update.reverse().map(fromNToI)),
                //         })
                //     // in all other cases just inserting normalized updates
                // } else {
                //     this.setState({
                //         items: update.slice(0, this.count).map(fromNToI),
                //     })
                // }
            }
            // close notification by id;
            close(id: number) {
                const index = this.state.items.findIndex((val) => val.notification.id === id)
                if (index === -1) return

                const items = this.state.items.slice(0)

                items[index] = {
                    ...items[index],
                    visible: false,
                }

                this.setState({ items })

                setTimeout(() => this.deleteItem(id), 500)
            }

            // deliting item from state
            deleteItem(id: number) {
                if (!this.mounted) return
                this.setState({
                    items: this.state.items.filter((n) => n.notification.id !== id),
                })
            }

            clear() {
                const include: (number | string)[] = []

                this.setState(
                    (prev) => ({
                        ...prev,
                        items: prev.items.map((item) => {
                            include.push(item.notification.id)
                            return {
                                ...item,
                                visible: false,
                            }
                        }),
                    }),
                    () => {
                        setTimeout(() => {
                            if (!this.mounted) return
                            this.setState((prev) => ({
                                ...prev,
                                items: prev.items.filter((item) => !include.includes(item.notification.id)),
                            }))
                        }, 500)
                    }
                )
            }

            componentWillUnmount() {
                this.mounted = false
                this.wsSub && this.wsSub.unsubscribe()
                if (this.sub) this.sub.unsubscribe()
                if (this.pollingSub) this.pollingSub.unsubscribe()
            }

            render() {
                const {
                    UpdateNotifications,
                    token,
                    SystemNotifications,
                    RegisterNotifications,
                    ...rest
                }: IRequiredProps = this.props

                return <Component {...this.state} {...(rest as Props)} />
            }
        }
