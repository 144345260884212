import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { GlobalWidth } from '@/_yc'
import React, { FC } from 'react'
import cn from './Header.module.scss'
import { FiltersPanel } from './FiltersPanel/FiltersPanel'
import { PlatformChanger } from './PlatformChanger/PlatformChanger'
import Flex from '@/_yc/Flex'
import { DownloadExcel } from './DownloadExcel/DownloadExcel'
import { useSelector } from 'react-redux'
import PageTitle from '@/components/PageTitle'
interface Header {}

export const Header: FC<Header> = () => {
    const t = useSpaceTranslation('top_menu')
    const linkToHelper = useSelector<any, string>((state) => state.Commercial_.value.hp_mplanner)

    return (
        <GlobalWidth fixedWidth>
            <div className={cn.FiltersBlock}>
                <PageTitle title={t('p4')} linkToHelper={linkToHelper} icon="ads-explorer" iconSize={'25px'} />

                <Flex content={'space-between'}>
                    <PlatformChanger />
                    <DownloadExcel />
                </Flex>
                <FiltersPanel />
            </div>
        </GlobalWidth>
    )
}
