import React, { FC, useCallback, useContext, useState } from 'react'
import Popup from '@/_yc/Popup/Popup'
import Text from '@/components/Text'
import cn from './MassAddPopup.module.scss'
import { BloggerArea, Flex } from '@/_yc'
import { Platform } from '@/pages/Reports/types'
import { useTranslation } from 'react-i18next'
import Button from '@/components/Button'
import { IParsedBlogger } from '@/components/MassParsingNEW/types'
import Icons from '@/components/Icons'
import notif from '@/components/Notifier/model/actions.js'
import { useDispatch } from 'react-redux'
import { parseBloggerInput } from '@/_helpers/getNames'
import LoadingButton from '@/components/Share/LoadingButton'
import { getExternalIds } from '@/components/MassParsingNEW/ParsingRequest'
import useToken from '@/_helpers/Hooks/useToken'
import { CampaignManagerContext } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerContext'
import { useCampaignId } from '@/_helpers/Hooks/useCampaignId'
import { useLazyQuery, useMutation } from '@apollo/client'
import { ADD_INFLUENCER, FIND_YOUTUBE_CHANNELS_IDS, GET_MEDIA_PLAN } from '../utils/queries'

interface MassAddPopupProps {
    title: string
    subtitle: string
    isOpen: boolean
    onClose: () => void
    refetchBloggers: () => void
}

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 500,
    zIndex: 120,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}

export const MassAddPopup: FC<MassAddPopupProps> = ({ title, subtitle, isOpen, onClose, refetchBloggers }) => {
    const { t } = useTranslation()
    const [curPlatform, setCurPlatform] = useState<Platform>(Platform.Instagram)
    const [uniqueBloggers] = useState<Set<string>>(new Set<string>())
    const [bloggers, setBloggers] = useState<IParsedBlogger[]>([])

    const context = useContext(CampaignManagerContext)
    const id = useCampaignId()

    const [addInfluencers, { loading }] = useMutation(ADD_INFLUENCER, {
        context: context,
        refetchQueries: [GET_MEDIA_PLAN, 'GetMediaPlan'],
        onCompleted: () => {
            refetchBloggers()
            onClose()
        },
    })

    const [searchChannelsByTitle] = useLazyQuery<any>(FIND_YOUTUBE_CHANNELS_IDS, {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        onCompleted: (data) => {
            if (data.youtube_channel) {
                const ids = data.youtube_channel.map((channel: any) => channel.id)
                addInfluencers({
                    variables: {
                        orderId: id,
                        bloggers: ids,
                        bloggersType: 'YOUTUBE',
                    },
                })
            }
        },
    })

    const token = useToken()

    const dispatch = useDispatch()

    const platformButton = [
        {
            icon: 'instagram',
            pl: Platform.Instagram,
            text: t('aud_insides.mass_parsing.inst'),
        },
        {
            icon: 'yt',
            pl: Platform.YouTube,
            text: t('aud_insides.mass_parsing.yt'),
        },
        {
            icon: 'ie-twitch',
            pl: Platform.Twitch,
            text: t('aud_insides.mass_parsing.tw'),
        },
    ]

    const clearState = () => {
        setBloggers([])
        uniqueBloggers.clear()
    }
    const changePlatform = (platform: Platform) => {
        setCurPlatform(platform)
        clearState()
    }

    const handleDelete = useCallback(
        (index: string) => {
            uniqueBloggers.delete(index)
            setBloggers((prev) => prev.filter((blogger) => blogger.index !== index))
        },
        [uniqueBloggers]
    )

    const parseInputBloggers = useCallback(
        (inputBloggers: string[]) => {
            const parsedBloggers: IParsedBlogger[] = []

            for (let blogger of inputBloggers) {
                const { name, type } = parseBloggerInput(blogger, curPlatform)

                if (type === 'yt_username') {
                    if (curPlatform !== Platform.YouTube) {
                        //checks cases, when there is yt_username on inst or twitch page
                        dispatch(notif.info(t('aud_insides.status.only_platform_WARN', { platform: curPlatform })))
                        continue
                    }
                } else if (type !== curPlatform) {
                    dispatch(notif.info(t('aud_insides.status.only_platform_WARN', { platform: curPlatform })))
                    continue
                }

                if (uniqueBloggers.has(name)) continue
                parsedBloggers.push({
                    label: blogger,
                    index: name,
                    type: type,
                    onClick: handleDelete,
                })

                uniqueBloggers.add(name)
            }
            return parsedBloggers
        },
        [uniqueBloggers, handleDelete, curPlatform, dispatch, t]
    )

    const separateBloggers = (inputBloggers: string[]) => {
        let separatedBloggers: string[] = []

        for (let blogger of inputBloggers) {
            separatedBloggers = separatedBloggers.concat(blogger.split(/[,;\t]/))
        }

        return separatedBloggers.filter((blogger) => blogger !== '')
    }

    const changeBloggers = (inputBloggers: string[]) => {
        const separatedBloggers = separateBloggers(inputBloggers)
        const parsedBloggers: IParsedBlogger[] = parseInputBloggers(separatedBloggers)
        const numOfBloggers = 200 - bloggers.length
        setBloggers((prev) => prev.concat(parsedBloggers.slice(0, numOfBloggers)))
    }

    const addBloggers = useCallback(() => {
        console.log('add')
        if (!token) return
        const instagramBloggers = bloggers
            .filter((blogger) => blogger.type === Platform.Instagram)
            .map((blogger) => blogger.index)

        if (instagramBloggers.length > 0) {
            getExternalIds(token, instagramBloggers).then((res: any) => {
                // const externalIds = res.data.data
                const ids = res.instagram_external_ids
                if (!ids || ids.length === 0) return

                addInfluencers({
                    variables: {
                        orderId: id,
                        bloggers: ids,
                        bloggersType: 'INSTAGRAM',
                    },
                })
            })
        }

        const youtubeBloggers = bloggers
            .filter((blogger) => blogger.type === Platform.YouTube)
            .map((blogger) => blogger.index)
        console.log(youtubeBloggers)

        if (youtubeBloggers.length > 0) {
            addInfluencers({
                variables: {
                    orderId: id,
                    bloggers: youtubeBloggers,
                    bloggersType: 'YOUTUBE',
                },
            })
        }

        const youtubeUsernameBloggers = bloggers
            .filter((blogger) => blogger.type === 'yt_username')
            .map((blogger) => `@${blogger.index.toLowerCase()}`)

        console.log(youtubeUsernameBloggers)

        if (youtubeUsernameBloggers.length > 0) {
            searchChannelsByTitle({
                variables: {
                    channelIds: youtubeUsernameBloggers,
                },
            })
        }
    }, [bloggers, addInfluencers, id, token, searchChannelsByTitle])

    return (
        <div className={cn.root}>
            <Popup style={style} isOpen={isOpen} onClose={onClose}>
                <div className={cn.popup}>
                    <Text fSize={20}>{title}</Text>

                    <Text fSize={14} color="gray-3" margin="10px 0 10px 0">
                        {subtitle}
                    </Text>

                    <>
                        <Flex content={'space-between'} className={cn.platforms}>
                            {platformButton.map((item) => (
                                <Button
                                    onClick={() => changePlatform(item.pl)}
                                    variant={curPlatform === item.pl ? 'primary' : 'outline'}
                                >
                                    <Flex align="center" content="center">
                                        <Icons
                                            margin="0 8px 0 0"
                                            name={item.icon as any}
                                            className={cn[`platforms--icon${curPlatform === item.pl ? '-active' : ''}`]}
                                        />
                                        {item.text}
                                    </Flex>
                                </Button>
                            ))}
                        </Flex>
                    </>

                    <div style={{ marginTop: '16px' }}>
                        <BloggerArea value={bloggers} onChange={changeBloggers} limit={200} />
                    </div>

                    <Flex content="flex-end" margin="20px 0 0 0">
                        <LoadingButton
                            noDownloadIcon
                            onClick={addBloggers}
                            width={'224px'}
                            variant={'primary'}
                            loading={loading}
                            // disabled={!curListItem}
                        >
                            {t('aud_insides.mass_parsing.add')}
                        </LoadingButton>

                        {/* <Button onClick={close} width={'156px'} variant={'outline'}>
                            {t('aud_insides.mass_parsing.cancel')}
                        </Button> */}
                    </Flex>
                </div>
            </Popup>
        </div>
    )
}
