import { NativeSubscription } from '@/Reponses'
import Service from '@/_helpers/Service'
import i18n from 'i18next'
import { PaymentResponse } from '@/pages/BillingPage/PaymentList/PaymentList'

const GetCurrentTariffService = new Service<NativeSubscription>('/api/subscriptions/current/')

export const getCurrentTariff = (token: string) =>
    GetCurrentTariffService.call(
        {
            headers: { 'accept-language': i18n.language },
        },
        token
    )

const UnsubscribeService = new Service({
    method: 'PATCH',
    url: '/api/subscription_settings/id/',
})

//works in both ways (sub and unsub)
export const tariffUnsubscribe = (
    token: string,
    subscriptionId: number,
    tariff: any,
    recurring_payment_option: boolean
) =>
    UnsubscribeService.call(
        {
            url: `/api/subscription_settings/${subscriptionId}/`,
            data: {
                tariff: tariff,
                recurring_payment_enabled: recurring_payment_option,
            },
        },
        token
    )

const RequestService = new Service<{
    first_name: string
    company: null
    email: string
    phone: string
    demo_type: string
}>({
    method: 'POST',
    url: '/api/subscription_settings/id/',
})

export const createRequest = (first_name: string, email: string, phone: string, token: string) =>
    RequestService.call(
        {
            url: 'api/requests/new/',
            data: {
                demo_type: 'platform',
                first_name,
                email,
                phone,
            },
        },
        token
    )

const GetListRequest = new Service<PaymentResponse>('/api/payments/all/list/')

export const getList = (token: string, page: number) =>
    GetListRequest.call(
        {
            url: `/api/payments/all/list/?page=${page}`,
            headers: { 'accept-language': i18n.language },
        },
        token
    )
