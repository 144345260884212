import React, { FC, useRef, useCallback, memo } from 'react'
import { useSelector } from 'react-redux'

import cn from './NotificationBell.module.scss'
import List from './List'
import useOpenClose from '@/_helpers/useOpenClose'
import Flex from '@/_yc/Flex'
import Popup from '@/_yc/Popup'
import { RootStore } from '@/_helpers/StoreType'
import useNotifications from './useNotifications'
import Icons from '@/components/Icons'
import Button from '@/components/Button'

export interface INotificationBell {
    margin: string | number
}

const style = { zIndex: 400, margin: '4px 50px 0 0' }

const NotificationBell: FC<INotificationBell> = (props) => {
    const ref = useRef<HTMLDivElement>(null)

    const [state, setOpen, setClose] = useOpenClose()
    const token = useSelector<RootStore, string | undefined>((store) => store.authentication.user?.token)
    const { hasNotifications, clean } = useNotifications(token)

    const open = useCallback(() => {
        setOpen()
        clean()
    }, [setOpen, clean])

    const close = useCallback(() => {
        setClose()
        clean()
    }, [setClose, clean])

    return (
        <>
            <Flex
                component={Button}
                align="center"
                content="center"
                variant="transparent"
                className={cn.chip}
                width={40}
                size="small"
                margin={props.margin}
                ref={ref}
                onClick={open}
            >
                {hasNotifications && <div className={cn.indicator} />}
                <Icons name="bell" className={cn['icon--white']} />
            </Flex>
            <Popup style={style} anchor={ref.current} isOpen={state} onClose={close} placement={'bottom'}>
                <div>
                    <List onClose={close} />
                </div>
            </Popup>
        </>
    )
}

export default memo(NotificationBell)
