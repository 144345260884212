import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import Avatar from '@/Layouts/ReportLayout/Content/Avatar/Avatar'
import MainInfo from '@/Layouts/ReportLayout/Content/MainInfo/MainInfo'
import Metrics from '@/Layouts/ReportLayout/Content/Metrics/Metrics'
import { useSpaceTranslation } from '@/_helpers'
import { RootStore } from '@/_helpers/StoreType'
import Flex from '@/_yc/Flex'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'

import { Platform } from '../../types'
import { GeneralInfo } from '../../BloggerReport/Title/GeneralInfo/GeneralInfo'

import cn from './ReportHeader.module.scss'

interface IReportHeader {}
export const ReportHeader: FC<IReportHeader> = () => {
    const t = useSpaceTranslation('channel_report')

    const metrics = useSelector((store: RootStore) => {
        const metrics = store.report.rawTikTokData

        if (!metrics) {
            return {
                name: '',
                verified: false,
                link: '',
                topics: [],
                avatar: '',
            }
        }

        // let daysAgo = null
        // if (metrics.report_info.profile_updated) {
        //     const lastVideoDate = new Date(metrics.report_info.profile_updated)
        //     daysAgo = Math.floor((new Date().getTime() - lastVideoDate.getTime()) / (1000 * 3600 * 24))
        // }

        return {
            name: metrics.user_profile.fullname,
            username: metrics.user_profile.username,
            verified: metrics.user_profile.is_verified,
            link: metrics.user_profile.url,
            topics: [],
            avatar: metrics.user_profile.picture,
            country: metrics.user_profile.geo?.country?.name,
            email:
                metrics.user_profile.contacts && metrics.user_profile.contacts.length > 1
                    ? metrics.user_profile.contacts[1].value
                    : '',
            lastUpdated: moment(metrics.report_info.profile_updated).format('DD.MM.YYYY'),
            isUpdating: metrics.report_info.profil_is_updating,
        }
    })

    const restOfData = useSelector((store: RootStore) => {
        const metrics = store.report.rawTikTokData

        if (!metrics) {
            return []
        }

        // const publDate = new Date(metrics.last_publication_date)
        // const publDateString =
        //     publDate.getDay() + ' ' + getMonth(publDate.getMonth(), getLang()) + ' ' + publDate.getFullYear()
        const rest = [
            {
                value: (metrics.user_profile.engagement_rate * 100).toFixed(2) + '%',
                name: t('metrics.er'),
            },
            {
                value: metrics.user_profile.avg_views,
                name: t('metrics.avg_views'),
            },
            {
                value: metrics.user_profile.avg_likes,
                name: t('metrics.avg_likes'),
            },
            {
                value: metrics.user_profile.avg_comments,
                name: t('metrics.avg_comments'),
            },
            {
                value: metrics.user_profile.posts_count,
                name: t('metrics.posts_count'),
            },
        ]

        if (metrics.user_profile.avg_saves)
            rest.push({ value: metrics.user_profile.avg_saves, name: t('metrics.avg_saves') })
        if (metrics.user_profile.avg_shares)
            rest.push({ value: metrics.user_profile.avg_shares, name: t('metrics.avg_shares') })

        return rest
    })

    const size = useWindowWidth('mobile')
    const isMobile = size === 'mobile'

    return (
        <div className={cn.root}>
            <GeneralInfo
                country={!isMobile ? metrics.country : undefined}
                // email={!isMobile ? metrics.email : undefined}
                updatedDate={metrics.lastUpdated + ''}
                inProgress={metrics.isUpdating}
                isDemo={false}
            />
            <div className={cn.header}>
                <Flex content="flex-start">
                    <Avatar type={Platform.TikTok} url={metrics.avatar} />
                    <MainInfo
                        data={{
                            name: metrics.name,
                            verified: metrics.verified,
                            link: metrics.link,
                            topics: [],
                            username: metrics.username,
                        }}
                    />
                </Flex>

                <Metrics restOfData={restOfData} grid={true} />
            </div>
        </div>
    )
}
