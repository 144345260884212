import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.50008 0.5C4.1989 0.5 2.33341 2.36548 2.33341 4.66667C2.33341 6.96785 4.1989 8.83333 6.50008 8.83333C8.80127 8.83333 10.6667 6.96785 10.6667 4.66667C10.6667 2.36548 8.80127 0.5 6.50008 0.5ZM4.00008 4.66667C4.00008 3.28595 5.11937 2.16667 6.50008 2.16667C7.88079 2.16667 9.00008 3.28595 9.00008 4.66667C9.00008 6.04738 7.88079 7.16667 6.50008 7.16667C5.11937 7.16667 4.00008 6.04738 4.00008 4.66667Z"
            fill="#8F8F8F"
        />
        <path
            d="M13.0904 4.84857C12.856 4.72903 12.5966 4.66672 12.3334 4.66672V3.00005C12.8597 3.00005 13.3786 3.12464 13.8475 3.36371C13.8991 3.39004 13.95 3.41768 14.0001 3.44658C14.4046 3.68011 14.7561 3.99641 15.0312 4.37546C15.3403 4.80143 15.5442 5.29456 15.6261 5.81447C15.708 6.33439 15.6657 6.86631 15.5025 7.36671C15.3394 7.86711 15.06 8.32176 14.6873 8.69344C14.3147 9.06513 13.8593 9.34328 13.3585 9.50514C12.9128 9.64917 12.4424 9.69746 11.978 9.64767C11.9206 9.64151 11.8632 9.63384 11.8059 9.62467C11.2866 9.54143 10.7943 9.33648 10.3694 9.02658L10.3685 9.02594L11.3509 7.67964C11.5635 7.83476 11.8098 7.93731 12.0697 7.97895C12.3295 8.02059 12.5955 8.00011 12.8459 7.91919C13.0963 7.83826 13.324 7.69919 13.5103 7.51335C13.6967 7.32752 13.8363 7.1002 13.9179 6.85001C13.9995 6.59981 14.0207 6.33386 13.9797 6.07391C13.9388 5.81396 13.8368 5.56741 13.6822 5.35443C13.5277 5.14145 13.3249 4.9681 13.0904 4.84857Z"
            fill="#8F8F8F"
        />
        <path
            d="M15.6652 15.5C15.6652 15.0625 15.579 14.6292 15.4116 14.225C15.2441 13.8208 14.9987 13.4535 14.6893 13.1441C14.3799 12.8347 14.0126 12.5893 13.6084 12.4219C13.2042 12.2544 12.7709 12.1682 12.3334 12.1682V10.5C12.9018 10.5 13.4654 10.5969 14.0001 10.786C14.0831 10.8153 14.1653 10.8468 14.2468 10.8806C14.8535 11.1319 15.4047 11.5002 15.8689 11.9645C16.3332 12.4288 16.7015 12.98 16.9528 13.5866C16.9866 13.6681 17.0181 13.7504 17.0475 13.8333C17.2365 14.368 17.3334 14.9316 17.3334 15.5H15.6652Z"
            fill="#8F8F8F"
        />
        <path
            d="M12.3334 15.5H10.6667C10.6667 13.1988 8.80127 11.3333 6.50008 11.3333C4.1989 11.3333 2.33341 13.1988 2.33341 15.5H0.666748C0.666748 12.2783 3.27842 9.66667 6.50008 9.66667C9.72174 9.66667 12.3334 12.2783 12.3334 15.5Z"
            fill="#8F8F8F"
        />
    </svg>
)
