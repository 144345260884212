import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import GlobalWidth from '@/_yc/GlobalWidth'
import React from 'react'

import Text from '@/components/Text'

import { useSelector } from 'react-redux'
import PageTitle from '@/components/PageTitle'
import { CreateNewCampaign } from './CreateNewCampaign'

import cn from './Header.module.scss'

export const Header = () => {
    const t = useSpaceTranslation('campaign_manager.header')

    const linkToHelper = useSelector<any, string>((state) => state.Commercial_.value.hp_cmanager)
    return (
        <>
            <GlobalWidth>
                <div className={cn.header} style={{ justifyContent: 'space-between' }}>
                    <div className={cn.heading}>
                        <PageTitle
                            title={t('title')}
                            linkToHelper={linkToHelper}
                            icon="campaign-manager"
                            iconSize="25px"
                            iconStyle={{ background: '#F4C79E' }}
                        >
                            <Text color={'gray-3'} fSize={14} margin={'0 0 0 4px'}>
                                {t('desc')}
                            </Text>
                        </PageTitle>
                    </div>
                    <CreateNewCampaign />
                </div>
            </GlobalWidth>
        </>
    )
}
