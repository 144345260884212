import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.34065 12.0133L1.33398 8.00667L5.34065 4L6.28398 4.94267L3.21732 8.00933L6.28398 11.076L5.34132 12.0133H5.34065ZM10.66 12.0133L9.71798 11.0713L12.7846 8.00467L9.71798 4.938L10.6606 4L14.6673 8.00667L10.6606 12.0133H10.66Z"
            fill="#5D697D"
        />
    </svg>
)
