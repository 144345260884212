import React, { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next'
import pt from 'prop-types'
import { Link } from 'react-router-dom'

import Menu, { IMenuItem } from './Menu'
import { Avatar, Flex } from '@/_yc/'
import RenamingInput from './RenamingInput'
import { INTERSECTIONS } from '@/routs.js'
import Text from "@/components/Text";

import cn from './IntersectionCard.module.scss'
import useClass from '@/_helpers/Hooks/useClass';
import useOpenClose from '@/_helpers/useOpenClose';

const mark = Math.random() + "Al:APFa";

const avatarStyle = {
	borderRadius: "50%",
	overflow: 'hidden',
	marginLeft: -14,
	border: "1px solid #fff",
	heigth: 48,
	width: 48
}

const loadingStyle = {
	marginLeft: -14,
	heigth: 48,
	width: 48
}

const IntersectionCard = props => {

	const { onRenamed, id } = props;

	const { t } = useTranslation();

	const [menuIsOpen, open, close] = useOpenClose();

	const rename = useCallback(
		(value) => {
			props.onRenamed(props.id, value)
		}, 
		// eslint-disable-next-line
		[onRenamed, id]
	)

	const click = useCallback(e => {
		if (e.target.getAttribute("data-mark") === mark || props.inProcess) {
			e.preventDefault()
		}
		// eslint-disable-next-line
	}, [props.inProcess, menuIsOpen])

	const root = useClass(
		props.inProcess ? cn["root--default"] : cn["root--pointer"],
		props.renaming && cn.absolute
	)

	return (
		<div style={{ width: "100%", height: "100%", position: "relative" }}>
			{props.renaming && <div className={cn.bg} />}
			<Flex
				column
				component={Link}
				onClick = {click} 
				to = {`${INTERSECTIONS}/${props.id}/`} 
				className = {root}
			>
				<Flex 
					margin={props.renaming ? "0 0 12px" : "0 0 16px"}
					align="center" 
					content={props.inProcess || (!props.inProcess && !props.menu) ? "flex-start" : "space-between"}
					className = {props.inProcess ? cn["hat--in-process"] : cn["hat--default"]}
				>
					{
						props.renaming ? 
						<RenamingInput initValue = {props.name} onSuccess = {rename} onCancel = {props.onCancel}/>:
						<Fragment>
							<Text fSize={18} component="h4" semibold={false}>{props.name}</Text>
							{
								props.inProcess 
								? <div className = {cn.process}>{t("intersections.dashboards.card.inProcess")}</div>
								: props.menu
								? <Menu 
									items = {props.menu}
									id = {props.id}
									mark = {mark}
									onOpen = {open}
									onClose = {close}
								/>
								: (
									<Text margin="0 0 0 10px" className={cn.demo_mark} semibold fSize={12} color="white">
										Demo
									</Text>
								)
							}
						</Fragment>
					}
				</Flex>
				<div className = {cn.bloggers}>
					{props.bloggers.map((src, i) => (
						<Avatar 
							key = {i} 
							src = {src}
							style = {avatarStyle}
							loadingStyle = {loadingStyle}
						/>
					))}
				</div>
				<Text fSize={12} color="gray-3" >
					{props.names}
				</Text>
				<Flex grow content="space-between" align="flex-end">
					<Text color="gray-4" fSize={12} >{props.date}</Text>
					<Text color="violet-2" fSize={12} >{props.counter}</Text>
				</Flex>
			</Flex>
		</div>
	)
}

export const IIntersectionCard = {
	name: pt.string.isRequired,
	bloggers: pt.arrayOf(pt.string).isRequired,
	date: pt.string.isRequired,
	menu: pt.arrayOf(IMenuItem),
	counter: pt.string.isRequired,
	names: pt.string.isRequired,
	id: pt.number.isRequired,

	renaming: pt.bool,
	onRenamed: pt.func.isRequired,
	onCancel: pt.func.isRequired
}

IntersectionCard.propTypes = IIntersectionCard;

export { IntersectionCard as default }