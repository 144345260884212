import React, { FC } from 'react'

import Popup from '@/_yc/Popup'
import Button from '@/components/Button'
import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'
import Portal from '@/_helpers/Portal'

import cn from './ApprovePopup.module.scss'

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 550,
    zIndex: 2002,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}

interface IApprovePopup {
    isOpen: boolean
    close: () => void
    approveFunction: Function
    title: string
    subTitle: string
    approve: string
    cancel?: string
}

const ApprovePopup: FC<IApprovePopup> = ({ isOpen, close, approveFunction, title, subTitle, approve, cancel }) => {
    return (
        <>
            {isOpen && (
                <Portal>
                    <div className={cn.root}>
                        <Popup style={style} isOpen={isOpen} onClose={close}>
                            <div className={cn.popup}>
                                <Flex content="space-between" align="center">
                                    <p className={cn['title']}>{title}</p>
                                    <Icons onClick={close} name="close" className={cn.icon} />
                                </Flex>

                                <p className={cn['title-sub']}>{subTitle}</p>
                                <div className={cn.line} />
                                <Flex content={cancel ? 'space-between' : 'flex-end'}>
                                    <Button
                                        variant="primary"
                                        width="220px"
                                        onClick={() => {
                                            approveFunction()
                                            close()
                                        }}
                                    >
                                        {approve}
                                    </Button>
                                    {cancel && (
                                        <Button width="220px" onClick={close}>
                                            {cancel}
                                        </Button>
                                    )}
                                </Flex>
                            </div>
                        </Popup>
                    </div>
                </Portal>
            )}
        </>
    )
}

export default ApprovePopup
