import Service from '@/_helpers/Service'
import { ICampaign } from './types'
const GetCampaignsList = new Service<ICampaign[]>({
    baseUrl: process.env.REACT_APP_CAMPAIGN_MANAGER,
    method: 'GET',
    url: '',
})
export const GetCampaignsListReq = (token: string) =>
    GetCampaignsList.call(
        {
            url: `/api/campaigns/`,
        },
        token
    )
