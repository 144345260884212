import React, { FC, memo } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
// import { LockButton } from '@/_yc'
import { RootStore } from '@/_helpers/StoreType'
import { useAsyncCallback } from '@/_helpers/Hooks/useAsyncCallback'
// import LoadingButton from '@/components/Share/LoadingButton/LoadingButton'
import useToken from '@/_helpers/Hooks/useToken'
import { INTERSECTIONS_CREATE } from '@/routs'
import {
    // getAllBloggersFromList,
    getListById,
} from '@/Services/Lists'
import { useLocked } from './useLocked'
import Icons from '@/components/Icons'
import Flex from '@/_yc/Flex'

import cn from './IntersectList.module.scss'

export interface IIntersectList {
    margin?: string | number
}

const IntersectList: FC<IIntersectList> = (props) => {
    const t = useSpaceTranslation('aud_insides')

    const { isLocked } = useLocked()

    const currentSet = useSelector((store: RootStore) => store.AudienceInsights.ActiveListId)

    const token = useToken()

    const history = useHistory()

    const disabled = currentSet === -1
    const checkedBloggers = useSelector((store: RootStore) => store.MassAddToList.dataINST)

    const [intersect] = useAsyncCallback(
        async (state) => {
            if (!token) return
            const [list] = await Promise.all([getListById(token, +currentSet)])

            history.push(INTERSECTIONS_CREATE, {
                initialBloggers: checkedBloggers.map((i) => i.username).filter((i) => i !== undefined),
                initialName: list.name,
            })
        },
        [history, currentSet, checkedBloggers]
    )

    if (isLocked || disabled) return null
    return (
        <>
            <Flex className={cn.root} onClick={intersect}>
                <Icons name="copy" className={cn.icon} />
                <p className={cn.text}>{t('intersect')}</p>
            </Flex>
        </>
    )
    // return (
    //     <LockButton locked={isLocked} light>
    //         <LoadingButton
    //             loading={loading}
    //             onClick={intersect}
    //             variant="primary"
    //             margin={props.margin}
    //             disabled={disabled || isLocked}
    //             size="small"
    //             noDownloadIcon
    //             className={cn.root}
    //         >
    //             {t('intersect')}
    //         </LoadingButton>
    //     </LockButton>
    // )
}

export default memo(IntersectList)
