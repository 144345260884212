import React, { FC } from 'react'
import Popup from '@/_yc/Popup/Popup'

import Question from './Question'

import cn from './DetailedInfo.module.scss'

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: 2000,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}

interface IDetailedInfoPopUp {
    isOpen: boolean
    close: VoidFunction
}
const DetailedInfoPopUp: FC<IDetailedInfoPopUp> = ({ isOpen, close }) => {
    return (
        <>
            <div className={cn.background}>
                <Popup style={style} isOpen={isOpen} onClose={() => {}}>
                    <div className={cn.root}>
                        <Question closePopUp={close} />
                    </div>
                </Popup>
            </div>
        </>
    )
}

export default DetailedInfoPopUp
