import React, { FC } from 'react'

import Flex from '@/_yc/Flex'

import cn from './Switch.module.scss'

export interface ISwitch {
    value: boolean
    setValue: Function
}
export const Switch: FC<ISwitch> = ({ value, setValue }) => {
    return (
        <>
            <Flex className={cn[`switch${value ? '--checked' : ''}`]} align="center" onClick={() => setValue(!value)}>
                <div className={cn[`switch--slider${value ? '--checked' : ''}`]} />
            </Flex>
        </>
    )
}
