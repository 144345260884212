import React, { useCallback, useMemo, FC, useState, useEffect, useContext } from 'react'
import Text from '@/components/Text'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import cn from './SendToMediaPlan.module.scss'
import Icons from '@/components/Icons'
import useOpenClose from '@/_helpers/useOpenClose'
import Popup from '@/_yc/Popup/Popup'
import Portal from '@/_helpers/Portal'
import Flex from '@/_yc/Flex'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import Button from '@/components/Button'
import { BloggerItem } from './BloggerItem/BloggerItem'
import { Campaigns } from './Campaigns'
import { useMutation } from '@apollo/client'
import { CREATE_EMPTY_MEDIA_PLAN } from '@/pages/MediaPlanning/utils/queries'
import { CampaignManagerContext } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerContext'
import notif from '@/components/Notifier/model/actions.js'
import { ADD_INFLUENCER } from '@/pages/MediaPlanning/MediaPlan/utils/queries'

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 500,
    zIndex: 2000,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}
interface SendToMediaPlanProps {
    independentBTN?: boolean
    addType: 'new' | 'add'
}

export const SendToMediaPlan: FC<SendToMediaPlanProps> = ({ independentBTN = true, addType }) => {
    const t = useSpaceTranslation('aud_insides.send_to_media_plan')
    const dispatch = useDispatch()
    const [isOpen, open, close] = useOpenClose()
    const createNew = addType === 'new'

    const instagramList = useSelector((store: RootStore) => store.MassAddToList.dataINST)
    const ytList = useSelector((store: RootStore) => store.MassAddToList.dataYT)

    const selectedItems = useMemo(() => {
        return instagramList.concat(ytList)
    }, [instagramList, ytList])

    const context = useContext(CampaignManagerContext)

    const [addInfluencers, { loading: addLoading }] = useMutation(ADD_INFLUENCER, {
        context: context,
        onCompleted: () => {
            dispatch(notif.success([t('success')]))
        },
        onError: () => {
            dispatch(notif.error([t('er')]))
        },
    })

    const [createMediaPlanMutation, { data, loading }] = useMutation(CREATE_EMPTY_MEDIA_PLAN, {
        context: context,
        onCompleted: (data) => {
            const id = data.createMediaPlanOrder.mediaPlanOrder.id

            const instagramToAdd = instagramList
                .map((item) => item.externalId)
                .filter((item) => item !== undefined) as string[]

            if (instagramToAdd.length > 0) {
                addInfluencers({
                    variables: {
                        orderId: id,
                        bloggers: instagramToAdd,
                        bloggersType: 'INSTAGRAM',
                    },
                })
            }

            const youtubeToAdd = ytList.map((item) => item.channelId).filter((item) => item !== undefined) as string[]

            if (youtubeToAdd.length > 0) {
                addInfluencers({
                    variables: {
                        orderId: id,
                        bloggers: youtubeToAdd,
                        bloggersType: 'YOUTUBE',
                    },
                })
            }

            dispatch(notif.success([t('success')]))
        },
    })

    useEffect(() => {
        if (
            data &&
            data.createMediaPlanOrder &&
            !loading &&
            !addLoading &&
            data.createMediaPlanOrder.mediaPlanOrder.id
        ) {
            console.log('redirect')
            window.location.href = `/media-planning/${data.createMediaPlanOrder.mediaPlanOrder.id}`
        }
    }, [data, loading, addLoading])

    const createNewMediaPlan = useCallback(() => {
        createMediaPlanMutation()
    }, [createMediaPlanMutation])

    const [state, setState] = useState<'bloggers' | 'camp'>('bloggers')
    const handleSubmit = useCallback(() => {
        if (createNew) {
            createNewMediaPlan()
        } else {
            setState('camp')
        }
    }, [createNew, setState, createNewMediaPlan])

    const addToExistingMediaPlan = useCallback(
        (mediaPlanId: number) => {
            addInfluencers({
                variables: {
                    orderId: mediaPlanId,
                    bloggers: instagramList
                        .map((item) => item.externalId)
                        .filter((item) => item !== undefined) as string[],
                    bloggersType: 'INSTAGRAM',
                },
            })
            addInfluencers({
                variables: {
                    orderId: mediaPlanId,
                    bloggers: ytList.map((item) => item.channelId).filter((item) => item !== undefined) as string[],
                    bloggersType: 'YOUTUBE',
                },
            })
        },
        [instagramList, ytList, addInfluencers]
    )

    useEffect(() => {
        if (!isOpen) setState('bloggers')
    }, [isOpen])

    return (
        <>
            {independentBTN ? (
                <div className={cn.sendBtn} onClick={open}>
                    <Icons name="mail"></Icons>
                    <Text color="gray-2" fSize={10} semibold margin="0 0 0 6px">
                        {t('button_text')}
                    </Text>
                </div>
            ) : (
                <>
                    <Flex onClick={open} className={cn.newBTN}>
                        <p>{t(addType)}</p>
                    </Flex>
                </>
            )}

            {isOpen && (
                <Portal>
                    <div className={cn.root}>
                        <Popup style={style} isOpen={isOpen} onClose={close}>
                            <div className={cn.popup} style={state === 'camp' ? { height: '400px' } : {}}>
                                {state === 'bloggers' ? (
                                    <div>
                                        <Flex content="space-between" align="center" margin="0 0 20px 0">
                                            <Text fSize={20}>{t('title')}</Text>
                                            <div onClick={close}>
                                                <Icons name="close" className={cn.close}></Icons>
                                            </div>
                                        </Flex>

                                        <div className={cn.mediaPlanListItems}>
                                            {selectedItems.map((item) => (
                                                <BloggerItem {...item} />
                                            ))}
                                        </div>

                                        <Flex content="flex-end" align="center" margin="20px 0 0 0">
                                            <Button variant="primary" onClick={handleSubmit}>
                                                <span>{createNew ? t('btn') : t('choose')}</span>
                                                <span>
                                                    <Icons name="arrow-right" className={cn.arrowRight}></Icons>
                                                </span>
                                            </Button>
                                        </Flex>
                                    </div>
                                ) : (
                                    <Campaigns close={close} onSubmit={addToExistingMediaPlan} />
                                )}
                            </div>
                        </Popup>
                    </div>
                </Portal>
            )}
        </>
    )
}
