import React, { FC } from 'react'
import CustomAccordion from '@/pages/TargetAudience/components/CustomAccordion'
import { Flex } from '@/_yc'
import cn from './interests.module.scss'
import styles from '@/pages/TargetAudience/components/CustomAccordion/custom-accordion.module.scss'
import Text from '@/components/Text'
import { ReactComponent as DoneIcon } from '../../../icons/done.svg'
import Button from '@/components/Button'
import { Category } from '@/pages/TargetAudience/services/types'
import { useTranslation } from 'react-i18next'

interface Iprops {
    initialSelectedOptions: Category[]
    onInterestsChange: (updatedSelection: Category[]) => void
    onEdit?: () => void
    options: Category[]
}

const Interests: FC<Iprops> = ({ initialSelectedOptions, onInterestsChange, onEdit, options }) => {
    const { t } = useTranslation()
    return (
        <Flex column className={cn['root']}>
            <Flex className={styles['title-wrap']} align="center" content="space-between">
                <Text fSize={onEdit ? '14px' : '18px'} color="gray-new-4">
                    {t('target_audience.interests')}
                </Text>
                {onEdit && <DoneIcon onClick={onEdit} style={{ cursor: 'pointer' }} />}
            </Flex>
            <CustomAccordion
                categories={options}
                initialSelectedOptions={initialSelectedOptions}
                onChange={onInterestsChange}
            />
            {onEdit && (
                <Flex column>
                    <Button onClick={onEdit} className={cn['btn']}>
                        Сохранить
                        <DoneIcon />
                    </Button>
                </Flex>
            )}
        </Flex>
    )
}

export default Interests
