import React from 'react';

import Book from './Book.svg'
import Business from './Business.svg'
import Cars from './Cars.svg'
import Education from './Education.svg'
import Entertaiment from './Entertaiment.svg'
import Family from './Family.svg'
import fashion from './fashion.svg'
import Fitness from './Fitness.svg'
import Food from './Food.svg'
import Gaming from './Gaming.svg'
import Health from './Health.svg'
import mirror from './mirror.svg'
import Music from './Music.svg'
import Pensil from './Pensil.svg'
import Pet from './Pet.svg'
import Photo from './Photo.svg'
import Politics from './Politics.svg'
import Sport from './Sport.svg'
import Tech from './Tech.svg'
import Travel from './Travel.svg'

export default ({ name }) => {
	switch (name) {
		case "book": return <img style = {{ margin: "0 5px 0 8px" }} src = {Book} alt = {<div>Book</div>} />
		case "business": return <img style = {{ margin: "0 5px 0 8px" }} src = {Business} alt = {<div>Business</div>} />
		case "cars": return <img style = {{ margin: "0 5px 0 8px" }} src = {Cars} alt = {<div>Cars</div>} />
		case "education": return <img style = {{ margin: "0 5px 0 8px" }} src = {Education} alt = {<div>Education</div>} />
		case "entertaiment": return <img style = {{ margin: "0 5px 0 8px" }} src = {Entertaiment} alt = {<div>Entertaiment</div>} />
		case "family": return <img style = {{ margin: "0 5px 0 8px" }} src = {Family} alt = {<div>Family</div>} />
		case "fashion": return <img style = {{ margin: "0 5px 0 8px" }} src = {fashion} alt = {<div>fashion</div>} />
		case "fitness": return <img style = {{ margin: "0 5px 0 8px" }} src = {Fitness} alt = {<div>Fitness</div>} />
		case "food": return <img style = {{ margin: "0 5px 0 8px" }} src = {Food} alt = {<div>Food</div>} />
		case "gaming": return <img style = {{ margin: "0 5px 0 8px" }} src = {Gaming} alt = {<div>Gaming</div>} />
		case "health": return <img style = {{ margin: "0 5px 0 8px" }} src = {Health} alt = {<div>Health</div>} />
		case "mirror": return <img style = {{ margin: "0 5px 0 8px" }} src = {mirror} alt = {<div>mirror</div>} />
		case "music": return <img style = {{ margin: "0 5px 0 8px" }} src = {Music} alt = {<div>Music</div>} />
		case "pensil": return <img style = {{ margin: "0 5px 0 8px" }} src = {Pensil} alt = {<div>Pensil</div>} />
		case "pet": return <img style = {{ margin: "0 5px 0 8px" }} src = {Pet} alt = {<div>Pet</div>} />
		case "photo": return <img style = {{ margin: "0 5px 0 8px" }} src = {Photo} alt = {<div>Photo</div>} />
		case "politics": return <img style = {{ margin: "0 5px 0 8px" }} src = {Politics} alt = {<div>Politics</div>} />
		case "sport": return <img style = {{ margin: "0 5px 0 8px" }} src = {Sport} alt = {<div>Sport</div>} />
		case "tech": return <img style = {{ margin: "0 5px 0 8px" }} src = {Tech} alt = {<div>Tech</div>} />
		case "travel": return <img style = {{ margin: "0 5px 0 8px" }} src = {Travel} alt = {<div>Travel</div>} />
		default: return null
	}
}