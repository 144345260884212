import { logout } from '../_helpers/auth-header'
import axios from 'axios'
import i18n from 'i18next'
import { LOGIN } from '@/routs'
import { history } from './history'
import * as Sentry from '@sentry/browser'
import { CORSError } from './errors/cors.error'

class APIService {
    constructor(props) {
        this.__axios = axios.create({
            baseURL: props.baseURL || process.env.REACT_APP_API_DOMAIN,
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': i18n.language,
            },
            // withCredentials: process.env.NODE_ENV === "production"
        })
        Object.keys(props.methods).forEach((key) => {
            this[key] = (...args) => {
                const config = props.methods[key](...args)
                return this.__axios.request(config).then(
                    (resp) => {
                        if (resp.data) {
                            let result = resp.data
                            Object.defineProperty(result, '__status', {
                                value: resp.status,
                                enumerable: false,
                            })
                            return result
                        }
                        return resp
                    },
                    (err) => {
                        if (!err.response) {
                            const state = JSON.parse(
                                localStorage.getItem('user')
                            )
                            Sentry.captureException(
                                new CORSError(state, this.url)
                            )
                            return Promise.reject(err)
                        }

                        if (err.response.status === 401) {
                            logout()
                            history.push(LOGIN)
                            // window.location.reload(true);
                        }
                        return Promise.reject(err)
                    }
                )
            }
        })
    }
}

export { APIService }
