import React, { FC, memo, useCallback, useRef } from 'react'
import Text from '@/components/Text'
import useOpenClose from '@/_helpers/useOpenClose'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import Popup from '@/_yc/Popup'
import cn from './Intersection.module.scss'
import LoadingButton from '@/components/Share/LoadingButton'

export interface IDownloadButton {
    pdf: VoidFunction
    excel: VoidFunction
    loading: boolean
}

const DownloadButton: FC<IDownloadButton> = ({ pdf, excel, loading }) => {
    const ref = useRef(null)

    const [isOpen, , close, toggle] = useOpenClose()

    const t = useSpaceTranslation('intersections.intersection')

    const asPdf = useCallback(() => {
        close()
        pdf()
    }, [pdf, close])

    const asExcel = useCallback(() => {
        close()
        excel()
    }, [excel, close])

    return (
        <>
            <LoadingButton
                onClick={toggle}
                ref={ref}
                variant="primary"
                size="small"
                noLoadingText
                loading={loading}
                className={cn.download_icon}
            >
                {t('download')}
            </LoadingButton>
            <Popup isOpen={isOpen} onClose={toggle} anchor={ref.current}>
                <div className={cn.paper}>
                    <Text onClick={asPdf} fSize={12} className={cn.item}>
                        {t('load_as.pdf')}
                    </Text>
                    <Text onClick={asExcel} fSize={12} className={cn.item}>
                        {t('load_as.excel')}
                    </Text>
                </div>
            </Popup>
        </>
    )
}

export default memo(DownloadButton)
