import { gql } from '@apollo/client'

export const GET_UNLOCKED_CHANNELS = gql`
    query GetUnlockedChannels {
        getUnlockedChannels {
            unlocked_youtube_channels
            unlocked_twitch_channels
            unlocked_vk_accounts
            unlocked_tg_accounts
            unlocked_tt_accounts
        }
    }
`

export const GET_CHANNELS_DATA = gql`
    query GetChannelsData($ids: [String!]) {
        youtube_channel(where: { id: { _in: $ids } }) {
            avatar
            id
            title
            subscriber_count
            er_followers
            channel_topics {
                youtube_channeltopic {
                    name
                }
            }
        }
    }
`

export const GET_RECENT_SEARCHES = gql`
    query MyQuery($page: Int!) {
        getRecentSearchesV2(page: $page) {
            data
            count
        }
    }
`

export const GET_RECENT_SEARCHES_COUNT = gql`
    query MyQuery {
        getRecentSearchesV2(page: 1) {
            count
        }
    }
`
