import React, { FC, SVGAttributes } from 'react'

import { Icon } from '@/components/Notifier/Types'
import InfoIcon from './InfoIcon'
import OkIcon from './OkIcon'
import ErrorIcon from './ErrorIcon'

export interface INotifIcons extends SVGAttributes<SVGElement> {
    icon: Icon
}

const NotifIcons: FC<INotifIcons> = ({ icon, ...rest }) => {
    switch (icon) {
        case 'info':
            return <InfoIcon {...rest} />
        case 'ok':
            return <OkIcon {...rest} />
        case 'error':
            return <ErrorIcon {...rest} />
        default:
            return null
    }
}

export default NotifIcons
