import React, { ComponentProps, forwardRef, ForwardRefRenderFunction, SVGAttributes } from 'react'
import { Stylable } from '@/Types'
import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'
import { Soon } from '@/components/Soon'

import cn from './MainFilterBase.module.scss'

export interface IFilterBase extends Stylable, ComponentProps<typeof Flex> {
    isActive?: boolean
    icon?: SVGAttributes<SVGElement>
    noArrow?: boolean
    soon?: boolean
}

const MainFilterBase: ForwardRefRenderFunction<any, IFilterBase> = (
    { className, isActive, withArrow, disabled, soon, ...props },
    ref
) => {
    return (
        <div className={cn.filterBase} onClick={soon ? null : props.onClick} ref={ref}>
            <Flex align="center" style={{ position: 'relative' }}>
                <div className={isActive ? cn['icon--active'] : cn['icon']}>{props.icon}</div>

                {props.children}

                {soon && (
                    <Flex margin="0 0 0 6px">
                        <Soon />{' '}
                    </Flex>
                )}
            </Flex>

            <Flex align="center">
                {!props.noArrow && !soon && (
                    <Icons name="arrow-down" className={cn[isActive ? 'arrow--opened' : 'arrow--closed']} />
                )}

                <div className={cn.divider} />
            </Flex>
        </div>
    )
}

export default forwardRef(MainFilterBase)
