import PageLayout from '@/Layouts/PageLayout'
import { useSpaceTranslation } from '@/_helpers'
import Flex from '@/_yc/Flex'
import PageTitle from '@/components/PageTitle'
import { SEARCH } from '@/routs'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Arrow from '@/components/Share/BackButton/Arrow'
import Text from '@/components/Text'

import cn from './Settings.module.scss'
import PartChanger from '@/Layouts/ReportLayout/Extra/PartChanger'
import { AccountSettings } from './AccountSettings/AccountSettings'
import { EmailAccounts } from './EmailAccounts/EmailAccounts'
import { GlobalWidth } from '@/_yc'
import Button from '@/components/Button'
import { MailSettingsPopup } from '@/components/MailSettingsPopup/MailSettingsPopup'
import useOpenClose from '@/_helpers/useOpenClose'

type Tab = 'account' | 'email'

export const Settings = () => {
    const t = useSpaceTranslation('settings')
    const [tab, setTab] = useState<Tab>('account')
    const [isOpenPopup, openPopup, closePopup] = useOpenClose()

    useEffect(() => {
        const urlTab = new URL(window.location.href).pathname.split('/')[2]
        if (!urlTab) return
        changeTab(urlTab)
    }, [])

    const changeTab = (value: string | number) => {
        setTab(value as Tab)

        const url = new URL(window.location.href)
        url.pathname = `/settings/${value}`
        window.history.pushState({}, '', url.toString())
    }

    return (
        <PageLayout color="common-background" title="Settings - Yoloco">
            <GlobalWidth>
                <div className={cn.header}>
                    <PageTitle
                        title={t('title')}
                        linkToHelper={''}
                        icon="settings"
                        marginBottom="0"
                        iconSize="25px"
                        iconStyle={{ backgroundColor: '#AEBACC' }}
                    >
                        <Link to={SEARCH}>
                            <Flex grow align="center" content="flex-start">
                                <Arrow className={cn.arrow} />
                                <Text margin={'0 0 0 10px'} color="gray-3">
                                    {t('go_back')}
                                </Text>
                            </Flex>
                        </Link>
                    </PageTitle>

                    {tab === 'email' && (
                        <Button variant="primary" onClick={openPopup}>
                            {t('add_account')}
                        </Button>
                    )}
                </div>

                <PartChanger
                    values={[
                        {
                            title: t('tabs.account'),
                            value: 'account',
                            dispFunction: (v) => changeTab(v),
                            isActive: tab === 'account',
                        },
                        {
                            title: t('tabs.email'),
                            value: 'email',
                            dispFunction: (v) => changeTab(v),
                            isActive: tab === 'email',
                        },
                    ]}
                    useActive
                />
                {tab === 'account' && (
                    <div className={cn.content}>
                        <AccountSettings />
                    </div>
                )}
                {tab === 'email' && <EmailAccounts />}
                <MailSettingsPopup isOpen={isOpenPopup} close={closePopup} />
            </GlobalWidth>
        </PageLayout>
    )
}
