import { shortNum } from '@/_helpers/_graphs/_tech'
import React from 'react'
import { ApexOptions } from 'apexcharts'
import { renderToString } from 'react-dom/server'
import { Graph } from '../../Model/types'
import Tooltip from './Tooltip/Tooltip'
import { getGraphLocale } from '@/pages/Reports/BloggerReport/Cards/InstaGrowGraph/utils'

interface Options {
    graphOpts: number[]
    info: Graph
    graphCategories: string[]
    locale: string
    isEmpty: boolean
    toFixed?: number
}

const getOptions = ({ graphOpts, info, graphCategories, locale, isEmpty, toFixed = 1 }: Options): ApexOptions => {
    return {
        chart: {
            height: 280,
            type: 'area',
            toolbar: {
                show: false,
                autoSelected: 'pan',
            },
            zoom: {
                enabled: false,
            },
            defaultLocale: getGraphLocale(locale),
            locales: [
                {
                    name: 'en',
                    options: {
                        shortMonths: [
                            'Jan',
                            'Feb',
                            'Mar',
                            'Apr',
                            'May',
                            'Jun',
                            'Jul',
                            'Aug',
                            'Sep',
                            'Oct',
                            'Nov',
                            'Dec',
                        ],
                    },
                },
                {
                    name: 'ru',
                    options: {
                        shortMonths: [
                            'Янв',
                            'Фев',
                            'Мар',
                            'Апр',
                            'Май',
                            'Июн',
                            'Июл',
                            'Авг',
                            'Сен',
                            'Окт',
                            'Ноя',
                            'Дек',
                        ],
                    },
                },
            ],
        },

        fill: {
            colors: ['#6071FF'],
        },
        markers: {
            size: 0,
        },
        stroke: {
            width: 2,
            colors: ['#6071FF'],
        },
        series: [
            {
                name: 'sales',
                data: graphOpts,
            },
        ],
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            enabled: !isEmpty,
            custom: (options) =>
                renderToString(
                    <Tooltip
                        type={info.type}
                        series={options.series}
                        seriesIndex={options.seriesIndex}
                        dataPointIndex={options.dataPointIndex}
                        w={options.w}
                        toFixed={toFixed}
                    />
                ),
            style: {
                fontSize: '42px',
                fontFamily: 'Montserrat',
            },
        },
        yaxis: {
            labels: {
                formatter: (val: any) => {
                    if (info.type === 'duration') {
                        return `${Math.round(val / 60)}h`
                    }
                    return info.type === 'er' ? `${shortNum(val * 100, toFixed, 1)}%` : shortNum(val, toFixed)
                },
            },
        },
        xaxis: {
            type: 'datetime',
            // tickAmount: 6,
            tooltip: {
                enabled: false,
            },
            labels: {
                style: {
                    fontSize: '10px',
                    colors: '#8F8F8F',
                },
            },
            categories: graphCategories,
        },
    }
}

export default getOptions
