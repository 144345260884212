import React from 'react';
import { useLocation } from 'react-router-dom'

import { LISTS, REPORT, SEARCH, SETTINGS, BILLING, ABOUT, TARIFFS, INTERSECTIONS, DEMO_REPORT } from '../../routs.js';

const rex = new RegExp(REPORT)
 
export const footerVariant = Component => props => {
	const { width } = props;
	const { pathname } = useLocation();

	const passCondition = pathname === LISTS ||
					      pathname.search(rex)!==-1 ||
					      `/${pathname.split('/')[1]}` === SEARCH ||
					      pathname === SETTINGS ||
					      pathname === BILLING ||
					      pathname === ABOUT || 
					      pathname === TARIFFS || 
					      pathname.search(INTERSECTIONS)!==-1 ||
					      pathname.search(DEMO_REPORT)!==-1;

	if (!passCondition) {
		return null
	}
	const background = /*pathname === "/tariffs" ? "rgba(255, 255, 255, .1)" :*/ undefined;
	const modifier = /*pathname === "/tariffs" ? "bottom" : */
					 width === "xs" ? "fixed" : 
					 "";

	const variant = { background, modifier }
	return (
		<Component variant = {variant} {...props}/>
	)
}