import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { AddToLikesReq, DislikeReq } from '@/pages/Lists/model/service'
import { Platform } from '@/pages/Reports/types'
import useToken from '@/_helpers/Hooks/useToken'
import Flex from '@/_yc/Flex'

import notif from '@/components/Notifier/model/actions.js'

import Icons from '../Icons'

import cn from './Like.module.scss'

interface LikeProps {
    platform: Platform
    externalId?: string
    likeId?: number
    disabled?: boolean
}

export const Like: FC<LikeProps> = ({ platform, externalId, likeId, disabled }) => {
    const dispatch = useDispatch()
    const [isLiked, setIsLiked] = useState(likeId ? true : false)
    const token = useToken()
    const { t } = useTranslation()

    const toggleLike = () => {
        if (disabled) return
        if (!token) return
        if (isLiked && likeId) {
            DislikeReq(token, likeId).then(() => {
                dispatch(
                    notif.success([
                        t('aud_insides.status.removed_from_list'),
                        { listName: t('aud_insides.lists.favorites'), listID: t('aud_insides.lists.favorites') },
                    ])
                )
            })
            setIsLiked(!isLiked)
            return
        }

        if (!externalId) return

        let promiseAr = []
        switch (platform) {
            case Platform.Instagram: {
                promiseAr.push(AddToLikesReq(token, [externalId]))
                break
            }
            case Platform.YouTube: {
                promiseAr.push(AddToLikesReq(token, [], [externalId]))
                break
            }
            case Platform.Twitch: {
                promiseAr.push(AddToLikesReq(token, [], [], [], [externalId]))
                break
            }
            case Platform.Vk: {
                promiseAr.push(AddToLikesReq(token, [], [], [], [], [], [externalId]))
                break
            }
            case Platform.Tg: {
                promiseAr.push(AddToLikesReq(token, [], [], [], [], [], [], [externalId]))
                break
            }
        }
        Promise.all(promiseAr).then(() => {
            dispatch(
                notif.success([
                    t('aud_insides.status.added_to_list_LINK'),
                    { listName: t('aud_insides.lists.favorites'), listID: t('aud_insides.lists.favorites') },
                ])
            )
        })

        setIsLiked(!isLiked)
    }

    return (
        <Flex
            onClick={toggleLike}
            className={cn[`like${isLiked ? '-active' : ''}`]}
            align="center"
            content="center"
            style={disabled ? { cursor: 'not-allowed' } : {}}
        >
            <Icons name={'heart_outline'} />
        </Flex>
    )
}
