import { useDispatch } from 'react-redux'
import Button from '@/components/Button'
import Icons from '@/components/Icons'
import useOpenClose from '@/_helpers/useOpenClose'
import { Flex } from '@/_yc'
import React, { FC, memo, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SideNavBar from '@/_yc/SideNavBar'
import cn from './Burger.module.scss'
import { BILLING, TARIFFS, SETTINGS, LOGIN } from '@/routs'
import Text from '@/components/Text'
// import LanguageSwitcher2 from '../LanguageSwitcher2'
import { loginActions } from '@/pages/Auth/LoginPage/Model'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

export interface IBurger {
    margin: string | number
    links: { title: string; to: string; icon: string }[]
}

const style = { zIndex: 400, right: 0, left: undefined }

const Burger: FC<IBurger> = (props) => {
    const ref = useRef(null)

    const { t } = useTranslation()

    const t2 = useSpaceTranslation('top_menu.user_menu')

    const [state, setOpen, setClose] = useOpenClose()

    const dispatch = useDispatch()
    const handleLogOut = () => {
        dispatch(loginActions.logout())
    }

    return (
        <>
            <Flex
                ref={ref}
                component={Button}
                align="center"
                content="center"
                variant="transparent"
                className={cn.btn}
                width={40}
                size="small"
                margin={props.margin}
                onClick={setOpen}
            >
                <Icons name="burger" className={cn.icon} />
            </Flex>
            <SideNavBar isOpen={state} onClose={setClose} style={style}>
                <Flex className={cn.navbar}>
                    <Flex
                        component={Button}
                        align="flex-start"
                        content="flex-end"
                        variant="transparent"
                        className={cn['btn--close']}
                        onClick={setClose}
                    >
                        <Icons name="close" className={cn.closeIcon} />
                    </Flex>
                    <Flex className={cn['pages--main']}>
                        {props.links.map((item) => (
                            <Flex
                                component={NavLink}
                                className={cn.link}
                                activeClassName={cn['link--active']}
                                key={item.to}
                                to={item.to}
                                align="center"
                            >
                                <Icons name={item.icon as any} className={cn.icon} />
                                <p className={cn['link--text']}>{t(item.title)}</p>
                            </Flex>
                        ))}
                    </Flex>
                    <Flex content="flex-end" className={cn['pages--secondary']}>
                        <Flex component={NavLink} className={cn['link--secondary']} to={BILLING} align="center">
                            <Icons name="sync" className={cn['icon--secondary']} />
                            <Text fSize={12} margin="0 10px 0" color="gray-3">
                                {t2('billing')}
                            </Text>
                        </Flex>
                        <Flex component={NavLink} className={cn['link--secondary']} to={TARIFFS} align="center">
                            <Icons name="tag--outline" className={cn['icon--secondary']} />
                            <Text fSize={12} margin="0 10px 0" color="gray-3">
                                {t2('pricing')}
                            </Text>
                        </Flex>
                        <Flex component={NavLink} className={cn['link--secondary']} to={SETTINGS} align="center">
                            <Icons name="settings" className={cn['icon--secondary']} />
                            <Text fSize={12} margin="0 10px 0" color="gray-3">
                                {t2('settings')}
                            </Text>
                        </Flex>
                        <Flex
                            component={NavLink}
                            className={cn['link--secondary']}
                            onClick={handleLogOut}
                            to={LOGIN}
                            align="center"
                        >
                            <Icons name="logout" className={cn['icon--secondary']} />
                            <Text fSize={12} margin="0 10px 0" color="gray-3">
                                {t2('logout')}
                            </Text>
                        </Flex>
                    </Flex>
                    {/* <div className={cn.divider} />
                    <LanguageSwitcher2 /> */}
                </Flex>
            </SideNavBar>
        </>
    )
}

export default memo(Burger)
