import React from 'react';

export default () => (
	<div style = {{ width: 13, height: 13, marginRight: 5, position: 'relative' }}>
		<svg style = {{position: 'absolute', left: 2, top: 0}} width="2" height="3" viewBox="0 0 2 3" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0.457293 2.66163H1.27431C1.5223 2.66163 1.72338 2.46425 1.72338 2.22044V0.441355C1.72338 0.197522 1.5223 0 1.27431 0H0.457293C0.209277 0 0.00823212 0.197522 0.00823212 0.441355V2.22044C0.00823212 2.46425 0.209277 2.66163 0.457293 2.66163Z" fill="white"/>
		</svg>
		<svg style = {{position: 'absolute', left: 9, top: 0}} width="2" height="3" viewBox="0 0 2 3" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0.457293 2.66163H1.27431C1.5223 2.66163 1.72338 2.46425 1.72338 2.22044V0.441355C1.72338 0.197522 1.5223 0 1.27431 0H0.457293C0.209277 0 0.00823212 0.197522 0.00823212 0.441355V2.22044C0.00823212 2.46425 0.209277 2.66163 0.457293 2.66163Z" fill="white"/>
		</svg>
		<svg style = {{position: 'absolute', left: 0, top: 1.5}} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M11.7789 0.44754V1.22117C11.7789 1.93195 11.1924 2.50473 10.4696 2.50473H9.64357C8.92066 2.50473 8.32638 1.93195 8.32638 1.22117V0.441895H4.60343V1.22117C4.60343 1.93195 4.00918 2.50473 3.28637 2.50473H2.46022C1.73738 2.50473 1.15091 1.93195 1.15091 1.22117V0.44754C0.519137 0.466262 0 0.980214 0 1.61193V10.8279C0 11.4715 0.530595 12 1.18523 12H11.7446C12.3982 12 12.9298 11.4704 12.9298 10.8279V1.61193C12.9298 0.980214 12.4107 0.466262 11.7789 0.44754ZM11.3953 10.254C11.3953 10.5321 11.1659 10.7578 10.8829 10.7578H2.02435C1.74134 10.7578 1.51196 10.5321 1.51196 10.254V4.42837C1.51196 4.15013 1.74131 3.92448 2.02435 3.92448H10.8829C11.1659 3.92448 11.3953 4.15013 11.3953 4.42837L11.3953 10.254Z" fill="white"/>
		</svg>
		<svg style = {{position: 'absolute', left: 3, top: 5.5}} width="2" height="3" viewBox="0 0 2 3" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.69645 1.17198C1.69645 1.00913 1.56136 0.877197 1.39469 0.877197H0.341653C0.175166 0.877197 0.0400543 1.00913 0.0400543 1.17198V2.20153C0.0400543 2.36459 0.175166 2.49657 0.341653 2.49657H1.39469C1.56136 2.49657 1.69645 2.36459 1.69645 2.20153V1.17198Z" fill="white"/>
		</svg>
		<svg style = {{position: 'absolute', left: 5.5, top: 5.5}} width="2" height="3" viewBox="0 0 2 3" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.69645 1.17198C1.69645 1.00913 1.56136 0.877197 1.39469 0.877197H0.341653C0.175166 0.877197 0.0400543 1.00913 0.0400543 1.17198V2.20153C0.0400543 2.36459 0.175166 2.49657 0.341653 2.49657H1.39469C1.56136 2.49657 1.69645 2.36459 1.69645 2.20153V1.17198Z" fill="white"/>
		</svg>	
		<svg style = {{position: 'absolute', left: 8.3, top: 5.5}} width="2" height="3" viewBox="0 0 2 3" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.69645 1.17198C1.69645 1.00913 1.56136 0.877197 1.39469 0.877197H0.341653C0.175166 0.877197 0.0400543 1.00913 0.0400543 1.17198V2.20153C0.0400543 2.36459 0.175166 2.49657 0.341653 2.49657H1.39469C1.56136 2.49657 1.69645 2.36459 1.69645 2.20153V1.17198Z" fill="white"/>
		</svg>	
		<svg style = {{position: 'absolute', left: 3, top: 8.5}} width="2" height="3" viewBox="0 0 2 3" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.69645 1.17198C1.69645 1.00913 1.56136 0.877197 1.39469 0.877197H0.341653C0.175166 0.877197 0.0400543 1.00913 0.0400543 1.17198V2.20153C0.0400543 2.36459 0.175166 2.49657 0.341653 2.49657H1.39469C1.56136 2.49657 1.69645 2.36459 1.69645 2.20153V1.17198Z" fill="white"/>
		</svg>
		<svg style = {{position: 'absolute', left: 5.5, top: 8.5}} width="2" height="3" viewBox="0 0 2 3" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.69645 1.17198C1.69645 1.00913 1.56136 0.877197 1.39469 0.877197H0.341653C0.175166 0.877197 0.0400543 1.00913 0.0400543 1.17198V2.20153C0.0400543 2.36459 0.175166 2.49657 0.341653 2.49657H1.39469C1.56136 2.49657 1.69645 2.36459 1.69645 2.20153V1.17198Z" fill="white"/>
		</svg>	
		<svg style = {{position: 'absolute', left: 8.3, top: 8.5}} width="2" height="3" viewBox="0 0 2 3" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.69645 1.17198C1.69645 1.00913 1.56136 0.877197 1.39469 0.877197H0.341653C0.175166 0.877197 0.0400543 1.00913 0.0400543 1.17198V2.20153C0.0400543 2.36459 0.175166 2.49657 0.341653 2.49657H1.39469C1.56136 2.49657 1.69645 2.36459 1.69645 2.20153V1.17198Z" fill="white"/>
		</svg>	
	</div>
)