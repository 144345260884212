import React, { FC } from 'react'

import cn from './Variant.module.scss'

export interface ITariff {
    price_model: string
    index: number
    currentTariff: number
    setTariff: Function
    label: string
}
const TariffOption: FC<ITariff> = ({ price_model, index, currentTariff, setTariff, label }) => {
    return (
        <div
            className={currentTariff === index ? cn['root--active'] : cn['root']}
            onClick={() => setTariff(index)}
            style={price_model !== 'report' ? { width: '110px' } : {}}
        >
            <div className={cn['label']}>{label}</div>
        </div>
    )
}

export default TariffOption
