import { useState, useCallback } from 'react'

import { FileDownloader } from '@/_helpers'

/**
 *
 * @param fileName name for file to save
 * @param intersection_id intersection id
 * @param callService function that returns data for xlsx
 * @example
 * import useExcelLoader from 'tools'
 *
 * const App = ({ id }) => {
 *
 *  const [loading, download] = useExcelLoader(
 *      "coolFile",
 *      id,
 *      () => Promise.resolve("DATA!")
 *  )
 *
 *  return (
 *      <div onClick = {download}>
 *          {loading ? "LOADING..." : "Download"}
 *      </div>
 *  )
 * }
 */
const useExcelLoader = (
    fileName: string,
    intersection_id: string | number,
    callService: (id: string | number) => Promise<BlobPart>
) => {
    const [loading, setLoading] = useState<boolean>(false)

    const download = useCallback(() => {
        setLoading(true)
        callService(intersection_id)
            .then((res) => {
                FileDownloader(res, fileName, 'xlsx')
            })
            .catch(console.error)
            .finally(() => {
                setLoading(false)
            })
        // eslint-disable-next-line
    }, [fileName, intersection_id])

    return [loading, download]
}

export default useExcelLoader
