import { useRef, useState, useEffect, RefObject } from 'react'
import { resizeService } from '../../Model/_services.js'

/**
 *
 * @param active should hook be active
 * @returns [ContainerRef, width, visibility]
 * @description used for line chart correct resizeing
 */
export const useWidth = (active?: boolean): [RefObject<HTMLDivElement>, number, boolean] => {
    const container = useRef<HTMLDivElement>(null)

    const [visible, setVisibility] = useState<boolean>(true)
    const [width, setWidth] = useState<number>(0)
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)

    useEffect(() => {
        if (container.current && active) {
            let style = getComputedStyle(container.current)
            setWidth(+style.width.slice(0, -2) + 30)
        }
    }, [container, active])

    useEffect(() => {
        if (!container.current || !active) return
        let mounted = true
        const sub = resizeService.subscribe(() => {
            if (!mounted) return
            setWindowWidth(window.innerWidth)
        })
        return () => {
            mounted = false
            sub.unsubscribe()
        }
    }, [container, active])

    useEffect(() => {
        if (!container.current || !active || width === 0) return
        let mounted = true
        setVisibility(false)

        setTimeout(() => {
            if (!container.current || !active || !mounted) return
            let style = getComputedStyle(container.current)
            setVisibility(true)
            setWidth(+style.width.slice(0, -2) + 30)
        }, 1000)

        return () => {
            mounted = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowWidth, container, active])

    /* useEffect(() => {
        if (container.current && active) {

            let mounted = true;

            const sub = resizeService.subscribe(() => {
                if (
                    mounted &&
                    prevWidth !== window.innerWidth &&
                    active &&
                    container.current
                ) {
                    setPrevWidth(window.innerWidth)
                    setVisibility(false)
                    let style = getComputedStyle(container.current);
                    setTimeout(() => {
                        setVisibility(true)
                        setWidth(+style.width.slice(0, -2) + 30)
                    }, 1000)
                }
            });
            return () => {
                mounted = false;
                sub.unsubscribe()
            }
        }
    }, [active, prevWidth]) */

    return [container, width, visible]
}
