import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import { Flex } from '@/_yc'
import Avatar from '@/components/Avatar'

import React, { FC } from 'react'
import cn from './SearchItem.module.scss'
import Text from '@/components/Text'
import { AutocompleteResult } from '@/Reponses'
import { shortNum } from '@/_helpers/_graphs/_tech'
import { getCorrect } from '@/_helpers'
import { useTranslation } from 'react-i18next'

interface SearchItemProps extends AutocompleteResult {
    onClick: () => void
}

export const SearchItem: FC<SearchItemProps> = ({ onClick, ...props }) => {
    const size = useWindowWidth()
    const { t } = useTranslation()

    return (
        <Flex className={cn.item} onClick={onClick}>
            <Avatar src={props.avatar} className={cn.avatar} type={props.type} />
            <Flex
                margin="0 0 0 10px"
                column={size === 'mobile'}
                grow
                content={size === 'mobile' ? 'center' : 'space-between'}
                align={size === 'mobile' ? 'flex-start' : 'center'}
            >
                <div className={cn.item__data}>
                    <Text semibold color="violet-1" component="h6">
                        {props.username}
                    </Text>
                    <Flex>
                        {props.fullname && (
                            <Text semibold fSize={12} color="gray-3">
                                {props.fullname}
                            </Text>
                        )}
                        <Text margin="0 0 0 10px" fSize={12} color="gray-3">
                            {t(`aud_insides.search_input.followers${getCorrect(props.followers)}`, {
                                count: shortNum(props.followers) as any,
                            })}
                        </Text>
                    </Flex>
                </div>
                {/* <Text fSize={12} semibold color="gray-3">
                    {t(
                        props.unlocked
                            ? 'aud_insides.search_input.button.report'
                            : 'aud_insides.search_input.button.unlock'
                    )}{' '}
                    <Arrow className={cn.icon} />
                </Text> */}
            </Flex>
        </Flex>
    )
}
