import React from 'react'
import Icons from '@/components/Icons'
import { SelectorFilter } from '../../../../filters/SelectorFilter'
import { PLATFORM } from '../consts'

const getGqlFilter = (gender: Array<string>, percentage: number) => {
    if (!gender || !percentage) return ''
    return `audience_agender_total: {${gender[0]}: {_gte: "${percentage / 100}"}}`
}

export const getAudGenderFilter = () => {
    return new SelectorFilter({
        platform: PLATFORM,
        locked: (features: string[]) => !features?.includes('audience_age_gender_yt'),
        title: 'filters.gender.audience.label',
        helper: 'filters.gender.audience.text',
        space: 1,
        hasPercentage: true,
        icon: <Icons name="filter--gender" />,
        featureKey: 'genderAudienceFilter',
        options: [
            { label: 'filters.gender.audience.male', value: 'total_man_share', translate: true },
            { label: 'filters.gender.audience.female', value: 'total_woman_share', translate: true },
        ],
        beforeSend: ({ value, percentage }) => {
            return {
                name: 'audience.country',
                type: '',
                value: value,
                gqlFilter: getGqlFilter(value, percentage),
            }
        },
    })
}
