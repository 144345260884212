import { useEffect } from 'react'
import { Reports } from '@/_helpers/TabChannel'

export default (id: number | string, onBought: VoidFunction) => {
    useEffect(() => {
        const subscription = Reports.subscribe((message) => {
            if (message.payload.blogger_id === id) {
                onBought()
            }
        })

        return () => subscription.unsubscribe()
    }, [id, onBought])
}
