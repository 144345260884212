import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.00496 11.8434H8.9883C8.93663 11.8434 3.76746 11.8334 2.4633 11.4792C1.74615 11.2861 1.18609 10.7257 0.993296 10.0084C0.758778 8.68538 0.64553 7.34371 0.654963 6.00008C0.648904 4.65426 0.765216 3.31067 1.00246 1.98591C1.20055 1.26801 1.75815 0.705039 2.47413 0.500081C3.74246 0.166748 8.76913 0.166748 8.98246 0.166748H8.99996C9.05246 0.166748 14.235 0.176748 15.5258 0.530915C16.2414 0.725089 16.8004 1.28433 16.9941 2.00008C17.2361 3.32803 17.3497 4.6762 17.3333 6.02592C17.3391 7.37011 17.2225 8.71204 16.985 10.0351C16.7897 10.7514 16.2293 11.3106 15.5125 11.5042C14.2458 11.8401 9.2183 11.8434 9.00496 11.8434ZM7.3383 3.50425L7.33413 8.50425L11.6775 6.00425L7.3383 3.50425Z"
                fill="#5D697D"
            />
        </svg>
    )
}
