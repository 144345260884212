import React from 'react'
import Icons from '@/components/Icons'
import { SearchFilter } from '@/pages/SearchPage/SearchAdapter/filters/SearchFilter'

const getGqlFilter = (value: string) => {
    if (!value || value.length === 0) return ''
    return `relevance: { value:  "${value}"}`
}
export const getLookalikeFilter = (isMain?: boolean) => {
    return new SearchFilter({
        platform: 'infl_tiktok',
        title: 'filters.username.label',
        icon: <Icons name="filter--similar" />,
        helper: 'filters.username.chaining.text',
        placeholder: 'filters.username.plh',
        space: 1,
        beforeSend: (value) => ({
            name: 'chaining',
            operator: 'equal',
            type: 'text',
            value: value,
            gqlFilter: getGqlFilter(value),
        }),
    })
}
