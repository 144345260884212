import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.33333 13.6667H2.66667C1.93029 13.6667 1.33333 13.0697 1.33333 12.3333V3.66666H0V2.33333H2.66667V1.66666C2.66667 0.930282 3.26362 0.333328 4 0.333328H8C8.73638 0.333328 9.33333 0.930282 9.33333 1.66666V2.33333H12V3.66666H10.6667V12.3333C10.6667 13.0697 10.0697 13.6667 9.33333 13.6667ZM2.66667 3.66666V12.3333H9.33333V3.66666H2.66667ZM4 1.66666V2.33333H8V1.66666H4ZM8 11H6.66667V5H8V11ZM5.33333 11H4V5H5.33333V11Z"
            fill="#76849B"
        />
    </svg>
)
