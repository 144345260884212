import React, { FC, memo, useRef } from 'react'
import Text from '@/components/Text'

import cn from './SearchItem.module.scss'
import Icons from '@/components/Icons'
import Flex from '@/_yc/Flex'
import { getNumberWithSpaces } from '@/_helpers/nums/numberWithSpaces'
import { IPostData } from '../types'
import moment from 'moment'
import BloggerLink from '@/components/Share/BloggerLink'
import { getPostLink } from '../utils/getPostLink'
import { PostBrands } from '../../PostBrands/PostBrands'
import Loader from '@/components/Loader'
import { Platform } from '@/pages/Reports/types'
import Button from '@/components/Button'
import { useTranslation } from 'react-i18next'
import AddToList from '@/pages/Lists/components/AddToList/AddToList'
import { MassAddCheckBoxNEW } from '@/components/MassAddToList'

export interface ISearchItem extends IPostData {
    index: number
    margin?: string | number
    isList?: boolean
}

const SearchItem: FC<ISearchItem> = (props) => {
    const { t } = useTranslation()
    const openPost = () => {
        window.open(getPostLink(props.code))
    }
    const ref = useRef<HTMLDivElement>(null)

    if (props.isList) {
        return (
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '0.2fr 0.8fr 1.2fr 0.5fr 0.5fr 0.8fr',
                    padding: '22px 16px',
                    background: 'white',
                }}
            >
                <div>
                    <MassAddCheckBoxNEW
                        data={{
                            hasEmail: false,
                            avatar: '',
                            name: '',
                            username: '',
                        }}
                        id={props?.userId ? props.userId.toString() : ''}
                        externalId={props?.userId ? props.userId.toString() : ''}
                        type={Platform.Instagram}
                        key={props.id}
                        index={props.index}
                    />
                </div>

                <BloggerLink newTab id={props.userId} platform={Platform.Instagram}>
                    <div className={cn['searchItem--info']} style={{ alignItems: 'center' }}>
                        <img src={props.profilePicture} alt={'avatar'} width={'34px'} height={'34px'} />

                        <div className={cn['searchItem--info--blogger']}>
                            <Text color={'violet-1'} fSize={14} bold>
                                {props.username}
                            </Text>
                            <Text margin={'3px 0 0 0'} color={'gray-3'} fSize={12}>
                                {moment(props.postDate).format('DD.MM.YYYY')}
                            </Text>
                        </div>
                    </div>
                </BloggerLink>

                <Flex>
                    <Flex content={'center'} onClick={openPost} style={{ cursor: 'pointer' }} ref={ref}>
                        <blockquote
                            className="instagram-media insta-embed"
                            style={{
                                borderRadius: '4px',
                                border: 'none',
                                boxShadow: 'none',
                                display: 'block',
                                margin: '0px 0px 12px',
                                maxHeight: '120px',
                                overflowY: 'scroll',
                            }}
                            data-instgrm-captioned
                            data-instgrm-permalink={`https://www.instagram.com/p/${props.code}/?utm_source=ig_embed&amp;utm_campaign=loading`}
                            data-instgrm-version="14"
                        >
                            <Flex content="center" align="center" style={{ width: '100%', height: '100%' }}>
                                <Loader />
                            </Flex>
                        </blockquote>
                    </Flex>

                    <Text color={'gray-1'} fSize={12} margin={'12px 0 16px 20px'} style={{ width: '270px' }}>
                        {props.text && props.text.slice(0, 110)}
                    </Text>
                </Flex>

                <Flex content={'flex-start'} margin="9px 0 0 0">
                    <Icons name={'likes'} />
                    <Text margin={'0 0 0 10px'} fSize="12px">
                        {props.likes && getNumberWithSpaces(props.likes)}
                    </Text>
                </Flex>

                <Flex content={'flex-start'} margin="9px 0 0 0">
                    <Icons name={'comments'} />
                    <Text margin={'0 0 0 10px'} fSize="12px">
                        {props.comments && getNumberWithSpaces(props.comments)}
                    </Text>
                </Flex>

                <Flex content="space-around">
                    <a href={`https://www.instagram.com/${props.username}`} target="_blank" rel="noreferrer noopener">
                        <Button variant="outline">{t('ads_explorer.open_profile')}</Button>
                    </a>

                    <Flex>
                        <AddToList
                            type={Platform.Instagram}
                            external_id={props?.userId ? props.userId.toString() : ''}
                            place="competitor_analysis"
                        />
                    </Flex>
                </Flex>
            </div>
        )
    }
    return (
        <div className={cn.searchItem}>
            <Flex content={'center'} onClick={openPost} style={{ cursor: 'pointer' }} ref={ref}>
                <blockquote
                    className="instagram-media insta-embed"
                    style={{
                        borderRadius: '4px',
                        border: 'none',
                        boxShadow: 'none',
                        display: 'block',
                        margin: '0px 0px 12px',
                        height: '300px',
                        overflowY: 'scroll',
                    }}
                    data-instgrm-captioned
                    data-instgrm-permalink={`https://www.instagram.com/p/${props.code}/?utm_source=ig_embed&amp;utm_campaign=loading`}
                    data-instgrm-version="14"
                >
                    <Flex content="center" align="center" style={{ width: '100%', height: '100%' }}>
                        <Loader />
                    </Flex>
                </blockquote>
            </Flex>

            <BloggerLink newTab id={props.userId} platform={Platform.Instagram}>
                <div className={cn['searchItem--info']}>
                    <img src={props.profilePicture} alt={'avatar'} width={'34px'} height={'34px'} />

                    <div className={cn['searchItem--info--blogger']}>
                        <Text color={'violet-1'} fSize={14} bold>
                            {props.username}
                        </Text>
                        <Text margin={'3px 0 0 0'} color={'gray-3'} fSize={12}>
                            {moment(props.postDate).format('DD.MM.YYYY')}
                        </Text>
                    </div>
                </div>
            </BloggerLink>

            <div style={{ margin: '10px 0 0 0' }}>
                <PostBrands brand={props.brand} />
            </div>

            <Text color={'gray-1'} fSize={12} margin={'12px 0 16px 0'} style={{ width: '270px' }}>
                {props.text && props.text.slice(0, 110)}
            </Text>

            <Flex content={'space-between'}>
                <Flex content={'flex-start'} align={'center'}>
                    <Icons name={'likes'} />
                    <Text margin={'0 0 0 10px'}>{props.likes && getNumberWithSpaces(props.likes)}</Text>
                </Flex>

                <Flex content={'flex-start'} align={'center'}>
                    <Icons name={'comments'} />
                    <Text margin={'0 0 0 10px'}>{props.comments && getNumberWithSpaces(props.comments)}</Text>
                </Flex>
            </Flex>
        </div>
    )
}

export default memo(SearchItem)
