import { APIService } from '@/_helpers/APIService.js'
import { authHeader } from '@/_helpers/auth-header.js'

const service = new APIService({
	methods: {
		loadInfo: () => ({
			url: "/api/dicts/company/",
			headers: authHeader()
		}),
		promoCheck: (coupon) => ({
			url: "/promo/check-coupon/",
			headers: authHeader(),
			params: { coupon }
		})
	}
})

export { service as default }