import { TwitchSearchItem } from '../types'
import { TwitchGameCategory } from './queries'
import { QueryType, TwitchUserInfoResult } from './types'

export const getTwitchChannelsFromRaw = (queryType: QueryType, result: TwitchUserInfoResult, sliceIndex: number) => {
    const data = result[queryType]
    if (!data || data.length === 0) {
        return [] as TwitchSearchItem[]
    }

    const unlockedChannels = result?.getUnlockedChannels?.unlocked_twitch_channels

    return data.slice(0, sliceIndex).map((item) => {
        const { id, login, subscriber_count, profile_image_url, median_view_count_20_videos } = item

        return {
            id,
            username: login,
            followers: subscriber_count,
            avatar: profile_image_url,
            averageViews: median_view_count_20_videos,
            unlocked: unlockedChannels?.includes(id),
        } as TwitchSearchItem
    })
}

export const transformTwitchGames = (games: TwitchGameCategory[]) => {
    if (!games || games.length === 0) return [{ label: 'ERROR', value: '' }]

    return games.map((i) => ({
        label: i.name,
        value: i.id,
    }))
}
