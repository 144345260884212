import Tag from '@/pages/SearchPage/Bloggers/SearchPanel/Chips/Tag'
import { trackFilterChange } from '@/_helpers/amplitude/track'
import useOpenClose from '@/_helpers/useOpenClose'
import React, { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {} from 'react-redux'
import { BasicFilter, BasicFiltersOptions } from '../BasicFilter'
import FilterBase from '../FilterBase'
import Text from '@/components/Text'
import { CustomSlider } from '../Slider/CustomSlider'
import Popup from '@/_yc/Popup/Popup'
import Scrollbars from 'react-custom-scrollbars'
import Item from '../SelectorFilter/Item'
import Flex from '@/_yc/Flex'

import cn from './FilterWithDate.module.scss'
import Icons from '@/components/Icons'

export type WithDateType = {
    days: number | null
    value: number | undefined
}

interface FilterWithDateOptions {
    options?: Array<{ label: string; value: string | number }>
    rangeConfig?: {
        default: number
        step: number
        max: number
    }
}

const daysOptionsDefault = [
    { label: '7d', value: 7 },
    { label: '30d', value: 30 },
    { label: '180d', value: 180 },
]

export class FilterWithDate<ServerData> extends BasicFilter<ServerData, WithDateType> {
    daysOptions: { label: string; value: number | string }[] = daysOptionsDefault
    rangeConfigDefault = {
        default: 500,
        step: 100,
        max: 100000,
    }

    constructor({
        options,
        rangeConfig,
        ...basic
    }: FilterWithDateOptions & BasicFiltersOptions<ServerData, WithDateType>) {
        super(basic)
        if (options) this.daysOptions = options
        if (rangeConfig) this.rangeConfigDefault = rangeConfig
    }

    getDefaultValue() {
        return {
            days: null,
            value: this.rangeConfigDefault ? this.rangeConfigDefault.default : 500,
        }
    }

    MainView = (props: { filterName: string }) => {
        return <div>{props.filterName}</div>
    }

    View = () => {
        const [state, setState] = this.useFilterState()
        const [growthValue, setGrowthValue] = useState(this.rangeConfigDefault.default)
        const [isOpen, open, close] = useOpenClose()

        const { t } = useTranslation()
        const locked = this.useIsLocked()
        const ref = useRef<HTMLDivElement>(null)

        const width = useMemo(() => {
            if (!ref.current || !isOpen) return
            return {
                width: window.getComputedStyle(ref.current).width,
            }
        }, [isOpen])

        const changeDays = useCallback(
            (day: number) => {
                setState((prev) => {
                    if (prev.days === day) return { days: null, value: undefined }

                    //not necessary if statement. Was added when daysOptions were constans and only number (days).
                    //After adding prop options, statement was commented
                    // if (day === 180 || day === 30 || day === 7) {
                    return { days: day, value: prev.value }
                    // }
                })
            },
            [setState]
        )

        const changeGrowthVal = useCallback((event: ChangeEvent<{}>, val: number | number[]) => {
            if (Array.isArray(val)) return
            setGrowthValue(val)
        }, [])

        const changeVal = useCallback(
            (event: ChangeEvent<{}>, val: number | number[]) => {
                if (Array.isArray(val)) {
                    return
                }

                setState((prev) => {
                    const newValue = { days: prev.days, value: val }
                    trackFilterChange({
                        t,
                        title: this.options.title,
                        value: newValue,
                        platform: this.options.platform,
                    })

                    return newValue
                })
            },
            [setState, t]
        )

        const daysLabel = useMemo(() => {
            return this.daysOptions.find((i) => i.value === state.days)?.label
        }, [state.days])

        return (
            <FilterBase disabled={locked} onClick={open} isActive={isOpen} ref={ref} style={width}>
                <Text color={!daysLabel ? 'gray-4' : 'gray-1'} fSize={14}>
                    {daysLabel || t('filters.global.any')}
                </Text>

                <Flex align="center">
                    <div className={cn.label}>
                        <Text color="gray-3" fSize={14}>
                            {growthValue}
                        </Text>
                    </div>

                    <Icons name="arrow-down" className={cn[isOpen ? 'icon--opened' : 'icon--closed']} />
                </Flex>

                <Popup
                    isOpen={isOpen}
                    onClose={close}
                    anchor={ref.current}
                    style={{ zIndex: 10, marginTop: 7 }}
                    placement={'bottom-start'}
                >
                    <div>
                        <div style={width} className={cn.popper}>
                            <Scrollbars autoHeight autoHeightMax={200}>
                                {this.daysOptions.map((item, i) => (
                                    <Item key={i} {...item} isActive={state.days === item.value} onClick={changeDays} />
                                ))}
                            </Scrollbars>
                        </div>
                        <div
                            style={{ ...width, marginTop: '6px' }}
                            className={`${cn.popper} ${daysLabel ? '' : cn['popper--disabled']} ${
                                cn['popper--slider']
                            }`}
                        >
                            <Text fSize={12} color="gray-new-2" margin="0 0 10px 0">
                                Subscribers
                            </Text>
                            <CustomSlider
                                value={growthValue}
                                onChange={changeGrowthVal}
                                onChangeCommitted={changeVal}
                                getAriaLabel={() => ''}
                                valueLabelDisplay="on"
                                disabled={!daysLabel}
                                min={0}
                                step={this.rangeConfigDefault.step}
                                max={this.rangeConfigDefault.max}
                            />
                        </div>
                    </div>
                </Popup>
            </FilterBase>
        )
    }
    Tags = () => {
        const [{ days, value }, , clear] = this.useFilterState()

        const { t } = useTranslation()

        const label = `in ${days} days - +${value ?? 0} subscribers`

        return <Tag label={`${t(this.options.title)}: ${label}`} onDelete={clear} />
    }
}
