import React, { FC } from 'react'
import Text from '@/components/Text'
import cn from './FilterButton.module.scss'
import Icons, { TUiIconName } from '@/components/Icons'

interface FilterButtonProps {
    text: string
    active: boolean
    onClick: () => void
    icon: TUiIconName
}

export const FilterButton: FC<FilterButtonProps> = ({ text, active, onClick, icon }) => {
    const state = active ? 'active' : 'default'
    return (
        <div className={cn[`filterButton--${state}`]} onClick={onClick}>
            <Icons name={icon} className={cn[`icon--${state}`]} />
            <Text fSize={12} className={cn[`text--${state}`]}>
                {text}
            </Text>
        </div>
    )
}
