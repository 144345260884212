import Service from '@/_helpers/Service'
import { DjangoPaggination, NativeNotificationModel } from '@/Reponses'
import { PagginationService } from '@/_helpers/PagginationService'

const PullAllService = new PagginationService<NativeNotificationModel<any>>(
    '/api/events/all/'
)
const PullNewService = new Service<
    DjangoPaggination<NativeNotificationModel<any>>
>('/api/events/new/')
const PullCountService = new Service<{ count: number }>(
    '/api/events/new/count/'
)

export const PullAll = (token: string, page: number) =>
    PullAllService.call({
        params: { page },
        headers: {
            Authorization: `Token ${token}`,
        },
    })

export const PullNew = (token: string, from: Date) =>
    PullNewService.call({
        params: { from: from.toISOString() },
        headers: {
            Authorization: `Token ${token}`,
        },
    })

export const PullCount = (token: string) =>
    PullCountService.call({
        headers: {
            Authorization: `Token ${token}`,
        },
    })

export const clearEvents = async (token: string) => {
    await PullAllService.getAll(undefined, token)
}
