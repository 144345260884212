import React, { FC, CSSProperties, useCallback, useRef, useMemo } from 'react'

import { LoginInput } from '@/_yc/'

import cn from './Locker.module.scss'
import BloggerLink from '@/components/Share/BloggerLink'
import useOpenClose from '@/_helpers/useOpenClose'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import useModalHidden from './useModalHidden'
import Popup from '@/_yc/Popup'
import Button from '@/components/Button'
import { TARIFFS } from '@/routs'
import { trackOpenReport } from '@/_helpers/amplitude/track'
import { Platform } from '@/pages/Reports/types'
import { VkAccountType } from '@/pages/SearchPage/SearchAdapter/bloggers/vk/utils/types'

const width = {
    width: 'calc(50% - 5px)',
}

const btnStyle: CSSProperties = {
    width: '100%',
    paddingRight: 0,
    paddingLeft: 0,
    textAlign: 'center',
}

const btnWidth: CSSProperties = {
    ...btnStyle,
    ...width,
}

const padding: CSSProperties = {
    padding: '0 0 20px',
}

export interface ILocker {
    id: string | number
    label: string
    style?: CSSProperties
    xs?: boolean
    disabled?: boolean
    isPromo: boolean
    platform: Platform
    accountType?: VkAccountType
}

const Locker: FC<ILocker> = (props) => {
    const t = useSpaceTranslation('Influencer_explorer.found_blogger.btnmenu')
    const block = useRef<HTMLAnchorElement>(null)
    const [hidden, setHidden] = useModalHidden()

    const [state, open, close] = useOpenClose()

    const closeWithEffect = useCallback(() => {
        hidden && setHidden(false)
        close()
    }, [setHidden, close, hidden])

    const click = useCallback(() => {
        if (hidden || props.isPromo) return
        open()
    }, [hidden, open, props.isPromo])

    const style = useMemo<CSSProperties>(
        () => ({
            flexGrow: props.xs ? 1 : undefined,
            textAlign: props.xs ? 'center' : undefined,
            ...props.style,
        }),
        [props.xs, props.style]
    )

    return (
        <>
            <Button
                size="small"
                component={BloggerLink}
                newTab
                id={props.id}
                disabled={!hidden && !props.isPromo}
                ref={block}
                onClick={click}
                style={style}
                className={cn.locker}
                platform={props.platform}
                failLink={`${TARIFFS}?error=error`}
                accountType={props.accountType}
                isPromo={props.isPromo}
            >
                {props.label}
            </Button>
            <Popup isOpen={state} onClose={closeWithEffect} anchor={block.current}>
                <div className={cn.popup}>
                    <h3>{t('title')}</h3>
                    <LoginInput
                        title={t('check')}
                        type="radial"
                        value={hidden}
                        onChange={setHidden}
                        disablePad
                        style={padding}
                    />
                    <div className={cn['btns']}>
                        <BloggerLink
                            style={width}
                            id={props.id}
                            newTab
                            platform={props.platform}
                            failLink={`${TARIFFS}?error=error`}
                            onClick={() => trackOpenReport(`infl_exp_${props.platform}`)}
                            accountType={props.accountType}
                            isPromo={props.isPromo}
                        >
                            <Button style={btnStyle} size="small" onClick={close}>
                                {t('ok')}
                            </Button>
                        </BloggerLink>
                        <Button color={'optional'} style={btnWidth} size="small" onClick={closeWithEffect}>
                            {t('cancel')}
                        </Button>
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default Locker
