import React from 'react'

import Icons from '@/components/Icons'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { SelectorFilter } from '../../../../filters/SelectorFilter'
import { GET_TOPICS } from '../../queries'
import { ITopicsQuery } from '../../types'
import { preprocessTopics } from '../../utils/filters/prepocess'
import { toQueryWithOR } from '../../utils/queries/toQueryWithOR'
import { PLATFORM } from '../consts'

const getGqlFilter = (topics: string[]) => {
    if (!topics || topics.length === 0) return ''
    const ids = topics.map((v) => v.split(',')).flat()

    const filterName = 'channel_topics'
    const filterId = 'channeltopic_id'
    return toQueryWithOR(ids, filterName, filterId)
}

const gamingOptions = [
    {
        value: '/m/025zzc',
        label: 'Action game',
    },
    {
        value: '/m/02ntfj',
        label: 'Action-adventure Game',
    },
    {
        value: '/m/0b1vjn',
        label: 'Casual game',
    },
    {
        value: '/m/02hygl',
        label: 'Music video game',
    },
    {
        value: '/m/04q1x3q',
        label: 'Puzzle video game',
    },
    {
        value: '/m/01sjng',
        label: 'Racing video game',
    },
    {
        value: '/m/0403l3g',
        label: 'Role-playing video game',
    },
    {
        value: '/m/021bp2',
        label: 'Simulation video game',
    },
    {
        value: '/m/022dc6',
        label: 'Sports game',
    },
    {
        value: '/m/03hf_rm',
        label: 'Strategy video game',
    },
]

export const getInterestsFilter = (fetcher: Fetcher, isMain?: boolean) => {
    return new SelectorFilter({
        platform: PLATFORM,
        title: 'filters.cat.fields.channel.label',
        autocomplete: !isMain,
        helper: 'filters.cat.fields.channel.text',
        icon: <Icons name="filter--interests--main" />,
        space: 1,
        id: 'interests_yt',
        isMulti: true,
        checkmarkOptions: gamingOptions,
        options: async (token: string) => {
            const client = fetcher.getClient()
            const res = await client.query<ITopicsQuery>({ query: GET_TOPICS }).then((res) => {
                const topics = res.data
                return preprocessTopics(topics)
            })
            return res
        },
        beforeSend: ({ value }) => ({
            name: 'interests',
            type: 'array',
            value: value,
            gqlFilter: getGqlFilter(value),
        }),
    })
}
