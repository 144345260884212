import React from 'react'

import Flex from '@/_yc/Flex'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

import cn from './Feedback.module.scss'

const Feedback = () => {
    const t = useSpaceTranslation('canny_feedback')
    return (
        <>
            <a href={'/feedback'}>
                <Flex className={cn.root}>
                    <p className={cn.text}>{t('button_title')}</p>
                </Flex>
            </a>
        </>
    )
}

export default Feedback
