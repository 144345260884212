import LegacyNotification from '@/components/Notifier/MessageTypes/LegacyNotification'
import { useCallback } from 'react'
import { Icon } from '@/components/Notifier/MessageTypes/Types'
import { push } from '@/components/Notifier/PushNotification'

/**
 * @returns notify function that takes notification content and icon
 */
const useSystemNotification = () => {
    const notify = useCallback((content: any, icon: Icon) => {
        push(new LegacyNotification(content, icon))
    }, [])

    return { notify }
}

export default useSystemNotification
