import { createReducer } from '@reduxjs/toolkit'
import {
    AddToListINST,
    DeleteFromListINST,
    AddToListYT,
    DeleteFromListYT,
    RemoveListINST,
    RemoveListYT,
    RemoveListVk,
    RemoveListTg,
    SetBloggersMailingList,
    RemoveFromMailingList,
    SetLastClicked,
    SetMailingListSetId,
    AddToListTwitch,
    DeleteFromListTwitch,
    AddToListVk,
    DeleteFromListVk,
    RemoveListTwitch,
    AddToListsAud,
    DeleteFromListsAud,
    RemoveListsAud,
    AddToListMediaPlannerPlans,
    DeleteFromListMediaPlannerPlans,
    RemoveListMediaPlannerPlans,
    AddToListMediaPlannerBloggers,
    DeleteFromListMediaPlannerBloggers,
    RemoveListMediaPlannerBloggers,
    SetAllBloggers,
    SetAllMediaPlans,
    SetDefault,
    SetAllMediaBloggers,
    AddToListTg,
    DeleteFromListTg,
} from './actions'
import { IMassAddToList } from './types'

const defaultState: IMassAddToList = {
    allItems: {
        allBloggers: [],
        allMediaPlans: [],
        allMediaBloggers: [],
    },
    dataINST: [],
    dataYT: [],
    dataTwitch: [],
    dataVk: [],
    dataTg: [],
    mailingList: {
        instList: [],
        ytList: [],
        setId: [],
    },
    mediaPlanner: {
        selectedMediaPlans: [],
        selectedBloggers: [],
    },
    lastClicked: undefined,
    listsAud: [],
}

const MassAddToList = createReducer<IMassAddToList>(defaultState, (builder) => {
    builder.addCase(AddToListINST, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                dataINST: state.dataINST.concat(payload),
            }
        }
    })
    builder.addCase(DeleteFromListINST, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                dataINST: state.dataINST.filter((item) => item.id !== payload),
            }
        }
    })
    builder.addCase(AddToListYT, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                dataYT: state.dataYT.concat(payload),
            }
        }
    })
    builder.addCase(DeleteFromListYT, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                dataYT: state.dataYT.filter((item) => item.id !== payload),
            }
        }
    })
    builder.addCase(RemoveListINST, (state) => {
        return {
            ...state,
            dataINST: [],
        }
    })
    builder.addCase(RemoveListYT, (state) => {
        return {
            ...state,
            dataYT: [],
        }
    })
    builder.addCase(RemoveListVk, (state) => {
        return {
            ...state,
            dataVk: [],
        }
    })
    builder.addCase(RemoveListTg, (state) => {
        return {
            ...state,
            dataTg: [],
        }
    })
    builder.addCase(SetBloggersMailingList, (state, payload) => {
        return {
            ...state,
            mailingList: payload.payload,
        }
    })
    builder.addCase(RemoveFromMailingList, (state, { payload }) => {
        const mailingList = state.mailingList
        const instList = mailingList.instList.filter((item) => item.id !== payload)
        const ytList = mailingList.ytList.filter((item) => item.id !== payload)

        // set updated mailing list to local storage
        localStorage.setItem('mailingList', JSON.stringify({ instList, ytList }))

        return {
            ...state,
            mailingList: {
                instList,
                ytList,
                setId: mailingList.setId,
            },
        }
    })
    builder.addCase(SetMailingListSetId, (state, payload) => {
        return {
            ...state,
            mailingList: {
                ...state.mailingList,
                setId: payload.payload,
            },
        }
    })
    builder.addCase(SetLastClicked, (state, payload) => {
        return {
            ...state,
            lastClicked: payload.payload,
        }
    })
    builder.addCase(AddToListTwitch, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                dataTwitch: state.dataTwitch.concat(payload),
            }
        }
    })
    builder.addCase(DeleteFromListTwitch, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                dataTwitch: state.dataTwitch.filter((item) => item.id !== payload),
            }
        }
    })
    builder.addCase(AddToListVk, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                dataVk: state.dataVk.concat(payload),
            }
        }
    })
    builder.addCase(DeleteFromListVk, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                dataVk: state.dataVk.filter((item) => item.id !== payload),
            }
        }
    })
    builder.addCase(AddToListTg, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                dataTg: state.dataTg.concat(payload),
            }
        }
    })
    builder.addCase(DeleteFromListTg, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                dataTg: state.dataTg.filter((item) => item.id !== payload),
            }
        }
    })
    builder.addCase(RemoveListTwitch, (state) => {
        return {
            ...state,
            dataTwitch: [],
        }
    })
    builder.addCase(AddToListsAud, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                listsAud: state.listsAud.concat(payload),
            }
        }
    })
    builder.addCase(DeleteFromListsAud, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                listsAud: state.listsAud.filter((item) => item.id !== payload),
            }
        }
    })
    builder.addCase(RemoveListsAud, (state) => {
        return {
            ...state,
            listsAud: [],
        }
    })
    builder.addCase(AddToListMediaPlannerPlans, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                mediaPlanner: {
                    ...state.mediaPlanner,
                    selectedMediaPlans: state.mediaPlanner.selectedMediaPlans.concat(payload),
                },
            }
        }
    })
    builder.addCase(DeleteFromListMediaPlannerPlans, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                mediaPlanner: {
                    ...state.mediaPlanner,
                    selectedMediaPlans: state.mediaPlanner.selectedMediaPlans.filter((item) => item !== payload),
                },
            }
        }
    })
    builder.addCase(RemoveListMediaPlannerPlans, (state) => {
        return {
            ...state,
            mediaPlanner: {
                ...state.mediaPlanner,
                selectedMediaPlans: [],
            },
        }
    })
    builder.addCase(AddToListMediaPlannerBloggers, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                mediaPlanner: {
                    ...state.mediaPlanner,
                    selectedBloggers: state.mediaPlanner.selectedBloggers.concat(payload),
                },
            }
        }
    })
    builder.addCase(DeleteFromListMediaPlannerBloggers, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                mediaPlanner: {
                    ...state.mediaPlanner,
                    selectedBloggers: state.mediaPlanner.selectedBloggers.filter((item) => item !== payload),
                },
            }
        }
    })
    builder.addCase(RemoveListMediaPlannerBloggers, (state) => {
        return {
            ...state,
            mediaPlanner: {
                ...state.mediaPlanner,
                selectedBloggers: [],
            },
        }
    })
    builder.addCase(SetAllBloggers, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                allItems: {
                    ...state.allItems,
                    allBloggers: payload,
                },
            }
        }
    })
    builder.addCase(SetAllMediaPlans, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                allItems: {
                    ...state.allItems,
                    allMediaPlans: payload,
                },
            }
        }
    })
    builder.addCase(SetAllMediaBloggers, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                allItems: {
                    ...state.allItems,
                    allMediaBloggers: payload,
                },
            }
        }
    })
    builder.addCase(SetDefault, (state) => {
        return {
            ...defaultState,
            mailingList: state.mailingList,
        }
    })
})
export default MassAddToList
