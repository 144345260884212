import Service from '@/_helpers/Service'

const downloadXlsService = new Service<any>('')
export const downloadXlsReq = (token: string, orderId: string) => {
    return downloadXlsService.call(
        {
            url: `/api/media-plan/export/?order_id=${orderId}`,
            responseType: 'arraybuffer',
        },
        token
    )
}
