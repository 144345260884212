import { AutocompleteResult } from '@/Reponses'
import { searchBloggerByUsername } from '@/Services/Search'
import useToken from '@/_helpers/Hooks/useToken'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useState, useEffect, useMemo } from 'react'
import { BehaviorSubject, from, of } from 'rxjs'
import { debounceTime, distinctUntilChanged, switchMap, skipWhile, catchError, tap, map } from 'rxjs/operators'
import { parseUrl } from './parseUrl'
import { TitleSearchQuery, TwitchSearchQuery } from './types'
import {
    SEARCH_CHANNELS_BY_TITLE,
    SEARCH_TWITCH_CHANNELS_BY_TITLE,
    SEARCH_VK_ACCOUNTS,
    SEARCH_TG_ACCOUNTS,
    SEARCH_TIKTOK_ACCOUNTS,
} from './queries'
import { GET_UNLOCKED_CHANNELS } from '@/pages/Lists/model/graphQL'
import { IUnlockedChannels } from '@/pages/Lists/model/types'
import { Platform } from '@/pages/Reports/types'
import { VkUserInfoResult } from '@/pages/SearchPage/SearchAdapter/bloggers/vk/utils/types'
import { TgUserInfoResult } from '@/pages/SearchPage/SearchAdapter/bloggers/telegram/utils/types'
import { ITikTokSearchAccountQuery } from '@/pages/Reports/TikTokReport/types'

interface IUseSearch {
    yt?: boolean
    twitch?: boolean
    vk?: boolean
    tg?: boolean
    tiktok?: boolean
    instagram?: boolean
}

export const useSearch = ({ yt, twitch, vk, tg, tiktok, instagram }: IUseSearch) => {
    const [search, setSearch] = useState('')
    const [instResults, setInstResults] = useState<AutocompleteResult[]>(() => [])
    const [combinedResults, setCombinedResults] = useState<AutocompleteResult[]>(() => [])
    const [current, setCurrent] = useState('')
    const [error, setError] = useState(false)
    const [isInstagramLoading, setIsInstagramLoading] = useState(false)

    const [searchChannelsByTitle, { data: titleQueryData, loading: titleQueryLoading, error: titleQueryError }] =
        useLazyQuery<TitleSearchQuery>(SEARCH_CHANNELS_BY_TITLE, {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        })

    const [searchTwitchByTitle, { data: twitchQueryData, loading: twitchQueryLoading, error: twitchQueryError }] =
        useLazyQuery<TwitchSearchQuery>(SEARCH_TWITCH_CHANNELS_BY_TITLE, {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        })

    const [searchVkAccount, { data: vkQueryData, loading: vkQueryLoading, error: vkQueryError }] =
        useLazyQuery<VkUserInfoResult>(SEARCH_VK_ACCOUNTS, {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        })

    const [searchTgAccount, { data: tgQueryData, loading: tgQueryLoading, error: tgQueryError }] =
        useLazyQuery<TgUserInfoResult>(SEARCH_TG_ACCOUNTS, {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        })

    const [searchTikTokAccount, { data: tiktokQueryData, loading: tiktokQueryLoading, error: tiktokQueryError }] =
        useLazyQuery<ITikTokSearchAccountQuery>(SEARCH_TIKTOK_ACCOUNTS, {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        })

    const unlockedChannelsIds = useQuery<IUnlockedChannels>(GET_UNLOCKED_CHANNELS, {
        skip: !yt,
        fetchPolicy: 'cache-first',
    })

    const clearSearch = () => setSearch('')

    const subj = useMemo(() => {
        return new BehaviorSubject<string>('')
    }, [])

    const token = useToken()

    useEffect(() => {
        subj.next(search)
    }, [subj, search])

    useEffect(() => {
        setError(false)
    }, [search])

    useEffect(() => {
        if (!token || !instagram) return
        const sub = subj
            .pipe(
                debounceTime(500),
                skipWhile((val) => !val),
                distinctUntilChanged(),
                map(parseUrl),
                tap((value) => setCurrent(value)),
                switchMap((search) => {
                    setIsInstagramLoading(true)

                    return from(searchBloggerByUsername(token, search)).pipe(
                        catchError(() => {
                            setError(true)
                            setIsInstagramLoading(false)
                            return of([] as AutocompleteResult[])
                        }),
                        tap(() => setIsInstagramLoading(false))
                    )
                })
            )
            .subscribe(setInstResults)
        return () => sub.unsubscribe()
    }, [subj, token, instagram])

    useEffect(() => {
        if (!yt || search.length === 0) return
        const delayedSearch = setTimeout(() => {
            searchChannelsByTitle({
                variables: {
                    search: search, //parseUrl(search).trim(),
                    limit: 5,
                },
            })
        }, 500)

        return () => {
            clearTimeout(delayedSearch)
        }
    }, [yt, search, searchChannelsByTitle])

    useEffect(() => {
        if (!twitch || search.length === 0) return

        const delayedSearch = setTimeout(() => {
            searchTwitchByTitle({
                variables: {
                    search: search, //parseUrl(search).trim(),
                    limit: 5,
                },
            })
        }, 500)

        return () => {
            clearTimeout(delayedSearch)
        }
    }, [twitch, search, searchTwitchByTitle])

    useEffect(() => {
        if (!vk || search.length === 0) return

        const delayedSearch = setTimeout(() => {
            searchVkAccount({
                variables: {
                    search: search, //parseUrl(search).trim(),
                    limit: 5,
                },
            })
        }, 500)

        return () => {
            clearTimeout(delayedSearch)
        }
    }, [vk, search, searchVkAccount])

    useEffect(() => {
        if (!tg || search.length === 0) return

        const delayedSearch = setTimeout(() => {
            searchTgAccount({
                variables: {
                    search: parseUrl(search).trim(),
                    limit: 5,
                },
            })
        }, 500)

        return () => {
            clearTimeout(delayedSearch)
        }
    }, [tg, search, searchTgAccount])

    useEffect(() => {
        if (!tiktok || search.length === 0) return

        const delayedSearch = setTimeout(() => {
            searchTikTokAccount({
                variables: {
                    search: parseUrl(search).trim(),
                    limit: 5,
                },
            })
        }, 500)

        return () => {
            clearTimeout(delayedSearch)
        }
    }, [tiktok, search, searchTgAccount, searchTikTokAccount])

    const twitchResults: AutocompleteResult[] = useMemo(() => {
        if (!twitch) return []

        const isReady =
            !twitchQueryError &&
            !twitchQueryLoading &&
            twitchQueryData &&
            twitchQueryData.search_general_twitch_channels
        const unlockedChannels = unlockedChannelsIds.data?.getUnlockedChannels.unlocked_twitch_channels

        return isReady && twitchQueryData
            ? twitchQueryData.search_general_twitch_channels.map((channel) => ({
                  username: channel.login,
                  avatar: channel.profile_image_url,
                  external_id: channel.id,
                  followers: channel.subscriber_count,
                  fullname: channel.login,
                  is_promo: false,
                  unlocked: unlockedChannels ? unlockedChannels.includes(channel.id) : false,
                  type: Platform.Twitch,
              }))
            : []
    }, [twitchQueryData, twitchQueryLoading, twitchQueryError, twitch, unlockedChannelsIds])

    const vkResults: AutocompleteResult[] = useMemo(() => {
        if (!vk) return []

        const isReady = !vkQueryError && !vkQueryLoading && vkQueryData && vkQueryData.search_general_vk_account
        const unlockedChannels = unlockedChannelsIds.data?.getUnlockedChannels.unlocked_vk_accounts
        const isUnlocked = (id: string, type: string) => {
            const temp = unlockedChannels?.filter((item) => item.account_type === type && item.vk_account_id === id)
            return temp ? temp.length > 0 : false
        }

        return isReady && vkQueryData && vkQueryData.search_general_vk_account
            ? vkQueryData.search_general_vk_account.map((channel) => ({
                  username: channel.username,
                  avatar: channel.avatar_link,
                  external_id: channel.account_id,
                  followers: channel.followers,
                  fullname: channel.full_name,
                  is_promo: false,
                  unlocked: isUnlocked(channel.account_id, channel.account_type), //unlockedChannels ? unlockedChannels.includes(channel.account_id) : false,
                  type: Platform.Vk,
                  vkAccountType: channel.account_type || 'user',
              }))
            : []
    }, [vkQueryData, vkQueryLoading, vkQueryError, vk, unlockedChannelsIds])

    const tgResults: AutocompleteResult[] = useMemo(() => {
        if (!tg) return []

        const isReady =
            !tgQueryError && !tgQueryLoading && tgQueryData && tgQueryData.search_telegram_by_title_description
        const unlockedChannels = unlockedChannelsIds.data?.getUnlockedChannels.unlocked_tg_accounts

        return isReady && tgQueryData && tgQueryData.search_telegram_by_title_description
            ? tgQueryData.search_telegram_by_title_description.map((channel) => ({
                  username: channel.username,
                  avatar: channel.avatar,
                  external_id: channel.id,
                  followers: channel.subscribers_count,
                  fullname: channel.title,
                  is_promo: false,
                  unlocked: unlockedChannels ? unlockedChannels.includes(channel.id) : false, //unlockedChannels ? unlockedChannels.includes(channel.account_id) : false,
                  type: Platform.Tg,
              }))
            : []
    }, [tgQueryData, tgQueryLoading, tgQueryError, tg, unlockedChannelsIds])

    const tiktokResults: AutocompleteResult[] = useMemo(() => {
        if (!tiktok) return []

        const isReady =
            !tiktokQueryError && !tiktokQueryLoading && tiktokQueryData && tiktokQueryData.searchTikTokBlogger
        const unlockedChannels = unlockedChannelsIds.data?.getUnlockedChannels.unlocked_tt_accounts

        return isReady && tiktokQueryData && tiktokQueryData.searchTikTokBlogger
            ? tiktokQueryData.searchTikTokBlogger.data.accounts
                  .filter((channel) => !channel.account.hidden_result)
                  .map((channel) => ({
                      username: channel.account.user_profile.username,
                      avatar: channel.account.user_profile.picture,
                      external_id: channel.account.user_profile.user_id,
                      followers: channel.account.user_profile.followers,
                      fullname: channel.account.user_profile.fullname,
                      is_promo: false,
                      unlocked: unlockedChannels
                          ? unlockedChannels.includes(channel.account.user_profile.user_id)
                          : false,
                      type: Platform.TikTok,
                  }))
            : []
    }, [tiktokQueryData, tiktokQueryLoading, tiktokQueryError, tiktok, unlockedChannelsIds])

    const ytTitleResults: AutocompleteResult[] = useMemo(() => {
        if (!yt) return []

        const isReady =
            !titleQueryError && !titleQueryLoading && titleQueryData && titleQueryData.search_general_youtube_channels
        const unlockedChannels = unlockedChannelsIds.data?.getUnlockedChannels.unlocked_youtube_channels

        return isReady && titleQueryData
            ? titleQueryData.search_general_youtube_channels.map((channel) => ({
                  username: channel.title,
                  avatar: channel.avatar,
                  external_id: channel.id,
                  followers: channel.subscriber_count,
                  fullname: channel.title,
                  is_promo: false,
                  unlocked: unlockedChannels ? unlockedChannels.includes(channel.id) : false,
                  type: Platform.YouTube,
              }))
            : []
    }, [titleQueryData, titleQueryLoading, titleQueryError, yt, unlockedChannelsIds])

    useEffect(() => {
        let newResults: AutocompleteResult[] = []

        if (search.length === 0) {
            setCombinedResults([])
            return
        }

        if (instagram) {
            newResults = instResults.map((res) => ({ ...res, type: Platform.Instagram }))
        }
        if (yt) {
            newResults = newResults.concat(ytTitleResults)
        }
        if (twitch) {
            newResults = newResults.concat(twitchResults)
        }
        if (vk) {
            newResults = newResults.concat(vkResults)
        }
        if (tg) {
            newResults = newResults.concat(tgResults)
        }
        if (tiktok) {
            newResults = newResults.concat(tiktokResults)
        }

        setCombinedResults(newResults)
    }, [
        instResults,
        ytTitleResults,
        twitchResults,
        vkResults,
        tgResults,
        tiktokResults,
        instagram,
        yt,
        twitch,
        vk,
        tg,
        tiktok,
        search,
    ])

    const isLoading = useMemo(() => {
        return (
            titleQueryLoading ||
            isInstagramLoading ||
            twitchQueryLoading ||
            vkQueryLoading ||
            tgQueryLoading ||
            tiktokQueryLoading
        )
    }, [titleQueryLoading, isInstagramLoading, twitchQueryLoading, vkQueryLoading, tgQueryLoading, tiktokQueryLoading])

    return {
        search,
        setSearch,
        results: combinedResults,
        current,
        clearSearch,
        error,
        isLoading,
    }
}
