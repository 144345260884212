import { useSpaceTranslation } from '@/_helpers'
import React, { useCallback, useMemo } from 'react'
import Text from '@/components/Text'
import cn from './Header.module.scss'
import { ChooseAllNEW } from '@/components/MassAddToList/ChooseAll'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import { clearSelectedCampaigns, updateAdvertisingCampaigns } from '@/pages/Outreach/Model/actions'
import useToken from '@/_helpers/Hooks/useToken'
import { CampaignAction, campaignsActionsReq, getAdvertisingCampaignsReq } from '@/pages/Outreach/Service/Campaigns'
import { Tooltip } from '@yoloker/ui-collection'

export const Header = () => {
    const t = useSpaceTranslation('outreach.stats.table.header')
    const selectedCampaigns = useSelector((state: RootStore) => state.Outreach.selectedCampaigns)
    const campaigns = useSelector((state: RootStore) => state.Outreach.advertisingCampaigns)
    const showSelectedMenu = useMemo(() => selectedCampaigns.length > 0, [selectedCampaigns])
    const activeStatusFilter = useSelector((state: RootStore) => state.Outreach.campaignsFilter)

    const dispatch = useDispatch()
    const token = useToken()

    const onChooseAllClick = useCallback(() => {
        if (showSelectedMenu) {
            dispatch(clearSelectedCampaigns())
        }
    }, [showSelectedMenu, dispatch])

    const onlyArchived = useMemo(() => {
        if (selectedCampaigns.length === 0) return false
        const selectedData = campaigns.results.filter((item) => selectedCampaigns.includes(item.id))
        return selectedData.every((item) => item.stats.status === 'archived')
    }, [campaigns, selectedCampaigns])

    const updateCampaigns = useCallback(() => {
        if (!token) return
        const status = activeStatusFilter === 'all' ? undefined : activeStatusFilter
        getAdvertisingCampaignsReq(token, status).then((data) => {
            dispatch(updateAdvertisingCampaigns(data))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, activeStatusFilter])

    const onArchiveClick = useCallback(() => {
        if (!token) return
        const status = onlyArchived ? CampaignAction.Activate : CampaignAction.Archive
        campaignsActionsReq(token, status, selectedCampaigns).then(() => {
            dispatch(clearSelectedCampaigns())
            updateCampaigns()
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, selectedCampaigns, updateCampaigns, onlyArchived])

    const onDeleteClick = useCallback(() => {
        if (!token) return
        campaignsActionsReq(token, CampaignAction.Pause, selectedCampaigns).then(() => {
            dispatch(clearSelectedCampaigns())
            updateCampaigns()
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, selectedCampaigns, updateCampaigns])

    if (showSelectedMenu) {
        return (
            <div className={cn.menu}>
                <div onClick={onChooseAllClick}>
                    <ChooseAllNEW platform="outreach" value={showSelectedMenu ? 'dashed' : 'empty'} />
                </div>
                <div className={cn['menu-button']} onClick={onArchiveClick}>
                    <Text color="gray-2" fSize={10}>
                        {onlyArchived ? t('activate') : t('archive')}
                    </Text>
                </div>
                <div className={cn['menu-button']} onClick={onDeleteClick}>
                    <Text color="gray-2" fSize={10}>
                        {t('delete')}
                    </Text>
                </div>
            </div>
        )
    }

    return (
        <div className={cn.header}>
            <div onClick={onChooseAllClick}>
                <ChooseAllNEW platform="outreach" value={showSelectedMenu ? 'dashed' : 'empty'} />
            </div>

            <Text color="gray-3" fSize={10}>
                {t('title')}
            </Text>
            <Text color="gray-3" fSize={10}>
                {t('state')}
            </Text>
            <Text color="gray-3" fSize={10}>
                {t('sent')}
            </Text>

            <div className={cn.itemWithTooltip}>
                <Text color="gray-3" fSize={10}>
                    {t('opened')}
                </Text>
                <Tooltip text={t('opened_helper')}></Tooltip>
            </div>

            <div className={cn.itemWithTooltip}>
                <Text color="gray-3" fSize={10}>
                    {t('replied')}
                </Text>
                <Tooltip text={t('replied_helper')}></Tooltip>
            </div>

            <div className={cn.itemWithTooltip}>
                <Text color="gray-3" fSize={10}>
                    {t('bounce')}
                </Text>
                <Tooltip text={t('bounce_helper')}></Tooltip>
            </div>
            <Text color="gray-3" fSize={10}>
                {t('sender')}
            </Text>
            <Text color="gray-3" fSize={10}>
                {t('created')}
            </Text>
        </div>
    )
}
