import React, { FC } from 'react'

import Flex from '@/_yc/Flex'
import Text from '@/components/Text'
import { IHistoricalMetrics } from './service'
import { getNumberWithSpaces } from '@/_helpers/nums/numberWithSpaces'

import cn from './Report.module.scss'
import { useTranslation } from 'react-i18next'

interface IStatsTable {
    data: IHistoricalMetrics[]
}
export const StatsTable: FC<IStatsTable> = ({ data }) => {
    const { t } = useTranslation()
    const head = [
        t('campaign_manager.campaign.content.header.time'),
        t('campaign_manager.campaign.content.header.views'),
        null,
        t('campaign_manager.campaign.content.header.likes'),
        null,
        t('campaign_manager.campaign.content.header.comments'),
        null,
    ]

    const getDynamic = (i: number, type: 'likes' | 'views' | 'comments'): { value: string; state: number } => {
        const getState = (n: number) => {
            if (n > 0) return 0
            if (n < 0) return 1
            return 2
        }
        if (!data[i - 1] || !data[i - 1][type]) return { value: '0', state: 2 }
        const temp = data[i][type] - data[i - 1][type]
        console.log(temp, getNumberWithSpaces(temp))
        return {
            value: `${temp > 0 ? '+' : ''}${getNumberWithSpaces(temp).length === 0 ? '0' : getNumberWithSpaces(temp)}`,
            state: getState(data[i][type] - data[i - 1][type]),
        }
    }
    return (
        <>
            <div style={{ overflow: 'scroll', position: 'relative' }}>
                <div className={cn['table-header']}>
                    {head.map((item) => (
                        <Text color="gray-3" fSize="10px">
                            {item ?? ''}
                        </Text>
                    ))}
                </div>
                {data.map((item, i) => (
                    <>
                        <div className={cn['table-item']}>
                            <Text color="gray-1" fSize="12px">
                                {new Date(item.created_at).toLocaleDateString('ru')}
                            </Text>

                            <Text color="gray-1" fSize="12px">
                                {item.views ? getNumberWithSpaces(item.views) : ''}
                            </Text>
                            <Flex
                                style={{
                                    padding: '3px 8px',
                                    backgroundColor:
                                        getDynamic(i, 'views').state === 0
                                            ? '#dbf5e7'
                                            : getDynamic(i, 'views').state === 1
                                            ? '#FFE1E7'
                                            : '#E6EAF0',
                                    borderRadius: '4px',
                                    width: 'fit-content',
                                }}
                                align="center"
                            >
                                <Text
                                    style={
                                        getDynamic(i, 'views').state === 0
                                            ? { color: '#10BF61' }
                                            : getDynamic(i, 'views').state === 1
                                            ? { color: '#F5535D' }
                                            : { color: '#5D697D' }
                                    }
                                    fSize={10}
                                >
                                    {getDynamic(i, 'views').value}
                                </Text>
                            </Flex>

                            <Text color="gray-1" fSize="12px">
                                {item.likes ? getNumberWithSpaces(item.likes) : ''}
                            </Text>
                            <Flex
                                style={{
                                    padding: '3px 8px',
                                    backgroundColor:
                                        getDynamic(i, 'likes').state === 0
                                            ? '#dbf5e7'
                                            : getDynamic(i, 'likes').state === 1
                                            ? '#FFE1E7'
                                            : '#E6EAF0',
                                    borderRadius: '4px',
                                    width: 'fit-content',
                                }}
                                align="center"
                            >
                                <Text
                                    style={
                                        getDynamic(i, 'likes').state === 0
                                            ? { color: '#10BF61' }
                                            : getDynamic(i, 'likes').state === 1
                                            ? { color: '#F5535D' }
                                            : { color: '#5D697D' }
                                    }
                                    fSize={10}
                                >
                                    {getDynamic(i, 'likes').value}
                                </Text>
                            </Flex>

                            <Text color="gray-1" fSize="12px">
                                {item.comments ? getNumberWithSpaces(item.comments) : ''}
                            </Text>
                            <Flex
                                style={{
                                    padding: '3px 8px',
                                    backgroundColor:
                                        getDynamic(i, 'comments').state === 0
                                            ? '#dbf5e7'
                                            : getDynamic(i, 'comments').state === 1
                                            ? '#FFE1E7'
                                            : '#E6EAF0',
                                    borderRadius: '4px',
                                    width: 'fit-content',
                                }}
                                align="center"
                            >
                                <Text
                                    style={
                                        getDynamic(i, 'comments').state === 0
                                            ? { color: '#10BF61' }
                                            : getDynamic(i, 'comments').state === 1
                                            ? { color: '#F5535D' }
                                            : { color: '#5D697D' }
                                    }
                                    fSize={10}
                                >
                                    {getDynamic(i, 'comments').value}
                                </Text>
                            </Flex>
                        </div>
                        <div className={cn.dividerHoriz} />
                    </>
                ))}
            </div>
        </>
    )
}
