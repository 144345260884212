import React, { FC, useState, CSSProperties } from 'react'

import cn from './Matrix.module.scss'
import ListLayout, { ListItem, Segment } from '@/Layouts/ListLayout'
import Text from '@/components/Text'
import OverlapPercent from './OverlapPercent'
import SelectableAvatar from './SelectableAvatar'
import { ID } from '@/Reponses'

export interface IMatrix {
    legend: Array<{
        id: ID
        avatar: string
    }>
    rows: Array<{
        id: ID
        username: string
        fullName: string
        avatar: string
        data: Array<{
            id: ID
            percent: number
        }>
    }>
}

const relative: CSSProperties = {
    position: 'relative',
}

const Matrix: FC<IMatrix> = (props) => {
    const [row, setRow] = useState<{ id: ID; rowNumber: number } | null>(null)
    const [col, setCol] = useState<{ id: ID; colNumber: number } | null>(null)

    return (
        <ListLayout
            className={cn.root}
            sidePadding={24}
            templateColumns={`240px 51px repeat(${props.rows.length}, 88px)`}
        >
            <ListItem height={78}>
                <Segment />
                <Segment />
                {props.legend.map((item, i) => (
                    <Segment
                        onClick={() =>
                            setCol((prev) => {
                                if (prev?.id === item.id) return null
                                return {
                                    id: item.id,
                                    colNumber: i,
                                }
                            })
                        }
                        content="center"
                        key={item.id}
                        style={relative}
                    >
                        <SelectableAvatar
                            src={item.avatar}
                            id={item.id}
                            active={item.id === col?.id}
                            isAllSetted={!!col && !!row}
                        />
                        {item.id === col?.id && row && (
                            <div
                                className={cn.vertical_selection}
                                style={{
                                    height: 118 + row.rowNumber * 76,
                                }}
                            />
                        )}
                    </Segment>
                ))}
            </ListItem>
            {props.rows.map((item, i) => (
                <ListItem margin="0 0 22px" key={i} height={54}>
                    <Segment margin="0 16px 0 0" align="flex-end" column>
                        <Text className={cn.hidden} tAlign="right" semibold component="div">
                            {item.fullName}
                        </Text>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${process.env.REACT_APP_ROOT_DOMAIN}/open/Instagram/${item.id}/demo/`}
                        >
                            <Text color="violet-1" component="div">
                                {item.username}
                            </Text>
                        </a>
                    </Segment>
                    <Segment
                        style={relative}
                        onClick={() =>
                            setRow((prev) => {
                                if (prev?.id === item.id) return null

                                return {
                                    id: item.id,
                                    rowNumber: i,
                                }
                            })
                        }
                    >
                        <SelectableAvatar
                            src={item.avatar}
                            id={item.id}
                            active={item.id === row?.id}
                            isAllSetted={!!col && !!row}
                        />
                        {item.id === row?.id && col && (
                            <div
                                className={cn.horizontal_selection}
                                style={{
                                    width: 143 + col.colNumber * 88,
                                }}
                            />
                        )}
                    </Segment>
                    {item.data.map((blogger, j) => (
                        <Segment content="center" align="center" key={j}>
                            <OverlapPercent
                                isActive={item.id === row?.id && ((blogger.id + '') as any) === col?.id}
                                percent={blogger.percent}
                            />
                        </Segment>
                    ))}
                </ListItem>
            ))}
        </ListLayout>
    )
}

export { Matrix as default }
