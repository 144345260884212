import React from 'react';

export default () => (
	<div style = {{ width: 16, height: 13, marginRight: 5, position: 'relative' }} >
		<svg style = {{position: "absolute", left: 0, top: 0}} width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M9.44211 8.82155C10.4093 8.39994 11.1727 7.82606 11.7322 7.09967C12.2916 6.37345 12.5714 5.58259 12.5714 4.72705C12.5714 3.87148 12.2916 3.08055 11.732 2.35429C11.1726 1.62804 10.4092 1.05409 9.44195 0.632381C8.47468 0.21074 7.42264 0 6.28565 0C5.14876 0 4.09671 0.210869 3.12938 0.632381C2.16214 1.05406 1.39874 1.628 0.8393 2.35429C0.279798 3.08068 0 3.87148 0 4.72708C0 5.45945 0.211623 6.1491 0.634213 6.79535C1.05677 7.44148 1.63716 7.98629 2.3752 8.42937C2.31572 8.57721 2.25471 8.71245 2.19219 8.83572C2.12965 8.95879 2.05531 9.07718 1.96894 9.19113C1.88262 9.30512 1.81573 9.3943 1.76807 9.45872C1.72044 9.52333 1.64307 9.61433 1.53587 9.73106C1.4287 9.84802 1.36021 9.92495 1.3305 9.96179C1.3305 9.95561 1.31859 9.96955 1.29476 10.0034C1.2709 10.0373 1.25751 10.0526 1.25457 10.0497C1.25157 10.0463 1.23965 10.0617 1.21883 10.0956C1.198 10.1295 1.18755 10.1464 1.18755 10.1464L1.16519 10.1924C1.15638 10.211 1.15031 10.2292 1.14737 10.2478C1.14437 10.2661 1.14287 10.2863 1.14287 10.3077C1.14287 10.3292 1.1458 10.3491 1.15187 10.3677C1.16379 10.4476 1.198 10.5121 1.25454 10.5616C1.31105 10.6107 1.372 10.6353 1.43755 10.6353H1.46435C1.76191 10.5922 2.01791 10.543 2.23216 10.4875C3.14883 10.2414 3.97628 9.84735 4.71435 9.30577C5.25002 9.40427 5.77384 9.45348 6.28571 9.45348C7.4227 9.4539 8.47487 9.24323 9.44211 8.82155Z" fill="white"/>
		</svg>
		<svg style = {{position: "absolute", right: 0, bottom: 0}} width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M10.366 6.16318C10.7888 5.52003 11 4.82938 11 4.09071C11 3.33357 10.7765 2.62533 10.3302 1.96682C9.88377 1.30837 9.27654 0.760488 8.50876 0.323486C8.64555 0.785064 8.71401 1.25282 8.71401 1.72685C8.71401 2.55153 8.51486 3.33322 8.11579 4.07189C7.717 4.81034 7.14555 5.4627 6.40154 6.02905C5.71103 6.54605 4.9253 6.94292 4.04429 7.21999C3.16352 7.49686 2.24382 7.63545 1.28546 7.63545C1.10696 7.63545 0.845014 7.62326 0.499825 7.59869C1.6962 8.41096 3.101 8.8173 4.71403 8.8173C5.22599 8.8173 5.74975 8.76796 6.28551 8.66949C7.02355 9.2113 7.85103 9.60503 8.76764 9.85138C8.98192 9.90693 9.23789 9.95605 9.53551 9.99909C9.60691 10.0053 9.67252 9.9837 9.732 9.93445C9.79151 9.88523 9.83026 9.81771 9.84802 9.73163C9.84524 9.69467 9.84802 9.6744 9.857 9.67145C9.86578 9.66854 9.86428 9.64843 9.85249 9.61156C9.84074 9.57457 9.83473 9.55611 9.83473 9.55611L9.81246 9.51006C9.80627 9.4979 9.7962 9.48086 9.78122 9.45939C9.76634 9.43804 9.75448 9.42243 9.74548 9.41324C9.73672 9.40406 9.72343 9.38857 9.70545 9.36719C9.68769 9.34585 9.67571 9.33188 9.6697 9.32571C9.63996 9.28874 9.57154 9.21185 9.46443 9.09485C9.35719 8.97802 9.27995 8.88725 9.23232 8.82264C9.18469 8.758 9.11771 8.66881 9.03143 8.55482C8.94518 8.44106 8.87068 8.32255 8.80817 8.19941C8.74569 8.07634 8.68467 7.94088 8.62516 7.79326C9.36326 7.34972 9.9435 6.80656 10.366 6.16318Z" fill="white"/>
		</svg>
	</div>
)