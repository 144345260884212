import React from 'react'
import PageLayout from '@/Layouts/PageLayout'
import { Flex } from '@/_yc'
import Loader from '@/components/Loader'
import { Header } from './Header/Header'
import { PaypalWidget } from './PaypalWidget/Paypal'
import { useExternalScript } from './UseExternalScript'

import cn from './Payment.module.scss'

export const Payment = () => {
    const params = window.location.pathname.split('/')
    const tariffId = params.length > 2 ? params[2] : '1'
    const currency = params.length > 3 ? params[3] : 'USD'

    const source = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENTID}&currency=${currency}`
    const state = useExternalScript(source)

    return (
        <PageLayout title={'Yoloco - Payment'} color={'common-background'}>
            <div className={cn.paymentPage}>
                <Header />
                <Flex content="center" align="center" style={{ width: '100%' }}>
                    {state === 'ready' ? (
                        <div style={{ width: '600px', marginTop: '200px' }}>
                            <PaypalWidget tariffId={parseInt(tariffId)} />
                        </div>
                    ) : state === 'error' ? (
                        <p>Something went wrong.</p>
                    ) : (
                        <Loader />
                    )}
                </Flex>
            </div>
        </PageLayout>
    )
}
