import React, { PureComponent } from 'react';

export default (PullService) => Component => class extends PureComponent {

	state = {
		money: null
	}

	mounted = true;

	componentDidMount(){
		this.updateMoney();
	}

	updateMoney = () => {
		PullService()
			.then(res => {
				if (!this.mounted) return
				this.setState({ money: res.remain_intersections })
			})
			.catch(err => console.error({ err }))
	}

	componentWillUnmount(){
		this.mounted = false;
	}

	render(){
		return <Component money = {this.state.money} updateMoney = {this.updateMoney} {...this.props}/>
	}
}