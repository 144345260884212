import React, { FC, useEffect, useState } from 'react'
import { Flex } from '@/_yc'
import NavFooter from '@/pages/TargetAudience/components/NavFooter'
import Step from '@/pages/TargetAudience/Views/components/Step/Step'
import cn from './additional-options.module.scss'
import { useTranslation } from 'react-i18next'
import Slider from '@/components/Icons/UI/Slider'
import AdditionalParams, {
    SelectedParams,
} from '@/pages/TargetAudience/Views/CreateReportStepFive/components/AdditionalParams'
import ReportNameModal from '@/pages/TargetAudience/components/ReportNameModal'
import { useHistory } from 'react-router-dom'
import {
    createChildrenTargetsReq,
    createDevicesTargetsReq,
    createFamilyTargetsReq,
    createLandlordsTargetsReq,
    createOccupationsTargetsReq,
    createSiteTargetsReq,
    fetchAllTargetData,
    getCampaignByIdReq,
    updateCampaignReq,
} from '@/pages/TargetAudience/services/services'
import useToken from '@/_helpers/Hooks/useToken'
import { mapOptions, mapOptionsAdapter, updateOptionsLanguage } from '@/pages/TargetAudience/utils'
import { IAdditionalParams, IAdditionalParamsData, Language } from '@/pages/TargetAudience/services/types'
import Loader from '@/components/Loader'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'

const Index: FC = () => {
    const [showModal, setShowModal] = useState(false)
    const [selectedParams, setSelectedParams] = useState<SelectedParams>({
        selectedAges: [],
        selectedLandlord: [],
        selectedDevices: [],
        selectedEmployment: [],
        selectedFamily: [],
        websites: [],
    })
    const [data, setData] = useState<IAdditionalParamsData | null>(null)
    const [loading, setLoading] = useState(false)
    const [targetingType, setTargetingType] = useState<'from_customer' | 'from_us'>('from_us')
    const name = useSelector<RootStore, string>((state) => state.targetAudience.campaign?.name)

    const enums = useSelector<RootStore, IAdditionalParams>((state) => state.targetAudience.enums)
    const { t } = useTranslation()
    const history = useHistory()
    const token = useToken()
    const { i18n } = useTranslation()

    const campaignId = parseInt(localStorage.getItem('campaignId') || '1')

    const handleParamsChange = (newParams: SelectedParams) => {
        setSelectedParams((prevParams) => ({
            ...prevParams,
            ...newParams,
        }))
    }

    useEffect(() => {
        if (enums) {
            setData({
                ChildrenAge: mapOptions(enums.ChildrenAge, i18n.language as Language),
                DeviceTypes: mapOptions(enums.DeviceTypes, i18n.language as Language),
                OccupationType: mapOptions(enums.OccupationType, i18n.language as Language),
                FamilyStatuses: mapOptions(enums.FamilyStatuses, i18n.language as Language),
                LandlordType: mapOptions(enums.LandlordType, i18n.language as Language),
            })
        }
    }, [enums, i18n.language])

    useEffect(() => {
        const loadData = async () => {
            setLoading(true)
            try {
                const targetData = await fetchAllTargetData(token, campaignId)
                if (targetData) {
                    transformDataForState(targetData)
                }
            } catch (error) {
                console.error('Ошибка загрузки данных целевой аудитории:', error)
            } finally {
                setLoading(false)
            }
        }

        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, campaignId])

    useEffect(() => {
        getCampaignByIdReq(token, campaignId).then((campaign) => {
            if (campaign) {
                setTargetingType(campaign.target_type)
            }
        })
    }, [token, campaignId])

    const transformDataForState = (data: any) => {
        setSelectedParams({
            selectedDevices: mapOptionsAdapter(data.devices, i18n.language as Language),
            selectedLandlord: mapOptionsAdapter(data.landlords, i18n.language as Language),
            selectedEmployment: mapOptionsAdapter(data.occupations, i18n.language as Language),
            selectedFamily: mapOptionsAdapter(data.familyStatus, i18n.language as Language),
            selectedAges: mapOptionsAdapter(data.childrenAge, i18n.language as Language),
            websites: data?.websites?.map((website: string) => ({ label: website, value: website })) || [],
        })
    }

    useEffect(() => {
        setSelectedParams((prevParams) => ({
            ...prevParams,
            selectedAges: updateOptionsLanguage(prevParams.selectedAges, data?.ChildrenAge || []),
            selectedDevices: updateOptionsLanguage(prevParams.selectedDevices, data?.DeviceTypes || []),
            selectedEmployment: updateOptionsLanguage(prevParams.selectedEmployment, data?.OccupationType || []),
            selectedFamily: updateOptionsLanguage(prevParams.selectedFamily, data?.FamilyStatuses || []),
            selectedLandlord: updateOptionsLanguage(prevParams.selectedLandlord, data?.LandlordType || []),
        }))
    }, [data, i18n.language])

    const handleNext = () => {
        if (!name) {
            setShowModal(true)
        } else {
            saveReportHandler(name)
        }
    }

    async function saveReportHandler(name: string) {
        const requests = [
            updateCampaignReq(token, campaignId, { name }),
            createChildrenTargetsReq(
                token,
                campaignId,
                selectedParams.selectedAges.map((option) => option.value)
            ),
            createDevicesTargetsReq(
                token,
                campaignId,
                selectedParams.selectedDevices.map((option) => option.value)
            ),
            createOccupationsTargetsReq(
                token,
                campaignId,
                selectedParams.selectedEmployment.map((option) => option.value)
            ),
            createFamilyTargetsReq(
                token,
                campaignId,
                selectedParams.selectedFamily.map((option) => option.value)
            ),
            createSiteTargetsReq(
                token,
                campaignId,
                selectedParams.websites.map((option) => option.value)
            ),
            createLandlordsTargetsReq(
                token,
                campaignId,
                selectedParams.selectedLandlord.map((option) => option.value)
            ),
        ]
        await Promise.allSettled(requests)
        setShowModal(false)
        history.push('/target/new/five')
    }

    return (
        <Flex column className={cn['main']}>
            <Flex className={cn['container']}>
                <Step
                    step={'4 из 5'}
                    title={t('target_audience.additional_params.additional_parameters')}
                    description={'target_audience.additionalParams'}
                    targetingType={targetingType}
                    icon={<Slider className={cn['slider']} />}
                />
                <Flex column className={cn['sidebar']} content="center" align="center">
                    {data && !loading ? (
                        <AdditionalParams
                            initialParams={selectedParams}
                            onParamsChange={handleParamsChange}
                            options={data}
                        />
                    ) : (
                        <Loader style={{ margin: 'auto' }} />
                    )}
                </Flex>
            </Flex>
            <NavFooter callBack={handleNext} prev={'/target/new/four'} next={'/target/additional-options'} />
            <ReportNameModal isVisible={showModal} onClose={() => setShowModal(false)} onSubmit={saveReportHandler} />
        </Flex>
    )
}

export default Index
