import Text from '@/components/Text'
import Flex from '@/_yc/Flex'
import React, { FC, ReactNode } from 'react'
import { HTMLAttributes } from 'react'
import { useCallback } from 'react'
import cn from './PartChanger.module.scss'

export interface IDispItem extends HTMLAttributes<HTMLElement> {
    value: string | number
    dispatch: (value: string | number) => void
    title: string
    index: number
    setIndex: React.Dispatch<React.SetStateAction<number>>
    children?: ReactNode
    soon?: boolean
}

const DispatchItem: FC<IDispItem> = ({ value, title, dispatch, setIndex, index, ...props }) => {
    const func = useCallback(
        (e: any) => {
            if (props.soon) return

            dispatch(value)
            setIndex(index)
        },
        [dispatch, value, setIndex, index, props.soon]
    )
    return (
        <Flex onClick={func} className={props.className} align="center">
            <Text className={cn['item--text']}>{title}</Text>
            {props.children}
        </Flex>
    )
}

export default DispatchItem
