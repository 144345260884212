import React, {
    FC,
    memo,
    ReactNode,
    useMemo,
    CSSProperties,
    ReactHTML,
} from 'react'
import { Stylable } from '@/Types'
import Flex from '@/_yc/Flex'
import { IFlex } from '@/_yc/Flex/Flex'

export interface IListItemSegment extends Stylable<{}> {
    children?: ReactNode

    /** @description CSS property grid-column */
    gridColumn?: string
    /** @description CSS property grid-row */
    gridRow?: string

    /** @description CSS property align-items(default: center) */
    align?: IFlex['align']
    /** @description CSS property justify-content(default: center) */
    content?: IFlex['content']
    /** @description Represents CSS property flex-direction. If prop equals true, it will be setted as column; */
    column?: IFlex['column']

    /** @description html tag to wrap content*/
    component?: keyof ReactHTML

    onClick?: VoidFunction
}

/**
 *
 * @description List item segment;
 * @props {
 *  gridColumn: CSS property grid-column
 *  gridRow: CSS property grid-row
 *  align: CSS property align-items(default: center)
 *  content: CSS property justify-content(default: center)
 *  column: represent CSS property flex-direction. If prop equals true, it will be setted as column;
 * }
 */
const ListItemSegment: FC<IListItemSegment> = (props) => {
    const style = useMemo<CSSProperties>(
        () => ({
            ...props.style,
            gridColumn: props.gridColumn,
            gridRow: props.gridRow,
        }),
        [props.gridColumn, props.gridRow, props.style]
    )

    return (
        <Flex
            component={props.component}
            margin={props.margin}
            style={style}
            className={props.className}
            column={props.column}
            onClick={props.onClick}
            align={
                props.align
                    ? props.align
                    : props.column
                    ? 'flex-start'
                    : 'center'
            }
            content={
                props.content
                    ? props.content
                    : props.column
                    ? 'center'
                    : 'flex-start'
            }
        >
            {props.children}
        </Flex>
    )
}

export default memo(ListItemSegment)
