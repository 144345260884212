import React, { SVGAttributes } from 'react'
const Arrow = (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.21935 3.33333L5.60602 0.94L4.66602 0L0.666016 4L4.66602 8L5.60602 7.06L3.21935 4.66667H11.3327V3.33333H3.21935Z"
            fill="white"
        />
    </svg>
)

export default Arrow
