import React, { FC } from 'react'

import Flex from '@/_yc/Flex'

import cn from './Buttons.module.scss'

import Button from '../DemoButton'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import LockerTs from '@/_yc/Icons/Default/Locker_ts'
import BloggerLink from '@/components/Share/BloggerLink'
import { TARIFFS } from '@/routs'
import { Platform } from '@/pages/Reports/types'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import { Link } from 'react-router-dom'

export interface IButtons {
    showBlock: VoidFunction
    id: string | number
}

const Buttons: FC<IButtons> = ({ id, showBlock }) => {
    const t = useSpaceTranslation('demo_report')
    const tariff = useSelector((store: RootStore) => {
        return store.authentication.user?.profile?.package
    })
    return (
        <Flex align="center" content="center" className={cn.column}>
            {tariff !== null && tariff !== undefined && tariff !== '' ? (
                <BloggerLink
                    disabled={tariff === null || tariff === undefined}
                    id={id}
                    failLink={`${TARIFFS}?error=error`}
                    platform={Platform.Instagram}
                >
                    <Button>
                        <LockerTs className={cn.locker} />
                        {t('unlock')}
                    </Button>
                </BloggerLink>
            ) : (
                <Link to={TARIFFS}>
                    <Button>
                        <LockerTs className={cn.locker} />
                        {t('unlock')}
                    </Button>
                </Link>
            )}
            <Button onClick={showBlock} color="white" className={cn['second-button']}>
                {t('view')}
            </Button>
        </Flex>
    )
}

export default Buttons
