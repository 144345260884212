import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13 25.5C6.09644 25.5 0.5 19.9036 0.5 13C0.5 6.09644 6.09644 0.5 13 0.5C19.9036 0.5 25.5 6.09644 25.5 13C25.4924 19.9004 19.9004 25.4924 13 25.5ZM11.75 3.0775C7.65756 3.59504 4.30284 6.57236 3.30288 10.5744C2.30293 14.5764 3.86295 18.7817 7.23099 21.1634C10.599 23.545 15.0839 23.6142 18.5238 21.3375L18.3988 21.4263L18.5163 21.345L18.59 21.2938L18.67 21.2375L18.69 21.2238L18.7013 21.215L12.2913 14.8025C11.943 14.4481 11.7485 13.9706 11.75 13.4738V3.0775ZM15.2863 14.25L20.57 19.5338L20.5788 19.5238L20.585 19.5163L20.5725 19.53C21.862 18.0419 22.68 16.2041 22.9225 14.25H15.2863ZM14.25 3.0775V11.75H22.9225C22.3519 7.21764 18.7824 3.64814 14.25 3.0775Z"
            fill="#6071FF"
        />
    </svg>
)
