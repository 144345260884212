import React, { FC, ReactElement } from 'react'
import { Tooltip, withStyles } from '@material-ui/core'

const CustomTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: '#5D697D',
        color: '#ffffff',
        fontSize: 10,
        padding: 10,
        borderRadius: 4,
        fontFamily: 'Montserrat',
        fontWeight: 500,
    },
    arrow: {
        color: '#5D697D',
    },
}))(Tooltip)

interface IProps {
    title: string
    placement: 'top' | 'bottom' | 'left' | 'right'
    children: ReactElement
}

const CustomTooltipComponent: FC<IProps> = ({ title, placement, children }) => {
    return (
        <CustomTooltip title={title} placement={placement} arrow>
            {children}
        </CustomTooltip>
    )
}

export default CustomTooltipComponent
