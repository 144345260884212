import React from 'react'
import Icons from '@/components/Icons'

import { FilterWithDate, WithDateType } from '@/pages/SearchPage/SearchAdapter/filters/FilterWithDate'

const getGqlFilter = (data: WithDateType) => {
    if (!data.days || !data.value) return ''

    return `followers_growth: {
        interval: "${data.days}",
        value: ${data.value}
    }`
}

export const getSubGainFilter = () => {
    return new FilterWithDate({
        platform: 'infl_tiktok',
        locked: (features: string[]) => !features?.includes('subscriber_growth_yt'),
        title: 'filters.sub_growth.label',
        helper: 'filters.sub_growth.text',
        icon: <Icons name="filter--engagement--yt" />,
        space: 1,
        options: [
            { label: '30d', value: 'i1month' },
            { label: '60d', value: 'i2months' },
            { label: '90d', value: 'i3months' },
            { label: '120d', value: 'i4months' },
            { label: '150d', value: 'i5months' },
            { label: '180d', value: 'i6months' },
        ],
        beforeSend: (data) => {
            return {
                name: 'metrics.gain',
                type: 'range',
                value: data,
                gqlFilter: getGqlFilter(data),
            }
        },
    })
}
