import React, { FC, ReactNode, useMemo } from 'react'

// import cn from './ListLayout.module.scss'

import Context, { IContext } from './Context'
import { Stylable } from '@/Types'
import useClass from '@/_helpers/Hooks/useClass'

export interface IListLayout extends Stylable<{ root: string }>, IContext {
    children: ReactNode
}

const ListLayout: FC<IListLayout> = (props) => {
    const value = useMemo<IContext>(
        () => ({
            templateColumns: props.templateColumns,
            sidePadding: props.sidePadding,
            gridGap: props.gridGap,
        }),
        [props.templateColumns, props.sidePadding, props.gridGap]
    )

    const root = useClass(props.className, props.classes?.root)

    return (
        <Context.Provider value={value}>
            <div
                className={root}
                style={{
                    margin: props.margin,
                    ...props.style,
                }}
            >
                {props.children}
            </div>
        </Context.Provider>
    )
}

export default ListLayout
