import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { FilterCategory } from '../../../filters/FilterCategory'
import { getFollowersFilter } from './FilterConfigs/Followers'
import { getKeywordsFilter } from './FilterConfigs/Keywords'
import { getCountryFilter } from './FilterConfigs/Location'
import { getERFilter } from './FilterConfigs/Er'
import { getLastVideoFilter } from './FilterConfigs/LastPublDate'
import { getAccountTypeFilter } from './FilterConfigs/AccountType'
import { getInterestsFilter } from './FilterConfigs/Interests'
import { getAutienceLoc } from './FilterConfigs/AudienceLocation'
import { getAudQuality } from './FilterConfigs/AudienceQuality'
import { getAudGenderFilter } from './FilterConfigs/Gender'
import { getAudAgeFilter } from './FilterConfigs/Age'

const LocationFilterObject = getCountryFilter()
// const InterestsFilterObject = getInterestsFilter()
const keyWordsFilter = getKeywordsFilter()

export const getMainFilters = (fetcher: Fetcher) => {
    return {
        main: new FilterCategory('filters.categories.main', {
            interests: getInterestsFilter(fetcher, true),
            followers: getFollowersFilter(),
            location: LocationFilterObject,
            // views: getAvgViewsFilter(),
            // language: getLanguagesFilter(fetcher),
            keywords: keyWordsFilter,
        }),
    }
}

export const getFilters = (fetcher: Fetcher) => {
    return {
        channel_metrics: new FilterCategory('filters.categories.channel', {
            location: LocationFilterObject,
            interests: getInterestsFilter(fetcher),
            followers: getFollowersFilter(),
            accountType: getAccountTypeFilter(),
            // language: getLanguagesFilter(fetcher),
            // contacts: getContactsFilter(),
        }),
        content_metrics: new FilterCategory('filters.categories.content', {
            // views: getAvgViewsFilter(),
            // streams: getStreamsFilter(),
            // games: getGamesFilter(fetcher),
            er: getERFilter(),
            lastPublDate: getLastVideoFilter(),
        }),
        audience_metrics: new FilterCategory('filters.categories.audience', {
            aud_quality: getAudQuality(),
            aud_location: getAutienceLoc(),
            gender: getAudGenderFilter(),
            age: getAudAgeFilter(),
        }),
        other: new FilterCategory('filters.categories.other', {
            keywords: keyWordsFilter,
        }),
    }
}
