import { APIService } from '../../../_helpers/APIService.js'
import { authHeader } from '../../../_helpers/auth-header.js'

export default new APIService({
	methods: {
		getCount: () => ({
			url: "/api/subscriptions/current/",
			method: "GET",
			headers: authHeader()
		})
	}
})