import React, { FC } from 'react'
import { Button, Flex } from '@/_yc'
import Text from '@/components/Text'
import { useTranslation } from 'react-i18next'
import cn from './empty-state.module.scss'
import Icons from '@/components/Icons'
import useToken from '@/_helpers/Hooks/useToken'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { createCampaignReq } from '@/pages/TargetAudience/services/services'
import { setCampaignData } from '@/_redux/_reducers/target_audience.reducer'

const EmptyState: FC = () => {
    const { t } = useTranslation()
    const token = useToken()
    const dispatch = useDispatch()
    const history = useHistory()

    const handleClickButton = async () => {
        try {
            const campaignData = {
                status: 'draft',
                target_type: 'from_us',
            }

            const response = await createCampaignReq(token, campaignData)

            if (response) {
                dispatch(setCampaignData(response))
                localStorage.setItem('campaignId', response.id.toString())
                history.push('/target/new/one')
            }
        } catch (error) {
            console.error('Ошибка создания кампании:', error)
        }
    }
    return (
        <Flex content="center" column align="center" className={cn.empty} margin="55px 0 55px 0">
            <Flex className={cn.wrap} margin="0 0 24px 0">
                <Icons name={'icon-audience'} className={cn.icon} style={{}} />
            </Flex>
            <Flex margin="0 0 8px 0">
                <Text fSize={20} semibold color={'gray-new-1'}>
                    {t('target_audience.create_first_analysis')}
                </Text>
            </Flex>
            <Flex margin="0 0 24px 0">
                <Text fSize={14} semibold color={'gray-new-3'}>
                    {t('target_audience.description')}
                </Text>
            </Flex>
            <Flex>
                <Button onClick={handleClickButton} className={cn['btn']} color="blue">
                    + {t('target_audience.create_analysis')}
                </Button>
            </Flex>
        </Flex>
    )
}

export default EmptyState
