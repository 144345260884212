import { gql } from '@apollo/client'

export const GET_TWITCH_REPORT = gql`
    query GetTwitchReport($id: String!) {
        getTwitchUser(user_id: $id) {
            data
        }
    }
`

export const GET_TWITCH_VIDEOS = gql`
    query GetTwitchVideos($userId: String, $videoTimestamp: timestamptz = "") {
        twitch_uservideo(
            where: { user_id: { _eq: $userId }, published_at: { _gte: $videoTimestamp } }
            limit: 9
            order_by: { view_count: desc_nulls_last }
        ) {
            published_at
            stream_id
            thumbnail_url
            title
            view_count
            id
        }
    }
`
