import React, { memo } from 'react';
import {
	Popper,
	Grow,
	ClickAwayListener
} from '@material-ui/core'


const Pop = ({ 
	open = false, 
	btn, 
	content, 
	anchor, 
	onClickAway, 
	disablePortal, 
	style, 
	arrowRef, 
	placement: _placement 
}) => {
	// console.log(arrowRef)
	return (
	<React.Fragment>
		{btn}
		<Popper 
			transition 
			anchorEl = {anchor} 
			open={open}
			disablePortal = {disablePortal}
			style = {style}
			placement = {_placement}
			modifiers = {{
				arrow: arrowRef ? {
			        enabled: true,
			        element: arrowRef,
		      	} : undefined,
		      	preventOverflow: {
                  	enabled: true,
                  	boundariesElement: 'scrollParent'
                }
			}}
		>
			{({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
	            <ClickAwayListener onClickAway={e=>onClickAway(false, e)}>
		            {content}
	            </ClickAwayListener>
            </Grow>
          )}
        </Popper>			
	</React.Fragment>
)}

export const StandartPopper = memo(Pop)