import i18next from 'i18next'

export type Lang = 'en' | 'pt' | 'ru'
const LANGS = ['en', 'ru', 'pt']

export const getLang = (): Lang => {
    const lang = i18next.language.slice(0, 2)

    if (!LANGS.includes(lang)) {
        return 'en' as Lang
    } else {
        return lang as Lang
    }
}
