import Text from '@/components/Text'
import Icons from '@/components/Icons'
import React, { FC, memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import cn from './ListPopup.module.scss'

export interface ISorterItem {
    label: string
    value: string
    onClick: (val: string) => void
    active: boolean
    disabled?: boolean
    fSize?: string | number
}

const SorterItem: FC<ISorterItem> = ({ onClick, ...props }) => {
    const { t } = useTranslation()

    const click = useCallback(() => {
        if (props.disabled) return
        onClick(props.value)
    }, [props.value, onClick, props.disabled])

    return (
        <li className={cn.sorterItem}>
            <Text
                className={props.disabled ? cn.disabled : undefined}
                onClick={click}
                fSize={props.fSize ?? 12}
                component="p"
                disabled={props.disabled}
                color={props.disabled ? 'gray-4' : undefined}
            >
                {t(props.label)}
            </Text>

            {props.active && <Icons name={'done-blue'} className={cn.active} />}
        </li>
    )
}

export default memo(SorterItem)
