import { compose, withProps } from 'recompose'
import { withTranslation } from 'react-i18next'
import { withRouter } from "react-router-dom";
import CM from './CreationModal.js'

export default compose(
	withRouter,
	withProps(props => {
		if(props.location.state?.initialBloggers instanceof Array) {
			return {
				initialBloggers: props.location.state.initialBloggers,
				initialName: props.location.state.initialName
			}
		}
	}),
	withTranslation()
)(CM)