// import LoginService from '../LoginPage/Model/_service.js'
import Axios from 'axios'
import i18n from 'i18next'

function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'))
    if (user && user.token) {
        return {
            Authorization: 'Token ' + user.token,
            'Content-Type': 'application/json',
            'Accept-Language': i18n.language,
        }
    } else {
        return {}
    }
}

function authHasura() {
    let user = JSON.parse(localStorage.getItem('user'))
    if (user && user.token) {
        return {
            Authorization: `Token ${user.token}`,
        }
    } else {
        return {}
    }
}

function logout() {
    // remove user from local storage to log user out
    Axios.request({
        baseURL: process.env.REACT_APP_API_DOMAIN,
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': i18n.language,
            ...authHeader(),
        },
        url: '/core/accounts/logout/',
        method: 'POST',
        data: { revoke_token: false },
    })
    localStorage.removeItem('user')
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text)
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout()
                window.location.reload(true)
            }

            const error = (data && data.message) || response.statusText
            return Promise.reject(error)
        }

        Object.defineProperty(data, '__code', {
            value: response.status,
            enumerable: false,
        })
        return data
    })
}

export { authHeader, logout, handleResponse, authHasura }
