const validateLink = (link: string) => {
    const re = /^[a-zA-Z0-9_-]*$/
    return re.test(link)
}

export const LinkValidator = (error: string) => (value: string) => {
    if (validateLink(value)) {
        return
    } else {
        return new Error(error)
    }
}
