import React, { memo, FC } from 'react'
import MoreModal from '@/components/MoreModal'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { IBrandPosts } from '../../Types'
import { Flex } from '@/_yc'
import { ListOfAccounts as ListOfAccountsUI, Tooltip } from '@yoloker/ui-collection'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import PopularBrands, { IBrand } from './PopularBrands'

import cn from './ListAccountsUI.module.scss'

export interface IItem {
    id: number
    url: string
    label: string
    subLabel?: string
    // value?: string | number
    value?: string
    followers?: number
    posts?: [IBrandPosts]
}

export interface IList {
    windowWidth: string
    title: string
    width: string | number
    hidden?: boolean
    data?: IItem[]
    isTwoTailed?: boolean
    space: number
}
interface IItemAr {
    data: IItem[]
    title: string
    isDemo?: boolean
}
//interface for lib
export interface IAccountData {
    id: string
    avatar: string
    title: string
    label?: string
    instPost_id?: string
    subscriber_count?: number
    published_at?: string
    views_OR_eng?: number | string
}

const ListOfAccounts: FC<IList> = ({ title, data, width, hidden, windowWidth, isTwoTailed, space }) => {
    if (title === 'brandAffinity') {
        return <>{data && <BrandMentions data={data} title={title} isDemo={hidden} />}</>
    }

    if (title === 'lookalikes') {
        return <>{data && <NotableFollowersAndSimilar data={data} title={title} isDemo={hidden} />}</>
    }

    //notableFollowers
    return <>{data && <NotableFollowersAndSimilar data={data} title={title} isDemo={hidden} />}</>
}

const BrandMentions: FC<IItemAr> = ({ data, isDemo }) => {
    const t = useSpaceTranslation('report_titles.brandAffinity')
    const size = useWindowWidth()

    const postsSort = (posts: [IBrandPosts]) => {
        return posts.sort((a: IBrandPosts, b: IBrandPosts) => {
            const check = new Date(a.post_date).getTime() - new Date(b.post_date).getTime()
            return check > 0 ? -1 : 1
        })
    }
    let brandData: Array<IAccountData> = []
    let popularBrands: Array<IBrand> = []

    data.forEach((item) => {
        const sortedPosts: IBrandPosts[] | undefined = item.posts ? postsSort(item.posts) : undefined
        sortedPosts &&
            sortedPosts.length !== 0 &&
            popularBrands.push({
                title: item.label,
                avatar: item.url,
                postsCount: item.posts ? item.posts.length : 0,
            })
        brandData.push({
            id: item.id ? item.id.toString() : '',
            avatar: item.url,
            label: item.value,
            title: item.label,
            instPost_id:
                sortedPosts && sortedPosts.length !== 0
                    ? sortedPosts[0].post_url.slice(24, sortedPosts[0].post_url.length - 1)
                    : undefined,
            views_OR_eng:
                sortedPosts && sortedPosts.length !== 0 ? `${Math.round(sortedPosts[0].er * 10000) / 100}%` : undefined,
            published_at: sortedPosts && sortedPosts.length !== 0 ? sortedPosts[0].post_date.slice(0, 10) : undefined,
        })
        if (sortedPosts && sortedPosts.length > 1) {
            brandData.push({
                id: item.id ? item.id.toString() : '',
                avatar: item.url,
                label: item.value,
                title: item.label,
                instPost_id: sortedPosts && sortedPosts[1].post_url.slice(24, sortedPosts[0].post_url.length - 1),
                views_OR_eng: sortedPosts && `${Math.round(sortedPosts[1].er * 10000) / 100}%`,
                published_at: sortedPosts && sortedPosts[1].post_date.slice(0, 10),
            })
        }
    })
    return (
        <>
            {brandData && brandData.length !== 0 && (
                <Flex className={cn['brand-container']}>
                    <div style={popularBrands.length > 1 && size !== 'mobile' ? { width: '720px' } : { width: '100%' }}>
                        <div>
                            <MoreModal title={t('title')}>
                                <ListOfAccountsUI
                                    amount={Math.min(brandData.length, 30)}
                                    cardType="horizontal"
                                    data={brandData}
                                    platform="instagram"
                                    isDemo={isDemo}
                                />
                            </MoreModal>
                        </div>
                        <ListOfAccountsUI
                            amount={6}
                            cardType="horizontal"
                            data={brandData}
                            platform="instagram"
                            isDemo={isDemo}
                        />
                    </div>
                    {popularBrands.length > 1 && <PopularBrands data={popularBrands} isDemo={isDemo} />}
                </Flex>
            )}
        </>
    )
}

const NotableFollowersAndSimilar: FC<IItemAr> = ({ data, title, isDemo }) => {
    let notableFollowersData: Array<IAccountData> = []
    data.forEach((item) => {
        notableFollowersData.push({
            id: item.id ? item.id.toString() : '',
            avatar: item.url,
            label: item.label,
            title: item.subLabel || item.label,
            subscriber_count: item.followers,
        })
    })
    const t = useSpaceTranslation(`report_titles.${title}`)
    return (
        <>
            {notableFollowersData && notableFollowersData.length !== 0 && (
                <div>
                    {title === 'notableFollowers' && (
                        <Flex align="center">
                            <p className={cn.title}>{t('title')} </p>
                            <Tooltip text={t('helper')} />
                        </Flex>
                    )}
                    <div>
                        <MoreModal title={t('title')}>
                            <ListOfAccountsUI
                                amount={Math.min(notableFollowersData.length, 30)}
                                cardType="vertical"
                                data={notableFollowersData}
                                platform="instagram"
                                isDemo={isDemo}
                            />
                        </MoreModal>
                    </div>
                    <ListOfAccountsUI
                        amount={5}
                        cardType="vertical"
                        data={notableFollowersData}
                        platform="instagram"
                        isDemo={isDemo}
                    />
                </div>
            )}
        </>
    )
}
export default memo(ListOfAccounts)
