import Button from '@/components/Button'
import Text from '@/components/Text'
import { LISTS } from '@/routs'
import useToken from '@/_helpers/Hooks/useToken'
import { RootStore } from '@/_helpers/StoreType'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentCampaign, updateAdvertisingCampaigns } from '../../Model/actions'
import { getAdvertisingCampaignsReq } from '../../Service/Campaigns'

import cn from './Campaigns.module.scss'

interface CampaignsProps {}

export const Campaigns: FC<CampaignsProps> = () => {
    const t = useSpaceTranslation('outreach.messenger.campaigns')
    const dispatch = useDispatch()
    const token = useToken()

    const campaigns = useSelector((store: RootStore) => store.Outreach.advertisingCampaigns)
    const curCampaign = useSelector((store: RootStore) => store.Outreach.currentCampaign)
    const totalChats = useMemo(() => {
        return campaigns.results.reduce((prev, curr) => prev + (curr.stats?.chats_count || 0), 0)
    }, [campaigns])

    const onClickCreate = () => {
        window.location.href = LISTS
    }

    useEffect(() => {
        if (!token) return

        getAdvertisingCampaignsReq(token).then((data) => {
            dispatch(updateAdvertisingCampaigns(data))
        })
    }, [dispatch, token])

    return (
        <div className={cn.campaigns}>
            <div className={cn.title}>
                <Text fSize={16} semibold>
                    {t('title')}
                </Text>
            </div>

            <hr className={cn.divider}></hr>

            <div className={cn.items}>
                <div
                    className={`${cn.item} ${curCampaign === '' ? cn['item--active'] : ''}`}
                    onClick={() => dispatch(setCurrentCampaign(''))}
                >
                    <Text>{t('all_chats')}</Text>
                    <Text color="gray-2">{totalChats}</Text>
                </div>

                {campaigns.results.map((campaign) => {
                    return (
                        <div
                            className={`${cn.item} ${campaign.id === curCampaign ? cn['item--active'] : ''}`}
                            onClick={() => dispatch(setCurrentCampaign(campaign.id))}
                            key={campaign.id}
                        >
                            <Text>{campaign.name}</Text>
                            <Text color="gray-2">{campaign.stats.chats_count || 0}</Text>
                        </div>
                    )
                })}
            </div>

            <div style={{ padding: '0px 8px', width: '100%' }}>
                <Button variant="primary" style={{ fontSize: 12, width: '100%' }} onClick={onClickCreate}>
                    {t('create')}
                </Button>
            </div>
        </div>
    )
}
