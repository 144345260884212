import React, { FC, useEffect, useState } from 'react'
import { SelectorWithInput } from '@/components/SelectorWithInput/SelectorWithInput'
import useOpenClose from '@/_helpers/useOpenClose'
import { useDispatch, useSelector } from 'react-redux'
import notif from '@/components/Notifier/model/actions.js'

import Text from '@/components/Text'
import cn from './ListSelector.module.scss'
import { AddCustomListReq } from '@/pages/Lists/model/service'
import { setLists } from '@/pages/Lists/model/actions'
import { useTranslation } from 'react-i18next'
import useToken from '@/_helpers/Hooks/useToken'
import { getListsWOpaginationReq } from '@/pages/Lists/model/service'
import { ListsEasy } from '@/pages/Lists/model/types'
import { RootStore } from '@/_helpers/StoreType'
import { ReactComponent as PlusIcon } from './icons/plus.svg'

interface ListSelectorProps {
    curItem: ListsEasy | undefined
    error?: boolean
    setCurItem: React.Dispatch<React.SetStateAction<ListsEasy | undefined>>
}

export const ListSelector: FC<ListSelectorProps> = ({ curItem, setCurItem, error }) => {
    const [isInputActive, setInputActiveTrue, setInputActiveFalse] = useOpenClose()

    const { t } = useTranslation()
    const token = useToken()

    const dispatch = useDispatch()

    const [customLists, setCustomLists] = useState<ListsEasy[]>([])
    const activeListId = useSelector((store: RootStore) => store.AudienceInsights.ActiveListId)

    useEffect(() => {
        if (!token) return
        getListsWOpaginationReq(token).then((res) => setCustomLists(res))
    }, [setCustomLists, token])
    useEffect(() => {
        if (!customLists || customLists.length === 0 || activeListId <= 0) return

        const list = customLists.filter((l) => l.id === activeListId)
        if (list.length !== 1) return
        setCurItem(list[0])
    }, [customLists, activeListId, setCurItem])

    const createList = (token: string | null, inputValue?: string) => {
        setInputActiveFalse()
        dispatch(notif.loading(t('aud_insides.status.loading')))
        if (!token || !inputValue) {
            dispatch(notif.error(t('aud_insides.status.error')))
            return
        }
        AddCustomListReq(token, inputValue)
            .then((res) => {
                dispatch(notif.success(t('aud_insides.status.list_saved', { listName: res.name })))
                dispatch(setLists([res]))
            })
            .catch(() => dispatch(notif.error(t('aud_insides.status.error'))))
    }

    const renderItem = (data: ListsEasy) => {
        return (
            <div className={cn.listItemPopup} onClick={() => setCurItem(data)} key={data.id}>
                <Text>{data.name}</Text>
            </div>
        )
    }

    return (
        <>
            <SelectorWithInput<ListsEasy>
                inputActive={isInputActive}
                selectorPlaceholder={t('bulk_add.chooseList')}
                curItem={curItem?.name}
                listData={customLists}
                renderItem={renderItem}
                inputPlaceholder={t('context_menu.placeholder')}
                onInputSubmit={(input) => createList(token, input)}
                onInputCancel={setInputActiveFalse}
            />
            {error && <Text className={cn.error}>{t('aud_insides.mass_parsing.er')}</Text>}
            {!isInputActive && (
                <div
                    className={cn['add-list']}
                    style={{ cursor: 'pointer', marginTop: 5, marginBottom: 10 }}
                    onClick={setInputActiveTrue}
                >
                    <PlusIcon style={{ marginRight: '6px' }} />
                    <Text fSize={'12px'} semibold color="violet-1">
                        {t('aud_insides.lists.add')}
                    </Text>
                </div>
            )}
        </>
    )
}
