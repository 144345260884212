import React, { FC } from 'react'
import { MediaPlanOrder } from '../utils/types'
import Demographics from './Demographics/Demographics'

interface ReportProps {
    isLoading: boolean
    mediaPlan: MediaPlanOrder
}

export const Report: FC<ReportProps> = ({ mediaPlan, isLoading }) => {
    return (
        <div>
            <Demographics mediaPlan={mediaPlan} isLoading={isLoading} />
        </div>
    )
}
