import React, { FC, memo, SVGAttributes, useMemo } from 'react'

import Bars from './UI/Bars'
import Copy from './UI/Copy'
import Search from './UI/Search'
import Flag from './UI/Flag'
import Bell from './UI/Bell'
import Profile from './UI/Profile'
import Instagram from './UI/Instagram'
import ArrowDown from './UI/ArrowDown'
import Slider from './UI/Slider'
import ExternalLink from './UI/ExternalLink'
import ListPlus from './UI/ListPlus'
import BellDotted from './UI/BellDotted'
import Done from './UI/Done'
import CloseSmall from './UI/CloseSmall'
import ShortLeft from './UI/ShortLeft'
import Burger from './UI/Burger'
import Close from './UI/Close'
import Settings from './UI/Settings'
import TagOutline from './UI/TagOutline'
import Sync from './UI/Sync'
import LogOut from './UI/LogOut'
import YTIcon from '@/pages/SearchPage/Bloggers/SearchPanel/SearchInput/YTIcon'
import Views from './Views'
import ArrowRight from './UI/ArrowRight'
import Lists from './UI/Lists'
import InstagramCircle from './UI/InstagramCircle'
import YoutubeCircle from './UI/YoutubeCircle'
import HeartOutline from './UI/HeartOutline'
import Messagecircle from './UI/Messagecircle'
import Group from './UI/Group'
import GroupList from './UI/GroupList'
import Download from './UI/Download'
import Redo from './UI/Redo'
import Code from './UI/Code'
import Vk from './UI/Vk'
import VkDisabled from './UI/VkDisabled'
import VkAvatar from './UI/VkAvatar'
import AlertTriangle from './UI/AlertTriangle'
import Telegram from './UI/Telegram'
import TelegramDis from './UI/TelegraDisabled'
import GridHorizontal from './UI/GridHorizontal'
import Share from './UI/Share'
import TikTokIE from './UI/TikTokIE'
import TikTokIEDisabled from './UI/TikTokIEDisabled'

//Filter icons
import FilterInterest from './FilterIcons/Interests'
import FilterFollowers from './FilterIcons/Followers'
import FilterLook from './FilterIcons/Look'
import FilterEngagement from './FilterIcons/Engagement'
import FilterGender from './FilterIcons/Gender'
import FilterAge from './FilterIcons/Age'
import FilterChildrenAge from './FilterIcons/ChildrenAge'
import FilterGeo from './FilterIcons/Geo'
import FilterReach from './FilterIcons/Reach'
import FilterLikes from './FilterIcons/Likes'
import FilterComments from './FilterIcons/Comments'
import FilterAudienceQuality from './FilterIcons/AudienceQuality'
import FilterPhoto from './FilterIcons/Photo'
import FilterSimilar from './FilterIcons/Similar'
import FilterBio from './FilterIcons/Bio'
import FilterBrand from './FilterIcons/Brand'
import InstagramAvatar from './UI/InstagramAvatar'
import YoutubeAvatar from './UI/YoutubeAvatar'
import Likes from './UI/Likes'
import Comments from './UI/Comments'
import AdsInstagram from './UI/AdsInstagram'
import AdsYoutube from './UI/AdsYoutube'
import AdsInstagramDisabled from './UI/AdsInstagramDisabled'
import AdsYoutubeDisabled from './UI/AdsYoutubeDisabled'
import AdsExplorer from './UI/AdsExplorer'
import DoneBlue from './UI/DoneBlue'
import AccountType from './UI/AccountType'
import CloseSmallGrey from './UI/CloseSmallGrey'
import CampaignManager from './UI/CampaignManager'
import EmptyPhoto from './UI/EmptyPhoto'
import Edit from './UI/Edit'
import BasicPhone from './UI/BasicPhone'
import Mail from './UI/Mail'
import Message from './UI/Message'
import RedCross from './UI/RedCross'
import Outreach from './UI/Outreach'
import ArrowUp from './UI/ArrowUp'
import InstagramIE from './UI/InstagramIE'
import YouTubeIE from './UI/YouTubeIE'
import InstagramIEDisabled from './UI/InstagramIEDisabled'
import YouTubeIEDisabled from './UI/YouTubeIEDisabled'
import IEIcon from './UI/IEIcon'
import InterestMain from './FilterIcons/InterestMain'
import Sorter from './UI/Sorter'
import FilterEngagementYt from './FilterIcons/EngagementYt'
import FilterLanguage from './FilterIcons/Language'
import FilterVideosCount from './FilterIcons/VideosCount'
import Trash from './UI/Trash'
import YoScore from './FilterIcons/YoScore'
import MoreHorizontal from './UI/MoreHorizontal'
import TwitchIE from './UI/TwitchIE'
import TwitchIEDisabled from './UI/TwitchIEDisabled'
import UnorderedList from './UI/UnorderedList'
import Magic from './UI/Magic'
import Clock from './UI/Clock'
import { HiddenLikes } from './UI/HiddenLikes'
import Refresh from './UI/Refresh'
import Phone from './UI/Phone'
import CheckboxTopicsChecked from './UI/CheckboxTopicsChecked'
import CheckboxTopics from './UI/CheckboxTopics'
import MediaPlanner from './UI/MediaPlanner'
import Reach from './UI/Reach'
import { Home } from './UI/Home'
import MediaPlanInstagram from './UI/MediaPlanInstagram'
import MediaPlanYoutube from './UI/MediaPlanYoutube'
import Pie from './UI/Pie'
import Show from './UI/Show'
import TiktokCircle from './UI/TiktokCircle'
import Saves from './UI/Saves'
import Shares from './UI/Shares'
import LikesThumb from './UI/LikesThumb'
import Unlock from './UI/Unlock'
import Audience from '@/components/Icons/UI/Audience'

export type TUiIconName =
    | 'search'
    | 'bars'
    | 'copy'
    | 'flag'
    | 'bell'
    | 'bell--dotted'
    | 'profile'
    | 'instagram'
    | 'arrow-down'
    | 'arrow-up'
    | 'slider'
    | 'external-link'
    | 'list-plus'
    | 'done'
    | 'done-blue'
    | 'close--small'
    | 'short--left'
    | 'yt'
    | 'burger'
    | 'close'
    | 'settings'
    | 'tag--outline'
    | 'sync'
    | 'logout'
    | 'views'
    | 'instagram-avatar'
    | 'youtube-avatar'
    | 'twitch-avatar'
    | 'likes'
    | 'comments'
    | 'ads-instagram'
    | 'ads-youtube'
    | 'ads-instagram--disabled'
    | 'ads-youtube--disabled'
    | 'ie-instagram'
    | 'ie-youtube'
    | 'ie-instagram--disabled'
    | 'ie-youtube--disabled'
    | 'ads-explorer'
    | 'close-small-grey'
    | 'campaign-manager'
    | 'outreach'
    | 'empty-photo'
    | 'pie'
    | 'edit'
    | 'basic-phone'
    | 'mail'
    | 'message'
    | 'red_cross'
    | 'arrow-right'
    | 'ie-icon'
    | 'sorter'
    | 'trash'
    | 'more--horizontal'
    | 'ie-twitch'
    | 'ie-twitch--disabled'
    | 'unordered-list'
    | 'magic'
    | 'twitch-avatar'
    | 'lists'
    | 'instagram-circle'
    | 'youtube-circle'
    | 'clock'
    | 'message-circle'
    | 'hidden-likes'
    | 'refresh'
    | 'phone'
    | 'checkbox-topics--checked'
    | 'checkbox-topics'
    | 'media-planner'
    | 'Group'
    | 'Reach'
    | 'GroupList'
    | 'Download'
    | 'home'
    | 'redo'
    | 'code'
    | 'media-plan-instagram'
    | 'media-plan-youtube'
    | 'ie-vk'
    | 'ie-vk--disabled'
    | 'vk-avatar'
    | 'show'
    | 'alert-triangle'
    | 'ie-tg'
    | 'ie-telegram'
    | 'ie-telegram--disabled'
    | 'grid'
    | 'share'
    | 'tiktok-circle'
    | 'ie-tiktok'
    | 'ie-tiktok--disabled'
    | 'unlock'
    | 'icon-audience'

type TFilterIconName =
    | 'filter--interests'
    | 'filter--yo-score'
    | 'filter--followers'
    | 'filter--look'
    | 'filter--engagement'
    | 'filter--engagement--yt'
    | 'filter--gender'
    | 'filter--age'
    | 'filter--children-age'
    | 'filter--geo'
    | 'filter--reach'
    | 'filter--likes'
    | 'filter--comments'
    | 'filter--audience-quality'
    | 'filter--photo'
    | 'filter--similar'
    | 'filter--bio'
    | 'filter--brand'
    | 'account--type'
    | 'filter--interests--main'
    | 'filter--language'
    | 'filter--videos--count'
    | 'heart_outline'
    | 'filter--saves'
    | 'filter--shares'
    | 'filter--likes-thumb'
    | 'audience'
    | 'icon-audience'

export interface IIcons extends SVGAttributes<SVGElement> {
    name: TUiIconName | TFilterIconName
    margin?: string | number
    fill?: string
}

const Icons: FC<IIcons> = ({ name, margin, style, fill, ...props }) => {
    const st = useMemo(
        () => ({
            margin,
            ...style,
        }),
        [margin, style]
    )

    switch (name) {
        case 'pie':
            return <Pie {...props} style={st} />
        case 'search':
            return <Search {...props} style={st} />
        case 'bars':
            return <Bars {...props} style={st} />
        case 'copy':
            return <Copy {...props} style={st} />
        case 'checkbox-topics--checked':
            return <CheckboxTopicsChecked {...props} style={st} />
        case 'Reach':
            return <Reach {...props} style={st} />
        case 'checkbox-topics':
            return <CheckboxTopics {...props} style={st} />
        case 'media-planner':
            return <MediaPlanner {...props} style={st} />
        case 'flag':
            return <Flag {...props} style={st} />
        case 'media-plan-instagram':
            return <MediaPlanInstagram {...props} style={st} />
        case 'media-plan-youtube':
            return <MediaPlanYoutube {...props} style={st} />
        case 'bell':
            return <Bell {...props} style={st} />
        case 'refresh':
            return <Refresh {...props} style={st} />
        case 'phone':
            return <Phone {...props} style={st} />
        case 'profile':
            return <Profile {...props} style={st} />
        case 'instagram':
            return <Instagram {...props} style={st} />
        case 'arrow-down':
            return <ArrowDown {...props} style={st} />
        case 'arrow-right':
            return <ArrowRight {...props} style={st} />
        case 'arrow-up':
            return <ArrowUp {...props} style={st} />
        case 'slider':
            return <Slider {...props} style={st} />
        case 'external-link':
            return <ExternalLink {...props} style={st} />
        case 'hidden-likes':
            return <HiddenLikes {...props} style={st} />
        case 'list-plus':
            return <ListPlus {...props} style={st} />
        case 'bell--dotted':
            return <BellDotted {...props} style={st} />
        case 'done':
            return <Done {...props} fill={fill} style={st} />
        case 'sorter':
            return <Sorter {...props} style={st} />
        case 'done-blue':
            return <DoneBlue {...props} style={st} />
        case 'close--small':
            return <CloseSmall {...props} style={st} />
        case 'short--left':
            return <ShortLeft {...props} style={st} />
        case 'yt':
            return <YTIcon {...props} style={st} />
        case 'home':
            return <Home {...props} style={st} />
        case 'burger':
            return <Burger {...props} style={st} />
        case 'close':
            return <Close {...props} style={st} />
        case 'settings':
            return <Settings {...props} style={st} />
        case 'tag--outline':
            return <TagOutline {...props} style={st} />
        case 'sync':
            return <Sync {...props} style={st} />
        case 'logout':
            return <LogOut {...props} style={st} />
        case 'views':
            return <Views {...props} style={st} />
        case 'likes':
            return <Likes {...props} style={st} />
        case 'trash':
            return <Trash {...props} style={st} />
        case 'magic':
            return <Magic {...props} style={st} />
        case 'more--horizontal':
            return <MoreHorizontal {...props} style={st} />
        case 'comments':
            return <Comments {...props} style={st} />
        case 'instagram-avatar':
            return <InstagramAvatar {...props} style={st} />
        case 'youtube-avatar':
            return <YoutubeAvatar {...props} style={st} />
        case 'ads-instagram':
            return <AdsInstagram {...props} style={st} />
        case 'ads-youtube':
            return <AdsYoutube {...props} style={st} />
        case 'ads-instagram--disabled':
            return <AdsInstagramDisabled {...props} style={st} />
        case 'ads-youtube--disabled':
            return <AdsYoutubeDisabled {...props} style={st} />
        case 'ads-explorer':
            return <AdsExplorer {...props} style={st} />
        case 'ie-instagram':
            return <InstagramIE {...props} style={st} />
        case 'ie-youtube':
            return <YouTubeIE {...props} style={st} />
        case 'ie-instagram--disabled':
            return <InstagramIEDisabled {...props} style={st} />
        case 'ie-youtube--disabled':
            return <YouTubeIEDisabled {...props} style={st} />
        case 'twitch-avatar':
            return <TwitchIE {...props} style={st} />
        case 'ie-twitch':
            return <TwitchIE {...props} style={st} />
        case 'ie-twitch--disabled':
            return <TwitchIEDisabled {...props} style={st} />
        case 'campaign-manager':
            return <CampaignManager {...props} style={st} />
        case 'outreach':
            return <Outreach {...props} style={st} />
        case 'empty-photo':
            return <EmptyPhoto {...props} style={st} />
        case 'edit':
            return <Edit {...props} style={st} />
        case 'close-small-grey':
            return <CloseSmallGrey {...props} style={st} />
        case 'unordered-list':
            return <UnorderedList {...props} style={st} />
        case 'filter--interests':
            return <FilterInterest {...props} style={st} />
        case 'filter--followers':
            return <FilterFollowers {...props} style={st} />
        case 'filter--look':
            return <FilterLook {...props} style={st} />
        case 'filter--engagement':
            return <FilterEngagement {...props} style={st} />
        case 'filter--engagement--yt':
            return <FilterEngagementYt {...props} style={st} />
        case 'filter--videos--count':
            return <FilterVideosCount {...props} style={st} />
        case 'filter--interests--main':
            return <InterestMain {...props} style={st} />
        case 'filter--gender':
            return <FilterGender {...props} style={st} />
        case 'filter--language':
            return <FilterLanguage {...props} style={st} />
        case 'filter--age':
            return <FilterAge {...props} style={st} />
        case 'filter--children-age':
            return <FilterChildrenAge {...props} style={st} />
        case 'filter--geo':
            return <FilterGeo {...props} style={st} />
        case 'filter--yo-score':
            return <YoScore {...props} style={st} />
        case 'filter--reach':
            return <FilterReach {...props} style={st} />
        case 'filter--likes':
            return <FilterLikes {...props} style={st} />
        case 'filter--comments':
            return <FilterComments {...props} style={st} />
        case 'filter--audience-quality':
            return <FilterAudienceQuality {...props} style={st} />
        case 'filter--photo':
            return <FilterPhoto {...props} style={st} />
        case 'filter--similar':
            return <FilterSimilar {...props} style={st} />
        case 'filter--bio':
            return <FilterBio {...props} style={st} />
        case 'filter--brand':
            return <FilterBrand {...props} style={st} />
        case 'account--type':
            return <AccountType {...props} style={st} />
        case 'basic-phone':
            return <BasicPhone {...props} style={st} />
        case 'mail':
            return <Mail {...props} style={st} />
        case 'message':
            return <Message {...props} style={st} />
        case 'red_cross':
            return <RedCross {...props} style={st} />
        case 'ie-icon':
            return <IEIcon {...props} style={st} />
        case 'lists':
            return <Lists {...props} style={st} />
        case 'instagram-circle':
            return <InstagramCircle {...props} style={st} />
        case 'youtube-circle':
            return <YoutubeCircle {...props} style={st} />
        case 'clock':
            return <Clock {...props} style={st} />
        case 'heart_outline':
            return <HeartOutline {...props} style={st} />
        case 'message-circle':
            return <Messagecircle {...props} style={st} />
        case 'Group':
            return <Group {...props} style={st} />
        case 'GroupList':
            return <GroupList {...props} style={st} />
        case 'Download':
            return <Download {...props} style={st} />
        case 'redo':
            return <Redo {...props} style={st} />
        case 'code':
            return <Code {...props} style={st} />
        case 'ie-vk':
            return <Vk {...props} style={st} />
        case 'ie-vk--disabled':
            return <VkDisabled {...props} style={st} />
        case 'vk-avatar':
            return <VkAvatar {...props} style={st} />
        case 'show':
            return <Show {...props} style={st} />
        case 'alert-triangle':
            return <AlertTriangle {...props} style={st} />
        case 'ie-tg':
            return <Telegram {...props} style={st} />
        case 'ie-telegram':
            return <Telegram {...props} style={st} />
        case 'ie-telegram--disabled':
            return <TelegramDis {...props} style={st} />
        case 'grid':
            return <GridHorizontal {...props} style={st} />
        case 'share':
            return <Share {...props} style={st} />
        case 'tiktok-circle':
            return <TiktokCircle {...props} style={st} />
        case 'ie-tiktok':
            return <TikTokIE {...props} style={st} />
        case 'ie-tiktok--disabled':
            return <TikTokIEDisabled {...props} style={st} />
        case 'filter--saves':
            return <Saves {...props} style={st} />
        case 'filter--shares':
            return <Shares {...props} style={st} />
        case 'filter--likes-thumb':
            return <LikesThumb {...props} style={st} />
        case 'unlock':
            return <Unlock {...props} style={st} />
        case 'audience':
            return <Audience {...props} style={st} />
        case 'icon-audience':
            return <Audience {...props} style={st} />
        default:
            return null
    }
}
export default memo(Icons)
