import React, { FC } from 'react'
// import cn from './DonutSegment.module.scss'

export const calcX = (ang: number, radius: number): number => radius * Math.cos(ang - Math.PI / 2) + radius
export const calcY = (ang: number, radius: number): number => radius * Math.sin(ang - Math.PI / 2) + radius

export interface IDonutSegment {
    radius: number
    ang: number
    rotate: number
    color: string
    lessThanHalf: boolean
}

const DonutSegment: FC<IDonutSegment> = ({ radius, ang, rotate, color, lessThanHalf }) => {
    if (ang >= Math.PI * 2) {
        return (
            <circle
                cx={radius}
                cy={radius}
                r={radius}
                style={{
                    fill: color,
                }}
            />
        )
    }

    const x = calcX(ang, radius)
    const y = calcY(ang, radius)

    return (
        <path
            d={`
				M ${radius} ${radius}
				L ${radius} ${0}
				A ${radius} ${radius} 1 ${lessThanHalf ? '0 1' : '1 1'} ${x} ${y}
				Z
			`}
            style={{
                transform: `rotate(${rotate}rad)`,
                fill: `${color}`,
            }}
            // className={cn[`safari-origin`]}
        />
    )
}

export default DonutSegment
