import { Flex } from '@/_yc'
import React, { FC, RefObject, useState } from 'react'
import Text from '@/components/Text'
import DispatchItem from './DispatchItem'
import FunctionItem from './FunctionItem'
import Item from './Item'

import cn from './PartChanger.module.scss'
import { useTranslation } from 'react-i18next'

export interface IChanger {
    values: Array<{
        title: string
        value: number | string | RefObject<HTMLInputElement>
        dispFunction?: (value: string | number) => void
        simpleFunction?: (value: string | number) => void
        isActive?: boolean
        soon?: boolean
    }>
    useActive?: boolean
    currentPart?: number
}

const Soon = () => {
    const { t } = useTranslation()
    return (
        <Flex
            margin="0 0 0 8px"
            style={{ padding: '2px 8px', backgroundColor: '#6071FF', borderRadius: '10px' }}
            align="center"
            content="center"
        >
            <Text fSize="12px" color="white">
                {t('campaign_manager.campaign.tabs.soon')}
            </Text>
        </Flex>
    )
}
const PartChanger: FC<IChanger> = ({ values, useActive, currentPart = 0 }) => {
    const [curIndex, setIndex] = useState(currentPart)
    return (
        <Flex className={cn.root} grow>
            {values.map((i, index) => {
                const isActive = useActive ? i.isActive : curIndex === index
                if (typeof i.value === 'object')
                    return (
                        <Item
                            className={isActive ? cn['item--active'] : cn.item}
                            index={index}
                            setIndex={setIndex}
                            value={i.value}
                            title={i.title}
                            key={index}
                            soon={i.soon}
                        >
                            {i.soon && <Soon />}
                        </Item>
                    )
                if (!!i.dispFunction)
                    return (
                        <DispatchItem
                            className={isActive ? cn['item--active'] : cn.item}
                            index={index}
                            setIndex={setIndex}
                            value={i.value}
                            title={i.title}
                            dispatch={i.dispFunction}
                            key={index}
                            soon={i.soon}
                        >
                            {i.soon && <Soon />}
                        </DispatchItem>
                    )
                if (!!i.simpleFunction)
                    return (
                        <FunctionItem
                            className={isActive ? cn['item--active'] : cn.item}
                            index={index}
                            setIndex={setIndex}
                            value={i.value}
                            title={i.title}
                            func={i.simpleFunction}
                            key={index}
                            soon={i.soon}
                        >
                            {i.soon && <Soon />}
                        </FunctionItem>
                    )
                return null
            })}
        </Flex>
    )
}

export default PartChanger
