import { memo } from 'react'
import { compose } from 'recompose'

import P, { IDemoReport, RequiredInfo } from './DemoReport'
import withAPICall, { IProvidedProps } from '@/_helpers/HOC/withAPICall'
import { Subtract } from 'utility-types'
import service from './services.js'

type afterHOC = Subtract<IDemoReport, IProvidedProps<RequiredInfo>>

// const service =

interface ResponseType {
    avatar: string
    bloggerInterests: { label: string }[]
    comments: number
    er: number
    externalId: number
    followers: number
    follows: number
    fullName?: string
    isVerified: boolean
    likes: number
    posts: number
    postsPerWeek: number
    updated: string
    username: string
    is_unlocked: boolean
}

export default compose<IDemoReport, afterHOC>(
    withAPICall<RequiredInfo, IDemoReport>((props) =>
        ((service as any).getDemo as (id: string) => Promise<ResponseType>)(props.match.params.id).then((res) => ({
            avComments: res.comments,
            avLikes: res.likes,
            avatar: res.avatar,
            categories: res.bloggerInterests.map((i) => i.label),
            er: res.er,
            external_id: res.externalId,
            followers: res.followers,
            follows: res.follows,
            isVerified: res.isVerified,
            postNumber: res.posts,
            username: res.username,
            fullName: res.fullName,
            is_unlocked: res.is_unlocked,
            updated: res.updated,
        }))
    ),
    memo
)(P)
