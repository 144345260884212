import { APIService, authHeader } from "@/_helpers";

export default new APIService({
    methods: {
        getDemo: (external_id) => ({
            url: "/api/reports/demo/",
            headers: authHeader(),
            params: { external_id }
        })
    }
})