function setCookie(name, value, time) {
	document.cookie = `${name}=${value}${time ? `; max-age=${time}` : ""}`
}

function getCookie(tar) {
	let res = null;
	document.cookie.split(";").forEach(pair=>{
		let [name, value] = pair.split("=");
		if (name[0]===" ") name = name.slice(1)
		if (name===tar) res = value
	});
	return res
}

export const cookieService = {
	setCookie,
	getCookie
}