import { useSelector } from 'react-redux'
import { RootStore } from '../StoreType'
import { Platform } from '@/pages/Reports/types'
export const useInstagramHidden = () => {
    // const profile = useSelector((store: RootStore) => store.authentication.user?.profile)
    // const userPackage = profile?.package
    // const countryCode = profile?.login_country_code
    // const language = profile?.language

    // if (countryCode === 'RU' || language === 'ru') {
    //     return false
    // }

    // if (!userPackage) {
    //     return true
    // }

    // if (userPackage === 'Free') {
    //     return true
    // }
    // if (userPackage.length === 0) {
    //     return true
    // }

    return false
}

// import { useSelector } from 'react-redux'
// import { RootStore } from '../StoreType'

export const useVkHidden = () => {
    const profile = useSelector((store: RootStore) => store.authentication.user?.profile)
    const countryCode = profile?.login_country_code
    const language = profile?.language
    const isVkAvailable = useSelector((store: RootStore) => store.authentication.user?.isVkAvailable)

    if (countryCode === undefined || language === undefined) {
        return null
    }

    return countryCode !== 'RU' && language !== 'ru' && !isVkAvailable
}

export const useTgHidden = () => {
    return false
    // const isTgAvailable = useSelector((store: RootStore) => store.authentication.user?.isTgAvailable)
    // if (isTgAvailable === null || isTgAvailable === undefined) {
    //     return null
    // }

    // if (isTgAvailable) return false
    // return true
}

export const useTikTokHidden = () => {
    const platforms = useSelector((store: RootStore) => store.authentication.user?.platforms)
    const isTikTokAvailable = platforms?.includes(Platform.TikTok.toLowerCase())

    return isTikTokAvailable !== undefined ? !isTikTokAvailable : true
}
