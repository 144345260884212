import { userConstants } from '../_constants';

export function bloggers(state = {}, action) {
  //debugger;
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        items: action.bloggers
      };
    case userConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

// export function campaigns(state = {}, action) {
//   //debugger;
//   switch (action.type) {
//     case userConstants.GETCAMPAIGNS_REQUEST:
//       return {
//         loading: true
//       };
//     case userConstants.GETCAMPAIGNS_SUCCESS:
//       return {
//         items: action.campaigns
//       };
//     case userConstants.GETCAMPAIGNS_FAILURE:
//       return {
//         error: action.error
//       };
//     default:
//       return state
//   }
// }

