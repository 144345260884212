import { RangeFilter, RangeValueType } from '@/pages/SearchPage/SearchAdapter/filters/RangeFilter'
import { getRange } from '../../../instagram/utils/getRange'

const getGqlFilter = (range: RangeValueType) => {
    if (!range.from && !range.to) return ''

    return `likeCount: {${getRange(range.from, range.to)}}`
}

export const getLikesFilter = () => {
    return new RangeFilter({
        platform: 'ads_yt',
        title: 'ads_explorer.filters.post_metrics.likes.label',
        helper: 'ads_explorer.filters.post_metrics.likes.text',
        space: 1,
        beforeSend: (data) => {
            return {
                name: 'metrics.likes',
                type: 'range',
                value: data,
                gqlFilter: getGqlFilter(data),
            }
        },
    })
}
