import React from 'react';
import pt from 'prop-types'
import { Tooltip, withStyles } from '@material-ui/core'

import Locked from '../Icons/Default/Locked.js'
import Locker from '../Icons/Default/Locker.js'

import cn from './LockIcon.module.scss'

const ToolT = withStyles(theme => ({
		tooltip: {
			backgroundColor: "#fff",
			padding: "8px 10px",
			borderRadius: 10,
			color: "#888",
			fontSize: 10,
			lineHeight: "13px",
			maxWidth: 220,
			boxShadow: "0 0 10px rgba(0,0,0,.2)"
		},
		arrow: {
			color: "#fff"
		}
	})
)(Tooltip)

const LockIcon = ({ text, locker, className, children, ...props }) => {
	const Icon = locker ? Locker : Locked;
	return (
		<ToolT 
			title={text || children} 
			arrow
			interactive
		>
			<Icon {...props} className={`${locker ? "" : cn.icon} ${className || ""}`}/>
		</ToolT>
	)
}

LockIcon.propTypes = {
	locker: pt.bool,
	text: pt.oneOfType([
		pt.string,
		pt.element
	]).isRequired
}

export { LockIcon }