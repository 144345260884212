import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import Text from '@/components/Text'
import cn from './CreateTemplate.module.scss'
import { useSpaceTranslation } from '@/_helpers'
import { Tooltip } from '@yoloker/ui-collection'
import { Checkbox } from '@/components/MassAddToList/Checkbox'
import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'
import useOpenClose from '@/_helpers/useOpenClose'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import useToken from '@/_helpers/Hooks/useToken'
import { OUTREACH } from '@/routs'
import notif from '@/components/Notifier/model/actions.js'
import { SetBloggersMailingList } from '@/components/MassAddToList/_model/actions'
import LoadingButton from '@/components/Share/LoadingButton'

import { createAdvertisingCampaignReq, GetUserMailsReq } from '../../Service/Campaigns'
import { createTemplateReq } from '../../Service/Templates'
import { MailSettingsPopup } from '@/components/MailSettingsPopup/MailSettingsPopup'
import Selector from '@/pages/SearchPage/SearchAdapter/filters/Selector'
import { Editor } from './Editor/index.js'
import VariableChooser from './Editor/plugins/VariableChooser'
import CheckEmailPopup from './CheckEmail'

interface CreateTemplateProps {
    setShowUpgradeTariff: React.Dispatch<React.SetStateAction<boolean>>
}
export const CreateTemplate: FC<CreateTemplateProps> = ({ setShowUpgradeTariff }) => {
    const t = useSpaceTranslation('outreach.templates.create')
    const [isSaveTemplate, , , setIsSaveTemplate] = useOpenClose()
    const [isCheckEmailOpen, openCheckEmail, closeCheckEmail] = useOpenClose()

    const token = useToken()
    const dispatch = useDispatch()

    const [campaignName, setCampaignName] = useState('')
    const [senderName, setSenderName] = useState('')
    const [subject, setSubject] = useState('')
    const [templateText, setTemplateText] = useState('')

    const [loading, setLoading] = useState(false)

    const mailingList = useSelector((store: RootStore) => store.MassAddToList.mailingList)

    const currentTemplateId = useSelector((store: RootStore) => store.Outreach.currentTemplate)
    const templates = useSelector((store: RootStore) => store.Outreach.templates)
    const excludedBloggers = useSelector((store: RootStore) => store.Outreach.excludedBloggers)
    const excludedChannels = useSelector((store: RootStore) => store.Outreach.excludedChannels)

    //gmail begin
    const [senderOptions, setSenderOptions] = useState<{ label: string; value: number }[]>([])
    const [selectedSender, setSelectedSender] = useState<null | number>(null)
    const [isOpenPopup, openPopup, closePopup] = useOpenClose()

    const [valueNameMap, setValueNameMap] = useState<{ name: string; value: number }[]>([])

    const isSendButtonDisabled = useMemo(() => {
        return campaignName === '' || senderName === '' || templateText === '' || selectedSender === null
    }, [campaignName, senderName, templateText, selectedSender])

    useEffect(() => {
        if (!token) return
        GetUserMailsReq(token)
            .then((res) => {
                const options = res.results.map((mail) => ({
                    label: mail.email,
                    value: mail.id,
                }))
                setValueNameMap(
                    res.results.map((mail) => ({
                        name: mail.sender_name,
                        value: mail.id,
                    }))
                )
                setSenderOptions(options)
            })
            .catch((e) => {
                console.log(e)
            })
    }, [token])
    //gmail end

    useEffect(() => {
        // sync storage and redux
        if (mailingList.instList.length === 0 && mailingList.ytList.length === 0) {
            const storageList = localStorage.getItem('mailingList')

            if (storageList) {
                const parsed = JSON.parse(storageList)
                if (parsed.instList.length !== 0 && parsed.ytList.length !== 0) {
                    dispatch(SetBloggersMailingList(parsed))
                }
            }
        } else {
            localStorage.setItem('mailingList', JSON.stringify(mailingList))
        }
    }, [mailingList, dispatch])

    const createCampaign = useCallback(() => {
        if (!selectedSender) {
            openPopup()
            return
        }
        if (!token) {
            dispatch(notif.error(t('authorization_error')))
            return
        }
        if (mailingList.instList.length === 0 && mailingList.ytList.length === 0) {
            dispatch(notif.error(t('no_bloggers')))
            return
        }
        const instIds = mailingList.instList.map((b) => b.externalId).filter((id) => id !== undefined) as string[]
        const ytIds = mailingList.ytList.map((b) => b.channelId).filter((id) => id !== undefined) as string[]
        const setId = mailingList.setId as number[]
        setLoading(true)

        createAdvertisingCampaignReq(token, {
            email_server_type: 'gmail',
            email_account: selectedSender,
            name: campaignName,
            sender: senderName,
            message: templateText,
            bloggers: setId.length === 0 ? instIds : [],
            set_id: setId[0],
            channels: setId.length === 0 ? ytIds : [],
            subject: subject,
            exclude_bloggers: excludedBloggers,
            exclude_channels: excludedChannels,
        })
            .then(() => {
                setLoading(false)
                if (!isSaveTemplate) {
                    window.location.href = `${OUTREACH}/messenger`
                } else {
                    createTemplateReq(token, {
                        name: campaignName,
                        message: templateText,
                        sender: senderName,
                        subject: subject,
                    }).then(() => {
                        window.location.href = `${OUTREACH}/messenger`
                    })
                }
            })
            .catch((e) => {
                setLoading(false)
                if (e.response?.status === 402) {
                    setShowUpgradeTariff(true)
                }
            })
    }, [
        campaignName,
        senderName,
        templateText,
        mailingList,
        token,
        isSaveTemplate,
        dispatch,
        t,
        setShowUpgradeTariff,
        subject,
        excludedBloggers,
        excludedChannels,
        selectedSender,
        openPopup,
    ])

    // const onGeneratorSave = ({ template, sender }: { template: string; sender: string }) => {
    //     setTemplateText(template)
    //     setSenderName(sender)
    // }

    const onSenderChange = useCallback(
        (value) => {
            if (value === -1) {
                openPopup()
            }
            setSenderName(valueNameMap.find((v) => v.value === value)?.name || '')
            setSelectedSender(value)
        },
        [openPopup, valueNameMap]
    )

    useEffect(() => {
        const currTemplate = templates.find((t) => t.id === currentTemplateId)

        if (!currTemplate) return

        setTemplateText(currTemplate.message)
        setSenderName(currTemplate.sender)
        setCampaignName(currTemplate.name)
        setSubject(currTemplate.subject)
    }, [currentTemplateId, templates])

    return (
        <div className={cn.createTemplate}>
            <Text semibold fSize={18} margin="0 0 10px 0">
                {t('title')}
            </Text>
            <Text color="gray-new-2" margin="0 0 22px 0">
                {t('subtitle')}
            </Text>

            <div className={cn.secondBlock}>
                <div className={cn.inputBlock}>
                    <div className={cn.blockTitle}>
                        <Text color="gray-new-2" fSize={12} semibold>
                            {t('campaign_name.title')}
                        </Text>
                        <Tooltip text={t('campaign_name.helper')}></Tooltip>
                    </div>

                    <input
                        placeholder={t('campaign_name.placeholder')}
                        className={cn.inputField}
                        value={campaignName}
                        onChange={(e) => setCampaignName(e.target.value)}
                    ></input>
                </div>

                <div className={cn.inputBlock}>
                    <div className={cn.blockTitle}>
                        <Text color="gray-new-2" fSize={12} semibold>
                            {t('sender.title')}
                        </Text>
                        <Tooltip text={t('sender.helper')}></Tooltip>
                    </div>
                    <Selector
                        options={senderOptions.concat({ value: -1, label: t('sender.add') })}
                        isActive={(val) => val === selectedSender}
                        onChange={onSenderChange}
                        display={
                            senderOptions.find((s) => s.value === selectedSender)?.label || t('sender.placeholder')
                        }
                    />
                </div>
            </div>

            <div className={cn.letterTemplate}>
                <Flex className={cn.blockTitle} content="space-between">
                    <Flex align="center">
                        <Text color="gray-new-2" fSize={12} semibold>
                            {t('editor.title')}
                        </Text>
                        <Tooltip text={t('editor.helper')}></Tooltip>
                    </Flex>

                    <Flex align="center">
                        <div onClick={setIsSaveTemplate}>
                            <Checkbox value={isSaveTemplate ? 'checked' : 'empty'} />
                        </div>
                        <Text fSize={12} color="gray-4" component="label" margin="0 0 0 6px">
                            {t('save_template')}
                        </Text>
                    </Flex>
                </Flex>

                <div className={cn['letterTemplate--border']}>
                    <Editor setTemplateText={setTemplateText} setSenderName={setSenderName} setSubject={setSubject}>
                        <div className={cn.inputBlock} style={{ margin: '7px 2px 0 2px' }}>
                            <Flex align="center">
                                <input
                                    placeholder={t('subject.placeholder')}
                                    className={cn['inputField--in-editor']}
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                ></input>
                                <VariableChooser />
                            </Flex>
                            <div className={cn['inputField--in-editor--line']} />
                        </div>
                    </Editor>
                </div>
            </div>

            <hr className={cn.divider}></hr>

            <Flex content="space-between" margin="10px 0 0 0">
                <LoadingButton
                    variant="outline"
                    disabled={isSendButtonDisabled}
                    onClick={openCheckEmail}
                    loading={loading}
                    noDownloadIcon
                    noLoadingText
                    width="49%"
                >
                    {t('btn_test')}
                </LoadingButton>

                <LoadingButton
                    variant="primary"
                    disabled={isSendButtonDisabled}
                    onClick={createCampaign}
                    loading={loading}
                    noDownloadIcon
                    noLoadingText
                    width="49%"
                >
                    {t('btn')}
                    <Icons className={cn.sendIcon} name="arrow-right" />
                </LoadingButton>
            </Flex>
            <CheckEmailPopup
                isOpen={isCheckEmailOpen}
                close={closeCheckEmail}
                messageBody={templateText}
                senderId={selectedSender || 0}
            />

            <MailSettingsPopup isOpen={isOpenPopup} close={closePopup} />
        </div>
    )
}
