import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.006 14.012H10.986C10.924 14.012 4.721 14 3.156 13.575C2.29543 13.3432 1.62335 12.6707 1.392 11.81C1.11058 10.2224 0.974685 8.61235 0.986004 7C0.978734 5.38501 1.11831 3.77271 1.403 2.183C1.64071 1.32151 2.30983 0.645949 3.169 0.4C4.691 0 10.723 0 10.979 0H11C11.063 0 17.282 0.012 18.831 0.437C19.6898 0.670009 20.3605 1.3411 20.593 2.2C20.8834 3.79354 21.0197 5.41134 21 7.031C21.007 8.64403 20.8671 10.2543 20.582 11.842C20.3477 12.7016 19.6752 13.3726 18.815 13.605C17.295 14.008 11.262 14.012 11.006 14.012ZM9.00601 4.005L9.00101 10.005L14.213 7.005L9.00601 4.005Z"
                fill="#76849B"
            />
        </svg>
    )
}
