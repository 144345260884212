import React, { FC, ReactNode } from 'react'

import LinksUnderPageTitle from '@/components/LinksUnderPageTitle'
import Heading from '@/components/Heading'
import Icons, { TUiIconName } from '@/components/Icons'
import Flex from '@/_yc/Flex'

import cn from './PageTitle.module.scss'
interface IPageTitle {
    title: string | ReactNode
    linkToHelper: string
    icon: TUiIconName
    marginBottom?: string
    iconSize?: string
    iconStyle?: React.CSSProperties
    children?: ReactNode
}

const PageTitle: FC<IPageTitle> = ({ title, linkToHelper, icon, marginBottom, iconSize, iconStyle, children }) => {
    return (
        <>
            <Heading type="h3" style={{ color: '#3D3F42', marginBottom: marginBottom || 24 }}>
                <Flex>
                    <div className={cn.wrap} style={iconStyle}>
                        <Icons
                            name={icon}
                            className={cn.icon}
                            style={iconSize ? { width: iconSize, height: iconSize } : {}}
                        />
                    </div>
                    <Flex column margin="0 0 0 20px">
                        {title}
                        {!children ? (
                            <div style={{ marginTop: 2 }}>
                                <LinksUnderPageTitle link={linkToHelper} />
                            </div>
                        ) : (
                            <div>{children}</div>
                        )}
                    </Flex>
                </Flex>
            </Heading>
        </>
    )
}
export default PageTitle
