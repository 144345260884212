import React from 'react'
import styles from './footer.module.scss'
import { ReactComponent as MagicIcon } from '../../icons/magic.svg'
import { Flex } from '@/_yc'
import Text from '@/components/Text'

interface FooterProps {
    text: string
}

const Footer: React.FC<FooterProps> = ({ text }) => {
    return (
        <Flex align="center" className={styles.footer}>
            <MagicIcon />
            <Text>{text}</Text>
        </Flex>
    )
}

export default Footer
