import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setSearchText as action } from '../../Model/actions'
import { RootStore } from '@/_helpers/StoreType'

export const useSearchText = () => {
    const search = useSelector((store: RootStore) => store.search.search)
    const dispatch = useDispatch()

    const setSearch = useCallback(
        (next: string) => {
            dispatch(action({ search: next }))
        },
        [dispatch]
    )

    return { search, setSearch }
}
