import { useSpaceTranslation } from '@/_helpers'
import React, { FC, useCallback, useContext, useMemo } from 'react'
import Text from '@/components/Text'
import cn from './Header.module.scss'
import { ChooseAllNEW } from '@/components/MassAddToList/ChooseAll'
import { useDispatch, useSelector } from 'react-redux'
import { RemoveListMediaPlannerPlans } from '@/components/MassAddToList/_model/actions'
import { RootStore } from '@/_helpers/StoreType'
import { useMutation } from '@apollo/client'
import { DELETE_MEDIA_PLANS } from '../../utils/queries'
import { CampaignManagerContext } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerContext'

interface HeaderProps {
    refetch: () => void
}
export const Header: FC<HeaderProps> = ({ refetch }) => {
    const t = useSpaceTranslation('media_planner.table.header')
    const selectedMediaPlans = useSelector((state: RootStore) => state.MassAddToList.mediaPlanner.selectedMediaPlans)
    const showSelectedMenu = useMemo(() => selectedMediaPlans.length > 0, [selectedMediaPlans])
    const dispatch = useDispatch()

    const context = useContext(CampaignManagerContext)
    const [deleteMediaPlan] = useMutation(DELETE_MEDIA_PLANS, {
        context: context,
    })

    const onChooseAllClick = useCallback(() => {
        if (showSelectedMenu) {
            dispatch(RemoveListMediaPlannerPlans())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSelectedMenu])

    const onDeleteClick = useCallback(() => {
        deleteMediaPlan({
            variables: {
                ids: selectedMediaPlans,
            },
        }).then(() => {
            return refetch()
        })
    }, [deleteMediaPlan, selectedMediaPlans, refetch])

    if (showSelectedMenu) {
        return (
            <div className={cn.menu}>
                <div onClick={onChooseAllClick}>
                    <ChooseAllNEW platform="media" value={showSelectedMenu ? 'dashed' : 'empty'} />
                </div>
                <div className={cn['menu-button']} onClick={onDeleteClick}>
                    <Text color="gray-2" fSize={10}>
                        {t('delete')}
                    </Text>
                </div>
            </div>
        )
    }

    return (
        <div className={cn.header}>
            <div onClick={onChooseAllClick}>
                <ChooseAllNEW platform="media" value={showSelectedMenu ? 'dashed' : 'empty'} />
            </div>

            <Text color="gray-3" fSize={10}>
                {t('title')}
            </Text>

            <Text color="gray-3" fSize={10}>
                {t('creator')}
            </Text>

            <Text color="gray-3" fSize={10}>
                {t('created')}
            </Text>

            <Text color="gray-3" fSize={10}>
                {t('social_media')}
            </Text>

            {/* <Text color="gray-3" fSize={10}>
                {t('influencers')}
            </Text> */}
        </div>
    )
}
