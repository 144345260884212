import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.66669 16.6667C5.14607 16.6674 3.81768 15.639 3.43752 14.1667H1.66669V12.5H3.43835C3.87139 10.8229 5.51678 9.75688 7.22442 10.0471C8.93206 10.3374 10.1328 11.8872 9.9874 13.6132C9.84198 15.3392 8.39882 16.6662 6.66669 16.6667ZM6.66669 11.6667C5.75618 11.6676 5.01493 12.399 5.00188 13.3095C4.98883 14.2199 5.70883 14.9723 6.61893 14.9993C7.52903 15.0263 8.29238 14.3179 8.33335 13.4083V13.7417V13.3333C8.33335 12.4129 7.58716 11.6667 6.66669 11.6667ZM18.3334 14.1667H10.8334V12.5H18.3334V14.1667ZM10.8334 10C9.31304 10.0004 7.9851 8.97204 7.60502 7.5H1.66669V5.83333H7.60502C8.03805 4.15621 9.68345 3.09021 11.3911 3.38047C13.0987 3.67073 14.2995 5.22051 14.1541 6.94653C14.0086 8.67254 12.5655 9.99956 10.8334 10ZM10.8334 5C9.92285 5.00092 9.18159 5.73237 9.16855 6.64279C9.1555 7.5532 9.87549 8.30559 10.7856 8.3326C11.6957 8.35961 12.459 7.65125 12.5 6.74167V7.075V6.66667C12.5 5.74619 11.7538 5 10.8334 5ZM18.3334 7.5H15V5.83333H18.3334V7.5Z"
            fill="#333333"
        />
    </svg>
)
