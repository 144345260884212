import React, { FC, useCallback, useMemo } from 'react'

import { ContentType, Currency, MediaPlanBlogger } from '../../utils/types'
import { MassAddCheckBoxNEW } from '@/components/MassAddToList'

import cn from './Row.module.scss'
import Flex from '@/_yc/Flex'
import Text from '@/components/Text'
import { getShortString } from '@/_helpers/strings/getShortString'
import { Config } from '../ConfigurableTable'
import Avatar from '@/components/Avatar'
import { Cell, CellType } from './Cell/Cell'
import { resolveObjectPath } from '../../utils/utils'
import BloggerLink from '@/components/Share/BloggerLink'

interface RowProps {
    blogger: MediaPlanBlogger
    contentTypes: ContentType[]
    index: number
    config: Config
    isUnlocked: boolean
    currency: Currency
}

export const Row: FC<RowProps> = ({ blogger, config, index, isUnlocked, contentTypes, currency }) => {
    const gridColumns = useMemo(() => {
        const columns = config.columns.filter((column) => column.active)
        const columnsWidth = columns.map((column) => column.width)
        return columnsWidth.join(' ')
    }, [config])

    const getCellType = useCallback(
        (cellType: CellType) => {
            return cellType === CellType.YoScore && !isUnlocked ? CellType.Analyze : cellType
        },
        [isUnlocked]
    )

    const getValue = useCallback(
        (cellType: CellType, columnKey: string) => {
            if (cellType === CellType.YoScore && !isUnlocked) {
                return blogger.externalId
            }

            return resolveObjectPath(columnKey, blogger)
        },
        [blogger, isUnlocked]
    )

    return (
        <div className={cn.item} key={blogger.data.info.username}>
            <div className={cn.row} style={{ gridTemplateColumns: `28px 256px ${gridColumns}` }}>
                <MassAddCheckBoxNEW id={blogger.externalId} type={'media-planner-bloggers'} index={index + 1} />
                <BloggerLink id={blogger.externalId} newTab platform={blogger.bloggerType}>
                    <Flex align="center">
                        <Avatar
                            alt="AVA"
                            src={blogger.data.info.avatar}
                            type={blogger.bloggerType}
                            aria-label="Аватарка"
                            className={cn.ava}
                        />

                        <Flex column margin="0 0 0 16px">
                            <Text semibold style={{ lineHeight: '18px', cursor: 'pointer' }} onClick={() => {}}>
                                {getShortString(blogger.data.info.fullName, 20)}
                            </Text>
                            <Text fSize={12} color="gray-3" margin="4px 0 0 0" style={{ lineHeight: '16px' }}>
                                {blogger.data.info.username}
                            </Text>
                        </Flex>
                    </Flex>
                </BloggerLink>

                {config.columns
                    .filter((column) => column.active)
                    .map((column) => (
                        <Cell
                            contentTypes={contentTypes}
                            key={column.key}
                            valueKey={column.key}
                            blogger={blogger}
                            cellType={getCellType(column.type)}
                            value={getValue(column.type, column.key)}
                            currency={currency}
                        />
                    ))}
            </div>
        </div>
    )
}
