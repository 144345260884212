import React, { FC, useCallback, ReactNode, useMemo, CSSProperties } from 'react'
import { ClickAwayListener } from '@material-ui/core'

import cn from './Modal.module.scss'
import { Stylable } from '@/Types'
import Flex from '../Flex'
import { Portal } from '@/_helpers'
import CloseIcon from './CloseIcon'

export interface IModal extends Stylable<{ root: string; background: string }> {
    onClose?: VoidFunction

    width?: string | number

    children?: ReactNode

    closeIcon?: boolean

    closeHigher?: boolean
}

const Modal: FC<IModal> = ({ onClose, ...props }) => {
    const close = useCallback(() => {
        onClose && onClose()
    }, [onClose])

    const style = useMemo<CSSProperties>(() => {
        return {
            margin: props.margin,
            width: props.width,
            ...props.style,
        }
    }, [props.margin, props.style, props.width])

    return (
        <Portal>
            <Flex content="center" className={`${cn.background} ${props.classes?.background || ''}`} align="flex-start">
                <ClickAwayListener onClickAway={close}>
                    <div className={`${cn.modal} ${props.className || ''} ${props.classes?.root || ''}`} style={style}>
                        {props.closeIcon && (
                            <Flex margin={props.closeHigher ? '-30px 0 0 0' : undefined} content="flex-end">
                                <CloseIcon className={cn.close} onClick={onClose} />
                            </Flex>
                        )}
                        {props.children}
                    </div>
                </ClickAwayListener>
            </Flex>
        </Portal>
    )
}

export default Modal
