import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="25" height="25" rx="12.5" fill="#27AE60" />
        <path
            d="M10.7455 17.2471C10.4412 17.2471 10.1369 17.1484 9.90511 16.9512L5.3483 13.0801C4.8839 12.686 4.8839 12.0464 5.3483 11.6522C5.81229 11.2577 6.56516 11.2577 7.02918 11.6522L10.7455 14.8093L17.2356 9.29595C17.7 8.90143 18.452 8.90143 18.9164 9.29595C19.3808 9.69011 19.3808 10.3297 18.9164 10.7239L11.5859 16.9512C11.3541 17.1484 11.0498 17.2471 10.7455 17.2471Z"
            fill="white"
        />
    </svg>
)
