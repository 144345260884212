import React from 'react';
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { withWidth } from '@material-ui/core'

import { combineHOC, footerVariant } from '../_helpers/'
import { OfertaLink } from '../_yc/'
import { TARIFFS } from '../routs.js'

import cn from './BottomBar.module.scss'

const menuItems = [
    // { label: "footer.labels.about", to: ABOUT },
    { label: "footer.labels.tariff", to: TARIFFS  },
    { label: "footer.labels.api", to: "/", disabled: true },
    { label: "footer.labels.blog", to: "https://yoloco.io/blog" ,external: true},
    // { label: "footer.labels.conf", to: "/", disabled: true }
]

const year = new Date().getFullYear();

function BottomBar(props){
    const { t } = useTranslation();
    const { variant: { background, modifier }} = props;
    return (
        <footer 
            className={cn[`footer${modifier ? `--${modifier}` : ""}`]} 
            style = {{ background }}
        >
            <div className = {cn.root}>
                <div className = {cn.menu}>
                    <div className = {cn.content}>
                        {menuItems.map((item, i) => !item.disabled ? 
                            (!item.external?
                                <Link 
                                    to = {item.to}
                                    key = {i} 
                                    className = {`${cn.item} ${cn.common}`}
                                >
                                    {t(item.label)}
                                </Link>
                            : <a href={item.to} className={`${cn.item} ${cn.common}`} key = {i} >{t(item.label)}</a>) 
                        : <div key = {i} className = {`${cn.item} ${cn.common}`}>{t(item.label)}</div>)}
                        <OfertaLink className = {`${cn.item} ${cn.common}`} >{t("footer.labels.conf")}</OfertaLink>
                    </div>
                </div>
                <div className = {cn.common}>
                    © {year} Yoloco
                </div>
            </div>
        </footer>
    );
}
// eslint-disable-next-line
BottomBar = combineHOC(
    footerVariant,
    withWidth("xs")
)(BottomBar);

export default BottomBar
