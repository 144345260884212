import React, { ComponentProps, forwardRef, ForwardRefRenderFunction } from 'react'
import { Stylable } from '@/Types'
import cn from './FilterBase.module.scss'
import { Flex } from '@/_yc'
import Icons from '@/components/Icons'
import useClass from '@/_helpers/Hooks/useClass'
import { Soon } from '@/components/Soon'
export interface IFilterBase extends Stylable, ComponentProps<typeof Flex> {
    isActive?: boolean
    withArrow?: boolean
    disabled?: boolean
}

const FilterBase: ForwardRefRenderFunction<any, IFilterBase> = (
    { className, isActive, withArrow, disabled, soon, ...props },
    ref
) => {
    const root = useClass(disabled ? cn['btn--disabled'] : isActive ? cn['btn--opened'] : cn['btn--closed'], className)

    const disabledOrSoon = disabled || soon
    return (
        <Flex
            {...props}
            onClick={disabledOrSoon ? null : props.onClick}
            ref={ref}
            align="center"
            content="space-between"
            className={root}
        >
            {props.children}

            {soon && <Soon />}
            {withArrow && !soon && (
                <Icons name="arrow-down" className={cn[isActive ? 'icon--opened' : 'icon--closed']} />
            )}
        </Flex>
    )
}

export default forwardRef(FilterBase)
