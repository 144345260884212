import Portal from '@/_helpers/Portal'
import Flex from '@/_yc/Flex'
import Popup from '@/_yc/Popup'

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import Text from '@/components/Text'

import cn from './TemplateGenerator.module.scss'
import Icons from '@/components/Icons'
import { useSpaceTranslation } from '@/_helpers'
import Selector from '@/pages/SearchPage/SearchAdapter/filters/Selector'
import Button from '@/components/Button'
import { getGptSettingsReq } from '@/pages/Outreach/Service/Templates'
import useToken from '@/_helpers/Hooks/useToken'
import { Configuration, OpenAIApi } from 'openai'
import LoadingButton from '@/components/Share/LoadingButton'

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 700,
    zIndex: 2000,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}

interface TemplateGeneratorProps {
    isOpen: boolean
    close: () => void
    onGeneratorSave: ({ template, sender }: { template: string; sender: string }) => void
}

export const TemplateGenerator: FC<TemplateGeneratorProps> = (props) => {
    const t = useSpaceTranslation('outreach.templates.create.editor.generator')

    const [brand, setBrand] = useState('')
    const [sender, setSender] = useState('')
    const [tone, setTone] = useState(0)

    const [backendPrompt, setBackendPrompt] = useState('')
    const [backendTemperature, setBackendTemperature] = useState(0.5)
    const [backendMaxTokens, setBackendMaxTokens] = useState(100)

    const [err, setErr] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const token = useToken()

    const [productDescription, setProductDescription] = useState('')

    const [generatedTemplate, setGeneratedTemplate] = useState('')

    useEffect(() => {
        if (!token) return

        getGptSettingsReq(token)
            .then((res) => {
                setBackendPrompt(res.prompt)
                setBackendTemperature(res.temperature)
                setBackendMaxTokens(res.max_tokens)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [token])

    const toneOptions = [
        {
            label: 'Official',
            value: 0,
        },
        {
            label: 'Neutral',
            value: 1,
        },
        {
            label: 'Friendly',
            value: 2,
        },
    ]

    const onClose = () => {
        props.close()
    }

    const constructPrompt = useCallback(() => {
        let prompt = backendPrompt
        prompt = prompt.replace('<brand_name>', brand)
        prompt = prompt.replace('<sender_name>', sender)
        prompt = prompt.replace('<product_description>', productDescription)
        prompt = prompt.replace('<tone>', toneOptions[tone].label)
        return prompt
    }, [backendPrompt, brand, sender, productDescription, tone, toneOptions])

    const onGenerate = useCallback(() => {
        const apiKey = localStorage.getItem('openai_api_key')
        if (!apiKey) return

        const configuration = new Configuration({
            apiKey: apiKey,
        })
        const openai = new OpenAIApi(configuration)

        const prompt = constructPrompt()

        setIsLoading(true)
        openai
            .createChatCompletion({
                model: 'gpt-3.5-turbo',
                messages: [
                    {
                        role: 'user',
                        content: prompt,
                    },
                ],
                max_tokens: backendMaxTokens,
                temperature: backendTemperature,
                top_p: 1,
            })
            .then((res: any) => {
                setGeneratedTemplate(res.data.choices[0]?.message?.content?.trim())
                setIsLoading(false)
            })
            .catch((err: any) => {
                if (err.response.data.error) {
                    setErr(err.response.data.error.message)
                }
                setIsLoading(false)
            })
    }, [constructPrompt, backendMaxTokens, backendTemperature])

    const isGenerateDisabled = useMemo(() => {
        return brand === '' || sender === '' || productDescription === ''
    }, [brand, sender, productDescription])

    const onSave = useCallback(() => {
        props.onGeneratorSave({
            template: generatedTemplate,
            sender: sender,
        })
        props.close()
    }, [generatedTemplate, sender, props])

    return (
        <Portal>
            <div className={cn.root}>
                <Popup style={style} isOpen={props.isOpen} onClose={props.close}>
                    <div className={cn.popup}>
                        <Flex content="space-between" align="center" margin="0 0 20px 0">
                            <Flex column>
                                <Text fSize={20}>{t('title')}</Text>
                            </Flex>
                            <div onClick={onClose}>
                                <Icons name="close" className={cn.close}></Icons>
                            </div>
                        </Flex>

                        <Flex style={{ gap: 20 }}>
                            <div className={cn.fieldContainer}>
                                <Flex margin="0 0 6px 0">
                                    <Text color="gray-1" fSize={12} semibold>
                                        {t('brand.title')}
                                    </Text>
                                </Flex>
                                <input
                                    placeholder={t('brand.placeholder')}
                                    className={cn.inputField}
                                    value={brand}
                                    onChange={(e) => setBrand(e.target.value)}
                                ></input>
                            </div>

                            <div className={cn.fieldContainer}>
                                <Flex margin="0 0 6px 0">
                                    <Text color="gray-1" fSize={12} semibold>
                                        {t('sender.title')}
                                    </Text>
                                </Flex>
                                <input
                                    placeholder={t('sender.placeholder')}
                                    className={cn.inputField}
                                    value={sender}
                                    onChange={(e) => setSender(e.target.value)}
                                ></input>
                            </div>

                            <div className={cn.fieldContainer}>
                                <Flex margin="0 0 6px 0">
                                    <Text color="gray-1" fSize={12} semibold>
                                        {t('tone.title')}
                                    </Text>
                                </Flex>
                                <Selector
                                    isActive={(v) => v === tone}
                                    options={toneOptions}
                                    onChange={(v) => setTone(v)}
                                    placeholder={t('tone.placeholder')}
                                    display={toneOptions[tone].label}
                                    disablePortal
                                />
                            </div>
                        </Flex>

                        <div className={cn.fieldContainer} style={{ marginTop: 20 }}>
                            <Flex margin="0 0 6px 0">
                                <Text color="gray-1" fSize={12} semibold>
                                    {t('product.title')}
                                </Text>
                            </Flex>
                            <textarea
                                className={cn.productArea}
                                placeholder={t('product.placeholder')}
                                value={productDescription}
                                onChange={(e) => setProductDescription(e.target.value)}
                            ></textarea>
                        </div>

                        <div className={cn.fieldContainer} style={{ marginTop: 20 }}>
                            <LoadingButton
                                variant="primary"
                                disabled={isGenerateDisabled}
                                onClick={onGenerate}
                                loading={isLoading}
                                noDownloadIcon
                                noLoadingText
                                width="100%"
                            >
                                <Icons className={cn.generateIcon} name="magic" />

                                {t('generate_btn')}
                            </LoadingButton>
                            {err !== '' && (
                                <Text color="red-1" fSize={10}>
                                    {err}
                                </Text>
                            )}
                        </div>

                        <div className={cn.fieldContainer} style={{ marginTop: 20 }}>
                            <Flex margin="0 0 6px 0">
                                <Text color="gray-1" fSize={12} semibold>
                                    {t('generated.title')}
                                </Text>
                            </Flex>
                            <textarea
                                className={cn.generatedTemplate}
                                placeholder={t('product.placeholder')}
                                value={generatedTemplate}
                                onChange={(e) => setGeneratedTemplate(e.target.value)}
                            ></textarea>
                        </div>

                        <Flex content="flex-end" margin="20px 0 0 0">
                            <Button variant="primary" onClick={onSave}>
                                {t('save')}
                            </Button>
                        </Flex>
                    </div>
                </Popup>
            </div>
        </Portal>
    )
}
