import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.83342 6.83317V11.8332H5.16675V6.83317H0.166748V5.1665H5.16675V0.166504H6.83342V5.1665H11.8334V6.83317H6.83342Z"
                fill="#929EB0"
            />
        </svg>
    )
}
