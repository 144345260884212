import React, { FC } from 'react'

import Flex from '@/_yc/Flex'
import Instagram from '@/_yc/Icons/Tariffs/Instagram'
import Youtube from '@/_yc/Icons/Tariffs/Youtube'

interface IIcons {
    tariffs: Array<string>
}
const style = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',

    color: '#7685FF',
    alignSelf: 'center',
}

const TariffIcons: FC<IIcons> = ({ tariffs }) => {
    const getTariffType = () => {
        const includesInst = tariffs.includes('instagram')
        const includesYoutube = tariffs.includes('youtube')

        if (includesInst && includesYoutube) {
            return 'both'
        } else if (includesInst) {
            return 'inst'
        } else {
            return 'yt'
        }
    }

    const type = getTariffType()

    return (
        <>
            <Flex const="space-between" align="center">
                {type !== 'yt' && <Instagram style={{ marginRight: '4px' }} />}
                {type === 'both' && <span style={style}>+</span>}
                {type === 'inst' ? (
                    <Youtube color="#CCCCCC" style={{ marginLeft: '4px' }} />
                ) : (
                    <Youtube style={{ marginLeft: '4px' }} />
                )}
            </Flex>
        </>
    )
}

export default TariffIcons
