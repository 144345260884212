import React, { useEffect, useState } from 'react'
import cn from './EmailAccounts.module.scss'
import { EmptyAccounts } from './EmptyAccounts/EmptyAccounts'
import useOpenClose from '@/_helpers/useOpenClose'
import { MailSettingsPopup } from '@/components/MailSettingsPopup/MailSettingsPopup'
import { GetUserMailsReq, MailServerData } from '@/pages/Outreach/Service/Campaigns'
import useToken from '@/_helpers/Hooks/useToken'
import { Row } from './Row/Row'
import Loader from '@/components/Loader'
import Flex from '@/_yc/Flex'
import { Header } from './Header/Header'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import { setEmailAccounts } from '../Model/actions'
import { SetDefault } from '@/components/MassAddToList/_model/actions'

export interface EmailAccount {
    id: number
    senderName: string
    email: string
    type: string
    totalEmails: number
    dailyLimit: number
    signature: string
    sentToday: number
    emailCredentials?: {
        imap: MailServerData
        smtp: MailServerData
    }
}

export const EmailAccounts = () => {
    const [isOpenPopup, openPopup, closePopup] = useOpenClose()
    const [accountsLoading, setAccountsLoading] = useState(false)

    const dispatch = useDispatch()
    const emailAccounts = useSelector((state: RootStore) => state.Settings.emailAccounts)

    const token = useToken()

    useEffect(() => {
        if (!token) return
        setAccountsLoading(true)
        GetUserMailsReq(token)
            .then((res) => {
                const emailAccounts = res.results.map((mail) => ({
                    id: mail.id,
                    senderName: mail.sender_name,
                    email: mail.email,
                    type: mail.email_server_type,
                    totalEmails: mail.total_emails,
                    dailyLimit: mail.day_limit,
                    sentToday: mail.sent_today,
                    signature: mail.signature,
                    emailCredentials: mail.email_credentials,
                }))
                dispatch(setEmailAccounts(emailAccounts))
                setAccountsLoading(false)
            })
            .catch((e) => {
                dispatch(setEmailAccounts([]))
                setAccountsLoading(false)
                console.log(e)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, isOpenPopup])

    useEffect(() => {
        return () => {
            dispatch(SetDefault())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={cn.emailAccounts}>
            <Header />
            <div className={cn.rows}>
                {emailAccounts.map((row, i) => (
                    <Row key={row.email} {...row} index={i} />
                ))}
            </div>

            {accountsLoading && (
                <Flex content="center" align="center">
                    <Loader />
                </Flex>
            )}
            {emailAccounts.length === 0 && !accountsLoading && <EmptyAccounts open={openPopup} />}

            <MailSettingsPopup isOpen={isOpenPopup} close={closePopup} />

            <div className={cn.table}></div>
        </div>
    )
}
