import React, { useRef, useState } from 'react'
import Text from '@/components/Text'
import { useSpaceTranslation } from '@/_helpers'
import Popup from '@/_yc/Popup'
import useOpenClose from '@/_helpers/useOpenClose'
import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'
import { ISharedPerson } from './model'

import cn from './Share.module.scss'

interface IEdit {
    member: ISharedPerson
    editedMembers: ISharedPerson[]
    setEditedMembers: Function
}

const EditRole: React.FC<IEdit> = ({ setEditedMembers, editedMembers, member }) => {
    const t = useSpaceTranslation('campaign_manager.header')
    const [isOpen, open, close] = useOpenClose()
    const ref = useRef(null)
    const [memberNewRole, setmemberNewRole] = useState<string | null>(null)

    const accessTypes: any = {
        Editor: t('share.roles.editor'),
        Reader: t('share.roles.reader'),
        Commentator: t('share.roles.commentator'),
        Owner: t('share.roles.owner'),
    }

    const updateMember = (newRole: string) => {
        const newMembers = editedMembers.filter((item) => item.email !== member.email)
        setEditedMembers([...newMembers, { ...member, access_type: newRole }])
        setmemberNewRole(newRole)
        close()
    }
    if (member.access_type === 'Owner') {
        return (
            <Flex align="center" style={{ padding: '0 8px 0 0' }}>
                <Text color="gray-3">{t('share.roles.owner')}</Text>
            </Flex>
        )
    }
    return (
        <>
            <Flex style={{ cursor: 'pointer' }} onClick={open} align="center">
                <Text ref={ref} color="violet-1" style={memberNewRole ? { fontWeight: 600 } : { fontWeight: 500 }}>
                    {accessTypes[memberNewRole ? memberNewRole : member.access_type]}
                </Text>
                <Icons
                    name="arrow-down"
                    className={cn['editRole-icon']}
                    style={isOpen ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}
                />
            </Flex>

            <Popup isOpen={isOpen} onClose={close} anchor={ref.current} placement="bottom-end" style={{ zIndex: 2003 }}>
                <Flex
                    style={{
                        borderRadius: '4px',
                        padding: '8px',
                        backgroundColor: 'white',
                        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                    }}
                    column
                >
                    <Flex className={cn['editRole-role']} onClick={() => updateMember('Reader')}>
                        <Text>{t('share.roles.reader')}</Text>
                    </Flex>
                    <Flex className={cn['editRole-role']} onClick={() => updateMember('Commentator')}>
                        <Text>{t('share.roles.commentator')}</Text>
                    </Flex>
                    <Flex className={cn['editRole-role']} onClick={() => updateMember('Editor')}>
                        <Text>{t('share.roles.editor')}</Text>
                    </Flex>
                </Flex>
            </Popup>
        </>
    )
}
export default EditRole
