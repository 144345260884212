import React, { FC, useRef, useState } from 'react'

import Icons from '@/components/Icons'
import Popup from '@/_yc/Popup'

import cn from './Menu.module.scss'
import Scrollbars from 'react-custom-scrollbars'
import Item from '@/pages/SearchPage/SearchAdapter/filters/SelectorFilter/Item'

import AddToList from '@/pages/Lists/components/AddToList/AddToList'
import { CreateListPlace } from '@/_helpers/amplitude/types'
import { Platform } from '@/pages/Reports/types'

enum ItemValue {
    addToList = 'addToList',
    addToFavorites = 'addToFavorites',
}

const items = [
    { label: 'Add to list', value: ItemValue.addToList, translate: false },
    // { label: 'Add to favorites', value: ItemValue.addToFavorites, translate: false },
]

interface MenuProps {
    type: Platform
    externalId: string
    place: CreateListPlace
}

export const Menu: FC<MenuProps> = ({ type, externalId, place }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isAddToListOpen, setIsAddToListOpen] = useState(false)

    const ref = useRef(null)

    const onItemClick = (value: ItemValue) => {
        switch (value) {
            case ItemValue.addToList:
                setIsAddToListOpen(true)
                setIsMenuOpen(false)
                break
            case ItemValue.addToFavorites:
                console.log('addToFavorites')
                break
        }
    }

    return (
        <div>
            <div style={{ cursor: 'pointer' }} onClick={() => setIsMenuOpen(true)} ref={ref}>
                <Icons name="more--horizontal" />
            </div>

            <Popup
                isOpen={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
                style={{ zIndex: 20 }}
                anchor={ref.current}
                placement="bottom-end"
            >
                <div className={cn.popper}>
                    <Scrollbars autoHeight autoHeightMax={200}>
                        {items.map((item, i) => (
                            <Item key={i} {...item} isActive={false} onClick={onItemClick} />
                        ))}
                    </Scrollbars>
                </div>
            </Popup>

            <AddToList
                place={place}
                useMobilePopup
                type={type}
                external_id={externalId}
                isMobileOpen={isAddToListOpen}
                onMobileClose={() => setIsAddToListOpen(false)}
            />
        </div>
    )
}
