import React, { SVGAttributes } from 'react'
export interface ILock {}
const Lock = (props: SVGAttributes<SVGElement> & ILock) => (
    <svg {...props} width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.75695 7.23977C9.59486 7.07768 9.39817 6.99677 9.16658 6.99677H8.88898V4.21902C8.88898 3.1483 8.50847 2.23267 7.74756 1.47161C6.98653 0.710529 6.07065 0.330078 5.00008 0.330078C3.92941 0.330078 3.01362 0.710529 2.25253 1.47158C1.49156 2.23255 1.11108 3.14827 1.11108 4.21899V6.99674H0.833331C0.601921 6.99674 0.405112 7.07783 0.243055 7.23974C0.080998 7.40164 0 7.59851 0 7.83001V12.8301C0 13.0617 0.0810284 13.2585 0.243055 13.4204C0.405112 13.5824 0.601921 13.6634 0.833331 13.6634H9.16658C9.39817 13.6634 9.59504 13.5824 9.75695 13.4204C9.91885 13.2585 10 13.0617 10 12.8301V7.83004C10.0001 7.59854 9.91906 7.40188 9.75695 7.23977ZM2.77771 4.21902V6.99677H7.22232V4.21902C7.22232 3.60552 7.00526 3.08183 6.57118 2.64778C6.13727 2.21379 5.61349 1.99679 5.00008 1.99679C4.3866 1.99679 3.86291 2.21382 3.42882 2.64778C2.99477 3.08183 2.77771 3.60549 2.77771 4.21902Z"
            fill="#6071FF"
        />
    </svg>
)

export default Lock
