import React, { CSSProperties, ReactNode, Ref, ForwardRefRenderFunction, HTMLAttributes, useMemo } from 'react'

import cn from './Text.module.scss'

import colors from '@/polette.module.scss'

export interface IText {
    type?: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    margin?: string | number
    className?: string
    style?: CSSProperties
    children: ReactNode
    ref?: Ref<HTMLElement>
    size?: string | number
    color?:
        | 'primary'
        | 'newPrimary'
        | 'secondary'
        | 'white'
        | 'gray'
        | 'link'
        | 'red'
        | 'green'
        | 'gray2'
        | 'lightGreen'
    semibold?: boolean
    onClick?: HTMLAttributes<HTMLElement>['onClick']
    align?: CSSProperties['textAlign']
}

const Text: ForwardRefRenderFunction<any, IText> = ({ type: Type = 'p', ...props }, ref) => {
    const color = useMemo(() => {
        return props.color === 'primary'
            ? colors.primary
            : props.color === 'newPrimary'
            ? colors.newPrimary
            : props.color === 'secondary'
            ? colors.secondary
            : props.color === 'white'
            ? colors.white
            : props.color === 'gray'
            ? colors.gray
            : props.color === 'link'
            ? colors.link
            : props.color === 'red'
            ? colors.red
            : props.color === 'green'
            ? colors.green
            : props.color === 'gray2'
            ? colors.gray2
            : props.color === 'lightGreen'
            ? colors.lightGreen
            : '#2a2a31'
    }, [props.color])

    const style = useMemo<CSSProperties>(() => {
        return {
            color: color,
            ...props.style,
            margin: props.margin,
            fontSize: props.size,
            fontWeight: props.semibold ? 600 : undefined,
            textAlign: props.align,
        }
    }, [props.align, props.semibold, props.size, color, props.style, props.margin])

    return (
        <Type ref={ref} style={style} onClick={props.onClick} className={`${cn.root} ${props.className || ''}`}>
            {props.children}
        </Type>
    )
}

export default Text
