import React from 'react'

import Text from '@/components/Text'
import { useSpaceTranslation } from '@/_helpers'

import cn from './EmptyAllChats.module.scss'

export const EmptyAllChats = () => {
    const t = useSpaceTranslation('outreach.messenger.all_chats.empty')
    return (
        <div className={cn.emptyAllChats}>
            <Text color="gray-3" fSize={12} margin="0 0 20px 0">
                {t('no_chats')}
            </Text>

            <Text color="violet-1" fSize={12} component="span" className={cn.link}>
                {t('start_link')}
            </Text>
            <Text color="gray-3" fSize={12} component="span">
                {' '}
                {t('few_steps')}
            </Text>

            <ul>
                <li>
                    <Text color="gray-3" fSize={12}>
                        {t('p1')}
                    </Text>
                </li>
                <li>
                    <Text color="gray-3" fSize={12}>
                        {t('p2')}
                    </Text>
                </li>
                <li>
                    <Text color="gray-3" fSize={12}>
                        {t('p3')}
                    </Text>
                </li>
            </ul>
        </div>
    )
}
