import React from 'react';
import i18n from 'i18next'
import {updateLibLocale} from '@yoloker/ui-collection'
import { StandartPopper } from '../../../_yc'

import cn from './LanguageSwitcher.module.scss'

export class LanguageSwitcher extends React.Component { 
	constructor(props){
		super(props);
		this.state = {
			options: [
				{ label: "РУС", lg: "ru" },
				{ label: "ENG", lg: "en" },
			],
			open: false,
			disabled: false
		}
		this.state.current = this.state.options.reduce((prev, item) => {
			if (i18n.language === item.lg) return item
				return prev
		})
		this.block = React.createRef();
	}
	handleClick(val){
		if (!val) {
			this.setState({ open: val }, e=>{
				setTimeout(e=>this.setState({ disabled: false }), 0)
			})
			return
		}
		this.setState({open: val, disabled: true})
	}
	handleChange = item => {
		this.setState({ current: item })
		i18n.changeLanguage(item.lg);
		updateLibLocale(item.lg)
		this.handleClick(false)
	}
	render(){
		return(
			<div className = {cn.root}>
				<StandartPopper 
					btn = {
						<div 
							ref = {this.block}
							className = {cn.lang_cur}
							onClick={!this.state.disabled ? e=>this.handleClick(!this.state.open) : e=>e} 
						>
							{this.state.current.label}
						</div>
					}
					content = {
						<div 
							className = {cn.popup} 
							style = {{
								background: this.props.color ? this.props.color : "#302F35"
							}}
						>
							{this.state.options.map((item, i) => (
								<div 
									key = {i}
									onClick = {e=>this.handleChange(item)}
									className = {cn.lang}
								>
									{item.label}
								</div>
							))}
						</div>
					}
					open = {this.state.open}
					onClickAway = {e=>this.handleClick(false)}
					anchor = {this.block.current}
				/>
			</div>
		)
	}
}