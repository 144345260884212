import React from 'react'

import Flex from '@/_yc/Flex'
import { useTranslation } from 'react-i18next'
import Text from '@/components/Text'
import { PostChangeCampaignReq } from '../../../queries'
import useToken from '@/_helpers/Hooks/useToken'
import notif from '@/components/Notifier/model/actions.js'
import { useDispatch } from 'react-redux'
import { useCampaignId } from '@/_helpers/Hooks/useCampaignId'
import pause from './pause.svg'
import play from './play.svg'
interface ITrack {
    state: boolean
}
export const TrackinStopper: React.FC<ITrack> = (props) => {
    const token = useToken()
    const id = useCampaignId()

    const { t } = useTranslation()
    const [state, setState] = React.useState<boolean>(false)
    const dispatch = useDispatch()
    React.useEffect(() => {
        setState(props.state)
    }, [props.state])

    const req = () => {
        dispatch(notif.loading(t('campaign_manager.hider.req.loading')))
        if (!token) {
            dispatch(notif.error(t('campaign_manager.hider.req.error')))
            return
        }
        PostChangeCampaignReq(token, id, !state)
            .then(() => {
                dispatch(notif.success(t('campaign_manager.hider.req.success')))
                setState(!state)
            })
            .catch(() => {
                dispatch(notif.error(t('campaign_manager.hider.req.error')))
            })
    }

    return (
        <>
            <Flex
                align="center"
                style={
                    state
                        ? {
                              padding: '0 20px',
                              borderRadius: '4px',
                              height: '42px',
                              cursor: 'pointer',
                              background: '#70D9A0',
                          }
                        : {
                              padding: '0 20px',
                              height: '42px',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              background: '#929EB0',
                          }
                }
                onClick={req}
            >
                {state ? <img src={pause} alt="pause" /> : <img src={play} alt="play" />}
                <Text margin="0 0 0 6px" color={'white'}>
                    {state ? t('campaign_manager.active') : t('campaign_manager.onPause')}
                </Text>
            </Flex>
        </>
    )
}
