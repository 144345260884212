import { IBrand, ILanguage } from '../../types'
import { GeneralTopic, ITopicsQuery, TopicsMap } from '../../types'
import { ICountry } from '../../types'

export const preprocessLanguages = (languages: ILanguage[]) => {
    if (!languages || languages.length === 0) return [{ label: 'ERROR', value: '' }]

    return [...languages]
        .sort((a, b) => b.priority - a.priority)
        .map((i) => ({
            label: i.name,
            value: i.youtube_language_id,
        }))
}

export const preprocessCountries = (countries: ICountry[]) => {
    if (!countries || countries.length === 0) return [{ label: 'ERROR', value: '' }]

    return countries.map((i) => ({
        label: i.name,
        value: i.country_id,
    }))
}

const getIds = (topic: GeneralTopic, mapping: TopicsMap[]) => {
    const filteredMapping = mapping.filter((item) => item.channeltopicgeneral_id === topic.id)
    const topicIds = filteredMapping.map((item) => item.channeltopic_id)

    return topicIds.join(',')
}

export const preprocessTopics = (topics: ITopicsQuery) => {
    const general = topics?.youtube_channeltopicgeneral
    const mapping = topics?.youtube_channeltopicgeneral_topics

    const isGeneralEmpty = !general || general.length === 0
    const isMappingEmpty = !mapping || mapping.length === 0

    if (isGeneralEmpty || isMappingEmpty) {
        return [{ label: 'ERROR', value: '' }]
    }

    return general.map((topic) => ({
        label: topic.name,
        value: getIds(topic, mapping),
    }))
}

export const preprocessBrands = (brands: Array<IBrand>) => {
    if (!brands || brands.length === 0) return [{ label: 'ERROR', value: '' }]

    return brands.map((i) => ({
        label: i.name,
        value: `${i.id}`,
    }))
}
