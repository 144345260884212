import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16 10.9237C18.9982 10.9237 21.4375 8.47356 21.4375 5.46187C21.4375 2.45018 18.9982 0 16 0C13.0017 0 10.5625 2.45018 10.5625 5.46187C10.5625 8.47356 13.0017 10.9237 16 10.9237ZM16 1.8834C17.9644 1.8834 19.5625 3.48869 19.5625 5.46187C19.5625 7.43505 17.9644 9.04034 16 9.04034C14.0356 9.04034 12.4375 7.43505 12.4375 5.46187C12.4375 3.48869 14.0356 1.8834 16 1.8834Z"
            fill="#6071FF"
        />
        <path
            d="M27 10.9237C28.8954 10.9237 30.4375 9.37472 30.4375 7.47079C30.4375 5.56686 28.8954 4.01788 27 4.01788C25.1046 4.01788 23.5625 5.56686 23.5625 7.47079C23.5625 9.37472 25.1046 10.9237 27 10.9237ZM27 5.90129C27.8616 5.90129 28.5625 6.60537 28.5625 7.47079C28.5625 8.33622 27.8616 9.04029 27 9.04029C26.1384 9.04029 25.4375 8.33622 25.4375 7.47079C25.4375 6.60537 26.1384 5.90129 27 5.90129Z"
            fill="#6071FF"
        />
        <path
            d="M27.7562 13.0583H26.3062C24.5975 13.0583 23.1225 14.0723 22.4504 15.5288C21.0666 14.0114 19.0789 13.0583 16.8737 13.0583H15.1263C12.9211 13.0583 10.9334 14.0114 9.54961 15.5288C8.87742 14.0723 7.40242 13.0583 5.69374 13.0583H4.24374C1.90375 13.0583 0 14.959 0 17.2953V24.1515C0 25.1658 0.824623 25.991 1.83825 25.991H7.57117C7.6673 27.1148 8.60805 28 9.75136 28H22.2486C23.3919 28 24.3326 27.1148 24.4288 25.991H30.0963C31.146 25.991 32 25.1366 32 24.0862V17.2953C31.9999 14.959 30.0962 13.0583 27.7562 13.0583ZM1.875 17.2953C1.875 15.9975 2.93762 14.9417 4.24374 14.9417H5.69374C6.99986 14.9417 8.06248 15.9975 8.06248 17.2953V17.9401C7.43205 19.589 7.56249 20.5772 7.56249 24.1076H1.875V17.2953ZM22.5625 25.8013C22.5625 25.9752 22.4217 26.1166 22.2486 26.1166H9.75129C9.57823 26.1166 9.43748 25.9751 9.43748 25.8013V20.6561C9.43748 17.5051 11.9895 14.9417 15.1263 14.9417H16.8736C20.0105 14.9417 22.5625 17.5051 22.5625 20.656V25.8013ZM30.1249 24.0862C30.1249 24.1187 30.4744 24.1076 24.4375 24.1076C24.4375 20.551 24.5669 19.5863 23.9375 17.9401V17.2953C23.9375 15.9975 25.0001 14.9417 26.3062 14.9417H27.7562C29.0623 14.9417 30.1249 15.9975 30.1249 17.2953V24.0862Z"
            fill="#6071FF"
        />
        <path
            d="M4.99999 10.9237C6.89543 10.9237 8.43749 9.37472 8.43749 7.47079C8.43749 5.56686 6.89543 4.01788 4.99999 4.01788C3.10456 4.01788 1.5625 5.56686 1.5625 7.47079C1.5625 9.37472 3.10456 10.9237 4.99999 10.9237ZM4.99999 5.90129C5.86155 5.90129 6.56249 6.60537 6.56249 7.47079C6.56249 8.33622 5.86155 9.04029 4.99999 9.04029C4.13843 9.04029 3.4375 8.33622 3.4375 7.47079C3.4375 6.60537 4.13843 5.90129 4.99999 5.90129Z"
            fill="#6071FF"
        />
    </svg>
)
