import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import * as Routs from '@/routs.js'
// import useWindowWidth from '@/_helpers/Resize/useWindowWidth
import PageLayout from '@/Layouts/PageLayout'
import useClass from '@/_helpers/Hooks/useClass'
import Flex from '@/_yc/Flex'
import useToken from '@/_helpers/Hooks/useToken'
import { GetTagsReq } from '@/pages/Lists/model/service'
import Icons from '@/components/Icons'
import { useQuery } from '@apollo/client'

import { ITag } from './model/types'
import { PageHeader } from './CatalogOfLists/components/PageHeader'
import CatalogOfLists from './CatalogOfLists'
import { getFavoritesListReq } from './model/service'
import { GET_RECENT_SEARCHES_COUNT } from './model/graphQL'

import cn from './Lists.module.scss'

const Lists = () => {
    const { t } = useTranslation()
    const token = useToken()
    const [tags, setTags] = useState<ITag[]>([])

    const [activeTag, setActiveTag] = useState(-1)

    useEffect(() => {
        if (!token) return
        GetTagsReq(token).then((res) => {
            setTags(res)
        })
    }, [token])

    const root = useClass(cn.root)

    const [likesCount, setLikesCount] = useState<number>(0)
    const getFavoritesID = useCallback(() => {
        if (!token) {
            return
        }
        getFavoritesListReq(token)
            .then((res) => {
                if (res && res.results && res.results.length > 0) setLikesCount(res.results[0].qty)
            })
            .catch((er) => {
                console.log(er)
            })
    }, [token])
    useEffect(() => {
        getFavoritesID()
    }, [getFavoritesID])

    const { data: recentSearchesCount } =
        useQuery<{ getRecentSearchesV2: { count: number } }>(GET_RECENT_SEARCHES_COUNT)

    return (
        <>
            <PageLayout color={'common-background'} className={root} title={t('titles.t2')}>
                <div className={cn.layout} id={'listLayoutID'}>
                    <PageHeader />

                    <Flex margin={'0 0 6px 0'} content={'space-between'} className={cn.tags}>
                        <Flex wrap className={cn['tags--all']}>
                            <Flex
                                onClick={() => setActiveTag(-1)}
                                className={cn[`tags--container${activeTag === -1 ? '-active' : ''}`]}
                                align="center"
                                content="center"
                                margin={'0 10px 10px 0'}
                            >
                                <Icons
                                    name={'lists'}
                                    className={cn[`tags--icon${activeTag === -1 ? '-active' : ''}`]}
                                />
                                <p className={cn[`tags--text${activeTag === -1 ? '-active' : ''}`]}>
                                    {t('aud_insides.tags.all_lists')}
                                </p>
                            </Flex>
                            {tags.map((tag, i) => (
                                <>
                                    <Flex
                                        key={i}
                                        onClick={() => setActiveTag(i)}
                                        className={cn[`tags--container${activeTag === i ? '-active' : ''}`]}
                                        align="center"
                                        content="center"
                                        margin={'0 10px 10px 0'}
                                    >
                                        <p className={cn[`tags--text${activeTag === i ? '-active' : ''}`]}>
                                            #{tag.name}
                                        </p>
                                    </Flex>
                                </>
                            ))}
                        </Flex>
                        <Flex style={{ alignSelf: 'flex-start' }}>
                            <Link to={`${Routs.LISTS + Routs.RECENT_SEARCHES}`}>
                                <Flex className={cn['head-list']}>
                                    <Icons name="clock" />
                                    <p>{t('aud_insides.lists.RECENT')}</p>
                                    <p>{recentSearchesCount?.getRecentSearchesV2.count || 0}</p>
                                </Flex>
                            </Link>
                            <Link to={`${Routs.LISTS + Routs.FAVORITES}`}>
                                <Flex className={cn['head-list']} margin="0 0 0 30px" align="center">
                                    <Icons name="heart_outline" />
                                    <p>{t('aud_insides.lists.favorites') + ' '}</p>
                                    <p>{likesCount}</p>
                                </Flex>
                            </Link>
                        </Flex>
                    </Flex>

                    <CatalogOfLists activeTag={activeTag !== -1 ? tags[activeTag].name : undefined} />
                </div>
            </PageLayout>
        </>
    )
}
export default Lists
