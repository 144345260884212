import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.5835 18.3332C13.6817 18.3437 12.8271 17.9316 12.2737 17.2197C11.7202 16.5077 11.5317 15.5778 11.7643 14.7066L6.54762 11.7249C5.64619 12.5508 4.32485 12.7256 3.23969 12.1625C2.15453 11.5994 1.53668 10.4184 1.69292 9.20591C1.84917 7.99339 2.74622 7.0076 3.93868 6.73803C5.13114 6.46845 6.365 6.9725 7.02762 7.99991L11.7635 5.2924C11.7022 5.06089 11.6697 4.8227 11.6668 4.58324C11.6548 3.18883 12.6233 1.97752 13.9861 1.68226C15.349 1.38699 16.7319 2.08886 17.2982 3.36317C17.8644 4.63748 17.4584 6.13426 16.3259 6.94783C15.1934 7.76139 13.6453 7.66831 12.6185 6.72491L7.49262 9.65407C7.48754 9.87017 7.45734 10.085 7.40262 10.2941L12.6185 13.2749C13.5787 12.3937 15.0074 12.2577 16.1167 12.9418C17.2259 13.626 17.7456 14.9638 17.3891 16.2174C17.0326 17.471 15.8867 18.3352 14.5835 18.3332ZM14.5835 14.1666C13.8931 14.1666 13.3335 14.7262 13.3335 15.4166C13.3335 16.1069 13.8931 16.6666 14.5835 16.6666C15.2738 16.6666 15.8335 16.1069 15.8335 15.4166C15.8335 14.7262 15.2738 14.1666 14.5835 14.1666ZM4.58345 8.33324C3.89309 8.33324 3.33345 8.89288 3.33345 9.58324C3.33345 10.2736 3.89309 10.8332 4.58345 10.8332C5.27381 10.8332 5.83345 10.2736 5.83345 9.58324C5.83345 8.89288 5.27381 8.33324 4.58345 8.33324ZM14.5835 3.33324C13.8931 3.33324 13.3335 3.89288 13.3335 4.58324C13.3335 5.27359 13.8931 5.83324 14.5835 5.83324C15.2738 5.83324 15.8335 5.27359 15.8335 4.58324C15.8335 3.89288 15.2738 3.33324 14.5835 3.33324Z"
            fill="#6071FF"
        />
    </svg>
)
