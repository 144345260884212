import { Lang } from '@/_helpers/getLang'
import { gql } from '@apollo/client'
import { QueryType } from './types'

type table = QueryType
interface queryParams {
    orderBy: string
    offset: number
    filters: string[]
}

export const getChannelsQuery = (table: table, search: string, queryParams: queryParams) => {
    return gql`
    query SearchChannels {
      ${table}(${search} limit: 30, order_by: {${queryParams.orderBy}}, offset: ${queryParams.offset}, 
        where: {
          ${queryParams.filters.join(', ')}
        }) {
        id
        avatar
        title
        average_video_reactions
        lang_id
        subscriber_count
        er_followers
        er_views
        channel_topics {
          youtube_channeltopic {
            youtube_channeltopicgeneral_topics {
              youtube_channeltopicgeneral {
                id
                name
              }
            }
          }
        }
        email
        average_video_watch
        engagement
      }
      getUnlockedChannels {
        unlocked_youtube_channels
        unlocked_twitch_channels
      }
    }
  `
}

export const getChannelsCountQuery = (table: table, search: string, queryParams: queryParams) => {
    return gql`
    query GetChannelsCount {
      ${table}_aggregate(${search} 
        where: {
          ${queryParams.filters.join(', ')}
        }) {
        aggregate {
          count
        }
      }
    }
  `
}

export const GET_TOPICS = gql`
    query GetTopics {
        youtube_channeltopicgeneral(order_by: { name: asc }) {
            id
            name
        }
        youtube_channeltopicgeneral_topics {
            channeltopicgeneral_id
            channeltopic_id
        }
    }
`

export const getCountries = (lang: Lang) => gql`
    query GetCountries {
        youtube_countriestranslate(where: { platform_language: { code: { _eq: "${lang}" } } }, order_by: {youtube_country: {priority: desc, name: asc}}) {
            country_id
            name
        }
    }
`

export const getLanguages = (lang: Lang) => gql`
    query GetLanguages {
        youtube_languagestranslate(where: { platform_language: { code: { _eq: "${lang}" } } }, order_by: { name: asc }) {
            name
            youtube_language_id
            priority
        }
    }
`

export const GET_BRAND_MENTIONS = gql`
    query GetBrandMentions {
        youtube_brand(order_by: { name: asc }) {
            id
            name
        }
    }
`
