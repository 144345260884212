import React from 'react'

import Icons from '@/components/Icons'
import { Fetcher } from '@/_helpers/graphql/Fetcher'

import { pageToOffset } from '../youtube/utils/queries/pageToOffset'
import ItemsHead from './ItemsHead'
import SearchItem from './SearchItem/SearchItem'
import { Adapter } from '../../Adapter'
import { getVkChannelsCountQuery, getVkChannelsQuery } from './utils/queries'
import { getVkAccountsFromRaw } from './utils/PreprData'
import { QueryType, VkUserInfoResult, ISearchBy } from './utils/types'
import { getMainFilters, getFilters } from './filters/filters'
import { getFilterQueries } from '../youtube/utils/filters/filterQueries'
import { getSearchByTable } from './utils/searchByTable'
import { RootStore } from '@/_helpers/StoreType'
import { store } from '@/_helpers/store'

import cn from '../youtube/YoutubeItems.module.scss'

const fetcher = new Fetcher()

export const VkAdapter = new Adapter({
    Head: ItemsHead,
    background: `linear-gradient(180deg, #5ca4f5 0%, #593cd9 100%)`,
    Icon: () => <Icons className={cn.icon} name="ie-vk" />,
    fetcher: async (_, config) => {
        const client = fetcher.getClient()

        const offSet = pageToOffset(config.page)
        const search = config.search && `args:{search:"${config.search}"},`
        // const queryType: QueryType = 'vk_account'
        const queryType: QueryType = search === '' ? 'vk_account' : getSearchByTable(config.by as ISearchBy[])

        const queries = getFilterQueries(config.filters)

        const queryParams = {
            orderBy: config.sorting,
            offset: offSet,
            filters: queries,
        }

        // const amount = await client.query({
        //     query: getVkChannelsCountQuery(queryType, search, queryParams),
        // })

        const accounts = await client.query<VkUserInfoResult>({
            query: getVkChannelsQuery(queryType, search, queryParams),
        })

        //@ts-ignore
        const isVkAvailable: RootStore = store.getState().authentication.user?.isVkAvailable

        const isFullTariff = !isVkAvailable
        const hasNext = !isFullTariff || config.page !== 1
        const sliceIndex = isFullTariff && (config.search !== '' || queries.length > 0) ? 3 : 30

        return {
            hasNext: hasNext,
            total: 0, //amount.data[`${queryType}_aggregate`].aggregate.count || 0,
            items: getVkAccountsFromRaw(queryType, accounts.data, sliceIndex),
            isHidden: isFullTariff,
        }
    },
    countCheck: async (token, config) => {
        const client = fetcher.getClient()

        const search = config.search && `args:{search:"${config.search}"},`
        // const queryType: QueryType = 'vk_account'
        const queryType: QueryType = search === '' ? 'vk_account' : getSearchByTable(config.by as ISearchBy[])

        const queries = getFilterQueries(config.filters)

        const queryParams = {
            orderBy: config.sorting,
            offset: 0,
            filters: queries,
        }

        const amount = await client.query({
            query: getVkChannelsCountQuery(queryType, search, queryParams),
        })

        return {
            count: amount.data[`${queryType}_aggregate`].aggregate.count || 0,
        }
    },
    keyWords: [
        { label: 'forKeywords.accName', value: 'title' },
        { label: 'forKeywords.accDescription', value: 'description' },
    ],
    sortings: [
        {
            label: `forKeywords.subCount`,
            value: 'followers: desc_nulls_last',
        },
        {
            label: 'forKeywords.medianER',
            value: 'er_views: desc_nulls_last',
        },
    ],
    mainFilters: getMainFilters(fetcher) as any,
    filters: getFilters(fetcher) as any,
    templateColumns: '',
    title: 'Vk',
    Item: SearchItem,
})
