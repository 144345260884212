import React, { FC, ChangeEvent, useCallback, InputHTMLAttributes, ReactNode } from 'react'

import cn from './Input.module.scss'
import { Stylable } from '@/Types'
import Flex from '../Flex'
import Text from '../Text'
import useOpenClose from '@/_helpers/useOpenClose'
import { ClickAwayListener } from '@material-ui/core'

export interface IInput extends Stylable<{ root: string; text: string; error: string }> {
    value?: string
    onChange?: (next: string) => void

    title?: string
    titleSize?: number
    titleColor?: 'primary' | 'newPrimary' | 'secondary' | 'white' | 'gray' | 'link' | 'red' | 'green' | 'gray2'
    helper?: string
    error?: string
    placeholder?: string
    spaceBetween?: boolean
    type?: InputHTMLAttributes<HTMLInputElement>['type']

    width?: string | number

    children?: ReactNode

    tSize?: number //Dont use with props.inputElement, it will break
    inputElement?: FC<{
        type?: InputHTMLAttributes<HTMLInputElement>['type']
        value?: string
        placeholder?: string
        onChange?: (next: string) => void
    }>
}

const Input: FC<IInput> = ({ onChange, inputElement: Component = 'input', ...props }) => {
    const change = useCallback(
        (event: ChangeEvent<HTMLInputElement> | string) => {
            onChange && onChange(typeof event === 'string' ? event : event.target.value)
        },
        [onChange]
    )
    const [isActive, activate, disable] = useOpenClose(false)
    return (
        <ClickAwayListener onClickAway={disable}>
            <div
                onClick={activate}
                style={{ margin: props.margin, width: props.width }}
                className={`${cn.root} ${props.classes?.root || ''}`}
            >
                {props.title && (
                    <Flex align="center" margin="0 0 7px">
                        <Flex
                            style={props.spaceBetween ? { width: '100%', justifyContent: 'space-between' } : undefined}
                            align="center"
                        >
                            <Text
                                semibold
                                className={props.classes?.text}
                                size={props.titleSize || props.tSize || 12}
                                color={props.titleColor || 'secondary'}
                            >
                                {props.title}
                            </Text>
                            {props.children}
                        </Flex>
                    </Flex>
                )}
                <Flex
                    align="center"
                    className={`${props.error && !isActive ? cn['input--error'] : cn['input--default']} ${
                        props.className || ''
                    }`}
                    style={{ ...props.style }}
                >
                    {!props.tSize ? (
                        <Component
                            type={props.type}
                            value={props.value}
                            placeholder={props.placeholder}
                            onChange={change}
                        />
                    ) : (
                        <input
                            style={{ fontSize: props.tSize || 14, height: 'inherit', width: 'inherit' }}
                            value={props.value}
                            placeholder={props.placeholder}
                            onChange={change}
                            type={props.type}
                        />
                    )}
                </Flex>
                {props.error && !isActive && (
                    <Flex content="space-between" align="center" margin="0 0 7px">
                        <Text className={props.classes?.error} size={12} color="red" margin="2px 0 0 0">
                            {props.error}
                        </Text>
                    </Flex>
                )}
            </div>
        </ClickAwayListener>
    )
}

export default Input
