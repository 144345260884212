import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="5"
        height="10"
        viewBox="0 0 5 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5 5.00002C5 5.17924 4.93998 5.35843 4.82018 5.49507L1.0486 9.79486C0.808678 10.0684 0.419689 10.0684 0.179867 9.79486C-0.0599557 9.52145 -0.0599557 9.07807 0.179867 8.80452L3.51717 5.00002L0.179983 1.19549C-0.0598395 0.921968 -0.0598395 0.478631 0.179983 0.205241C0.419806 -0.0684138 0.808794 -0.0684138 1.04871 0.205241L4.8203 4.50496C4.94011 4.64166 5 4.82086 5 5.00002Z"
            fill="#888888"
        />
    </svg>
)
