import React, { FC, useMemo, memo } from 'react'

import DonutSegment from './DonutSegment'

import cn from './Donut.module.scss'

export interface IDonut {
    data: Array<{
        value: number
        color: string
        label?: string
    }>
    title?: string
    radius: number
    isLarge: boolean
    isLoading?: boolean
}

const Donut: FC<IDonut> = ({ data, radius, isLarge, title, isLoading }) => {
    const all = useMemo(() => data.reduce((prev, item) => prev + item.value, 0), [data])

    let total = 0

    return (
        <div className={cn.root}>
            <div className={cn.chart}>
                <svg width={radius * 2} height={radius * 2}>
                    {data.map((item, index) => {
                        let ang = (item.value / all) * Math.PI * 2
                        const rotate = total
                        total += ang

                        let color = item.color
                        if (isLoading) {
                            color = item.color === '#6071FF' ? 'rgba(96, 113, 255, 0.5)' : 'rgba(255, 101, 156, 0.5)'
                        }
                        return (
                            <DonutSegment
                                key={index}
                                radius={radius}
                                ang={ang}
                                rotate={rotate}
                                lessThanHalf={item.value / all < 0.5}
                                color={color}
                            />
                        )
                    })}
                </svg>
                <div
                    className={cn.center}
                    style={{
                        width: radius * 2 * 0.62,
                        height: radius * 2 * 0.62,
                        marginLeft: radius * 2 * 0.18,
                    }}
                />
            </div>
        </div>
    )
}

export default memo(Donut)
