import { useState, useCallback, useEffect } from 'react'

type useOpenCloseResponse = [boolean, VoidFunction, VoidFunction, VoidFunction]

export default (def: boolean = false, disabled?: boolean): useOpenCloseResponse => {
    const [isOpen, setIsOpen] = useState<boolean>(def)

    useEffect(() => {
        setIsOpen(def)
    }, [def])

    const open: VoidFunction = useCallback(() => {
        if (disabled) return
        setIsOpen(true)
    }, [disabled])

    const close: VoidFunction = useCallback(() => {
        setIsOpen(false)
    }, [])

    const toggle = useCallback(() => {
        if (disabled) return
        setIsOpen((p) => !p)
    }, [disabled])

    useEffect(() => {
        if (disabled) setIsOpen(false)
    }, [disabled])

    return [isOpen, open, close, toggle]
}
