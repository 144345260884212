import React from 'react';

import { Container } from './Container'
import { Title } from './Title.js'
import { InfoBoard } from './InfoBoard.js'
import { Carousel } from './Carousel.js'
import { BlockTitle } from '../BlockTitle/BlockTitle';
import { 
	Post,
	ErMetrics,
	ErSpread,
	Chips,
	IconList,
	Note,
	BarChart,
	HorizontalChart,
	MultiBarChart,
	DonutChart,
	ListOfAccounts,
	InstaGrowGraph,
	Interests,
	PostType,
	AllocationGraph,
	OnParsingLoader,
} from '../Cards'

export const Mediator = ({ item, width, hidden, isTwoTailed }) => {
	switch (item.type) {
		case "chips": return <Chips {...item} hidden={hidden}  width={width}/>
		case "container": return  <Container {...item} hidden={hidden} width={width}/>
		case "title": return <Title {...item} hidden={hidden} width={width}/>
		case "infoBoard": return <InfoBoard {...item} hidden={hidden} width={width}/>
		case "post": return <Post {...item} hidden={hidden} width={width}/>
		case "er_metrics": return <ErMetrics {...item} hidden={hidden} width={width}/> 
		case "line_chart": return <InstaGrowGraph {...item} hidden={hidden} width={width}/>
		case "list": return <ListOfAccounts {...item} hidden={hidden} width={width} isTwoTailed={isTwoTailed}/>
		case "er_spread": return <ErSpread {...item} hidden={hidden} width={width}/>
		case "icon_list": return <IconList {...item} hidden={hidden} width={width}/>
		case "note": return <Note {...item} hidden={hidden} width={width}/>
		case "bar_chart": return <BarChart {...item} hidden={hidden} width={width}/>
		case "horizontal_chart": return <HorizontalChart {...item} hidden={hidden} width={width}/>
		case "multi_bar_chart": return <MultiBarChart {...item} hidden={hidden} blockWidth={width}/>
		case "donut_chart": return <DonutChart {...item} hidden={hidden} width={width}/>
		case "carousel": return <Carousel {...item} hidden={hidden} width={width}/>
		case "block_title": return <BlockTitle {...item} />
		case "interests": return <Interests {...item} hidden={hidden}  width={width}/>
		case "postType": return <PostType {...item} hidden={hidden} width={width} />
		case "allocation_chart": return <AllocationGraph {...item} hidden={hidden} width={width} />
		case 'OnParsingLoader': return <OnParsingLoader {...item} />
		default: return null
	}
}