import React, { memo } from 'react';
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'

import { getParams } from '../_helpers/'
import OAuth from './OAuth.js';
import serv from './service.js'
import notif from '../components/Notifier/model/actions.js'
import CommonError from '../components/Commercial/Notifaications/CommonError.js'

const mapDispatch = dispatch => ({
	error: () => {
		dispatch(notif.error(<CommonError />, 3000))
	}
})

export default compose(
	connect(null, mapDispatch),
	withProps({ getParams, OAuth: serv.OAuth }),
	memo
)(OAuth)
