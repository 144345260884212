import { gql } from '@apollo/client'
import { QueryType } from './utils/types'

export type Table = 'twitch_userinfo'
interface QueryParams {
    orderBy: string
    offset: number
    filters: string[]
}

export const getTwitchChannelsQuery = (table: QueryType, search: string, queryParams: QueryParams) => {
    return gql`
      query SearchChannels {
        ${table} (${search} limit: 30, order_by: {${queryParams.orderBy}}, offset: ${queryParams.offset}, 
          where: {
            ${queryParams.filters.join(', ')}
          }) 
        {
          created_at
          display_name
          description
          id
          language
          last_video_date
          login
          median_view_count_20_videos
          profile_image_url
          subscriber_count
          subscribers_180_days_growth
          subscribers_30_days_growth
          subscribers_7_days_growth
          video_count_30_days_growth
          video_count_7_days_growth
          video_duration_30_days_growth
          video_duration_7_days_growth
          view_count
          broadcaster_type
        }

        getUnlockedChannels {
          unlocked_twitch_channels
          unlocked_youtube_channels
        }
      }
  `
}

export const getTwitterChannelsCountQuery = (queryType: QueryType, search: string, queryParams: QueryParams) => {
    return gql`
      query GetChannelsCount {
        ${queryType}_aggregate(${search} 
          where: {
            ${queryParams.filters.join(', ')}
          }) {
          aggregate {
            count
          }
        }
      }
  `
}
