import React from 'react'
import Icons from '@/components/Icons'
import { SelectorFilter } from '@/pages/SearchPage/SearchAdapter/filters/SelectorFilter'

const getGqlFilter = (value: number[]) => {
    if (!value || value.length === 0) return ''

    return `engagement_rate: {value: ${value[0] / 100}}`
}

export const getERFilter = () => {
    return new SelectorFilter({
        platform: 'infl_tiktok',
        title: 'filters.er.fields.er.label',
        helper: 'filters.er.fields.er.text',
        icon: <Icons name="filter--engagement--yt" />,
        space: 1,
        options: new Array(20).fill(null).map((_, i) => ({
            label: `>=${i + 1}%`,
            value: i + 1,
        })),
        beforeSend: ({ value }) => ({
            name: 'metrics.er',
            type: 'number',
            value: value,
            gqlFilter: getGqlFilter(value),
        }),
    })
}
