import React, { FC, HTMLAttributes, memo, ReactHTML, useContext } from 'react'
import cn from './ListLayout.module.scss'
import { Stylable } from '@/Types'
import Context from './Context'
import useClass from '@/_helpers/Hooks/useClass'

export interface IListItem extends Stylable, HTMLAttributes<HTMLElement> {
    component?: keyof ReactHTML

    height?: string | number
}

const ListItem: FC<IListItem> = ({
    component: Component = 'div',
    height,
    margin,
    className,
    style,
    ...props
}) => {
    const { sidePadding, templateColumns, gridGap } = useContext(Context)

    const root = useClass(cn.item, className)

    return (
        <Component
            {...props}
            className={root}
            style={{
                margin: margin,
                paddingLeft:
                    typeof sidePadding === 'object'
                        ? sidePadding.left
                        : sidePadding,
                paddingRight:
                    typeof sidePadding === 'object'
                        ? sidePadding.right
                        : sidePadding,
                ...style,
                gridTemplateColumns: templateColumns,
                gridAutoRows: height,
                gridGap,
            }}
        />
    )
}

export default memo(ListItem)
