import { Button, Flex } from '@/_yc'
import Header from '../Header'
import Tabs from '../../Views/components/Tabs'
import React from 'react'
import cn from './layout.module.scss'

import { useTranslation } from 'react-i18next'
import { createCampaignReq } from '@/pages/TargetAudience/services/services'
import useToken from '@/_helpers/Hooks/useToken'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCampaignData } from '@/_redux/_reducers/target_audience.reducer'

const ListLayout: React.FC = ({ children }) => {
    const { t } = useTranslation()
    const token = useToken()
    const dispatch = useDispatch()
    const history = useHistory()

    const handleClickButton = async () => {
        try {
            const campaignData = {
                status: 'draft',
                target_type: 'from_us',
            }

            const response = await createCampaignReq(token, campaignData)

            if (response) {
                dispatch(setCampaignData(response))
                localStorage.setItem('campaignId', response.id.toString())
                history.push('/target/new/one')
            }
        } catch (error) {
            console.error('Ошибка создания кампании:', error)
        }
    }

    return (
        <div>
            <Flex content={'space-between'}>
                <Header />
                <Button onClick={handleClickButton} size="medium" className={cn['create-btn']}>
                    + {t('target_audience.create_analysis')}
                </Button>
            </Flex>
            <Tabs />
            <div>{children}</div>
        </div>
    )
}
export default ListLayout
