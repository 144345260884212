import React, { SVGAttributes } from 'react'

export const Home = (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.21972 7.13023L8.46972 1.88023C8.76259 1.58745 9.23734 1.58745 9.53022 1.88023L14.7802 7.13023C14.9209 7.27085 15 7.46159 15 7.66049V14.85C15 15.2642 14.6642 15.6 14.25 15.6H10.5V10.35H7.49997V15.6H3.74997C3.33576 15.6 2.99997 15.2642 2.99997 14.85V7.66049C2.99997 7.46159 3.07906 7.27085 3.21972 7.13023Z"
                fill="#6071FF"
            />
        </svg>
    )
}
