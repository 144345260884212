import React from 'react'
import Icons from '@/components/Icons'
import { SelectorFilter } from '@/pages/SearchPage/SearchAdapter/filters/SelectorFilter'

const getGqlFilter = (value: number[]) => {
    if (!value || value.length !== 1) return ''
    const genderValues: { [key: string]: string } = {
        1: 'MALE',
        2: 'FEMALE',
        3: 'KNOWN',
        4: 'UNKOWN',
    }
    return `gender: {code: "${genderValues[value[0].toString()]}"}`
}

export const getGenderFilter = (isMain?: boolean) => {
    return new SelectorFilter({
        platform: 'infl_tiktok',
        title: 'filters.gender.influencer.label',
        icon: <Icons name="filter--gender" />,
        helper: 'filters.gender.influencer.text',
        space: 1,
        isMulti: false,
        options: [
            {
                label: 'filters.gender.value.male',
                value: 1,
                translate: true,
            },
            {
                label: 'filters.gender.value.female',
                value: 2,
                translate: true,
            },
            {
                label: 'filters.gender.value.known',
                value: 3,
                translate: true,
            },
            {
                label: 'filters.gender.value.unkown',
                value: 4,
                translate: true,
            },
        ],
        beforeSend: ({ value }) => ({
            name: 'ageGender.gender',
            type: 'text',
            value: value[0],
            gqlFilter: getGqlFilter(value),
        }),
    })
}
