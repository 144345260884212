import Service from '@/_helpers/Service'

const ParsingRequest = new Service<any>({
    baseUrl: process.env.REACT_APP_CAMPAIGN_MANAGER,
    method: 'POST',
    url: '',
})

export const ParsingRequestReq = (token: string, campaignId: number, bloggers: string[]) =>
    ParsingRequest.call(
        {
            url: `/api/campaigns/${campaignId}/requests/by_urls`,
            data: [...bloggers],
        },
        token
    )
