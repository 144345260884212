import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.5008 3H12.0008V4.91782C11.1083 4.2165 9.9829 3.79823 8.75977 3.79823C5.86027 3.79823 3.50977 6.14873 3.50977 9.04823C3.50977 11.9477 5.86027 14.2982 8.75977 14.2982C10.3726 14.2982 11.8156 13.5709 12.7787 12.4264L11.5642 11.5378C10.8773 12.311 9.87541 12.7982 8.75977 12.7982C6.6887 12.7982 5.00977 11.1193 5.00977 9.04823C5.00977 6.97716 6.6887 5.29823 8.75977 5.29823C9.5749 5.29823 10.3293 5.5583 10.9445 6L9.00085 6V7.5H13.5008V3Z"
            fill="#323841"
        />
    </svg>
)
