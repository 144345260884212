import React, { FC, memo } from 'react'

import Sorter from '../../../Bloggers/SearchItems/Sorter'
import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'
import cn from './index.module.scss'
import { useTranslation } from 'react-i18next'
import Text from '@/components/Text'

export interface IItemHead {
    margin?: string | number
    setIsList: Function
    isList: boolean
}

const ItemHead: FC<IItemHead> = ({ setIsList, isList }) => {
    const { t } = useTranslation()
    return (
        <>
            <Flex margin={'0 0 20px 0'} content={'flex-end'} align="cetner">
                <Text fSize="12px" margin="0 10px 0 0" color={'gray-3'}>
                    {t('ads_explorer.view')}
                </Text>

                <Flex onClick={() => setIsList(!isList)} align="center">
                    <Icons name="grid" className={cn[`icon${!isList ? '-active' : ''}`]} style={{ height: '20px' }} />
                </Flex>

                <Flex onClick={() => setIsList(!isList)} align="center" margin="0 20px 0 4px">
                    <Icons name="lists" className={cn[`icon${!isList ? '' : '-active'}`]} style={{ height: '11px' }} />
                </Flex>

                <Sorter />
            </Flex>
        </>
    )
}

export default memo(ItemHead)
