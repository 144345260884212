import React, { FC, CSSProperties } from 'react'
import cn from './LoadCircle.module.scss'
import spinner from './Ellipse.png'

export interface ILoadCircle {
    style?: CSSProperties
}

const LoadCircle: FC<ILoadCircle> = ({ style }) => (
    <img src={spinner} className={cn.loader} style={style} alt="loading"></img>
)

export default LoadCircle
