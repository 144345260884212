import React, { memo, FC, useMemo } from 'react'

import Flex from '@/_yc/Flex'
import Text from '@/components/Text'
import Icons from '@/components/Icons'
// import { MassAddCheckBoxNEW } from '@/components/MassAddToList'

import cn from '../CampaignList.module.scss'
import { CAMPAIGN_MANAGER } from '@/routs'
import { Link } from 'react-router-dom'
import { ICampaign } from '../model/types'

interface ICampaignItem {
    item: ICampaign
}
const CampaignItem: FC<ICampaignItem> = ({ item }) => {
    const active = useMemo(() => !item.is_acitve, [item])
    return (
        <>
            <div className={cn.campaignItem}>
                <Flex align="center">
                    {/* <MassAddCheckBoxNEW
                        id={''}
                        externalId={''}
                        data={{
                            hasEmail: false,
                            avatar: '',
                            name: '',
                            username: '',
                        }}
                        bloggerId={1}
                        channelId={''}
                        type={'lists'}
                        // place={'listBloggers'}
                        key={1}
                        disabled={false}
                        index={1}
                    /> */}
                </Flex>

                {/* name */}
                <Link to={{ pathname: `${CAMPAIGN_MANAGER}/${item.id}`, state: { name: item.name } }}>
                    <Flex column>
                        <Text color={'gray-1'} fSize={14} fWeight={600}>
                            {item.name}
                        </Text>
                        <Text color={'gray-3'} fSize={12} fWeight={600}>
                            {/* 25 influencers */}
                            {item.description}
                        </Text>
                    </Flex>
                </Link>

                <Flex
                    style={{
                        padding: '3px 8px',
                        backgroundColor: active ? '#dbf5e7' : '#e7eaff',
                        borderRadius: '4px',
                        width: 'fit-content',
                    }}
                    align="center"
                >
                    <Text style={active ? { color: '#10BF61' } : { color: '#6071FF' }} fSize={10}>
                        {active ? 'Active' : 'On Pause'}
                    </Text>
                </Flex>

                <Flex className={cn.socials} align="center">
                    {item.platforms.includes('youtube') && <Icons name="ie-youtube" />}
                    {item.platforms.includes('instagram') && <Icons name="ie-instagram" />}
                    {item.platforms.includes('vk') && <Icons name="ie-vk" />}
                    {item.platforms.includes('twitch') && <Icons name="ie-twitch" />}
                    {item.platforms.includes('telegram') && <Icons name="ie-tg" />}
                </Flex>

                <Text fSize={10}>{new Date(item.created_at).toLocaleDateString()}</Text>
                <Text fSize={10}>{item.medias_count}</Text>
            </div>
        </>
    )
}

export default memo(CampaignItem)
