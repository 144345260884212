import React, { FC, memo } from 'react'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'

import cn from './Bar.module.scss'

export interface IBar {
    title: string
    share: number
    label?: string
    category?: string
    name?: string
    max: number
}

const Bar: FC<IBar> = (props) => {
    const val = props.share > 1 ? props.share / 10000000 : props.share
    const size = useWindowWidth('mobile')
    return (
        <div className={cn.root}>
            <p className={cn.label}>
                <span className={cn['label--hide-overflow']}>{props.label || props.category || props.name}</span>
            </p>
            <div className={cn.one}>
                <div
                    className={cn.fill}
                    style={{
                        background: props.title === 'AudienceInterests' ? '#6071FF' : '#4ECF81',
                        height: size !== 'mobile' ? `${(val * 100) / props.max}%` : '10px',
                        width: size === 'mobile' ? `${(val * 100) / props.max}%` : '20px',
                    }}
                >
                    {props.share ? <div className={cn.share}>{`${Math.round(val * 100)}%`}</div> : null}
                </div>
            </div>
        </div>
    )
}

export default memo(Bar)
