import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.6629 17.505H14.6396C11.4382 17.4262 8.38318 16.1479 6.07962 13.9234C3.85422 11.6219 2.57519 8.56799 2.49619 5.36753C2.49147 4.91919 2.6675 4.48782 2.98462 4.17086L5.24628 1.91086C5.5717 1.58554 6.0992 1.58554 6.42462 1.91086L9.75795 5.24419C10.0833 5.56961 10.0833 6.09711 9.75795 6.42253L8.42795 7.75003C8.67462 8.67163 9.12901 9.52452 9.75628 10.2434C10.4738 10.8709 11.3256 11.3256 12.2463 11.5725L13.5796 10.2442C13.905 9.91887 14.4325 9.91887 14.7579 10.2442L18.0913 13.5775C18.4166 13.9029 18.4166 14.4304 18.0913 14.7559L15.8296 17.0167C15.5212 17.3283 15.1013 17.504 14.6629 17.505ZM5.83378 3.67919H5.82962L4.16295 5.34919C4.2309 8.11474 5.33488 10.7539 7.25628 12.7442C9.24664 14.6649 11.8853 15.7688 14.6504 15.8375L16.3229 14.1667L14.1629 12.0125L13.0854 13.0892C12.9313 13.2451 12.7213 13.333 12.5021 13.3334C12.4436 13.3334 12.3852 13.3272 12.3279 13.315C10.9393 13.0078 9.64994 12.3571 8.57795 11.4225C7.64333 10.3505 6.99263 9.06117 6.68545 7.67253C6.6275 7.3965 6.71311 7.10989 6.91295 6.91086L7.98962 5.83336L5.83378 3.67919Z"
            fill="#8F8F8F"
        />
    </svg>
)
