import React, { FC, memo, useEffect } from 'react'

import { useInView } from 'react-intersection-observer'

export interface IScrollListener {
    onReach?: VoidFunction
    onHide?: VoidFunction
    height?: number | string
    className?: string
}

const options = {
    threshold: [0, 1],
}

const ScrollListener: FC<IScrollListener> = ({ onReach, onHide, height, className }) => {
    const { ref, inView } = useInView(options)

    useEffect(() => {
        if (inView && onReach) onReach()
        if (!inView && onHide) onHide()
    }, [inView, onReach, onHide])

    return <div id="scroll-listener" className={className} style={{ height: height ?? 0, width: '100%' }} ref={ref} />
}

export default memo(ScrollListener)
