import React, { useCallback, useState, FC, ChangeEvent, memo, FormEvent, MouseEvent as Me } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from '@/_yc/'
import Menu from './Popup'
import Icons from '@/components/Icons'

import cn from './SearchInput.module.scss'
import useOpenClose from '@/_helpers/useOpenClose'
import { useComputedStyle } from '@/_helpers/Hooks/useComputedStyle'
import { useSearch } from './useSearch'
import { useHistory } from 'react-router-dom'
import { LISTS, RECENT_SEARCHES } from '@/routs'

import { useAsyncCallback } from '@/_helpers/Hooks/useAsyncCallback'
import { parsingRequest } from '@/Services/UserRequests'
import useToken from '@/_helpers/Hooks/useToken'
import { AxiosError } from 'axios'
import { push } from '@/components/Notifier/PushNotification'
import UsernameNotification from '@/components/Notifier/MessageTypes/UsernameNotification'
import LegacyNotification from '@/components/Notifier/MessageTypes/LegacyNotification'
import { useVkHidden } from '@/_helpers/Hooks/useIsInstagramHidden'

import { OpenReportPlace } from '@/_helpers/amplitude/types'

const style = {
    zIndex: 52,
    marginTop: 6,
}

const random = Math.random() + Math.random() + 'ASD'

export interface ISearchInput {
    disableMassParsing?: boolean
    placeholder?: string
    onBloggerClick?: (id: string) => void
    place: OpenReportPlace
}

const SearchInput: FC<ISearchInput> = (props) => {
    const history = useHistory()
    const [isFocused, focus, blur] = useOpenClose()
    const [mark] = useState(() => Math.random() + 'ASDKJHQ;')
    const [isOpen, open, close] = useOpenClose()
    const [activeSocial, setActiveSocial] = useState('instagram')
    const isVkHidden = useVkHidden()
    const refInput = React.useRef<HTMLInputElement>(null)

    const token = useToken()

    const { search, setSearch, results, clearSearch, error, isLoading } = useSearch({
        instagram: activeSocial === 'instagram',
        yt: activeSocial === 'youtube',
        twitch: activeSocial === 'twitch',
        vk: activeSocial === 'vk',
        tg: activeSocial === 'telegram',
        tiktok: activeSocial === 'tiktok',
    })

    const { ref } = useComputedStyle((style) => style.width, [])

    const { t } = useTranslation()

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setSearch(e.target.value)
            open()
        },
        [setSearch, open]
    )

    const [parse] = useAsyncCallback(
        async (state, search: string) => {
            if (!token) return Promise.reject()
            try {
                await parsingRequest(token, search).then(() => {
                    history.push(LISTS + RECENT_SEARCHES)
                })
                push(new UsernameNotification(search, 'onParsingStart'))
                clearSearch()
            } catch (e) {
                if (e?.isAxiosError) {
                    const err: AxiosError = e
                    if (err.response?.status === 400) {
                        push(new LegacyNotification(t('aud_insides.search_input.invalid_username'), 'error'))
                    } else if (err.response?.status === 403) {
                        push(new LegacyNotification(t('report.alerts.renew'), 'error'))
                    } else {
                        push(new LegacyNotification(t('common_error'), 'error'))
                    }
                } else {
                    push(new LegacyNotification(t('common_error'), 'error'))
                }
            }
        },
        [token]
    )

    const closePopup = useCallback((event: Me<Document, MouseEvent> | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (refInput?.current && refInput?.current.contains(event.target as Node)) {
            return
        }
        close()
        clearSearch()
        //eslint-disable-next-line
    }, [])

    const handleFocus = () => {
        focus()
        open()
    }

    const submit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault()
            close()
            parse(search)
        },
        [parse, search, close]
    )

    return (
        <>
            <Flex
                component="form"
                align="center"
                data-mark={mark}
                ref={ref}
                className={isFocused ? cn['root--focused'] : cn['root--default']}
                autoComplete={'off'}
                onSubmit={submit}
            >
                <Icons className={cn.search_icon} name="search" data-mark={mark} />
                <input
                    ref={refInput}
                    value={search}
                    onChange={onChange}
                    placeholder={t(
                        props.placeholder || `aud_insides.search_input.placeholder${isVkHidden ? '' : '_vk'}`
                    )}
                    onFocus={handleFocus}
                    onBlur={blur}
                    data-mark={mark}
                    name={random}
                    autoComplete={'off'}
                    key={'top_search_input' + (isVkHidden ? '' : 'vk')}
                />
            </Flex>
            {/* new mass parsing can be used */}

            <Menu
                width={ref.current?.offsetWidth + 10 ?? 200}
                isOpen={isOpen}
                onClose={closePopup}
                anchor={ref.current}
                style={style}
                onChange={console.log}
                items={results}
                onParsing={parse}
                error={error}
                place={props.place}
                isLoading={isLoading}
                fullSearch={search}
                activeSocial={activeSocial}
                setActiveSocial={setActiveSocial}
            />
        </>
    )
}

export default memo(SearchInput)
