import React from 'react'
import { EmptyChatIcon } from './EmptyChatIcon'
import cn from './EmptyChat.module.scss'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import Text from '@/components/Text'

export const EmptyChat = () => {
    const t = useSpaceTranslation('outreach.messenger.chat')
    return (
        <div className={cn.emptyChat}>
            <div className={cn.icon}>
                <EmptyChatIcon />
            </div>

            <Text fSize={12} color="gray-3" tAlign="center">
                {t('empty')}
            </Text>
        </div>
    )
}
