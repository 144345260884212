import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.1675 16.6667H5.00083C4.08036 16.6667 3.33417 15.9205 3.33417 15V5.83333C3.33417 4.91286 4.08036 4.16667 5.00083 4.16667H8.33417V5.83333H5.00083V15H14.1675V11.6667H15.8342V15C15.8342 15.9205 15.088 16.6667 14.1675 16.6667ZM9.75083 11.4225L8.57583 10.2442L13.82 5H10.8342V3.33333H16.6675V9.16667H15.0008V6.17917L9.75083 11.4225Z"
            fill="#333333"
        />
    </svg>
)
