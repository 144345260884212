import React from 'react'
import cn from './step.module.scss'
import Text from '@/components/Text'
import { Flex } from '@/_yc'
import { useTranslation } from 'react-i18next'
import Targeting from '@/pages/TargetAudience/Views/components/Targeting'
import { ReactComponent as EditIcon } from '../../../../icons/edit.svg'
import { ReactComponent as ShowIcon } from '../../../../icons/show.svg'
import { Link } from 'react-router-dom'

interface StepProps {
    step: number | string
    title: string
    description: string
    targetingType?: 'from_customer' | 'from_us'
    hide?: boolean
    gptPrompt?: string
}

const Index: React.FC<StepProps> = ({ gptPrompt, step, title, description, targetingType, hide }) => {
    const { t } = useTranslation()
    return (
        <div className={cn['stepContainer']}>
            <Flex style={{ width: '100%' }} content={'space-between'} align={'start'}>
                {targetingType && <Targeting targetingType={targetingType} />}
                <Flex>
                    <span className={cn['stepNumber']}>
                        {t('target_audience.step')} {step}
                    </span>
                </Flex>
            </Flex>
            <Flex content={'center'} align={'center'} className={cn['wrapper']}>
                <Flex className={cn['content']} column>
                    <Flex content={'center'} align={'center'} className={cn['avatar']}>
                        <ShowIcon />
                    </Flex>
                    <Text component="h1" fSize={'20px'} color={'gray-new-4'} margin="0 0 10px 0">
                        {t(title)}
                    </Text>
                    <Text fSize={'12px'} color={'gray-new-4'}>
                        {t(description)}
                    </Text>
                </Flex>
            </Flex>
            {!hide && (
                <Flex column className={cn['ai-block']}>
                    <Flex content={'space-between'} align={'center'} margin={'0 0 10px 0'}>
                        <Text fSize={'14px'} semibold color={'gray-new-1'}>
                            Ваш запрос
                        </Text>
                        <Link to="/target/new/two">
                            <Flex>
                                <Text fSize={'12px'} semibold color={'violet-1'}>
                                    Редактировать
                                </Text>
                                <EditIcon className={cn['edit-icon']} />
                            </Flex>
                        </Link>
                    </Flex>
                    <Flex>
                        <Text style={{ lineHeight: '18px' }} fSize={'14px'} color={'gray-new-1'}>
                            {gptPrompt}
                        </Text>
                    </Flex>
                </Flex>
            )}
        </div>
    )
}

export default Index
