import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { numberFormatter } from '@/_helpers'
import LoadingButton from '@/components/Share/LoadingButton'
import { useCount } from '@/pages/SearchPage/SearchAdapter/hooks/useCount'
import { useAdapter } from '@/pages/SearchPage/SearchAdapter'
import { trackShowResultsBtn } from '@/_helpers/amplitude/track'

import cn from './Filters.module.scss'

export interface ISearchButton {
    onClick?: VoidFunction
    platform?: string
}

const SearchButton: FC<ISearchButton> = (props) => {
    const { t } = useTranslation()

    const state = useCount()
    const { title } = useAdapter()

    const onClick = () => {
        trackShowResultsBtn(title)
        props.onClick && props.onClick()
    }
    const countWOyt =
        props.platform === 'YouTube' || props.platform === 'Twitch' ? '' : `(${numberFormatter(state.count || 0)})`

    return (
        <LoadingButton
            loading={props.platform !== 'YouTube' && state.loading}
            variant="primary"
            className={cn.searchBtn}
            // disabled={!state.count}
            onClick={onClick}
            noDownloadIcon
            noLoadingText
        >
            {t('Influencer_explorer.show_results')} {countWOyt}
        </LoadingButton>
    )
}

export default memo(SearchButton)
