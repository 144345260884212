import { gql } from '@apollo/client'

export const GET_MEDIA_PLAN = gql`
    query GetMediaPlan($orderId: Int) {
        mediaPlanOrderById(orderId: $orderId) {
            id
            name
            status
            data
            unlockedBloggers
            currency
            contentTypes {
                name
                color
                id
            }
            bloggers {
                externalId
                mediaPlanId {
                    id
                }
                data
                postsReach
                videosViews
                storiesReach
                totalCost
                customData
                bloggerType
                contentType {
                    name
                    color
                    id
                }
                cpv
                totalReach
            }
        }
    }
`

export const SET_CURRENCY = gql`
    mutation SetCurrency($orderId: Int!, $currency: MediaPlanCurrenciesEnum) {
        updateMediaPlanOrder(orderId: $orderId, currency: $currency, bloggers: [], bloggersType: INSTAGRAM) {
            __typename
        }
    }
`

export const ADD_INFLUENCER = gql`
    mutation UpdateMediaPlan($orderId: Int!, $bloggers: [String], $bloggersType: MediaPlanBloggerTypeEnum!) {
        updateMediaPlanOrder(orderId: $orderId, bloggers: $bloggers, bloggersType: $bloggersType) {
            mediaPlanOrder {
                name
                id
                bloggers {
                    postCost
                    data
                }
            }
        }
    }
`

export const UPDATE_CUSTOM_BLOGGER_DATA = gql`
    mutation UpdateMediaPlan($orderId: Int!, $bloggerData: String!, $bloggerId: String!) {
        updateBloggerCustomDataInMediaPlan(bloggerData: $bloggerData, bloggerId: $bloggerId, orderId: $orderId) {
            mediaPlanOrder {
                bloggers {
                    externalId
                    data
                    postsReach
                }
            }
        }
    }
`

export const CREATE_CUSTOM_COLUMN = gql`
    mutation CreateCustomColumn($orderId: Int!, $columnName: String!, $columnKey: String!) {
        createCustomColumnInMediaPlan(columnName: $columnName, columnKey: $columnKey, orderId: $orderId) {
            mediaPlanOrder {
                id
            }
        }
    }
`

export const GET_CUSTOM_COLUMNS = gql`
    query Columns($orderId: Int!) {
        customColumn(orderId: $orderId) {
            name
            key
        }
    }
`

export const UPDATE_BLOGGER_DATA = gql`
    mutation UpdateMediaPlan($orderId: Int!, $bloggerData: MediaPlanBloggerInput!, $bloggerId: String!) {
        updateBloggerInMediaPlan(bloggerData: $bloggerData, bloggerId: $bloggerId, orderId: $orderId) {
            mediaPlanOrder {
                bloggers {
                    externalId
                    data
                    postsReach
                    storiesReach
                    totalCost
                    totalReach
                }
            }
        }
    }
`

export const DELETE_BLOGGERS_FROM_MEDIA_PLAN = gql`
    mutation DeleteBloggerFromMediaPlan($orderId: Int!, $bloggerIds: [String]!) {
        deleteMediaPlanOrderBloggers(bloggerIds: $bloggerIds, orderId: $orderId) {
            __typename
        }
    }
`

export const FIND_YOUTUBE_CHANNELS_IDS = gql`
    query FindYoutubeChannelsIds($channelIds: [String]!) {
        youtube_channel(where: { custom_url: { _in: $channelIds } }) {
            id
        }
    }
`

export const OPEN_INFLUENCERS = gql`
    mutation OpenInfluencers($orderId: Int!) {
        openMediaPlanBloggers(orderId: $orderId) {
            __typename
        }
    }
`

export const ASSIGN_CONTENT_TYPE = gql`
    mutation AssignContentType($orderId: Int!, $bloggerId: String!, $contentTypeId: Int!) {
        updateBloggerInMediaPlan(
            bloggerId: $bloggerId
            orderId: $orderId
            bloggerData: { contentType: $contentTypeId }
        ) {
            __typename
        }
    }
`

export const CREATE_CONTENT_TYPE = gql`
    mutation CreateContentType($orderId: Int!, $name: String!, $color: String!) {
        createMediaPlanContentType(name: $name, color: $color, orderId: $orderId) {
            __typename
        }
    }
`

export const GET_CONTENT_TYPES = gql`
    query GetContentTypes($orderId: Int!) {
        mediaPlanOrderById(orderId: $orderId) {
            contentTypes {
                name
                color
                id
                mediaPlan {
                    id
                }
            }
        }
    }
`

export const DELETE_CONTENT_TYPE = gql`
    mutation DeleteContentType($orderId: Int!, $contentTypeId: Int!) {
        deleteMediaPlanContentType(orderId: $orderId, contentTypeId: $contentTypeId) {
            __typename
        }
    }
`
