import Observable from './Observable'

/**
 * @description represents WebSocket api
 */
export default class WebSocketService<ReceivingMessageType> {
    private subs = new Observable<ReceivingMessageType>()

    private websocket?: WebSocket

    /**
     *
     * @param path websocket path started without '/'
     * @param guard type guard
     * @param baseUrl websocket base url
     */
    constructor(
        private path: string,
        private guard: (native: any) => ReceivingMessageType | null,
        private baseUrl: string = process.env.REACT_APP_WSS_DOMAIN ||
            'wss://api.yolotech.ru/'
    ) {
        this.subs.onSubscribe = (count) => {
            if (count === 1) {
                this.init()
            }
        }
        this.subs.onUnsubscribe = (count) => {
            if (count === 0) {
                this.close()
            }
        }
    }

    // connection initialization
    private init() {
        const ws = new WebSocket(`${this.baseUrl}${this.path}`)
        ws.onopen = () => {
            console.log(`> ws connected`)
        }

        ws.onmessage = (message) => {
            const data = this.guard(message.data)
            if (!data) return
            this.subs.update(data)
        }

        ws.onerror = (error) => {
            console.error('ws error', error)
        }

        ws.onclose = (event) => {
            console.log(`Connection closed`)
        }
        this.websocket = ws
    }

    subscribe: Observable<
        ReceivingMessageType
    >['subscribe'] = this.subs.subscribe.bind(this.subs)

    // close connection
    close() {
        this.subs.clear()
        this.websocket?.close()
        this.websocket = undefined
    }
}
