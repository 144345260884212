import React from 'react';
import { connect } from 'react-redux'

import actions from './Model/actions.js'

class ReportCounter extends React.Component{
	/*constructor(props){
		super(props);
	}*/
	counterStyle = {
		color: "#FFDA54",
		fontSize: 14,
		fontWeight: 'bold',
		padding: this.props.counter.loaded ? 5 : 0,
		background: this.props.bg || "#000",
		borderRadius: 4,
		marginLeft: 5,
		width: "fit-content",
		maxWidth: this.props.counter.loaded ? 80 : 0,
		transition: "max-width .5s, padding .5s",
		overflow: 'hidden'
	}
	componentDidMount(){
		this.props.dispatch(actions.getCount())	
	}
	render(){
		return(
			<div 
				className = {this.props.className} 
				style = {!this.props.style ? this.counterStyle : this.props.style}
			>
				{this.props.counter.count}
			</div>
		)
	}
}

ReportCounter = connect(
	({ HeaderMenu_ }) => ({ counter: HeaderMenu_.ReportCounter_ })
)(ReportCounter);

export {ReportCounter as default}