import Icons from '@/components/Icons'
import Text from '@/components/Text'
import { Flex } from '@/_yc'
import React, { FC, memo, useCallback } from 'react'
import cn from './Chips.module.scss'
import { useSearch } from '../../../SearchAdapter'

export interface ITag {
    label: string
    value?: any
    onDelete: (value: any | null) => void
    onClick?: Function
}

const Tag: FC<ITag> = ({ onDelete, ...props }) => {
    const { restart } = useSearch()

    const click = useCallback(
        (event: any) => {
            event?.stopPropagation()
            onDelete(props.value ?? null)
            restart()
        },
        [onDelete, props.value, restart]
    )

    return (
        <Flex align="center" content="space-between" className={cn.tag}>
            <Text fSize={12} color="white" onClick={props.onClick}>
                {props.label}
            </Text>
            <Icons margin="0 0 0 4px" name="close--small" onClick={click} />
        </Flex>
    )
}

export default memo(Tag)
