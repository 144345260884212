import React, { FC } from 'react'

import Text from '@/components/Text'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

import { Tooltip } from '@yoloker/ui-collection'
import Flex from '@/_yc/Flex'

export interface IBlockTitle {
    type: string
    title: string
    myRef: React.MutableRefObject<null>
    marginBottom?: string
}

export const BlockTitle: FC<IBlockTitle> = ({ type, title, myRef, marginBottom }) => {
    const t = useSpaceTranslation('report_titles')

    return (
        <Flex ref={myRef} style={{ scrollMarginTop: '40px' }} align={'center'}>
            <Text color="gray-1" fSize={18} semibold margin={`40px 0 ${marginBottom ? marginBottom : '20px'} 0`}>
                {t(title)}
            </Text>
            <div style={{ margin: `40px 0 ${marginBottom ? marginBottom : '20px'} 0` }}>
                <Tooltip text={t(`tooltips.${title}`)}></Tooltip>
            </div>
        </Flex>
    )
}
