import React, { FC, useMemo } from 'react'
import Modal from '@/_yc/Modal'

import cn from './UnsubscribeModal.module.scss'
import { Flex, Text } from '@/_yc'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { Trans } from 'react-i18next'
import polette from '@/polette.module.scss'
import { parseDate } from '@/_helpers'
import Button from '@/components/Button'

export interface IUnsubscribeModal {
    exp: string

    onClose?: VoidFunction
    onSubmit?: VoidFunction
}

const components = [<span style={{ color: polette.primary }} />]
const UnsubscribeModal: FC<IUnsubscribeModal> = (props) => {
    const t = useSpaceTranslation('billing.tariff.modal')

    const values = useMemo(() => {
        const { date, month, year } = parseDate(props.exp, true, true)

        return { date: `${date}.${month}.${year}` }
    }, [props.exp])

    return (
        <Modal width={440} onClose={props.onClose} className={cn.modal}>
            <div className={cn.root}>
                <p className={cn.title}>{t('title')}</p>
                <p className={cn.text} style={{ marginBottom: 0 }}>
                    <Trans i18nKey="billing.tariff.modal.details_question" values={values} components={components} />
                </p>
                <p className={cn.text} style={{ marginTop: '5px' }}>
                    <Trans i18nKey="billing.tariff.modal.details" values={values} components={components} />
                </p>
                <Flex content="space-between" margin="30px 0 0">
                    <Flex content="center">
                        <Button onClick={props.onClose} variant="primary" className={cn['btn']}>
                            <Text semibold size={18} color="white">
                                {t('not_now')}
                            </Text>
                        </Button>
                    </Flex>
                    <Flex content="center">
                        <Button onClick={props.onSubmit} variant="pure" className={cn['btn--gray']}>
                            <Text semibold size={18} style={{ color: '#8F8F8F' }}>
                                {t('unsubscribe')}
                            </Text>
                        </Button>
                    </Flex>
                </Flex>
            </div>
        </Modal>
    )
}

export default UnsubscribeModal
