import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.00008 17.3334C4.39771 17.3334 0.666748 13.6025 0.666748 9.00008C0.666748 4.39771 4.39771 0.666748 9.00008 0.666748C13.6025 0.666748 17.3334 4.39771 17.3334 9.00008C17.3284 13.6004 13.6004 17.3284 9.00008 17.3334ZM8.16675 2.38508C5.43845 2.7301 3.20198 4.71499 2.53534 7.38299C1.8687 10.051 2.90871 12.8546 5.15407 14.4423C7.39944 16.0301 10.3893 16.0762 12.6826 14.5584L12.5992 14.6176L12.6776 14.5634L12.7267 14.5292L12.7801 14.4917L12.7934 14.4826L12.8009 14.4767L8.52758 10.2017C8.29539 9.96548 8.16574 9.64718 8.16675 9.31592V2.38508ZM10.5242 9.83342L14.0467 13.3559L14.0526 13.3492L14.0567 13.3442L14.0484 13.3534C14.9081 12.3614 15.4534 11.1361 15.6151 9.83342H10.5242ZM9.83342 2.38508V8.16675H15.6151C15.2347 5.14517 12.855 2.76551 9.83342 2.38508Z"
            fill="#8F8F8F"
        />
    </svg>
)
