import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.58333 17.0833C4.1231 17.0833 3.75 16.7102 3.75 16.25V3.75C3.75 3.28976 4.1231 2.91665 4.58333 2.91665H9.9C10.2168 2.91446 10.5074 3.09208 10.65 3.375L11.25 4.58333H15.4167C15.8769 4.58333 16.25 4.95643 16.25 5.41667V12.0833C16.25 12.5436 15.8769 12.9167 15.4167 12.9167H10.925C10.6112 12.9157 10.3245 12.7386 10.1833 12.4583L9.58333 11.25H5.41667V16.25C5.41667 16.7102 5.04357 17.0833 4.58333 17.0833ZM5.41667 4.58333V9.58333H10.4167L11.25 11.25H14.5833V6.25H10.4167L9.58333 4.58333H5.41667Z"
            fill="#333333"
        />
    </svg>
)
