import Icons from '@/components/Icons'
import React, { FC } from 'react'

import cn from './Color.module.scss'

interface ColorProps {
    color: string
    markColor: string
    isActive?: boolean
    isChosen?: boolean
}

export const Color: FC<ColorProps> = ({ color, isActive, isChosen, markColor }) => {
    return (
        <div
            className={isActive ? cn['color--chosen'] : cn['color']}
            style={{ backgroundColor: color, borderColor: markColor }}
        >
            {(isActive || isChosen) && <Icons name={'done'} className={cn.icon} fill={markColor} />}
        </div>
    )
}
