import React, { FC, memo } from 'react'

import cn from './LoadingBar.module.scss'

interface ILoadingBar {
    width: number
    height: number
}
const LoadingBar: FC<ILoadingBar> = (props) => {
    return (
        <>
            <div className={cn.background} style={{ width: props.width, height: props.height }} />
        </>
    )
}
export default memo(LoadingBar)
