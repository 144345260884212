import React, { FC } from 'react'
import styles from './list.module.scss'
import { Data } from '@/pages/TargetAudience/Views/AllDataReports/data'
import HeaderList from '@/pages/TargetAudience/Views/components/list/HeaderList'
import ItemList from '@/pages/TargetAudience/Views/components/list/ItemList'
import EmptyState from '@/pages/TargetAudience/Views/components/EmptyState/EmptyState'
import { ICampaignResponse } from '@/pages/TargetAudience/services/types'
import Loader from '@/components/Loader'

interface TableProps<T> {
    headers: string[]
    data: ICampaignResponse[]
    loading?: boolean
}

const Index: FC<TableProps<Data>> = ({ headers, data, loading }: TableProps<Data>) => {
    return (
        <div className={styles['list']}>
            <HeaderList headers={headers} />
            {!loading ? (
                <div className={styles.body}>
                    {data.map((item, index) => (
                        <ItemList key={item.id} item={item} />
                    ))}
                </div>
            ) : (
                <Loader style={{ margin: 'auto' }} />
            )}
            {!data.length && !loading && <EmptyState />}
        </div>
    )
}

export default Index
