import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { RootStore } from '@/_helpers/StoreType'
import Flex from '@/_yc/Flex'
import { TARIFFS } from '@/routs'

import Text from '../Text'
import Star from './Star.png'
import Button from '../Button'

import cn from './ExpiredSubs.module.scss'

const ExpiredSubs = () => {
    const { t } = useTranslation()
    const [isShown, setIsShown] = React.useState(false)
    const pageLayout = document.getElementById('page-layout-yolo')
    const header = document.getElementById('top-header-yolo')
    const ref = React.useRef<HTMLDivElement>(null)
    const tariff = useSelector((store: RootStore) => store.authentication.user?.profile?.package)

    useEffect(() => {
        if (tariff === undefined || tariff === null) {
            setIsShown(false)
            if (pageLayout) pageLayout.style.marginTop = '0'
            if (header) header.style.top = '0'
            return
        }
        if (tariff.length === 0) {
            setIsShown(true)

            const height = ref.current ? ref.current.offsetHeight + 'px' : '56px'
            if (pageLayout) pageLayout.style.marginTop = height
            if (header) header.style.top = height
        }
        // eslint-disable-next-line
    }, [tariff, header, pageLayout, ref.current])

    if (!isShown) return null
    return (
        <>
            <Flex className={cn.root} align="center" ref={ref}>
                <Flex align="center">
                    <img src={Star} alt="star-icon" />
                    <Text color="white" margin="0 0 0 6px">
                        {t('report_layout.expired')}
                    </Text>
                </Flex>
                <Link to={TARIFFS}>
                    <Button style={{ background: '#F5C208', whiteSpace: 'nowrap' }} className={cn.btn}>
                        {t('billing.tariff.empty.choose')}
                    </Button>
                </Link>
            </Flex>
        </>
    )
}
export default ExpiredSubs
