import { authHeader, handleResponse, logout } from '../../_helpers';

export const userService = {
    login,
    logout: logout,
    getAll,
    getCampaings
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
        mode: 'cors'
    };

    return fetch(process.env.REACT_APP_API_DOMAIN+`/token-auth/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        });
}

// function logout() {
//     // remove user from local storage to log user out
//     localStorage.removeItem('user');
// }

function getAll(page, query) {
    debugger;
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        mode: 'cors'
    };
    let q = '';

    if(query){
        q = '&description=' + query;
    }
    return fetch(process.env.REACT_APP_API_DOMAIN+'/api/posts/?page='+page+q, requestOptions).then(handleResponse);
}

function getCampaings(page) {
    debugger;
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        mode: 'cors'
    };

    return fetch(process.env.REACT_APP_API_DOMAIN+'/api/campaigns/?page='+page, requestOptions).then(handleResponse);
}

// function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 // auto logout if 401 response returned from api
//                 logout();
//                 window.location.reload(true);
//             }
//
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//
//         return data;
//     });
// }