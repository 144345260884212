import React, { FC } from 'react'
import ReactDOM from 'react-dom'
import { payment, capturePayment } from '@/pages/TariffPage/Model/service'

export const PaypalWidget: FC<{ tariffId: number }> = ({ tariffId }) => {
    let user = JSON.parse(localStorage.getItem('user') || '{token: ""}')
    // console.log(tariffId)

    const onApprove = (data: any, actions: any) => {
        // console.log(data)
        return capturePayment(user.token, data.orderID).then((orderData) => {
            // Successful capture! For dev/demo purposes:
            console.log('Capture result', orderData, JSON.stringify(orderData, null, 2))
        })
    }

    const createOrder = (data: any, actions: any) => {
        // console.log(data, actions)
        return payment(user.token, tariffId, false).then((response) => response.order_id)
    }

    // @ts-ignore
    const PayPalButton = window.paypal?.Buttons.driver('react', { React, ReactDOM })

    return (
        <PayPalButton
            createOrder={(data: any, actions: any) => createOrder(data, actions)}
            onApprove={(data: any, actions: any) => onApprove(data, actions)}
        />
    )
}
