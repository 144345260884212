import React, { FC, memo, useMemo } from 'react'
import { IInput } from '@/_yc/Input/Input'
import Input from '@/_yc/Input'
import { useForm } from '@schematic-forms/react'
import Flex from '@/_yc/Flex/'

export interface IFormInput extends IInput {
    name: string
    tSize?: number
    initialValue?: string
    permaValue?: string
    errorTransform?: (name: string, error: string) => string
    spaceBetween?: boolean
    removeSpace?: boolean
}

const permanentStyle = {
    width: 120,
    padding: '0 0 0 22px',
    border: 'none',
    background: 'white',
    height: 40,
    outline: 'none',
    fontWeight: 600,
    color: '#8F8F8F',
    borderRadius: '4px 0 0 4px ',
}

const temporaryStyle = {
    marginLeft: '20',
    borderRadius: '0 4px 4px 0',
    backgroundColor: 'transparent',
}

const flexStyle = {
    border: '1px solid #CCCCCC',
    borderRadius: 4,
}

const FormInput: FC<IFormInput> = ({ errorTransform, ...props }) => {
    const [value, setValue, nativeError] = useForm(props.name, '')
    const setValueWithoutSpace = (s: string) => {
        setValue(s.replace(/ /g, ''))
    }
    const error = useMemo<string | undefined>(() => {
        if (!nativeError) return
        if (errorTransform) return errorTransform(props.name, nativeError)
        return nativeError
    }, [errorTransform, nativeError, props.name])
    return props.permaValue ? (
        <Flex style={flexStyle}>
            <input style={permanentStyle} readOnly value={props.permaValue}></input>
            <Input
                spaceBetween={props.spaceBetween}
                {...props}
                style={{ ...temporaryStyle }}
                tSize={props.tSize}
                value={value}
                onChange={props.removeSpace ? setValueWithoutSpace : setValue}
                error={error}
                titleSize={props.titleSize}
            />
        </Flex>
    ) : (
        <Input
            {...props}
            tSize={props.tSize}
            titleSize={props.titleSize}
            value={value}
            onChange={props.removeSpace ? setValueWithoutSpace : setValue}
            error={error}
        />
    )
}

export default memo(FormInput)
