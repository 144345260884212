import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
// import { useTranslation } from 'react-i18next'

import { RootStore } from '@/_helpers/StoreType'
import { IRawTikTokData } from '../types'
// import Text from '@/components/Text'
import Flex from '@/_yc/Flex'
import Post from './Post'

const Posts = () => {
    // const { t } = useTranslation()
    const tiktokData: IRawTikTokData | undefined = useSelector((store: RootStore) => store.report.rawTikTokData)

    const topPosts = useMemo(() => {
        if (!tiktokData?.user_profile.top_posts) return { success: false }
        if (tiktokData?.user_profile.top_posts.length === 0) return { success: false }
        return {
            success: true,
            data: tiktokData?.user_profile.top_posts.slice(0, 10).map((item) => {
                return {
                    username: item.username,
                    avatar: item.user_picture,
                    likes: item.stat.likes,
                    comments: item.stat.comments,
                    created: item.created,
                    text: item.text,
                    followers: tiktokData?.user_profile.followers ?? 0,
                    fullName: tiktokData?.user_profile.fullname ?? '',
                    url: item.link,
                    thumbnail: item.thumbnail,
                    hashtags: item.hashtags,
                }
            }),
        }
    }, [tiktokData])

    return (
        <>
            {topPosts.success && topPosts.data && (
                <div style={{ background: 'white', borderRadius: '10px', padding: '20px 24px' }}>
                    {/* <Text color={'gray-1'} fSize={18} style={{ lineHeight: '22px', fontWeight: 600 }}>
                        {t('report_layout.pop_posts.title')}
                    </Text> */}
                    <Flex wrap>
                        <Post posts={topPosts.data} />
                    </Flex>
                </div>
            )}
        </>
    )
}
export default Posts
