import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import Avatar from '@/Layouts/ReportLayout/Content/Avatar/Avatar'
import MainInfo from '@/Layouts/ReportLayout/Content/MainInfo/MainInfo'
import { useSpaceTranslation } from '@/_helpers'
import { RootStore } from '@/_helpers/StoreType'
import Flex from '@/_yc/Flex'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import { getMonth } from '@/_helpers/parseDate_ts'
import { getLang } from '@/_helpers/getLang'
import Text from '@/components/Text'
import { shortNum } from '@/_helpers/_graphs/_tech'
import HiddenMetric from '@/components/HiddenMetric'

import { Platform } from '../../types'
import { GeneralInfo } from '../../BloggerReport/Title/GeneralInfo/GeneralInfo'

import cn from './ReportHeader.module.scss'

interface IReportHeader {}
export const ReportHeader: FC<IReportHeader> = () => {
    const t = useSpaceTranslation('channel_report')

    const metrics = useSelector((store: RootStore) => {
        const userInfo = store.report.rawTgData?.getTelegraReport?.data.telegram_channel

        if (!userInfo || userInfo.length === 0) {
            return {
                name: '',
                verified: false,
                link: '',
                topics: [],
                avatar: '',
                upd_date: '',
                username: '',
            }
        }

        const name = userInfo[0].title
        const avatar = userInfo[0].avatar

        const loc = undefined //userInfo.country + (userInfo.city ? ', ' + userInfo.city : '')

        const updDate = new Date(userInfo[0].updated_at)
        const publDateString =
            updDate.getDate() + ' ' + getMonth(updDate.getMonth() + 1, getLang()) + ' ' + updDate.getFullYear()

        const topics = userInfo[0].channel_topics
            .map((item) => {
                return item?.telegram_topic?.name ?? null
            })
            .filter((item) => item)
        return {
            name,
            verified: false,
            link: `https://t.me/${userInfo[0].username}/`,
            topics: topics,
            avatar,
            country: loc,
            email: userInfo[0].contacts?.join(' ') ?? null,
            upd_date: publDateString,
            username: userInfo[0].username,
        }
    })

    const restOfData = useSelector((store: RootStore) => {
        const metrics = store.report.rawTgData?.getTelegraReport?.data.telegram_channel

        if (!metrics || metrics.length === 0) {
            return []
        }

        const doesExists = (val: any) => {
            if (val === null || val === undefined || val === 0 || val === '0' || val === '0.0' || val === '') {
                return false
            }
            return true
        }
        const rest = [
            {
                value: doesExists(metrics[0].er_followers) ? (metrics[0].er_followers * 100).toFixed(2) + '%' : null,
                name: t('metrics.er_f'),
            },
            {
                value: doesExists(metrics[0].er_views) ? (metrics[0].er_views * 100).toFixed(2) + '%' : null,
                name: t('metrics.er_views'),
            },
            {
                value: doesExists(metrics[0].subscribers_count) ? metrics[0].subscribers_count : null,
                name: t('metrics.sub_count'),
            },
            {
                value: doesExists(metrics[0].median_views_m) ? metrics[0].median_views_m : null,
                name: t('metrics.post_views'),
            },
            {
                value: doesExists(metrics[0].mentions_count_m) ? metrics[0].mentions_count_m : null,
                name: t('metrics.mentions'),
            },
        ]

        return rest
    })

    const size = useWindowWidth('mobile')
    const isMobile = size === 'mobile'

    return (
        <div className={cn.root}>
            <GeneralInfo
                country={!isMobile ? metrics.country : undefined}
                email={!isMobile ? metrics.email : undefined}
                updatedDate={metrics.upd_date}
                inProgress={false}
                isDemo={false}
            />
            <div className={cn.header}>
                <Flex content="flex-start">
                    <Avatar type={Platform.Tg} url={metrics.avatar} />
                    <MainInfo
                        data={{
                            name: metrics.name,
                            verified: metrics.verified,
                            link: metrics.link,
                            topics: metrics.topics,
                            username: metrics.username,
                        }}
                    />
                </Flex>

                {/* <Metrics restOfData={restOfData} /> */}

                <>
                    <Flex className={cn.Metrics} wrap>
                        {restOfData.map((item, i) => (
                            <Flex className={cn.MetricItem} column>
                                {item.value ? (
                                    <Text className={cn['MetricItem--value']} semibold>
                                        {typeof item.value === 'number' ? shortNum(item.value) : item.value}
                                    </Text>
                                ) : (
                                    <>
                                        <HiddenMetric />
                                    </>
                                )}
                                <Text color={'gray-3'} className={cn['MetricItem--name']}>
                                    {item.name}
                                </Text>
                            </Flex>
                        ))}
                    </Flex>
                </>
            </div>
        </div>
    )
}
