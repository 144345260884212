import { RootStore } from '@/_helpers/StoreType'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import Text from '@/components/Text'

import cn from './InfluencersList.module.scss'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

import MoreIcon from './MoreIcon.svg'
import BloggerLink from '@/components/Share/BloggerLink'
import useOpenClose from '@/_helpers/useOpenClose'
import { InfluencersPopup } from './InfluencersPopup/InfluencersPopup'
import Avatar from '@/components/Avatar'
import Flex from '@/_yc/Flex'
import Button from '@/components/Button'
import Icons from '@/components/Icons'
import { AddFromListPopup } from './AddFromListPopup/AddFromListPopup'
// import { AddManuallyPopup } from './AddManuallyPopup/AddManuallyPopup'
import { useBloggerList } from '../../utils/useBloggerList'
import { Platform } from '@/pages/Reports/types'

export const InfluencersList = () => {
    const mailingList = useSelector((store: RootStore) => store.MassAddToList.mailingList)
    const setId = useSelector((store: RootStore) => store.MassAddToList.mailingList.setId)
    const excludedBloggers = useSelector((store: RootStore) => store.Outreach.excludedBloggers)
    const excludedChannels = useSelector((store: RootStore) => store.Outreach.excludedChannels)
    const { bloggers } = useBloggerList({ listId: mailingList.setId[0], page: 1 })

    const allList = useMemo(() => {
        if (setId.length > 0) return bloggers.filter((b) => b.hasEmail)

        return mailingList.instList.concat(mailingList.ytList)
    }, [mailingList, setId, bloggers])

    const totalExcluded = useMemo(() => {
        if (setId.length > 0) return excludedBloggers.length + excludedChannels.length
        return 0
    }, [excludedBloggers, excludedChannels, setId])

    const totalBloggers = useMemo(() => {
        if (setId.length > 0) return allList.length - totalExcluded

        return allList.length
    }, [allList, setId, totalExcluded])

    const t = useSpaceTranslation('outreach.templates.influencers_list')

    const [isInfluencersListPopupOpen, openInfluencersListPopup, closeInfluencersListPopup] = useOpenClose()
    const [isAddFromListPopupOpen, openAddFromListPopup, closeAddFromListPopup] = useOpenClose()
    // const [isAddManuallyPopupOpen, openAddManuallyPopup, closeAddManuallyPopup] = useOpenClose()

    return (
        <>
            <div className={cn.influencersList}>
                <div className={cn.header} onClick={openInfluencersListPopup}>
                    <Text fSize={16} semibold>
                        {t('title')}
                    </Text>
                    <Text fSize={12} color="gray-2">{`${totalBloggers} ${t('bloggers')}`}</Text>
                </div>

                {allList.length > 0 && (
                    <div className={cn.avatars}>
                        {allList.slice(0, 14).map((b) => {
                            return (
                                <BloggerLink
                                    id={b.externalId || b.channelId || ''}
                                    newTab
                                    platform={b.channelId ? Platform.YouTube : Platform.Instagram}
                                >
                                    <div className={cn.avatarContainer}>
                                        <Avatar
                                            alt="AVA"
                                            src={b.avatar}
                                            aria-label="Аватарка"
                                            className={cn.avatar}
                                            loadingAvatarWidth="40"
                                        />
                                    </div>
                                </BloggerLink>
                            )
                        })}

                        {allList.length > 14 && (
                            <div className={cn.moreIcon} onClick={openInfluencersListPopup}>
                                <img src={MoreIcon} alt="more" />
                            </div>
                        )}
                    </div>
                )}

                {allList.length === 0 && (
                    <Flex column>
                        <Text fSize={14} color="gray-new-2" margin="0 0 20px 0">
                            {t('controls.help_text')}
                        </Text>

                        <Flex>
                            <Button style={{ width: '50%' }} content="center" onClick={openAddFromListPopup}>
                                <Flex align="center">
                                    <Icons name="list-plus" margin="0 10px 0 0" className={cn.controlIcon}></Icons>
                                    <div>{t('controls.add_from_list')}</div>
                                </Flex>
                            </Button>

                            {/* <Button margin="0 0 0 20px" style={{ width: '100%' }} onClick={openAddManuallyPopup}>
                                <Flex align="center" content="center">
                                    <Icons
                                        name="filter--followers"
                                        margin="0 10px 0 0"
                                        className={cn.controlIcon}
                                    ></Icons>
                                    <div>{t('controls.add_manually')}</div>
                                </Flex>
                            </Button> */}
                        </Flex>
                    </Flex>
                )}
            </div>

            <InfluencersPopup isOpen={isInfluencersListPopupOpen} close={closeInfluencersListPopup} />
            <AddFromListPopup isOpen={isAddFromListPopupOpen} close={closeAddFromListPopup} />
            {/* <AddManuallyPopup isOpen={isAddManuallyPopupOpen} close={closeAddManuallyPopup} /> */}
        </>
    )
}
