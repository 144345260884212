import { SubChannel } from '@/_helpers/CrossTabChannel'
import channel from './TabChannel'
import Observable from './Observable'

type Native<DataType> = {
    value: DataType
}

export default class SyncronizedStorage<DataType> {
    private channel: SubChannel<DataType>
    private _value: DataType
    private observable = new Observable<DataType>()

    constructor(
        private storageKey: string,
        private defaultState: DataType,
        private encode: SubChannel<DataType>['encode'],
        private decode: SubChannel<DataType>['decode']
    ) {
        this.channel = channel.subChannel<DataType>(`${storageKey}__CHANNEL`, encode, decode)

        this.channel.subscribe(({ payload }) => {
            this._value = payload
            this.observable.update(payload)
        })

        const native = localStorage.getItem(storageKey)

        if (!native) {
            localStorage.setItem(
                storageKey,
                JSON.stringify({
                    value: defaultState,
                })
            )
            this._value = defaultState
        } else {
            try {
                const json = JSON.parse(native)
                const parsed = decode(json)
                if (!parsed) {
                    this._value = defaultState
                } else {
                    this._value = parsed
                }
            } catch (e) {
                this._value = defaultState
            }
        }
    }

    getValue(): DataType {
        return this._value
    }

    setValue(value: DataType) {
        this._value = value
        this.observable.update(value)
        this.channel.post(value)
        localStorage.setItem(
            this.storageKey,
            JSON.stringify({
                value,
            })
        )
    }

    subscribe = this.observable.subscribe.bind(this.observable)
}
