import React, { PureComponent } from 'react';
import pt from 'prop-types'

import IntersectionCard, { IIntersectionCard } from './IntersectionCard'
import { Flex, GlobalWidth } from '@/_yc'
import { EmptyBar } from '@/components/Controls'
import CreateCard from './CreateCard'
import CreationModal from './CreationModal'
import { NameError, NotEnough, MoreBloggers, EmptyName, NoInformation } from './CreationModal/Errors.js'
import DemoCard from "./demoData";
import cn from './Dashboards.module.scss'
import { history } from "@/_helpers/history";
import { INTERSECTIONS_CREATE, INTERSECTIONS_DEFAULT } from '@/routs';
import DeletionModal from './DeletionModal';
import { MoneyCounter } from './MoneyCounter';
import Loader from '@/components/Loader'
import { HelperWrapper } from './HelperWrapper'

const Center = {
	display: 'flex',
	justifyContent: 'center',
	padding: '20px 0px'
}

const { menu, counter, names, ...card } = IIntersectionCard;

class Dashboards extends PureComponent{

	static get propTypes(){
		return {
			t: pt.func.isRequired,
			width: pt.string.isRequired,
			deleteItem: pt.func.isRequired,
			addItem: pt.func.isRequired,
			money: pt.number,
			items: pt.shape({
				value: pt.arrayOf(pt.shape({ 
					bloggers: pt.arrayOf(pt.shape({
						avatar: pt.string.isRequired,
						username: pt.string.isRequired
					})).isRequired,
					...card
				})).isRequired,
				loaded: pt.bool.isRequired,
				error: pt.bool.isRequired
			}),
			create: pt.bool
		}
	}

	state = {
		renaming: undefined,
		error: null,
		deletion: null
	}

	createIntersection = () => {
		history.push(INTERSECTIONS_CREATE)
	}

	handleClose = () => {
		history.push(INTERSECTIONS_DEFAULT)
	}

	onRenamingCancel = () => {
		this.setState(prev => ({
			...prev,
			renaming: undefined
		}))
	}
	setRenaming = (renaming) => {
		this.setState({
			renaming
		})
	}

	deleteItem = (id) => {
		this.setState({
			deletion: { id }
		})
		// this.props.deleteItem(id);
	}

	onRenamed = (id, newName) => {
		this.props.renameItem(id, newName);
		this.onRenamingCancel();
	}

	deleteIntersection = (id) => {
		this.props.deleteItem(id);
		this.setState({
			deletion: null
		})
	}

	menu = [
		/*{ label: this.props.t("intersections.dashboards.card.menu.download"), cb: console.log},*/
		{ label: this.props.t("intersections.dashboards.card.menu.rename"), cb: this.setRenaming },
		{ label: this.props.t("intersections.dashboards.card.menu.delete"), color: "red-1", cb: this.deleteItem }
	]

	cancelDeletion = () => {
		this.setState({
			deletion: null
		})
	}

	handleSuccess = (name, bloggers) => {
		this.props.addItem(name, bloggers)
			.then(e => this.handleClose())
			.catch(err => {
				if (!err.response) return this.props.error();
				switch (err.response.status) {
					case 404:
						const nameMap = {};
						err.response.data.forEach(({ username }) => {
							nameMap[username] = true;
							this.setState({
								error: new NameError(nameMap)
							})
						})
						break;
					case 402: return this.setState({ error: new NotEnough() })
					case 503: 
						const excluded = {};
						err.response.data.excluded.forEach(({ username }) => {
							excluded[username] = true;
						})
						return this.setState({ error: new NoInformation(excluded) })
					case "more": return this.setState({ error: new MoreBloggers() })
					case "empty_name": return this.setState({ error: new EmptyName() })
					default: return this.props.error();
				}
			})
	}

	render(){
		const { t, items, money } = this.props;
		return(
			<>
				{this.state.deletion && <DeletionModal onDelete={this.deleteIntersection} intersectionId={this.state.deletion.id} onClose={this.cancelDeletion} />}
				<div className={cn.head}>
					<GlobalWidth>
						<HelperWrapper/>
						<MoneyCounter margin="14px 0 0" money={money} />
					</GlobalWidth>
				</div>
				<GlobalWidth className={cn.root} >
					{
						!items.loaded && 
						<Flex content="center" margin="20px 0 0">
							<Loader />
						</Flex>
					}
					{
						items.loaded && !items.error && 
						<div className = {cn.grid}>
							<CreateCard onClick = {this.createIntersection}/>
							{
								items.value.length > 0 ? 
								items.value.map((card, i) => (
									<IntersectionCard
										key={card.id}
										{...card}
										renaming={card.id === this.state.renaming}
										onRenamed={this.onRenamed}
										onCancel={this.onRenamingCancel}
										menu={this.menu}
									/>
								)) : 
								<IntersectionCard 
									name={t("intersections.dashboards.demo.title")}
									{...DemoCard}
								/>
							}
						</div>
					}
					{
						items.error && 
						<div style={Center}>
							<EmptyBar reason = {t("common_error")}/>
						</div>
					}
					{
						this.props.create && 
						<CreationModal 
							onClose = {this.handleClose} 
							onSuccess = {this.handleSuccess} 
							error = {this.state.error}
							state = {this.props.items}
						/>
					}
				</GlobalWidth>
			</>
		)
	}
}

export { Dashboards as default }