import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.05756 1.05496C6.90986 1.11849 6.78447 1.2291 6.71921 1.35349C6.66047 1.46543 5 10.6607 5 10.8741C5 11.0817 5.11227 11.2861 5.29068 11.4032L5.43807 11.5L6.60155 11.5102L7.76499 11.5203L7.74811 11.6685C7.73882 11.75 7.64893 13.3144 7.54834 15.145L7.36545 18.4734L7.4432 18.6326C7.63712 19.03 8.16936 19.1251 8.48403 18.8187C8.5622 18.7425 9.64506 16.7418 11.7898 12.7105C14.7456 7.15481 14.9804 6.70089 14.9952 6.51465C15.0087 6.34441 14.997 6.28981 14.9186 6.15712C14.8677 6.071 14.7661 5.96352 14.6928 5.91832C14.5606 5.83681 14.5488 5.836 13.3138 5.82565L12.0682 5.81521L12.5808 3.78524C12.9847 2.18577 13.0901 1.72003 13.0779 1.58905C13.0573 1.36728 12.9203 1.16792 12.7238 1.07353C12.5695 0.999479 12.5486 0.998916 9.87491 1.00029C7.5494 1.00148 7.16454 1.00894 7.05756 1.05496ZM11.5621 2.35382C11.5621 2.38309 11.3387 3.29315 11.0655 4.37615C10.7924 5.45915 10.5689 6.40777 10.5689 6.48415C10.5689 6.66314 10.6208 6.79605 10.7397 6.9222C10.9198 7.11326 10.9689 7.12016 12.1488 7.12016C13.065 7.12016 13.2327 7.1279 13.223 7.16976C13.2085 7.23241 8.88811 15.3503 8.87577 15.3381C8.87077 15.3331 8.92411 14.3103 8.99435 13.0652C9.12988 10.6623 9.12977 10.6506 8.96941 10.4484C8.92855 10.3969 8.83338 10.3235 8.75797 10.2853C8.63038 10.2208 8.54575 10.216 7.53759 10.216C6.69469 10.216 6.45427 10.2062 6.45417 10.172C6.45399 10.1202 7.83558 2.40912 7.85662 2.34453C7.86782 2.31009 8.27131 2.30056 9.71653 2.30056C11.3407 2.30056 11.5621 2.30696 11.5621 2.35382Z"
            fill="#A2AEC2"
        />
    </svg>
)
