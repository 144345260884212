import { IBlogger } from '@/components/MassAddToList/_model/types'
import { ICustomListReq } from '@/pages/Lists/model/types'
import useToken from '@/_helpers/Hooks/useToken'
import { RootStore } from '@/_helpers/StoreType'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getUserListReq } from '../Service/Templates'

interface BloggerListParams {
    listId: number | null
    page: number
}

const transformBlogger = (blogger: ICustomListReq) => {
    if (blogger.blogger) {
        return {
            id: blogger.blogger?.external_id,
            bloggerId: blogger.blogger?.id,
            channelId: undefined,
            externalId: blogger.blogger?.external_id,
            name: blogger.blogger?.username,
            avatar: blogger.blogger?.avatar,
            username: blogger.blogger?.username,
            hasEmail: blogger.blogger?.has_email,
        } as IBlogger
    }

    return {
        id: blogger.channel_id,
        bloggerId: undefined,
        channelId: blogger.channel_id,
        externalId: blogger.channel_id,
        name: blogger.channel?.title,
        avatar: blogger.channel?.avatar,
        username: blogger.channel?.title,
        hasEmail: blogger.channel?.has_email,
    } as IBlogger
}

export const useBloggerList = ({ listId, page }: BloggerListParams) => {
    const [loading, setLoading] = useState(false)
    const [bloggers, setBloggers] = useState<IBlogger[]>([])
    const [noMoreBloggers, setNoMoreBloggers] = useState(false)
    const [count, setCount] = useState(0)

    const excludedBloggers = useSelector((state: RootStore) => state.Outreach.excludedBloggers)
    const excludedChannels = useSelector((state: RootStore) => state.Outreach.excludedChannels)

    const token = useToken()

    useEffect(() => {
        if (!token || !listId) return

        setLoading(true)
        getUserListReq(token, listId, page).then((res) => {
            setLoading(false)
            setCount(res.count)
            setBloggers((bloggers) => [...bloggers, ...res.results.map(transformBlogger)])
            setNoMoreBloggers(res.next === null)
        })

        return () => {
            setLoading(false)
            setNoMoreBloggers(false)
        }
    }, [listId, page, setLoading, setNoMoreBloggers, token])

    // console.log(excludedBloggers, excludedChannels, 'excludedBloggers, excludedChannels')

    const filteredBloggers = useMemo(() => {
        return bloggers.filter((blogger) => {
            if (blogger.externalId) {
                return !excludedBloggers.includes(blogger.externalId)
            }

            if (blogger.channelId) {
                return !excludedChannels.includes(blogger.channelId)
            }

            return true
        })
    }, [excludedBloggers, excludedChannels, bloggers])

    return { loading, bloggers: filteredBloggers, noMoreBloggers, count }
}
