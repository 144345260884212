import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M18.7291 12.8811C18.3993 12.4611 18.4937 12.2743 18.7291 11.8989C18.7334 11.8946 21.456 8.10086 21.7365 6.81429L21.7382 6.81343C21.8776 6.34457 21.7382 6 21.0642 6H18.8337C18.2659 6 18.004 6.29571 17.8638 6.62657C17.8638 6.62657 16.7281 9.36771 15.1216 11.1446C14.6031 11.658 14.3634 11.8226 14.0803 11.8226C13.9409 11.8226 13.7241 11.658 13.7241 11.1891V6.81343C13.7241 6.25114 13.5652 6 13.0951 6H9.5879C9.23174 6 9.02008 6.26229 9.02008 6.50657C9.02008 7.03971 9.82336 7.16229 9.90666 8.66229V11.9169C9.90666 12.63 9.77831 12.7611 9.49355 12.7611C8.73532 12.7611 6.89501 10.0089 5.80442 6.85886C5.58426 6.24771 5.36921 6.00086 4.79714 6.00086H2.56581C1.92914 6.00086 1.80078 6.29657 1.80078 6.62743C1.80078 7.212 2.55901 10.1186 5.3267 13.9586C7.17127 16.5797 9.76896 18 12.132 18C13.5524 18 13.7258 17.6846 13.7258 17.142C13.7258 14.6374 13.5975 14.4009 14.309 14.4009C14.6388 14.4009 15.2066 14.5654 16.5326 15.8297C18.0482 17.3289 18.2973 18 19.1456 18H21.3761C22.0119 18 22.3341 17.6846 22.1488 17.0623C21.7246 15.7534 18.8583 13.0611 18.7291 12.8811Z"
            fill="#5D697D"
        />
    </svg>
)
