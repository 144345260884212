import { APIService } from '@/_helpers/APIService.js'
import { authHeader } from '@/_helpers/auth-header.js'

const AudInsService = new APIService({
	methods:{
		downloadList: set => ({
			method: "GET",
			url: "/api/sets/export/",
			headers: authHeader(),
			responseType: 'blob',
			params: { set, 'open_reports': true }
		})
	}
})

export { AudInsService as default }
export { AudInsService }