import { HistoricalMetric } from '@/Layouts/ReportLayout/Model/types'
import moment from 'moment'

const calculatePercentChange = (startValue: number, endValue: number, isPercents: boolean) => {
    const diff = endValue - startValue

    if (isPercents) {
        return diff
    }

    return startValue === 0 ? 0 : diff / startValue
}

const filterMetrics = (metrics: Array<HistoricalMetric>, startDate: string) => {
    return metrics.filter((metric) => {
        return new Date(metric.created_at) >= new Date(startDate)
    })
}

const isGraphDataEmpty = (graphData: number[]) => {
    const nonZeroes = graphData?.filter((d) => d !== 0)
    return nonZeroes.length < 2 || graphData.length < 2
}

const generateDataPlug = (dataLength: number) => {
    const plug = new Array(dataLength)
    plug[0] = 2

    for (let i = 1; i < dataLength; i++) {
        plug[i] = plug[i - 1] * 1.1
    }

    return plug
}

const generateCategoriesPlug = (dataLength: number) => {
    const startDate = new Date(1970)
    const endDate = new Date(1970)
    endDate.setDate(startDate.getDate() + dataLength)
    const plug = []

    for (let dt = startDate; dt < endDate; dt.setDate(dt.getDate() + 1)) {
        plug.push(moment(new Date(dt)).format())
    }

    return plug
}

const getGraphLocale = (loc?: string) => {
    if (!loc) return 'en'

    return loc.slice(0, 2) === 'ru' ? 'ru' : 'en'
}

export {
    calculatePercentChange,
    filterMetrics,
    isGraphDataEmpty,
    generateDataPlug,
    generateCategoriesPlug,
    getGraphLocale,
}
