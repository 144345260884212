import { IPost } from '@/pages/CampaignManager/types'
import React, { FC } from 'react'
import Post from './PostItem/PostItem'

import cn from './PostsGrid.module.scss'

interface IPostsGrid {
    posts: IPost[]
}

export const PostsGrid: FC<IPostsGrid> = ({ posts }) => {
    return (
        <div className={cn.postsGrid}>
            {posts.map((post, i) => (
                <Post {...post} index={i} key={post.channel_id} />
            ))}
        </div>
    )
}
