import { APIService } from '@/_helpers/APIService.js'
import { authHeader } from '@/_helpers/auth-header.js'

export default new APIService({
	methods: {
		getIntersections: (page) => ({
			url: "/api/intersections/list/",
			method: "GET",
			headers: authHeader(),
			params: { page }
		}),
		addIntersection: (name, bloggers) => ({
			url: "/api/intersections/new/",
			method: "POST",
			headers: authHeader(),
			data: { name, bloggers }
		}),
		getById: id => ({
			url: `/api/intersections/list/${id}/`,
			method: "GET",
			headers: authHeader()
		}),
		deleteIntersection: id => ({
			url: `/api/intersections/list/${id}/`,
			method: "DELETE",
			headers: authHeader()
		}),
		renameIntersection: (id, name) => ({
			url: `/api/intersections/list/${id}/`,
			method: "PUT",
			headers: authHeader(),
			data: { name }
		}),
		requirePdfIntersection: (id) => ({
			url: `/api/intersections/details/${id}/pdf/`,
			method: "POST",
			headers: authHeader()
		}),
		checkPdfTaskStatus: (id, task_id) => ({
			url: `/api/intersections/details/${id}/pdf/`,
			method: "GET",
			headers: authHeader(),
			params: { task_id }
		}),
		downloadPDF: (url) => ({
			url,
			method: "GET",
			responseType: 'arraybuffer',
			headers: authHeader()
		}),
		requireExcel: (intersection_id) => ({
			url: `/api/intersections/export/`,
			method: "GET",
			responseType: 'arraybuffer',
			headers: authHeader(),
			params: { intersection_id }
		})
	}
})