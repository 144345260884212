import Icons from '@/components/Icons'
import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import { IQuantityValue, ValueName, ValueType } from '../Tabs/Influencers/InfluencersTable/InfluencerItem/types'
import { IPost } from '@/pages/CampaignManager/types'

import cn from './ValueInput.module.scss'

interface IValueInput {
    value?: string | number | IQuantityValue
    onSubmit?: (bloggerData: any, post: IPost | undefined) => void
    valueName?: ValueName | 'reach' | 'price'
    valueType?: ValueType
    post?: IPost
}

export const ValueInput: FC<IValueInput> = ({ value, onSubmit, valueName, valueType, post }) => {
    const [isFocused, setIsFocused] = useState(false)
    const [inputVal, setInputValue] = useState(
        valueType === 'quantity' ? (value as IQuantityValue).planned || 0 : (value as string)
    )

    const edit = useCallback(() => {
        setIsFocused(true)
    }, [setIsFocused])

    const done = useCallback(() => {
        setIsFocused(false)
        onSubmit && inputVal && valueName && onSubmit({ [valueName]: inputVal }, post)
    }, [setIsFocused, inputVal, valueName, onSubmit, post])

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const re = /^[0-9\b]+$/

            if (e.target.value === '' || re.test(e.target.value)) {
                setInputValue(e.target.value)
            }
        },
        [setInputValue]
    )

    return (
        <div className={cn.priceInput}>
            <input
                value={
                    valueType === 'quantity'
                        ? `${!isFocused ? (value as IQuantityValue).actual + ' of ' + inputVal : inputVal}`
                        : inputVal
                }
                placeholder={'Enter'}
                type={'number'}
                onFocus={edit}
                onBlur={done}
                onChange={onChange}
            />
            {!isFocused && !value && !inputVal && (
                <div className={cn.editIcon}>
                    <Icons name={'edit'} />
                </div>
            )}

            {isFocused && (
                <div className={cn.doneButton} onClick={done}>
                    <Icons name={'done'} />
                </div>
            )}
            {isFocused && (
                <div className={cn.crossButton}>
                    <Icons name={'close--small'} />
                </div>
            )}
        </div>
    )
}
