import React from 'react';

import { shortNum } from '../../../../_helpers/_graphs/_tech'

import { info_board, inform } from './Blocks.module.scss'

export const InfoBoard = props => (
	<div 
		className = {info_board}
		style = {{ 
			width: props.width,
		}}
	>
		{props.content.map((item, index) => (
			<div 
				key={index} 
				className = {inform}
				style = {{
					marginBottom: (
						window.innerWidth <= 600 ? (
							props.content.length % 2 === 0 ? (
								index!==props.content.length-1 && index!==props.content.length-2 ? 
								40 : undefined
							):(
								index!==props.content.length-1 ? 
								40 : undefined
							)
						) : undefined
					)
				}}
			>
				<h3>{shortNum(item.value)}</h3>
				{item.label}
			</div>
		))}
	</div>
)