import React, { useState, useCallback, useEffect, useRef } from 'react';
import pt from 'prop-types'
import { ClickAwayListener } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import Text from '@/components/Text'

import cn from './RenamingInput.module.scss'


const RenamingInput = props => {
	const [value, setValue] = useState(props.initValue);
	const { t } = useTranslation();

	const onClick = useCallback((e) => {
		e.preventDefault();
		props.onSuccess(value)
	}, [props, value]);
	
	const ref = useRef(null)
	useEffect(() => {
		ref.current.focus();
	})
	return (
		<ClickAwayListener onClickAway = {props.onCancel}>
			<form className = {cn.root}>
				<Text fSize={18} component="input" ref = {ref} value = {value} onChange = {e => setValue(e.target.value)}/>
				<Button variant="primary" margin="1px 1px 1px 5px" type="submit" size = "small" onClick = {onClick}>
					{t("intersections.dashboards.card.save")}
				</Button>
			</form>
		</ClickAwayListener>
	)
}

RenamingInput.propTypes = {
	initValue: pt.string.isRequired,
	onSuccess: pt.func.isRequired,
	onCancel: pt.func.isRequired
}

export { RenamingInput as default }