import React from 'react'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import moment from 'moment'
import { GET_BRAND_MENTIONS } from '../../queries'
import { IBrands } from '../../types'
import { toQueryWithOR } from '../../utils/queries/toQueryWithOR'
import { SelectorWithOption } from '../../../../filters/SelectorWithOption'
import { preprocessBrands } from '../../utils/filters/prepocess'
import { PLATFORM } from '../consts'
import Icons from '@/components/Icons'

const getGqlFilter = (brands: string[], timeFrame: number | null, notCheck: boolean) => {
    if ((!brands || brands.length === 0) && !timeFrame) return ''

    const currDate = new Date()
    const frame = timeFrame || 180
    const date = frame ? moment(currDate.setDate(currDate.getDate() - frame)).format() : null
    const videoQuery = !notCheck && date ? `youtube_video : {published_at: { _gte: "${date}" }}` : ''
    const tempBrand = toQueryWithOR(brands, '', 'brand_id')
    const brand_ids = brands.length !== 0 ? tempBrand.substring(3, tempBrand.length - 1) : 'brand_id: {_is_null: false}'

    return `brand_mentions: { ${notCheck ? '_not' : '_and'}: {${brand_ids},${videoQuery}}}`
}

export const getBrandMentions = (fetcher: Fetcher) => {
    return new SelectorWithOption({
        platform: PLATFORM,
        locked: (features: string[]) => !features?.includes('brands_mentions_yt'),
        title: 'filters.brand.label',
        autocomplete: true,
        helper: 'filters.brand.text',
        icon: <Icons name="filter--brand" />,
        space: 1,
        isMulti: true,
        options: async (token: string) => {
            const client = fetcher.getClient()
            return await client
                .query<IBrands>({ query: GET_BRAND_MENTIONS })
                .then((res) => {
                    const brands = res.data?.youtube_brand
                    return preprocessBrands(brands)
                })
        },
        beforeSend: ({ value, timeFrame, notCheck }) => {
            return {
                name: 'filters.brand.label',
                type: 'array',
                value: value,
                gqlFilter: getGqlFilter(value, timeFrame, notCheck),
            }
        },
    })
}
