import React from 'react'
import styles from '@/pages/TargetAudience/Views/components/list/ItemList/item-list.module.scss'
import { Checkbox, Flex } from '@/_yc'
import Text from '@/components/Text'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ICampaignResponse } from '@/pages/TargetAudience/services/types'
import { ReactComponent as YolocoAndBeeline } from '@/pages/TargetAudience/icons/y&b.svg'
import { ReactComponent as Draft } from '@/pages/TargetAudience/icons/draft.svg'
import { ReactComponent as User } from '@/pages/TargetAudience/icons/users.svg'

interface TableRowProps {
    item: ICampaignResponse
}
const targetingType = {
    from_customer: 'Own base',
    from_us: 'Beeline & Yoloco',
}

const Index: React.FC<TableRowProps> = ({ item }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const [checked, setChecked] = React.useState(false)

    const handleClick = () => {
        localStorage.setItem('campaignId', item.id.toString())
        history.push('/target/new/one')
    }

    const renderIcon = () => {
        if (item.status === 'draft') {
            return <Draft />
        } else if (item.target_type === 'from_us') {
            return <YolocoAndBeeline />
        } else if (item.target_type === 'from_customer') {
            return <User />
        }
        return null
    }

    return (
        <Flex className={styles.row}>
            <div>
                <Checkbox value={checked} onChange={() => setChecked(!checked)} className={styles['checkbox']} />
            </div>
            <Flex>
                <Flex margin="0 10px 0 0">{renderIcon()}</Flex>
                <Flex column className={styles['name-wrapper']}>
                    <Text className={styles['name']}>{item.name}</Text>
                    <Text className={styles['sub-name']}>{item.description}</Text>
                </Flex>
            </Flex>
            <Flex>
                <Text fSize={'12px'} color={'gray-new-1'}>
                    {targetingType[item.target_type]}
                </Text>
            </Flex>
            <Flex>
                <Text fSize={'12px'} color={'gray-new-1'}>
                    {new Date(item.created_at).toLocaleDateString()}
                </Text>
            </Flex>
            <div className={styles.status}>
                <Flex className={item.status === 'active' ? styles['status-completed'] : styles['status-progress']}>
                    <Text fSize={'10px'} color={'gray-new-4'}>
                        {item.status}
                    </Text>
                </Flex>
            </div>
            <Flex content="end">
                <button onClick={handleClick} disabled={item.status === 'progress'} className={styles.actionButton}>
                    {t('target_audience.open')}
                </button>
            </Flex>
        </Flex>
    )
}

export default Index
