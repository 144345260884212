import React from 'react';
import PageLayout from '@/Layouts/PageLayout';

import Dashboards from './Dashboards'
import Intersection from './Intersection'

import cn from './Intersections.module.scss'
import { useTranslation } from 'react-i18next';

const Intersections = ({ width, intersection }) => {

	const { t } = useTranslation(); 

	return (
		<PageLayout title={t("titles.t3")} color={"common-background"} className = {cn.root} >
			{
				intersection === "all" 
				? <Dashboards width = {width}/> 
				: intersection === "create"
				? <Dashboards width = {width} create/> 
				: <Intersection id = {intersection}/>
			}
		</PageLayout>
	)
}

export { Intersections as default }