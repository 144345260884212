import React from 'react';

import { Mediator } from './Mediator.js'
import { Width } from '../helpers'

export const Container = props => (
	<div 
		className="container"
		style = {{ 
			display: 'flex',
			width: props.width, 
			flexDirection: props.direction==="col" ? "column" : undefined,
			justifyContent: props.direction!=="col" ? 'space-between': undefined
		}}
	>
		{props.content.map((item, index) => <Mediator key={index} hidden={props.hidden} item={item} width={Width(item.space, props.space)}/>)}
	</div>
)