import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import Text from '@/components/Text'

import cn from './AllChats.module.scss'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { EmptyAllChats } from './EmptyAllChats/EmptyAllChats'
import Icons from '@/components/Icons'
import { ChatItem } from './ChatItem/ChatItem'
import YolocoChatAvatar from '../AllChats/ChatItem/YolocoImg.svg'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import {
    addChats,
    clearUnread,
    getTutorialMessages,
    setCurrentChat,
    updateChats,
    updateSearchChats,
} from '../../Model/actions'
import useToken from '@/_helpers/Hooks/useToken'
import Loader from '@/components/Loader'
import Flex from '@/_yc/Flex'
import { ChatsFilter, ChatsFilterType } from './ChatsFilter/ChatsFilter'
import { useHistory } from 'react-router-dom'
import useUrlSearchParams from '@/pages/Auth/ResetPassword/useUrlSearchParams'
import { InternalChatData } from '../Chat/Chat'
import { getChatsReq, searchChatsReq } from '../../Service/Chats'
import { Platform } from '@/pages/Reports/types'
import InstrumentsPopUp from './InstrumentsPopUp'
// import { GetUserMailsReq } from '../../Service/Campaigns'
// import ApprovePopup from '@/components/ApprovePoup'
// import useOpenClose from '@/_helpers/useOpenClose'

interface AllChatsProps {}

export const AllChats: FC<AllChatsProps> = () => {
    const t = useSpaceTranslation('outreach.messenger.all_chats')
    const [searchValue, setSearchValue] = useState('')
    const curChat = useSelector((store: RootStore) => store.Outreach.currentChat)
    const curCampaign = useSelector((store: RootStore) => store.Outreach.currentCampaign)
    const tutorialMsgs = useSelector((store: RootStore) => store.Outreach.tutorialMessages)
    // const campaigns = useSelector((store: RootStore) => store.Outreach.advertisingCampaigns.results)
    const dispatch = useDispatch()
    const token = useToken()
    const chatsRef = useRef<HTMLDivElement>(null)
    // const [isOpen, open, closePopUp] = useOpenClose(false)

    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [noMoreChats, setNoMoreChats] = useState(false)

    const chats = useSelector((store: RootStore) => store.Outreach.chats)
    const searchChats = useSelector((store: RootStore) => store.Outreach.searchChats)

    const { params } = useUrlSearchParams(['filter'])
    const history = useHistory()

    const [chatsFilter, setChatsFilters] = useState<ChatsFilterType>((params.filter as ChatsFilterType) || 'all')

    useEffect(() => {
        dispatch(getTutorialMessages())
    }, [dispatch])

    const yolocoChat = [
        {
            campaignId: '',
            campaignName: '',
            chatId: 0,
            bloggerId: '',
            unreadCount: 0,
            avatar: YolocoChatAvatar,
            bloggerName: 'Yoloco Team',
            lastMessage:
                tutorialMsgs[tutorialMsgs.length - 1]?.text ||
                'I will show you how to create effective advertising campaigns. Is it interesting for you?',
            time: tutorialMsgs[tutorialMsgs.length - 1]?.time || '',
            isOnline: true,
            lastReadMessageId: 0,
            internalId: '',
            stats: {
                er: '',
                followers: 0,
            },
            platform: Platform.Instagram,
        },
    ]

    const allChats = useMemo(() => {
        let filtered = chats

        if (searchValue !== '') {
            filtered = chats.filter((chat) => searchChats.includes(chat.chatId))
        }

        if (searchValue !== '' || curCampaign !== '' || chatsFilter !== 'all') {
            return filtered
        }

        return filtered.concat(yolocoChat)
    }, [chats, yolocoChat, searchChats, searchValue, curCampaign, chatsFilter])

    //get users email and email type
    // const [emails, setEmails] = useState<Array<{ email: string; type: string }>>([])
    // useEffect(() => {
    //     if (!token) return
    //     GetUserMailsReq(token)
    //         .then((res) => {
    //             let emailstemp: Array<{ email: string; type: string }> = []
    //             res.results.forEach((em) => {
    //                 emailstemp.push({
    //                     email: em.email,
    //                     type: em.email_server_type,
    //                 })
    //             })
    //             // setEmails(emailstemp)
    //         })
    //         .catch((e) => {
    //             console.log(e)
    //         })
    // }, [token])

    const onSearchChange = useCallback(
        (value: string) => {
            if (!token) return
            setSearchValue(value)

            setLoading(true)
            searchChatsReq(token, value).then((data) => {
                dispatch(updateSearchChats(data.chats.map((chat) => chat.id)))
                setLoading(false)
            })
        },
        [setSearchValue, token, dispatch, setLoading]
    )

    useEffect(() => {
        if (!token || searchValue === '') return

        setLoading(true)
        searchChatsReq(token, searchValue).then((data) => {
            dispatch(updateSearchChats(data.chats.map((chat) => chat.id)))
            setLoading(false)
        })
    }, [searchValue, curCampaign, token, setLoading, dispatch])

    useEffect(() => {
        if (!token) return
        setLoading(true)

        const params = new URLSearchParams({ filter: chatsFilter })
        history.replace({ search: params.toString() })
        getChatsReq({ token, basisId: curCampaign, page, filter: chatsFilter }).then((data) => {
            if (page === 0) {
                dispatch(updateChats(data))
            } else {
                dispatch(addChats(data))
            }
            setLoading(false)

            if (data.length === 0) {
                setNoMoreChats(true)
            }
        })
    }, [dispatch, curCampaign, token, setLoading, page, setNoMoreChats, chatsFilter, history])

    useEffect(() => {
        // set empty chat if user chose all chats
        if (curCampaign !== '') {
            dispatch(setCurrentChat(-1))
        }

        dispatch(updateSearchChats([]))
        dispatch(updateChats([]))
        setPage(0)
        setNoMoreChats(false)
    }, [curCampaign, dispatch, setNoMoreChats, setPage])

    // const checkEmailType = (chatId: number) => {
    //     const CurCampaignId = chats.filter((ch) => ch.chatId === chatId)[0].campaignId
    //     const curCampaignTemp = campaigns.filter((c) => c.id === CurCampaignId)[0]

    //     const curAccount = curCampaignTemp.email_account
    //     if (curAccount === null || curAccount === undefined) {
    //         return false
    //     }
    //     const curType = emails.filter((e) => e.email === curAccount.email)

    //     return curType[0].type === 'gmail' ? true : false
    // }

    const onChatClick = (chatId: number) => {
        // const checkChat = checkEmailType(chatId)
        // if (!checkChat) {
        //     open()
        //     return
        // }

        dispatch(setCurrentChat(chatId))
        dispatch(clearUnread(chatId))
    }

    const onScroll = useCallback(() => {
        if (!chatsRef.current) {
            return
        }

        const { scrollTop, scrollHeight, clientHeight } = chatsRef.current
        if (scrollTop + clientHeight > scrollHeight - 200) {
            !loading && !noMoreChats && setPage((prev) => prev + 1)
        }
    }, [setPage, loading, noMoreChats])

    const onFilterChange = useCallback(
        (filter: ChatsFilterType) => {
            setChatsFilters(filter)
            dispatch(setCurrentChat(-1))
        },
        [setChatsFilters, dispatch]
    )

    return (
        <div className={cn.allChats}>
            <Flex className={cn.title} content={'space-between'}>
                <Text fSize={18} semibold>
                    {t('title')}
                </Text>
                <InstrumentsPopUp curCampaign={curCampaign} />
            </Flex>

            <ChatsFilter onClick={onFilterChange} chatsFilters={chatsFilter} />

            <div className={cn.search}>
                <Icons className={cn.searchIcon} name="search" />
                <input
                    className={cn.searchInput}
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => onSearchChange(e.target.value)}
                ></input>
            </div>

            <div className={cn.chats} ref={chatsRef} onScroll={onScroll}>
                {allChats.length === 0 && <EmptyAllChats />}
                {allChats.length > 0 &&
                    allChats.map((chat: InternalChatData) => (
                        <div onClick={() => onChatClick(chat.chatId)} key={chat.chatId}>
                            <ChatItem {...chat} active={curChat === chat.chatId} />
                        </div>
                    ))}
                {loading && (
                    <Flex margin="20px 0" align={'center'} content="center">
                        <Loader />
                    </Flex>
                )}
            </div>
            {/* <ApprovePopup
                isOpen={isOpen}
                close={closePopUp}
                approveFunction={closePopUp}
                title={t('approve.title')}
                subTitle={t('approve.text')}
                approve={t('approve.approve')}
            /> */}
        </div>
    )
}
