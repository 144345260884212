import React, { FC, useMemo } from 'react'

import useReportTranslation from '../useReportTranslation'
import { MultiBarChart } from '@yoloker/ui-collection'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'

export interface IBarChart {
    title: string
    width: string | number
    data?: Array<{
        label: string
        subLabel: string
        value: number
    }>
    hidden?: boolean
}

const BarChart: FC<IBarChart> = ({ title, width, data, hidden }) => {
    const size = useWindowWidth()
    const height = useMemo(() => {
        return size === 'mobile' ? '' : '310px'
    }, [size])

    const t = useReportTranslation(title)
    const preprocessedData = useMemo(() => {
        return data?.map((d) => ({
            label: d.label,
            items: [
                {
                    qty: 0,
                    share: d.value,
                    color: '#6071FF',
                },
            ],
        }))
    }, [data])

    return (
        <>
            {preprocessedData && (
                <MultiBarChart
                    size={'small'}
                    title={t('title')}
                    style={{ width, height }}
                    data={preprocessedData}
                    isDemo={hidden}
                    titleStyle={{ color: 'gray-4', fSize: 14 }}
                    tooltipText={t('helper')}
                />
            )}
        </>
    )
}

export default BarChart
