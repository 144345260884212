import React from 'react';

import { Mediator } from './Mediator.js'
import { Width } from '../helpers'

import { carousel } from './Blocks.module.scss'

export const Carousel = props => (
	<div 
		className = {carousel}
		style = {{ 
			width: props.width
		}}
	>
		{props.content.map((item, index) => <Mediator hidden={props.hidden} key={index} item={item} width={Width(item.space, props.space)}/>)}
	</div>
)