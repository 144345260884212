import React, { ReactElement } from 'react'
import Interests from '@/pages/TargetAudience/Views/components/Interests'
import EditMainCriteria from '@/pages/TargetAudience/Views/CreateReportStepFive/components/EditMainCriteria'
import AdditionalParams from '@/pages/TargetAudience/Views/CreateReportStepFive/components/AdditionalParams'
import { Option } from '@/pages/TargetAudience/Views/types'
import { Category } from '@/pages/TargetAudience/services/types'
import cn from '@/pages/TargetAudience/Views/CreateReportStepFive/step-five.module.scss'
import { Flex } from '@/_yc'

interface EditComponentRendererProps {
    type: string | null
    data: any
    selectedInterests: Category[]
    selectedParams: { [key: string]: Option[] }
    selectedAdditional: { [key: string]: Option[] }
    onEdit: (value: string | null) => void
    onInterestsChange: (newInterests: Category[]) => void
    onParamsChange: (data: { [key: string]: Option[] }) => void
    onAdditionalChange: (data: { [key: string]: Option[] }) => void
    hide?: boolean
}

const EditComponentRenderer: React.FC<EditComponentRendererProps> = ({
    type,
    data,
    selectedInterests,
    selectedParams,
    selectedAdditional,
    onEdit,
    onInterestsChange,
    onParamsChange,
    onAdditionalChange,
    hide,
}) => {
    const componentMap: { [key: string]: ReactElement | null } = {
        interests: (
            <Interests
                onInterestsChange={onInterestsChange}
                onEdit={() => onEdit(null)}
                initialSelectedOptions={selectedInterests}
                options={data as Category[]}
            />
        ),
        params: (
            <EditMainCriteria onChange={onParamsChange} onEdit={onEdit} options={data} initialState={selectedParams} />
        ),
        additional: (
            <AdditionalParams
                initialParams={selectedAdditional}
                onParamsChange={onAdditionalChange}
                onEdit={() => onEdit(null)}
                options={data}
            />
        ),
    }

    return hide ? null : <Flex className={cn['params-wrap']}>{componentMap[type || ''] || null} </Flex>
}

export default EditComponentRenderer
