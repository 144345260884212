import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.9375 14.7142L3.8125 10.5892L4.99083 9.41083L8.03583 12.4525L7.9375 12.3567L15.0083 5.28583L16.1867 6.46416L9.11583 13.5358L7.93833 14.7133L7.9375 14.7142Z"
            fill={props.fill || '#333333'}
        />
    </svg>
)
