import { useSpaceTranslation } from '@/_helpers'
import React from 'react'
import cn from './EmptyState.module.scss'

import Text from '@/components/Text'
import Icons from '@/components/Icons'
import Button from '@/components/Button'
import { Link } from 'react-router-dom'
import { OUTREACH } from '@/routs'

export const EmptyState = () => {
    const t = useSpaceTranslation('outreach.stats.table.empty')

    return (
        <div className={cn.emptyState}>
            <div className={cn.imgContainer}>
                <Icons name="outreach" className={cn.img}></Icons>
            </div>
            <Text fSize={20} margin="24px 0 0 0" semibold style={{ lineHeight: '24px' }}>
                {t('title')}
            </Text>
            <Text fSize={14} margin="8px 0 0 0" color="gray-3">
                {t('subtitle')}
            </Text>
            <Link to={`${OUTREACH}/templates`}>
                <Button variant="primary" margin="20px 0 0 0">
                    {t('create')}
                </Button>
            </Link>
        </div>
    )
}
