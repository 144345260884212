import React, { PureComponent } from 'react';
import pt from 'prop-types'

import { Success, Failure, UsedError } from './Notifaications'

export default (CheckService, getSearchParams) => Component => class withPromo extends PureComponent {
	static propTypes = {
		success: pt.func.isRequired,
		error: pt.func.isRequired,
		check: pt.func.isRequired
	}

	mounted = true;

	componentDidMount(){
		const params = getSearchParams();
		if (!params.promo) return this.props.check();
		CheckService(params.promo)
			.then(res => {
				console.log(res)
				this.props.success(<Success name={res.name} detail = {res.description} />, params.promo)
			})
			.catch((err) => {
				if (!this.mounted) return
				this.props.check()
				if (!err.response) return
				if (err.response.status === 404) {
					this.props.error(<Failure />)
				}else if (err.response.status === 400) {
					this.props.error(<UsedError />)
				}
			})
	}

	componentWillUnmount(){
		this.mounted = false
	}

	render(){
		return <Component {...this.props}/>
	}
}