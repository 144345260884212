import Text from '@/components/Text'
import { Platform } from '@/pages/Reports/types'
import Topics from '@/pages/SearchPage/Bloggers/Topics/Topics'
import { useSpaceTranslation } from '@/_helpers'
import { getShortString } from '@/_helpers/strings/getShortString'
import { Flex } from '@/_yc'
import React, { FC } from 'react'
import VerificationIcon from './VerificationIcon'
import cn from './MainInfo.module.scss'

export interface IMain {
    type?: Platform
    data: {
        name: string
        verified: boolean
        link: string
        topics: Array<string>
        username?: string
    }
}

const MainInfo: FC<IMain> = ({ data, type = Platform.Instagram }) => {
    const t = useSpaceTranslation('report_layout')

    return (
        <Flex content="flex-start" column className={cn.name}>
            <Flex align="center">
                <Text margin="0 10px 0 0" semibold className={cn.text}>
                    {getShortString(data.name, 26)}
                </Text>
                {data.verified && <VerificationIcon type={Platform.Vk} />}
            </Flex>

            <a target="_blank" rel="noopener noreferrer" href={data.link}>
                <Text className={cn.ViewAccount} semibold color="violet-1">
                    {data.username ? '@' + data.username : t('view_account')}
                </Text>
            </a>

            <Topics topics={data.topics} size={14} fSize={10} />
        </Flex>
    )
}

export default MainInfo
