import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.68333 16.1491C1.44956 16.1486 1.22672 16.0501 1.06916 15.8774C0.908699 15.7061 0.828965 15.4745 0.849995 15.2407L1.05416 12.9957L10.4858 3.56738L13.4333 6.51405L4.00416 15.9416L1.75916 16.1457C1.73333 16.1482 1.70749 16.1491 1.68333 16.1491ZM14.0217 5.92488L11.075 2.97821L12.8425 1.21071C12.9988 1.05423 13.2109 0.966309 13.4321 0.966309C13.6533 0.966309 13.8654 1.05423 14.0217 1.21071L15.7892 2.97821C15.9456 3.13452 16.0336 3.34662 16.0336 3.5678C16.0336 3.78897 15.9456 4.00107 15.7892 4.15738L14.0225 5.92405L14.0217 5.92488Z"
            fill="#8F8F8F"
        />
    </svg>
)
