import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import ContextMenu from './ContextMenu.js'
import { _actions } from './_actions.js'

const mapStateToProps = createSelector(
	store => store.ContextMenu_,
	(store) => ({ store })
)

const mapDispatchToProps = dispatch => ({
	addToLists(lists, blogger_id, blogger_name, type) {
		dispatch(_actions.addToLists(lists, blogger_id, blogger_name, type))
	}
})

const Component = connect(mapStateToProps, mapDispatchToProps)(ContextMenu)

export { Component as ContextMenu }