import { memo } from 'react'
import { withWidth } from '@material-ui/core'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import Page from './AboutPage.js'

const mapStateToProps = state => ({
	source: state.Commercial_
})

export default compose(
	withWidth("xs"),
	connect(mapStateToProps),
	memo
)(Page)