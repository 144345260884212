import { memo } from 'react';
import { compose } from 'recompose';

import RE from './Resend.js'
import withResend from './withResend.js'
import serv from '../Model/service.js'

export default compose(
	withResend(serv.resendEmail),
	memo
)(RE)