import { CalendarFilter } from '@/pages/SearchPage/SearchAdapter/filters/CalendarFilter'

const getGqlFilter = (date: string[]) => {
    if (!date || date.length === 0) return ''

    return `publishedAt: {gt: {date: "${date[0]}"}, lt: {date: "${date[1]}"}}`
}

export const getDateFilter = () => {
    const translationPrefix = 'ads_explorer.filters.post_metrics.date'

    return new CalendarFilter({
        platform: 'ads_yt',
        title: `${translationPrefix}.label`,
        helper: `${translationPrefix}.text`,
        space: 1,
        beforeSend: (value) => ({
            name: 'metrics.date',
            type: 'number',
            value: value,
            gqlFilter: getGqlFilter(value.dates),
        }),
    })
}
