import React from 'react'
import { useTranslation } from 'react-i18next'

import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'

import CreateNewList from '../../components/CreateNewList'

import cn from '../CatalogOfLists.module.scss'

const EmptyPage = () => {
    const { t } = useTranslation()

    const size = useWindowWidth('mobile')
    const isMobile = size === 'mobile'
    return (
        <>
            <Flex className={cn.empty} align="center" content="center" column>
                <Flex className={cn.wrap}>
                    <Icons name="lists" className={cn.icon} />
                </Flex>

                <p className={cn.title}>{t('aud_insides.create')}</p>
                <p className={cn['title-sub']}>{t('aud_insides.desc')}</p>

                {!isMobile && (
                    <>
                        <CreateNewList />
                    </>
                )}
            </Flex>
        </>
    )
}

export default EmptyPage
