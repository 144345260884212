function getParams(): { [key: string]: string }{
	const result: { [key: string]: string } = {}

	const params = new URLSearchParams(window.location.search);

	for (const item of params.entries()) {
		result[item[0]] = item[1]
	}

	return result
}

export default getParams;