export default {
	LOGIN_PAGE_REQUEST: "LOGIN_PAGE_REQUEST",
	LOGIN_PAGE_LOGIN_SUCCESS: "LOGIN_PAGE_LOGIN_SUCCESS",
	LOGIN_PAGE_LOGIN_ERROR: "LOGIN_PAGE_LOGIN_ERROR",

	LOGIN_PAGE_LOGOUT: "LOGIN_PAGE_LOGOUT",

	LOGIN_PAGE_USER_INFO_REQUESTED: "LOGIN_PAGE_USER_INFO_REQUESTED",
	LOGIN_PAGE_USER_INFO_SUCCESS: "LOGIN_PAGE_USER_INFO_SUCCESS",
	LOGIN_PAGE_USER_INFO_FAILURE: "LOGIN_PAGE_USER_INFO_FAILURE",
	LOGIN_PAGE_USER_INFO_UPDATE: "LOGIN_PAGE_USER_INFO_UPDATE",

	USER_DETAILS_SUCCESS: "USER_DETAILS_SUCCESS",
	USER_DETAILS_ERROR: "USER_DETAILS_ERROR",
	
	USER_PLATFORMS: "USER_PLATFORMS",
}