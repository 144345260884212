import Icons from '@/components/Icons'
import { useSpaceTranslation } from '@/_helpers'
import React, { FC, useCallback, useRef } from 'react'
import cn from './MessageArea.module.scss'

interface MessageAreaProps {
    messageContainerRef: React.RefObject<HTMLDivElement>
    onSendMessage: (message: string) => void
}

export const MessageArea: FC<MessageAreaProps> = (props) => {
    const t = useSpaceTranslation('outreach.messenger.chat')
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    const resizeTextArea = useCallback(
        (e: any) => {
            if (!textAreaRef.current || !props.messageContainerRef.current) return

            const val = e.target.value
            let numOfLines = Math.max(val.split('\n').length - 1, Math.floor(val.length / 51))
            if (e.keyCode === 13 && e.shiftKey) {
                numOfLines++
            }
            const height = Math.min(25 + numOfLines * 18, 108)
            textAreaRef.current.style.height = '25px'
            textAreaRef.current.style.height = `${height}px`
            props.messageContainerRef.current.style.height = `${445 - height}px`
        },
        [props.messageContainerRef]
    )

    const handleKeyDown = (e: any) => {
        if (!textAreaRef.current || !props.messageContainerRef.current) return

        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()

            sendMessage()
        }
        resizeTextArea(e)
    }

    const sendMessage = () => {
        if (!textAreaRef.current) return

        const message = textAreaRef.current.value

        if (message.trim() === '') return

        props.onSendMessage(message)

        textAreaRef.current.value = ''
    }

    return (
        <>
            <textarea
                className={cn.messageArea}
                placeholder={t('placeholder')}
                onKeyDown={handleKeyDown}
                onChange={resizeTextArea}
                ref={textAreaRef}
            ></textarea>
            <Icons className={cn.sendIcon} name="arrow-right" onClick={sendMessage} />
        </>
    )
}
