import { useEffect } from 'react'

import { history } from '../_helpers/history.js'
import { SEARCH, LOGIN } from '../routs.js'

const OAuth = props => {
	useEffect(() => {
		const params = props.getParams();
		props.OAuth(params)
			.then(res => {
				if(res.Token) {
					localStorage.setItem('user', JSON.stringify({ token: res.Token }));
					window.location.href = SEARCH;
				}
			})
			.catch(err => {
				props.error();
				history.push(LOGIN)
				console.error({ err })
			})
	})
	return null
}

export default OAuth;