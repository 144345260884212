import { createAction } from '@reduxjs/toolkit'
import { ICustomListInfo, INormalListData, FavoriteBloggers } from './types'

export const setLists = createAction<ICustomListInfo[]>('@AudienceInsights/Lists')
export const updateList = createAction<ICustomListInfo>('@AudienceInsights/ListsUpdate')
export const delList = createAction<number>('@AudienceInsights/ListDelete')
export const renameList = createAction<ICustomListInfo>('@AudienceInsights/ListRename')
export const setListsCount = createAction<number>('@AudienceInsights/ListsCount')
export const updateQTY = createAction<{ qty: number; id: number }>('@AudienceInsights/ListsUpdateQTY')

export const setRecentsCount = createAction<number>('@AudienceInsights/RecentsCount')

export const setActiveList = createAction<number>('@AudienceInsights/ActiveListId')

export const setBloggersAndChannels = createAction<INormalListData[]>('@AudienceInsights/BloggersAndChannels')
export const delBloggersAndChannels = createAction<number>('@AudienceInsights/BloggersAndChannelsDelete')
export const setBloggersAndChannelsDefault = createAction<INormalListData[]>(
    '@AudienceInsights/BloggersAndChannelsDefault'
)

export const setLikes = createAction<FavoriteBloggers[]>('@AudienceInsights/SetLikes')
export const delLikes = createAction<number>('@AudienceInsights/delLikes')
export const setLikesDefault = createAction<FavoriteBloggers[]>('@AudienceInsights/setLikesDefault')

export const setListLockedCount = createAction<number>('@AudienceInsights/ListLockedCount')
