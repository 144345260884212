import React, { useCallback, useMemo, FC, useState, useEffect } from 'react'
import Text from '@/components/Text'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import cn from './SendToMailingList.module.scss'
import Icons from '@/components/Icons'
import useOpenClose from '@/_helpers/useOpenClose'
import Popup from '@/_yc/Popup/Popup'
import Portal from '@/_helpers/Portal'
import Flex from '@/_yc/Flex'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import { MailingListItem } from './MailingListItem/MailingListItem'
import Button from '@/components/Button'
import { useHistory } from 'react-router-dom'
import { OUTREACH } from '@/routs'
import { SetBloggersMailingList } from '@/components/MassAddToList/_model/actions'
import { Campaigns } from './Campaigns'

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 500,
    zIndex: 2000,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}
interface ISendToMailingList {
    independentBTN?: boolean
    addType: 'new' | 'add'
}

export const SendToMailingList: FC<ISendToMailingList> = ({ independentBTN = true, addType }) => {
    const t = useSpaceTranslation('aud_insides.send_to_mail')
    const dispatch = useDispatch()
    const [isOpen, open, close] = useOpenClose()
    const createNew = addType === 'new'

    const instagramList = useSelector((store: RootStore) => store.MassAddToList.dataINST)
    const ytList = useSelector((store: RootStore) => store.MassAddToList.dataYT)
    console.log(instagramList, ytList)
    const history = useHistory()

    const mailingListItems = useMemo(() => {
        return instagramList.concat(ytList)
    }, [instagramList, ytList])

    const saveBloggersWithEmail = useCallback(() => {
        dispatch(
            SetBloggersMailingList({
                ytList: ytList.filter((item) => item.hasEmail),
                instList: instagramList.filter((item) => item.hasEmail),
                setId: [],
            })
        )
        history.push(`${OUTREACH}/templates`)
    }, [instagramList, ytList, dispatch, history])

    const noEmailCount = useMemo(() => {
        const instCount = instagramList.filter((item) => !item.hasEmail).length
        const ytCount = ytList.filter((item) => !item.hasEmail).length

        return instCount + ytCount
    }, [instagramList, ytList])

    const [state, setState] = useState<'bloggers' | 'camp'>('bloggers')
    const handleSubmit = useCallback(() => {
        if (createNew) saveBloggersWithEmail()
        else {
            dispatch(
                SetBloggersMailingList({
                    ytList: ytList.filter((item) => item.hasEmail),
                    instList: instagramList.filter((item) => item.hasEmail),
                    setId: [],
                })
            )
            setState('camp')
        }
    }, [createNew, saveBloggersWithEmail, instagramList, ytList, dispatch, setState])

    useEffect(() => {
        //to skip first popup when noEmailCount === 0
        if (!createNew && noEmailCount === 0) handleSubmit()
    }, [noEmailCount, handleSubmit, createNew])

    useEffect(() => {
        if (!isOpen) setState('bloggers')
    }, [isOpen])
    return (
        <>
            {independentBTN ? (
                <div className={cn.sendBtn} onClick={open}>
                    <Icons name="mail"></Icons>
                    <Text color="gray-2" fSize={10} semibold margin="0 0 0 6px">
                        {t('button_text')}
                    </Text>
                </div>
            ) : (
                <>
                    <Flex onClick={open} className={cn.newBTN}>
                        <p>{t(addType)}</p>
                    </Flex>
                </>
            )}

            {isOpen && (
                <Portal>
                    <div className={cn.root}>
                        <Popup style={style} isOpen={isOpen} onClose={close}>
                            <div className={cn.popup} style={state === 'camp' ? { height: '400px' } : {}}>
                                {state === 'bloggers' ? (
                                    <div>
                                        <Flex content="space-between" align="center" margin="0 0 20px 0">
                                            <Text fSize={20}>{t('title')}</Text>
                                            <div onClick={close}>
                                                <Icons name="close" className={cn.close}></Icons>
                                            </div>
                                        </Flex>

                                        <div>
                                            <Text fSize={14} color="gray-3" margin="10px 0 10px 0" component="span">
                                                {t('subtitle')}
                                            </Text>
                                            <span>
                                                <Icons name="mail" margin="0 0 0 6px"></Icons>
                                            </span>
                                        </div>

                                        <div className={cn.info}>
                                            <div className={cn.infoSelected}>
                                                <Text color="violet-1" fSize={12} semibold>
                                                    {`${mailingListItems.length} ${t('influencers')}`}
                                                </Text>
                                                <Text color="violet-1" fSize={10}>
                                                    {t('selected')}
                                                </Text>
                                            </div>
                                            <div className={cn.infoEmail}>
                                                {/* add email calculation here */}
                                                <Text color="red-2" fSize={12} semibold>{`${noEmailCount} ${t(
                                                    'influencers'
                                                )}`}</Text>
                                                <Text color="red-2" fSize={10}>
                                                    {t('not_specified')}
                                                </Text>
                                            </div>
                                        </div>

                                        <div className={cn.mailingListItems}>
                                            {mailingListItems.map((item) => (
                                                <MailingListItem email={item.hasEmail} {...item} />
                                            ))}
                                        </div>

                                        <Flex content="flex-end" align="center" margin="20px 0 0 0">
                                            <Button
                                                variant="primary"
                                                disabled={noEmailCount === mailingListItems.length}
                                                onClick={handleSubmit}
                                            >
                                                <span>{createNew ? t('btn') : t('choose')}</span>
                                                <span>
                                                    <Icons name="arrow-right" className={cn.arrowRight}></Icons>
                                                </span>
                                            </Button>
                                        </Flex>
                                    </div>
                                ) : (
                                    <>
                                        <Campaigns close={close} />
                                    </>
                                )}
                            </div>
                        </Popup>
                    </div>
                </Portal>
            )}
        </>
    )
}
