import { useCallback, useEffect, useState, useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'

import useToken from '@/_helpers/Hooks/useToken'

import { GetListsReq } from '../model/service'
import { setLists } from '../model/actions'

const PaginatedFetchLists = (tag?: string) => {
    const token = useToken()
    const dispatch = useDispatch()

    const [status, setStatus] = useState<{ loading: boolean; error: boolean }>({ loading: true, error: false })
    const [nextPage, setNextPage] = useState<string | null>(null)
    const [page, setPage] = useState<number>(1)

    const getLists = useCallback(() => {
        if (!token) return
        setStatus({ loading: true, error: false })

        GetListsReq(token, page, tag)
            .then((res) => {
                dispatch(setLists(res.results.filter((l) => l.is_likes !== true)))
                setStatus({ loading: false, error: false })
                setNextPage(res.next)
            })
            .catch(() => {
                setStatus({ loading: false, error: true })
            })
    }, [token, dispatch, page, tag])

    useLayoutEffect(() => {
        setPage(1)
        dispatch(setLists([]))
    }, [tag, dispatch])

    useEffect(() => {
        getLists()
    }, [page, getLists, tag])

    //pagination logic
    const handleScroll = useCallback(() => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

        if (bottom && nextPage && !status.loading) {
            //change page so request will run from useEffect
            setPage(page + 1)
        }
    }, [nextPage, page, status])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true,
        })

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [handleScroll])

    //scroll for 4k displays
    const listElementsContainer = document.getElementById('listItemsID')
    useEffect(() => {
        function scrollTrigger(this: HTMLDivElement) {
            // // @ts-ignore
            var el = this
            var sc = el.scrollHeight - el.clientHeight - el.scrollTop

            if (sc < 1) {
                if (nextPage && !status.loading) {
                    //change page so request will run from useEffect
                    setPage(page + 1)
                }
            }
        }
        if (!listElementsContainer) return
        listElementsContainer.addEventListener('scroll', scrollTrigger, {
            passive: true,
        })

        return () => {
            listElementsContainer.removeEventListener('scroll', scrollTrigger)
        }
    }, [listElementsContainer, nextPage, status, page])

    return status
}
export default PaginatedFetchLists
