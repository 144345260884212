import React, { FC, useCallback, useContext, useState } from 'react'
import Text from '@/components/Text'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import cn from './SendToMediaPlan.module.scss'
import Icons from '@/components/Icons'
import Flex from '@/_yc/Flex'
import Button from '@/components/Button'
import Loader from '@/components/Loader'
import { CampaignManagerContext } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerContext'
import { useQuery } from '@apollo/client'
import { GET_MEDIA_PLANS_LIST } from '@/pages/MediaPlanning/utils/queries'
import { MediaPlansListRaw } from '@/pages/MediaPlanning/utils/types'

interface ISendToMailingList {
    close: VoidFunction
    onSubmit: (id: number) => void
}

export const Campaigns: FC<ISendToMailingList> = ({ close, onSubmit }) => {
    const t = useSpaceTranslation('aud_insides.send_to_media_plan')

    const [chosenCamp, setChosenCamp] = useState<number>(-1)

    const context = useContext(CampaignManagerContext)
    const { data, loading } = useQuery<MediaPlansListRaw>(GET_MEDIA_PLANS_LIST, {
        context: context,
    })

    const choseCamp = (id: number) => {
        setChosenCamp(id)
    }

    const submit = useCallback(() => {
        onSubmit(chosenCamp)
        close()
    }, [chosenCamp, close, onSubmit])

    return (
        <>
            <Flex content="space-between" align="center" margin="0 0 20px 0">
                <Text fSize={20}>{t('title_campaigns')}</Text>
                <div onClick={close}>
                    <Icons name="close" className={cn.close}></Icons>
                </div>
            </Flex>

            <Flex
                column
                className={cn['campaign--block']}
                align={loading ? 'center' : ''}
                content={loading ? 'center' : ''}
            >
                {loading && <Loader />}
                {data &&
                    data.mediaPlanOrder.map((item) => (
                        <>
                            <Flex column className={cn.campaign} content="center" onClick={() => choseCamp(item.id)}>
                                <Flex content="space-between">
                                    <Flex column>
                                        <p className={cn['campaign--text']}>{item.name}</p>
                                    </Flex>
                                    {item.id === chosenCamp && <Icons name="done" />}
                                </Flex>
                            </Flex>
                            <div className={cn['divider']} />
                        </>
                    ))}
            </Flex>

            <div className={cn['divider--big']} />
            <Flex content="flex-end" align="center" margin="20px 0 0 0">
                <Button
                    variant="primary"
                    disabled={chosenCamp === -1}
                    onClick={() => {
                        submit()
                    }}
                >
                    <span>{t('add_1')}</span>
                    <span>
                        <Icons name="arrow-right" className={cn.arrowRight}></Icons>
                    </span>
                </Button>
            </Flex>
        </>
    )
}
