import React, { FC } from 'react'
import cn from './DataChange.module.scss'

export interface IDataChange {
    changeValue: number
    isEmptyData: boolean
}

const DataChange: FC<IDataChange> = ({ changeValue, isEmptyData }) => {
    const sign = changeValue >= 0 ? '+' : ''
    const percentValue = `${sign}${(changeValue * 100).toFixed(2)}%`
    const negativeClass = changeValue < 0 ? cn.negative : ''
    const emptyClass = isEmptyData ? cn['blur--active'] : cn['blur--default']

    return <div className={`${cn.dataChange} ${negativeClass} ${emptyClass}`}>{percentValue}</div>
}

export default DataChange
