import Observable from '@/_helpers/Observable'
import SubChannel from './SubChannel'

type basicTypes = string | number | boolean | undefined

export interface Payload {
    [key: string]: any
}

export interface Message {
    type: string
    time: number
    to?: string
    payload?: Payload
}

export default class Channel {
    private subs = new Observable<Message>()

    constructor(private transmitionKey: string) {
        window.addEventListener('storage', this.reciveMessage)
    }

    reciveMessage = (e: StorageEvent) => {
        if (e.key !== this.transmitionKey) return

        if (e.newValue) {
            try {
                const json: Message = JSON.parse(e.newValue)
                this.subs.update(json)
            } catch (e) {
                console.error(e)
            }
        }
    }

    postMessage(type: string, payload?: Payload, to?: string) {
        localStorage.setItem(
            this.transmitionKey,
            JSON.stringify({
                type,
                payload,
                to,
                time: Date.now(),
            })
        )
    }

    subscribe = this.subs.subscribe.bind(this.subs)

    /**
     *
     * @param type observable message type
     * @param encode function that encode message
     * @param decode function that decode message and guaranty type safety
     */
    subChannel<PayloadType>(
        type: string,
        encode: SubChannel<PayloadType>['encode'],
        decode: SubChannel<PayloadType>['decode']
    ): SubChannel<PayloadType> {
        return new SubChannel<PayloadType>(this, type, encode, decode)
    }
}
