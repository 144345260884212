import { useMemo } from 'react'

import usePromiseCall from '../usePromiseCall'

import useToken from './useToken'

type Return<DataType> = [boolean, DataType | null, boolean, VoidFunction]

function useAuthCall<DataType>(
    call: (token: string) => Promise<DataType>
): Return<DataType>
function useAuthCall<DataType, Argument extends { token: string }>(
    call: (data: Argument) => Promise<DataType>,
    argument: Omit<Argument, 'token'>
): Return<DataType>
function useAuthCall<
    DataType,
    Argument extends { token: string } | void = void
>(
    call: (data: Argument | string) => Promise<DataType>,
    argument?: Argument extends Record<string, any>
        ? Omit<Argument, 'token'>
        : void
): Return<DataType> {
    const token = useToken()

    const arg = useMemo<
        Argument extends Record<string, any> ? Argument : string
    >(() => {
        if (typeof argument === 'object') {
            return {
                ...argument,
                token: token || 'ANAUTHORIZAED',
            } as any
        } else {
            return token || ('ANAUTHORIZAED' as any)
        }
    }, [argument, token])

    const result = usePromiseCall<DataType, Argument>(call, arg as any)

    return result
}

export default useAuthCall
