import React, { FC, useMemo } from 'react'

import ReportLayout from '@/Layouts/ReportLayout/ReportLayout'
import { useQuery } from '@apollo/client'
import { useSpaceTranslation } from '@/_helpers'
import { setId, setInfo, setRawChannelData, setRawVideosData } from '@/Layouts/ReportLayout/Model/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { GET_REPORT_DATA, GET_VIDEOS_DATA } from './reportQueries'
import useChannelId from '@/_helpers/useChannelId'
import { RootStore } from '@/_helpers/StoreType'
import { ChannelMetrics, IGetChannelData, IGetVideosData, Topic } from '@/Layouts/ReportLayout/Model/types'
import { isNotEnoughReportsError } from '@/pages/Reports/OpenReportPage/utils'
import { push } from '@/components/Notifier/PushNotification'
import LegacyNotification from '@/components/Notifier/MessageTypes/LegacyNotification'
import { history } from '@/_helpers'

export interface IChannelReport {}

const ChannelReport: FC<IChannelReport> = () => {
    const t = useSpaceTranslation('channel_report')
    const channelId = useChannelId()
    const dispatch = useDispatch()
    dispatch(setId(channelId))

    const channelData = useSelector((store: RootStore) => {
        return store.report.rawChannelData
    })

    const videosDataStored = useSelector((store: RootStore) => {
        return store.report.rawVideosData
    })

    const { loading, data, error } = useQuery<IGetChannelData>(GET_REPORT_DATA, {
        variables: {
            id: channelId,
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        skip: channelData.getChannel !== undefined,
    })

    const {
        loading: loadingVideos,
        data: videosData,
        error: videosError,
    } = useQuery<IGetVideosData>(GET_VIDEOS_DATA, {
        variables: {
            id: channelId,
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        skip: videosDataStored.getVideos !== undefined,
    })

    useEffect(() => {
        const isNotEnoughReports = error && isNotEnoughReportsError(error)
        if (isNotEnoughReports) {
            push(new LegacyNotification(t('not_enough_reports'), 'error'))
            history.push('/billing')
        }
    }, [error, t])

    useEffect(() => {
        const isReady = !loading && !error
        isReady && data?.getChannel && dispatch(setRawChannelData(data.getChannel))
    }, [data, loading, error, dispatch])

    useEffect(() => {
        const isReady = !loadingVideos && !videosError
        isReady && videosData?.getVideos && dispatch(setRawVideosData(videosData.getVideos))
    })

    const getErViews = (metrics: ChannelMetrics) => {
        const er_views = metrics.er_views
        return `${(er_views * 100).toFixed(2)}%`
    }
    const getErFol = (metrics: ChannelMetrics) => {
        const er_fol = metrics.er_followers
        return `${(er_fol * 100).toFixed(2)}%`
    }

    const getChannelTopics = (topics: Topic[]) => {
        const generalTopics = topics?.map((topic) => topic.youtube_channeltopic?.youtube_channeltopicgeneral_topics)
        const preprocessedTopics =
            generalTopics && generalTopics.map((topic) => topic.map((t) => t.youtube_channeltopicgeneral.name))
        return [...new Set(preprocessedTopics.flat())]
    }

    const preprocessedData = useMemo(() => {
        if (!channelData.getChannel) return undefined

        const getChannelData = channelData.getChannel.data
        const metrics = getChannelData?.youtube_channel[0]

        const erPercentsViews = getErViews(metrics)
        const erPercentsFol = getErFol(metrics)

        return {
            id: metrics.id,
            lang_id: metrics.lang_id,
            avatar: metrics.avatar,
            title: metrics.title,
            er_followers: metrics.er_followers,
            er_views: metrics.er_views,
            email: metrics.email,
            engagement: metrics.engagement,
            average_video_watch: metrics.average_video_watch,
            subscriber_count: metrics.subscriber_count,
            metrics: [
                {
                    value: metrics.subscriber_count,
                    name: t('metrics.sub_count'),
                },
                {
                    value: metrics.average_video_watch,
                    name: t('metrics.avg_views'),
                },
                {
                    value: metrics.engagement,
                    name: t('metrics.avg_eng'),
                },
                {
                    value: erPercentsViews,
                    name: t('metrics.er_v'),
                },
                {
                    value: erPercentsFol,
                    name: t('metrics.er_f'),
                },
            ],
            country: metrics.channel_country?.name || '',
            topics: getChannelTopics(metrics.channel_topics),
        }
    }, [channelData.getChannel, t])

    useEffect(() => {
        dispatch(setInfo(preprocessedData))
    }, [channelData.getChannel, preprocessedData, dispatch])

    return <ReportLayout title={t('title')} error={!!error} loading={loading} platform={'YouTube'} />
}

export default ChannelReport
