import React, { memo, ComponentType } from 'react'
import { Link } from 'react-router-dom'
import * as Routs from '@/routs.js'

import { Icon } from '../Types'
import { MessageType } from './Types'
import Text from '@/components/Text'
import CircleLoader from '@/components/CircleLoader'

export type LegacyNotificationProps = { content: any }

export default class LegacyNotification implements MessageType<LegacyNotificationProps> {
    props: LegacyNotificationProps

    /**
     *
     * @param content Notification content
     * @param icon Icon for sticker("info" | "ok" | "error")
     */
    constructor(content: any, public icon: Icon) {
        this.props = { content }
    }
    Component: ComponentType<{ withSublabel?: boolean }> = memo(({ withSublabel }) => {
        if (this.icon === 'info') return <CircleLoader />

        return this.props.content.length > 1 &&
            this.props.content[1].hasOwnProperty('listName') &&
            this.props.content[1].hasOwnProperty('listID') ? (
            <Text fSize={14}>
                {this.props.content[0]}

                <Link to={`${Routs.LISTS}/${this.props.content[1].listID}`}>{this.props.content[1].listName}</Link>
            </Text>
        ) : (
            <Text fSize={14}>{this.props.content}</Text>
        )
    })
}
