import React, { FC } from 'react'

import { useSpaceTranslation } from '@/_helpers'
import Flex from '@/_yc/Flex'

import cn from '../DetailedInfo.module.scss'

interface IBar {
    stateCounter: number
    maxCount: number | undefined
}

const Bar: FC<IBar> = ({ stateCounter, maxCount }) => {
    const t = useSpaceTranslation('detailed_info')
    const width = maxCount === undefined ? '0%' : (100 / maxCount) * (stateCounter + 1) + '%'

    const counter = () => {
        if (maxCount === 6 && stateCounter === 5) return '6/6'
        if (maxCount === 3 && stateCounter === 2) return '3/3'
        if (maxCount) {
            return `${stateCounter + 2}/${maxCount}`
        }
        if (stateCounter + 2 === 7) return 6
        return stateCounter + 2
    }
    return (
        <>
            <Flex column>
                <p className={cn['bar--title']}>
                    {t('question')} {counter()}
                </p>
                <div className={cn['bar--gray']}>
                    <div className={cn['bar--blue']} style={{ width: width }} />
                </div>
            </Flex>
        </>
    )
}

export default Bar
