import React from 'react'
import PT from 'prop-types'

import DownArrow from '@material-ui/icons/KeyboardArrowDown'
import cn from './Select.module.scss'
import Popup from '../Popup'

export class Select extends React.PureComponent {
    static get defaultProps() {
        return {
            options: [],
            className: 'select',
        }
    }

    static get propTypes() {
        return {
            onOpen: PT.func,
            onClose: PT.func,
            options: PT.array,
            className: PT.string,
            label: PT.string,
            onChange: PT.func.isRequired,
            disabled: PT.bool,
            style: PT.object,
        }
    }

    state = {
        open: false,
        disabled: false,
        prevProps: {
            disabled: this.props.disabled,
        },
    }

    block = React.createRef()

    handleClick(val) {
        if (this.props.disabled) return
        if (!val) {
            this.setState({ open: val }, (e) => {
                if (this.props.onClose) this.props.onClose()
                setTimeout((e) => this.setState({ disabled: false }), 0)
            })
            return
        }
        if (this.props.onOpen) this.props.onOpen()
        this.setState({ open: val, disabled: true })
    }
    handleChange = (value) => {
        this.handleClick(false)
        this.props.onChange(value)
    }
    componentDidUpdate(prevProps) {
        if (prevProps.disabled !== this.props.disabled && !prevProps.disabled) {
            this.setState({ open: false, disabled: false })
            if (this.props.onClose) this.props.onClose()
        }
    }
    render() {
        const className = `${this.state.open ? `${cn['btn--focused']}` : ''} ${
            this.props.disabled ? cn['btn--disabled'] : cn['btn--active']
        }`
        return (
            <>
                <div
                    ref={this.block}
                    onClick={
                        !this.state.disabled && !this.props.disabled
                            ? (e) => this.handleClick(!this.state.open)
                            : undefined
                    }
                    className={className}
                    style={this.props.style}
                >
                    {this.props.value ? (
                        this.props.value.label
                    ) : (
                        <span style={{ color: '#888' }}>
                            {this.props.label}
                        </span>
                    )}
                    <DownArrow
                        className={`${cn.arrow} ${
                            this.state.open ? cn.arrow_up : cn.arrow_down
                        }`}
                    />
                </div>
                <Popup
                    onClose={(e) => this.handleClick(false)}
                    anchor={this.block.current}
                    style={{
                        marginTop: 6,
                        ...this.props.popperStyle,
                    }}
                    isOpen={this.state.open && !this.props.disabled}
                >
                    <div
                        style={{
                            width: this.block.current
                                ? window.getComputedStyle(this.block.current)
                                      .width
                                : 'auto',
                        }}
                        className={`${cn.root} ${
                            (this.props.classes &&
                                this.props.classes.options) ||
                            ''
                        }`}
                    >
                        {this.props.options.map((item, index) => {
                            if (
                                this.props.value &&
                                item.value.value === this.props.value.value
                            )
                                return null
                            return (
                                <div
                                    key={index}
                                    className={cn.option}
                                    onClick={(e) => this.handleChange(item)}
                                >
                                    {item.label}
                                </div>
                            )
                        })}
                    </div>
                </Popup>
            </>
        )
    }
}
