import { gql } from '@apollo/client'

export const UPDATE_NAME = gql`
    mutation UpdateMediaPlan($orderId: Int!, $name: String) {
        updateMediaPlanOrder(orderId: $orderId, bloggers: [], name: $name, bloggersType: INSTAGRAM) {
            mediaPlanOrder {
                name
                id
            }
        }
    }
`
