import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Platform } from '@/pages/Reports/types'
import { RootStore } from '@/_helpers/StoreType'
import Flex from '@/_yc/Flex'
import { shortNum } from '@/_helpers/_graphs/_tech'
import Topics from '@/pages/SearchPage/Bloggers/Topics/Topics'
import BloggerLink from '@/components/Share/BloggerLink'
import Button from '@/components/Button'
import { TARIFFS } from '@/routs'
import InstaLink from '@/components/Share/InstaLink'
import YTLink from '@/components/Share/YTLink/YTLink'
import Avatar from '@/components/Avatar'
import Icons from '@/components/Icons'
import { MassAddCheckBoxNEW } from '@/components/MassAddToList'
import useToken from '@/_helpers/Hooks/useToken'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import useOpenClose from '@/_helpers/useOpenClose'
import { Comment, NewComment } from '@/components/Comment/Comment'
import { GetLevel as YoScoreLevels } from '@/pages/Reports/BloggerReport/BloggerRating/BloggerLevel'
import { Like } from '@/components/Like/Like'
import { ExternalLink } from '@/components/Share/ExternalLink/ExternalLink'

import { INormalListData } from '../../../model/types'
import LoadBar from '../LoadBar/LoadBar'
import { AddCommentToBloggerReq, CreateCommentToBloggerReq } from '@/pages/Lists/model/service'

import cn from './ListItem.module.scss'

interface IListItem {
    item: INormalListData
    index: number
    grid: string
    activeListId: number
}

const ListItem: FC<IListItem> = ({ grid, item, index, activeListId }) => {
    const { t } = useTranslation()
    const token = useToken()
    const [isOpen, open, close] = useOpenClose()

    const isPromo = useSelector((store: RootStore) => store.authentication.user?.profile?.isPromo)
    const isDemo = isPromo && item.type === Platform.Instagram && !item.unlocked

    //comment
    const [currentComment, setCurrentComment] = useState<string>(item.comment?.comment || '')

    const handleOpen = (e: any) => {
        e.preventDefault()
        open()
    }

    const addComment = (valueComment: string) => {
        if (!token) return
        if (item.comment?.comment_id)
            AddCommentToBloggerReq(token, valueComment, item.external_id, item.comment.comment_id).then(() => {
                setCurrentComment(valueComment)
            })
        else
            CreateCommentToBloggerReq(token, valueComment, item.external_id).then(() => {
                setCurrentComment(valueComment)
            })
        close()
    }
    const size = useWindowWidth('mobile')
    const isMobile = size === 'mobile'

    return (
        <>
            <div style={{ display: 'grid', gridTemplateColumns: grid }} className={cn.item}>
                <Flex align="center" margin="0 10px 0 0" className={cn['hide-mobile']}>
                    <MassAddCheckBoxNEW
                        id={item.id ? item.id.toString() : ''}
                        externalId={item.external_id}
                        data={{
                            hasEmail: item.has_email,
                            avatar: item.avatar,
                            name: item.fullName || '',
                            username: item.userName,
                        }}
                        bloggerId={item.bloggerId}
                        channelId={item.channelId}
                        type={item.type}
                        place={'listBloggers'}
                        key={item.id}
                        disabled={(item.external_id ? false : true) || item.type === Platform.TikTok}
                        index={index}
                    />
                </Flex>

                <Flex>
                    {/* Avatar */}
                    <Avatar alt="AVA" src={item.avatar} type={item.type} aria-label="Аватарка" className={cn.ava} />

                    <Flex column content="center" margin="0 0 0 16px" className={cn.names}>
                        <Flex align="center">
                            <p className={cn.fullname}>{item.fullName ? item.fullName : item.userName}</p>

                            <>
                                {item.has_email && (
                                    <Icons name="mail" style={{ marginLeft: '6px', minWidth: '12px' }} />
                                )}
                            </>

                            {!isMobile && (!currentComment || currentComment.length === 0) && (
                                <Flex className={cn['new-comment']}>
                                    <NewComment handleOpen={handleOpen} />
                                </Flex>
                            )}
                        </Flex>
                        {item.fullName && <p className={cn.username}>{item.userName}</p>}
                    </Flex>
                </Flex>

                {item.statusPercent && item.statusPercent !== 1 ? (
                    <Flex style={{ gridColumn: '3/9' }}>
                        <LoadBar status={item.statusPercent} />
                    </Flex>
                ) : (
                    <>
                        <p className={cn.text}>{shortNum(item.followers)}</p>
                        <p className={cn.text}>{item.er ? item.er + '%' : ''}</p>

                        {item.topics ? (
                            <Topics
                                topics={typeof item.topics === 'string' ? [item.topics] : item.topics}
                                size={12}
                                fSize={10}
                            />
                        ) : (
                            <p className={cn.text}></p>
                        )}

                        {item.unlocked && (
                            <Flex align={'center'}>
                                <p className={cn.text}>
                                    {item.yoscore ? `${(parseFloat(item.yoscore) * 100).toFixed(0)}` : ''}
                                </p>
                                {item.yoscore && (
                                    <div
                                        style={{
                                            height: '6px',
                                            width: '6px',
                                            marginLeft: '4px',
                                            borderRadius: '50%',
                                            background: `${YoScoreLevels(parseFloat(item.yoscore)).color}`,
                                        }}
                                    />
                                )}
                            </Flex>
                        )}

                        <BloggerLink
                            newTab
                            id={item.external_id}
                            isDemo={isDemo}
                            platform={item.type}
                            failLink={`${TARIFFS}?error=error`}
                            className={`${cn[`list-item${item.unlocked ? '--unlocked--mobile' : '--locked'}`]} ${
                                cn['mobile--gridThree']
                            }`}
                            style={item.unlocked ? {} : { gridColumn: '6/8' }}
                            accountType={item.account_type}
                        >
                            <Button width="100%" size="small" variant={item.unlocked ? 'primary' : 'outline'}>
                                <p className={cn['button-text']}>
                                    {item.unlocked
                                        ? t('aud_insides.found_blogger.analyze_button_label')
                                        : t('aud_insides.found_blogger.unlock')}
                                </p>
                            </Button>
                        </BloggerLink>

                        <Flex content={'space-around'}>
                            <Flex content="center" margin="0 0 0 2px">
                                {item.type === Platform.YouTube ? (
                                    <YTLink username={item.external_id} />
                                ) : item.type === Platform.Tg ? (
                                    <>
                                        <ExternalLink link={`https://t.me/${item.userName}/`} />
                                    </>
                                ) : item.type === Platform.Vk ? (
                                    <>
                                        <ExternalLink link={`https://vk.com/${item.userName}/`} />
                                    </>
                                ) : item.type === Platform.Twitch ? (
                                    <ExternalLink link={`https://twitch.com/${item.userName}/`} />
                                ) : item.type === Platform.TikTok ? (
                                    <ExternalLink link={`https://www.tiktok.com/@${item.userName}/`} />
                                ) : (
                                    <>
                                        <InstaLink username={item.userName} />
                                    </>
                                )}
                            </Flex>

                            <Flex>
                                <Like
                                    externalId={item.external_id}
                                    likeId={item.isLike}
                                    platform={item.type}
                                    disabled={item.type === Platform.TikTok}
                                />
                            </Flex>
                        </Flex>
                        {!isMobile && (
                            <Comment
                                close={close}
                                open={open}
                                isOpen={isOpen}
                                value={currentComment || undefined}
                                setValue={setCurrentComment}
                                style={{ gridColumn: '2/8', marginLeft: '66px' }}
                                submit={addComment}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    )
}

export default ListItem
