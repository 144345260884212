import Const from './_constants.js'

export default (store = { isFinished: false, items: [], error: false, checked: false }, action) => {
	switch (action.type) {
		case Const.CONTEXT_MENU_REQUEST: return store
		case Const.CONTEXT_MENU_SUCCESS: 
			return {
			...store,
				items: [
					...store.items,
					...action.results
				],
				isFinished: !action.next
			}
		case Const.CONTEXT_MENU_FAILURE:
			return {
				isFinished: true,
				error: true,
			}
		case Const.CONTEXT_MENU_CHECK:
			const items = store.items.slice(0);
			const newValue = !items[action.index].checked
			items[action.index] = {
				...items[action.index],
				checked: newValue
			};
			if (newValue) 
				return {
					...store,
					items,
					checked: true
				}	
			return {
				...store,
				items,
				checked: items.reduce((prev, cur) => prev || cur.checked, false)
			}	
		case Const.CONTEXT_MENU_NEW_SUCCESS:
			return {
				...store,
				items: store.items.concat([action.item])
			}
		case Const.CONTEXT_MENU_CLEAR: 
			return { isFinished: false, items: [], error: false }
		default: return store;
	}
}