import { memo } from 'react'
import { compose, withProps } from 'recompose'

import P, { IList } from './List'
import withListDownload, { IProvidedProps, IRequiredProps } from './withListDownload'
import { Subtract } from 'utility-types'
import { connect, MapStateToProps } from 'react-redux'
import { PullAll } from '@/Services/Updates'
import parseNativeNotification from '@/components/Notifier/parseNativeNotification'
import { RootStore } from '@/_helpers/StoreType'

type afterHOC = IRequiredProps & Subtract<IList, IProvidedProps>

type TState = Pick<afterHOC, 'token'>
type afterConnect = Subtract<afterHOC, TState>

type toAdd = Pick<afterConnect, 'PullPage'>
type afterAdd = Subtract<afterConnect, toAdd>

const mapState: MapStateToProps<TState, afterConnect, RootStore> = (state) => ({
    token: state.authentication.user?.token || null,
})

const Add: toAdd = {
    PullPage: (token, page) =>
        new Promise((resolve, reject) => {
            PullAll(token, page)
                .then((res) => resolve({ hasNext: !!res.next, results: parseNativeNotification(res.results) }))
                .catch(reject)
        }),
}

export default compose<IList, afterAdd>(
    withProps(Add),
    connect<TState, undefined, afterConnect, RootStore>(mapState),
    withListDownload(),
    memo
)(P)
