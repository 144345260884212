import Loader from '@/components/Loader'
import Controls from '@/Layouts/ReportLayout/Content/Videos/Controls/Contorls'
import { IVideo } from '@/Layouts/ReportLayout/Content/Videos/types'
import VideosTable from '@/Layouts/ReportLayout/Content/Videos/VideosTable/VideosTable'
import { TimeSpanType, VideoFilter } from '@/Layouts/ReportLayout/Model/types'
import { getNumberWithSpaces } from '@/_helpers/nums/numberWithSpaces'
import { RootStore } from '@/_helpers/StoreType'
import Flex from '@/_yc/Flex'
import { useLazyQuery } from '@apollo/client'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { GET_TWITCH_VIDEOS } from '../queries'
import { TwitchVideoResponse } from '../types'

const videos: any = []

for (let i = 0; i < 30; i++) {
    videos.push({
        id: '1',
        title: 'New vid',
        views: '1203',
        imageLink: 'http://img.youtube.com/vi/NQslXbsjVSM/mqdefault.jpg',
        date: '2020-12-12',
    })
}

const getDate = (timeStamp: string) => {
    const date = new Date(timeStamp)
    return date.toISOString().substring(0, 10).replace(/-/g, '.')
}

const getTimestamp = (timeSpan: TimeSpanType) => {
    const today = new Date()
    let timestamp: string

    if (timeSpan === 'month') {
        const year = today.getFullYear()
        const month = today.getMonth() + 1 // January is 0
        timestamp = `${year}-${month < 10 ? '0' + month : month}-01`
    } else if (timeSpan === '6months') {
        const year = today.getFullYear()
        const month = today.getMonth() + 1 // January is 0
        const sixMonthsAgo = month - 6 < 1 ? 12 - (6 - month) : month - 6
        const sixMonthsAgoYear = sixMonthsAgo > month ? year - 1 : year
        timestamp = `${sixMonthsAgoYear}-${sixMonthsAgo < 10 ? '0' + sixMonthsAgo : sixMonthsAgo}-01`
    } else if (timeSpan === 'year') {
        const yearAgo = today.getFullYear() - 1
        timestamp = `${yearAgo}-01-01`
    } else {
        throw new Error(`Invalid time span: ${timeSpan}`)
    }

    return timestamp
}

const formatThumbnailUrl = (url: string) => {
    return url.replace('%{width}x%{height}', '320x180')
}

export const Videos = () => {
    const timeSpan: TimeSpanType = useSelector((store: RootStore) => {
        return store.report.videos.timespan
    })

    const videoFilter: VideoFilter = useSelector((store: RootStore) => {
        return store.report.videos.filter
    })

    const [getVideos, { data, loading, error }] = useLazyQuery<TwitchVideoResponse>(GET_TWITCH_VIDEOS, {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
    })

    const userId = useSelector((store: RootStore) => {
        const data = store.report.rawTwitchData?.getTwitchUser?.data.twitch_userinfo[0]
        return data?.id
    })

    useEffect(() => {
        let videoTimestamp = getTimestamp(timeSpan)

        if (videoFilter === 'views') {
            videoTimestamp = '2011-01-01'
        }

        getVideos({
            variables: {
                userId: userId,
                videoTimestamp: videoTimestamp,
            },
        })
    }, [timeSpan, userId, getVideos, videoFilter])

    const videos = useMemo(() => {
        if (loading || error || !data) {
            return []
        }

        return data.twitch_uservideo.map((video) => {
            return {
                id: video.id,
                title: video.title,
                views: getNumberWithSpaces(video.view_count),
                imageLink: formatThumbnailUrl(video.thumbnail_url),
                date: getDate(video.published_at),
            } as IVideo
        })
    }, [data, loading, error])

    return (
        <Flex column>
            <Controls />

            {!loading || videos.length > 0 ? (
                <VideosTable videos={videos.slice(0, 9)} />
            ) : (
                <Flex content="center" margin="20px 0 0 0">
                    <Loader />
                </Flex>
            )}
        </Flex>
    )
}
