import Icons from '@/components/Icons'
import React from 'react'
import { RangeFilter, RangeValueType } from '../../../../filters/RangeFilter'
import { PLATFORM } from '../../consts'

const getGqlFilter = (range: RangeValueType) => {
    if (!range.from && !range.to) return ''
    // return `average_video_watch: ${getRange(range.from, range.to)}`
}

export const getStreamsFilter = () => {
    return new RangeFilter({
        platform: PLATFORM,
        title: 'filters.streams.label',
        helper: 'filters.streams.text',
        icon: <Icons name="filter--interests" />,
        space: 1,
        options: {
            from: [
                { value: 1, label: '1' },
                { value: 2, label: '2' },
                { value: 3, label: '3' },
                { value: 4, label: '4' },
                { value: 5, label: '5' },
                { value: 6, label: '6' },
            ],
            to: [
                { value: 2, label: '2' },
                { value: 3, label: '3' },
                { value: 4, label: '4' },
                { value: 5, label: '5' },
                { value: 6, label: '6' },
                { value: 7, label: '7' },
            ],
        },
        beforeSend: (data) => {
            return {
                name: 'metrics.views',
                type: 'range',
                value: data,
                gqlFilter: getGqlFilter(data),
            }
        },
    })
}
