import React, { ComponentType, PureComponent } from 'react'
import { Subtract } from 'utility-types'
import { pollingFunction } from '@/_helpers/HOC/withPollinger'

export interface IRequiredProps {
    CheckService: (external_id: number) => Promise<boolean>
    polling: pollingFunction
    pollingStop: VoidFunction
    updateReport: VoidFunction
}

interface Blogger {
    id: number
    data: {
        is_fresh: boolean
        updated?: string
        mainInfo: {
            externalId: number
        }
    }
}

export interface ISameProps {
    blogger: {
        loaded: boolean
        blogger: null | Blogger
    }
}

export interface IProvidedProps {
    updateComplete: boolean
    completeDate: string
}

export default <Props extends IProvidedProps & ISameProps>() => (Component: ComponentType<Props>) =>
    class withUpdates extends PureComponent<
        IRequiredProps & ISameProps & Subtract<Props, IProvidedProps>,
        IProvidedProps
    > {
        state: IProvidedProps = {
            updateComplete: false,
            completeDate: '',
        }

        componentDidUpdate(prevProps: IRequiredProps & ISameProps) {
            if (prevProps.blogger.loaded || !this.props.blogger.loaded || !this.props.blogger.blogger) return

            if (!this.props.blogger.blogger.data.is_fresh) {
                return this.props.polling(10000, this.call)
            }
            this.setState({
                updateComplete: true,
                completeDate: this.props.blogger.blogger.data.updated
                    ? (this.props.blogger.blogger.data.updated as string)
                    : '',
            })
        }

        call = () =>
            new Promise((next) => {
                if (this.props.blogger.blogger)
                    this.props
                        .CheckService(this.props.blogger.blogger.data.mainInfo.externalId)
                        .then((finished) => {
                            if (!finished) return next()
                            const n = new Date()
                            this.setState({
                                updateComplete: true,
                                completeDate: n.toISOString(),
                            })
                            this.props.updateReport()
                            this.props.pollingStop()
                            next()
                        })
                        .catch((err) => {
                            console.error(err)
                            next()
                            this.props.pollingStop()
                        })
            })

        render() {
            const { polling, pollingStop, CheckService, updateReport, ...rest }: IRequiredProps = this.props

            return <Component {...this.state} {...(rest as Props)} />
        }
    }
