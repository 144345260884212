import { IDemographicsItem } from './types'

export const verticalDefaultData = [
    {
        label: '>54',
        items: [
            {
                qty: 141,
                share: 0.001554027245073403,
                color: '#6071FF',
            },
            {
                qty: 71,
                share: 0.0007825243574483093,
                color: '#FF659C',
            },
        ],
    },
    {
        label: '0-17',
        items: [
            {
                qty: 1484,
                share: 0.016355861217651986,
                color: '#6071FF',
            },
            {
                qty: 2132,
                share: 0.02349777366309571,
                color: '#FF659C',
            },
        ],
    },
    {
        label: '18-24',
        items: [
            {
                qty: 5539,
                share: 0.06104792135079134,
                color: '#6071FF',
            },
            {
                qty: 12197,
                share: 0.13442886743376098,
                color: '#FF659C',
            },
        ],
    },
    {
        label: '25-34',
        items: [
            {
                qty: 21752,
                share: 0.23973901159458624,
                color: '#6071FF',
            },
            {
                qty: 30473,
                share: 0.3358572499228497,
                color: '#FF659C',
            },
        ],
    },
    {
        label: '35-44',
        items: [
            {
                qty: 9790,
                share: 0.10790018956928096,
                color: '#6071FF',
            },
            {
                qty: 5224,
                share: 0.05757615835647842,
                color: '#FF659C',
            },
        ],
    },
    {
        label: '45-54',
        items: [
            {
                qty: 1121,
                share: 0.012355067671824715,
                color: '#6071FF',
            },
            {
                qty: 607,
                share: 0.006690032182691884,
                color: '#FF659C',
            },
        ],
    },
    {
        label: '55-999',
        items: [
            {
                qty: 183,
                share: 0.002016928977648459,
                color: '#6071FF',
            },
            {
                qty: 18,
                share: 0.00019838645681788123,
                color: '#FF659C',
            },
        ],
    },
]

export const horizontalDefaultData: IDemographicsItem[] = [
    {
        label: 'def1',
        items: [
            {
                qty: 0,
                share: 0.7249699673931698,
                color: '#6071FF',
            },
        ],
    },
    {
        label: 'def2',
        items: [
            {
                qty: 0,
                share: 0.15665007722670327,
                color: '#6071FF',
            },
        ],
    },
    {
        label: 'def3',
        items: [
            {
                qty: 0,
                share: 0.025536296550540585,
                color: '#6071FF',
            },
        ],
    },
    {
        label: 'def4',
        items: [
            {
                qty: 0,
                share: 0.017127166638064184,
                color: '#6071FF',
            },
        ],
    },
    {
        label: 'def5',
        items: [
            {
                qty: 0,
                share: 0.00816886905783422,
                color: '#6071FF',
            },
        ],
    },
    {
        label: 'def6',
        items: [
            {
                qty: 0,
                share: 0.007516732452376866,
                color: '#6071FF',
            },
        ],
    },
    {
        label: 'def7',
        items: [
            {
                qty: 0,
                share: 0.0070362107430925,
                color: '#6071FF',
            },
        ],
    },
    {
        label: 'def8',
        items: [
            {
                qty: 0,
                share: 0.006178136262227562,
                color: '#6071FF',
            },
        ],
    },
]
