import React, { memo, ComponentType } from 'react'
import { Icon } from '../Types'
import { MessageType } from './Types'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import Text from '@/components/Text'

import BloggerLink from '@/components/Share/BloggerLink'
import { Trans } from 'react-i18next'

export type BloggerReadyProps = {
    username: string
    external_id: number | string
    promo?: boolean
}

export default class BloggerReady implements MessageType<BloggerReadyProps> {
    icon: Icon = 'ok'
    props: BloggerReadyProps

    private components: any
    private values: any

    constructor(username: string, external_id: number | string, promo?: boolean) {
        this.props = { username, external_id, promo }
        this.components = [
            <Text fSize={14} component={BloggerLink} newTab id={this.props.external_id} color="violet-1" />,
        ]

        this.values = {
            username: this.props.username,
        }
    }
    Component: ComponentType<{ withSublabel?: boolean }> = memo(({ withSublabel }) => {
        const t = useSpaceTranslation('Notifications.onReady')

        return (
            <div>
                <Text margin={withSublabel ? '0 0 2px' : '0'}>
                    <Trans i18nKey="Notifications.onReady.text" components={this.components} values={this.values} />
                </Text>
                {withSublabel && (
                    <Text
                        fSize={12}
                        semibold={false}
                        component={BloggerLink}
                        newTab
                        id={this.props.external_id}
                        promo={this.props.promo}
                    >
                        {t('open')}
                    </Text>
                )}
            </div>
        )
    })
}
