import React, { FC, memo, useEffect, useMemo } from 'react'

import PageLayout from '@/Layouts/PageLayout'
import { useTranslation } from 'react-i18next'
import { Header } from './Header/Header'
import { useSelector, useDispatch } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import Loader from '@/components/Loader'
import Flex from '@/_yc/Flex'
import { loginActions } from '@/pages/Auth/LoginPage'
import { CampaignManagerProvider } from '../CampaignManager/CampaignManagerContext/CampaignManagerProvider'
import { PlansTable } from './PlansTable/Table'
import GlobalWidth from '@/_yc/GlobalWidth'

export interface ICampaignManager {}

const MediaPlanning: FC<ICampaignManager> = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const platforms = useSelector((store: RootStore) => {
        return store.authentication.user?.platforms
    })

    useEffect(() => {
        if (!!platforms) return
        dispatch(loginActions.getPlatforms())
    }, [platforms, dispatch])

    const isMediaPlanerAvailable = useMemo(() => {
        return true
    }, [])

    return (
        <CampaignManagerProvider>
            <PageLayout color="common-background" title={t('titles.t7')}>
                <Header />
                <GlobalWidth fixedWidth>
                    {isMediaPlanerAvailable === undefined ? (
                        <Flex content={'center'} margin="20px 0 0 0">
                            <Loader />
                        </Flex>
                    ) : isMediaPlanerAvailable ? (
                        <PlansTable />
                    ) : (
                        <div>hidden</div>
                        // <CampaignHider />
                    )}
                </GlobalWidth>
            </PageLayout>
        </CampaignManagerProvider>
    )
}

export default memo(MediaPlanning)
