import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ApexOptions } from 'apexcharts'

import Flex from '@/_yc/Flex'
import Text from '@/components/Text'
import PartChanger from '@/Layouts/ReportLayout/Extra/PartChanger'
import {
    calculatePercentChange,
    convertMinutesToDuration,
    generateCategoriesPlug,
    generateDataPlug,
    isGraphDataEmpty,
} from '@/Layouts/ReportLayout/Content/GrowGraph/utils'
import { useSpaceTranslation } from '@/_helpers'
import { setGraphTimespan, setGraphType } from '@/Layouts/ReportLayout/Model/actions'
import { RootStore } from '@/_helpers/StoreType'
import DataChange from '@/Layouts/ReportLayout/Content/GrowGraph/DataChange/DataChange'
import Button from '@/components/Button'
import NoData from '@/Layouts/ReportLayout/Content/Videos/VideosTable/NoData'
import getOptions from '@/Layouts/ReportLayout/Content/GrowGraph/options'
import getStartDate from '@/Layouts/ReportLayout/Content/utils'
import { shortNum } from '@/_helpers/_graphs/_tech'

import { IHistoricalMetrics } from '../service'

import cn from './GrowGraph.module.scss'

const PLUG_LENGTH = 40

interface IGrow {
    data: IHistoricalMetrics[]
}

interface HasCreatedAtField {
    created_at: Date
}

function filterMetrics<T extends IHistoricalMetrics>(metrics: T[], startDate: string): T[] {
    return metrics.filter((metric) => {
        return new Date(metric.created_at) >= new Date(startDate)
    })
}

const GrowGraph: FC<IGrow> = ({ data }) => {
    const t = useSpaceTranslation('report_layout.graph')
    const { i18n } = useTranslation()

    const [isEmptyData, setIsEmptyData] = useState(false)

    const categoriesPlug = generateCategoriesPlug(PLUG_LENGTH)
    const dataPlug = generateDataPlug(PLUG_LENGTH)

    const [changeValue, setChangeValue] = useState(0)

    const graphRef = useRef(null)

    const graph = useSelector((store: RootStore) => {
        return store.report.graph
    })

    const graphSpan = useSelector((store: RootStore) => {
        return store.report.graph.timespan
    })

    const channelMetrics = useSelector(() => {
        const filteredChannelMetrics = data && filterMetrics(data, getStartDate(graphSpan))

        return filteredChannelMetrics
    })

    const graphType = useSelector((store: RootStore) => store.report.graph.type)

    const graphData = useMemo(() => {
        if (!channelMetrics) return []

        return channelMetrics
            .map((metric) => {
                if (graphType === 'likes') return metric.likes
                if (graphType === 'comments') return metric.comments
                return metric.views
            })
            .filter((item) => item !== null)
    }, [channelMetrics, graphType])

    useEffect(() => {
        if (!graphData || graphData.length === 0) return

        const startValue = graphData[0]
        const endValue = graphData[graphData.length - 1]

        setChangeValue(calculatePercentChange(startValue, endValue))
    }, [graphData])

    useEffect(() => {
        setIsEmptyData(isGraphDataEmpty(graphData))
    }, [graphData, setIsEmptyData, graphType, graphSpan])

    const dispatch = useDispatch()

    const partChangerValues = useMemo(() => {
        const values = [
            {
                title: t('views'),
                value: '',
                dispFunction: () => {
                    dispatch(setGraphType('views'))
                },
            },
            {
                title: t('likes'),
                value: '',
                dispFunction: () => {
                    dispatch(setGraphType('likes'))
                },
            },
            {
                title: t('comments'),
                value: '',
                dispFunction: () => {
                    dispatch(setGraphType('comments'))
                },
            },
        ]

        return values
    }, [dispatch, t])

    const graphCategories = useMemo(() => {
        if (!channelMetrics) return []

        return channelMetrics.map((i) => moment(i.created_at).format()).reverse()
    }, [channelMetrics])

    const options = useMemo<ApexOptions>(() => {
        const isEmpty = isGraphDataEmpty(graphData)
        const categories = isEmpty ? categoriesPlug : graphCategories
        const data = isEmpty ? dataPlug : [...graphData].reverse()

        return getOptions({
            graphOpts: data,
            info: graph,
            graphCategories: categories,
            locale: i18n.language.slice(0, 2),
            isEmpty: isEmpty,
            toFixed: 2,
        })
    }, [graphData, graph, graphCategories, i18n.language, categoriesPlug, dataPlug])

    useEffect(() => {
        console.log('render')
        if (channelMetrics) {
            const chart = new ApexCharts(graphRef.current, options)
            chart.render()
            return () => {
                chart.destroy()
            }
        }
    }, [options, channelMetrics])

    return (
        <Flex className={cn.root} column>
            <Flex>
                <PartChanger values={partChangerValues} />
            </Flex>

            <Flex margin="20px 0 0 0" align="center" content="space-between" wrap>
                <Flex align={'center'}>
                    <Text fSize={21} semibold className={cn[`blur--${isEmptyData ? 'active' : 'default'}`]}>
                        {graphType === 'duration'
                            ? convertMinutesToDuration(graphData[graphData?.length - 1])
                            : shortNum(graphData[graphData?.length - 1], 2)}
                    </Text>

                    <DataChange changeValue={changeValue} isEmptyData={isEmptyData} />
                </Flex>

                <Flex>
                    <Button
                        margin="0 10px 0 0"
                        color="blue"
                        onClick={() => dispatch(setGraphTimespan('month'))}
                        active={graph.timespan === 'month'}
                        variant="outline--mobileV2"
                    >
                        {t('month')}
                    </Button>
                    <Button
                        color="blue"
                        onClick={() => dispatch(setGraphTimespan('6months'))}
                        active={graph.timespan === '6months'}
                        variant="outline--mobileV2"
                    >
                        {t('6months')}
                    </Button>
                    <Button
                        margin="0 0 0 10px"
                        color="blue"
                        onClick={() => dispatch(setGraphTimespan('year'))}
                        active={graph.timespan === 'year'}
                        variant="outline--mobileV2"
                    >
                        {t('year')}
                    </Button>
                </Flex>
            </Flex>

            <div ref={graphRef} className={cn[`blur--${isEmptyData ? 'active' : 'default'}`]} />
            {isEmptyData && <NoData />}
        </Flex>
    )
}

export default memo(GrowGraph)
