import React from 'react'

import { history } from '../../../_helpers'
import { VerifyService } from './Model'
import { LOGIN, SEARCH } from '../../../routs.js'

export class VerifyEmail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: null,
        }
        const params = new URLSearchParams(props.location.search)
        VerifyService.verify(params.get('user_id'), params.get('timestamp'), params.get('signature')).then(
            (e) => {
                if (e.detail === 'User verified successfully') {
                    const token = e.token
                    if (token) {
                        localStorage.setItem('user', JSON.stringify({ token }))
                        history.push(SEARCH)
                    } else {
                        history.push(LOGIN, { verified: 'Registration completed successfully!' })
                    }
                } else {
                    this.setState({ status: 'ERROR!' })
                    history.push(LOGIN)
                }
            },
            (e) => {
                this.setState({ status: 'ERROR!' })
                history.push(LOGIN)
            }
        )
    }
    render() {
        return <div>{this.state.status}</div>
    }
}
