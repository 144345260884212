import React, { FC, useMemo, memo } from 'react'

import { shortNum } from '@/_helpers/_graphs/_tech'

import cn from './Bar.module.scss'
import ParsedDate from '@/components/Share/ParsedDate'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import Icons from '@/components/Icons'
import Flex from '@/_yc/Flex'

export interface IBar {
    label: string
    url: string
    max: number
    value: Array<{
        value: number
        color: string
    }>
}

const Bar: FC<IBar> = (props) => {
    const t = useSpaceTranslation('months')
    const tLikes = useSpaceTranslation('report_titles.legend')

    const local_max = useMemo(
        () => props.value.reduce((prev, item) => (item.value > prev ? item.value : prev), 0),
        [props.value]
    )

    const hasHidden = useMemo(() => props.value.some((item) => item.value === 3), [props.value])

    return (
        <a className={cn.root} href={props.url} target="_blank" rel="noopener noreferrer">
            <ParsedDate dateString={props.label}>
                {({ date, month }) => (
                    <div className={cn.date}>
                        {t(month)} {date}
                    </div>
                )}
            </ParsedDate>
            {props.value.map((item, index) => {
                const isHidden = item.value === 3
                const height = isHidden ? 0 : (Math.log10(item.value) / props.max) * 100
                return (
                    <div
                        key={index}
                        className={cn.one}
                        style={{
                            zIndex: Math.round(((local_max - item.value) * 100) / local_max),
                        }}
                    >
                        <div
                            className={cn.fill}
                            style={{
                                background: item.color,
                                height: `${height}%`,
                            }}
                        >
                            {/* Handle hidden likes :) */}
                            {hasHidden && !isHidden && (
                                <div className={cn.hiddenLikes} style={{ top: height < 10 ? '-38px' : '-22px' }}>
                                    <Flex align="center" content="center">
                                        {tLikes('likes')} <Icons margin="0 0 0 5px" name="hidden-likes" />
                                    </Flex>
                                </div>
                            )}
                            {!isHidden && item.value ? (
                                <div
                                    className={`${
                                        (item.value !== local_max || hasHidden) && height > 10 ? cn.min : cn.max
                                    }`}
                                >
                                    {shortNum(item.value)}
                                </div>
                            ) : null}
                        </div>
                    </div>
                )
            })}
        </a>
    )
}

export default memo(Bar)
