import useClass from '@/_helpers/Hooks/useClass'
import React, {
    ReactNode,
    HTMLAttributes,
    Ref,
    ForwardRefRenderFunction,
    ReactHTML,
    ComponentType,
} from 'react'

import cn from './Flex.module.scss'

export interface IFlex extends HTMLAttributes<HTMLDivElement> {
    children?: ReactNode
    content?:
        | 'center'
        | 'flex-start'
        | 'flex-end'
        | 'space-between'
        | 'space-around'
    align?: 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline'
    wrap?: boolean
    column?: boolean
    margin?: string
    ref?: Ref<HTMLDivElement>
    grow?: boolean
    component?: keyof ReactHTML | ComponentType<any>
    [key: string]: any
}

const Flex: ForwardRefRenderFunction<HTMLDivElement, IFlex> = (
    props: IFlex,
    ref
) => {
    const {
        children,
        content,
        align,
        wrap,
        column,
        margin,
        style,
        className,
        grow,
        component: Component = 'div',
        ...rest
    } = props

    const root = useClass(cn.root, className)

    return (
        <Component
            className={root}
            ref={ref}
            style={{
                justifyContent: content,
                alignItems: align,
                flexWrap: wrap ? 'wrap' : undefined,
                flexDirection: column ? 'column' : undefined,
                margin: margin,
                flexGrow: grow ? 1 : undefined,
                ...style,
            }}
            {...rest}
        >
            {props.children}
        </Component>
    )
}

export default Flex
