import React from 'react'

import Icons from '@/components/Icons'
import { StandartPopper } from '../StandartPopper.js'
import Popper from './Popper'
import Button from "@/components/Button"

import cn from './ContextMenu.module.scss'

export default class ContextMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            disabled: false,
        }
        this.block = React.createRef()
        this.number = 0
    }
    handleClick(val) {
        if (!val) {
            this.number++
            return this.setState({ open: val }, (e) => {
                setTimeout((e) => this.setState({ disabled: false }), 0)
                if (this.props.store.checked)
                    this.props.addToLists(
                        this.props.store.items.filter(
                            (item) => item.checked === true
                        ),
                        this.props.id,
                        this.props.username,
                        this.props.type
                    )
            })
        }

        this.setState({ open: val, disabled: true })
    }
    onChange() {
        this.number++
        this.addInQ(this.number)
    }
    addInQ(number) {
        setTimeout(() => {
            if (number === this.number) {
                this.handleClick(false)
            }
        }, 2000)
    }
    render() {
        return (
            <StandartPopper
                btn={this.props.reportButton
                    ? <Button
                        variant={'transparent--outline'}
                        ref={this.block}
                        onClick={
                            !this.state.disabled
                                ? (e) => this.handleClick(!this.state.open)
                                : (e) => e
                        }
                        className={cn.btn}
                        style={{

                            margin: this.props.margin,
                            ...this.props.style,
                        }}
                    >
                        {this.props.t('report.addToList')}
                    </Button>
                    : <div
                        ref={this.block}
                        onClick={
                            !this.state.disabled
                                ? (e) => this.handleClick(!this.state.open)
                                : (e) => e
                        }
                        className={cn.btn}
                        style={{
                            margin: this.props.margin,
                            ...this.props.style,
                        }}
                    >
                        <Icons name="list-plus" className={cn.icon} />
                    </div>
                }
                content={
                    <div>
                        <Popper
                            onChange={(e) => this.onChange()}
                            id={this.props.id}
                            closer={(e) => this.handleClick(false)}
                        />
                    </div>
                }
                open={this.state.open}
                anchor={this.block.current}
                onClickAway={this.handleClick.bind(this)}
            />
        )
    }
}
