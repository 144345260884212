import Const from '../constants.js'

const defaultState = {
	loaded: false,
	reportsCount: null, 
	intersectionsCount: null, 
	chatsCount: null,
	error: false,
}

export default (state = defaultState, action) => {
	switch (action.type) {
		case Const.HEADER_MENU_COUNT_SUCCESS: return {
			...state,
			loaded: true,
			reportsCount: action.reportsCount, 
			intersectionsCount: action.intersectionsCount, 
			chatsCount: action.chatsCount,
		}
		case Const.HEADER_MENU_COUNT_ERROR: return {
			...state,
			reportsCount: 0, 
			intersectionsCount: 0, 
			chatsCount: 0,
			error: action.err
		}
		default: return state
	}
}