import { APIService } from '@/_helpers'
import { authHeader } from '@/_helpers/auth-header.js'

export const Service = new APIService({
    methods: {
        OAuth: (params: any) => ({
            url: `/core/social-accounts/complete/google-email/`,
            method: 'GET',
            headers: authHeader(),
            params,
        }),
    },
})
