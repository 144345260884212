import Text from '@/components/Text'
import ListPopup from '@/components/Share/ListPopup'
import useOpenClose from '@/_helpers/useOpenClose'
import React, { FC, memo, useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import FilterBase from '../FilterBase'
import cn from './SelectorFilter.module.scss'

export interface IPercentage {
    value: number
    onChange: (next: number) => void

    margin?: number | string
    disabled?: boolean
}

const zIndex = { zIndex: 5 }

const Percentage: FC<IPercentage> = ({ onChange, ...props }) => {
    const [state, , close, toggle] = useOpenClose(false, props.disabled)

    const ref = useRef<any>(null)

    const { t } = useTranslation()

    const options = useMemo(
        () => [
            { label: t('filters.percentage', { percent: 3 }), value: 3 },
            { label: t('filters.percentage', { percent: 5 }), value: 5 },
            { label: t('filters.percentage', { percent: 10 }), value: 10 },
            { label: t('filters.percentage', { percent: 15 }), value: 15 },
            { label: t('filters.percentage', { percent: 20 }), value: 20 },
            { label: t('filters.percentage', { percent: 25 }), value: 25 },
            { label: t('filters.percentage', { percent: 30 }), value: 30 },
            { label: t('filters.percentage', { percent: 35 }), value: 35 },
            { label: t('filters.percentage', { percent: 40 }), value: 40 },
            { label: t('filters.percentage', { percent: 45 }), value: 45 },
            { label: t('filters.percentage', { percent: 50 }), value: 50 },
            { label: t('filters.percentage', { percent: 55 }), value: 55 },
            { label: t('filters.percentage', { percent: 60 }), value: 60 },
            { label: t('filters.percentage', { percent: 65 }), value: 65 },
            { label: t('filters.percentage', { percent: 70 }), value: 70 },
            { label: t('filters.percentage', { percent: 80 }), value: 80 },
            { label: t('filters.percentage', { percent: 90 }), value: 90 },
        ],
        [t]
    )

    const change = useCallback(
        (value: number) => {
            onChange(value)
            close()
        },
        [onChange, close]
    )

    return (
        <>
            <FilterBase
                ref={ref}
                onClick={toggle}
                isActive={state}
                disabled={props.disabled}
                withArrow
                className={cn.percentage}
                margin={props.margin}
            >
                <Text>{t('filters.percentage', { percent: props.value })}</Text>
            </FilterBase>
            <ListPopup
                popupStyle={zIndex}
                width={100}
                options={options}
                onClick={change}
                anchor={ref.current}
                isOpen={state}
                onClose={toggle}
            />
        </>
    )
}

export default memo(Percentage)
