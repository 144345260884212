import React from 'react';
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import withItems from './withItems.js'
import withMoney from './withMoney.js'
import DB from './Dashboards.js'
import service from '../Model/service.js'
import notif from '../../../components/Notifier/model/actions.js'
import { CommonError, CommonLoading } from '@/components/Commercial/Notifaications'
import headerService from '@/components/HeaderMenu/Model/service.js'

// import test from './testAPI.json'

/*const pull = () => new Promise(resolve => setTimeout(() => {
	resolve(test)
}, 2000))*/

/*const DeleteService = () => new Promise((resolve, rej) => {
	setTimeout(() => {
		rej()
	}, 2000)
})*/

const mapDispatchToProps = dispatch => ({
	loading(){
		dispatch(notif.loading(<CommonLoading />))
	},
	error(){
		dispatch(notif.error(<CommonError />, 3000))
	},
	clear(){
		dispatch(notif.clear())
	}
})

export default compose(
	connect(null, mapDispatchToProps),
	withMoney(headerService.getCount),
	withItems(
		service.getIntersections, 
		service.addIntersection, 
		service.deleteIntersection, 
		service.renameIntersection
	),
	withTranslation()
)(DB)