import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.16667 17.5H2.5V16.6667C2.50367 13.4465 5.1132 10.837 8.33333 10.8333H11.6667C14.8868 10.837 17.4963 13.4465 17.5 16.6667V17.5H15.8333V16.6667C15.8306 14.3666 13.9667 12.5028 11.6667 12.5H8.33333C6.03329 12.5028 4.16942 14.3666 4.16667 16.6667V17.5ZM10 10C7.69881 10 5.83333 8.13453 5.83333 5.83334C5.83333 3.53215 7.69881 1.66667 10 1.66667C12.3012 1.66667 14.1667 3.53215 14.1667 5.83334C14.1639 8.13338 12.3 9.99725 10 10ZM10 3.33334C8.61929 3.33334 7.5 4.45263 7.5 5.83334C7.5 7.21405 8.61929 8.33334 10 8.33334C11.3807 8.33334 12.5 7.21405 12.5 5.83334C12.5 4.45263 11.3807 3.33334 10 3.33334Z"
            fill="#333333"
        />
    </svg>
)
