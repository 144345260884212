import React, { FC, useEffect, useState } from 'react'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import cn from './additional-params.module.scss'
import MultiSelect from '@/pages/TargetAudience/components/MultiSelect'
import RangeSelect from '@/pages/TargetAudience/components/RangeSelect'
import InputTags from '@/pages/TargetAudience/components/InputTags'
import styles from '@/pages/TargetAudience/components/CustomAccordion/custom-accordion.module.scss'
import { ReactComponent as DoneIcon } from '../../../../icons/done.svg'
import Button from '@/components/Button'
import { IAdditionalParamsData } from '@/pages/TargetAudience/services/types'
import { useTranslation } from 'react-i18next'
import Tooltip from '@/pages/TargetAudience/components/Tooltip'

export type Option = {
    label: string
    value: string
}

export interface SelectedParams {
    [key: string]: Option[]
}

interface AdditionalParamsProps {
    initialParams?: SelectedParams
    onParamsChange: (params: SelectedParams) => void
    onEdit?: () => void
    options?: IAdditionalParamsData
}

const AdditionalParams: FC<AdditionalParamsProps> = ({
    initialParams = {
        selectedAges: [],
        selectedLandlord: [],
        selectedDevices: [],
        selectedEmployment: [],
        selectedFamily: [],
        websites: [],
    },
    onParamsChange,
    onEdit,
    options,
}) => {
    const [selectedParams, setSelectedParams] = useState<SelectedParams>(initialParams)
    const { t } = useTranslation()

    useEffect(() => {
        setSelectedParams(initialParams)
        // eslint-disable-next-line
    }, [JSON.stringify(initialParams)])

    useEffect(() => {
        onParamsChange(selectedParams)
        // eslint-disable-next-line
    }, [selectedParams])

    const handleChange = (key: keyof SelectedParams, selected: Option[]) => {
        setSelectedParams((prevParams) => ({
            ...prevParams,
            [key]: selected,
        }))
    }

    return (
        <Flex column className={cn['root']}>
            {onEdit && (
                <Flex className={styles['title-wrap']} align="center" content="space-between">
                    <Text fSize="14" color="gray-new-4">
                        {t('target_audience.additional_params.additional_parameters')}
                    </Text>
                    <DoneIcon onClick={onEdit} style={{ cursor: 'pointer' }} />
                </Flex>
            )}
            <Flex column>
                <Flex align="center" margin="0 0 8px 0">
                    <Text fSize="12px" semibold color="gray-new-4">
                        {t('target_audience.additional_params.children_age')}
                    </Text>
                    <Tooltip title={t('target_audience.target_children_age_groups')} placement={'right'}>
                        <span className={cn['info-icon']}>i</span>
                    </Tooltip>
                </Flex>
                <Flex className={cn['field-wrapper']}>
                    <RangeSelect
                        options={options?.ChildrenAge || []}
                        selectedValues={selectedParams.selectedAges || []}
                        onChange={(selected) => handleChange('selectedAges', selected)}
                    />
                </Flex>
            </Flex>
            <Flex column>
                <Flex align="center" margin="0 0 8px 0">
                    <Text fSize="12px" semibold color="gray-new-4">
                        {t('target_audience.additional_params.owners')}
                    </Text>
                    <Tooltip title={t('target_audience.selectAssets')} placement={'right'}>
                        <span className={cn['info-icon']}>i</span>
                    </Tooltip>
                </Flex>
                <MultiSelect
                    options={options?.LandlordType || []}
                    placeholder="Выберите"
                    initialSelected={selectedParams.selectedLandlord || []}
                    onChange={(selected) => handleChange('selectedLandlord', selected)}
                    enableSearch={false}
                />
            </Flex>
            <Flex column>
                <Flex align="center" margin="0 0 8px 0">
                    <Text fSize="12px" semibold color="gray-new-4">
                        {t('target_audience.additional_params.device_type')}
                    </Text>
                    <Tooltip title={t('target_audience.selectDevices')} placement={'right'}>
                        <span className={cn['info-icon']}>i</span>
                    </Tooltip>
                </Flex>
                <Flex className={cn['field-wrapper']}>
                    <RangeSelect
                        options={options?.DeviceTypes || []}
                        selectedValues={selectedParams.selectedDevices || []}
                        onChange={(selected) => handleChange('selectedDevices', selected)}
                    />
                </Flex>
            </Flex>
            <Flex column>
                <Flex align="center" margin="0 0 8px 0">
                    <Text fSize="12px" semibold color="gray-new-4">
                        {t('target_audience.additional_params.employment_type')}
                    </Text>
                    <Tooltip title={t('target_audience.selectOccupation')} placement={'right'}>
                        <span className={cn['info-icon']}>i</span>
                    </Tooltip>
                </Flex>
                <Flex className={cn['field-wrapper']}>
                    <RangeSelect
                        options={options?.OccupationType || []}
                        selectedValues={selectedParams.selectedEmployment || []}
                        onChange={(selected) => handleChange('selectedEmployment', selected)}
                    />
                </Flex>
            </Flex>
            <Flex column>
                <Flex align="center" margin="0 0 8px 0">
                    <Text fSize="12px" semibold color="gray-new-4">
                        {t('target_audience.additional_params.family')}
                    </Text>
                    <Tooltip title={t('target_audience.selectMaritalStatus')} placement={'right'}>
                        <span className={cn['info-icon']}>i</span>
                    </Tooltip>
                </Flex>
                <MultiSelect
                    options={options?.FamilyStatuses || []}
                    placeholder={t('target_audience.additional_params.select')}
                    initialSelected={selectedParams.selectedFamily || []}
                    onChange={(selected) => handleChange('selectedFamily', selected)}
                    enableSearch={false}
                />
            </Flex>
            <Flex column>
                <Flex align="center" margin="0 0 8px 0">
                    <Text fSize="12px" semibold color="gray-new-4">
                        {t('target_audience.additional_params.interested_sites')}
                    </Text>
                    <Tooltip title={t('target_audience.enterWebsites')} placement={'right'}>
                        <span className={cn['info-icon']}>i</span>
                    </Tooltip>
                </Flex>
                <InputTags
                    initialWebsites={selectedParams.websites || []}
                    onChange={(updatedWebsites) => handleChange('websites', updatedWebsites)}
                    placeholder={t('target_audience.additional_params.enter_format')}
                />
            </Flex>
            {onEdit && (
                <Flex column className={cn['field-wrapper']}>
                    <Button onClick={onEdit} className={cn['btn']}>
                        {t('target_audience.save')}
                        <DoneIcon />
                    </Button>
                </Flex>
            )}
        </Flex>
    )
}

export default AdditionalParams
