import React, { FC, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Text } from '@/_yc/'
import Button from '@/components/Button'
import { TARIFFS } from '@/routs'

import cn from './Tariff.module.scss'
import Flex from '@/_yc/Flex'
import ParsedDate from '@/components/Share/ParsedDate'

import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { getCurrentTariff, tariffUnsubscribe } from '@/Services/Billing'
import useAuthCall from '@/_helpers/Hooks/useAuthCall'
import useOpenClose from '@/_helpers/useOpenClose'
import UnsubscribeModal from './UnsubscribeModal'
import { numberFormatter } from '@/_helpers'
import useToken from '@/_helpers/Hooks/useToken'
import useSystemNotification from '@/_helpers/Hooks/useSystemNotification'

import Tariffs from '@/_yc/Icons/Tariffs'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'

import BillingIcons from './Icons/BillingIcons'
import Loader from '@/components/Loader'
import { getCurrency } from '@/_helpers/getCurrency'
import BuyButton from './BuyButton'

export interface ITariff {}
const Tariff: FC<ITariff> = (props) => {
    const [state, open, close] = useOpenClose()
    const { i18n } = useTranslation()

    const [loading, data, error, reload] = useAuthCall(getCurrentTariff)
    useEffect(() => {
        reload()
    }, [i18n.language, reload])

    const { notify } = useSystemNotification()

    const t = useSpaceTranslation('billing.tariff')
    const token = useToken()
    const unsubscribe = useCallback(async () => {
        if (!token || !data) return
        try {
            await tariffUnsubscribe(token, data.id, data['tariff'], !data['recurring_payment_enabled'])
            close()
            reload()
            notify(t('common_success', undefined, true), 'ok')
        } catch (e) {
            notify(t('common_error', undefined, true), 'error')
            close()
        }
    }, [close, data, notify, reload, t, token])

    let tariff_status = false
    const currentTariff = useSelector((store: RootStore) => {
        return store.authentication.user?.profile?.package ?? ''
    })
    if (currentTariff !== '') {
        tariff_status = true
    }

    const currency = getCurrency(data?.tariff?.currency)
    const packageID = data?.tariff?.package?.id

    return (
        <Flex column className={`${cn.root} ${error ? cn['root_hide'] : ''}`}>
            {state && data && <UnsubscribeModal onSubmit={unsubscribe} exp={data.valid_till || ''} onClose={close} />}
            <Flex align="center" content="space-between" margin="0 0 16px">
                <h2 className={cn.current}>{t('title')}</h2>
                {tariff_status ? (
                    <div className={cn.status}>{t('statusActive')}</div>
                ) : (
                    <div className={cn['status--inactive']}>{t('statusSuspended')}</div>
                )}
            </Flex>
            {loading && (
                <Flex content="center">
                    <Loader />
                </Flex>
            )}
            {!loading && !error && data && data.tariff && (
                <>
                    <Flex column>
                        <div className={cn.tariff}>
                            <div className={cn['tariff--icon']}>
                                <Tariffs id={data.tariff.package.id} />
                            </div>

                            <Flex column={true} style={{ minWidth: '180px' }}>
                                <Flex column={false} content="space-between" wrap>
                                    <Text className={cn['tariff--title']} margin="0 15px 0 0">
                                        {data.tariff.package.name}
                                    </Text>
                                    <BillingIcons tariffs={data.tariff.platforms} />
                                </Flex>
                                <p className={cn['tariff--price']}>
                                    {data.tariff.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') +
                                        ' ' +
                                        currency}
                                    <span className={cn['tariff--price--for']}>{t(`price_month`)}</span>
                                </p>
                                <Link to={TARIFFS}>
                                    {/* <Button color="white" className={cn.button}> */}
                                    <span className={cn['button--title']}>{t('button')}</span>
                                    {/* </Button> */}
                                </Link>
                            </Flex>
                        </div>
                        <Flex margin="16px 0 8px" align="center" content="space-between">
                            <span className={cn['tariff--text']}>{t('subExp')}</span>
                            {data.valid_till && (
                                <ParsedDate monthAsNumber dateString={data.valid_till}>
                                    {({ date, month, year }) => (
                                        <span className={cn['tariff--text--value']}>
                                            {date}.{month}.{year}
                                        </span>
                                    )}
                                </ParsedDate>
                            )}
                        </Flex>
                        <Flex margin="8px 0 8px" align="center" content="space-between">
                            <span className={cn['tariff--text']}>{t('repLeft')}</span>
                            <span className={cn['tariff--text--value']}>{data.remain_reports}</span>
                        </Flex>
                        <Flex margin="8px 0 8px" align="center" content="space-between">
                            <span className={cn['tariff--text']}>{t('itersectionsLeft')}</span>
                            <span className={cn['tariff--text--value']}>
                                {numberFormatter(data.remain_intersections || 0)}
                            </span>
                        </Flex>
                        {data.recurring_payment_enabled && (
                            <Flex align="center" content="space-between" className={cn['tariff--recurring']}>
                                <span className={cn['tariff--text']}>{t('recurring')}</span>
                                {data.valid_till && (
                                    <ParsedDate monthAsNumber dateString={data.valid_till}>
                                        {({ date, month, year }) => (
                                            <span className={cn['tariff--text--value']}>
                                                {date}.{month}.{year}
                                            </span>
                                        )}
                                    </ParsedDate>
                                )}
                            </Flex>
                        )}
                        <Flex margin="10px 0 8px" align="flex-end" content="center">
                            <Flex content="center" align="center">
                                <BuyButton
                                    unsubscribe={unsubscribe}
                                    recurring_payment_enabled={data.recurring_payment_enabled}
                                    open={open}
                                    packageID={packageID}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </>
            )}
            {!loading && !error && data && !data.tariff && (
                <>
                    <Flex column>
                        <div className={cn.tariff}>
                            <div className={cn['tariff--icon']}>
                                <Tariffs id={0} />
                            </div>

                            <Flex column={true}>
                                <Text className={cn['tariff--title']}>{t('empty.title')}</Text>
                                <p className={cn['tariff--price']}>
                                    <span className={cn['tariff--price--for']}>{t('empty.subsubTitle')}</span>
                                </p>
                            </Flex>
                        </div>
                        <Flex margin="8px 0 8px" align="center" content="space-between">
                            <span className={cn['tariff--text']}>{t('repLeft')}</span>
                            <span className={cn['tariff--text--value']}>{data.remain_reports}</span>
                        </Flex>
                        <Flex margin="8px 0 8px" align="center" content="space-between">
                            <span className={cn['tariff--text']}>{t('empty.subTitle')}</span>
                        </Flex>
                        <Link to={TARIFFS}>
                            <Button className={cn.sub} color="white">
                                <span style={{ color: 'white' }}>{t('empty.choose')}</span>
                            </Button>
                        </Link>
                    </Flex>
                </>
            )}
        </Flex>
    )
}

export default Tariff
