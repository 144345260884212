// import { Option } from '@/pages/SearchPage/SearchAdapter/filters/OrSelctorFilter'
import { SelectorFilter } from '@/pages/SearchPage/SearchAdapter/filters/SelectorFilter'

import { Fetcher } from '@/_helpers/graphql/Fetcher'
import {
    BRANDS_QUERY,
    // BRAND_CATEGORIES_QUERY
} from '../../queries'
import {
    IGetBrands,
    // IGetCategories
} from '../../types'

// const getGqlFilter = (brands: string[], option: Option) => {
//     if (brands.length === 0) return ''

//     return `${option === 'option1' ? 'brand' : 'brandCategory'}: {in: [${brands.join('')}]}`
// }
const getGqlFilter = (brands: string[]) => {
    let ar: string[] = []

    brands.forEach((item) => {
        item.split(';').forEach((brand) => ar.push(brand.trim()))
    })
    return `fullText: {terms: [${ar.join(' ')}]}`
}
export const getOrBrandsFilter = (fetcher: Fetcher) => {
    return new SelectorFilter({
        platform: 'ads_ig',
        title: 'ads_explorer.filters.post_metrics.brands.brand.label',
        helper: 'ads_explorer.filters.post_metrics.brands.brand.text',
        // innerMeta: true,
        autocomplete: true,
        space: 1,
        isMulti: true,
        options: async (token: string, locale: string, query?: string) => {
            const client = fetcher.getClient()
            const brands = await client.query<IGetBrands>({
                query: BRANDS_QUERY,
                variables: {
                    brand: query || '',
                },
            })

            if (brands.loading || brands.error || !brands.data) {
                return [{ label: 'ERROR', value: 'ERROR' }]
            }

            return brands.data?.igBrands?.slice(0, 30).map((brand) => ({
                label: brand.brandName,
                value: `${brand.brandName}; ${brand.brandUsername}`, //`"${brand.brandUsername}"`,
            }))
        },
        // options2: async (token: string) => {
        //     const client = fetcher.getClient()
        //     const brandCategories = await client.query<IGetCategories>({
        //         query: BRAND_CATEGORIES_QUERY,
        //     })

        //     if (brandCategories.loading || brandCategories.error || !brandCategories.data) {
        //         return [{ label: 'ERROR', value: 'ERROR' }]
        //     }

        //     return brandCategories.data.igBrandCategories?.map((category) => ({
        //         label: category,
        //         value: `"${category}"`,
        //     }))
        // },
        beforeSend: ({ value }) => {
            return {
                name: 'filters.brand.label',
                type: 'array',
                value: value,
                gqlFilter: getGqlFilter(value),
            }
        },
    })
}
