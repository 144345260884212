import React, { FC } from 'react'
import cn from './Notifier.module.scss'
import { Notification } from './Types'
import Flex from '@/_yc/Flex'
import NotifItem from './NotifItem'
// import Text from '@/components/Text'
// import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

export interface INotifier {
    items: Array<{
        visible: boolean
        notification: Notification
        isPrimary: boolean
    }>
    close: (id: number | string) => void
    onClear: VoidFunction
}

const Notifier: FC<INotifier> = ({ items, close, onClear }) => {
    // const t = useSpaceTranslation('Notifications')

    return (
        <Flex className={cn.root}>
            {/* <Text
                onClick={onClear}
                color="violet-1"
                className={items.length > 1 ? cn['closeArea--visible'] : cn['closeArea--hidden']}
                component="div"
            >
                {t('hideAll')}
            </Text> */}
            {items.map((item) => (
                <NotifItem
                    key={item.notification.id}
                    onClose={close}
                    item={item.notification}
                    visible={item.visible}
                    isPrimary={item.isPrimary}
                />
            ))}
        </Flex>
    )
}

export default Notifier
