import moment from 'moment'
import { HistoricalMetric } from '../../Model/types'

const calculatePercentChange = (startValue: number, endValue: number) => {
    const diff = endValue - startValue
    return startValue === 0 ? 0 : diff / startValue
}

const filterMetrics = (metrics: Array<HistoricalMetric>, startDate: string) => {
    return metrics.filter((metric) => {
        return new Date(metric.created_at) >= new Date(startDate)
    })
}

const isGraphDataEmpty = (graphData: number[]) => {
    const nonZeroes = graphData.filter((d) => d !== 0)
    return nonZeroes.length === 0 || graphData.length === 0
}

const generateDataPlug = (dataLength: number) => {
    const plug = new Array(dataLength)
    plug[0] = 2

    for (let i = 1; i < dataLength; i++) {
        plug[i] = plug[i - 1] * 1.1
    }

    return plug
}

const generateCategoriesPlug = (dataLength: number) => {
    const startDate = new Date(1970)
    const endDate = new Date(1970)
    endDate.setDate(startDate.getDate() + dataLength)
    const plug = []

    for (let dt = startDate; dt < endDate; dt.setDate(dt.getDate() + 1)) {
        plug.push(moment(new Date(dt)).format())
    }

    return plug
}

function convertMinutesToDuration(minutes: number): string {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60
    const seconds = Math.round((remainingMinutes % 1) * 60)

    const parts = []

    if (hours > 0) {
        parts.push(`${hours}h`)
    }

    if (remainingMinutes > 0) {
        parts.push(`${remainingMinutes}m`)
    }

    if (seconds > 0) {
        parts.push(`${seconds}s`)
    }

    const duration = parts.join(' ')

    return duration
}

function convertTimeDurationToMinutes(duration: string): number {
    const regex = /^(\d+h)?(\d+m)?(\d+)s$/
    const match = duration.match(regex)

    if (!match) {
        throw new Error(`Invalid time duration format ${duration}`)
    }

    const hours = match[1] ? parseInt(match[1].slice(0, -1), 10) : 0
    const minutes = match[2] ? parseInt(match[2], 10) : 0
    const seconds = parseInt(match[3], 10)

    const totalMinutes = hours * 60 + minutes + Math.round(seconds / 60)

    return totalMinutes
}

export {
    calculatePercentChange,
    filterMetrics,
    isGraphDataEmpty,
    generateDataPlug,
    generateCategoriesPlug,
    convertMinutesToDuration,
    convertTimeDurationToMinutes,
}
