import React, { SVGAttributes } from 'react'
const MediaPlanner = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.6667 12H1.33333C0.596954 12 0 11.403 0 10.6667V1.33333C0 0.596954 0.596954 0 1.33333 0H10.6667C11.403 0 12 0.596954 12 1.33333V10.6667C12 11.403 11.403 12 10.6667 12ZM1.33333 1.33333V10.6667H10.6667V1.33333H1.33333ZM10 9.33333H2L4 6.66667L4.66667 7.33333L6.66667 4.66667L10 9.33333ZM3.66667 5.33333C3.11438 5.33333 2.66667 4.88562 2.66667 4.33333C2.66667 3.78105 3.11438 3.33333 3.66667 3.33333C4.21895 3.33333 4.66667 3.78105 4.66667 4.33333C4.66667 4.88562 4.21895 5.33333 3.66667 5.33333Z"
            fill="#323841"
        />
    </svg>
)

export default MediaPlanner
