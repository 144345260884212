import React, { FC } from 'react'

import cn from './VideoTable.module.scss'
import VideoCard from './VideoCard/VideoCard'
import { IVideo } from '../types'
import NoData from './NoData'

export interface IVideosTable {
    videos: Array<IVideo>
}

const VideosTable: FC<IVideosTable> = ({ videos }) => {
    if (videos.length === 0) {
        return <NoData />
    }

    return (
        <div className={cn.VideoTable}>
            {videos.map(({ id, title, views, imageLink, date }) => (
                <VideoCard
                    id={id}
                    title={title}
                    views={views}
                    imageLink={imageLink}
                    date={date}
                    key={id}
                />
            ))}
        </div>
    )
}

export default VideosTable
