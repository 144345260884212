import React, { FC, memo } from 'react'

import cn from './LineChart.module.scss'

export interface IDifs {
    dif: number
    hidden?: boolean
}

const Difs: FC<IDifs> = ({ dif, hidden }) => (
    <div
        className={hidden ? cn['dif--hidden'] : cn['dif--default']}
        style={{
            background: dif >= 0 ? '#27AE60' : '#FF6161',
        }}
    >
        {dif.toFixed(2)}%
    </div>
)

export default memo(Difs)
