import React from 'react';
import { useTranslation } from 'react-i18next'

import cn from './Success.module.scss'

const Success = ({ name, detail }) => {
	const { t } = useTranslation();
	return (
		<div className = {cn.root}>
			<h3>{t("promo.success.title", { name: name })}</h3>
			<p>{detail}</p>
		</div>
	)
}

export { Success as default }