import React, { FC, memo, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { track } from '@amplitude/analytics-browser'

import { FormProvider, useController, FieldConsumer, ErrorConsumer } from '@schematic-forms/react'
import { Flex, Text, Checkbox, OfertaLink, Button } from '@/_yc'
import FormInput from '@/components/Share/Form/FormInput'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { Str, EmailValidator, Bool } from '@schematic-forms/core'
import { LOGIN } from '@/routs'
import RequireDemoCalendly from '@/components/Share/RequireDemoButton/RequireDemoCalendly'
import { OAuthButton } from '@/components/Share'

import useWindowWidth from '@/_helpers/Resize/useWindowWidth'

import cn from './SignUp.module.scss'
import { getPlatform } from '@/_helpers/getPlatform'
import { SIGNUP } from '@/_helpers/amplitude/events'
import { EMAIL_SIGNUP } from '@/_helpers/amplitude/values'

export interface IForm {
    onSubmit: (email: string, password: string) => Promise<{ email?: string; password?: string } | undefined>
}
const fontSizeMobile = { fontSize: 12 }
const fontSize = { fontSize: 14, lHeight: 18 }
const lHeight = { lineHeight: 1 }

const Form: FC<IForm> = ({ onSubmit, ...props }) => {
    const t = useSpaceTranslation('signup')
    const { controller, submit } = useController({
        fields: {
            email: Str(true),
            password: Str(true),
            agreement: Bool(true),
        },
        validators: {
            email: EmailValidator('INCORRECT'),
            password: (value) => {
                if (!value) return
                if (value.length < 8) return new Error('TOO_SHORT')
            },
        },
        guards: {
            email: (email) => {
                if (typeof email !== 'string') return String(email)
                return email
            },
            password: (e) => e.replace(/ /g, ''),
            agreement: (e) => e,
        },
        submit: async (data, submit: IForm['onSubmit']) => {
            track(SIGNUP, {
                kindOfSignUp: EMAIL_SIGNUP,
                deviceOfSignUp: getPlatform(),
            })
            return await submit(data.email.toLowerCase(), data.password)
        },
    })

    const size = useWindowWidth('mobile')

    const errorTransform = useCallback(
        (name: string, error: string) => {
            if (!error.includes(' ') || name !== 'password') return t(`fields.${name}.errors.${error}`)
            else return error
        },
        [t]
    )
    const sub = useCallback(() => {
        submit(onSubmit)
    }, [onSubmit, submit])

    return (
        <FormProvider controller={controller}>
            <Flex margin="0 0 20px">
                <Text margin="0 8px 0 0" size={size === 'mobile' ? 12 : 14}>
                    {t('subtitle.plain_text')}
                </Text>
                <Link style={size === 'mobile' ? fontSizeMobile : fontSize} to={LOGIN}>
                    {t('subtitle.link')}
                </Link>
            </Flex>
            <FormInput
                titleSize={size === 'mobile' ? 10 : 16}
                titleColor="gray2"
                tSize={size === 'mobile' ? 14 : 18}
                margin="0 0 10px"
                name="email"
                title={t('fields.email.label')}
                placeholder={t('fields.email.label')}
                errorTransform={errorTransform}
                className={size === 'mobile' ? cn['field--mobile'] : cn.field}
                removeSpace={true}
            />
            <FormInput
                titleSize={size === 'mobile' ? 10 : 16}
                titleColor="gray2"
                name="password"
                title={t('fields.password.label')}
                placeholder={t('fields.password.label')}
                errorTransform={errorTransform}
                margin="0 0 15px"
                type="password"
                tSize={size === 'mobile' ? 14 : 18}
                className={size === 'mobile' ? cn['field--mobile'] : cn.field}
                removeSpace={true}
            />
            <FieldConsumer field="agreement">
                {({ value, setValue, error }) => (
                    <Flex
                        margin="0 0 20px"
                        onClick={() => setValue(!value)}
                        align="center"
                        className={error ? cn.error : undefined}
                    >
                        <Checkbox className={cn.check} value={value} />
                        <Text margin="0 0 0 10px" size={size === 'mobile' ? 10 : 12} color="secondary">
                            {t('fields.agreements.label')}
                            <OfertaLink>{t('fields.agreements.link')}</OfertaLink>
                        </Text>
                    </Flex>
                )}
            </FieldConsumer>
            <ErrorConsumer>
                {({ hasError }) => (
                    <Button
                        className={
                            !hasError
                                ? size === 'mobile'
                                    ? cn['btn--mobile']
                                    : cn.btn
                                : size === 'mobile'
                                ? cn['btn--disabled--mobile']
                                : cn['btn--disabled']
                        }
                        onClick={sub}
                        disabled={hasError}
                        width={'100%'}
                    >
                        <Text style={lHeight} color="white">
                            {t('sign_up_button_label')}
                        </Text>
                    </Button>
                )}
            </ErrorConsumer>

            <div className={size === 'mobile' ? undefined : cn.demo}>
                <RequireDemoCalendly margin={size === 'mobile' ? '20px 0 0' : '80px 0 0'} />
            </div>

            <Flex margin="18px 0" content="space-between" align="center">
                <Flex grow className={cn.line} />
                <Text size={12} color="secondary" margin="0 14px">
                    {t('login.or', undefined, true)}
                </Text>
                <Flex grow className={cn.line} />
            </Flex>
            <OAuthButton margin="0" isSignUp />
        </FormProvider>
    )
}

export default memo(Form)
