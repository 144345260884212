import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.36757 11.4857L5.80324 11.7187C6.47841 12.0796 7.2324 12.2679 7.998 12.2668L8 12.2668C10.5774 12.2668 12.6667 10.1775 12.6667 7.60009C12.6667 5.02268 10.5774 2.93343 8 2.93343C5.42259 2.93343 3.33334 5.02268 3.33334 7.60009V7.6021C3.33219 8.3677 3.52048 9.12169 3.88145 9.79686L4.11437 10.2325L3.75622 11.8439L5.36757 11.4857ZM2.00001 13.6001L2.70561 10.4255C2.24093 9.55632 1.99852 8.58568 2.00001 7.60009C2.00001 4.2863 4.68621 1.6001 8 1.6001C11.3138 1.6001 14 4.2863 14 7.60009C14 10.9139 11.3138 13.6001 8 13.6001C7.01442 13.6016 6.04377 13.3592 5.17461 12.8945L2.00001 13.6001Z"
                fill="#929EB0"
            />
        </svg>
    )
}
