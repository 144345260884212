import React, { ForwardRefRenderFunction, HTMLAttributes, ReactNode, Ref } from 'react'

import cn from './PopupPapper.module.scss'

export interface IPopupPapper extends HTMLAttributes<HTMLDivElement> {
    margin?: string
    children: ReactNode
    width?: number | string
    ref?: Ref<HTMLDivElement>
}

const PopupPapper: ForwardRefRenderFunction<HTMLDivElement, IPopupPapper> = (props, ref) => {
    const { margin, children, className, style, width, ...rest } = props

    return (
        <div
            ref={ref}
            className={`${cn.root} ${className || ''}`}
            style={{
                ...style,
                width,
                margin,
            }}
            {...rest}
        >
            {props.children}
        </div>
    )
}

export default PopupPapper
