import Button from '@/components/Button'
import Text from '@/components/Text'
import Counter from '@/Layouts/PageLayout/header/Counter'
import { TARIFFS } from '@/routs'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { Flex } from '@/_yc'
import React, { memo, FC } from 'react'
import { Link } from 'react-router-dom'
import ym from 'react-yandex-metrika'

import cn from './PromoUpgrade.module.scss'

export interface IPromoUpgrade {
    margin?: number | string
    target?: { name: string; category: string; action: string }
}

const PromoUpgrade: FC<IPromoUpgrade> = (props) => {
    const t = useSpaceTranslation('intersections.dashboards.hidder')
    const useGlob = () => {
        if (props.target && (global as any).isProd) {
            ;(global as any).gtag('event', props.target.name, {
                event_category: props.target.category,
                event_action: props.target.action,
            })
            ym('reachGoal', props.target.category)
        } else if (props.target) {
            console.log(props.target)
        }
    }
    return (
        <Flex margin={props.margin} className={cn.root} align="center">
            <Text margin="0 10px 0 10px" color="gray-3" semibold fSize={12}>
                {t('free')}
            </Text>

            <div className={cn.divider} />

            <Counter style={{ padding: '12px' }} />

            <Link to={TARIFFS}>
                <Button margin="0" size="small" className={cn.button} variant="primary" onClick={useGlob}>
                    {t('upgrade')}
                </Button>
            </Link>
        </Flex>
    )
}

export default memo(PromoUpgrade)
