import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.99992 17.3334C7.73997 17.337 6.49594 17.0519 5.36325 16.5001C4.94871 16.2985 4.55153 16.0629 4.17575 15.7959L4.06159 15.7126C3.0281 14.9498 2.18315 13.9603 1.59159 12.8201C0.9797 11.64 0.662343 10.3294 0.666545 9.00008C0.666545 4.39771 4.39755 0.666748 8.99992 0.666748C13.6023 0.666748 17.3333 4.39771 17.3333 9.00008C17.3375 10.3287 17.0204 11.6387 16.4091 12.8184C15.8184 13.9579 14.9746 14.9471 13.9424 15.7101C13.5531 15.9951 13.1399 16.246 12.7074 16.4601L12.6408 16.4934C11.5074 17.0482 10.2618 17.3355 8.99992 17.3334ZM8.99992 13.1667C7.75115 13.1643 6.60631 13.8618 6.03575 14.9726C7.90358 15.8977 10.0963 15.8977 11.9641 14.9726V14.9684C11.3928 13.8588 10.2479 13.1629 8.99992 13.1667ZM8.99992 11.5001C10.805 11.5024 12.4694 12.4752 13.3574 14.0467L13.3699 14.0359L13.3816 14.0259L13.3674 14.0384L13.3591 14.0451C15.4666 12.2243 16.2202 9.28536 15.2488 6.67517C14.2774 4.06498 11.7858 2.33368 9.00076 2.33368C6.21568 2.33368 3.72408 4.06498 2.75271 6.67517C1.78134 9.28536 2.53492 12.2243 4.64242 14.0451C5.53097 12.4743 7.19522 11.5023 8.99992 11.5001ZM8.99992 10.6667C7.15897 10.6667 5.66659 9.17436 5.66659 7.33342C5.66659 5.49247 7.15897 4.00008 8.99992 4.00008C10.8409 4.00008 12.3333 5.49247 12.3333 7.33342C12.3333 8.21747 11.9821 9.06532 11.3569 9.69044C10.7318 10.3156 9.88398 10.6667 8.99992 10.6667ZM8.99992 5.66675C8.07945 5.66675 7.33325 6.41294 7.33325 7.33342C7.33325 8.25389 8.07945 9.00008 8.99992 9.00008C9.9204 9.00008 10.6666 8.25389 10.6666 7.33342C10.6666 6.41294 9.9204 5.66675 8.99992 5.66675Z"
                fill="#8F8F8F"
            />
        </svg>
    )
}
