import constants from './constants.js'
import TarConst from './tariffConstants.js'

export function snacks(state = { msg: null, type: null }, action) {
	switch(action.type) {
		case constants.SNACK.SUCCESS: return { 
			msg: action.payload, 
			type: "success",  
			options: action.options
		}
		case constants.SNACK.ERROR: return { msg: action.payload, type: "error" }
		case constants.SNACK.INFO: return { msg: action.payload, type: "info" }
		case constants.SNACK.WARNING: return { msg: action.payload, type: "warning" }
		case constants.SNACK.LOADING: return { msg: action.payload, type: "loading" }
		case constants.SNACK.CLEAR: return { msg: null, type: null }
		case TarConst.TARIFF_PAGE_OPEN_MODEL: return {
			msg: null,
			type: null
		}
		default: return state
	}
}