import React from 'react'

import cn from './CircleLoader.module.scss'

const CircleLoader = () => {
    return (
        <>
            <div className={cn['lds-ring']}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </>
    )
}
export default CircleLoader
