import React, { FC, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import i18n from 'i18next'

import Avatar from '@/Layouts/ReportLayout/Content/Avatar/Avatar'
import MainInfo from '@/Layouts/ReportLayout/Content/MainInfo/MainInfo'
import Metrics from '@/Layouts/ReportLayout/Content/Metrics/Metrics'
import { useSpaceTranslation } from '@/_helpers'
import { RootStore } from '@/_helpers/StoreType'
import Flex from '@/_yc/Flex'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import { useQuery } from '@apollo/client'
// import { getMonth } from '@/_helpers/parseDate_ts'
// import { getLang } from '@/_helpers/getLang'

import { VkParsingStatusResponseData } from '../types'
import { Platform } from '../../types'
import { GeneralInfo } from '../../BloggerReport/Title/GeneralInfo/GeneralInfo'
import { GET_VK_PARSING_STATUS } from '../queries'

import cn from './ReportHeader.module.scss'

interface IReportHeader {
    id: string
    type: string
}
export const ReportHeader: FC<IReportHeader> = ({ id, type }) => {
    const t = useSpaceTranslation('channel_report')

    const [status, setStatus] = useState<boolean>(false)
    const [updatedDate, setUpdatedDate] = useState<string | undefined>()

    const {
        loading: loadingStatus,
        data: dataStatus,
        error: errorStatus,
    } = useQuery<VkParsingStatusResponseData>(GET_VK_PARSING_STATUS, {
        variables: {
            id: id,
            type: type,
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        skip: false,
    })

    useEffect(() => {
        if (!loadingStatus && !errorStatus && dataStatus) {
            if (dataStatus.parsing_vk_request.length > 0 && dataStatus.parsing_vk_request[0].state_done)
                setStatus(dataStatus.parsing_vk_request[0].state_done)
            else setStatus(false)

            if (dataStatus.parsing_vk_request.length > 0 && dataStatus.parsing_vk_request[0].updated_at)
                setUpdatedDate(new Date(dataStatus.parsing_vk_request[0].updated_at).toLocaleDateString(i18n.language))
            else setUpdatedDate(undefined)
        }
    }, [loadingStatus, dataStatus, errorStatus])

    const metrics = useSelector((store: RootStore) => {
        const userInfo = store.report.rawVkData?.getVkReport?.data.vk_account[0]

        if (!userInfo) {
            return {
                name: '',
                verified: false,
                link: '',
                topics: [],
                avatar: '',
            }
        }

        const name = userInfo.full_name
        const avatar = userInfo.avatar_link
        const link = `https://www.vk.ru/${userInfo.username}`

        const loc = userInfo.country + (userInfo.city ? ', ' + userInfo.city : '')
        return {
            name,
            verified: userInfo.verified === 0 ? false : true,
            link,
            topics: userInfo.vk_accounttopic.map((item) => item.vk_topic.name),
            avatar,
            country: loc,
            email: userInfo.contacts,
        }
    })

    const restOfData = useSelector((store: RootStore) => {
        const metrics = store.report.rawVkData?.getVkReport?.data.vk_account[0]

        if (!metrics) {
            return []
        }

        let daysAgo = null
        if (metrics.last_publication_date) {
            const lastVideoDate = new Date(metrics.last_publication_date)
            daysAgo = Math.floor((new Date().getTime() - lastVideoDate.getTime()) / (1000 * 3600 * 24))
        }

        // const publDate = new Date(metrics.last_publication_date)
        // const publDateString =
        //     publDate.getDay() + ' ' + getMonth(publDate.getMonth(), getLang()) + ' ' + publDate.getFullYear()
        const rest = [
            {
                value: (metrics.er_views * 100).toFixed(2) + '%',
                name: t('metrics.er_views'),
            },
            {
                value: metrics.median_views_m,
                name: t('metrics.avg_views'),
            },
            {
                value: metrics.posts,
                name: t('metrics.posts_count'),
            },
            // {
            //     value: metrics.last_publication_date && publDateString,
            //     name: t('metrics.last_publ'),
            // },
        ]

        if (daysAgo !== null) {
            rest.push({
                value: daysAgo,
                name: t('metrics.last_post'),
            })
        }

        return rest
    })

    const size = useWindowWidth('mobile')
    const isMobile = size === 'mobile'

    return (
        <div className={cn.root}>
            <GeneralInfo
                country={!isMobile ? metrics.country : undefined}
                // email={!isMobile ? metrics.email : undefined}
                updatedDate={updatedDate}
                inProgress={status}
                isDemo={false}
            />
            <div className={cn.header}>
                <Flex content="flex-start">
                    <Avatar type={Platform.Vk} url={metrics.avatar} />
                    <MainInfo
                        data={{
                            name: metrics.name,
                            verified: metrics.verified,
                            link: metrics.link,
                            topics: metrics.topics,
                        }}
                    />
                </Flex>

                <Metrics restOfData={restOfData} />
            </div>
        </div>
    )
}
