import Text from '@/components/Text'
import React from 'react'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

import cn from './NoResults.module.scss'
import { useSearch } from '@/pages/SearchPage/SearchAdapter'
import Button from '@/components/Button'
import Refresh from './Refresh'

const NoResults = () => {
    const t = useSpaceTranslation('Influencer_explorer.no_results')
    const { clearFilters, clearSearch, restart } = useSearch()

    const onClick = () => {
        clearFilters()
        clearSearch()
        restart()
    }

    return (
        <div className={cn.root}>
            <div className={cn.icon}>
                <Refresh />
            </div>
            <Text margin="24px 0 0 0" fSize={20} semibold>
                {t('header')}
            </Text>
            <Text margin="8px 0 0 0" color="gray-3">
                {t('text')}
            </Text>
            <Button onClick={onClick} margin="24px 0 0 0">
                {t('clear')}
            </Button>
        </div>
    )
}

export default NoResults
