import React, { memo, FC, useMemo, HTMLAttributes, useCallback, SyntheticEvent, useEffect, useRef } from 'react'
import { Stylable } from '@/Types'
import useClass from '@/_helpers/Hooks/useClass'
import useOpenClose from '@/_helpers/useOpenClose'
import LoadingAvatar from '@/components/LoadingAvatar/LoadingAvatar'

import cn from './Avatar.module.scss'
import Icons from '../Icons'
import { Platform } from '@/pages/Reports/types'

type platformIcon = 'instagram-avatar' | 'youtube-avatar' | 'twitch-avatar' | 'vk-avatar'

export interface IAvatar extends Stylable, HTMLAttributes<HTMLImageElement> {
    src: string
    size?: number | string
    alt?: string
    type?: Platform
    loadingAvatarWidth?: string
}

const Avatar: FC<IAvatar> = ({ size, margin, style, className, onLoad, alt, ...props }) => {
    const ref = useRef<HTMLImageElement>(null)
    const [isLoaded, setLoaded] = useOpenClose()

    const root = useClass(cn.root, className)

    const st = useMemo(
        () => ({
            ...(size && {
                minWidth: size,
                maxWidth: size,
                minHeight: size,
                maxHeight: size,
            }),
            margin: margin,
            ...style,
        }),
        [size, margin, style]
    )

    const load = useCallback(
        (e: SyntheticEvent<HTMLImageElement, Event>) => {
            setLoaded()
            onLoad && onLoad(e)
        },
        [setLoaded, onLoad]
    )

    useEffect(() => {
        if (!props.src || props.src.length === 0) return
        if (ref.current && ref.current.complete && ref.current.naturalWidth > 0) {
            setLoaded()
        }
    }, [setLoaded, ref, props.src])
    return (
        <div className={root} style={st}>
            {!isLoaded && <LoadingAvatar width={props.loadingAvatarWidth} />}
            <img
                key={props.src}
                style={!isLoaded ? { display: 'none' } : {}}
                {...props}
                onLoad={load}
                alt={alt ?? 'Avatar'}
                ref={ref}
            />
            {/* Temporary solution until redesign. No twitch icon in design */}
            {(props.type === Platform.Twitch || props.type === Platform.Vk) && (
                <div className={cn.twitchAvatar}>
                    <Icons name={`${props.type.toLowerCase()}-avatar` as platformIcon} />
                </div>
            )}
            {props.type === Platform.Tg && (
                <div className={cn.twitchAvatar}>
                    <Icons name={`ie-telegram` as platformIcon} width={16} height={16} />
                </div>
            )}
            {props.type !== Platform.Twitch && props.type && (
                <Icons name={`${props.type.toLowerCase()}-circle` as platformIcon} className={cn.platformType} />
            )}
        </div>
    )
}

export default memo(Avatar)
