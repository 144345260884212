import { TimeSpanType } from '../Model/types'

const getStartDate = (timespan: TimeSpanType): string => {
    const timespanMonths = timespan === 'month' ? 1 : timespan === '6months' ? 6 : 12

    const today = new Date()
    const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDay())
    startDate.setMonth(today.getMonth() - timespanMonths)

    return startDate.toISOString()
}

export default getStartDate
