import Flex from '@/_yc/Flex'
import React, { FC } from 'react'
import Text from '@/components/Text'

import cn from './MobilePopup.module.scss'
import Icons from '../Icons'

interface MobilePopupProps {
    children: React.ReactNode
    isOpen: boolean
    onClose: () => void
    title: string
}

export const MobilePopup: FC<MobilePopupProps> = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return <></>

    return (
        <>
            <div className={cn.hider}></div>
            <div className={cn.mobilePopup}>
                <div className={cn.header}>
                    <Flex content="space-between">
                        <Text fSize={16}>{title}</Text>
                        <Icons name="close" style={{ cursor: 'pointer' }} onClick={onClose} />
                    </Flex>
                    <hr className={cn.divider}></hr>
                </div>

                <div className={cn.content}>{children}</div>
            </div>
        </>
    )
}
