import React, {
    ForwardRefRenderFunction,
    HTMLAttributes,
    useCallback,
    ReactNode,
    Ref,
    useMemo,
} from 'react'

import cn from './Button.module.scss'

export interface IButton extends HTMLAttributes<HTMLButtonElement> {
    disabled?: boolean
    color?: 'optional' | 'white' | 'blue'
    size?: 'small' | 'medium' | 'huge'
    children: ReactNode
    ref?: Ref<HTMLButtonElement>
    margin?: string
    width?: string | number
    onClick?: VoidFunction
    type?: 'button' | 'submit'
}

const Button: ForwardRefRenderFunction<HTMLButtonElement, IButton> = (
    {
        disabled,
        color,
        style,
        size,
        className,
        onClick,
        children,
        margin,
        width,
        ...rest
    },
    ref
) => {
    const styleClass = useMemo(() => {
        if (color) return cn[`root--${color}`]
        return cn['root--active']
    }, [color])

    const sizeClass = useMemo(() => {
        return cn[size || 'medium']
    }, [size])

    const click = useCallback(() => {
        if (!disabled && onClick) onClick()
    }, [disabled, onClick])

    return (
        <button
            ref={ref}
            onClick={click}
            className={`${styleClass} ${sizeClass} ${className || ''} ${
                disabled ? cn['root--disabled'] : ''
            }`}
            style={{ margin, width, ...style }}
            {...rest}
        >
            {children}
        </button>
    )
}

export default Button
