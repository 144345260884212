import React from 'react'

import Icons from '@/components/Icons'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { SelectorFilter } from '../../../../filters/SelectorFilter'
import { GET_TG_TOPICS } from '../../utils/queries'
import { ITopicsResponse } from '../../utils/types'
import { toQueryWithOR } from '../../../youtube/utils/queries/toQueryWithOR'

const getGqlFilter = (topics: string[]) => {
    if (!topics || topics.length === 0) return ''
    const ids = topics.map((v) => v.split(',')).flat()

    const filterName = 'channel_topics'
    const filterId = 'topic_id'
    return toQueryWithOR(ids, filterName, filterId)
}

export const getInterestsFilter = (fetcher: Fetcher, isMain?: boolean) => {
    return new SelectorFilter({
        platform: 'infl_vk',
        title: 'filters.cat.fields.channel.label',
        autocomplete: !isMain,
        helper: 'filters.cat.fields.channel.text',
        icon: <Icons name="filter--interests--main" />,
        space: 1,
        id: 'interests_tg',
        isMulti: true,
        isAvailable: true,
        soon: false,
        options: async (token: string) => {
            const client = fetcher.getClient()
            const res = await client.query<ITopicsResponse>({ query: GET_TG_TOPICS }).then((res) => {
                const topics = res.data?.telegram_topic ?? []
                return topics.map((item) => {
                    return { label: item.name, value: item.id.toString() }
                })
            })
            return res
        },
        beforeSend: ({ value }) => ({
            name: 'interests',
            type: 'array',
            value: value,
            gqlFilter: getGqlFilter(value),
        }),
    })
}
