import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { FilterCategory } from '../../../filters/FilterCategory'
import { getCommentsFilter } from './postMetrics/comments'
import { getPostDateFilter } from './postMetrics/date'
import { getFollowersFilter } from './postMetrics/followers'
import { getGeoFilter } from './postMetrics/geo'
import { getLikesFilter } from './postMetrics/likes'
import { getOrBrandsFilter } from './postMetrics/orBrands'
import { getUsernameFilter } from './postMetrics/userName'
import { getKeywordFilter } from './postMetrics/keywords'

export const getFilters = (fetcher: Fetcher) => {
    return {
        post_metrics: new FilterCategory('', {
            orBrands: getOrBrandsFilter(fetcher),
            keywords: getKeywordFilter(),
            likes: getLikesFilter(),
            comments: getCommentsFilter(),
            userName: getUsernameFilter(),
            postDate: getPostDateFilter(),
            followers: getFollowersFilter(),
            geo: getGeoFilter(),
        }),
    }
}
