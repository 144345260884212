import React from 'react'
import styles from './modal.module.scss'
import LoadCircle from '@/components/LoadCircle/LoadCircle'
import { ReactComponent as Success } from '../../icons/ok.svg'
import { ReactComponent as Loader } from '../../icons/loader.svg'
import { ReactComponent as Error } from '../../icons/Error.svg'
import Text from '@/components/Text'

type ModalProps = {
    isOpen: boolean
    onClose: () => void
    title: string
    description: string
    buttonText: string
    isLoading: boolean
    isPaymentSuccessful: boolean | null
    buttonDisabled: boolean
    error: boolean
}

const ModalSubscription: React.FC<ModalProps> = ({
    isOpen,
    onClose,
    title,
    description,
    buttonText,
    isLoading,
    isPaymentSuccessful,
    buttonDisabled,
    error,
}) => {
    if (!isOpen) return null

    const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            onClose()
        }
    }

    return (
        <div className={styles.overlay} onClick={handleOverlayClick}>
            <div className={styles.modal}>
                <div className={styles.icon}>
                    {isLoading ? <LoadCircle /> : null}
                    {!isLoading && isPaymentSuccessful ? <Success /> : null}
                    {error ? <Error /> : null}
                </div>
                <Text component="h2" semibold fSize="20px">
                    {title}
                </Text>
                <Text fSize="14px">{description}</Text>
                <button className={styles.button} onClick={onClose} disabled={buttonDisabled}>
                    {isLoading ? <Loader className={styles['spinner']} /> : buttonText}
                </button>
            </div>
        </div>
    )
}

export default ModalSubscription
