import React, { PureComponent, ComponentType } from 'react'
import { Subtract } from 'utility-types'

export interface IProvidedProps<DataType>{
    data: DataType | null
    loaded: boolean
    error: boolean
}

export default <DataType, Props extends IProvidedProps<DataType>>(
    PullService: (p: Subtract<Props, IProvidedProps<DataType>>
) => Promise<DataType>) => (Component: ComponentType<Props>) => 
class withAPICall extends PureComponent<Subtract<Props, IProvidedProps<DataType>>, IProvidedProps<DataType>> {

    state: IProvidedProps<DataType> = {
        data: null,
        error: false,
        loaded: false
    }

    mounted: boolean = false;

    componentDidMount(){
        PullService(this.props)
            .then(data => {
                this.setState({
                    data,
                    loaded: true
                })
            })
            .catch(() => {
                this.setState({
                    error: true,
                    loaded: true
                })
            })
    }

    render(){
        return (
            <Component {...this.state} {...this.props as Props} />
        )
    }
}
