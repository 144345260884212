import React, { FC } from 'react'

import cn from './DemoPosts.module.scss'
import DemoHidder from '../DemoHidder'
import Buttons from '../DemoHidder/Buttons'
// import Text from '@/_yc/Text'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import Flex from '@/_yc/Flex'
import Post from '@/pages/Reports/BloggerReport/Cards/Post'
import { PostType } from '@/pages/Reports/BloggerReport/Cards/Post/Post'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import { Tooltip } from '@yoloker/ui-collection'

const posts: PostType[] = [
    {
        shortcode: 'B7yD_13A7A5',
        comments: 33413,
        date: '2020-01-26T12:01:59',
        text: 'Любительницы красной помады меня поймут😂😂😂😂😂💄\n.\nLADY IN RED epta\n.\nОТМЕЧАЙ НАШИХ👇🏽👇🏽👇🏽👇🏽 .\n#настяивлеева #косметика #_agentgirl_ #девушки #vine #жиза #вино #fun #cosmetics',
        img: 'https://cdn.yoloco.ru/posts/46829718/2229862346799689785.jpe',
        likes: 2428889,
    },
    {
        shortcode: 'B6voz9XAkYw',
        comments: 7863,
        date: '2019-12-31T16:12:02',
        text: 'Итоги подводить не буду! Вы лучше меня всё знаете! Но кое-что выделить все таки хочу! В этом году меня взяли в жёны😂👰🏼Человечка, который не умеет готовить,не разбирается в хозяйстве и все время в командировках!🤦🏼‍♀️ Чудо, не иначе! @sayonaraboy ты самый лучший муж и друг на всем белом свете! ❤️ И потому, на этой волне, я всем желаю ЛЮБВИ! ЛЮБВИ к СЕБЕ (с неё кстати все и начинается), к окружающим, близким, планете! ЛЮБВИ к жизни! С НОВЫМ ГОДОМ!!!🎄🥂🍾🥰 Ваша, Ивлиентэз! \n#2020',
        img: 'https://cdn.yoloco.ru/posts/46829718/2211165434500236848.jpe',
        likes: 2356684,
    },
    {
        shortcode: 'ByU5UsKAKku',
        comments: 13061,
        date: '2019-06-05T11:06:40',
        text: '💋🍦',
        img: 'https://cdn.yoloco.ru/posts/46829718/2059523040068282670.jpe',
        likes: 2246189,
    },
]

export interface IDemoPosts {
    id: number | string
}

const DemoPosts: FC<IDemoPosts> = (props) => {
    const t = useSpaceTranslation('report.headers.posts.ops')

    const size = useWindowWidth('desktop')

    const isDesktop = size === 'desktop'

    return (
        <DemoHidder id={props.id} grid={isDesktop ? '1fr' : undefined}>
            {({ open, isOpen }) => (
                <div className={cn.root}>
                    <Flex align={'center'} margin={'0 0 12px 0'}>
                        <div className={cn.header}>{t('popular')}</div>
                        <div className={cn.tooltip}>
                            <Tooltip text={t('tooltips.popular')}></Tooltip>
                        </div>
                    </Flex>
                    <Flex margin="0 0 20px" className={cn.carousel} content={isDesktop ? 'space-between' : undefined}>
                        {posts.map((item) => (
                            <div key={item.shortcode} className={cn.wrapper}>
                                <Post className={cn.post} width={'100%'} hidden={!isOpen} {...item} />
                            </div>
                        ))}
                        {size !== 'desktop' && (
                            <div className={isOpen ? cn['btns--hidden'] : cn['btns--visible']}>
                                <Buttons id={props.id} showBlock={open} />
                            </div>
                        )}
                    </Flex>
                </div>
            )}
        </DemoHidder>
    )
}

export default DemoPosts
