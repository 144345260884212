import { IFilters } from '../../types'

export const getFilterQueries = (filters: IFilters) => {
    let brandsAr = []
    let keyWrod
    const filterQueries: string[] = []

    for (const [, filterGroupValue] of Object.entries(filters)) {
        for (const [, value] of Object.entries(filterGroupValue)) {
            if (value && Array.isArray(value)) {
                for (let loc of value) {
                    loc.gqlFilter && filterQueries.push(loc.gqlFilter)
                }
            }

            if (value?.name === 'filters.brand.label') {
                value && value.gqlFilter && brandsAr.push(value.value)
            }

            if (value?.name === 'metrics.keyword') {
                if (value && value.gqlFilter) keyWrod = value.value
            }

            if (value?.name !== 'filters.brand.label' && value?.name !== 'metrics.keyword') {
                value && value.gqlFilter && filterQueries.push(value.gqlFilter)
            }
        }
    }
    let ar: string[] = []
    //@ts-ignore
    brandsAr.flat().forEach((item: string) => {
        item.split(';').forEach((brand) => ar.push(brand.trim()))
    })

    const addQuotes = (s: string) => {
        const ar = s.split('')
        if (ar[0] === '"' && ar[ar.length - 1] === '"') return `${s}`
        else {
            return `"${s}"`
        }
    }
    const brandFilter = brandsAr.length > 0 ? `[${ar.map((item) => addQuotes(item))}]` : '' //brandsAr is string[][], make string[]
    const keyFilter = keyWrod ? `, [${keyWrod}]` : ''
    filterQueries.push(`fullText: {And: [${brandFilter} ${keyFilter}]}`)

    return filterQueries
}
