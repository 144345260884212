import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.75 2.3999L3 6.04276V19.157H7.5V22.7999L11.25 19.157H14.25L21 12.5999V2.3999H6.75ZM19.5 11.8713L16.5 14.7856H13.5L10.875 17.3356V14.7856H7.5V3.85705H19.5V11.8713Z"
                fill="#5D697D"
            />
            <path d="M17.25 6.40705H15.75V10.7785H17.25V6.40705Z" fill="#5D697D" />
            <path d="M13.125 6.40705H11.625V10.7785H13.125V6.40705Z" fill="#5D697D" />
        </svg>
    )
}
