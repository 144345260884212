import { IFilters } from '@/pages/SearchPage/SearchAdapter/bloggers/youtube/types'

interface IFilterMap {
    [name: string]: string
}

export const getFilterQueries = (filters: IFilters) => {
    const filterMap: IFilterMap = {}

    for (const [, filterGroupValue] of Object.entries(filters)) {
        for (const [, value] of Object.entries(filterGroupValue)) {
            if (!value || !value.gqlFilter) continue
            const key = value.gqlFilter.split(':')[0].trim()
            const val = value.gqlFilter.substring(value.gqlFilter.indexOf(':') + 1).trim()

            if (key in filterMap) {
                if (key === '_or') {
                    const oldOr = filterMap[key]
                    delete filterMap[key]
                    filterMap['_and'] = `[{_or: ${oldOr}},{_or: ${val}}]`
                } else {
                    filterMap[key] = `{${filterMap[key].slice(1, -1)}, ${val.slice(1, -1)}}`
                }
            } else {
                filterMap[key] = val
            }
        }
    }

    const filterQueries = Object.entries(filterMap).map((f) => `${f[0]}: ${f[1]}`)

    return filterQueries
}
