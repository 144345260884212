import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { RootStore } from '@/_helpers/StoreType'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import { Flex } from '@/_yc'
import MultiBarChart from '@/Layouts/ReportLayout/Content/ResuableWidgets/MultiBarChart'
import DonutChart from '@/Layouts/ReportLayout/Content/ResuableWidgets/DonutChart'
import { shortNum } from '@/_helpers/_graphs/_tech'
import { IDemographicsItem } from '@/Layouts/ReportLayout/Content/ResuableWidgets/MultiBarChart/types'
import MoreModal from '@/components/MoreModal'
import { Platform } from '@/pages/Reports/types'
import ListOfAccounts from '@/Layouts/ReportLayout/Content/ResuableWidgets/AccountCards'
import { IRawTikTokData } from '../types'
// import {Text} from '@/_yc'
import Text from '@/components/Text'
const style = {
    borderRadius: '10px',
    backgroundColor: 'white',
}

const Demographics = () => {
    const { t } = useTranslation()
    const tiktokData: IRawTikTokData | undefined = useSelector((store: RootStore) => store.report.rawTikTokData)

    const countries = useMemo(() => {
        if (!tiktokData?.audience_followers.data.audience_geo?.countries) return { success: false }
        if (tiktokData?.audience_followers.data.audience_geo?.countries.length === 0) return { success: false }
        const sortedAr = [...tiktokData?.audience_followers.data.audience_geo?.countries].sort(
            (a, b) => b.weight - a.weight
        )
        return {
            success: true,
            data: sortedAr.map((item): IDemographicsItem => {
                return {
                    label: item.name ?? 'Other languages',
                    items: [
                        {
                            qty: 0,
                            share: item.weight,
                            color: '#6071FF',
                        },
                    ],
                }
            }),
        }
    }, [tiktokData])

    const languages = useMemo(() => {
        if (!tiktokData?.audience_followers.data.audience_languages) return { success: false }
        if (tiktokData?.audience_followers.data.audience_languages.length === 0) return { success: false }
        const sortedAr = [...tiktokData?.audience_followers.data.audience_languages].sort((a, b) => b.weight - a.weight)
        return {
            success: true,
            data: sortedAr.map((item): IDemographicsItem => {
                return {
                    label: item.name ?? 'Other languages',
                    items: [
                        {
                            qty: 0,
                            share: item.weight,
                            color: '#6071FF',
                        },
                    ],
                }
            }),
        }
    }, [tiktokData])
    const ageGender = useMemo(() => {
        if (!tiktokData?.audience_followers.data.audience_genders_per_age) return { success: false }
        if (tiktokData?.audience_followers.data.audience_genders_per_age.length === 0) return { success: false }

        return {
            success: true,
            data: tiktokData?.audience_followers.data.audience_genders_per_age.map((item) => {
                return {
                    label:
                        item.code[item.code.length - 1] !== '-'
                            ? item.code
                            : '>' + item.code.substring(0, item.code.length - 1),
                    items: [
                        {
                            qty: 0,
                            share: item.male,
                            color: '#6071FF',
                        },
                        {
                            qty: 0,
                            share: item.female,
                            color: '#FF659C',
                        },
                    ],
                }
            }),
        }
    }, [tiktokData])

    const age_gender_Legend = useMemo(() => {
        return [
            {
                id: 2,
                value: t('report_layout.demographics.age_gender.legend.men'),
                color: '#6071FF',
            },
            {
                id: 1,
                value: t('report_layout.demographics.age_gender.legend.women'),
                color: '#FF659C',
            },
        ]
    }, [t])

    const genderDist = useMemo(() => {
        if (!tiktokData?.audience_followers.data.audience_genders) return { success: false }
        if (tiktokData?.audience_followers.data.audience_genders.length !== 2) return { success: false }

        return {
            success: true,
            data: [
                {
                    label: t('report_layout.demographics.gender_dist.legend.men'),
                    label_color: `#6071FF`,
                    value:
                        tiktokData?.audience_followers.data.audience_genders[0].code === 'MALE'
                            ? tiktokData?.audience_followers.data.audience_genders[0].weight
                            : tiktokData?.audience_followers.data.audience_genders[1].weight,
                    color: '#6071FF',
                    percent_color: `#E2E6FF`,
                },
                {
                    label: t('report_layout.demographics.gender_dist.legend.women'),
                    label_color: `#FF659C`,
                    value:
                        tiktokData?.audience_followers.data.audience_genders[0].code === 'MALE'
                            ? tiktokData?.audience_followers.data.audience_genders[1].weight
                            : tiktokData?.audience_followers.data.audience_genders[0].weight,
                    color: '#FF659C',
                    percent_color: `#FFDCE2`,
                },
            ],
        }
    }, [t, tiktokData])

    const size = useWindowWidth('mobile')
    const isMobile = size === 'mobile'

    const reachData = useMemo(() => {
        const data = tiktokData?.audience_followers
        if (!data?.success) return { success: false }
        if (!data || !data.data || !data.data.audience_reachability) return { success: false }
        if (data.data.audience_reachability.length === 0) return { success: false }

        const transformWeight = (str: string) => {
            if (!str || str.length === 0) return ''

            if (str[0] === '-') return '>' + shortNum(parseInt(str.substring(1)))
            if (str[str.length - 1] === '-') return '<' + shortNum(parseInt(str.substring(0, str.length - 1)))
            const nums = str
                .split('-')
                .map((item) => {
                    return shortNum(parseInt(item.trim()))
                })
                .join(' - ')

            return nums
        }
        return {
            success: true,
            data: data.data.audience_reachability.map((reach) => {
                return {
                    label: transformWeight(reach.code),
                    items: [
                        {
                            qty: 0,
                            share: reach.weight,
                            color: '#6071FF',
                        },
                    ],
                }
            }),
        }
    }, [tiktokData])

    const similarAccounts = useMemo(() => {
        if (!tiktokData?.audience_followers.data.audience_lookalikes) return { success: false }
        if (tiktokData?.audience_followers.data.audience_lookalikes.length === 0) return { success: false }

        return {
            success: true,
            data: tiktokData?.audience_followers.data.audience_lookalikes.map((item) => {
                return {
                    id: item.user_id,
                    avatar: item.picture ?? '',
                    title: item.fullname ? item.fullname : item.username ? item.username : '',
                    subscriber_count: item.followers,
                    published_at: undefined,
                    views: undefined,
                    linkToPost: undefined,
                    username: item.username,
                }
            }),
        }
    }, [tiktokData])
    return (
        <>
            {reachData.success && (
                <Flex content="space-between" margin="20px 0 0 0" wrap>
                    <MultiBarChart
                        data={reachData.data}
                        title={t('report_titles.reach.title')}
                        size="large"
                        containerStyle={{ height: isMobile ? 'unset' : '330px', width: '100%', ...style }}
                    />
                </Flex>
            )}
            {ageGender.success && genderDist.success && genderDist.data && (
                <Flex content="space-between" margin="20px 0 0 0" wrap>
                    <MultiBarChart
                        title={t('report_layout.demographics.age_gender.title')}
                        data={ageGender.data}
                        legend={age_gender_Legend}
                        size="large"
                        containerStyle={{ height: isMobile ? 'unset' : '330px', ...style }}
                    />
                    <DonutChart
                        containerStyle={{
                            // width: isMobile ? 'unset' : '23%',
                            marginTop: isMobile ? '20px' : '0',
                            height: isMobile ? 'unset' : '330px',
                            ...style,
                        }}
                        data={genderDist.data}
                        title={t('report_layout.demographics.gender_dist.title')}
                    />
                </Flex>
            )}
            {similarAccounts.success && similarAccounts.data && (
                <div style={{ background: 'white', borderRadius: '10px', padding: '20px 24px' }}>
                    <Text color={'gray-4'} fSize={14} style={{ lineHeight: '24px', fontWeight: 600 }}>
                        {t('report_layout.similar_accounts.title')}
                    </Text>

                    <div>
                        <MoreModal title={t('report_layout.similar_accounts.title')}>
                            <ListOfAccounts
                                amount={Math.min(similarAccounts.data.length, 30)}
                                cardType="vertical"
                                data={similarAccounts.data}
                            />
                        </MoreModal>
                    </div>
                    <ListOfAccounts
                        platform={Platform.TikTok}
                        amount={5}
                        cardType="vertical"
                        data={similarAccounts.data}
                    />
                </div>
            )}
            <Flex content="space-between" margin="20px 0 0 0" wrap>
                {countries.success && (
                    <MultiBarChart
                        title={t('report_layout.demographics.countries.title')}
                        data={countries.data}
                        type="horizontal"
                        size="medium"
                        containerStyle={{ padding: '20px 24px', marginBottom: '20px', ...style }}
                    />
                )}
                {languages.success && (
                    <MultiBarChart
                        title={t('report_layout.demographics.lang.title')}
                        data={languages.data}
                        type="horizontal"
                        size="medium"
                        containerStyle={{ padding: '20px 24px', marginBottom: '20px', ...style }}
                    />
                )}
            </Flex>
        </>
    )
}

export default Demographics
