import { compose, mapProps } from 'recompose'
import { memo } from 'react'
import { withWidth } from '@material-ui/core'

import IN from './Intersections.js'

const propsMapper = ({history, location, match ,...props}) => {
	return {
		intersection: match.params.intersection,
		...props
	}
}

export default compose(
	withWidth(),
	mapProps(propsMapper),
	memo
)(IN)