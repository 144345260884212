import React, { FC } from 'react'
import { IPlatform } from '../../types'
import Text from '@/components/Text'

import cn from './Tab.module.scss'
import { Link } from 'react-router-dom'
import { ADS_EXPLORER } from '@/routs'
import Icons, { TUiIconName } from '@/components/Icons'

interface ITab {
    platform: IPlatform
    selected: boolean
    index: number
}

export const Tab: FC<ITab> = ({ platform, selected, index }) => {
    const style = {
        height: selected ? '50px' : '46px',
        backgroundColor: selected ? 'white' : '#E1E6F3',
        borderRadius: selected ? '4px 4px 0 0' : index === 0 ? '4px 0 0 0' : '0 4px 0 0',
    }

    const iconName = `ads-${platform.id}${selected ? '' : '--disabled'}` as TUiIconName

    return (
        <Link to={`${ADS_EXPLORER}/${platform.id}`}>
            <div className={cn.tab} style={style}>
                <Icons name={iconName} />
                <Text semibold color={selected ? 'gray-1' : 'gray-10'} margin={'0 0 0 8px'}>
                    {platform.title}
                </Text>
            </div>
        </Link>
    )
}
