import React, { FC, useRef } from 'react'
import ListPopup from '@/components/Share/ListPopup'
import Text from '@/components/Text'
import { useSpaceTranslation } from '@/_helpers'

import cn from './ChatsFilter.module.scss'
import useOpenClose from '@/_helpers/useOpenClose'
import Icons from '@/components/Icons'
import Flex from '@/_yc/Flex'

export type ChatsFilterType = 'all' | 'answered' | 'unread'

interface ChatsFilterProps {
    chatsFilters: ChatsFilterType
    onClick: (filters: ChatsFilterType) => void
}

export const ChatsFilter: FC<ChatsFilterProps> = ({ onClick, chatsFilters }) => {
    const t = useSpaceTranslation('outreach.messenger.all_chats.filter')
    const ref = useRef(null)

    const [state, , close, tg] = useOpenClose()

    const options = [
        {
            label: t('all'),
            value: 'all' as ChatsFilterType,
        },
        {
            label: t('answered'),
            value: 'answered' as ChatsFilterType,
        },
        {
            label: t('unread'),
            value: 'unread' as ChatsFilterType,
        },
    ]

    const isActiveCheck = (value: ChatsFilterType) => {
        return chatsFilters === value
    }

    return (
        <div className={cn.filter} onMouseLeave={close}>
            <Flex onClick={tg} style={{ cursor: 'pointer' }}>
                <Text color="gray-new-2" fSize={12} ref={ref}>
                    {options.find((item) => item.value === chatsFilters)?.label}
                </Text>
                <Icons name="arrow-down" className={cn[state ? 'icon--opened' : 'icon--closed']} />
            </Flex>

            <ListPopup
                popupStyle={{ zIndex: 5 }}
                options={options}
                anchor={ref.current}
                isOpen={state}
                onClose={close}
                isActiveCheck={isActiveCheck}
                onClick={onClick}
                placement="bottom-start"
            />
        </div>
    )
}
