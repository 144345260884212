import React, { FC } from 'react'
import Text from '@/components/Text'
import { Flex } from '@/_yc'
import { ReactComponent as YolocoIcon } from '../../../icons/yoloco.svg'
import { ReactComponent as BilineIcon } from '../../../icons/biline.svg'
import { ReactComponent as UsersIcon } from '../../../icons/users.svg'
import { useTranslation } from 'react-i18next'
import cn from './targeting.module.scss'

const textType = {
    from_us: 'target_audience.yoloco_data',
    from_customer: 'target_audience.user_data',
}

const iconType = {
    from_us: (
        <Flex align="center" margin="0 10px 0 0">
            <YolocoIcon style={{ width: '30px', height: '30px' }} />
            <div className={cn['icon-wrapper']}>
                <BilineIcon className={cn['beeline']} />
            </div>
        </Flex>
    ),
    from_customer: <UsersIcon style={{ marginRight: '10px' }} />,
}

const Index: FC<{ targetingType: 'from_us' | 'from_customer' }> = ({ targetingType }) => {
    const { t } = useTranslation()
    if (!targetingType) {
        targetingType = 'from_us'
    }
    return (
        <Flex align="center">
            {iconType[targetingType]}
            <Text fSize={12} color="gray-new-1">
                {t(textType[targetingType])}
            </Text>
        </Flex>
    )
}

export default Index
