import { createContext } from 'react'

export interface IContext {
    templateColumns: string
    sidePadding?:
        | string
        | number
        | { left?: number | string; right?: number | string }
    gridGap?: string | number
}

export default createContext<IContext>({
    templateColumns: 'repeat(auto-fill, 1fr)',
})
