import React, { memo, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import useToken from '@/_helpers/Hooks/useToken'
import CloseIcon from '@material-ui/icons/Close'
import Flex from '@/_yc/Flex'
import notif from '@/components/Notifier/model/actions.js'
import Button from '@/components/Button'
import { AddCustomListReq } from '../../model/service'
import { setLists } from '../../model/actions'

import cn from './AddToList.module.scss'
import { CreateListPlace } from '@/_helpers/amplitude/types'
import { track } from '@amplitude/analytics-browser'

interface CreateNewListProps {
    place: CreateListPlace
    addToList?: Function
    onActiveChange?: (active: boolean) => void
}

const CreateNewList = ({ place, addToList, onActiveChange }: CreateNewListProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const token = useToken()

    const ref = useRef<HTMLInputElement>(null)

    const [inputData, setInputData] = useState<string>('')
    const [isInputFocused, setIsInputFocused] = useState<boolean>(false)

    const handleChange = (value: string) => {
        setInputData(value)
    }
    const req = () => {
        dispatch(notif.loading(t('aud_insides.status.loading')))
        if (!token || !inputData) {
            dispatch(notif.error(t('aud_insides.status.error')))
            return
        }
        AddCustomListReq(token, inputData)
            .then((res) => {
                addToList && addToList(res)
                dispatch(notif.success(t('aud_insides.status.list_saved', { listName: res.name })))
                dispatch(setLists([res]))
            })
            .catch(() => dispatch(notif.error(t('aud_insides.status.error'))))
    }
    const handleReq = (place: CreateListPlace, e?: React.KeyboardEvent<HTMLInputElement>) => {
        if (e && e.key !== 'Enter') return
        setIsInputFocused(false)
        onActiveChange && onActiveChange(false)
        if (ref.current) {
            ref.current.blur()
        }
        track(`create_list_${place}`)
        req()
        setInputData('')
        return
    }
    return (
        <>
            <Flex align="center" className={cn[`writer${isInputFocused ? '--active' : ''}`]}>
                <form style={{ width: '100%' }}>
                    <input
                        placeholder={isInputFocused ? t('context_menu.placeholder') : t('aud_insides.lists.add')}
                        value={inputData}
                        onChange={(e) => handleChange(e.target.value)}
                        onKeyDown={(e) => handleReq(place, e)}
                        onFocus={() => {
                            setIsInputFocused(true)
                            onActiveChange && onActiveChange(true)
                        }}
                        ref={ref}
                    />
                </form>
                {isInputFocused && (
                    <CloseIcon
                        className={cn['writer--active--icon']}
                        onClick={() => {
                            handleChange('')
                            onActiveChange && onActiveChange(false)
                            setIsInputFocused(false)
                        }}
                    />
                )}
            </Flex>
            {isInputFocused && (
                <Button
                    onClick={() => handleReq(place)}
                    disabled={!inputData || inputData.length < 1}
                    style={{ width: 'calc(100% - 20px)' }}
                    margin="12px 10px 10px"
                >
                    {t('aud_insides.lists.saveList')}
                </Button>
            )}
        </>
    )
}
export default memo(CreateNewList)
