import { compose } from 'recompose'
import { memo } from 'react'

import INT from './Intersection.js'
import withData from './withData.js'
import serv from '../Model/service.js'
import { withPdfLoader } from '@/_helpers/index.js'

export default compose(
	withPdfLoader(
		serv.requirePdfIntersection,
		serv.checkPdfTaskStatus,
		serv.downloadPDF,
		console.error
	),
	withData(serv.getById),
	memo
)(INT)