import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => {
    return (
        <svg {...props} width="12" height="4" viewBox="0 0 12 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.0003 3.33317C9.26394 3.33317 8.66699 2.73622 8.66699 1.99984C8.66699 1.26346 9.26394 0.666504 10.0003 0.666504C10.7367 0.666504 11.3337 1.26346 11.3337 1.99984C11.3337 2.35346 11.1932 2.6926 10.9431 2.94265C10.6931 3.19269 10.3539 3.33317 10.0003 3.33317ZM6.00032 3.33317C5.26395 3.33317 4.66699 2.73622 4.66699 1.99984C4.66699 1.26346 5.26395 0.666504 6.00032 0.666504C6.7367 0.666504 7.33366 1.26346 7.33366 1.99984C7.33366 2.35346 7.19318 2.6926 6.94313 2.94265C6.69308 3.19269 6.35395 3.33317 6.00032 3.33317ZM2.00033 3.33317C1.26395 3.33317 0.666992 2.73622 0.666992 1.99984C0.666992 1.26346 1.26395 0.666504 2.00033 0.666504C2.7367 0.666504 3.33366 1.26346 3.33366 1.99984C3.33366 2.35346 3.19318 2.6926 2.94313 2.94265C2.69309 3.19269 2.35395 3.33317 2.00033 3.33317Z"
                fill="#929EB0"
            />
        </svg>
    )
}
