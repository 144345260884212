import React from 'react'
import Icons from '@/components/Icons'
import { SearchFilter } from '@/pages/SearchPage/SearchAdapter/filters/SearchFilter'

const getGqlFilter = (text: string) => {
    if (!text || text.length === 0) return ''

    return `text: "${text}"`
}

export const getBioFilter = (isMain?: boolean) => {
    return new SearchFilter({
        platform: 'infl_tiktok',
        title: 'filters.bio.label',
        icon: <Icons name="filter--bio" />,
        helper: 'filters.bio.text',
        placeholder: 'filters.bio.plh_2',
        space: 1,
        beforeSend: (value) => ({
            name: 'info.biography',
            operator: 'equal',
            type: 'text',
            value: value,
            gqlFilter: getGqlFilter(value),
        }),
    })
}
