import { useState, ComponentProps } from 'react'
import useUpdatingState from '@/_helpers/useUpdatingState'
import { IContacts } from '@/pages/Reports/BloggerReport/Contacts/Contacts'
import { Predictions } from '../BloggerReport/Predictions/Predictions'

const a: string[] = []
const b: number[] = [0, 0, 0]

export default (
    dataLoaded: boolean,

    categories: string[],
    postNumber: number,
    avLikes: number,
    avComments: number,
    followers: number,
    follows: number,
    er: number
): [
    ComponentProps<typeof Predictions>['items'],
    { label: string }[],
    { label: string; value: number }[],
    IContacts['data']
] => {
    const [predictions] = useState<ComponentProps<typeof Predictions>['items']>([
        {
            icon: 'Group',
            title: 'post_coverage',
            value: 1234123,
            tooltipText: '',
        },
        {
            icon: 'Reach',
            title: 'postPrice',
            value: { from: 222, to: 4000 },
            currency: 'USD',
            tooltipText: '',
        },
        {
            icon: 'Reach',
            title: 'storyPrice',
            value: 1234123,
            tooltipText: '',
        },
    ])

    const serializedCats = useUpdatingState(
        ([cats]) => cats.map((i) => ({ label: i })),
        dataLoaded ? [categories] : [a]
    )

    let metrics = useUpdatingState(
        ([postNumber, avLikes, avComments]) => [
            {
                label: 'posts',
                value: postNumber,
            },
            {
                label: 'likes',
                value: avLikes,
            },
            {
                label: 'comments',
                value: avComments,
            },
        ],
        !dataLoaded ? b : [postNumber, avLikes, avComments]
    )

    const [contacts] = useState<IContacts['data']>([
        {
            icon: 'location',
            title: 'geo',
            value: 'Los Angeles',
            blur: true,
        },
        {
            icon: 'email',
            title: 'email',
            value: 'alala@mail.com',
            blur: true,
        },
        {
            icon: 'location',
            title: 'phone',
            value: '8 (922) 822 82 75',
            blur: true,
        },
    ])

    return [predictions, serializedCats, metrics, contacts]
}
