import React from 'react'
import Icons from '@/components/Icons'
import { RangeFilter, RangeValueType } from '@/pages/SearchPage/SearchAdapter/filters/RangeFilter'

interface IRange {
    from: number
    to?: number
}
const getGqlFilter = (range: RangeValueType) => {
    if (!range.from && !range.to) return ''
    return `age: { left_number:  "${range.from}", right_number: "${range.to}"}`
}
export const getAgeFilter = (isMain?: boolean) => {
    return new RangeFilter({
        platform: 'infl_tiktok',
        title: 'filters.age.influencer.label',
        icon: <Icons name="filter--age" />,
        helper: 'filters.age.influencer.text',
        space: 1,
        options: {
            from: [
                { value: 18, label: '18' },
                { value: 25, label: '25' },
                { value: 35, label: '35' },
                { value: 45, label: '45' },
                { value: 65, label: '65' },
            ],
            to: [
                { value: 18, label: '18' },
                { value: 25, label: '25' },
                { value: 35, label: '35' },
                { value: 45, label: '45' },
                { value: 65, label: '65' },
            ],
        },
        beforeSend: (data) => {
            return {
                name: 'metrics.views',
                type: 'range',
                value: data,
                gqlFilter: getGqlFilter(data),
            }
        },
    })
}
