import React, { FC } from 'react'

import Flex from '@/_yc/Flex'
import Instagram from '@/_yc/Icons/Tariffs/Instagram'
import Youtube from '@/_yc/Icons/Tariffs/Youtube'
import Icons from '@/components/Icons'

import { tariff } from '../../Model/types'

import cn from './Icons.module.scss'

interface IIcons {
    tariffs: Array<tariff>
    option: string
}

const TariffIcons: FC<IIcons> = ({ tariffs, option }) => {
    const platforms = tariffs.map((t) => t.platforms).flat()
    const platformsOrder = ['instagram', 'youtube', 'twitch', 'tiktok', 'telegram', 'vk']

    return (
        <>
            <Flex const="space-between" align="center" style={{ gap: '6px', marginTop: '12px' }}>
                {platformsOrder.map((item) => {
                    if (!platforms.includes(item)) return null

                    switch (item) {
                        case 'instagram':
                            return <Instagram />
                        case 'youtube':
                            return <Youtube />
                        case 'twitch':
                            return <Icons name="ie-twitch" className={cn.icon} />
                        case 'tiktok':
                            return <Icons name="ie-tiktok" className={cn.icon} />
                        case 'telegram':
                            return <Icons name="ie-tg" className={cn.icon} />
                        case 'vk':
                            return <Icons name="ie-vk" className={cn.icon} />
                        default:
                            return null
                    }
                })}
            </Flex>
        </>
    )
}

export default TariffIcons
