import { connect } from 'react-redux'
import { compose } from 'recompose'

import CM from './Commercial.js';
import actions from './Model/actions.js'
import withStoredValue from './withStoredValue.js'
import notif from '../Notifier/model/actions.js'
import withPromo from './withPromo.js'
import serv from './Model/service.js'
import { getParams } from '../../_helpers'

/* const mapStateToProps = state => {
	const commLoaded = state.Commercial_.loaded && !state.Commercial_.error;
	const isPromo = state.authentication.infoLoaded && state.authentication.user.profile.isPromo;
	return {
		showCard: commLoaded && isPromo,
		text: state.Commercial_.value.welcome_text
	}
} */

const mapDispatchToProps = dispatch => ({
	loadCommercial(){
		dispatch(actions.loadInfo())
	},
	success(message, promocode){
		dispatch(notif.success(message, 5000))
		dispatch(actions.checkPromo(promocode))
	},
	error(message){
		dispatch(notif.error(message, 5000))
		dispatch(actions.checkPromo())
	},
	check(){
		dispatch(actions.checkPromo())
	}
})

export default compose(
	connect(null, mapDispatchToProps),
	withPromo(serv.promoCheck, getParams),
	withStoredValue(localStorage)
)(CM)