import { Adapter } from '../../Adapter'
import SearchItem from './SearchItem/SearchItem'
import { getFilters } from './filters/filters'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { getItems } from './utils/getItems'
import { getYouTubePostsQuery } from './queries'
import { IQueryData } from './types'
import { getCount } from './utils/getCount'
import { getFilterQueries } from './filters/utils/filterQueries'
import ItemsHead from '../Head/ItemsHead'
import service from '@/pages/Auth/LoginPage/Model/_service'

const fetcher = new Fetcher()

export const AdsExplorerYoutubeAdapter = new Adapter({
    Icon: () => null,
    title: 'AdsExplorerYouTube',
    templateColumns: '',
    fetcher: async (_, config) => {
        const client = fetcher.getClient()
        const queryParams = {
            orderBy: config.sorting,
            limit: 20,
            filters: getFilterQueries(config.filters),
            cursor: config.cursor,
        }
        const videos = await client.query<IQueryData>({
            query: getYouTubePostsQuery(queryParams),
        })
        const items = getItems(videos)

        // @ts-ignore
        const platformsRes = await service.getSubscriptionPlatforms()
        const platforms: string[] = platformsRes.tariff?.platforms
        const isTariffIncludesAds = platforms?.includes('ads') || false
        const isHidden = !isTariffIncludesAds
        const hasNext = isTariffIncludesAds || config.page !== 1
        const sliceIndex = isHidden ? 3 : 20

        return {
            hasNext: hasNext,
            total: videos?.data?.ytPosts?.totalCount || 0,
            items: items.slice(0, sliceIndex),
            isHidden: isHidden,
            cursor: videos?.data?.ytPosts?.pageInfo?.endCursor,
        }
    },
    countCheck: async (_, config) => {
        const client = fetcher.getClient()
        const queryParams = {
            orderBy: config.sorting,
            limit: 20,
            filters: getFilterQueries(config.filters),
        }
        const count = await client.query<IQueryData>({
            query: getYouTubePostsQuery(queryParams),
        })

        return {
            count: getCount(count) || 0,
        }
    },
    background: 'red',
    Item: SearchItem,
    Head: ItemsHead,
    sortings: [
        {
            label: 'ads_explorer.sortings.views',
            value: 'viewCount: DESC',
        },
        {
            label: 'ads_explorer.sortings.video_date',
            value: 'publishedAt: DESC',
        },
        {
            label: 'ads_explorer.sortings.likes',
            value: 'likeCount: DESC',
        },
    ],
    keyWords: [{ label: 'error', value: 'error' }],
    filters: getFilters(fetcher),
})
