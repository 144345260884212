import React, { FC, memo, useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Flex } from '@/_yc'
import useOpenClose from '@/_helpers/useOpenClose'
import { SEARCH } from '@/routs'
import { getColor } from '@/components/color'
import { RootStore } from '@/_helpers/StoreType'
import PromoUpgrade from '@/components/Share/PromoUpgrade'
import SearchInput from '@/Layouts/AudienceLayout/Header/SearchInput'
import CannyChangelog from '@/components/Canny/ChangeLog'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import CalendlyWidget from '@/components/Share/CalendlyWidget'
import DetailedInfoPopUp from '@/pages/Auth/DetailedInfoPopUp'

import NotificationBell from './NotificationBell'
import ProfileMenu from './ProfileMenu'
import LanguageSwitcher from './LanguageSwitcher'
import Counter from './Counter'
import Yoloco from './Yoloco'
import Burger from './Burger'

import cn from './Header.module.scss'

export interface IHeader {
    color: string
    links: {
        title: string
        to: string
        icon: string
        active: string[]
    }[]
}

const Header: FC<IHeader> = (props) => {
    const t = useSpaceTranslation('top_menu')
    const style = useMemo(() => {
        return {
            background: getColor(props.color),
        }
    }, [props.color])

    const isPromo = useSelector((store: RootStore) => store.authentication.user?.profile?.isPromo)

    const [isOpen, open, close] = useOpenClose()

    const isDetailedShown = useSelector((store: RootStore) => store.authentication.user?.userDetails?.isShown)
    const [isDetailedOpen, openDetailed, closeDetailed] = useOpenClose()
    useEffect(() => {
        if (isDetailedShown === false) {
            openDetailed()
        }
    }, [isDetailedShown, openDetailed])

    return (
        <>
            <header style={style} className={cn['header--fixed']} id={'top-header-yolo'}>
                <Flex column>
                    <Flex className={cn.root} align="center" content="space-between">
                        {isDetailedOpen && <DetailedInfoPopUp isOpen={isDetailedOpen} close={closeDetailed} />}
                        <Flex align="center" className={cn['root--first-block']}>
                            <Link to={SEARCH}>
                                <Flex className={cn.yoloco} column>
                                    <Yoloco />
                                </Flex>
                            </Link>

                            <Flex className={cn.lang}>
                                <LanguageSwitcher margin="0 0 0 15px" />
                            </Flex>
                            <Flex className={cn.search}>
                                <SearchInput
                                    disableMassParsing
                                    // placeholder={'aud_insides.search_input.placeholder'}
                                    place="header"
                                />
                            </Flex>
                        </Flex>

                        <Flex>
                            <CalendlyWidget close={close} isOpen={isOpen} />
                            {isPromo && (
                                <Flex className={cn['demo--desk']} align="center" content="center" onClick={open}>
                                    <p>{t('try')}</p>
                                </Flex>
                            )}
                            {isPromo ? (
                                <>
                                    <Flex className={cn['promo--mobile']}>
                                        <PromoUpgrade />
                                    </Flex>
                                    <Flex className={cn['counter--mobile']}>
                                        <Counter />
                                    </Flex>
                                </>
                            ) : (
                                <Flex className={cn['promo--mobile']}>
                                    <Counter />
                                </Flex>
                            )}
                            <CannyChangelog />
                            <NotificationBell margin="0 0 0 12px" />
                            <Flex className={cn.profile}>
                                <ProfileMenu margin="0 0 0 12px" />
                            </Flex>
                            <Flex className={cn.burger}>
                                <Burger margin="0 2px 0 12px" links={props.links} />
                            </Flex>
                        </Flex>
                    </Flex>
                    <div className={cn.searchMobile}>
                        <SearchInput
                            disableMassParsing
                            placeholder={'aud_insides.search_input.placeholder_short'}
                            place="header"
                        />
                    </div>
                </Flex>
            </header>
        </>
    )
}

export default memo(Header)
