import Text from '@/components/Text'
import Flex from '@/_yc/Flex'
import React, { FC, HTMLAttributes, RefObject, ReactNode } from 'react'
import { useCallback } from 'react'
import cn from './PartChanger.module.scss'

export interface IItem extends HTMLAttributes<HTMLElement> {
    value: RefObject<HTMLInputElement>
    title: string
    index: number
    setIndex: React.Dispatch<React.SetStateAction<number>>
    children?: ReactNode
    soon?: boolean
}

const Item: FC<IItem> = ({ value, title, setIndex, index, ...props }) => {
    const scrollToItem = useCallback(() => {
        if (props.soon) return

        value.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        setIndex(index)
    }, [value, setIndex, index, props.soon])

    return (
        <Flex className={props.className} onClick={scrollToItem} align="center">
            <Text className={cn['item--text']}>{title}</Text>
            {props.children}
        </Flex>
    )
}

export default Item
