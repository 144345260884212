import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from '@/_yc/'
import { IReportReadyStatus } from '../../Types'

import cn from './OnParsingLoader.module.scss'

interface IOnParsingLoader {
    data: IReportReadyStatus
}

const OnParsingLoader: FC<IOnParsingLoader> = ({ data }) => {
    const { t } = useTranslation()

    const barWidth = (data * 100).toFixed(1) + '%'

    return (
        <>
            <Flex className={cn.root} column content="space-between">
                <p className={cn.title}>{t('report_layout.OnParsingLoader.title')}</p>
                <p className={cn.helper}>{t('report_layout.OnParsingLoader.helper')}</p>
                <div className={cn['bar-container']}>
                    <div className={cn['bar-filled']} style={{ width: barWidth }} />
                </div>
                <p className={cn.note}>{t('report_layout.OnParsingLoader.note')}</p>
            </Flex>
        </>
    )
}

export default memo(OnParsingLoader)
