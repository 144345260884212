import React, { FC, memo } from 'react'

import { Notification } from '@/components/Notifier/Types'
import NotifIcons from './NotifIcons'
import UpdatingTime from './UpdatingTime'
import Flex from '@/_yc/Flex'
import cn from './List.module.scss'

export interface IItem {
    Notification: Notification
    hideTime?: boolean
    notLast: boolean
    withSublabel: boolean
}

const Item: FC<IItem> = ({ Notification, hideTime, notLast, withSublabel }) => {
    return (
        <Flex margin={notLast ? '0 0 30px' : undefined}>
            <NotifIcons icon={Notification.Message.icon} className={cn.icon} />
            <div>
                <Notification.Message.Component withSublabel={withSublabel} />
                {!hideTime && <UpdatingTime unix={Notification.postDate} />}
            </div>
        </Flex>
    )
}

export default memo(Item)
