import Icons from '@/components/Icons'
import React from 'react'
import { SelectorFilter } from '../../../../filters/SelectorFilter'
import { PLATFORM } from '../consts'

const getGqlFilter = (videoCount: number[]) => {
    if (!videoCount || videoCount.length === 0) return ''

    return `video_count: {_gte: "${videoCount[0]}"}`
}

export const getVideoCountFilter = () => {
    return new SelectorFilter({
        platform: PLATFORM,
        title: 'filters.videos.label',
        helper: 'filters.videos.text',
        space: 1,
        icon: <Icons name="filter--videos--count" />,
        options: [
            { label: '>= 50', value: 50 },
            { label: '>= 100', value: 100 },
            { label: '>= 500', value: 500 },
            { label: '>= 1000', value: 1000 },
        ],
        beforeSend: ({ value }) => ({
            name: 'metrics.er',
            type: 'number',
            value: value,
            gqlFilter: getGqlFilter(value),
        }),
    })
}
