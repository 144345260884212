import React from 'react';

import bike from './bike.png';
import car from './car.png';
import track from './Track.png';

export default ({name, ...props}) => {
	switch (name) {
		case "bike": return <img src = {bike} alt = {<div />} {...props}/>
		case "car": return <img src = {car} alt = {<div />} {...props}/>
		case "truck": return <img src = {track} alt = {<div />} {...props}/>
		default: return null
	}
}