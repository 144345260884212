// import React from 'react'
// import Icons from '@/components/Icons'
import { SelectorFilter } from '../../../../filters/SelectorFilter'

const getGqlFilter = (value: number[]) => {
    if (!value || value.length === 0) return ''

    return `posts_m: {_gte: "${value[0]}"}`
}

export const getPostsFilter = () => {
    return new SelectorFilter({
        platform: 'infl_tg',
        title: 'filters.posts_month.label',
        helper: 'filters.posts_month.text',
        // icon: <Icons name="filter--engagement--yt" />,
        space: 1,
        options: [
            { label: '> 10', value: 10 },
            { label: '> 50', value: 50 },
            { label: '> 100', value: 100 },
        ],
        beforeSend: ({ value }) => ({
            name: 'metrics.er',
            type: 'number',
            value: value,
            gqlFilter: getGqlFilter(value),
        }),
    })
}
