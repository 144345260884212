import React, { FC, useMemo } from 'react'

import { StandartLabel, StandartEmptyText } from '../../Blocks'

import cn from './HorizontalChart.module.scss'
import useReportTranslation from '../useReportTranslation'
import { Flex, Modal, Text } from '@/_yc'
import useOpenClose from '@/_helpers/useOpenClose'
import Scrollbars from 'react-custom-scrollbars'

export interface IHorizontalChart {
    title: string
    data: Array<{
        value: number
        label: string
    }>
    width: string | number
    hidden?: boolean
}

const HorizontalChart: FC<IHorizontalChart> = ({ title, data, width, hidden }) => {
    const t = useReportTranslation(title)

    const sum = useMemo(() => data.reduce((prev, item) => item.value + prev, 0), [data])
    const [isOpen, open, close] = useOpenClose(false)
    if (!data || !data.length)
        return (
            <div className={cn.root} style={{ width }}>
                <StandartEmptyText label={t('empty.label')} sublabel={t('empty.subLabel')} />
                <div className={cn.root_plh}>
                    <StandartLabel title={t('title')} helperText={t('helper')} />
                    <div className={cn.root_plh_body}>
                        <div className={cn.root_plh_body_label} />
                        <div style={{ width: '90%', marginBottom: 48 }} className={cn.root_plh_body_line} />
                        <div className={cn.root_plh_body_label} />
                        <div style={{ width: '80%', marginBottom: 48 }} className={cn.root_plh_body_line} />
                        <div className={cn.root_plh_body_label} />
                        <div style={{ width: '60%', marginBottom: 48 }} className={cn.root_plh_body_line} />
                        <div className={cn.root_plh_body_label} />
                        <div style={{ width: '40%' }} className={cn.root_plh_body_line} />
                    </div>
                </div>
            </div>
        )

    return (
        <div className={cn.root} style={{ width }}>
            {isOpen && !hidden && (
                <Modal width={1180} closeIcon onClose={close}>
                    <Flex column content="space-between" className={hidden ? '' : cn.label}>
                        <Text size={24} margin={'0 0 12 0'} semibold color="gray2">
                            {t('title')}
                        </Text>
                    </Flex>
                    <div className={`${cn.vals}`}>
                        <Scrollbars autoHeightMin={476} autoHeight>
                            {data.map((item, i) => (
                                <div key={i} className={cn.bar}>
                                    <div className={cn.tops}>
                                        <div className={cn.label}>{item.label}</div>
                                        <div className={cn.value}>{(item.value * 100).toFixed(1)}%</div>
                                    </div>
                                    <div className={cn.line_container}>
                                        <div
                                            className={cn.line}
                                            style={{
                                                width: `${(item.value * 100) / sum}%`,
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </Scrollbars>
                    </div>
                </Modal>
            )}
            <Flex content="space-between" className={hidden ? '' : cn.label}>
                <StandartLabel title={t('title')} helperText={t('helper')} />
                <Text onClick={open} className={cn.more} semibold color="link">
                    {t('show_all')}
                </Text>
            </Flex>
            <div className={`${hidden ? cn['blur--active'] : cn['blur--default']}`}>
                {data.slice(0, 5).map((item, i) => (
                    <div key={i} className={cn.bar}>
                        <div className={cn.tops}>
                            <div className={cn.label}>{item.label}</div>
                            <div className={cn.value}>{(item.value * 100).toFixed(1)}%</div>
                        </div>
                        <div className={cn.line_container}>
                            <div
                                className={cn.line}
                                style={{
                                    width: `${(item.value * 100) / sum}%`,
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default HorizontalChart
