import React, { useEffect } from 'react'
import PageLayout from '@/Layouts/PageLayout'
import { useTranslation } from 'react-i18next'
import cn from './target-audience.module.scss'
import TargetAudienceRoutes from '@/pages/TargetAudience/Routes'
import { useHistory, useLocation } from 'react-router-dom'
import {
    GetAudienceEnumsReq,
    GetAudienceInterestsReq,
    getCampaignByIdReq,
} from '@/pages/TargetAudience/services/services'
import useToken from '@/_helpers/Hooks/useToken'
import { useDispatch, useSelector } from 'react-redux'
import { setEnums, setInterests, setTargetTypeAndDescription } from '@/_redux/_reducers/target_audience.reducer'
import { ICampaignResponse } from '@/pages/TargetAudience/services/types'
import { RootStore } from '@/_helpers/StoreType'

const TargetAudience = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    const token = useToken()
    const dispatch = useDispatch()
    const { i18n } = useTranslation()
    const campaignId = localStorage.getItem('campaignId')
    const platforms = useSelector((store: RootStore) => store.authentication.user?.platforms)

    useEffect(() => {
        localStorage.setItem('i18nextLng', i18n?.language?.split('-')?.[0] || 'ru')
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language])

    useEffect(() => {
        if (location.pathname === '/target' && platforms?.includes('audience')) {
            history.push('/target/all')
        }
        if (Array.isArray(platforms) && !platforms?.includes('audience')) {
            history.push('/')
        }
    }, [history, location.pathname, platforms])

    useEffect(() => {
        const fetchEnumsAndInterests = async () => {
            try {
                const requests = [
                    GetAudienceEnumsReq(token),
                    GetAudienceInterestsReq(token),
                    getCampaignByIdReq(token, campaignId || ''),
                ]
                const [enumsData, interestsData, campaign] = await Promise.allSettled(requests)

                if (enumsData.status === 'fulfilled' && enumsData.value) {
                    dispatch(setEnums(enumsData.value))
                }
                if (interestsData.status === 'fulfilled' && interestsData.value) {
                    dispatch(setInterests(interestsData.value))
                }

                if (campaign && campaign.status === 'fulfilled' && campaign.value) {
                    const campaignData = campaign.value as ICampaignResponse
                    dispatch(
                        setTargetTypeAndDescription({
                            target_type: campaignData.target_type || 'from_us',
                            description: campaignData.description,
                            name: campaignData.name,
                        })
                    )
                }
            } catch (error) {
                console.error('Ошибка при получении данных:', error)
            }
        }

        fetchEnumsAndInterests()
    }, [token, dispatch, campaignId])

    return (
        <PageLayout className={cn['root']} title={t('Target Audience')} color="common-background">
            <div className={cn['audience']}>
                <TargetAudienceRoutes />
            </div>
        </PageLayout>
    )
}

export default TargetAudience
