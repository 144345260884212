import React, { FC, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'

import cn from './NotificationBell.module.scss'
import List from './List'
import BellIcon from './BellIcon'
import DottedBellIcon from './DottedBellIcon'
import useOpenClose from '@/_helpers/useOpenClose'
import Flex from '@/_yc/Flex'
import Popup from '@/_yc/Popup'
import { RootStore } from '@/_helpers/StoreType'
import useNotifications from './useNotifications'

export interface INotificationBell {}

const style = { zIndex: 400 }

const NotificationBell: FC<INotificationBell> = (props) => {
    const ref = useRef<HTMLDivElement>(null)

    const [state, setOpen, setClose] = useOpenClose()
    const token = useSelector<RootStore, string | undefined>((store) => store.authentication.user?.token)
    const { hasNotifications, clean } = useNotifications(token)

    const open = useCallback(() => {
        setOpen()
        clean()
    }, [setOpen, clean])

    const close = useCallback(() => {
        setClose()
        clean()
    }, [setClose, clean])

    return (
        <>
            <Flex align="center" content="center" className={cn.root} onClick={open} ref={ref}>
                <div className={cn.bell}>{hasNotifications ? <DottedBellIcon /> : <BellIcon />}</div>
            </Flex>
            <Popup style={style} anchor={ref.current} isOpen={state} onClose={close}>
                <div>
                    <List />
                </div>
            </Popup>
        </>
    )
}

export default NotificationBell
