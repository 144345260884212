import React from 'react'
import Icons from '@/components/Icons'

import { FilterWithDate, WithDateType } from '../../../../filters/FilterWithDate'
import { PLATFORM } from '../consts'

const getGqlFilter = (data: WithDateType) => {
    if (!data.days || !data.value) return ''

    return `subscribers_${data.days}_days_growth: {_gt: ${data.value}}`
}

export const getSubGainFilter = () => {
    return new FilterWithDate({
        platform: PLATFORM,
        locked: (features: string[]) => !features?.includes('subscriber_growth_yt'),
        title: 'filters.sub_growth.label',
        helper: 'filters.sub_growth.text',
        icon: <Icons name="filter--engagement--yt" />,
        space: 1,
        beforeSend: (data) => {
            return {
                name: 'metrics.gain',
                type: 'range',
                value: data,
                gqlFilter: getGqlFilter(data),
            }
        },
    })
}
