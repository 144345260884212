import { allocationChart, erData, followersData, followsData } from './DemoData.desktop'

export default () => [
    {
        type: 'line_chart',
        info: {
            follows_v2: followsData(),
            followers_v2: followersData(),
            erStat_v2: erData(),
        },
        inHidder: true,
    },
    {
        type: 'er_spread',
        title: 'er_spread',
        space: 1,
        data: [
            {
                label: '2020-06-09T07:06:17',
                value: [
                    {
                        value: 2717,
                        color: '#6172FF',
                    },
                    {
                        value: 703273,
                        color: '#FFDA54',
                    },
                ],
                url: 'https://www.instagram.com/p/CBNJiAmg3_8',
            },
            {
                label: '2020-06-09T06:06:00',
                value: [
                    {
                        value: 1093,
                        color: '#6172FF',
                    },
                    {
                        value: 649451,
                        color: '#FFDA54',
                    },
                ],
                url: 'https://www.instagram.com/p/CBNGhi5gaZ0',
            },
            {
                label: '2020-06-08T19:06:30',
                value: [
                    {
                        value: 4735,
                        color: '#6172FF',
                    },
                    {
                        value: 409622,
                        color: '#FFDA54',
                    },
                ],
                url: 'https://www.instagram.com/p/CBL6GxCgBtg',
            },
            {
                label: '2020-06-08T16:06:26',
                value: [
                    {
                        value: 1439,
                        color: '#6172FF',
                    },
                    {
                        value: 467509,
                        color: '#FFDA54',
                    },
                ],
                url: 'https://www.instagram.com/p/CBLnYRLAtgh',
            },
            {
                label: '2020-06-02T08:06:32',
                value: [
                    {
                        value: 4696,
                        color: '#6172FF',
                    },
                    {
                        value: 944102,
                        color: '#FFDA54',
                    },
                ],
                url: 'https://www.instagram.com/p/CA7T_qdgL4K',
            },
            {
                label: '2020-05-31T19:05:09',
                value: [
                    {
                        value: 2812,
                        color: '#6172FF',
                    },
                    {
                        value: 633770,
                        color: '#FFDA54',
                    },
                ],
                url: 'https://www.instagram.com/p/CA3RHSygxEA',
            },
            {
                label: '2020-05-31T09:05:50',
                value: [
                    {
                        value: 6664,
                        color: '#6172FF',
                    },
                    {
                        value: 1313656,
                        color: '#FFDA54',
                    },
                ],
                url: 'https://www.instagram.com/p/CA2P81AAWUx',
            },
            {
                label: '2020-05-30T13:05:03',
                value: [
                    {
                        value: 2722,
                        color: '#6172FF',
                    },
                    {
                        value: 735589,
                        color: '#FFDA54',
                    },
                ],
                url: 'https://www.instagram.com/p/CA0Dq83AVSM',
            },
            {
                label: '2020-05-27T14:05:28',
                value: [
                    {
                        value: 1574,
                        color: '#6172FF',
                    },
                    {
                        value: 762625,
                        color: '#FFDA54',
                    },
                ],
                url: 'https://www.instagram.com/p/CAscM1Eg1Ya',
            },
            {
                label: '2020-05-25T17:05:14',
                value: [
                    {
                        value: 1406,
                        color: '#6172FF',
                    },
                    {
                        value: 699973,
                        color: '#FFDA54',
                    },
                ],
                url: 'https://www.instagram.com/p/CAnld4ugPjz',
            },
        ],
        legend: [
            {
                color: 'rgb(255, 218, 84)',
                label: 'likes',
            },
            {
                color: '#6172FF',
                label: 'comments',
            },
        ],
    },
    {
        type: 'twoTailed',
        content: [
            {
                type: 'donut_chart',
                title: 'aq',
                space: 1,
                data: [
                    {
                        value: 0.6385849037622617,
                        label: 'good',
                        color: '#9bdb5a',
                    },
                    {
                        value: 0.20963785783921904,
                        label: 'suspicious',
                        color: '#43444b',
                    },
                    {
                        value: 0.1364448647294131,
                        label: 'mass',
                        color: '#d9dfe2',
                    },
                    {
                        value: 0.015332373669106067,
                        label: 'influencers',
                        color: '#e2f665',
                    },
                ],
            },
            {
                type: 'bar_chart',
                title: 'reach',
                space: 1,
                data: [
                    {
                        value: 0.43256499225379036,
                        label: '<500',
                        subLabel: 'follows',
                    },
                    {
                        value: 0.2803670624214633,
                        label: '500-1k',
                        subLabel: 'follows',
                    },
                    {
                        value: 0.10089369493286314,
                        label: '1k-1.5k',
                        subLabel: 'follows',
                    },
                    {
                        value: 0.1861742503918832,
                        label: '>1.5k',
                        subLabel: 'follows',
                    },
                ],
            },
        ],
    },
    {
        type: 'twoTailed',
        content: [
            {
                type: 'list',
                title: 'brandAffinity',
                space: 1,
                data: [
                    {
                        label: 'mastercard',
                        subLabel: 'finance',
                        link: 'mastercard',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/mastercard.jpg',
                    },
                    {
                        label: 'Телеканал ПЯТНИЦА!',
                        subLabel: 'entertainment',
                        link: 'fridaytv',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/fridaytv.jpg',
                    },
                    {
                        label: 'Maybelline New York',
                        subLabel: 'beauty',
                        link: 'maybelline',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/maybelline.jpg',
                    },
                    {
                        label: 'ЦУМ / TSUM',
                        subLabel: 'shop',
                        link: 'tsum_moscow',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/tsum_moscow.jpg',
                    },
                    {
                        label: 'Glamour Russia',
                        subLabel: 'magazine',
                        link: 'glamour_russia',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/glamour_russia.jpg',
                    },
                    {
                        label: 'Золотое Яблоко',
                        subLabel: 'beauty',
                        link: 'goldapple_ru',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/goldapple_ru.jpg',
                    },
                    {
                        label: 'Philips Russia',
                        subLabel: 'electronics',
                        link: 'philipsrussia',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/philipsrussia.jpg',
                    },
                    {
                        label: 'COSMOPOLITAN Russia',
                        subLabel: 'magazine',
                        link: 'cosmopolitan_russia',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/cosmopolitan_russia.jpg',
                    },
                    {
                        label: 'adidas Russia',
                        subLabel: 'clothing',
                        link: 'adidasrussia',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/adidasrussia.jpg',
                    },
                    {
                        label: 'Первый Канал',
                        subLabel: 'entertainment',
                        link: '1tv',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/1tv.jpg',
                    },
                    {
                        label: 'Бери Беги',
                        subLabel: 'clothing',
                        link: 'beribegi',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/beribegi.jpg',
                    },
                    {
                        label: 'MAXIM Russia',
                        subLabel: 'magazine',
                        link: 'maximmagazinerussia',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/maximmagazinerussia.jpg',
                    },
                    {
                        label: 'RUSSIAN MUSICBOX',
                        subLabel: 'magazine',
                        link: 'russian_musicbox',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/russian_musicbox.jpg',
                    },
                    {
                        label: 'Магазины Лента',
                        subLabel: 'shop',
                        link: 'lenta_com',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/lenta_com.jpg',
                    },
                    {
                        label: 'HONOR Russia',
                        subLabel: 'electronics',
                        link: 'honorrus',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/honorrus.jpg',
                    },
                    {
                        label: 'MAC Cosmetics Russia',
                        subLabel: 'beauty',
                        link: 'maccosmeticsrussia',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/maccosmeticsrussia.jpg',
                    },
                    {
                        label: 'BMW Russia',
                        subLabel: 'auto',
                        link: 'bmwru',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/bmwru.jpg',
                    },
                    {
                        label: 'RADEN',
                        subLabel: 'clothing',
                        link: 'raden_moscow',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/raden_moscow.jpg',
                    },
                    {
                        label: 'Reebok Russia',
                        subLabel: 'clothing',
                        link: 'reebok_russia',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/reebok_russia.jpg',
                    },
                    {
                        label: 'Kat Von D Beauty',
                        subLabel: 'beauty',
                        link: 'katvondbeauty',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/katvondbeauty.jpg',
                    },
                    {
                        label: 'SEPHORA Russia',
                        subLabel: 'beauty',
                        link: 'sephora_russia',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/sephora_russia.jpg',
                    },
                    {
                        label: 'SIAMMSIAMM',
                        subLabel: 'clothing',
                        link: 'siammstore',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/siammstore.jpg',
                    },
                    {
                        label: 'Телеканал ТНТ',
                        subLabel: 'entertainment',
                        link: 'tnt_online',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/tnt_online.jpg',
                    },
                    {
                        label: 'Off-White',
                        subLabel: 'clothing',
                        link: 'off____white',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/off____white.jpg',
                    },
                    {
                        label: 'Bosco di Ciliegi',
                        subLabel: 'shop',
                        link: 'bosco_di_ciliegi',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/bosco_di_ciliegi.jpg',
                    },
                    {
                        label: 'Max Mara',
                        subLabel: 'clothing',
                        link: 'maxmara',
                        url: 'https://storage.googleapis.com/yolomedia/avatars/maxmara.jpg',
                    },
                ],
            },
            {
                type: 'list',
                space: 1,
                title: 'notableFollowers',
                data: [
                    {
                        label: 'borodylia',
                        subLabel: 'Ксения Бородина',
                        url: 'https://storage.googleapis.com/yolomedia/borodylia.jpe',
                    },
                    {
                        label: 'tnt_online',
                        subLabel: 'Телеканал ТНТ',
                        url: 'https://storage.googleapis.com/yolomedia/tnt_online.jpe',
                    },
                    {
                        label: 'egorkreed',
                        subLabel: 'Егор Крид | #HeartBreakKid',
                        url: 'https://storage.googleapis.com/yolomedia/egorkreed.jpe',
                    },
                    {
                        label: 'm_galustyan',
                        subLabel: 'Михаил Галустян',
                        url: 'https://storage.googleapis.com/yolomedia/m_galustyan.jpe',
                    },
                    {
                        label: 'samoylovaoxana',
                        subLabel: 'Samoylova Oxana',
                        url: 'https://storage.googleapis.com/yolomedia/samoylovaoxana.jpe',
                    },
                    {
                        label: 'dava_m',
                        subLabel: 'Давид Манукян',
                        url: 'https://storage.googleapis.com/yolomedia/dava_m.jpe',
                    },
                    {
                        label: 'kateclapp',
                        subLabel: 'Kate Clapp / Катя Клэп',
                        url: 'https://storage.googleapis.com/yolomedia/kateclapp.jpe',
                    },
                    {
                        label: 'reginatodorenko',
                        subLabel: 'regina todorenko',
                        url: 'https://storage.googleapis.com/yolomedia/reginatodorenko.jpe',
                    },
                    {
                        label: 'xenia_sobchak',
                        subLabel: 'Ксения Собчак',
                        url: 'https://storage.googleapis.com/yolomedia/xenia_sobchak.jpe',
                    },
                ],
            },
        ],
    },
    {
        type: 'twoTailed',
        content: [
            {
                type: 'multi_bar_chart',
                title: 'ageGender',
                data: [
                    {
                        values: [
                            {
                                value: 0.030532157665950528,
                                label: 'f',
                                color: '#FFDA54',
                            },
                            {
                                value: 0.01291385307276196,
                                label: 'm',
                                color: '#6172FF',
                            },
                        ],
                        label: '0-17',
                        position: '1',
                    },
                    {
                        values: [
                            {
                                value: 0.16275631408896357,
                                label: 'f',
                                color: '#FFDA54',
                            },
                            {
                                value: 0.024578086210410605,
                                label: 'm',
                                color: '#6172FF',
                            },
                        ],
                        label: '18-24',
                        position: '2',
                    },
                    {
                        values: [
                            {
                                value: 0.4701360882886555,
                                label: 'f',
                                color: '#FFDA54',
                            },
                            {
                                value: 0.11666237875512461,
                                label: 'm',
                                color: '#6172FF',
                            },
                        ],
                        label: '25-34',
                        position: '3',
                    },
                    {
                        values: [
                            {
                                value: 0.09735842374144228,
                                label: 'm',
                                color: '#6172FF',
                            },
                            {
                                value: 0.0743958221262918,
                                label: 'f',
                                color: '#FFDA54',
                            },
                        ],
                        label: '35-44',
                        position: '4',
                    },
                    {
                        values: [
                            {
                                value: 0.00568176122903796,
                                label: 'm',
                                color: '#6172FF',
                            },
                            {
                                value: 0.0036218930739648036,
                                label: 'f',
                                color: '#FFDA54',
                            },
                        ],
                        label: '45-54',
                        position: '5',
                    },
                    {
                        values: [
                            {
                                value: 0.0009271912620158975,
                                label: 'm',
                                color: '#6172FF',
                            },
                            {
                                value: 0.0004360304853804491,
                                label: 'f',
                                color: '#FFDA54',
                            },
                        ],
                        label: '>54',
                        position: '6',
                    },
                ],
                space: 1,
                legend: [
                    {
                        color: 'rgb(255, 218, 84)',
                        label: 'women',
                    },
                    {
                        color: '#6172FF',
                        label: 'men',
                    },
                ],
            },
            {
                type: 'container',
                space: 1,
                direction: 'col',
                content: [
                    {
                        type: 'donut_chart',
                        title: 'gender',
                        space: 1,
                        data: [
                            {
                                value: 0.7418783057292067,
                                label: 'f',
                                color: '#FFDA54',
                            },
                            {
                                value: 0.2581216942707933,
                                label: 'm',
                                color: '#6172FF',
                            },
                        ],
                    },
                    {
                        type: 'horizontal_chart',
                        space: 1,
                        title: 'cities',
                        data: [
                            {
                                value: 0.1179191018198959,
                                label: 'Moscow',
                            },
                            {
                                value: 0.0734105989735273,
                                label: 'Krasnodarskiy',
                            },
                            {
                                value: 0.06613563020611862,
                                label: 'St.-Petersburg',
                            },
                            {
                                value: 0.042415342371831005,
                                label: 'Moskovskaya',
                            },
                            {
                                value: 0.02017674844825938,
                                label: 'Tatarstan',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        type: 'allocation_chart',
        title: 'er_spread',
        space: 4,
        info: allocationChart.info,
    },
    {
        type: 'twoTailed',
        content: [
            {
                type: 'horizontal_chart',
                space: 1,
                title: 'countries',
                data: [
                    {
                        value: 0.6942793700927585,
                        label: 'RU',
                    },
                    {
                        value: 0.09908197596883762,
                        label: 'UA',
                    },
                    {
                        value: 0.02681869808400192,
                        label: 'KZ',
                    },
                    {
                        value: 0.025114196650671168,
                        label: 'BY',
                    },
                    {
                        value: 0.016280131678505314,
                        label: 'US',
                    },
                ],
            },
            {
                type: 'horizontal_chart',
                space: 1,
                title: 'languages',
                data: [
                    {
                        value: 0.4018778953190901,
                        label: 'ru',
                    },
                    {
                        value: 0.0884408490631266,
                        label: 'en',
                    },
                    {
                        value: 0.03264698425868308,
                        label: 'bg',
                    },
                    {
                        value: 0.015190025121574594,
                        label: 'et',
                    },
                    {
                        value: 0.013304754661319688,
                        label: 'mk',
                    },
                ],
            },
        ],
    },
]
