import React, { forwardRef, ForwardRefRenderFunction, useCallback } from 'react'
import { Icons } from '@/_yc'
import { useTranslation } from 'react-i18next'
import DownloadIcon from './DownloadIcon'
import Button, { IButton } from '@/components/Button'

import cn from './LoadingButton.module.scss'
import useClass from '@/_helpers/Hooks/useClass'

export interface ILoadingButton extends Omit<IButton, 'color'> {
    loading?: boolean
    noLoadingText?: boolean
    noDownloadIcon?: boolean
    width?: string
    tooltip?: string
}

const style = { margin: '0 8px 0 0' }

const LoadingButton: ForwardRefRenderFunction<HTMLDivElement, ILoadingButton> = (
    { loading, children, className, onClick, noDownloadIcon, noLoadingText, ...props },
    ref
) => {
    const { t } = useTranslation()

    const cl = useClass(loading && cn.load, className)

    const click = useCallback(
        (e) => {
            if (onClick) onClick(e)
        },
        [onClick]
    )

    return (
        <Button ref={ref} onClick={click} color="blue" className={cl} {...props}>
            {loading ? (
                <Icons context="default" className={cn.icon} name="loading" />
            ) : noDownloadIcon ? null : (
                <DownloadIcon style={style} className={!props.disabled ? cn.violetIcon : ''} />
            )}
            {loading && !noLoadingText ? t('report.downloadInProcess') : children}
        </Button>
    )
}

export default forwardRef(LoadingButton)
