import { RootStore } from '@/_helpers/StoreType'
import { useSelector } from 'react-redux'

export const useLocked = (key?: string) => {
    const included = useSelector((store: RootStore) => {
        if (!key) return true
        return store.authentication.user?.profile?.features.includes(key) ?? false
    })

    return { isLocked: !included }
}
