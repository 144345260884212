import React, { useRef, useState, useCallback, memo } from 'react';
import pt from 'prop-types'

import { StandartPopper } from '@/_yc/'

import MI from './MenuIcon'

import cn from './Menu.module.scss'
import Text from "@/components/Text"

const pointer = {
	cursor: 'pointer'
}

const style = {
	marginTop: 10,
	paddingLeft: 10
}

const Menu = props => {
	const ref = useRef(null);
	const [isOpen, setIsOpen] = useState(false);

	const open = useCallback(() => {
		setIsOpen(true)

		if (props.onOpen) props.onOpen()
        // eslint-disable-next-line
	}, [props.onOpen])

	const close = useCallback(() => {
		setIsOpen(false)

		if (props.onClose) props.onClose()
        // eslint-disable-next-line
	}, [props.onClose])

	return (
		<StandartPopper 
			btn = {
				<div data-mark={props.mark} className = {cn.btn} onClick = {open} ref = {ref}>
					<div data-mark={props.mark} className = {cn.fill}>
						<MI data-mark={props.mark} style = {pointer}/>
					</div>
				</div>
			}
			content = {
				<ul data-mark={props.mark} className = {cn.root}>
					{props.items.map(item => (
						<MenuItem 
							mark={props.mark} 
							key = {item.label} 
							id = {props.id} 
							onClick = {item.cb}
							label = {item.label} 
							color={item.color}
						/>
					))}
				</ul>
			}
			open = {isOpen}
			anchor = {ref.current}
			onClickAway = {close}
			placement = {"bottom-end"}
			style = {style}
		/>
	)
}

const MenuItem = memo(({ label, id, onClick, mark, color }) => {

	const click = useCallback(() => {
		onClick && onClick(id)
	}, [onClick, id])

	return (
		<Text color={color} data-mark={mark} fSize={12} component="li" onClick = {click} className = {cn.item}>
			{label}
		</Text>
	)
})

export const IMenuItem = pt.shape({
	label: pt.string.isRequired,
	cb: pt.func.isRequired
})

Menu.propTypes = {
	items: pt.arrayOf(IMenuItem),
	id: pt.number.isRequired,
	onOpen: pt.func,
	onClose: pt.func
};

export { Menu as default }