import React, { FC } from 'react'

import Colorize from './Colorize'

import cn from './Overview.module.scss'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import useSorting from '@/_helpers/Hooks/useSorting'
import { numberFormatter } from '@/_helpers/'
import ListLayout, { ListItem, Segment } from '@/Layouts/ListLayout'
import Text from '@/components/Text'
import Avatar from '@/components/Avatar'

const formatedFollowers = (num: number): string => {
    if (num < 10000) return num + ''
    return numberFormatter((num / 1000).toFixed(0)) + 'K'
}

export interface IOverview {
    data: Array<{
        id: number
        avatar: string
        username: string
        fullName: string
        followers: number
        intersectionsPercent: number
        intersectionsCount: number
        aq: number
        age: string
        gender: 1 | 0
    }>
    margin?: string | number
}

const Overview: FC<IOverview> = ({ data, margin }) => {
    const t = useSpaceTranslation('intersections.intersection.overview')

    const [sorted, setSortingKey, currentSorting] = useSorting(
        data,
        (a, b, sortingKey) => {
            if (sortingKey === 'followers') return b.followers - a.followers
            if (sortingKey === 'count') return b.intersectionsCount - a.intersectionsCount
            if (sortingKey === 'aq') return b.aq - a.aq
            if (sortingKey === 'percent') return b.intersectionsPercent - a.intersectionsPercent
            else return 0
        },
        'count'
    )

    return (
        <ListLayout
            margin={margin}
            gridGap={16}
            sidePadding={24}
            templateColumns="40px 3fr repeat(5, 1fr) 1.5fr"
            className={cn.root}
        >
            <ListItem className={cn.hat} height={56}>
                <Text gridColumn="1/3" fSize={10} color={'gray-4'} component={Segment}>
                    {t('blogger')}
                </Text>
                <Text
                    fSize={10}
                    color={currentSorting === 'followers' ? 'gray-1' : 'gray-4'}
                    component={Segment}
                    onClick={() => setSortingKey('followers')}
                    className={cn['hat__item--pointer']}
                >
                    {t('followers')}
                </Text>
                <Text
                    fSize={10}
                    color={currentSorting === 'percent' ? 'gray-1' : 'gray-4'}
                    component={Segment}
                    onClick={() => setSortingKey('percent')}
                    className={cn['hat__item--pointer']}
                >
                    {t('percent')}
                </Text>
                <Text
                    fSize={10}
                    color={currentSorting === 'count' ? 'gray-1' : 'gray-4'}
                    component={Segment}
                    onClick={() => setSortingKey('count')}
                    className={cn['hat__item--pointer']}
                >
                    {t('count')}
                </Text>
                <Text
                    fSize={10}
                    color={currentSorting === 'aq' ? 'gray-1' : 'gray-4'}
                    component={Segment}
                    onClick={() => setSortingKey('aq')}
                    className={cn['hat__item--pointer']}
                >
                    {t('aq')}
                </Text>
                <Text fSize={10} color={'gray-4'} component={Segment} className={cn['hat__item']}>
                    {t('age')}
                </Text>
                <Text fSize={10} color={'gray-4'} component={Segment} className={cn['hat__item']}>
                    {t('gender')}
                </Text>
            </ListItem>
            {sorted.map((item) => (
                <ListItem height={56} key={item.username} className={cn.item}>
                    <Segment>
                        <Avatar src={item.avatar} size={40} />
                    </Segment>
                    <Segment column>
                        <Text
                            style={{
                                whiteSpace: 'nowrap',
                            }}
                            semibold
                            component="div"
                        >
                            {item.fullName}
                        </Text>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${process.env.REACT_APP_ROOT_DOMAIN}/open/Instagram/${item.id}/demo/`}
                        >
                            <Text color="violet-1" component="div">
                                {item.username}
                            </Text>
                        </a>
                    </Segment>
                    <Text component={Segment}>{formatedFollowers(item.followers)}</Text>
                    <Segment>
                        <Colorize percentage={item.intersectionsPercent} />
                    </Segment>
                    <Text component={Segment}>{formatedFollowers(item.intersectionsCount)}</Text>
                    <Segment>
                        <Colorize percentage={item.aq} />
                    </Segment>
                    <Text component={Segment}>{item.age}</Text>
                    <Text component={Segment}>
                        {t(`filters.gender.value.${item.gender === 1 ? 'male' : 'female'}`, undefined, true)}
                    </Text>
                </ListItem>
            ))}
        </ListLayout>
    )
}

export { Overview as default }
