import {
    AudienceInterestsResponse,
    Category,
    DataItem,
    IAudienceInterest,
    IInterest,
    Language,
    TargetGeo,
} from '@/pages/TargetAudience/services/types'
import { Option } from '@/pages/TargetAudience/Views/types'
import { additionalTitles, paramsTitles, tooltips } from '@/pages/TargetAudience/Views/CreateReportStepFive/const'

type Filter = Record<string, { label: string; values: { value: string }[] }>

export const updateFilters = (
    filters: Filter[],
    filterIndex: number,
    filterKey: string,
    valueKey: string
): Filter[] => {
    return filters
        .map((filter, index) => {
            if (index === filterIndex && filter[filterKey]) {
                const updatedValues = filter[filterKey].values.filter(
                    (item: { value: string }) => item.value !== valueKey
                )

                if (updatedValues.length === 0) {
                    const { [filterKey]: _, ...remainingFilters } = filter
                    return Object.keys(remainingFilters).length > 0 ? remainingFilters : null
                }

                return {
                    ...filter,
                    [filterKey]: {
                        ...filter[filterKey],
                        values: updatedValues,
                    },
                }
            }
            return filter
        })
        .filter((filter): filter is Filter => filter !== null)
}

export const mapOptions = (data: DataItem[], language: Language): Option[] => {
    return data.map((item) => ({
        label: item[`name_${language.split('-')[0]}` as keyof DataItem],
        value: item.value,
    }))
}
export const updateOptionsLanguage = (optionsList: Option[], originalOptions: Option[]): Option[] => {
    return optionsList?.map((selected) => {
        const updatedLabel = originalOptions.find((opt) => opt.value === selected.value)?.label
        return { ...selected, label: updatedLabel ?? selected.label }
    })
}

export const transformAudienceData = (data: IAudienceInterest[], lang: string): Category[] => {
    return data.map((category) => ({
        type: category.id.toString(),
        title: lang === 'ru' ? category.name_ru : lang === 'en' ? category.name_en : category.name_pt,
        values: category.interests.map((interest) => ({
            label: lang === 'ru' ? interest.name_ru : lang === 'en' ? interest.name_en : interest.name_pt,
            value: interest.id.toString(),
        })),
    }))
}
export function transformDataByType(
    type: string,
    data: Record<string, Option[]> | Category[]
): { type: string; title: string; tooltip: string; values: Option[] }[] | Category[] {
    if (Array.isArray(data) && data.every((item) => 'title' in item && Array.isArray(item.values))) {
        return data as Category[]
    }

    if (typeof data === 'object' && !Array.isArray(data)) {
        const titles = type === 'additional' ? additionalTitles : paramsTitles

        return Object.entries(data).map(([key, values]) => ({
            type,
            title: titles[key] || 'Неизвестный заголовок',
            tooltip: tooltips[key as keyof typeof tooltips] || titles[key],
            values,
        }))
    }

    return []
}
export const transformGeoOptions = (options: { value: string; label: string }[]) => {
    return options.map((option) => {
        const [type, id] = option.value.split('_')
        return {
            city_id: type === 'city' ? Number(id) : undefined,
            region_id: type === 'region' ? Number(id) : undefined,
        }
    })
}

export const transformInterests = (
    originalInterests: AudienceInterestsResponse,
    dataInterests: IInterest[],
    lang: 'ru' | 'en' | 'pt'
): Category[] => {
    return originalInterests.map((audienceInterest) => {
        const title = audienceInterest[`name_${lang}` as keyof IAudienceInterest] as string
        const type = audienceInterest.id.toString()

        const values: Option[] = audienceInterest.interests
            .filter((interest) => dataInterests.some((i) => i.id === interest.id))
            .map((interest) => ({
                value: interest.id.toString(),
                label: interest[`name_${lang}` as keyof IInterest] as string,
            }))

        return {
            type,
            title,
            values,
        }
    })
}

export function mapOptionsAdapter(
    data: { [key: string]: string }[],
    language: string
): { label: string; value: string }[] {
    return (
        data?.map((item) => ({
            label: item[`name_${language}`] || item.name_en,
            value: item.code,
        })) || []
    )
}
export function transformDataToOptions(data: TargetGeo[], language: string): Option[] {
    return data.map((item) => ({
        label: language === 'ru' ? item.name_ru : language === 'en' ? item.name_en : item.name_pt,
        value: item.city_id ? `city_${item.city_id}` : `region_${item.region_id}`,
    }))
}

type LanguageKeys = 'name_ru' | 'name_en' | 'name_pt'
type IAudienceInterestWithLangKeys = IAudienceInterest & { [key in LanguageKeys]: string }

export function updateInterestsLanguage(
    data: Category[],
    originalData: IAudienceInterestWithLangKeys[],
    lang: 'ru' | 'en' | 'pt'
): Category[] {
    return data.map((item) => {
        const originalItem = originalData?.find((orig) => orig.id.toString() === item.type)

        if (!originalItem) {
            return item
        }

        const titleKey = `name_${lang}` as LanguageKeys
        const updatedTitle = originalItem[titleKey] || item.title

        const updatedValues = item.values?.map((value) => {
            const originalValue = originalItem.interests?.find(
                (origInterest) => origInterest.id.toString() === value.value
            )

            if (!originalValue) {
                return value
            }

            const labelKey = `name_${lang}` as LanguageKeys
            return {
                ...value,
                label: originalValue[labelKey] || value.label,
            }
        })

        return {
            ...item,
            title: updatedTitle,
            values: updatedValues,
        }
    })
}
