import { IChannels, QueryType } from '../types'
import { ApolloQueryResult } from '@apollo/client/core/types'

export const getItems = (channels: ApolloQueryResult<IChannels>, queryType: QueryType, sliceIndex: number) => {
    const data = channels.data[queryType]
    const unlockedChannels = channels.data?.getUnlockedChannels?.unlocked_youtube_channels

    if (channels.error || !data || !unlockedChannels) return []
    const preprocessedData = data.slice(0, sliceIndex).map((item) => {
        return {
            id: item.id,
            avatar: item.avatar,
            city: '',
            er: item.er_views || 0,
            followers: item.subscriber_count || 0,
            fullName: item.title || null,
            promo: false,
            YTtopics: item.channel_topics || [],
            unlocked: unlockedChannels.includes(item.id),
            username: item.title,
            hasEmail: item.email ? true : false,
            views: item.average_video_reactions || 0,
        }
    })

    return preprocessedData
}
