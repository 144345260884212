import { useSpaceTranslation } from '@/_helpers'
import React, { FC, useCallback, useContext, useMemo } from 'react'
import Text from '@/components/Text'
import cn from './Header.module.scss'
import { ChooseAllNEW } from '@/components/MassAddToList/ChooseAll'
import { Config } from '../ConfigurableTable'
import { ConfigMenu } from './ConfigMenu/ConfigMenu'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import { RemoveListMediaPlannerBloggers } from '@/components/MassAddToList/_model/actions'
import { CampaignManagerContext } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerContext'
import { useMutation } from '@apollo/client'
import { DELETE_BLOGGERS_FROM_MEDIA_PLAN } from '../../utils/queries'
import { useCampaignId } from '@/_helpers/Hooks/useCampaignId'

interface HeaderProps {
    config: Config
    onColumnToggle: (column: string) => void
    refetchColumns: () => void
    refetchBloggers: () => void
}
export const Header: FC<HeaderProps> = ({ config, onColumnToggle, refetchColumns, refetchBloggers }) => {
    const t = useSpaceTranslation('media_planner.plan.table.header')
    const selectedBloggers = useSelector((store: RootStore) => store.MassAddToList.mediaPlanner.selectedBloggers)
    const showSelectedMenu = useMemo(() => selectedBloggers.length > 0, [selectedBloggers])

    const dispatch = useDispatch()

    const orderId = useCampaignId()

    const context = useContext(CampaignManagerContext)
    const [deleteBloggersFromMediaPlan] = useMutation(DELETE_BLOGGERS_FROM_MEDIA_PLAN, {
        context: context,
    })

    const gridColumns = useMemo(() => {
        const columns = config.columns.filter((column) => column.active)
        const columnsWidth = columns.map((column) => column.width)
        return columnsWidth.join(' ')
    }, [config])

    const onChooseAllClick = useCallback(() => {
        if (showSelectedMenu) {
            dispatch(RemoveListMediaPlannerBloggers())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSelectedMenu])

    const onDeleteClick = useCallback(() => {
        deleteBloggersFromMediaPlan({
            variables: {
                bloggerIds: selectedBloggers,
                orderId: orderId,
            },
        }).then(() => {
            return refetchBloggers()
        })
    }, [orderId, selectedBloggers, refetchBloggers, deleteBloggersFromMediaPlan])

    if (showSelectedMenu) {
        return (
            <div className={cn.header} style={{ gridTemplateColumns: `28px 256px ${gridColumns}` }}>
                <div onClick={onChooseAllClick}>
                    <ChooseAllNEW platform="media-bloggers" value={showSelectedMenu ? 'dashed' : 'empty'} />
                </div>
                <div className={cn['menu-button']} onClick={onDeleteClick}>
                    <Text color="gray-2" fSize={10}>
                        {t('delete')}
                    </Text>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className={cn.header} style={{ gridTemplateColumns: `28px 256px ${gridColumns}` }}>
                <div onClick={onChooseAllClick}>
                    <ChooseAllNEW platform="media-bloggers" value={showSelectedMenu ? 'dashed' : 'empty'} />
                </div>

                <Text color="gray-3" fSize={10}>
                    {t('name')}
                </Text>

                {config.columns
                    .filter((column) => column.active)
                    .map((column) => (
                        <Text color="gray-3" fSize={10} key={column.key}>
                            {column.name}
                        </Text>
                    ))}

                <ConfigMenu config={config} onColumnToggle={onColumnToggle} refetch={refetchColumns} />
            </div>
        </>
    )
}
