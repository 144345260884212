import React, { FC, memo, useState } from 'react'
import { TwitchSearchItem } from '../types'
import Text from '@/components/Text'

import cn from './SearchItem.module.scss'
import { MassAddCheckBox } from '@/components/MassAddToList'
import Avatar from '@/components/Avatar'
import { getShortString } from '@/_helpers/strings/getShortString'
import { shortNum } from '@/_helpers/_graphs/_tech'
import Flex from '@/_yc/Flex'
import Button from '@/components/Button'
import { useSpaceTranslation } from '@/_helpers'
import { ExternalLink } from '@/components/Share/ExternalLink/ExternalLink'
import AddToList from '@/pages/Lists/components/AddToList/AddToList'
import Locker from '@/components/Locker'
import BloggerLink from '@/components/Share/BloggerLink'
import { trackOpenReport } from '@/_helpers/amplitude/track'
import { Platform } from '@/pages/Reports/types'
import { Like } from '@/components/Like/Like'
import { Comment, NewComment } from '@/components/Comment/Comment'
import useOpenClose from '@/_helpers/useOpenClose'
import { AddCommentToBloggerReq, CreateCommentToBloggerReq } from '@/pages/Lists/model/service'
import useToken from '@/_helpers/Hooks/useToken'

interface SearchItemProps extends TwitchSearchItem {
    index: number
    margin?: string | number
    likedId?: number
    comment?: { comment: string; comment_id: number }
}

const SearchItem: FC<SearchItemProps> = (props) => {
    const t = useSpaceTranslation('Influencer_explorer')
    const locked = !props.unlocked

    // comment
    const token = useToken()
    const [isOpen, open, close] = useOpenClose()
    const [comment, setComment] = useState<string | undefined>(props.comment?.comment)
    const addComment = (valueComment: string) => {
        if (!token) return
        if (props.comment?.comment_id)
            AddCommentToBloggerReq(token, valueComment, props.id.toString(), props.comment.comment_id).then(() => {
                setComment(valueComment)
            })
        else
            CreateCommentToBloggerReq(token, valueComment, props.id.toString()).then(() => {
                setComment(valueComment)
            })
        close()
    }

    const handleOpen = (e: any) => {
        e.preventDefault()
        open()
    }
    return (
        <div className={cn.row}>
            <MassAddCheckBox
                data={{
                    hasEmail: false,
                    avatar: props.avatar,
                    name: props.username,
                    username: props.username,
                }}
                id={props.id.toString()}
                externalId={props.id.toString()}
                type={Platform.Twitch}
                key={props.id}
                index={props.index}
            />

            <Text fSize={12}>{props.index}</Text>

            <Flex content="flex-start" align="center">
                <Avatar src={props.avatar} />

                <Text semibold margin="0 0 0 12px">
                    {getShortString(props.username, 30)}
                </Text>
                {(!comment || comment.length === 0) && (
                    <Flex className={cn['new-comment']}>
                        <NewComment handleOpen={handleOpen} />
                    </Flex>
                )}
            </Flex>

            <Text margin="0 0 0 12px" fSize={12}>
                {shortNum(props.followers)}
            </Text>

            <Text margin="0 0 0 12px" fSize={12}>
                {shortNum(props.averageViews)}
            </Text>

            <Flex content="flex-end" align="center" style={{ gap: 10 }}>
                {locked ? (
                    <Locker
                        style={{
                            width: 120,
                            flexGrow: 0,
                            padding: 0,
                        }}
                        platform={Platform.Twitch}
                        isPromo={false}
                        id={props.id}
                        label={t('found_blogger.unlock')}
                    />
                ) : (
                    <BloggerLink newTab id={props.id} platform={Platform.Twitch}>
                        <Button
                            width={120}
                            size="small"
                            style={{ padding: 0 }}
                            variant={locked ? 'outline' : 'primary'}
                            onClick={() => trackOpenReport('infl_exp_twitch')}
                        >
                            {locked
                                ? t('found_blogger.unlock')
                                : false
                                ? t('found_blogger.analyze_button_promo')
                                : t('found_blogger.analyze_button_label')}
                        </Button>
                    </BloggerLink>
                )}
                <ExternalLink link={`https://twitch.com/${props.username}`} />
                {/* Refactor AddToList - move to components, generalize, separate view from logic, make mobile popup general */}
                <AddToList type={Platform.Twitch} external_id={props.id.toString()} place="infl_exp_twitch" />
                <Flex>
                    <Like externalId={props.id.toString()} likeId={props.likedId} platform={Platform.Twitch} />
                </Flex>
            </Flex>
            <Comment
                isOpen={isOpen}
                open={open}
                close={close}
                value={comment}
                setValue={setComment}
                submit={addComment}
                style={{ gridColumn: '3/9', margin: '0 0 0 62px' }}
            />
        </div>
    )
}

export default memo(SearchItem)
