import { useState } from 'react'

export default <Source, ValueType>(handler: (s: Source[]) => ValueType, sources: Source[]) => {
    const [prevSource, setPrevSource] = useState<Source[]>(sources);
    const [value, setValue] = useState<ValueType>(handler(sources))

    if (prevSource.length !== sources.length){
        setValue(handler(sources))
        setPrevSource(sources)
    } else if (sources.length > 0){
        for(let i = 0; i < sources.length; i++){
            if (sources[i] !== prevSource[i]) {
                setValue(handler(sources))
                setPrevSource(sources)
                break;
            }
        }
    }

    return value
}