import { BlockTitle } from '../BloggerReport/ReadyCards'
import { IRefs } from '../BloggerReport/Types'

export default (refs: IRefs) => [
    [BlockTitle('account_data.title', refs.accountData)],
    {
        type: 'line_chart',
        info: {
            follows_v2: followsData(),
            followers_v2: followersData(),
            erStat_v2: erData(),
        },
        inHidder: true,
    },
    [BlockTitle('auditory.title', refs.audience)],
    [qualityAudienceBlock, audienceReach],
    [ageGender, genderSplit],
    [notableFollowersBlock],
    [cities, countries, languages],
    [AudienceInterests(), AudienceBrandAffinity()],
    [BlockTitle('brandAffinity.title', refs.brands, '0')],
    [brandAffinity],
    [BlockTitle('lookalikes.title', refs.similarAccs, '0')],
    [lookalikes],
    [BlockTitle('content.title', refs.content)],
    [engagementRate],
    [allocationChart],
    [PostTypes(), tags],
]

export const erData = () => ({
    mainValue: 0.01297,
    hidden: false,
    weekly_30: {
        dataSet: [
            {
                value: 0.012305751,
                label: '2021-12-19',
                step: 431,
            },
            {
                value: 0.012297864,
                label: '2021-12-20',
                step: 432,
            },
            {
                value: 0.012128934,
                label: '2021-12-21',
                step: 433,
            },
            {
                value: 0.012174939,
                label: '2021-12-22',
                step: 434,
            },
        ],
        changes: '0.3800',
    },
    weekly_180: {
        dataSet: [
            {
                value: 0.012962121,
                label: '2021-11-27',
                step: 411,
            },
            {
                value: 0.012676212,
                label: '2021-11-28',
                step: 412,
            },
            {
                value: 0.0146501865,
                label: '2021-11-29',
                step: 413,
            },
            {
                value: 0.013348058,
                label: '2021-11-30',
                step: 414,
            },
            {
                value: 0.014213368,
                label: '2021-12-01',
                step: 415,
            },
            {
                value: 0.014650152,
                label: '2021-12-02',
                step: 416,
            },
            {
                value: 0.014055868,
                label: '2021-12-03',
                step: 417,
            },
            {
                value: 0.014273047,
                label: '2021-12-04',
                step: 418,
            },
            {
                value: 0.014026092,
                label: '2021-12-05',
                step: 419,
            },
            {
                value: 0.0134158125,
                label: '2021-12-06',
                step: 420,
            },
            {
                value: 0.013603019,
                label: '2021-12-07',
                step: 421,
            },
            {
                value: 0.013449794,
                label: '2021-12-08',
                step: 422,
            },
            {
                value: 0.013414586,
                label: '2021-12-09',
                step: 423,
            },
            {
                value: 0.014176978,
                label: '2021-12-10',
                step: 424,
            },
            {
                value: 0.021696638,
                label: '2021-12-12',
                step: 425,
            },
            {
                value: 0.017068313,
                label: '2021-12-13',
                step: 426,
            },
            {
                value: 0.016097585,
                label: '2021-12-14',
                step: 427,
            },
            {
                value: 0.016172407,
                label: '2021-12-15',
                step: 428,
            },
            {
                value: 0.008792925,
                label: '2021-12-16',
                step: 429,
            },
            {
                value: 0.010140417,
                label: '2021-12-17',
                step: 430,
            },
            {
                value: 0.012305751,
                label: '2021-12-19',
                step: 431,
            },
            {
                value: 0.012297864,
                label: '2021-12-20',
                step: 432,
            },
            {
                value: 0.012128934,
                label: '2021-12-21',
                step: 433,
            },
            {
                value: 0.012174939,
                label: '2021-12-22',
                step: 434,
            },
        ],
        changes: '0.3800',
    },
    weekly_365: {
        dataSet: [
            {
                value: 0.01354075,
                label: '2021-10-18',
                step: 383,
            },
            {
                value: 0.021819443,
                label: '2021-10-20',
                step: 384,
            },
            {
                value: 0.015237915,
                label: '2021-10-21',
                step: 385,
            },
            {
                value: 0.015231524,
                label: '2021-10-22',
                step: 386,
            },
            {
                value: 0.013437723,
                label: '2021-10-25',
                step: 387,
            },
            {
                value: 0.0128473,
                label: '2021-10-26',
                step: 388,
            },
            {
                value: 0.013612001,
                label: '2021-10-27',
                step: 389,
            },
            {
                value: 0.013577738,
                label: '2021-10-28',
                step: 390,
            },
            {
                value: 0.015925815,
                label: '2021-10-29',
                step: 391,
            },
            {
                value: 0.01591118,
                label: '2021-11-01',
                step: 392,
            },
            {
                value: 0.015900562,
                label: '2021-11-02',
                step: 393,
            },
            {
                value: 0.015872585,
                label: '2021-11-03',
                step: 394,
            },
            {
                value: 0.015850138,
                label: '2021-11-08',
                step: 395,
            },
            {
                value: 0.015982669,
                label: '2021-11-09',
                step: 396,
            },
            {
                value: 0.019948464,
                label: '2021-11-10',
                step: 397,
            },
            {
                value: 0.019728344,
                label: '2021-11-11',
                step: 398,
            },
            {
                value: 0.019646406,
                label: '2021-11-12',
                step: 399,
            },
            {
                value: 0.020934971,
                label: '2021-11-15',
                step: 400,
            },
            {
                value: 0.021731803,
                label: '2021-11-16',
                step: 401,
            },
            {
                value: 0.02049821,
                label: '2021-11-17',
                step: 402,
            },
            {
                value: 0.018041711,
                label: '2021-11-18',
                step: 403,
            },
            {
                value: 0.015808098,
                label: '2021-11-19',
                step: 404,
            },
            {
                value: 0.020538047,
                label: '2021-11-21',
                step: 405,
            },
            {
                value: 0.0155495545,
                label: '2021-11-22',
                step: 406,
            },
            {
                value: 0.014708351,
                label: '2021-11-23',
                step: 407,
            },
            {
                value: 0.01464791,
                label: '2021-11-24',
                step: 408,
            },
            {
                value: 0.014292524,
                label: '2021-11-25',
                step: 409,
            },
            {
                value: 0.012837638,
                label: '2021-11-26',
                step: 410,
            },
            {
                value: 0.012962121,
                label: '2021-11-27',
                step: 411,
            },
            {
                value: 0.012676212,
                label: '2021-11-28',
                step: 412,
            },
            {
                value: 0.0146501865,
                label: '2021-11-29',
                step: 413,
            },
            {
                value: 0.013348058,
                label: '2021-11-30',
                step: 414,
            },
            {
                value: 0.014213368,
                label: '2021-12-01',
                step: 415,
            },
            {
                value: 0.014650152,
                label: '2021-12-02',
                step: 416,
            },
            {
                value: 0.014055868,
                label: '2021-12-03',
                step: 417,
            },
            {
                value: 0.014273047,
                label: '2021-12-04',
                step: 418,
            },
            {
                value: 0.014026092,
                label: '2021-12-05',
                step: 419,
            },
            {
                value: 0.0134158125,
                label: '2021-12-06',
                step: 420,
            },
            {
                value: 0.013603019,
                label: '2021-12-07',
                step: 421,
            },
            {
                value: 0.013449794,
                label: '2021-12-08',
                step: 422,
            },
            {
                value: 0.013414586,
                label: '2021-12-09',
                step: 423,
            },
            {
                value: 0.014176978,
                label: '2021-12-10',
                step: 424,
            },
            {
                value: 0.021696638,
                label: '2021-12-12',
                step: 425,
            },
            {
                value: 0.017068313,
                label: '2021-12-13',
                step: 426,
            },
            {
                value: 0.016097585,
                label: '2021-12-14',
                step: 427,
            },
            {
                value: 0.016172407,
                label: '2021-12-15',
                step: 428,
            },
            {
                value: 0.008792925,
                label: '2021-12-16',
                step: 429,
            },
            {
                value: 0.010140417,
                label: '2021-12-17',
                step: 430,
            },
            {
                value: 0.012305751,
                label: '2021-12-19',
                step: 431,
            },
            {
                value: 0.012297864,
                label: '2021-12-20',
                step: 432,
            },
            {
                value: 0.012128934,
                label: '2021-12-21',
                step: 433,
            },
            {
                value: 0.012174939,
                label: '2021-12-22',
                step: 434,
            },
        ],
        changes: '0.3800',
    },
    monthly: {
        dataSet: [
            {
                value: 0.03193074,
                label: '2021-01-01',
                step: 13,
            },
            {
                value: 0.028191954,
                label: '2021-02-01',
                step: 8,
            },
            {
                value: 0.027100887,
                label: '2021-03-01',
                step: 6,
            },
            {
                value: 0.02550963,
                label: '2021-04-01',
                step: 2,
            },
            {
                value: 0.045321926,
                label: '2021-05-01',
                step: 26,
            },
            {
                value: 0.016342144,
                label: '2021-06-01',
                step: 9,
            },
            {
                value: 0.023052635,
                label: '2021-07-01',
                step: 11,
            },
            {
                value: 0.020628318,
                label: '2021-08-01',
                step: 14,
            },
            {
                value: 0.014988508,
                label: '2021-09-01',
                step: 15,
            },
            {
                value: 0.026062567,
                label: '2021-10-01',
                step: 22,
            },
            {
                value: 0.021731803,
                label: '2021-11-01',
                step: 19,
            },
            {
                value: 0.021696638,
                label: '2021-12-01',
                step: 3,
            },
        ],
        changes: '-0.1600',
    },
})

export const followersData = () => ({
    mainValue: 30235505,
    hidden: false,
    weekly_30: {
        dataSet: [
            {
                value: 32150658,
                label: '2021-12-19',
                step: 431,
            },
            {
                value: 32161031,
                label: '2021-12-20',
                step: 432,
            },
            {
                value: 32194338,
                label: '2021-12-21',
                step: 433,
            },
            {
                value: 32198190,
                label: '2021-12-22',
                step: 434,
            },
        ],
        changes: '0.0100',
    },
    weekly_180: {
        dataSet: [
            {
                value: 31866931,
                label: '2021-11-27',
                step: 411,
            },
            {
                value: 31887957,
                label: '2021-11-28',
                step: 412,
            },
            {
                value: 31897239,
                label: '2021-11-29',
                step: 413,
            },
            {
                value: 31919551,
                label: '2021-11-30',
                step: 414,
            },
            {
                value: 31915447,
                label: '2021-12-01',
                step: 415,
            },
            {
                value: 31924207,
                label: '2021-12-02',
                step: 416,
            },
            {
                value: 31946586,
                label: '2021-12-03',
                step: 417,
            },
            {
                value: 31945631,
                label: '2021-12-04',
                step: 418,
            },
            {
                value: 31956834,
                label: '2021-12-05',
                step: 419,
            },
            {
                value: 31981180,
                label: '2021-12-06',
                step: 420,
            },
            {
                value: 31998781,
                label: '2021-12-07',
                step: 421,
            },
            {
                value: 31986810,
                label: '2021-12-08',
                step: 422,
            },
            {
                value: 32006579,
                label: '2021-12-09',
                step: 423,
            },
            {
                value: 32024527,
                label: '2021-12-10',
                step: 424,
            },
            {
                value: 32077780,
                label: '2021-12-12',
                step: 425,
            },
            {
                value: 32079122,
                label: '2021-12-13',
                step: 426,
            },
            {
                value: 32084501,
                label: '2021-12-14',
                step: 427,
            },
            {
                value: 32090616,
                label: '2021-12-15',
                step: 428,
            },
            {
                value: 32093643,
                label: '2021-12-16',
                step: 429,
            },
            {
                value: 32122346,
                label: '2021-12-17',
                step: 430,
            },
            {
                value: 32150658,
                label: '2021-12-19',
                step: 431,
            },
            {
                value: 32161031,
                label: '2021-12-20',
                step: 432,
            },
            {
                value: 32194338,
                label: '2021-12-21',
                step: 433,
            },
            {
                value: 32198190,
                label: '2021-12-22',
                step: 434,
            },
        ],
        changes: '0.0100',
    },
    weekly_365: {
        dataSet: [
            {
                value: 31049388,
                label: '2021-10-18',
                step: 383,
            },
            {
                value: 31080744,
                label: '2021-10-20',
                step: 384,
            },
            {
                value: 31092508,
                label: '2021-10-21',
                step: 385,
            },
            {
                value: 31107918,
                label: '2021-10-22',
                step: 386,
            },
            {
                value: 31161567,
                label: '2021-10-25',
                step: 387,
            },
            {
                value: 31188033,
                label: '2021-10-26',
                step: 388,
            },
            {
                value: 31205293,
                label: '2021-10-27',
                step: 389,
            },
            {
                value: 31226556,
                label: '2021-10-28',
                step: 390,
            },
            {
                value: 31241069,
                label: '2021-10-29',
                step: 391,
            },
            {
                value: 31333217,
                label: '2021-11-01',
                step: 392,
            },
            {
                value: 31363893,
                label: '2021-11-02',
                step: 393,
            },
            {
                value: 31388272,
                label: '2021-11-03',
                step: 394,
            },
            {
                value: 31516287,
                label: '2021-11-08',
                step: 395,
            },
            {
                value: 31533657,
                label: '2021-11-09',
                step: 396,
            },
            {
                value: 31552203,
                label: '2021-11-10',
                step: 397,
            },
            {
                value: 31567322,
                label: '2021-11-11',
                step: 398,
            },
            {
                value: 31592446,
                label: '2021-11-12',
                step: 399,
            },
            {
                value: 31654306,
                label: '2021-11-15',
                step: 400,
            },
            {
                value: 31669715,
                label: '2021-11-16',
                step: 401,
            },
            {
                value: 31692887,
                label: '2021-11-17',
                step: 402,
            },
            {
                value: 31711460,
                label: '2021-11-18',
                step: 403,
            },
            {
                value: 31747714,
                label: '2021-11-19',
                step: 404,
            },
            {
                value: 31784888,
                label: '2021-11-21',
                step: 405,
            },
            {
                value: 31787984,
                label: '2021-11-22',
                step: 406,
            },
            {
                value: 31816857,
                label: '2021-11-23',
                step: 407,
            },
            {
                value: 31840993,
                label: '2021-11-24',
                step: 408,
            },
            {
                value: 31848502,
                label: '2021-11-25',
                step: 409,
            },
            {
                value: 31863804,
                label: '2021-11-26',
                step: 410,
            },
            {
                value: 31866931,
                label: '2021-11-27',
                step: 411,
            },
            {
                value: 31887957,
                label: '2021-11-28',
                step: 412,
            },
            {
                value: 31897239,
                label: '2021-11-29',
                step: 413,
            },
            {
                value: 31919551,
                label: '2021-11-30',
                step: 414,
            },
            {
                value: 31915447,
                label: '2021-12-01',
                step: 415,
            },
            {
                value: 31924207,
                label: '2021-12-02',
                step: 416,
            },
            {
                value: 31946586,
                label: '2021-12-03',
                step: 417,
            },
            {
                value: 31945631,
                label: '2021-12-04',
                step: 418,
            },
            {
                value: 31956834,
                label: '2021-12-05',
                step: 419,
            },
            {
                value: 31981180,
                label: '2021-12-06',
                step: 420,
            },
            {
                value: 31998781,
                label: '2021-12-07',
                step: 421,
            },
            {
                value: 31986810,
                label: '2021-12-08',
                step: 422,
            },
            {
                value: 32006579,
                label: '2021-12-09',
                step: 423,
            },
            {
                value: 32024527,
                label: '2021-12-10',
                step: 424,
            },
            {
                value: 32077780,
                label: '2021-12-12',
                step: 425,
            },
            {
                value: 32079122,
                label: '2021-12-13',
                step: 426,
            },
            {
                value: 32084501,
                label: '2021-12-14',
                step: 427,
            },
            {
                value: 32090616,
                label: '2021-12-15',
                step: 428,
            },
            {
                value: 32093643,
                label: '2021-12-16',
                step: 429,
            },
            {
                value: 32122346,
                label: '2021-12-17',
                step: 430,
            },
            {
                value: 32150658,
                label: '2021-12-19',
                step: 431,
            },
            {
                value: 32161031,
                label: '2021-12-20',
                step: 432,
            },
            {
                value: 32194338,
                label: '2021-12-21',
                step: 433,
            },
            {
                value: 32198190,
                label: '2021-12-22',
                step: 434,
            },
        ],
        changes: '0.0100',
    },
    monthly: {
        dataSet: [
            {
                value: 26833965,
                label: '2021-01-01',
                step: 13,
            },
            {
                value: 27287740,
                label: '2021-02-01',
                step: 8,
            },
            {
                value: 27801856,
                label: '2021-03-01',
                step: 6,
            },
            {
                value: 27917025,
                label: '2021-04-01',
                step: 2,
            },
            {
                value: 28344045,
                label: '2021-05-01',
                step: 26,
            },
            {
                value: 29106530,
                label: '2021-06-01',
                step: 9,
            },
            {
                value: 29496439,
                label: '2021-07-01',
                step: 11,
            },
            {
                value: 30049872,
                label: '2021-08-01',
                step: 14,
            },
            {
                value: 30327731,
                label: '2021-09-01',
                step: 15,
            },
            {
                value: 30956153,
                label: '2021-10-01',
                step: 22,
            },
            {
                value: 31695216,
                label: '2021-11-01',
                step: 19,
            },
            {
                value: 32046940,
                label: '2021-12-01',
                step: 3,
            },
        ],
        changes: '1.1100',
    },
})

export const followsData = () => ({
    mainValue: 455,
    hidden: false,
    weekly_30: {
        dataSet: [
            {
                value: 487,
                label: '2021-12-19',
                step: 431,
            },
            {
                value: 487,
                label: '2021-12-20',
                step: 432,
            },
            {
                value: 487,
                label: '2021-12-21',
                step: 433,
            },
            {
                value: 487,
                label: '2021-12-22',
                step: 434,
            },
        ],
        changes: '0.0000',
    },
    weekly_180: {
        dataSet: [
            {
                value: 479,
                label: '2021-11-27',
                step: 411,
            },
            {
                value: 479,
                label: '2021-11-28',
                step: 412,
            },
            {
                value: 479,
                label: '2021-11-29',
                step: 413,
            },
            {
                value: 482,
                label: '2021-11-30',
                step: 414,
            },
            {
                value: 482,
                label: '2021-12-01',
                step: 415,
            },
            {
                value: 482,
                label: '2021-12-02',
                step: 416,
            },
            {
                value: 481,
                label: '2021-12-03',
                step: 417,
            },
            {
                value: 481,
                label: '2021-12-04',
                step: 418,
            },
            {
                value: 480,
                label: '2021-12-05',
                step: 419,
            },
            {
                value: 482,
                label: '2021-12-06',
                step: 420,
            },
            {
                value: 483,
                label: '2021-12-07',
                step: 421,
            },
            {
                value: 483,
                label: '2021-12-08',
                step: 422,
            },
            {
                value: 483,
                label: '2021-12-09',
                step: 423,
            },
            {
                value: 483,
                label: '2021-12-10',
                step: 424,
            },
            {
                value: 484,
                label: '2021-12-12',
                step: 425,
            },
            {
                value: 484,
                label: '2021-12-13',
                step: 426,
            },
            {
                value: 486,
                label: '2021-12-14',
                step: 427,
            },
            {
                value: 486,
                label: '2021-12-15',
                step: 428,
            },
            {
                value: 487,
                label: '2021-12-16',
                step: 429,
            },
            {
                value: 487,
                label: '2021-12-17',
                step: 430,
            },
            {
                value: 487,
                label: '2021-12-19',
                step: 431,
            },
            {
                value: 487,
                label: '2021-12-20',
                step: 432,
            },
            {
                value: 487,
                label: '2021-12-21',
                step: 433,
            },
            {
                value: 487,
                label: '2021-12-22',
                step: 434,
            },
        ],
        changes: '0.0000',
    },
    weekly_365: {
        dataSet: [
            {
                value: 472,
                label: '2021-10-18',
                step: 383,
            },
            {
                value: 471,
                label: '2021-10-20',
                step: 384,
            },
            {
                value: 472,
                label: '2021-10-21',
                step: 385,
            },
            {
                value: 473,
                label: '2021-10-22',
                step: 386,
            },
            {
                value: 472,
                label: '2021-10-25',
                step: 387,
            },
            {
                value: 474,
                label: '2021-10-26',
                step: 388,
            },
            {
                value: 474,
                label: '2021-10-27',
                step: 389,
            },
            {
                value: 476,
                label: '2021-10-28',
                step: 390,
            },
            {
                value: 477,
                label: '2021-10-29',
                step: 391,
            },
            {
                value: 477,
                label: '2021-11-01',
                step: 392,
            },
            {
                value: 478,
                label: '2021-11-02',
                step: 393,
            },
            {
                value: 478,
                label: '2021-11-03',
                step: 394,
            },
            {
                value: 478,
                label: '2021-11-08',
                step: 395,
            },
            {
                value: 479,
                label: '2021-11-09',
                step: 396,
            },
            {
                value: 478,
                label: '2021-11-10',
                step: 397,
            },
            {
                value: 478,
                label: '2021-11-11',
                step: 398,
            },
            {
                value: 479,
                label: '2021-11-12',
                step: 399,
            },
            {
                value: 477,
                label: '2021-11-15',
                step: 400,
            },
            {
                value: 477,
                label: '2021-11-16',
                step: 401,
            },
            {
                value: 477,
                label: '2021-11-17',
                step: 402,
            },
            {
                value: 477,
                label: '2021-11-18',
                step: 403,
            },
            {
                value: 478,
                label: '2021-11-19',
                step: 404,
            },
            {
                value: 478,
                label: '2021-11-21',
                step: 405,
            },
            {
                value: 478,
                label: '2021-11-22',
                step: 406,
            },
            {
                value: 478,
                label: '2021-11-23',
                step: 407,
            },
            {
                value: 479,
                label: '2021-11-24',
                step: 408,
            },
            {
                value: 479,
                label: '2021-11-25',
                step: 409,
            },
            {
                value: 480,
                label: '2021-11-26',
                step: 410,
            },
            {
                value: 479,
                label: '2021-11-27',
                step: 411,
            },
            {
                value: 479,
                label: '2021-11-28',
                step: 412,
            },
            {
                value: 479,
                label: '2021-11-29',
                step: 413,
            },
            {
                value: 482,
                label: '2021-11-30',
                step: 414,
            },
            {
                value: 482,
                label: '2021-12-01',
                step: 415,
            },
            {
                value: 482,
                label: '2021-12-02',
                step: 416,
            },
            {
                value: 481,
                label: '2021-12-03',
                step: 417,
            },
            {
                value: 481,
                label: '2021-12-04',
                step: 418,
            },
            {
                value: 480,
                label: '2021-12-05',
                step: 419,
            },
            {
                value: 482,
                label: '2021-12-06',
                step: 420,
            },
            {
                value: 483,
                label: '2021-12-07',
                step: 421,
            },
            {
                value: 483,
                label: '2021-12-08',
                step: 422,
            },
            {
                value: 483,
                label: '2021-12-09',
                step: 423,
            },
            {
                value: 483,
                label: '2021-12-10',
                step: 424,
            },
            {
                value: 484,
                label: '2021-12-12',
                step: 425,
            },
            {
                value: 484,
                label: '2021-12-13',
                step: 426,
            },
            {
                value: 486,
                label: '2021-12-14',
                step: 427,
            },
            {
                value: 486,
                label: '2021-12-15',
                step: 428,
            },
            {
                value: 487,
                label: '2021-12-16',
                step: 429,
            },
            {
                value: 487,
                label: '2021-12-17',
                step: 430,
            },
            {
                value: 487,
                label: '2021-12-19',
                step: 431,
            },
            {
                value: 487,
                label: '2021-12-20',
                step: 432,
            },
            {
                value: 487,
                label: '2021-12-21',
                step: 433,
            },
            {
                value: 487,
                label: '2021-12-22',
                step: 434,
            },
        ],
        changes: '0.0000',
    },
    monthly: {
        dataSet: [
            {
                value: 421,
                label: '2021-01-01',
                step: 13,
            },
            {
                value: 430,
                label: '2021-02-01',
                step: 8,
            },
            {
                value: 437,
                label: '2021-03-01',
                step: 6,
            },
            {
                value: 439,
                label: '2021-04-01',
                step: 2,
            },
            {
                value: 439,
                label: '2021-05-01',
                step: 26,
            },
            {
                value: 452,
                label: '2021-06-01',
                step: 9,
            },
            {
                value: 447,
                label: '2021-07-01',
                step: 11,
            },
            {
                value: 452,
                label: '2021-08-01',
                step: 14,
            },
            {
                value: 458,
                label: '2021-09-01',
                step: 15,
            },
            {
                value: 471,
                label: '2021-10-01',
                step: 22,
            },
            {
                value: 477,
                label: '2021-11-01',
                step: 19,
            },
            {
                value: 484,
                label: '2021-12-01',
                step: 3,
            },
        ],
        changes: '1.4700',
    },
})

const lookalikesData = [
    {
        label: 'kyliejenner',
        subLabel: 'Kylie ✨',
        url: 'https://storage.googleapis.com/yolomedia/avatars/kyliejenner.jpg',
    },
    {
        label: 'billieeilish',
        subLabel: 'BILLIE EILISH',
        url: 'https://storage.googleapis.com/yolomedia/avatars/billieeilish.jpg',
    },
    {
        label: 'buzova86',
        subLabel: 'Ольга Бузова',
        url: 'https://storage.googleapis.com/yolomedia/buzova86.jpe',
    },
    {
        label: 'timatiofficial',
        subLabel: 'Black Star',
        url: 'https://storage.googleapis.com/yolomedia/timatiofficial.jpe',
    },
    {
        label: 'pavelvolyaofficial',
        subLabel: 'Павел Воля | Pavel Volya',
        url: 'https://storage.googleapis.com/yolomedia/pavelvolyaofficial.jpe',
    },
    {
        label: 'borodylia',
        subLabel: 'Ксения Бородина',
        url: 'https://storage.googleapis.com/yolomedia/borodylia.jpe',
    },
    {
        label: 'ptencoff',
        subLabel: 'Лучшие Видео🐥',
        url: 'https://storage.googleapis.com/yolomedia/ptencoff.jpe',
    },
    {
        label: 'tnt_online',
        subLabel: 'Телеканал ТНТ',
        url: 'https://storage.googleapis.com/yolomedia/tnt_online.jpe',
    },
    {
        label: 'gusein.gasanov',
        subLabel: 'ГУСЕЙН ГАСАНОВ',
        url: 'https://storage.googleapis.com/yolomedia/gusein.gasanov.jpe',
    },
    {
        label: 'ververa',
        subLabel: 'Vera Brezhneva',
        url: 'https://storage.googleapis.com/yolomedia/ververa.jpe',
    },
    {
        label: 'egorkreed',
        subLabel: 'Егор Крид | #HeartBreakKid',
        url: 'https://storage.googleapis.com/yolomedia/egorkreed.jpe',
    },
    {
        label: 'samburskaya',
        subLabel: 'Настасья СамбурскАя',
        url: 'https://storage.googleapis.com/yolomedia/samburskaya.jpe',
    },
    {
        label: 'samoylovaoxana',
        subLabel: 'Samoylova Oxana',
        url: 'https://storage.googleapis.com/yolomedia/samoylovaoxana.jpe',
    },
    {
        label: 'zelenskiy_official',
        subLabel: 'Володимир Зеленський',
        url: 'https://storage.googleapis.com/yolomedia/zelenskiy_official.jpe',
    },
    {
        label: 'mikhail_litvin',
        subLabel: 'Михаил Литвин',
        url: 'https://storage.googleapis.com/yolomedia/mikhail_litvin.jpe',
    },
    {
        label: 'annakhilkevich',
        subLabel: 'Анна Хилькевич',
        url: 'https://storage.googleapis.com/yolomedia/annakhilkevich.jpe',
    },
    {
        label: 'dava_m',
        subLabel: 'Давид Манукян',
        url: 'https://storage.googleapis.com/yolomedia/dava_m.jpe',
    },
    {
        label: 'gagara1987',
        subLabel: 'Polina Gagarina 🇷🇺',
        url: 'https://storage.googleapis.com/yolomedia/gagara1987.jpe',
    },
    {
        label: 'golos',
        subLabel: 'Видео Юмор',
        url: 'https://storage.googleapis.com/yolomedia/golos.jpe',
    },
    {
        label: 'kateclapp',
        subLabel: 'Kate Clapp / Катя Клэп',
        url: 'https://storage.googleapis.com/yolomedia/kateclapp.jpe',
    },
    {
        label: 'reginatodorenko',
        subLabel: 'regina todorenko',
        url: 'https://storage.googleapis.com/yolomedia/reginatodorenko.jpe',
    },
    {
        label: 'garikkharlamov',
        subLabel: 'Гарик Харламов',
        url: 'https://storage.googleapis.com/yolomedia/garikkharlamov.jpe',
    },
    {
        label: 'maxgalkinru',
        subLabel: 'Максим Галкин',
        url: 'https://storage.googleapis.com/yolomedia/maxgalkinru.jpe',
    },
    {
        label: 'goar_avetisyan',
        subLabel: 'Goar Avetisyan',
        url: 'https://storage.googleapis.com/yolomedia/goar_avetisyan.jpe',
    },
    {
        label: 'missalena.92',
        subLabel: 'Alena Shishkova',
        url: 'https://storage.googleapis.com/yolomedia/missalena.92.jpe',
    },
    {
        label: 'maryanaro',
        subLabel: 'Maryana Ro',
        url: 'https://storage.googleapis.com/yolomedia/maryanaro.jpe',
    },
    {
        label: 'kativarnava',
        subLabel: 'КАТЯ ВАРНАВА',
        url: 'https://storage.googleapis.com/yolomedia/kativarnava.jpe',
    },
    {
        label: 'galichida',
        subLabel: 'ИДА ГАЛИЧ / ИДА БАСИЕВА',
        url: 'https://storage.googleapis.com/yolomedia/galichida.jpe',
    },
    {
        label: 'lobodaofficial',
        subLabel: 'LOBODA',
        url: 'https://storage.googleapis.com/yolomedia/lobodaofficial.jpe',
    },
    {
        label: 'karinakross',
        subLabel: 'Карина Лазарьянц',
        url: 'https://storage.googleapis.com/yolomedia/karinakross.jpe',
    },
    {
        label: 'lenatemnikovaofficial',
        subLabel: 'Elena Temnikova ・ Темникова',
        url: 'https://storage.googleapis.com/yolomedia/lenatemnikovaofficial.jpe',
    },
    {
        label: 'shnurovs',
        subLabel: 'Shnurov Sergey',
        url: 'https://storage.googleapis.com/yolomedia/shnurovs.jpe',
    },
    {
        label: 'sashaspilberg',
        subLabel: 'SASHA SPILBERG',
        url: 'https://storage.googleapis.com/yolomedia/sashaspilberg.jpe',
    },
    {
        label: 'maria__way',
        subLabel: 'Маша Вэй 👶🏻 Maria Way',
        url: 'https://storage.googleapis.com/yolomedia/maria__way.jpe',
    },
    {
        label: 'annasedokova',
        subLabel: 'ANNA SEDOKOVA',
        url: 'https://storage.googleapis.com/yolomedia/annasedokova.jpe',
    },
    {
        label: 'mmott23',
        subLabel: 'МОТ',
        url: 'https://storage.googleapis.com/yolomedia/mmott23.jpe',
    },
    {
        label: 'nadyadorofeeva',
        subLabel: 'nadyadorofeeva',
        url: 'https://storage.googleapis.com/yolomedia/nadyadorofeeva.jpe',
    },
    {
        label: 'nyusha_nyusha',
        subLabel: 'Nyusha',
        url: 'https://storage.googleapis.com/yolomedia/nyusha_nyusha.jpe',
    },
    {
        label: 'gan_13_',
        subLabel: 'Андрей Борисов',
        url: 'https://storage.googleapis.com/yolomedia/gan_13_.jpe',
    },
    {
        label: 'evgeny_kulik',
        subLabel: 'ЕВГЕНИЙ КУЛИК 🤘',
        url: 'https://storage.googleapis.com/yolomedia/avatars/evgeny_kulik.jpg',
    },
    {
        label: 'lazarevsergey',
        subLabel: 'Sergey Lazarev',
        url: 'https://storage.googleapis.com/yolomedia/lazarevsergey.jpe',
    },
    {
        label: 'katusha_adushkina',
        subLabel: 'Катя Адушкина',
        url: 'https://storage.googleapis.com/yolomedia/katusha_adushkina.jpe',
    },
    {
        label: 'kamenskux',
        subLabel: 'NK | Nastia Kamenskykh',
        url: 'https://storage.googleapis.com/yolomedia/avatars/kamenskux.jpg',
    },
    {
        label: 'woman_vines',
        subLabel: '▪️Юмор▪️Вайны ▪️',
        url: 'https://storage.googleapis.com/yolomedia/woman_vines.jpe',
    },
    {
        label: 'nastyaivleeva',
        subLabel: 'НАСТЯ ИВЛЕЕВА',
        url: 'https://storage.googleapis.com/yolomedia/nastyaivleeva.jpe',
    },
    {
        label: 'apasnayaryka',
        subLabel: 'Апасный',
        url: 'https://storage.googleapis.com/yolomedia/apasnayaryka.jpe',
    },
    {
        label: 'rudovanata',
        subLabel: 'Natalia Rudova',
        url: 'https://storage.googleapis.com/yolomedia/rudovanata.jpe',
    },
    {
        label: 'helen_yes1',
        subLabel: 'Елена Сажина',
        url: 'https://storage.googleapis.com/yolomedia/helen_yes1.jpe',
    },
    {
        label: 'natali_iashchuk',
        subLabel: 'Натали Ящук',
        url: 'https://storage.googleapis.com/yolomedia/natali_iashchuk.jpe',
    },
    {
        label: 'asmuskristina',
        subLabel: 'Asmus Kristina',
        url: 'https://storage.googleapis.com/yolomedia/asmuskristina.jpe',
    },
    {
        label: 'nadin_serovski',
        subLabel: '! НАДИН СЕРОВСКИ',
        url: 'https://storage.googleapis.com/yolomedia/nadin_serovski.jpe',
    },
    {
        label: 'yan_gordienko',
        subLabel: 'YanGo',
        url: 'https://storage.googleapis.com/yolomedia/yan_gordienko.jpe',
    },
    {
        label: 'seryabkina',
        subLabel: 'MOLLY | Ольга Серябкина',
        url: 'https://storage.googleapis.com/yolomedia/seryabkina.jpe',
    },
    {
        label: 'tatarkafm',
        subLabel: 'Татарка ФМ',
        url: 'https://storage.googleapis.com/yolomedia/tatarkafm.jpe',
    },
    {
        label: 'nika_viper',
        subLabel: 'НИКА ВАЙПЕР ЛУЧШИЙ БЛОГЕР2019*',
        url: 'https://storage.googleapis.com/yolomedia/nika_viper.jpe',
    },
    {
        label: 'brianmaps',
        subLabel: 'Брайн Мапс (Макс Тарасенко)',
        url: 'https://storage.googleapis.com/yolomedia/brianmaps.jpe',
    },
    {
        label: 'bastaakanoggano',
        subLabel: 'Василий Вакуленко',
        url: 'https://storage.googleapis.com/yolomedia/bastaakanoggano.jpe',
    },
    {
        label: 'ya.molli',
        subLabel: 'МОЛЛИ девочка с каре',
        url: 'https://storage.googleapis.com/yolomedia/ya.molli.jpe',
    },
    {
        label: 'lubyatinka',
        subLabel: 'Любятинка Любовь Сидоркина',
        url: 'https://storage.googleapis.com/yolomedia/lubyatinka.jpe',
    },
    {
        label: 'sayonaraboy',
        subLabel: 'Элджей',
        url: 'https://storage.googleapis.com/yolomedia/sayonaraboy.jpe',
    },
    {
        label: 'ershovich',
        subLabel: 'Евгений Ершов',
        url: 'https://storage.googleapis.com/yolomedia/ershovich.jpe',
    },
    {
        label: 'volkonskaya.reshetova',
        subLabel: 'Reshetova Anastasia',
        url: 'https://storage.googleapis.com/yolomedia/volkonskaya.reshetova.jpe',
    },
    {
        label: 'morgen_shtern',
        subLabel: 'ЛЕГЕНДАРНАЯ ПЫЛЬ',
        url: 'https://storage.googleapis.com/yolomedia/morgen_shtern.jpe',
    },
    {
        label: 'yurydud',
        subLabel: 'Юрий Дудь',
        url: 'https://storage.googleapis.com/yolomedia/yurydud.jpe',
    },
    {
        label: 'offi_hanna',
        subLabel: 'Ханна❤️',
        url: 'https://storage.googleapis.com/yolomedia/offi_hanna.jpe',
    },
    {
        label: 'aizalovesam',
        subLabel: 'A I Z A  I S  T H E  M O O N',
        url: 'https://storage.googleapis.com/yolomedia/aizalovesam.jpe',
    },
    {
        label: 'klyukina_d',
        subLabel: 'Дарья Клюкина',
        url: 'https://storage.googleapis.com/yolomedia/klyukina_d.jpe',
    },
    {
        label: '_________verona_________',
        subLabel: 'Берникова Вероника',
        url: 'https://storage.googleapis.com/yolomedia/_________verona_________.jpe',
    },
    {
        label: 'klavacoca',
        subLabel: 'КЛАВА КОКА | BLACK STAR',
        url: 'https://storage.googleapis.com/yolomedia/klavacoca.jpe',
    },
    {
        label: 'alana_venum',
        subLabel: 'Алена Венум',
        url: 'https://storage.googleapis.com/yolomedia/alana_venum.jpe',
    },
    {
        label: 'maryme.23',
        subLabel: 'Mari Melnikova',
        url: 'https://storage.googleapis.com/yolomedia/maryme.23.jpe',
    },
    {
        label: 'marysenn',
        subLabel: 'Мари Сенн 🕊 Mary Senn',
        url: 'https://storage.googleapis.com/yolomedia/marysenn.jpe',
    },
    {
        label: 'senoritasaeva',
        subLabel: 'Dina Saeva',
        url: 'https://storage.googleapis.com/yolomedia/senoritasaeva.jpe',
    },
    {
        label: 'maxkorzhmus',
        subLabel: 'Макс Корж',
        url: 'https://storage.googleapis.com/yolomedia/maxkorzhmus.jpe',
    },
    {
        label: 'anokhina_elizabeth_2007',
        subLabel: 'Лиза Анохина',
        url: 'https://storage.googleapis.com/yolomedia/anokhina_elizabeth_2007.jpe',
    },
    {
        label: 'yarushinstanislav',
        subLabel: 'ЯРУШИН СТАНИСЛАВ',
        url: 'https://storage.googleapis.com/yolomedia/yarushinstanislav.jpe',
    },
    {
        label: 'kagramana',
        subLabel: 'Роман Каграманов',
        url: 'https://storage.googleapis.com/yolomedia/kagramana.jpe',
    },
    {
        label: 'danvirlan',
        subLabel: 'N E M A J O R',
        url: 'https://storage.googleapis.com/yolomedia/danvirlan.jpe',
    },
    {
        label: 'actorsashapetrov',
        subLabel: 'Alexander Petrov',
        url: 'https://storage.googleapis.com/yolomedia/actorsashapetrov.jpe',
    },
    {
        label: 'dasha__kozlovskaya',
        subLabel: 'ДАША КОЗЛОВСКАЯ',
        url: 'https://storage.googleapis.com/yolomedia/dasha__kozlovskaya.jpe',
    },
    {
        label: 'love_is_top',
        subLabel: 'Алла И Дима ❤️Муж И Жена',
        url: 'https://storage.googleapis.com/yolomedia/love_is_top.jpe',
    },
    {
        label: 'pusshman',
        subLabel: 'ᴊᴜʟɪᴀ ᴘᴜsʜᴍᴀɴ',
        url: 'https://storage.googleapis.com/yolomedia/pusshman.jpe',
    },
    {
        label: 'krasnovanatasha',
        subLabel: 'КРАСНОВА',
        url: 'https://storage.googleapis.com/yolomedia/krasnovanatasha.jpe',
    },
    {
        label: 'norimyxxxo',
        subLabel: 'Oxxxymiron',
        url: 'https://storage.googleapis.com/yolomedia/norimyxxxo.jpe',
    },
    {
        label: 'dlgreez',
        subLabel: 'Джарахов',
        url: 'https://storage.googleapis.com/yolomedia/dlgreez.jpe',
    },
    {
        label: 'renat_agzamov',
        subLabel: 'RENAT AGZAMOV',
        url: 'https://storage.googleapis.com/yolomedia/renat_agzamov.jpe',
    },
    {
        label: 'kuznecovsky',
        subLabel: 'YURY KUZNETSOV',
        url: 'https://storage.googleapis.com/yolomedia/kuznecovsky.jpe',
    },
    {
        label: 'devchata_vine',
        subLabel: '🍥САМЫЙ ЖЕНСКИЙ🍥',
        url: 'https://storage.googleapis.com/yolomedia/avatars/devchata_vine.jpg',
    },
    {
        label: 'andrewglazunov',
        subLabel: 'Andrew Glazunov',
        url: 'https://storage.googleapis.com/yolomedia/andrewglazunov.jpe',
    },
    {
        label: 'sashachistova',
        subLabel: '𝚜𝚊𝚜𝚑𝚊 𝚌𝚑𝚒𝚜𝚝𝚘𝚟𝚊.',
        url: 'https://storage.googleapis.com/yolomedia/sashachistova.jpe',
    },
    {
        label: 'migelshow',
        subLabel: 'МИГЕЛЬ',
        url: 'https://storage.googleapis.com/yolomedia/migelshow.jpe',
    },
    {
        label: 'galabob',
        subLabel: 'Galina Bob',
        url: 'https://storage.googleapis.com/yolomedia/galabob.jpe',
    },
    {
        label: 'ritadakota',
        subLabel: 'РИТА DАКОТА',
        url: 'https://storage.googleapis.com/yolomedia/ritadakota.jpe',
    },
    {
        label: 'madam_kaka',
        subLabel: 'Полина Трубенкова',
        url: 'https://storage.googleapis.com/yolomedia/madam_kaka.jpe',
    },
    {
        label: 'tomaschgood',
        subLabel: 'Томаш Кудрявый',
        url: 'https://storage.googleapis.com/yolomedia/tomaschgood.jpe',
    },
    {
        label: 'kristina_si',
        subLabel: '𝔖𝔦 🌙🛸',
        url: 'https://storage.googleapis.com/yolomedia/kristina_si.jpe',
    },
    {
        label: 'khomenko.tv',
        subLabel: 'XTV',
        url: 'https://storage.googleapis.com/yolomedia/khomenko.tv.jpe',
    },
    {
        label: 'facepublicenemy',
        subLabel: 'FACE',
        url: 'https://storage.googleapis.com/yolomedia/facepublicenemy.jpe',
    },
    {
        label: 'biedniakov',
        subLabel: 'Андрей Бедняков',
        url: 'https://storage.googleapis.com/yolomedia/biedniakov.jpe',
    },
    {
        label: 'shteps_s',
        subLabel: 'Серега Штепс',
        url: 'https://storage.googleapis.com/yolomedia/shteps_s.jpe',
    },
    {
        label: 'arinadanilova_official',
        subLabel: 'АРИНА ДАНИЛОВА',
        url: 'https://storage.googleapis.com/yolomedia/arinadanilova_official.jpe',
    },
    {
        label: 'salmanov_denis',
        subLabel: 'Денис Сальманов',
        url: 'https://storage.googleapis.com/yolomedia/salmanov_denis.jpe',
    },
    {
        label: 'evdklar',
        subLabel: 'Слишком искренняя Евдокия',
        url: 'https://storage.googleapis.com/yolomedia/evdklar.jpe',
    },
    {
        label: 'diva_olivka',
        subLabel: 'Алевтина ✨',
        url: 'https://storage.googleapis.com/yolomedia/diva_olivka.jpe',
    },
    {
        label: 'sobolevv',
        subLabel: 'Nikolay Sobolev',
        url: 'https://storage.googleapis.com/yolomedia/avatars/sobolevv.jpg',
    },
    {
        label: 'dima_maslen',
        subLabel: 'Дима Масленников',
        url: 'https://storage.googleapis.com/yolomedia/dima_maslen.jpe',
    },
    {
        label: 'miller.ev',
        subLabel: 'Ева Миллер 🦄 Eva Miller',
        url: 'https://storage.googleapis.com/yolomedia/miller.ev.jpe',
    },
    {
        label: 'iliyaprusikin',
        subLabel: 'Ilia Prusikin / Илья Прусикин',
        url: 'https://storage.googleapis.com/yolomedia/iliyaprusikin.jpe',
    },
    {
        label: 'kkkkkkkkkkkkkkkkkkkkkkk___kkkk',
        subLabel: 'KATERINA',
        url: 'https://storage.googleapis.com/yolomedia/kkkkkkkkkkkkkkkkkkkkkkk___kkkk.jpe',
    },
    {
        label: 'singer.ru',
        subLabel: 'Сингер',
        url: 'https://storage.googleapis.com/yolomedia/singer.ru.jpe',
    },
    {
        label: 'itsashacat',
        subLabel: 'саша кэт',
        url: 'https://storage.googleapis.com/yolomedia/itsashacat.jpe',
    },
    {
        label: 'karinakasparyants',
        subLabel: 'ᴋᴀʀɪɴᴀ ᴋᴀsᴘᴀʀʏᴀɴᴛs',
        url: 'https://storage.googleapis.com/yolomedia/karinakasparyants.jpe',
    },
    {
        label: 'real_scrip',
        subLabel: 'Скриптонит',
        url: 'https://storage.googleapis.com/yolomedia/real_scrip.jpe',
    },
    {
        label: 'agataagata',
        subLabel: 'Agata M',
        url: 'https://storage.googleapis.com/yolomedia/agataagata.jpe',
    },
    {
        label: 'astiselfmade',
        subLabel: 'ASTI',
        url: 'https://storage.googleapis.com/yolomedia/astiselfmade.jpe',
    },
    {
        label: 'belovme101',
        subLabel: 'Илья Белов',
        url: 'https://storage.googleapis.com/yolomedia/belovme101.jpe',
    },
    {
        label: 'bellapotemkinaofficial',
        subLabel: '𝐁𝐞𝐥𝐥𝐚 𝐏𝐨𝐭𝐞𝐦𝐤𝐢𝐧𝐚',
        url: 'https://storage.googleapis.com/yolomedia/bellapotemkinaofficial.jpe',
    },
    {
        label: 'gudokgudok',
        subLabel: '📯',
        url: 'https://storage.googleapis.com/yolomedia/gudokgudok.jpe',
    },
    {
        label: 'aneistazzzi',
        subLabel: '🕸Анастасия Иванцова🕸',
        url: 'https://storage.googleapis.com/yolomedia/aneistazzzi.jpe',
    },
    {
        label: 'allazemaalla',
        subLabel: 'Алла Земскова @love_is_top',
        url: 'https://storage.googleapis.com/yolomedia/avatars/allazemaalla.jpg',
    },
    {
        label: 'nastiagoncul',
        subLabel: 'Настя Гонцул',
        url: 'https://storage.googleapis.com/yolomedia/avatars/nastiagoncul.jpg',
    },
    {
        label: 'minogarova',
        subLabel: 'Я Minogarova🔥🐀🔥',
        url: 'https://storage.googleapis.com/yolomedia/minogarova.jpe',
    },
    {
        label: 'matrang_mg',
        subLabel: 'Алан Хадзарагов',
        url: 'https://storage.googleapis.com/yolomedia/matrang_mg.jpe',
    },
    {
        label: 'lusia_chebotina',
        subLabel: 'ЛЮСЯ ЧЕБОТИНА',
        url: 'https://storage.googleapis.com/yolomedia/lusia_chebotina.jpe',
    },
    {
        label: 'scroodgee_official',
        subLabel: 'СКРУДЖИ',
        url: 'https://storage.googleapis.com/yolomedia/scroodgee_official.jpe',
    },
    {
        label: '_iza_bell_',
        subLabel: '',
        url: 'https://storage.googleapis.com/yolomedia/_iza_bell_.jpe',
    },
    {
        label: 'novaya_luna',
        subLabel: 'Алена Токарева💥',
        url: 'https://storage.googleapis.com/yolomedia/novaya_luna.jpe',
    },
    {
        label: 'timabelorusskih',
        subLabel: '• Тима Белорусских •',
        url: 'https://storage.googleapis.com/yolomedia/timabelorusskih.jpe',
    },
    {
        label: 'orelireshka_official',
        subLabel: 'Орёл и Решка',
        url: 'https://storage.googleapis.com/yolomedia/orelireshka_official.jpe',
    },
    {
        label: 'lizashatilova_',
        subLabel: 'Elizaveta Shatilova',
        url: 'https://storage.googleapis.com/yolomedia/avatars/lizashatilova_.jpg',
    },
    {
        label: 'kravacomedy',
        subLabel: 'MARIA KRAVCHENKO',
        url: 'https://storage.googleapis.com/yolomedia/avatars/kravacomedy.jpg',
    },
    {
        label: 'karrrambaby',
        subLabel: 'KARRAMBABY КАРИНА KaRaMbaby',
        url: 'https://storage.googleapis.com/yolomedia/karrrambaby.jpe',
    },
    {
        label: 'natalie_dayy',
        subLabel: 'Мама-Блогер🤱🏻',
        url: 'https://storage.googleapis.com/yolomedia/avatars/natalie_dayy.jpg',
    },
    {
        label: 'russiansingers',
        subLabel: 'Русская Музыка',
        url: 'https://storage.googleapis.com/yolomedia/avatars/russiansingers.jpg',
    },
    {
        label: 'dasharhipova',
        subLabel: 'БЬЮТИ-БАЛАГУР ДАША АРХИПОВА',
        url: 'https://storage.googleapis.com/yolomedia/dasharhipova.jpe',
    },
    {
        label: 'misha_marvin',
        subLabel: 'Миша Марвин | Black Star',
        url: 'https://storage.googleapis.com/yolomedia/misha_marvin.jpe',
    },
    {
        label: 'biankarnb',
        subLabel: 'Bianka ✌️',
        url: 'https://storage.googleapis.com/yolomedia/biankarnb.jpe',
    },
    {
        label: 'juliagodunova',
        subLabel: 'Юля Годунова',
        url: 'https://storage.googleapis.com/yolomedia/juliagodunova.jpe',
    },
    {
        label: 'akuna_m_a_t_a_t_a',
        subLabel: 'Чисто #оля',
        url: 'https://storage.googleapis.com/yolomedia/akuna_m_a_t_a_t_a.jpe',
    },
    {
        label: 'hammali_',
        subLabel: 'HAMMALI',
        url: 'https://storage.googleapis.com/yolomedia/hammali_.jpe',
    },
    {
        label: 'musically_cover',
        subLabel: 'Лучшие музыкальные таланты 🎤🎶🎼',
        url: 'https://storage.googleapis.com/yolomedia/musically_cover.jpe',
    },
    {
        label: 'gus__original',
        subLabel: 'Гусь',
        url: 'https://storage.googleapis.com/yolomedia/gus__original.jpe',
    },
    {
        label: 'muz.cover',
        subLabel: 'МУЗЫКАЛЬНЫЕ ТАЛАНТЫ🎤🎼',
        url: 'https://storage.googleapis.com/yolomedia/muz.cover.jpe',
    },
    {
        label: 'benzogang_tape',
        subLabel: 'Big Baby Tape',
        url: 'https://storage.googleapis.com/yolomedia/benzogang_tape.jpe',
    },
    {
        label: 'shaynidis_health',
        subLabel: 'Анесто Шайнидис',
        url: 'https://storage.googleapis.com/yolomedia/shaynidis_health.jpe',
    },
    {
        label: '_zivert',
        subLabel: 'ZIVERT🎙 life в кайф 👽',
        url: 'https://storage.googleapis.com/yolomedia/_zivert.jpe',
    },
    {
        label: 'vberberyy',
        subLabel: 'Влад Беренич 🧸',
        url: 'https://storage.googleapis.com/yolomedia/avatars/vberberyy.jpg',
    },
    {
        label: 'korotkova_viktoriya',
        subLabel: 'Viktoriya Korotkova',
        url: 'https://storage.googleapis.com/yolomedia/avatars/korotkova_viktoriya.jpg',
    },
    {
        label: 'semamolokanov',
        subLabel: 'Красавица и Чудовище🎬😂',
        url: 'https://storage.googleapis.com/yolomedia/semamolokanov.jpe',
    },
    {
        label: 'muzika_tamara',
        subLabel: 'ТУРАВА ТАМАРА ✵ Музыка ✵',
        url: 'https://storage.googleapis.com/yolomedia/muzika_tamara.jpe',
    },
    {
        label: 't_fest',
        subLabel: '',
        url: 'https://storage.googleapis.com/yolomedia/t_fest.jpe',
    },
    {
        label: 'haha_ski',
        subLabel: 'Хахаски',
        url: 'https://storage.googleapis.com/yolomedia/haha_ski.jpe',
    },
    {
        label: 'houusse',
        subLabel: 'Сергей Халус',
        url: 'https://storage.googleapis.com/yolomedia/avatars/houusse.jpg',
    },
    {
        label: 'anton.shastoon',
        subLabel: 'Антон Шастун',
        url: 'https://storage.googleapis.com/yolomedia/anton.shastoon.jpe',
    },
    {
        label: 'celebrity_ins',
        subLabel: 'СЕЛЕБРИТИ',
        url: 'https://storage.googleapis.com/yolomedia/celebrity_ins.jpe',
    },
    {
        label: 'pp_foodpro',
        subLabel: 'Рецепты Простых ВКУСНЯШЕК 🤗',
        url: 'https://storage.googleapis.com/yolomedia/pp_foodpro.jpe',
    },
    {
        label: 'talanter',
        subLabel: 'ШОУ | ТАЛАНТЫ',
        url: 'https://storage.googleapis.com/yolomedia/talanter.jpe',
    },
    {
        label: 'lyubarskaya',
        subLabel: 'Любарская',
        url: 'https://storage.googleapis.com/yolomedia/lyubarskaya.jpe',
    },
    {
        label: 'mariakakdela',
        subLabel: 'Maria Tarasova [Belova]',
        url: 'https://storage.googleapis.com/yolomedia/mariakakdela.jpe',
    },
    {
        label: 'alex_svik',
        subLabel: 'Леша Свик',
        url: 'https://storage.googleapis.com/yolomedia/alex_svik.jpe',
    },
    {
        label: 'khomenki',
        subLabel: 'ХОМЕНКИ',
        url: 'https://storage.googleapis.com/yolomedia/khomenki.jpe',
    },
    {
        label: 'alexandraposnova',
        subLabel: 'Александра Поснова',
        url: 'https://storage.googleapis.com/yolomedia/alexandraposnova.jpe',
    },
    {
        label: 'inadenka',
        subLabel: 'Надя Сысоева',
        url: 'https://storage.googleapis.com/yolomedia/inadenka.jpe',
    },
    {
        label: 'gan_13_life',
        subLabel: 'Эндрю Borisof 🇧🇾',
        url: 'https://storage.googleapis.com/yolomedia/avatars/gan_13_life.jpg',
    },
    {
        label: '_mariapark_',
        subLabel: 'M A S H A П A K | ФЭШН ДИВА',
        url: 'https://storage.googleapis.com/yolomedia/_mariapark_.jpe',
    },
    {
        label: 'senoritagalo',
        subLabel: 'Галина Ржаксенская',
        url: 'https://storage.googleapis.com/yolomedia/senoritagalo.jpe',
    },
    {
        label: 'fridaytv',
        subLabel: 'Телеканал ПЯТНИЦА!',
        url: 'https://storage.googleapis.com/yolomedia/avatars/fridaytv.jpg',
    },
    {
        label: 'lyna_com2000',
        subLabel: 'ПРОСТО БУДЬ ДОБРЕЕ',
        url: 'https://storage.googleapis.com/yolomedia/lyna_com2000.jpe',
    },
    {
        label: 'im_lyubovaksenova',
        subLabel: 'Любовь Аксёнова',
        url: 'https://storage.googleapis.com/yolomedia/avatars/im_lyubovaksenova.jpg',
    },
    {
        label: 'sobolev_tut',
        subLabel: 'Ilya Sobolev',
        url: 'https://storage.googleapis.com/yolomedia/sobolev_tut.jpe',
    },
    {
        label: 'lyolya_baranova01',
        subLabel: 'Lyolya_Baranova',
        url: 'https://storage.googleapis.com/yolomedia/avatars/lyolya_baranova01.jpg',
    },
    {
        label: 'elvira_t_music',
        subLabel: 'Elvira T',
        url: 'https://storage.googleapis.com/yolomedia/elvira_t_music.jpe',
    },
    {
        label: 'nikaviper_life',
        subLabel: 'Ника Вайпер',
        url: 'https://storage.googleapis.com/yolomedia/nikaviper_life.jpe',
    },
    {
        label: 'tamiklife',
        subLabel: 'Тамирлан Кулиев',
        url: 'https://storage.googleapis.com/yolomedia/tamiklife.jpe',
    },
    {
        label: '_poposha_',
        subLabel: 'Polina🍍ʰˡ',
        url: 'https://storage.googleapis.com/yolomedia/_poposha_.jpe',
    },
    {
        label: 'siuzannavarnina',
        subLabel: 'Siuzanna Varnina',
        url: 'https://storage.googleapis.com/yolomedia/siuzannavarnina.jpe',
    },
    {
        label: 'ida_galich_life',
        subLabel: 'Галич Ида',
        url: 'https://storage.googleapis.com/yolomedia/ida_galich_life.jpe',
    },
    {
        label: 'sofabro_life',
        subLabel: 'волшебная деичка Софи👑',
        url: 'https://storage.googleapis.com/yolomedia/sofabro_life.jpe',
    },
    {
        label: 'be.bloome',
        subLabel: 'Bloome',
        url: 'https://storage.googleapis.com/yolomedia/be.bloome.jpe',
    },
    {
        label: 'chusalina',
        subLabel: 'Алина Чусь',
        url: 'https://storage.googleapis.com/yolomedia/chusalina.jpe',
    },
    {
        label: 'khodzhulya',
        subLabel: 'Екатерина Ходжаева -Ходжуля',
        url: 'https://storage.googleapis.com/yolomedia/khodzhulya.jpe',
    },
    {
        label: 'ira_blanc',
        subLabel: 'ИРА БЛАН',
        url: 'https://storage.googleapis.com/yolomedia/ira_blanc.jpe',
    },
    {
        label: 'mamaotlichnika',
        subLabel: 'Мама Отличника',
        url: 'https://storage.googleapis.com/yolomedia/mamaotlichnika.jpe',
    },
    {
        label: 'pipsses.vi',
        subLabel: 'КСЮША ЗВЕРЕВА',
        url: 'https://storage.googleapis.com/yolomedia/pipsses.vi.jpe',
    },
    {
        label: 'morozovq',
        subLabel: 'Перфе / Никита Морозов',
        url: 'https://storage.googleapis.com/yolomedia/morozovq.jpe',
    },
    {
        label: 'danasokolova_official',
        subLabel: 'Дана Соколова | Black Star',
        url: 'https://storage.googleapis.com/yolomedia/avatars/danasokolova_official.jpg',
    },
    {
        label: 'assol13',
        subLabel: 'Ассоль',
        url: 'https://storage.googleapis.com/yolomedia/assol13.jpe',
    },
    {
        label: 'zodi_goroskop',
        subLabel: 'Твой Любимый Гороскоп 💛',
        url: 'https://storage.googleapis.com/yolomedia/avatars/zodi_goroskop.jpg',
    },
    {
        label: 'st_stoizsta',
        subLabel: 'Alexander ST Stepanov',
        url: 'https://storage.googleapis.com/yolomedia/st_stoizsta.jpe',
    },
    {
        label: 'mahavsekruto',
        subLabel: '🐰Million Dollar Baby🐰',
        url: 'https://storage.googleapis.com/yolomedia/mahavsekruto.jpe',
    },
    {
        label: 'lspolegi',
        subLabel: 'olegi',
        url: 'https://storage.googleapis.com/yolomedia/lspolegi.jpe',
    },
    {
        label: 'alexandrovannya',
        subLabel: 'Ханна Кривуля',
        url: 'https://storage.googleapis.com/yolomedia/avatars/alexandrovannya.jpg',
    },
    {
        label: 'spletnik.youtuba',
        subLabel: 'Сплетни Ютуба',
        url: 'https://storage.googleapis.com/yolomedia/spletnik.youtuba.jpe',
    },
    {
        label: 'katya_iowa',
        subLabel: 'Екатерина Иванчикова - IOWA',
        url: 'https://storage.googleapis.com/yolomedia/katya_iowa.jpe',
    },
    {
        label: 'natalykoreneva',
        subLabel: 'НАТАША КОРЕНЕВА🥑',
        url: 'https://storage.googleapis.com/yolomedia/natalykoreneva.jpe',
    },
    {
        label: 'clan6',
        subLabel: 'КЛАН 6 🖤 CLAN VI',
        url: 'https://storage.googleapis.com/yolomedia/clan6.jpe',
    },
    {
        label: 'flo_rida',
        subLabel: 'АЛАН БАСИЕВ',
        url: 'https://storage.googleapis.com/yolomedia/flo_rida.jpe',
    },
    {
        label: 'fisunka',
        subLabel: 'inna fisun',
        url: 'https://storage.googleapis.com/yolomedia/avatars/fisunka.jpg',
    },
    {
        label: 'favlav',
        subLabel: 'Polina Favorskaya',
        url: 'https://storage.googleapis.com/yolomedia/favlav.jpe',
    },
    {
        label: '_nurlansaburov',
        subLabel: 'Нурлан Сабуров Stand UP 🎙',
        url: 'https://storage.googleapis.com/yolomedia/avatars/_nurlansaburov.jpg',
    },
    {
        label: 'rinagrishina',
        subLabel: 'Rina Grishina',
        url: 'https://storage.googleapis.com/yolomedia/avatars/rinagrishina.jpg',
    },
    {
        label: 'ulyanabanana',
        subLabel: 'ULYANA BANANA',
        url: 'https://storage.googleapis.com/yolomedia/avatars/ulyanabanana.jpg',
    },
    {
        label: 'koval_now',
        subLabel: 'ЮЛИЯ КОВАЛЬ',
        url: 'https://storage.googleapis.com/yolomedia/koval_now.jpe',
    },
    {
        label: 'dava_fan_page',
        subLabel: 'Фан аккаунт Давида Манукян🔥',
        url: 'https://storage.googleapis.com/yolomedia/dava_fan_page.jpe',
    },
    {
        label: 'alina_astrovskaya_',
        subLabel: 'ASTROVSKAYA',
        url: 'https://storage.googleapis.com/yolomedia/alina_astrovskaya_.jpe',
    },
    {
        label: 'womhype',
        subLabel: '📽 Женский Мир',
        url: 'https://storage.googleapis.com/yolomedia/avatars/womhype.jpg',
    },
    {
        label: 'slava_slame',
        subLabel: 'Slame | BLACK STAR',
        url: 'https://storage.googleapis.com/yolomedia/slava_slame.jpe',
    },
    {
        label: 'music_box_tv',
        subLabel: 'Музыка',
        url: 'https://storage.googleapis.com/yolomedia/music_box_tv.jpe',
    },
    {
        label: 'likakashirina',
        subLabel: 'ANGELIKA KASHIRINA',
        url: 'https://storage.googleapis.com/yolomedia/likakashirina.jpe',
    },
    {
        label: 'melnikovadsh',
        subLabel: 'Дарья Мельникова',
        url: 'https://storage.googleapis.com/yolomedia/melnikovadsh.jpe',
    },
    {
        label: 'iamplc',
        subLabel: 'PLC',
        url: 'https://storage.googleapis.com/yolomedia/iamplc.jpe',
    },
    {
        label: 'annaboronina',
        subLabel: '🎶 БОРОНИНА',
        url: 'https://storage.googleapis.com/yolomedia/avatars/annaboronina.jpg',
    },
    {
        label: 'mish.mari',
        subLabel: 'ГУРУ КРЕАТИВА МАША МИШМАРИ',
        url: 'https://storage.googleapis.com/yolomedia/mish.mari.jpe',
    },
    {
        label: 'radistkaaa_ket',
        subLabel: 'C h r i s 🍯',
        url: 'https://storage.googleapis.com/yolomedia/avatars/radistkaaa_ket.jpg',
    },
    {
        label: 'grannypocket',
        subLabel: 'Лера Яскевич',
        url: 'https://storage.googleapis.com/yolomedia/grannypocket.jpe',
    },
    {
        label: 'rigina_gaisina',
        subLabel: '⠀⠀⠀⠀⠀⠀⠀⠀⠀Ригина Гайсина',
        url: 'https://storage.googleapis.com/yolomedia/rigina_gaisina.jpe',
    },
    {
        label: 'vnemoi',
        subLabel: 'лёша внемой',
        url: 'https://storage.googleapis.com/yolomedia/vnemoi.jpe',
    },
    {
        label: 'sepwun',
        subLabel: 'SPWN🤙🏻',
        url: 'https://storage.googleapis.com/yolomedia/sepwun.jpe',
    },
    {
        label: 'evg.verbee',
        subLabel: 'VERBEE | Евгений Вербицкий',
        url: 'https://storage.googleapis.com/yolomedia/evg.verbee.jpe',
    },
    {
        label: 'pravda_rub_',
        subLabel: 'Pravda_RUB',
        url: 'https://storage.googleapis.com/yolomedia/pravda_rub_.jpe',
    },
    {
        label: 'anfisavist',
        subLabel: 'Анфиса Вистингаузен 🎥',
        url: 'https://storage.googleapis.com/yolomedia/anfisavist.jpe',
    },
    {
        label: 'iiimaks',
        subLabel: 'Максим Свобода',
        url: 'https://storage.googleapis.com/yolomedia/iiimaks.jpe',
    },
    {
        label: 'parapatronov',
        subLabel: 'Сын Олигарха',
        url: 'https://storage.googleapis.com/yolomedia/avatars/parapatronov.jpg',
    },
    {
        label: 'babayaga.official',
        subLabel: 'BY-BABA YAGA БАБА ЯГА',
        url: 'https://storage.googleapis.com/yolomedia/babayaga.official.jpe',
    },
    {
        label: 'govor.goroda',
        subLabel: 'делаем, что нравится',
        url: 'https://storage.googleapis.com/yolomedia/govor.goroda.jpe',
    },
    {
        label: 'tanyatekis',
        subLabel: 'Tanya Morgunova🌴',
        url: 'https://storage.googleapis.com/yolomedia/avatars/tanyatekis.jpg',
    },
    {
        label: 'noviiiknova',
        subLabel: 'КРИСТИНА НОВИКОВА',
        url: 'https://storage.googleapis.com/yolomedia/noviiiknova.jpe',
    },
    {
        label: 'kagramana_',
        subLabel: 'Реклама 170р🔥',
        url: 'https://storage.googleapis.com/yolomedia/kagramana_.jpe',
    },
    {
        label: 'sharl.ot',
        subLabel: 'ШАРЛОТ',
        url: 'https://storage.googleapis.com/yolomedia/sharl.ot.jpe',
    },
    {
        label: 'marielobanova',
        subLabel: '➰Marie Tsyganova (Lobanova)➰',
        url: 'https://storage.googleapis.com/yolomedia/marielobanova.jpe',
    },
    {
        label: 'agentgirl_fan_',
        subLabel: '',
        url: 'https://storage.googleapis.com/yolomedia/agentgirl_fan_.jpe',
    },
    {
        label: 'nikmakser',
        subLabel: 'max nikolaev 🥽',
        url: 'https://storage.googleapis.com/yolomedia/nikmakser.jpe',
    },
    {
        label: 'lesha_lukin',
        subLabel: 'Алексей Лукин',
        url: 'https://storage.googleapis.com/yolomedia/avatars/lesha_lukin.jpg',
    },
    {
        label: 'innfly_',
        subLabel: 'Дарьюшка',
        url: 'https://storage.googleapis.com/yolomedia/avatars/innfly_.jpg',
    },
    {
        label: 'maxkorzhfan',
        subLabel: 'Max Korzh Team ↗️',
        url: 'https://storage.googleapis.com/yolomedia/maxkorzhfan.jpe',
    },
    {
        label: 'janedam_',
        subLabel: 'ДАШка #янедам',
        url: 'https://storage.googleapis.com/yolomedia/janedam_.jpe',
    },
    {
        label: 'di_gg',
        subLabel: 'Диана',
        url: 'https://storage.googleapis.com/yolomedia/di_gg.jpe',
    },
    {
        label: 'igorlantratov',
        subLabel: '𝕀𝔾𝕆ℝ 𝕃𝔸ℕ𝕋ℝ𝔸𝕋𝕆𝕍',
        url: 'https://storage.googleapis.com/yolomedia/igorlantratov.jpe',
    },
    {
        label: 'karinakross.live',
        subLabel: 'Фан страница Карины Кросс🔥',
        url: 'https://storage.googleapis.com/yolomedia/karinakross.live.jpe',
    },
    {
        label: 'panda_lena',
        subLabel: 'Lenok Panda',
        url: 'https://storage.googleapis.com/yolomedia/panda_lena.jpe',
    },
    {
        label: 'ekaterina_sedik',
        subLabel: 'Екатерина Седик',
        url: 'https://storage.googleapis.com/yolomedia/ekaterina_sedik.jpe',
    },
    {
        label: '__.agentgirl.__',
        subLabel: '💎 Настя Ивлеева 💎',
        url: 'https://storage.googleapis.com/yolomedia/__.agentgirl.__.jpe',
    },
    {
        label: 'vladagarmash',
        subLabel: 'СОЗДАЮ МАСКИ ДЛЯ STORIES',
        url: 'https://storage.googleapis.com/yolomedia/avatars/vladagarmash.jpg',
    },
    {
        label: 'pravda.show.news',
        subLabel: 'VPSH',
        url: 'https://storage.googleapis.com/yolomedia/pravda.show.news.jpe',
    },
    {
        label: '_agentgirl_news',
        subLabel: 'НАСТЯ ИВЛЕЕВА ОФИЦИАЛЬНЫЙ ФК',
        url: 'https://storage.googleapis.com/yolomedia/avatars/_agentgirl_news.jpg',
    },
    {
        label: 'anetsai',
        subLabel: 'Анет Сай',
        url: 'https://storage.googleapis.com/yolomedia/anetsai.jpe',
    },
    {
        label: 'youtube_ru.s',
        subLabel: 'YOUTUBE | БЛОГЕРЫ',
        url: 'https://storage.googleapis.com/yolomedia/youtube_ru.s.jpe',
    },
    {
        label: 'pacanki_show',
        subLabel: 'Реалити-шоу Пацанки 🔥',
        url: 'https://storage.googleapis.com/yolomedia/pacanki_show.jpe',
    },
    {
        label: 'khomenki_news',
        subLabel: 'ОФИЦИАЛЬНЫЙ FAN ХОМЕНОК❤',
        url: 'https://storage.googleapis.com/yolomedia/khomenki_news.jpe',
    },
    {
        label: 'super_kanal',
        subLabel: 'Супер. Новый телеканал.',
        url: 'https://storage.googleapis.com/yolomedia/super_kanal.jpe',
    },
    {
        label: 'jmar_real',
        subLabel: 'Джей Мар | ПЕСНИ НА ТНТ',
        url: 'https://storage.googleapis.com/yolomedia/jmar_real.jpe',
    },
    {
        label: 'aprolubov',
        subLabel: 'А Про ЛЮБОВЬ ❤️',
        url: 'https://storage.googleapis.com/yolomedia/aprolubov.jpe',
    },
    {
        label: 'pacankitv',
        subLabel: 'ПАЦАНКИ 4 СЕЗОН. ДИКОЕ ПЛЕМЯ',
        url: 'https://storage.googleapis.com/yolomedia/pacankitv.jpe',
    },
    {
        label: 'sayonaraboy.page',
        subLabel: 'Элджей ️',
        url: 'https://storage.googleapis.com/yolomedia/sayonaraboy.page.jpe',
    },
    {
        label: 'erikalundmoen',
        subLabel: 'Лундмоен Эрика Хансовна',
        url: 'https://storage.googleapis.com/yolomedia/erikalundmoen.jpe',
    },
    {
        label: 'pacanki_realyty',
        subLabel: 'Пацанки',
        url: 'https://storage.googleapis.com/yolomedia/pacanki_realyty.jpe',
    },
    {
        label: 'tiktok.ugarr',
        subLabel: 'Трэш видео TikToka',
        url: 'https://storage.googleapis.com/yolomedia/tiktok.ugarr.jpe',
    },
    {
        label: 'murasshik',
        subLabel: 'просто Мурашик',
        url: 'https://storage.googleapis.com/yolomedia/murasshik.jpe',
    },
    {
        label: 'kusochkifilma',
        subLabel: 'Кусочки Фильма',
        url: 'https://storage.googleapis.com/yolomedia/avatars/kusochkifilma.jpg',
    },
    {
        label: 'lana_volska',
        subLabel: 'Светлана',
        url: 'https://storage.googleapis.com/yolomedia/lana_volska.jpe',
    },
    {
        label: '_other_nastya_',
        subLabel: 'ЭТО МОЙ ПУТЬ К СЕБЕ',
        url: 'https://storage.googleapis.com/yolomedia/_other_nastya_.jpe',
    },
    {
        label: 'youtube__ru.s',
        subLabel: 'YouTube | BLOGGERS',
        url: 'https://storage.googleapis.com/yolomedia/youtube__ru.s.jpe',
    },
    {
        label: 'nastyaivleevva',
        subLabel: 'НАСТЯ ИВЛЕЕВА | AGENT GIRL',
        url: 'https://storage.googleapis.com/yolomedia/nastyaivleevva.jpe',
    },
    {
        label: 'elcostalacoste',
        subLabel: 'Коста Лакоста',
        url: 'https://storage.googleapis.com/yolomedia/avatars/elcostalacoste.jpg',
    },
    {
        label: 'nevedrova',
        subLabel: 'Натали Неведрова 🦄',
        url: 'https://storage.googleapis.com/yolomedia/nevedrova.jpe',
    },
    {
        label: 'i_am_doshik',
        subLabel: 'Darya',
        url: 'https://storage.googleapis.com/yolomedia/i_am_doshik.jpe',
    },
    {
        label: 'handmade.beauty.bar',
        subLabel: 'HANDMADE BEAUTY BAR',
        url: 'https://storage.googleapis.com/yolomedia/handmade.beauty.bar.jpe',
    },
    {
        label: 'mi_rr_os',
        subLabel: '⚡️VLADOSMIROS ⚡️',
        url: 'https://storage.googleapis.com/yolomedia/mi_rr_os.jpe',
    },
    {
        label: 'cherkasov__2',
        subLabel: 'Данил Черкасов',
        url: 'https://storage.googleapis.com/yolomedia/avatars/cherkasov__2.jpg',
    },
    {
        label: 'ninali_blog',
        subLabel: 'ОБРАБОТКА▫️БЬЮТИ▫️ПРЕСЕТЫ',
        url: 'https://storage.googleapis.com/yolomedia/avatars/ninali_blog.jpg',
    },
    {
        label: 'fuckt_fest',
        subLabel: 't_fest',
        url: 'https://storage.googleapis.com/yolomedia/avatars/fuckt_fest.jpg',
    },
    {
        label: 'nastyaivleeva_idagalich_fan',
        subLabel: '❤️НАСТЯ ИВЛЕЕВА/ИДА ГАЛИЧ❤️',
        url: 'https://storage.googleapis.com/yolomedia/avatars/nastyaivleeva_idagalich_fan.jpg',
    },
    {
        label: 'nastyaivleeva_everyday',
        subLabel: '',
        url: 'https://storage.googleapis.com/yolomedia/avatars/nastyaivleeva_everyday.jpg',
    },
    {
        label: 'galichida_official',
        subLabel: 'ИДА ГАЛИЧ || FAN',
        url: 'https://storage.googleapis.com/yolomedia/avatars/galichida_official.jpg',
    },
    {
        label: 'arhipova_team_',
        subLabel: '🔥КОМАНДА АРХИПОВОЙ🔥',
        url: 'https://storage.googleapis.com/yolomedia/avatars/arhipova_team_.jpg',
    },
    {
        label: 'agentshow2',
        subLabel: 'AGENT SHOW',
        url: 'https://storage.googleapis.com/yolomedia/avatars/agentshow2.jpg',
    },
    {
        label: '_____verona________',
        subLabel: '🗣️ Реклама - 50/100 Р.',
        url: 'https://storage.googleapis.com/yolomedia/avatars/_____verona________.jpg',
    },
    {
        label: 'pacanki_reality',
        subLabel: '⚜️ПАЦАНКИ⚜️',
        url: 'https://storage.googleapis.com/yolomedia/avatars/pacanki_reality.jpg',
    },
]

const erSpreadData = [
    {
        label: '2020-06-09T07:06:17',
        value: [
            {
                value: 2717,
                color: '#4ECF81',
            },
            {
                value: 703273,
                color: '#6071FF',
            },
        ],
        url: 'https://www.instagram.com/p/CBNJiAmg3_8',
    },
    {
        label: '2020-06-09T06:06:00',
        value: [
            {
                value: 1093,
                color: '#4ECF81',
            },
            {
                value: 649451,
                color: '#6071FF',
            },
        ],
        url: 'https://www.instagram.com/p/CBNGhi5gaZ0',
    },
    {
        label: '2020-06-08T19:06:30',
        value: [
            {
                value: 4735,
                color: '#4ECF81',
            },
            {
                value: 409622,
                color: '#6071FF',
            },
        ],
        url: 'https://www.instagram.com/p/CBL6GxCgBtg',
    },
    {
        label: '2020-06-08T16:06:26',
        value: [
            {
                value: 1439,
                color: '#4ECF81',
            },
            {
                value: 467509,
                color: '#6071FF',
            },
        ],
        url: 'https://www.instagram.com/p/CBLnYRLAtgh',
    },
    {
        label: '2020-06-02T08:06:32',
        value: [
            {
                value: 4696,
                color: '#4ECF81',
            },
            {
                value: 944102,
                color: '#6071FF',
            },
        ],
        url: 'https://www.instagram.com/p/CA7T_qdgL4K',
    },
    {
        label: '2020-05-31T19:05:09',
        value: [
            {
                value: 2812,
                color: '#4ECF81',
            },
            {
                value: 633770,
                color: '#6071FF',
            },
        ],
        url: 'https://www.instagram.com/p/CA3RHSygxEA',
    },
    {
        label: '2020-05-31T09:05:50',
        value: [
            {
                value: 6664,
                color: '#4ECF81',
            },
            {
                value: 1313656,
                color: '#6071FF',
            },
        ],
        url: 'https://www.instagram.com/p/CA2P81AAWUx',
    },
    {
        label: '2020-05-30T13:05:03',
        value: [
            {
                value: 2722,
                color: '#4ECF81',
            },
            {
                value: 735589,
                color: '#6071FF',
            },
        ],
        url: 'https://www.instagram.com/p/CA0Dq83AVSM',
    },
    {
        label: '2020-05-27T14:05:28',
        value: [
            {
                value: 1574,
                color: '#4ECF81',
            },
            {
                value: 762625,
                color: '#6071FF',
            },
        ],
        url: 'https://www.instagram.com/p/CAscM1Eg1Ya',
    },
    {
        label: '2020-05-25T17:05:14',
        value: [
            {
                value: 1406,
                color: '#4ECF81',
            },
            {
                value: 699973,
                color: '#6071FF',
            },
        ],
        url: 'https://www.instagram.com/p/CAnld4ugPjz',
    },
]

const brandAffinityData = [
    {
        label: 'Телеканал ПЯТНИЦА!',
        posts: [
            {
                er: '0.005194046354413691',
                post_date: '2021-11-24 09:11:47',
                post_url: 'https://instagram.com/p/CWpu64YDC7o/',
            },
            {
                er: '0.005194046354413691',
                post_date: '2021-11-24 09:11:47',
                post_url: 'https://instagram.com/p/CWpu64YDC7o/',
            },
            {
                er: '0.003194046354413691',
                post_date: '2021-11-23 09:11:47',
                post_url: 'https://instagram.com/p/CWpu64YDC7o/',
            },
            {
                er: '0.005194046354413691',
                post_date: '2021-11-20 09:11:47',
                post_url: 'https://instagram.com/p/CWpu64YDC7o/',
            },
            {
                er: '0.005831976575941895',
                post_date: '2021-11-21 09:11:48',
                post_url: 'https://instagram.com/p/CWiEv3xs-qh/',
            },
        ],
        subLabel: 'media',
        url: 'https://storage.googleapis.com/yolomedia/avatars/fridaytv.jpg',
        value: 'fridaytv',
    },
    {
        label: 'НТВ',
        subLabel: 'media',
        url: 'https://storage.googleapis.com/yolomedia/avatars/ntvru.jpg',
        value: 'ntvru',
        posts: [
            {
                er: '0.006823079760692958',
                post_date: '2022-01-05 09:01:06',
                post_url: 'https://instagram.com/p/CYV7wAKFeKV/',
            },
            {
                er: '0.003913563228592588',
                post_date: '2022-01-04 15:01:20',
                post_url: 'https://instagram.com/p/CYT_7PqFMMI/',
            },
        ],
    },
    {
        label: 'Kinder Russia',
        posts: [
            {
                er: '0.0026358537124303015',
                post_date: '2021-03-18 13:03:42',
                post_url: 'https://instagram.com/p/CMj8F0NDCMP/',
            },
            {
                er: '0.0026358537124303015',
                post_date: '2021-03-18 13:03:42',
                post_url: 'https://instagram.com/p/CMj8F0NDCMP/',
            },
            {
                er: '0.003847921133333888',
                post_date: '2021-03-15 13:03:52',
                post_url: 'https://instagram.com/p/CMcLb89Dx30/',
            },
            {
                er: '0.003847921133333888',
                post_date: '2021-03-15 13:03:52',
                post_url: 'https://instagram.com/p/CMcLb89Dx30/',
            },
        ],
        subLabel: 'food',
        url: 'https://storage.googleapis.com/yolomedia/avatars/kinder_rus.jpg',
        value: 'kinder_rus',
    },
    {
        label: 'Банк ВТБ',
        posts: [
            {
                er: '0.003043574332276588',
                post_date: '2021-06-26 11:06:14',
                post_url: 'https://instagram.com/p/CQlLgf_jiT2/',
            },
        ],
        subLabel: 'finance',
        url: 'https://storage.googleapis.com/yolomedia/avatars/bankvtb.jpg',
        value: 'bankvtb',
    },
    {
        label: 'HELLO! Russia',
        posts: [
            {
                er: '0.0164059011319101',
                post_date: '2021-06-20 16:06:52',
                post_url: 'https://instagram.com/p/CQWSDvMDXal/',
            },
            {
                er: '0.016109124898416545',
                post_date: '2021-05-21 15:05:37',
                post_url: 'https://instagram.com/p/CPI9t_Ojbf1/',
            },
        ],
        subLabel: 'media',
        url: 'https://storage.googleapis.com/yolomedia/avatars/hello__ru.jpg',
        value: 'hello__ru',
    },
]

const aqData = [
    {
        value: 0.6385849037622617,
        label: 'good',
        color: '#4ECF81',
        label_color: '#4ECF81',
        percent_color: 'rgba(78,207,129,0.2)',
    },
    {
        value: 0.20963785783921904,
        label: 'suspicious',
        color: '#EF3B46',
        label_color: '#EF3B46',
        percent_color: 'rgba(239,59,70,0.2)',
    },
    {
        value: 0.1364448647294131,
        label: 'mass',
        color: '#56595E',
        label_color: '#56595E',
        percent_color: 'rgba(86,89,94,0.2)',
    },
    {
        value: 0.015332373669106067,
        label: 'influencers',
        color: '#6071FF',
        label_color: '#6071FF',
        percent_color: 'rgba(96,113,255,0.2)',
    },
]

const reachData = [
    {
        value: 0.43256499225379036,
        label: '<500',
        subLabel: 'follows',
    },
    {
        value: 0.2803670624214633,
        label: '500-1k',
        subLabel: 'follows',
    },
    {
        value: 0.10089369493286314,
        label: '1k-1.5k',
        subLabel: 'follows',
    },
    {
        value: 0.1861742503918832,
        label: '>1.5k',
        subLabel: 'follows',
    },
]

const notableFollowers = [
    {
        label: 'borodylia',
        subLabel: 'Ксения Бородина',
        url: 'https://storage.googleapis.com/yolomedia/borodylia.jpe',
    },
    {
        label: 'tnt_online',
        subLabel: 'Телеканал ТНТ',
        url: 'https://storage.googleapis.com/yolomedia/tnt_online.jpe',
    },
    {
        label: 'egorkreed',
        subLabel: 'Егор Крид | #HeartBreakKid',
        url: 'https://storage.googleapis.com/yolomedia/egorkreed.jpe',
    },
    {
        label: 'm_galustyan',
        subLabel: 'Михаил Галустян',
        url: 'https://storage.googleapis.com/yolomedia/m_galustyan.jpe',
    },
    {
        label: 'samoylovaoxana',
        subLabel: 'Samoylova Oxana',
        url: 'https://storage.googleapis.com/yolomedia/samoylovaoxana.jpe',
    },
    {
        label: 'dava_m',
        subLabel: 'Давид Манукян',
        url: 'https://storage.googleapis.com/yolomedia/dava_m.jpe',
    },
    {
        label: 'kateclapp',
        subLabel: 'Kate Clapp / Катя Клэп',
        url: 'https://storage.googleapis.com/yolomedia/kateclapp.jpe',
    },
    {
        label: 'reginatodorenko',
        subLabel: 'regina todorenko',
        url: 'https://storage.googleapis.com/yolomedia/reginatodorenko.jpe',
    },
    {
        label: 'xenia_sobchak',
        subLabel: 'Ксения Собчак',
        url: 'https://storage.googleapis.com/yolomedia/xenia_sobchak.jpe',
    },
]

export const ageGenderData = [
    {
        items: [
            {
                share: 0.030532157665950528,
                label: 'f',
                color: '#FF659C',
            },
            {
                share: 0.01291385307276196,
                label: 'm',
                color: '#6172FF',
            },
        ],
        label: '0-17',
        position: '1',
    },
    {
        items: [
            {
                share: 0.16275631408896357,
                label: 'f',
                color: '#FF659C',
            },
            {
                share: 0.024578086210410605,
                label: 'm',
                color: '#6172FF',
            },
        ],
        label: '18-24',
        position: '2',
    },
    {
        items: [
            {
                share: 0.4701360882886555,
                label: 'f',
                color: '#FF659C',
            },
            {
                share: 0.11666237875512461,
                label: 'm',
                color: '#6172FF',
            },
        ],
        label: '25-34',
        position: '3',
    },
    {
        items: [
            {
                share: 0.09735842374144228,
                label: 'm',
                color: '#6172FF',
            },
            {
                share: 0.0743958221262918,
                label: 'f',
                color: '#FF659C',
            },
        ],
        label: '35-44',
        position: '4',
    },
    {
        items: [
            {
                share: 0.00568176122903796,
                label: 'm',
                color: '#6172FF',
            },
            {
                share: 0.0036218930739648036,
                label: 'f',
                color: '#FF659C',
            },
        ],
        label: '45-54',
        position: '5',
    },
    {
        items: [
            {
                share: 0.0009271912620158975,
                label: 'm',
                color: '#6172FF',
            },
            {
                share: 0.0004360304853804491,
                label: 'f',
                color: '#FF659C',
            },
        ],
        label: '>54',
        position: '6',
    },
]

const qualityAudienceBlock = {
    type: 'donut_chart',
    title: 'aq',
    space: 2,
    data: aqData,
}
const audienceReach = {
    type: 'bar_chart',
    title: 'reach',
    space: 1,
    data: reachData,
}
const ageGender = {
    type: 'multi_bar_chart',
    title: 'ageGender',
    data: ageGenderData,
    space: 3,
    legend: [
        {
            color: '#FF659C',
            label: 'women',
        },
        {
            color: '#6172FF',
            label: 'men',
        },
    ],
}
const genderSplit = {
    type: 'donut_chart',
    title: 'gender',
    space: 1,
    data: [
        {
            value: 0.7418783057292067,
            label: 'f',
            color: '#FF659C',
            label_color: '#FF659C',
            percent_color: '#FFDCE2',
        },
        {
            value: 0.2581216942707933,
            label: 'm',
            color: '#6172FF',
            label_color: '#6071FF',
            percent_color: '#E2E6FF',
        },
    ],
}
const notableFollowersBlock = {
    type: 'list',
    space: 1,
    title: 'notableFollowers',
    data: notableFollowers,
}
const cities = {
    type: 'horizontal_chart',
    space: 1,
    title: 'cities',
    data: [
        {
            value: 0.1179191018198959,
            label: 'Moscow',
        },
        {
            value: 0.0734105989735273,
            label: 'Krasnodarskiy',
        },
        {
            value: 0.06613563020611862,
            label: 'St.-Petersburg',
        },
        {
            value: 0.042415342371831005,
            label: 'Moskovskaya',
        },
        {
            value: 0.02017674844825938,
            label: 'Tatarstan',
        },
    ],
}
const countries = {
    type: 'horizontal_chart',
    space: 1,
    title: 'countries',
    data: [
        {
            value: 0.6942793700927585,
            label: 'Russia',
        },
        {
            value: 0.09908197596883762,
            label: 'Ukraine',
        },
        {
            value: 0.02681869808400192,
            label: 'Kazakhstan',
        },
        {
            value: 0.025114196650671168,
            label: 'Belarus',
        },
        {
            value: 0.016280131678505314,
            label: 'United States',
        },
    ],
}
const languages = {
    type: 'horizontal_chart',
    space: 1,
    title: 'languages',
    data: [
        {
            value: 0.4018778953190901,
            label: 'Russian',
        },
        {
            value: 0.0884408490631266,
            label: 'English',
        },
        {
            value: 0.03264698425868308,
            label: 'German',
        },
        {
            value: 0.015190025121574594,
            label: 'Indonesian',
        },
        {
            value: 0.013304754661319688,
            label: 'Arabic',
        },
    ],
}
const brandAffinity = {
    type: 'list',
    title: 'brandAffinity',
    space: 1,
    data: brandAffinityData,
}
const lookalikes = {
    type: 'list',
    space: 1,
    title: 'lookalikes',
    data: lookalikesData,
}
const tags = {
    type: 'chips',
    tagsTitle: 'userTags',
    hashTagsTitle: 'popularHashTags',
    tagsData: [
        'temaoper',
        'fridaytv',
        'irushairusha',
        'malafeeva_ekaterina',
        'yaroslavdamer',
        'karinalavrenko',
        'juliawhitephoto',
        'lov2lov_moscow',
        'glamour_russia',
        'margaritadirge',
    ],
    hashTagsData: ['recoversesh', 'PhotographyRedefined', 'ad', 'Ad', 'vivoX70Series'],
    space: 3,
}
const engagementRate = {
    type: 'er_spread',
    title: 'er_spread',
    space: 4,
    data: erSpreadData,
    legend: [
        {
            color: '#6071FF',
            label: 'likes',
        },
        {
            color: '#4ECF81',
            label: 'comments',
        },
    ],
}
export const allocationChart = {
    type: 'allocation_chart',
    title: 'er_spread',
    space: 4,
    info: erSpreadData.map((item) => ({ x: item.value[1].value, y: item.value[0].value, fillColor: '#6071FF' })),
}

const interests = {
    audience_brand_affinity: {
        hidden: false,
        values: [
            {
                share: 1,
                label: 'clothing',
            },
            {
                share: 0.5,
                label: 'places',
            },
            {
                share: 0.1,
                label: 'media',
            },
            {
                share: 0.3,
                label: 'music',
            },
            {
                share: 0.2,
                label: 'art_supplies',
            },
        ],
    },
    post_type_aggregations_by_days: {
        hidden: false,
        values: {
            '90': {
                GraphImage: 20,
            },
            '30': {
                GraphImage: 9,
            },
        },
    },
    audience_interests: {
        hidden: false,
        values: [
            {
                label: 'Education',
                share: 0.7,
            },
            {
                label: 'Fashion',
                share: 0.3,
            },
            {
                label: 'Comedy',
                share: 0.5,
            },
            {
                label: 'Cars & Moto',
                share: 0.1,
            },
            {
                label: 'Music',
                share: 0.3,
            },
            {
                label: 'Beauty',
                share: 0.8,
            },
            {
                label: 'Business',
                share: 0.2,
            },
            {
                label: 'Music',
                share: 0.5,
            },
            {
                label: 'Sport',
                share: 0.4,
            },
            {
                label: 'Books',
                share: 0.2,
            },
        ],
    },
}
export const AudienceInterests = () => ({
    type: 'interests',
    space: 1.5,
    title: 'AudienceInterests',
    data: interests.audience_interests.values,
})
export const AudienceBrandAffinity = () => ({
    type: 'interests',
    space: 1.5,
    title: 'AudienceBrandAffinity',
    data: interests.audience_brand_affinity.values,
})
export const PostTypes = () => ({
    type: 'postType',
    space: 3,
    title: 'PostType',
    data: {
        30: {
            GraphImage: 34,
            GraphVideo: 53,
        },
        90: {
            GraphImage: 130,
            GraphVideo: 430,
        },
    },
})
