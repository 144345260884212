import React from 'react'
import { useSpaceTranslation } from '@/_helpers'
import Flex from '@/_yc/Flex'
import Text from './Text'

type SoonProps = {
    style?: React.CSSProperties
    textStyle?: React.CSSProperties
}

export const Soon = ({ style, textStyle }: SoonProps) => {
    const t = useSpaceTranslation('Influencer_explorer')
    return (
        <>
            <Flex
                style={{
                    padding: '6px 10px',
                    borderRadius: '10px',
                    background: '#EFF1FF',
                    width: 'fit-content',
                    ...style,
                }}
                content="center"
                align="center"
            >
                <Text style={{ fontSize: '10px', color: '#6071FF', ...textStyle }}>{t('search_input.soon')}</Text>
            </Flex>
        </>
    )
}
