import { IBrands } from '../../types'
export const preprocessBrands = (brands: IBrands[]) => {
    if (!brands || brands.length === 0) return [{ label: 'ERROR', value: '' }]

    const getSubstring = (subs: string[]) => {
        let res = ''
        subs.forEach((s) => (res += `"${s}" `))

        return res
    }
    return brands.map((i) => ({
        label: i.name,
        value: getSubstring(i.substrings),
    }))
}
