import React, {
    FC,
    memo,
    useEffect,
    //  useMemo
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import PageLayout from '@/Layouts/PageLayout'
import { RootStore } from '@/_helpers/StoreType'
import Loader from '@/components/Loader'
import Flex from '@/_yc/Flex'
import { loginActions } from '@/pages/Auth/LoginPage'

import CampaignsList from './CampaignsList'
import { Header } from './Header/Header'
import { CampaignHider } from './CampaignHider/CampaignHider'
import { CampaignManagerProvider } from './CampaignManagerContext/CampaignManagerProvider'
// import CampaignGrid from './CampaignGrid/CampaignGrid'
import { OnBoardingPopup } from './components/OnBoarding'

export interface ICampaignManager {}

const CampaignManager: FC<ICampaignManager> = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const platforms = useSelector((store: RootStore) => {
        return store.authentication.user?.platforms
    })

    useEffect(() => {
        if (!!platforms) return
        dispatch(loginActions.getPlatforms())
    }, [platforms, dispatch])

    const isCampaignAvailable = true
    //  useMemo(() => {
    //     return platforms?.includes('campaign')
    // }, [platforms])

    return (
        <CampaignManagerProvider>
            <PageLayout color="common-background" title={t('titles.t5')}>
                <Header />

                {isCampaignAvailable === undefined ? (
                    <Flex content={'center'} margin="20px 0 0 0">
                        <Loader />
                    </Flex>
                ) : isCampaignAvailable ? (
                    <>
                        {/* <CampaignGrid /> */}
                        <CampaignsList />
                    </>
                ) : (
                    <CampaignHider />
                )}
                <OnBoardingPopup />
            </PageLayout>
        </CampaignManagerProvider>
    )
}

export default memo(CampaignManager)
