import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'

import { RootStore } from '@/_helpers/StoreType'
import Text from '@/components/Text'
import { useTranslation } from 'react-i18next'
import { ChooseAllNEW } from '@/components/MassAddToList/ChooseAll'
import { Platform } from '@/pages/Reports/types'
import { MassAddHeadCheckBox } from '@/components/MassAddToList/MassAddHeadCheckBox'
import { useLocation } from 'react-router-dom'
export interface IItemHead {
    margin?: string | number
}

const ListHeader: FC<IItemHead> = (props) => {
    const { t } = useTranslation()
    const MassAddToListDataINST = useSelector((store: RootStore) => store.MassAddToList.dataINST)
    const MassAddToListDataYT = useSelector((store: RootStore) => store.MassAddToList.dataYT)
    const showHeader = MassAddToListDataINST.length === 0 && MassAddToListDataYT.length === 0

    const location = useLocation()
    //get last word from location
    const lastWord = location.pathname.split('/').pop()
    return (
        <div
            style={{
                padding: '23px 16px',
                borderRadius: '4px',
                background: '#F8F9FB',
                display: 'grid',
                gridTemplateColumns: '0.2fr 0.8fr 1.2fr 0.5fr 0.5fr 0.8fr',
                position: 'sticky',
                top: '69px',
                zIndex: 10,
            }}
        >
            {!showHeader ? (
                <>
                    <MassAddHeadCheckBox place={'competitor_analysis'} />
                </>
            ) : (
                <>
                    <div>
                        <ChooseAllNEW platform={lastWord === 'youtube' ? Platform.YouTube : Platform.Instagram} />
                    </div>
                    <Text fSize="10px" color="gray-3">
                        {t('ads_explorer.head.name')}
                    </Text>
                    <Text fSize="10px" color="gray-3">
                        {t('ads_explorer.head.content')}
                    </Text>
                    <Text fSize="10px" color="gray-3">
                        {t('ads_explorer.head.likes')}
                    </Text>
                    <Text fSize="10px" color="gray-3">
                        {t('ads_explorer.head.comments')}
                    </Text>
                    <div></div>
                </>
            )}
        </div>
    )
}

export default memo(ListHeader)
