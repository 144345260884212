import React from 'react';
import { useTranslation } from 'react-i18next'

import cn from './Resend.module.scss'

const Resend = ({ disabled, onClick, time}) => {
	const { t } = useTranslation();

	return (
		<div className = {cn.root}>
			<div onClick = {onClick} className = {disabled ? cn["resend--disabled"] : cn["resend--active"]}>
				{t("signup.resend.label")}
			</div>
			{
				disabled &&
				<div className = {cn.timer}>
					{time}
				</div>
			}
		</div>
	)
}

export default Resend;