import { TwitchReportResponseData } from '@/pages/Reports/TwitchReport/types'
import { createAction } from '@reduxjs/toolkit'
import { IVideosData, MainInfo, ReportData, TimeSpanType, VideoFilter, ViewsFilter } from './types'
import { VkReportResponseData } from '@/pages/Reports/VkReport/types'
import { IGrowGraphTypes } from './types'
import { IRawTikTokData } from '@/pages/Reports/TikTokReport/types'

export const setInfo = createAction<MainInfo | undefined>('@/report/set_info')

export const setGraphOptions = createAction<Array<number>>('@report/graph/set_options')

export const setGraphType = createAction<IGrowGraphTypes>('@report/graph/set_type')

export const setGraphTimespan = createAction<TimeSpanType>('@report/graph/set_timespan')

export const setVideosFilter = createAction<VideoFilter>('@report/videos/set_filter')

export const setVideosFilterViews = createAction<ViewsFilter>('@report/videos/set_views_filter')

export const setVideosTimespan = createAction<TimeSpanType>('@report/videos/set_timespan')

export const setRawChannelData = createAction<ReportData>('@report/set_raw_channel_data')
export const setRawVideosData = createAction<IVideosData>('@report/set_raw_videos_data')

export const setId = createAction<string>('@report/graph/set_id')

export const setRawTwitchData = createAction<TwitchReportResponseData>('@report/set_raw_twitch_data')

export const setVkRawData = createAction<VkReportResponseData>('@report/set_raw_vk_data')

export const setTgRawData = createAction<any>('@report/set_raw_tg_data')

export const setTikTokRawData = createAction<IRawTikTokData>('@report/set_raw_tiktok_data')
