import React, { FC } from 'react'

import PaymentList from './PaymentList'
import Tariff from './Tariff'

import cn from './BillingPage.module.scss'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import { Flex } from '@/_yc'
import PageLayout from '../../Layouts/PageLayout'

import Text from '@/components/Text'

const BillingPage: FC<{}> = () => {
    const t = useSpaceTranslation('billing')
    const Ttitle = useSpaceTranslation('titles')

    const size = useWindowWidth('mobile')

    return (
        <PageLayout title={Ttitle('tariffs')} color="common-background">
            <div className={cn.root}>
                <main className={cn.container}>
                    <Text component={'h1'} className={cn.title}>
                        {t('title')}
                    </Text>
                    <Flex column={size === 'mobile'}>
                        <Tariff />
                        <PaymentList />
                    </Flex>
                </main>
            </div>
        </PageLayout>
    )
}

export default BillingPage
