import React from 'react'
import Icons from '@/components/Icons'
import { RangeFilter, RangeValueType } from '../../../../filters/RangeFilter'
import { PLATFORM } from '../../consts'
import { getRange } from '../../../youtube/utils/filters/getRange'

const getGqlFilter = (range: RangeValueType) => {
    if (!range.from && !range.to) return ''

    return `subscriber_count: ${getRange(range.from, range.to)}`
}

export const getFollowersFilter = (isMain?: boolean) => {
    return new RangeFilter({
        platform: PLATFORM,
        title: 'filters.followers.channel.label',
        helper: 'filters.followers.channel.text',
        icon: <Icons name="filter--followers" />,
        space: 1,
        id: 'followers_yt',
        beforeSend: (data) => {
            return {
                name: 'metrics.followers',
                type: 'range',
                value: data,
                gqlFilter: getGqlFilter(data),
            }
        },
    })
}
