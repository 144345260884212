import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="18"
        height="12"
        viewBox="0 0 18 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.00008 11.8334C7.63331 11.8504 6.28067 11.5554 5.04508 10.9709C4.08733 10.5036 3.22729 9.85819 2.51091 9.06921C1.75211 8.25348 1.15464 7.30143 0.750081 6.26338L0.666748 6.00004L0.754248 5.73671C1.15909 4.69957 1.75528 3.74777 2.51175 2.93088C3.22787 2.14197 4.08761 1.4966 5.04508 1.02921C6.28068 0.4447 7.63331 0.149719 9.00008 0.16671C10.3668 0.149749 11.7195 0.444728 12.9551 1.02921C13.9129 1.49649 14.7729 2.14187 15.4892 2.93088C16.2495 3.74551 16.8471 4.69785 17.2501 5.73671L17.3334 6.00004L17.2459 6.26338C15.9386 9.66653 12.6452 11.8912 9.00008 11.8334ZM9.00008 1.83338C6.16331 1.74448 3.5596 3.39596 2.43091 6.00004C3.55941 8.60429 6.16324 10.2558 9.00008 10.1667C11.8368 10.2554 14.4404 8.60397 15.5692 6.00004C14.442 3.39469 11.8374 1.74261 9.00008 1.83338ZM9.00008 8.50004C7.79785 8.508 6.75789 7.66451 6.51755 6.48652C6.27722 5.30853 6.90364 4.12506 8.0129 3.66143C9.12216 3.19779 10.4044 3.58349 11.0739 4.58213C11.7433 5.58078 11.6129 6.91344 10.7626 7.76338C10.297 8.23442 9.66238 8.49968 9.00008 8.50004Z"
            fill="#8F8F8F"
        />
    </svg>
)
