import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 3.36V0H12.6567V1.2H13.8847L12.0281 3.06586C11.2711 2.49691 10.3314 2.16 9.31343 2.16C7.56049 2.16 6.03972 3.15902 5.28409 4.62146C5.04114 4.58104 4.79167 4.56 4.53731 4.56C2.03142 4.56 0 6.60158 0 9.12C0 11.3934 1.65541 13.2783 3.8209 13.6235V14.88H2.14925V16.32H3.8209V18H5.25373V16.32H6.92537V14.88H5.25373V13.6235C6.69843 13.3932 7.9161 12.4776 8.56665 11.2185C8.80961 11.259 9.05907 11.28 9.31343 11.28C11.8193 11.28 13.8507 9.23842 13.8507 6.72C13.8507 5.69692 13.5155 4.75254 12.9494 3.99176L14.806 2.1259V3.36H16ZM4.84181 5.94246C4.7416 5.93288 4.64003 5.92799 4.53731 5.92799C2.78319 5.92799 1.36119 7.35712 1.36119 9.12C1.36119 10.8829 2.78319 12.312 4.53731 12.312C5.68561 12.312 6.69158 11.6996 7.24948 10.782C5.78118 10.0269 4.77612 8.49144 4.77612 6.72C4.77612 6.45492 4.79862 6.19515 4.84181 5.94246ZM7.69488 9.46702C6.76237 8.91088 6.13731 7.8888 6.13731 6.72C6.13731 6.60275 6.1436 6.48697 6.15586 6.37298C7.08837 6.92912 7.71343 7.9512 7.71343 9.12C7.71343 9.23725 7.70714 9.35303 7.69488 9.46702ZM9.00893 9.89754C9.05212 9.64485 9.07463 9.38508 9.07463 9.12C9.07463 7.34856 8.06956 5.81306 6.60127 5.05802C7.15917 4.14044 8.16514 3.52799 9.31343 3.52799C11.0676 3.52799 12.4896 4.95712 12.4896 6.72C12.4896 8.48288 11.0676 9.91201 9.31343 9.91201C9.21072 9.91201 9.10915 9.90712 9.00893 9.89754Z"
            fill="#8F8F8F"
        />
    </svg>
)
