import Text from '@/components/Text'
import Flex from '@/_yc/Flex'
import React, {
    ForwardRefRenderFunction,
    HTMLAttributes,
    ReactNode,
} from 'react'
import cn from './Segment.module.scss'

export interface ISegment extends HTMLAttributes<HTMLDivElement> {
    title: string
    children?: ReactNode
}

const Segment: ForwardRefRenderFunction<HTMLDivElement, ISegment> = (
    props: ISegment,
    ref
) => {
    return (
        <Flex ref={ref} column className={cn.main}>
            <Text fSize={18} semibold>
                {props.title}
            </Text>
            {props.children}
        </Flex>
    )
}

export default Segment
