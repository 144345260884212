import { BloggersList, BloggerListItem } from '@/Reponses'
import { PagginationService } from '@/_helpers/PagginationService'
import Service from '@/_helpers/Service'

const GetBloggersByListIdService = new PagginationService<BloggerListItem>(
    '/api/sets/blogger/'
)

export const getListBloggers = (
    token: string,
    listId: number,
    page: number = 1
) =>
    GetBloggersByListIdService.call(
        {
            params: {
                set: listId,
                page,
            },
        },
        token
    )

export const getAllBloggersFromList = (token: string, listId: number) =>
    GetBloggersByListIdService.getAll({ params: { set: listId } }, token)

const GetListByIdService = new Service<BloggersList>('/api/sets/list/')

export const getListById = (token: string, listId: number) =>
    GetListByIdService.call(
        {
            url: `/api/sets/list/${listId}/`,
        },
        token
    )
