import React, { ComponentType, FC, memo } from 'react'

export const chainComponents = <
    Second extends Record<string, any>,
    MasterProps extends { component: any } & Second
>(
    Component: ComponentType<MasterProps>,
    Apply: ComponentType<Second>
): FC<MasterProps & Second> => {
    return memo((props) => <Component {...props} component={Apply} />)
}
