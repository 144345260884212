import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import { BloggerRating } from '@yoloker/ui-collection'
import PartChanger from '@/Layouts/ReportLayout/Extra/PartChanger'

import { Flex } from '@/_yc/'
import Layout, { ExtraContent } from '@/Layouts/AudienceLayout'
import { getMonth } from '@/_helpers/parseDate_ts'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import { LISTS } from '@/routs.js'

import Title from './Title'
import Posts from './Posts'
import PDFBar from './PDFBar'
import ReportRows from './ReportRows'
import { IRefs, IReportReadyStatus } from './Types'
import { ContextMenu } from '@/_yc'

import cn from './BloggerReport.module.scss'
import { Predictions } from './Predictions/Predictions'

export interface IBloggerReport extends RouteComponentProps<{ id: string }> {
    loadReport: VoidFunction

    clearReport: VoidFunction

    blogger: {
        loaded: boolean
        error: boolean
        blogger: {
            data: any
        }
        status: IReportReadyStatus
    }

    pdfLoader: {
        loading: boolean
        startLoading: (username: string, blogger_id: string) => void
    }

    updateComplete: boolean
    completeDate: string
}

export interface Item {
    value:
        | null
        | number
        | {
              from: number | null
              to: number | null
          }
    title: string
    icon: 'Group' | 'Reach'
    currency?: 'RUB' | 'USD' | 'BRL'
    currency_name?: 'RUB' | 'USD' | 'BRL'
    tooltipText: string
}

export interface IPredictions {
    items: Item[]
    isDemo?: boolean
    inProgress?: boolean
}

const classes = { container: cn.root }

const addToListStyle = {
    width: 170,
    height: 40,
    color: '#6071ff',
    fontSize: 12,
    padding: '0 19px',
    textAlign: 'center',
}

const BloggerReport: FC<IBloggerReport> = ({ clearReport, loadReport, blogger, pdfLoader, ...props }) => {
    const reportReadyStatus = blogger.status
    const info = blogger.blogger.data
    const { t } = useTranslation()

    const [updatedDate, setUpdatedDate] = useState<{ date: string; completed: boolean }>()

    const size = useWindowWidth('mobile')

    const xs = size === 'mobile'

    const predictions: Array<Item> = useMemo(
        () =>
            info?.predictions
                ?.filter((item: Item) => item.title === 'coverage')
                ?.map((item: Item) => {
                    const isCoverage = item.title.includes('coverage')
                    return {
                        ...item,
                        currency: item.currency_name,
                        icon: isCoverage ? 'Group' : 'Reach',
                        tooltipText: t(`report_titles.predictions.tooltips.${item.title}`),
                    }
                }),
        [info, t]
    )
    const InProgressPredictions: Array<Item> = [
        {
            icon: 'Group',
            title: 'post_coverage',
            value: 1,
            tooltipText: t('report_titles.predictions.tooltips.post_coverage'),
        },
        {
            currency_name: 'RUB',
            icon: 'Reach',
            title: 'postPrice',
            value: {
                from: 1,
                to: 2,
            },
            tooltipText: t('report_titles.predictions.tooltips.postPrice'),
        },
        {
            currency_name: 'RUB',
            icon: 'Reach',
            title: 'storyPrice',
            value: 1,
            tooltipText: t('report_titles.predictions.tooltips.storyPrice'),
        },
    ]

    const download = useCallback(() => {
        pdfLoader.startLoading(blogger.blogger.data.mainInfo.username, props.match.params.id)
    }, [pdfLoader, blogger.blogger.data, props.match.params.id])

    useEffect(() => {
        loadReport()
        return () => {
            clearReport()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const tempDate = new Date(props.completeDate)
        const actualCompleteDate = `${tempDate.getDate()} ${getMonth(
            tempDate.getMonth() + 1,
            i18n.language
        )} ${tempDate.getFullYear()}`
        setUpdatedDate({
            date: actualCompleteDate,
            completed: props.updateComplete,
        })
    }, [blogger.loaded, props.updateComplete, props.completeDate])

    const accountDataRef = useRef(null)
    const audienceRef = useRef(null)
    const brandsRef = useRef(null)
    const similarAccsRef = useRef(null)
    const contentRef = useRef(null)

    const refs: IRefs = {
        accountData: accountDataRef,
        audience: audienceRef,
        brands: brandsRef,
        similarAccs: similarAccsRef,
        content: contentRef,
    }

    return (
        <Layout classes={classes} loading={!blogger.loaded} error={blogger.error} isReport={true}>
            <ExtraContent>
                <Link to={LISTS} className={cn.back_button}>
                    <ArrowLeft fontSize="small" />
                    {t('report.back_btn')}
                </Link>

                <Flex>
                    <div className={cn.addToList}>
                        <ContextMenu
                            t={t}
                            reportButton
                            style={addToListStyle}
                            margin={'0 20px 0 0'}
                            username={info?.mainInfo?.username}
                            id={info?.mainInfo?.externalId}
                        />
                    </div>
                    <PDFBar
                        onClick={download}
                        loading={pdfLoader.loading}
                        lockedOnParsing={reportReadyStatus !== 1}
                        tooltip={reportReadyStatus !== 1 && 'updating_data'}
                    />
                </Flex>
            </ExtraContent>

            {blogger.loaded && (
                <div className={cn.container}>
                    <Title
                        isVerified={info.mainInfo.isVerified}
                        avatar={info.mainInfo.avatar}
                        fullName={info.mainInfo.fullName}
                        username={info.mainInfo.username}
                        metrics={info.horizontalMetrics}
                        categories={info.bloggerInterests.values}
                        xs={xs}
                        contacts={info.contacts}
                        updatedDate={updatedDate}
                        er={info.erStat_v2?.mainValue}
                        reportReadyStatus={reportReadyStatus !== 1}
                    />
                    {size === 'desktop' && (
                        <PartChanger
                            values={[
                                {
                                    title: t('report_titles.account_data.title'),
                                    value: accountDataRef,
                                },
                                {
                                    title: t('report_titles.auditory.title'),
                                    value: audienceRef,
                                },
                                ...(info.brandAffinity.values.length > 0
                                    ? [
                                          {
                                              title: t('report_titles.brandAffinity.title'),
                                              value: brandsRef,
                                          },
                                      ]
                                    : []),
                                ...(info.chaining.values.length > 0
                                    ? [
                                          {
                                              title: t('report_titles.lookalikes.title'),
                                              value: similarAccsRef,
                                          },
                                      ]
                                    : []),
                                {
                                    title: t('report_titles.content.title'),
                                    value: contentRef,
                                },
                            ]}
                        />
                    )}
                    <Flex column={size === 'mobile'} margin={size !== 'mobile' ? '30px 0 0 0' : ''}>
                        <BloggerRating
                            mainValue={info.blogger_rating?.main_value}
                            tooltipText={t('report_titles.blogger_rating.tooltip')}
                            inProgress={reportReadyStatus !== 1}
                        />
                        <Flex
                            margin={size !== 'mobile' && info.blogger_rating?.main_value ? '0 0 0 20px' : ''}
                            style={{ flexBasis: '100%' }}
                        >
                            <Predictions
                                items={reportReadyStatus === 1 ? predictions : InProgressPredictions}
                                inProgress={reportReadyStatus !== 1}
                            />
                        </Flex>
                    </Flex>
                    {/* <Contacts
                        data={info.contacts.filter(
                            (item: { value: any }) => !!item.value && item.value !== 'None, None'
                        )}
                    /> */}
                    <ReportRows xs={xs} data={info} refs={refs} reportReadyStatus={reportReadyStatus} />
                    <Posts popular={info.topPosts.popular.values} promotion={info.topPosts.promotion.values} />
                </div>
            )}
        </Layout>
    )
}

export default memo(BloggerReport)
