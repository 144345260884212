import React from 'react';

import { StandartLabel } from '../../Blocks'

import cn from './Note.module.scss'

export const Note = ({ title, helperText, value }) => (
	<div className = {`${cn.root} ${!value ? cn["root--hidden"] : ""}`}>
		<StandartLabel title={title} helperText={helperText} style={{marginBottom: 8}}/>
		{!!value ?
			<h3 className = {cn.root_val}>
				{(value * 100).toFixed(2)}%
			</h3> : <div className = {cn.placeholder}></div>
		}
	</div>
)