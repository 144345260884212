import React from 'react'

export const EmptyChatIcon = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30" cy="30" r="30" fill="#F2F4F7" />
            <path d="M26.25 26.25H23.75V28.75H26.25V26.25Z" fill="#A2AEC2" />
            <path d="M28.75 26.25H31.25V28.75H28.75V26.25Z" fill="#A2AEC2" />
            <path d="M36.25 26.25H33.75V28.75H36.25V26.25Z" fill="#A2AEC2" />
            <path
                d="M18.75 21.25V41.25L24.75 36.75C25.1823 36.4245 25.7089 36.249 26.25 36.25H38.75C40.1307 36.25 41.25 35.1307 41.25 33.75V21.25C41.25 19.8693 40.1307 18.75 38.75 18.75H21.25C19.8693 18.75 18.75 19.8693 18.75 21.25ZM21.25 36.25V21.25H38.75V33.75H25.4175C24.8763 33.7485 24.3496 33.9241 23.9175 34.25L21.25 36.25Z"
                fill="#A2AEC2"
            />
        </svg>
    )
}
