import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.8333 15.5H2.16667C1.24619 15.5 0.5 14.7538 0.5 13.8333V2.16667C0.5 1.24619 1.24619 0.5 2.16667 0.5H13.8333C14.7538 0.5 15.5 1.24619 15.5 2.16667V13.8333C15.5 14.7538 14.7538 15.5 13.8333 15.5ZM2.16667 2.16667V13.8333H13.8333V2.16667H2.16667ZM13 12.1667H3L5.5 8.83333L6.33333 9.66667L8.83333 6.33333L13 12.1667ZM5.08333 7.16667C4.39298 7.16667 3.83333 6.60702 3.83333 5.91667C3.83333 5.22631 4.39298 4.66667 5.08333 4.66667C5.77369 4.66667 6.33333 5.22631 6.33333 5.91667C6.33333 6.60702 5.77369 7.16667 5.08333 7.16667Z"
            fill="#8F8F8F"
        />
    </svg>
)
