import { authHeader } from '../../_helpers/auth-header.js'
import { APIService } from '../../_helpers/APIService.js'

export default new APIService({
	methods: {
		getLists: page => ({
			url: "/api/sets/list/?page=" + page,
			method: "GET",
			headers: authHeader()
		}),
		addBloggers: (set_id, external_ids)=>({
			url: "/api/sets/blogger/?set="+set_id,
			method: "POST",
			headers: authHeader(),
			data: {
				set_id,
				external_ids
			}
		}),
		addTwitchChannels: (set_id, external_ids)=>({
			url: "/api/sets/blogger/?set="+set_id,
			method: "POST",
			headers: authHeader(),
			data: {
				set_id,
				twitch_channels_ids: external_ids
			}
		}),
		addChannels: (set_id, external_ids)=>({
			url: "/api/sets/blogger/?set="+set_id,
			method: "POST",
			headers: authHeader(),
			data: {
				set_id,
				external_ids,
				channel_ids: external_ids
			}
		}),
		addList: (name)=>({
			url: "/api/sets/list/",
			method: "POST",
			headers: authHeader(),
			data: { name }
		})
	}
})