import React from 'react';
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

import UserIcon from './UserIcon.js'

import cn from './CreateCard.module.scss'
import Text from '@/components/Text';
import { LockIcon } from '@/_yc';
import { selectLocker } from "@/_helpers/selectLocker"
import { TARIFFS } from '@/routs.js';

const shouldLock = selectLocker("intersection");

const components = [<Link to={TARIFFS} />];

const text = { padding: "0 24px" };

const position = { position: 'absolute'};

const CreateCard = ({ onClick }) => {
	const { t } = useTranslation();
	const disabled = useSelector(shouldLock);
	return (
		<div onClick={disabled ? undefined : onClick} className = {disabled ? cn["root--disabled"] : cn["root--active"]}>
			<div className = {cn.icons}>
				<UserIcon className = {cn.icon} />
				<UserIcon className = {cn.icon} />
				<UserIcon className = {cn.icon} />
			</div>
			<Text color={disabled ? "gray-3" : undefined} tAlign="center" margin="12px 0 0" fSize={18} component="h4" semibold={false}>
				{t("intersections.dashboards.create.label")}
			</Text>
			<Text style={text} color={disabled ? "gray-5" : "gray-3"} tAlign="center" fSize={12} >
				{t("intersections.dashboards.create.text")}
			</Text>
			{
				disabled && 
				<LockIcon style={position} locker className={cn.locker} button>
					<Trans
						i18nKey={"intersections.dashboards.create.disabled"}
						components={components}
					/>
				</LockIcon>
			}
		</div>
	)
}

export { CreateCard as default }