import React from 'react'
import Icons from '@/components/Icons'
import { RangeFilter, RangeValueType } from '@/pages/SearchPage/SearchAdapter/filters/RangeFilter'
// import { getRange } from '../../../../youtube/utils/filters/getRange'

const getGqlFilter = (range: RangeValueType) => {
    if (!range.from && !range.to) return ''

    return `followers: { left_number:  ${range.from}, right_number: ${range.to}}`
}

export const getFollowersFilter = (isMain?: boolean) => {
    return new RangeFilter({
        platform: 'infl_tiktok',
        title: 'filters.followers.label',
        helper: 'filters.followers.text',
        icon: <Icons name="filter--followers" />,
        space: 1,
        id: 'followers_tiktok',
        beforeSend: (data) => {
            return {
                name: 'metrics.followers',
                type: 'range',
                value: data,
                gqlFilter: getGqlFilter(data),
            }
        },
    })
}
