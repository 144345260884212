import Popup from '@/_yc/Popup'
import React, { FC, useCallback, useMemo, useState } from 'react'

import cn from './EditSmtpPopup.module.scss'
import Flex from '@/_yc/Flex'
import Text from '@/components/Text'
import Icons from '@/components/Icons'
import { useSpaceTranslation } from '@/_helpers'
import { Input } from '@/components/Input/Input'
import { CheckBox } from '../CheckBox/CheckBox'
import LoadingButton from '@/components/Share/LoadingButton'
import {
    MailServerData,
    SmtpData,
    checkImapReq,
    checkSmtpReq,
    editAccountReq,
} from '@/pages/Outreach/Service/Campaigns'
import useToken from '@/_helpers/Hooks/useToken'
import { MarkerDot } from '@/components/MarkerDot/MarkerDot'
import { EmailAccount } from '@/pages/SettingsPage/EmailAccounts/EmailAccounts'
import { z } from 'zod'

interface EditSmtpPopupProps {
    isOpen: boolean
    close: () => void
    account: EmailAccount
}

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 700,
    zIndex: 2000,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}

export const EditSmtpPopup: FC<EditSmtpPopupProps> = ({ isOpen, close, account }) => {
    const t = useSpaceTranslation('settings.mail.smtp')

    const smtpSchema = useMemo(
        () =>
            z.object({
                email: z.string().email('Invalid email address'),
                sender_name: z.string().min(1, 'Sender name is required'),
                signature: z.string().min(1, 'Signature is required'),
                imap_host: z.string().min(1, 'IMAP host is required'),
                imap_port: z.string().min(1, 'IMAP port is required'),
                imap_password: z.string().min(1, 'IMAP password is required'),
                smtp_host: z.string().min(1, 'SMTP host is required'),
                smtp_port: z.string().min(1, 'SMTP port is required'),
                smtp_password: z.string().min(1, 'SMTP password is required'),
                total_emails: z.string().min(1, 'Total emails is required'),
            }),
        []
    )

    const [errors, setErrors] = useState({
        email: [],
        sender_name: [],
        signature: [],
        imap_host: [],
        imap_port: [],
        imap_password: [],
        smtp_host: [],
        smtp_port: [],
        smtp_password: [],
        total_emails: [],
    })

    const [email, setEmail] = useState(account.email)
    const [senderName, setSenderName] = useState(account.senderName)

    const [imapHost, setImapHost] = useState(account.emailCredentials?.imap?.host || '')
    const [imapPort, setImapPort] = useState(account.emailCredentials?.imap?.port || '')
    const [imapPassword, setImapPassword] = useState(account.emailCredentials?.imap?.password || '')

    const [smtpHost, setSmtpHost] = useState(account.emailCredentials?.smtp?.host || '')
    const [smtpPort, setSmtpPort] = useState(account.emailCredentials?.smtp?.port || '')
    const [smtpPassword, setSmtpPassword] = useState(account.emailCredentials?.smtp?.password || '')

    const [enableImapSsl, setEnableImapSsl] = useState<boolean>(account.emailCredentials?.imap?.enable_ssl || true)
    const [enableSmtpSsl, setEnableSmtpSsl] = useState<boolean>(account.emailCredentials?.smtp?.enable_ssl || true)

    const [totalEmails, setTotalEmails] = useState(account.dailyLimit.toString())

    const [signature, setSignature] = useState(account.signature || '')

    const token = useToken()

    const validSchema = useCallback(() => {
        try {
            smtpSchema.parse({
                email,
                sender_name: senderName,
                signature,
                imap_host: imapHost,
                imap_port: imapPort.toString(),
                imap_password: imapPassword,
                smtp_host: smtpHost,
                smtp_port: smtpPort.toString(),
                smtp_password: smtpPassword,
                total_emails: totalEmails.toString(),
            })
            return true
            // @ts-ignore
        } catch (e: any) {
            setErrors(e.formErrors.fieldErrors)
            console.log(e)
            return false
        }
    }, [
        email,
        senderName,
        signature,
        imapHost,
        imapPort,
        imapPassword,
        smtpHost,
        smtpPort,
        smtpPassword,
        totalEmails,
        smtpSchema,
    ])

    const handleCreateAccount = useCallback(() => {
        if (!token) return

        if (!validSchema()) return

        const data: SmtpData = {
            email: email,
            sender_name: senderName,
            signature: signature,
            email_server_type: 'smtp/imap',
            day_limit: parseInt(totalEmails),
            daily_ramp_up: 40,
            email_credentials: {
                imap: {
                    host: imapHost,
                    port: parseInt(imapPort.toString()),
                    password: imapPassword,
                    enable_ssl: enableImapSsl,
                    username: email,
                },
                smtp: {
                    host: smtpHost,
                    port: parseInt(smtpPort.toString()),
                    password: smtpPassword,
                    enable_ssl: enableSmtpSsl,
                    username: email,
                },
            },
        }
        editAccountReq(token, data, account.id).then((res) => {
            console.log(res)
        })

        close()
    }, [
        validSchema,
        email,
        senderName,
        imapHost,
        imapPort,
        imapPassword,
        smtpHost,
        smtpPort,
        smtpPassword,
        enableImapSsl,
        enableSmtpSsl,
        totalEmails,
        signature,
        token,
        close,
        account.id,
    ])

    const [isImapTestOk, setIsImapTestOk] = useState(false)
    const [isImapTested, setIsImapTested] = useState(false)

    const [isSmtpTestOk, setIsSmtpTestOk] = useState(false)
    const [isSmtpTested, setIsSmtpTested] = useState(false)

    const testImap = useCallback(() => {
        if (!token) return

        const data: MailServerData = {
            host: imapHost,
            port: parseInt(imapPort.toString()),
            password: imapPassword,
            enable_ssl: enableImapSsl,
            username: email,
        }

        checkImapReq(token, data)
            .then((res) => {
                setIsImapTestOk(true)
                setIsImapTested(true)
            })
            .catch((err) => {
                console.log(err)
                setIsImapTestOk(false)
                setIsImapTested(true)
            })
    }, [imapHost, imapPort, imapPassword, enableImapSsl, token, email])

    const testSmtp = useCallback(() => {
        if (!token) return

        const data: MailServerData = {
            host: smtpHost,
            port: parseInt(smtpPort.toString()),
            password: smtpPassword,
            enable_ssl: enableSmtpSsl,
            username: email,
        }

        checkSmtpReq(token, data)
            .then((res) => {
                setIsSmtpTestOk(true)
                setIsSmtpTested(true)
            })
            .catch((err) => {
                console.log(err)
                setIsSmtpTestOk(false)
                setIsSmtpTested(true)
            })
    }, [smtpHost, smtpPort, smtpPassword, enableSmtpSsl, token, email])

    return (
        <div className={cn.root}>
            <Popup style={style} isOpen={isOpen} onClose={close}>
                <div className={cn.popup}>
                    <div className={cn.header}>
                        <Flex content="space-between" align="center">
                            <Text fSize={20}>{t('edit_smtp_title')}</Text>
                            <div onClick={close}>
                                <Icons name="close" className={cn['popup--close']} />
                            </div>
                        </Flex>
                    </div>

                    <div className={cn.grid} style={{ marginTop: 46 }}>
                        <div>
                            <Input
                                label={t('email.title')}
                                placeholder={t('email.placeholder')}
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                disabled
                            />
                            {errors.email?.length > 0 && (
                                <Text color="red-1" fSize={10}>
                                    {errors.email[0]}
                                </Text>
                            )}
                        </div>
                        <div>
                            <Input
                                label={t('sender_name.title')}
                                placeholder={t('sender_name.placeholder')}
                                value={senderName}
                                onChange={(e) => setSenderName(e.target.value)}
                            />
                            {errors.sender_name?.length > 0 && (
                                <Text color="red-1" fSize={10}>
                                    {errors.sender_name[0]}
                                </Text>
                            )}
                        </div>

                        <div className={cn.card}>
                            <Flex content="space-between">
                                <Text fSize={16}>{t('receiving_emails.title')}</Text>

                                <Flex align="center" style={{ gap: 4 }}>
                                    {isImapTested && <MarkerDot color={isImapTestOk ? 'green' : 'red'} />}
                                    <Text
                                        color={isImapTested && isImapTestOk ? 'gray-3' : 'violet-1'}
                                        onClick={testImap}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Test
                                    </Text>
                                </Flex>
                            </Flex>
                            <div>
                                <Input
                                    label={t('receiving_emails.imap_host.title')}
                                    placeholder={t('receiving_emails.imap_host.placeholder')}
                                    value={imapHost}
                                    onChange={(e) => setImapHost(e.target.value)}
                                />
                                {errors.imap_host?.length > 0 && (
                                    <Text color="red-1" fSize={10}>
                                        {errors.imap_host[0]}
                                    </Text>
                                )}
                            </div>

                            <div>
                                <Input
                                    label={t('receiving_emails.imap_port.title')}
                                    placeholder={t('receiving_emails.imap_port.placeholder')}
                                    value={imapPort}
                                    onChange={(e) => setImapPort(e.target.value)}
                                />
                                {errors.imap_port?.length > 0 && (
                                    <Text color="red-1" fSize={10}>
                                        {errors.imap_port[0]}
                                    </Text>
                                )}
                            </div>

                            <div>
                                <Input
                                    label={t('receiving_emails.password.title')}
                                    placeholder={t('receiving_emails.password.placeholder')}
                                    type="password"
                                    value={imapPassword}
                                    onChange={(e) => setImapPassword(e.target.value)}
                                />
                                {errors.imap_password?.length > 0 && (
                                    <Text color="red-1" fSize={10}>
                                        {errors.imap_password[0]}
                                    </Text>
                                )}
                            </div>
                            <CheckBox
                                isChecked={enableImapSsl}
                                label={t('receiving_emails.enable_ssl')}
                                onClick={() => setEnableImapSsl((prev) => !prev)}
                            />
                        </div>
                        <div className={cn.card}>
                            <Flex content="space-between">
                                <Text fSize={16}>{t('sending_emails.title')}</Text>
                                <Flex align="center" style={{ gap: 4 }}>
                                    {isSmtpTested && <MarkerDot color={isSmtpTestOk ? 'green' : 'red'} />}
                                    <Text
                                        color={isSmtpTested && isSmtpTestOk ? 'gray-3' : 'violet-1'}
                                        onClick={testSmtp}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Test
                                    </Text>
                                </Flex>
                            </Flex>
                            <div>
                                <Input
                                    label={t('sending_emails.smtp_host.title')}
                                    placeholder={t('sending_emails.smtp_host.placeholder')}
                                    value={smtpHost}
                                    onChange={(e) => setSmtpHost(e.target.value)}
                                />
                                {errors.smtp_host?.length > 0 && (
                                    <Text color="red-1" fSize={10}>
                                        {errors.smtp_host[0]}
                                    </Text>
                                )}
                            </div>

                            <div>
                                <Input
                                    label={t('sending_emails.smtp_port.title')}
                                    placeholder={t('sending_emails.smtp_port.placeholder')}
                                    value={smtpPort}
                                    onChange={(e) => setSmtpPort(e.target.value)}
                                />
                                {errors.smtp_port?.length > 0 && (
                                    <Text color="red-1" fSize={10}>
                                        {errors.smtp_port[0]}
                                    </Text>
                                )}
                            </div>

                            <div>
                                <Input
                                    label={t('sending_emails.password.title')}
                                    type="password"
                                    placeholder={t('sending_emails.password.placeholder')}
                                    value={smtpPassword}
                                    onChange={(e) => setSmtpPassword(e.target.value)}
                                />
                                {errors.smtp_password?.length > 0 && (
                                    <Text color="red-1" fSize={10}>
                                        {errors.smtp_password[0]}
                                    </Text>
                                )}
                            </div>
                            <CheckBox
                                isChecked={enableSmtpSsl}
                                label={t('sending_emails.enable_ssl')}
                                onClick={() => setEnableSmtpSsl((prev) => !prev)}
                            />
                        </div>
                    </div>

                    <div style={{ marginTop: 30 }}>
                        <Text fSize={16} component="span">
                            {t('safety.title')}
                        </Text>
                        <Text fSize={16} component="span" color="gray-3">
                            {' '}
                            {t('safety.optional')}
                        </Text>
                    </div>

                    <div className={cn.grid} style={{ marginTop: 18 }}>
                        <div>
                            <Input
                                label={t('safety.total_emails.label')}
                                value={totalEmails}
                                onChange={(e) => setTotalEmails(e.target.value)}
                            />
                            {errors.total_emails?.length > 0 && (
                                <Text color="red-1" fSize={10}>
                                    {errors.total_emails[0]}
                                </Text>
                            )}
                        </div>
                        <Text color="gray-new-2" fSize={12} margin="18px 0 0 0">
                            {t('safety.total_emails.explainer')}
                        </Text>
                    </div>

                    <div className={cn.signature}>
                        <Text fSize={12}>{t('signature.title')}</Text>
                        <div className={cn.editor}>
                            <textarea
                                className={cn.editorArea}
                                placeholder={t('signature.placeholder')}
                                value={signature}
                                onChange={(e) => setSignature(e.target.value)}
                            ></textarea>
                        </div>

                        {errors.signature?.length > 0 && (
                            <Text color="red-1" fSize={10}>
                                {errors.signature[0]}
                            </Text>
                        )}
                    </div>

                    <div className={cn.footer}>
                        <LoadingButton variant="primary" noDownloadIcon noLoadingText onClick={handleCreateAccount}>
                            {t('btn_save')}
                        </LoadingButton>
                    </div>
                </div>
            </Popup>
        </div>
    )
}
