import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react'

import Flex from '@/_yc/Flex'

import Button from '@/components/Button'
// import Icons from '@/components/Icons'
import Text from '@/components/Text'
import { useAdapter, useSearch } from '../../../SearchAdapter'

import cn from './SearchInput.module.scss'
import useOpenClose from '@/_helpers/useOpenClose'
import Popup from '@/_yc/Popup'
import { InstagramAdapter } from '../../../SearchAdapter/bloggers/instagram'
import { YoutubeAdapter } from '../../../SearchAdapter/bloggers/youtube'
import { VkAdapter } from '@/pages/SearchPage/SearchAdapter/bloggers/vk'
import { TgAdapter } from '@/pages/SearchPage/SearchAdapter/bloggers/telegram'
import { TikTokAdapter } from '@/pages/SearchPage/SearchAdapter/bloggers/tiktok'
import { useTranslation } from 'react-i18next'
// import { Link } from 'react-router-dom'
import { SEARCH } from '@/routs'
import InstaIcon from './InstaIcon'
import YTIcon from './YTIcon'
import { Link } from 'react-router-dom'
import { TwitchAdapter } from '@/pages/SearchPage/SearchAdapter/bloggers/twitch'
import TwitchIE from '@/components/Icons/UI/TwitchIE'
import VkIE from '@/components/Icons/UI/Vk'
import { Platform } from '@/pages/Reports/types'
import { useInstagramHidden } from '@/_helpers/Hooks/useIsInstagramHidden'
import { useVkHidden, useTgHidden, useTikTokHidden } from '@/_helpers/Hooks/useIsInstagramHidden'
import TgIE from '@/components/Icons/UI/Telegram'
import TikTokIE from '@/components/Icons/UI/TikTokIE'

export interface IAdapterSelector {}

const popup = { marginTop: 10, zIndex: 10 }

const items = [
    {
        label: InstagramAdapter.config.title,
        Icon: InstaIcon,
        soon: false,
        adapter: InstagramAdapter,
        platform: Platform.Instagram,
    },
    {
        label: YoutubeAdapter.config.title,
        Icon: YTIcon,
        soon: false,
        adapter: YoutubeAdapter,
        platform: Platform.YouTube,
    },
    {
        label: TwitchAdapter.config.title,
        Icon: TwitchIE,
        soon: false,
        adapter: TwitchAdapter,
        platform: Platform.Twitch,
    },
    {
        label: VkAdapter.config.title,
        Icon: VkIE,
        soon: false,
        adapter: VkAdapter,
        platform: Platform.Vk,
    },
    {
        label: TgAdapter.config.title,
        Icon: TgIE,
        soon: false,
        adapter: TgAdapter,
        platform: Platform.Tg,
    },
    {
        label: TikTokAdapter.config.title,
        Icon: TikTokIE,
        soon: false,
        adapter: TikTokAdapter,
        platform: Platform.TikTok,
    },
]

const AdapterSelector: FC<IAdapterSelector> = (props) => {
    const [isOpen, , close, toggle] = useOpenClose()
    const isVkHidden = useVkHidden()
    const isTgHidden = useTgHidden()
    const isTikTokHidden = useTikTokHidden()

    const { restart, clearSearch, clearFilters } = useSearch()

    const ref = useRef(null)

    const Adapter = useAdapter()

    const style = useMemo(
        () => ({
            background: Adapter.background,
        }),
        [Adapter.background]
    )

    const { t } = useTranslation()

    const [platformsState, setPlatforms] = useState(items)
    const isInstagramHidden = useInstagramHidden()

    useEffect(() => {
        if (!isInstagramHidden && !isVkHidden && !isTgHidden) {
            setPlatforms(items)
        }
        if (isInstagramHidden) {
            setPlatforms(items.filter((platform) => platform.platform !== Platform.Instagram))
        }
        if (isVkHidden) {
            setPlatforms(items.filter((platform) => platform.platform !== Platform.Vk))
        }
        if (isTgHidden) {
            setPlatforms(items.filter((platform) => platform.platform !== Platform.Tg))
        }
        if (isTikTokHidden) {
            setPlatforms(items.filter((platform) => platform.platform !== Platform.TikTok))
        }
    }, [isInstagramHidden, isVkHidden, isTgHidden, isTikTokHidden])

    const onLinkClick = () => {
        restart()
        clearSearch()
        clearFilters()
    }

    return (
        <>
            <Flex
                ref={ref}
                content="space-between"
                component={Button}
                align="center"
                // width={212}
                className={cn.button}
                style={style}
                variant="pure"
                onClick={toggle}
            >
                <Flex align="center">
                    <Adapter.Icon margin="0 10px 0 0" className={cn.icon} />
                    <Text className={cn['adapter--title']} component="span" color="white" semibold fSize={12}>
                        {Adapter.title}
                    </Text>
                </Flex>
                {/* <Icons name="arrow-down" className={cn.icon} /> */}
            </Flex>
            <Popup onClose={toggle} anchor={ref.current} isOpen={isOpen} style={popup}>
                <div className={cn.papper}>
                    {platformsState.map((Item) =>
                        Item.soon ? (
                            <Flex
                                onClick={close}
                                key={Item.label}
                                align="center"
                                className={Item.soon ? cn['adapter--soon'] : cn['adapter--default']}
                            >
                                <Item.Icon data-op="opacity" />
                                <Text component="span" data-op="opacity" fSize={12} margin="0 8px 0">
                                    {Item.label}
                                </Text>
                                {Item.soon && (
                                    <Text component="div" fSize={10} color="white" semibold className={cn.soon}>
                                        {t('Influencer_explorer.search_input.soon')}
                                    </Text>
                                )}
                            </Flex>
                        ) : (
                            <Link onClick={onLinkClick} to={`${SEARCH}/${Item.platform}`} key={Item.label}>
                                <Flex
                                    onClick={close}
                                    key={Item.label}
                                    align="center"
                                    className={Item.soon ? cn['adapter--soon'] : cn['adapter--default']}
                                >
                                    <Item.Icon data-op="opacity" />
                                    <Text component="span" data-op="opacity" fSize={12} margin="0 8px 0">
                                        {Item.label}
                                    </Text>
                                    {Item.soon && (
                                        <Text component="div" fSize={10} color="white" semibold className={cn.soon}>
                                            {t('Influencer_explorer.search_input.soon')}
                                        </Text>
                                    )}
                                </Flex>
                            </Link>
                        )
                    )}
                </div>
            </Popup>
        </>
    )
}

export default memo(AdapterSelector)
export { items as Adapters }
