import { createContext } from 'react'
import { Adapter } from './Adapter'
import { BasicFilter } from './filters/BasicFilter'
import { FilterCategory } from './filters/FilterCategory'

export interface IAdapterContext {
    adapter: Adapter<
        any,
        any,
        any,
        Record<string, FilterCategory<Record<string, BasicFilter<any, any>>>>
    >
}

export const AdapterContext = createContext<IAdapterContext>({
    adapter: new Adapter({
        Icon: () => null,
        title: 'ERROR',
        templateColumns: '',
        fetcher: () => Promise.reject(),
        countCheck: () => Promise.reject(),
        background: 'red',
        Item: () => null,
        Head: () => null,
        sortings: [{ label: 'error', value: 'error' }],
        keyWords: [{ label: 'error', value: 'error' }],
        filters: {},
    }),
})
