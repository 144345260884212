import React, { useState, useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'

import Text from '@/components/Text'
import Button from '@/components/Button'
import useOpenClose from '@/_helpers/useOpenClose'
import CloseIcon from '@material-ui/icons/Close'
import Popup from '@/_yc/Popup/Popup'
import Flex from '@/_yc/Flex'
import { BloggerArea } from '@/_yc'
import notif from '@/components/Notifier/model/actions.js'
import useToken from '@/_helpers/Hooks/useToken'

import { ParsingRequestReq } from './service'
import { PostChangeCampaignReq } from '../../queries'

import cn from './AddLinks.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Icons from '@/components/Icons'
import { TARIFFS } from '@/routs'
import { RootStore } from '@/_helpers/StoreType'
import { useCampaignId } from '@/_helpers/Hooks/useCampaignId'
const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 460,
    zIndex: 120,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}
export interface ITags {
    label: string
    index: string
    onClick: (key: string) => void
}

const LIMIT = 100
export const AddLinksPopup = () => {
    const location = useLocation<{ pathname?: string }>()

    const token = useToken()
    const dispatch = useDispatch()
    const [isOpen, open, close] = useOpenClose()
    const [tags, setTags] = useState<ITags[]>([])
    const { t } = useTranslation()
    const countsInfo = useSelector((store: RootStore) => store.HeaderMenu_.ReportCounter_)
    const id = useCampaignId()

    const req = async () => {
        dispatch(notif.loading(t('campaign_manager.hider.req.loading')))
        if (!token) {
            dispatch(notif.error(t('campaign_manager.hider.req.error')))
            return
        }
        ParsingRequestReq(
            token,
            parseInt(location.pathname.split('/')[2]),
            tags.map((item: ITags) => item.label)
        )
            .then(() => {
                dispatch(notif.success(t('campaign_manager.hider.req.success')))
                setState('done')
                PostChangeCampaignReq(token, id, true)
                // done()
            })
            .catch(() => {
                dispatch(notif.error(t('campaign_manager.hider.req.error')))
                done()
            })
        // setTags([])
    }

    const [uniqueTags] = useState<Set<string>>(new Set<string>())

    const handleDelete = useCallback(
        (index: string) => {
            uniqueTags.delete(index)
            setTags((prev) => prev.filter((blogger) => blogger.index !== index))
        },
        [uniqueTags]
    )

    const parseInputTags = useCallback(
        (inputTags: string[]) => {
            const parsedTags: ITags[] = []

            for (let blogger of inputTags) {
                const name = blogger

                if (uniqueTags.has(name)) continue
                parsedTags.push({
                    label: blogger,
                    index: name,
                    onClick: handleDelete,
                })

                uniqueTags.add(name)
            }

            return parsedTags
        },
        [uniqueTags, handleDelete]
    )

    const changeTags = (inputTags: string[]) => {
        const numOfTags = LIMIT - tags.length
        const parsedTags: ITags[] = parseInputTags(inputTags)

        setTags((prev) => prev.concat(parsedTags.slice(0, numOfTags)))
    }

    const [state, setState] = useState<'links' | 'approve' | 'done' | 'error'>('links')

    const done = () => {
        setTags([])
        setState('links')
        uniqueTags.clear()
        close()
    }
    return (
        <>
            <Button onClick={open} variant={'primary'} margin="6px 0 6px 8px">
                <Text color="white">{t('campaign_manager.add_links.btn')}</Text>
            </Button>
            {isOpen && (
                <div className={cn.root}>
                    <Popup style={style} isOpen={isOpen} onClose={done}>
                        <Flex className={cn.popup} column>
                            {state === 'links' && (
                                <>
                                    <Flex content={'space-between'}>
                                        <p className={cn.title}>{t('campaign_manager.add_links.btn')}</p>
                                        <CloseIcon className={cn['close']} onClick={done} />
                                    </Flex>

                                    <p className={cn.labelSub}>{t('campaign_manager.add_links.desc')}</p>

                                    <div>
                                        <p className={cn.label}>{t('campaign_manager.add_links.list')}</p>
                                        <BloggerArea
                                            value={tags}
                                            onChange={changeTags}
                                            limit={LIMIT}
                                            placeholder={
                                                tags.length === 0 ? t('campaign_manager.add_links.placeholder') : ''
                                            }
                                        />
                                    </div>

                                    <div className={cn.divider} />
                                    <Flex content="flex-end" style={{ width: '100%' }}>
                                        <Button
                                            variant="primary"
                                            className={cn.submit}
                                            onClick={() => setState('approve')}
                                            disabled={tags.length === 0}
                                        >
                                            {t('campaign_manager.add_links.submit')}
                                        </Button>
                                    </Flex>
                                </>
                            )}
                            {state === 'approve' && (
                                <>
                                    <Flex column style={{ rowGap: '20px' }}>
                                        <Flex
                                            style={{
                                                borderRadius: '10px',
                                                background: '#EFF1FF',
                                                width: 'fit-content',
                                                padding: '6px',
                                            }}
                                        >
                                            <Icons name="alert-triangle" className={cn.alert} />
                                        </Flex>

                                        <Text color="gray-1" fSize={20} fWeight={600}>
                                            {t('campaign_manager.add_links.approve.title', { count: tags.length })}
                                        </Text>

                                        <Text
                                            color="gray-1"
                                            fSize="14px"
                                            dangerouslySetInnerHTML={{
                                                __html: t('campaign_manager.add_links.approve.subTitle'),
                                            }}
                                        ></Text>

                                        <Flex style={{ padding: '10px', background: '#F2F4F7', borderRadius: '10px' }}>
                                            <Text color="gray-1" fSize="14px">
                                                {t('campaign_manager.add_links.approve.toolt')}
                                            </Text>
                                        </Flex>

                                        <Flex style={{ width: '100%' }} margin="20px 0 0 0">
                                            <Button
                                                variant="primary"
                                                className={cn.submit}
                                                onClick={() => {
                                                    if (
                                                        countsInfo.reportsCount === null ||
                                                        countsInfo.reportsCount < tags.length
                                                    ) {
                                                        setState('error')
                                                        return
                                                    }
                                                    req()
                                                }}
                                                style={{ width: '100%' }}
                                            >
                                                {t('campaign_manager.add_links.approve.agree')}
                                            </Button>
                                        </Flex>

                                        <Flex style={{ width: '100%' }}>
                                            <Button
                                                variant="outline"
                                                className={cn.submit}
                                                onClick={done}
                                                style={{ width: '100%' }}
                                            >
                                                {t('campaign_manager.add_links.approve.decline')}
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </>
                            )}
                            {state === 'done' && (
                                <>
                                    <Flex column style={{ rowGap: '20px' }}>
                                        <Flex
                                            style={{
                                                borderRadius: '10px',
                                                background: '#D9F5E6',
                                                width: 'fit-content',
                                                padding: '6px',
                                            }}
                                        >
                                            <Icons name="done" className={cn.done} />
                                        </Flex>

                                        <Text color="gray-1" fSize={20} fWeight={600}>
                                            {t('campaign_manager.add_links.done.title')}
                                        </Text>
                                        <Text color="gray-1" fSize="14px">
                                            {t('campaign_manager.add_links.done.subTitle', { count: tags.length })}
                                        </Text>

                                        <Flex style={{ padding: '10px', background: '#F2F4F7', borderRadius: '10px' }}>
                                            <Text color="gray-1" fSize="14px">
                                                {t('campaign_manager.add_links.done.toolt')}
                                            </Text>
                                        </Flex>

                                        <Flex style={{ width: '100%' }} margin="20px 0 0 0">
                                            <Button
                                                variant="primary"
                                                className={cn.submit}
                                                onClick={() => {
                                                    done()
                                                    window.location.reload()
                                                }}
                                                style={{ width: '100%' }}
                                            >
                                                {t('campaign_manager.add_links.done.agree')}
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </>
                            )}

                            {state === 'error' && (
                                <>
                                    <Flex column style={{ rowGap: '20px' }}>
                                        <Flex
                                            style={{
                                                borderRadius: '10px',
                                                background: '#FFE1E7',
                                                width: 'fit-content',
                                                padding: '6px',
                                            }}
                                        >
                                            <Icons name="close" className={cn.er} />
                                        </Flex>

                                        <Text color="gray-1" fSize={20} fWeight={600}>
                                            {t('campaign_manager.add_links.error.title')}
                                        </Text>
                                        <Text color="gray-1" fSize="14px">
                                            {t('campaign_manager.add_links.error.subTitle')}
                                        </Text>

                                        <Flex style={{ width: '100%' }} margin="20px 0 0 0" column>
                                            <Link to={TARIFFS}>
                                                <Button
                                                    variant="primary"
                                                    className={cn.submit}
                                                    onClick={done}
                                                    style={{ width: '100%' }}
                                                >
                                                    {t('campaign_manager.add_links.error.agree')}
                                                </Button>
                                            </Link>

                                            <Flex style={{ width: '100%' }} margin="20px 0 0 0">
                                                <Button
                                                    variant="outline"
                                                    className={cn.submit}
                                                    onClick={done}
                                                    style={{ width: '100%' }}
                                                >
                                                    {t('campaign_manager.add_links.error.decline')}
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </>
                            )}
                        </Flex>
                    </Popup>
                </div>
            )}
        </>
    )
}
