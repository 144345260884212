import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
    <svg
        {...props}
        width="18"
        height="12"
        viewBox="0 0 18 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.99935 11.8334C7.63258 11.8504 6.27993 11.5554 5.04435 10.9709C4.08659 10.5036 3.22656 9.85819 2.51018 9.06921C1.75138 8.25348 1.15391 7.30143 0.749349 6.26338L0.666016 6.00004L0.753516 5.73671C1.15836 4.69957 1.75455 3.74777 2.51102 2.93088C3.22713 2.14197 4.08688 1.4966 5.04435 1.02921C6.27994 0.4447 7.63258 0.149719 8.99935 0.16671C10.3661 0.149749 11.7187 0.444728 12.9543 1.02921C13.9121 1.49649 14.7722 2.14187 15.4885 2.93088C16.2488 3.74551 16.8464 4.69785 17.2493 5.73671L17.3327 6.00004L17.2452 6.26338C15.9379 9.66653 12.6445 11.8912 8.99935 11.8334ZM8.99935 1.83338C6.16257 1.74448 3.55886 3.39596 2.43018 6.00004C3.55868 8.60429 6.16251 10.2558 8.99935 10.1667C11.8361 10.2554 14.4396 8.60397 15.5685 6.00004C14.4413 3.39469 11.8366 1.74261 8.99935 1.83338ZM8.99935 8.50004C7.79712 8.508 6.75716 7.66451 6.51682 6.48652C6.27649 5.30853 6.90291 4.12506 8.01217 3.66143C9.12143 3.19779 10.4037 3.58349 11.0731 4.58213C11.7426 5.58078 11.6122 6.91344 10.7618 7.76338C10.2963 8.23442 9.66165 8.49968 8.99935 8.50004Z"
            fill="#6071FF"
        />
    </svg>
)
