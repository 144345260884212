import React, { FC, memo } from 'react'
// import { AdapterProvider, useSearchBy } from '../SearchAdapter'
import { Header } from './Header/Header'
import { SearchItems } from './SearchItems/SearchItems'
import PageLayout from '@/Layouts/PageLayout'
import { useTranslation } from 'react-i18next'
import { AdapterContext as Context } from '../SearchAdapter/AdapterContext'
import { AdsExplorerInstagramAdapter } from '../SearchAdapter/adsExplorer/instagram'
import { Adapter } from '../SearchAdapter/Adapter'
import { useLocation } from 'react-router-dom'
import { findAdapterType } from '../utils/findAdapterType'
import { platforms } from './platforms'

export interface ISearchPage {}

const SearchPage: FC<ISearchPage> = () => {
    const { t } = useTranslation()

    const loc = useLocation()
    const curPlatform = findAdapterType(loc.pathname)
    const adapter: { adapter: Adapter<any, any, any, any> } = {
        adapter: platforms.find((item) => item.id === curPlatform)?.adapter || AdsExplorerInstagramAdapter,
    }

    return (
        <Context.Provider value={adapter}>
            <PageLayout color="common-background" title={t('titles.t4')}>
                <Header />
                <SearchItems />
            </PageLayout>
        </Context.Provider>
    )
}

export default memo(SearchPage)
