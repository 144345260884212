import { MailingListItem } from '@/pages/Lists/components/ToOutreach/SendToMailingList/MailingListItem/MailingListItem'
import Portal from '@/_helpers/Portal'
import { RootStore } from '@/_helpers/StoreType'
import Flex from '@/_yc/Flex'
import Popup from '@/_yc/Popup'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from './InfluencersPopup.module.scss'

import Text from '@/components/Text'
import Icons from '@/components/Icons'
import { useSpaceTranslation } from '@/_helpers'
import { RemoveFromMailingList } from '@/components/MassAddToList/_model/actions'
import { useBloggerList } from '@/pages/Outreach/utils/useBloggerList'
import Loader from '@/components/Loader'
import { addExcludedBlogger, addExcludedChannel } from '@/pages/Outreach/Model/actions'

interface InfluencersPopupProps {
    isOpen: boolean
    close: () => void
}

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 460,
    zIndex: 2000,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}

export const InfluencersPopup: FC<InfluencersPopupProps> = (props) => {
    const t = useSpaceTranslation('outreach.templates.influencers_list.popup')
    const mailingList = useSelector((store: RootStore) => store.MassAddToList.mailingList)

    const [page, setPage] = useState(1)
    const bloggersRef = React.useRef<HTMLDivElement>(null)

    const listId = useMemo(() => {
        if (!mailingList.setId.length) return null
        return mailingList.setId[0]
    }, [mailingList])

    // disable scroll on body
    useEffect(() => {
        if (props.isOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [props.isOpen])

    const { bloggers, loading, noMoreBloggers } = useBloggerList({ listId, page })

    const dispatch = useDispatch()

    const onScroll = useCallback(() => {
        if (!bloggersRef.current) {
            return
        }

        const { scrollTop, scrollHeight, clientHeight } = bloggersRef.current
        if (scrollTop + clientHeight > scrollHeight - 200) {
            !loading && !noMoreBloggers && setPage((prev) => prev + 1)
        }
    }, [setPage, loading, noMoreBloggers])

    const onDelete = useCallback(
        (id: string) => {
            if (!listId) {
                dispatch(RemoveFromMailingList(id))
                return
            }

            const blogger = bloggers.find((item) => item.id === id)
            if (!blogger) return

            const channelId = blogger.channelId
            const bloggerId = blogger.externalId

            if (channelId) {
                dispatch(addExcludedChannel(channelId))
            } else if (bloggerId) {
                dispatch(addExcludedBlogger(bloggerId))
            }
        },
        [bloggers, listId, dispatch]
    )

    const allList = useMemo(() => {
        if (listId) {
            return bloggers
        }

        return mailingList.instList.concat(mailingList.ytList)
    }, [mailingList, bloggers, listId])

    if (!props.isOpen) return <></>

    return (
        <Portal>
            <div className={cn.root}>
                <Popup style={style} isOpen={props.isOpen} onClose={props.close}>
                    <div className={cn.popup}>
                        <Flex content="space-between" align="center" margin="0 0 20px 0">
                            <Flex column>
                                <Text fSize={20}>{t('title')}</Text>
                                <Text color="gray-new-2">{`${allList.length} influencers`}</Text>
                            </Flex>
                            <div onClick={props.close}>
                                <Icons name="close" className={cn.close}></Icons>
                            </div>
                        </Flex>

                        <div className={cn.mailingListItems} ref={bloggersRef} onScroll={onScroll}>
                            {allList.map((item, i) => (
                                <MailingListItem
                                    key={item.id}
                                    email={item.hasEmail}
                                    {...item}
                                    onDelete={onDelete}
                                    currNumber={i + 1}
                                />
                            ))}

                            {(bloggers.length === 0 || loading) && (
                                <Flex content="center" align="center">
                                    <Loader />
                                </Flex>
                            )}
                        </div>
                    </div>
                </Popup>
            </div>
        </Portal>
    )
}
