import { gql } from '@apollo/client'
import { QueryType } from './types'

interface QueryParams {
    orderBy: string
    offset: number
    filters: string[]
}

export const getTelegramChannelsQuery = (table: QueryType, search: string, queryParams: QueryParams) => {
    return gql`
      query SearchChannels {
        ${table} (${search} limit: 30, order_by: {${queryParams.orderBy}}, offset: ${queryParams.offset}, 
          where: {
            is_active: {_eq: true},
            ${queryParams.filters.join(', ')}
          }) 
        {
          id
          username
          title
          avatar
          subscribers_count
          er_views
          median_views_w
          channel_topics{
            telegram_topic{
              name
            }
          }
        }
        getUnlockedChannels {
          unlocked_tg_accounts
        }
      }
  `
}

export const getTelegramChannelsCountQuery = (queryType: QueryType, search: string, queryParams: QueryParams) => {
    return gql`
      query GetChannelsCount {
        ${queryType}_aggregate(${search} 
          where: {
            ${queryParams.filters.join(', ')}
          }) {
          aggregate {
            count
          }
        }
      }
  `
}

export const GET_TG_TOPICS = gql`
    query GetTopics {
        telegram_topic(order_by: { name: asc }) {
            id
            name
        }
    }
`
