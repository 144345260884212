import React, { FC } from 'react'

import { MobilePopup } from '@/components/MobilePopup/MobilePopup'
import Button from '@/components/Button'

import cn from './ListItem.module.scss'

interface IButton {
    isOpen: boolean
    close: VoidFunction
    title: string
    submit: VoidFunction
    value: string
    setValue: Function
    state: 'tag' | 'comment'
}

const MobilePopUp: FC<IButton> = ({ isOpen, close, title, submit, value, setValue, state }) => {
    return (
        <>
            <MobilePopup isOpen={isOpen} onClose={close} title={title}>
                {state === 'tag' ? (
                    <input value={value} onChange={(e) => setValue(e.target.value)} className={cn.input} />
                ) : (
                    <textarea
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        className={cn.input}
                        style={{ height: '90px' }}
                    />
                )}

                <div style={{ width: '100%', background: '#D6DBE6', borderRadius: '1px', height: '1px' }} />

                <Button variant="primary" onClick={submit} margin="15px 0 5px 0" style={{ width: '100%' }}>
                    {title}
                </Button>
            </MobilePopup>
        </>
    )
}

export default MobilePopUp
