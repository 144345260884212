import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { RootStore } from '@/_helpers/StoreType'
import PageLayout from '@/Layouts/PageLayout'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import Loader from '@/components/Loader'
import Flex from '@/_yc/Flex'
import Heading from '@/components/Heading'
import GlobalWidth from '@/_yc/GlobalWidth'

import cn from './FeedbackPage.module.scss'

const FeedbackPage = () => {
    const t = useSpaceTranslation('canny_feedback')
    const cannyToken = useSelector((store: RootStore) => store.authentication.user?.profile?.canny_token || null)

    const [loadWidget, setLoadWidget] = useState<boolean>(false)

    useEffect(() => {
        if (!cannyToken) return
        //@ts-ignore
        Canny('render', {
            boardToken: 'dc785db9-205b-d31e-356d-08bb731470d8',
            basePath: '/feedback', // See 'web' tab
            ssoToken: cannyToken, // See 'web' tab
            onLoadCallback: () => setLoadWidget(true),
        })
    }, [cannyToken])

    return (
        <PageLayout title={t('page_title')} color={'common-background'}>
            <GlobalWidth>
                <Heading className={cn.head} type="h1">
                    {t('page_header')}
                </Heading>
                {!loadWidget && (
                    <Flex content="center" align="center" style={{ width: '100%' }} margin="60px 0 0 0">
                        <Loader />
                    </Flex>
                )}
                <div data-canny className={cn.main} />
                {/* // style={{ padding: '60px 10px 0 10px' }} /> */}
            </GlobalWidth>
        </PageLayout>
    )
}
export default FeedbackPage
