import React, { FC } from 'react'

import cn from './LegendItem.module.scss'
import useReportTranslation from '../useReportTranslation'

export interface ILegendItem {
    color: string
    label: string
}

const LegendItem: FC<ILegendItem> = ({ color, label }) => {
    const t = useReportTranslation('legend')

    return (
        <div className={cn.root}>
            <div style={{ background: color }} className={cn.sq} />
            <span>{t(label)}</span>
        </div>
    )
}
export default LegendItem
