import React, { FC } from 'react'

import { shortNum } from '@/_helpers/_graphs/_tech'
import { StandartLabel, StandartEmptyText } from '../../Blocks'
// import Line from './Line'
import Dif from './Difs'

import cn from './LineChart.module.scss'
// import useData from './useData'
import useReportTranslation from '../useReportTranslation'
import { useWidth } from './useWidth'
import { GrowGraph } from '@yoloker/ui-collection'

export interface ILineChart {
    data?: {
        dataSet?: Array<{
            label: string
            value: number
        }>
        mainValue: number | string
        changes: string
    }
    width: string | number
    title: string
    hidden?: boolean
    toFixed?: number
    units?: string
}

// const empty = new Array<{ label: string; value: number }>()

const LineChart: FC<ILineChart> = ({ data, ...props }) => {
    const t = useReportTranslation(props.title)

    // const [max, min, months] = useData(data?.dataSet || empty)
    const dif: number = data ? +data.changes : 0

    const [container, ,] = useWidth(data?.dataSet && data.dataSet.length > 0)
    if (!data || !data.dataSet?.length)
        return (
            <div className={cn.root} style={{ width: props.width }}>
                <StandartEmptyText label={t('empty.label')} sublabel={t('empty.subLabel')} />
                <div className={cn.root_placeholder}>
                    <StandartLabel title={t('title')} helperText={t('helper')} />
                    <div className={cn['grid--layout']}>
                        <div className={cn.grid}>
                            <div className={cn['grid--placeholder']} />
                        </div>
                    </div>
                </div>
            </div>
        )

    return (
        <div className={cn.root} style={{ width: props.width }}>
            <StandartLabel title={t('title')} helperText={t('helper')} />
            <div className={cn.value}>
                {shortNum(data.mainValue, 2, props.toFixed)} {props.units}
                {!!dif && <Dif hidden={props.hidden} dif={dif} />}
            </div>
            <div className={props.hidden ? cn['blur--active'] : cn['blur--default']}>
                {data.dataSet && data.dataSet.length > 1 ? (
                    <div ref={container} className={cn.chart}>
                        {/* {container.current && visible && (
                            <Line
                                height={240}
                                width={width - 30}
                                data={data.dataSet}
                                max={max}
                                min={min}
                                months={months}
                                toFixed={props.toFixed}
                            />
                        )} */}
                        <GrowGraph
                            hideAxis
                            height={230}
                            graphData={data.dataSet.map((item) => item.value)}
                            graphCategories={data.dataSet.map((item) => item.label)}
                            type={props.title}
                            locale="ru"
                        />
                    </div>
                ) : (
                    <div className={cn['grid--layout']}>
                        <div className={cn.grid}>
                            <div className={cn['grid--placeholder']} />
                        </div>
                        <div className={cn['details-text']}>{t('empty.subLabel')}</div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default LineChart
