import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ChatHeader } from '../ChatHeader/ChatHeader'
import { Message } from '../Message/Message'
import YolocoChatAvatar from '../../AllChats/ChatItem/YolocoImg.svg'

import cn from './YolocoChat.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import Text from '@/components/Text'
import { getTutorial, YolocoChatMessage } from './getTutorial'
import { useSpaceTranslation } from '@/_helpers'
import { setTutorialMessages } from '@/pages/Outreach/Model/actions'
import { getCurrentTime } from '@/pages/Outreach/utils/getCurrentTime'
import { Platform } from '@/pages/Reports/types'

export const YolocoChat = () => {
    const t = useSpaceTranslation('outreach.messenger')
    const dispatch = useDispatch()
    const senderFirstName = useSelector((store: RootStore) => store.authentication.user?.profile?.first_name)

    const savedMessages = useSelector((state: RootStore) => state.Outreach.tutorialMessages)
    const [messages, setMessages] = useState<YolocoChatMessage[]>([])

    const senderIcon = useMemo(() => {
        if (!senderFirstName) return 'Y'

        return senderFirstName[0]
    }, [senderFirstName])

    const getMessagesFromSteps = useCallback(
        (steps: number[]) => {
            const curTime = getCurrentTime()
            const chatMessages = getTutorial(t, curTime, senderFirstName)
            return chatMessages.filter((message) => steps.includes(message.step || -100))
        },
        [senderFirstName, t]
    )

    const onClick = useCallback(
        (toSteps, text, callback) => {
            if (callback) {
                callback()
                return
            }

            const msgsCopy = messages.slice()
            const curTime = getCurrentTime()
            msgsCopy.push({ sender: 'user', text: text, time: curTime, id: -1 })
            msgsCopy.push(...getMessagesFromSteps(toSteps))
            setMessages(msgsCopy)
            dispatch(setTutorialMessages(msgsCopy))
            localStorage.setItem('tutorialMsgs', JSON.stringify(msgsCopy))
        },
        [messages, getMessagesFromSteps, dispatch]
    )

    useEffect(() => {
        if (savedMessages.length > 0) {
            setMessages(savedMessages)
        } else {
            setMessages(getMessagesFromSteps([1, 2]))
        }
    }, [setMessages, savedMessages, getMessagesFromSteps])

    return (
        <div>
            <ChatHeader
                name="Yoloco Team"
                campaignName="Onboarding"
                externalId=""
                avatar=""
                platform={Platform.Instagram}
                stats={{ er: '', followers: 0 }}
            />
            <hr className={cn.divider}></hr>

            <div className={cn.messagesContainer}>
                {messages
                    .slice()
                    .reverse()
                    .map((message) => (
                        <Message
                            id={message.id}
                            image={message.img}
                            message={message.text || ''}
                            time={message.time || ''}
                            sender={message.sender}
                            avatar={message.sender === 'blogger' ? YolocoChatAvatar : senderIcon}
                            isRead={true}
                            chatId={0}
                            username={''}
                        />
                    ))}
            </div>

            <div className={cn.buttons}>
                {messages[messages.length - 1]?.buttons?.map((btn) => {
                    return (
                        <div className={cn.btn} onClick={() => onClick(btn.toSteps, btn.text, btn.callback)}>
                            <Text tAlign="center" style={{ lineHeight: '16px' }}>
                                {btn.text}
                            </Text>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
