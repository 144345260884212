import { connect } from 'react-redux'
import { compose, withProps } from "recompose";

import { App } from './App';
import withCrossAuth from "./withCrossDomainAuth";
import { checkExternalDomain } from '@/_helpers/DomainPortal';
import actions from "@/pages/Auth/LoginPage/Model/_actions"; 
import { history } from '@/_helpers/'
import { SEARCH } from '@/routs.js'

const Wrapper = compose(
    withProps({
        CheckExternal: checkExternalDomain
    }),
    connect(
        ({ authentication }) => ({ authentication }),
        (dispatch) => ({
            login(token){
                localStorage.setItem("user", JSON.stringify({ token }))
                dispatch(actions.getUserInfo())
                history.push(SEARCH);
            },
            dispatch
        })
    ),
    withCrossAuth()
)(App);

export { Wrapper as App }
export * from './BottomBar.js'