import React, { FC, CSSProperties } from 'react'

import cn from './InstaLink.module.scss'
import Icons from '@/components/Icons'
import useClass from '@/_helpers/Hooks/useClass'

export interface IInstaLink {
    username: string
    postCode?: string

    margin?: string
    className?: string
    style?: CSSProperties
}

// TODO: remove this component and use ExternalLink instead
/**
 * @deprecated use ExternalLink instead
 */
const InstaLink: FC<IInstaLink> = (props) => {
    const root = useClass(cn.root, props.className)

    const link = props.postCode
        ? `https://www.instagram.com/p/${props.postCode}`
        : `https://www.instagram.com/${props.username}`

    return (
        <a
            href={link}
            className={root}
            style={{
                margin: props.margin,
                ...props.style,
            }}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Icons name="external-link" />
        </a>
    )
}

export default InstaLink
