import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import { Flex } from '@/_yc'
import { ApexOptions } from 'apexcharts'
import React, { useCallback, useMemo } from 'react'

import cn from './Graph.module.scss'

import ReactApexChart from 'react-apexcharts'
import { shortNum } from '@/_helpers/_graphs/_tech'
import Tooltip from './Tooltip/Tooltip'
import { renderToString } from 'react-dom/server'
import { TooltipOptions, Option } from './types'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import Loader from '@/components/Loader'

const AllocationGraph = () => {
    const t = useSpaceTranslation('report_layout.allocation_graph')

    const channelVideos = useSelector((store: RootStore) => {
        return store.report.rawVideosData.getVideos?.data?.youtube_channel[0]
    })
    const data: Option[] = useMemo(() => {
        let recentVideos = channelVideos?.recent_videos || []
        recentVideos = recentVideos?.filter((video) => !!video.youtube_videometric)

        return channelVideos
            ? recentVideos.map((video) => {
                  const likes = video.youtube_videometric?.like_count
                  const comments = video.youtube_videometric?.comment_count
                  const views = video.youtube_videometric?.view_count

                  return {
                      x: +video.youtube_videometric?.view_count || 0,
                      y: (likes + comments) / views || 0,
                      fillColor: '#6071FF',
                  }
              })
            : []
    }, [channelVideos])

    const displayTooltip = useCallback((options: TooltipOptions) => {
        return renderToString(<Tooltip dataPointIndex={options.dataPointIndex} w={options.w} />)
    }, [])

    const options: ApexOptions = {
        chart: {
            height: 320,
            type: 'scatter',
            toolbar: {
                show: false,
                autoSelected: 'pan',
            },
            zoom: {
                enabled: false,
            },
        },

        title: {
            text: t('title'),
            offsetY: 5,
            style: {
                fontFamily: 'Montserrat',
                color: '#ADADAD',
            },
        },
        series: [{ data: data }],
        tooltip: {
            custom: displayTooltip,
        },
        xaxis: {
            title: {
                text: t('views'),
                offsetY: 0,
                style: {
                    fontFamily: 'Montserrat',
                    color: '#ADADAD',
                },
            },
            type: 'numeric',
            tickAmount: 6,
            labels: {
                formatter: (val) => shortNum(val),
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            labels: {
                formatter: (val) => `${shortNum(val * 100, 1, 2)}%`,
            },

            title: {
                text: t('engagement'),
                offsetY: 15,
                style: {
                    fontFamily: 'Montserrat',
                    color: '#ADADAD',
                },
            },
        },
        markers: {},
    }

    return (
        <Flex className={cn.root} column>
            {data.length === 0 ? (
                <Flex content="center" margin="20px 0 30px 0">
                    <Loader />
                </Flex>
            ) : (
                <ReactApexChart options={options} height={'280px'} series={[{ data: data }]} type="scatter" />
            )}
        </Flex>
    )
}

export default AllocationGraph
