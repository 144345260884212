import { createReducer } from '@reduxjs/toolkit'
import { InternalChatData } from '../Messenger/Chat/Chat'
import { Messages } from '../Messenger/Chat/types'
import { YolocoChatMessage } from '../Messenger/Chat/YolocoChat/getTutorial'
import { AdvertisingCampaigns, CampaignStatus } from '../Service/Campaigns'
import { Template } from '../Service/Templates'
import { getBloggerData } from '../utils/bloggerData'
import {
    addChats,
    addExcludedBlogger,
    addExcludedChannel,
    addMessage,
    addToSelectedCampaigns,
    addToSelectedCampaignsAr,
    addUnread,
    clearExcluded,
    clearSelectedCampaigns,
    clearUnread,
    getTutorialMessages,
    removeFromSelectedCampaigns,
    setCampaignsFilter,
    setCurrentCampaign,
    setCurrentChat,
    setCurrentTemplate,
    setTutorialMessages,
    updateAdvertisingCampaigns,
    updateChats,
    updateLastMessage,
    updateMessages,
    updateSearchChats,
    updateTemplates,
} from './actions'

export interface OutreachState {
    tutorialMessages: YolocoChatMessage[]
    advertisingCampaigns: AdvertisingCampaigns
    chats: InternalChatData[]
    messages: Messages
    currentTemplate: number
    templates: Template[]
    searchChats: number[]
    currentChat: number
    currentCampaign: string
    selectedCampaigns: string[]
    campaignsFilter: CampaignStatus | 'all'
    excludedBloggers: string[]
    excludedChannels: string[]
}

const defaultState: OutreachState = {
    tutorialMessages: [],
    advertisingCampaigns: { results: [], count: 0, next: null, previous: null },
    chats: [],
    messages: [],
    currentTemplate: -1,
    templates: [],
    searchChats: [],
    currentChat: -1,
    currentCampaign: '',
    selectedCampaigns: [],
    campaignsFilter: 'all',
    excludedBloggers: [],
    excludedChannels: [],
}

export const Outreach = createReducer<OutreachState>(defaultState, (builder) => {
    builder.addCase(setTutorialMessages, (state, { payload }) => {
        if (payload) {
            return {
                ...state,
                tutorialMessages: payload,
            }
        } else {
            return {
                ...state,
            }
        }
    })
    builder.addCase(getTutorialMessages, (state) => {
        return {
            ...state,
            tutorialMessages: JSON.parse(localStorage.getItem('tutorialMsgs') || '[]') as YolocoChatMessage[],
        }
    })
    builder.addCase(updateAdvertisingCampaigns, (state, { payload }) => {
        const filteredCampaigns = payload.results.filter((campaign) => campaign.stats.status !== 'paused')
        return {
            ...state,
            advertisingCampaigns: {
                ...payload,
                results: filteredCampaigns,
            },
        }
    })
    builder.addCase(updateChats, (state, { payload }) => {
        return {
            ...state,
            chats: payload.map((chat) => {
                const { data: blogger, platform } = getBloggerData(chat)

                return {
                    campaignId: chat.advertising_campaign.id,
                    campaignName: chat.advertising_campaign.name,
                    chatId: chat.id,
                    bloggerName: blogger?.name,
                    bloggerId: blogger?.id,
                    avatar: blogger?.avatar,
                    lastMessage: chat.last_message.body,
                    time: chat.last_message.created_at,
                    unreadCount: chat.unread_messages,
                    lastReadMessageId: chat.last_read_message,
                    internalId: blogger?.internal_id,
                    stats: {
                        er: blogger?.er,
                        followers: blogger?.followers,
                    },
                    platform: platform,
                }
            }),
        }
    })
    builder.addCase(addChats, (state, { payload }) => {
        return {
            ...state,
            chats: [
                ...state.chats,
                ...payload.map((chat) => {
                    const { data: blogger, platform } = getBloggerData(chat)

                    return {
                        campaignId: chat.advertising_campaign.id,
                        campaignName: chat.advertising_campaign.name,
                        chatId: chat.id,
                        bloggerName: blogger.name,
                        bloggerId: blogger.id,
                        avatar: blogger.avatar,
                        lastMessage: chat.last_message.body,
                        time: chat.last_message.created_at,
                        unreadCount: chat.unread_messages,
                        lastReadMessageId: chat.last_read_message,
                        internalId: blogger.internal_id,
                        stats: {
                            er: blogger.er,
                            followers: blogger.followers,
                        },
                        platform: platform,
                    }
                }),
            ],
        }
    })
    builder.addCase(updateLastMessage, (state, { payload }) => {
        return {
            ...state,
            chats: state.chats.map((chat) => {
                if (chat.chatId === payload.chatId) {
                    return {
                        ...chat,
                        lastMessage: payload.message,
                        time: payload.time,
                    }
                } else {
                    return chat
                }
            }),
        }
    })
    builder.addCase(addUnread, (state, { payload }) => {
        // TODO: Move chat to top of list
        return {
            ...state,
            chats: state.chats.map((chat) => {
                if (chat.chatId === payload) {
                    return {
                        ...chat,
                        unreadCount: chat.unreadCount + 1,
                    }
                } else {
                    return chat
                }
            }),
        }
    })
    builder.addCase(clearUnread, (state, { payload }) => {
        return {
            ...state,
            chats: state.chats.map((chat) => {
                if (chat.chatId === payload) {
                    return {
                        ...chat,
                        unreadCount: 0,
                    }
                } else {
                    return chat
                }
            }),
        }
    })
    builder.addCase(updateMessages, (state, { payload }) => {
        return {
            ...state,
            messages: payload.map((m) => {
                return {
                    sender: m.sender_blogger || m.sender_channel ? 'blogger' : 'user',
                    message: m.body,
                    time: m.created_at,
                    id: m.id,
                    isRead: m.is_read,
                }
            }),
        }
    })
    builder.addCase(addMessage, (state, { payload }) => {
        return {
            ...state,
            messages: [payload, ...state.messages],
        }
    })
    builder.addCase(updateTemplates, (state, { payload }) => {
        return {
            ...state,
            templates: payload.results,
        }
    })
    builder.addCase(setCurrentTemplate, (state, { payload }) => {
        return {
            ...state,
            currentTemplate: payload,
        }
    })
    builder.addCase(updateSearchChats, (state, { payload }) => {
        return {
            ...state,
            searchChats: payload,
        }
    })
    builder.addCase(setCurrentChat, (state, { payload }) => {
        return {
            ...state,
            currentChat: payload,
        }
    })
    builder.addCase(setCurrentCampaign, (state, { payload }) => {
        return {
            ...state,
            currentCampaign: payload,
        }
    })
    builder.addCase(addToSelectedCampaigns, (state, { payload }) => {
        return {
            ...state,
            selectedCampaigns: [...state.selectedCampaigns, payload],
        }
    })
    builder.addCase(addToSelectedCampaignsAr, (state, { payload }) => {
        return {
            ...state,
            selectedCampaigns: state.selectedCampaigns.concat(payload),
        }
    })
    builder.addCase(removeFromSelectedCampaigns, (state, { payload }) => {
        return {
            ...state,
            selectedCampaigns: state.selectedCampaigns.filter((c) => c !== payload),
        }
    })
    builder.addCase(clearSelectedCampaigns, (state, { payload }) => {
        return {
            ...state,
            selectedCampaigns: [],
        }
    })
    builder.addCase(setCampaignsFilter, (state, { payload }) => {
        return {
            ...state,
            campaignsFilter: payload,
        }
    })
    builder.addCase(addExcludedBlogger, (state, { payload }) => {
        console.log(payload)
        return {
            ...state,
            excludedBloggers: [...state.excludedBloggers, payload],
        }
    })
    builder.addCase(addExcludedChannel, (state, { payload }) => {
        return {
            ...state,
            excludedChannels: [...state.excludedChannels, payload],
        }
    })
    builder.addCase(clearExcluded, (state, { payload }) => {
        return {
            ...state,
            excludedBloggers: [],
            excludedChannels: [],
        }
    })
})
