import React, { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement> & { color?: string }) => (
    <svg
        {...props}
        width={props.width || '180'}
        height={props.height || '30'}
        viewBox="0 0 180 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill={props.color || 'white'}
            d="M29.6574 1.44385H38.525L23.1235 18.7701V29.5187H15.4015V18.7701L0 1.44385H9.20696L19.4322 13.6364L29.6574 1.44385Z"
        />
        <path
            fill={props.color || 'white'}
            d="M38.1222 27.1123C35.3219 25.1604 33.9218 22.4599 33.9218 19.0107C33.9218 15.5615 35.3219 12.8743 38.1222 10.9492C40.9508 8.99733 44.7269 8.02139 49.4506 8.02139C54.1743 8.02139 57.9363 8.99733 60.7365 10.9492C63.5651 12.8743 64.9794 15.5615 64.9794 19.0107C64.9794 22.4599 63.5651 25.1604 60.7365 27.1123C57.9363 29.0374 54.1743 30 49.4506 30C44.7269 30 40.9508 29.0374 38.1222 27.1123ZM43.3833 14.9198C41.9408 15.9893 41.2195 17.3529 41.2195 19.0107C41.2195 20.6685 41.9408 22.0455 43.3833 23.1417C44.8542 24.2112 46.8766 24.746 49.4506 24.746C52.0246 24.746 54.0329 24.2112 55.4754 23.1417C56.9463 22.0455 57.6817 20.6685 57.6817 19.0107C57.6817 17.3529 56.9463 15.9893 55.4754 14.9198C54.0329 13.8235 52.0246 13.2754 49.4506 13.2754C46.8766 13.2754 44.8542 13.8235 43.3833 14.9198Z"
        />
        <path fill={props.color || 'white'} d="M69.5799 0H76.9624V29.5187H69.5799V0Z" />
        <path
            fill={props.color || 'white'}
            d="M85.7298 27.1123C82.9296 25.1604 81.5294 22.4599 81.5294 19.0107C81.5294 15.5615 82.9296 12.8743 85.7298 10.9492C88.5584 8.99733 92.3345 8.02139 97.0582 8.02139C101.782 8.02139 105.544 8.99733 108.344 10.9492C111.173 12.8743 112.587 15.5615 112.587 19.0107C112.587 22.4599 111.173 25.1604 108.344 27.1123C105.544 29.0374 101.782 30 97.0582 30C92.3345 30 88.5584 29.0374 85.7298 27.1123ZM90.9909 14.9198C89.5484 15.9893 88.8271 17.3529 88.8271 19.0107C88.8271 20.6685 89.5484 22.0455 90.9909 23.1417C92.4618 24.2112 94.4842 24.746 97.0582 24.746C99.6322 24.746 101.64 24.2112 103.083 23.1417C104.554 22.0455 105.289 20.6685 105.289 19.0107C105.289 17.3529 104.554 15.9893 103.083 14.9198C101.64 13.8235 99.6322 13.2754 97.0582 13.2754C94.4842 13.2754 92.4618 13.8235 90.9909 14.9198Z"
        />
        <path
            fill={props.color || 'white'}
            d="M145.784 21.7781C145.303 24.3717 143.748 26.3904 141.117 27.8342C138.515 29.2781 135.276 30 131.401 30C126.677 30 122.944 29.0508 120.2 27.1524C117.484 25.2273 116.127 22.5134 116.127 19.0107C116.127 15.508 117.47 12.8075 120.157 10.9091C122.873 8.98396 126.55 8.02139 131.189 8.02139C135.007 8.02139 138.218 8.72995 140.82 10.1471C143.422 11.5374 144.95 13.4893 145.402 16.0027L138.274 16.4037C137.794 15.4144 136.917 14.6524 135.644 14.1176C134.371 13.5562 132.886 13.2754 131.189 13.2754C128.841 13.2754 126.96 13.7968 125.546 14.8396C124.16 15.8824 123.467 17.2727 123.467 19.0107C123.467 20.7754 124.174 22.1791 125.588 23.2219C127.003 24.238 128.94 24.746 131.401 24.746C133.24 24.746 134.781 24.4519 136.026 23.8636C137.299 23.2487 138.161 22.4332 138.614 21.4171L145.784 21.7781Z"
        />
        <path
            fill={props.color || 'white'}
            d="M153.143 27.1123C150.343 25.1604 148.942 22.4599 148.942 19.0107C148.942 15.5615 150.343 12.8743 153.143 10.9492C155.971 8.99733 159.748 8.02139 164.471 8.02139C169.195 8.02139 172.957 8.99733 175.757 10.9492C178.586 12.8743 180 15.5615 180 19.0107C180 22.4599 178.586 25.1604 175.757 27.1123C172.957 29.0374 169.195 30 164.471 30C159.748 30 155.971 29.0374 153.143 27.1123ZM158.404 14.9198C156.961 15.9893 156.24 17.3529 156.24 19.0107C156.24 20.6685 156.961 22.0455 158.404 23.1417C159.875 24.2112 161.897 24.746 164.471 24.746C167.045 24.746 169.053 24.2112 170.496 23.1417C171.967 22.0455 172.702 20.6685 172.702 19.0107C172.702 17.3529 171.967 15.9893 170.496 14.9198C169.053 13.8235 167.045 13.2754 164.471 13.2754C161.897 13.2754 159.875 13.8235 158.404 14.9198Z"
        />
    </svg>
)
