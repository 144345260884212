import React, { FC, useContext, useRef, useState } from 'react'
import Flex from '@/_yc/Flex'
import Text from '@/components/Text'
import { ContentType, MediaPlanBlogger } from '@/pages/MediaPlanning/MediaPlan/utils/types'
import { useSpaceTranslation } from '@/_helpers'
import Icons from '@/components/Icons'

import cn from './ContentType.module.scss'
import useOpenClose from '@/_helpers/useOpenClose'
import Popup from '@/_yc/Popup'
import { CampaignManagerContext } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerContext'
import { useCampaignId } from '@/_helpers/Hooks/useCampaignId'
import { useMutation } from '@apollo/client'
import {
    ASSIGN_CONTENT_TYPE,
    CREATE_CONTENT_TYPE,
    DELETE_CONTENT_TYPE,
    GET_CONTENT_TYPES,
} from '@/pages/MediaPlanning/MediaPlan/utils/queries'
import { Color } from './Color/Color'

interface ContentTypeProps {
    blogger: MediaPlanBlogger
    contentTypes: ContentType[]
}

type ColorMapType = {
    [key in 'blue' | 'red' | 'violet' | 'gray' | 'green']: {
        background: string
        font: string
    }
}

const COLOR_MAP: ColorMapType = {
    blue: {
        background: '#DFE2FF',
        font: '#6071FF',
    },
    red: {
        background: '#FFE1E7',
        font: '#F5535D',
    },
    violet: {
        background: '#E9DAFF',
        font: '#9147FF',
    },
    gray: {
        background: '#E6EAF0',
        font: '#929EB0',
    },
    green: {
        background: '#CFF2DF',
        font: '#3DBA7E',
    },
}

export const ContentTypePopup: FC<ContentTypeProps> = ({ blogger, contentTypes }) => {
    const t = useSpaceTranslation('media_planner.plan.table.content_type')
    const ref = useRef(null)
    const [isOpen, open, close] = useOpenClose()
    const [currentColor, setCurrentColor] = useState<keyof ColorMapType>('gray')
    const [typeNameInput, setTypeNameInput] = useState<string>('')
    const [showColors, setShowColors] = useState<boolean>(false)

    const context = useContext(CampaignManagerContext)
    const id = useCampaignId()

    const [assignContentType] = useMutation(ASSIGN_CONTENT_TYPE, {
        context: context,
        onCompleted: () => {
            close()
        },
    })

    const [createContentType] = useMutation(CREATE_CONTENT_TYPE, {
        context: context,
        refetchQueries: [GET_CONTENT_TYPES, 'GetContentTypes'],
        onCompleted: (res) => {
            setTypeNameInput('')
        },
    })

    const [deleteContentType] = useMutation(DELETE_CONTENT_TYPE, {
        context: context,
        refetchQueries: [GET_CONTENT_TYPES, 'GetContentTypes'],
    })

    return (
        <>
            <div ref={ref}>
                {!blogger.contentType && (
                    <Flex
                        align="center"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            open()
                        }}
                    >
                        <Text color="gray-3" fSize={12}>
                            {t('select')}
                        </Text>
                        <Icons name="arrow-down" className={cn.arrow} />
                    </Flex>
                )}

                {blogger.contentType && (
                    <Flex
                        align="center"
                        className={cn.item}
                        style={{
                            backgroundColor: COLOR_MAP[blogger.contentType.color].background,
                            color: COLOR_MAP[blogger.contentType.color].font,
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            open()
                        }}
                    >
                        <p>{blogger.contentType?.name || 'empty'}</p>
                    </Flex>
                )}
            </div>

            <Popup placement="top" isOpen={isOpen} onClose={close} anchor={ref.current} style={{ zIndex: 20 }}>
                <Flex className={cn.popup} column>
                    <div className={cn.items}>
                        {contentTypes.map((type) => (
                            <div className={cn.itemContainer}>
                                <Flex
                                    align="center"
                                    className={cn.item}
                                    style={{
                                        backgroundColor: COLOR_MAP[type.color].background,
                                        color: COLOR_MAP[type.color].font,
                                        cursor: 'pointer',
                                    }}
                                >
                                    <p
                                        onClick={() => {
                                            blogger.contentType = type
                                            assignContentType({
                                                variables: {
                                                    orderId: id,
                                                    bloggerId: blogger.externalId,
                                                    contentTypeId: type.id,
                                                },
                                            })
                                        }}
                                    >
                                        {type.name}
                                    </p>
                                </Flex>

                                {type.mediaPlan && (
                                    <Icons
                                        name="trash"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            deleteContentType({
                                                variables: {
                                                    orderId: id,
                                                    contentTypeId: type.id,
                                                },
                                            })
                                        }}
                                    />
                                )}
                            </div>
                        ))}
                    </div>

                    <div className={cn.create}>
                        <div className={cn.createFirstLine}>
                            <Flex content="start">
                                <Color
                                    color={COLOR_MAP[currentColor].background}
                                    markColor={COLOR_MAP[currentColor].font}
                                    isActive
                                />
                                <Icons
                                    name="arrow-down"
                                    margin="0 5px"
                                    className={showColors ? cn['arrow-up'] : cn['arrow-down']}
                                    onClick={() => {
                                        setShowColors(!showColors)
                                    }}
                                />

                                <input
                                    placeholder="Enter name"
                                    style={{ width: 120 }}
                                    value={typeNameInput}
                                    onChange={(e) => setTypeNameInput(e.target.value)}
                                ></input>
                            </Flex>

                            <div
                                className={cn.done}
                                onClick={() => {
                                    createContentType({
                                        variables: {
                                            orderId: id,
                                            name: typeNameInput,
                                            color: currentColor,
                                        },
                                    })
                                }}
                            >
                                <Icons name="done" />
                            </div>
                        </div>

                        <div
                            className={cn.colors}
                            style={{ maxHeight: showColors ? 100 : 0, opacity: showColors ? 1 : 0 }}
                        >
                            {Object.keys(COLOR_MAP).map((color) => (
                                <div onClick={() => setCurrentColor(color as keyof ColorMapType)}>
                                    <Color
                                        color={COLOR_MAP[color as keyof ColorMapType].background}
                                        markColor={COLOR_MAP[color as keyof ColorMapType].font}
                                        isChosen={currentColor === color}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </Flex>
            </Popup>
        </>
    )
}
