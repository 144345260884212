import React, { FC } from 'react'

import Flex from '@/_yc/Flex'
import Text from '@/components/Text'
import { numberFormatter, useSpaceTranslation } from '@/_helpers'
import { Tooltip } from '@yoloker/ui-collection'
import Icons from '@/components/Icons'

import cn from './DownloadExcel.module.scss'
import { T } from '@/_helpers/useSpaceTranslation'
import { ValueHider } from '@/components/ValueHider/ValueHider'

interface IDownloadParams {
    numPosts: number | null
    numTokens: number
    translate?: T
    costLoading?: boolean
}

export const DownloadParams: FC<IDownloadParams> = ({ numPosts, numTokens, translate, costLoading = false }) => {
    let t = useSpaceTranslation('ads_explorer.download_excel')
    t = translate || t

    return (
        <>
            {/* Cost and amount info */}
            <Flex content={'space-between'} style={{ margin: '30px 0 0 0' }}>
                {/* Num of posts info */}
                <div>
                    <Flex>
                        <Text fSize={12} color={'gray-2'}>
                            {t('selected')}
                        </Text>
                        <Tooltip text={t('selected_helper')} />
                    </Flex>

                    <Flex align={'flex-end'}>
                        <Text fSize={24} semibold component={'span'}>
                            {numPosts ? numberFormatter(numPosts) : 0}
                        </Text>
                        <Text component={'span'} className={cn.sideText}>
                            {t('posts')}
                        </Text>
                        <Icons name={'ads-explorer'} className={cn.icon} />
                    </Flex>
                </div>

                {/* Cost info */}
                <div>
                    <Flex>
                        <Text fSize={12} color={'gray-2'}>
                            {t('cost')}
                        </Text>
                        <Tooltip text={t('cost_helper')} />
                    </Flex>

                    <Flex align={'flex-end'}>
                        {!costLoading && (
                            <>
                                <Text fSize={24} semibold component={'span'}>
                                    {numTokens}
                                </Text>
                                <Text component={'span'} className={cn.sideText}>
                                    {t('tokens')}
                                </Text>
                                <Icons name={'flag'} className={cn.icon} />
                            </>
                        )}
                        {costLoading && <ValueHider style={{ marginTop: 10 }} />}
                    </Flex>
                </div>
            </Flex>
        </>
    )
}
