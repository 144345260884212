import { VkSearchItem } from './types'
import { QueryType, VkUserInfoResult, VkAccountType } from './types'

export const getVkAccountsFromRaw = (
    queryType: QueryType,
    result: VkUserInfoResult,
    sliceIndex: number
): VkSearchItem[] => {
    const data = result[queryType]
    if (!data || data.length === 0) {
        return []
    }

    const unlockedChannels = result?.getUnlockedChannels?.unlocked_vk_accounts

    const isUnlocked = (id: string, accountType: VkAccountType): boolean => {
        return (
            unlockedChannels.filter((item) => item.account_type === accountType && item.vk_account_id === id).length > 0
        )
    }

    return data.slice(0, sliceIndex).map((item) => {
        return {
            id: item.account_id,
            username: item.username,
            fullName: item.full_name,
            followers: item.followers,
            averageViews: item.er_views,
            avatar: item.avatar_link,
            account_type: item.account_type,
            topics: item.vk_accounttopic.map((item) => item.vk_topic.name),
            unlocked: isUnlocked(item.account_id, item.account_type), // unlockedChannels?.includes(item.account_id),
        }
    })
}
