import { gql } from '@apollo/client'
export const SEARCH_CHANNELS_BY_TITLE = gql`
    query SearchChannelsByTitle($search: String, $limit: Int) {
        search_general_youtube_channels(
            args: { search: $search }
            limit: $limit
            order_by: { subscriber_count: desc_nulls_last }
        ) {
            avatar
            id
            title
            subscriber_count
        }
    }
`

export const SEARCH_CHANNELS_BY_ID = gql`
    query SearchChannelsById($id: String) {
        youtube_channel(where: { id: { _eq: $id } }) {
            avatar
            id
            title
            youtube_channelmetric {
                subscriber_count
            }
        }
    }
`

export const SEARCH_TWITCH_CHANNELS_BY_TITLE = gql`
    query SearchTwitchChannelsByTitle($search: String) {
        search_general_twitch_channels(args: { search: $search }) {
            id
            login
            profile_image_url
            subscriber_count
        }
    }
`

export const SEARCH_VK_ACCOUNTS = gql`
    query SearchVkAccount($search: String) {
        search_general_vk_account(args: { search: $search }, limit: 5) {
            avatar_link
            account_type
            full_name
            username
            followers
            er_views
            account_id
        }
        getUnlockedChannels {
            unlocked_vk_accounts
        }
    }
`

export const SEARCH_TG_ACCOUNTS = gql`
    query SearchChannels($search: String) {
        search_telegram_by_title_description(args: { search: $search }, limit: 5, where: { is_active: { _eq: true } }) {
            id
            username
            title
            avatar
            subscribers_count
        }
    }
`
export const SEARCH_TIKTOK_ACCOUNTS = gql`
    query searchTikTokBlogger($search: String!) {
        searchTikTokBlogger(username: $search) {
            data
        }
    }
`
